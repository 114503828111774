import { RentleSegment } from 'common/utils/segments';

import { SegmentApi, SegmentDb } from './types';

export const toApi = (data: SegmentDb): SegmentApi | undefined => {
	const segmentMap: Record<RentleSegment, SegmentApi> = {
		adult: 'adult',
		teen: 'teen',
		kid: 'kid',
		senior: 'senior',
	};
	return segmentMap[data] ?? undefined;
};

export const toDb = (data: SegmentApi): SegmentDb => data;
