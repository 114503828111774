import {
	ActiveState,
	AdditionalDetails,
	CancellationObject,
	Channel,
	CustomValueToCollect,
	Discount,
	HistoryObject,
	Languages,
	Location,
	OrderInfo,
	OrderProduct,
	PartialVariant,
	PayLinkInfo,
	PricingItem,
	ProductType,
	RentalLinkInfo,
	RentalState,
	ResponsiblePerson,
	SegmentPricing,
	Shopper,
	Terms,
	UserDetail,
	UserProperties,
} from 'common/types';
import { getLatestProductEndTime, getNextProductReturnTime } from 'common/utils/dateCalculations';

import { DepositObject, PaymentObject } from './taxMigrationHelpers';

// added export here in testing purposes
export function newFirestoreId(): string {
	// Alphanumeric characters
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let autoId = '';
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	if (autoId.length !== 20) {
		throw new Error('Invalid auto ID: ' + autoId);
	}
	return autoId;
}

// added export here in testing purposes
export const removeUndefinedValuesFromObject = (obj: any) => {
	Object.keys(obj).forEach((key) => {
		if (obj[key] && typeof obj[key] === 'object') {
			removeUndefinedValuesFromObject(obj[key]);
		} else if (obj[key] === undefined) {
			delete obj[key];
		}
	});
	return obj;
};

export const durationMsToSeconds = (duration: number) => {
	return duration / 1000;
};

// added export here in testing purposes
export const oldShopperToNewShopper = (
	shopper: OldShopper,
	shopperId: string,
	shopId: string,
	rentalId: string,
	productIds: string[],
): Shopper => {
	const dateNow = new Date().toISOString();
	const newShopper = {
		id: shopperId,
		created: dateNow,
		shopId,
		rentalId,
		firstName: shopper.firstName,
		lastName: shopper.lastName,
		phone: shopper.phone,
		email: shopper.email,
		marketing: shopper.marketing,
		paymentToken: shopper.paymentToken,
		language: shopper.language,
		category: shopper.category,
		segment: shopper.segment,
		userProperties: shopper.userProperties,
		additionalInformation: shopper.additionalInformation,
		productsReturned: shopper.productsReturned,
		productIds,
	};
	return removeUndefinedValuesFromObject(newShopper);
};

// added export here in testing purposes
export const oldOrderInfoToNewOrderInfo = (
	rental: OldOrderInfo,
	shoppers: Shopper[],
	products: OrderProduct[],
): OrderInfo => {
	rental = handleOldOrderInfoTypeErrorsWithBackend(rental);
	const shopperNames = [];
	const shopperNameKeywords = [];
	const shopperIdsWithProducts = {};
	const rentalProductCodes = [];
	const returnTimeNext = getNextProductReturnTime(products);
	const returnTimeLast = getLatestProductEndTime(products);
	const externalResponsible =
		rental.responsiblePerson &&
		rental.responsiblePerson.external &&
		rental.responsiblePerson.person;
	if (externalResponsible) {
		const name =
			externalResponsible.firstName +
			(externalResponsible.lastName ? ' ' + externalResponsible.lastName : '');
		shopperNames.push(name);
		shopperNameKeywords.push(externalResponsible.firstName.toLowerCase());
		if (externalResponsible.lastName) {
			shopperNameKeywords.push(externalResponsible.lastName.toLowerCase());
			shopperNameKeywords.push(name.toLowerCase());
		}
	}
	for (const shopper of shoppers) {
		const name = shopper.firstName + (shopper.lastName ? ' ' + shopper.lastName : '');
		shopperNames.push(name);
		shopperNameKeywords.push(shopper.firstName.toLowerCase());
		if (shopper.lastName) {
			shopperNameKeywords.push(shopper.lastName.toLowerCase());
			shopperNameKeywords.push(name.toLowerCase());
		}
		const shopperProducts = products.filter((p) => p.shopperId === shopper.id);
		const productIds = shopperProducts.map((p) => p.id);
		shopperIdsWithProducts[shopper.id] = productIds;
		for (const shopperProduct of shopperProducts) {
			if (shopperProduct.productCode) {
				rentalProductCodes.push(shopperProduct.productCode);
			}
		}
	}
	let endDateReturned = null;
	if (rental.rentalState === 'COMPLETED') {
		for (const state of rental.rentalStates) {
			if (state.state === 'COMPLETED') {
				endDateReturned = state.timestamp;
			}
		}
		if (!endDateReturned && rental.activeState === 'RENT_ENDED') {
			endDateReturned = returnTimeLast;
		}
	}
	const filteredShopperNameKeywords = shopperNameKeywords.filter((keyword) => Boolean(keyword));
	const orderInfo = {
		id: rental.id,
		activeState: rental.activeState,
		created: rental.created || rental.startDate || '',
		startDate: rental.startDate,
		returnTimeNext,
		endDateReturned,
		handlePayment: rental.handlePayment,
		charge: rental.charge,
		deposit: rental.deposit,
		discount: rental.discount,
		extraCharges: rental.extraCharges,
		type: rental.type,
		cancellationPolicy: rental.cancellationPolicy,
		channel: rental.channel,
		rentalState: rental.rentalState,
		shopId: rental.shopId,
		startLocation: oldLocationToNewLocation(rental.startLocation),
		endLocation: oldLocationToNewLocation(rental.endLocation),
		live: rental.live,
		responsiblePerson: rental.responsiblePerson,
		terms: {
			...rental.terms,
		},
		additionalInformation: rental.additionalInformation,
		shopperNames,
		shopperNameKeywords: filteredShopperNameKeywords,
		shopperIdsWithProducts,
		rentalProductCodes,
	};
	return removeUndefinedValuesFromObject(orderInfo);
};

export const getHistoryWithOldRentalStates = (rental: OldOrderInfo): HistoryObject[] => {
	const { rentalStates, activeStates } = rental;
	const rentalStateHistories = rentalStates.map(
		(state, index): HistoryObject => {
			const rentalStateHistory = {
				id: newFirestoreId(),
				timestamp: state.timestamp,
				eventType: 'RENTAL_STATE',
				field: 'rentalState',
				previousValue: index === 0 ? null : rentalStates[index - 1].state,
				newValue: state.state,
			};
			return removeUndefinedValuesFromObject(rentalStateHistory);
		},
	);
	const activeStateHistories = activeStates.map(
		(state, index): HistoryObject => {
			const activeStateHistory = {
				id: newFirestoreId(),
				timestamp: state.timestamp,
				eventType: 'ACTIVE_STATE',
				field: 'activeState',
				previousValue: index === 0 ? null : rentalStates[index - 1].state,
				newValue: state.state,
			};
			return removeUndefinedValuesFromObject(activeStateHistory);
		},
	);
	return [...rentalStateHistories, ...activeStateHistories];
};

export interface OldLocation {
	name: string;
	address: OldAddress;
}

export interface OldAddress {
	address: string;
	zipCode?: string;
	city?: string;
	country?: string;
	state?: string;
}

// added export here in testing purposes
export const oldLocationToNewLocation = (location: OldLocation): Location => {
	return {
		name: location.name,
		...location.address,
		id: '',
	};
};

// added export here in testing purposes
export const handleOldOrderInfoTypeErrorsWithBackend = (rental: OldOrderInfo): OldOrderInfo => {
	if (!rental.shoppers) {
		return rental;
	}
	rental.shoppers.map((s) => {
		if (!s.userProperties) {
			s.userProperties = {};
		}
		if ((s as any).weight) {
			s.userProperties.weight = (s as any).weight;
		}
		if ((s as any).height) {
			s.userProperties.height = (s as any).height;
		}
		if ((s as any).shoeSize) {
			s.userProperties.shoeSize = (s as any).shoeSize;
		}
		if ((s as any).additionalProducts) {
			(s as any).additionalProducts.forEach((ap: OldOrderProduct) => {
				s.products.push({ ...ap, selectedAsAdditional: true });
			});
			delete (s as any).additionalProducts;
		}
	});
	if (!rental.responsiblePerson) {
		rental.responsiblePerson = {
			external: false,
			shopperId: rental.shoppers[0].id,
		};
	}
	if (rental.responsiblePerson.external === undefined) {
		const responsiblePersonDetails = rental.responsiblePerson as any;
		let responsiblePerson: ResponsiblePerson = {
			external: true,
			person: responsiblePersonDetails,
		};
		const responsibleShopper = rental.shoppers.filter((sh, index) => {
			if (sh.id === responsiblePersonDetails.id) {
				rental.shoppers[index] = {
					...rental.shoppers[index],
					...responsiblePersonDetails,
				};
				return true;
			}
			return false;
		})[0];
		if (responsibleShopper) {
			responsiblePerson = {
				external: false,
				shopperId: responsibleShopper.id,
			};
		}
		rental.responsiblePerson = responsiblePerson;
	}
	return rental;
};

// added export here in testing purposes
export interface OldPayLinkInfo {
	linkId?: string;
	sendSms?: boolean;
	sendEmail?: boolean;
	comment?: string;
	websiteLink?: boolean;
	websiteUserEmail?: string;
}

// added export here in testing purposes
export interface OldOrderInfo {
	id: string;
	activeState: ActiveState;
	activeStates: Array<{
		state: ActiveState;
		timestamp: string;
	}>;
	rentalStates: Array<{
		state: RentalState;
		timestamp: string;
	}>;
	created?: string;
	startDate: string;
	endDate: string;
	handlePayment: boolean;
	charge: PaymentObject;
	deposit: DepositObject;
	discount: Discount;
	extraCharges?: PaymentObject[];
	type?: ProductType;
	cancellationPolicy?: CancellationObject[];
	channel: Channel;
	payLink?: PayLinkInfo;
	rentalState: RentalState;
	shopId: string;
	startLocation: OldLocation;
	endLocation: OldLocation;
	terminal?: {
		state: string;
		info?: string;
	};
	live: boolean;
	history?: HistoryObject[];
	shoppers: OldShopper[];
	responsiblePerson: ResponsiblePerson;
	terms: {
		signatureRef?: string;
		accepted: boolean;
		timestamp?: string;
	};
	additionalInformation?: string;
}

// added export here in testing purposes
export interface OldShopper {
	id: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	marketing: boolean;
	regularCustomer?: boolean;
	paymentToken?: string;
	language?: Languages;
	products: OldOrderProduct[];
	endDate?: string;
	category?: string;
	rentalDuration: number | '';
	segment?: string;
	experience?: string;
	userProperties: UserProperties;
	additionalInformation?: string;
	productsReturned?: boolean;
}

// added export here in testing purposes

interface OldProductVariant {
	name: string;
	price: number | '';
}
export interface OldOrderProduct extends OldProductApi {
	units: number;
	setProducts: OldOrderProduct[];
	selectedAsAdditional: boolean;
	manualCharge?: number;
	manualDeposit?: number;
	productCode?: string;
	customValues?: Array<{
		name: string;
		value: number | string;
	}>;
	variant?: OldProductVariant;
	// FALLBACK FOR IN-STORE
	additionalProducts?: OldOrderProduct[];
}

export interface OldProductApi {
	version?: number;
	id: string;
	additionalProductIds?: string[]; // PRODUCT ID:S
	setProductIds?: string[]; // PRODUCT ID:S in case it is a set
	set: boolean;
	additionalProduct: boolean;
	fixedPrice: number | '';
	image?: string;
	name: string;
	name_en?: string;
	category?: string;
	value?: number | '';
	useSavedPricingTable?: boolean;
	savedPricingTable?: string;
	pricing?: PricingItem[];
	segmentPricings?: SegmentPricing[];
	vatPercent: number;
	terms?: Terms;
	shopId: string;
	description?: string;
	description_en?: string;
	additionalDetails?: AdditionalDetails[];
	additionalDetails_en?: AdditionalDetails[];
	type?: ProductType;
	collectProductCode?: boolean;
	customValuesToCollect?: CustomValueToCollect[];
	userDetails?: UserDetail[];
	highlightProduct: boolean;
	variants?: OldProductVariant[];
	variants_en?: OldProductVariant[];
	segmentFilters?: string[];
	hidden?: boolean;
	variantProperties?: string[];
	productVariants?: PartialVariant[];
	stockProperties?: string[];
	stockType?: 'bulk' | 'item' | null;
}
