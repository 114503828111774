import { Address } from 'common/models/_atoms/Address';
import { Language } from 'common/models/_atoms/Language';
import { ResponsiblePersonDetails } from 'common/types';
import { getResponsiblePersonDetails } from 'common/utils/newRentalUtils';
import { isDeepEmptyObject } from 'common/utils/objects';

import { PersonDb } from '../Person/types';
import { CustomerDetailsApi, CustomerDetailsDb } from './types';

export const toApi = (
	data: CustomerDetailsDb,
	options: { persons: PersonDb[] },
): CustomerDetailsApi => {
	const getMetaData = (
		data: ResponsiblePersonDetails | PersonDb,
	): CustomerDetailsApi['metafields'] => {
		const metafields = {
			externalAuth: data.meta?.auth
				? {
						uid: data.meta?.auth?.uid,
						email: data.meta?.auth?.email,
				  }
				: undefined,
		};
		return isDeepEmptyObject(metafields) ? undefined : metafields;
	};

	const person: ResponsiblePersonDetails | PersonDb =
		getResponsiblePersonDetails(data, options.persons) ?? options.persons[0];
	return {
		firstName: person.firstName,
		lastName: person.lastName,
		phone: person.phone,
		email: person.email,
		marketingConsent: person.marketing,
		language: !!person.language ? Language.toApi(person.language) : undefined,
		address: !!person.homeAddress ? Address.toApi(person.homeAddress) : undefined,
		customerId: person.customerId ?? null,
		metafields: getMetaData(person),
	};
};
