import { Plan } from 'common/modules/plans';

/* 
    reference structure: [TYPE][BILLING TYPE][UNIQUE CODE]
    TYPES:
        BK: Booking
        MS: Merchant service
        SB: Subscription
		CS: Custom fee
		CR: Credits
    BILLING TYPES:
        E: Per event
        M: Monthly
        D: Daily
        A: Annual
		O: One-off
	UNIQUE CODE:
		- Sequential from 01-99
*/

export enum RentleProductRef {
	/**
	 * Subscription Plan products
	 */
	BASIC_PLAN = 'SBD02',
	GROW_PLAN = 'SBD03',
	ADVANCED_PLAN = 'SBD04',
	DISCOVER_PLAN = 'SBD05',
	BUILD_PLAN = 'SBD06',
	ADVANCE_PLAN = 'SBD07',
	ADDITIONAL_STORE = 'SBD08',
	/**
	 * Add-on products (generated by add-ons)
	 */
	DELIVERY_MERCHANT_SERVICE_BOOKING = 'MSE01',
	API_WEBHOOKS_USAGE = 'MSE02',
	/**
	 * Custom products (internal use)
	 */
	CUSTOM_DISCOUNT_ONE_OFF = 'CSO01',
	CUSTOM_FEE_ONE_OFF = 'CSO02',

	/**
	 * Revenue discounts
	 */
	BASIC_REVENUE_DISCOUNT = 'SBO01',
	GROW_REVENUE_DISCOUNT = 'SBO02',
	ADVANCED_REVENUE_DISCOUNT = 'SBO03',

	/**
	 * Subscription discounts
	 */
	SUBSCRIPTION_START_DISCOUNT = 'SBO05',
	SUBSCRIPTION_CANCEL_REFUND = 'SBO06',
	SUBSCRIPTION_CAMPAIGN_DISCOUNT = 'SBO07',

	/**
	 * Credits
	 */
	CREDITS_USED = 'CRO01',
}

/**
 * A "Rentle product" is:
 *
 * - Something we bill from the customer, where each rentle product represents a row in the invoice
 *
 * A "Rentle product" is not:
 *
 * - A description of a feature or plan
 * - Something that needs to exist for a feature, add-on or plan to exist
 * - A one-to-one mapping to plans/features/add-ons. A specific plan/feature/add-on can
 * for example generate billing events with multiple different rentle products.
 */
export interface RentleProduct {
	/**
	 * The unique reference code of the product
	 */
	ref: RentleProductRef;

	/**
	 * The description of the product, which will be printed in the corresponding invoice row
	 */
	description: string;

	/**
	 * Reference for a plan
	 */
	planRef?: Plan;
}
