import React from 'react';

import { Box, ListItem, ListItemIcon, Typography, alpha, styled, useTheme } from '@mui/material';

import { useTranslation } from 'services/localization';
import { OnboardingStep } from 'services/onboarding';

export interface Props {
	step: OnboardingStep;
	isOpen: boolean;
	onClick: () => void;
}
const OnboardingStepHeader = (props: Props) => {
	const { step, isOpen, onClick } = props;
	const { name, icon: Icon, items } = step;
	const { t } = useTranslation();
	const theme = useTheme();
	const itemsCompleted = items?.filter((item) => item.done).length ?? 0;
	const itemsCount = items?.length ?? 1;
	const itemsLeft = itemsCount - itemsCompleted;
	const stepCompleted = itemsCompleted === itemsCount;
	return (
		<>
			<ListItem
				sx={{
					p: !!Icon ? '16px' : '24px 16px 24px 32px',
					'&:hover': {
						outline: !isOpen ? `1px solid ${theme.palette.primary.main}` : undefined,
						cursor: 'pointer',
						borderRadius: !isOpen ? '5px' : 0,
					},
					borderBottom: isOpen ? `1px solid ${theme.palette.border.table}` : undefined,
				}}
				onClick={onClick}
			>
				{!!Icon && (
					<StyledIcon>
						{
							<Icon
								color={stepCompleted ? theme.palette.primary.lighter : theme.palette.primary.main}
							/>
						}
					</StyledIcon>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						gap: 1,
					}}
				>
					<Typography
						sx={{
							fontWeight: '500',
							color: stepCompleted ? theme.palette.text.tertiary : theme.palette.text.primary,
							flex: '1',
						}}
					>
						{name}
					</Typography>
					<Typography
						variant="body2"
						sx={{
							color: stepCompleted ? theme.palette.success.main : theme.palette.colors.tundra.main,
							textAlign: 'right',
							'@media (max-width:440px)': {
								flex: stepCompleted ? '0 1 0' : undefined,
							},
							fontWeight: '400',
						}}
					>
						{stepCompleted
							? t('homeView.onboarding.completed', 'Completed')
							: t('homeView.onboarding.stepsOfStepsLeft', {
									defaultValue: '{{itemsLeft}}/{{itemsCount}} steps left',
									itemsLeft,
									itemsCount,
							  })}
					</Typography>
				</Box>
			</ListItem>
		</>
	);
};

const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
	height: 40,
	width: 40,
	minWidth: 40,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
	marginRight: theme.spacing(2),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: 4,
}));

export default OnboardingStepHeader;
