import { getOpenApiExample } from 'common/utils/openApi';

import { zOrderApi } from './api';
import { toApi } from './mapper';

export const Order = {
	api: zOrderApi,
	toApi,
	example: getOpenApiExample(zOrderApi),
};
