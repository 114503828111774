import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import { InventoryConfiguration } from 'common/modules/inventory/types';

export const getInventoryConfigurationQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.inventoryConfigurations;
	const dataUpdater = undefined;
	const query = buildQuery<InventoryConfiguration>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<InventoryConfiguration>(collectionPath, dbRefs, dataUpdater),
	};
};
