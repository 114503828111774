import React from 'react';

import { Box, Button, Grid, Hidden, Typography, styled } from '@mui/material';

import ContainerBox from 'components/ContainerBox';
import HasFeature, { HasFeatureProps } from 'components/features/HasFeature';
import LockedButton from 'components/features/LockedButton';

export interface PromoPageProps {
	tag: string;
	title: string;
	description: JSX.Element;
	image?: string;
	primaryCta: {
		text: string;
		onClick: () => void;
		requiredFeature?: HasFeatureProps['requiredFeature'];
	};
	secondaryCta?: {
		text: string;
		onClick: () => void;
		requiredFeature?: HasFeatureProps['requiredFeature'];
	};
}
const PlanPromoPage = (props: PromoPageProps) => {
	return (
		<ContainerBox>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" sx={{ mb: 2 }}>
						{props.tag}
					</Typography>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box display="flex" flexDirection="column">
						{!!props.image && (
							<Hidden mdUp>
								<ImageContainer sx={{ my: 2 }}>
									<Image src={props.image} alt="" />
								</ImageContainer>
							</Hidden>
						)}
						<Typography variant="h4" sx={{ mb: 3 }}>
							{props.title}
						</Typography>
						{props.description}
						<Box sx={{ display: 'flex', flexDirection: 'row', mt: 5 }}>
							<HasFeature
								requiredFeature={props.primaryCta.requiredFeature}
								fallback={
									<LockedButton color="primary" variant="contained" sx={{ mr: 1 }}>
										{props.primaryCta.text}
									</LockedButton>
								}
							>
								<Button
									color="primary"
									variant="contained"
									onClick={props.primaryCta.onClick}
									sx={{ mr: 1 }}
								>
									{props.primaryCta.text}
								</Button>
							</HasFeature>
							{!!props.secondaryCta && (
								<HasFeature requiredFeature={props.secondaryCta.requiredFeature}>
									<Button color="primary" variant="outlined" onClick={props.secondaryCta.onClick}>
										{props.secondaryCta.text}
									</Button>
								</HasFeature>
							)}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={1}></Grid>
				{!!props.image && (
					<Hidden mdDown>
						<Grid item xs={12} md={7}>
							<ImageContainer sx={{ mt: 1 }}>
								<Image src={props.image} alt="" />
							</ImageContainer>
						</Grid>
					</Hidden>
				)}
			</Grid>
		</ContainerBox>
	);
};

const ImageContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	paddingTop: '66.6%',
	position: 'relative',
}));

const Image = styled('img')({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	objectFit: 'cover',
});
export default PlanPromoPage;
