import { Paper, Stack, Typography } from '@mui/material';

import { Listing } from '@rentle/resell-api-client';
import ListingCard from 'common/components/listings/ListingCard';
import { useTranslation } from 'services/localization';

interface ListingPreviewProps {
	value: Listing;
}

const ListingPreview = (props: ListingPreviewProps) => {
	const { value } = props;
	const { t } = useTranslation();

	return (
		<Paper sx={{ height: '100%' }}>
			<Stack p={2} sx={{ height: '100%' }} alignContent="stretch">
				<Typography variant="h5" color="text.secondary" mb={2}>
					{t('listings.preview', 'Preview')}
				</Typography>
				<ListingCard listing={value} t={t} />
			</Stack>
		</Paper>
	);
};

export default ListingPreview;
