import { SnapshotMetadata } from '@firebase/firestore';

import * as dbPath from 'common/api/db/paths';
import { DbRefs, QueryFields } from 'common/db/api/types';
import { OpeningHoursDoc } from 'common/modules/openingHours';

import { buildQuery, createBasicDbOperations } from '../utils';
import { createQueryFieldsHelpers } from '../utils/queryFields';
import { GetQueryFields } from './../types';

export const getOpeningHoursQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.openingHours;
	const query = buildQuery<OpeningHoursDocWithQueryFields>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<OpeningHoursDocWithQueryFields>(collectionPath, dbRefs),
		...createQueryFieldsHelpers(getQueryFields),
		/** Get the opening hours to use for a store
		 *
		 * @param storeId The ID of the store
		 * @param merchantId The ID of the merchant
		 * @returns The store opening hours document if it exists, or the merchant default document if not
		 */
		storeDocOrDefault: (storeId: string, merchantId: string) => {
			const storeDocQuery = query
				.where('merchantId', '==', merchantId)
				.where('__query.tags', 'array-contains-any', [storeId, 'default'])
				.orderBy('isDefault', 'asc')
				.limit(1);

			return {
				get: () => storeDocQuery.get().then((res) => res[0] as OpeningHoursDoc | undefined),
				listen: (
					callback: (data: OpeningHoursDoc | undefined, metadata?: SnapshotMetadata) => void,
					errorCallback?: (error: Error) => void,
				) =>
					storeDocQuery.onSnapshot((data, _, metadata) => {
						callback(data[0] as OpeningHoursDoc | undefined, metadata);
					}, errorCallback),
			};
		},
		/** Get opening hours for all stores for a given merchant
		 *
		 * @param merchantId The ID of the merchant
		 * @returns An array of opening hours documents
		 */
		byMerchantId: (merchantId: string) => query.where('merchantId', '==', merchantId),
	};
};

export type OpeningHoursDocWithQueryFields = OpeningHoursDoc & QueryFields<OpeningHoursQueryFields>;

type OpeningHoursQueryFields = {
	tags: [string] | [string, 'default'];
};

const getQueryFields: GetQueryFields<OpeningHoursDoc, OpeningHoursQueryFields> = (doc) => {
	const tags: [string] | [string, 'default'] = doc.isDefault
		? [doc.storeId, 'default']
		: [doc.storeId];
	return {
		tags,
	};
};
