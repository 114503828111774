import { z } from 'zod';

import { ItemType } from 'common/models/_atoms/ItemType';
import { LocaleField } from 'common/models/_atoms/LocaleField';
import { withOpenApi } from 'common/utils/openApi';

const zVariantInventoryApi = withOpenApi(
	z.object({
		skuId: z.string(),
		type: ItemType.api,
		units: z.number().int().nonnegative(),
	}),
	{
		title: 'SKU connected to the variant',
		description: 'The inventory SKU connected to the variant',
		fields: {
			skuId: 'The SKU ID this variant is connected to.',
			type: 'The type of the SKU.',
			units: 'The amount of SKU units that should be reserved when this variant is booked.',
		},
	},
);

export const zVariantApi = withOpenApi(
	z.object({
		id: z.string(),
		properties: withOpenApi(
			z.object({
				value: LocaleField.api,
				name: LocaleField.api,
				propertyId: z.string(),
			}),
			{
				fields: {
					value: 'Value of the variant property.',
					name: 'Name of the variant property.',
					propertyId: 'ID of the variant property.',
				},
			},
		).array(),
		sales: withOpenApi(
			z.object({
				enabled: z.boolean(),
				priceOverride: z.number().int().nonnegative().optional(),
			}),
			{
				fields: {
					enabled: 'Defines if the variant is available for sale.',
					priceOverride: 'Sales price override of the variant. In minor units.',
				},
			},
		).optional(),
		rentals: withOpenApi(
			z.object({
				enabled: z.boolean(),
				priceIncrease: z.number().int().nonnegative().optional(),
			}),
			{
				fields: {
					enabled: 'Defines if the variant is available for rental.',
					priceIncrease: 'The price increase of the variant. In minor units.',
				},
			},
		).optional(),
		inventory: withOpenApi(
			z.array(
				withOpenApi(z.array(zVariantInventoryApi), {
					title: 'List of inventory resource options',
					description:
						'List of SKU objects that can fulfill the single inventory resource need. Example: Bike resource could be any item from SKUs BIK-M-22 or BIK-M-23',
				}),
			),
			{
				title: 'List of inventory resources',
				description:
					'List of different inventory resources that should be reserved for the variant. Example: a bicycle rental could need an inventory resource for bike, lock and helmet.',
			},
		).optional(),
	}),
	{
		description:
			'A variant is a specific option for a product. For example, a product could have a variant size with values "Medium" and "Large".',
		fields: {
			id: 'Unique ID of the variant.',
			properties:
				'List of variant properties that describe the variant and their values.',
			sales: 'Defines if the variant is available for sale and if it has specific configurations.',
			rentals:
				'Defines if the variant is available for rental and if it has specific configurations.',
			inventory:
				'The inventory connection of the variant. It defines the SKUs that should be reserved when a variant is booked. It is defined as two-dimensional array, where the first dimension defines the list of different inventory resources that should be reserved for the variant. The second dimension defines the possible SKU options for the inventory resource. As an example, a bicycle could need both a bike SKU and a helmet SKU from the inventory. This would be the first dimension listing. The bike SKU could have 3 different SKUs that would all be valid for the variant. These would be in the second dimension array.',
		},
	},
);

export const zVariantPropertyApi = withOpenApi(
	z.object({
		id: z.string(),
		name: LocaleField.api,
	}),
	{
		description:
			'A variant property is used to describe the different product characteristics, such as Size or Color.',
		fields: {
			id: 'ID of the variant property.',
			name: 'Name of the variant property.',
		},
		example: { id: '123', name: { def: 'Size' } },
	},
);
