import { intersection, minBy, partition } from 'lodash';
import moment from 'moment-timezone';

import { Duration, DurationType, LocaleField, PricingItem } from 'common/types';

import { getDurationInSecondsFromPricingItem, getDurationTypeFromTimePeriod } from './index';

const getItemsWithMatchingType = (pricingItems: PricingItem[], durationType: DurationType) =>
	pricingItems.filter(
		(pricingItem) => getDurationTypeFromTimePeriod(pricingItem.timePeriod) === durationType,
	);

const getItemsWithSameDurationPricings = (
	pricingItems: PricingItem[],
	durationType: DurationType,
	openingHourDays: number,
	baseDurationInSeconds: number,
) =>
	pricingItems.filter((pricingItem) => {
		return (
			(durationType === 'opening_hours' && openingHourDays === pricingItem.timeValue) ||
			(durationType === '24h' &&
				getDurationInSecondsFromPricingItem(pricingItem.timePeriod, pricingItem.timeValue || 0) ===
					baseDurationInSeconds)
		);
	});

const getItemsMatchingDefinitions = (
	pricingItems: PricingItem[],
	durationName: LocaleField | null,
) =>
	pricingItems.filter((pricingItem) => {
		const pricingItemName = pricingItem.label && pricingItem.label.def;
		return durationName && durationName.def === pricingItemName;
	});

const labelCheck = (x: PricingItem) => !x.label || !x.label.def;

const partitionByLabel = (pricingItems: PricingItem[]) => partition(pricingItems, labelCheck);

const preferLabelless = (a: PricingItem, b: PricingItem) => {
	return labelCheck(a) ? -1 : labelCheck(b) ? 1 : 0;
};

const sortByMatchingTypePreferLabelless = (
	a: PricingItem,
	b: PricingItem,
	durationType: DurationType,
) => {
	const aSameType = getDurationTypeFromTimePeriod(a.timePeriod) === durationType;
	const bSameType = getDurationTypeFromTimePeriod(b.timePeriod) === durationType;
	return aSameType && bSameType ? preferLabelless(a, b) : aSameType ? -1 : bSameType ? 1 : 0;
};

const sortByClosestDurations = (
	pricingItems: PricingItem[],
	durationType: DurationType,
	baseDurationInSeconds: number,
) => {
	const items = pricingItems.slice();
	items.sort((a, b) => {
		const aSeconds = getDurationInSecondsFromPricingItem(a.timePeriod, a.timeValue || 0);
		const bSeconds = getDurationInSecondsFromPricingItem(b.timePeriod, b.timeValue || 0);
		const aDiff = Math.abs(baseDurationInSeconds - aSeconds);
		const bDiff = Math.abs(baseDurationInSeconds - bSeconds);
		const diff = aDiff - bDiff;
		return diff === 0 ? sortByMatchingTypePreferLabelless(a, b, durationType) : diff;
	});
	return items;
};

const findClosestWithSameTypeAndDuration = (pricingItems: PricingItem[]) => {
	const [sameTypeWithoutLabel, sameTypeWithLabel] = partitionByLabel(pricingItems);
	return sameTypeWithoutLabel.length > 0 ? sameTypeWithoutLabel[0] : sameTypeWithLabel[0];
};

const findSmallestMultiplierFromMoreOrLess = (
	sortedPricingItems: PricingItem[],
	baseDurationInSeconds: number,
) => {
	const [moreThanRentalTimeSeconds, lessThanRentalTimeSeconds] = partition(
		sortedPricingItems,
		(p) =>
			getDurationInSecondsFromPricingItem(p.timePeriod, p.timeValue || 0) >= baseDurationInSeconds,
	);
	const [closestMoreThan] = moreThanRentalTimeSeconds;
	const [closestLessThan] = lessThanRentalTimeSeconds;

	return !!closestMoreThan && !!closestLessThan
		? (minBy([closestMoreThan, closestLessThan], (c) =>
				getPricingItemMultiplier(c, baseDurationInSeconds),
		  ) as PricingItem)
		: !!closestLessThan
		? closestLessThan
		: closestMoreThan;
};

const findClosestGuess = (
	pricingItems: PricingItem[],
	durationType: DurationType,
	baseDurationInSeconds: number,
) => {
	const sortedByClosestDurations = sortByClosestDurations(
		pricingItems,
		durationType,
		baseDurationInSeconds,
	);
	const preferredPricingItem = findSmallestMultiplierFromMoreOrLess(
		sortedByClosestDurations,
		baseDurationInSeconds,
	);
	return preferredPricingItem;
};

const getPricingItemMultiplier = (pricingItem: PricingItem, baseDurationInSeconds: number) => {
	const durationInSeconds = getDurationInSecondsFromPricingItem(
		pricingItem.timePeriod,
		pricingItem.timeValue || 0,
	);
	return durationInSeconds >= baseDurationInSeconds
		? pricingItem!.multiplier || 0
		: (pricingItem.multiplier || 0) * Math.ceil(baseDurationInSeconds / durationInSeconds);
};

// Calculate pricing as follows:
// 1. Get closest pricings where durations are less than and more than the rental duration
// 2. If:
//      - no less than: use closest more than duration pricing
//      - no more than: use closest less than duration pricing multiplied by the duration offset
//          (take into account minimum increments, 1 day 3h -> 1 day increment -> calculate for 2 days price)
//      - both: calculate, which price is cheaper, 1 day 3h -> 1 day (50$), 2 days (80$) -> get 2 days (80$) instead of 2x 1 day (100$)
export const getClosestPricingMultiplierForGivenDuration = (
	pricingItems: PricingItem[],
	duration: Duration,
): number | null => {
	if (pricingItems.length === 0) return null;

	const { durationType, durationName } = duration;
	const givenDurationInSeconds = duration.durationInSeconds || 0;
	const openingHourDays = Math.ceil(moment.duration(givenDurationInSeconds, 'seconds').asDays());

	const pItemsWithMatchingType = getItemsWithMatchingType(pricingItems, durationType);
	const pItemsWithSameDurationPricings = getItemsWithSameDurationPricings(
		pricingItems,
		durationType,
		openingHourDays,
		givenDurationInSeconds,
	);
	const pItemsWithMatchingDefinitions = getItemsMatchingDefinitions(pricingItems, durationName);

	const pItemsWithSameTypeAndDuration = intersection(
		pItemsWithMatchingType,
		pItemsWithSameDurationPricings,
	);
	const pItemsWithSameNameSameTypeAndDuration = intersection(
		pItemsWithMatchingDefinitions,
		pItemsWithSameTypeAndDuration,
	);

	const bestMatchingPricingItem =
		pItemsWithSameNameSameTypeAndDuration.length > 0
			? pItemsWithSameNameSameTypeAndDuration[0]
			: pItemsWithSameTypeAndDuration.length > 0
			? findClosestWithSameTypeAndDuration(pItemsWithSameTypeAndDuration)
			: findClosestGuess(pricingItems, durationType, givenDurationInSeconds);

	return getPricingItemMultiplier(bestMatchingPricingItem, givenDurationInSeconds);
};
