import { chain, memoize, sortBy } from 'lodash';
import moment from 'moment-timezone';

import { HH_MM, ISOString, YYYY_MM_DD } from 'common/types';

import {
	getDateAndTimeFromISOString,
	getDayTypeSpecificity,
	isDateDayType,
} from '../atoms/dates/utils';
import {
	dateAndTimeToISOString,
	isTimeAfter,
	isTimeBefore,
	isTimeBeforeOrSame,
	isTimeBetween,
	yyyyMmDd,
} from '../atoms/times';
import { DEFAULT_OPENING_HOURS, DEFAULT_OPENING_TIMES } from './constants';
import {
	OpeningHours,
	OpeningHoursConfig,
	OpeningHoursConfigWithValidity,
	OpeningHoursDoc,
	OpeningHoursExceptionRow,
	OpeningHoursRecurringRow,
	OpeningHoursRow,
	OpeningTimes,
} from './types';

export const getOpeningHoursForStore = (args: {
	openingHoursDocs: OpeningHoursDoc[];
	storeId: string;
}): OpeningHours => {
	const { openingHoursDocs, storeId } = args;
	return (
		openingHoursDocs.find((doc) => doc.storeId === storeId) ??
		getMerchantDefaultOpeningHours({ openingHoursDocs })
	);
};

export const getMerchantDefaultOpeningHours = (args: {
	openingHoursDocs: OpeningHoursDoc[];
}): OpeningHours => {
	const { openingHoursDocs } = args;
	return openingHoursDocs.find((doc) => doc.isDefault === true) ?? DEFAULT_OPENING_HOURS;
};

export const getOpeningHoursConfigForDate = (args: {
	openingHours: OpeningHours;
	date: YYYY_MM_DD;
}): OpeningHoursConfig => {
	const { openingHours, date } = args;
	return (
		openingHours.exceptions?.find((e) => date >= e.validity.from && date <= e.validity.to) ??
		openingHours.base
	);
};

export const getOpeningTimesForDate = memoize(
	(args: { openingHours: OpeningHours; date: YYYY_MM_DD }): OpeningTimes => {
		const { openingHours, date } = args;
		const config = getOpeningHoursConfigForDate({ openingHours, date });
		const sortedRows = sortOpeningHoursRowsBySpecificity(config.rows);

		if (!sortedRows.length) return DEFAULT_OPENING_TIMES;

		return (
			sortedRows.find((row) => isOpeningHoursRowValidForDate(row, date))?.times ?? {
				isClosed: true,
			}
		);
	},
	(args) => JSON.stringify(args),
);

export const getOpeningTimestampForDate = (args: {
	openingHours: OpeningHours;
	date: YYYY_MM_DD;
	timezone: string;
}): ISOString => {
	const openingTimes = getOpeningTimesForDate(args);

	if (openingTimes.isClosed) return moment.tz(args.date, args.timezone).endOf('day').toISOString();
	return dateAndTimeToISOString({
		date: args.date,
		time: openingTimes.openTime,
		timezone: args.timezone,
	});
};

export const getClosingTimestampForDate = (args: {
	openingHours: OpeningHours;
	date: YYYY_MM_DD;
	timezone: string;
}): ISOString => {
	const openingTimes = getOpeningTimesForDate(args);

	if (openingTimes.isClosed) return moment.tz(args.date, args.timezone).endOf('day').toISOString();
	return dateAndTimeToISOString({
		date: args.date,
		time: openingTimes.closeTime,
		timezone: args.timezone,
	});
};

export const isDateTimeWithinOpeningHours = (args: {
	openingHours: OpeningHours;
	dateTime: ISOString;
	timezone: string;
	inclusive?: {
		start: boolean;
		end: boolean;
	};
}): boolean => {
	const { openingHours, dateTime, timezone, inclusive } = args;
	const { date, time } = getDateAndTimeFromISOString(dateTime, timezone);
	const openingTimes = getOpeningTimesForDate({ openingHours, date });

	return (
		!openingTimes.isClosed &&
		isTimeBetween(time, [openingTimes.openTime, openingTimes.closeTime], inclusive)
	);
};

export const isDateTimeOutsideOpeningHours = (args: {
	openingHours: OpeningHours;
	dateTime: ISOString;
	timezone: string;
}): boolean => {
	return !isDateTimeWithinOpeningHours({
		...args,
		inclusive: {
			start: true,
			end: true,
		},
	});
};

export const isDateTimeAfterOpeningHours = (args: {
	openingHours: OpeningHours;
	dateTime: ISOString;
	timezone: string;
}): boolean => {
	const { openingHours, dateTime, timezone } = args;
	const { date, time } = getDateAndTimeFromISOString(dateTime, timezone);
	const openingTimes = getOpeningTimesForDate({ openingHours, date });

	if (openingTimes.isClosed) return true;
	return isTimeAfter(time, openingTimes.closeTime);
};

export const isDateTimeBeforeOpeningHours = (args: {
	openingHours: OpeningHours;
	dateTime: ISOString;
	timezone: string;
}): boolean => {
	const { openingHours, dateTime, timezone } = args;
	const { date, time } = getDateAndTimeFromISOString(dateTime, timezone);
	const openingTimes = getOpeningTimesForDate({ openingHours, date });

	if (openingTimes.isClosed) return true;
	return isTimeBefore(time, openingTimes.openTime);
};

export const isTimeWithinOpeningTimes = (args: {
	openingTimes: OpeningTimes;
	time: HH_MM;
	inclusive: {
		start: boolean;
		end: boolean;
	};
}): boolean => {
	const { openingTimes, time } = args;

	if (openingTimes.isClosed) return false;
	return isTimeBetween(time, [openingTimes.openTime, openingTimes.closeTime], args.inclusive);
};

export const isTimeAfterClosingTime = (args: {
	openingTimes: OpeningTimes;
	time: HH_MM;
}): boolean => {
	const { openingTimes, time } = args;
	if (openingTimes.isClosed) return true;

	return isTimeAfter(time, openingTimes.closeTime);
};

export const isTimeBeforeOpeningTime = (args: {
	openingTimes: OpeningTimes;
	time: HH_MM;
}): boolean => {
	const { openingTimes, time } = args;
	if (openingTimes.isClosed) return true;

	return isTimeBefore(time, openingTimes.openTime);
};

export const isStoreOpenForDay = (args: {
	openingHours: OpeningHours;
	date: YYYY_MM_DD;
}): boolean => {
	const { openingHours, date } = args;
	const openingTimes = getOpeningTimesForDate({ openingHours, date });
	return !openingTimes.isClosed;
};

export const isStoreClosedForDay = (args: {
	openingHours: OpeningHours;
	date: YYYY_MM_DD;
}): boolean => {
	return !isStoreOpenForDay(args);
};

export const getNextOpenDay = (args: {
	openingHours: OpeningHours;
	limit: YYYY_MM_DD;
}): YYYY_MM_DD | null => {
	const { openingHours, limit } = args;
	let current = moment().format('YYYY-MM-DD');

	while (current <= limit) {
		if (isStoreOpenForDay({ openingHours, date: current })) {
			return current;
		}
		current = moment(current).add(1, 'day').format('YYYY-MM-DD');
	}

	return null;
};

export const isOpeningHoursRowValidForDate = (row: OpeningHoursRow, date: YYYY_MM_DD): boolean => {
	return row.dayType === 'custom' ? row.date === yyyyMmDd(date) : isDateDayType(date, row.dayType);
};

export const sortOpeningHoursRowsBySpecificity = (rows: OpeningHoursRow[]): OpeningHoursRow[] => {
	return sortBy(rows, ({ dayType }) => {
		return dayType === 'custom' ? -Infinity : -1 * getDayTypeSpecificity(dayType);
	});
};

export const isRecurringRow = (row: OpeningHoursRow): row is OpeningHoursRecurringRow => {
	return row.dayType !== 'custom';
};

export const isExceptionRow = (row: OpeningHoursRow): row is OpeningHoursExceptionRow => {
	return row.dayType === 'custom';
};

export const areExceptionPeriodsOverlapping = (
	period1: OpeningHoursConfigWithValidity,
	period2: OpeningHoursConfigWithValidity,
) => {
	return (
		moment(period1.validity.from).isSameOrBefore(period2.validity.to) &&
		moment(period1.validity.to).isSameOrAfter(period2.validity.from)
	);
};

export const sanitizeOpeningHours = (openingHours: OpeningHours): OpeningHours => {
	const sanitizeOpeningHoursRows = (
		rows: OpeningHoursRow[],
		range?: { from: YYYY_MM_DD; to: YYYY_MM_DD },
	): OpeningHoursRow[] => {
		const recurringRows = chain(rows)
			.filter(isRecurringRow)
			.sortBy((row) => {
				return getDayTypeSpecificity(row.dayType);
			})
			.uniqBy((row) => row.dayType)
			.filter(
				(row) => row.times.isClosed || isTimeBeforeOrSame(row.times.openTime, row.times.closeTime),
			)
			.value();

		const exceptionRows = chain(rows)
			.filter(isExceptionRow)
			.sortBy((row) => {
				return row.date;
			})
			.uniqBy((row) => row.date)
			.filter(
				(row) => row.times.isClosed || isTimeBeforeOrSame(row.times.openTime, row.times.closeTime),
			)
			.filter((row) => {
				return !range || moment(row.date).isBetween(range.from, range.to, 'day', '[]');
			})
			.value();

		return [...recurringRows, ...exceptionRows];
	};

	const sanitizeExceptionPeriods = (
		periods: OpeningHoursConfigWithValidity[] | undefined,
	): OpeningHoursConfigWithValidity[] => {
		if (!periods) return [];
		return chain(periods)
			.sortBy((period) => {
				return period.validity.to;
			})
			.filter((period) => {
				return period.validity.to >= period.validity.from;
			})
			.uniqBy((period) => period.validity.from)
			.map((period) => {
				return {
					...period,
					rows: sanitizeOpeningHoursRows(period.rows, period.validity),
				};
			})
			.value();
	};

	return {
		...openingHours,
		base: {
			...openingHours.base,
			rows: sanitizeOpeningHoursRows(openingHours.base.rows),
		},
		exceptions: sanitizeExceptionPeriods(openingHours.exceptions),
	};
};
