import { Api } from 'common/db/api/paths';

export const getFreeShopUrl = async (name: string, args: { api: Api }) => {
	const { api } = args;
	const findFreeUrl = async (urlName: string, suffix?: number): Promise<string> => {
		const shopUrlName = (urlName + (suffix ?? '')).trim();
		const shopUrlData = await api.shopUrls.doc(shopUrlName).get();
		if (!shopUrlData) {
			return shopUrlName;
		} else {
			shopSuffix++;
			return findFreeUrl(urlName, shopSuffix);
		}
	};
	let shopSuffix = 0;
	const onlyLettersNumbersHyphens = /[^A-Za-z0-9-]+/g;
	const onlySingleHyphen = /[--]+/g;
	const baseShopUrl = name
		.trim()
		.replace(onlyLettersNumbersHyphens, '')
		.replace(onlySingleHyphen, '-')
		.replace(/(^-+|-+$)/g, '')
		.toLocaleLowerCase();
	return findFreeUrl(baseShopUrl);
};
