import {
	OnboardingStep,
	OnboardingStepDefinition,
} from 'common/services/analytics/tractionAnalytics';

import { ADDITIONAL_FEATURES_DEFINITION, ADDITIONAL_FEATURES_STEP } from './additionalFeatures';
import {
	BUSINESS_AND_CONTACT_INFORMATION_DEFINITION,
	BUSINESS_AND_CONTACT_INFORMATION_STEP,
} from './businessAndContactInfo';
import {
	CUSTOMIZE_ONLINE_STORE_DEFINITION,
	CUSTOMIZE_ONLINE_STORE_STEP,
} from './customizeOnlineStore';
import { GET_TO_KNOW_RENTLE_DEFINITION, GET_TO_KNOW_RENTLE_STEP } from './getToKnowRentle';
import { HIGHLIGHTED_FEATURES_DEFINITION, HIGHLIGHTED_FEATURES_STEP } from './highlightedFeatures';
import { INVENTORY_DEFINITION, INVENTORY_STEP } from './inventory';
import { LINK_STORE_DEFINITION, LINK_STORE_STEP } from './linkStore';
import { LOCALIZATION_DEFINITION, LOCALIZATION_STEP } from './localization';
import { PAYMENTS_DEFINITION, PAYMENTS_STEP } from './payments';
import { PRODUCT_CATALOG_DEFINITION, PRODUCT_CATALOG_STEP } from './productCatalog';
import { USERS_DEFINITION, USERS_STEP } from './users';
import { WAIVER_DEFINITION, WAIVER_STEP } from './waiver';

export const onboardingStepDefinitions: Record<OnboardingStep, OnboardingStepDefinition> = {
	...GET_TO_KNOW_RENTLE_DEFINITION,
	...CUSTOMIZE_ONLINE_STORE_DEFINITION,
	...PRODUCT_CATALOG_DEFINITION,
	...HIGHLIGHTED_FEATURES_DEFINITION,
	...LINK_STORE_DEFINITION,
	...PAYMENTS_DEFINITION,
	...ADDITIONAL_FEATURES_DEFINITION,
	...LOCALIZATION_DEFINITION,
	...INVENTORY_DEFINITION,
	...BUSINESS_AND_CONTACT_INFORMATION_DEFINITION,
	...USERS_DEFINITION,
	...WAIVER_DEFINITION,
};

export const ONBOARDING_STEPS = {
	GET_TO_KNOW_RENTLE: GET_TO_KNOW_RENTLE_STEP,
	CUSTOMIZE_ONLINE_STORE: CUSTOMIZE_ONLINE_STORE_STEP,
	PRODUCT_CATALOG: PRODUCT_CATALOG_STEP,
	HIGHLIGHTED_FEATURES: HIGHLIGHTED_FEATURES_STEP,
	LINK_STORE: LINK_STORE_STEP,
	PAYMENTS: PAYMENTS_STEP,
	ADDITIONAL_FEATURES: ADDITIONAL_FEATURES_STEP,
	LOCALIZATION: LOCALIZATION_STEP,
	INVENTORY: INVENTORY_STEP,
	USERS: USERS_STEP,
	BUSINESS_AND_CONTACT_INFORMATION: BUSINESS_AND_CONTACT_INFORMATION_STEP,
	WAIVER: WAIVER_STEP,
};
