import { updateToNewTransactionType } from 'common/api/dataUpdates';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { TransactionApi } from 'common/types';

import { buildQuery, createBasicDbOperations } from '../utils';

export const getTransactionQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.transactions;
	const dataUpdater = updateToNewTransactionType;
	const query = buildQuery<TransactionApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<TransactionApi>(collectionPath, dbRefs, dataUpdater),
		getTransactionsByOrderId: (orderId: string) => query.where('orderId', '==', orderId),
	};
};
