import { useEffect, useRef } from 'react';

import { api } from 'common/frontend/api';

const useClientUpdateChecker = (channel: 'admin', updateCallback: () => void) => {
	const callbackRef = useRef(updateCallback);

	useEffect(() => {
		callbackRef.current = updateCallback;
	}, [updateCallback]);

	useEffect(() => {
		let lastVersion: undefined | number = undefined;
		const unsubscribe = api()
			.clientUpdates.doc(channel)
			.listen((doc) => {
				const newVersion = doc?.version;
				if (!newVersion || isNaN(newVersion)) return;
				const versionInitialized = !!lastVersion != null;
				const existingVersionIncreased = lastVersion != null && newVersion > lastVersion;
				if (versionInitialized && existingVersionIncreased) {
					callbackRef.current();
				}
				lastVersion = newVersion;
			});
		return () => {
			unsubscribe();
		};
	}, [channel]);

	return null;
};

export default useClientUpdateChecker;
