import countriesAndTimezones, { Timezone } from 'countries-and-timezones';
import { TFunction } from 'i18next';
import moment, { isMoment } from 'moment-timezone';

import { TimeUnit, TimeUnits } from 'common/constants/timeUnits';
import {
	CountryCode,
	getDateFormatByCountryCode,
	getStartDayByCountryCode,
	getTimeFormatByCountryCode,
} from 'common/modules/atoms/countries';
import { CancellationObject, DateFormatObject, FirstDayOfWeek, ISOWeekdays } from 'common/types';
import { notUndefined } from 'common/utils/common';

export const TIME_FORMATS = ['HH:mm', 'hh:mm A'] as const;

export type TimeFormat = typeof TIME_FORMATS[number];

export const DATE_FORMATS = ['DD.MM.YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD'] as const;

export type DateFormat = typeof DATE_FORMATS[number];

export const START_DAYS = ['monday', 'saturday', 'sunday'] as const;

export type StartDay = typeof START_DAYS[number];

export const getStartDayAsISOWeekday = (startDay: StartDay): FirstDayOfWeek => {
	switch (startDay) {
		case 'monday':
			return ISOWeekdays.Monday;
		case 'saturday':
			return ISOWeekdays.Saturday;
		case 'sunday':
			return ISOWeekdays.Sunday;
		default:
			return ISOWeekdays.Monday;
	}
};

export const getCountryTimeZones = (country: string) => {
	return countriesAndTimezones.getTimezonesForCountry(country);
};

export const getCountryFromTimezone = (timezone: string) => {
	return countriesAndTimezones.getCountryForTimezone(timezone);
};

export const getAllTimeZones = () => {
	const getAllTimeZones = countriesAndTimezones.getAllTimezones();
	const sorted = Object.keys(getAllTimeZones).sort((a, b) => {
		const aNameArray = a.split('/');
		const bNameArray = b.split('/');
		return aNameArray[aNameArray.length - 1].localeCompare(bNameArray[bNameArray.length - 1]);
	});
	const sortedTimeZones = sorted.map((name) => getAllTimeZones[name]);
	return sortedTimeZones;
};

export const getFormattedTimeZone = (timeZone: Timezone) => {
	const nameArray = timeZone.name.split('/');
	const nameOfTimeZone = nameArray[nameArray.length - 1].replace('_', ' ');
	return nameOfTimeZone + ' - (' + moment.tz(timeZone.name).format('z Z') + ')';
};

export const getDefaultDateObject = (): DateFormatObject => ({
	dateFormat: 'DD.MM.YYYY',
	timeFormat: 'HH:mm',
	startDay: 'monday',
});

export const getDefaultTimezone = (): string => moment.tz.guess();

const dateFormats = [
	'HH',
	'HH:mm',
	'ddd HH:mm',
	'dddd HH:mm',
	'D.M., HH:mm',
	'DD.MM., HH:mm',
	'DD.MM.',
	'ddd D.M',
	'ddd DD.MM.',
	'ddd D.M., HH:mm',
	'ddd DD.MM. HH:mm',
	'dddd DD.MM. HH:mm',
	'dddd DD.MM. HH:mm YYYY',
	'dddd DD.MM.',
	'DD.MM.YYYY',
	'DD/MM/YYYY',
	'ddd DD.MM.YYYY',
	'dddd DD.MM.YYYY',
	'ddd D.M.YYYY',
	'ddd D.M.YYYY HH:mm',
	'ddd. MMM D',
	'ddd. MMM D, HH:mm',
	'DD.MM.YYYY, HH:mm',
	'MMMM Do YYYY',
	'MMMM Do YYYY, HH:mm',
	'MMMM Do YYYY, HH:mm:ss',
	'MMM DD',
	'MMM DD, YYYY',
	'MMM DD, HH:mm',
	'MMM DD, YYYY HH:mm',
] as const;

export type MomentDateFormat = typeof dateFormats[number];

const dateFormatMap = {
	'MM/DD/YYYY': {
		'D.M.': 'M/D',
		'D.M.,': 'M/D,',
		'DD.MM.': 'MM/DD',
		'DD.MM.,': 'MM/DD,',
		'D.M.YYYY': 'M/D/YYYY',
		'DD.MM.YYYY': 'MM/DD/YYYY',
		'DD.MM.YYYY,': 'MM/DD/YYYY,',
		'DD/MM/YYYY': 'MM/DD/YYYY',
	},
	'YYYY-MM-DD': {
		'D.M.': 'M-D',
		'D.M.,': 'M-D,',
		'DD.MM.': 'MM-DD',
		'DD.MM.,': 'MM-DD,',
		'D.M.YYYY': 'YYYY-M-D',
		'DD.MM.YYYY': 'YYYY-MM-DD',
		'DD.MM.YYYY,': 'YYYY-MM-DD,',
		'DD/MM/YYYY': 'YYYY-MM-DD',
	},
	'hh:mm A': {
		HH: 'hh A',
		'HH:mm': 'hh:mm A',
		'HH:mm:ss': 'hh:mm:ss A',
	},
};

export const localFormat = (
	dateTime: string | moment.Moment | undefined,
	dateFormat: MomentDateFormat,
	shopDateFormat: DateFormatObject,
) => {
	const m = isMoment(dateTime) ? dateTime : moment(dateTime);
	return m.format(localFormatString(dateFormat, shopDateFormat));
};

export const localFormatString = (
	dateFormat: MomentDateFormat,
	shopDateFormat: DateFormatObject,
): string => {
	let returnString = '';
	const splitDateFormat = dateFormat.split(' ');
	splitDateFormat.forEach((string, index) => {
		const localString =
			dateFormatMap[shopDateFormat.dateFormat]?.[string] ??
			dateFormatMap[shopDateFormat.timeFormat]?.[string] ??
			string;
		returnString += localString + (index !== splitDateFormat.length - 1 ? ' ' : '');
	});
	return returnString;
};

export const momentToDateInShopTimezone = (date: moment.Moment) =>
	new Date(moment(date).format('YYYY/MM/DD HH:mm:ss'));

export const getHoursAndMinutesFromHourMinuteString = (hoursAndMinutes: string) => {
	const [hours, minutes] = hoursAndMinutes.split(':').map((val) => Number(val));
	return [hours, minutes];
};

export const convertSeconds = (seconds: number, to: 'hours' | 'days') => {
	const timeInSeconds: Record<typeof to, number> = {
		hours: 1 / (60 * 60),
		days: 1 / (60 * 60 * 24),
	};
	return seconds * timeInSeconds[to];
};

export const formatTime = (time: number, opts: { precision: number; suffix: 'h' | 'd' }) => {
	const timePrecision = parseFloat(time.toFixed(opts.precision));
	return `${timePrecision} ${opts.suffix}`;
};

export const convertHoursToTimeUnit = (hours: number, unit: TimeUnit) => {
	const convert = {
		[TimeUnits.hours]: hours,
		[TimeUnits.days]: hours / 24,
		[TimeUnits.weeks]: hours / 24 / 7,
	};
	return convert[unit] ?? hours;
};

export const convertTimeUnitsToHours = (value: number, unit: TimeUnit) => {
	const convert = {
		[TimeUnits.hours]: value,
		[TimeUnits.days]: value * 24,
		[TimeUnits.weeks]: value * 24 * 7,
	};
	return convert[unit] ?? value;
};

export const convertToTimeUnitHours = (unitHours: number, unitFrom: TimeUnit, unitTo: TimeUnit) => {
	const { hours, days, weeks } = TimeUnits;
	const convert = {
		[`${hours}_${days}`]: unitHours * 24,
		[`${hours}_${weeks}`]: unitHours * 7 * 24,
		[`${days}_${hours}`]: unitHours / 24,
		[`${days}_${weeks}`]: unitHours * 7,
		[`${weeks}_${hours}`]: unitHours / 7 / 24,
		[`${weeks}_${days}`]: unitHours / 7,
	};
	return convert[`${unitFrom}_${unitTo}`] ?? unitHours;
};

type Unit = 'seconds' | 'minutes' | 'hours' | 'days';

export const convertTime = (value: number, from: Unit, to: Unit): number => {
	const convertToHours = (value: number, from: Unit): number => {
		const convert: Record<Unit, number> = {
			seconds: value / 60 / 60,
			minutes: value / 60,
			hours: value,
			days: value * 24,
		};
		return convert[from];
	};
	const convertFromHours = (value: number, to: Unit): number => {
		const convert: Record<Unit, number> = {
			seconds: value * 60 * 60,
			minutes: value * 60,
			hours: value,
			days: value / 24,
		};
		return convert[to];
	};
	const hours = convertToHours(value, from);
	return convertFromHours(hours, to);
};

export const cancellationHoursAsString = (cancellation: CancellationObject, t: TFunction) => {
	const { beforeHours: hours, displayAs: unit } = cancellation;
	const value = convertHoursToTimeUnit(hours || 0, unit ?? 'hours');

	if (unit === TimeUnits.days) {
		return hours === 24
			? `${value} ${t('common:times.day')}`
			: `${value} ${t('common:times.days')}`;
	} else if (unit === TimeUnits.weeks) {
		return hours === 168
			? `${value} ${t('common:times.week')}`
			: `${value} ${t('common:times.weeks')}`;
	} else {
		return hours === 1
			? `${value} ${t('common:times.hour')}`
			: `${value} ${t('common:times.hours')}`;
	}
};

export const yearMonthDayAsMoment = ({
	year,
	month,
	day,
}: {
	month: number;
	year: number;
	day: number;
}) =>
	moment()
		.year(year)
		.month(month - 1) //Moment indexes months from 0-11
		.date(day)
		.startOf('day');

export const getYearMonthDayFromMoment = (date: moment.Moment) => ({
	year: date.year(),
	month: date.month() + 1, //Moment indexes months from 0-11
	day: date.date(),
});

export const getDateFormatFromCountry = (countryCode: CountryCode): DateFormatObject => {
	return {
		dateFormat: getDateFormatByCountryCode(countryCode),
		timeFormat: getTimeFormatByCountryCode(countryCode),
		startDay: getStartDayByCountryCode(countryCode),
	};
};

const weekdayMap = {
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
	SUNDAY: 7,
};

export const asIsoWeekdays = (weekdays: string[]): number[] =>
	weekdays.map((w) => weekdayMap[w.toUpperCase()]).filter(notUndefined);

export const isValidWeekday = (validWeekdays: string[] | undefined, isoWeekday: number) => {
	return !validWeekdays ? true : asIsoWeekdays(validWeekdays).includes(isoWeekday);
};

export const isIsoDate = (date: string) => {
	/**
	 * Reference: https://github.com/honeinc/is-iso-date/blob/master/index.js
	 */
	var isoDateRegExp = new RegExp(
		/^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))$|^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$|^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))$/,
	);
	return isoDateRegExp.test(date);
};

export const isHHMMString = (time: string) => {
	/**
	 * Reference: https://stackoverflow.com/a/51177696
	 */
	var isHHMMFormatWithLeadingZero = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
	return isHHMMFormatWithLeadingZero.test(time);
};

/**
 * Rough transformation from years to minutes (disregards leap years)
 */

export const yearsToMinutes = (years: number) => {
	const MINS_PER_YEAR = 24 * 365 * 60;
	return years * MINS_PER_YEAR;
};

/**
 * Converts a date string to a number. The function first formats the date string using `moment` to ensure it's in the format 'YYYY-MM-DD'.
 * If the date string is not provided or does not meet the expected format after formatting, it returns undefined.
 *
 * @param {string} [date] - The input date string. It doesn't strictly need to be in 'YYYY-MM-DD' format as `moment` will attempt to format it.
 * @returns {number|undefined} - The date as a number (e.g., '2023-09-04' becomes 20230904) or undefined if the input or formatted result is invalid.
 */

export const getDateStringAsNumber = (date?: string): number | undefined => {
	if (!date) return undefined;
	const formattedDate = moment(date).format('YYYY-MM-DD');
	const dateArray = formattedDate.split('-');
	if (dateArray.length < 3) return undefined;
	const year = dateArray[0];
	const month = dateArray[1];
	const day = dateArray[2];
	if (year.length !== 4 || month.length !== 2 || day.length !== 2) return undefined;
	return parseInt(dateArray[0] + dateArray[1] + dateArray[2]);
};
