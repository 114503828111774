import { isEmpty } from 'lodash';

import { getItemCodesFromStock } from 'common/modules/orders/products';

import { ItemSkus } from '../ItemSkus';
import { PackageItemApi, PackageItemDb } from './types';

export const toApi = (data: PackageItemDb): PackageItemApi => {
	return {
		productId: data.productApiId,
		name: data.name,
		returnedDate: data.returnedDate ?? undefined,
		itemCodes: getItemCodesFromStock({ stock: data.stock }),
		variantId: data.variant?.id,
		variantValues: data.variant?.values ? Object.values(data.variant.values) : undefined,
		itemSkus: isEmpty(data.stock)
			? undefined
			: Object.values(data.stock).map((value) => ItemSkus.toApi(value)),
		removedFromPackage: data.removedFromParent ?? false,
	};
};
