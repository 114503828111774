import { groupBy } from 'lodash';
import { TFunction } from 'react-i18next';

import { api } from 'common/frontend/api';
import { Callable } from 'common/frontend/callable';
import {
	AllocationType,
	AllocationTypes,
	InventoryStatus,
	SingleInventoryItem,
} from 'common/modules/inventory';
import { GetDINOptions, ProductDinValues, UserDetailNames, UserProperties } from 'common/types';
import { notFalsey } from 'common/utils/common';
import { getDIN } from 'common/utils/dinCalculation';
import { getUserDetailOptions } from 'common/utils/userDetails';

export interface ProductCodeOption {
	label: string;
	value: string;
	status: InventoryStatus;
	allocationTypes: AllocationType[];
	inventoryItemId: string;
}

export const fetchProductCodeOptions = async (args: {
	shopId: string | null | undefined;
	skuId: string | null | undefined;
	locationId: string | null;
}): Promise<{
	options: ProductCodeOption[];
	optionsMap: Map<string, ProductCodeOption>;
	inventoryItemsMap: Map<string, SingleInventoryItem>;
}> => {
	const { shopId, locationId, skuId } = args;
	if (!shopId || !skuId) {
		return {
			options: [],
			optionsMap: new Map(),
			inventoryItemsMap: new Map(),
		};
	}
	const baseQuery = api()
		.inventoryItems.singleItems.where('shopId', '==', shopId)
		.where('skuId', '==', skuId);
	const query = !!locationId ? baseQuery.where('locationId', '==', locationId) : baseQuery;

	const inventoryItems = await query.get();

	return inventoryItems.reduce(
		(result, item) => {
			if (item.status === 'SOLD' || item.status === 'LOST') return result;
			result.inventoryItemsMap.set(item.id, item);

			const identifiers = item.identifiers;

			const allocationTypes = Object.entries(item.allocation)
				.filter(([, v]) => notFalsey(v))
				.map(([k]) => k) as AllocationType[];

			identifiers.forEach((identifier) => {
				const option = {
					label: identifier,
					value: identifier,
					status: item.status,
					allocationTypes,
					inventoryItemId: item.id,
				};
				result.options.push(option);
				result.optionsMap.set(option.value, option);
			});

			return result;
		},
		{
			options: [] as ProductCodeOption[],
			optionsMap: new Map<string, ProductCodeOption>(),
			inventoryItemsMap: new Map<string, SingleInventoryItem>(),
		},
	);
};

export const fetchProductCodesInUse = async (args: {
	skuId: string | null | undefined;
	shopId: string | undefined;
	startDate: string;
	endDate: string;
	excludeOrderId?: string;
}): Promise<Set<string>> => {
	const { skuId, startDate, endDate, shopId, excludeOrderId } = args;
	if (!skuId || !shopId) return new Set();
	const data = await Callable.availability.getProductCodesInUse({
		skuId,
		startDate,
		endDate,
		shopId,
		excludeOrderId,
	});
	return new Set(data.productCodes);
};

export const fetchIsProductCodeInUse = async (args: {
	code: string | null | undefined;
	shopId: string | undefined;
	startDate: string;
	endDate: string;
	excludeOrderId?: string;
}): Promise<boolean> => {
	const { code, startDate, endDate, shopId, excludeOrderId } = args;
	if (!code || !shopId) return false;
	return Callable.availability.isProductCodeInUse({
		code,
		startDate,
		endDate,
		shopId,
		excludeOrderId,
	});
};

export const getCalculatedDinValue = (args: {
	soleLength: ProductDinValues['soleLength'];
	dinVariation: ProductDinValues['dinVariation'];
	userProperties: UserProperties;
	t: TFunction;
}): {
	din: number | null;
	error: string | null;
} => {
	const { soleLength, dinVariation, userProperties, t } = args;
	const weight = (userProperties.weight && Number(userProperties.weight.value)) || null;
	const height = (userProperties.height && Number(userProperties.height.value)) || null;
	const experienceLevel =
		(userProperties.experienceLevel && userProperties.experienceLevel.value) || null;
	const age = (userProperties.age && userProperties.age.value) || null;
	if (!weight || !height || !experienceLevel || !soleLength || !age) {
		return {
			din: null,
			error: `${t('din.missing')}: ${
				(!weight && t('common:form.weight')) ||
				(!height && t('common:form.height')) ||
				(!experienceLevel && t('common:form.experienceLevel')) ||
				(!soleLength && t('din.soleLength', 'Sole length')) ||
				(!age && t('common:form.age')) ||
				''
			}`,
		};
	}
	const experienceLevelValues = getUserDetailOptions(UserDetailNames.experienceLevel)?.values;
	const experienceNumber = (experienceLevelValues ?? []).indexOf(experienceLevel) + 1;
	if (experienceNumber <= 0) {
		return {
			din: null,
			error: `${t('din.missing')}: ${t('common:form.experienceLevel')}`,
		};
	}
	const [minAgeString, maxAgeString] = age.split('-');
	const minAge = Number(minAgeString);
	const maxAge = Number(maxAgeString);
	if ((!minAge && minAge !== 0) || (!maxAge && maxAge !== 0)) {
		return {
			din: null,
			error: `${t('din.missing')}: ${t('common:form.age')}`,
		};
	}
	const dinParams: GetDINOptions = {
		weight,
		height,
		age: maxAge || maxAge === 0 ? maxAge : minAge,
		bootSoleLength: soleLength,
		skierType: experienceNumber,
		dinVariation: dinVariation ?? null,
	};
	const dinValue = getDIN(dinParams);
	if (dinValue) {
		return {
			din: Number(dinValue),
			error: null,
		};
	}
	return {
		din: Number(dinValue) || null,
		error: dinValue ? null : t('din.calculationError', 'Not calculated, check measurements'),
	};
};

export const sortOptions = (deps: {
	selectedIdentifier: string | undefined;
	allocationType: AllocationType;
	productCodesInUse: Set<string>;
}) => (optionsToSort: ProductCodeOption[]) => {
	const { selectedIdentifier, allocationType, productCodesInUse } = deps;

	const sortFn = (a: ProductCodeOption, b: ProductCodeOption): number => {
		const sortBySelected =
			Number(b.value === selectedIdentifier) - Number(a.value === selectedIdentifier);
		if (sortBySelected !== 0) {
			return sortBySelected;
		}
		const inventoryStatusOrder = ['IN_USE', 'OUT_OF_USE', 'LOST', 'SOLD'];
		const sortByStatus =
			inventoryStatusOrder.indexOf(a.status) - inventoryStatusOrder.indexOf(b.status);
		if (sortByStatus !== 0) {
			return sortByStatus;
		}
		const aIsOccupied = productCodesInUse.has(a.value);
		const bIsOccupied = productCodesInUse.has(b.value);
		const sortByInUse = Number(aIsOccupied) - Number(bIsOccupied);
		if (sortByInUse !== 0) {
			return sortByInUse;
		}
		const sortByName = a.label.localeCompare(b.label);
		return sortByName;
	};

	const optionsByAllocationType = groupBy(optionsToSort, (o) => o.allocationTypes);
	const salesOptions = (optionsByAllocationType?.[AllocationTypes.sales] ?? []).sort(sortFn);
	const rentalOptions = (optionsByAllocationType?.[AllocationTypes.rental] ?? []).sort(sortFn);

	switch (allocationType) {
		case AllocationTypes.sales:
			return [...salesOptions, ...rentalOptions];
		case AllocationTypes.rental:
			return [...rentalOptions, ...salesOptions];
	}
};
