import moment from 'moment-timezone';

import { Api } from 'common/db/api/paths';
import { AvailabilityByStartDay } from 'common/modules/availabilities/startTimeCounts';

import { AvailabilityDataSources } from './types';

export interface FetchStartTimesProps {
	productId: string;
	startDate: string;
	endDate: string;
	startLocationId: string;
	ignoredOrderProductIds?: string[];
	dataSources?: AvailabilityDataSources;
	api: Api;
}

interface GetStartTimesFromDataProps extends FetchStartTimesProps {
	availabilityByStartDay: AvailabilityByStartDay[];
}

export const fetchStartTimes = async (
	props: FetchStartTimesProps,
): Promise<AvailabilityByStartDay[]> => {
	const {
		productId,
		startDate,
		endDate,
		startLocationId,
		ignoredOrderProductIds,
		dataSources,
		api,
	} = props;
	if (ignoredOrderProductIds?.includes(productId)) return [];

	if (!!dataSources) {
		return getStartTimesFromData({
			...props,
			availabilityByStartDay: dataSources.availabilityByStartDay,
		});
	}
	const availabilityStartTimes = await api.availabilityStartTimes.get
		.where('locationId', '==', startLocationId)
		.where('productId', '==', productId)
		.where('startDay', '>=', moment(startDate).format('YYYY-MM-DD'))
		.where('startDay', '<=', moment(endDate).format('YYYY-MM-DD'))
		.get();
	return availabilityStartTimes;
};

const getStartTimesFromData = (args: GetStartTimesFromDataProps): AvailabilityByStartDay[] => {
	const {
		productId: queryProductId,
		startDate,
		endDate,
		startLocationId,
		availabilityByStartDay,
	} = args;
	return availabilityByStartDay.filter(({ locationId, productId, startDay }) => {
		const queryStartDay = moment(startDate).format('YYYY-MM-DD');
		const queryEndDay = moment(endDate).format('YYYY-MM-DD');
		return (
			locationId === startLocationId &&
			productId === queryProductId &&
			startDay >= queryStartDay &&
			startDay <= queryEndDay
		);
	});
};
