import * as React from 'react';

import Typography from '@mui/material/Typography';

import LinkButton from 'common/components/LinkButton';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes } from 'routing';

const NotFound = () => {
	const { t } = useTranslation();
	return (
		<div style={{ textAlign: 'center', padding: 80 }}>
			<Typography variant="h3" gutterBottom>
				{'404'}
			</Typography>
			<Typography variant="h5">
				{t('common:errors.notFound', 'Sorry, the page cannot be found')}
			</Typography>
			<LinkButton variant="contained" color="primary" to={Routes.ROOT} style={{ marginTop: 32 }}>
				{t('common:actions.goBack')}
			</LinkButton>
		</div>
	);
};

export default NotFound;
