import { useEffect } from 'react';

import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { InstoreRedirect, PayLinkRedirect } from 'routing/redirection';
import AuthUserActions from 'views/AuthUserActions';
import Home from 'views/Home';
import Login from 'views/Login';
import {
	getIsUnregisteredUserLogin,
	removeIsUnregisteredUserLogin,
} from 'views/Login/unregisteredUserLogin';
import PasswordReset from 'views/PasswordReset';
import Register from 'views/Register';

import { signInServer } from 'common/api/authentication';
import ScrollToTop from 'common/components/routing/ScrollToTop';
import { api } from 'common/frontend/api';
import { User, onAuthStateChanged, signOut } from 'common/frontend/firebase/auth';
import WebSessionCounter from 'common/frontend/helpers/SessionCounter';
import { toZeroBasedWeekday } from 'common/modules/atoms/dates';
import * as Analytics from 'common/services/analytics';
import * as IntercomService from 'common/services/analytics/intercom';
import { sleep } from 'common/utils/async';
import { StorageKeys, Storages, getFromStorage, removeFromStorage } from 'common/utils/frontUtils';
import * as ViewActions from 'actions/ViewActions';
import useGrowthbook from 'hooks/useGrowthbook';
import { usePosthog } from 'hooks/usePosthog';
import useShopFormat from 'hooks/useShopFormat';
import useSleekplan from 'hooks/useSleekplan';
import { useUserflow } from 'hooks/useUserflow';
import * as ShopSelectors from 'selectors/ShopSelectors';
import history from 'services/history';
import { Routes } from 'routing';

const AppRouter = () => {
	useSleekplan();
	useGrowthbook();
	const { resetPosthog } = usePosthog();
	const dispatch = useDispatch();
	const { identifyUser, resetUser } = useUserflow();

	const { firstDayOfWeek } = useShopFormat();
	const timezone = useSelector(ShopSelectors.getShopTimeZone);

	/**
	 * To ensure that the Login screen has time to add the global flag,
	 * we add a minimal delay here before checking the flag. Otherwise,
	 * it might be that onAuthStateChanged is called before Login screen
	 * has time to add the flag.
	 */
	const isUnregisteredUserLogin = async (authUser: User | null) => {
		if (!authUser) return false;
		await sleep(1);
		return getIsUnregisteredUserLogin();
	};

	/**
	 * For unregistered logins, we want to sign them out and
	 * remove the user, since they should register first
	 */
	const handleUnregisteredLogin = (authUser: User | null) => {
		if (!authUser) return;
		removeIsUnregisteredUserLogin();
		signOut();
		authUser.delete();
	};

	useEffect(() => {
		const reservationId = getFromStorage(Storages.SESSION, StorageKeys.RESERVATION_ID);
		if (reservationId) {
			api().reservations.doc(reservationId).delete();
			removeFromStorage(Storages.SESSION, StorageKeys.RESERVATION_ID);
		}
		// Check if user has logged in or not
		const unsubscribe = onAuthStateChanged(async (authUser) => {
			/**
			 * Social logins do not have sign up action separately, but only sign-in. It might happen that
			 * user logs in with social login successfully, but doesn't actually have a registered account in Rentle yet.
			 * If that happens, we add a global flag, and show an error message in Login screen. onAuthStateChanged is still
			 * called directly after user logs in, so we need to handle the login, cancel onAuthStateChanged actions,
			 * and sign the user out.
			 */
			const isUnregisteredLoginAttempt = await isUnregisteredUserLogin(authUser);
			if (isUnregisteredLoginAttempt) {
				handleUnregisteredLogin(authUser);
				return;
			}
			dispatch(
				ViewActions.setAuthState({
					isAuthenticated: !!authUser,
					loading: false,
				}),
			);
			if (authUser) {
				signInServer(authUser);
				const { email } = authUser;
				IntercomService.boot({ email: email ?? undefined });
				Analytics.setGaUserProperties({ user_id: authUser.uid }, { sendToAllTags: true });
				WebSessionCounter.addSessionListener(() => {
					Analytics.setHubspotProperties(
						{
							rentle_lastseen_timestamp: new Date().getTime(),
						},
						email ?? undefined,
					);
					Analytics.addSession(email ?? undefined);
				});
				identifyUser(authUser.uid);
			} else {
				IntercomService.logout();
				WebSessionCounter.removeSessionListener();
				resetPosthog();
			}
		});
		if (timezone) {
			moment.tz.setDefault(timezone);
		}
		if (firstDayOfWeek) {
			moment.locale(moment.locale(), {
				week: {
					/**
					 * Here moment.js expects the weekday to be passed in as 0-6
					 *
					 * https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
					 *
					 */
					dow: toZeroBasedWeekday(firstDayOfWeek),
				},
			});
		}
		return () => {
			unsubscribe();
		};
	}, [dispatch, identifyUser, resetPosthog, resetUser, timezone, firstDayOfWeek]);

	return (
		<Router history={history}>
			<ScrollToTop />
			<Switch>
				<Route path={Routes.LOGIN} component={Login} />
				<Route path={Routes.REGISTER} component={Register} />
				<Route path={Routes.PASSWORD_RESET} component={PasswordReset} />
				<Route path="/authAction" component={AuthUserActions} />
				<Route path={Routes.PAY} render={PayLinkRedirect} />
				<Route path={Routes.STORE_ID_LOCATION} render={InstoreRedirect} />
				<Route path={Routes.STORE_ID} render={InstoreRedirect} />
				<Route path={Routes.ROOT} component={Home} />
			</Switch>
		</Router>
	);
};

export default AppRouter;
