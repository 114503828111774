import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { DeliveryLocation } from 'common/types';

import { buildQuery, createBasicCollectionOperations, createBasicDbOperations } from '../utils';

const dataUpdaterFn = undefined;

export const getDeliveryLocationQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.deliveryLocations;
	const query = buildQuery<DeliveryLocation>(collectionPath, dbRefs, dataUpdaterFn);

	return {
		...createBasicDbOperations<DeliveryLocation>(collectionPath, dbRefs, dataUpdaterFn),
		...createBasicCollectionOperations<DeliveryLocation>(collectionPath, dbRefs, dataUpdaterFn),
		byStoreId: (storeId: string) => query.where('storeId', '==', storeId),
		byMerchantId: (merchantId: string) => query.where('merchantId', '==', merchantId),
		byIds: (ids: string[]) => query.where('id', 'in', ids),
	};
};
