import React, { useMemo } from 'react';

import { Box, Button, Container, Link, LinkProps, Theme, Typography } from '@mui/material';
import { useHasFeature, useOnce } from 'hooks';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import VideoIcon from 'common/assets/inventory-empty@2x.png';
import SupportIcon from 'common/assets/supportIcon.png';
import UpgradeIcon from 'common/assets/upgradePlanIcon.png';
import { auth } from 'common/frontend/firebase/auth';
import { isFreemiumPlan, isLegacyPlan } from 'common/modules/plans';
import { getOnlineStoreUrl } from 'common/modules/urls';
import * as IntercomService from 'common/services/analytics/intercom';
import { notUndefined } from 'common/utils/common';
import { getClientEnv } from 'common/utils/frontUtils';
import * as ViewActions from 'actions/ViewActions';
import ExternalLink from 'components/ExternalLink';
import { useAnalytics } from 'hooks/useAnalytics';
import { useFeatureEnabled } from 'hooks/useGrowthbook';
import useOnboardingAction from 'hooks/useOnboardingAction';
import { usePosthog } from 'hooks/usePosthog';
import * as RentalsSelectors from 'selectors/RentalsSelectors';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import history from 'services/history';
import { useTranslation } from 'services/localization/useTranslation';
import { RELEASE_DATES, getOnboardingListVersion, getOnboardingSteps } from 'services/onboarding';
import { Routes } from 'routing';

import HomeCTA from './components/HomeCTA';
import OnboardingSection from './components/OnboardingList';
import TodaySection from './components/TodaySection';

const Home = () => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const { hasFeature } = useHasFeature();
	const dispatch = useDispatch();

	const plan = useSelector(ShopSelectors.shopPlan);
	const bookingsToday = useSelector(RentalsSelectors.upcomingRentalsToday);
	const amountOfBookingsToday = bookingsToday.length;
	const shopAnalytics = useSelector(ShopSelectors.activeShopAnalytics);
	const shopInfo = useSelector(ShopSelectors.activeShopInfo);
	const activeLocationId = useSelector(UserSelectors.userActiveLocationId)!;
	const shopUrls = useSelector(ShopSelectors.activeShopUrls);
	const isMultiLocationStore = Object.keys(shopUrls?.locationUrls ?? {}).length;
	const shopCountry = useSelector(ShopSelectors.activeShopCountry);
	const shopPlan = useSelector(ShopSelectors.shopPlan);

	const [, setIntroVideoDone] = useOnboardingAction('INTRO_VIDEO');
	const { logAnalyticsEvent } = useAnalytics();
	const { getFeatureFlagKey } = usePosthog();

	const showProductTours = useFeatureEnabled('show-product-tours');

	const hasRecentlySignedIn =
		!!auth.currentUser?.metadata.lastSignInTime &&
		moment().diff(moment(auth.currentUser?.metadata.lastSignInTime), 'minutes') < 1;

	const isAdmin = useSelector(UserSelectors.isAdmin);

	const shouldShowPlanUpgradeBanner = hasRecentlySignedIn && isAdmin && plan && isLegacyPlan(plan);

	useOnce(() => {
		if (shouldShowPlanUpgradeBanner) {
			dispatch(ViewActions.toggleSwitchToNewPlansModal(true));
		}
	});

	const hasUserflowChecklist =
		getFeatureFlagKey('use-userflow-onboarding-checklist') === 'use-userflow';

	const onboardingListVersion = useMemo(
		() =>
			getOnboardingListVersion({
				shopAnalytics,
				createdAt: shopInfo?.createdAt,
				userflowChecklist: hasUserflowChecklist,
			}),
		[hasUserflowChecklist, shopAnalytics, shopInfo?.createdAt],
	);

	const onboardingSteps = useMemo(() => {
		const onlineStoreUrl = getOnlineStoreUrl({
			env: getClientEnv(),
			locationId: isMultiLocationStore ? activeLocationId : null,
			shopUrlDoc: shopUrls,
		});
		return getOnboardingSteps({
			shopAnalytics,
			onlineStoreUrl,
			t,
			showProductTours,
			country: shopCountry,
			plan: shopPlan,
			onboardingListVersion,
		});
	}, [
		isMultiLocationStore,
		activeLocationId,
		shopUrls,
		shopAnalytics,
		t,
		showProductTours,
		shopCountry,
		shopPlan,
		onboardingListVersion,
	]);

	const allOnboardingStepsCompleted = onboardingSteps
		.flatMap((item) => item.items)
		.every((item) => item.done);

	const hideOnboardingList =
		hasFeature('HIDE_ONBOARDING_LIST', { scope: 'shop' }) ||
		(shopAnalytics && shopAnalytics.showOnboarding === false);

	const showTodaySection =
		hideOnboardingList || allOnboardingStepsCompleted || hasUserflowChecklist;
	const showVideoSection = moment(shopInfo?.createdAt).isSameOrAfter(RELEASE_DATES.V2);

	const handleTalkToUsClick = () => {
		IntercomService.open();
		logAnalyticsEvent({ name: 'chat_with_us_press', params: { from: history.location.pathname } });
	};

	const _ExternalLink = (props: LinkProps) => (
		<Link {...props} target="_blank" rel="noopener noreferrer" />
	);

	const ChangePlanLink = (props: RRLinkProps) => (
		<RRLink to={props.href!}>
			<Button variant="outlined">{t('homeView.upgradeBannerFirstCTA', 'Change plan')}</Button>
		</RRLink>
	);

	return (
		<>
			<Container maxWidth="md" className={classes.homeViewContainer}>
				{showTodaySection && (
					<TodaySection
						amountOfBookings={amountOfBookingsToday}
						hasUserflowChecklist={hasUserflowChecklist}
					/>
				)}
				{!hideOnboardingList && (
					<OnboardingSection
						onboardingSteps={onboardingSteps}
						hasUserflowChecklist={hasUserflowChecklist}
					/>
				)}
				<Box>
					<Typography variant="h6">{t('homeView.learnMore', 'Learn more')}</Typography>
					{showVideoSection && (
						<HomeCTA
							img={VideoIcon}
							imgAlt={'Introduction video'}
							title={t('homeView.introVideoBanner.title', 'Watch introduction video')}
							description={`${t(
								'homeView.introVideoBanner.description',
								'Learn how to get the most value out of Rentle',
							)}.`}
							ctas={[
								{
									children: t('homeView.introVideoBanner.cta', 'Watch now'),
									variant: 'outlined' as const,
									LinkComponent: _ExternalLink,
									href:
										'https://www.youtube.com/embed/CRkM12TcERc?modestbranding=1&plyasinline=0&rel=0&widget_referrer=admin.rentle.io',
									onClick: () => {
										setIntroVideoDone();
									},
								},
							]}
						/>
					)}
					<HomeCTA
						img={SupportIcon}
						imgAlt={'Support icon'}
						title={t('homeView.helpBannerTitle', 'Got a question?')}
						description={t(
							'homeView.helpBannerText',
							'We are here to guide you in using Rentle so you can find answers to your questions.',
						)}
						ctas={[
							{
								children: t('homeView.helpBannerSecondCTA', 'Help center'),
								variant: 'outlined' as const,
								LinkComponent: _ExternalLink,
								href: 'https://support.rentle.io',
							},
							hasFeature('CONTACT_SUPPORT')
								? {
										onClick: handleTalkToUsClick,
										children: t('homeView.helpBannerFirstCTA', 'Contact support'),
										variant: 'text' as const,
										sx: { minWidth: 'max-content' },
								  }
								: undefined,
						].filter(notUndefined)}
					/>
					{plan && isFreemiumPlan(plan) && (
						<HomeCTA
							img={UpgradeIcon}
							imgAlt={'Upgrade icon'}
							title={t('homeView.upgradeBannerTitle2', 'Grow better with Rentle Discover')}
							description={t(
								'homeView.upgradeBannerText',
								'Get access to more features by upgrading your account plan',
							)}
							externalLink={
								<ExternalLink href={'https://rentle.io/pricing'}>
									{t('homeView.upgradeBannerLink', 'View plans')}
								</ExternalLink>
							}
							ctas={[
								{
									LinkComponent: ChangePlanLink,
									href: Routes.PLANS,
								},
								{
									children: t('homeView.bookADemo', 'Book a demo'),
									variant: 'text',
									LinkComponent: _ExternalLink,
									href: 'https://www.rentle.io/meetings/demo-rentle/30min',
								},
							]}
						/>
					)}
				</Box>
			</Container>
		</>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	homeViewContainer: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(2),
		paddingBottom: theme.spacing(8),
	},
}));

export default Home;
