import { PaymentObject } from 'common/models/_atoms/PaymentObject';
import { withOpenApi } from 'common/utils/openApi';

export const zOrderPaymentApi = withOpenApi(
	PaymentObject.api.pick({
		paid: true,
		refunded: true,
		authorised: true,
		cancelled: true,
		captured: true,
	}),
	{
		example: {
			paid: 100_00,
			refunded: 0,
			authorised: 100_00,
			cancelled: 0,
			captured: 100_00,
		},
	},
);
