import { ByCustomerPersona } from 'common/types';
import { switchUnreachable } from 'common/utils/common';

import {
	CustomerPersona,
	CustomerPersonas,
	MerchantProfile,
	MerchantProfiles,
	RevenueOption,
} from './tractionAnalytics';

export const getFormattedUrlPath = (urlString: string) => {
	const urlStringParts = urlString.split('/');
	return urlStringParts.map((part) => (isDatabaseId(part) ? 'ID' : part)).join('/');
};

/**
 * Naive implementation trying to identify if a string is database ID
 * Checks if
 *  - string length is 20 chars
 *  - at least 2 uppercase characters (>99.9% probability to have this if DB ID)
 */
export const isDatabaseId = (textString: string): boolean => {
	if (textString.length !== 20) {
		return false;
	}
	const minTwoUppercaseLetters = new RegExp('[A-Z].*[A-Z]');
	if (!minTwoUppercaseLetters.test(textString)) {
		return false;
	}
	return true;
};

export const getMerchantProfile = ({
	customerPersona,
	revenue,
}: {
	customerPersona?: {
		value?: CustomerPersona;
		specifications?: ByCustomerPersona<string>;
	};
	revenue?: RevenueOption;
}): MerchantProfile | undefined => {
	switch (revenue) {
		case '0-5k':
			return MerchantProfiles.A_1;
		case '5-50k':
			return MerchantProfiles.A_2;
		case '50-250k':
		case '250k-1M':
		case '1M+':
			return customerPersona?.value === CustomerPersonas.REPLACING &&
				customerPersona?.specifications?.[CustomerPersonas.REPLACING] === 'CORE_BUSINESS'
				? MerchantProfiles.C
				: MerchantProfiles.B;
		case undefined:
			return customerPersona?.value === CustomerPersonas.DISCOVERING
				? MerchantProfiles.A_1
				: undefined;
		default:
			return switchUnreachable(revenue);
	}
};
