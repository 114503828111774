import moment from 'moment-timezone';

import { Api } from 'common/db/api/paths';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { PartialReservation, Reservation } from 'common/types';

import { AvailabilityDataSources } from './types';

export interface FetchReservationsProps {
	skuId: string;
	startDate: string;
	endDate: string;
	startLocationId: string;
	ownReservationId?: string;
	dataSources?: AvailabilityDataSources;
	api: Api;
}

export interface FetchReservationsFromDataProps extends FetchReservationsProps {
	reservations: Reservation[];
}

export const fetchReservations = async (
	props: FetchReservationsProps,
): Promise<PartialReservation[]> => {
	try {
		if (!!props.dataSources) {
			return getReservationsFromData({
				...props,
				reservations: props.dataSources.reservations,
			});
		}
		return getReservations(props);
	} catch (e) {
		errorHandler.report(e);
		return [];
	}
};

const getReservationsFromData = (args: FetchReservationsFromDataProps): PartialReservation[] => {
	const { skuId, startDate, endDate, ownReservationId, startLocationId, reservations } = args;

	return reservations
		.filter((r) => {
			return (
				r.skuIds.includes(skuId) &&
				r.startLocationId === startLocationId &&
				moment(r.created).isAfter(moment().subtract(20, 'minutes')) &&
				r.id !== ownReservationId
			);
		})
		.flatMap((reservation) => reservationToPartialReservations(reservation))
		.filter(isReservationOverlapping(skuId, startDate, endDate));
};

const getReservations = async (props: FetchReservationsProps): Promise<PartialReservation[]> => {
	const { skuId, startDate, endDate, ownReservationId, startLocationId, api } = props;

	try {
		const snapshot = await api.reservations.get
			.whereArray('skuIds', 'array-contains', skuId)
			.where('startLocationId', '==', startLocationId)
			.orderBy('created')
			.startAt(moment().add(-20, 'minutes').toISOString())
			.get();

		return snapshot
			.filter((reservation) => reservation.id !== ownReservationId)
			.flatMap((reservation) => reservationToPartialReservations(reservation))
			.filter(isReservationOverlapping(skuId, startDate, endDate));
	} catch (e) {
		return Promise.reject(
			`Failed fetching reservations for sku ${skuId} due to error: ${JSON.stringify(e, null, 2)}`,
		);
	}
};

const reservationToPartialReservations = (reservation: Reservation): PartialReservation[] =>
	reservation.itemsPerPeriod.flatMap((itemsPerPeriod) =>
		itemsPerPeriod.items.map((i) => ({
			startDate: i.unavailable.from,
			endDate: i.unavailable.until,
			units: i.quantity,
			skuIds: i.skuIds,
		})),
	);

const isReservationOverlapping = (skuId: string, startDate: string, endDate: string) => (
	partialReservation: PartialReservation,
) => {
	if (partialReservation.startDate && partialReservation.endDate) {
		const hasSkuId = partialReservation.skuIds.includes(skuId);
		if (!hasSkuId) return false;

		const reservationStartDate = partialReservation.startDate;
		const reservationEndDate = partialReservation.endDate;
		return doesDateRangeOverlap(startDate, endDate, reservationStartDate, reservationEndDate);
	}
	return false;
};
