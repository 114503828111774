import * as React from 'react';

import rentleLogoWhite from 'common/assets/rentle-logo/rentle-logo-outline-white.svg';
import rentleLogoBlack from 'common/assets/rentle-logo/rentle-logo-solid-black.svg';
import rentleLogoColor from 'common/assets/rentle-logo/rentle-logo-twocolor.svg';
import rentleLogoSymbol from 'common/assets/rentle-logo/rentle-symbol-red-product.svg';

interface Props {
	type: 'black' | 'white' | 'color' | 'symbol';
	width?: number | string;
	height?: number | string;
	grayscale?: boolean;
	invert?: boolean;
	opacity?: number;
}

type RentleLogoType = 'black' | 'white' | 'color' | 'symbol';

const RentleLogo = (props: Props) => {
	const { type } = props;

	const logos: Record<RentleLogoType, string> = {
		black: rentleLogoBlack,
		white: rentleLogoWhite,
		color: rentleLogoColor,
		symbol: rentleLogoSymbol,
	};

	return (
		<img
			style={{
				objectFit: 'contain',
				width: props.width ?? 'auto',
				maxWidth: '100%',
				height: props.height ?? 'auto',
				filter:
					`${props.grayscale ? 'grayscale(1)' : ''} ${props.invert ? 'invert(1)' : ''}` || 'none',
				opacity: props.opacity ?? 1,
			}}
			src={logos[type]}
			alt="Rentle logo"
		/>
	);
};

export default RentleLogo;
