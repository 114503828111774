import { DbRefs } from 'common/db/api/types';
import { OrderInfo, OrderObject } from 'common/types';

import { createApi } from '../utils/createApi';

export const getOrderObject = (dbRefs: DbRefs) => {
	async function get(orderId: string): Promise<null | OrderObject>;
	async function get(orderId: string, args: { orderInfo: OrderInfo }): Promise<OrderObject>;
	async function get(
		orderId: string,
		args?: { orderInfo: OrderInfo },
	): Promise<null | OrderObject> {
		const api = createApi(dbRefs);
		const [orderInfo, shoppers, products, orderDelivery] = await Promise.all([
			args?.orderInfo ?? api.orders.doc(orderId).get(),
			api.orderShoppers.byOrderId(orderId).get(),
			api.orderProducts.byOrderId(orderId).get(),
			api.orderDeliveries.byOrderId(orderId).get(),
		]);
		if (!orderInfo) {
			return null;
		}
		return {
			rentalInfo: orderInfo,
			shoppers: shoppers,
			products: products,
			orderDelivery: orderDelivery[0],
		};
	}
	return get;
};
