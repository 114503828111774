import { Box, Divider } from '@mui/material';

import FlexBox from './FlexBox';

export const TextDivider = ({ text }: { text: string }) => {
	return (
		<FlexBox margin="auto" justifyContent="center">
			<Divider
				sx={{
					width: 50,
					background: (theme) =>
						`linear-gradient(to right, transparent, ${theme.palette.border.table})`,
				}}
			/>
			<Box px={2}>{text}</Box>
			<Divider
				sx={{
					width: 50,
					background: (theme) =>
						`linear-gradient(to left, transparent, ${theme.palette.border.table})`,
				}}
			/>
		</FlexBox>
	);
};
