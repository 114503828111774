import { createAction } from '@reduxjs/toolkit';

export type Notification = {
	id: string;
	message: string | JSX.Element;
	variant: 'success' | 'info' | 'error';
	autoDismissMs?: number;
	disableAutoDismiss?: boolean;
	hideDismiss?: boolean;
};

type UpdateNotification = Omit<Partial<Notification>, 'id'>;
type CreateNotification = Omit<Notification, 'id'> & { id?: string };

export const showNotification = createAction<CreateNotification>('Notifications/SHOW_NOTIFICATION');
export const updateNotification = createAction<{ id: string; updates: UpdateNotification }>(
	'Notifications/UPDATE_NOTIFICATION',
);
export const dismissNotification = createAction<string>('Notifications/DISMISS_NOTIFICATION');
export const dismissAllNotifications = createAction('Notifications/DISMISS_ALL_NOTIFICATIONS');
