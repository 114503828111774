import { getReservationEndTime, isReservationExpired } from 'common/modules/reservations';
import { ById } from 'common/types';

import { StartTimeCountReservation } from '.';

export const getStartTimeDocId = (args: {
	productId: string;
	locationId: string;
	startDay: string;
}) => {
	return `${args.productId}_${args.locationId}_${args.startDay}`;
};

export const getValidReservationItems = (
	reservations: ById<StartTimeCountReservation> | undefined,
): ById<StartTimeCountReservation> => {
	const validReservations = Object.entries(reservations ?? {}).reduce((tot, curr) => {
		const [id, reservation] = curr;
		const reservationTime = reservation.timestamp;
		const reservationEndTime = getReservationEndTime(reservationTime);
		const validReservation = !isReservationExpired(reservationEndTime);
		return validReservation
			? {
					...tot,
					[id]: reservation,
			  }
			: tot;
	}, {} as ById<StartTimeCountReservation>);
	return validReservations;
};
