import { createQueryKeys } from '@lukemorales/query-key-factory';
import { UseQueryOptions, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { Api } from 'common/core-api';
import { CreateWebhookDTO } from 'common/core-api/generated';

import { queryClient } from './client';
import { fnQueryKeys } from './utils';

const webhooks = createQueryKeys('webhooks', {
	list: fnQueryKeys(Api.webhooks.list),
	get: fnQueryKeys(Api.webhooks.get),
});

type QueryOptions = Pick<UseQueryOptions, 'enabled'>;

export const useWebhooksList = (
	apiParams: Parameters<typeof Api.webhooks.list>[0],
	queryOptions?: QueryOptions,
) =>
	useInfiniteQuery(
		webhooks.list(apiParams).queryKey,
		({ pageParam }) => {
			if (pageParam) {
				(apiParams ?? {}).pageToken = pageParam;
			}
			return Api.webhooks.list(apiParams);
		},
		{
			getNextPageParam: (lastPage) => lastPage.nextPageToken,
			...queryOptions,
		},
	);

export const useWebhooksGet = (
	apiParams: Parameters<typeof Api.webhooks.get>[0],
	queryOptions?: QueryOptions,
) =>
	useQuery(webhooks.get(apiParams).queryKey, () => Api.webhooks.get(apiParams), {
		...queryOptions,
	});

export const useWebhooksRemove = () =>
	useMutation(
		async (id: string) => {
			return Api.webhooks.remove(id);
		},
		{
			onSettled: (_, __, variable) => {
				queryClient.invalidateQueries(webhooks.list._def);
				queryClient.invalidateQueries(webhooks.get(variable));
			},
		},
	);

export const useWebhooksCreate = () =>
	useMutation((data: CreateWebhookDTO) => Api.webhooks.create(data), {
		onSettled: () => {
			queryClient.invalidateQueries(webhooks.list._def);
		},
	});

export const useWebhooksTest = () =>
	useMutation((apiParams: Parameters<typeof Api.webhooks.test>[0]) => Api.webhooks.test(apiParams));
