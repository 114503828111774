import { Order } from 'common/models/Order';
import { Product } from 'common/models/Product';
import { removeInternalApiFieldsFromData } from 'common/modules/api';
import { switchUnreachable } from 'common/utils/common';
import { newFirestoreId } from 'common/utils/dataMigrations';

import { WebhookPayload } from '../WebhookPayload/types';
import { WebhookEvents_LEGACY } from './db';
import {
	LegacyWebhookEvent,
	NonLegacyWebhookEventApi,
	WebhookEventDb,
	WebhookEventResource,
} from './types';

const getMockDataForWebhookEvent = (
	resource: WebhookEventResource | undefined,
): WebhookPayload['data'] | {} => {
	const resourceMapper: Record<WebhookEventResource, WebhookPayload['data'] | {}> = {
		order: Order.example ?? {},
		product: Product.example ?? {},
	};
	const eventData = !resource ? {} : resourceMapper[resource] ?? {};
	return removeInternalApiFieldsFromData(eventData);
};

export const getMockPayloadForWebhookEvent = (
	event: NonLegacyWebhookEventApi,
): WebhookPayload | (Omit<WebhookPayload, 'data'> & { data: {} }) => {
	const resource = getWebhookResourceFromEvent(event);
	const basePayload: Omit<WebhookPayload, 'data'> = {
		merchantId: newFirestoreId(),
		scope: event,
		created: new Date().toISOString(),
		eventId: newFirestoreId(),
	};
	const webhookData = getMockDataForWebhookEvent(resource);
	return {
		...basePayload,
		data: webhookData,
	};
};

export const isLegacyWebhookEvent = (event: WebhookEventDb): event is LegacyWebhookEvent => {
	return !!WebhookEvents_LEGACY[event];
};

export const getWebhookResourceFromEvent = <T extends NonLegacyWebhookEventApi>(
	event: T,
): WebhookEventResource<T> | undefined => {
	const allowedResources: WebhookEventResource[] = ['order', 'product'];
	const resource = event.split('/')[0];
	if (allowedResources.includes(resource as WebhookEventResource)) {
		return resource as WebhookEventResource<T>;
	}
	return undefined;
};
