import React from 'react';

import { Box, Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { RiCloseLine } from 'react-icons/ri';
import { makeStyles } from 'tss-react/mui';

import { BannerProps, IconByVariant } from './types';

const Banner = (props: BannerProps) => {
	const {
		variant,
		title,
		description,
		icon,
		button,
		buttonEl,
		onClose,
		raised,
		...boxProps
	} = props;
	const { classes } = useStyles(props);

	const Icon = variant !== 'none' ? icon ?? IconByVariant[variant] : undefined;

	return (
		<Box className={classes.wrapper} {...boxProps}>
			<Stack direction={'column'} spacing={1}>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="flex-start"
					alignItems={{ xs: 'flex-start', sm: 'center' }}
				>
					<Stack direction="row" sx={{ flex: '1 1 0' }}>
						<Box className={classes.iconWrapper}>{!!Icon && <Icon color="inherit" />}</Box>
						<Stack direction="column">
							<Typography variant="h6" sx={{ flex: 1, mr: 2 }} className={classes.title}>
								{title}
							</Typography>
							{typeof description === 'string' ? (
								<Box>
									<Typography variant="body2" className={classes.description}>
										{description}
									</Typography>
								</Box>
							) : (
								description
							)}
						</Stack>
					</Stack>
					{!!button && (
						<Box className={classes.buttonWrapper}>
							<Button onClick={button.onClick} color="primary" className={classes.button}>
								{button.loading ? <CircularProgress size={20} color="inherit" /> : button.text}
							</Button>
						</Box>
					)}
					{!!buttonEl && <Box ml={{ xs: 3, sm: 0 }}>{buttonEl}</Box>}
					{!!onClose && (
						<Stack sx={{ position: 'absolute', top: 0, right: 0 }}>
							<IconButton onClick={onClose}>
								<RiCloseLine />
							</IconButton>
						</Stack>
					)}
				</Stack>
				{!!description && (
					<Stack direction="row">
						<Box className={classes.iconWrapper} />
					</Stack>
				)}
			</Stack>
		</Box>
	);
};

const useStyles = makeStyles<BannerProps>()((theme: Theme, { variant, raised }) => ({
	wrapper: {
		padding: variant === 'none' ? 0 : theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		borderRadius: 4,
		boxShadow: raised && variant !== 'none' ? '0 1px 10px 0 rgba(0,0,0,0.35)' : 'none',
		backgroundColor: (() => {
			switch (variant) {
				case 'info':
					return theme.palette.info.lightest;
				case 'warning':
					return theme.palette.warning.lightest;
				case 'danger':
					return theme.palette.error.lightest;
				case 'success':
					return theme.palette.success.lightest;
				default:
					return undefined;
			}
		})(),
		background: (() => {
			switch (variant) {
				case 'upgrade':
					return theme.palette.background.upgradeGradient;
				default:
					return undefined;
			}
		})(),
		position: 'relative',
	},
	iconWrapper: {
		paddingRight: theme.spacing(2),
		minWidth: 40,
		maxWidth: 40,
		color: (() => {
			switch (variant) {
				case 'info':
					return theme.palette.info.main;
				case 'warning':
					return theme.palette.warning.main;
				case 'danger':
					return theme.palette.error.main;
				case 'success':
					return theme.palette.success.main;
				default:
					return undefined;
			}
		})(),
	},

	title: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 16,
	},
	description: {
		color: theme.palette.text.secondary,
		fontWeight: 400,
		fontSize: 14,
	},
	buttonWrapper: {
		color: (() => {
			switch (variant) {
				case 'info':
					return theme.palette.text.primary;
				case 'warning':
					return theme.palette.text.primary;
				case 'danger':
					return theme.palette.text.primary;
				default:
					return undefined;
			}
		})(),
		marginLeft: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			marginTop: theme.spacing(0),
			marginLeft: theme.spacing(2),
		},
	},
	button: {
		textTransform: 'none',
		fontWeight: 600,
		color: (() => {
			switch (variant) {
				case 'upgrade':
					return theme.palette.text.primary;
				default:
					return undefined;
			}
		})(),
		'&:hover': {
			backgroundColor: (() => {
				switch (variant) {
					case 'upgrade':
						return theme.palette.common.white;
					default:
						return undefined;
				}
			})(),
		},
	},
}));

export default Banner;
