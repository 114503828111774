import { api } from 'common/frontend/api';
import { Shopper } from 'common/types';

export let removeOrderShoppersListener: () => void = () => undefined;

export const addOrderShoppersListener = (
	rentalId: string,
	shopId: string,
	callback: (shoppers: Shopper[]) => void,
) => {
	const rentalShoppersRef = api()
		.orderShoppers.get.where('rentalId', '==', rentalId)
		.where('shopId', '==', shopId)
		.orderBy('created', 'asc');
	removeOrderShoppersListener = rentalShoppersRef.onSnapshot(async (shoppers) => {
		callback(shoppers);
	});
};

export const getOrderShoppers = async (rentalId: string, shopId: string): Promise<Shopper[]> => {
	const orderShoppers = await api()
		.orderShoppers.get.where('rentalId', '==', rentalId)
		.where('shopId', '==', shopId)
		.get();
	return orderShoppers;
};

export const getOrderShopper = async (shopperId: string): Promise<Shopper | undefined> => {
	return api().orderShoppers.doc(shopperId).get();
};
