import { clamp, isEmpty, multiply } from 'lodash';
import moment from 'moment-timezone';

import {
	PlanCampaign,
	PlanCampaignDiscount,
	PlanCampaignRules,
	PlanCampaignValidity,
	PublicPlanCampaign,
	zPlanCampaignVisibilityTypes,
} from 'common/modules/plans/campaigns/types';
import { Plan } from 'common/modules/plans/plans';
import { BillingCycle, getPlanDefaultBasePrice } from 'common/modules/plans/subscriptions';
import { AmountObject, ISOString, ShopReadOnlyData } from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { multiplyPrice } from 'common/utils/pricing';

export const isPublicPlanCampaign = (campaign: PlanCampaign): campaign is PublicPlanCampaign => {
	return campaign.visibility.type === zPlanCampaignVisibilityTypes.Enum.PUBLIC;
};

export const getMonthlyCampaignDiscountAmount = (
	price: AmountObject,
	discount: PlanCampaignDiscount,
	billingCycle?: BillingCycle,
): AmountObject => {
	const amount = (() => {
		switch (discount.type) {
			case 'FIXED_PRICE_FIRST_PAYMENT':
				return {
					value:
						billingCycle === BillingCycle.YEARLY
							? (price.value * 12 - discount.value) / 12
							: price.value - discount.value,
					currency: price.currency,
				};
			case 'FIXED_PRICE_OFF_FIRST_PAYMENT':
				return {
					value: billingCycle === BillingCycle.YEARLY ? discount.value / 12 : discount.value,
					currency: price.currency,
				};
			case 'PERCENTAGE_OFF_FIRST_PAYMENT':
				return multiplyPrice(price, discount.value / 100);
			default:
				return switchUnreachable(discount);
		}
	})();

	return {
		...amount,
		value: clamp(amount.value, 0, price.value),
	};
};

const isDateInValidityRange = (date: ISOString, validity: PlanCampaignValidity | null): boolean => {
	if (!validity || !validity.startDate || !validity.endDate) {
		return true;
	}
	return moment
		.utc(date)
		.isBetween(
			moment.utc(validity.startDate).startOf('day'),
			moment.utc(validity.endDate).endOf('day'),
		);
};

const getValidCampaignRules = (rules: PlanCampaignRules | null): Array<keyof PlanCampaignRules> => {
	return Object.entries(rules ?? {})
		.filter(([_, value]) => !isEmpty(value))
		.map(([key]) => key as keyof PlanCampaignRules);
};

export const doesCampaignApplyToUpgrade = (args: {
	campaign: PlanCampaign;
	date: ISOString;
	shopReadOnly: ShopReadOnlyData;
	newPlan: Plan;
	newBillingCycle: BillingCycle;
}): boolean => {
	const { campaign, date, shopReadOnly, newPlan, newBillingCycle } = args;

	const validDate = isDateInValidityRange(date, campaign.validity);
	if (!validDate) {
		return false;
	}

	const validRules = getValidCampaignRules(campaign.rules);

	for (const rule of validRules) {
		switch (rule) {
			case 'newPlan':
				if (!campaign.rules!.newPlan!.includes(newPlan)) {
					return false;
				}
				break;
			case 'newBillingCycle':
				if (!campaign.rules!.newBillingCycle!.includes(newBillingCycle)) {
					return false;
				}
				break;
			case 'currentPlan':
				const currentPlan = shopReadOnly.features.plan.plan;
				if (!campaign.rules!.currentPlan!.includes(currentPlan)) {
					return false;
				}
				break;
			case 'currentBillingCycle':
				const currentCycle = shopReadOnly.features.plan.subscription?.billingCycle;
				if (currentCycle && !campaign.rules!.currentBillingCycle!.includes(currentCycle)) {
					return false;
				}
				break;
			default: {
				return switchUnreachable(rule);
			}
		}
	}

	return true;
};

export const isCampaignValidForShop = (args: {
	campaign: PlanCampaign;
	date: ISOString;
	shopReadOnly: ShopReadOnlyData;
	plan?: Plan;
	billingCycle?: BillingCycle;
}): boolean => {
	const { campaign, date, shopReadOnly, plan, billingCycle } = args;
	const validDate = isDateInValidityRange(date, campaign.validity);
	if (!validDate) {
		return false;
	}
	const validRules = getValidCampaignRules(campaign.rules);
	for (const rule of validRules) {
		switch (rule) {
			case 'currentPlan':
				const currentPlan = shopReadOnly.features.plan.plan;
				if (!campaign.rules!.currentPlan!.includes(currentPlan)) {
					return false;
				}
				break;
			case 'currentBillingCycle':
				const currentCycle = shopReadOnly.features.plan.subscription?.billingCycle;
				if (currentCycle && !campaign.rules!.currentBillingCycle!.includes(currentCycle)) {
					return false;
				}
				break;
			case 'newPlan':
				if (!!plan && !campaign.rules!.newPlan!.includes(plan)) {
					return false;
				}
				break;
			case 'newBillingCycle':
				if (!!billingCycle && !campaign.rules!.newBillingCycle!.includes(billingCycle)) {
					return false;
				}
				break;
			default:
				switchUnreachable(rule);
		}
	}
	return true;
};

export const getBestPublicCampaign = (args: {
	campaigns?: PublicPlanCampaign[];
	defaultAmount?: AmountObject;
	billingCycle?: BillingCycle;
}): PublicPlanCampaign | undefined => {
	const { campaigns, defaultAmount, billingCycle } = args;
	if (!campaigns || !defaultAmount) {
		return undefined;
	}
	const bestCampaign = campaigns.reduce((best, campaign) => {
		if (!campaign.discount) {
			return best;
		}
		if (!best.discount) {
			return campaign;
		}
		if (campaign.discount.type === best.discount.type) {
			return campaign.discount.value > best.discount.value ? campaign : best;
		}
		const campaignPrice = getMonthlyCampaignDiscountAmount(
			defaultAmount,
			campaign.discount,
			billingCycle,
		);
		const bestPrice = getMonthlyCampaignDiscountAmount(defaultAmount, best.discount, billingCycle);
		return campaignPrice.value > bestPrice.value ? campaign : best;
	}, {} as PublicPlanCampaign);
	return Object.keys(bestCampaign).length ? bestCampaign : undefined;
};

export const getPlanPrices = (args: {
	plan: Plan;
	billingCycle?: BillingCycle;
	campaign?: PlanCampaign;
}): {
	highestPrice: number;
	realPrice: number;
	savedPrice: number;
} => {
	const { plan, billingCycle = BillingCycle.MONTHLY, campaign } = args;
	const highestPricePricing = getPlanDefaultBasePrice(plan, BillingCycle.MONTHLY);
	const highestPrice = highestPricePricing?.fixed?.value ?? 0;

	const price = getPlanDefaultBasePrice(plan, billingCycle);

	const discountValue =
		!!campaign?.discount && price?.fixed
			? getMonthlyCampaignDiscountAmount(price.fixed, campaign.discount, billingCycle).value
			: 0;

	const realPrice = (price?.fixed?.value ?? 0) - discountValue;

	const savedPrice =
		billingCycle === BillingCycle.YEARLY
			? multiply(12, highestPrice - realPrice)
			: highestPrice - realPrice;

	return {
		highestPrice,
		realPrice,
		savedPrice,
	};
};
