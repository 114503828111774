import { TFunction } from 'i18next';

import { switchUnreachable } from 'common/utils/common';

import { TimePeriod } from '../types';

export const MINUTES: TimePeriod = 'minutes';
export const HOURS: TimePeriod = 'hours';
export const DAYS: TimePeriod = 'days_within_opening_hours';
export const DAYS_24H: TimePeriod = '24h_days';
export const WEEKS: TimePeriod = 'weeks';
export const MONTHS: TimePeriod = 'months';
export const YEARS: TimePeriod = 'years';

export const TIME_PERIODS: TimePeriod[] = [MINUTES, HOURS, DAYS, DAYS_24H, WEEKS, MONTHS, YEARS];

export const getTimePeriodLabel = (timePeriod: TimePeriod, t: TFunction) => {
	switch (timePeriod) {
		case MINUTES:
			return {
				singular: t('common:times.minute', 'minute'),
				plural: t('common:times.minutes', 'minutes'),
			};
		case HOURS:
			return {
				singular: t('common:times.hour'),
				plural: t('common:times.hours'),
			};
		case DAYS:
			return {
				singular: t('common:times.day'),
				plural: t('common:times.days'),
			};
		case 'days':
			return {
				singular: t('common:times.day'),
				plural: t('common:times.days'),
			};
		case DAYS_24H:
			return {
				singular: t('common:times.day24', 'day (24h)'),
				plural: t('common:times.days24'),
			};
		case WEEKS:
			return {
				singular: t('common:times.week'),
				plural: t('common:times.weeks'),
			};
		case MONTHS:
			return {
				singular: t('common:times.month', 'month'),
				plural: t('common:times.months', 'months'),
			};
		case YEARS:
			return {
				singular: t('common:times.year', 'year'),
				plural: t('common:times.years', 'years'),
			};
		default: {
			try {
				switchUnreachable(timePeriod);
			} catch (err) {}
			return '';
		}
	}
};

export const getDurationLabel = (
	timePeriod: TimePeriod,
	amount: number,
	t: TFunction,
	isOpenEnded?: boolean,
): string => {
	const timePeriodLabel = getTimePeriodLabel(timePeriod, t);

	if (!timePeriodLabel) return amount.toString();

	return `${amount}${!!isOpenEnded ? '+' : ''} ${
		amount === 1 ? timePeriodLabel.singular : timePeriodLabel.plural
	}`;
};
