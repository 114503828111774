import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { InventoryTransfer } from 'common/modules/inventoryTransfers';

import { createBasicDbOperations } from '../utils';

export const getInventoryTransferQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.inventoryTransfers;
	const dataUpdater = undefined;

	return {
		...createBasicDbOperations<InventoryTransfer>(collectionPath, dbRefs, dataUpdater),
	};
};
