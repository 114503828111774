import { zLocaleFieldApi } from './api';
import { toApi, toDb } from './mapper';

export * from './types';

export const LocaleField = {
	toApi,
	toDb,
	api: zLocaleFieldApi,
};
