import { Plans } from '../../plans';
import { MerchantSolutionDefinition, MerchantSolutions } from '../types';

const definition: MerchantSolutionDefinition = {
	id: MerchantSolutions.DELIVERY,
	name: 'Delivery',
	pricing: {
		default: {
			fixed: {
				amount: {
					value: 90,
					currency: 'USD',
				},
				description: 'Fixed price per booking with delivery',
			},
		},
		byPlan: {
			[Plans.BASIC]: {
				fixed: {
					amount: {
						value: 90,
						currency: 'USD',
					},
					description: 'Fixed price per booking with delivery',
				},
			},
			[Plans.GROW]: {
				fixed: {
					amount: {
						value: 90,
						currency: 'USD',
					},
					description: 'Fixed price per booking with delivery',
				},
			},
			[Plans.ADVANCED]: {
				fixed: {
					amount: {
						value: 90,
						currency: 'USD',
					},
					description: 'Fixed price per booking with delivery',
				},
			},
			[Plans.FREE]: null,
			[Plans.DISCOVER]: null,
			[Plans.BUILD]: null,
			[Plans.ADVANCE]: null,
		},
	},
	plans: [
		Plans.BASIC,
		Plans.GROW,
		Plans.ADVANCED,
		Plans.FREE,
		Plans.DISCOVER,
		Plans.BUILD,
		Plans.ADVANCE,
	],
};

export default definition;
