import React from 'react';

import { Dialog, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { RiCloseLine } from 'react-icons/ri';

import { AddOn, AddOnChannel } from 'common/modules/plans';

import AddOnModalContent from './AddOnModalContent';

interface Props {
	addOn: AddOn;
	addOnChannel: AddOnChannel;
	open: boolean;
	onClose: () => void;
	onExited: () => void;
}
const AddOnModal = (props: Props) => {
	const { addOn, onClose, onExited, open, addOnChannel } = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				TransitionProps={{ onExited }}
				fullScreen={isMobile}
				maxWidth="md"
				fullWidth
			>
				<IconButton
					onClick={onClose}
					sx={{
						position: 'absolute',
						top: 0,
						right: 0,
						margin: theme.spacing(1),
						padding: theme.spacing(2),
					}}
				>
					<RiCloseLine />
				</IconButton>
				<AddOnModalContent addOn={addOn} addOnChannel={addOnChannel} onClose={onClose} />
			</Dialog>
		</>
	);
};

export default AddOnModal;
