import { createSelector } from '@reduxjs/toolkit';

import { SELF_RETURN_CARRIER } from 'common/modules/delivery/constants';
import { DEFAULT_OPENING_HOURS } from 'common/modules/openingHours';
import { LoadingData, OrderObject, PurchaseTypes } from 'common/types';
import { arrayIncludesSome } from 'common/utils/arrays';
import { notFalsey } from 'common/utils/common';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { ReduxState } from 'services/types';

export const _orderProducts = (state: ReduxState) => state.rentalView.orderProducts;
export const orderProducts = (state: ReduxState) => _orderProducts(state).data ?? [];
export const orderProductsLoading = (state: ReduxState) => _orderProducts(state).loading;
export const orderProductsError = (state: ReduxState) => _orderProducts(state).error;

export const _orderShoppers = (state: ReduxState) => state.rentalView.orderShoppers;
export const orderShoppers = (state: ReduxState) => _orderShoppers(state).data ?? [];
export const orderShoppersLoading = (state: ReduxState) => _orderShoppers(state).loading;
export const orderShoppersError = (state: ReduxState) => _orderShoppers(state).error;

export const _orderInfo = (state: ReduxState) => state.rentalView.orderInfo;
export const orderInfo = (state: ReduxState) => _orderInfo(state).data;
export const orderInfoLoading = (state: ReduxState) => _orderInfo(state).loading;
export const orderInfoError = (state: ReduxState) => _orderInfo(state).error;
export const orderId = createSelector(orderInfo, (info) => info?.id);

export const _orderDelivery = (state: ReduxState) => state.rentalView.orderDelivery;
export const orderDelivery = (state: ReduxState) => _orderDelivery(state).data;
export const orderDeliveryLoading = (state: ReduxState) => _orderDelivery(state).loading;
export const orderDeliveryError = (state: ReduxState) => _orderDelivery(state).error;

export const _activeOrder = createSelector(
	_orderInfo,
	_orderProducts,
	_orderShoppers,
	_orderDelivery,
	(info, products, shoppers, delivery): LoadingData<OrderObject> => {
		const loading = info.loading || products.loading || shoppers.loading || delivery.loading;
		const errors = [info.error, products.error, shoppers.error, delivery.error].filter(notFalsey);

		const data: OrderObject | null =
			!!info.data && !!products.data && !!shoppers.data
				? {
						rentalInfo: info.data,
						products: products.data,
						shoppers: shoppers.data,
						orderDelivery: delivery.data ?? undefined,
				  }
				: null;

		if (loading) {
			return {
				error: null,
				loading: true,
				data,
			};
		}

		if (errors.length > 0) {
			return {
				error: errors[0],
				loading: false,
				data: null,
			};
		}

		if (!data) {
			return {
				error: 'Order not found',
				loading: false,
				data: null,
			};
		}

		return {
			loading: false,
			error: null,
			data,
		};
	},
);

export const activeOrder = createSelector(_activeOrder, (order) => order.data);
export const activeOrderLoading = createSelector(_activeOrder, (order) => order.loading);
export const activeOrderError = createSelector(_activeOrder, (order) => order.error);

export const activeShopperId = (state: ReduxState) => state.rentalView.activeShopperId;

export const hideProductCodeErrors = (state: ReduxState) => state.rentalView.hideProductCodeErrors;

export const orderStartLocationId = createSelector(
	orderInfo,
	(orderInfo) => orderInfo?.startLocation.id,
);

export const orderOpeningHours = createSelector(
	orderInfo,
	ShopSelectors.openingHoursByStore,
	ShopSelectors.activeShopMainLocationId,
	(orderInfo, openingHoursByStore, mainLocationId) => {
		const locationId = orderInfo?.endLocation.id ?? mainLocationId;
		return openingHoursByStore[locationId] ?? DEFAULT_OPENING_HOURS;
	},
);

export const rentalHasDelivery = createSelector(
	orderDelivery,
	(orderDelivery) => !!orderDelivery?.to,
);

export const rentalHasPickup = createSelector(
	orderDelivery,
	(orderDelivery) => !!orderDelivery?.from,
);

export const paymentMethod = createSelector(
	orderInfo,
	(rentalInfo) => rentalInfo?.pricing.paymentMethod,
);

export const orderHasRentalsOrSubscriptions = createSelector(orderInfo, (orderInfo) => {
	return arrayIncludesSome(orderInfo?.purchaseTypes, [
		PurchaseTypes.rental,
		PurchaseTypes.subscription,
	]);
});

export const orderHasSubscriptions = createSelector(
	orderInfo,
	(orderInfo) => orderInfo?.purchaseTypes?.includes(PurchaseTypes.subscription) ?? false,
);

export const orderHasSales = createSelector(
	orderInfo,
	(orderInfo) => orderInfo?.purchaseTypes?.includes(PurchaseTypes.sales) ?? false,
);

export const orderIsACS = createSelector(orderInfo, (orderInfo) => !!orderInfo?.acs);

export const canAddRentalProductsToOrder = createSelector(
	orderIsACS,
	orderInfo,
	(isACS, orderInfo) =>
		isACS ||
		!orderInfo?.purchaseTypes ||
		(orderInfo?.purchaseTypes.some((type) => type === 'rental') ?? false),
);

export const customCheckoutFields = createSelector(
	orderInfo,
	(orderInfo) => orderInfo?.customCheckoutFields,
);

export const hasDeliveryWarnings = createSelector(
	orderDelivery,
	ShopSelectors.shopDeliveryOptions,
	ShopSelectors.activeShopCarriers,
	(orderDelivery, deliveryOptions, shopCarriers) => {
		const isMissingDeliveryCarrier = !orderDelivery?.to?.carrier?.id;
		const isMissingPickupCarrier = !!orderDelivery?.from && !orderDelivery.from.carrier?.id;
		const isMissingCarrier = isMissingDeliveryCarrier || isMissingPickupCarrier;

		const noPickupReturn = orderDelivery?.from?.carrier?.id === SELF_RETURN_CARRIER.id;

		const isDeliveryOptionDeleted = !deliveryOptions.find(
			(o) => o.id === orderDelivery?.deliveryOptionId,
		);
		const isDeliveryCarrierDeleted =
			!!orderDelivery?.to?.carrier?.id &&
			!shopCarriers.find((c) => c.id === orderDelivery?.to?.carrier?.id);
		const isPickupCarrierDeleted =
			!!orderDelivery?.from?.carrier?.id &&
			!noPickupReturn &&
			!shopCarriers.find((c) => c.id === orderDelivery?.from?.carrier?.id);

		const carrierDeleted = isDeliveryCarrierDeleted || isPickupCarrierDeleted;
		const carriersDeleted = isDeliveryCarrierDeleted && isPickupCarrierDeleted;
		const showWarningBanner = isDeliveryOptionDeleted || carrierDeleted;
		const deliveryOptionAndCarriersDeleted = isDeliveryOptionDeleted && carrierDeleted;

		return {
			isMissingDeliveryCarrier,
			isMissingPickupCarrier,
			isMissingCarrier,
			carriersDeleted,
			showWarningBanner,
			deliveryOptionAndCarriersDeleted,
			isDeliveryOptionDeleted,
			isDeliveryCarrierDeleted,
			isPickupCarrierDeleted,
		};
	},
);
