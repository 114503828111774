import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { ReduxState } from 'services/types';

export enum RentalsType {
	BOOKED = 'BOOKED',
	EXPIRED = 'EXPIRED',
	ACTIVE = 'ACTIVE',
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}

export enum RentalsTabValue {
	BOOKED = 'BOOKED',
	EXPIRED = 'EXPIRED',
	ACTIVE = 'ACTIVE',
	COMPLETED = 'COMPLETED',
}

export enum LocationType {
	START = 'START',
	END = 'END',
	BOTH = 'BOTH',
}

export const rentals = (state: ReduxState) => state.storeRentals;

export const buildRentalsOfType = (type: RentalsType) =>
	createSelector(rentals, (rentals) => {
		switch (type) {
			case RentalsType.BOOKED:
				return rentals.activeBookedStoreRentals;
			case RentalsType.EXPIRED:
				return rentals.expiredBookedStoreRentals;
			case RentalsType.ACTIVE:
				return rentals.activeStoreRentals;
			case RentalsType.PENDING:
				return rentals.pendingStoreRentals;
			case RentalsType.COMPLETED:
				return rentals.completedStoreRentals;
			default:
				throw new Error(`Invalid argument type ${type}`);
		}
	});

export const buildRentalsOfTypeData = (type: RentalsType) =>
	createSelector(buildRentalsOfType(type), (rentals) =>
		rentals.kind === 'FETCHED' ? rentals.data : [],
	);

export const upcomingRentalsToday = createSelector(
	buildRentalsOfTypeData(RentalsType.BOOKED),
	UserSelectors.userActiveLocationId,
	(rentals, locationId) => {
		const today = moment();
		return rentals.filter((rental) => {
			return rental.startLocation.id === locationId && today.isSame(rental.startDate, 'day');
		});
	},
);

export const buildRentalsOfTypeLoading = (type: RentalsType) =>
	createSelector(buildRentalsOfType(type), (rentals) => rentals.kind === 'LOADING');

export const buildRentalsOfTypeError = (type: RentalsType) =>
	createSelector(buildRentalsOfType(type), (rentals) =>
		rentals.kind === 'ERROR' ? rentals.error : null,
	);

export const buildRentalsOfTypeAtSelectedLocation = (
	type: RentalsType,
	locationType: LocationType,
) =>
	createSelector(
		UserSelectors.userActiveLocationId,
		ShopSelectors.activeShopAllLocations,
		ViewSelectors.bookingsViewLocationType,
		buildRentalsOfTypeData(type),
		(activeLocationId, allLocations, locationFilterType, rentals) => {
			if (locationFilterType === 'all') return rentals;
			const selectedLocation = allLocations.find((location) => location.id === activeLocationId);
			if (!selectedLocation) {
				return rentals;
			}

			switch (locationType) {
				case LocationType.START:
					return rentals.filter(
						(rental) =>
							rental.startLocation.id === activeLocationId ||
							(!rental.startLocation.id &&
								selectedLocation.address === rental.startLocation.address),
					);
				case LocationType.END:
					return rentals.filter(
						(rental) =>
							rental.endLocation.id === activeLocationId ||
							(!rental.endLocation.id && selectedLocation.address === rental.endLocation.address),
					);
				case LocationType.BOTH:
					return rentals.filter(
						(rental) =>
							rental.startLocation.id === activeLocationId ||
							rental.endLocation.id === activeLocationId ||
							(!rental.startLocation.id &&
								rental.startLocation.address === selectedLocation.address) ||
							(!rental.endLocation.id && rental.endLocation.address === selectedLocation.address),
					);
				default:
					throw new Error(`Invalid argument locationType ${locationType}`);
			}
		},
	);

export const rentalsAtSelectedLocationWithDeliveryTo = (
	type: RentalsType,
	locationType: LocationType,
) =>
	createSelector(buildRentalsOfTypeAtSelectedLocation(type, locationType), (rentals) =>
		rentals.filter((rental) => rental.services?.delivery?.to),
	);

export const rentalsAtSelectedLocationWithDeliveryFrom = (
	type: RentalsType,
	locationType: LocationType,
) =>
	createSelector(buildRentalsOfTypeAtSelectedLocation(type, locationType), (rentals) =>
		rentals.filter((rental) => rental.services?.delivery?.from),
	);

export const buildServiceRentalsOfTypeAtSelectedLocation = (
	type: RentalsType,
	locationType: LocationType,
) =>
	createSelector(buildRentalsOfTypeAtSelectedLocation(type, locationType), (rentals) =>
		rentals.filter((rental) => rental.services),
	);

export const buildRentalsOfTypeAtSelectedLocationCount = (
	type: RentalsType,
	locationType: LocationType,
) =>
	createSelector(
		buildRentalsOfTypeAtSelectedLocation(type, locationType),
		(rentals) => rentals.length,
	);
