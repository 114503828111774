import React from 'react';

import { Box, Button, Divider, Link, Typography, useTheme } from '@mui/material';
import { RiExternalLinkLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import BackdropDialog from 'common/components/BackdropDialog';
import { BILLING_CURRENCY } from 'common/modules/billing';
import { getPricingString } from 'common/utils/common';
import * as ViewActions from 'actions/ViewActions';
import { useAnalytics } from 'hooks/useAnalytics';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { EarnCreditsContent } from 'services/earnCredits/utils';
import { useTranslation } from 'services/localization';

export interface Props {}
const EarnCreditsModal = () => {
	const { t } = useTranslation();
	const { logAnalyticsEvent } = useAnalytics();

	const earnCreditsContent: EarnCreditsContent[] = [
		{
			title: t('earnCredits.modal.capterra.title', 'Review Rentle on Capterra'),
			method: 'Capterra',
			credits: 5000,
			list: [
				t(
					'earnCredits.modal.reviewContentItem1',
					'Reviews from merchants like you really help us 🙏',
				),
				t(
					'earnCredits.modal.reviewContentItem2',
					"You'll get $50 worth of credits per review for up to 3 reviews written by your team",
				),
				t(
					'earnCredits.mdaol.reviewContentItem3',
					'Send a message via our chat, after writing your review to receive the credits',
				),
			],
			cta: {
				label: t('earnCredits.modal.cta', 'Write a review'),
				src: 'https://reviews.capterra.com/new/187639',
			},
		},
		{
			title: 'Review Rentle on G2',
			method: 'G2',
			credits: 5000,
			list: [
				t(
					'earnCredits.modal.reviewContentItem2',
					"You'll get $50 worth of credits per review for up to 3 reviews written by your team",
				),
				t(
					'earnCredits.mdaol.reviewContentItem3',
					'Send a message via our chat, after writing your review to receive the credits',
				),
			],
			cta: {
				label: t('earnCredits.modal.cta', 'Write a review'),
				src: 'https://www.g2.com/products/rentle/reviews/start',
			},
		},
	];

	const dispatch = useDispatch();
	const isModalOpen = useSelector(ViewSelectors.isEarnCreditsModalOpen);
	const closeModal = () => dispatch(ViewActions.toggleEarnCreditsModal(false));
	const theme = useTheme();
	return (
		<BackdropDialog
			isOpen={isModalOpen}
			onClose={closeModal}
			variant="primary"
			titleCloseButton
			label={t('earnCredits.modalTitle', 'Earn Rentle Credits') + '!'}
			body={
				<Box pb={0}>
					{earnCreditsContent.map(({ title, method, credits, list, cta }, idx) => {
						const isFinalListItem = idx === earnCreditsContent.length - 1;
						return (
							<React.Fragment key={idx}>
								<Box display="flex" flexDirection="column" justifyContent="flex-start">
									<Typography variant="h6">{title}</Typography>
									<Typography sx={{ fontWeight: 500 }}>
										{t('earnCredits.modalRewardString', {
											reward: getPricingString(credits, BILLING_CURRENCY),
											defaultValue: '{{reward}} / per review',
										})}
									</Typography>
								</Box>
								<Box>
									<ul style={{ paddingLeft: '20px' }}>
										{list.map((item, idx) => (
											<li style={{ padding: theme.spacing(1) }} key={idx}>
												{item}
											</li>
										))}
									</ul>
								</Box>
								<Link
									sx={{ textDecoration: 'none' }}
									target="_blank"
									rel="noopener noreferrer"
									href={cta.src}
									onClick={() =>
										logAnalyticsEvent({ name: 'earn_credits_modal_cta_click', params: { method } })
									}
								>
									<Button
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
										variant="outlined"
										size="small"
									>
										<>
											{cta.label}
											<Box
												sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
												ml={1}
											>
												<RiExternalLinkLine size={20} />
											</Box>
										</>
									</Button>
								</Link>

								{!isFinalListItem && (
									<Box py={3}>
										<Divider />
									</Box>
								)}
							</React.Fragment>
						);
					})}
				</Box>
			}
		/>
	);
};

export default EarnCreditsModal;
