import React from 'react';

import { FallbackProps, ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';

import errorHandler from '../errorHandler';
import ErrorFallbackEmpty from './ErrorFallbackEmpty';

export interface Props {
	FallbackComponent?: React.ComponentType<FallbackProps>;
	onError?: (e: Error) => void;
	onReset?: () => void;
}
const ErrorBoundary: React.FC<React.PropsWithChildren<Props>> = ({
	FallbackComponent,
	onError,
	onReset,
	children,
}) => {
	const handleError = (e: Error) => {
		!!onError && onError(e);
		errorHandler.report(e);
	};
	return (
		<_ErrorBoundary
			FallbackComponent={FallbackComponent ?? ErrorFallbackEmpty}
			onError={handleError}
			onReset={() => (!!onReset ? onReset() : window.location.reload())}
		>
			{children}
		</_ErrorBoundary>
	);
};

export default ErrorBoundary;
