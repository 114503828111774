import React from 'react';

import { Stack, Tooltip, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { useLayoutContext } from 'components/Layout/context';
import UpgradeBadge from 'components/features/UpgradeBadge';

import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_COLLAPSED } from '../constants';
import { SidebarItem as SidebarItemType } from './utils';

export interface Props {
	item: SidebarItemType;
	isExpanded: boolean;
	isActive: boolean;
}
const SidebarItem = (props: Props) => {
	const { item, isExpanded, isActive } = props;
	const { isMobile, setSidebarOpen } = useLayoutContext();
	const { label, to, Icon, locked } = item;
	return (
		<Tooltip
			title={!isExpanded ? label : ''}
			placement="right"
			arrow
			enterDelay={300}
			leaveDelay={0}
		>
			<StyledLink
				to={to}
				onClick={() => {
					if (isMobile) {
						setSidebarOpen(false);
					}
				}}
				sx={{
					backgroundColor: (theme) => (isActive ? theme.palette.action.selected : undefined),
					width: isExpanded ? SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED,
				}}
				data-qa={item.id}
			>
				<Stack direction="row" alignItems="center" justifyContent="flex-start">
					<StyledIcon
						sx={{
							color: (theme) => (isActive ? theme.palette.primary.main : 'inherit'),
						}}
					>
						<Icon size={20} color={'inherit'} />
						{locked && (
							<UpgradeBadge
								size={12}
								sx={{ top: '4px', left: '30px', width: '16px', height: '16px' }}
							/>
						)}
					</StyledIcon>
					{isExpanded && (
						<Typography
							variant="body2"
							sx={{ fontWeight: 400, color: (theme) => theme.palette.text.primary }}
						>
							{label}
						</Typography>
					)}
				</Stack>
			</StyledLink>
		</Tooltip>
	);
};

const StyledLink = styled(Link)(({ theme }) => ({
	flexDirection: 'row',
	justifyContent: 'flex-start',
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
	'&:focus': {
		backgroundColor: theme.palette.action.selected,
		color: theme.palette.primary.main,
	},
}));

const StyledIcon = styled(Stack)(({ theme }) => ({
	height: '48px',
	width: SIDEBAR_WIDTH_COLLAPSED,
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',
}));

export default SidebarItem;
