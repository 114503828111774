import React from 'react';

import { Box, LinearProgressProps, Stack, Typography, styled } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

type Props = LinearProgressProps & { value: number; spacing?: number };

const ProgressWithLabel = (props: Props) => {
	return (
		<Stack direction="row" spacing={props.spacing ?? 1} alignItems="center">
			<Box width={'100%'}>
				<CustomProgressBar
					variant="determinate"
					{...props}
					value={props.value === 0 ? -1 : props.value} //Fix for visual bug in progress bar empty state
				/>
			</Box>
			<Box>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Stack>
	);
};

const CustomProgressBar = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	overflow: 'hidden',
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.primary.lightest,
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.primary.main,
		transitionTimingFunction: 'ease',
		transitionDelay: '150ms',
	},
}));

export default ProgressWithLabel;
