import { getOpenApiExample } from 'common/utils/openApi';

import { zOrderItemApi } from './api';
import { toApi } from './mapper';

export const OrderItem = {
	api: zOrderItemApi,
	toApi,
	example: getOpenApiExample(zOrderItemApi),
};
