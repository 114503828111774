import { getDownloadURL, uploadBytes } from 'firebase/storage';
import { TFunction } from 'react-i18next';
import { IconType } from 'react-icons/lib';
import { RiSettings5Line, RiWindowLine } from 'react-icons/ri';

import { storageRef } from 'common/frontend/firebase/storage';
import { FullContentPath, PartialContentPath } from 'common/modules/customization';
import { Languages, createLocaleField, updateDefault } from 'common/modules/translations';
import { LocaleField } from 'common/types';
import ImageTools from 'common/utils/ImageTools';
import { newId } from 'common/utils/common';

import { CustomizerPage } from './constants';

export const getPageTitle = (page: CustomizerPage, t: TFunction): string => {
	return (
		{
			general: t('channels.online.customizer.pages.general', 'General'),
			home: t('channels.online.customizer.pages.home', 'Homepage'),
			about: t('channels.online.customizer.pages.about', 'About'),
			checkout: t('channels.online.customizer.pages.checkout', 'Checkout'),
		}[page] ?? ''
	);
};

// function that returns page title and page icon
export const getPageTitleAndIcon = (
	page: CustomizerPage,
	t: TFunction,
): { title: string; icon: IconType } => {
	return {
		general: {
			title: t('channels.online.customizer.pages.general', 'General'),
			icon: RiSettings5Line,
		},
		home: {
			title: t('channels.online.customizer.pages.home', 'Homepage'),
			icon: RiWindowLine,
		},
		about: {
			title: t('channels.online.customizer.pages.about', 'About'),
			icon: RiWindowLine,
		},
		checkout: {
			title: t('channels.online.customizer.pages.checkout', 'Checkout'),
			icon: RiWindowLine,
		},
	}[page];
};

export const getFeatureImage = (args: {
	file: File;
	width: number;
	height: number;
}): Promise<Blob> => {
	const { file, width, height } = args;

	return new Promise((resolve) =>
		ImageTools.resize(
			file,
			{
				width,
				height,
			},
			(blob: any) => {
				resolve(blob);
			},
		),
	);
};

export const getCustomizerLocaleOnChange = (
	eventValue: string,
	fieldValue: LocaleField | null | undefined,
	defaultLanguage: Languages,
) => {
	return !!eventValue
		? !!fieldValue
			? updateDefault(fieldValue, defaultLanguage, eventValue)
			: createLocaleField(eventValue, defaultLanguage)
		: null;
};

export const uploadImage = async (
	e: React.ChangeEvent<HTMLInputElement>,
	onChange: (...event: any[]) => void,
	args: {
		shopId: string;
		width: number;
		height: number;
		path: string;
	},
) => {
	const { shopId, width, height, path } = args;
	const files = e.target.files;

	uploadImageFiles(files, { shopId, width, height, path }, onChange);
};

export const uploadImageFiles = async (
	files: File[] | FileList | null,
	args: {
		shopId: string;
		width: number;
		height: number;
		path: string;
	},
	onChange: (...event: any[]) => void,
) => {
	const { shopId, width, height, path } = args;

	if (!!files?.length) {
		const file = files[0];
		if (!file.type.match(/image.*/)) {
			throw Error('Wrong file type');
		}
		const fileName = `${file.name.replace(/[^A-Z0-9]/g, '')}_${newId(5)}`;
		const thumbImage = await getFeatureImage({ file, width, height });
		const ref = storageRef();
		const imageRef = storageRef(ref, `${path}/` + shopId + '/' + fileName);
		const imageUpload = await uploadBytes(imageRef, thumbImage);
		const src = await getDownloadURL(imageUpload.ref);

		onChange(src);
	}
};

export const getCustomizerSectionPath = (
	page: 'about' | 'checkout',
): 'onlineLayout.about.customContent.sections' | 'onlineLayout.checkout.customContent.sections' => {
	return `onlineLayout.${page}.customContent.sections`;
};

export const getCustomizerCustomPath = (args: {
	page: 'about' | 'checkout';
	sectionIndex: number;
	contentIndex?: number;
}): FullContentPath | PartialContentPath => {
	const { page, sectionIndex, contentIndex } = args;
	return contentIndex !== undefined
		? (`onlineLayout.${page}.customContent.sections.${sectionIndex}.content.${contentIndex}` as FullContentPath)
		: (`onlineLayout.${page}.customContent.sections.${sectionIndex}.content` as PartialContentPath);
};

export const showShopHeader = (page: CustomizerPage): boolean => {
	return {
		general: true,
		home: true,
		about: true,
		checkout: false,
	}[page];
};
