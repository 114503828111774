import { LocationEmailSettings } from 'common/modules/emails/types';
import { buildQuery, createBasicDbOperations, createBasicCollectionOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getEmailSettingsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.emailSettings;
	const query = buildQuery<LocationEmailSettings>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<LocationEmailSettings>(collectionPath, dbRefs),
		...createBasicCollectionOperations<LocationEmailSettings>(collectionPath, dbRefs),
	};
};
