import { AmountObject, ShopReadOnlyData } from 'common/types';
import { constrain } from 'common/utils/math';
import { sumPrices } from 'common/utils/pricing';

import { BILLING_CURRENCY } from '../constants';
import { BillingCreditsBalance } from '../types';

export const EMPTY_CREDITS_BALANCE: BillingCreditsBalance = {
	manual: {
		value: 0,
		currency: BILLING_CURRENCY.code,
	},
	refund: {
		value: 0,
		currency: BILLING_CURRENCY.code,
	},
};

export const getCreditsToUseAndBalanceAfter = (args: {
	creditsBalance: BillingCreditsBalance | undefined;
	creditsToUse: number;
	type: 'refund' | 'manual' | 'any';
}): {
	creditsToUse: {
		refund: number;
		manual: number;
		total: number;
	};
	balanceAfter: BillingCreditsBalance;
} => {
	const { type, creditsBalance, creditsToUse } = args;

	switch (type) {
		case 'refund': {
			const refundCreditsBalance = getRefundCreditsBalance(creditsBalance);
			const amountToUse = constrain(0, refundCreditsBalance.value)(creditsToUse);

			return {
				creditsToUse: {
					refund: amountToUse,
					manual: 0,
					total: amountToUse,
				},
				balanceAfter: removeCredits({
					creditsBalance,
					amount: amountToUse,
					type: 'refund',
				}),
			};
		}
		case 'manual': {
			const manualCreditsBalance = getManualCreditsBalance(creditsBalance);
			const amountToUse = constrain(0, manualCreditsBalance.value)(creditsToUse);

			return {
				creditsToUse: {
					refund: 0,
					manual: amountToUse,
					total: amountToUse,
				},
				balanceAfter: removeCredits({
					creditsBalance,
					amount: amountToUse,
					type: 'manual',
				}),
			};
		}
		case 'any': {
			const refundCreditsResult = getCreditsToUseAndBalanceAfter({
				creditsBalance,
				creditsToUse,
				type: 'refund',
			});

			const manualCreditsResult = getCreditsToUseAndBalanceAfter({
				creditsBalance: refundCreditsResult.balanceAfter,
				creditsToUse: creditsToUse - refundCreditsResult.creditsToUse.refund,
				type: 'manual',
			});

			return {
				creditsToUse: {
					refund: refundCreditsResult.creditsToUse.total,
					manual: manualCreditsResult.creditsToUse.total,
					total: refundCreditsResult.creditsToUse.total + manualCreditsResult.creditsToUse.total,
				},
				balanceAfter: manualCreditsResult.balanceAfter,
			};
		}
	}
};

export const removeCredits = (args: {
	creditsBalance: BillingCreditsBalance | undefined;
	amount: number;
	type: 'manual' | 'refund';
}): BillingCreditsBalance => {
	const { amount, type } = args;
	const creditsBalance = args.creditsBalance ?? getInitialCreditsBalance();
	switch (type) {
		case 'refund': {
			return {
				...creditsBalance,
				refund: {
					...creditsBalance.refund,
					value: creditsBalance.refund.value - amount,
				},
			};
		}
		case 'manual': {
			return {
				...creditsBalance,
				manual: {
					...creditsBalance.manual,
					value: creditsBalance.manual.value - amount,
				},
			};
		}
	}
};

export const addCredits = (args: {
	creditsBalance: BillingCreditsBalance | undefined;
	amount: number;
	type: 'manual' | 'refund';
}): BillingCreditsBalance => {
	const { amount, type } = args;
	const creditsBalance = args.creditsBalance ?? getInitialCreditsBalance();

	switch (type) {
		case 'refund': {
			return {
				...creditsBalance,
				refund: {
					...creditsBalance.refund,
					value: creditsBalance.refund.value + amount,
				},
			};
		}
		case 'manual': {
			return {
				...creditsBalance,
				manual: {
					...creditsBalance.manual,
					value: creditsBalance.manual.value + amount,
				},
			};
		}
	}
};

export const getCreditsDifference = (args: {
	gained: {
		refund: number;
		manual: number;
	};
	used: {
		refund: number;
		manual: number;
	};
}): BillingCreditsBalance => {
	return {
		refund: {
			value: args.gained.refund - args.used.refund,
			currency: BILLING_CURRENCY.code,
		},
		manual: {
			value: args.gained.manual - args.used.manual,
			currency: BILLING_CURRENCY.code,
		},
	};
};

export const getCreditsChange = (args: {
	before: BillingCreditsBalance;
	after: BillingCreditsBalance;
}): BillingCreditsBalance => {
	return {
		refund: {
			value: args.after.refund.value - args.before.refund.value,
			currency: BILLING_CURRENCY.code,
		},
		manual: {
			value: args.after.manual.value - args.before.manual.value,
			currency: BILLING_CURRENCY.code,
		},
	};
};

export const getTotalCreditsBalance = (
	creditsBalance: BillingCreditsBalance | undefined,
): AmountObject => {
	return sumPrices([
		getRefundCreditsBalance(creditsBalance),
		getManualCreditsBalance(creditsBalance),
	]);
};

export const getRefundCreditsBalance = (
	creditsBalance: BillingCreditsBalance | undefined,
): AmountObject => {
	return creditsBalance?.refund ?? { currency: BILLING_CURRENCY.code, value: 0 };
};

export const getManualCreditsBalance = (
	creditsBalance: BillingCreditsBalance | undefined,
): AmountObject => {
	return creditsBalance?.manual ?? { currency: BILLING_CURRENCY.code, value: 0 };
};

export const getInitialCreditsBalance = (): BillingCreditsBalance => {
	return {
		refund: { currency: BILLING_CURRENCY.code, value: 0 },
		manual: { currency: BILLING_CURRENCY.code, value: 0 },
	};
};

export const getCreditsBalanceObject = (shopReadOnly: ShopReadOnlyData | undefined) => {
	return shopReadOnly?.billing.credits ?? getInitialCreditsBalance();
};
