import { ClientDbBatch, ClientDbRefs, ClientDbTransaction } from 'common/db/api/types';
import { isBatch } from 'common/db/api/utils/typeGuards';
import { createApi } from 'common/db/api/utils/createApi';
import {
	db as clientDb,
	limit,
	orderBy,
	limitToLast,
	startAt,
	startAfter,
	endBefore,
	endAt,
	where,
	query,
	collection,
	doc,
	collectionGroup,
	updateDoc,
	setDoc,
	getDoc,
	deleteDoc,
	getDocs,
	onSnapshot,
	writeBatch,
	runTransaction,
	FieldValues,
} from 'common/frontend/firebase/firestore';
import { Api } from 'common/db/api/paths';

const baseDbRefs: ClientDbRefs = {
	db: clientDb,
	FieldValues,
	firestoreMethods: {
		limit,
		orderBy,
		limitToLast,
		startAt,
		startAfter,
		endBefore,
		endAt,
		where,
		query,
		collection,
		doc,
		collectionGroup,
		updateDoc,
		setDoc,
		getDoc,
		deleteDoc,
		getDocs,
		onSnapshot,
		writeBatch,
		runTransaction,
	},
};

const baseApi: Api = createApi(baseDbRefs);

export const api = (batchOrTransaction?: ClientDbBatch | ClientDbTransaction): Api => {
	if (batchOrTransaction === undefined) return baseApi;
	const dbRefs = isBatch(batchOrTransaction)
		? { ...baseDbRefs, batch: batchOrTransaction }
		: { ...baseDbRefs, transaction: batchOrTransaction };
	return createApi(dbRefs);
};
