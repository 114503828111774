import { createSelector } from '@reduxjs/toolkit';

import { userHasFeatures } from 'common/modules/plans';
import {
	isAdmin as _isAdmin,
	getAllPermissionsForUser,
	getUserAccessClaims,
	getUserDefaultLocation,
	getUserLocations,
	isRentleSupportFromUser,
} from 'common/modules/users/utils';
import { Permission, RentleSupportRole, Role, Roles } from 'common/types';
import { ReduxState } from 'services/types';

import * as ShopSelectors from './ShopSelectors';

export const user = (state: ReduxState) => state.user.user;
export const userData = (state: ReduxState) => user(state).data;
export const userLoading = (state: ReduxState) => user(state).loading;
export const userError = (state: ReduxState) => user(state).error;

export const activeUserId = createSelector(userData, (user) => user?.id ?? null);
export const activeUserLanguage = createSelector(userData, (user) => user?.language ?? null);
export const activeUserEmail = createSelector(userData, (user) => user?.email ?? null);
export const userActiveShopId = createSelector(userData, (user) => user?.activeShopId ?? null);
export const userActiveShopAccess = createSelector(userData, (user) => {
	if (!user || !user.activeShopId) return null;
	return getUserAccessClaims(user, user.activeShopId);
});

export const activeLocations = (state: ReduxState) => state.user.activeLocations;
export const userActiveLocationId = createSelector(
	activeUserId,
	activeLocations,
	(userId, locations) => {
		return userId ? locations?.[userId] ?? null : null;
	},
);

export const userActiveLocation = createSelector(
	userActiveLocationId,
	(state: ReduxState) => ShopSelectors.allShopLocationsById(state),
	(locationId, locationsById) => {
		return locationId ? locationsById[locationId] : null;
	},
);

export const userLocations = createSelector(
	userActiveShopAccess,
	(state: ReduxState) => ShopSelectors.activeShopAllLocations(state),
	(access, locations) => {
		if (!access) return [];
		return getUserLocations({
			access,
			locations,
		});
	},
);

export const userHasAccessToAllLocations = createSelector(userActiveShopAccess, (access) => {
	if (!access) return false;
	if (_isAdmin(access.roles)) return true;
	if (!access.locations) return true;
	return false;
});

export const userDefaultLocation = createSelector(
	userActiveShopAccess,
	(state: ReduxState) => ShopSelectors.activeShopAllLocations(state),
	(state: ReduxState) => ShopSelectors.activeShopMainLocationId_SAFE(state),
	(access, locations, mainLocationId) => {
		if (!access || !mainLocationId) return null;
		return getUserDefaultLocation({
			access,
			locations,
			mainLocationId,
		});
	},
);

export const userMerchantLevelPreferences = createSelector(
	userData,
	(state: ReduxState) => ShopSelectors.activeShopId(state),
	(user, shopId) => (shopId ? user?.userPreferences?.merchants?.[shopId] : null),
);

export const userShops = createSelector(userData, (user) => Object.keys(user?.shops ?? {}));

export const userRoles = createSelector(
	userData,
	(state: ReduxState) => ShopSelectors.activeShopId(state),
	(user, shopId): Role[] => {
		if (!user || !shopId) return [];
		const userAccess = getUserAccessClaims(user, shopId);
		if (!userAccess) return [];
		const supportRoles: RentleSupportRole[] =
			!!user && isRentleSupportFromUser(user, shopId) ? [Roles.rentle_support] : [];
		return [...userAccess.roles, ...supportRoles];
	},
);

export const userPermissions = createSelector(
	userData,
	(state: ReduxState) => ShopSelectors.activeShopId(state),
	(user, shopId): Permission[] => {
		if (!user || !shopId) return [];
		const userAccess = getUserAccessClaims(user, shopId);
		if (!userAccess) return [];
		return getAllPermissionsForUser(userAccess);
	},
);

export const userRolesPermissions = createSelector(
	userRoles,
	userPermissions,
	(roles, permissions) => ({ roles, permissions }),
);

export const userFeatures = createSelector(
	(state: ReduxState) => ShopSelectors.allShopFeatures(state),
	userRoles,
	userPermissions,
	(shopFeatures, roles, permissions) => {
		const rolesPermissions = { roles, permissions };
		return shopFeatures.filter((feature) => userHasFeatures(feature)(rolesPermissions));
	},
);

export const isAdmin = createSelector(userRoles, (roles) => _isAdmin(roles));
