import { TFunction } from 'i18next';
import moment from 'moment-timezone';

import { getFunctionsBaseUrl } from 'common/api/configs';
import { getItemsPayments, getOrderDeliveryPayments } from 'common/modules/atoms/paymentAmounts';
import { getTaxDetails, getTaxRateFromVatPercent, getTotalTaxes } from 'common/modules/atoms/taxes';
import { getTotalDiscountFromAppliedDiscountCodes } from 'common/modules/discountCodes';
import { isSubscriptionPurchaseType } from 'common/modules/orders/products';
import {
	isAdyenPaymentMethod,
	isOnlineProviderPaymentMethod,
} from 'common/modules/payments/paymentMethods';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { getSubscriptionDurationString } from 'common/modules/subscriptions';
import { getTranslation } from 'common/modules/translations';
import {
	AppliedDiscountCodes,
	Commission,
	CommissionSplit,
	Environment,
	ExtensionDuration,
	FeeItemObject,
	ItemObject,
	LocaleField,
	MarketPlaceSplit,
	ModifyPaymentRequest,
	OnlineOrderRequest,
	OrderDelivery,
	OrderProduct,
	PaymentAction,
	PaymentApi,
	PaymentRequest,
	PaymentState,
	PurchaseTypes,
	ReceiptApi,
	ShopPublicInfo,
	SplitObject,
	TransactionApi,
} from 'common/types';
import { AmountObject } from 'common/types/adyen';
import { getResponsiblePersonDetails, newFirestoreId } from 'common/utils/newRentalUtils';

import { getDurationString } from './dateCalculations';
import { getExtensionDurationShortString } from './extensions';

export const createPaymentObjects = (
	data: PaymentRequest | ModifyPaymentRequest,
): {
	payment: PaymentApi;
	transactions: TransactionApi[];
	receipt: ReceiptApi;
} => {
	const {
		amount,
		shopId,
		orderId,
		live,
		receiptNumber,
		shopperId,
		orderPaymentRequestId,
		channel,
		startDate,
		shopReceiptInfo,
		transactionsInfo,
		paymentType,
		productIds,
		items,
		capture,
		paymentAction,
		locationId,
		invoiceIds,
	} = data;
	const {
		originalPaymentId,
		originalTransactionId,
		originalPspReference,
	} = data as Partial<ModifyPaymentRequest>;
	const allProductIds = productIds;
	const currentDate = new Date().toISOString();
	const usageDate = moment(startDate).isAfter(moment(currentDate)) ? startDate : currentDate;
	const receiptId: string = newFirestoreId();
	const newPayment: PaymentApi = {
		id: newFirestoreId(),
		orderProductIds: allProductIds,
		transactionIds: transactionsInfo.map((t) => t.transactionId),
		items,
		orderId,
		locationId,
		shopperId: shopperId || null,
		shopId,
		invoiceIds,
		channel,
		methods: [...new Set(transactionsInfo.map((t) => t.method))],
		paymentType,
		paymentRequestId: orderPaymentRequestId != null ? orderPaymentRequestId : null,
		amount: {
			value: amount.value,
			currency: amount.currency,
			captured: capture ? amount.value : 0,
			paid: capture ? amount.value : 0,
			authorised: amount.value,
		},
		usageDate,
		timestamp: currentDate,
		completed: true,
		live,
		receiptId,
		receiptNumber,
		capture,
		paymentAction,
		state: getPaymentStateFromAction(paymentAction),
		...(originalPaymentId && { originalPaymentId }),
	};
	const transactions: TransactionApi[] = [];
	for (const transactionInfo of transactionsInfo) {
		const {
			transactionId,
			amount: transactionAmount,
			method,
			pspReference,
			provider,
			result,
			splitData,
		} = transactionInfo;
		const fee = getFeeFromSplitData(splitData || null);
		const newTransaction: TransactionApi = {
			id: transactionId,
			success: true,
			pspReference: pspReference || null,
			timestamp: currentDate,
			shopperId: shopperId || null,
			shopId,
			invoiceIds,
			amount: {
				value: transactionAmount.value,
				currency: transactionAmount.currency,
				captured: capture ? transactionAmount.value : 0,
				paid: capture ? transactionAmount.value : 0,
				authorised: transactionAmount.value,
			},
			paymentType,
			method,
			cardOnFile: false,
			cardToken: null,
			paymentId: newPayment.id,
			orderId,
			live,
			status: 'SUCCESSFUL',
			state: capture ? 'CAPTURE' : 'AUTHORISATION',
			capture,
			events: [
				{
					code: paymentAction,
					timestamp: currentDate,
				},
			],
			refund: null,
			refunds: [],
			errorCondition: null,
			errorMessage: null,
			commission: null,
			provider,
			splitData: splitData,
			fee,
			paymentAction,
			...(!!originalTransactionId && { originalTransactionId }),
			...(originalPspReference !== undefined && { originalReference: originalPspReference }),
			...(!!result && { details: result }),
		};
		transactions.push(newTransaction);
	}
	const receipt: ReceiptApi = {
		id: receiptId,
		shopId,
		orderId,
		invoiceIds,
		paymentId: newPayment.id,
		receiptNumber,
		items,
		timestamp: currentDate,
		shopInfo: shopReceiptInfo,
		transactionsInfo: transactions.map((t) => ({
			method: t.method,
			methodDetails: null,
			pspReference: t.pspReference,
			amount: t.amount,
			transactionId: t.id,
		})),
		capture,
	};
	return {
		payment: newPayment,
		transactions,
		receipt,
	};
};

const getPaymentStateFromAction = (paymentAction: PaymentAction): PaymentState => {
	switch (paymentAction) {
		case 'AUTHORISATION':
			return 'AUTHORISATION';
		case 'REFUND':
			return 'REFUND';
		case 'CANCEL':
			return 'CANCEL';
		case 'CAPTURE':
		default:
			return 'CAPTURE';
	}
};

export interface SplitDataRequest {
	totalAmount: AmountObject;
	account: string;
	commission: Commission;
	vatPercentage: number;
	marketPlaceSplitReference?: string;
	commissionSplitReference?: string;
	refund?: boolean; // Indicated if split should be negative
}

export const calculateCommissionAmount = ({
	commission,
	totalAmount,
	vatPercentage,
}: {
	commission: Commission;
	totalAmount: AmountObject;
	vatPercentage: number;
}) => {
	const { fixedInCents, percentageInDecimals } = commission;
	let commissionAmount = totalAmount.value * percentageInDecimals + fixedInCents;
	if (vatPercentage) {
		commissionAmount = commissionAmount * (1 + vatPercentage / 100);
	}
	return Math.floor(commissionAmount) <= totalAmount.value
		? Math.floor(commissionAmount)
		: totalAmount.value;
};

export const getShopBankStatementDescription = (shopPublicInfo: ShopPublicInfo) => {
	const shopStatementDescription = shopPublicInfo.name || 'Rentle';
	// From https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
	const normalizedDescription = shopStatementDescription
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
	// These special characters are from Stripe list, excluding * that is quite widely used by others
	const descriptionFilteredOutSpecialCharacters = normalizedDescription.replace(
		/[`'"<>{}[\]\\/]/gi,
		'',
	);
	return descriptionFilteredOutSpecialCharacters.substring(0, 22);
};

export const getShopperEmailFromOrderRequest = (
	orderRequest: OnlineOrderRequest,
): string | null => {
	const {
		order: { rentalInfo, shoppers },
	} = orderRequest;
	const responsiblePerson = rentalInfo.responsiblePerson;
	const responsiblePersonDetails = getResponsiblePersonDetails(responsiblePerson, shoppers);
	const shopperEmail = (responsiblePersonDetails && responsiblePersonDetails.email) || null;
	return shopperEmail;
};

export const getSplitData = ({
	totalAmount,
	account,
	commission,
	vatPercentage,
	marketPlaceSplitReference,
	commissionSplitReference,
}: SplitDataRequest): SplitObject[] => {
	let commissionAmount = calculateCommissionAmount({
		commission,
		totalAmount,
		vatPercentage,
	});
	const marketplaceAmount = totalAmount.value - commissionAmount;
	const marketPlaceSplit: MarketPlaceSplit = {
		amount: {
			value: marketplaceAmount,
			currency: totalAmount.currency,
		},
		type: 'MarketPlace',
		account,
		reference: marketPlaceSplitReference || newFirestoreId(),
	};
	const commissionSplit: CommissionSplit = {
		amount: {
			value: commissionAmount,
			currency: totalAmount.currency,
		},
		type: 'Commission',
		feeVatPercentage: vatPercentage,
		feeCommission: commission,
		reference: commissionSplitReference || newFirestoreId(),
	};
	return [marketPlaceSplit, commissionSplit];
};

export const getFeeFromSplitData = (splitData: SplitObject[] | null): FeeItemObject | null => {
	const commissionSplit = ((splitData && splitData.find((split) => split.type === 'Commission')) ||
		null) as CommissionSplit | null;
	if (!commissionSplit) return null;
	const total = commissionSplit.amount.value;
	const taxDetails = getTaxDetails(total, {
		rate: commissionSplit.feeVatPercentage / 100,
		taxExcluded: false,
	});
	const subtotal = total;
	const listPrice = subtotal;
	const feeItem: FeeItemObject | null = commissionSplit
		? {
				name: 'Rentle',
				durationString: null,
				units: null,
				pricing: {
					total: commissionSplit.amount.value,
					currency: commissionSplit.amount.currency,
					taxLines: taxDetails.taxLines,
					totalTaxes: taxDetails.totalTaxes,
					listPrice,
					originalListPrice: listPrice,
					subtotal,
					taxExcluded: false,
					totalDiscounts: 0,
				},
				productId: null,
				feeDetails: commissionSplit.feeCommission,
				stockProductId: null,
		  }
		: null;
	return feeItem;
};

export const getItemsForPayment = (
	products: OrderProduct[],
	orderDelivery: OrderDelivery | undefined,
	amount: AmountObject,
	t: TFunction,
) => {
	const productsTotalUnpaid = getItemsPayments(products).charge.unpaid;
	const servicesTotalUnpaid = orderDelivery
		? getOrderDeliveryPayments(orderDelivery).charge.unpaid
		: 0;
	const totalUnpaid = productsTotalUnpaid + servicesTotalUnpaid;
	const paidRatio = !!totalUnpaid ? amount.value / totalUnpaid : 0;
	const productItems: ItemObject[] = getItemsFromOrderProducts(products, paidRatio, t);
	const serviceItems: ItemObject[] = orderDelivery
		? [getItemFromOrderDelivery(orderDelivery, paidRatio)]
		: [];
	return [...productItems, ...serviceItems];
};

interface ExtensionProductDetails {
	productId: string;
	vatPercent: number;
	name: LocaleField;
	amount: AmountObject;
	stockProductId: string;
	taxExcluded: boolean;
}

export const getItemsForExtendPayment = (
	products: ExtensionProductDetails[],
	extensionDuration: ExtensionDuration,
	t: TFunction,
): ItemObject[] => {
	const durationString = getExtensionDurationShortString(extensionDuration, t, '+');

	return products.map((product) => {
		const total = product.amount.value;
		const taxDetails = getTaxDetails(total, {
			rate: getTaxRateFromVatPercent(product.vatPercent),
			taxExcluded: false,
		});
		const subtotal = product.taxExcluded ? total - taxDetails.totalTaxes : total;
		const listPrice = subtotal;
		return {
			name: getTranslation(product.name, 'def'),
			durationString,
			units: 1,
			amount: product.amount,
			vatPercent: product.vatPercent,
			productId: product.productId,
			stockProductId: product.stockProductId,
			pricing: {
				total,
				currency: product.amount.currency,
				taxLines: taxDetails.taxLines,
				totalTaxes: taxDetails.totalTaxes,
				listPrice,
				originalListPrice: listPrice,
				subtotal,
				taxExcluded: product.taxExcluded,
				totalDiscounts: 0,
			},
		};
	});
};

export const getItemsFromOrderProducts = (
	orderProducts: OrderProduct[],
	paidRatio: number,
	t: TFunction,
): ItemObject[] => {
	return orderProducts.map((product) => {
		const chargePayment = getItemsPayments(product).charge;
		const unpaid = chargePayment.unpaid;
		const total = Math.round(unpaid * paidRatio);

		const taxLines = product.pricing.taxLines.flatMap((line) => {
			const taxDetails = getTaxDetails(total, {
				label: line.label,
				rate: line.rate,
				taxExcluded: false,
			});
			return taxDetails.taxLines;
		});
		const totalTaxes = getTotalTaxes(taxLines);
		const manualDiscount = !product.pricing.manualDiscount
			? undefined
			: Math.round(product.pricing.manualDiscount * paidRatio);
		const discountCodes = !product.pricing.discountCodes
			? undefined
			: Object.entries(product.pricing.discountCodes).reduce((prev, [code, obj]) => {
					return {
						...prev,
						[code]: {
							...obj,
							totalDiscountValue: obj.totalDiscountValue * paidRatio,
						},
					};
			  }, {} as AppliedDiscountCodes);
		const totalDiscounts =
			(manualDiscount ?? 0) +
			(discountCodes ? getTotalDiscountFromAppliedDiscountCodes(discountCodes) : 0);
		const subtotal = product.pricing.taxExcluded ? total - totalTaxes : total;
		const listPrice = subtotal + totalDiscounts;

		const durationString = (() => {
			switch (product.purchaseType) {
				case PurchaseTypes.rental: {
					return (
						getDurationString(
							{
								durationInSeconds: product.rentalDurationInSeconds || 0,
								durationType: product.durationType,
								durationName: null,
							},
							'short',
							'en',
							t,
						) || null
					);
				}
				case PurchaseTypes.subscription: {
					if (!product.subscription) return null;
					return getSubscriptionDurationString(product.subscription, t, 'short');
				}
				case PurchaseTypes.sales:
				default:
					return null;
			}
		})();

		return {
			name: getTranslation(product.name, 'def'),
			durationString,
			units: 1,
			pricing: {
				total,
				currency: product.pricing.currency,
				...(manualDiscount && { manualDiscount }),
				...(discountCodes && { discountCodes }),
				totalDiscounts,
				taxExcluded: product.pricing.taxExcluded,
				totalTaxes,
				taxLines,
				listPrice,
				originalListPrice: listPrice,
				subtotal,
			},
			productId: product.id,
			stockProductId: product.productApiId,
		};
	});
};

export const getItemsFromSubscriptionOrderProducts = (
	orderProducts: OrderProduct[],
	t: TFunction,
): ItemObject[] => {
	return orderProducts
		.filter((p) => isSubscriptionPurchaseType(p.purchaseType))
		.map((product) => {
			const total = product.pricing.total;

			const taxLines = product.pricing.taxLines.flatMap((line) => {
				const taxDetails = getTaxDetails(total, {
					label: line.label,
					rate: line.rate,
					taxExcluded: false,
				});
				return taxDetails.taxLines;
			});
			const totalTaxes = getTotalTaxes(taxLines);
			const subtotal = product.pricing.taxExcluded ? total - totalTaxes : total;
			const listPrice = subtotal;

			const durationString = !!product.subscription
				? getSubscriptionDurationString(product.subscription, t, 'short')
				: null;

			return {
				name: getTranslation(product.name, 'def'),
				durationString,
				units: 1,
				pricing: {
					total,
					currency: product.pricing.currency,
					totalDiscounts: 0,
					taxExcluded: product.pricing.taxExcluded,
					totalTaxes,
					taxLines,
					listPrice,
					originalListPrice: listPrice,
					subtotal,
				},
				productId: product.id,
				stockProductId: product.productApiId,
			};
		});
};

export const getItemFromOrderDelivery = (
	orderDelivery: OrderDelivery,
	paidRatio: number,
): ItemObject => {
	const service = orderDelivery;
	const chargePayment = getOrderDeliveryPayments(service).charge;
	const unpaid = chargePayment.unpaid;
	const total = Math.round(unpaid * paidRatio);
	const taxLines = service.pricing.taxLines.flatMap((line) => {
		const taxDetails = getTaxDetails(total, {
			label: line.label,
			rate: line.rate,
			taxExcluded: false,
		});
		return taxDetails.taxLines;
	});
	const totalTaxes = getTotalTaxes(taxLines);
	const manualDiscount = !service.pricing.manualDiscount
		? undefined
		: Math.round(service.pricing.manualDiscount * paidRatio);
	const discountCodes = !service.pricing.discountCodes
		? undefined
		: Object.entries(service.pricing.discountCodes).reduce((prev, [code, obj]) => {
				return {
					...prev,
					[code]: {
						...obj,
						totalDiscountValue: obj.totalDiscountValue * paidRatio,
					},
				};
		  }, {} as AppliedDiscountCodes);
	const totalDiscounts =
		(manualDiscount ?? 0) +
		(discountCodes ? getTotalDiscountFromAppliedDiscountCodes(discountCodes) : 0);
	const subtotal = service.pricing.taxExcluded ? total - totalTaxes : total;
	const listPrice = subtotal + totalDiscounts;
	return {
		name: getTranslation(service.name, 'def'),
		durationString: null,
		units: null,
		pricing: {
			total,
			currency: service.pricing.currency,
			...(manualDiscount && { manualDiscount }),
			...(discountCodes && { discountCodes }),
			totalDiscounts,
			taxExcluded: service.pricing.taxExcluded,
			totalTaxes,
			taxLines,
			listPrice,
			originalListPrice: listPrice,
			subtotal,
		},
		deliveryId: service.id,
		productId: null,
		stockProductId: null,
	};
};

export const getItemsForDeposit = (
	amount: AmountObject,
	vatPercent: number,
	taxExcluded: boolean,
): ItemObject[] => {
	const total = amount.value;
	const taxDetails = getTaxDetails(total, {
		rate: getTaxRateFromVatPercent(vatPercent),
		taxExcluded: false,
	});
	const subtotal = taxExcluded ? total - taxDetails.totalTaxes : total;
	const listPrice = subtotal;
	return [
		{
			name: `Deposit`,
			durationString: null,
			units: null,
			productId: null,
			stockProductId: null,
			pricing: {
				total: amount.value,
				currency: amount.currency,
				taxLines: taxDetails.taxLines,
				totalTaxes: taxDetails.totalTaxes,
				listPrice,
				originalListPrice: listPrice,
				subtotal,
				taxExcluded,
				totalDiscounts: 0,
			},
		},
	];
};

export const getItemsForCardAuth = (
	amount: AmountObject,
	vatPercent: number,
	taxExcluded: boolean,
): ItemObject[] => {
	const total = amount.value;
	const taxDetails = getTaxDetails(total, {
		rate: getTaxRateFromVatPercent(vatPercent),
		taxExcluded: false,
	});
	const subtotal = taxExcluded ? total - taxDetails.totalTaxes : total;
	const listPrice = subtotal;
	return [
		{
			name: `Rentle card authorisation`,
			durationString: null,
			units: null,
			productId: null,
			stockProductId: null,
			pricing: {
				total: amount.value,
				currency: amount.currency,
				taxLines: taxDetails.taxLines,
				totalTaxes: taxDetails.totalTaxes,
				listPrice,
				originalListPrice: listPrice,
				subtotal,
				taxExcluded,
				totalDiscounts: 0,
			},
		},
	];
};

export const getItemsForCapture = (
	originalPaymentData: PaymentApi,
	captureAmount: AmountObject,
	captureDescription?: string,
): ItemObject[] => {
	const captureItems: ItemObject[] = [];
	const originalItems = originalPaymentData.items;
	const totalPaymentValue = originalPaymentData.amount.value || 0;
	const totalCaptureValue = captureAmount.value || 0;
	let remainingSumFromCapture = totalCaptureValue;
	for (const [index, item] of originalItems.entries()) {
		const itemTotalValue = item.pricing.total;
		let itemCapturePart = totalPaymentValue
			? Math.round(totalCaptureValue * (itemTotalValue / totalPaymentValue))
			: 0;
		remainingSumFromCapture -= itemCapturePart;
		const lastItem = index === originalItems.length - 1;
		if (lastItem) {
			itemCapturePart += remainingSumFromCapture;
		}
		if (itemCapturePart !== 0) {
			const total = itemCapturePart;
			const taxLines = item.pricing.taxLines.flatMap((line) => {
				const taxDetails = getTaxDetails(total, {
					label: line.label,
					rate: line.rate,
					taxExcluded: false,
				});
				return taxDetails.taxLines;
			});
			const totalTaxes = getTotalTaxes(taxLines);
			const subtotal = item.pricing.taxExcluded ? total - totalTaxes : total;
			const listPrice = subtotal;
			captureItems.push({
				name: captureDescription || item.name,
				durationString: null,
				units: item.units,
				productId: item.productId,
				stockProductId: item.stockProductId,
				pricing: {
					total,
					currency: captureAmount.currency,
					taxLines: taxLines,
					totalTaxes,
					listPrice,
					originalListPrice: listPrice,
					subtotal,
					taxExcluded: item.pricing.taxExcluded,
					totalDiscounts: 0,
				},
			});
		}
	}
	return captureItems;
};

export const getItemsForRefund = (
	originalPaymentData: PaymentApi,
	refundAmount: AmountObject,
): ItemObject[] => {
	const refundItems: ItemObject[] = [];
	const originalItems = originalPaymentData.items;
	const totalPaymentValue = originalPaymentData.amount.value || 0;
	const totalRefundValue = refundAmount.value || 0;
	let remainingSumFromRefund = totalRefundValue;
	for (const [index, item] of originalItems.entries()) {
		const itemTotalValue = item.pricing.total;
		let itemRefundPart = totalPaymentValue
			? Math.round(totalRefundValue * (itemTotalValue / totalPaymentValue))
			: 0;
		remainingSumFromRefund -= itemRefundPart;
		const lastItem = index === originalItems.length - 1;
		if (lastItem) {
			itemRefundPart += remainingSumFromRefund;
		}
		if (itemRefundPart !== 0) {
			const total = itemRefundPart;
			const taxLines = item.pricing.taxLines.flatMap((line) => {
				const taxDetails = getTaxDetails(total, {
					label: line.label,
					rate: line.rate,
					taxExcluded: false,
				});
				return taxDetails.taxLines;
			});
			const totalTaxes = getTotalTaxes(taxLines);
			const subtotal = item.pricing.taxExcluded ? total - totalTaxes : total;
			const listPrice = subtotal;
			refundItems.push({
				name: item.name,
				durationString: item.durationString,
				units: item.units,
				productId: item.productId ?? null,
				stockProductId: item.stockProductId ?? null,
				deliveryId: item.deliveryId,
				pricing: {
					total,
					currency: refundAmount.currency,
					taxLines: taxLines,
					totalTaxes,
					listPrice,
					originalListPrice: listPrice,
					subtotal,
					taxExcluded: item.pricing.taxExcluded,
					totalDiscounts: 0,
				},
			});
		}
	}
	return refundItems;
};

export const createRefundPaymentObjects = (
	data: ModifyPaymentRequest,
): {
	payment: PaymentApi;
	transactions: TransactionApi[];
	receipt: ReceiptApi;
} => {
	const {
		originalPaymentId,
		productIds,
		originalTransactionId,
		originalPspReference,
		amount,
		shopId,
		orderId,
		live,
		receiptNumber,
		shopperId,
		channel,
		shopReceiptInfo,
		transactionsInfo,
		items,
		capture,
		paymentAction,
		paymentType,
		locationId,
		originalReceiptNumber,
	} = data;
	const currentDate = new Date().toISOString();
	const receiptId: string = newFirestoreId();
	const newPayment: PaymentApi = {
		id: newFirestoreId(),
		orderProductIds: productIds,
		transactionIds: transactionsInfo.map((t) => t.transactionId),
		items,
		orderId,
		locationId,
		shopperId: shopperId || null,
		shopId,
		channel,
		methods: [...new Set(transactionsInfo.map((t) => t.method))],
		paymentType,
		amount: {
			value: amount.value,
			currency: amount.currency,
			paid: amount.value,
			captured: amount.value,
			authorised: amount.value,
		},
		originalPaymentId,
		timestamp: currentDate,
		completed: capture,
		live,
		usageDate: currentDate,
		receiptId,
		receiptNumber,
		capture,
		paymentAction,
		state: getPaymentStateFromAction(paymentAction),
	};
	const transactions: TransactionApi[] = [];
	for (const transactionInfo of transactionsInfo) {
		const {
			transactionId,
			amount: transactionAmount,
			method,
			pspReference,
			provider,
			result,
			splitData,
		} = transactionInfo;
		const fee = getFeeFromSplitData(splitData || null);
		const newTransaction: TransactionApi = {
			id: transactionId,
			success: true,
			pspReference,
			timestamp: currentDate,
			shopperId: shopperId || null,
			shopId,
			amount: {
				...transactionAmount,
				paid: transactionAmount.value,
				captured: transactionAmount.value,
				authorised: transactionAmount.value,
			},
			status: 'SUCCESSFUL',
			paymentType,
			method,
			cardOnFile: false,
			cardToken: null,
			paymentId: newPayment.id,
			originalTransactionId,
			orderId,
			live,
			capture,
			events: [
				{
					code: paymentAction,
					timestamp: currentDate,
				},
			],
			refund: null,
			refunds: [],
			errorCondition: null,
			errorMessage: null,
			details: result || null,
			provider,
			splitData: splitData,
			fee,
			paymentAction,
			state: getPaymentStateFromAction(paymentAction),
			...(originalPspReference !== undefined && { originalReference: originalPspReference }),
		};
		transactions.push(newTransaction);
	}
	const receipt: ReceiptApi = {
		id: receiptId,
		shopId,
		orderId,
		paymentId: newPayment.id,
		receiptNumber,
		items,
		timestamp: currentDate,
		transactionsInfo: transactions.map((t) => ({
			method: t.method,
			methodDetails: null,
			pspReference: t.pspReference,
			amount: t.amount,
			transactionId: t.id,
		})),
		shopInfo: shopReceiptInfo,
		capture,
		type: 'refund',
		originalReceiptNumber,
	};
	return {
		payment: newPayment,
		transactions,
		receipt,
	};
};

export const isOnlinePaymentsInUse = (activePaymentMethods: ShopOnlinePaymentMethodObject[]) => {
	return activePaymentMethods.map((m) => m.id).some((m) => isOnlineProviderPaymentMethod(m));
};

export const isRentlePaymentsInUse = (activePaymentMethods: ShopOnlinePaymentMethodObject[]) => {
	return activePaymentMethods.map((m) => m.id).some((m) => isAdyenPaymentMethod(m));
};

export const getPaymentModificationState = (
	transaction: TransactionApi | PaymentApi,
): { modificationState: PaymentState | null; possibleActions: PaymentAction[] } => {
	const { amount, paymentAction } = transaction;
	const { value, captured, cancelled, refunded, pendingCaptures, pendingRefunds } = amount;
	switch (paymentAction) {
		case 'AUTHORISATION':
			if (!!captured && captured > (refunded || 0)) {
				return {
					modificationState: captured - (refunded || 0) === value ? 'CAPTURE' : 'PARTIAL_CAPTURE',
					possibleActions: ['REFUND'],
				};
			}
			if (!!captured && captured <= (refunded ?? 0)) {
				return {
					modificationState: 'REFUND',
					possibleActions: [],
				};
			}
			if (!!cancelled) {
				return { modificationState: 'CANCEL', possibleActions: [] };
			}
			if (!!Object.keys(pendingCaptures || {}).length) {
				return { modificationState: 'CAPTURE_PENDING', possibleActions: [] };
			}
			if (!!Object.keys(pendingRefunds || {}).length) {
				return { modificationState: 'REFUND_PENDING', possibleActions: [] };
			}
			return { modificationState: null, possibleActions: ['CAPTURE', 'CANCEL'] };
		case 'CAPTURE':
		case 'PAYMENT':
			if (!!refunded) {
				const fullRefund = refunded === captured;
				return {
					modificationState: fullRefund ? 'REFUND' : 'PARTIAL_REFUND',
					possibleActions: fullRefund ? [] : ['REFUND'],
				};
			}
			if (!!Object.keys(pendingRefunds || {}).length) {
				return { modificationState: 'REFUND_PENDING', possibleActions: ['REFUND'] };
			}
			return { modificationState: null, possibleActions: ['REFUND'] };
		case 'REFUND':
		case 'CANCEL':
		default:
			return { modificationState: null, possibleActions: [] };
	}
};

const functionsBaseUrl = getFunctionsBaseUrl(process.env.REACT_APP_ENV as Environment);

export const getPaymentReturnUrl = (params: { transactionId: string; redirectUrl: string }) =>
	`${functionsBaseUrl}/api/v1/transaction-redirect/${
		params.transactionId
	}?redirectUrl=${encodeURIComponent(params.redirectUrl)}`;
