import React from 'react';

import { Box, BoxProps, Button } from '@mui/material';

import HasFeature from 'components/features/HasFeature';
import LockedButton from 'components/features/LockedButton';
import { t } from 'services/localization';

interface UninstallButtonProps extends BoxProps {
	onClick: () => void;
}

const UninstallButton = (props: UninstallButtonProps) => {
	const { onClick, ...boxProps } = props;
	return (
		<Box {...boxProps}>
			<HasFeature
				requiredFeature="ADD_ONS"
				fallback={
					<LockedButton variant="contained" color="error" fullWidth>
						{t('account.addOns.installedAddOns.uninstall', 'Uninstall')}
					</LockedButton>
				}
			>
				<Button variant="contained" color="error" onClick={onClick} fullWidth>
					{t('account.addOns.installedAddOns.uninstall', 'Uninstall')}
				</Button>
			</HasFeature>
		</Box>
	);
};

export default UninstallButton;
