import { useCallback, useState } from 'react';

type Validator<T> = (value: T) => string | undefined;

export interface FormFieldProps<T> {
	value: T;
	error: string | undefined;
	setError: (error: string | undefined) => void;
	handleChange: (value: any) => void;
	triggerReset: () => void;
	triggerValidate: () => string | undefined;
	isDirty: boolean;
}

export type Form<T> = {
	[K in keyof T]: FormFieldProps<T[K]>;
};
interface ValidateProps<T> {
	errors: string[];
	values: T;
}

export const validateForm = <T extends object>(
	form: Form<T>,
	keysToValidate?: Array<keyof T>,
): ValidateProps<T> => {
	const keys = keysToValidate ?? (Object.keys(form) as Array<keyof T>);
	return keys.reduce(
		(result: ValidateProps<T>, field) => {
			const error = form[field]!.triggerValidate();
			return {
				values: {
					...result.values,
					[field]: form[field]!.value,
				},
				errors: error ? [...result.errors, error] : result.errors,
			};
		},
		{
			values: {} as T,
			errors: [],
		},
	);
};

export const resetFormErrors = <T extends object>(form: Form<T>, keysToReset?: Array<keyof T>) => {
	const keys = keysToReset ?? (Object.keys(form) as Array<keyof T>);
	keys.forEach((field) => {
		form[field]!.setError(undefined);
	});
};

const useFormField = <T>(initialValue: T, validator: Validator<T>): FormFieldProps<T> => {
	const [value, setValue] = useState(initialValue);
	const [error, setError] = useState<string>();

	const triggerReset = useCallback(() => {
		setValue(initialValue);
		setError(undefined);
	}, [initialValue]);

	const triggerValidate = useCallback(() => {
		if (validator) {
			const err = validator(value);
			setError(err);
			return err;
		}
		return;
	}, [value, validator]);

	const parseValue = useCallback((eventOrValue: T | React.ChangeEvent<HTMLInputElement>) => {
		if (typeof eventOrValue === 'object' && !!eventOrValue && 'target' in eventOrValue) {
			return eventOrValue.target.value as T;
		}
		return eventOrValue;
	}, []);

	const handleChange = useCallback(
		(_value: T | React.ChangeEvent<HTMLInputElement>) => {
			const parsedValue = parseValue(_value);
			setValue(parsedValue);
			if (Boolean(error) && Boolean(validator)) {
				setError(validator(parsedValue));
			}
		},
		[error, validator, parseValue],
	);

	return {
		value,
		error,
		setError,
		handleChange,
		triggerReset,
		triggerValidate,
		isDirty: value !== initialValue,
	};
};

export default useFormField;
