import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { updateToNewOrderProductType } from 'common/api/dataUpdates';
import { buildQuery, createBasicDbOperations } from '../utils';
import { OrderProduct } from 'common/types';

export const getOrderProductQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.orderProducts;
	const dataUpdater = updateToNewOrderProductType;
	const query = buildQuery<OrderProduct>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<OrderProduct>(collectionPath, dbRefs, dataUpdater),
		byOrderId: (orderId: string) => query.where('rentalId', '==', orderId),
	};
};
