import { updateToNewShopAnalyticsType } from 'common/api/dataUpdates';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { ShopAnalytics } from 'common/services/analytics/tractionAnalytics';

import { buildQuery, createBasicDbOperations } from '../utils';

export const getShopAnalyticsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.shopAnalyticsCollection;
	const dataUpdater = updateToNewShopAnalyticsType;
	const query = buildQuery<ShopAnalytics>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<ShopAnalytics>(collectionPath, dbRefs, dataUpdater),
	};
};
