import { getOpenApiExample } from 'common/utils/openApi';

import { zItemPricingApi } from './api';
import { toApi, toDb } from './mapper';

export const ItemPricing = {
	api: zItemPricingApi,
	toApi,
	toDb,
	example: getOpenApiExample(zItemPricingApi),
};
