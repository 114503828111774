import { Session } from 'common/services/analytics/tractionAnalytics';
import { AccessClaims, ProductType, ShopPublicInfo, UserApi } from 'common/types';

import { CallableCreator } from '..';

export interface RegisterShopArgs {
	shopType: ProductType;
	uid: string;
	email: string;
	termsVersionAccepted: number;
	shopInfo: Partial<ShopPublicInfo>;
	timeZone: string;
	affiliateId: string | undefined;
	promotionCode: string | undefined;
	sessions?: {
		initial: Session | null;
		signup: Session | null;
	};
}

export interface RegisterShopResult {
	shopId: string;
	shopAnalyticsDocId: string;
}

export interface DeleteUserFromShopArgs {
	shopId: string;
	userId: string;
}

export interface InviteUsersToShopArgs {
	shopId: string;
	emails: string[];
	accessClaims: AccessClaims;
}

export interface InviteUsersToShopResult {
	created: UserApi[];
	failed: string[];
}

export interface SendInvitationLinkArgs {
	email: string;
}

export interface SendPasswordResetLinkArgs {
	email: string;
}

export interface SetUserAccessArgs {
	shopId: string;
	userId: string;
	accessClaims: AccessClaims;
}
export interface GetUserInfoFromIPResult {
	country?: string | null;
	region?: string | null;
	city?: string | null;
	cityLatLong?: { lat: string; long: string } | null;
}

export const users = (createCallable: CallableCreator) => ({
	deleteUserFromShop: createCallable<DeleteUserFromShopArgs, void>(
		'users-functions-deleteUserFromShop',
	),
	inviteUsersToShop: createCallable<InviteUsersToShopArgs, InviteUsersToShopResult>(
		'users-functions-inviteUsersToShop',
	),
	sendInvitationLink: createCallable<SendInvitationLinkArgs, void>(
		'users-functions-sendInvitationLink',
	),
	sendPasswordResetLink: createCallable<SendPasswordResetLinkArgs, void>(
		'users-functions-sendPasswordResetLink',
	),
	setUserAccess: createCallable<SetUserAccessArgs, void>('users-functions-setUserAccess'),
	registerShop: createCallable<RegisterShopArgs, RegisterShopResult>(
		'users-functions-registerShop',
	),
	getUserInfoFromIP: createCallable<void, GetUserInfoFromIPResult>(
		'users-functions-getUserInfoFromIP',
	),
});
