import { ItemPricing, OrderPricing } from 'common/modules/atoms/pricing';
import { getTaxDetails, getTaxRateFromVatPercent } from 'common/modules/atoms/taxes';
import { getTotalDiscountFromAppliedDiscountCodes } from 'common/modules/discountCodes';
import { getOrderPricing } from 'common/modules/orders';
import {
	AmountObject,
	AppliedDiscountCodes,
	DepositType,
	Discount,
	ItemObject,
	OrderDelivery,
	OrderDeliveryDetails,
	OrderDeliveryService,
	OrderInfo,
	OrderObject,
	OrderProduct,
	PaymentApi,
	ReceiptApi,
	Shopper,
	TransactionApi,
} from 'common/types';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';

export const mapToNewPaymentType = (payment: PaymentApi): PaymentApi => {
	return {
		...payment,
		items: payment.items.map((item) => mapToNewItemType(item)),
	};
};

export const mapToNewReceiptTypes = (data: ReceiptApi): ReceiptApi => {
	return {
		...data,
		items: data.items.map((i) => mapToNewItemType(i)),
	};
};

export const mapToNewTransactionType = (transaction: TransactionApi): TransactionApi => {
	return {
		...transaction,
		fee: transaction.fee ? mapToNewItemType(transaction.fee) : transaction.fee,
	};
};

const isNewItem = (item: ItemObject_LEGACY | ItemObject): item is ItemObject => {
	if (!!(item as ItemObject).pricing) return true;
	return false;
};

export const mapToNewItemType = (item: ItemObject_LEGACY | ItemObject): ItemObject => {
	if (isNewItem(item)) return item;
	const total = (item.amount ? item.amount.value : item.price) || 0;
	const taxDetails = getTaxDetails(total, {
		taxExcluded: false,
		rate: getTaxRateFromVatPercent(item.vatPercent),
	});
	const subtotal = total;
	const listPrice = subtotal;
	return {
		...item,
		pricing: {
			total,
			subtotal,
			listPrice,
			totalTaxes: taxDetails.totalTaxes,
			taxLines: taxDetails.taxLines,
			totalDiscounts: 0,
			originalListPrice: listPrice,
			taxExcluded: false,
			currency: item.amount?.currency ?? getDefaultCurrencyObject().code,
		},
	};
};

export const mapToPricingSupportedOrderInfo = (
	order: OrderInfo | OrderInfo_LEGACY,
	products?: (OrderProduct | OrderProduct_LEGACY)[],
	orderDelivery?: OrderDelivery | OrderDeliveryService_LEGACY,
): OrderInfo => {
	const isNewOrder = (order: OrderInfo | OrderInfo_LEGACY): order is OrderInfo => {
		return !!(order as OrderInfo).pricing;
	};
	if (isNewOrder(order)) {
		return order;
	}
	if (!!products && !!products.length) {
		const newProducts = products.map((p) => mapToPricingSupportedProduct(p));
		const newOrderDelivery = !!orderDelivery
			? mapToPricingSupportedOrderDelivery(orderDelivery, order.charge.currency)
			: undefined;

		const orderPricing = getOrderPricing(newProducts, newOrderDelivery, {
			taxExcluded: false,
			depositInfo: {
				type: order.deposit.type,
				description: order.deposit.description,
				additionalInfo: order.deposit.additionalInfo,
			},
			fallbackCurrency: order.charge.currency,
		});
		return {
			...order,
			pricing: orderPricing,
		};
	} else {
		const charge = order.charge;
		const deposit = order.deposit;
		const totalDiscountsFromCodes = order.appliedDiscountCodes
			? getTotalDiscountFromAppliedDiscountCodes(order.appliedDiscountCodes)
			: 0;
		// This is not getting the right discount, order has not had discount. But used for this edge migration need
		const totalManualDiscounts = order.discount?.amount || 0;
		const totalPrice = charge.amount;
		const subtotal = totalPrice;
		const pricing: OrderPricing = {
			currency: charge.currency,
			total: totalPrice,
			totalDiscounts: totalDiscountsFromCodes + totalManualDiscounts,
			totalTaxes: 0,
			subtotal,
			taxExcluded: false,
			taxLines: [],
			manualDiscount: { amount: totalManualDiscounts },
			deposit: {
				value: deposit.amount,
				type: deposit.type,
				description: deposit.description,
				additionalInfo: deposit.additionalInfo,
			},
			...(charge.additionalInfo && { additionalInfo: charge.additionalInfo }),
			discountCodes: order.appliedDiscountCodes,
		};
		return {
			...order,
			pricing,
		};
	}
};

export const mapToPricingSupportedProduct = (
	_product: OrderProduct | OrderProduct_LEGACY,
): OrderProduct => {
	const isNewProduct = (product: OrderProduct | OrderProduct_LEGACY): product is OrderProduct => {
		return !!(product as OrderProduct).pricing;
	};
	if (isNewProduct(_product)) {
		return _product;
	}
	const product = _product as OrderProduct_LEGACY;
	const charge = product.charge;
	const deposit = product.deposit;
	const totalDiscountsFromCodes = product.appliedDiscountCodes
		? getTotalDiscountFromAppliedDiscountCodes(product.appliedDiscountCodes)
		: 0;
	const totalManualDiscounts = charge.discount?.amount || 0;
	const totalPrice = Math.max(0, charge.amount - totalDiscountsFromCodes - totalManualDiscounts);
	const { taxLines, totalTaxes } = getTaxDetails(totalPrice, {
		rate: getTaxRateFromVatPercent(product.vatPercent),
		taxExcluded: false,
	});
	const subtotal = totalPrice;
	const pricing: ItemPricing = {
		currency: charge.currency,
		total: totalPrice,
		totalDiscounts: totalDiscountsFromCodes + totalManualDiscounts,
		totalTaxes,
		listPrice: charge.amount,
		originalListPrice:
			typeof charge.originalAmount === 'number' ? charge.originalAmount : charge.amount,
		...(charge.additionalInfo && { additionalInfo: charge.additionalInfo }),
		subtotal,
		taxExcluded: false,
		taxLines,
		manualDiscount: totalManualDiscounts,
		deposit: deposit?.amount ?? 0,
		discountCodes: product.appliedDiscountCodes,
	};
	return {
		...product,
		pricing,
	};
};

const mapToNewOrderDeliveryDetails = (
	details: OrderDeliveryDetails_LEGACY | OrderDeliveryDetails,
	vatPercent: number,
	currency: string,
) => {
	const isNewOrderDeliveryDetails = (
		details: OrderDeliveryDetails_LEGACY | OrderDeliveryDetails,
	): details is OrderDeliveryDetails => {
		return !!(details as OrderDeliveryDetails).pricing;
	};
	if (isNewOrderDeliveryDetails(details)) {
		return details;
	}
	const price = details.price;
	const { taxLines, totalTaxes } = getTaxDetails(price ?? 0, {
		rate: getTaxRateFromVatPercent(vatPercent),
		taxExcluded: false,
	});
	const subtotal = price;
	const pricing: ItemPricing = {
		currency,
		total: price,
		totalDiscounts: 0,
		totalTaxes,
		listPrice: subtotal,
		originalListPrice: subtotal,
		subtotal,
		taxExcluded: false,
		taxLines,
	};
	return {
		...details,
		pricing,
	};
};

export const mapToPricingSupportedOrderDelivery = (
	delivery: OrderDelivery | OrderService_LEGACY,
	currency: string,
): OrderDelivery => {
	const isNewOrderDelivery = (
		delivery: OrderDelivery | OrderService_LEGACY,
	): delivery is OrderDelivery => {
		return !!(delivery as OrderDelivery).pricing;
	};
	if (isNewOrderDelivery(delivery)) {
		return delivery;
	}
	const price = delivery.price;
	const vatPercent = delivery.vatPercent;
	const { taxLines, totalTaxes } = getTaxDetails(price ?? 0, {
		rate: (vatPercent ?? 24) / 100,
		taxExcluded: false,
	});
	const subtotal = price;
	const pricing: ItemPricing = {
		currency,
		total: price,
		totalDiscounts: 0,
		totalTaxes,
		listPrice: subtotal,
		originalListPrice: subtotal,
		subtotal,
		taxExcluded: false,
		taxLines,
	};
	return {
		...delivery,
		pricing,
		to: delivery.to
			? mapToNewOrderDeliveryDetails(delivery.to, vatPercent ?? 24, currency)
			: undefined,
		from: delivery.from
			? mapToNewOrderDeliveryDetails(delivery.from, vatPercent ?? 24, currency)
			: undefined,
	};
};

export interface OrderProduct_LEGACY extends Omit<OrderProduct, 'pricing' | 'deposit' | 'charge'> {
	deposit: PaymentObject;
	charge: PaymentObject;
	appliedDiscountCodes?: AppliedDiscountCodes;
}

export interface OrderDeliveryService_LEGACY
	extends Omit<OrderDeliveryService, 'to' | 'from' | 'pricing'> {
	price: number;
	vatPercent: number;
	to?: OrderDeliveryDetails_LEGACY;
	from?: OrderDeliveryDetails_LEGACY;
}

export interface OrderDeliveryDetails_LEGACY extends Omit<OrderDeliveryDetails, 'pricing'> {
	price: number;
}

export type OrderService_LEGACY = OrderDeliveryService_LEGACY;

export interface ItemObject_LEGACY {
	name: string;
	durationString: string | null;
	units: number | null;
	price?: number; // To be deprecated
	amount?: AmountObject;
	vatPercent: number;
	productId: string | null;
	discount?: number;
	stockProductId: string | null;
	type?: 'discount';
}

export interface OrderInfo_LEGACY extends Omit<OrderInfo, 'pricing' | 'deposit' | 'charge'> {
	deposit: DepositObject;
	charge: PaymentObject;
	appliedDiscountCodes?: AppliedDiscountCodes;
	discount?: Discount;
}
export interface DepositObject extends PaymentObject {
	type?: DepositType;
}
export interface PaymentObject {
	amount: number;
	currency: string;
	paid: number;
	discount?: Discount;
	originalAmount?: number | '';
	refunded?: number;
	authorised?: number;
	cancelled?: number;
	captured?: number;
	description?: string;
	pendingRefunds?: {
		[transactionId: string]: number;
	};
	failedRefunds?: {
		[transactionId: string]: number;
	};
	// value?: number; // To start taking this into use?
	pendingCaptures?: {
		[transactionId: string]: number;
	};
	failedCaptures?: {
		[transactionId: string]: number;
	};
	// Used to indicate special payment information, or deposit information
	additionalInfo?: string;
}
export interface OrderObject_LEGACY {
	rentalInfo: OrderInfo_LEGACY;
	products: OrderProduct_LEGACY[];
	shoppers: Shopper[];
	orderDelivery?: OrderService_LEGACY;
}

const isNewOrderObject = (order: OrderObject | OrderObject_LEGACY): order is OrderObject => {
	return (
		!!(order as OrderObject).rentalInfo.pricing &&
		(order as OrderObject).products.every((p) => !!p.pricing) &&
		!!(order as OrderObject)?.orderDelivery?.pricing
	);
};

export const mapToPricingSupportedOrderObject = (
	order: OrderObject | OrderObject_LEGACY,
): OrderObject => {
	if (isNewOrderObject(order)) {
		return order;
	}
	const newRentalInfo = mapToPricingSupportedOrderInfo(
		order.rentalInfo,
		order.products,
		order.orderDelivery,
	);
	return {
		...order,
		rentalInfo: newRentalInfo,
		products: order.products.map((p) => mapToPricingSupportedProduct(p)),
		orderDelivery: order.orderDelivery
			? mapToPricingSupportedOrderDelivery(order.orderDelivery, newRentalInfo.pricing.currency)
			: undefined,
	};
};
