import React from 'react';

import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';

export interface Props {
	index: number;
	title?: string;
}
const RegistrationRow: React.FC<React.PropsWithChildren<Props>> = ({ index, title, children }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<motion.div
			initial={{
				opacity: 0,
				transitionDuration: '0.3s',
			}}
			animate={{
				opacity: 1,
				transitionDuration: `0.3s`,
			}}
			variants={{
				hidden: {
					opacity: 0,
				},
				show: {
					transition: {
						staggerChildren: 0.3,
					},
				},
			}}
			key={`RegistrationRow_${index}`}
		>
			{!!title && (
				<Stack mx={isMobile ? 1 : 0}>
					<Typography variant="body2" mb={isMobile ? 2 : 1}>
						{title}
					</Typography>
				</Stack>
			)}
			<Grid container spacing={1}>
				{children}
			</Grid>
		</motion.div>
	);
};

export default RegistrationRow;
