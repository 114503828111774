import React from 'react';

import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link as RRLink, useLocation } from 'react-router-dom';

import {
	AddOn,
	addonRequiresManualSetup,
	getAddOnDetails,
	getLowestRequiredPlanForAddOn,
	getPlanName,
	isAddOnAvailableOnPlan,
	isAddOnInstalled,
} from 'common/modules/plans';
import AddOnLink from 'components/modals/AddOnModal/components/AddOnLink';
import BookDemoButton from 'components/modals/AddOnModal/components/BookDemoButton';
import InstallButton from 'components/modals/AddOnModal/components/InstallButton';
import UninstallButton from 'components/modals/AddOnModal/components/UninstallButton';
import { useAnalytics } from 'hooks/useAnalytics';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { Trans, useTranslation } from 'services/localization';
import { Routes, push } from 'routing';

interface Props {
	addOn: AddOn;
	onClose: () => void;
	onInstall: () => void;
	onUninstall: () => void;
	loading: boolean;
}
const AddOnModalInstallSection = (props: Props) => {
	const { addOn, onInstall, onUninstall, loading } = props;
	const { t } = useTranslation();
	const theme = useTheme();
	const location = useLocation();
	const { logAnalyticsEvent } = useAnalytics();

	const shopFeatures = useSelector(ShopSelectors.shopFeatures);
	const shopPlan = useSelector(ShopSelectors.shopPlan);

	const isIntegration = addonRequiresManualSetup(addOn);

	const isInstalled = !loading && isAddOnInstalled(addOn, shopFeatures);
	const canInstall = !!shopPlan && isAddOnAvailableOnPlan(shopPlan, addOn);
	const lowestRequiredPlan = getLowestRequiredPlanForAddOn(addOn);

	return (
		<Stack direction="column" justifyContent="space-between" spacing={2} flex={1}>
			<Box flex={1}>
				{!isInstalled && !canInstall && !!lowestRequiredPlan && (
					<Box mb={2}>
						<Typography sx={{ fontWeight: 400 }} variant="body2">
							{
								<Trans
									i18nKey="account.addOns.modal.addOnRequiresPlan"
									defaults="{{addOn}} is available on the <requiredPlan>{{requiredPlan}}</requiredPlan> plan. You are currently on <shopPlan>{{shopPlan}}</shopPlan>."
									values={{
										requiredPlan: getPlanName(lowestRequiredPlan),
										shopPlan: shopPlan ? getPlanName(shopPlan) : '',
										addOn: getAddOnDetails(addOn, t).name,
									}}
									components={{
										shopPlan: <span style={{ fontWeight: 500 }}></span>,
										requiredPlan: (
											<Link component={RRLink} to={Routes.PlanConfirm(lowestRequiredPlan)}></Link>
										),
									}}
								/>
							}
						</Typography>
					</Box>
				)}
				{isInstalled ? (
					<Box>
						<AddOnLink type="setup" addOn={addOn} mb={1.5} />
						<AddOnLink type="help-article" addOn={addOn} mb={1.5} />
					</Box>
				) : (
					<Box>
						<AddOnLink type="help-article" addOn={addOn} mb={1.5} />
						{!isIntegration && <AddOnLink type="book-demo" addOn={addOn} />}
					</Box>
				)}
			</Box>
			{isInstalled ? (
				<Box>
					<UninstallButton mt={8} onClick={onUninstall} />
				</Box>
			) : (
				<Box
					sx={{
						[theme.breakpoints.down('sm')]: {
							position: 'fixed',
							borderTop: (theme) => `1px solid ${theme.palette.divider}`,
							padding: theme.spacing(4),
							bottom: 0,
							left: 0,
							right: 0,
						},
					}}
				>
					{isIntegration ? (
						<BookDemoButton
							analytics={{
								block: addOn,
								action: 'book-demo',
							}}
						/>
					) : canInstall ? (
						<InstallButton loading={loading} onClick={onInstall} />
					) : (
						<Button
							variant="contained"
							onClick={() => {
								push(Routes.PLANS, { from: location.pathname });
								logAnalyticsEvent({
									name: 'feature_gate_click',
									params: { type: 'button', data: { restriction: 'plan', feature: undefined } },
								});
							}}
						>
							{t('common:actions.comparePlans', 'Compare plans')}
						</Button>
					)}
				</Box>
			)}
		</Stack>
	);
};

export default AddOnModalInstallSection;
