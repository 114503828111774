import React, { useCallback, useEffect, useState } from 'react';

interface BarCodeProps {
	children?: any;
	barCodeRead: (latestBarCodeString: string) => void;
	caseSensitive?: boolean;
}

let timeout: NodeJS.Timeout | null = null;

const BarCodeReader = (props: BarCodeProps) => {
	const [, setScannedString] = useState('');
	const { barCodeRead, caseSensitive } = props;

	const enterFunction = useCallback(
		(e: KeyboardEvent) => {
			setScannedString((prevState) => {
				if (e.keyCode === 13 && prevState !== '') {
					barCodeRead(prevState);
					timeout && clearTimeout(timeout);
					timeout = null;
					return '';
				} else {
					var inp = String.fromCharCode(e.keyCode);
					if (timeout) {
						clearTimeout(timeout);
						timeout = null;
					}
					timeout = setTimeout(() => {
						setScannedString('');
						timeout = null;
					}, 100);
					if (/[a-zA-Z0-9-_ ]/.test(inp)) {
						return prevState + (caseSensitive ? inp : inp.toUpperCase());
					}
					return prevState;
				}
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[barCodeRead],
	);

	useEffect(() => {
		window.addEventListener('keypress', enterFunction, false);
		return () => window.removeEventListener('keypress', enterFunction, false);
	}, [enterFunction]);

	return <>{props.children}</>;
};

export default BarCodeReader;
