import moment from 'moment-timezone';

const RESERVATION_DURATION_MINUTES = 20;

export const getReservationEndTime = (reservationCreated: moment.Moment | string) => {
	return moment(reservationCreated).add(RESERVATION_DURATION_MINUTES, 'minutes');
};

export const isReservationExpired = (reservationEndTime: moment.Moment) => {
	return moment(reservationEndTime).isBefore(moment());
};
