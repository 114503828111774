import { z } from 'zod';

import { TaxLine } from 'common/models/_atoms/TaxLine';
import { withOpenApi } from 'common/utils/openApi';

const zManualDiscount = withOpenApi(
	z.object({
		percentage: z.number().int().min(0).max(100).optional(),
		value: z.number().int().nonnegative(),
	}),
	{
		description: 'Applied manual discount.',
		fields: {
			percentage: 'Percentage of the discount, if discount was given as percentage',
			value: 'Discount value in minor units',
		},
	},
);

export const zOrderPricingApi = withOpenApi(
	z.object({
		currency: z.string(),
		total: z.number().int().nonnegative(),
		subtotal: z.number().int().nonnegative(),
		totalTaxes: z.number().int().nonnegative(),
		totalDiscounts: z.number().int().nonnegative(),
		taxExcluded: z.boolean(),
		taxLines: z.array(TaxLine.api),
		manualDiscount: zManualDiscount.optional(),
		discountCodes: withOpenApi(
			z.object({
				code: z.string(),
				quantity: z.number().int().nonnegative(),
				value: z.number().int().nonnegative(),
			}),
			{
				fields: {
					code: 'Discount code.',
					quantity: 'How many times the code was used.',
					value: 'Discount value in minor units.',
				},
			},
		)
			.array()
			.optional(),
		deposit: withOpenApi(
			z.object({
				value: z.number().int().nonnegative(),
				type: z.enum(['payment', 'other', 'no-deposit']),
			}),
			{
				fields: {
					value: 'Deposit value in minor units.',
					type: 'Deposit type.',
				},
			},
		).optional(),
	}),
	{
		fields: {
			total: 'Total price. Includes taxes and discounts.',
			currency: 'Currency of the price.',
			subtotal:
				'Subtotal price. Does not include taxes if pricing has taxes excluded, defined in `taxExcluded`.',
			totalTaxes: 'Total taxes.',
			totalDiscounts: 'Total discounts value.',
			taxExcluded: 'Whether taxes are excluded from the list prices.',
			taxLines: 'List of tax lines of the price.',
			manualDiscount: 'Applied manual discount.',
			discountCodes: 'List of discount codes.',
			deposit: 'Deposit information.',
		},
		example: {
			total: 100_00,
			currency: 'USD',
			subtotal: 100_00,
			totalTaxes: 24_00,
			totalDiscounts: 0,
			taxExcluded: false,
			taxLines: [
				{
					price: 24_00,
					rate: 24,
				},
			],
			deposit: {
				value: 0,
				type: 'payment',
			},
		},
	},
);
