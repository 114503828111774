import { TFunction } from 'react-i18next';

import { CustomContentSection } from 'common/modules/customization';
import {
	CustomCheckboxLabelTranslatable,
	CustomImageDescriptionTranslatable,
	CustomImageHeadingTranslatable,
	CustomTextBodyTranslatable,
	CustomTextHeadingTranslatable,
	CustomTextfieldLabelTranslatable,
	Modify,
	TranslatableField,
} from 'common/types';
import { switchUnreachable } from 'common/utils/common';

type CustomContentTranslatable = Modify<
	TranslatableField,
	{
		opts:
			| CustomImageHeadingTranslatable
			| CustomTextHeadingTranslatable
			| CustomImageDescriptionTranslatable
			| CustomTextBodyTranslatable
			| CustomTextfieldLabelTranslatable
			| CustomCheckboxLabelTranslatable;
	}
>;

const pageName = (pageSettings: 'about' | 'checkout') => {
	switch (pageSettings) {
		case 'about':
			return 'About page';

		case 'checkout':
			return 'Checkout page';

		default:
			return switchUnreachable(pageSettings);
	}
};

export const customContentTranslations = (
	sections: CustomContentSection[],
	themeId: string,
	page: 'about' | 'checkout',
	t: TFunction,
): CustomContentTranslatable[] => {
	const translatableContent: CustomContentTranslatable[] = [];

	sections.forEach((section, sectionIndex) => {
		section.content?.forEach((content, contentIndex) => {
			switch (content?.type) {
				case 'image':
					if (!!content.heading?.def) {
						translatableContent.push({
							id: `${sectionIndex}.${page}.customImage.${contentIndex}.image.heading`,
							label: t('translations.fields.customContent.imageHeading', {
								sectionIdx: sectionIndex + 1,
								contentIdx: contentIndex + 1,
								pageName: pageName(page),
								defaultValue:
									'{{pageName}}, section {{sectionIdx}}, custom content {{contentIdx}}, image heading',
							}),
							value: content.heading,
							opts: {
								type: 'customImageHeading',
								args: {
									themeId,
									sectionIndex,
									contentIndex,
									key: 'heading',
									page,
								},
							},
						});
					}
					if (!!content.description?.def) {
						translatableContent.push({
							id: `${sectionIndex}.${page}.customImage.${contentIndex}.image.description`,
							label: t('translations.fields.customContent.imageDescription', {
								sectionIdx: sectionIndex + 1,
								contentIdx: contentIndex + 1,
								pageName: pageName(page),
								defaultValue:
									'{{pageName}}, section {{sectionIdx}}, custom content {{contentIdx}}, image description',
							}),
							value: content.description,
							opts: {
								type: 'customImageDescription',
								args: {
									themeId,
									sectionIndex,
									contentIndex,
									key: 'description',
									page,
								},
							},
						});
					}
					break;
				case 'text':
					if (!!content.heading?.def) {
						translatableContent.push({
							id: `${sectionIndex}.${page}.customText.${contentIndex}.text.heading`,
							label: t('translations.fields.customContent.textHeading', {
								sectionIdx: sectionIndex + 1,
								contentIdx: contentIndex + 1,
								pageName: pageName(page),
								defaultValue:
									'{{pageName}}, section {{sectionIdx}}, custom content {{contentIdx}}, heading',
							}),
							value: content.heading,
							opts: {
								type: 'customTextHeading',
								args: {
									themeId,
									sectionIndex,
									contentIndex,
									key: 'heading',
									page,
								},
							},
						});
					}
					if (!!content.body?.def) {
						translatableContent.push({
							id: `${sectionIndex}.${page}.customText.${contentIndex}.text.body`,
							label: t('translations.fields.customContent.textBody', {
								sectionIdx: sectionIndex + 1,
								contentIdx: contentIndex + 1,
								pageName: pageName(page),
								defaultValue:
									'{{pageName}}, section {{sectionIdx}}, custom content {{contentIdx}}, body',
							}),
							value: content.body,
							textArea: true,
							opts: {
								type: 'customTextBody',
								args: {
									themeId,
									sectionIndex,
									contentIndex,
									key: 'body',
									page,
								},
							},
						});
					}
					break;

				case 'textfield':
					if (!!content.label?.def) {
						translatableContent.push({
							id: `${sectionIndex}.${page}.customTextfield.${contentIndex}.textfield.label`,
							label: t('translations.fields.customContent.textfieldLabel', {
								sectionIdx: sectionIndex + 1,
								contentIdx: contentIndex + 1,
								pageName: pageName(page),
								defaultValue:
									'{{pageName}}, section {{sectionIdx}}, custom content {{contentIdx}}, textfield label',
							}),
							value: content.label,
							textArea: true,
							opts: {
								type: 'customTextfieldLabel',
								args: {
									themeId,
									sectionIndex,
									contentIndex,
									key: 'label',
								},
							},
						});
					}
					break;

				case 'checkbox':
					if (!!content.label?.def) {
						translatableContent.push({
							id: `${sectionIndex}.${page}.customCheckbox.${contentIndex}.checkbox.label`,
							label: t('translations.fields.customContent.checkboxLabel', {
								sectionIdx: sectionIndex + 1,
								contentIdx: contentIndex + 1,
								pageName: pageName(page),
								defaultValue:
									'{{pageName}}, section {{sectionIdx}}, custom content {{contentIdx}}, checkbox label',
							}),
							value: content.label,
							textArea: true,
							opts: {
								type: 'customCheckboxLabel',
								args: {
									themeId,
									sectionIndex,
									contentIndex,
									key: 'label',
								},
							},
						});
					}
					break;
			}
		});
	});

	return translatableContent;
};
