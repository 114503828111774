import { range } from 'lodash';

/**
 * Get an array of searchable keywords from a string.
 *
 * See test cases for examples of the expected output.
 *
 * @param input An input string to extract keywords from
 * @param maxKeywords The maximum number of keywords to return (defaults to 10)
 *
 */
export const getKeywords = (input: string, maxKeywords: number = 10): string[] => {
	if (!input) return [];

	const sanitized = input
		.toLowerCase()
		.replace(', ', ' ')
		.split(' ')
		.map((word) => word.trim())
		.filter((word) => !!word)
		.join(' ');
	const words = sanitized.split(' ');

	if (words.length === 1) {
		return words;
	}

	const keywords = [sanitized, ...words];

	const groupSizes = range(2, words.length);

	for (const groupSize of groupSizes) {
		if (keywords.length >= maxKeywords) break;

		const wordGroups = range(0, words.length - groupSize + 1).map((i) =>
			words.slice(i, i + groupSize),
		);
		const keywordsFromGroups = wordGroups.map((g) => g.join(' '));

		for (const keyword of keywordsFromGroups) {
			if (keywords.length >= maxKeywords) break;

			keywords.push(keyword);
		}
	}

	const uniqueKeywords = [...new Set(keywords)];
	return uniqueKeywords.slice(0, maxKeywords);
};
