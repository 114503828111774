import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const LOCALIZATION_DEFINITION: Record<'LOCALIZATION', OnboardingStepDefinition> = {
	LOCALIZATION: {
		plans: 'all',
		versions: {
			all: ['TAX_RATES', 'REGIONAL_FORMATS', 'VERIFY_LANGUAGE'],
		},
	},
};
