import userflow from 'userflow.js';

import errorHandler from 'common/services/errorHandling/errorHandler';
import { Languages } from 'common/types';

import { newOnboardingActionCompletedEvent } from '../events';
import { FLOW_IDS } from './constants';

export type UserflowUser = {
	local_code: Languages;
	device_type: 'desktop' | 'mobile';
	useUserflowChecklist?: boolean;
	email?: string;
};

type UserflowFlowEvent = {
	endReason: 'ACTION' | 'USER_CLOSED' | 'USERFLOWJS';
	flow: {
		id: string;
		type: 'flow';
	};
};

export const initialize = () => {
	const completeLinkOnlineStoreFlow = (event: UserflowFlowEvent) => {
		if (event.flow.id === FLOW_IDS.LINK_ONLINE_STORE && event.endReason === 'ACTION') {
			newOnboardingActionCompletedEvent('link_online_store');
			userflow.off('flowEnded', completeLinkOnlineStoreFlow);
		}
	};
	try {
		const userflowToken = process.env.REACT_APP_USERFLOW_TOKEN;
		if (!userflowToken) {
			errorHandler.report('Userflow token is not defined');
			return;
		}
		userflow.init(userflowToken);
		userflow.setInferenceAttributeNames(['name', 'id', 'data-qa']);
		userflow.on('flowEnded', completeLinkOnlineStoreFlow);
	} catch (err) {
		errorHandler.report(`Error initializing userflow: ${err}`);
	}
};

export const identifyUser = (userId: string, properties: UserflowUser) => {
	try {
		userflow.identify(userId, properties);
	} catch (err) {
		errorHandler.report(`Error identifying userflow user: ${err}`);
	}
};

export const updateUser = (properties: Partial<UserflowUser>) => {
	try {
		userflow.updateUser(properties);
	} catch (err) {
		errorHandler.report(`Error updating userflow user: ${err}`);
	}
};

export const resetUser = () => {
	try {
		userflow.reset();
	} catch (err) {
		errorHandler.report(`Error reseting userflow user: ${err}`);
	}
};

export const trackEvent = (eventName: string, properties?: any) => {
	try {
		userflow.track(eventName, properties);
	} catch (err) {
		errorHandler.report(`Error tracking userflow event: ${err}`);
	}
};

export const startFlow = (flowId: string, once?: boolean) => {
	try {
		userflow.start(flowId, { once });
	} catch (err) {
		errorHandler.report(`Error starting userflow flow ${flowId}: ${err}`);
	}
};

export const setCustomNavigate = (navigate: (url: string) => void) => {
	try {
		userflow.setCustomNavigate(navigate);
	} catch (err) {
		errorHandler.report(`Error setting userflow custom navigate: ${err}`);
	}
};
