import { z } from 'zod';

export const zTimePeriodApi = z.enum([
	'minutes',
	'hours',
	'days',
	'weeks',
	'months',
	'days_within_opening_hours',
	'years',
]);
