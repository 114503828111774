import { getOpenApiExample } from 'common/utils/openApi';

import { zPersonApi } from './api';
import { toApi } from './mapper';

export const Person = {
	toApi,
	api: zPersonApi,
	example: getOpenApiExample(zPersonApi),
};
