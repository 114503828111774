import { BUILD_AND_ABOVE } from 'common/modules/plans/plans/constants';

import { AddOnDefinition, AddOns } from '../types';

const definition: AddOnDefinition = {
	addOn: AddOns.DISCOUNT_CODES,
	blockSlots: 1,
	requiresPlan: [...BUILD_AND_ABOVE],
	autoInstall: true,
};

export default definition;
