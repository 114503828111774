import moment from 'moment-timezone';

import {
	BILLING_CURRENCY,
	BillingCreditsBalance,
	getCreditsBalanceObject,
	getCreditsChange,
	getCreditsToUseAndBalanceAfter,
} from 'common/modules/billing';
import {
	PlanDefinitions,
	getIncludedStoreCount,
	getPurchasedPlanAdditionalStorePrice,
	getPurchasedPlanBasePrice,
	planHasRevenueDiscount,
} from 'common/modules/plans';
import { LocationSubscription } from 'common/modules/plans/subscriptions';
import { AmountObject, ISOString, ShopPublicInfo, ShopReadOnlyData } from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { multiplyPrice, sumPrices } from 'common/utils/pricing';
import { getShopLocations } from 'common/utils/shopUtils';

import {
	getNewLocationSubscription,
	getSubscriptionBillingCycle,
	getSubscriptionStartDiscount,
} from './utils';

export interface AddStorePriceBreakdown {
	subscription: LocationSubscription | null;
	subscriptionPrice: AmountObject;
	startDiscount: AmountObject;
	revenueDiscount: AmountObject;
	leftToPay: AmountObject;
	refundableAmount: AmountObject;
	creditsToUse: {
		refund: number;
		manual: number;
		total: number;
	};
	creditsBalanceAfter: BillingCreditsBalance;
	creditsBalanceChange: BillingCreditsBalance;
}

export const getAddStoreSubscriptionPriceBreakdown = (args: {
	shopPublicInfo: ShopPublicInfo;
	shopReadOnly: ShopReadOnlyData;
	date: ISOString;
}): AddStorePriceBreakdown => {
	const { shopPublicInfo, shopReadOnly, date } = args;

	const plan = shopReadOnly.features.plan;
	const planDefinition = PlanDefinitions[plan.plan];

	const locationIds = getShopLocations(shopPublicInfo).map((l) => l.id);
	const creditsBalance = getCreditsBalanceObject(shopReadOnly);

	const subscription = !!plan.subscription
		? getNewLocationSubscription({
				startDate: date,
				billingCycle: getSubscriptionBillingCycle(plan.subscription),
				currentSubscription: plan.subscription,
		  })
		: null;

	const subscriptionPrice = (() => {
		switch (planDefinition.billing.type) {
			case 'none':
				return null;
			case 'fixed-store-count':
				return null;
			case 'price-per-additional-store': {
				const currentStoreCount = locationIds.length;
				const includedStoreCount = getIncludedStoreCount({ purchasedPlan: plan });
				return currentStoreCount >= includedStoreCount
					? getPurchasedPlanAdditionalStorePrice(plan)
					: null;
			}
			case 'subscription-per-store': {
				return getPurchasedPlanBasePrice(plan);
			}
			default: {
				return switchUnreachable(planDefinition.billing);
			}
		}
	})()?.fixed ?? {
		currency: BILLING_CURRENCY.code,
		value: 0,
	};

	const hasRevenueDiscount = planHasRevenueDiscount(plan.plan);
	const subscriptionPriceWithRevenueDiscount = hasRevenueDiscount
		? multiplyPrice(subscriptionPrice, 0.5)
		: subscriptionPrice;

	const startDiscount = getSubscriptionStartDiscount({
		subscription,
		subscriptionPrice: subscriptionPriceWithRevenueDiscount,
		startDate: moment.utc(date).format('YYYY-MM-DD'),
	});

	const revenueDiscount = hasRevenueDiscount
		? multiplyPrice(subscriptionPrice, 0.5)
		: {
				currency: BILLING_CURRENCY.code,
				value: 0,
		  };

	const priceAfterDiscounts = sumPrices([
		subscriptionPrice,
		multiplyPrice(startDiscount, -1),
		multiplyPrice(revenueDiscount, -1),
	]);

	const { creditsToUse, balanceAfter } = getCreditsToUseAndBalanceAfter({
		creditsBalance: creditsBalance,
		creditsToUse: priceAfterDiscounts.value,
		type: 'any',
	});

	const leftToPay = sumPrices([
		priceAfterDiscounts,
		multiplyPrice(
			{
				value: creditsToUse.total,
				currency: BILLING_CURRENCY.code,
			},
			-1,
		),
	]);

	const refundableAmount = sumPrices([
		leftToPay,
		{
			value: creditsToUse.refund,
			currency: BILLING_CURRENCY.code,
		},
	]);

	if (!!subscription) {
		subscription.previousBillingInfo = {
			refundableAmount,
			hasRevenueDiscount: revenueDiscount.value > 0,
		};
	}

	return {
		subscription,
		subscriptionPrice,
		startDiscount,
		revenueDiscount,
		leftToPay,
		refundableAmount,
		creditsToUse,
		creditsBalanceAfter: balanceAfter,
		creditsBalanceChange: getCreditsChange({ before: creditsBalance, after: balanceAfter }),
	};
};
