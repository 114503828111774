import * as dbPath from 'common/api/db/paths';
import { OrderDelivery } from 'common/types';

import { DbRefs } from '../types';
import { buildQuery, createBasicDbOperations } from '../utils';

export function dataUpdaterFn(orderDelivery: OrderDelivery): OrderDelivery {
	if (!!orderDelivery.from) {
		const fromLocation = orderDelivery.from?.location;
		const fromDetails = (fromLocation as any)?.details as string | null | undefined;
		if (!!fromLocation && !!fromDetails && !fromLocation.address) {
			orderDelivery.from.location = {
				address: {
					address: fromDetails,
				},
			};
		}
	}

	if (!!orderDelivery.to) {
		const toLocation = orderDelivery.to?.location;
		const toDetails = (toLocation as any)?.details as string | null | undefined;
		if (!!toLocation && !!toDetails && !toLocation.address) {
			orderDelivery.to.location = {
				address: {
					address: toDetails,
				},
			};
		}
	}

	return orderDelivery;
}

export const getOrderDeliveriesQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.orderDeliveries;
	const dataUpdater = dataUpdaterFn;
	const query = buildQuery<OrderDelivery>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<OrderDelivery>(collectionPath, dbRefs, dataUpdater),
		byOrderId: (orderId: string) => query.where('orderId', '==', orderId),
		byOrderAndShopId: (orderId: string, shopId: string) =>
			query.where('orderId', '==', orderId).where('shopId', '==', shopId),
	};
};
