import {
	OnboardingItem,
	OnboardingListVersion,
	OnboardingListVersions,
	OnboardingStepDefinition,
} from 'common/services/analytics/tractionAnalytics';
import { switchUnreachable } from 'common/utils/common';

const GET_TO_KNOW_RENTLE_INCLUDED_ITEMS = (version: OnboardingListVersion): OnboardingItem[] => {
	switch (version) {
		case OnboardingListVersions.V3_USERFLOW:
			return [];
		case OnboardingListVersions.V3:
			return [
				'VISIT_ONLINE_STORE',
				'PRODUCT_CREATION',
				'LOGO',
				'APPEARANCE_ONLINE_STORE',
				'BOOKING_CREATION',
				'BOOKING_COMPLETION',
			];
		case OnboardingListVersions.V2_NO_TEMPLATE:
			return ['PRODUCT_CREATION', 'BOOKING_CREATION', 'BOOKING_COMPLETION'];
		case OnboardingListVersions.V2_TEMPLATE:
			return ['BOOKING_CREATION', 'BOOKING_COMPLETION'];
		case OnboardingListVersions.V1_TEMPLATE:
			return ['VISIT_ONLINE_STORE', 'BOOKING_CREATION', 'BOOKING_COMPLETION'];
		case OnboardingListVersions.V1_NO_TEMPLATE:
		case OnboardingListVersions.V0:
			return ['PRODUCT_CREATION', 'VISIT_ONLINE_STORE', 'BOOKING_CREATION', 'BOOKING_COMPLETION'];
		default:
			return switchUnreachable(version);
	}
};

export const GET_TO_KNOW_RENTLE_DEFINITION: Record<
	'GET_TO_KNOW_RENTLE',
	OnboardingStepDefinition
> = {
	GET_TO_KNOW_RENTLE: {
		plans: 'all',
		versions: {
			V0: GET_TO_KNOW_RENTLE_INCLUDED_ITEMS('V0'),
			V1_TEMPLATE: GET_TO_KNOW_RENTLE_INCLUDED_ITEMS('V1_TEMPLATE'),
			V1_NO_TEMPLATE: GET_TO_KNOW_RENTLE_INCLUDED_ITEMS('V1_NO_TEMPLATE'),
			V2_TEMPLATE: GET_TO_KNOW_RENTLE_INCLUDED_ITEMS('V2_TEMPLATE'),
			V2_NO_TEMPLATE: GET_TO_KNOW_RENTLE_INCLUDED_ITEMS('V2_NO_TEMPLATE'),
			V3: GET_TO_KNOW_RENTLE_INCLUDED_ITEMS('V3'),
		},
	},
};
