/**
 * Social logins do not have sign up action separately, but only sign-in. It might happen that
 * user logs in with social login successfully, but doesn't actually have a registered account in Rentle yet.
 * If that happens, we use this global flag isUnregisteredUserLogin to indicate this.
 */

let isUnregisteredUserLogin = false;

export const addIsUnregisteredUserLogin = () => {
	isUnregisteredUserLogin = true;
};

export const removeIsUnregisteredUserLogin = () => {
	isUnregisteredUserLogin = false;
};

export const getIsUnregisteredUserLogin = () => {
	return isUnregisteredUserLogin === true;
};
