import { Currency } from 'common/types';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';

import { UnsupportedTypeError } from './ErrorHandling.mock';

// CURRENTLY NOT SUPPORTING DYNAMIC CURRENCY ADDITION
export const getCurrency = (p?: Partial<Currency>): Currency => {
	const getDefaults = (): Currency => getDefaultCurrencyObject().code;
	const listOfAvailableCurrencies: Currency[] = ['EUR', 'USD'];
	if (p && !listOfAvailableCurrencies.includes(p)) {
		throw new UnsupportedTypeError();
	}
	return p ? p : getDefaults();
};
