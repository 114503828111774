import { z } from 'zod';

import { ItemType } from 'common/models/_atoms/ItemType';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { withOpenApi } from 'common/utils/openApi';

const zItemSkusBase = withOpenApi(
	z.object({
		// TODO I believe this should be skuCode, but seems like it's just the product name in DB - not meaningful to return from API
		// name: z.string(),
		units: z.number().int().nonnegative(),
		skuId: z.string(),
	}),
	{
		fields: {
			units: 'Number of units of the SKU.',
			skuId: 'SKU ID.',
		},
	},
);

export const zItemSkusBulkApi = withOpenApi(
	zItemSkusBase.extend({
		type: z.literal(ItemType.api.enum.bulk),
	}),
	{
		title: 'Bulk SKU',
		fields: {
			type: 'Type of the SKU.',
		},
		example: {
			units: 1,
			skuId: newFirestoreId(),
			type: 'bulk',
		},
	},
);

export const zItemSkusSingleApi = withOpenApi(
	zItemSkusBase.extend({
		type: z.literal(ItemType.api.enum.single),
		itemCodes: z.string().array(),
	}),
	{
		title: 'Tracked SKU',
		fields: {
			type: 'Type of the SKU.',
			itemCodes: 'Item codes of the SKU articles.',
		},
		example: {
			units: 1,
			skuId: newFirestoreId(),
			type: 'single',
			itemCodes: ['E-1'],
		},
	},
);

export const zItemSkusApi = z.union([zItemSkusBulkApi, zItemSkusSingleApi]);
