import posthog from 'posthog-js';

import { isDevEnv } from 'common/utils/common';

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string, {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	disable_session_recording: isDevEnv() ? true : false,
	session_recording: {
		maskTextSelector: '*', // Mask all text inputs
	},
});
