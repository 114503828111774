import { BUILD_AND_ABOVE, LITE_AND_ABOVE } from 'common/modules/plans/plans/constants';
import {
	OnboardingItem,
	OnboardingItemDefinition,
	OnboardingListVersion,
} from 'common/services/analytics/tractionAnalytics';

import { OnboardingStepItem, OnboardingStepsArgs } from '../types';
import { APPEARANCE_ONLINE_STORE_ITEM } from './appearanceOnlineStore';
import { ARTICLE_CREATION_ITEM } from './articleCreation';
import { BOOKING_COMPLETION_ITEM } from './bookingCompletion';
import { BOOKING_CREATION_ITEM } from './bookingCreation';
import { BUSINESS_CONTACT_INFORMATION_ITEM } from './businessContactInformation';
import { BUSINESS_ID_ITEM } from './businessId';
import { CANCELLATION_POLICY_ITEM } from './cancellationPolicy';
import { CREATE_CATEGORY_ITEM } from './createCategory';
import { CREATE_MULTIPLE_PRODUCTS_ITEM } from './createMultipleProducts';
import { EDIT_PRODUCT_PRICING_ITEM } from './editProductPricing';
import { EDIT_PRODUCT_QUANTITY_ITEM } from './editProductQuantity';
import { INTRO_VIDEO_ITEM } from './introVideo';
import { LINK_ONLINE_STORE_ITEM } from './linkOnlineStore';
import { LOGO_ITEM } from './logo';
import { OPENING_HOURS_ITEM } from './openingHours';
import { PAYMENTS_ITEM } from './payments';
import { PRIVACY_POLICY_ITEM } from './privacyPolicy';
import { PRODUCT_CREATION_ITEM } from './productCreation';
import { PRODUCT_SKU_CONNECTION_ITEM } from './productSkuConnection';
import { RECOMMEND_ADD_ON_PRODUCTS_ITEM } from './recommendAddOnProducts';
import { REGIONAL_FORMATS_ITEM } from './regionalFormats';
import { SKU_CREATION_ITEM } from './skuCreation';
import { STORE_DETAILS_ITEM } from './storeDetails';
import { TAX_RATES_ITEM } from './taxRates';
import { TERMS_OF_SERVICE_ITEM } from './termsOfService';
import { USERS_ITEM } from './users';
import { VERIFY_LANGUAGE_ITEM } from './verifyLanguage';
import { VIEW_CATALOG_ITEM } from './viewCatalog';
import { VISIT_BLOCK_STORE_ITEM } from './visitBlockStore';
import { VISIT_DELIVERY_ITEM } from './visitDelivery';
import { VISIT_INVENTORY_ITEM } from './visitInventory';
import { VISIT_INVENTORY_SUPPORT_LINK_ITEM } from './visitInventorySupportLink';
import { VISIT_ONLINE_STORE_ITEM } from './visitOnlineStore';
import { WAIVER_AND_POLICY_ITEM } from './waiverAndPolicy';

export const onboardingItemDefinitions: Record<OnboardingItem, OnboardingItemDefinition> = {
	PRODUCT_CREATION: {
		plans: 'all',
	},
	VISIT_ONLINE_STORE: {
		plans: 'all',
	},
	BOOKING_CREATION: {
		plans: 'all',
	},
	BOOKING_COMPLETION: {
		plans: 'all',
	},
	APPEARANCE_ONLINE_STORE: {
		plans: 'all',
	},
	LOGO: {
		plans: 'all',
	},
	VIEW_CATALOG: {
		plans: 'all',
	},
	CREATE_MULTIPLE_PRODUCTS: {
		plans: 'all',
	},
	EDIT_PRODUCT_PRICING: {
		plans: 'all',
	},
	EDIT_PRODUCT_QUANTITY: {
		plans: 'all',
	},
	RECOMMEND_ADD_ON_PRODUCTS: {
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	CREATE_CATEGORY: {
		plans: 'all',
	},
	LINK_ONLINE_STORE: { plans: 'all' },
	PAYMENTS: {
		plans: 'all',
	},
	VISIT_BLOCK_STORE: {
		plans: 'all',
	},
	VISIT_DELIVERY: {
		plans: 'all',
	},
	TAX_RATES: {
		plans: 'all',
	},
	REGIONAL_FORMATS: {
		plans: 'all',
	},
	VERIFY_LANGUAGE: {
		plans: 'all',
	},
	VISIT_INVENTORY: {
		plans: 'all',
	},
	VISIT_INVENTORY_SUPPORT_LINK: {
		plans: 'all',
	},
	SKU_CREATION: {
		plans: 'all',
	},
	ARTICLE_CREATION: { plans: 'all' },
	PRODUCT_SKU_CONNECTION: {
		plans: 'all',
	},
	USERS: {
		plans: 'all',
	},
	BUSINESS_CONTACT_INFORMATION: {
		plans: 'all',
	},
	OPENING_HOURS: {
		plans: 'all',
	},
	CANCELLATION_POLICY: {
		plans: 'all',
	},
	STORE_DETAILS: {
		plans: 'all',
	},
	PRIVACY_POLICY: {
		plans: 'all',
	},
	TERMS_OF_SERVICE: {
		plans: 'all',
	},
	WAIVER_AND_POLICY: {
		plans: 'all',
	},
	INTRO_VIDEO: {
		plans: 'all',
	},
	/* Not in use anymore, but merchant's can still have these */
	BUSINESS_ID: {
		plans: 'none',
	},
};

export const ONBOARDING_ITEMS = ({
	t,
	onlineStoreUrl,
	showProductTours,
}: OnboardingStepsArgs): Record<
	OnboardingItem,
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	return {
		...PRODUCT_CREATION_ITEM({ t, showProductTours }),
		...VISIT_ONLINE_STORE_ITEM({ t, onlineStoreUrl, showProductTours }),
		...BOOKING_CREATION_ITEM({ t, onlineStoreUrl, showProductTours }),
		...BOOKING_COMPLETION_ITEM({ t, showProductTours }),
		...APPEARANCE_ONLINE_STORE_ITEM({ t, showProductTours }),
		...LOGO_ITEM({ t, showProductTours }),
		...VIEW_CATALOG_ITEM({ t, showProductTours }),
		...CREATE_MULTIPLE_PRODUCTS_ITEM({ t }),
		...EDIT_PRODUCT_PRICING_ITEM({ t, showProductTours }),
		...EDIT_PRODUCT_QUANTITY_ITEM({ t, showProductTours }),
		...RECOMMEND_ADD_ON_PRODUCTS_ITEM({ t, showProductTours }),
		...CREATE_CATEGORY_ITEM({ t }),
		...LINK_ONLINE_STORE_ITEM({ t, showProductTours }),
		...PAYMENTS_ITEM({ t }),
		...VISIT_BLOCK_STORE_ITEM({ t }),
		...VISIT_DELIVERY_ITEM({ t }),
		...TAX_RATES_ITEM({ t }),
		...REGIONAL_FORMATS_ITEM({ t }),
		...VERIFY_LANGUAGE_ITEM({ t }),
		...VISIT_INVENTORY_SUPPORT_LINK_ITEM({ t }),
		...VISIT_INVENTORY_ITEM({ t }),
		...SKU_CREATION_ITEM({ t }),
		...ARTICLE_CREATION_ITEM({ t }),
		...PRODUCT_SKU_CONNECTION_ITEM({ t }),
		...USERS_ITEM({ t }),
		...STORE_DETAILS_ITEM({ t }),
		...OPENING_HOURS_ITEM({ t }),
		...CANCELLATION_POLICY_ITEM({ t }),
		...BUSINESS_CONTACT_INFORMATION_ITEM({ t }),
		...PRIVACY_POLICY_ITEM({ t }),
		...TERMS_OF_SERVICE_ITEM({ t }),
		...BUSINESS_ID_ITEM({ t }),
		...WAIVER_AND_POLICY_ITEM({ t }),
		...INTRO_VIDEO_ITEM({ t }),
	};
};
