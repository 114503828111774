import * as React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

interface Props {
	in?: boolean;
	size?: number;
	color?: string;
	padding?: number;
	thickness?: number;
	relative?: boolean;
	margin?: number;
	text?: string;
}

const Spinner = (props: Props) => {
	const { classes, cx } = useStyles();
	return (
		<div className={classes.container}>
			<div className={cx(classes.wrapper, { [classes.relative]: props.relative })}>
				<Fade
					in={props.in ? props.in : true}
					style={{
						transitionDelay: props.in ? '0ms' : '0ms',
					}}
					unmountOnExit
				>
					<CircularProgress
						style={{
							width: props.size ? props.size : '100%',
							height: props.size ? props.size : '100%',
							padding: props.padding ? props.padding : '',
							margin: props.margin ? props.margin : '',
							color: props.color ? props.color : '',
						}}
						thickness={props.thickness ? props.thickness : 3.6}
						classes={{
							svg: classes.svg,
							root: classes.spinner,
						}}
					/>
				</Fade>
				{props.text ? (
					<Typography className={classes.spacingTop} variant="body1">
						{props.text}
					</Typography>
				) : null}
			</div>
		</div>
	);
};

const useStyles = makeStyles()((theme) => ({
	wrapper: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		backgroundColor: 'inherit',
		zIndex: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	container: {
		minWidth: 24,
		minHeight: 24,
	},
	relative: {
		position: 'relative',
	},
	spinner: {
		maxWidth: 50,
		maxHeight: 50,
	},
	svg: {
		width: '100%',
		height: '100%',
	},
	spacingTop: {
		marginTop: 16,
	},
}));

export default Spinner;
