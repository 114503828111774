import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.categoryTerms.name', 'Category Terms'),
	subHeader: t(
		'common:blocks.details.categoryTerms.subHeader',
		'Get relevant terms & conditions in place',
	),
	description: t(
		'common:blocks.details.categoryTerms.description',
		'Customizing rental terms is a nice way to improve customer experience when products are naturally used under different circumstances. Dynamic rental terms that change based on the product helps your customers to understand the rights and liabilities relevant to them when using your services',
	),
	supportArticle: SupportArticles.CATEGORY_TERMS,
	uninstallNotice: t(
		'common:blocks.details.categoryTerms.uninstallNotice',
		'By uninstalling Category terms, you will lose all your configured terms',
	),
});

export default details;
