import { Address } from 'common/models/_atoms/Address';
import { LocaleField } from 'common/models/_atoms/LocaleField';

import { ItemPricing } from '../OrderItem/ItemPricing';
import { OrderPayment } from '../OrderPayment';
import { OrderDeliveryApi, OrderDeliveryDb } from './types';

export const toApi = (data: OrderDeliveryDb): OrderDeliveryApi => {
	const getOrderDeliveryDetails = (
		data: NonNullable<OrderDeliveryDb['to']>,
	): OrderDeliveryApi['to'] => {
		return {
			timeslot: data.timeslot ?? undefined,
			pricing: ItemPricing.toApi(data.pricing),
			handlingTimeMinutes: data.handlingTimeMinutes,
			location: {
				address: !!data.location.address ? Address.toApi(data.location.address) : undefined,
			},
			carrierName: data.carrier?.name,
			carrierId: data.carrier?.id,
			trackingId: data.trackingId,
		};
	};

	return {
		name: data.name,
		pricing: ItemPricing.toApi(data.pricing),
		payment: OrderPayment.toApi(data.charge),
		deliveryOptionId: data.deliveryOptionId,
		to: data.to ? getOrderDeliveryDetails(data.to) : undefined,
		from: data.from ? getOrderDeliveryDetails(data.from) : undefined,
		returnInstructions: data.returnInstructions
			? LocaleField.toApi(data.returnInstructions)
			: undefined,
	};
};
