import { Plan, PlanDefinition, Plans } from './types';

export const PlanDefinitions: Record<Plan, PlanDefinition> = {
	[Plans.FREE]: {
		id: Plans.FREE,
		name: 'Free',
		pricing: null,
		blockSlots: Infinity,
		users: 1,
		languages: 2,
		stores: 1,
		articles: 1000,
		billing: {
			type: 'none',
		},
		pickupLocations: 2,
		public: true,
	},
	[Plans.DISCOVER]: {
		id: Plans.DISCOVER,
		name: 'Discover',
		pricing: {
			fixed: {
				amount: {
					value: 39_00,
					currency: 'USD',
				},
				description: 'Price per month',
			},
		},
		blockSlots: Infinity,
		users: 5,
		languages: 3,
		stores: 1,
		articles: 1000,
		billing: {
			type: 'fixed-store-count',
		},
		pickupLocations: 5,
		public: true,
	},
	[Plans.BUILD]: {
		id: Plans.BUILD,
		name: 'Build',
		pricing: {
			fixed: {
				amount: {
					value: 92_00,
					currency: 'USD',
				},
				description: 'Price per month',
			},
		},
		blockSlots: Infinity,
		users: 10,
		languages: 5,
		stores: 2,
		articles: 1000,
		billing: {
			type: 'fixed-store-count',
		},
		pickupLocations: 10,
		public: true,
	},
	[Plans.ADVANCE]: {
		id: Plans.ADVANCE,
		name: 'Advance',
		pricing: {
			fixed: {
				amount: {
					value: 380_00,
					currency: 'USD',
				},
				description: 'Price per month',
			},
		},
		blockSlots: Infinity,
		users: 20,
		languages: Infinity,
		articles: 3000,
		stores: 3,
		billing: {
			type: 'price-per-additional-store',
			pricePerAdditionalStore: {
				fixed: {
					amount: {
						value: 24_00,
						currency: 'USD',
					},
					description: 'Price per additional store, per month',
				},
			},
		},
		pickupLocations: Infinity,
		public: true,
	},
	[Plans.ENTERPRISE]: {
		id: Plans.ENTERPRISE,
		name: 'Enterprise',
		pricing: null,
		blockSlots: Infinity,
		users: Infinity,
		languages: Infinity,
		articles: Infinity,
		stores: Infinity,
		billing: {
			type: 'none',
		},
		pickupLocations: Infinity,
		public: false,
	},
	[Plans.DEMO]: {
		id: Plans.DEMO,
		name: 'Demo',
		pricing: null,
		blockSlots: Infinity,
		users: Infinity,
		languages: Infinity,
		articles: Infinity,
		stores: Infinity,
		pickupLocations: Infinity,
		billing: {
			type: 'none',
		},
		public: false,
	},
	[Plans.INTERNAL]: {
		id: Plans.INTERNAL,
		name: 'Internal',
		pricing: null,
		blockSlots: Infinity,
		users: Infinity,
		languages: Infinity,
		articles: Infinity,
		stores: Infinity,
		pickupLocations: Infinity,
		billing: {
			type: 'none',
		},
		public: false,
	},
	/**
	 * Legacy plans (to be removed in the future)
	 */
	[Plans.LITE]: {
		id: Plans.LITE,
		name: 'Rentle Free',
		pricing: null,
		blockSlots: 0,
		users: Infinity,
		languages: 1,
		articles: Infinity,
		stores: 1,
		pickupLocations: 0,
		billing: {
			type: 'none',
		},
		legacy: true,
		public: false,
	},
	[Plans.BASIC]: {
		id: Plans.BASIC,
		name: 'Rentle Basic',
		blockSlots: 6,
		pricing: {
			fixed: {
				amount: {
					value: 39_00,
					currency: 'USD',
				},
				description: 'Price per month, per store',
			},
		},
		users: Infinity,
		languages: Infinity,
		articles: Infinity,
		stores: Infinity,
		billing: {
			type: 'subscription-per-store',
		},
		pickupLocations: 0,
		legacy: true,
		public: false,
	},
	[Plans.GROW]: {
		id: Plans.GROW,
		name: 'Rentle Grow',
		blockSlots: 8,
		pricing: {
			fixed: {
				amount: {
					value: 79_00,
					currency: 'USD',
				},
				description: 'Price per month, per store',
			},
		},
		users: Infinity,
		languages: Infinity,
		articles: Infinity,
		stores: Infinity,
		billing: {
			type: 'subscription-per-store',
		},
		pickupLocations: 0,
		legacy: true,
		public: false,
	},
	[Plans.ADVANCED]: {
		id: Plans.ADVANCED,
		name: 'Rentle Advanced',
		blockSlots: 16,
		pricing: {
			fixed: {
				amount: {
					value: 399_00,
					currency: 'USD',
				},
				description: 'Price per month, per store',
			},
		},
		users: Infinity,
		languages: Infinity,
		articles: Infinity,
		stores: Infinity,
		billing: {
			type: 'subscription-per-store',
		},
		pickupLocations: 0,
		legacy: true,
		public: false,
	},
};
