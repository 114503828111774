import { createReducer } from '@reduxjs/toolkit';

import { Notification } from 'actions/NotificationActions';
import * as NotificationActions from 'actions/NotificationActions';

export type NotificationState = {
	notifications: Notification[];
};

const INITIAL_STATE: NotificationState = {
	notifications: [],
};

const NotificationReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(NotificationActions.showNotification, (state, action) => {
		state.notifications.push({
			...action.payload,
			id: action.payload.id ?? `${Date.now()}_${Math.floor(Math.random() * 100000)}`,
		});
	});

	builder.addCase(NotificationActions.updateNotification, (state, action) => {
		state.notifications = state.notifications.map((n) => {
			if (n.id === action.payload.id) {
				return {
					...n,
					...action.payload.updates,
				};
			}
			return n;
		});
	});

	builder.addCase(NotificationActions.dismissNotification, (state, action) => {
		const id = action.payload;
		state.notifications = state.notifications.filter((n) => n.id !== id);
	});

	builder.addCase(NotificationActions.dismissAllNotifications, (state) => {
		state.notifications = [];
	});
});

export default NotificationReducer;
