import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export interface RentleAccountSeed {
	shopperIds: string[];
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	tosAcceptance: {
		version: number;
		timestamp: string;
	};
	created: string;
}

export const getRentleAccountSeedQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.rentleAccountSeeds;
	const query = buildQuery<RentleAccountSeed>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<RentleAccountSeed>(collectionPath, dbRefs),
	};
};
