import { Box, Stack, Typography, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { RiUploadCloud2Line } from 'react-icons/ri';

import { useTranslation } from 'services/localization';

interface Props {
	onDrop: (files: File[]) => void;
}
const ImageUploader = (props: Props) => {
	const { onDrop } = props;
	const { t } = useTranslation();

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<OutlinedInputBox sx={{ p: 2 }}>
			<Box {...getRootProps()} sx={{ width: '100%' }}>
				<input {...getInputProps()} />
				<Stack
					justifyContent="center"
					alignItems="center"
					sx={{ height: 150, opacity: () => (isDragActive ? 0.5 : 1) }}
				>
					<RiUploadCloud2Line size={32} />
					<Typography variant="body1">{t('listings.uploadImage', 'Click to add photo')}</Typography>
					<Typography variant="caption">
						{t('listings.uploadImageCaption', 'Or drag and drop')}
					</Typography>
				</Stack>
			</Box>
		</OutlinedInputBox>
	);
};

const OutlinedInputBox = styled(Box)(({ theme }) => ({
	cursor: 'pointer',
	border: `1px solid ${theme.palette.border.inputStandard}`,
	borderRadius: theme.shape.borderRadius,
	color: theme.palette.text.secondary,
	'&:hover': {
		color: theme.palette.text.primary,
		borderColor: theme.palette.text.primary,
	},
}));

export default ImageUploader;
