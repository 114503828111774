import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.INVENTORY_ARTICLES_ADD,
		}),
		content: {
			title: t('homeView.onboarding.createArticlesForYourSkus', 'Create articles for your SKUs'),
		},
		id: 'ARTICLE_CREATION',
		type: 'row',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.INVENTORY_ARTICLES_ADD,
		}),
		content: {
			title: t(
				'homeView.onboarding.createFirstInventoryArticle2',
				'Create your articles for the SKU',
			),
		},
		id: 'ARTICLE_CREATION',
		type: 'row',
	},
});

export const ARTICLE_CREATION_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'ARTICLE_CREATION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		ARTICLE_CREATION: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
