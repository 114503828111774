import { ApiConfig, Api as BaseApi } from 'common/apis/admin';
import { auth } from 'common/frontend/firebase/auth';
import { hasWindow } from 'common/utils/browserUtils';

import { getAdminApiBaseUrl } from './utils';

type SecurityData = { token: string };
type SecurityWorker = ApiConfig<SecurityData>['securityWorker'];

const getFetch = () => {
	if (hasWindow() && window.fetch !== undefined) {
		return window.fetch.bind(window);
	}
	// NOTE: node-fetch needs to be resolvable from the tree
	// of the caller
	return require('node-fetch');
};

const getSecurityWorker = (): SecurityWorker => {
	// NOTE: this could be used from outside the browser as well, e.g. in
	// manual tests
	if (hasWindow()) {
		return async () => {
			// the library should automatically refresh the token if necessary
			const token = await auth.currentUser?.getIdToken();
			return {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};
		};
	}
	// falls back to use the manually set security data object
	return (securityData) => {
		if (!securityData?.token) return;
		return {
			headers: {
				Authorization: `Bearer ${securityData.token}`,
			},
		};
	};
};

/**
 * The generated API SDK is always using the latest version of the API
 */
const baseApi = new BaseApi<SecurityData>({
	baseUrl: getAdminApiBaseUrl(),
	baseApiParams: {
		secure: true,
	},
	securityWorker: getSecurityWorker(),
	customFetch: getFetch(),
});

export const setSecurityData = baseApi.setSecurityData.bind(baseApi);

/**
 * NOTE: the exported API here is the Admin API.
 */
export const Api = baseApi;
