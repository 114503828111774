import posthog from 'posthog-js';

import { EcomEventName, EventName, Events } from './types';

export const shouldCapturePosthogEvents = (): boolean => {
	const env = process.env.REACT_APP_ENV ?? '';
	const production = env === 'production';
	const hasEnabledCapture = posthog?.isFeatureEnabled('enable-capturing') === true;
	return production || hasEnabledCapture;
};

export const isFeatureEnabled = (name: string): boolean | undefined => {
	return posthog?.isFeatureEnabled(name);
};

export const logEvent = <T extends Exclude<EventName, EcomEventName>>(
	name: T,
	params?: Events[T],
) => {
	const hasEnabledCapture = shouldCapturePosthogEvents();
	if (hasEnabledCapture) {
		posthog?.capture(name, params);
	}
};

export const logPageView = (path: string) => {
	const hasEnabledCapture = shouldCapturePosthogEvents();
	if (hasEnabledCapture) {
		posthog?.capture('$pageview', { path });
	}
};
