import { getOpenApiExample } from 'common/utils/openApi';

import { zPaymentObjectApi } from './api';
import { toApi } from './mapper';

export const PaymentObject = {
	toApi,
	api: zPaymentObjectApi,
	example: getOpenApiExample(zPaymentObjectApi),
};
