import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.analytics.plugAndPlayIntegration',
			'Plug and play integration',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.analytics.support',
			'Support for UA, GA4 and GTM',
		),
	},
	{
		description: i18n.t('account.addOns.valueProps.analytics.gdprCompliant', 'GDPR compliant'),
	},
];

export default valueProps;
