import { DayTypeApi, DayTypeDb } from './types';

export const toApi = (data: DayTypeDb): DayTypeApi => {
	const map: Record<DayTypeDb, DayTypeApi> = {
		allDays: 'all_days',
		weekDays: 'weekdays',
		weekends: 'weekends',
		monday: 'monday',
		tuesday: 'tuesday',
		wednesday: 'wednesday',
		thursday: 'thursday',
		friday: 'friday',
		saturday: 'saturday',
		sunday: 'sunday',
	};
	return map[data];
};

export const toDb = (data: DayTypeApi): DayTypeDb => {
	const map: Record<DayTypeApi, DayTypeDb> = {
		all_days: 'allDays',
		weekdays: 'weekDays',
		weekends: 'weekends',
		monday: 'monday',
		tuesday: 'tuesday',
		wednesday: 'wednesday',
		thursday: 'thursday',
		friday: 'friday',
		saturday: 'saturday',
		sunday: 'sunday',
	};
	return map[data];
};
