import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import * as UserflowService from 'common/services/analytics/userflow';
import { isDevEnv, removeUndefinedValues } from 'common/utils/common';
import * as UserSelectors from 'selectors/UserSelectors';
import history from 'services/history';
import { useTranslation } from 'services/localization';

export const useUserflow = () => {
	const [isInitialized, setIsInitialized] = useState(false);
	const { lang } = useTranslation();
	const userEmail = useSelector(UserSelectors.activeUserEmail);

	useEffect(() => {
		UserflowService.initialize();
		UserflowService.setCustomNavigate((url: string) => history.push(url));
		setIsInitialized(true);
	}, []);

	const identifyUser = useCallback(
		(userId: string) => {
			if (!isInitialized) {
				return;
			}
			UserflowService.identifyUser(
				userId,
				removeUndefinedValues({
					local_code: lang,
					email: isDevEnv() ? userEmail : undefined,
					device_type: window.innerWidth > 800 ? 'desktop' : 'mobile',
				}),
			);
		},
		[isInitialized, lang, userEmail],
	);

	return {
		identifyUser,
		resetUser: UserflowService.resetUser,
	};
};

export default useUserflow;
