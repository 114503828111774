import * as get from './get';
import * as set from './set';

const _InventoryItemApi = () => {
	return {
		get,
		set,
	};
};

export const InventoryItemApi = _InventoryItemApi();
