import { TFunction } from 'i18next';

import { ONLINE_DEFAULT_FEATURED_IMAGE_URL } from 'common/constants/defaultImages';
import { OnlineLayoutSettings } from 'common/modules/customization/types';

import { isValidUrl } from '../atoms/validation';
import { PLAIN_THEME_ID, RENTLE_STANDARD_THEME_ID, RENTLE_STANDARD_THEME_NAME } from './constants';
import { CustomizerTheme, FeaturedImageButton, HeaderLogoPlacement, SliderValue } from './types';

export const getDefaultOnlineLayout = (): OnlineLayoutSettings => ({
	defaultLayout: 'categories',
	header: {
		logo: {
			placement: 'left' as HeaderLogoPlacement,
			width: 120 as SliderValue,
		},
	},
	productGridColumns: {
		desktop: 3 as SliderValue,
	},
	featuredImage: {
		src: ONLINE_DEFAULT_FEATURED_IMAGE_URL,
		alignment: 'center',
	},
	footer: {
		menu: {
			hidden: false,
			toggleMenu: true,
		},
	},
});

export const isValidButton = (
	button: FeaturedImageButton | null,
	t: TFunction,
): { valid: boolean; message?: string } => {
	if (!button?.label?.def && !button?.src) return { valid: true };
	const hasBothFields = !!button?.label?.def && !!button.src;
	const isValidLink = isValidUrl(button?.src ?? '');

	if (hasBothFields && isValidLink) {
		return { valid: true };
	} else if (!hasBothFields) {
		return {
			valid: false,
			message: t('common:validation.mustContainBothFields', 'Must contain both fields'),
		};
	} else if (!isValidLink) {
		return {
			valid: false,
			message: t('common:validation.mustContainURLProtocol', 'The url must begin with "https://"'),
		};
	}
	return { valid: true };
};

export const getRentleDefaultTheme = (): CustomizerTheme => {
	return {
		id: RENTLE_STANDARD_THEME_ID,
		name: RENTLE_STANDARD_THEME_NAME,
		merchantId: '',
		createdAt: '',
		updatedAt: '',
		live: getDefaultOnlineLayout(),
		type: 'online',
	};
};

export const isRentleDefaultThemeId = (themeId: string) => themeId === RENTLE_STANDARD_THEME_ID;

export const isPlainThemeId = (themeId: string) => themeId === PLAIN_THEME_ID;

export const getCustomCheckoutContentValue = (
	value: string | boolean,
	t: TFunction,
): string | null => {
	if (typeof value === 'boolean' && !!value) {
		return t('common:actions.confirmed', 'Confirmed');
	}
	if (typeof value === 'string') {
		return value;
	}
	return null;
};
