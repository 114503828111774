import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.dashboard.name', 'Dashboard'),
	subHeader: t(
		'common:blocks.details.dashboard.subHeader',
		'Get a better overview of your operations',
	),
	description: t(
		'common:blocks.details.dashboard.description',
		'Know your peak hours and days in advanced and prepare with sufficient staffing. Booking dashboard gives you insights on incoming and outgoing customer traffic',
	),
	supportArticle: SupportArticles.DASHBOARD,
});

export default details;
