import type { DocumentChange } from '@firebase/firestore';
import { createAction } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';
import { TFunction } from 'react-i18next';
import { getUpdatedPartialProductsOnCodeChange } from 'views/Rental/ShopperCard/utils';

import { api } from 'common/frontend/api';
import { BatchManager } from 'common/frontend/firebase/firestore';
import { SingleInventoryItem } from 'common/modules/inventory';
import { getVariantsBySkuId } from 'common/modules/products/variants';
import { getNextInstalmentFromProducts } from 'common/modules/subscriptions';
import { ListenerManager } from 'common/services/ListenerManager';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	OrderDelivery,
	OrderInfo,
	OrderProduct,
	PartialOrder,
	PartialWithId,
	SetProduct,
	SetProductWithParentId,
	Shopper,
} from 'common/types';
import { hashByUniqueField } from 'common/utils/arrays';
import { getUniqProductTypes, getUniqPurchaseTypes } from 'common/utils/rentalUtils';
import * as RentalViewSelectors from 'selectors/RentalViewSelectors';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as StockSelectors from 'selectors/StockSelectors';
import { ReduxState } from 'services/types';
import { getProductCodes } from 'services/utils';
import { createAppThunk } from 'services/utils/redux';

const rentalViewListeners = new ListenerManager<
	'orderInfo' | 'orderProducts' | 'orderShoppers' | 'orderDelivery'
>();

export const updateOrderProducts = createAction<PartialWithId<OrderProduct>[]>(
	'Rental/UPDATE_ORDER_PRODUCTS',
);
export const updateOrderShoppers = createAction<PartialWithId<Shopper>[]>(
	'Rental/UPDATE_ORDER_SHOPPERS',
);
export const updateOrderInfo = createAction<Partial<OrderInfo>>('Rental/UPDATE_ORDER_INFO');

export const updateSaveStatus = createAction<'SAVING' | 'SAVED' | 'NOT_SAVED'>(
	'Rental/UPDATE_SAVE_STATUS',
);

export const updateActiveShopperId = createAction<string | null>('Rental/UPDATE_ACTIVE_SHOPPER_ID');
export const updateSelectedNonSetProductIds = createAction<string[]>(
	'Rental/UPDATE_SELECTED_NON_SET_PRODUCT_IDS',
);
export const updateSelectedSetProducts = createAction<SetProductWithParentId[]>(
	'Rental/UPDATE_SELECTED_SET_PRODUCTS',
);

export const deleteOrderProductsWithIds = createAction<string[]>(
	'Rental/DELETE_ORDER_PRODUCTS_WITH_IDS',
);
export const deleteOrderProductWithId = createAction<string>('Rental/DELETE_ORDER_PRODUCT_WITH_ID');
export const deleteOrderShoppersWithIds = createAction<string[]>(
	'Rental/DELETE_ORDER_SHOPPERS_BY_ID',
);
export const deleteOrderShopperWithId = createAction<string>('Rental/DELETE_ORDER_SHOPPER_WITH_ID');

export const setHideProductCodeErrors = createAction<boolean>(
	'Rental/SET_HIDE_PRODUCT_CODE_ERRORS',
);

export const updateActivePartialOrder = createAppThunk(
	'Rental/UPDATE_ACTIVE_PARTIAL_ORDER',
	async (partialOrder: PartialOrder, thunkAPI) => {
		return new Promise<boolean>(async (resolve) => {
			const order = RentalViewSelectors.activeOrder(thunkAPI.getState());
			if (!order) return;

			try {
				const batchManager = new BatchManager();
				const {
					products,
					shoppers,
					rentalInfo,
					productIdsToDelete,
					shopperIdsToDelete,
				} = partialOrder;

				const { products: currentProducts } = order;
				const newProducts = currentProducts
					.filter((p) => !productIdsToDelete?.includes(p.id))
					.map((p) => {
						const updates = products?.find(({ id }) => id === p.id);
						return !!updates ? { ...p, ...updates.data } : p;
					});

				if (!!products) {
					thunkAPI.dispatch(updateOrderProducts(products));
					products.forEach((product) => {
						api(batchManager.batch()).orderProducts.doc(product.id).update(product.data);
					});
				}

				if (!!shoppers) {
					thunkAPI.dispatch(updateOrderShoppers(shoppers));
					shoppers.forEach((shopper) => {
						api(batchManager.batch()).orderShoppers.doc(shopper.id).update(shopper.data);
					});
				}

				if (!!productIdsToDelete) {
					thunkAPI.dispatch(deleteOrderProductsWithIds(productIdsToDelete));
					productIdsToDelete.forEach((id) => {
						api(batchManager.batch()).orderProducts.doc(id).delete();
					});
				}

				if (!!shopperIdsToDelete) {
					thunkAPI.dispatch(deleteOrderShoppersWithIds(shopperIdsToDelete));
					shopperIdsToDelete.forEach((id) => {
						api(batchManager.batch()).orderShoppers.doc(id).delete();
					});
				}

				const productCodesUpdates = ((): Partial<OrderInfo> => {
					const currentProductCodes = getProductCodes(currentProducts).sort();
					const newProductCodes = getProductCodes(newProducts).sort();
					return !isEqual(newProductCodes, currentProductCodes)
						? { rentalProductCodes: newProductCodes }
						: {};
				})();

				const productTypesUpdates = ((): Partial<OrderInfo> => {
					const currentProductTypes = getUniqProductTypes(currentProducts).sort();
					const newProductTypes = getUniqProductTypes(newProducts).sort();
					return !isEqual(newProductTypes, currentProductTypes)
						? { includedProductTypes: newProductTypes }
						: {};
				})();

				const purchaseTypesUpdates = ((): Partial<OrderInfo> => {
					const currentPurchaseTypes = getUniqPurchaseTypes(currentProducts).sort();
					const newPurchaseTypes = getUniqPurchaseTypes(newProducts).sort();
					return !isEqual(newPurchaseTypes, currentPurchaseTypes)
						? {
								purchaseTypes: newPurchaseTypes,
								purchaseType: newPurchaseTypes.length === 1 ? newPurchaseTypes[0] : null,
						  }
						: {};
				})();

				const subscriptionUpdates = ((): Partial<OrderInfo> => {
					const currentNextBillingDate = getNextInstalmentFromProducts(currentProducts)?.date;
					const newNextBillingDate = getNextInstalmentFromProducts(newProducts)?.date;
					return !isEqual(currentNextBillingDate, newNextBillingDate)
						? {
								subscription: {
									nextBillingDate: newNextBillingDate ?? null,
								},
						  }
						: {};
				})();

				const orderInfoUpdates = {
					...rentalInfo?.data,
					...productCodesUpdates,
					...productTypesUpdates,
					...purchaseTypesUpdates,
					...subscriptionUpdates,
				};

				if (!isEmpty(orderInfoUpdates)) {
					thunkAPI.dispatch(updateOrderInfo(orderInfoUpdates));
					api(batchManager.batch()).orders.doc(order.rentalInfo.id).update(orderInfoUpdates);
				}

				const timeout = setTimeout(() => {
					thunkAPI.dispatch(updateSaveStatus('SAVING'));
				}, 1000);
				await batchManager.commit();
				clearTimeout(timeout);
				thunkAPI.dispatch(updateSaveStatus('SAVED'));
				resolve(true);
			} catch (e) {
				errorHandler.report(e);
				thunkAPI.dispatch(updateSaveStatus('NOT_SAVED'));
				resolve(false);
			}
		});
	},
);

export const updateSelectedProductCode = createAppThunk(
	'Rental/UPDATE_SELECTED_PRODUCT_CODE',
	(
		args: {
			code: string;
			selectedStockItem: SingleInventoryItem | undefined;
			orderProduct: OrderProduct | SetProduct;
			orderProductId: string;
			shopper: Shopper;
			setIndex: number | null;
			t: TFunction;
		},
		thunkAPI,
	) => {
		const state = thunkAPI.getState();
		const { code, selectedStockItem, orderProduct, orderProductId, setIndex, shopper, t } = args;
		const stockProductsById = StockSelectors.stockProductsById(state);
		const order = RentalViewSelectors.activeOrder(state);
		if (!order) return;
		const orderProducts = order.products;
		const shopperProducts = orderProducts.filter((p) => p.shopperId === shopper.id);
		const useDinVariation = ShopSelectors.useDinVariation(state);
		const dinCollectionMethod = ShopSelectors.shopDinCollectionMethod(state);
		const stockProduct = stockProductsById[orderProduct.productApiId];
		const skuId = selectedStockItem?.skuId;
		const validProductVariantIdsForSkuId = skuId
			? getVariantsBySkuId(stockProduct?.variants.options ?? [], skuId).map((v) => v.id)
			: undefined;
		const newVariantId =
			!validProductVariantIdsForSkuId?.length ||
			validProductVariantIdsForSkuId.includes(orderProduct.variant?.id ?? '')
				? undefined
				: validProductVariantIdsForSkuId[0];
		const updatedPartialProducts = getUpdatedPartialProductsOnCodeChange({
			code,
			orderProductId,
			setIndex,
			currentVariant: orderProduct.variant,
			newVariantId,
			stockProduct: stockProduct,
			selectedStockItem,
			shopperProducts,
			useDinVariation,
			userProperties: shopper.userProperties,
			t,
			dinCollectionMethod,
		});

		thunkAPI.dispatch(
			updateActivePartialOrder({
				products: updatedPartialProducts,
			}),
		);
	},
);

export const setOrderInfoData = createAction<OrderInfo>('Rental/SET_ORDER_INFO_DATA');
export const setOrderInfoError = createAction<string>('Rental/SET_ORDER_INFO_ERROR');
export const addOrderInfoListener = createAppThunk<OrderInfo, string, { state: ReduxState }>(
	'Rental/ADD_ORDER_INFO_LISTENER',
	(orderId: string, thunkAPI) => {
		return new Promise<OrderInfo>((resolve, reject) => {
			rentalViewListeners.update({
				id: 'orderInfo',
				key: orderId,
				listener: api()
					.orders.doc(orderId)
					.listen(
						(order) => {
							if (!!order) {
								thunkAPI.dispatch(setOrderInfoData(order));
								return resolve(order);
							} else {
								const err = 'Order not found';
								thunkAPI.dispatch(setOrderInfoError(err));
								return reject(err);
							}
						},
						(error) => {
							thunkAPI.dispatch(setOrderInfoError(error.message));
							return reject(error);
						},
					),
			});
		});
	},
);

export const setOrderProductsData = createAction<OrderProduct[]>('Rental/SET_ORDER_PRODUCTS_DATA');
export const setOrderProductsError = createAction<string>('Rental/SET_ORDER_PRODUCTS_ERROR');
export const addOrderProductsListener = createAppThunk(
	'Rental/ADD_ORDER_PRODUCTS_LISTENER',
	(
		args: {
			orderId: string;
			shopId: string;
		},
		thunkAPI,
	) => {
		const { orderId, shopId } = args;
		return new Promise<OrderProduct[]>((resolve, reject) => {
			rentalViewListeners.update({
				id: 'orderProducts',
				key: orderId,
				listener: api()
					.orderProducts.get.where('rentalId', '==', orderId)
					.where('shopId', '==', shopId)
					.orderBy('productApiId')
					.onSnapshot(
						(listenerOrderProducts, snapshot) => {
							const dataUpdater = api().orderProducts.dataUpdater;
							const modifiedDbProductDocs = snapshot.docChanges() as DocumentChange<OrderProduct>[];
							const modifiedDbProducts = modifiedDbProductDocs.map((c) =>
								dataUpdater ? dataUpdater(c.doc.data()) : c.doc.data(),
							);
							const modifiedDbProductsById = hashByUniqueField(modifiedDbProducts, 'id');
							const order = RentalViewSelectors.activeOrder(thunkAPI.getState());
							const currentStateProductsById = hashByUniqueField(order?.products ?? [], 'id');
							/**
							 * This DB listener returns all the order products whenever any product has changes in the DB, including the ones that didn't change.
							 * But it might happen that user has product changes in the Redux state that has not yet been saved to the DB,
							 * and they would be overwritten if we would directly use the products from the DB listener to set the new state.
							 * This might especially happen when multiple users modify the same order at the same time, and an update from another person triggers a listener.
							 * To handle these issues, here we try to ensure that the latest update of the product is always added to the state -
							 * wether it's an update coming from DB trigger, or a local state update.
							 *
							 * This is done by following the priority order of:
							 * 1) check if product has been modified in the DB, and use that
							 * 2) if not, use the product from current state (it should always either have local updates, or equal to the DB version)
							 * 3) if not found from local state, use the unmodified product from the DB listener
							 */
							const updatedProducts = listenerOrderProducts.map((p) => {
								const remoteModifiedProduct = modifiedDbProductsById[p.id];
								const currentStateProduct = currentStateProductsById[p.id];
								const updatedProduct = remoteModifiedProduct ?? currentStateProduct ?? p;
								return updatedProduct;
							});
							thunkAPI.dispatch(setOrderProductsData(updatedProducts));
							return resolve(updatedProducts);
						},
						(error) => {
							thunkAPI.dispatch(setOrderProductsError(error.message));
							return reject(error);
						},
					),
			});
		});
	},
);

export const setOrderShoppersData = createAction<Shopper[]>('Rental/SET_ORDER_SHOPPERS_DATA');
export const setOrderShoppersError = createAction<string>('Rental/SET_ORDER_SHOPPERS_ERROR');
export const addOrderShoppersListener = createAppThunk(
	'Rental/ADD_ORDER_SHOPPERS_LISTENER',
	(
		args: {
			orderId: string;
			shopId: string;
		},
		thunkAPI,
	) => {
		const { orderId, shopId } = args;
		return new Promise<Shopper[]>((resolve, reject) => {
			rentalViewListeners.update({
				id: 'orderShoppers',
				key: orderId,
				listener: api()
					.orderShoppers.get.where('rentalId', '==', orderId)
					.where('shopId', '==', shopId)
					.orderBy('created', 'asc')
					.onSnapshot(
						(shoppers) => {
							thunkAPI.dispatch(setOrderShoppersData(shoppers));
							return resolve(shoppers);
						},
						(error) => {
							thunkAPI.dispatch(setOrderShoppersError(error.message));
							return reject(error);
						},
					),
			});
		});
	},
);

export const setOrderDeliveryData = createAction<OrderDelivery | null>(
	'Rental/SET_ORDER_DELIVERY_DATA',
);
export const setOrderDeliveryError = createAction<string>('Rental/SET_ORDER_DELIVERY_ERROR');
export const addOrderDeliveryListener = createAppThunk(
	'Rental/ADD_ORDER_DELIVERY_LISTENER',
	(
		args: {
			orderId: string;
			shopId: string;
		},
		thunkAPI,
	) => {
		const { orderId, shopId } = args;
		return new Promise<OrderDelivery | null>((resolve, reject) => {
			rentalViewListeners.update({
				id: 'orderDelivery',
				key: orderId,
				listener: api()
					.orderDeliveries.byOrderAndShopId(orderId, shopId)
					.onSnapshot(
						(orderDeliveries) => {
							/**
							 * TODO: Check this
							 */
							const orderDelivery = orderDeliveries[0] ?? null;
							thunkAPI.dispatch(setOrderDeliveryData(orderDelivery));
							return resolve(orderDelivery);
						},
						(error) => {
							thunkAPI.dispatch(setOrderDeliveryError(error.message));
							return reject(error);
						},
					),
			});
		});
	},
);

export const addActiveOrderListeners = createAppThunk(
	'Rental/ADD_ACTIVE_ORDER_LISTENERS',
	(args: { orderId: string; shopId: string }, thunkAPI) => {
		const { orderId, shopId } = args;
		thunkAPI.dispatch(addOrderInfoListener(orderId));
		thunkAPI.dispatch(addOrderProductsListener({ orderId, shopId }));
		thunkAPI.dispatch(addOrderShoppersListener({ orderId, shopId }));
		thunkAPI.dispatch(addOrderDeliveryListener({ orderId, shopId }));
	},
);

export const removeActiveOrderListeners = createAppThunk(
	'Rental/REMOVE_ACTIVE_ORDER_LISTENERS',
	() => {
		rentalViewListeners.clearAll();
	},
);
