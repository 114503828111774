import { isEmpty, uniq } from 'lodash';

import {
	InventoryItem,
	ProductVariant,
	SkuItem,
	StockSourceOption,
	VariantProperty,
	VariantStockSource,
	getNewInventoryItem,
	getNewSkuItem,
	nameToSkuCode,
} from 'common/modules/inventory';
import { createLocaleField } from 'common/modules/translations';
import { IndustryCategory } from 'common/services/analytics/tractionAnalytics';
import {
	ByIndustry,
	Category,
	Languages,
	LocaleField,
	PricingItem,
	ProductApi,
	ProductTypes,
} from 'common/types';
import { newId, notNull, notUndefined, switchUnreachable } from 'common/utils/common';
import { newFirestoreId } from 'common/utils/newRentalUtils';

import { ByProductId, SetIncludesItem } from './../../types/common';
import {
	CopyCategoriesArgs,
	CopyCategoryArgs,
	CopyImagesArgs,
	CopyInventoryItemArgs,
	CopyInventoryItemsArgs,
	CopyOptionArgs,
	CopyProductArgs,
	CopyProductsArgs,
	CopySkuItemArgs,
	CopySkuItemsArgs,
	CopyVariantsArgs,
} from './types';

export const getUniqueTemplateIdsForIndustyCategories = (
	templateIdsByIndustry: ByIndustry<string[]> | undefined,
	industryCategories: IndustryCategory[],
) => {
	return uniq(
		Object.entries(templateIdsByIndustry || {}).reduce((acc, [industry, templateIds]) => {
			const includesIndustryCategory = industryCategories.includes(industry as IndustryCategory);
			return [...acc, ...(includesIndustryCategory ? templateIds : [])];
		}, [] as string[]),
	);
};

export const _optionValuesToKeyLocaleField = (
	optionValues: {
		[key: string]: LocaleField;
	},
	propertiesMap: { [propertyId: string]: string },
	defaultLanguage: Languages,
): { [key: string]: LocaleField } =>
	Object.entries(optionValues).reduce((acc, [key, value]) => {
		const newKey = propertiesMap[key];
		return !!newKey
			? {
					...acc,
					[newKey]: { def: value.def, [defaultLanguage]: value.def },
			  }
			: acc;
	}, {});

export const _copyOption = ({
	option,
	oldPropertiesIdToNewPropertiesIdMap,
	oldSkuIdToNewSkuItemMap,
	defaultLanguage,
}: CopyOptionArgs): ProductVariant => {
	if (!option.stockSources || !oldSkuIdToNewSkuItemMap) {
		return {
			id: newFirestoreId(),
			stockSources: null,
			values: _optionValuesToKeyLocaleField(
				option.values,
				oldPropertiesIdToNewPropertiesIdMap,
				defaultLanguage,
			),
			sales: option.sales
				? {
						enabled: option.sales.enabled,
						priceOverride: option.sales.priceOverride,
				  }
				: null,
			rentals: option.rentals
				? {
						enabled: option.rentals.enabled,
						priceIncrease: option.rentals.priceIncrease,
				  }
				: null,
		};
	}
	const newStockSources: VariantStockSource = Object.values(option.stockSources)
		.map((stockSourceOption: StockSourceOption[]) =>
			stockSourceOption.map((innerOption: StockSourceOption) => ({
				type: innerOption.type,
				units: innerOption.units,
				skuId: !!oldSkuIdToNewSkuItemMap[innerOption.skuId]
					? oldSkuIdToNewSkuItemMap[innerOption.skuId].id
					: null,
			})),
		)
		.reduce((acc, cur, currentIndex) => {
			acc[currentIndex] = cur;
			return acc;
		}, {});
	return {
		values: _optionValuesToKeyLocaleField(
			option.values,
			oldPropertiesIdToNewPropertiesIdMap,
			defaultLanguage,
		),
		id: newFirestoreId(),
		stockSources: newStockSources,
		sales: option.sales
			? {
					enabled: option.sales.enabled,
					priceOverride: option.sales.priceOverride,
			  }
			: null,
		rentals: option.rentals
			? {
					enabled: option.rentals.enabled,
					priceIncrease: option.rentals.priceIncrease,
			  }
			: null,
	};
};

export const _copyVariants = ({
	variants,
	oldSkuIdToNewSkuItemMap,
	defaultLanguage,
}: CopyVariantsArgs): {
	properties: VariantProperty[];
	options: ProductVariant[];
} => {
	const oldPropertiesIdToNewPropertiesIdMap: { [propertyId: string]: string } = {};
	return {
		properties: variants.properties.map((property) => {
			const newId = newFirestoreId();
			oldPropertiesIdToNewPropertiesIdMap[property.id] = newId;
			return {
				id: newId,
				name: createLocaleField(property.name.def, defaultLanguage),
			};
		}),
		options: variants.options.map((option) =>
			_copyOption({
				option,
				oldPropertiesIdToNewPropertiesIdMap,
				oldSkuIdToNewSkuItemMap,
				defaultLanguage,
			}),
		),
	};
};

export const _getImagePath = (
	imageContainer: 'product-images' | 'featured-images',
	url: string,
) => {
	switch (imageContainer) {
		case 'product-images':
			return url.replace(/^.+product-images/, '').replace(/\?alt=media&token.*$/, '');
		case 'featured-images':
			return url.replace(/^.+featured-images/, '').replace(/\?alt=media&token.*$/, '');
		default:
			return switchUnreachable(imageContainer);
	}
};

export const _getNewImageUrlFromOldUrl = (
	imageContainer: 'product-images' | 'featured-images',
	oldUrl?: string | null,
	imageUrlToTemplateImageUrlMap?: { [imageUrl: string]: string },
): string | null => {
	if (!!oldUrl && !!imageUrlToTemplateImageUrlMap) {
		const oldUrlPathWithoutSignature = _getImagePath(imageContainer, oldUrl);
		const imageUrls = Object.entries(imageUrlToTemplateImageUrlMap).find(
			([key]) => _getImagePath(imageContainer, key) === oldUrlPathWithoutSignature,
		);
		return imageUrls ? imageUrls[1] : null;
	}
	return null;
};

export const _copyPricing = (
	pricing: PricingItem[] | undefined,
	defaultLanguage: Languages,
): PricingItem[] | undefined => {
	if (!pricing) {
		return undefined;
	}
	return pricing.map((pricingItem) => ({
		channels: ['ALL'],
		timePeriod: pricingItem.timePeriod,
		timeValue: pricingItem.timeValue,
		multiplier: pricingItem.multiplier,
		price: pricingItem.price,
		...(pricingItem.label && {
			label: createLocaleField(pricingItem.label.def, defaultLanguage),
		}),
	}));
};

export const _getUniqueSkuIdentifiers = (
	skuName: string,
	existingIdentifiers: string[],
	amount: number,
): string[] => {
	const nameStrings = skuName.toUpperCase().split(' ');
	const charsToTake = nameStrings.length === 1 ? 3 : 2;
	const idPrefix = nameStrings.map((string) => string.substring(0, charsToTake)).join('-');
	let uniqueIds: string[] = [];
	let _numberSuffix = 1;
	let _idsToCreateCount = amount;

	try {
		do {
			const numberSuffix = _numberSuffix;
			const idsToCreateCount = _idsToCreateCount;
			const autoIds = new Array(idsToCreateCount)
				.fill(0)
				.map((_, index) =>
					!!idPrefix ? `${idPrefix}-${numberSuffix + index}` : `${numberSuffix + index}`,
				);
			const newUniqueIds = autoIds.filter((id) => !existingIdentifiers.includes(id));
			uniqueIds.push(...newUniqueIds);
			_numberSuffix = numberSuffix + idsToCreateCount;
			_idsToCreateCount = idsToCreateCount - newUniqueIds.length;
		} while (_idsToCreateCount > 0);
		return uniqueIds;
	} catch (e) {
		return new Array(amount).fill(0).map(() => newId(8).toUpperCase());
	}
};

export const _copyCategory = (args: CopyCategoryArgs): Category => {
	const { category, orderIndex, newShopId, defaultLanguage, imageUrlMap } = args;
	const { imageUrl, name, description } = category;
	return {
		id: newFirestoreId(),
		imageUrl: _getNewImageUrlFromOldUrl('product-images', imageUrl, imageUrlMap),
		name: createLocaleField(name.def, defaultLanguage),
		...(description && {
			description: createLocaleField(description.def, defaultLanguage),
		}),
		shopId: newShopId,
		terms: null,
		orderIndex,
		fromTemplate: true,
	};
};

export const copyCategories = (args: CopyCategoriesArgs): { [oldCategoryId: string]: Category } => {
	const { categories, newShopId, defaultLanguage, imageUrlMap } = args;
	const newCategories = categories.map((category, index) => {
		return _copyCategory({ category, orderIndex: index, newShopId, defaultLanguage, imageUrlMap });
	});
	const oldCategoryIdToCategoryIdMap: { [categoryId: string]: Category } = newCategories.reduce(
		(acc, category, index) => {
			acc[categories[index].id] = category;
			return acc;
		},
		{},
	);
	return oldCategoryIdToCategoryIdMap;
};

export const _copyProduct = (
	args: CopyProductArgs,
): Omit<ProductApi, 'additionalProductIds' | 'setProductIds'> => {
	const {
		product,
		orderIndex,
		defaultLanguage,
		oldCategoryIdToNewCategoryMap,
		newShopId,
		vatPercent,
		oldSkuIdToNewSkuItemMap,
		imageUrlMap,
		pricingOptions,
	} = args;
	const {
		additionalProduct,
		categoryIds,
		description,
		image,
		images,
		name,
		pricing,
		rentals,
		sales,
		set,
		variants,
		highlightProduct,
		subscriptions,
		type,
	} = product;
	return {
		additionalProduct,
		created: new Date().toISOString(),
		highlightProduct: highlightProduct,
		id: newFirestoreId(),
		image: _getNewImageUrlFromOldUrl('product-images', image, imageUrlMap) ?? undefined,
		name: createLocaleField(name.def, defaultLanguage),
		orderIndex,
		pricing: _copyPricing(pricing, defaultLanguage),
		...(!!pricingOptions?.length
			? {
					...(pricingOptions.includes('BOOKINGS')
						? { rentals }
						: {
								rentals: {
									enabled: false,
									basePrice: null,
								},
						  }),
					...(pricingOptions.includes('SALES')
						? { sales }
						: {
								sales: {
									enabled: false,
									basePrice: null,
								},
						  }),
					...(pricingOptions.includes('SUBSCRIPTIONS')
						? { subscriptions }
						: {
								subscriptions: null,
						  }),
			  }
			: { rentals, sales, subscriptions }),
		set,
		setIncludes: product.setIncludes,
		shopId: newShopId,
		variants: _copyVariants({ variants, oldSkuIdToNewSkuItemMap, defaultLanguage }),
		vatPercent,
		...(categoryIds && {
			categoryIds: categoryIds?.map((categoryId) => oldCategoryIdToNewCategoryMap[categoryId].id),
		}),
		...(!!description && {
			description: createLocaleField(description.def, defaultLanguage),
		}),
		...(images && {
			images: images
				?.map((image) => _getNewImageUrlFromOldUrl('product-images', image, imageUrlMap))
				.filter(notNull),
		}),
		type: !!type ? type : ProductTypes.RENTAL,
		fromTemplate: true,
	};
};

export const copyProducts = (args: CopyProductsArgs): ProductApi[] => {
	const {
		products,
		newShopId,
		defaultLanguage,
		vatPercent,
		oldCategoryIdToNewCategoryMap,
		oldSkuIdToNewSkuItemMap,
		imageUrlMap,
		pricingOptions,
	} = args;
	let oldIdToNewIdMap: { [id: string]: string } = {};
	const productCopies = products.map((product, index) => {
		const productCopy = _copyProduct({
			product,
			orderIndex: index,
			defaultLanguage,
			oldCategoryIdToNewCategoryMap,
			newShopId,
			vatPercent,
			oldSkuIdToNewSkuItemMap,
			imageUrlMap: imageUrlMap,
			pricingOptions,
		});
		oldIdToNewIdMap[product.id] = productCopy.id;
		return productCopy;
	});
	return productCopies.map((product, index) => {
		const oldProduct = products[index];
		const newAdditionalProductIds = oldProduct.additionalProductIds
			?.map((additionalProductId) => oldIdToNewIdMap[additionalProductId])
			.filter(notUndefined);
		const newSetIncludes = Object.entries(oldProduct.setIncludes ?? {}).reduce(
			(acc, [productId, item]) => {
				const newId = oldIdToNewIdMap[productId];
				if (!!newId) {
					acc[newId] = item;
				}
				return acc;
			},
			{} as ByProductId<SetIncludesItem>,
		);
		return {
			...product,
			...(!!newAdditionalProductIds?.length && {
				additionalProductIds: newAdditionalProductIds,
			}),
			...(!isEmpty(newSetIncludes) && {
				setIncludes: newSetIncludes,
			}),
		};
	});
};

export const _copySkuItem = (args: CopySkuItemArgs): SkuItem => {
	const { newShopId, skuItem, skuCodes } = args;
	const { skuName, itemType } = skuItem;
	return getNewSkuItem({
		id: newFirestoreId(),
		name: skuName,
		skuCode: nameToSkuCode(skuName, skuCodes),
		shopId: newShopId,
		itemType,
		fromTemplate: true,
	});
};

export const copySkuItems = (args: CopySkuItemsArgs): { [oldSkuId: string]: SkuItem } => {
	const { skuItems, newShopId, existingSkuCodes = [] } = args;
	const skuCodes = existingSkuCodes;
	const newSkuItems = skuItems.map((skuItem) => {
		const skuItemCopy = _copySkuItem({ newShopId, skuItem, skuCodes });
		skuCodes.push(skuItemCopy.skuCode);
		return skuItemCopy;
	});
	const oldSkuIdToNewSkuItemMap: { [skuId: string]: SkuItem } = newSkuItems.reduce(
		(acc, sku, index) => {
			acc[skuItems[index].id] = sku;
			return acc;
		},
		{},
	);
	return oldSkuIdToNewSkuItemMap;
};

export const _copyInventoryItem = (args: CopyInventoryItemArgs) => {
	const { inventoryItem, identifiers, skuItem, newShopId, locationId } = args;
	switch (inventoryItem.type) {
		case 'bulk':
			const newBulkItem = getNewInventoryItem({
				id: newFirestoreId(),
				skuCode: skuItem?.skuCode ?? '',
				skuId: skuItem?.id ?? '',
				shopId: newShopId,
				locationId: locationId,
				fieldValues: {},
				skuName: skuItem?.skuName ?? '',
				type: 'bulk',
				quantity: inventoryItem.quantity,
				statuses: inventoryItem.statuses,
				allocations: inventoryItem.allocations,
				fixedFieldValues: inventoryItem.fixedFieldValues,
				identifier: inventoryItem.skuId
					? _getUniqueSkuIdentifiers(
							skuItem?.skuName ?? '',
							identifiers,
							inventoryItem.identifiers.length,
					  )
					: null,
				fromTemplate: true,
			});
			return newBulkItem;
		case 'single':
			const newSingleItem = getNewInventoryItem({
				id: newFirestoreId(),
				skuCode: skuItem?.skuCode ?? '',
				skuId: skuItem?.id ?? '',
				shopId: newShopId,
				locationId: locationId,
				fieldValues: {},
				skuName: skuItem?.skuName ?? '',
				type: 'single',
				fixedFieldValues: inventoryItem.fixedFieldValues,
				status: inventoryItem.status,
				allocation: inventoryItem.allocation,
				identifier: inventoryItem.skuId
					? _getUniqueSkuIdentifiers(
							skuItem?.skuName ?? '',
							identifiers,
							inventoryItem.identifiers.length,
					  )
					: [],
				fromTemplate: true,
			});
			return newSingleItem;
		default:
			return switchUnreachable(inventoryItem);
	}
};

export const copyInventoryItems = (args: CopyInventoryItemsArgs): InventoryItem[] => {
	const {
		inventoryItems,
		newShopId,
		locationId,
		oldSkuIdToNewSkuItemMap,
		existingIdentifiers = [],
	} = args;
	const identifiers = existingIdentifiers;
	return inventoryItems
		.map((inventoryItem) => {
			const skuItem = inventoryItem.skuId
				? oldSkuIdToNewSkuItemMap[inventoryItem.skuId]
				: undefined;
			const inventoryItemCopy = _copyInventoryItem({
				inventoryItem,
				newShopId,
				skuItem,
				identifiers,
				locationId,
			});
			inventoryItemCopy.identifiers.forEach((identifier) => identifiers.push(identifier));
			return inventoryItemCopy;
		})
		.filter(notNull);
};

export const copyImagesToNewFolderAsPublicImages = async (args: CopyImagesArgs) => {
	const { storage, imageContainer, oldFolderId, newFolderId } = args;
	const [oldImages] = await storage
		.bucket()
		.getFiles({ autoPaginate: false, prefix: `${imageContainer}/${oldFolderId}/` });

	return await oldImages.reduce(async (imageMap, file) => {
		const oldUrl = file.publicUrl();
		const shortUrl = `${imageContainer}/${newFolderId}/template_img_${newId(10)}`;
		const [fileCopy] = await file.copy(shortUrl);

		await fileCopy.makePublic();
		const fileCopyUrl = fileCopy.publicUrl();
		return {
			...(await imageMap),
			[oldUrl]: fileCopyUrl,
		};
	}, {} as Promise<{ [oldUrl: string]: string }>);
};
