import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.ACCOUNT_PAYMENTS,
			completeOnClick: true,
		}),
		content: {
			title: t('homeView.onboarding.onlinePayments.title', 'Online payments'),
			descriptions: [
				t(
					'homeView.onboarding.onlinePayments.description',
					'Accept one-time and recurring online payments, security deposits and send payment links',
				),
			],
			cta: t('homeView.onboarding.onlinePayments.cta', 'Set up online payments'),
		},
		id: 'PAYMENTS',
		type: 'card',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.ACCOUNT_PAYMENTS,
			completeOnClick: true,
		}),
		content: {
			title: t(
				'homeView.onboarding.setUpOnlinePaymentMethods',
				'Set up your online payment methods to accept pre-paid orders',
			),
		},
		id: 'PAYMENTS',
		type: 'row',
	},
});

export const PAYMENTS_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'PAYMENTS',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		PAYMENTS: {
			V3_USERFLOW: versions['V1'],
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
