import React from 'react';

import { ButtonBaseProps, IconButton, Tooltip, Typography } from '@mui/material';
import { User01 } from '@untitled-ui/icons-react';

export interface Props extends ButtonBaseProps {
	label: string;
}
const AccountMenuButton = (props: Props) => {
	const { label } = props;
	const initials = label
		.split(' ')
		.map((n) => n[0])
		.join('')
		.toUpperCase();

	return (
		<Tooltip placement="right" title={label} arrow enterDelay={300} leaveDelay={0}>
			<IconButton
				onClick={props.onClick}
				sx={{ background: (theme) => theme.palette.background.default }}
			>
				{!!initials.length ? <Typography variant="body1">{initials}</Typography> : <User01 />}
			</IconButton>
		</Tooltip>
	);
};

export default AccountMenuButton;
