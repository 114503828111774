import analytics from './ANALYTICS/definition';
import branding from './BRANDING/definition';
import categoryTerms from './CATEGORY_TERMS/definition';
import cloudPOS from './CLOUD_POS/definition';
import dashboard from './DASHBOARD/definition';
import dinSettings from './DIN_SETTINGS/definition';
import discountCodes from './DISCOUNT_CODES/definition';
import extensions from './EXTENSIONS/definition';
import maintenanceTime from './MAINTENANCE_TIME/definition';
import manualPaymentMethods from './MANUAL_PAYMENT_METHODS/definition';
import massReturn from './MASS_RETURN/definition';
import packageProducts from './PACKAGE_PRODUCTS/definition';
import productVariants from './PRODUCT_VARIANTS/definition';
import skidata from './SKIDATA/definition';
import tekso from './TEKSO/definition';
import { AddOn, AddOnDefinition, AddOns } from './types';

export const AddOnDefinitions: Record<AddOn, AddOnDefinition> = {
	[AddOns.EXTENSIONS]: extensions,
	[AddOns.GOOGLE_ANALYTICS]: analytics,
	[AddOns.BRANDING]: branding,
	[AddOns.PACKAGE_PRODUCTS]: packageProducts,
	[AddOns.PRODUCT_VARIANTS]: productVariants,
	[AddOns.MASS_RETURN]: massReturn,
	[AddOns.DIN_SETTINGS]: dinSettings,
	[AddOns.DISCOUNT_CODES]: discountCodes,
	[AddOns.MAINTENANCE_TIME]: maintenanceTime,
	[AddOns.CATEGORY_TERMS]: categoryTerms,
	[AddOns.MANUAL_PAYMENT_METHODS]: manualPaymentMethods,
	[AddOns.DASHBOARD]: dashboard,
	[AddOns.SKIDATA]: skidata,
	[AddOns.TEKSO]: tekso,
	[AddOns.CLOUD_POS]: cloudPOS,
};
