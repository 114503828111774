import { TFunction } from 'i18next';

import { SkuItem } from 'common/modules/inventory';
import { PLAN_TIERS } from 'common/modules/plans/plans/constants';
import { isRentleSupportFromUser } from 'common/modules/users/utils';
import { DeliveryLocation, ISOString, Languages, Location, UserApi } from 'common/types';
import { switchUnreachable } from 'common/utils/common';

import { PlanDefinitions } from '.';
import {
	FreePlan,
	FreePlans,
	PaidPlan,
	PaidPlans,
	Plan,
	PlanDefinition,
	Plans,
	PurchasedPlan,
	PurchasedPlanConfigurableValue,
	PurchasedPlanWithSubscription,
} from './types';

export const isPlanWithSubscription = (
	plan: PurchasedPlan,
): plan is PurchasedPlanWithSubscription => {
	return !!plan.subscription;
};

export const getDefaultPurchasedPlan = (
	plan: Plan,
	startDate: ISOString = new Date().toISOString(),
): PurchasedPlan => {
	return {
		plan,
		startDate,
		pricing: {
			type: 'default',
		},
		additionalStorePricing: {
			type: 'default',
		},
		blockSlots: {
			type: 'default',
		},
	};
};

export const getPlanInfo = (args: {
	plan?: Plan;
	t: TFunction;
}): { name: string; description: string } => {
	const { plan, t } = args;
	switch (plan) {
		case 'LITE':
			return {
				name: 'LITE',
				description: t('shop.billingView.liteInfo', 'Improve your online offering in minutes.'),
			};
		case 'ENTERPRISE':
			return {
				name: 'Enterprise',
				description: '',
			};
		case 'BASIC':
			return {
				name: 'Basic',
				description: '',
			};
		case 'GROW':
			return {
				name: 'Grow',
				description: '',
			};
		case 'ADVANCED':
			return {
				name: 'Advanced',
				description: '',
			};
		case 'DEMO': {
			return {
				name: 'Demo',
				description: '',
			};
		}
		case 'INTERNAL': {
			return {
				name: 'Internal',
				description: '',
			};
		}
		case 'FREE': {
			return {
				name: 'Free',
				description: '',
			};
		}
		case 'DISCOVER': {
			return {
				name: 'Discover',
				description: '',
			};
		}
		case 'BUILD': {
			return {
				name: 'Build',
				description: '',
			};
		}
		case 'ADVANCE': {
			return {
				name: 'Advance',
				description: '',
			};
		}
		default:
			return {
				name: '',
				description: '',
			};
	}
};

export const isLegacyPlan = (plan: Plan): boolean => {
	return !!PlanDefinitions[plan].legacy;
};

export const isLegacySubscriptionPlan = (plan: Plan): boolean => {
	return isSubscriptionPlan(plan) && isLegacyPlan(plan);
};

export const planHasRevenueDiscount = (plan: Plan): boolean => {
	return isLegacySubscriptionPlan(plan);
};

export const isSubscriptionPlan = (plan: Plan): boolean => {
	const planDefinition = PlanDefinitions[plan];
	switch (planDefinition.billing.type) {
		case 'none':
			return false;
		case 'subscription-per-store':
		case 'price-per-additional-store':
		case 'fixed-store-count':
			return true;
		default: {
			return switchUnreachable(planDefinition.billing);
		}
	}
};

export const isPurchaseablePlan = (plan: Plan): boolean => {
	const planDefinition = PlanDefinitions[plan];

	return !!planDefinition.public;
};

export const ALL_PLANS = Object.values(Plans);
export const SUBSCRIPTION_PLANS = ALL_PLANS.filter(isSubscriptionPlan);

export const isNewSubscriptionPlan = (plan: Plan): boolean => {
	return isSubscriptionPlan(plan) && !isLegacyPlan(plan);
};

export const isFreePlan = (plan: Plan): plan is FreePlan => {
	return plan in FreePlans;
};

export const isPaidPlan = (plan: Plan): plan is PaidPlan => {
	return plan in PaidPlans;
};

export const isFreemiumPlan = (plan: Plan): boolean => {
	return plan === Plans.FREE || plan === Plans.LITE;
};

export const planHasAdditionalStorePrice = (plan: PlanDefinition): boolean => {
	return plan.billing.type === 'price-per-additional-store';
};

export const getPlanName = (plan: Plan): string => {
	return PlanDefinitions[plan].name;
};

/**
 * @deprecated We shouldn't use conditional logic based on plan names - let's instead check the restrictions/features needed for the specific case
 */
export const isLitePlan = (plan: Plan): boolean => {
	return plan === Plans.LITE;
};

/**
 * @deprecated We shouldn't use conditional logic based on plan tiers - they are not always so clearly defined
 */
export const isSameOrHigherPlan = (plan: Plan, compareTo: Plan): boolean => {
	const planTier = PLAN_TIERS[plan];
	const compareTier = PLAN_TIERS[compareTo];

	return planTier >= compareTier;
};

/**
 * @deprecated We shouldn't use conditional logic based on plan tiers - they are not always so clearly defined
 */
export const isLowerPlan = (plan: Plan, compareTo: Plan) => {
	const planTier = PLAN_TIERS[plan];
	const compareTier = PLAN_TIERS[compareTo];

	return planTier < compareTier;
};

/**
 * @deprecated We shouldn't use conditional logic based on plan tiers - they are not always so clearly defined
 */
export const isHigherPlan = (plan: Plan, compareTo: Plan) => {
	const planTier = PLAN_TIERS[plan];
	const compareTier = PLAN_TIERS[compareTo];

	return planTier > compareTier;
};

/**
 * @deprecated We shouldn't use conditional logic based on plan tiers - they are not always so clearly defined
 */
export const isPlanDowngrade = (args: {
	currentPlan: Plan | undefined;
	newPlan: Plan;
}): boolean => {
	const { currentPlan, newPlan } = args;
	if (!currentPlan) return false;

	return isLowerPlan(newPlan, currentPlan);
};

/**
 * @deprecated We shouldn't use conditional logic based on plan tiers - they are not always so clearly defined
 */
export const isPlanUpgrade = (args: { currentPlan: Plan | undefined; newPlan: Plan }): boolean => {
	const { currentPlan, newPlan } = args;
	if (!currentPlan) return false;

	return isHigherPlan(newPlan, currentPlan);
};

/**
 * @deprecated We shouldn't use conditional logic based on plan tiers - they are not always so clearly defined
 */
export const getPreviousPlanName = (plan: Plan) => {
	switch (plan) {
		case 'BASIC':
			return PlanDefinitions.LITE.name;
		case 'GROW':
			return PlanDefinitions.BASIC.name;
		case 'ADVANCED':
			return PlanDefinitions.GROW.name;
		default:
			return '';
	}
};

export const getPurchasedPlanConfigurableValue = (
	value: PurchasedPlanConfigurableValue | undefined,
	defaultValue: number,
): number => {
	switch (value?.type) {
		case 'custom':
			return value.amount;
		case 'unlimited':
			return Infinity;
		case 'default':
		default: {
			return defaultValue;
		}
	}
};

export const getPurchasedPlanUserCount = (purchasedPlan: PurchasedPlan | undefined): number => {
	if (!purchasedPlan) return 0;
	return getPurchasedPlanConfigurableValue(
		purchasedPlan.users,
		PlanDefinitions[purchasedPlan.plan].users,
	);
};

export const getCurrentUserCount = (users: UserApi[], shopId: string): number => {
	const usersWithoutRentleAccount = users.filter((user) => !isRentleSupportFromUser(user, shopId));
	return usersWithoutRentleAccount.length;
};

export const getRemainingUserCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	users: UserApi[];
	shopId: string;
}): number => {
	const { purchasedPlan, users, shopId } = args;
	const userCount = getPurchasedPlanUserCount(purchasedPlan);
	const currentUsers = getCurrentUserCount(users, shopId);
	const remainingCount = userCount - currentUsers;
	return Math.max(remainingCount, 0);
};

export const hasRemainingUserCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	users: UserApi[];
	shopId: string;
}): boolean => {
	const { purchasedPlan, users, shopId } = args;
	return getRemainingUserCount({ purchasedPlan, users, shopId }) > 0;
};

export const getPurchasedPlanLanguageCount = (purchasedPlan: PurchasedPlan | undefined): number => {
	if (!purchasedPlan) return 0;
	return getPurchasedPlanConfigurableValue(
		purchasedPlan.languages,
		PlanDefinitions[purchasedPlan.plan].languages,
	);
};

export const getCurrentLanguageCount = (languages: Languages[] | undefined): number => {
	if (!languages) return 0;
	return languages.length;
};

export const getRemainingLanguageCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	languages: Languages[] | undefined;
}): number => {
	const { purchasedPlan, languages } = args;
	const languageCount = getPurchasedPlanLanguageCount(purchasedPlan);
	const currentLanguages = getCurrentLanguageCount(languages);
	const remainingCount = languageCount - currentLanguages;
	return Math.max(remainingCount, 0);
};

export const hasRemainingLanguageCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	languages: Languages[] | undefined;
}): boolean => {
	const { purchasedPlan, languages } = args;
	return getRemainingLanguageCount({ purchasedPlan, languages }) > 0;
};

export const getPurchasedPlanStoreCount = (purchasedPlan: PurchasedPlan | undefined): number => {
	if (!purchasedPlan) return 0;
	const planDefinition = PlanDefinitions[purchasedPlan.plan];

	const canAddAdditionalStores = planDefinition.billing.type === 'price-per-additional-store';
	return canAddAdditionalStores
		? Infinity
		: getPurchasedPlanConfigurableValue(purchasedPlan.stores, planDefinition.stores);
};

export const getCurrentStoreCount = (locations: Location[]): number => locations.length;

export const getRemainingStoreCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	locations: Location[];
}): number => {
	const { purchasedPlan, locations } = args;
	const storeCount = getPurchasedPlanStoreCount(purchasedPlan);
	const currentStores = getCurrentStoreCount(locations);
	const remainingCount = storeCount - currentStores;
	return Math.max(remainingCount, 0);
};

export const getIncludedStoreCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
}): number => {
	const { purchasedPlan } = args;
	if (!purchasedPlan) return 0;

	return getPurchasedPlanConfigurableValue(
		purchasedPlan.stores,
		PlanDefinitions[purchasedPlan.plan].stores,
	);
};

export const getAdditionalStoreCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	totalStoreCount: number;
}): number => {
	const { purchasedPlan, totalStoreCount } = args;
	if (!purchasedPlan) return 0;

	const includedStoreCount = getIncludedStoreCount({ purchasedPlan });
	return Math.max(totalStoreCount - includedStoreCount, 0);
};

export const hasRemainingStoreCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	locations: Location[];
}): boolean => {
	const { purchasedPlan, locations } = args;
	return getRemainingStoreCount({ purchasedPlan, locations }) > 0;
};

export const getPurchasedPlanArticlesCount = (purchasedPlan: PurchasedPlan | undefined): number => {
	if (!purchasedPlan) return 0;
	return getPurchasedPlanConfigurableValue(
		purchasedPlan.articles,
		PlanDefinitions[purchasedPlan.plan].articles,
	);
};

export const getCurrentArticlesCount = (skuItems: SkuItem[] | undefined): number => {
	if (!skuItems) return 0;
	return skuItems.reduce((acc, skuItem) => acc + skuItem.quantity, 0);
};

export const getRemainingArticlesCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	skuItems: SkuItem[] | undefined;
}): number => {
	const { purchasedPlan, skuItems } = args;
	const articlesCount = getPurchasedPlanArticlesCount(purchasedPlan);
	const currentArticles = getCurrentArticlesCount(skuItems);
	const remainingCount = articlesCount - currentArticles;
	return Math.max(remainingCount, 0);
};

export const hasRemainingArticlesCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	skuItems: SkuItem[] | undefined;
}): boolean => {
	const { purchasedPlan, skuItems } = args;
	return getRemainingArticlesCount({ purchasedPlan, skuItems }) > 0;
};

export const getRemainingPickupLocationCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	deliveryLocations: DeliveryLocation[];
}) => {
	const { purchasedPlan, deliveryLocations } = args;
	const pickupLocationCount = getPurchasedPlanPickupLocationCount(purchasedPlan);
	const remainingCount = pickupLocationCount - deliveryLocations.length;
	return Math.max(remainingCount, 0);
};

export const getCurrentPickupLocationCount = (pickupLocations: DeliveryLocation[]) =>
	pickupLocations.length;

export const hasRemainingPickupLocationCount = (args: {
	purchasedPlan: PurchasedPlan | undefined;
	deliveryLocations: DeliveryLocation[];
}) => {
	const { purchasedPlan, deliveryLocations } = args;
	return getRemainingPickupLocationCount({ purchasedPlan, deliveryLocations }) > 0;
};

export const getPurchasedPlanPickupLocationCount = (
	purchasedPlan: PurchasedPlan | undefined,
): number => {
	if (!purchasedPlan) return 0;
	return getPurchasedPlanConfigurableValue(
		purchasedPlan.pickupLocations,
		PlanDefinitions[purchasedPlan.plan].pickupLocations,
	);
};
