import { SolteqReceiptObject } from 'common/types/solteq';

import { CallableCreator } from '..';

export type PosReceiptReq = {
	receiptObject: SolteqReceiptObject;
	integrationType: 'TEKSO' | 'CLOUD_POS' | 'CLOUD_POS_DIRECT';
};

export type PosReceiptRes = 'OK';

export const solteq = (createCallable: CallableCreator) => ({
	sendPosReceipt: createCallable<PosReceiptReq, PosReceiptRes>(
		'integrations-solteq-sendPosReceipt',
	),
	createMerchantSecret: createCallable<{ merchantId: string }, { secret: string; created: string }>(
		'integrations-solteq-createMerchantSecret',
	),
	deleteMerchantSecret: createCallable<{ merchantId: string }, void>(
		'integrations-solteq-deleteMerchantSecret',
	),
});
