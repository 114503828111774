import {
	applyActionCode as _applyActionCode,
	checkActionCode as _checkActionCode,
	confirmPasswordReset as _confirmPasswordReset,
	connectAuthEmulator as _connectAuthEmulator,
	createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail as _fetchSignInMethodsForEmail,
	onAuthStateChanged as _onAuthStateChanged,
	onIdTokenChanged as _onIdTokenChanged,
	signInWithCustomToken as _signInWithCustomToken,
	signInWithEmailAndPassword as _signInWithEmailAndPassword,
	signInWithPopup as _signInWithPopup,
	signInWithRedirect as _signInWithRedirect,
	signOut as _signOut,
	verifyPasswordResetCode as _verifyPasswordResetCode,
	getAuth,
} from 'firebase/auth';

import { RemoveFirstFromTuple } from 'common/types/utils';

import { firebaseApp } from './app';

export type { User } from 'firebase/auth';

export const auth = getAuth(firebaseApp);

const authWrapper = <T extends (...args: any) => any>(
	authFn: T,
): ((...args: RemoveFirstFromTuple<Parameters<T>>) => ReturnType<T>) => {
	return (...paramsWithoutAuth: RemoveFirstFromTuple<Parameters<T>>) =>
		authFn(auth, ...paramsWithoutAuth);
};

// Auth functions

export const onAuthStateChanged = authWrapper(_onAuthStateChanged);

export const onIdTokenChanged = authWrapper(_onIdTokenChanged);

export const signInWithCustomToken = authWrapper(_signInWithCustomToken);

export const signOut = authWrapper(_signOut);

export const signInWithEmailAndPassword = authWrapper(_signInWithEmailAndPassword);

export const signInWithPopup = authWrapper(_signInWithPopup);

export const signInWithRedirect = authWrapper(_signInWithRedirect);

export const createUserWithEmailAndPassword = authWrapper(_createUserWithEmailAndPassword);

export const applyActionCode = authWrapper(_applyActionCode);

export const checkActionCode = authWrapper(_checkActionCode);

export const verifyPasswordResetCode = authWrapper(_verifyPasswordResetCode);

export const confirmPasswordReset = authWrapper(_confirmPasswordReset);

export const fetchSignInMethodsForEmail = authWrapper(_fetchSignInMethodsForEmail);

export const connectAuthEmulator = () =>
	_connectAuthEmulator(auth, 'http://localhost:9099/', { disableWarnings: true });
