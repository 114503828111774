import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { RiCheckLine } from 'react-icons/ri';
import { makeStyles } from 'tss-react/mui';

import LinkButton from 'common/components/LinkButton';
import RentleLogo from 'common/components/RentleLogo';
import { applyActionCode } from 'common/frontend/firebase/auth';
import Spinner from 'components/Spinner';
import { useTranslation } from 'services/localization/useTranslation';
import { LoadingData } from 'services/types';
import { Routes } from 'routing';

import InvalidCode from './InvalidCode';

const VerifyEmail = ({ code }: { code: string }) => {
	const [validCode, setValidCode] = useState<LoadingData<boolean>>({
		loading: true,
		data: null,
		error: null,
	});
	const { classes } = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		const validateCode = async (code: string) => {
			try {
				await applyActionCode(code);
				setValidCode({
					loading: false,
					data: true,
					error: null,
				});
			} catch (e) {
				setValidCode({
					loading: false,
					data: false,
					error: null,
				});
			}
		};
		validateCode(code);
	}, [code]);

	return (
		<>
			<Grid container>
				<Grid item xs={12} lg={6}>
					{validCode.loading ? (
						<Spinner />
					) : (
						<div className={classes.container}>
							<RentleLogo width={111} type="black" />
							<Box mt={11} mb={6.5}>
								{validCode.data ? (
									<>
										<div className={classes.doneWrapper}>
											<RiCheckLine size={56} className={classes.checkIcon} />
											<Typography variant="h6">
												{t('authActions.emailVerified', 'Your email has been verified')}!
											</Typography>
										</div>
										<Box mt={4} />
										<LinkButton fullWidth variant="contained" color="primary" to={Routes.ROOT}>
											<Typography variant="caption">
												{t('authActions.backHome', 'Back to home page')}
											</Typography>
										</LinkButton>
									</>
								) : (
									<InvalidCode />
								)}
							</Box>
						</div>
					)}
				</Grid>
			</Grid>
		</>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	container: {
		paddingTop: theme.spacing(11),
		paddingBottom: theme.spacing(11),
		maxWidth: '315px',
		margin: '0 auto',
		width: '100%',
	},
	doneWrapper: {
		display: 'flex',
		margin: '16px auto',
		alignItems: 'center',
	},
	checkIcon: {
		color: 'green',
		marginRight: 16,
	},
}));

export default VerifyEmail;
