import { TimePeriodApi, TimePriodDb } from './types';

export const toApi = (data: TimePriodDb): TimePeriodApi => {
	const mapper: Record<TimePriodDb, TimePeriodApi> = {
		minutes: 'minutes',
		hours: 'hours',
		days: 'days',
		weeks: 'weeks',
		months: 'months',
		days_within_opening_hours: 'days_within_opening_hours',
		'24h_days': 'days',
		years: 'years',
	};
	return mapper[data];
};

export const toDb = (data: TimePeriodApi): TimePriodDb => {
	const mapper: Record<TimePeriodApi, TimePriodDb> = {
		minutes: 'minutes',
		hours: 'hours',
		days: '24h_days',
		weeks: 'weeks',
		months: 'months',
		days_within_opening_hours: 'days_within_opening_hours',
		years: 'years',
	};
	return mapper[data];
};
