import { CartProduct, OrderProduct, ProductApi, SetProduct } from 'common/types';

import { OrderProductSkuInfoBulk, OrderProductSkuInfoSingle } from './types';

export const isSkuInfoBulk = (
	skuInfo: OrderProductSkuInfoBulk | OrderProductSkuInfoSingle,
): skuInfo is OrderProductSkuInfoBulk => {
	return (skuInfo as OrderProductSkuInfoBulk).type === 'bulk';
};

export const isProductApi = (
	product: ProductApi | OrderProduct | SetProduct | CartProduct,
): product is ProductApi => {
	return (product as ProductApi).variants !== undefined;
};

export const isOrderProduct = (
	product: ProductApi | OrderProduct | SetProduct | CartProduct,
): product is OrderProduct =>
	!isProductApi(product) && !!(product as OrderProduct).summary && !!(product as OrderProduct).id;

export const isCartProduct = (
	product: ProductApi | OrderProduct | SetProduct | CartProduct,
): product is CartProduct =>
	!isProductApi(product) && !!(product as OrderProduct).summary && !(product as OrderProduct).id;

export const isSetProduct = (
	product: ProductApi | OrderProduct | SetProduct | CartProduct,
): product is SetProduct => {
	return !isProductApi(product) && !isOrderProduct(product) && !isCartProduct(product);
};
