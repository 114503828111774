import { TFunction } from 'i18next';
import { clamp } from 'lodash';
import moment from 'moment-timezone';

import { OpeningHours, isStoreOpenForDay } from 'common/modules/openingHours';
import { DateFormatObject, ISOString } from 'common/types';
import { switchUnreachable } from 'common/utils/common';

import {
	MAX_AMOUNT_PER_BUFFER_TIME_UNIT,
	MAX_BUFFER_RANGE_DAYS,
	MAX_BUFFER_TIME_MINUTES,
} from './constants';
import {
	BufferTime,
	BufferTimeDayType,
	BufferTimeUnit,
	FixedBufferTime,
	RelativeBufferTime,
} from './types';

export const getBufferTimeAsMinutes = (args: {
	value: BufferTime | undefined;
	from: ISOString;
	direction: 'before' | 'after';
	timezone: string;
	openingHours: OpeningHours;
}): number => {
	if (!args.value) return 0;
	switch (args.value.type) {
		case 'none':
			return 0;
		case 'relative':
			return getRelativeBufferTimeAsMinutes({ ...args, value: args.value.value });
		case 'fixed':
			return getFixedBufferTimeAsMinutes({ ...args, value: args.value.value });
		default:
			return switchUnreachable(args.value);
	}
};

export const getRelativeBufferTimeAsMinutes = (args: {
	value: RelativeBufferTime | undefined;
	from: ISOString;
	direction: 'before' | 'after';
	openingHours: OpeningHours;
	timezone: string;
}): number => {
	const { from, timezone, value, direction, openingHours } = args;

	if (!value || value.days < 0) return 0;

	const { days: _days, dayType, timeOfDay } = value;
	const days = clamp(_days, 0, MAX_AMOUNT_PER_BUFFER_TIME_UNIT[value.dayType]);
	const [hours, minutes] = timeOfDay.split(':').map(Number);

	let daysTried = 0;
	let daysUsed = 0;

	let currentBufferEndDate = moment.tz(from, timezone).startOf('day').hours(hours).minutes(minutes);
	while (days !== 0 && daysTried < MAX_BUFFER_RANGE_DAYS) {
		daysTried++;

		const isCurrentDayValid = isDateValidForDayType(currentBufferEndDate, dayType, openingHours);

		if (isCurrentDayValid && daysUsed >= days) {
			break;
		}

		const nextDate =
			direction === 'before'
				? moment(currentBufferEndDate).subtract(1, 'day')
				: moment(currentBufferEndDate).add(1, 'day');

		const isNextDayValid = isDateValidForDayType(nextDate, dayType, openingHours);

		if (isNextDayValid) {
			daysUsed++;
		}

		currentBufferEndDate = nextDate;
	}

	if (daysTried >= MAX_BUFFER_RANGE_DAYS) {
		return moment.duration(MAX_BUFFER_TIME_MINUTES, 'minutes').asMinutes();
	}

	const difference =
		direction === 'before'
			? moment(from).diff(currentBufferEndDate, 'minutes')
			: moment(currentBufferEndDate).diff(from, 'minutes');

	return Math.max(difference, 0);
};

export const getFixedBufferTimeAsMinutes = (args: {
	value: FixedBufferTime | undefined;
	from: ISOString;
	direction: 'before' | 'after';
	openingHours: OpeningHours;
}): number => {
	const { value, from, direction, openingHours } = args;

	if (!value) return 0;

	const { unit, amount: _amount } = value;
	const amount = clamp(_amount, 0, MAX_AMOUNT_PER_BUFFER_TIME_UNIT[value.unit]);

	if (amount === 0) return 0;

	switch (unit) {
		case 'minutes': {
			return amount;
		}
		case 'hours': {
			return moment.duration().add(amount, 'hours').asMinutes();
		}
		case 'days':
			return moment.duration().add(amount, 'days').asMinutes();
		case 'openingDays':
		case 'weekDays':
			let daysUsed = 0;
			let daysTried = 0;

			let currentBufferEndDate =
				direction === 'before' ? moment(from).subtract(1, 'day') : moment(from).add(1, 'day');

			while (daysTried < MAX_BUFFER_RANGE_DAYS) {
				daysTried++;
				const isCurrentDayValid = isDateValidForDayType(currentBufferEndDate, unit, openingHours);

				if (isCurrentDayValid) {
					daysUsed++;
				}

				if (daysUsed >= amount) {
					break;
				}

				switch (direction) {
					case 'before':
						currentBufferEndDate.subtract(1, 'day');
						break;
					case 'after':
						currentBufferEndDate.add(1, 'day');
						break;
					default:
						break;
				}
			}

			if (daysTried >= MAX_BUFFER_RANGE_DAYS) {
				return moment.duration(MAX_BUFFER_TIME_MINUTES, 'minutes').asMinutes();
			}

			if (direction === 'before') {
				currentBufferEndDate = currentBufferEndDate.startOf('day');
			} else {
				currentBufferEndDate = currentBufferEndDate.endOf('day');
			}

			return Math.abs(currentBufferEndDate.diff(from, 'minutes'));
		default:
			return switchUnreachable(unit);
	}
};

export const isDateValidForDayType = (
	date: moment.Moment,
	dayType: BufferTimeDayType,
	openingHours: OpeningHours,
) => {
	switch (dayType) {
		case 'days':
			return true;
		case 'weekDays':
			const isoWeekday = date.isoWeekday();
			return isoWeekday >= 1 && isoWeekday <= 5;
		case 'openingDays':
			return isStoreOpenForDay({
				date: date.format('YYYY-MM-DD'),
				openingHours,
			});
		default:
			return switchUnreachable(dayType);
	}
};

export const getBufferTimeAsString = (args: {
	bufferTime: BufferTime | undefined;
	direction: 'before' | 'after' | null;
	shopDateFormat: DateFormatObject;
	t: TFunction;
}): string => {
	const { bufferTime, direction, shopDateFormat, t } = args;
	if (!bufferTime) return '';
	const { type } = bufferTime;

	switch (type) {
		case 'fixed': {
			const { value } = bufferTime;
			if (!bufferTime.value.amount) return '';
			const unitString = getBufferTimeUnitLabel({ unit: value.unit, amount: value.amount, t });

			switch (direction) {
				case 'before':
					return t('common:times.timeUnitsBefore', {
						amount: value.amount,
						units: unitString,
						defaultValue: '{{amount}} {{units}} before',
					});
				case 'after':
					return t('common:times.timeUnitsAfter', {
						amount: value.amount,
						units: unitString,
						defaultValue: '{{amount}} {{units}} after',
					});
				default:
					return '';
			}
		}
		case 'relative': {
			const { value } = bufferTime;
			const timeOfDayString = moment(value.timeOfDay, 'HH:mm').format(shopDateFormat.timeFormat);
			const dayTypeLabel = getBufferTimeUnitLabel({ unit: value.dayType, amount: value.days, t });

			switch (direction) {
				case 'before':
					if (value.days === 0) {
						return t('common:times.sameDayByTime', {
							hhMM: timeOfDayString,
							defaultValue: 'Same day by {{hhMM}}',
						});
					}

					if (value.days === 1) {
						return t('common:times.previousDayByTime', {
							hhMM: timeOfDayString,
							defaultValue: 'Previous day by {{hhMM}}',
						});
					}

					return t('common:times.daysBeforeByTime', {
						amount: value.days,
						days: dayTypeLabel,
						hhMM: timeOfDayString,
						defaultValue: '{{amount}} {{days}} before by {{hhMM}}',
					});
				case 'after':
					if (value.days === 0) {
						return t('common:times.sameDayByTime', {
							hhMM: timeOfDayString,
							defaultValue: 'Same day by {{hhMM}}',
						});
					}

					if (value.days === 1) {
						return t('common:times.nextDayByTime', {
							hhMM: timeOfDayString,
							defaultValue: 'Next day by {{hhMM}}',
						});
					}
					return t('common:times.daysAfterByTime', {
						amount: value.days,
						days: dayTypeLabel,
						hhMM: timeOfDayString,
						defaultValue: '{{amount}} {{days}} after by {{hhMM}}',
					});
				default:
					return '';
			}
		}
		case 'none':
		default:
			return '';
	}
};

export const getBufferTimeUnitLabel = (args: {
	unit: BufferTimeUnit;
	amount: number;
	t: TFunction;
}) => {
	const { unit, amount, t } = args;
	const isPlural = amount !== 1;
	switch (unit) {
		case 'days': {
			return isPlural ? t('common:times.days', 'days') : t('common:times.day', 'day');
		}
		case 'openingDays': {
			return isPlural
				? t('common:times.openingDays', 'opening days')
				: t('common:times.openingDay', 'opening day');
		}
		case 'weekDays': {
			return isPlural
				? t('common:times.weekdays', 'weekdays')
				: t('common:times.weekday', 'weekday');
		}
		case 'hours':
			return isPlural ? t('common:times.hours', 'hours') : t('common:times.hour', 'hour');
		case 'minutes':
			return isPlural ? t('common:times.minutes', 'minutes') : t('common:times.minute', 'minute');
		default:
			try {
				switchUnreachable(unit);
			} catch (err) {}
			return '';
	}
};

export const getBufferTimeUnitMaxAmount = (unit: BufferTimeUnit): number => {
	return MAX_AMOUNT_PER_BUFFER_TIME_UNIT[unit] ?? 0;
};

export const clampBufferTimeUnitAmount = (unit: BufferTimeUnit, amount: number): number => {
	return clamp(amount, 0, getBufferTimeUnitMaxAmount(unit));
};
