import * as React from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface ContainerProps {
	className?: any;
	style?: any;
	children?: any;
	id?: string;
	paddingBottom?: boolean;
	fluid?: boolean;
	densePaddingTop?: boolean;
	noVerticalPadding?: boolean;
}

const Container = ({
	id,
	className,
	style,
	children,
	paddingBottom,
	densePaddingTop,
	noVerticalPadding,
	fluid,
}: ContainerProps) => {
	const { classes, cx } = useStyles();
	return (
		<div
			id={id}
			className={cx(
				classes.root,
				className,
				paddingBottom ? classes.paddingBottom : {},
				densePaddingTop ? classes.densePaddingTop : {},
				fluid ? classes.fluid : {},
				noVerticalPadding ? classes.noVerticalPadding : {},
			)}
			style={style}
		>
			{children}
		</div>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	root: {
		padding: '32px 16px 56px 16px',
		width: '100%',
		flex: '1 1 auto',
		[theme.breakpoints.up('sm')]: {
			padding: '48px 32px 64px 32px',
		},
		[theme.breakpoints.up('md')]: {
			padding: '64px 64px 80px 64px',
		},
		[theme.breakpoints.up(1408)]: {
			maxWidth: '1280px',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paddingBottom: {
		/* To be 32px padding to bottom btn */
		paddingBottom: 'calc(99px + 100vh*0.05)',
	},
	fluid: {
		[theme.breakpoints.up(1408)]: {
			maxWidth: 'unset',
			padding: '64px 64px 80px 64px',
		},
	},
	densePaddingTop: {
		paddingTop: 16,
	},
	noVerticalPadding: {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
	},
}));

export default Container;
