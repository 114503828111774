import { store } from 'ReduxRoot';
import { Location, createBrowserHistory } from 'history';

import WebSessionCounter from 'common/frontend/helpers/SessionCounter';
import * as Analytics from 'common/services/analytics';
import { websiteGaTag } from 'common/services/analytics';
import * as IntercomService from 'common/services/analytics/intercom';
import * as PosthogService from 'common/services/analytics/posthog';
import errorHandler from 'common/services/errorHandling/errorHandler';

const history = createBrowserHistory();
const env = process.env.REACT_APP_ENV;
let prevLocation: undefined | Location<unknown>;

// For first pageview that is not tracked ly "listen" event
const setInitialPageView = () => {
	// Add timeout so Firebase analytics have time to initialize
	setTimeout(() => {
		Analytics.pageView(window.location.pathname, 'ADMIN', websiteGaTag);
	}, 2500);
};

history.listen((location) => {
	const isNewClientUpdate = store.getState().view.newClientUpdate;
	if (env !== 'local') {
		try {
			IntercomService.update({ last_request_at: new Date().getTime() / 1000 });
		} catch (e) {
			errorHandler.report(e);
		}
	}
	WebSessionCounter.update();
	Analytics.pageView(location.pathname, 'ADMIN', websiteGaTag);
	const isDifferentLocation = prevLocation?.pathname !== location.pathname;
	if (isDifferentLocation) {
		PosthogService.logPageView(location.pathname);
		(window as any).growthbook?.refresh();
	}
	if (isNewClientUpdate) {
		const mainCurrentLocationPath = location.pathname.split('/')[1];
		const mainPrevLocationPath = prevLocation?.pathname.split('/')[1];
		const differentMainLocationPath =
			!mainPrevLocationPath || mainCurrentLocationPath !== mainPrevLocationPath;
		if (differentMainLocationPath) {
			window.location.reload();
		}
	}
	prevLocation = location;
});

setInitialPageView();

export default history;
