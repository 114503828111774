import { ChannelApi, ChannelDb } from './types';

export const toApi = <T extends ChannelDb>(data: T): typeof apiMapper[T] => {
	/**
	 * Kept for reference and to to show TS error in case we add new Channels. The maps should be kept in sync.
	 * When we upgrade to TS 4.9.4 these can be replaced with:
	 * const apiMapper = {
		ADMIN: 'admin',
		ONLINE: 'online',
		STORE: 'check_in',
		PAYLINK: 'booking_link',
		CONSUMER: 'manage_booking',
	} satisfies Record<ChannelDb, ChannelApi>;
	 */
	const _apiMapper: Record<ChannelDb, ChannelApi> = {
		ADMIN: 'admin',
		ONLINE: 'online',
		STORE: 'check_in',
		PAYLINK: 'booking_link',
		CONSUMER: 'manage_booking',
		API: 'api',
	};
	const apiMapper = {
		ADMIN: 'admin',
		ONLINE: 'online',
		STORE: 'check_in',
		PAYLINK: 'booking_link',
		CONSUMER: 'manage_booking',
		API: 'api',
	} as const;
	return apiMapper[data];
};

export const toDb = <T extends ChannelApi>(data: T): typeof dbMapper[T] => {
	/**
	 * Kept for reference and to to show TS error in case we add new Channels. The maps should be kept in sync.
	 * When we upgrade to TS 4.9.4 these can be replaced with:
	 * const dbMapper = {
		admin: 'ADMIN',
		online: 'ONLINE',
		check_in: 'STORE',
		booking_link: 'PAYLINK',
		manage_booking: 'CONSUMER',
	} satisfies Record<ChannelApi, ChannelDb>;
	*/
	const _dbMapper: Record<ChannelApi, ChannelDb> = {
		admin: 'ADMIN',
		online: 'ONLINE',
		check_in: 'STORE',
		booking_link: 'PAYLINK',
		manage_booking: 'CONSUMER',
		api: 'API',
	};
	const dbMapper = {
		admin: 'ADMIN',
		online: 'ONLINE',
		check_in: 'STORE',
		booking_link: 'PAYLINK',
		manage_booking: 'CONSUMER',
		api: 'API',
	} as const;
	return dbMapper[data];
};
