import { getOpenApiExample } from 'common/utils/openApi';

import { zCustomerDetailsApi } from './api';
import { toApi } from './mapper';

export const CustomerDetails = {
	api: zCustomerDetailsApi,
	toApi,
	example: getOpenApiExample(zCustomerDetailsApi),
};
