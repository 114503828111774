import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t('account.addOns.valueProps.massReturn.scanAndReturn', 'Scan and return'),
	},
	{
		description: i18n.t('account.addOns.valueProps.massReturn.visualUI', 'Visual UI'),
	},
];

export default valueProps;
