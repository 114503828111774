import { HH_MM } from '../times';

/**
 * A date in ISO 8601 format
 */
export type ISOString = string;

/**
 * A date in YYYY-MM-DD format
 */
export type YYYY_MM_DD = string;

export enum ISOWeekdays {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 7,
}
export type ISOWeekday =
	| ISOWeekdays.Monday
	| ISOWeekdays.Tuesday
	| ISOWeekdays.Wednesday
	| ISOWeekdays.Thursday
	| ISOWeekdays.Friday
	| ISOWeekdays.Saturday
	| ISOWeekdays.Sunday;

export type FirstDayOfWeek = ISOWeekdays.Monday | ISOWeekdays.Saturday | ISOWeekdays.Sunday;

/**
 * A timezone-agnostic date and time
 */
export type DateAndTime = {
	date: YYYY_MM_DD;
	time: HH_MM;
};

/**
 * A type of day
 */
export type DayType =
	| 'allDays'
	| 'weekDays'
	| 'weekends'
	| 'monday'
	| 'tuesday'
	| 'wednesday'
	| 'thursday'
	| 'friday'
	| 'saturday'
	| 'sunday';
