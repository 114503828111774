import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { ShopTemplate } from 'common/modules/shopTemplates';

import { buildQuery, createBasicCollectionOperations, createBasicDbOperations } from '../utils';

export const getShopTemplatesQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.shopTemplates;
	const query = buildQuery<ShopTemplate>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<ShopTemplate>(collectionPath, dbRefs),
		...createBasicCollectionOperations<ShopTemplate>(collectionPath, dbRefs),
	};
};
