import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.ACCOUNT_TERMS,
			routeHash: '#cancellation-policy',
		}),
		content: {
			title: t('homeView.onboarding.addACancellationPolicy', 'Add a cancellation policy'),
		},
		id: 'CANCELLATION_POLICY',
		type: 'row',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.ACCOUNT_TERMS,
			routeHash: '#cancellation-policy',
		}),
		content: {
			title: t(
				'homeView.onboarding.addCancellationPolicyForCustomersOrders',
				'Add a cancellation policy for your customers’ orders',
			),
		},
		id: 'CANCELLATION_POLICY',
		type: 'row',
	},
});

export const CANCELLATION_POLICY_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'CANCELLATION_POLICY',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		CANCELLATION_POLICY: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
