import { Plan, Plans } from 'common/modules/plans/plans/types';

/**
 * @deprecated We should not build any logic based on plan tiers - all logic using these should
 * be refactored
 */
export const PLAN_TIERS: Record<Plan, number> = {
	[Plans.LITE]: 0,
	[Plans.FREE]: 0,
	[Plans.BASIC]: 1,
	[Plans.DISCOVER]: 1,
	[Plans.GROW]: 2,
	[Plans.BUILD]: 2,
	[Plans.ADVANCED]: 3,
	[Plans.ADVANCE]: 3,
	[Plans.DEMO]: 3,
	[Plans.INTERNAL]: 3,
	[Plans.ENTERPRISE]: 4,
};

export const ADVANCE_AND_ABOVE: Plan[] = [
	Plans.ADVANCE,
	Plans.ENTERPRISE,
	Plans.DEMO,
	Plans.INTERNAL,
];
export const BUILD_AND_ABOVE: Plan[] = [Plans.BUILD, ...ADVANCE_AND_ABOVE];
export const DISCOVER_AND_ABOVE: Plan[] = [Plans.DISCOVER, ...BUILD_AND_ABOVE];
export const FREE_AND_ABOVE: Plan[] = [Plans.FREE, ...DISCOVER_AND_ABOVE];
/**
 * @deprecated Legacy plans
 */
export const ADVANCED_AND_ABOVE: Plan[] = [
	Plans.ADVANCED,
	Plans.ENTERPRISE,
	Plans.DEMO,
	Plans.INTERNAL,
];
/**
 * @deprecated Legacy plans
 */
export const GROW_AND_ABOVE: Plan[] = [Plans.GROW, ...ADVANCED_AND_ABOVE];
/**
 * @deprecated Legacy plans
 */
export const BASIC_AND_ABOVE: Plan[] = [Plans.BASIC, ...GROW_AND_ABOVE];
/**
 * @deprecated Legacy plans
 */
export const LITE_AND_ABOVE: Plan[] = [Plans.LITE, ...BASIC_AND_ABOVE];
