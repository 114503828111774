import { api } from 'common/frontend/api';
import { UserApi } from 'common/types';

export const getShopUsersListener = (
	shopId: string,
	callback: (users: UserApi[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	return api()
		.users.get.where(`shops.${shopId}` as any, '!=', null)
		.onSnapshot((users) => {
			const accessedUsers = users.filter((user) => !!user.access?.[shopId]);
			callback(accessedUsers);
		}, errorCallback);
};
