import {
	QueryKey,
	UseMutationOptions,
	UseQueryOptions,
	useMutation,
	useQuery,
} from '@tanstack/react-query';

import { notNullish } from 'common/utils/common';

/**
 * Helper to create query keys from function arguments for react-query.
 * The query key library expects a non-empty value in an array to be returned,
 * that's why the default to `[false]` in case of empty args
 */
export const fnQueryKeys = <T extends (args: any) => any>(fn: T) => {
	return (...arg: Parameters<typeof fn>) => {
		const nonNullishArg = arg.filter(notNullish);
		return !nonNullishArg.length ? ([false] as const) : ([...nonNullishArg] as any);
	};
};

/**
 * Create a typed useQuery hook from a query function and a query key function
 *
 */
export const createUseQuery = <
	TQueryFn extends (args: any) => Promise<any>,
	TQueryKeyFn extends (params: Parameters<TQueryFn>[0]) => { queryKey: QueryKey },
	TData = Awaited<ReturnType<TQueryFn>>
>(
	queryFn: TQueryFn,
	queryKeyFn: TQueryKeyFn,
	mapperFn?: (data: Awaited<ReturnType<TQueryFn>>) => TData,
) => {
	type TError = unknown;
	type TQueryKey = ReturnType<TQueryKeyFn>['queryKey'];

	return {
		useQuery: <TSelectData = TData>(
			params?: Parameters<TQueryFn>[0],
			options?: Omit<
				UseQueryOptions<TData, TError, TSelectData, TQueryKey>,
				'queryFn' | 'queryKey'
			>,
		) => {
			return useQuery<TData, TError, TSelectData, TQueryKey>(
				queryKeyFn(params).queryKey,
				async () => {
					const data = await queryFn(params);
					return mapperFn ? mapperFn(data) : data;
				},
				options,
			);
		},
	};
};

/**
 * Create a typed useMutation hook from a mutation function
 *
 * Still missing support for passing `options` when consuming the hook,
 * but we don't currently really have a use case for that.
 */
export const createUseMutation = <TMutationFn extends (args: any) => Promise<any>>(
	mutationFn: TMutationFn,
	options: UseMutationOptions<
		Awaited<ReturnType<TMutationFn>>,
		unknown,
		Parameters<TMutationFn>[0],
		unknown
	>,
) => {
	type TData = Awaited<ReturnType<TMutationFn>>;
	type TError = unknown;
	type TVariables = Parameters<TMutationFn>[0];
	type TContext = unknown;

	return {
		useMutation: () => {
			return useMutation<TData, TError, TVariables, TContext>(
				(params: Parameters<TMutationFn>[0]) => mutationFn(params),
				options,
			);
		},
	};
};

export const queryUpdater = <T extends object>(args: {
	condition: (data: T) => boolean;
	updates: Partial<T> | ((prev: T) => T);
}) => (prev: T | T[] | undefined) => {
	const { condition, updates } = args;
	if (!prev) return;
	if (Array.isArray(prev)) {
		return prev.map((item) => {
			if (!condition(item)) return item;
			if (typeof updates === 'function') return updates(item);
			return { ...item, ...updates };
		});
	}
	if (condition(prev)) {
		if (typeof updates === 'function') return updates(prev);
		return { ...prev, ...updates };
	}
	return;
};

export const queryDeleter = <T extends object>(args: { condition: (data: T) => boolean }) => (
	prev: T | T[] | undefined,
) => {
	const { condition } = args;
	if (!prev) return;
	if (Array.isArray(prev)) {
		return prev.filter((item) => !condition(item));
	}
	return;
};
