import { Environment } from 'common/types';

const devFunctionsBaseUrl = 'https://europe-west1-rentle-dev.cloudfunctions.net';

const prodFunctionsBaseUrl = 'https://europe-west1-rentle-prod.cloudfunctions.net';

const localFunctionsBaseUrl = 'http://localhost:5001/rentle-dev/europe-west1';

export const getFunctionsBaseUrl = (env: Environment = 'development') => {
	if (env === 'production') {
		return prodFunctionsBaseUrl;
	}
	if (env === 'development') {
		return devFunctionsBaseUrl;
	}
	if (env === 'local') {
		return localFunctionsBaseUrl;
	}
	return devFunctionsBaseUrl;
};

const projectId: string =
	process.env?.REACT_APP_FIREBASE_PROJECT_ID ??
	(!!process.env.FIREBASE_CONFIG && JSON.parse(process.env.FIREBASE_CONFIG).projectId) ??
	'rentle-dev';

export const getFirestoreApiBaseUrl = (useEmulator?: boolean) =>
	useEmulator
		? `http://localhost:8100/v1beta1/projects/${projectId}/databases/(default)/documents`
		: `https://firestore.googleapis.com/v1beta1/projects/${projectId}/databases/(default)/documents`;
