import React from 'react';

import { Box, Typography, styled } from '@mui/material';

export interface Props {
	title: string;
	text: string;
	innerRef?: (element: HTMLElement | null) => void;
}
const CtaCarouselItem = ({ text, title, innerRef }: Props) => {
	return (
		<Box position="absolute" ref={innerRef}>
			<Tag>{title}</Tag>
			<Typography variant="body1" sx={{ color: (theme) => theme.palette.primary.main }}>
				{text}
			</Typography>
		</Box>
	);
};

const Tag = styled(Typography)(({ theme }) => ({
	padding: theme.spacing(0, 2),
	backgroundColor: theme.palette.background.default,
	color: theme.palette.primary.main,
	width: 'fit-content',
	borderRadius: '50px',
	textAlign: 'center',
	letterSpacing: '-1px',
	fontWeight: 400,
	lineHeight: '40px',
	fontSize: '2.8rem',
	marginBottom: theme.spacing(3),
}));

export default CtaCarouselItem;
