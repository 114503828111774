import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const OPENING_HOURS_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'OPENING_HOURS',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	OPENING_HOURS: {
		all: {
			action: getRouteOnboardingStepItem({
				routeTo: Routes.StoreTab('opening-hours'),
			}),
			content: {
				title: t('homeView.onboarding.setUpOpeningHours', 'Set up opening hours'),
			},
			id: 'OPENING_HOURS',
			type: 'row',
		},
	},
});
