import React from 'react';

import { Menu, MenuItem, MenuItemProps, Stack, Typography, styled } from '@mui/material';
import { LogOut01 } from '@untitled-ui/icons-react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import useMenuState from 'common/hooks/useMenuState';
import * as UserActions from 'actions/UserActions';
import { Routes, push } from 'routing';

import AccountMenuButton from './AccountMenuButton';
import { HeaderMenuItem } from './HeaderMenuNEW';

interface AccountMenuProps {
	label: string;
	email: string;
	profileItems?: HeaderMenuItem[];
	resourcesItems?: HeaderMenuItem[];
}
const AccountMenu = (props: AccountMenuProps) => {
	const { profileItems, resourcesItems, label, email } = props;
	const { menuProps, menuActions } = useMenuState();
	const dispatch = useDispatch();

	const handleSignout = () => {
		dispatch(UserActions.logout());
		push(Routes.LOGIN);
	};

	const renderMenuItem = (item: HeaderMenuItem) => {
		const ItemIcon = item.icon;
		const contents = (
			<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
				{!!ItemIcon && <ItemIcon size="16px" />}
				<Typography variant="body2">{item.label}</Typography>
			</Stack>
		);
		const sharedProps: MenuItemProps<any, {}> = {
			key: item.label,
			selected: item.selected,
			className: item.className,
		};

		switch (item.type) {
			case 'external-link': {
				return (
					<MenuItem
						{...sharedProps}
						component="a"
						href={item.href}
						rel="nopener noreferrer"
						target="_blank"
						sx={{
							'&:last-child': {
								borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
							},
						}}
					>
						{contents}
					</MenuItem>
				);
			}
			case 'internal-link': {
				return (
					<MenuItem
						onClick={item.onClick}
						{...sharedProps}
						component={Link}
						to={item.route}
						sx={{
							'&:last-child': {
								borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
							},
						}}
					>
						{contents}
					</MenuItem>
				);
			}
			case 'button': {
				return (
					<MenuItem
						{...sharedProps}
						onClick={item.onClick}
						sx={{
							'&:last-child': {
								borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
							},
						}}
					>
						{contents}
					</MenuItem>
				);
			}
			default:
				return null;
		}
	};

	return (
		<Stack px={1}>
			<AccountMenuButton label={label} onClick={menuActions.open} />
			<StyledMenu {...menuProps} onClick={menuActions.close}>
				<Stack spacing={2}>
					<Stack
						spacing={1}
						direction="row"
						p={1}
						sx={{ borderBottom: (theme) => `solid 1px ${theme.palette.divider}` }}
						alignItems="center"
					>
						<AccountMenuButton label={label} />
						<Stack>
							<Typography variant="body1" sx={{ fontWeight: 600 }}>
								{label}
							</Typography>
							<Typography variant="body2" sx={{ fontWeight: 400 }}>
								{email}
							</Typography>
						</Stack>
					</Stack>
					<Stack>
						<Typography pl={2} variant="body2" sx={{ fontWeight: 600 }}>
							{'Profile'}
						</Typography>
						{profileItems?.map((item) => renderMenuItem(item))}
					</Stack>
					<Stack>
						<Typography pl={2} variant="body2" sx={{ fontWeight: 600 }}>
							{'Resources'}
						</Typography>
						{resourcesItems?.map((item) => renderMenuItem(item))}
					</Stack>
					<MenuItem onClick={handleSignout}>
						<Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
							<Stack sx={{ color: (theme) => theme.palette.error.light, fontSize: '16px' }}>
								<LogOut01 color="inherit" />
							</Stack>
							<Typography color="error" variant="body2">
								{'Log Out'}
							</Typography>
						</Stack>
					</MenuItem>
				</Stack>
			</StyledMenu>
		</Stack>
	);
};

const StyledMenu = styled(Menu)(({ theme }) => ({
	'& .MuiMenu-paper': {
		borderRadius: 8,
		background: theme.palette.background.default,
	},
}));

export default AccountMenu;
