import { PaymentApi } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { updateToNewPaymentTypes } from './dataUpdater';

export const getPaymentQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.payments;
	const dataUpdater = updateToNewPaymentTypes;
	const query = buildQuery<PaymentApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PaymentApi>(collectionPath, dbRefs, dataUpdater),
	};
};
