import { useCallback, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { OnboardingAction } from 'common/services/analytics/tractionAnalytics';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { isOnboardingActionDone, setOnboardingActionDone } from 'services/onboarding';

type UseOnboardingActionOptions = {
	completeOnMount: boolean;
};

type UseOnboardingAction = [boolean, () => void];

const useOnboardingAction = (
	_action: OnboardingAction | OnboardingAction[],
	options?: UseOnboardingActionOptions,
): UseOnboardingAction => {
	const shopAnalytics = useSelector(ShopSelectors.activeShopAnalytics);
	const actions = useMemo(() => (Array.isArray(_action) ? _action : [_action]), [_action]);
	const actionsDone = actions.every((action) => isOnboardingActionDone(action, shopAnalytics));

	const setDone = useCallback(() => {
		setOnboardingActionDone(actions, shopAnalytics);
	}, [actions, shopAnalytics]);

	useEffect(() => {
		if (options?.completeOnMount) {
			setDone();
		}
	}, [options?.completeOnMount, setDone]);

	return [actionsDone, setDone];
};

export default useOnboardingAction;
