import { Plans } from 'common/modules/plans/plans/types';

import { RentleProduct, RentleProductRef } from './types';

/**
 * Rentle plan products
 */

export const SubscriptionPlanProducts = {
	[RentleProductRef.BASIC_PLAN]: {
		ref: RentleProductRef.BASIC_PLAN,
		description: 'Rentle Basic subscription',
		planRef: Plans.BASIC,
	},
	[RentleProductRef.GROW_PLAN]: {
		ref: RentleProductRef.GROW_PLAN,
		description: 'Rentle Grow subscription',
		planRef: Plans.GROW,
	},
	[RentleProductRef.ADVANCED_PLAN]: {
		ref: RentleProductRef.ADVANCED_PLAN,
		description: 'Rentle Advanced subscription',
		planRef: Plans.ADVANCED,
	},
	[RentleProductRef.DISCOVER_PLAN]: {
		ref: RentleProductRef.DISCOVER_PLAN,
		description: 'Discover subscription',
		planRef: Plans.DISCOVER,
	},
	[RentleProductRef.BUILD_PLAN]: {
		ref: RentleProductRef.BUILD_PLAN,
		description: 'Build subscription',
		planRef: Plans.BUILD,
	},
	[RentleProductRef.ADVANCE_PLAN]: {
		ref: RentleProductRef.ADVANCE_PLAN,
		description: 'Advance subscription',
		planRef: Plans.ADVANCE,
	},
};

export const AdditionalProducts = {
	[RentleProductRef.ADDITIONAL_STORE]: {
		ref: RentleProductRef.ADDITIONAL_STORE,
		description: 'Additional store',
	},
};

/**
 * Rentle plan product discounts
 */

export const RevenueDiscountProducts = {
	[RentleProductRef.BASIC_REVENUE_DISCOUNT]: {
		ref: RentleProductRef.BASIC_REVENUE_DISCOUNT,
		description: 'Rentle Basic revenue discount (-50%)',
		planRef: Plans.BASIC,
	},
	[RentleProductRef.GROW_REVENUE_DISCOUNT]: {
		ref: RentleProductRef.GROW_REVENUE_DISCOUNT,
		description: 'Rentle Grow revenue discount (-50%)',
		planRef: Plans.GROW,
	},
	[RentleProductRef.ADVANCED_REVENUE_DISCOUNT]: {
		ref: RentleProductRef.ADVANCED_REVENUE_DISCOUNT,
		description: 'Rentle Advanced revenue discount (-50%)',
		planRef: Plans.ADVANCED,
	},
};

export const SubscriptionDiscountProducts = {
	[RentleProductRef.SUBSCRIPTION_START_DISCOUNT]: {
		ref: RentleProductRef.SUBSCRIPTION_START_DISCOUNT,
		description: 'Subscription start discount',
	},
	[RentleProductRef.SUBSCRIPTION_CANCEL_REFUND]: {
		ref: RentleProductRef.SUBSCRIPTION_CANCEL_REFUND,
		description: 'Subscription cancel refund',
	},
	[RentleProductRef.SUBSCRIPTION_CAMPAIGN_DISCOUNT]: {
		ref: RentleProductRef.SUBSCRIPTION_CAMPAIGN_DISCOUNT,
		description: 'Subscription campaign discount',
	},
};

/**
 * Merchant solution products
 */

export const MerchantSolutionProducts = {
	[RentleProductRef.DELIVERY_MERCHANT_SERVICE_BOOKING]: {
		ref: RentleProductRef.DELIVERY_MERCHANT_SERVICE_BOOKING,
		description: 'Delivery, booking fee',
	},
	[RentleProductRef.API_WEBHOOKS_USAGE]: {
		ref: RentleProductRef.API_WEBHOOKS_USAGE,
		description: 'API & Webhooks usage',
	},
};

/**
 * Custom products that can be assigned to subscription invoices
 */

export const CustomProducts = {
	[RentleProductRef.CUSTOM_DISCOUNT_ONE_OFF]: {
		ref: RentleProductRef.CUSTOM_DISCOUNT_ONE_OFF,
		description: 'Custom discount, one-off',
	},
	[RentleProductRef.CUSTOM_FEE_ONE_OFF]: {
		ref: RentleProductRef.CUSTOM_FEE_ONE_OFF,
		description: 'Custom fee, one-off',
	},
};

/**
 * Credit products
 */

export const CreditsProducts = {
	[RentleProductRef.CREDITS_USED]: {
		ref: RentleProductRef.CREDITS_USED,
		description: 'Credits used',
	},
};

export const RentleProducts: Record<RentleProductRef, RentleProduct> = {
	...MerchantSolutionProducts,
	...SubscriptionPlanProducts,
	...RevenueDiscountProducts,
	...SubscriptionDiscountProducts,
	...CustomProducts,
	...CreditsProducts,
	...AdditionalProducts,
};
