import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

export const zTaxLineApi = withOpenApi(
	z.object({
		price: z.number().int(),
		rate: z.number().nonnegative(),
	}),
	{
		fields: {
			price: 'Price of the tax in minor units',
			rate: 'Tax rate in decimals',
		},
	},
);
