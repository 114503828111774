import React from 'react';

import { Box, Stack, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';

import { IndustryOption as IndustryOptionType } from 'common/services/analytics/tractionAnalytics';

export interface Props {
	isSelected: boolean;
	option: IndustryOptionType;
	onChange: (isSelected: boolean) => void;
}
const IndustryOption = (props: Props) => {
	const { isSelected, option, onChange } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const Icon = option.icon;

	return (
		<Stack
			sx={{
				outline: (theme) =>
					isSelected
						? `2px solid ${theme.palette.primary.main}`
						: `1px solid ${theme.palette.border.inputOutline}`,
				'&:hover': !isMobile
					? {
							outline: (theme) =>
								!isSelected ? `1px solid ${theme.palette.primary.dark}` : undefined,
							boxShadow: (theme) => theme.shadows[6],
							cursor: 'pointer',
					  }
					: undefined,
				borderRadius: (theme) => theme.spacing(0.5),
				padding: 2,
				height: '100%',
				zIndex: 1,
			}}
			onClick={() => onChange(!isSelected)}
			alignItems="center"
		>
			<SvgIcon sx={{ height: 50, width: 50, mb: 1 }}>
				<Icon color={isSelected ? theme.palette.primary.main : theme.palette.text.tertiary} />
			</SvgIcon>
			<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
				<Typography
					sx={{
						fontSize: '1.6rem',
						lineHeight: 1.4,
						textAlign: 'center',
						color: 'inherit',
					}}
				>
					{option.heading}
				</Typography>
			</Box>
		</Stack>
	);
};

export default IndustryOption;
