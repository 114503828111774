import React from 'react';

import { Box, Typography } from '@mui/material';
import { omit, sortBy } from 'lodash';
import { TFunction } from 'react-i18next';

import ContainerBox from 'common/components/layout/ContainerBox';
import { AddOn, AllAddOns, InstalledAddOn, Plan, ShopAddons } from 'common/modules/plans';

import ConfigureAddOn from './ConfigureAddOn';

interface Props {
	value: ShopAddons;
	onChange: (value: ShopAddons) => void;
	plan: Plan;
	t: TFunction;
	mode: 'internal-admin' | 'admin';
}
const ConfigureAddOns = (props: Props) => {
	const { value, onChange, plan, t, mode } = props;

	const handleAddOnChange = (addOn: AddOn) => (installedAddOn: InstalledAddOn | null) => {
		if (!installedAddOn) {
			onChange(omit(value, addOn));
		} else {
			onChange({
				...value,
				[addOn]: installedAddOn,
			});
		}
	};

	return (
		<ContainerBox>
			<Typography variant="h6">Blocks</Typography>
			<Typography variant="body1" color="textSecondary">
				Configure the blocks enabled for this store
			</Typography>
			<Box mt={4} />
			<Box>
				{sortBy(AllAddOns).map((addOn) => (
					<ConfigureAddOn
						key={addOn}
						addOn={addOn}
						plan={plan}
						value={value?.[addOn] ?? null}
						onChange={handleAddOnChange(addOn)}
						mode={mode}
						t={t}
					/>
				))}
			</Box>
		</ContainerBox>
	);
};

export default ConfigureAddOns;
