import { fromPairs, partition } from 'lodash';

import { Api } from 'common/db/api/paths';
import { InventoryItemApi } from 'common/modules/inventory/InventoryItemApi';
import { getBulkItemsSkuIdPairsFromOrderProducts } from 'common/modules/inventory/utils';
import { OrderProduct } from 'common/types';
import { notUndefined } from 'common/utils/common';

export const updateSingleInventoryItemsReservationOrderIds = async (
	api: Api,
	args: {
		shopId: string;
		itemCodes: string[];
		orderId: string;
		operation: 'add' | 'remove';
	},
) => {
	const { shopId, itemCodes, orderId, operation } = args;
	const singleInventoryItems = await Promise.all(
		itemCodes.map((code) => InventoryItemApi.get.singleItemByCode(api, { shopId, code })),
	);
	await Promise.all(
		singleInventoryItems.filter(notUndefined).map(({ id: itemId }) => {
			return InventoryItemApi.set.updateItemReservedCountsByOrderId(api, {
				itemId,
				updatesByOrderId: {
					[orderId]: operation === 'add' ? 1 : -1,
				},
			});
		}),
	);
};

export const updateBulkInventoryItemsReservationOrderIds = async (
	api: Api,
	args: {
		products: OrderProduct[];
		operation: 'add' | 'remove';
	},
) => {
	const { products, operation } = args;

	if (!products.length) {
		return;
	}

	const { rentalId: orderId, startLocationId: locationId, shopId } = products[0];
	const [setProducts, normalProducts] = partition(products, (p) => !!p.set);

	const bulkItemSkuIdAmountPairs = getBulkItemsSkuIdPairsFromOrderProducts(normalProducts);
	const setBulkItemSkuIdAmountPairs = getBulkItemsSkuIdPairsFromOrderProducts(
		setProducts.flatMap((p) => p.setProducts) as OrderProduct[],
	);

	const amountsBySkuId: { [skuId: string]: number } = fromPairs(
		[...bulkItemSkuIdAmountPairs, ...setBulkItemSkuIdAmountPairs].map(([skuId, items]) => [
			skuId,
			items.length,
		]),
	);

	const inventoryItems = await Promise.all(
		Object.keys(amountsBySkuId).map((skuId) =>
			InventoryItemApi.get.bulkItemBySkuId(api, {
				shopId,
				locationId,
				skuId,
			}),
		),
	);

	await Promise.all(
		inventoryItems.map(({ id: itemId, skuId }) => {
			if (skuId === null) {
				return Promise.resolve({});
			}

			return InventoryItemApi.set.updateItemReservedCountsByOrderId(api, {
				itemId,
				updatesByOrderId: {
					[orderId]: (operation === 'add' ? 1 : -1) * (amountsBySkuId[skuId] ?? 0),
				},
			});
		}),
	);
};
