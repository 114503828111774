import { createReducer } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';

import * as Actions from 'actions/ActiveDiscountActions';
import { ActiveDiscountState } from 'services/types';

const INITIAL_STATE: ActiveDiscountState = {
	discountCode: {
		loading: true,
		error: null,
		data: null,
	},
	localChanges: {},
	saving: false,
	error: null,
};

const ActiveDiscountReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(Actions.setActiveDiscountData, (state, action) => {
		/** Whenever the remote data updates, re-diff the local changes */
		const discount = action.payload.data;
		const changes = state.localChanges;
		if (discount && !isEmpty(changes)) {
			const newChanges = Object.keys(state.localChanges).reduce((result, field) => {
				if (!isEqual(discount[field], changes[field])) {
					result[field] = changes[field];
				}
				return result;
			}, {});

			return {
				...state,
				discountCode: action.payload,
				localChanges: {
					...state.localChanges,
					...newChanges,
				},
			};
		}

		return {
			...state,
			discountCode: action.payload,
		};
	});

	builder.addCase(Actions.editLocalDiscount, (state, action) => {
		return {
			...state,
			localChanges: {
				...state.localChanges,
				...action.payload,
			},
		};
	});

	builder.addCase(Actions.resetLocalDiscount, (state) => ({
		...state,
		localChanges: INITIAL_STATE.localChanges,
	}));

	builder.addCase(Actions.setSaving, (state, action) => ({
		...state,
		saving: action.payload,
		error: action.payload === true ? null : state.error,
	}));

	builder.addCase(Actions.setError, (state, action) => ({
		...state,
		error: action.payload,
	}));

	builder.addCase(Actions.reset, () => INITIAL_STATE);
});

export default ActiveDiscountReducer;
