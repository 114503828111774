import React, { useMemo, useRef, useState } from 'react';

import { Box, IconButton, Snackbar, Typography } from '@mui/material';
import { closeSideBar } from 'actions';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { getOrderByOrderNumber, isValidOrderId } from 'common/api/frontend';
import { getOrderNumberFromOrderBarcode } from 'common/modules/orderReceipts/utils';
import BarCodeReader from 'components/BarCodeReader';
import { RentalsType, buildRentalsOfTypeData } from 'selectors/RentalsSelectors';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes, push } from 'routing';

interface SnackBarFeedback {
	message: string;
	key: number;
}

const BarCodeReceiptScan: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const { pathname } = useLocation();
	const shopId = useSelector(ShopSelectors.activeShopId)!;
	const sideBarOpen = useSelector(ViewSelectors.sideBarOpen);
	const bookedRentals = useSelector(buildRentalsOfTypeData(RentalsType.BOOKED));
	const activeRentals = useSelector(buildRentalsOfTypeData(RentalsType.ACTIVE));
	const expiredRentals = useSelector(buildRentalsOfTypeData(RentalsType.EXPIRED));
	const dispatch = useDispatch();

	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [snackBarFeedback, setSnackBarFeedback] = useState<SnackBarFeedback | undefined>(undefined);
	const queueRef = useRef<SnackBarFeedback[]>([]);

	const rentals = useMemo(() => [...bookedRentals, ...activeRentals, ...expiredRentals], [
		bookedRentals,
		activeRentals,
		expiredRentals,
	]);

	const isIgnoredBarcodeUrlPath = (pathname: string) => {
		const ignoredBarcodeReadPaths = [
			// These routes should be ignored, since they have own logic for barcode reading in place
			Routes.BOOKINGS,
			Routes.BOOKING_PAGE,
			Routes.RETURN,
			Routes.INVENTORY,
		];
		return matchPath(pathname, {
			path: ignoredBarcodeReadPaths,
			exact: false,
			strict: false,
		});
	};

	const processQueue = () => {
		if (queueRef.current && queueRef.current.length > 0) {
			setSnackBarFeedback(queueRef.current.shift());
			setSnackBarOpen(true);
		}
	};

	const handleGiveFeedback = (message: string) => {
		queueRef.current.push({ message, key: new Date().getTime() });
		snackBarOpen ? setSnackBarOpen(false) : processQueue();
	};

	const getOrderIdFromOrderBarcode = async (barcodeString: string) => {
		const orderNumber = getOrderNumberFromOrderBarcode(barcodeString);
		if (orderNumber === undefined || isNaN(orderNumber)) return false;
		return (await getOrderByOrderNumber(orderNumber, shopId, rentals))?.id;
	};

	const navigateToOrder = (orderId: string) => {
		if (sideBarOpen) {
			dispatch(closeSideBar());
		}
		push(Routes.BookingPage(orderId));
	};

	const isOrderId = async (barcodeString: string) => {
		const ORDER_DOCUMENT_FIRESTORE_ID_LENGTH = 20;
		if (barcodeString.length !== ORDER_DOCUMENT_FIRESTORE_ID_LENGTH) {
			return false;
		}
		return isValidOrderId(barcodeString, shopId, rentals);
	};

	const handleBarCodeRead = async (barcodeString: string) => {
		if (!!isIgnoredBarcodeUrlPath(pathname)) return;
		if (await isOrderId(barcodeString)) {
			const orderId = barcodeString;
			navigateToOrder(orderId);
			return;
		}
		const orderIdFromOrderNumber = await getOrderIdFromOrderBarcode(barcodeString);
		if (!!orderIdFromOrderNumber) {
			navigateToOrder(orderIdFromOrderNumber);
			return;
		}
		handleGiveFeedback(
			t('scanningFeedback.noOrderFound', {
				orderId: barcodeString,
				defaultValue: 'No order found with scanned number: {{orderId}}',
			}),
		);
	};

	const renderScanFeedback = () => (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={snackBarOpen}
			autoHideDuration={5000}
			classes={{
				root: classes.snackBarRoot,
			}}
			onClose={() => setSnackBarOpen(false)}
			ContentProps={{
				'aria-describedby': 'message-id',
			}}
			TransitionProps={{
				onExited: processQueue,
			}}
		>
			<Box className={classes.snack}>
				<IconButton
					key="close"
					aria-label="close"
					color="inherit"
					className={classes.close}
					onClick={() => setSnackBarOpen(false)}
					size="large"
				>
					<RiCloseLine />
				</IconButton>
				<Typography variant="h5" align="center">
					{snackBarFeedback ? snackBarFeedback.message : undefined}
				</Typography>
			</Box>
		</Snackbar>
	);

	return (
		<BarCodeReader barCodeRead={handleBarCodeRead} caseSensitive>
			{children}
			{renderScanFeedback()}
		</BarCodeReader>
	);
};

const useStyles = makeStyles()((theme) => ({
	snackBarRoot: {
		right: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			right: theme.spacing(1),
		},
	},
	close: {
		padding: theme.spacing(0.5),
		position: 'absolute',
		top: 14,
		right: 14,
	},
	snack: {
		backgroundColor: theme.palette.error.main,
		padding: theme.spacing(4),
		color: theme.palette.error.contrastText,
		borderRadius: 4,
		width: '100%',
	},
}));

export default BarCodeReceiptScan;
