import { IconType } from 'react-icons';

import { EnumValues } from 'common/types';
import { stringEnum } from 'common/utils/objects';

export const SearchGroups = stringEnum(['Common', 'Routes', 'Actions']);
export const SearchTypes = stringEnum(['route', 'actions']);

export type SearchGroup = EnumValues<typeof SearchGroups>;
export type SearchType = EnumValues<typeof SearchTypes>;

interface SearchableRouteResult {
	group: typeof SearchGroups.Routes;
	type: typeof SearchTypes.route;
	label: string;
	value: string;
	icon?: IconType;
}

//As an example the searchable result could be an action where the value is a function
interface SearchableActionResult {
	group: typeof SearchGroups.Actions;
	type: typeof SearchTypes.actions;
	label: string;
	value: () => void;
	icon: IconType;
}

type SearchableCommonResult = {
	group: typeof SearchGroups.Common;
	label: string;
	icon: IconType;
} & (
	| Pick<SearchableRouteResult, 'type' | 'value'>
	| Pick<SearchableActionResult, 'type' | 'value'>
);

export type SearchableResult =
	| SearchableRouteResult
	| SearchableCommonResult
	| SearchableActionResult;

export interface Searchable {
	[index: string]: SearchableResult;
}
