import { useState } from 'react';

import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

import { Form } from 'common/hooks/useFormField';
import Spinner from 'components/Spinner';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';

import { RegistrationForm } from '..';
import { TermsAcceptanceText } from './TermsAcceptanceText';

interface Props {
	handleSubmit: () => void;
	form: Form<RegistrationForm>;
	loading: boolean;
	isPartnerAuthentication: boolean;
	promotionCode: string | undefined;
	setPromotionCode: (value: string) => void;
}

export const EmailSignup = (props: Props) => {
	const { handleSubmit, form, loading, isPartnerAuthentication } = props;
	const { logRegisterActionEvent } = useAnalytics();
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showPromotionCodeInput, setShowPromotionCodeInput] = useState<boolean>(false);

	return (
		<>
			<TextField
				sx={{ textTransform: 'none', borderRadius: '8px' }}
				variant="outlined"
				label={t('common:form.emailAddress', 'Email address')}
				placeholder={t('register.emailPlaceholder', 'john.doe@domain.com')}
				name="email"
				autoComplete="email"
				value={form.email.value}
				onChange={form.email.handleChange}
				onBlur={() => logRegisterActionEvent('email')}
				margin="none"
				type="text"
				error={Boolean(form.email.error)}
				helperText={form.email.error}
				fullWidth
			/>
			<Box mt={2} />
			<TextField
				variant="outlined"
				label={t('common:form.password', 'Password')}
				placeholder={t('common:form.passwordPlaceholder', '')}
				name="password"
				autoComplete="new-password"
				value={form.password.value}
				onChange={form.password.handleChange}
				onBlur={() => {
					logRegisterActionEvent('password');
				}}
				margin="none"
				type={showPassword ? 'text' : 'password'}
				error={Boolean(form.password.error)}
				helperText={Boolean(form.password.error) ? form.password.error : undefined}
				fullWidth
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
								edge="end"
								size="small"
							>
								{showPassword ? <RiEyeOffFill color="#848484" /> : <RiEyeFill color="#848484" />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{isPartnerAuthentication ? null : (
				<Box mt={2}>
					{showPromotionCodeInput ? (
						<TextField
							label={t('register.yourReferralCode', 'Your referral code')}
							value={props.promotionCode}
							onChange={(e) => {
								logRegisterActionEvent('promotion_code', e.target.value);
								props.setPromotionCode(e.target.value);
							}}
							variant="outlined"
							fullWidth
						/>
					) : (
						<Button
							variant="text"
							sx={{
								padding: 0,
								textTransform: 'none',
								fontWeight: 500,
							}}
							onClick={() => setShowPromotionCodeInput(true)}
						>
							{`${t('register.doYouHaveReferralCode', 'Do you have a referral code')}?`}
						</Button>
					)}
				</Box>
			)}
			<Box my={5}>
				<TermsAcceptanceText />
			</Box>
			<Button
				sx={{
					background: (theme) => theme.palette.common.black,
					'&:hover': {
						background: (theme) => theme.palette.secondary.dark,
					},
					borderRadius: '8px',
					textTransform: 'none',
				}}
				disabled={loading}
				onClick={() => handleSubmit()}
				variant="contained"
				fullWidth
			>
				{loading ? (
					<Spinner padding={8} thickness={4} color="inherit" />
				) : (
					t('register.signUp', 'Sign up')
				)}
			</Button>
		</>
	);
};
