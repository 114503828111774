import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const WAIVER_AND_POLICY_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'WAIVER_AND_POLICY',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	WAIVER_AND_POLICY: {
		all: {
			action: getRouteOnboardingStepItem({
				routeTo: Routes.ACCOUNT_TERMS,
			}),
			content: {
				title: t('homeView.onboarding.addWaiverAndPolicies', 'Add your waiver and policies'),
			},
			marksItemAsDone: ['PRIVACY_POLICY', 'TERMS_OF_SERVICE'],
			id: 'WAIVER_AND_POLICY',
			type: 'row',
		},
	},
});
