import { useCallback, useEffect, useRef } from 'react';

export default function useTimeout(callback: () => void, delay?: number | null) {
	const timeout = useRef<NodeJS.Timeout>();

	const cancel = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
	}, []);

	const reset = useCallback(
		(newDelay?: number) => {
			cancel();
			const _delay = newDelay ?? delay;
			if (_delay) {
				timeout.current = setTimeout(callback, _delay);
			}
		},
		[cancel, callback, delay],
	);

	useEffect(() => {
		reset();
	}, [reset]);

	return [cancel, reset];
}
