import { TFunction } from 'i18next';
import { range } from 'lodash';

import {
	getTotalDiscountFromAppliedDiscountCodes,
	mergeDiscountCodes,
} from 'common/modules/discountCodes';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { getTranslation } from 'common/modules/translations';
import {
	AmountObject,
	AppliedDiscountCodes,
	Currency,
	CurrencyObject,
	Languages,
	OrderObject,
} from 'common/types';
import { getPricingString } from 'common/utils/common';

import { StoreServiceFee } from '../serviceFee/types';
import {
	getCombinedTaxLines,
	getDefaultTaxLabel,
	getTaxDetails,
	getTaxPercentFromTaxRate,
	mergeTaxlines,
} from '../taxes';
import {
	ExtendedItemPricing,
	ExtendedOrderPricing,
	ItemPricing,
	ItemPricingWithoutCurrency,
	OrderPricing,
	OrderPricingForEmail,
} from './types';

export const getInitialOrderPricing = (opts: {
	currency: string;
	taxExcluded: boolean;
	serviceFee?: StoreServiceFee;
	paymentMethod: ShopOnlinePaymentMethodObject | undefined;
}): OrderPricing => {
	return {
		currency: opts.currency,
		total: 0,
		subtotal: 0,
		totalDiscounts: 0,
		totalTaxes: 0,
		taxExcluded: opts.taxExcluded,
		taxLines: [],
		manualDiscount: {
			amount: 0,
		},
		paymentMethod: opts.paymentMethod,
		...(opts.serviceFee && {
			serviceFee: { ...opts.serviceFee, total: 0, taxLines: [], taxExcluded: opts.taxExcluded },
		}),
	};
};

export const getEmptyItemPricing = (currency: string): ItemPricing => {
	return {
		currency,
		total: 0,
		subtotal: 0,
		listPrice: 0,
		originalListPrice: 0,
		totalDiscounts: 0,
		totalTaxes: 0,
		taxExcluded: false,
		taxLines: [],
		manualDiscount: 0,
	};
};

export const getInfiniteItemPricing = (currency: string): ItemPricing => {
	return {
		currency,
		total: Infinity,
		subtotal: Infinity,
		listPrice: Infinity,
		originalListPrice: Infinity,
		totalDiscounts: 0,
		totalTaxes: 0,
		taxExcluded: false,
		taxLines: [],
		manualDiscount: 0,
	};
};

export const getEmptyItemPricingWithoutCurrency = (): ItemPricingWithoutCurrency => {
	return {
		...getEmptyItemPricing(''),
		currency: undefined,
	};
};

export const getExtendedItemPricing = (
	pricing: ItemPricing | ItemPricingWithoutCurrency,
): ExtendedItemPricing => {
	return {
		...pricing,
		subtotalWithoutManualDiscount: pricing.subtotal + (pricing.manualDiscount ?? 0),
		subtotalWithoutDiscountCodes:
			pricing.subtotal + getTotalDiscountFromAppliedDiscountCodes(pricing.discountCodes ?? {}),
	};
};

export const getExtendedOrderPricing = (pricing: OrderPricing): ExtendedOrderPricing => {
	return {
		...pricing,
		subtotalWithoutManualDiscount: pricing.subtotal + (pricing.manualDiscount?.amount || 0),
		subtotalWithoutDiscountCodes:
			pricing.subtotal + getTotalDiscountFromAppliedDiscountCodes(pricing.discountCodes ?? {}),
	};
};

export const itemPricingToOrderPricing = (
	pricing: ItemPricing,
	paymentMethod?: ShopOnlinePaymentMethodObject,
): OrderPricing => {
	return {
		...pricing,
		manualDiscount: pricing.manualDiscount != null ? { amount: pricing.manualDiscount } : undefined,
		paymentMethod,
		deposit:
			pricing.deposit != null
				? {
						value: pricing.deposit,
						type: 'payment',
				  }
				: undefined,
	};
};

export const orderWithPaymentMethod = (
	order: OrderObject,
	paymentMethod: ShopOnlinePaymentMethodObject,
): OrderObject => {
	const pricing: OrderPricing = {
		...order.rentalInfo.pricing,
		paymentMethod,
	};
	return { ...order, rentalInfo: { ...order.rentalInfo, pricing } };
};

export const pricingToAmountObject = (
	pricing: ItemPricing | OrderPricing,
): { charge: AmountObject; deposit: AmountObject } => {
	return {
		charge: {
			value: pricing.total,
			currency: pricing.currency,
		},
		deposit: {
			value: typeof pricing.deposit === 'number' ? pricing.deposit : pricing.deposit?.value ?? 0,
			currency: pricing.currency,
		},
	};
};

export const recalculateItemPricingFromListPrice = (pricing: ItemPricing) => {
	const totalDiscounts =
		(pricing.manualDiscount ?? 0) +
		getTotalDiscountFromAppliedDiscountCodes(pricing.discountCodes ?? {});
	const subtotal = pricing.listPrice - totalDiscounts;

	const taxDetails = getTaxDetails(
		subtotal,
		pricing.taxLines.map((line) => ({
			rate: line.rate,
			taxExcluded: pricing.taxExcluded,
		})),
	);
	const total = Math.round(pricing.taxExcluded ? subtotal + taxDetails.totalTaxes : subtotal);
	return {
		...pricing,
		total,
		subtotal,
		taxLines: taxDetails.taxLines,
		totalTaxes: taxDetails.totalTaxes,
		totalDiscounts,
	};
};

export function getTotalItemPricing(
	pricings: ItemPricing[],
): ItemPricing | ItemPricingWithoutCurrency;
export function getTotalItemPricing(pricings: ItemPricing[], fallbackCurrency: string): ItemPricing;
export function getTotalItemPricing(
	pricings: ItemPricing[],
	fallbackCurrency?: string,
): ItemPricing | ItemPricingWithoutCurrency {
	if (!pricings.length) {
		return !!fallbackCurrency
			? getEmptyItemPricing(fallbackCurrency)
			: getEmptyItemPricingWithoutCurrency();
	}
	return pricings.reduce((prev, curr) => {
		const addManualDiscount = prev.manualDiscount || curr.manualDiscount;
		const addDeposit = prev.deposit || curr.deposit;
		return {
			currency: prev.currency,
			total: prev.total + curr.total,
			subtotal: prev.subtotal + curr.subtotal,
			listPrice: prev.listPrice + curr.listPrice,
			originalListPrice: prev.originalListPrice + curr.originalListPrice,
			totalDiscounts: prev.totalDiscounts + curr.totalDiscounts,
			totalTaxes: prev.totalTaxes + curr.totalTaxes,
			taxExcluded: prev.taxExcluded,
			taxLines: mergeTaxlines([...prev.taxLines, ...curr.taxLines]),
			...(!!addManualDiscount && {
				manualDiscount: (prev.manualDiscount ?? 0) + (curr.manualDiscount ?? 0),
			}),
			...(addDeposit && { deposit: (prev.deposit ?? 0) + (curr.deposit ?? 0) }),
			discountCodes: mergeDiscountCodes(prev.discountCodes ?? {}, curr.discountCodes ?? {}),
		};
	});
}

export const multiplyItemPricing = (pricing: ItemPricing, multiplier: number): ItemPricing => {
	if (isFinite(multiplier)) {
		const pricings = range(Math.floor(multiplier)).map(() => pricing);
		return getTotalItemPricing(pricings, pricing.currency);
	}
	return getTotalItemPricing([pricing, getInfiniteItemPricing(pricing.currency)], pricing.currency);
};

export const mapToOrderPricingForEmail = (
	pricing: OrderPricing,
	currency: CurrencyObject,
	t: TFunction,
	lang: Languages,
): OrderPricingForEmail => {
	const discountCodes = !pricing.discountCodes
		? undefined
		: Object.entries(pricing.discountCodes)
				.filter(([_, obj]) => !!obj.totalDiscountValue)
				.map(([code, discountObj]) => ({
					code,
					value: getPricingString(discountObj.totalDiscountValue, currency),
				}));
	const serviceFee = !pricing.serviceFee
		? undefined
		: {
				...pricing.serviceFee,
				total: getPricingString(pricing.serviceFee.total, currency),
				taxLines: pricing.serviceFee.taxLines.map((line) => ({
					...line,
					label: line.label ? getTranslation(line.label, lang) : getDefaultTaxLabel(line.rate, t),
					price: getPricingString(line.price, currency),
					rate: `${getTaxPercentFromTaxRate(line.rate).toString()}%`,
				})),
		  };
	const combinedTaxLines = getCombinedTaxLines(pricing.taxLines);
	const taxLines = combinedTaxLines.map((line) => ({
		...line,
		label: line.label ? getTranslation(line.label, lang) : getDefaultTaxLabel(line.rate, t),
		price: getPricingString(line.price, currency),
		rate: `${getTaxPercentFromTaxRate(line.rate).toString()}%`,
	}));
	const manualDiscount = !pricing.manualDiscount?.amount
		? undefined
		: {
				...pricing.manualDiscount,
				amount: getPricingString(pricing.manualDiscount.amount || 0, currency),
		  };
	return {
		...pricing,
		total: getPricingString(pricing.total, currency),
		subtotal: getPricingString(pricing.subtotal, currency),
		totalTaxes: getPricingString(pricing.totalTaxes, currency),
		totalDiscounts: getPricingString(pricing.totalDiscounts, currency),
		taxLines,
		manualDiscount,
		discountCodes,
		serviceFee,
		deposit: !pricing.deposit?.value
			? undefined
			: getPricingString(pricing.deposit.value, currency),
	};
};

export const getItemPricingFromListPrice = (
	listPrice: number,
	options: {
		taxRate: number;
		deposit?: number;
		manualDiscount?: number;
		discountCodes?: AppliedDiscountCodes;
		taxExcluded: boolean;
		currency: Currency;
	},
): ItemPricing => {
	const { taxRate, deposit, manualDiscount, taxExcluded, currency, discountCodes } = options;
	const discountFromCodes = getTotalDiscountFromAppliedDiscountCodes(discountCodes ?? {});
	const totalDiscounts = (manualDiscount ?? 0) + discountFromCodes;
	const subtotal = listPrice - totalDiscounts;
	const taxes = getTaxDetails(subtotal, {
		rate: taxRate,
		taxExcluded,
	});
	const totalPrice = taxExcluded ? subtotal + taxes.totalTaxes : subtotal;
	return {
		total: totalPrice,
		subtotal,
		totalTaxes: taxes.totalTaxes,
		taxLines: taxes.taxLines,
		listPrice,
		originalListPrice: listPrice,
		manualDiscount,
		discountCodes,
		totalDiscounts: manualDiscount ?? 0,
		taxExcluded,
		currency,
		deposit,
	};
};
