import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const BUSINESS_ID_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'BUSINESS_ID',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	BUSINESS_ID: {
		all: {
			action: getRouteOnboardingStepItem({ routeTo: Routes.ACCOUNT_GENERAL }),
			content: {
				title: t('homeView.onboarding.addBusinessId', 'Add your business ID'),
			},
			id: 'BUSINESS_ID',
			type: 'row',
		},
	},
});
