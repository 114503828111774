import {
	OrderDeliveryService,
	OrderInfo,
	OrderPayment,
	OrderProduct,
	_PaymentObject,
} from 'common/types';
import { getObjectValuesSum } from 'common/utils/common';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';

import { PaymentAmounts } from './types';

const emptyPaymentAmounts: PaymentAmounts = {
	total: 0,
	paid: 0,
	reserved: 0,
	captured: 0,
	refunded: 0,
	cancelled: 0,
	pendingRefunds: 0,
	failedRefunds: 0,
	pendingCaptures: 0,
	failedCaptures: 0,
	unpaid: 0,
	unreserved: 0,
	refundable: 0,
	cancellable: 0,
	currency: getDefaultCurrencyObject().code,
};

export const sumPaymentAmounts = (paymentAmounts: PaymentAmounts[]) => {
	const unpaidSum = paymentAmounts.reduce(
		(prev, curr) => prev + (curr.total - curr.paid - curr.pendingCaptures),
		0,
	);
	const sumPayment = paymentAmounts.reduce((prev, curr) => {
		return {
			total: prev.total + curr.total,
			paid: prev.paid + curr.paid,
			reserved: prev.reserved + curr.reserved,
			captured: prev.captured + curr.captured,
			refunded: prev.refunded + curr.refunded,
			cancelled: prev.cancelled + curr.cancelled,
			pendingRefunds: prev.pendingRefunds + curr.pendingRefunds,
			failedRefunds: prev.failedRefunds + curr.failedRefunds,
			pendingCaptures: prev.pendingCaptures + curr.pendingCaptures,
			failedCaptures: prev.failedCaptures + curr.failedCaptures,
			unpaid: prev.unpaid + curr.unpaid,
			unreserved: prev.unreserved + curr.unreserved,
			refundable: prev.refundable + curr.refundable,
			cancellable: prev.cancellable + curr.cancellable,
			currency: curr.currency,
		};
	}, emptyPaymentAmounts);
	return {
		...sumPayment,
		unpaid: Math.min(sumPayment.unpaid, Math.max(0, unpaidSum)),
	};
};

export const getPaymentAmounts = (payment: _PaymentObject): PaymentAmounts => {
	const { value: total, currency } = payment;
	const captured = payment.paid || payment.captured || 0;
	const refunded = payment.refunded || 0;
	const cancelled = payment.cancelled || 0;
	const authorised = payment.authorised || 0;
	const pendingRefunds = getObjectValuesSum(payment.pendingRefunds || {});
	const failedRefunds = getObjectValuesSum(payment.failedRefunds || {});
	const pendingCaptures = getObjectValuesSum(payment.pendingCaptures || {});
	const failedCaptures = getObjectValuesSum(payment.failedCaptures || {});
	const paid = Math.max(0, captured - refunded);
	const unpaid = Math.max(0, total - paid - pendingCaptures);
	const reserved = Math.max(0, authorised - cancelled - captured);
	const unreserved = Math.max(0, total - captured - reserved);
	const refundable = Math.max(0, paid - pendingRefunds);
	const cancellable = reserved;
	return {
		total,
		paid,
		reserved,
		captured,
		refunded,
		cancelled,
		pendingRefunds,
		failedRefunds,
		pendingCaptures,
		failedCaptures,
		unpaid,
		unreserved,
		refundable,
		cancellable,
		currency,
	};
};

export const getPaymentAmountsFromOrderPayment = (
	total: number,
	currency: string,
	payment: OrderPayment,
): PaymentAmounts => {
	const paymentObject: _PaymentObject = {
		...payment,
		value: total,
		currency,
	};
	return getPaymentAmounts(paymentObject);
};

export const getOrderPayments = (
	rentalInfo: OrderInfo,
): { charge: PaymentAmounts; deposit: PaymentAmounts } => {
	const charge = getPaymentAmountsFromOrderPayment(
		rentalInfo.pricing.total,
		rentalInfo.pricing.currency,
		rentalInfo.charge,
	);
	const deposit = getPaymentAmountsFromOrderPayment(
		rentalInfo.pricing.deposit?.value ?? 0,
		rentalInfo.pricing.currency,
		rentalInfo.deposit,
	);
	return {
		charge,
		deposit,
	};
};

export const getItemsPayments = (
	products: OrderProduct | OrderProduct[],
): { charge: PaymentAmounts; deposit: PaymentAmounts } => {
	if (!Array.isArray(products)) {
		return getItemPayments(products);
	}
	if (!products.length) {
		return { charge: emptyPaymentAmounts, deposit: emptyPaymentAmounts };
	}
	const itemPayments = products.map((p) => getItemPayments(p));
	return {
		charge: sumPaymentAmounts(itemPayments.map((p) => p.charge)),
		deposit: sumPaymentAmounts(itemPayments.map((p) => p.deposit)),
	};
};

export const getOrderDeliveryPayments = (
	orderDelivery: OrderDeliveryService,
): { charge: PaymentAmounts } => {
	if (!orderDelivery) {
		return { charge: emptyPaymentAmounts };
	}
	const payment = getPaymentAmountsFromOrderPayment(
		orderDelivery.pricing.total,
		orderDelivery.pricing.currency,
		orderDelivery.charge ?? { paid: 0 },
	);
	return {
		charge: payment,
	};
};

const getItemPayments = (
	product: OrderProduct,
): { charge: PaymentAmounts; deposit: PaymentAmounts } => {
	const charge = getPaymentAmountsFromOrderPayment(
		product.pricing.total,
		product.pricing.currency,
		product.charge,
	);
	const deposit = getPaymentAmountsFromOrderPayment(
		product.pricing.deposit ?? 0,
		product.pricing.currency,
		product.deposit ?? {
			paid: 0,
		},
	);
	return {
		charge,
		deposit,
	};
};

export const getOrderDeliveryForPayment = (orderDelivery: OrderDeliveryService | undefined) => {
	if (!orderDelivery) return undefined;
	const { charge } = getOrderDeliveryPayments(orderDelivery);
	return charge.unpaid > 0 || charge.total === 0 ? orderDelivery : undefined;
};
