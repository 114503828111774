import {
	Autocomplete,
	Checkbox,
	Chip,
	Divider,
	FormControlLabel,
	InputAdornment,
	Paper,
	TextField,
	TextFieldProps,
} from '@mui/material';
import { merge } from 'lodash';
import { TFunction } from 'react-i18next';

import { CountryType } from 'common/modules/atoms/countries';
import countryList from 'common/services/countryCodes.json';
import { notUndefined } from 'common/utils/common';

export interface CountryPickerProps<Multiple extends boolean>
	extends Omit<TextFieldProps, 'onChange' | 'error' | 'value'> {
	// List of country codes, if not provided, all countries will be shown
	options?: string[] | 'all';
	error?: string;
	onChange: (value: Multiple extends true ? string[] | 'all' : string) => void;
	multiple?: Multiple;
	value: (Multiple extends true ? string[] | 'all' : string) | undefined;
	t?: TFunction;
}

const isMultiplePickerProps = (
	props: CountryPickerProps<boolean>,
): props is CountryPickerProps<true> => {
	return props.multiple === true;
};

const isSinglePickerProps = (
	props: CountryPickerProps<boolean>,
): props is CountryPickerProps<false> => {
	return !props.multiple;
};

const AutoCompleteCountryPicker = <Multiple extends boolean = false>(
	props: CountryPickerProps<Multiple>,
) => {
	const {
		t,
		value: _value,
		error,
		multiple = false,
		onChange,
		options: _options,
		...textFieldProps
	} = props;

	const isAllSelected =
		_value === 'all' || (Array.isArray(_value) && _value.length === _options?.length);

	const values: string[] = (Array.isArray(_value) ? _value : [_value]).filter(notUndefined);
	const options = Array.isArray(_options)
		? _options
				.map((option) => countryList.find((country) => country.code === option))
				.filter(notUndefined)
		: countryList;
	const selectedValues = values
		.map((value) => countryList.find((country) => country.code === value))
		.filter(notUndefined);

	const handleChange = (countryOption: CountryType | CountryType[] | null) => {
		if (!countryOption) return;
		if (isMultiplePickerProps(props)) {
			const option = Array.isArray(countryOption) ? countryOption : [countryOption];
			return props.onChange(option.map((country) => country.code));
		}
		if (!Array.isArray(countryOption) && isSinglePickerProps(props)) {
			return props.onChange(countryOption.code);
		}
	};

	const handleToggleSelectAll = () => {
		if (isMultiplePickerProps(props)) {
			if (isAllSelected) props.onChange([]);
			else props.onChange('all');
		}
	};

	return (
		<Autocomplete
			multiple={!!multiple}
			fullWidth
			value={(multiple ? selectedValues : selectedValues[0]) ?? null}
			getOptionLabel={(opt) => opt.name}
			isOptionEqualToValue={(option, value) => option.code === value.code}
			options={options}
			onChange={(_: any, option) => handleChange(option)}
			renderInput={(_params) => {
				// We might pass nested props to TextField, so we need to deep merge them
				const InputProps = {
					startAdornment:
						_value === 'all' ? (
							<InputAdornment position="start">
								<Chip label={t && t('delivery.allCountries', 'All countries')} />
							</InputAdornment>
						) : undefined,
				};
				const params = merge(_params, {
					...textFieldProps,
					InputProps: {
						...textFieldProps.InputProps,
						...InputProps,
					},
				});
				return <TextField {...params} error={!!error} helperText={error} multiline />;
			}}
			disableCloseOnSelect={multiple}
			renderOption={
				!multiple
					? undefined
					: (props, option, { selected }) => (
							<li {...props}>
								<Checkbox sx={{ mr: 1 }} checked={isAllSelected || selected} />
								{option.name}
							</li>
					  )
			}
			ListboxComponent={isMultiplePickerProps(props) ? ListBoxWithSelectAll : undefined}
			ListboxProps={
				{
					handleToggleSelectAll,
					isAllSelected,
					t,
				} as any
			}
		/>
	);
};

const ListBoxWithSelectAll = (props: any) => {
	const { isAllSelected, handleToggleSelectAll, t } = props;
	return (
		<Paper {...props} onMouseDown={(e: any) => e.preventDefault()}>
			<FormControlLabel
				sx={{ pl: 2, py: 2, width: '100%' }}
				label={t('common:address.allCountries', 'All countries')}
				onClick={handleToggleSelectAll}
				control={<Checkbox checked={isAllSelected} />}
			/>
			<Divider />
			{props.children}
		</Paper>
	);
};

export default AutoCompleteCountryPicker;
