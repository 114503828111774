import { TFunction } from 'react-i18next';

import { SupportArticles } from 'common/modules/support';
import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';

import { OnboardingStepItem } from '../types';
import { getLinkOnboardingStepItem } from '../utils';

export const VISIT_INVENTORY_SUPPORT_LINK_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'VISIT_INVENTORY_SUPPORT_LINK',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	VISIT_INVENTORY_SUPPORT_LINK: {
		all: {
			action: getLinkOnboardingStepItem({
				toUrl: SupportArticles.INVENTORY_OVERVIEW,
				completeOnClick: true,
			}),
			content: {
				title: t(
					'homeView.onboarding.learnHowInventoryWorks',
					'Learn how your inventory works and the difference to your catalog',
				),
			},
			id: 'VISIT_INVENTORY_SUPPORT_LINK',
			type: 'row',
		},
	},
});
