import { clamp } from 'lodash';
import moment from 'moment-timezone';

import { DateFormatObject } from 'common/types';
import { getDefaultDateObject } from 'common/utils/dateUtils';

import { ISOString, YYYY_MM_DD } from '../dates';
import { HH_MM } from './types';

export const isTimeSame = (time: HH_MM, other: HH_MM): boolean => {
	return getTimeAsMinutes(time) === getTimeAsMinutes(other);
};

export const isTimeBefore = (time: HH_MM, other: HH_MM): boolean => {
	return getTimeAsMinutes(time) < getTimeAsMinutes(other);
};

export const isTimeBeforeOrSame = (time: HH_MM, other: HH_MM): boolean => {
	return getTimeAsMinutes(time) <= getTimeAsMinutes(other);
};

export const isTimeAfter = (time: HH_MM, other: HH_MM): boolean => {
	return getTimeAsMinutes(time) > getTimeAsMinutes(other);
};

export const isTimeAfterOrSame = (time: HH_MM, other: HH_MM): boolean => {
	return getTimeAsMinutes(time) >= getTimeAsMinutes(other);
};

export const isTimeBetween = (
	time: HH_MM,
	range: [start: HH_MM, end: HH_MM],
	inclusive?: {
		start: boolean;
		end: boolean;
	},
): boolean => {
	const isAfterStart = inclusive?.start
		? isTimeAfterOrSame(time, range[0])
		: isTimeAfter(time, range[0]);

	const isBeforeEnd = inclusive?.end
		? isTimeBeforeOrSame(time, range[1])
		: isTimeBefore(time, range[1]);

	return isAfterStart && isBeforeEnd;
};

export const addMinutes = (time: HH_MM, minutes: number): HH_MM => {
	const newMinutes = getTimeAsMinutes(time) + minutes;
	return getMinutesAsTime(newMinutes);
};

export const subtractMinutes = (time: HH_MM, minutes: number): HH_MM => {
	return addMinutes(time, -minutes);
};

export const addHours = (time: HH_MM, hours: number): HH_MM => {
	const newMinutes = getTimeAsMinutes(time) + hours * 60;
	return getMinutesAsTime(newMinutes);
};

export const subtractHours = (time: HH_MM, hours: number): HH_MM => {
	return addHours(time, -hours);
};

/**
 * Converts a time (ISOString) to the HH:mm format
 *
 * @param time A time in ISOString format
 * @param timezone Timezone
 * @returns Date represented as HH:mm
 */

export const hhMm = (time: ISOString, timezone: string): HH_MM => {
	return moment.tz(time, timezone).format('HH:mm');
};

/**
 * Ensure a date is in YYYY-MM-DD format
 */
export const yyyyMmDd = (date: ISOString): YYYY_MM_DD => {
	return moment(date).format('YYYY-MM-DD');
};

/**
 * Get an array of times with a given interval. Examples:
 *
 * getTimesWithInterval('00:00', '23:59', 30) => ['00:00', '00:30', '01:00', ..., '23:30']
 * getTimesWithInterval('00:00', '23:59', 30, { alwaysIncludeTo: true }) => ['00:00', '00:30', '01:00', ..., '23:30', '23:59']
 *
 * @returns An array of HH:mm tines with the given range and interval
 */
export const getTimesWithInterval = (
	start: HH_MM,
	end: HH_MM,
	interval: number,
	opts?: {
		alwaysIncludeEnd?: boolean;
	},
): HH_MM[] => {
	if (interval <= 0) return [];
	const { alwaysIncludeEnd = false } = opts ?? {};
	const startMinutes = getTimeAsMinutes(start);
	const endMinutes = getTimeAsMinutes(end);

	let currentMinutes = startMinutes;
	const result: HH_MM[] = [];

	while (currentMinutes <= endMinutes) {
		result.push(getMinutesAsTime(currentMinutes));
		currentMinutes += interval;
	}

	if (alwaysIncludeEnd && result[result.length - 1] !== end) {
		result.push(end);
	}

	return result;
};

/**
 * Converts a HH:mm time to minutes
 *
 * @param time A time in HH:mm format
 * @returns The number of minutes from 00:00
 */
export const getTimeAsMinutes = (time: HH_MM): number => {
	const [hours, minutes] = getTimeParts(time);
	// 1439 = the amount of minutes equal to 23:59
	return clamp(hours * 60 + minutes, 0, 1439);
};

/**
 * Converts a number of minutes to a HH:mm time
 *
 * @param minutes The number of minutes from 00:00
 * @returns A time in HH:mm format
 */
export const getMinutesAsTime = (minutes: number): HH_MM => {
	const _minutes = clamp(minutes, 0, 1439);
	const hours = Math.floor(_minutes / 60);
	const remainingMinutes = _minutes % 60;
	return getTimeFromParts(hours, remainingMinutes);
};

export const getTimeFromParts = (hours: number, minutes: number): HH_MM => {
	return `${padStart(hours.toString(), 2, '0')}:${padStart(minutes.toString(), 2, '0')}`;
};

const padStart = (string: string, length: number, pad: string): string => {
	if (string.length >= length) {
		return string;
	} else {
		return padStart(pad + string, length, pad);
	}
};

export const getTimeParts = (time: HH_MM): [hours: number, minutes: number] => {
	try {
		const [hours, minutes] = time.split(':').map(Number);
		if (isNaN(hours) || isNaN(minutes)) return [0, 0];
		return [hours, minutes];
	} catch (err) {
		return [0, 0];
	}
};

export const dateAndTimeToISOString = (args: {
	date: YYYY_MM_DD;
	time: HH_MM;
	timezone: string;
}): ISOString => {
	const { date, time, timezone } = args;
	const [hours, minutes] = getTimeParts(time);
	return moment.tz(date, timezone).startOf('day').hours(hours).minutes(minutes).toISOString();
};

export const formatTime = (
	time: HH_MM,
	dateFormat: DateFormatObject = getDefaultDateObject(),
): string => {
	const [hours, minutes] = getTimeParts(time);
	return moment.utc().hours(hours).minutes(minutes).format(dateFormat.timeFormat);
};
