import { PendingRefundOrCancelTransaction } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getPendingRefundsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.pendingRefunds;
	const dataUpdater = undefined;
	const query = buildQuery<PendingRefundOrCancelTransaction>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PendingRefundOrCancelTransaction>(
			collectionPath,
			dbRefs,
			dataUpdater,
		),
	};
};
