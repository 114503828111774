import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.packageProducts.unlimitedProducts',
			'Unlimited products',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.packageProducts.productsAndServices',
			'Products & services',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.packageProducts.inventoryTracking',
			'Accurate inventory tracking',
		),
	},
];

export default valueProps;
