import moment from 'moment-timezone';
import { z } from 'zod';

import { Address } from 'common/models/_atoms/Address';
import { CancellationPolicy } from 'common/models/_atoms/CancellationPolicy';
import { zBookingChannelApi } from 'common/models/_atoms/Channel/api';
import { LocaleField } from 'common/models/_atoms/LocaleField';
import { Metafields } from 'common/models/_atoms/Metafields';
import { zInternal } from 'common/models/base';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { withOpenApi } from 'common/utils/openApi';
import { zIsoString } from 'common/utils/zod';

import { CustomerDetails } from '../CustomerDetails';
import { OrderPayment } from '../OrderPayment';
import { OrderPricing } from '../OrderPricing';
import { OrderState } from '../OrderState';

const zOrderMetaFields = Metafields.api
	.and(
		z.object({
			skiData: z
				.object({
					confirmationNumber: z.string().optional(),
				})
				.optional(),
		}),
	)
	.optional();

export const zOrderInfoApi = withOpenApi(
	z.object({
		id: z.string(),
		createdAt: zIsoString,
		startDate: zIsoString,
		endDate: zIsoString,
		returnedDate: zIsoString.optional(),
		payment: OrderPayment.api,
		deposit: OrderPayment.api,
		cancellationPolicy: CancellationPolicy.api.optional(),
		channel: zBookingChannelApi,
		state: OrderState.api,
		store: z.object({
			name: z.string(),
			storeId: z.string(),
			address: Address.api,
		}),
		customCheckoutFields: withOpenApi(
			z.object({
				label: LocaleField.api,
				value: z.union([z.string().nullable(), z.boolean()]),
			}),
			{
				fields: {
					label: 'Label of the custom checkout field',
					value:
						'Value provided by the customer on checkout. The value can be null if the customer did not fill in a checkout field.',
				},
			},
		)
			.array()
			.optional(),
		customerDetails: CustomerDetails.api,
		terms: z.object({
			accepted: z.boolean(),
			timestamp: zIsoString.optional(),
		}),
		staffComment: z.string().optional(),
		orderNumber: z.number().int().nonnegative().optional(),
		prepared: z.boolean(),
		pricing: OrderPricing.api,
		bookingComment: withOpenApi(
			z.object({
				label: LocaleField.api,
				value: z.string(),
			}),
			{
				fields: {
					label: 'Label of the comment.',
					value: 'Value of the comment.',
				},
			},
		).optional(),
		metafields: zOrderMetaFields,
		_internal: zInternal({
			acs: z
				.object({
					updateState: z.enum(['PENDING', 'IN_PROGRESS', 'SUCCESS', 'FAILED']),
					updateStateTs: zIsoString,
					updateError: z
						.object({
							message: z.string(),
							raw: z.any(),
						})
						.nullable(),
				})
				.optional(),
		}),
	}),
	{
		fields: {
			id: 'Unique order identifier.',
			createdAt: 'Order creation date.',
			startDate: 'Start date of order.',
			endDate:
				'End date of order. This will show the initial end date even when the order is returned.',
			returnedDate:
				'Date when order was returned. If order is not returned, this field is not present.',
			payment: 'Payment details for the order.',
			deposit: 'Deposit details for the order.',
			cancellationPolicy: 'Order cancellation policy.',
			channel:
				'Channel where the order was created. All orders created via API will have the channel `api`.',
			state: 'Current state of the order.',
			store: {
				description: 'Store where the order belongs to.',
				fields: {
					name: 'Name of the store.',
					storeId: 'ID of the store.',
					address: 'Address of the store.',
				},
			},
			customCheckoutFields: 'Custom checkout fields filled by the customer during checkout.',
			customerDetails: 'Details of the person who made the order.',
			terms: {
				description: 'Order terms acceptance information.',
				fields: {
					accepted: 'Whether the terms were accepted.',
					timestamp: 'Timestamp when the terms were accepted.',
				},
			},
			staffComment: 'Internal comment about the order, added by the store staff.',
			orderNumber:
				'Order number. May not be present if the order number has not been generated yet.',
			prepared: 'Whether the order has been marked as prepared.',
			pricing: 'Order pricing information.',
			bookingComment: 'Custom comment field filled during the booking.',
		},
		example: () => {
			const created = moment();
			const start = created.clone().add(1, 'day').minutes(0).seconds(0).milliseconds(0);
			const end = start.clone().add(1, 'day');
			return {
				id: newFirestoreId(),
				createdAt: created.toISOString(),
				startDate: start.toISOString(),
				endDate: end.toISOString(),
				payment: OrderPayment.example,
				deposit: OrderPayment.example,
				cancellationPolicy: CancellationPolicy.example,
				channel: 'admin' as const,
				state: 'booked' as const,
				store: {
					name: 'ACME Store',
					storeId: newFirestoreId(),
					address: Address.example,
				},
				customerDetails: CustomerDetails.example,
				terms: {
					accepted: true,
					timestamp: created.toISOString(),
				},
				staffComment: 'Customer will arrive 30 minutes late.',
				orderNumber: 1507,
				prepared: false,
				pricing: OrderPricing.example,
				_internal: {
					acs: {
						updateState: 'IN_PROGRESS' as const,
						updateStateTs: created.toISOString(),
						updateError: null,
					},
				},
			};
		},
	},
);
