import { AdditionalDetailsApi, AdditionalDetailsDb } from './types';

export const toApi = (data: AdditionalDetailsDb): AdditionalDetailsApi => {
	return Object.entries(data).map(([key, value]) => {
		return !!value.localInput
			? { name: key, unit: value.localInput.unit, value: value.localInput.value }
			: { name: key, unit: value.unit || null, value: value.value };
	});
};

// This does not support localInput option at the moment, should be added later on
export const toDb = (data: AdditionalDetailsApi): AdditionalDetailsDb => {
	return data.reduce((acc, value) => {
		return {
			...acc,
			[value.name]: {
				unit: value.unit ?? undefined,
				value: value.value,
			},
		};
	}, {} as AdditionalDetailsDb);
};
