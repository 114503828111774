import { AddOnDefinition, AddOns } from '..';
import { DISCOVER_AND_ABOVE } from '../../plans/constants';

const definition: AddOnDefinition = {
	addOn: AddOns.MAINTENANCE_TIME,
	blockSlots: 1,
	requiresPlan: [...DISCOVER_AND_ABOVE],
	autoInstall: true,
};

export default definition;
