import { api } from 'common/frontend/api';
import { OrderDelivery } from 'common/types';
import { newFirestoreId } from 'common/utils/dataMigrations';

export let removeOrderDeliveryListener: () => void = () => undefined;

export const addOrderDeliveryListener = (
	rentalId: string,
	shopId: string,
	callback: (orderDelivery: OrderDelivery | undefined) => void,
) => {
	const orderDeliveryRef = api().orderDeliveries.byOrderAndShopId(rentalId, shopId);
	removeOrderDeliveryListener = orderDeliveryRef.onSnapshot(async (orderDelivery) => {
		callback(orderDelivery[0]);
	});
};

export const getOrderDelivery = async (
	rentalId: string,
	shopId: string,
): Promise<OrderDelivery | undefined> => {
	const orderDelivery = await api().orderDeliveries.byOrderAndShopId(rentalId, shopId).get();
	return orderDelivery[0];
};

export const updateOrderDelivery = (
	partialOrderDelivery: Partial<OrderDelivery>,
	orderDeliveryId: string,
) => {
	return api().orderDeliveries.doc(orderDeliveryId).update(partialOrderDelivery);
};

export const createOrderDelivery = async (orderDelivery: Omit<OrderDelivery, 'id'>) => {
	const orderDeliveryId = newFirestoreId();
	const delivery: OrderDelivery = {
		id: orderDeliveryId,
		...orderDelivery,
	};
	return api().orderDeliveries.doc(delivery.id).set(delivery);
};

export const removeOrderDelivery = async (orderDeliveryId: string) => {
	return api().orderDeliveries.doc(orderDeliveryId).delete();
};
