import React from 'react';

import { Tooltip, TooltipProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface Props extends TooltipProps {
	minWidth?: number;
}

const CustomTooltip = (props: Props) => {
	const { classes } = useStyles(props);
	const { title, children, placement, minWidth, ...rest } = props;

	return (
		<Tooltip
			arrow
			classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
			title={title}
			placement={placement ?? 'bottom'}
			{...rest}
		>
			{children}
		</Tooltip>
	);
};

const useStyles = makeStyles<Props>()((theme: Theme, { minWidth }) => ({
	tooltip: {
		fontSize: '14px',
		padding: theme.spacing(1, 1, 1, 1),
		background: theme.palette.common.black,
		color: theme.palette.common.white,
		minWidth,
	},
	arrow: {
		color: theme.palette.common.black,
	},
}));

export default CustomTooltip;
