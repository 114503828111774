import * as React from 'react';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: '32px 16px 56px 16px',
		width: '100%',
		flex: '1 1 auto',
		'&.fluid': {
			maxWidth: 'unset',
		},
		'& > *:first-child': {
			marginTop: 0,
		},
		[theme.breakpoints.up('sm')]: {
			padding: '32px 32px 64px 32px',
		},
		[theme.breakpoints.up('md')]: {
			padding: '32px 64px 80px 64px',
		},
		[theme.breakpoints.up(1408)]: {
			maxWidth: '1280px',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paddingBottom: {
		/* To be 32px padding to bottom btn */
		paddingBottom: 'calc(99px + 100vh*0.05)',
	},
	fluid: {
		[theme.breakpoints.up(1408)]: {
			maxWidth: 'unset',
			paddingBottom: '64px',
		},
	},
	noVerticalPadding: {
		paddingBottom: '0 !important',
		paddingTop: '0 !important',
	},
	noBottomPadding: {
		paddingBottom: '0 !important',
	},
	relative: {
		position: 'relative',
	},
}));

interface ContainerProps {
	className?: any;
	style?: any;
	children?: any;
	id?: string;
	paddingBottom?: boolean;
	noVerticalPadding?: boolean;
	noBottomPadding?: boolean;
	relative?: boolean;
	fluid?: boolean;
}

const Container = ({
	id,
	className,
	style,
	children,
	paddingBottom,
	noVerticalPadding,
	noBottomPadding,
	relative,
	fluid,
}: ContainerProps) => {
	const { classes, cx } = useStyles();
	return (
		<div
			id={id}
			style={style}
			className={cx(classes.root, className, {
				[classes.paddingBottom]: paddingBottom,
				[classes.noVerticalPadding]: noVerticalPadding,
				[classes.noBottomPadding]: noBottomPadding,
				[classes.relative]: relative,
				[classes.fluid]: fluid,
			})}
		>
			{children}
		</div>
	);
};

export default Container;
