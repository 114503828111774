import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import * as sleekPlan from 'common/services/analytics/sleekplan';
import * as UserSelectors from 'selectors/UserSelectors';

const useSleekplan = () => {
	const user = useSelector(UserSelectors.userData);
	const [initialized, setInitialized] = useState<boolean>(false);

	const sleek = (window as any).$sleek;

	useEffect(() => {
		if (!initialized) return;
		if (!!user) {
			sleekPlan.setUser(user);
		} else {
			sleekPlan.resetUser();
			sleekPlan.close();
		}
	}, [user, initialized]);

	useEffect(() => {
		if (typeof sleek.on === 'function') {
			sleekPlan.on('widget_init', () => {
				setInitialized(true);
			});
		}
	}, [sleek]);
};

export default useSleekplan;
