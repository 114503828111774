import { DISCOVER_AND_ABOVE } from 'common/modules/plans/plans/constants';

import { AddOnDefinition, AddOns } from './../types';

const definition: AddOnDefinition = {
	addOn: AddOns.BRANDING,
	blockSlots: 1,
	requiresPlan: [...DISCOVER_AND_ABOVE],
	autoInstall: true,
};

export default definition;
