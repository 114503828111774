import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const USERS_DEFINITION: Record<'USERS', OnboardingStepDefinition> = {
	USERS: {
		plans: 'all',
		versions: {
			all: ['USERS'],
		},
	},
};
