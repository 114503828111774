import { createAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { getDiscountCodeListener } from 'common/api/frontend';
import { api } from 'common/frontend/api';
import { FieldValues } from 'common/frontend/firebase/firestore';
import { MAX_DEBOUNCE_MS } from 'common/hooks/useDebouncedForm';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { DiscountCodeApi } from 'common/types';
import * as asyncUtils from 'common/utils/async';
import { replaceUndefinedValuesWith } from 'common/utils/common';
import * as ActiveDiscountSelectors from 'selectors/ActiveDiscountSelectors';
import { LoadingData, ReduxThunkAction } from 'services/types';
import { Routes, replace } from 'routing';

export const setActiveDiscountData = createAction<LoadingData<DiscountCodeApi>>(
	'ActiveDiscount/SET_ACTIVE_DISCOUNT_DATA',
);

export const editLocalDiscount = createAction<Partial<DiscountCodeApi>>(
	'ActiveDiscount/EDIT_LOCAL_DISCOUNT',
);

export const resetLocalDiscount = createAction('ActiveDiscount/RESET_LOCAL_DISCOUNT');

export const setSaving = createAction<boolean>('ActiveDiscount/SET_SAVING');
export const setError = createAction<string | null>('ActiveDiscount/SET_ERROR');
export const reset = createAction('ActiveDiscount/RESET');

export const setDiscountListener = (discountId: string): ReduxThunkAction => (dispatch) => {
	return getDiscountCodeListener(
		discountId,
		(discountCode: DiscountCodeApi) => {
			dispatch(
				setActiveDiscountData({
					loading: false,
					error: null,
					data: discountCode,
				}),
			);
		},
		(error) => {
			dispatch(
				setActiveDiscountData({
					loading: false,
					error: error.message,
					data: null,
				}),
			);
		},
	);
};

export const deleteActiveDiscount = (): ReduxThunkAction => async (dispatch, getState) => {
	const activeDiscount = ActiveDiscountSelectors.discountCodeData(getState());
	if (!activeDiscount) return;
	dispatch(
		setActiveDiscountData({
			loading: true,
			error: null,
			data: null,
		}),
	);
	try {
		await api().discountCodes.doc(activeDiscount.id).delete();
	} catch (e) {
		errorHandler.report(e);
	} finally {
		replace(Routes.CatalogTab('discounts'));
	}
};

export const saveChanges = (): ReduxThunkAction => async (dispatch, getState) => {
	dispatch(setSaving(true));
	await asyncUtils.sleep(MAX_DEBOUNCE_MS);
	const state = getState();
	const discountId = ActiveDiscountSelectors.discountCodeData(state)?.id;
	const discountCodeChanges = ActiveDiscountSelectors.localChanges(state);
	if (!discountId) {
		dispatch(setSaving(false));
		return;
	}

	/** Firestore does not allow setting undefined values, so they must be replaced here.
	 */
	const changes = replaceUndefinedValuesWith(discountCodeChanges, FieldValues.delete());

	try {
		if (!isEmpty(changes)) {
			await api().discountCodes.doc(discountId).update(changes);
		}
	} catch (err) {
		errorHandler.report(err);
		dispatch(setError(err.message));
	} finally {
		dispatch(setSaving(false));
	}
};
