import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.maintenanceTime.productSpecific',
			'Product specific',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.maintenanceTime.flexibleAutomation',
			'Flexible automation',
		),
	},
];

export default valueProps;
