import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import { Category } from 'common/types';

export const getProductCategoryQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.productCategories;
	const dataUpdater = undefined;
	const query = buildQuery<Category>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<Category>(collectionPath, dbRefs, dataUpdater),
	};
};
