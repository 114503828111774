import React from 'react';

import { Button, Link } from '@mui/material';

import { AddOn } from 'common/modules/plans';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';

interface Props {
	analytics: {
		block: AddOn;
		action: 'book-demo';
	};
}

const BookDemoButton = (props: Props) => {
	const { t } = useTranslation();
	const { logAnalyticsEvent } = useAnalytics();
	const { analytics } = props;
	const { block, action } = analytics;
	return (
		<Link
			rel="noopener noreferrer"
			target="_blank"
			href="https://www.rentle.io/meetings/hi/integration-setup"
			underline="none"
			onClick={() =>
				logAnalyticsEvent({
					name: 'block_click',
					params: { type: 'dialog', data: { block, action } },
				})
			}
		>
			<Button fullWidth color="primary" variant="contained">
				{t('account.addOns.modal.bookADemoCTA', 'Book a demo')}
			</Button>
		</Link>
	);
};

export default BookDemoButton;
