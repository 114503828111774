import { Address } from 'common/models/_atoms/Address';
import { Language } from 'common/models/_atoms/Language';
import { Segment } from 'common/models/_atoms/Segment';
import { isDeepEmptyObject } from 'common/utils/objects';

import { OrderInfoDb } from '../OrderInfo/types';
import { AdditionalDetails } from './AdditionalDetails';
import { PersonApi, PersonDb } from './types';

export const toApi = (data: PersonDb, options: { orderInfo: OrderInfoDb }): PersonApi => {
	const getMetaFields = (data: PersonDb): PersonApi['metafields'] => {
		const metafields = {
			externalAuth: data.meta?.auth
				? {
						uid: data.meta?.auth?.uid,
						email: data.meta?.auth?.email,
				  }
				: undefined,
		};
		return isDeepEmptyObject(metafields) ? undefined : metafields;
	};
	const isLiableCustomer = options.orderInfo.responsiblePerson.external
		? false
		: options.orderInfo.responsiblePerson.shopperId === data.id;

	return {
		id: data.id,
		createdAt: data.created,
		firstName: data.firstName,
		lastName: data.lastName,
		phone: data.phone,
		email: data.email,
		marketingConsent: data.marketing,
		language: !!data.language ? Language.toApi(data.language) : undefined,
		address: data.homeAddress ? Address.toApi(data.homeAddress) : undefined,
		customerId: data.customerId ?? null,
		isLiableCustomer,
		segment: !!data.segment ? Segment.toApi(data.segment) : undefined,
		staffComment: data.additionalInformation,
		additionalDetails: AdditionalDetails.toApi(data.userProperties),
		metafields: getMetaFields(data),
		itemIds: data.productIds,
	};
};
