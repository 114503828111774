import { WriteBatch, writeBatch } from 'common/frontend/firebase/firestore';
import { BaseBatchManager } from './BaseBatchManager';

/**
 * Batch manager that can be used with the client Firestore SDK
 */
export class ClientBatchManager extends BaseBatchManager<WriteBatch> {
	createBatch() {
		return writeBatch();
	}
}
