import { TFunction } from 'react-i18next';
import { IconType } from 'react-icons';
import {
	RiAddBoxLine,
	RiApps2Line,
	RiArrowLeftRightLine,
	RiDashboard3Line,
	RiDownload2Line,
	RiGroupLine,
	RiHome5Line,
	RiListSettingsLine,
	RiPriceTag3Line,
	RiSettings4Line,
	RiTableLine,
	RiTruckLine,
} from 'react-icons/ri';

import { RestrictedFeature } from 'common/modules/plans';
import { FeatureProps } from 'components/features/types';
import { Routes } from 'routing';

export type SidebarItem = {
	id: string;
	label: string;
	Icon: IconType | React.FC;
	to: string;
	locked?: boolean;
	hidden?: boolean;
};

export const getSidebarItems = (
	t: TFunction,
	hasFeature: (
		requiredFeature: RestrictedFeature | RestrictedFeature[] | undefined,
		options?: Omit<FeatureProps, 'requiredFeature'> | undefined,
	) => boolean,
): SidebarItem[] => [
	{
		id: 'home',
		label: t('headerBar.home', 'Home'),
		Icon: RiHome5Line,
		to: Routes.HOME_VIEW,
	},
	{
		id: 'create-order',
		label: t('headerBar.createOrder', 'Create order'),
		Icon: RiAddBoxLine,
		to: Routes.NEW_RENTAL,
		locked: !hasFeature('NEW_RENTAL', { scope: 'shop' }),
		hidden: !hasFeature('NEW_RENTAL', { scope: 'user' }),
	},
	{
		id: 'orders',
		label: t('headerBar.orders', 'Orders'),
		Icon: RiArrowLeftRightLine,
		to: Routes.BOOKINGS,
	},
	{
		id: 'skidata',
		label: 'SKIDATA',
		Icon: RiDownload2Line,
		to: Routes.SKIDATA,
		hidden: !hasFeature('SKIDATA', { scope: 'shop' }),
	},
	{
		id: 'catalog',
		label: t('headerBar.catalog', 'Catalog'),
		Icon: RiPriceTag3Line,
		to: Routes.CATALOG,
		hidden: !hasFeature('PRODUCTS'),
	},
	{
		id: 'inventory',
		label: t('headerBar.inventory', 'Inventory'),
		Icon: RiTableLine,
		to: Routes.INVENTORY,
		hidden: !hasFeature('INVENTORY'),
	},
	{
		id: 'customers',
		label: t('headerBar.customers', 'Customers'),
		Icon: RiGroupLine,
		to: Routes.CUSTOMERS,
		locked: !hasFeature('CUSTOMER_DATA'),
	},
	{
		id: 'delivery',
		label: t('delivery.delivery', 'Delivery'),
		Icon: RiTruckLine,
		to: Routes.DELIVERY,
		locked: !hasFeature('DELIVERY_SERVICE', { scope: 'shop' }),
		hidden: !hasFeature('DELIVERY_SERVICE', { scope: 'user' }),
	},
	{
		id: 'dashboard',
		label: t('headerBar.dashboard'),
		Icon: RiDashboard3Line,
		to: Routes.DASHBOARD,
		hidden: !hasFeature('DASHBOARD'),
	},
	{
		id: 'settings',
		label: t('headerBar.settings'),
		Icon: RiListSettingsLine,
		to: Routes.STORE,
		hidden: !hasFeature('STORE_SETTINGS'),
	},
	{
		id: 'addOns',
		label: t('addOns.pageTitle2', 'Add-ons'),
		Icon: RiApps2Line,
		to: Routes.ADD_ONS_STORE,
	},
	{
		id: 'account',
		label: t('account.pageTitle'),
		Icon: RiSettings4Line,
		to: Routes.ACCOUNT,
	},
];

export const getResaleSidebarItems = (): SidebarItem[] => [
	{
		id: 'home',
		label: 'Home',
		Icon: RiHome5Line,
		to: Routes.LISTINGS,
	},
	{
		id: 'create-listing',
		label: 'Create listing',
		Icon: RiAddBoxLine,
		to: Routes.LISTINGS_CREATE,
	},
];
