import { useCallback, useEffect, useRef, useState } from 'react';

interface CarouselOptions {
	itemCount: number;
	autoplayInterval?: number;
	infinite?: boolean;
}

const useCarousel = ({ itemCount, autoplayInterval, infinite = true }: CarouselOptions) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const timeoutRef = useRef<number | null>(null);

	const goLeft = useCallback(() => {
		if (infinite || activeIndex > 0) {
			setActiveIndex((activeIndex + itemCount - 1) % itemCount);
		}
	}, [activeIndex, infinite, itemCount]);

	const goRight = useCallback(() => {
		if (infinite || activeIndex < itemCount - 1) {
			setActiveIndex((activeIndex + 1) % itemCount);
		}
	}, [activeIndex, infinite, itemCount]);

	// Ensure activeIndex is not out of bounds when itemCount decreases
	useEffect(() => {
		if (activeIndex >= itemCount) {
			setActiveIndex(itemCount - 1);
		}
	}, [itemCount, activeIndex]);

	// Setup and manage autoplay
	useEffect(() => {
		if (!autoplayInterval) return;
		const startAutoplay = () => {
			clearTimeout(timeoutRef.current || undefined);
			// To avoid type errors, we explicitly use the browser's setTimeout
			timeoutRef.current = window.setTimeout(goRight, autoplayInterval);
		};
		startAutoplay();
		// Clear timeout if component is unmounted or if activeIndex changes
		return () => clearTimeout(timeoutRef.current || undefined);
	}, [autoplayInterval, activeIndex, goRight]);

	return { activeIndex, goLeft, goRight, setActiveIndex };
};

export default useCarousel;
