import { getInventoryTransferQueries } from 'common/db/api/paths/inventoryTransfers';

import { createApiHelperQueries } from '../helperQueries';
import { DbRefs } from '../types';
import { createCollectionGroupQuery } from '../utils/collectionGroupQuery';
import { getAcsGatewayQueries } from './acsGateways';
import { getAcsInventoryQueries } from './acsInventory';
import { getAcsOrderQueries } from './acsOrders';
import { getAcsPurchaseOrderQueries } from './acsPurchaseOrders';
import { getAcsRetirementQueries } from './acsRetirements';
import { getAcsReturnQueries } from './acsReturns';
import { getAdminQueries } from './admin';
import { getAppmixerUsersQueries } from './appmixerUsers';
import { getAvailabilityStartTimes } from './availabilityStartTimes';
import { getBillingQueries } from './billing';
import { getCampaignsQueries } from './campaigns';
import { getCarriersQueries } from './carriers';
import { getClientUpdatesQueries } from './clientUpdates';
import { getCustomThemeQueries } from './customThemes';
import { getCustomersQueries } from './customers';
import { getDeletedShopsQueries } from './deletedShops';
import { getDeliveryLocationQueries } from './deliveryLocations';
import { getDeliveryOptionsQueries } from './deliveryOptions';
import { getDiscountCodeQueries } from './discountCodes';
import { getEmailSettingsQueries } from './emailSettings';
import { getInventoryConfigurationQueries } from './inventoryConfigurations';
import { getInventoryItemQueries } from './inventoryItems';
import { getInvoicePaymentQueries } from './invoicePayments';
import { getInvoiceReceiptQueries } from './invoiceReceipts';
import { getInvoiceTransactionQueries } from './invoiceTransactions';
import { getMockAcsDbQueries } from './mockAcsDb';
import { getOpeningHoursQueries } from './openingHours';
import { getOrderDeliveriesQueries } from './orderDeliveries';
import { getOrderProductQueries } from './orderProducts';
import { getOrderShopperQueries } from './orderShoppers';
import { getOrderSubscriptionPayments } from './orderSubscriptionPayments';
import { getOrderQueries } from './orders';
import { getPartnerAuthQueries } from './partnerAuth';
import { getPaymentQueries } from './payments';
import { getPendingCapturesQueries } from './pendingCaptures';
import { getPendingDepositQueries } from './pendingDeposits';
import { getPendingExtendRequestQueries } from './pendingExtendRequests';
import { getPendingOrderQueries } from './pendingOrders';
import { getPendingRefundsQueries } from './pendingRefunds';
import { getPendingTransactionQueries } from './pendingTransactions';
import { getPlanAuthPaymentQueries } from './planAuthPayments';
import { getPlanAuthReceiptQueries } from './planAuthReceipts';
import { getPlanAuthTransactionQueries } from './planAuthTransactions';
import { getProductCategoryQueries } from './productCategories';
import { getProductQueries } from './products';
import { getReceiptQueries } from './receipts';
import { getRentleAccountSeedQueries } from './rentleAccountSeeds';
import { getReportExportQueries } from './reportExports';
import { getReservationQueries } from './reservations';
import { getShopAnalyticsQueries } from './shopAnalytics';
import { getShopBalancesQueries } from './shopBalance';
import { getShopTemplatesQueries } from './shopTemplates';
import { getShopUrlsQueries } from './shopUrls';
import { getShopQueries } from './shops';
import { getSkuItemQueries } from './skuItems';
import { getStoreEvents } from './storeEvents';
import { getTransactionLedgerQueries } from './transactionLedger';
import { getTransactionQueries } from './transactions';
import { getTransactionsMissingQueries } from './transactionsMissing';
import { getUsageHistoryQueries } from './usageHistory';
import { getUserQueries } from './users';

export const createDbPaths = (dbRef: DbRefs) =>
	({
		_db: dbRef.db,
		FieldValues: dbRef.FieldValues,
		// Utility helpers for api
		_utils: createApiHelperQueries(dbRef),
		_collectionGroup: createCollectionGroupQuery(dbRef),
		// All DB paths and their queries
		availabilityStartTimes: getAvailabilityStartTimes(dbRef),
		campaigns: getCampaignsQueries(dbRef),
		clientUpdates: getClientUpdatesQueries(dbRef),
		customers: getCustomersQueries(dbRef),
		deletedShops: getDeletedShopsQueries(dbRef),
		admin: getAdminQueries(dbRef),
		invoiceTransactions: getInvoiceTransactionQueries(dbRef),
		invoicePayments: getInvoicePaymentQueries(dbRef),
		invoiceReceipts: getInvoiceReceiptQueries(dbRef),
		openingHours: getOpeningHoursQueries(dbRef),
		orders: getOrderQueries(dbRef),
		orderShoppers: getOrderShopperQueries(dbRef),
		orderProducts: getOrderProductQueries(dbRef),
		orderDeliveries: getOrderDeliveriesQueries(dbRef),
		orderSubscriptionPayments: getOrderSubscriptionPayments(dbRef),
		usageHistory: getUsageHistoryQueries(dbRef),
		productCategories: getProductCategoryQueries(dbRef),
		pendingExtendRequests: getPendingExtendRequestQueries(dbRef),
		pendingCaptures: getPendingCapturesQueries(dbRef),
		pendingDeposits: getPendingDepositQueries(dbRef),
		pendingOrders: getPendingOrderQueries(dbRef),
		pendingRefunds: getPendingRefundsQueries(dbRef),
		pendingTransactions: getPendingTransactionQueries(dbRef),
		planAuthPayments: getPlanAuthPaymentQueries(dbRef),
		planAuthReceipts: getPlanAuthReceiptQueries(dbRef),
		planAuthTransactions: getPlanAuthTransactionQueries(dbRef),
		receipts: getReceiptQueries(dbRef),
		rentleAccountSeeds: getRentleAccountSeedQueries(dbRef),
		storeEvents: getStoreEvents(dbRef),
		inventoryItems: getInventoryItemQueries(dbRef),
		inventoryConfigurations: getInventoryConfigurationQueries(dbRef),
		inventoryTransfers: getInventoryTransferQueries(dbRef),
		skuItems: getSkuItemQueries(dbRef),
		shops: getShopQueries(dbRef),
		shopBalances: getShopBalancesQueries(dbRef),
		payments: getPaymentQueries(dbRef),
		products: getProductQueries(dbRef),
		reportExports: getReportExportQueries(dbRef),
		reservations: getReservationQueries(dbRef),
		users: getUserQueries(dbRef),
		shopAnalytics: getShopAnalyticsQueries(dbRef),
		discountCodes: getDiscountCodeQueries(dbRef),
		shopUrls: getShopUrlsQueries(dbRef),
		transactions: getTransactionQueries(dbRef),
		transactionLedger: getTransactionLedgerQueries(dbRef),
		transactionsMissing: getTransactionsMissingQueries(dbRef),
		billing: getBillingQueries(dbRef),
		deliveryOptions: getDeliveryOptionsQueries(dbRef),
		deliveryLocations: getDeliveryLocationQueries(dbRef),
		carriers: getCarriersQueries(dbRef),
		emailSettings: getEmailSettingsQueries(dbRef),
		shopTemplates: getShopTemplatesQueries(dbRef),
		customThemes: getCustomThemeQueries(dbRef),
		appmixerUsers: getAppmixerUsersQueries(dbRef),
		acsGateways: getAcsGatewayQueries(dbRef),
		acsOrders: getAcsOrderQueries(dbRef),
		acsPurchaseOrders: getAcsPurchaseOrderQueries(dbRef),
		acsInventory: getAcsInventoryQueries(dbRef),
		acsReturns: getAcsReturnQueries(dbRef),
		acsRetirements: getAcsRetirementQueries(dbRef),
		mockAcsDb: getMockAcsDbQueries(dbRef),
		partnerAuth: getPartnerAuthQueries(dbRef),
	} as const);

export type Api = ReturnType<typeof createDbPaths>;
