import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { ACSGatewayDb } from 'common/modules/acs';

import { createBasicDbOperations } from '../utils';

export const getAcsGatewayQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.acsGateways;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<ACSGatewayDb>(collectionPath, dbRefs, dataUpdater),
	};
};
