import { fixInventoryItemAllocationQuantity } from './../../../modules/inventory/utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import { SkuItem } from 'common/modules/inventory/types';
import { getAllocationsByLocationFromStatusesByLocation } from 'common/modules/inventory';

const dataUpdaterFn = (item: SkuItem) => {
	if ((item as any).type && !item.itemType) {
		item.itemType = (item as any).type === 'individual' ? 'single' : 'bulk';
	}

	if (item.allocationsByLocation === undefined) {
		item.allocationsByLocation = getAllocationsByLocationFromStatusesByLocation(item);
	}

	item.allocationsByLocation = Object.entries(item.statusesByLocation ?? {}).reduce(
		(result, [locationId, statuses]) => {
			result[locationId] = fixInventoryItemAllocationQuantity(
				item.allocationsByLocation?.[locationId] ?? {},
				statuses?.IN_USE,
			);
			return result;
		},
		{},
	);

	return item;
};

export const getSkuItemQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.skuItems;
	const dataUpdater = dataUpdaterFn;
	const query = buildQuery<SkuItem>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<SkuItem>(collectionPath, dbRefs, dataUpdater),
	};
};
