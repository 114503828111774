import { createReducer } from '@reduxjs/toolkit';

import { CustomizerTheme } from 'common/modules/customization/types';
import { LocationEmailSettings } from 'common/modules/emails/types';
import { OpeningHoursDoc } from 'common/modules/openingHours';
import { getDefaultInstalledAddOn } from 'common/modules/plans';
import { Carrier, DeliveryOption, DiscountCodeApi, Shop, UserApi } from 'common/types';
import * as ShopActions from 'actions/ShopActions';
import * as UserActions from 'actions/UserActions';
import { LoadingData } from 'services/types';
import { setError, setFulfilled, setLoading } from 'services/utils/redux';

export interface ShopState {
	shop: LoadingData<Shop>;
	users: LoadingData<UserApi[]>;
	discountCodes: LoadingData<DiscountCodeApi[]>;
	adyenPaymentMethods: LoadingData<ICheckout.PaymentMethodsResponse | null>;
	deliveryOptions: LoadingData<DeliveryOption[]>;
	carriers: LoadingData<Carrier[]>;
	emailSettings: LoadingData<LocationEmailSettings[]>;
	customThemes: LoadingData<CustomizerTheme[]>;
	rentleCustomTheme: LoadingData<CustomizerTheme | undefined>;
	openingHours: LoadingData<OpeningHoursDoc[]>;
}

const INITIAL_STATE: ShopState = {
	shop: {
		loading: true,
		data: null,
		error: null,
	},
	users: {
		loading: true,
		data: null,
		error: null,
	},
	discountCodes: {
		loading: true,
		data: null,
		error: null,
	},
	adyenPaymentMethods: {
		loading: true,
		data: null,
		error: null,
	},
	deliveryOptions: {
		loading: true,
		data: null,
		error: null,
	},
	carriers: {
		loading: true,
		data: null,
		error: null,
	},
	emailSettings: {
		loading: true,
		data: null,
		error: null,
	},
	customThemes: {
		loading: true,
		data: null,
		error: null,
	},
	rentleCustomTheme: {
		loading: true,
		data: null,
		error: null,
	},
	openingHours: {
		loading: true,
		data: null,
		error: null,
	},
};

const ShopReducer = createReducer(INITIAL_STATE, (builder) => {
	/**
	 * Active shop
	 */
	builder
		.addCase(ShopActions.setActiveShop.pending, (state) => {
			setLoading(state.shop);
		})
		.addCase(ShopActions.setActiveShop.rejected, (state, action) => {
			setError(state.shop, { error: action.error.message ?? 'Error getting active shop' });
		})
		.addCase(ShopActions.setActiveShop.fulfilled, (state, action) => {
			setFulfilled(state.shop, { data: action.payload });
		});

	/**
	 * Active shop sub-tree updates
	 */
	builder
		.addCase(ShopActions.setShopInfoData, (state, action) => {
			if (!!state.shop.data) {
				state.shop.data.shopInfo = action.payload;
			}
		})
		.addCase(ShopActions.setShopPublicInfoData, (state, action) => {
			if (!!state.shop.data) {
				state.shop.data.publicInfo = action.payload;
			}
		})
		.addCase(ShopActions.setShopReadOnlyData, (state, action) => {
			if (!!state.shop.data) {
				state.shop.data.readOnly = action.payload;
			}
		})
		.addCase(ShopActions.setShopUrlsData, (state, action) => {
			if (!!state.shop.data) {
				state.shop.data.shopUrls = action.payload;
			}
		})
		.addCase(ShopActions.setShopAnalyticsData, (state, action) => {
			if (!!state.shop.data) {
				state.shop.data.shopAnalytics = action.payload;
			}
		});

	/**
	 * Active shop users
	 */
	builder
		.addCase(ShopActions.setActiveShopUsersListener.pending, (state) => {
			setLoading(state.users);
		})
		.addCase(ShopActions.setActiveShopUsersError, (state, action) => {
			if (!state.users.data) {
				setError(state.users, { error: action.payload });
			}
		})
		.addCase(ShopActions.setActiveShopUsersData, (state, action) => {
			setFulfilled(state.users, { data: action.payload });
		});

	/**
	 * Active shop discount codes
	 */
	builder
		.addCase(ShopActions.setDiscountCodesListener.pending, (state) => {
			setLoading(state.discountCodes);
		})
		.addCase(ShopActions.setDiscountCodesError, (state, action) => {
			setError(state.discountCodes, { error: action.payload });
		})
		.addCase(ShopActions.setDiscountCodesData, (state, action) => {
			setFulfilled(state.discountCodes, { data: action.payload });
		});

	/**
	 * Active shop payment methods
	 */
	builder
		.addCase(ShopActions.setAdyenPaymentMethodsError, (state, action) => {
			setError(state.adyenPaymentMethods, { error: action.payload });
		})
		.addCase(ShopActions.setAdyenPaymentMethodsData, (state, action) => {
			setFulfilled(state.adyenPaymentMethods, { data: action.payload });
		});

	/**
	 * Active shop delivery options
	 */
	builder
		.addCase(ShopActions.setShopDeliveryOptionsListener.pending, (state) => {
			setLoading(state.deliveryOptions);
		})
		.addCase(ShopActions.setShopDeliveryOptionsError, (state, action) => {
			setError(state.deliveryOptions, { error: action.payload });
		})
		.addCase(ShopActions.setShopDeliveryOptionsData, (state, action) => {
			setFulfilled(state.deliveryOptions, { data: action.payload });
		});

	/**
	 * Active shop carrier options
	 */
	builder
		.addCase(ShopActions.setShopCarriersListener.pending, (state) => {
			setLoading(state.carriers);
		})
		.addCase(ShopActions.setShopCarriersError, (state, action) => {
			setError(state.carriers, { error: action.payload });
		})
		.addCase(ShopActions.setShopCarriersData, (state, action) => {
			setFulfilled(state.carriers, { data: action.payload });
		});

	builder.addCase(ShopActions.addInstalledAddOn, (state, action) => {
		if (!!state.shop.data?.readOnly?.features) {
			const addOn = action.payload;
			state.shop.data.readOnly.features.addOns = {
				...state.shop.data.readOnly.features.addOns,
				[addOn]: getDefaultInstalledAddOn(addOn),
			};
		}
	});

	/**
	 * Active shop email settings
	 */
	builder
		.addCase(ShopActions.setActiveShopEmailSettingsListener.pending, (state) => {
			setLoading(state.emailSettings);
		})
		.addCase(ShopActions.setActiveShopEmailSettingsError, (state, action) => {
			setError(state.emailSettings, { error: action.payload });
		})
		.addCase(ShopActions.setActiveShopEmailSettingsData, (state, action) => {
			setFulfilled(state.emailSettings, { data: action.payload });
		});

	/**
	 * Active shop custom themes
	 */
	builder
		.addCase(ShopActions.setMerchantCustomThemesListener.pending, (state) => {
			setLoading(state.customThemes);
		})
		.addCase(ShopActions.setMerchantCustomThemesError, (state, action) => {
			setError(state.customThemes, { error: action.payload });
		})
		.addCase(ShopActions.setMerchantCustomThemes, (state, action) => {
			setFulfilled(state.customThemes, { data: action.payload });
		});

	/**
	 * Active rentle custom theme
	 */
	builder
		.addCase(ShopActions.setRentleCustomThemeListener.pending, (state) => {
			setLoading(state.rentleCustomTheme);
		})
		.addCase(ShopActions.setRentleCustomThemeError, (state, action) => {
			setError(state.rentleCustomTheme, { error: action.payload });
		})
		.addCase(ShopActions.setRentleCustomTheme, (state, action) => {
			setFulfilled(state.rentleCustomTheme, { data: action.payload });
		});

	/**
	 * Active shop opening hours
	 */
	builder
		.addCase(ShopActions.setShopOpeningHoursListener.pending, (state) => {
			setLoading(state.openingHours);
		})
		.addCase(ShopActions.setShopOpeningHoursError, (state, action) => {
			setError(state.openingHours, { error: action.payload });
		})
		.addCase(ShopActions.setShopOpeningHours, (state, action) => {
			setFulfilled(state.openingHours, { data: action.payload });
		});

	builder.addCase(UserActions.logout.fulfilled, () => INITIAL_STATE);
});

export default ShopReducer;
