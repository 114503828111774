import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getRouteOnboardingStepItem } from '../utils';

export const VIEW_CATALOG_ITEM = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<
	'VIEW_CATALOG',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	VIEW_CATALOG: {
		all: {
			action: showProductTours
				? getFlowOnboardingStepItem({ onboardingAction: 'VIEW_CATALOG' })
				: getRouteOnboardingStepItem({
						routeTo: Routes.CatalogTab('products'),
						completeOnClick: true,
				  }),
			content: {
				title: t('homeView.onboarding.checkCatalogOverview', 'Check the catalog overview'),
			},
			id: 'VIEW_CATALOG',
			type: 'row',
		},
	},
});
