import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

import { BaseOrderItem } from '../_BaseOrderItem.api';

export const zPackageItemApi = withOpenApi(
	BaseOrderItem.api
		.pick({
			productId: true,
			name: true,
			fulfillmentFields: true,
			returnedDate: true,
			itemCodes: true,
			variantId: true,
			variantValues: true,
			itemSkus: true,
		})
		.extend({
			removedFromPackage: z.boolean().optional(),
		}),
	{
		fields: {
			removedFromPackage: 'Indicates if the item was removed from the package',
		},
		example: {
			...BaseOrderItem.example!,
			removedFromPackage: false,
		},
	},
);
