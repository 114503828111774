import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

import { OrderPricing } from '../../OrderPricing';

export const zItemPricingApi = withOpenApi(
	OrderPricing.api
		.omit({
			manualDiscount: true,
			serviceFee: true,
			deposit: true,
			paymentMethod: true,
		})
		.extend({
			manualDiscount: z.number().int().nonnegative().optional(),
			deposit: z.number().int().nonnegative().optional(),
			listPrice: z.number().int().nonnegative(),
			originalListPrice: z.number().int().nonnegative(),
		}),
	{
		fields: {
			listPrice:
				'Item price without any discounts. Can be modified by store manually by editing item price.',
			originalListPrice: 'Original item price without any discounts or modifications',
			manualDiscount: 'Manual discount applied.',
			deposit: 'Deposit amount.',
		},
		example: {
			total: 100_00,
			currency: 'USD',
			subtotal: 100_00,
			totalTaxes: 24_00,
			totalDiscounts: 0,
			taxExcluded: false,
			taxLines: [
				{
					price: 24_00,
					rate: 24,
				},
			],
			manualDiscount: 0,
			deposit: 0,
			listPrice: 100_00,
			originalListPrice: 100_00,
		},
	},
);
