import { ADVANCE_AND_ABOVE } from 'common/modules/plans/plans/constants';

import { AddOnDefinition, AddOns } from '../types';

const definition: AddOnDefinition = {
	addOn: AddOns.MASS_RETURN,
	blockSlots: 1,
	hidden: true,
	requiresPlan: [...ADVANCE_AND_ABOVE],
	autoInstall: true,
};

export default definition;
