import { TFunction } from 'i18next';
import { omit } from 'lodash';
import moment from 'moment-timezone';

import { getItemPricingFromListPrice } from 'common/modules/atoms/pricing';
import { getTaxRateFromVatPercent } from 'common/modules/atoms/taxes';
import {
	AppliedDiscountCodes,
	CartDelivery,
	Currency,
	CurrencyObject,
	DateFormatObject,
	DeliveryOpeningHoursBehaviour,
	DeliveryOption,
	DeliveryOptionHandlingTimes,
	DeliveryPeriod,
	DeliveryTime,
	DeliveryTimeSlot,
	DeliveryTimeSlotTimes,
	DeliveryType,
	DeliveryTypes,
	Duration,
	DurationWithPrice,
	ISOString,
	LocaleField,
	OrderDelivery,
	OrderDeliveryDetails,
	OrderDeliveryService,
} from 'common/types';
import { getPricingString, notUndefined } from 'common/utils/common';
import { localFormat } from 'common/utils/dateUtils';
import { isSameDurationOption } from 'common/utils/duration';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import { getDurationFromDurationWithPriceOption } from 'common/utils/pricing';

import { EMPTY_FIXED_BUFFER_TIME, getBufferTimeAsMinutes } from '../atoms/bufferTimes';
import { ItemPricing, getEmptyItemPricing, getTotalItemPricing } from '../atoms/pricing';
import { OpeningHours } from '../openingHours';
import { SELF_RETURN_CARRIER } from './constants';

export const sortOrderDeliveriesByName = (s1: OrderDelivery, s2: OrderDelivery): 0 | 1 | -1 => {
	if (s1.name.def > s2.name.def) {
		return -1;
	} else if (s1.name.def < s2.name.def) {
		return 1;
	}
	return 0;
};

export const getOrderDeliveryTotalPricing = (services: OrderDelivery): ItemPricing => {
	const orderDeliveryPricing = services.pricing;
	return getTotalItemPricing([orderDeliveryPricing], orderDeliveryPricing.currency);
};

export const timeslotDurationInMinutes = (timeslot?: DeliveryTimeSlot): number => {
	if (!timeslot) return 0;
	return moment(timeslot.endDate).diff(timeslot.startDate, 'minutes');
};

export const timeslotToString = (args: {
	timeslot: DeliveryTimeSlot | null;
	shopDateFormat: DateFormatObject;
	timezone: string;
	hideDates?: boolean;
}): string => {
	const { timeslot, shopDateFormat, timezone, hideDates } = args;
	if (!timeslot) return '';
	const startMoment = moment.tz(timeslot.startDate, timezone);
	const startDate = localFormat(startMoment, 'ddd. MMM D', shopDateFormat);
	const startTime = localFormat(startMoment, 'HH:mm', shopDateFormat);

	const endMoment = moment.tz(timeslot.endDate, timezone);
	const endDate = localFormat(endMoment, 'ddd. MMM D', shopDateFormat);
	const endTime = localFormat(endMoment, 'HH:mm', shopDateFormat);

	return !!hideDates
		? `${startTime} - ${endTime}`
		: endDate === startDate
		? `${startDate}, ${startTime} - ${endTime}`
		: `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
};

export const deliveryTimeSlotTimesToDates = (args: {
	deliveryTimeSlotTimes: DeliveryTimeSlotTimes;
	date: ISOString;
	timezone: string;
}): DeliveryTimeSlot => {
	const { deliveryTimeSlotTimes, date, timezone } = args;
	const [startHours, startMinutes] = deliveryTimeSlotTimes.startTime.split(':').map(Number);
	const [endHours, endMinutes] = deliveryTimeSlotTimes.endTime.split(':').map(Number);
	return {
		startDate: moment
			.tz(date, timezone)
			.hours(startHours)
			.minutes(startMinutes)
			.startOf('minute')
			.toISOString(),
		endDate: moment
			.tz(date, timezone)
			.hours(endHours)
			.minutes(endMinutes)
			.startOf('minute')
			.toISOString(),
	};
};

export const asDateString = (shopDateFormat: DateFormatObject, date?: string): string | undefined =>
	!!date ? localFormat(date, 'ddd. MMM D', shopDateFormat) : undefined;

export const getDeliveryInventoryBlockersBefore = (args: {
	deliveryOption: DeliveryOption;
	deliverySlot: DeliveryTimeSlot | null;
	timezone: string;
	openingHours: OpeningHours;
}) => {
	const { deliveryOption, deliverySlot, timezone, openingHours } = args;
	const deliverySlotStartDate = deliverySlot?.startDate;
	const deliveryHandlingBefore = !!deliverySlotStartDate
		? getBufferTimeAsMinutes({
				value: deliveryOption.handlingTimes?.handlingTimeBefore,
				from: deliverySlotStartDate,
				direction: 'before',
				timezone,
				openingHours,
		  })
		: 0;
	return {
		...(!!deliverySlot && { deliverySlot }),
		...(!!deliveryHandlingBefore && { deliveryHandlingBefore }),
	};
};

export const getDeliveryInventoryBlockersAfter = (args: {
	deliveryOption: DeliveryOption;
	pickupSlotOrEndDate: DeliveryTimeSlot | ISOString | null;
	timezone: string;
	openingHours: OpeningHours;
}) => {
	const { deliveryOption, pickupSlotOrEndDate, timezone, openingHours } = args;
	const pickupSlot = typeof pickupSlotOrEndDate === 'string' ? null : pickupSlotOrEndDate;
	const orderEndDate = typeof pickupSlotOrEndDate === 'string' ? pickupSlotOrEndDate : null;
	const handlingStartDate = pickupSlot?.endDate ?? orderEndDate;
	const deliveryHandlingAfter = !!handlingStartDate
		? getBufferTimeAsMinutes({
				value: deliveryOption.handlingTimes?.handlingTimeAfter,
				from: handlingStartDate,
				direction: 'after',
				timezone,
				openingHours,
		  })
		: 0;
	return {
		...(!!pickupSlot && { pickupSlot }),
		...(!!deliveryHandlingAfter && { deliveryHandlingAfter }),
	};
};

interface DeliveryOptionToCartDeliveryProps {
	currency: Currency;
	taxExcluded: boolean;
	openingHours: OpeningHours;
	timezone: string;
	manualDiscount?: number;
	discountCodes?: AppliedDiscountCodes;
	getTranslation: (l: LocaleField) => string;
	t: TFunction;
}

export const getDeliveryPickupDetails = (args: {
	deliveryOption: DeliveryOption;
	options: DeliveryOptionToCartDeliveryProps;
}): OrderDeliveryDetails | null => {
	const { deliveryOption, options } = args;
	const { currency } = options;

	switch (deliveryOption.type) {
		case 'DELIVERY_AND_PICKUP':
			return {
				pricing: getEmptyItemPricing(currency),
				timeslot: null,
				handlingTimeMinutes: 0,
				location: {
					address: null,
				},
			};

		case 'DELIVERY_ONLY': {
			return {
				pricing: getEmptyItemPricing(currency),
				timeslot: null,
				handlingTimeMinutes: 0,
				location: {
					address: null,
				},
				disabled: true,
				carrier: SELF_RETURN_CARRIER,
			};
		}
		case 'DELIVERY_AND_OPTIONAL_PICKUP':
		default:
			return null;
	}
};

export const getDeliveryDetails = (args: {
	deliveryOption: DeliveryOption;
	options: DeliveryOptionToCartDeliveryProps & { deliveryTimeslot: DeliveryTimeSlot };
}): OrderDeliveryDetails => {
	const { deliveryOption, options } = args;
	const {
		currency,
		taxExcluded,
		manualDiscount,
		discountCodes,
		deliveryTimeslot,
		openingHours,
		timezone,
	} = options;

	return {
		pricing: getItemPricingFromListPrice(deliveryOption.price.delivery, {
			taxRate: getTaxRateFromVatPercent(deliveryOption.vatPercent),
			manualDiscount,
			discountCodes,
			taxExcluded,
			currency,
		}),
		timeslot: deliveryTimeslot,
		handlingTimeMinutes:
			getDeliveryInventoryBlockersBefore({
				deliveryOption,
				deliverySlot: deliveryTimeslot,
				openingHours,
				timezone,
			}).deliveryHandlingBefore ?? 0,
		location: {
			address: null,
		},
	};
};

export const deliveryOptionToCartDelivery = (
	delivery: DeliveryOption,
	options: DeliveryOptionToCartDeliveryProps & { deliveryTimeslot: DeliveryTimeSlot },
): CartDelivery => {
	const { id, name } = delivery;
	const { currency } = options;

	const to = getDeliveryDetails({
		deliveryOption: delivery,
		options,
	});

	const from = getDeliveryPickupDetails({
		deliveryOption: delivery,
		options,
	});

	const totalPricing = getTotalItemPricing(
		[to.pricing, from?.pricing].filter(notUndefined),
		currency,
	);

	const returnInstructions = delivery.type === 'DELIVERY_ONLY' ? delivery.returnInstructions : null;

	return {
		id: newFirestoreId(),
		name,
		pricing: totalPricing,
		deliveryOptionId: id,
		to,
		...(!!returnInstructions && { returnInstructions }),
		...(!!from && { from }),
	};
};

export const isDurationSupportedByDeliveryOption = (
	duration: Duration | DurationWithPrice,
	deliveryOption: DeliveryOption,
): boolean => {
	/**
	 * Always allow fixed price products for duration
	 */
	if (duration.durationInSeconds === 0) return true;
	return duration.durationInSeconds >= deliveryOption.minimalDurationInSeconds;
};

export const isDurationMatchingWithCartDuration = (
	option: DurationWithPrice,
	cartDuration: Duration | null,
) => {
	if (!cartDuration) return true;
	const optionDuration = getDurationFromDurationWithPriceOption(option);
	return isSameDurationOption(cartDuration, optionDuration, { ignoreLabel: true });
};

export const getDeliveryInventoryBlockersBeforeAsMinutes = (args: {
	deliveryOption: DeliveryOption;
	cartDelivery: CartDelivery;
	timezone: string;
	openingHours: OpeningHours;
}): number => {
	const { deliveryOption, cartDelivery, timezone, openingHours } = args;
	const blockers = getDeliveryInventoryBlockersBefore({
		deliveryOption,
		deliverySlot: cartDelivery.to?.timeslot ?? null,
		timezone,
		openingHours,
	});
	const slotDuration = timeslotDurationInMinutes(blockers.deliverySlot);
	const handlingTimeBefore = blockers.deliveryHandlingBefore ?? 0;
	return slotDuration + handlingTimeBefore;
};

export const orderHasDeliveryOrPickup = (orderDelivery: OrderDelivery | null | undefined) => {
	return !!orderDelivery;
};

export const orderHasDelivery = (orderDelivery: OrderDelivery | null | undefined) => {
	return !!orderDelivery?.to && !orderDelivery?.to?.disabled;
};

export const orderHasPickup = (orderDelivery: OrderDelivery | null | undefined) => {
	return (
		!!orderDelivery?.from &&
		!orderDelivery?.from?.disabled &&
		orderDelivery?.from.carrier?.id !== SELF_RETURN_CARRIER?.id
	);
};

export const withoutAvailablePickupSlots = (
	orderDelivery: OrderDeliveryService,
): OrderDeliveryService => omit(orderDelivery, 'availablePickupSlots');

export const getDeliveryPricingString = (
	deliveryOption: DeliveryOption,
	currency: CurrencyObject,
) => {
	return getPricingString(deliveryOption.price.delivery, currency);
};

export const areAllDeliveryOptionsHidden = (options: DeliveryOption[]) =>
	options.every((o) => o.hidden);

export const deliveryOptionSupportsPickup = (deliveryOption: DeliveryOption): boolean => {
	switch (deliveryOption?.type) {
		case 'DELIVERY_AND_OPTIONAL_PICKUP':
		case 'DELIVERY_AND_PICKUP':
			return true;
		case 'DELIVERY_ONLY':
		default:
			return false;
	}
};

export const deliveryOptionRequiresPickup = (deliveryOption: DeliveryOption): boolean => {
	switch (deliveryOption?.type) {
		case 'DELIVERY_AND_OPTIONAL_PICKUP':
		case 'DELIVERY_ONLY':
			return false;
		case 'DELIVERY_AND_PICKUP':
		default:
			return true;
	}
};

export const isDeliveryType = (value?: any): value is DeliveryType => {
	return Object.values(DeliveryTypes).includes(value);
};

export const getDeliveryTimeslotsForDate = (args: {
	date: ISOString;
	deliveryOption: DeliveryOption;
	timezone: string;
}): DeliveryTimeSlot[] => {
	const { date, deliveryOption, timezone } = args;
	const deliveryPeriods = filterDeliveryPeriodsForDate({
		date,
		periods: deliveryOption.deliveryPeriods,
	});

	const deliveryTimes = filterDeliveryTimesForDate({
		date,
		times: deliveryPeriods.flatMap((period) => period.times),
	});

	return getDeliveryTimeslotsFromDeliveryTimes({
		date,
		times: deliveryTimes,
		timezone,
	});
};

export const getDeliveryTimeslotsFromDeliveryTimes = (args: {
	date: ISOString;
	times: DeliveryTime[];
	timezone: string;
}): DeliveryTimeSlot[] => {
	const { date, times, timezone } = args;
	return times.flatMap((time) => {
		return time.slots.map((slot) =>
			deliveryTimeSlotTimesToDates({ deliveryTimeSlotTimes: slot, date, timezone }),
		);
	});
};

export const filterDeliveryTimesForDate = (args: {
	date: ISOString;
	times: DeliveryTime[];
}): DeliveryTime[] => {
	return args.times.filter((t) => isDeliveryTimeValidForDate({ date: args.date, time: t }));
};

export const filterDeliveryPeriodsForDate = (args: {
	date: ISOString;
	periods: DeliveryPeriod[];
}): DeliveryPeriod[] => {
	return args.periods.filter((p) => isDeliveryPeriodValidForDate({ date: args.date, period: p }));
};

export const isDeliveryTimeValidForDate = (args: {
	date: ISOString;
	time: DeliveryTime;
}): boolean => {
	const { date, time } = args;

	const isoWeekDay = moment(date).isoWeekday();
	const isWeekend = isoWeekDay === 6 || isoWeekDay === 7;

	return (
		time.dayType === 'allDays' ||
		(time.dayType === 'weekends' && isWeekend) ||
		(time.dayType === 'weekDays' && !isWeekend) ||
		time.dayType === moment(date).locale('en').format('dddd').toLowerCase()
	);
};

export const isDeliveryPeriodValidForDate = (args: {
	date: ISOString;
	period: DeliveryPeriod;
}): boolean => {
	const { date, period } = args;
	const periodStart = moment.utc(period.startDate, 'YYYY-MM-DD').startOf('day');
	const periodEnd = moment.utc(period.endDate, 'YYYY-MM-DD').endOf('day');

	const isWithinOpenRange = period.endDate === null && moment(date).isSameOrAfter(periodStart);
	const isWithinRange = moment.utc(date).isBetween(periodStart, periodEnd, undefined, '[]');
	return isWithinOpenRange || isWithinRange;
};

export const getDefaultHandlingTimes = (): DeliveryOptionHandlingTimes => ({
	preparationTime: EMPTY_FIXED_BUFFER_TIME,
	handlingTimeBefore: EMPTY_FIXED_BUFFER_TIME,
	handlingTimeAfter: EMPTY_FIXED_BUFFER_TIME,
});

export const getDefaultOpeningHoursBehaviour = (): DeliveryOpeningHoursBehaviour => ({
	allowStartOnClosedDays: false,
	allowEndOnClosedDays: false,
});
