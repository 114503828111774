import { User, signInWithCustomToken } from 'common/frontend/firebase/auth';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { isDevEnv, isLocalEnv } from 'common/utils/common';

const baseUrl = (() => {
	return isLocalEnv()
		? 'http://localhost:5000/auth'
		: isDevEnv()
		? 'http://auth.dev.rentle.shop/auth'
		: 'http://auth.rentle.shop/auth';
})();

const getCustomAuthToken = async () => {
	const authStatus = await fetch(`${baseUrl}/checkStatus`, {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
		method: 'POST',
		credentials: 'include',
	});
	const statusResponse = await authStatus.json();
	const { customToken } = statusResponse;
	return customToken;
};

export const signInWithAuthToken = async () => {
	const customToken = await getCustomAuthToken();
	await signInWithCustomToken(customToken);
};

export const signInServer = async (user: User | null) => {
	if (!user) return;
	try {
		const idToken = await user!.getIdTokenResult();
		await fetch(`${baseUrl}/signIn`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
			body: JSON.stringify({ idToken }),
			method: 'POST',
			credentials: 'include',
		});
	} catch (e) {
		if (isLocalEnv()) {
			console.warn('Unable to sign in server');
			return;
		}
		errorHandler.report(e);
	}
};

export const signOutServer = async () => {
	try {
		await fetch(`${baseUrl}/signOut`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
			method: 'POST',
			credentials: 'include',
		});
	} catch (e) {
		errorHandler.report(e);
	}
};
