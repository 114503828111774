import { useSelector } from 'react-redux';

import { queries } from 'common/frontend/queries';
import {
	getCurrentArticlesCount,
	getCurrentLanguageCount,
	getCurrentPickupLocationCount,
	getCurrentStoreCount,
	getCurrentUserCount,
	getPurchasedPlanArticlesCount,
	getPurchasedPlanLanguageCount,
	getPurchasedPlanPickupLocationCount,
	getPurchasedPlanStoreCount,
	getPurchasedPlanUserCount,
	getRemainingArticlesCount,
	getRemainingBlockSlots,
	getRemainingLanguageCount,
	getRemainingPickupLocationCount,
	getRemainingStoreCount,
	getRemainingUserCount,
	getTotalBlockSlots,
	getUsedBlockSlots,
	hasRemainingArticlesCount,
	hasRemainingBlockSlots,
	hasRemainingLanguageCount,
	hasRemainingPickupLocationCount,
	hasRemainingStoreCount,
	hasRemainingUserCount,
} from 'common/modules/plans';
import { getShopLocations } from 'common/utils/shopUtils';
import * as ShopSelectors from 'selectors/ShopSelectors';

export const usePlanRestrictions = (
	type: 'users' | 'languages' | 'stores' | 'articles' | 'blocks' | 'pickupLocations',
): {
	remainingCount: number;
	currentCount: number;
	maxCount: number;
	hasRemainingCount: boolean;
} => {
	const purchasedPlan = useSelector(ShopSelectors.shopPurchasedPlan);
	const users = useSelector(ShopSelectors.usersData);
	const shopId = useSelector(ShopSelectors.activeShopId)!;
	const shopPublicInfo = useSelector(ShopSelectors.activeShopPublicInfo);
	const languages = useSelector(ShopSelectors.activeShopLanguages);
	const shopFeatures = useSelector(ShopSelectors.shopFeatures);

	const locations = getShopLocations(shopPublicInfo);
	const deliveryLocations =
		queries.deliveryLocations.listByMerchant.useQuery({
			merchantId: shopId,
		}).data ?? [];

	const skuItems = queries.skuItems.listByShop.useQuery({ shopId }).data;

	switch (type) {
		case 'users':
			return {
				remainingCount: getRemainingUserCount({ purchasedPlan, users, shopId }),
				currentCount: getCurrentUserCount(users, shopId),
				maxCount: getPurchasedPlanUserCount(purchasedPlan),
				hasRemainingCount: hasRemainingUserCount({ purchasedPlan, users, shopId }),
			};
		case 'languages':
			return {
				remainingCount: getRemainingLanguageCount({ purchasedPlan, languages }),
				currentCount: getCurrentLanguageCount(languages),
				maxCount: getPurchasedPlanLanguageCount(purchasedPlan),
				hasRemainingCount: hasRemainingLanguageCount({ purchasedPlan, languages }),
			};
		case 'stores':
			return {
				remainingCount: getRemainingStoreCount({ purchasedPlan, locations }),
				currentCount: getCurrentStoreCount(locations),
				maxCount: getPurchasedPlanStoreCount(purchasedPlan),
				hasRemainingCount: hasRemainingStoreCount({ purchasedPlan, locations }),
			};
		case 'articles':
			return {
				remainingCount: getRemainingArticlesCount({ purchasedPlan, skuItems }),
				currentCount: getCurrentArticlesCount(skuItems),
				maxCount: getPurchasedPlanArticlesCount(purchasedPlan),
				hasRemainingCount: hasRemainingArticlesCount({ purchasedPlan, skuItems }),
			};
		case 'blocks': {
			return {
				remainingCount: getRemainingBlockSlots(shopFeatures),
				currentCount: getUsedBlockSlots(shopFeatures),
				maxCount: getTotalBlockSlots(shopFeatures),
				hasRemainingCount: hasRemainingBlockSlots(shopFeatures),
			};
		}
		case 'pickupLocations': {
			return {
				remainingCount: getRemainingPickupLocationCount({ purchasedPlan, deliveryLocations }),
				currentCount: getCurrentPickupLocationCount(deliveryLocations),
				maxCount: getPurchasedPlanPickupLocationCount(purchasedPlan),
				hasRemainingCount: hasRemainingPickupLocationCount({ purchasedPlan, deliveryLocations }),
			};
		}
	}
};
