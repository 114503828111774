import createFastContext from 'common/frontend/context/createFastContext';
import {
	CapabilityOptionName,
	CustomerPersona,
	IndustryCategory,
	RevenueOption,
} from 'common/services/analytics/tractionAnalytics';
import { ByCustomerPersona, ById, ByIndustry, EnumValues, Languages } from 'common/types';
import { newId } from 'common/utils/common';
import { StorageKeys, Storages, getFromStorage } from 'common/utils/frontUtils';
import { stringEnum } from 'common/utils/objects';

const RegistrationStatuses = stringEnum(['initial', 'registering', 'done', 'error']);

type RegistrationStatus = EnumValues<typeof RegistrationStatuses>;

type RegisterContextState = {
	capabilities: ById<CapabilityOptionName[]>;
	capabilitySpecifications: ById<string> | undefined;
	customerPersona: {
		value?: CustomerPersona;
		specifications?: ByCustomerPersona<string>;
	};
	defaultLanguage: Languages;
	email: string;
	industryCategories: {
		values: IndustryCategory[];
		specifications?: ByIndustry<string>;
	};
	registrationStatus: RegistrationStatus;
	revenue?: RevenueOption;
	shopAnalyticsDocId: string | null;
	shopId: string | null;
	startWithTemplate: boolean;
	templateIdsByIndustry: ByIndustry<string[]>;
	websiteUrl?: string;
	shopName: string | undefined;
	placeholderUrlId: string;
	placeholderShopName: string;
	dirty: boolean;
};

const RegisterContext = createFastContext<RegisterContextState>();

const placeholderUrlId = newId(3);

const INITIAL_STATE_REGISTER = {
	capabilities: {},
	capabilitySpecifications: {},
	customerPersona: {},
	defaultLanguage: 'en' as Languages,
	email: '',
	industryCategories: {
		values: [],
		specifications: {},
	},
	registrationStatus: 'initial' as RegistrationStatus,
	revenue: undefined,
	shopAnalyticsDocId: null,
	shopId: null,
	startWithTemplate: true,
	templateIdsByIndustry: {},
	websiteUrl: undefined,
	shopName: undefined,
	placeholderUrlId,
	placeholderShopName: 'My store',
	dirty: false,
};

export const getInitialState = (): RegisterContextState => {
	const sessionShopId = getFromStorage(Storages.SESSION, StorageKeys.REGISTRATION_SHOP_ID);
	const sessionAnalyticsId = getFromStorage(
		Storages.SESSION,
		StorageKeys.REGISTRATION_ANALYTICS_ID,
	);
	return {
		...INITIAL_STATE_REGISTER,
		...(!!sessionShopId && { shopId: sessionShopId }),
		...(!!sessionAnalyticsId && { shopAnalyticsDocId: sessionAnalyticsId }),
		...(!!sessionShopId && !!sessionAnalyticsId && { registrationStatus: 'done' }),
	};
};

export const RegisterContextProvider = RegisterContext.Provider;
export const useRegisterContext = RegisterContext.useStore;
