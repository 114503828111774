import { Api } from 'common/db/api/paths';
import {
	OrderProductSkuInfo,
	getOrderProductWithSetProducts,
	isSkuInfoBulk,
} from 'common/modules/orders/products';
import { OrderProduct, SetProduct } from 'common/types';

import { InventoryItemApi } from '../InventoryItemApi';
import { ItemState } from '../types';

export const updateActiveOrdersInfoFromMainProduct = async (
	api: Api,
	{
		product,
		state,
	}: {
		product: OrderProduct;
		state: ItemState;
	},
) => {
	const { shopId, rentalId: orderId, startLocationId: locationId } = product;
	const productWithSetProducts = getOrderProductWithSetProducts(product);
	await Promise.all(
		productWithSetProducts.map((p) =>
			updateActiveOrdersInfoFromProduct(api, { product: p, shopId, state, orderId, locationId }),
		),
	);
};

export const updateActiveOrdersInfoFromProduct = async (
	api: Api,
	{
		product,
		locationId,
		shopId,
		orderId,
		state,
	}: {
		shopId: string;
		locationId: string;
		orderId: string;
		product: OrderProduct | SetProduct;
		state: ItemState;
	},
) => {
	const stock = product.stock;
	if (stock == null) {
		return;
	}
	await Promise.all(
		Object.entries(stock).map(([skuId, skuInfo]) =>
			updateItemActiveOrders(api, {
				shopId,
				orderId,
				locationId,
				skuId,
				skuInfo,
				state,
			}),
		),
	);
};

const updateItemActiveOrders = async (
	api: Api,
	args: {
		shopId: string;
		locationId: string;
		orderId: string;
		skuId: string;
		skuInfo: OrderProductSkuInfo;
		state: ItemState;
	},
) => {
	const { shopId, skuId, skuInfo, state, orderId, locationId } = args;
	if (isSkuInfoBulk(skuInfo)) {
		const inventoryItem = await InventoryItemApi.get.bulkItemBySkuId(api, {
			shopId,
			locationId,
			skuId,
		});
		if (!inventoryItem) return;
		return InventoryItemApi.set.updateItemActiveOrders(api, {
			itemId: inventoryItem.id,
			orderId,
			state,
		});
	} else {
		const inventoryItems = skuInfo.items;
		await Promise.all(
			inventoryItems.map(async (item) => {
				const code = item.code;
				if (!code) return;
				return updateActiveOrdersByItemCode(api, { shopId, orderId, code, state });
			}),
		);
	}
};

export const updateActiveOrdersByItemCode = async (
	api: Api,
	args: {
		shopId: string;
		orderId: string;
		code: string;
		state: ItemState;
	},
) => {
	const { shopId, state, orderId, code } = args;
	const inventoryItem = await InventoryItemApi.get.singleItemByCode(api, {
		shopId,
		code,
	});
	if (!inventoryItem) return;
	return InventoryItemApi.set.updateItemActiveOrders(api, {
		itemId: inventoryItem.id,
		orderId,
		state,
	});
};
