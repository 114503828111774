import { createSelector } from '@reduxjs/toolkit';
import { chain, isEmpty } from 'lodash';

import { Translatable, TranslatableField } from 'common/modules/translations';
import { ById } from 'common/types';
import { hashByUniqueField } from 'common/utils/arrays';
import { SaveTranslationPayload } from 'reducers/TranslationsReducer';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as StockSelectors from 'selectors/StockSelectors';
import { t } from 'services/localization';
import { ReduxState } from 'services/types';

import { customContentTranslations } from './utils/customContentTranslations';

export const activeLanguage = (state: ReduxState) => state.translations.language ?? 'en';
export const activeLanguage_RAW = (state: ReduxState) => state.translations.language;
export const activeDefaultLanguage = (state: ReduxState) =>
	state.translations.defaultLanguage ?? 'en';
export const translatableLanguages = createSelector(
	ShopSelectors.activeShopLanguages,
	ShopSelectors.activeShopDefaultLanguage,
	(languages, defaultLanguage) => languages.filter((lang) => lang !== defaultLanguage),
);

export const translations = (state: ReduxState) => state.translations.translations;

export const saveQueue = (state: ReduxState) => state.translations.saveQueue;

export const savingFields = createSelector(saveQueue, (saveQueue) => {
	return Object.values(saveQueue).reduce((result, item) => {
		if (item.status === 'saving') result.push(item.payload);
		return result;
	}, [] as SaveTranslationPayload[]);
});

export const errorFields = createSelector(saveQueue, (saveQueue) => {
	return Object.values(saveQueue).reduce((result, item) => {
		if (item.status === 'error') result.push(item.payload);
		return result;
	}, [] as SaveTranslationPayload[]);
});

export const isSaving = createSelector(savingFields, (saving) => saving.length > 0);

export const isError = createSelector(errorFields, (errors) => errors.length > 0);

export const productTranslatables = createSelector(
	StockSelectors.stockProductsData,
	(products): Translatable[] => {
		return products
			.map((product) => {
				const fields: ById<TranslatableField> = {
					name: {
						id: 'name',
						label: t('common:form.name', 'Name'),
						value: product.name,
						opts: {
							type: 'productName',
							args: {
								productId: product.id,
							},
						},
					},
				};

				if (!!product.description?.def) {
					const id = 'description';
					fields[id] = {
						id,
						label: t('common:form.description', 'Description'),
						value: product.description,
						textArea: true,
						opts: {
							type: 'productDescription',
							args: {
								productId: product.id,
							},
						},
					};
				}

				product.variants.properties.forEach((property) => {
					const id = `variantProperties.${property.id}`;
					if (!!property) {
						fields[id] = {
							id,
							label:
								t('translations.fields.variantProperty', 'Variant property') +
								`: ${property.name.def}`,
							value: property.name,
							opts: {
								type: 'productVariantProperty',
								args: {
									productId: product.id,
									propertyId: property.id,
								},
							},
						};

						const propertyValues = chain(product.variants.options)
							.flatMap((option) =>
								Object.entries(option.values).map(([propertyId, value]) => ({ propertyId, value })),
							)
							.filter((item) => item.propertyId === property.id && !!item.value.def)
							.uniqBy((item) => item.value.def)
							.value();

						propertyValues.forEach(({ propertyId, value }) => {
							const id = `variantProperties.${property.id}.value.${value.def}`;
							fields[id] = {
								id,
								label: `${property.name.def} - ${value.def}`,
								value,
								opts: {
									type: 'productVariantValue',
									args: {
										productId: product.id,
										propertyId,
										originalValue: value.def,
									},
								},
							};
						});
					}
				});

				product.pricing?.forEach((pricingItem, index) => {
					if (!!pricingItem.label?.def) {
						const id = `pricing.${index}`;
						fields[id] = {
							id,
							label: t('translations.fields.customPricingLabel', 'Custom pricing label'),
							value: pricingItem.label,
							opts: {
								type: 'productPricingLabel',
								args: {
									productId: product.id,
									index,
								},
							},
						};
					}
				});

				product.segmentPricings?.forEach((segmentPricing, segmentPricingIndex) => {
					segmentPricing.pricingTable.forEach((pricingItem, index) => {
						if (!!pricingItem.label?.def) {
							const id = `segmentPricings.${segmentPricingIndex}.pricingTable.${index}`;
							fields[id] = {
								id,
								label: t('translations.fields.customPricingLabel', 'Custom pricing label'),
								value: pricingItem.label,
								opts: {
									type: 'productSegmentPricingLabel',
									args: {
										productId: product.id,
										segmentPricingIndex,
										index,
									},
								},
							};
						}
					});
				});

				return {
					id: product.id,
					label: product.name.def,
					fields,
				};
			})
			.filter((i) => !isEmpty(i.fields));
	},
);

export const categoryTranslatables = createSelector(
	StockSelectors.categoriesData,
	(categories): Translatable[] => {
		return categories
			.map((category) => {
				const fields: ById<TranslatableField> = {
					name: {
						id: 'name',
						label: t('common:form.name', 'Name'),
						value: category.name,
						opts: {
							type: 'categoryName',
							args: {
								categoryId: category.id,
							},
						},
					},
				};

				if (!!category.description?.def) {
					const id = 'description';
					fields[id] = {
						id,
						label: t('common:form.description', 'Description'),
						value: category.description,
						textArea: true,
						opts: {
							type: 'categoryDescription',
							args: {
								categoryId: category.id,
							},
						},
					};
				}

				return {
					id: category.id,
					label: category.name.def,
					fields,
				};
			})
			.filter((i) => !isEmpty(i.fields));
	},
);

export const storeCustomizationTranslatables = createSelector(
	ShopSelectors.customThemesData,
	(themes): Translatable[] => {
		return (themes ?? [])
			.map((theme) => {
				const fields: ById<TranslatableField> = {};

				if (!!theme) {
					const themeId = theme.id;
					const footerSettings = theme.live.footer;
					const featuredImageSettings = theme.live.featuredImage;
					const aboutSettings = theme.live.about;
					const checkoutSettings = theme.live.checkout;
					const aboutPageCustomContent = aboutSettings?.customContent;
					const checkoutPageCustomContent = checkoutSettings?.customContent;

					if (!!featuredImageSettings) {
						if (!!featuredImageSettings.heading?.def) {
							const id = 'featureImage.heading';
							fields[id] = {
								id,
								label: t('translations.fields.featuredImage.heading', 'Featured image, heading'),
								value: featuredImageSettings.heading,
								opts: {
									type: 'featuredImageHeading',
									args: {
										themeId,
									},
								},
							};
						}

						if (!!featuredImageSettings.description?.def) {
							const id = 'featuredImage.description';
							fields[id] = {
								id,
								label: t(
									'translations.fields.featuredImage.description',
									'Featured image, description',
								),
								value: featuredImageSettings.description,
								opts: {
									type: 'featuredImageDescription',
									args: {
										themeId,
									},
								},
							};
						}

						if (!!featuredImageSettings.button?.label?.def) {
							const id = 'featuredImage.button.label';
							fields[id] = {
								id,
								label: t(
									'translations.fields.featuredImage.buttonLabel',
									'Featured image, button label',
								),
								value: featuredImageSettings.button.label,
								opts: {
									type: 'featuredImageButtonLabel',
									args: {
										themeId,
									},
								},
							};
						}
					}

					if (!!footerSettings) {
						if (!!footerSettings.about?.heading?.def) {
							const id = 'footerSettings.about.heading';
							fields[id] = {
								id,
								label: t(
									'translations.fields.footer.aboutHeading',
									'Footer About section, heading',
								),
								value: footerSettings.about.heading,
								opts: {
									type: 'footerAboutHeading',
									args: {
										themeId,
									},
								},
							};
						}

						if (!!footerSettings.about?.description?.def) {
							const id = 'footerSettings.about.description';
							fields[id] = {
								id,
								label: t(
									'translations.fields.footer.aboutDescription',
									'Footer About section, description',
								),
								value: footerSettings.about.description,
								opts: {
									type: 'footerAboutDescription',
									args: {
										themeId,
									},
								},
							};
						}

						if (!!footerSettings.menu?.heading?.def) {
							const id = 'footerSettings.menu.heading';
							fields[id] = {
								id,
								label: t('translations.fields.footer.menuHeadings', 'Footer Menu section, heading'),
								value: footerSettings.menu.heading,
								opts: {
									type: 'footerMenuHeading',
									args: {
										themeId,
									},
								},
							};
						}

						if (!!footerSettings.links?.heading?.def) {
							const id = 'footerSettings.links.heading';
							fields[id] = {
								id,
								label: t(
									'translations.fields.footer.linksHeading',
									'Footer Links section, heading',
								),
								value: footerSettings.links.heading,
								opts: {
									type: 'footerLinksHeading',
									args: {
										themeId,
									},
								},
							};
						}

						if (!!footerSettings.links?.linkObjects) {
							const linkObjects = footerSettings.links?.linkObjects;

							linkObjects.forEach((link, index) => {
								if (!!link.label?.def) {
									const id = `footerSettings.link.${index}.label`;
									fields[id] = {
										id,
										label: t('translations.fields.footer.linkLabel', {
											index: index + 1,
											defaultValue: 'Link label {{index}}',
										}),
										value: link.label,
										opts: {
											type: 'footerLinkLabel',
											args: {
												themeId,
												index,
											},
										},
									};
								}
							});
						}
					}

					if (!!aboutSettings) {
						if (!!aboutSettings.heading?.def) {
							const id = 'aboutSettings.heading';
							fields[id] = {
								id,
								label: t('translations.fields.about.heading', 'About page custom heading'),
								value: aboutSettings.heading,
								opts: {
									type: 'aboutHeading',
									args: {
										themeId,
									},
								},
							};
						}
						if (!!aboutSettings.textContent?.def) {
							const id = 'aboutSettings.textContent';
							fields[id] = {
								id,
								label: t('translations.fields.about.textContent', 'About page custom content'),
								value: aboutSettings.textContent,
								textArea: true,
								opts: {
									type: 'aboutTextContent',
									args: {
										themeId,
									},
								},
							};
						}
					}

					if (!!aboutPageCustomContent?.sections) {
						const sectionsContent = customContentTranslations(
							aboutPageCustomContent.sections,
							themeId,
							'about',
							t,
						);
						sectionsContent.forEach((content) => {
							fields[content.id] = content;
						});
					}

					if (!!checkoutPageCustomContent?.sections) {
						const sectionsContent = customContentTranslations(
							checkoutPageCustomContent.sections,
							themeId,
							'checkout',
							t,
						);
						sectionsContent.forEach((content) => {
							fields[content.id] = content;
						});
					}
				}

				return {
					id: theme.id,
					label: theme.name,
					fields,
				};
			})
			.filter((i) => !isEmpty(i.fields));
	},
);

export const storeTranslatables = createSelector(
	ShopSelectors.activeShopAllLocations,
	ShopSelectors.activeShopPublicInfo,
	ShopSelectors.activeShopCarriers,
	ShopSelectors.shopDeliveryOptions,
	(stores, shopPublicInfo, carriers, deliveryOptions): Translatable[] => {
		if (!shopPublicInfo) return [];
		const visitingAddressId = shopPublicInfo?.visitingAddress?.id;
		return stores
			.map((store) => {
				const fields: ById<TranslatableField> = {};

				if (!!store.description?.def) {
					const id = 'description';
					fields[id] = {
						id,
						label: t('common:form.description', 'Description'),
						value: store.description,
						textArea: true,
						opts: {
							type: 'storeDescription',
							args: {
								storeId: store.id,
								shopId: shopPublicInfo.shopId,
							},
						},
					};
				}

				if (!!store.additionalInformation?.def) {
					const id = 'additionalInformation';
					fields[id] = {
						id,
						label: t('common:form.additionalInformation', 'Additional information'),
						value: store.additionalInformation,
						textArea: true,
						opts: {
							type: 'storeAdditionalInformation',
							args: {
								storeId: store.id,
								shopId: shopPublicInfo.shopId,
							},
						},
					};
				}

				if (!!shopPublicInfo?.checkoutCommentField?.[store.id]?.label?.def) {
					const id = 'checkoutCommentField';
					fields[id] = {
						id,
						label: t(
							'translations.fields.checkoutCommentField',
							'Online store, checkout comment field',
						),
						value: shopPublicInfo.checkoutCommentField[store.id].label,
						opts: {
							type: 'storeCheckoutCommentField',
							args: {
								storeId: store.id,
								shopId: shopPublicInfo.shopId,
							},
						},
					};
				}

				if (!!shopPublicInfo?.inStoreEndText) {
					const endTextRows =
						store.id === visitingAddressId
							? shopPublicInfo.inStoreEndText.defaultValue
							: shopPublicInfo.inStoreEndText.location?.[store.id];

					if (!!endTextRows?.[0]?.text?.def) {
						const id = 'inStoreEndTextTitle';
						fields[id] = {
							id,
							label: t('translations.fields.inStoreEndTextTitle', 'Check-in, end text title'),
							value: endTextRows[0].text,
							opts: {
								type: 'storeInStoreEndText',
								args: {
									shopId: shopPublicInfo.shopId,
									storeId: store.id,
									index: 0,
								},
							},
						};
					}
					if (!!endTextRows?.[1]?.text?.def) {
						const id = 'inStoreEndTextDescription';
						fields[id] = {
							id,
							label: t(
								'translations.fields.inStoreEndTextDescription',
								'Check-in, end text description',
							),
							value: endTextRows[1].text,
							opts: {
								type: 'storeInStoreEndText',
								args: {
									shopId: shopPublicInfo.shopId,
									storeId: store.id,
									index: 1,
								},
							},
						};
					}
				}

				if (!!deliveryOptions) {
					const filteredOptions = deliveryOptions.filter((option) =>
						option.locationIds.includes(store.id),
					);

					filteredOptions.forEach((option, index) => {
						if (!!option.name.def) {
							const id = `deliveryOption.${option.id}.name`;
							fields[id] = {
								id,
								label: t('translations.fields.deliveryOptionsName', {
									index: index + 1,
									defaultValue: 'Delivery option {{index}}, name',
								}),
								value: option.name,
								opts: {
									type: 'deliveryOptionName',
									args: {
										deliveryOptionId: option.id,
										storeId: store.id,
									},
								},
							};
						}

						if (!!option.description.def) {
							const id = `deliveryOption.${option.id}.description`;
							fields[id] = {
								id,
								label: t('translations.fields.deliveryOptionsDescription', {
									index: index + 1,
									defaultValue: 'Delivery option {{index}}, description',
								}),
								value: option.description,
								opts: {
									type: 'deliveryOptionDescription',
									args: {
										deliveryOptionId: option.id,
										storeId: store.id,
									},
								},
							};
						}

						if (!!option.returnInstructions?.def) {
							const id = `deliveryOption.${option.id}.returnInstructions`;
							fields[id] = {
								id,
								label: t('translations.fields.deliveryOptionReturnInstructions', {
									index: index + 1,
									defaultValue: 'Delivery option {{index}}, return instructions',
								}),
								value: option.returnInstructions,
								opts: {
									type: 'deliveryOptionReturnInstructions',
									args: {
										deliveryOptionId: option.id,
										storeId: store.id,
									},
								},
							};
						}
					});
				}

				if (!!carriers) {
					const filteredCarriers = carriers.filter((carrier) =>
						carrier.locationIds.includes(store.id),
					);
					filteredCarriers.forEach((carrier, index) => {
						if (!!carrier.name) {
							const id = `deliveryCarrier.${index}.name`;
							fields[id] = {
								id,
								label: t('translations.fields.deliveryCarrier', {
									index: index + 1,
									defaultValue: 'Delivery carrier {{index}}',
								}),
								value: carrier.name,
								opts: {
									type: 'deliveryCarrierName',
									args: {
										carrierId: carrier.id,
										storeId: store.id,
									},
								},
							};
						}
					});
				}

				return {
					id: store.id,
					label: store.name,
					fields,
				};
			})
			.filter((i) => !isEmpty(i.fields));
	},
);

export const pricingTableTranslatables = createSelector(
	ShopSelectors.activeShopPublicInfo,
	(shopPublicInfo): Translatable[] => {
		const shopId = shopPublicInfo?.shopId;
		const pricingTables = shopPublicInfo?.pricingTables;
		if (!pricingTables || isEmpty(pricingTables) || !shopId) return [];

		const pricingTableNames = Object.keys(pricingTables);

		return pricingTableNames
			.map((name) => {
				const fields = {};

				pricingTables[name].forEach((pricingItem, index) => {
					if (!!pricingItem.label?.def) {
						const id = `${name}.${index}`;
						fields[id] = {
							id,
							label: t('translations.fields.customLabel', 'Custom label'),
							value: pricingItem.label,
							opts: {
								type: 'pricingTableLabel',
								args: {
									shopId,
									pricingTableName: name,
									index,
								},
							},
						};
					}
				});

				return {
					id: name,
					label: name,
					fields,
				};
			})
			.filter((i) => !isEmpty(i.fields));
	},
);

export const miscellaneousTranslatables = createSelector(
	ShopSelectors.activeShopPublicInfo,
	(shopPublicInfo) => {
		const fields: ById<TranslatableField> = {};

		if (!!shopPublicInfo?.marketingConsentText?.text?.def) {
			const id = 'marketingConsentText';
			fields[id] = {
				id,
				label: t('shop.consentTextLabel', 'Consent text'),
				value: shopPublicInfo.marketingConsentText.text,
				opts: {
					type: 'marketingConsentText',
					args: {
						shopId: shopPublicInfo.shopId,
					},
				},
			};
		}

		return [
			{
				id: 'marketing',
				label: t('account.marketing.pageTitle', 'Marketing'),
				fields,
			},
		].filter((i) => !isEmpty(i.fields));
	},
);

export const skidataTranslatables = createSelector(
	StockSelectors.liftTicketSegments,
	StockSelectors.categoriesData,
	StockSelectors.liftTicketCategory,
	(segments, categories, ticketCategory): Translatable[] => {
		const fields: ById<TranslatableField> = {};
		if (!ticketCategory) return [];

		if (!!segments) {
			segments.forEach((segment, index) => {
				const categoryId = categories.find((category) =>
					category.segmentMapping?.includes(segment),
				);
				const id = `segmentMapping.${index}.name`;
				fields[id] = {
					id,
					label: t('translations.fields.label', {
						name: segment.externalSegmentName,
						defaultValue: 'Label, {{name}}',
					}),
					value: segment.name ?? { def: segment.externalSegmentName },
					opts: {
						type: 'skidataDiscountGroup',
						args: {
							segmentId: segment.externalSegmentId,
							categoryId: categoryId?.id ?? '',
						},
					},
				};
			});
		}
		return [
			{
				id: ticketCategory.id,
				label: t('translations.fields.discountGroups'),
				fields,
			},
		].filter((i) => !isEmpty(i.fields));
	},
);

export const manualPaymentsTranslatables = createSelector(
	ShopSelectors.activeShopInfo,
	(shopInfo): Translatable[] => {
		const manualPaymentMethods = shopInfo?.manualPaymentMethods;
		if (!manualPaymentMethods || !shopInfo) return [];

		return manualPaymentMethods
			.map((method) => {
				const fields: ById<TranslatableField> = {};
				if (!!method.label.def) {
					const id = `manualPayments.${method.id}.label`;
					fields[id] = {
						id,
						label: t('translations.fields.label', {
							name: method.label.def,
							defaultValue: 'Label, {{name}}',
						}),
						value: method.label,
						opts: {
							type: 'manualPaymentMethodLabel',
							args: {
								shopId: shopInfo.id,
								methodId: method.id,
							},
						},
					};
				}

				if (method.description?.def) {
					const id = `manualPayments.${method.id}.description`;

					fields[id] = {
						id,
						label: t('translations.fields.description', {
							name: method.description.def,
							defaultValue: 'Description, {{name}}',
						}),
						value: method.description,
						opts: {
							type: 'manualPaymentMethodDescription',
							args: {
								shopId: shopInfo.id,
								methodId: method.id,
							},
						},
					};
				}
				return {
					id: method.id,
					label: method.label.def,
					fields,
				};
			})
			.filter((i) => !isEmpty(i.fields));
	},
);

export const allTranslatablesById = createSelector(
	productTranslatables,
	categoryTranslatables,
	pricingTableTranslatables,
	storeTranslatables,
	miscellaneousTranslatables,
	skidataTranslatables,
	manualPaymentsTranslatables,
	storeCustomizationTranslatables,
	(
		products,
		categories,
		pricingTables,
		stores,
		miscellaneous,
		skidata,
		manualPaymentMethods,
		customizations,
	) => {
		return {
			products: hashByUniqueField(products, 'id'),
			categories: hashByUniqueField(categories, 'id'),
			stores: hashByUniqueField(stores, 'id'),
			pricingTables: hashByUniqueField(pricingTables, 'id'),
			miscellaneous: hashByUniqueField(miscellaneous, 'id'),
			skidata: hashByUniqueField(skidata, 'id'),
			manualPaymentMethods: hashByUniqueField(manualPaymentMethods, 'id'),
			customizations: hashByUniqueField(customizations, 'id'),
		};
	},
);

export const someTranslatables = createSelector(
	productTranslatables,
	categoryTranslatables,
	pricingTableTranslatables,
	storeTranslatables,
	miscellaneousTranslatables,
	skidataTranslatables,
	manualPaymentsTranslatables,
	storeCustomizationTranslatables,
	(
		products,
		categories,
		pricingTables,
		stores,
		miscellaneous,
		skidata,
		manualPaymentMethods,
		customizations,
	) => {
		return (
			products.length > 0 ||
			categories.length > 0 ||
			pricingTables.length > 0 ||
			stores.length > 0 ||
			miscellaneous.length > 0 ||
			skidata.length > 0 ||
			manualPaymentMethods.length > 0 ||
			customizations.length > 0
		);
	},
);
