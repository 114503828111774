import { api } from 'common/frontend/api';
import { BatchManager } from 'common/frontend/firebase/firestore';
import { ProductApi } from 'common/types';
import { chunks } from 'common/utils/arrays';

export let removeProductsListener: () => void = () => undefined;
export let removeProductListener: () => void = () => undefined;

export const addProductsListener = (
	shopId: string,
	callback: (products: ProductApi[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const productsRef = api().products.get.where('shopId', '==', shopId).orderBy('orderIndex', 'asc');
	removeProductsListener = productsRef.onSnapshot((products) => {
		callback(products);
	}, errorCallback || undefined);
};

export const addProductListener = (
	productId: string,
	callback: (product: ProductApi) => void,
	errorCallback?: (error: Error) => void,
) => {
	const productsRef = api().products.doc(productId);
	const removeListener = productsRef.listen((product) => {
		if (!!product) {
			callback(product);
		} else {
			if (errorCallback) {
				errorCallback(new Error('No product found'));
			}
		}
	}, errorCallback || undefined);
	removeProductListener = () => removeListener();
	return removeListener;
};

export const updateProductBatch = (products: ProductApi[]) => {
	const batchManager = new BatchManager();
	for (const product of products) {
		api(batchManager.batch())
			.products.doc(product.id)
			.update({ ...product });
	}
	return batchManager.commit();
};

export const getProductsByIds = async (productIds: string[]): Promise<ProductApi[]> => {
	if (productIds.length === 0) return [];

	const chunkedIds = chunks(productIds, 9);

	const queries = await Promise.all(
		chunkedIds.map((ids) => {
			return api().products.get.whereIn('id', 'in', ids).get();
		}),
	);

	return queries.flat();
};

export const getProductById = async (productId: string): Promise<ProductApi | undefined> => {
	return api().products.doc(productId).get();
};

export const removeActiveProductListeners = () => {
	removeProductListener();
};
