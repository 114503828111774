import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.productVariants.name', 'Product Variants'),
	subHeader: t(
		'common:blocks.details.productVariants.subHeader',
		'Expand your offering with product variants',
	),
	description: t(
		'common:blocks.details.productVariants.description',
		'Add variants to your products that come in more than one option, like different sizes or colors. If some variants have a different value than others, you can set price increases to them.  Similarly to product availability, also variant level availability is easy to track by linking them to your inventory',
	),
	supportArticle: SupportArticles.PRODUCT_VARIANTS,
	uninstallNotice: t(
		'common:blocks.details.productVariants.uninstallNotice',
		'If you uninstall Product variants, all of your existing product variants will be deleted',
	),
});

export default details;
