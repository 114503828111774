import { StoreRentalsAction } from 'actions';

import { StoreRentalsState } from 'services/types';

const INITIAL_STATE: StoreRentalsState = {
	activeBookedStoreRentals: { kind: 'LOADING' },
	expiredBookedStoreRentals: { kind: 'LOADING' },
	activeStoreRentals: { kind: 'LOADING' },
	pendingStoreRentals: { kind: 'LOADING' },
	completedStoreRentals: { kind: 'LOADING' },
	allOutgoingProducts: { kind: 'LOADING' },
	expiringIncomingProducts: { kind: 'LOADING' },
	expiredIncomingProducts: { kind: 'LOADING' },
};

const StoreRentalsReducer = (
	state = INITIAL_STATE,
	action: StoreRentalsAction,
): StoreRentalsState => {
	switch (action.type) {
		case 'UPDATE_ACTIVE_BOOKED_STORE_RENTALS':
			return { ...state, activeBookedStoreRentals: action.rentalList };
		case 'UPDATE_PENDING_STORE_RENTALS':
			return { ...state, pendingStoreRentals: action.rentalList };
		case 'UPDATE_EXPIRED_BOOKED_STORE_RENTALS':
			return { ...state, expiredBookedStoreRentals: action.rentalList };
		case 'UPDATE_ACTIVE_STORE_RENTALS':
			return { ...state, activeStoreRentals: action.rentalList };
		case 'UPDATE_COMPLETED_STORE_RENTALS':
			return { ...state, completedStoreRentals: action.rentalList };
		case 'UPDATE_OUTGOING_PRODUCTS':
			return { ...state, allOutgoingProducts: action.products };
		case 'UPDATE_EXPIRING_INCOMING_PRODUCTS':
			return { ...state, expiringIncomingProducts: action.products };
		case 'UPDATE_EXPIRED_INCOMING_PRODUCTS':
			return { ...state, expiredIncomingProducts: action.products };
		case 'ADD_ALL_OUTGOING_LISTENER_STARTED':
			return { ...state, allOutgoingProducts: { kind: 'LOADING' } };
		case 'ADD_EXPIRING_INCOMING_LISTENER_STARTED':
			return { ...state, expiringIncomingProducts: { kind: 'LOADING' } };
		case 'ADD_EXPIRED_INCOMING_LISTENER_STARTED':
			return { ...state, expiredIncomingProducts: { kind: 'LOADING' } };
		default:
			return state;
	}
};

export default StoreRentalsReducer;
