import { z } from 'zod';

import { EnumValues } from 'common/types';
import { stringEnum } from 'common/utils/objects';

import {
	AdyenMethodType,
	AdyenPaymentMethod,
	AutomaticDepositPaymentMethod,
	CardPaymentMethod,
	ManualDepositPaymentMethod,
	NonDepositPaymentMethod,
	NonRecurringPaymentMethod,
	OnlinePaymentProvider,
	RecurringPaymentMethod,
} from './types';

export const AdyenMethods = stringEnum([
	'CARD_ONLINE',
	'TRUSTLY',
	'PAYTRAIL',
	'MOBILEPAY',
	'APPLEPAY',
	'GOOGLEPAY',
]);
export const zAdyenMethods = z.nativeEnum(AdyenMethods);

export const AdyenMethodsWithoutWalletMethods = stringEnum([
	'CARD_ONLINE',
	'TRUSTLY',
	'PAYTRAIL',
	'MOBILEPAY',
]);
export const zAdyenMethodsWithoutWalletMethods = z.nativeEnum(AdyenMethodsWithoutWalletMethods);

export const StripeMethods = stringEnum(['CARD_ONLINE_STRIPE']);
export const zStripeMethods = z.nativeEnum(StripeMethods);

export const ExternalMethods = stringEnum(['EPASSI_ONLINE']);
export const zExternalMethods = z.nativeEnum(ExternalMethods);

export const ManualMethods = stringEnum(['PAY_STORE']);
export const zManualMethods = z.nativeEnum(ManualMethods);

export const WalletMethods = stringEnum(['APPLEPAY', 'GOOGLEPAY']);
export const zWalletMethods = z.nativeEnum(WalletMethods);

export const RecurringMethods = stringEnum(['CARD_ONLINE', 'CARD_ONLINE_STRIPE']);
export const zRecurringMethods = z.nativeEnum(RecurringMethods);

export const AutomaticDepositMethods = stringEnum(['CARD_ONLINE', 'CARD_ONLINE_STRIPE']);
export const zAutomaticDepositMethods = z.nativeEnum(AutomaticDepositMethods);

export const ManualDepositMethods = stringEnum(['PAY_STORE', 'MANUAL']);
export const zManualDepositMethods = z.nativeEnum(ManualDepositMethods);

export const DepositMethods = { ...AutomaticDepositMethods, ...ManualDepositMethods };

export const CustomManualMethodId = stringEnum(['MANUAL']);
export const zCustomManualMethodId = z.nativeEnum(CustomManualMethodId);

export const OnlineMethods = {
	...AdyenMethods,
	...ExternalMethods,
	...ManualMethods,
	...StripeMethods,
} as const;
export const zOnlineMethod = z.nativeEnum(OnlineMethods);

export const ShopOnlineMethods = {
	...AdyenMethodsWithoutWalletMethods,
	...ExternalMethods,
	...ManualMethods,
	...StripeMethods,
} as const;
export const zShopOnlineMethod = z.nativeEnum(ShopOnlineMethods);

export const OnlineProviderMethods = {
	...AdyenMethods,
	...StripeMethods,
};

export const OnlineMethodsByProvider = {
	ADYEN: AdyenMethods,
	EXTERNAL: ExternalMethods,
	MANUAL: ManualMethods,
	STRIPE: StripeMethods,
} as const;

export const OnlinePaymentProviders = stringEnum(['ADYEN', 'STRIPE']);
export const zOnlinePaymentProviders = z.nativeEnum(OnlinePaymentProviders);

export const AdyenMethodTypes = stringEnum([
	'scheme',
	'trustly',
	'ebanking_FI',
	'mobilepay',
	'applepay',
	'paywithgoogle',
]);
export const zAdyenMethodTypes = z.nativeEnum(AdyenMethodTypes);

export const PAYMENT_METHOD_TO_ADYEN_TYPE_MAP: Record<AdyenPaymentMethod, AdyenMethodType> = {
	CARD_ONLINE: 'scheme',
	TRUSTLY: 'trustly',
	PAYTRAIL: 'ebanking_FI',
	MOBILEPAY: 'mobilepay',
	APPLEPAY: 'applepay',
	GOOGLEPAY: 'paywithgoogle',
} as const;

export const CARD_METHODS_BY_ONLINE_PROVIDER: Record<OnlinePaymentProvider, CardPaymentMethod> = {
	ADYEN: 'CARD_ONLINE',
	STRIPE: 'CARD_ONLINE_STRIPE',
} as const;

interface PaymentMethodLimitation_BASE {
	countries?: string[];
	currencies?: string[];
	recurring: boolean;
	externalRedirect?: boolean;
	disabledInIframe?: boolean;
	rentleDomainOnly?: boolean;
	deposits: false | 'manual' | 'automatic';
	subscriptions: boolean;
}

interface RecurringPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	recurring: true;
}

interface NonRecurringPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	recurring: false;
}

interface ManualDepositPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	deposits: 'manual';
}

interface AutomaticDepositPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	deposits: 'automatic';
}
interface NonDepositPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	deposits: false;
}

export type PaymentMethodLimitationObject =
	| RecurringPaymentMethodLimitation
	| NonRecurringPaymentMethodLimitation
	| ManualDepositPaymentMethodLimitation
	| AutomaticDepositPaymentMethodLimitation
	| NonDepositPaymentMethodLimitation;

export const ONLINE_PAYMENT_METHOD_LIMITATIONS: Record<
	RecurringPaymentMethod,
	RecurringPaymentMethodLimitation
> &
	Record<ManualDepositPaymentMethod, ManualDepositPaymentMethodLimitation> &
	Record<AutomaticDepositPaymentMethod, AutomaticDepositPaymentMethodLimitation> &
	Record<NonRecurringPaymentMethod, NonRecurringPaymentMethodLimitation> &
	Record<NonDepositPaymentMethod, NonDepositPaymentMethodLimitation> = {
	CARD_ONLINE: {
		recurring: true,
		deposits: 'automatic',
		externalRedirect: true,
		subscriptions: true,
	},
	TRUSTLY: {
		recurring: false,
		currencies: ['EUR', 'SEK', 'NOK', 'DKK'],
		countries: ['FI', 'SE', 'NO', 'DK'],
		externalRedirect: true,
		deposits: false,
		subscriptions: false,
	},
	PAYTRAIL: {
		recurring: false,
		currencies: ['EUR'],
		countries: ['FI'],
		externalRedirect: true,
		deposits: false,
		subscriptions: false,
	},
	MOBILEPAY: {
		recurring: false,
		currencies: ['EUR', 'DKK'],
		countries: ['FI', 'DK'],
		deposits: false,
		subscriptions: false,
	},
	APPLEPAY: {
		recurring: false,
		disabledInIframe: true,
		deposits: false,
		subscriptions: false,
	},
	GOOGLEPAY: {
		recurring: false,
		deposits: false,
		rentleDomainOnly: true,
		subscriptions: false,
	},
	EPASSI_ONLINE: {
		recurring: false,
		currencies: ['EUR'],
		countries: ['FI'],
		deposits: false,
		subscriptions: false,
	},
	PAY_STORE: {
		recurring: false,
		deposits: 'manual',
		subscriptions: false,
	},
	CARD_ONLINE_STRIPE: {
		recurring: true,
		deposits: 'automatic',
		subscriptions: true,
	},
	MANUAL: {
		recurring: false,
		deposits: 'manual',
		subscriptions: false,
	},
};

export const PaymentProviders = stringEnum(['adyen', 'stripe']);
export type PaymentProvider = EnumValues<typeof PaymentProviders>;
