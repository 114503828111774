import { Stack } from '@mui/material';
import {
	ArrowSquareUpRight,
	FileQuestion02,
	Flash,
	HelpCircle,
	Settings01,
	SwitchHorizontal01,
} from '@untitled-ui/icons-react';
import { useHasFeature } from 'hooks';
import { RiMenuLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import * as Sleekplan from 'common/services/analytics/sleekplan';
import * as UserSelectors from 'selectors/UserSelectors';
import { useTranslation } from 'services/localization';
import { Routes } from 'routing';

import HeaderButton from '../Header/HeaderButton';
import { HeaderMenuItem } from '../HeaderNEW/HeaderMenuNEW';
import { MODES } from '../constants';
import { useLayoutContext } from '../context';
import AccountMenu from './AccountMenu';
import ModeSelectButton from './ModeSelectButton';

export interface Props {}
const HeaderNEW = (props: Props) => {
	const { setMode } = useLayoutContext();
	const { t } = useTranslation();
	const { hasFeature } = useHasFeature();
	const activeUser = useSelector(UserSelectors.userData);
	const { setSidebarOpen, isMobile } = useLayoutContext();

	const accountMenuProfileItems = ((): HeaderMenuItem[] => {
		let items: HeaderMenuItem[] = [
			{
				type: 'internal-link',
				label: 'Settings',
				icon: Settings01,
				route: Routes.ACCOUNT_PROFILE,
				onClick: () => setMode(MODES.rentals),
			},
		];
		return items;
	})();

	const accountMenuResourcesItems = ((): HeaderMenuItem[] => {
		let items: HeaderMenuItem[] = [
			{
				type: 'button',
				label: t('headerBar.viewUpdates', 'View updates'),
				icon: Flash,
				onClick: () => Sleekplan.open(),
			},
			{
				type: 'external-link',
				label: t('headerBar.helpCenter', 'Help center'),
				icon: FileQuestion02,
				href: 'https://support.rentle.io',
			},
		];

		if (hasFeature('CONTACT_SUPPORT')) {
			items = [
				...items,
				{
					type: 'external-link',
					label: t('headerBar.contactSupport', 'Contact support'),
					icon: HelpCircle,
					href: 'mailto:cgregytq@rentle.intercom-mail.com',
					className: 'intercom_launcher',
				},
			];
		}
		return items;
	})();

	const handleSidebarOpen = () => {
		setSidebarOpen((prev) => !prev);
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="stretch" height="100%">
				<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
					{isMobile ? (
						<HeaderButton
							onClick={handleSidebarOpen}
							label={t('common:actions.openMenu', 'Open menu')}
							collapsed
							icon={RiMenuLine}
							data-qa="header-sidebar-menu-button"
						/>
					) : (
						<Stack direction="row" px={1}>
							<ModeSelectButton
								label={t('modes.rentals', 'Rentals')}
								icon={SwitchHorizontal01}
								onClick={() => setMode(MODES.rentals)}
								to={Routes.HOME_VIEW}
							/>
							<ModeSelectButton
								label={t('modes.resell', 'Resell')}
								icon={ArrowSquareUpRight}
								onClick={() => setMode(MODES.resell)}
								to={Routes.LISTINGS}
							/>
						</Stack>
					)}
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1}>
					<AccountMenu
						label={`${activeUser?.firstName} ${activeUser?.lastName}` ?? 'User'}
						email={activeUser?.email ?? ''}
						profileItems={accountMenuProfileItems}
						resourcesItems={accountMenuResourcesItems}
					/>
				</Stack>
			</Stack>
		</>
	);
};

export default HeaderNEW;
