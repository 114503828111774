import moment from 'moment-timezone';

import { api } from 'common/frontend/api';
import { Plan } from 'common/modules/plans';
import {
	OnboardingEventName,
	newOnboardingActionCompletedEvent,
	newOnboardingClickEvent,
} from 'common/services/analytics';
import {
	OnboardingAction,
	OnboardingItem as OnboardingItemType,
	OnboardingListVersion,
	OnboardingListVersions,
	OnboardingStepDefinition,
	OnboardingStep as OnboardingStepType,
	ShopAnalytics,
	ShopSetups,
} from 'common/services/analytics/tractionAnalytics';
import * as UserflowService from 'common/services/analytics/userflow';
import { notUndefined } from 'common/utils/common';

import { ONBOARDING_ITEMS, onboardingItemDefinitions } from './items';
import { ONBOARDING_STEPS, onboardingStepDefinitions } from './steps';
import {
	OnboardingItem,
	OnboardingStep,
	OnboardingStepItem,
	OnboardingStepItemActionFlow,
	OnboardingStepItemActionLink,
	OnboardingStepItemActionRoute,
	OnboardingStepItemActionVideo,
	OnboardingStepItemTypes,
	OnboardingStepsArgs,
} from './types';

export const RELEASE_DATES = {
	V1: '2023-02-24',
	V2: '2023-06-27',
	V3: '2023-09-05',
};

const completeOnboardingAction = (onboardingAction: string, analyticsDocId: string) => {
	const lowerCaseAction = onboardingAction.toLowerCase() as OnboardingEventName;
	newOnboardingActionCompletedEvent(lowerCaseAction);
	return api()
		.shopAnalytics.doc(analyticsDocId)
		.update({
			[`onboardingActions.${onboardingAction}` as `onboardingActions.${'USERS'}`]: true,
		});
};

export const setOnboardingActionDone = async (
	_action: OnboardingAction[] | OnboardingAction,
	shopAnalytics: ShopAnalytics | undefined,
) => {
	if (!shopAnalytics) {
		return;
	}
	const action = typeof _action === 'string' ? [_action] : _action;
	return await Promise.all(
		action.map(
			(onboardingAction) =>
				!isOnboardingActionDone(onboardingAction, shopAnalytics) &&
				completeOnboardingAction(onboardingAction, shopAnalytics.id),
		),
	);
};

export const isOnboardingActionDone = (
	action: OnboardingAction,
	shopAnalytics: ShopAnalytics | undefined,
): boolean => {
	return shopAnalytics?.onboardingActions?.[action] === true;
};

export const getVideoOnboardingStepItem = (args: {
	url: string;
	completeOnClick?: boolean;
}): OnboardingStepItemActionVideo => {
	const { url, completeOnClick } = args;
	return {
		type: OnboardingStepItemTypes.VIDEO,
		value: {
			url,
			completeOnClick,
		},
	};
};

export const getFlowOnboardingStepItem = (args: {
	onboardingAction: OnboardingAction;
	completeOnClick?: boolean;
}): OnboardingStepItemActionFlow => {
	const { onboardingAction, completeOnClick } = args;
	return {
		type: OnboardingStepItemTypes.FLOW,
		value: {
			flowId: UserflowService.FLOW_IDS[onboardingAction],
			completeOnClick,
		},
	};
};

export const getLinkOnboardingStepItem = (args: {
	toUrl: string | undefined;
	completeOnClick?: boolean;
}): OnboardingStepItemActionLink => {
	const { toUrl, completeOnClick } = args;
	return {
		type: OnboardingStepItemTypes.LINK,
		value: {
			toUrl,
			completeOnClick,
		},
	};
};

export const getRouteOnboardingStepItem = (args: {
	routeTo: string;
	routeHash?: string;
	completeOnClick?: boolean;
}): OnboardingStepItemActionRoute => {
	const { routeTo, routeHash, completeOnClick } = args;
	return {
		type: OnboardingStepItemTypes.ROUTE,
		value: {
			routeTo,
			routeHash,
			completeOnClick,
		},
	};
};

export const getOnboardingItemFromOnboardingStepItem = (
	item: OnboardingStepItem,
	shopAnalytics: ShopAnalytics | undefined,
): OnboardingItem => {
	const { content, action, id } = item;
	switch (action.type) {
		case 'ROUTE':
			return {
				id,
				action,
				content,
				routeTo: action.value.routeTo,
				routeHash: action.value.routeHash,
				done: !!item.marksItemAsDone
					? item.marksItemAsDone.some((action) => isOnboardingActionDone(action, shopAnalytics))
					: isOnboardingActionDone(item.id, shopAnalytics),
				onClick: () => {
					newOnboardingClickEvent({ action: item.id });
					!!action.value.completeOnClick && setOnboardingActionDone(item.id, shopAnalytics);
				},
			};
		case 'FLOW':
			return {
				id,
				action,
				content,
				done: !!item.marksItemAsDone
					? item.marksItemAsDone.some((action) => isOnboardingActionDone(action, shopAnalytics))
					: isOnboardingActionDone(item.id, shopAnalytics),
				onClick: () => {
					newOnboardingClickEvent({ action: item.id });
					!!action.value.completeOnClick && setOnboardingActionDone(item.id, shopAnalytics);
					!!action.value.flowId && UserflowService.startFlow(action.value.flowId);
				},
			};
		case 'LINK':
			return {
				id,
				action,
				content,
				done: !!item.marksItemAsDone
					? item.marksItemAsDone.some((action) => isOnboardingActionDone(action, shopAnalytics))
					: isOnboardingActionDone(item.id, shopAnalytics),
				onClick: () => {
					newOnboardingClickEvent({ action: item.id });
					!!action.value.completeOnClick && setOnboardingActionDone(item.id, shopAnalytics);
					window.open(action.value.toUrl, '_blank', 'noopener,noreferrer');
				},
			};
		case 'VIDEO':
			return {
				id,
				action,
				content,
				done: !!item.marksItemAsDone
					? item.marksItemAsDone.some((action) => isOnboardingActionDone(action, shopAnalytics))
					: isOnboardingActionDone(item.id, shopAnalytics),
				onClick: () => {
					newOnboardingClickEvent({ action: item.id });
					!!action.value.completeOnClick && setOnboardingActionDone(item.id, shopAnalytics);
				},
			};
		default:
			throw new Error(`Invalid argument type ${action.type}`);
	}
};

export const getOnboardingListVersion = (args: {
	createdAt: string | undefined;
	shopAnalytics: ShopAnalytics | undefined;
	userflowChecklist?: boolean;
}): OnboardingListVersion => {
	const { createdAt, shopAnalytics, userflowChecklist } = args;
	if (!shopAnalytics || !createdAt) return OnboardingListVersions.V0;
	const createdAtMoment = moment(createdAt);
	const isTemplateVersion = shopAnalytics.shopSetup === ShopSetups.TEMPLATE;
	if (createdAtMoment.isBefore(RELEASE_DATES.V1)) {
		return OnboardingListVersions.V0;
	}
	if (createdAtMoment.isBefore(RELEASE_DATES.V2)) {
		return isTemplateVersion
			? OnboardingListVersions.V1_TEMPLATE
			: OnboardingListVersions.V1_NO_TEMPLATE;
	}
	if (createdAtMoment.isBefore(RELEASE_DATES.V3)) {
		return isTemplateVersion
			? OnboardingListVersions.V2_TEMPLATE
			: OnboardingListVersions.V2_NO_TEMPLATE;
	}
	return userflowChecklist ? OnboardingListVersions.V3_USERFLOW : OnboardingListVersions.V3;
};

export const _onboardingStepIncludesVersion = (
	stepVersions: (OnboardingListVersion | 'all')[],
	version: OnboardingListVersion,
): boolean => {
	if (!stepVersions.length) return false;
	return stepVersions.includes('all') || stepVersions.includes(version);
};

export const _onboardingStepIncludesPlan = (
	stepPlans: Plan[] | 'all' | 'none',
	plan?: Plan,
): boolean => {
	if (!plan) return false;
	switch (stepPlans) {
		case 'all':
			return true;
		case 'none':
			return false;
		default:
			return stepPlans.includes(plan);
	}
};

export const _onboardingItemsForPlan = (plan?: Plan): OnboardingItemType[] => {
	if (!plan) return [];
	return Object.entries(onboardingItemDefinitions)
		.filter(([, item]) => item.plans === 'all' || item.plans.includes(plan))
		.map(([name]) => name as OnboardingItemType);
};

export const _onboardingStepItemsForVersion = ({
	stepDefinition,
	version,
}: {
	stepDefinition: OnboardingStepDefinition;
	version: OnboardingListVersion;
}): OnboardingItemType[] => {
	return !!stepDefinition.versions
		? stepDefinition.versions[version] ?? stepDefinition.versions['all'] ?? []
		: [];
};

const getOnboardingStepWithItems = ({
	step,
	version,
	plan,
}: {
	step: OnboardingStepType;
	version: OnboardingListVersion;
	plan?: Plan;
}) => {
	const stepDefinition = onboardingStepDefinitions[step];
	const stepItemsForVersion = _onboardingStepItemsForVersion({
		stepDefinition,
		version,
	});
	const stepItemsForPlan = _onboardingItemsForPlan(plan);

	const stepItemsForVersionAndPlan = stepItemsForVersion.filter((item) =>
		stepItemsForPlan.includes(item),
	);

	return {
		name: step,
		items: stepItemsForVersionAndPlan,
	};
};

export const _getOnboardingItem = ({
	allItems,
	item,
	version,
}: {
	allItems: Record<
		OnboardingItemType,
		{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
	>;
	item: OnboardingItemType;
	version: OnboardingListVersion;
}): OnboardingStepItem | undefined => {
	const stepItem = allItems[item];
	return stepItem[version] ?? stepItem['all'];
};

export const getOnboardingSteps = (args: OnboardingStepsArgs): OnboardingStep[] => {
	const { shopAnalytics, onboardingListVersion, plan, t, country } = args;

	const allItems = ONBOARDING_ITEMS(args);

	const onboardingStepIds = (Object.keys(onboardingStepDefinitions) as OnboardingStepType[]).filter(
		(step) => {
			const stepDefinition = onboardingStepDefinitions[step];
			const stepVersions = Object.keys(stepDefinition.versions ?? {}) as (
				| OnboardingListVersion
				| 'all'
			)[];
			return (
				_onboardingStepIncludesVersion(stepVersions, onboardingListVersion) &&
				_onboardingStepIncludesPlan(stepDefinition.plans, plan)
			);
		},
	);

	const filteredOnboardingSteps = onboardingStepIds
		.map((step) => getOnboardingStepWithItems({ step, version: onboardingListVersion, plan }))
		.filter((step) => step.items.length > 0);

	const steps = filteredOnboardingSteps
		.map((step) => {
			const stepItems = step.items
				.map((item) => _getOnboardingItem({ allItems, item, version: onboardingListVersion }))
				.filter(notUndefined);
			const stepVersions = ONBOARDING_STEPS[step.name]({
				t,
				shopAnalytics,
				items: stepItems,
				country,
			});
			return stepVersions[onboardingListVersion] ?? stepVersions['all'];
		})
		.filter(notUndefined);
	return steps;
};
