//https://github.com/jdbence/firestore-parser
export const getFireStoreProp = (value: any) => {
	const props = {
		arrayValue: 1,
		bytesValue: 1,
		booleanValue: 1,
		doubleValue: 1,
		geoPointValue: 1,
		integerValue: 1,
		mapValue: 1,
		nullValue: 1,
		referenceValue: 1,
		stringValue: 1,
		timestampValue: 1,
	};
	return Object.keys(value).find((k) => props[k] === 1);
};

//https://github.com/jdbence/firestore-parser
export const parseFirestoreApiJSON = (value: any) => {
	const prop = getFireStoreProp(value);
	if (prop === 'doubleValue' || prop === 'integerValue') {
		value = Number(value[prop]);
	} else if (prop === 'arrayValue') {
		value = ((value[prop] && value[prop].values) || []).map((v: any) => parseFirestoreApiJSON(v));
	} else if (prop === 'mapValue') {
		value = parseFirestoreApiJSON((value[prop] && value[prop].fields) || {});
	} else if (prop === 'geoPointValue') {
		value = { latitude: 0, longitude: 0, ...value[prop] };
	} else if (prop) {
		value = value[prop];
	} else if (typeof value === 'object') {
		Object.keys(value).forEach((k) => (value[k] = parseFirestoreApiJSON(value[k])));
	}
	return value;
};

export const asQuerySelectFields = (fields: string[]) => fields.map((f) => ({ fieldPath: f }));

export const parseFirestoreQueryResponse = (response: any) =>
	response.data.reduce((arr: any[], d: any) => {
		if (!!d?.document?.fields) arr.push(parseFirestoreApiJSON(d.document.fields));
		return arr;
	}, []);
