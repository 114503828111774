import { OrderPaymentApi, OrderPaymentDb } from './types';

export const toApi = (data: OrderPaymentDb): OrderPaymentApi => {
	return {
		paid: data.paid,
		refunded: data.refunded,
		authorised: data.authorised,
		cancelled: data.cancelled,
		captured: data.captured,
	};
};
