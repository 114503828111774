import { sortBy, startCase } from 'lodash';
import { SearchGroup, SearchableResult } from 'views/__INTERNAL__/DevShortcut/types';

export const noDynamicRoutes = (value: string) => !value.includes(':');
export const toPathLabelString = (value: string) => startCase(value).replaceAll(' ', ' > ');

export const getItemsMatchingSearch = (
	items: SearchableResult[],
	search: string,
): SearchableResult[] => {
	return items.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()));
};

export const sortItemsByGroup = (items: SearchableResult[]): SearchableResult[] => {
	const sortOrder: Record<SearchGroup, number> = {
		Common: 0,
		Routes: 1,
		Actions: 2,
	};
	return sortBy(items, (item) => sortOrder[item.group]);
};
