import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'BOOKING_COMPLETION' })
			: getRouteOnboardingStepItem({ routeTo: Routes.BookingsTab('booked') }),
		content: {
			title: showProductTours
				? t('homeView.onboarding.findAndViewYourOrder', 'Find and view your order')
				: t('homeView.onboarding.viewFulfillEndOrder', 'View, fulfill, and end an order'),
		},
		id: 'BOOKING_COMPLETION',
		type: 'row',
	},
	V0: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'BOOKING_COMPLETION' })
			: getRouteOnboardingStepItem({ routeTo: Routes.BookingsTab('booked') }),
		content: {
			title: showProductTours
				? t(
						'homeView.onboarding.viewWhereAndHowToPrepareOrders',
						'View where and how to handle your orders',
				  )
				: t('homeView.onboarding.viewFulfillEndOrder', 'View, fulfill, and end an order'),
		},
		id: 'BOOKING_COMPLETION',
		type: 'row',
	},
});

export const BOOKING_COMPLETION_ITEM = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<
	'BOOKING_COMPLETION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t, showProductTours });
	return {
		BOOKING_COMPLETION: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
