import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.DINSettings.name', 'DIN Settings'),
	subHeader: t(
		'common:blocks.details.DINSettings.subHeader',
		'Get the right DIN values calculated for you',
	),
	description: t(
		'common:blocks.details.DINSettings.description',
		"Track DIN values for your products manually or use automatic calculation. Collect needed customers' details as a part of booking confirmation. Track DIN values as you prepare a booking and store them on a receipt",
	),
	supportArticle: SupportArticles.DIN_SETTINGS,
	uninstallNotice: t(
		'common:blocks.details.DINSettings.uninstallNotice',
		'By uninstalling DIN Settings, you will lose all your DIN calculation settings',
	),
});

export default details;
