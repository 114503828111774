import { AddOn, AddOns } from 'common/modules/plans';
import { Routes } from 'routing';

import analyticsValueProps from './ANALYTICS/valueProps';
import categoryTermsValueProps from './CATEGORY_TERMS/valueProps';
import dashboardValueProps from './DASHBOARD/valueProps';
import discountCodesValueProps from './DISCOUNT_CODES/valueProps';
import extensionsValueProps from './EXTENSIONS/valueProps';
import maintenanceTimeValueProps from './MAINTENANCE_TIME/valueProps';
import manualPaymentMethodsValueProps from './MANUAL_PAYMENT_METHODS/valueProps';
import massReturnValueProps from './MASS_RETURN/valueProps';
import packageProductsValueProps from './PACKAGE_PRODUCTS/valueProps';
import productVariantsValueProps from './PRODUCT_VARIANTS/valueProps';
import { AddOnValueProp } from './types';

export const getAddOnSetupPage = (addOn: AddOn) => {
	const setupPage: Record<AddOn, string | null> = {
		[AddOns.EXTENSIONS]: Routes.CATALOG,
		[AddOns.GOOGLE_ANALYTICS]: Routes.ACCOUNT_INTEGRATIONS,
		[AddOns.BRANDING]: Routes.ACCOUNT_GENERAL,
		[AddOns.PACKAGE_PRODUCTS]: Routes.CATALOG_CREATE_PRODUCT,
		[AddOns.PRODUCT_VARIANTS]: Routes.CATALOG,
		[AddOns.MASS_RETURN]: Routes.RETURN,
		[AddOns.DIN_SETTINGS]: Routes.CatalogTab('settings'),
		[AddOns.DISCOUNT_CODES]: Routes.CATALOG_CREATE_DISCOUNT,
		[AddOns.MAINTENANCE_TIME]: Routes.CATALOG,
		[AddOns.CATEGORY_TERMS]: Routes.CatalogTab('categories'),
		[AddOns.MANUAL_PAYMENT_METHODS]: Routes.ACCOUNT_PAYMENTS,
		[AddOns.DASHBOARD]: Routes.DASHBOARD,
		[AddOns.SKIDATA]: Routes.ACCOUNT_INTEGRATIONS,
		[AddOns.TEKSO]: Routes.ACCOUNT_INTEGRATIONS,
		[AddOns.CLOUD_POS]: Routes.ACCOUNT_INTEGRATIONS,
	};

	return setupPage[addOn] ?? Routes.AddOnStoreTab('installed');
};

export const getAddOnValueProps = (addOn: AddOn) => {
	const valueProp: Record<AddOn, AddOnValueProp[]> = {
		[AddOns.EXTENSIONS]: extensionsValueProps,
		[AddOns.GOOGLE_ANALYTICS]: analyticsValueProps,
		[AddOns.BRANDING]: [],
		[AddOns.PACKAGE_PRODUCTS]: packageProductsValueProps,
		[AddOns.PRODUCT_VARIANTS]: productVariantsValueProps,
		[AddOns.MASS_RETURN]: massReturnValueProps,
		[AddOns.DIN_SETTINGS]: [],
		[AddOns.DISCOUNT_CODES]: discountCodesValueProps,
		[AddOns.MAINTENANCE_TIME]: maintenanceTimeValueProps,
		[AddOns.CATEGORY_TERMS]: categoryTermsValueProps,
		[AddOns.DASHBOARD]: dashboardValueProps,
		[AddOns.MANUAL_PAYMENT_METHODS]: manualPaymentMethodsValueProps,
		[AddOns.SKIDATA]: [],
		[AddOns.TEKSO]: [],
		[AddOns.CLOUD_POS]: [],
	};
	return valueProp[addOn];
};
