export type IsValidUrlOptions = {
	allowEmpty?: boolean;
	allowedProtocols?: string[];
	requireTLD?: boolean;
};

export const isValidUrl = (value: string | undefined, options?: IsValidUrlOptions): boolean => {
	const { allowEmpty = false, requireTLD = false, allowedProtocols } = options || {};

	try {
		if (!value) {
			return allowEmpty;
		}

		const url = new URL(value);

		if (!!allowedProtocols) {
			if (allowedProtocols.length === 0 && !!url.protocol) return false;
			if (
				allowedProtocols.length > 0 &&
				!allowedProtocols.includes(url.protocol.replace(':', '').replace('/', ''))
			)
				return false;
		}

		if (!!requireTLD) {
			const tld = getTLD(url);
			if (!tld) return false;
		}

		return !!url;
	} catch (err) {
		return false;
	}
};

export const getNormalizedUrl = (
	_url: string | undefined,
	options?: {
		defaultProtocol?: string;
	},
): string => {
	const { defaultProtocol } = options || {};
	let url = _url;
	if (!url) return '';

	if (!!defaultProtocol) {
		const currentProtocol = getProtocol(url);
		if (!currentProtocol) {
			url = withProtocol(defaultProtocol, url);
		}
	}

	return url;
};

export const getProtocol = (url: string | undefined): string | null => {
	try {
		if (!url) return null;
		return new URL(url).protocol;
	} catch (err) {
		return null;
	}
};

export const withProtocol = (protocol: string, url: string): string => {
	return `${protocol.replace(':', '').replace('/', '')}://${url}`;
};

export const getTLD = (url: URL): string | null => {
	const hostname = url.hostname;
	if (!hostname) return null;
	return hostname.includes('.') ? hostname.split('.').pop() ?? null : null;
};
