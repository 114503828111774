import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { Api } from 'common/core-api';
import { Callable } from 'common/frontend/callable';
import { isDevEnv } from 'common/utils/common';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	onClose: (email: string | null) => void;
	open: boolean;
	email: string;
}

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>;

const SimpleFormDialog = (props: Props) => {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const [email, setEmail] = useState<string>(props.email);
	const [resetError, setResetError] = useState<string>('');
	const [completed, setCompleted] = useState<boolean>(false);

	const handleInputChange = (event: InputEvent) => {
		setEmail(event.target.value);
	};

	const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!email) {
			setResetError(t('common:mandatory'));
			return;
		}
		setCompleted(true);
		try {
			// Temporarily we only use new Core API in DEV environment, and fallback to Callable in production
			if (isDevEnv()) {
				await Api.users.resetPassword({ email });
			} else {
				await Callable.users.sendPasswordResetLink({ email });
			}
		} catch (e) {}
	};

	return (
		<Dialog
			open={props.open}
			onClose={() => props.onClose(email)}
			aria-labelledby="form-dialog-title"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle id="form-dialog-title">
				{t('resetPasswordDialog.resetHeader', 'Reset password')}
			</DialogTitle>
			<form onSubmit={handleResetPassword}>
				<DialogContent className={classes.dialogContent}>
					{completed ? (
						<DialogContentText>
							{t(
								'resetPasswordDialog.resetPasswordDone',
								'Thank you! You should receive an email with instructions for resetting your password within a few minutes.',
							)}
						</DialogContentText>
					) : (
						<div>
							<DialogContentText>
								{t(
									'resetPasswordDialog.resetPasswordPrompt',
									"Type in the email address you use to sign in to Rentle, and we'll send you an email with instructions on how to reset your password.",
								)}
							</DialogContentText>
							<TextField
								autoFocus
								margin="normal"
								id="email"
								label={t('common:form.email')}
								onChange={handleInputChange}
								value={email}
								type="email"
								fullWidth
							/>
							<Typography gutterBottom color="error">
								{resetError}
							</Typography>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					{completed ? (
						<Button onClick={() => props.onClose(email)} color="primary" variant="contained">
							{t('common:greetings.okBtn')}
						</Button>
					) : (
						<React.Fragment>
							<Button onClick={() => props.onClose(null)} color="secondary">
								{t('common:actions.cancel')}
							</Button>
							<Button type="submit" color="primary" variant="contained">
								{t('common:actions.send')}
							</Button>
						</React.Fragment>
					)}
				</DialogActions>
			</form>
		</Dialog>
	);
};

const useStyles = makeStyles()((theme) => ({
	dialogContent: {
		minHeight: 160,
		display: 'flex',
		alignItems: 'center',
	},
}));

export default SimpleFormDialog;
