import * as dbPath from 'common/api/db/paths';
import buildPath from 'common/api/db/paths/buildPath';
import { DbRefs } from 'common/db/api/types';
import { AcsInventoryDb } from 'common/modules/acs';

import {
	createBasicCollectionOperations,
	createBasicDbOperations,
	createBasicDocOperations,
} from '../utils';

export const getAcsInventoryQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.acsInventory;
	return {
		doc: (id: string) => ({
			...createBasicDocOperations<AcsInventoryDb>(collectionPath, dbRefs, id),
			versions: acsInventoryVersionsQueries(dbRefs, id),
		}),
		...createBasicCollectionOperations<AcsInventoryDb>(collectionPath, dbRefs),
	};
};

const acsInventoryVersionsQueries = (dbRefs: DbRefs, id: string) => {
	const collectionPath = `${buildPath(dbPath.acsInventoryDocBase, id)}/versions`;
	return createBasicDbOperations<AcsInventoryDb>(collectionPath, dbRefs);
};
