import {
	AddOn,
	BillingCycle,
	Plan,
	PurchasedPlanConfigurableValue,
	PurchasedProductPricing,
	RestrictedFeature,
} from 'common/modules/plans';
import { ById, YYYY_MM_DD } from 'common/types';

import { CallableCreator } from '..';

export interface EnableAddOnArgs {
	shopId: string;
	addOns: AddOn[];
}

export interface DisableAddOnArgs {
	shopId: string;
	addOns: AddOn[];
}

export interface UpdatePlanArgs {
	shopId: string;
	plan: Plan;
}

export interface UpdateFeaturesArgs {
	shopId: string;
	enabledFeatures?: RestrictedFeature[];
	hiddenFeatures?: RestrictedFeature[];
}

export interface PlanPurchaseArgs {
	merchantId: string;
	plan: Plan;
	billingCycle: BillingCycle;
	campaignCode?: string;
}

export interface PlanChangeArgs {
	merchantId: string;
	plan: Plan;
	blockSlots: PurchasedPlanConfigurableValue;
	billingCycle: BillingCycle;
	nextBillingDate?: YYYY_MM_DD;
	stores?: PurchasedPlanConfigurableValue;
	pickupLocations?: PurchasedPlanConfigurableValue;
	languages?: PurchasedPlanConfigurableValue;
	users?: PurchasedPlanConfigurableValue;
	articles?: PurchasedPlanConfigurableValue;
	pricing: PurchasedProductPricing;
	additionalStorePricing?: PurchasedProductPricing;
	locationPricing?: ById<PurchasedProductPricing>;
	internalNote?: string;
}

export interface PlanPauseRequestArgs {
	merchantName: string;
	merchantId: string;
	userEmail: string;
	existingPlan: Plan;
	pauseDuration: number;
}

export interface AddStoreSubscriptionArgs {
	merchantId: string;
	storeName: string;
}
export interface RemoveStoreSubscriptionArgs {
	merchantId: string;
	storeId: string;
}

export const plans = (createCallable: CallableCreator) => ({
	addOns: {
		enable: createCallable<EnableAddOnArgs, void>('plans-functions-enableAddOns'),
		disable: createCallable<DisableAddOnArgs, void>('plans-functions-disableAddOns'),
	},
	plan: {
		update: createCallable<UpdatePlanArgs, void>('plans-functions-updatePlan'),
		purchase: createCallable<PlanPurchaseArgs, void>('billing-f-subscriptions-purchase'),
	},
	subscriptions: {
		addStore: createCallable<AddStoreSubscriptionArgs, void>('billing-f-subscriptions-addStore'),
		removeStore: createCallable<RemoveStoreSubscriptionArgs, void>(
			'billing-f-subscriptions-removeStore',
		),
		purchase: createCallable<PlanPurchaseArgs, void>('billing-f-subscriptions-purchase'),
		change: createCallable<PlanChangeArgs, void>('billing-f-subscriptions-change'),
		pauseRequest: createCallable<PlanPauseRequestArgs, void>(
			'billing-f-subscriptions-pauseRequest',
		),
	},
	features: {
		update: createCallable<UpdateFeaturesArgs, void>('plans-functions-updateFeatures'),
	},
});
