import React from 'react';

import {
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	ListSubheader,
	Menu,
	MenuItem,
	MenuItemProps,
} from '@mui/material';
import { IconType } from 'react-icons';
import { RiCheckLine } from 'react-icons/ri';
import { Link as RRLink } from 'react-router-dom';

import useMenuState from 'common/hooks/useMenuState';
import HeaderButton from 'components/Layout/Header/HeaderButton';

type HeaderMenuBaseItem = {
	label: string;
	icon?: IconType;
	selected?: boolean;
	className?: string;
};

type HeaderMenuButtonItem = HeaderMenuBaseItem & {
	type: 'button';
	onClick: () => void;
};

type HeaderMenuExternalLinkItem = HeaderMenuBaseItem & {
	type: 'external-link';
	href: string;
};

type HeaderMenuInternalLinkItem = HeaderMenuBaseItem & {
	type: 'internal-link';
	route: string;
};

export type HeaderMenuItem =
	| HeaderMenuButtonItem
	| HeaderMenuExternalLinkItem
	| HeaderMenuInternalLinkItem;

interface HeaderMenuProps {
	icon?: IconType;
	label?: string;
	/**
	 * Hide the label and only show the icon
	 */
	collapsed?: boolean;
	/**
	 * Only show the icon when the item is collapsed and the label is not visible
	 */
	collapsedIconOnly?: boolean;
	/**
	 * When collapsed, show the label as the menu heading
	 */
	collapsedMenuLabel?: boolean;
	items?: HeaderMenuItem[];
}

const HeaderMenu = (props: HeaderMenuProps) => {
	const { icon, label, collapsed, collapsedIconOnly, collapsedMenuLabel, items } = props;
	const { menuProps, menuActions } = useMenuState();
	return (
		<>
			<HeaderButton
				onClick={menuActions.open}
				icon={icon}
				label={label}
				collapsed={collapsed}
				collapsedIconOnly={collapsedIconOnly}
			/>

			<Menu {...menuProps} onClick={menuActions.close}>
				{!!collapsed && collapsedMenuLabel && <ListSubheader>{label}</ListSubheader>}
				{items?.map((item) => {
					const ItemIcon = item.icon;
					const contents = (
						<>
							{!!ItemIcon && (
								<ListItemIcon>
									<ItemIcon />
								</ListItemIcon>
							)}

							<ListItemText primary={item.label} />
							<ListItemSecondaryAction>
								{item.selected && <RiCheckLine size={20} />}
							</ListItemSecondaryAction>
						</>
					);

					const sharedProps: MenuItemProps<any, {}> = {
						key: item.label,
						selected: item.selected,
						className: item.className,
					};

					switch (item.type) {
						case 'external-link': {
							return (
								<MenuItem
									{...sharedProps}
									component="a"
									href={item.href}
									rel="nopener noreferrer"
									target="_blank"
								>
									{contents}
								</MenuItem>
							);
						}
						case 'internal-link': {
							return (
								<MenuItem {...sharedProps} component={RRLink} to={item.route}>
									{contents}
								</MenuItem>
							);
						}
						case 'button': {
							return (
								<MenuItem {...sharedProps} onClick={item.onClick}>
									{contents}
								</MenuItem>
							);
						}
						default:
							return null;
					}
				})}
			</Menu>
		</>
	);
};

export default HeaderMenu;
