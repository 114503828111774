import React from 'react';

import { TextField } from '@mui/material';
import { useRegisterContext } from 'views/Register/RegisterContext';
import { CapabilityQuestion } from 'views/Register/utils';

import {
	CapabilityOptionName,
	OFFERING_OPTION_NAMES,
} from 'common/services/analytics/tractionAnalytics';
import { useTranslation } from 'services/localization';

import RegistrationOption from '../RegistrationOption';
import RegistrationRow from '../RegistrationRow';
import CapabilityOption from './CapabilityOption';

export interface Props {
	index: number;
	value: CapabilityQuestion;
	selectedOptions: CapabilityOptionName[];
	onChange: (updatedOptions: CapabilityOptionName[]) => void;
}

const CapabilityQuestionRow = (props: Props) => {
	const { index, value, selectedOptions, onChange } = props;
	const { t } = useTranslation();
	const [capabilitySpecifications, setStore] = useRegisterContext(
		(state) => state.capabilitySpecifications,
	);

	const isOtherOptionSelected = selectedOptions.includes(OFFERING_OPTION_NAMES.OTHER);

	const handleOnChange = (isActive: boolean, optionId: CapabilityOptionName) => {
		const updatedOptions = isActive
			? [...selectedOptions, optionId]
			: selectedOptions.filter((option) => option !== optionId);
		onChange(updatedOptions);
	};

	return (
		<RegistrationRow title={value.title} index={index}>
			{value.options.map((option, index) => {
				const isLast = index === value.options.length - 1;
				const isSelected = selectedOptions.includes(option.id);
				return (
					<RegistrationOption
						key={`${option.id}-${index}`}
						isLast={isLast}
						styles={isOtherOptionSelected ? { mb: 2 } : undefined}
					>
						<CapabilityOption
							option={option}
							isSelected={isSelected}
							onChange={(isSelected) => handleOnChange(isSelected, option.id)}
						/>
					</RegistrationOption>
				);
			})}
			{isOtherOptionSelected && (
				<TextField
					fullWidth
					label={`${t('common:pleaseSpecify', 'Please specify')}`}
					value={capabilitySpecifications?.[OFFERING_OPTION_NAMES.OTHER] ?? ''}
					sx={{ ml: (theme) => theme.spacing(1), mb: (theme) => theme.spacing(4) }}
					onChange={(e) =>
						setStore((store) => {
							store.dirty = true;
							store.capabilitySpecifications = {
								...store.capabilitySpecifications,
								[OFFERING_OPTION_NAMES.OTHER]: e.target.value,
							};
						})
					}
					autoFocus
				/>
			)}
		</RegistrationRow>
	);
};

export default CapabilityQuestionRow;
