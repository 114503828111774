import { createAction } from '@reduxjs/toolkit';

import { signOutServer } from 'common/api/authentication';
import { refreshAuthUserIfNecessary } from 'common/api/frontend/user';
import { api } from 'common/frontend/api';
import { signOut } from 'common/frontend/firebase/auth';
import { ListenerManager } from 'common/services/ListenerManager';
import { ShopPublicInfo, UserApi } from 'common/types';
import { notUndefined } from 'common/utils/common';
import * as UserSelectors from 'selectors/UserSelectors';
import { createAppThunk } from 'services/utils/redux';

const userListeners = new ListenerManager<'user' | 'shops'>();

export const logout = createAppThunk('User/LOGOUT', async (args: void, thunkAPI) => {
	thunkAPI.dispatch(clearUserListeners());
	await signOutServer();
	await signOut();
});

export const setUserListeners = createAppThunk(
	'User/SET_USER_LISTENERS',
	(activeUserId: string, thunkAPI) => {
		thunkAPI.dispatch(setActiveUserListener(activeUserId));
		thunkAPI.dispatch(setActiveUserShopsListener(activeUserId));
	},
);

export const clearUserListeners = createAppThunk('User/CLEAR_USER_LISTENERS', () => {
	userListeners.clearAll();
});

export const setActiveUserData = createAction<UserApi | null>('User/SET_ACTIVE_USER_DATA');
export const setActiveUserError = createAction<string>('User/SET_ACTIVE_USER_ERROR');
export const setActiveUserListener = createAppThunk(
	'User/SET_ACTIVE_USER_LISTENER',
	(userId: string, thunkAPI) => {
		return new Promise<UserApi>((resolve, reject) => {
			userListeners.update({
				id: 'user',
				key: userId,
				listener: api()
					.users.doc(userId)
					.listen(
						(user) => {
							if (!user) {
								/**
								 * User document may not have been created yet when the listener returns the first time
								 */
								return;
							}
							if (!user) {
								const err = 'User not found';
								thunkAPI.dispatch(setActiveUserError(err));
								return reject(err);
							}
							const userBefore = UserSelectors.userData(thunkAPI.getState());

							if (!!userBefore) {
								refreshAuthUserIfNecessary(userBefore, user);
							}

							thunkAPI.dispatch(setActiveUserData(user));
							return resolve(user);
						},
						(error) => {
							const err = error.message ?? 'Error getting active user';
							thunkAPI.dispatch(setActiveUserError(err));
							return reject(err);
						},
					),
			});
		});
	},
);
export const clearActiveUserListener = createAppThunk('User/CLEAR_ACTIVE_USER_LISTENER', () => {
	userListeners.clear('user');
});

export const setActiveUserShopsData = createAction<ShopPublicInfo[]>(
	'User/SET_ACTIVE_USER_SHOPS_DATA',
);
export const setActiveUserShopsError = createAction<string>('User/SET_ACTIVE_USER_SHOPS_ERROR');
export const setActiveUserShopsListener = createAppThunk(
	'User/SET_ACTIVE_USER_SHOPS_LISTENER',
	(userId: string, thunkAPI) => {
		return new Promise<ShopPublicInfo[]>((resolve, reject) => {
			userListeners.update({
				id: 'shops',
				key: userId,
				listener: api()
					.shops.get.where(`users.${userId}` as any, '==', true)
					.onSnapshot(
						async (shops) => {
							const updatedShopsCount = shops.length;
							const currentActiveUserShops = thunkAPI.getState().user.shops.data ?? [];
							const currentActiveUserShopsCount = currentActiveUserShops.length;
							if (updatedShopsCount !== currentActiveUserShopsCount) {
								const publicInfos = (
									await Promise.all(
										shops.map(async (shop) => {
											const shopId = shop.id;
											return api().shops.doc(shopId).public.get();
										}),
									)
								).filter(notUndefined);
								thunkAPI.dispatch(setActiveUserShopsData(publicInfos));
								return resolve(publicInfos);
							}
							return resolve(currentActiveUserShops);
						},
						(error) => {
							const err = error.message ?? 'Error getting active user shops';
							thunkAPI.dispatch(setActiveUserShopsError(err));
							return reject(err);
						},
					),
			});
		});
	},
);
export const clearActiveUserShopsListener = createAppThunk(
	'User/CLEAR_ACTIVE_USER_SHOPS_LISTENER',
	() => {
		userListeners.clear('shops');
	},
);

export const setActiveLocationId = createAppThunk(
	'User/SET_ACTIVE_LOCATION_ID',
	(locationId: string, thunkAPI) => {
		const activeUserId = UserSelectors.activeUserId(thunkAPI.getState());
		if (!activeUserId) return;
		const activeLocations = JSON.parse(localStorage.getItem('activeLocations') ?? '{}');
		activeLocations[activeUserId] = locationId;
		localStorage.setItem('activeLocations', JSON.stringify(activeLocations));
		return activeLocations;
	},
);
