import errorHandler from 'common/services/errorHandling/errorHandler';
import { isLocalEnv } from 'common/utils/common';

import { CustomerPersona } from './tractionAnalytics';

/** Event ids */
export const CREATED_ORDER = 'created-order';
export const CREATED_PRODUCT = 'created-product';
export const PLAN_CHANGED = 'plan-changed';
export const ONBOARDING_EVENT = 'onboarding-event';

const INTERCOM_APP_ID = 'cgregytq';

declare var Intercom: any;

type UnixTimestamp = number;

type IntercomCompanyInfoFixed = {
	company_id: string;
	name: string;
	created_at?: UnixTimestamp;
	plan?: string;
	monthly_spend?: number;
	size?: number;
	website?: string;
	industry?: string;
	customer_persona?: CustomerPersona;
	product_count?: number;
	order_count?: number;
	revenue?: number;
};

type IntercomUserInfoFixed = {
	email?: string;
	user_id?: string;
	created_at?: UnixTimestamp;
	name?: string;
	phone?: number;
	language_override?: number;
	avatar?: {
		type: 'avatar';
		image_url: string;
	};
	user_hash?: string;
	company?: IntercomCompany;
	companies?: IntercomCompany[];
};

export type IntercomCompany = IntercomCompanyInfoFixed & {
	[customAttribute: string]: string | number | undefined | object | boolean;
};

export type IntercomUser = IntercomUserInfoFixed & {
	[customAttribute: string]: string | number | undefined | object | boolean;
};

export const trackEvent = (event: string, metadata?: any) => {
	if (isLocalEnv()) {
		return;
	}
	try {
		Intercom('trackEvent', event, metadata);
	} catch (e) {
		errorHandler.report(e);
	}
};

export const update = (userDetails?: object) => {
	if (isLocalEnv()) {
		return;
	}
	try {
		Intercom('update', userDetails);
	} catch (e) {
		errorHandler.report(e);
	}
};

export const boot = ({ email }: { email?: string }) => {
	if (isLocalEnv()) {
		return;
	}
	try {
		Intercom('boot', {
			app_id: INTERCOM_APP_ID,
			...(email && { email }),
			custom_launcher_selector: '.intercom_launcher',
			hide_default_launcher: true,
		});
	} catch (e) {
		errorHandler.report(e);
	}
};

export const logout = () => {
	if (isLocalEnv()) {
		return;
	}
	try {
		Intercom('shutdown');
	} catch (e) {
		errorHandler.report(e);
	}
};

export const open = (msg?: string) => {
	if (isLocalEnv()) {
		return;
	}

	try {
		msg ? Intercom('showNewMessage', msg) : Intercom('showNewMessage');
	} catch (e) {
		errorHandler.report(e);
	}
};
