import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Draft, produce } from 'immer';

/**
 * Based on fast context from Jack Herrington
 *
 * Slightly modified to:
 * - Use immer for state updates
 * - Support updating the store if the initial state changes
 *
 * https://www.youtube.com/watch?v=ZKlXqrcBx88
 * https://github.com/jherr/fast-react-context
 */

export default function createFastContext<Store>() {
	function useStoreData(
		initialState: Store,
		updateStoreOnInitialStateChange?: (currentState: Store, newInitialState: Store) => Store | null,
	): {
		get: () => Store;
		set: (producer: (draft: Draft<Store>) => void) => void;
		subscribe: (callback: () => void) => () => void;
	} {
		const store = useRef(initialState);

		const get = useCallback(() => store.current, []);

		const subscribers = useRef(new Set<() => void>());

		const set = useCallback((producer: (draft: Draft<Store>) => void) => {
			store.current = produce(store.current, producer);
			subscribers.current.forEach((callback) => callback());
		}, []);

		const subscribe = useCallback((callback: () => void) => {
			subscribers.current.add(callback);
			return () => subscribers.current.delete(callback);
		}, []);

		useEffect(() => {
			if (!updateStoreOnInitialStateChange) return;
			const newState = updateStoreOnInitialStateChange(store.current, initialState);
			if (!newState) return;
			set(() => {
				return newState;
			});
		}, [initialState, updateStoreOnInitialStateChange, set]);

		return {
			get,
			set,
			subscribe,
		};
	}

	type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

	const StoreContext = createContext<UseStoreDataReturnType | null>(null);

	function Provider({
		children,
		initialState,
		updateStoreOnInitialStateChange,
	}: {
		children: React.ReactNode;
		initialState: Store;
		updateStoreOnInitialStateChange?: (currentState: Store, newInitialState: Store) => Store | null;
	}) {
		return (
			<StoreContext.Provider value={useStoreData(initialState, updateStoreOnInitialStateChange)}>
				{children}
			</StoreContext.Provider>
		);
	}

	function useStore<SelectorOutput>(
		selector: (store: Store) => SelectorOutput,
	): [SelectorOutput, (producer: (draft: Draft<Store>) => void) => void] {
		const store = useContext(StoreContext);
		if (!store) {
			throw new Error('Store not found');
		}

		const [state, setState] = useState(selector(store.get()));

		useEffect(() => {
			return store.subscribe(() => setState(selector(store.get())));
		}, [selector, store]);

		return [state, store.set];
	}

	return {
		Provider,
		useStore,
	};
}
