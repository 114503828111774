import * as dbPath from 'common/api/db/paths';
import { buildQuery, createBasicDbOperations } from '../utils';
import { Reservation, SalesReservation } from 'common/types';
import { DbRefs } from '../types';

export const getReservationQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.reservations;
	const dataUpdater = undefined;
	const query = buildQuery<Reservation>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<Reservation>(collectionPath, dbRefs, dataUpdater),
		sales: createBasicDbOperations<SalesReservation>(dbPath.salesReservations, dbRefs, dataUpdater),
	};
};
