import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { createBasicDbOperations } from '../utils';
import { UserApi } from 'common/types';

export const getUserQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.usersCollection;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<UserApi>(collectionPath, dbRefs, dataUpdater),
	};
};
