import { stringEnum } from 'common/utils/objects';
import { z } from 'zod';
import { InventoryStatus } from './types';

export const InventoryStatuses = stringEnum(['IN_USE', 'OUT_OF_USE', 'LOST', 'SOLD']);
export const zInventoryStatus = z.nativeEnum(InventoryStatuses);
export const AllInventoryStatuses: InventoryStatus[] = Object.values(InventoryStatuses);

export const ItemStates = stringEnum(['IN', 'OUT']);

export const FixedFieldValueOptions = stringEnum(['soleLength', 'dinVariation']);
