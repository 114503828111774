// Solution from: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// "Feel free to copy paste it in your project or put it on npm."

import { useCallback, useEffect, useRef, useState } from 'react';

interface IntervalOptions {
	triggerOnInit: boolean;
}

export function useInterval(callback: () => void, delay: number, options?: IntervalOptions) {
	const savedCallback = useRef<() => void>();
	const triggerOnInit = Boolean(options?.triggerOnInit);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

	const _clearInterval = useCallback(() => {
		if (intervalId) {
			clearInterval(intervalId);
		}
	}, [intervalId]);

	useEffect(() => {
		if (triggerOnInit) {
			callback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			if (savedCallback.current) {
				savedCallback.current();
			}
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			setIntervalId(id);
			return () => clearInterval(id);
		}
		return () => undefined;
	}, [delay]);

	return {
		clearInterval: _clearInterval,
	};
}
