import React from 'react';

import { Box, BoxProps } from '@mui/material';
import { RiArrowUpLine } from 'react-icons/ri';

interface UpgradeBadgeProps extends BoxProps {
	size?: number;
}
const UpgradeBadge = (props: UpgradeBadgeProps) => {
	const { size = 16, sx } = props;
	return (
		<Box
			sx={{
				display: 'flex',
				position: 'absolute',
				justifyContent: 'center',
				alignItems: 'center',
				top: -6,
				right: -6,
				borderRadius: '50%',
				background: (theme) => theme.palette.background.upgradeGradient,
				width: `calc(${size}px + 8px)`,
				height: `calc(${size}px + 8px)`,
				zIndex: 100,
				color: (theme) => theme.palette.text.primary,
				...sx,
			}}
		>
			<RiArrowUpLine size={size} />
		</Box>
	);
};

export default UpgradeBadge;
