import { PaymentObjectApi, PaymentObjectDb } from './types';

export const toApi = (data: PaymentObjectDb): PaymentObjectApi => {
	return {
		value: data.value,
		currency: data.currency,
		paid: data.paid,
		refunded: data.refunded,
		authorised: data.authorised,
		cancelled: data.cancelled,
		captured: data.captured,
	};
};
