import React from 'react';

import { setUserActive } from 'actions';
import { connect } from 'react-redux';

import { UserApi } from 'common/types';
import { ReduxState } from 'services/types';

import { pageVisibilityApi } from '../services/utils';

const { hidden, visibilityChange } = pageVisibilityApi();

interface StateToProps {
	userIsActive: boolean;
	user: UserApi | null;
}

interface DispatchProps {
	setUserActive: (userIsActive: boolean) => void;
}

type Props = DispatchProps & StateToProps;

class VisibilityManager extends React.Component<Props> {
	componentDidMount() {
		this.props.setUserActive(true);
		document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

		document.addEventListener('focus', this.forceVisibilityTrue, false);
		document.addEventListener('blur', this.forceVisibilityFalse, false);

		window.addEventListener('focus', this.forceVisibilityTrue, false);
		window.addEventListener('blur', this.forceVisibilityFalse, false);
	}

	handleVisibilityChange = (forcedFlag: any) => {
		// this part handles when it's triggered by the focus and blur events
		if (typeof forcedFlag === 'boolean') {
			if (forcedFlag) {
				return this.setVisibility(true);
			}
			return this.setVisibility(false);
		}

		// this part handles when it's triggered by the page visibility change events
		if (document[hidden]) {
			return this.setVisibility(false);
		}
		return this.setVisibility(true);
	};

	forceVisibilityTrue = () => {
		this.handleVisibilityChange(true);
	};

	forceVisibilityFalse = () => {
		this.handleVisibilityChange(false);
	};

	setVisibility = (flag: boolean) => {
		if (this.props.userIsActive !== flag) {
			this.props.setUserActive(flag);
		}
	};

	componentWillUnmount() {
		document.removeEventListener(visibilityChange, this.handleVisibilityChange, false);

		document.removeEventListener('focus', this.forceVisibilityTrue, false);
		document.removeEventListener('blur', this.forceVisibilityFalse, false);

		window.removeEventListener('focus', this.forceVisibilityTrue, false);
		window.removeEventListener('blur', this.forceVisibilityFalse, false);
	}

	render() {
		return null;
	}
}

const mapStateToProps = (state: ReduxState): StateToProps => {
	const { userIsActive } = state.view;
	const user = state.user.user.data;
	return { userIsActive, user };
};

const dispatchProps: DispatchProps = {
	setUserActive,
};

export default connect(mapStateToProps, dispatchProps)(VisibilityManager);
