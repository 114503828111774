import { TFunction } from 'react-i18next';

import { OnboardingListVersion, ShopAnalytics } from 'common/services/analytics/tractionAnalytics';

import { OnboardingStep, OnboardingStepItem } from '../../types';
import { getOnboardingItemFromOnboardingStepItem } from '../../utils';

export const PAYMENTS_STEP = ({
	items,
	shopAnalytics,
	t,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
}): { all: OnboardingStep } | { [key in OnboardingListVersion]?: OnboardingStep } => ({
	all: {
		name: t(
			'homeView.onboarding.setUpOnlinePaymentMethods',
			'Set up your online payment methods to accept pre-paid orders',
		),
		type: 'list' as 'list',
		items: items.map((item) => getOnboardingItemFromOnboardingStepItem(item, shopAnalytics)),
	},
});
