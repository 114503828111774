import { matchPath } from 'react-router';

import history from 'services/history';

import buildPath from './buildPath';
import * as T from './types';

const navigate = (method: 'push' | 'replace') => (
	to: string | { route: T.Route; args: T.RouteArguments },
	state?: T.StateArguments,
) => {
	if (typeof to === 'string') {
		history[method](to, state);
	} else {
		const path = buildPath(to.route, to.args);
		history[method](path, state);
	}
};

export const push = navigate('push');
export const replace = navigate('replace');
export const goBack = history.goBack;
export const goForward = history.goForward;

export const isRouteActive = (
	route: string,
	currentPath: string,
	props: {
		exact?: boolean;
		strict?: boolean;
	} = {},
) => {
	return (
		matchPath(currentPath, {
			path: route,
			exact: props.exact || false,
			strict: props.strict || false,
		}) !== null
	);
};
