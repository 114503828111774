import axios from 'axios';

import { getFirestoreApiBaseUrl } from 'common/api/configs';
import { OrderProduct, PurchaseTypes } from 'common/types';
import { stringEnum } from 'common/utils/objects';

import { asQuerySelectFields, parseFirestoreQueryResponse } from './utils';

const useEmulator = process.env.REACT_APP_USE_EMULATOR === 'true';

interface FetchOrderProductAvailabilitiesProps {
	skuId: string;
	startDate: string;
	startLocationId: string;
}

interface FetchSoldOrderProductAvailabilitiesProps {
	skuId: string;
	startLocationId: string;
}

export const OrderProductAvailabilityFields = stringEnum(['id', 'unavailable', 'cancelled']);
export type OrderProductAvailability = Pick<
	OrderProduct,
	keyof typeof OrderProductAvailabilityFields
>;

export const fetchOrderProductAvailabilities = async (
	props: FetchOrderProductAvailabilitiesProps,
) => {
	const { skuId, startDate, startLocationId } = props;

	const response = await axios.post(`${getFirestoreApiBaseUrl(useEmulator)}:runQuery`, {
		structuredQuery: {
			from: [{ collectionId: 'orderProducts' }],
			orderBy: [{ field: { fieldPath: 'unavailable.until' }, direction: 'ASCENDING' }],
			startAt: { values: [{ stringValue: startDate }], before: true },
			select: {
				fields: asQuerySelectFields(Object.keys(OrderProductAvailabilityFields)),
			},
			where: {
				compositeFilter: {
					filters: [
						{
							fieldFilter: {
								field: {
									fieldPath: 'startLocationId',
								},
								op: 'EQUAL',
								value: {
									stringValue: startLocationId,
								},
							},
						},
						{
							fieldFilter: {
								field: {
									fieldPath: 'summary.skuIds',
								},
								op: 'ARRAY_CONTAINS',
								value: {
									stringValue: skuId,
								},
							},
						},
					],
					op: 'AND',
				},
			},
		},
	});

	return parseFirestoreQueryResponse(response) as OrderProductAvailability[];
};

export const fetchSoldOrderProductAvailabilities = async (
	props: FetchSoldOrderProductAvailabilitiesProps,
) => {
	const { skuId, startLocationId } = props;

	const response = await axios.post(`${getFirestoreApiBaseUrl(useEmulator)}:runQuery`, {
		structuredQuery: {
			from: [{ collectionId: 'orderProducts' }],
			select: {
				fields: asQuerySelectFields(Object.keys(OrderProductAvailabilityFields)),
			},
			where: {
				compositeFilter: {
					filters: [
						{
							fieldFilter: {
								field: {
									fieldPath: 'startLocationId',
								},
								op: 'EQUAL',
								value: {
									stringValue: startLocationId,
								},
							},
						},
						{
							fieldFilter: {
								field: {
									fieldPath: 'summary.skuIds',
								},
								op: 'ARRAY_CONTAINS',
								value: {
									stringValue: skuId,
								},
							},
						},
						{
							fieldFilter: {
								field: {
									fieldPath: 'purchaseType',
								},
								op: 'EQUAL',
								value: {
									stringValue: PurchaseTypes.sales,
								},
							},
						},
						{
							unaryFilter: {
								field: {
									fieldPath: 'fulfillmentDate',
								},
								op: 'IS_NULL',
							},
						},
						{
							unaryFilter: {
								field: {
									fieldPath: 'endDateReturned',
								},
								op: 'IS_NULL',
							},
						},
					],
					op: 'AND',
				},
			},
		},
	});

	return parseFirestoreQueryResponse(response) as OrderProductAvailability[];
};
