import { api } from 'common/frontend/api';
import { Category } from 'common/types';

export let removeCategoriesListener: () => void = () => undefined;

export const addCategoriesListener = (
	shopId: string,
	callback: (categories: Category[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const shopCategoriesRef = api()
		.productCategories.get.where('shopId', '==', shopId)
		.orderBy('orderIndex', 'asc');
	removeCategoriesListener = shopCategoriesRef.onSnapshot(async (categories) => {
		callback(categories);
	}, errorCallback || undefined);
};
