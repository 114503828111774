import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';

interface ImageCarouselProps {
	images: string[];
	title?: string;
	t: TFunction;
}

const ImageCarousel = (props: ImageCarouselProps) => {
	const { images, title, t } = props;
	const [currentImageIdx, _] = React.useState(0);

	const currentImage = images[currentImageIdx];

	return (
		<Stack sx={{ height: '100%' }} alignItems="stretch">
			{currentImage ? (
				<Box
					component="img"
					sx={{
						width: '100%',
					}}
					alt={title}
					src={currentImage}
				/>
			) : (
				<Box
					sx={{
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.12)',
						borderRadius: 1,
					}}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Typography color="text.secondary">
						{t('common:listings.previewImagePlaceholder', 'Your Listing preview')}
					</Typography>
				</Box>
			)}
		</Stack>
	);
};

export default ImageCarousel;
