import { notUndefined } from 'common/utils/common';

import { ItemSkusApi, ItemSkusDb } from './type';
import { isItemSkusSingleDb } from './utils';

export const toApi = (data: ItemSkusDb): ItemSkusApi => {
	const base = {
		units: data.units,
		skuId: data.skuId,
	};
	if (isItemSkusSingleDb(data)) {
		return {
			...base,
			type: 'single',
			itemCodes: data.items.map((item) => item.code ?? undefined).filter(notUndefined),
		};
	} else {
		return {
			...base,
			type: 'bulk',
		};
	}
};
