import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	Box,
	Button,
	FormControl,
	GlobalStyles,
	Grid,
	Hidden,
	Link,
	TextField,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FacebookAuthProvider, GoogleAuthProvider, getAdditionalUserInfo } from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc';
import { RiFacebookCircleFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import RentleLogo from 'common/components/RentleLogo';
import AutoCompleteCountryPicker from 'common/components/pickers/AutoCompleteCountryPicker';
import { Api } from 'common/core-api';
import { Callable } from 'common/frontend/callable';
import {
	User,
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	signInWithPopup,
	signOut,
} from 'common/frontend/firebase/auth';
import useFormField, { Form, resetFormErrors, validateForm } from 'common/hooks/useFormField';
import { BLACK_LISTED_COUNTRIES, CountryCode } from 'common/modules/atoms/countries';
import countryList from 'common/services/countryCodes.json';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { isDevEnv, isRentleEmail, switchUnreachable } from 'common/utils/common';
import { getQueryParam } from 'common/utils/frontUtils';
import * as UserActions from 'actions/UserActions';
import { Banner } from 'components/Banners';
import Spinner from 'components/Spinner';
import { useAnalytics } from 'hooks/useAnalytics';
import { useFeatureValue } from 'hooks/useGrowthbook';
import * as ViewSelectors from 'selectors/ViewSelectors';
import history from 'services/history';
import i18n, { Trans, useTranslation } from 'services/localization';
import { validateCountry, validateEmail, validatePassword } from 'services/utils';
import { getCtaContent } from 'utils/registerUtils';
import { Routes, push } from 'routing';

import { useRegistration } from '../useRegistration';
import { AuthPartner, AuthProvider, EXTERNAL_AUTH_PARTNERS, getAffiliateId } from '../utils';
import CtaContentCarousel from './components/CtaContentCarousel';
import CtaContentWithIcon from './components/CtaContentWithIcon';
import CtaTrustedBy from './components/CtaTrustedBy';
import { EmailSignup } from './components/EmailSignup';
import { PartnerAuthDoneDialog } from './components/PartnerAuthDoneDialog';
import { TermsAcceptanceText } from './components/TermsAcceptanceText';

export type RegistrationForm = {
	email: string;
	password: string;
	countryCode: string;
};

const SIGN_IN_METHOD_PARAM = 'signInMethod';

const getAuthenticationPartner = () => {
	const partnerQueryParam = getQueryParam('partner');
	return !!partnerQueryParam && EXTERNAL_AUTH_PARTNERS.includes(partnerQueryParam as AuthPartner)
		? partnerQueryParam
		: undefined;
};

const Register = () => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const signInMethodParam = getQueryParam(SIGN_IN_METHOD_PARAM);
	const { handleRegister } = useRegistration();
	const { logRegisterActionEvent } = useAnalytics();
	const isAuthenticated = useSelector(ViewSelectors.isAuthenticated);
	const [loading, setLoading] = useState<AuthProvider | null>(null);
	const [countryLoaded, setCountryLoaded] = useState<boolean>(false);
	const [error, setError] = useState<string | null | JSX.Element>(null);
	const [promotionCode, setPromotionCode] = useState<string | undefined>();
	const theme = useTheme();
	const isGrowthbookInitialized = useSelector(ViewSelectors.isGrowthbookInitialized);
	const signupVersion = useFeatureValue('signup-page-version', 'V0');
	const authenticationPartner = getAuthenticationPartner();
	const isPartnerAuthentication = !!authenticationPartner;
	const [partnerAuthStatus, setPartnerAuthStatus] = useState<
		'success' | 'error' | 'pendingAuthentication' | null
	>(null);
	const isEmailSignUpSelected = signInMethodParam === 'email';
	const showSocialLogins = isDevEnv() || getQueryParam('social') === 'true';

	const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
	const { t } = useTranslation();

	const form: Form<RegistrationForm> = {
		email: useFormField<string>('', (email) => validateEmail(email, t)),
		password: useFormField<string>('', (password) => validatePassword(password, t)),
		countryCode: useFormField<string>('', (country) => validateCountry(country, t)),
	};

	const affiliateId = useMemo(() => getAffiliateId(), []);

	const ctaContent = useMemo(() => getCtaContent(signupVersion), [signupVersion]);

	useEffect(() => {
		const getCountryFromIP = async () => {
			if (!!form.countryCode.value || countryLoaded) return;
			setCountryLoaded(true);
			try {
				const { country } = await Callable.users.getUserInfoFromIP();
				if (!!country && countryList.some((c) => c.code === country)) {
					form.countryCode.handleChange(country);
				}
			} catch (err) {}
		};
		getCountryFromIP();
	}, [form.countryCode, countryLoaded]);

	useEffect(() => {
		dispatch(UserActions.logout());
	}, [dispatch]);

	const handlePartnerAuthentication = useCallback(async () => {
		if (!isAuthenticated) {
			setPartnerAuthStatus('pendingAuthentication');
			return;
		}
		let registrationError = false;
		const { oauthCode, instanceId } = getPartnerAuthProps();
		if (!oauthCode || !instanceId || !authenticationPartner) {
			registrationError = true;
		} else {
			try {
				await Api.oauth.connect(authenticationPartner, {
					code: oauthCode,
					instanceId,
				});
			} catch (e) {
				errorHandler.report(e);
				registrationError = true;
			}
		}
		setPartnerAuthStatus(registrationError ? 'error' : 'success');
	}, [isAuthenticated, authenticationPartner]);

	useEffect(() => {
		if (isAuthenticated && partnerAuthStatus === 'pendingAuthentication') {
			setPartnerAuthStatus(null);
			handlePartnerAuthentication();
		}
	}, [handlePartnerAuthentication, isAuthenticated, partnerAuthStatus]);

	const immediateCountryValidation = (countryCode: string) => {
		if (BLACK_LISTED_COUNTRIES.includes(countryCode)) {
			return i18n.t('register.notAvailableInCountry', 'Rentle is not available in your country');
		}

		return;
	};

	const getPartnerAuthProps = () => {
		const oauthCode = getQueryParam('code');
		const instanceId = getQueryParam('instanceId');
		return {
			oauthCode,
			instanceId,
		};
	};

	const getEmailExistsError = () => {
		return (
			<Typography variant="body2" color="secondary">
				<Trans
					i18nKey="register.emailExists"
					defaults="Looks like you already have an account with that email address. Please <loginLink>log in</loginLink> instead."
					components={{
						loginLink: (
							<Link
								sx={{
									color: (theme) => theme.palette.primary.main,
									textDecoration: 'none',
								}}
								href={Routes.LOGIN}
							>
								{t('common:actions.log_in', 'Log in').toLocaleLowerCase()}
							</Link>
						),
					}}
				/>
			</Typography>
		);
	};

	const getAuthError = (error: any): { success: false; error: string | JSX.Element } => {
		let errorText: string | JSX.Element = t(
			'common:errors.somethingWentWrong',
			'Something went wrong - please try again.',
		);
		const emailInUseCodes = [
			'auth/email-already-in-use',
			'auth/account-exists-with-different-credential',
		];
		if (emailInUseCodes.includes(error?.code)) {
			errorText = getEmailExistsError();
		}
		return { success: false, error: errorText };
	};

	const createUser = async (
		args:
			| { provider: 'email_password'; email: string; password: string }
			| { provider: Exclude<AuthProvider, 'email_password'> },
	): Promise<{ success: true; user: User } | { success: false; error: string | JSX.Element }> => {
		const provider = args.provider;
		const registerUser = () => {
			switch (provider) {
				case 'email_password': {
					return createUserWithEmailAndPassword(args.email, args.password);
				}
				case 'google': {
					const provider = new GoogleAuthProvider();
					provider.setCustomParameters({
						prompt: 'select_account',
					});
					return signInWithPopup(provider);
				}
				case 'facebook': {
					const provider = new FacebookAuthProvider();
					provider.setCustomParameters({
						display: 'popup',
					});
					return signInWithPopup(provider);
				}
				default: {
					return switchUnreachable(provider);
				}
			}
		};
		try {
			const result = await registerUser();
			const additionalUserInfo = getAdditionalUserInfo(result);
			// If logged in user is not a new user, we log them out and return an error
			if (!additionalUserInfo?.isNewUser) {
				signOut();
				return {
					success: false,
					error: getEmailExistsError(),
				};
			}
			return { success: true, user: result.user };
		} catch (e) {
			errorHandler.report(e);
			return getAuthError(e);
		}
	};

	const handleSubmit = async (provider: AuthProvider) => {
		setError(null);
		resetFormErrors(form);
		const baseFieldsToValidate: (keyof RegistrationForm)[] = ['countryCode'];
		const { errors } = validateForm(
			form,
			provider === 'email_password'
				? [...baseFieldsToValidate, 'email', 'password']
				: baseFieldsToValidate,
		);
		if (!!errors.length) return;

		setLoading(provider);

		let user: User | undefined;
		let error: string | undefined | JSX.Element;
		const result = await createUser(
			provider === 'email_password'
				? { provider, email: form.email.value, password: form.password.value }
				: { provider },
		);
		if (result.success) {
			user = result.user;
		} else {
			error = result.error;
		}
		if (error || !user?.email) {
			const errorMessage =
				error ?? t('common:errors.somethingWentWrong', 'Something went wrong - please try again.');
			setError(errorMessage);
			setLoading(null);
			return;
		}

		const register = handleRegister({
			uid: user.uid,
			email: user.email,
			countryCode: form.countryCode.value as CountryCode,
			affiliateId: !isRentleEmail(user.email) && affiliateId ? affiliateId : undefined,
			promotionCode,
		});

		if (isPartnerAuthentication) {
			try {
				await register;
			} catch (e) {
				setError(t('common:errors.somethingWentWrong', 'Something went wrong - please try again.'));
				setLoading(null);
				return;
			}
			await handlePartnerAuthentication();
			return;
		}
		setLoading(null);
		push(Routes.REGISTER_GET_STARTED);
	};

	const handleEmailClick = async () => {
		setError(null);
		const { errors } = validateForm(form, ['email', 'countryCode']);
		if (!!errors.length) return;
		const signInMethods = await fetchSignInMethodsForEmail(form.email.value);
		if (!!signInMethods.length) {
			setError(getEmailExistsError());
			return;
		}
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set(SIGN_IN_METHOD_PARAM, 'email');
		history.push({
			pathname: window.location.pathname,
			search: queryParams.toString(),
		});
	};

	const renderLogo = () => {
		return <RentleLogo width={120} height={38} type="color" />;
	};

	const renderCtaContent = () => {
		switch (signupVersion) {
			case 'V1':
				return <CtaContentCarousel content={ctaContent} />;
			case 'V0':
			default:
				return <CtaContentWithIcon content={ctaContent} />;
		}
	};

	return !isGrowthbookInitialized ? (
		<Spinner />
	) : (
		<>
			<GlobalStyles
				styles={(theme) => ({ body: { backgroundColor: theme.palette.common.white } })}
			/>
			<Grid container sx={{ height: '100%' }}>
				<Grid
					item
					xs={12}
					lg={isPartnerAuthentication ? 12 : 6}
					sx={{
						px: 3,
						backgroundColor: (theme) =>
							signupVersion === 'V1' ? theme.palette.background.paper : undefined,
					}}
				>
					<Box
						sx={{
							pb: 2,
							maxWidth: 355,
							margin: '0 auto',
							width: '100%',
							pt: {
								xs: 5.25,
								lg: 7,
							},
						}}
					>
						{renderLogo()}
						<Box mt={2} mb={5}>
							<Typography
								variant="h5"
								gutterBottom
								sx={{
									fontSize: '2.0rem',
								}}
							>
								{isPartnerAuthentication
									? t('register.setupAccount', 'Set up your account')
									: t('register.title', 'Commerce on repeat')}
							</Typography>
						</Box>
						{!!error && (
							<Box my={3}>
								<Banner variant="danger" description={error} />
							</Box>
						)}
						{isEmailSignUpSelected ? (
							<EmailSignup
								handleSubmit={() => handleSubmit('email_password')}
								loading={!!loading}
								isPartnerAuthentication={isPartnerAuthentication}
								promotionCode={promotionCode}
								setPromotionCode={setPromotionCode}
								form={form}
							/>
						) : (
							<>
								<FormControl fullWidth>
									<AutoCompleteCountryPicker
										label={t('register.countrySelect', 'Where is your business located?')}
										id="user-country-select"
										value={form.countryCode.value}
										onChange={(country: string) => {
											form.countryCode.handleChange(country);
											logRegisterActionEvent('country', country);
										}}
										error={
											immediateCountryValidation(form.countryCode.value) || form.countryCode.error
										}
										variant="filled"
										size="medium"
										InputProps={{
											classes: {
												root: classes.countrySelect,
											},
										}}
									/>
								</FormControl>
								<Box mt={6} />
								{showSocialLogins ? (
									<>
										<TextField
											sx={{ textTransform: 'none', borderRadius: '8px' }}
											variant="outlined"
											label={t('common:form.emailAddress', 'Email address')}
											placeholder={t('register.emailPlaceholder', 'john.doe@domain.com')}
											name="email"
											autoComplete="email"
											value={form.email.value}
											onChange={form.email.handleChange}
											onBlur={() => logRegisterActionEvent('email')}
											margin="none"
											type="text"
											error={Boolean(form.email.error)}
											helperText={form.email.error}
											fullWidth
											autoFocus
										/>
										<Box mb={2} />
										<Button
											sx={{
												background: theme.palette.common.black,
												'&:hover': {
													background: (theme) => theme.palette.secondary.dark,
												},
												borderRadius: '8px',
												textTransform: 'none',
											}}
											disabled={!!loading}
											onClick={handleEmailClick}
											variant="contained"
											fullWidth
										>
											{loading === 'email_password' ? (
												<Spinner padding={8} thickness={4} color="inherit" />
											) : (
												t('register.continueWithEmail', 'Continue with email')
											)}
										</Button>
										<Box mt={2} />
										<Button
											sx={{ textTransform: 'none', borderRadius: '8px' }}
											disabled={!!loading}
											onClick={() => handleSubmit('google')}
											variant="outlined"
											fullWidth
											startIcon={<FcGoogle />}
										>
											{'Sign up with Google'}
										</Button>
										<Box mt={2} />
										<Button
											sx={{ textTransform: 'none', borderRadius: '8px' }}
											disabled={!!loading}
											onClick={() => handleSubmit('facebook')}
											variant="outlined"
											fullWidth
											startIcon={<RiFacebookCircleFill color="#1877F2" />}
										>
											{'Sign up with Facebook'}
										</Button>
										<Box mt={2} />
										<TermsAcceptanceText />
									</>
								) : (
									<EmailSignup
										handleSubmit={() => handleSubmit('email_password')}
										loading={!!loading}
										isPartnerAuthentication={isPartnerAuthentication}
										promotionCode={promotionCode}
										setPromotionCode={setPromotionCode}
										form={form}
									/>
								)}
							</>
						)}
						{isPartnerAuthentication ? null : (
							<Typography mt={5} variant="body2" color="secondary">
								{
									<Trans
										i18nKey="register.alreadyRegistered"
										defaults="Already using Rentle? <links>Log in</links>"
										components={{
											links: (
												<Link
													sx={{
														color: (theme) => theme.palette.primary.main,
														textDecoration: 'none',
													}}
													href={Routes.LOGIN}
												>
													{t('common:actions.log_in', 'Log in')}
												</Link>
											),
										}}
									/>
								}
							</Typography>
						)}
					</Box>
					{signupVersion === 'V1' && !isLargeScreen && (
						<Box mt={3} mb={2}>
							<CtaTrustedBy />
						</Box>
					)}
				</Grid>
				{!isPartnerAuthentication && <Hidden lgDown>{renderCtaContent()}</Hidden>}
			</Grid>
			{isPartnerAuthentication &&
				(partnerAuthStatus === 'success' || partnerAuthStatus === 'error') && (
					<PartnerAuthDoneDialog
						variant={partnerAuthStatus}
						attemptAutoClose={partnerAuthStatus === 'success'}
					/>
				)}
		</>
	);
};

const useStyles = makeStyles()((theme) => ({
	countrySelect: {
		backgroundColor: theme.palette.background.default,
		borderRadius: theme.spacing(1),
		// We need important to force also :hover to not have border either
		'&:before': {
			borderBottom: 'none !important',
		},
		'&:after': {
			borderBottom: 'none !important',
		},
	},
}));

export default Register;
