import * as dbPath from 'common/api/db/paths';
import { OrderSubscriptionPayment } from 'common/modules/subscriptions/types';

import { DbRefs } from '../types';
import { buildQuery, createBasicDbOperations } from '../utils';

export const getOrderSubscriptionPayments = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.orderSubscriptionPayments;
	const dataUpdater = undefined;
	const query = buildQuery<OrderSubscriptionPayment>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<OrderSubscriptionPayment>(collectionPath, dbRefs, dataUpdater),
		byOrderId: (orderId: string) => query.where('orderId', '==', orderId),
	};
};
