import Stripe from 'stripe';

import { CallableCreator } from '..';

interface OnboardRequest {
	origin: string;
	shopId: string;
	accountId?: string;
}

interface OnboardResult {
	accountId?: string;
	onboardingUrl?: string;
	error?: string;
}

export const stripe = (createCallable: CallableCreator) => ({
	onboard: createCallable<OnboardRequest, OnboardResult>('paymentAccounts-stripe-onboard'),
	get: createCallable<{ accountId: string; shopId: string }, Stripe.Account>(
		'paymentAccounts-stripe-get',
	),
	getAccountId: createCallable<{ shopId: string }, string | null>(
		'paymentAccounts-stripe-getAccountId',
	),
	createSetupIntent: createCallable<
		{ orderId: string; shopId: string },
		{ clientSecret: string; accountId: string }
	>('paymentAccounts-stripe-createSetupIntent'),
});
