import React from 'react';

import {
	Box,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link as RRLink } from 'react-router-dom';

import { useTranslation } from 'services/localization';
import { Routes } from 'routing';

type TodayProps = {
	amountOfBookings: number;
	hasUserflowChecklist: boolean;
};

const TodaySection = (props: TodayProps) => {
	const { amountOfBookings, hasUserflowChecklist } = props;
	const { t } = useTranslation();
	const singular = amountOfBookings === 1;

	const renderItem = (label: string, route: string) => {
		return (
			<Paper sx={{ mt: 2 }} elevation={1}>
				<List disablePadding>
					<RRLink to={route}>
						<ListItem
							sx={{
								p: 2,
								'&:hover': {
									backgroundColor: (theme) => theme.palette.action.hover,
									cursor: 'pointer',
								},
							}}
						>
							<ListItemText primary={label} />
							<ListItemSecondaryAction>
								<RiArrowRightSLine size={24} />
							</ListItemSecondaryAction>
						</ListItem>
					</RRLink>
				</List>
			</Paper>
		);
	};

	return (
		<Box mb={4}>
			<Box mb={4}>
				<Typography variant="h5">
					{t('homeView.header', {
						date: moment().format('dddd MMMM Do'),
						defaultValue: 'Today, {{date}}',
					})}
				</Typography>
				<Typography variant="body1" color="secondary">
					{singular
						? t('homeView.upcomingBookingSingular2', {
								bookings: amountOfBookings,
								defaultValue: '{{bookings}} upcoming order',
						  })
						: t('homeView.upcomingBookings2', {
								bookings: amountOfBookings,
								defaultValue: '{{bookings}} upcoming orders',
						  })}
				</Typography>
			</Box>
			{hasUserflowChecklist ? (
				<>
					{renderItem(t('homeView.manageProducts', 'Manage products'), Routes.CATALOG)}
					{renderItem(t('homeView.manageInventory', 'Manage inventory'), Routes.INVENTORY)}
					{renderItem(t('homeView.viewBookings2', 'View orders'), Routes.Bookings())}
					{renderItem(
						t('homeView.discoverAdditionalFeatures', 'Discover additional features'),
						Routes.ADD_ONS_STORE,
					)}
				</>
			) : (
				<>
					{renderItem(t('homeView.viewBookings2', 'View orders'), Routes.Bookings())}
					{renderItem(t('homeView.manageProducts', 'Manage products'), Routes.CATALOG)}
					{renderItem(t('homeView.manageInventory', 'Manage inventory'), Routes.INVENTORY)}
					{renderItem(t('homeView.manageAddOns', 'Manage add-ons'), Routes.ADD_ONS_STORE)}
				</>
			)}
		</Box>
	);
};

export default TodaySection;
