import React, { useState } from 'react';

import { Box, Theme, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { StaticContext } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Api } from 'common/core-api';
import { Callable } from 'common/frontend/callable';
import { isDevEnv } from 'common/utils/common';
import AuthContainer from 'components/AuthContainer';
import Spinner from 'components/Spinner';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes } from 'routing';

type LocationState = {
	from: { [key: string]: string };
};

type Props = RouteComponentProps<{}, StaticContext, LocationState>;

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>;

const PasswordReset = (props: Props) => {
	const searchParmas = new URLSearchParams(props.location.search);
	const [email, setEmail] = useState<string>(searchParmas.get('email') || '');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [completed, setCompleted] = useState<boolean>(false);

	const { classes, cx } = useStyles();
	const { t } = useTranslation();

	const handleEmailChange = (event: InputEvent) => {
		setEmail(event.target.value);
	};

	const handleResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!email) {
			setError(t('resetPasswordDialog.invalid', 'Please enter a valid email address.'));
			return;
		}
		try {
			setLoading(true);
			// Temporarily we only use new Core API in DEV environment, and fallback to Callable in production
			if (isDevEnv()) {
				await Api.users.resetPassword({ email });
			} else {
				await Callable.users.sendPasswordResetLink({ email });
			}
			setLoading(false);
			setCompleted(true);
		} catch (e) {}
	};

	return (
		<AuthContainer title={t('resetPasswordDialog.resetHeader', 'Reset password')}>
			{!completed ? (
				<form onSubmit={handleResetPassword}>
					<p>
						{t(
							'resetPasswordDialog.resetPasswordPrompt',
							"Type in the email address you use to sign in to Rentle, and we'll send you an email with instructions on how to reset your password.",
						)}
					</p>
					<TextField
						id="email"
						variant="outlined"
						label={t('common:form.email')}
						name="email"
						className={classes.textField}
						value={email}
						onChange={handleEmailChange}
						margin="normal"
						type="text"
						autoFocus
						inputProps={{
							style: {
								paddingRight: '32px',
							},
						}}
					/>

					{!!error && (
						<Box my={2} display="block">
							<Typography color="error" variant="body2" gutterBottom>
								{error}
							</Typography>
						</Box>
					)}
					<Box my={4}>
						<Button
							className={classes.bgBlack}
							variant="contained"
							type="submit"
							disabled={loading}
							data-qa="send"
						>
							{loading ? <Spinner padding={8} thickness={4} /> : t('common:actions.send')}
						</Button>
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Link className={cx(classes.green, classes.uppercase, classes.bold)} to={Routes.LOGIN}>
							{t('common:actions.back')}
						</Link>
					</Box>
				</form>
			) : (
				<>
					<p>
						{t(
							'resetPasswordDialog.resetPasswordDone',
							'Thank you! You should receive an email with instructions for resetting your password within a few minutes.',
						)}
					</p>
					<Box my={4}>
						<Button
							href={Routes.LOGIN}
							className={classes.bgBlack}
							variant="contained"
							data-qa="send"
						>
							{t('common:actions.back')}
						</Button>
					</Box>
				</>
			)}
		</AuthContainer>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	bgBlack: {
		width: '100%',
		background: theme.palette.common.black,
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},

	textField: {
		justifyContent: 'center',
		display: 'flex',
	},

	green: {
		color: theme.palette.primary.main,
	},

	bold: {
		fontWeight: 'bold',
	},

	uppercase: {
		textTransform: 'uppercase',
	},
}));

export default PasswordReset;
