import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { PartnerAuth } from 'common/modules/partnerAuth/types';

import { createBasicDbOperations } from '../utils';

export const getPartnerAuthQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.partnerAuth;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<PartnerAuth>(collectionPath, dbRefs, dataUpdater),
	};
};
