import React from 'react';

import { Box, Button, Container, Grid, Stack } from '@mui/material';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { useTranslation } from 'services/localization';

export interface Props {
	mainCTA: {
		alignment: 'center' | 'right';
		component: React.ReactNode;
	};
	options?: {
		back?: {
			onClick: () => void;
		};
		skip?: {
			onClick: () => void;
		};
		topContent?: {
			component: React.ReactNode;
		};
	};
}

const RegistrationFooter = (props: Props) => {
	const { options, mainCTA } = props;
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0,
				py: 3,
				backgroundColor: (theme) => theme.palette.background.paper,
				boxShadow: (theme) => theme.shadows[6],
				zIndex: 10,
			}}
		>
			<Container sx={{ display: 'flex', flexDirection: 'column' }}>
				{!!options?.topContent && options.topContent.component}
				<Grid
					container
					justifyContent={mainCTA.alignment === 'center' ? 'center' : 'space-between'}
					alignItems="flex-end"
				>
					{!!options?.back && (
						<Grid
							item
							xs={mainCTA.alignment === 'center' ? 2 : undefined}
							sx={{ flex: mainCTA.alignment === 'center' ? 1 : undefined }}
						>
							<Button
								variant="text"
								onClick={options.back.onClick}
								startIcon={<RiArrowLeftSLine />}
								sx={{ color: (theme) => theme.palette.common.black, pl: 0 }}
							>
								{t('common:actions.back', 'Back')}
							</Button>
						</Grid>
					)}
					<Grid item></Grid>
					<Grid
						item
						display="flex"
						justifyContent={mainCTA.alignment === 'center' ? 'center' : 'flex-end'}
						sx={{ flex: mainCTA.alignment === 'center' ? 1 : undefined }}
					>
						<Stack direction="row" spacing={2} width="100%" justifyContent="center">
							{!!options?.skip && (
								<Button
									sx={{
										color: (theme) => theme.palette.common.black,
									}}
									variant="text"
									onClick={options.skip.onClick}
								>
									{t('common:actions.skip', 'Skip')}
								</Button>
							)}
							{mainCTA.component}
						</Stack>
					</Grid>
					{mainCTA.alignment === 'center' && !!options?.back && (
						<Grid item xs={2} sx={{ flex: 1 }} />
					)}
				</Grid>
			</Container>
		</Box>
	);
};

export default RegistrationFooter;
