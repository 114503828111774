import { ItemType } from 'common/models/_atoms/ItemType';
import { LocaleField, LocaleFieldDb } from 'common/models/_atoms/LocaleField';
import { notUndefined } from 'common/utils/common';

import { VariantApi, VariantDb, VariantPropertyDb } from './types';

export const toApi = (
	db: VariantDb,
	options: { variantProperties: VariantPropertyDb[] },
): VariantApi => {
	return {
		id: db.id,
		properties: options.variantProperties
			.map((p) => {
				const value = db.values[p.id];
				if (!value) return undefined;
				return {
					propertyId: p.id,
					name: p.name,
					value,
				};
			})
			.filter(notUndefined),
		sales: !!db.sales
			? {
					enabled: db.sales.enabled,
					priceOverride: db.sales.priceOverride ?? undefined,
			  }
			: undefined,
		rentals: !!db.rentals
			? {
					enabled: db.rentals.enabled,
					priceIncrease: db.rentals.priceIncrease ?? undefined,
			  }
			: undefined,
		inventory: Object.values(db.stockSources ?? {}).map((source) => {
			return source;
		}),
	};
};

export const toDb = (api: VariantApi): VariantDb => {
	return {
		id: api.id,
		values: api.properties.reduce((acc, value) => {
			return {
				...acc,
				[value.propertyId]: LocaleField.toApi(value.value),
			};
		}, {} as Record<string, LocaleFieldDb>),
		sales: !!api.sales
			? {
					enabled: api.sales.enabled,
					priceOverride: api.sales.priceOverride ?? null,
			  }
			: null,
		rentals: !!api.rentals
			? {
					enabled: api.rentals.enabled,
					priceIncrease: api.rentals.priceIncrease ?? null,
			  }
			: null,
		stockSources: (api.inventory ?? []).reduce((acc, source, index) => {
			return {
				...acc,
				[index]: source.map((s) => ({
					...s,
					type: ItemType.toDb(s.type, { single: 'item' }),
				})),
			};
		}, {} as VariantDb['stockSources']),
	};
};
