import { createSelector } from '@reduxjs/toolkit';

import { isSubscriptionPlan } from 'common/modules/plans';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { ReduxState } from 'services/types';

export const pendingRentalLinks = (state: ReduxState) => state.view.pendingRentalLinks;

export const sideBarOpen = (state: ReduxState) => state.view.sideBarOpen;

export const rentalsActiveTab = (state: ReduxState) => state.view.rentalsActiveTab;

export const lang = (state: ReduxState) => state.lang;

export const upgradeModalOpen = (state: ReduxState) => state.view.upgradeModalOpen;

export const mobileRentalSideBarOpen = (state: ReduxState) => state.view.mobileRentalSideBarOpen;

export const bookingsViewLocationType = (state: ReduxState) => state.view.bookingsViewLocationType;

export const isDevPanelOpen = (state: ReduxState) => state.view.devPanelOpen;

export const ordersViewLocationType = (state: ReduxState) => state.view.ordersViewLocationType;

export const isNewClientUpdate = (state: ReduxState) => state.view.newClientUpdate;

export const newRentalStartDate = (state: ReduxState) => state.view.startDate;
export const newRentalEndDate = (state: ReduxState) => state.view.endDate;
export const newRentalToggled = (state: ReduxState) => state.view.rentNowToggle;
export const newRentalDuration = (state: ReduxState) => state.view.duration;

export const showRentalViewFormErrors = (state: ReduxState) => state.view.showRentalViewFormErrors;

export const installedAddOnModalState = (state: ReduxState) => state.view.installedAddOnModalState;
export const addOnModalState = (state: ReduxState) => state.view.addOnModalState;

export const showPaymentCardBanner = createSelector(
	ShopSelectors.shopHasPrimaryPaymentMethod,
	ShopSelectors.shopBilling,
	ShopSelectors.shopFeatures,
	ShopSelectors.adyenPaymentMethodsLoading,
	(hasPaymentMethod, billing, features, loading) => {
		const purchasedPlan = features?.plan.plan;
		if (loading || !purchasedPlan) return false;

		return (
			isSubscriptionPlan(purchasedPlan) &&
			billing?.billingMethod === 'credit-card' &&
			!hasPaymentMethod
		);
	},
);

export const dataGridStates = (state: ReduxState) => state.view.dataGridStates;
export const isAuthenticated = (state: ReduxState) => state.view.authState.isAuthenticated;
export const isAuthLoading = (state: ReduxState) => state.view.authState.loading;

export const isEarnCreditsModalOpen = (state: ReduxState) => state.view.earnCreditsModalOpen;

export const isSwitchToNewPlansModalOpen = (state: ReduxState) =>
	state.view.switchToNewPlansModalOpen;

export const isGrowthbookInitialized = (state: ReduxState) => state.view.growthbookInitialized;
