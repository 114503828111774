import { httpsCallable } from 'common/frontend/firebase/functions';
import countryCodes from 'common/services/countryCodes.json';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	AccountHolderApi,
	AccountHolderBalanceApi,
	AccountHolderTransactionListResponse,
	AdyenPaymentResponse,
	GetAccountHolderData,
	OnlinePaymentRequest,
} from 'common/types/adyen';

export const getAccountHolder = async (data: GetAccountHolderData): Promise<AccountHolderApi> => {
	const getAccountHolderCallable = httpsCallable<any, AccountHolderApi>('getAccountHolder');
	try {
		const response = await getAccountHolderCallable(data);
		let result = response.data;
		if ((result.accountHolderDetails as any).phoneNumber) {
			const { phoneCountryCode, phoneNumber } = (result.accountHolderDetails as any).phoneNumber;
			const phoneCountryCodeObject = countryCodes.find(
				(countryCode) => countryCode.code === phoneCountryCode,
			);
			const phoneDialCode = (phoneCountryCodeObject && phoneCountryCodeObject.dial_code) || '';
			const fullPhoneNumber = phoneDialCode + phoneNumber;
			if (!result.accountHolderDetails.fullPhoneNumber) {
				result = {
					...result,
					accountHolderDetails: {
						...result.accountHolderDetails,
						fullPhoneNumber: fullPhoneNumber,
					},
				};
			}
			delete (result.accountHolderDetails as any).phoneNumber;
		}
		return result;
	} catch (e) {
		errorHandler.report(e);
		throw e;
	}
};

export const getAccountHolderBalance = async (data: {
	accountHolderCode: string;
}): Promise<AccountHolderBalanceApi> => {
	const getAccountHolderBalanceCallable = httpsCallable<any, AccountHolderBalanceApi>(
		'getAccountHolderBalance',
	);
	try {
		const response = await getAccountHolderBalanceCallable(data);
		let result = response.data;
		return result;
	} catch (e) {
		errorHandler.report(e);
		throw e;
	}
};

export const getAccountHolderTransactionsList = async (data: {
	accountHolderCode: string;
	page?: number;
	includedStatuses?: string[];
}): Promise<AccountHolderTransactionListResponse> => {
	const getAccountHolderTransactionListCallable = httpsCallable<
		any,
		AccountHolderTransactionListResponse
	>('getAccountHolderTransactionList');
	try {
		const response = await getAccountHolderTransactionListCallable(data);
		let result = response.data;
		return result;
	} catch (e) {
		errorHandler.report(e);
		throw e;
	}
};

export const makePayment = async (data: OnlinePaymentRequest): Promise<AdyenPaymentResponse> => {
	const makePaymentCallable = httpsCallable<any, AdyenPaymentResponse>('makePayment');
	try {
		const response = await makePaymentCallable(data);
		return response.data;
	} catch (e) {
		errorHandler.report(e);
		throw e;
	}
};
