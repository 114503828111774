import { TFunction } from 'react-i18next';

import { api } from 'common/frontend/api';
import { BatchManager } from 'common/frontend/firebase/firestore';
import { BLACK_LISTED_COUNTRIES } from 'common/modules/atoms/countries';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	OrderObject,
	OrderProduct,
	ResponsiblePerson,
	ResponsiblePersonDetails,
	Shopper,
} from 'common/types';
import { PaymentMethod } from 'common/types/common';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { isValidEmail } from 'common/utils/validation';

export const getProductCodes = (products: OrderProduct[]) => {
	const productCodes = [];
	for (const product of products) {
		if (product.productCode) {
			productCodes.push(product.productCode);
		}
		if (product.setProducts) {
			for (const setProduct of product.setProducts) {
				if (setProduct.productCode) {
					productCodes.push(setProduct.productCode);
				}
			}
		}
	}
	return productCodes.filter((code) => code !== '');
};

export const updateActiveRentalToDb = async (rental: OrderObject) => {
	const { rentalInfo, shoppers, products } = rental;
	const rentalId = rentalInfo.id;
	try {
		const batchManager = new BatchManager();
		const productCodes = [];
		for (const product of products) {
			if (product.productCode) {
				productCodes.push(product.productCode);
			}
			if (product.setProducts) {
				for (const setProduct of product.setProducts) {
					if (setProduct.productCode) {
						productCodes.push(setProduct.productCode);
					}
				}
			}
		}
		api(batchManager.batch())
			.orders.doc(rentalId)
			.update({
				...rentalInfo,
				rentalProductCodes: productCodes,
			});
		for (const shopper of shoppers) {
			api(batchManager.batch()).orderShoppers.doc(shopper.id).set(shopper, { merge: true });
		}
		for (const product of products) {
			api(batchManager.batch()).orderProducts.doc(product.id).set(product, { merge: true });
		}
		await batchManager.commit();
	} catch (e) {
		errorHandler.report(e);
	}
};

export const getRandomUserId = (): string => {
	return newFirestoreId();
};

export const pageVisibilityApi = () => {
	let hidden: string = '';
	let visibilityChange: string = '';
	if (typeof document.hidden !== 'undefined') {
		// Opera 12.10 and Firefox 18 and later support
		hidden = 'hidden';
		visibilityChange = 'visibilitychange';
	} else if (typeof document['msHidden'] !== 'undefined') {
		hidden = 'msHidden';
		visibilityChange = 'msvisibilitychange';
	} else if (typeof document['webkitHidden'] !== 'undefined') {
		hidden = 'webkitHidden';
		visibilityChange = 'webkitvisibilitychange';
	}

	return { hidden, visibilityChange };
};

export const getResponsiblePersonDetails = (
	responsiblePerson: ResponsiblePerson,
	shoppers: Shopper[],
): ResponsiblePersonDetails | null => {
	if (responsiblePerson.external) {
		return responsiblePerson.person;
	}
	return shoppers.filter((s) => s.id === responsiblePerson.shopperId)[0] || null;
};

export const getDefaultPaymentOptions = (): PaymentMethod[] => {
	return ['CARD_TERMINAL', 'CASH', 'INVOICE'];
};

export const validatePassword = (password: string, t: TFunction): string | undefined => {
	if (!validPassword(password)) {
		return t('common:validation.lengthAtLeastIncludeLettersNumbers', {
			field: t('common:form.password', 'Password'),
			int: '7',
			defaultValue:
				'{{field}} must be at least {{int}} characters, and include both letters and numbers',
		});
	}
	return;
};

export const validPassword = (password: string) => {
	const invalidPwValidationRegexp = /^(.{0,6}|[^0-9]*|[^a-zA-Z]*)$/; // Min. 7 chars, both letters and numbers
	return !invalidPwValidationRegexp.test(password);
};

export const validateEmail = (email: string, t: TFunction) => {
	if (!email) {
		return t('common:validation.cannotBeEmpty', {
			field: t('common:form.emailAddress', 'Email address'),
			defaultValue: '{{field}} cannot be empty',
		}) as string;
	}

	if (!isValidEmail(email)) {
		return t('common:validation.isNotValidEmail');
	}
	return;
};

export const validateShopName = (shopName: string, t: TFunction) => {
	if (!shopName || shopName.length < 3) {
		return t('common:validation.lengthAtLeast', {
			field: t('register.shopName', 'Shop name'),
			int: '3',
			defaultValue: '{{field}} must be at least {{int}} characters',
		}) as string;
	}

	if (shopName.length > 100) {
		return t('common:validation.lengthAtMost', {
			shopName: t('register.shopName', 'Shop name'),
			int: '100',
			defaultValue: '{{shopName}} can be at most {{int}} characters',
		}) as string;
	}

	return;
};

export const validateCountry = (countryCode: string, t: TFunction) => {
	if (!countryCode) {
		return t('register.mustChooseCountry', 'You must choose a country');
	}

	if (BLACK_LISTED_COUNTRIES.includes(countryCode)) {
		return t('register.notAvailableInCountry', 'Rentle is not available in your country');
	}

	return;
};

export const validateTerms = (termsAccepted: boolean, t: TFunction) => {
	if (!termsAccepted) {
		return t(
			'register.mustAcceptTerms',
			'Terms of service must be accepted in order to use the service',
		);
	}

	return;
};

export const getRentalLinkUrl = (linkId: string, lang: string): string => {
	const env = process.env.REACT_APP_ENV;
	const linkBaseUrl = getLinkBaseUrl(env);
	const langParam = lang ? `?lang=${lang}` : '';
	return `${linkBaseUrl}/${linkId}${langParam}`;
};

export const getLinkBaseUrl = (env: string = 'production') => {
	switch (env) {
		case 'local':
			return `http://localhost:3000`;
		case 'development':
			return `https://dev.link.rentle.shop`;
		case 'production':
		default:
			return `https://link.rentle.shop`;
	}
};
