import * as deliveryLocations from './deliveryLocations.queries';
import * as inventoryConfigurations from './inventoryConfigurations.queries';
import * as inventoryEvents from './inventoryEvents.queries';
import * as inventoryItems from './inventoryItems.queries';
import * as inventoryTransfers from './inventoryTransfers.queries';
import * as listings from './listings.queries';
import * as skuItems from './skuItems.queries';
import * as webhooks from './webhooks.queries';

export { queryClient } from './client';

export const queries = {
	deliveryLocations,
	inventoryConfigurations,
	inventoryItems,
	inventoryTransfers,
	skuItems,
	webhooks,
	inventoryEvents,
	listings,
};
