import moment from 'moment-timezone';

import { getInitialLanguage, getMomentLocaleCode } from 'common/locales/utils';
import { adminLanguages } from 'common/locales/utils/constants';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { Languages } from 'common/types';
import {
	StorageKeys,
	Storages,
	getFromStorage,
	getHashParam,
	getQueryParam,
	saveToStorage,
} from 'common/utils/frontUtils';

import { updateI18nLanguage } from './i18n';

export const updateMomentLanguage = async (lang: Languages) => {
	const momentLang = getMomentLocaleCode(lang);
	try {
		// en language bundled, no need to import
		if (lang !== 'en') {
			const { default: locale } = await import(`../../../../common/locales/${lang}/moment.js`);
			moment.updateLocale(momentLang, locale);
		} else {
			moment.locale('en');
		}
	} catch (e) {
		errorHandler.report(e);
	}
};

export const setLanguage = (lang: Languages) => {
	saveToStorage(Storages.LOCAL, StorageKeys.LANG, lang);
	updateI18nLanguage(lang);
	updateMomentLanguage(lang);
};

export const getLanguage = (): Languages => {
	// Check query parameter
	const queryParamLanguage = getQueryParam('lang');
	if (queryParamLanguage && adminLanguages.includes(queryParamLanguage as Languages)) {
		saveToStorage(Storages.SESSION, StorageKeys.LANG, queryParamLanguage);
		return queryParamLanguage as Languages;
	}

	// Check hash
	const hashParamLanguage = getHashParam('lang');
	if (hashParamLanguage && adminLanguages.includes(hashParamLanguage as Languages)) {
		saveToStorage(Storages.SESSION, StorageKeys.LANG, hashParamLanguage);
		return hashParamLanguage as Languages;
	}

	const sessionStorageLang = getFromStorage(Storages.SESSION, StorageKeys.LANG);
	if (sessionStorageLang && adminLanguages.includes(sessionStorageLang as Languages)) {
		return sessionStorageLang as Languages;
	}

	const localStorageLang = getFromStorage(Storages.LOCAL, StorageKeys.LANG);
	if (localStorageLang === 'fi' && adminLanguages.includes(localStorageLang as Languages)) {
		return localStorageLang;
	}

	// Check browser
	let browserLanguage = navigator.language;
	if (navigator.languages && navigator.languages.length) {
		browserLanguage = navigator.languages[0];
	}
	if (
		browserLanguage !== 'undefined' &&
		browserLanguage !== null &&
		browserLanguage !== undefined
	) {
		browserLanguage = browserLanguage.substring(0, 2);
		if (adminLanguages.includes(browserLanguage as Languages)) {
			return browserLanguage as Languages;
		}
	}
	// Default
	return 'en';
};

// From https://gist.github.com/mirontoli/4722797 by @mirontoli
/**
 * @author Phil Teare
 * using wikipedia data
 */

export const getPaymentComponentLocale = (lang: Languages) => {
	const defaultLocale = 'en-US';
	const adyenLocale = adyenComponentLocales.find((locale) => {
		const isoLangCode = locale.split('-')[0];
		return isoLangCode.toLocaleLowerCase() === lang.toLocaleLowerCase();
	});
	return adyenLocale || defaultLocale;
};

const adyenComponentLocales: string[] = [
	'zh-CN',
	'zh-TW',
	'da-DK',
	'nl-NL',
	'en-US',
	'fi-FI',
	'fr-FR',
	'de-DE',
	'it-IT',
	'ja-JP',
	'ko-KR',
	'no-NO',
	'pl-PL',
	'pt-BR',
	'ru-RU',
	'es-ES',
	'sv-SE',
];

export const getInitialAdminLanguage = () => getInitialLanguage(adminLanguages);
