import React from 'react';

import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';

import { useTranslation } from 'services/localization';

export interface Props {
	onClose: () => void;
	onClick: () => void;
	isExpanded: boolean;
}
const EarnCreditsBanner = (props: Props) => {
	const { onClose, onClick, isExpanded } = props;
	const { t } = useTranslation();
	return isExpanded ? (
		<StyledWrapper onClick={onClick}>
			<Stack direction="row" spacing={2}>
				<Box>{'🎁'}</Box>
				<Typography sx={{ fontWeight: 400, mr: 2, width: 110 }} variant="body2">
					{t('earnCredits.modalTitle', 'Earn Rentle Credits')}
				</Typography>
			</Stack>
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
				<IconButton
					sx={{
						color: (theme) => theme.palette.colors.peach.contrastText,
						position: 'absolute',
						top: 0,
						right: 0,
					}}
					onClick={onClose}
				>
					<RiCloseLine size={20} />
				</IconButton>
			</motion.div>
		</StyledWrapper>
	) : (
		<StyledWrapper onClick={onClick}>
			<Stack direction="row" spacing={2}>
				<Box>{'🎁'}</Box>
			</Stack>
		</StyledWrapper>
	);
};

const StyledWrapper = styled(Stack)(({ theme }) => ({
	backgroundColor: theme.palette.colors.peach.light,
	padding: theme.spacing(1.5),
	height: '100%',
	cursor: 'pointer',
	position: 'relative',
	flexDirection: 'row',
	alignItems: 'flex-start',
	borderRadius: '4px',
}));

export default EarnCreditsBanner;
