import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

import { AddressApi } from './types';

export const zAddressApi = withOpenApi(
	z.object({
		address: z.string(),
		zipCode: z.string().optional(),
		city: z.string().optional(),
		country: z.string().optional(),
		state: z.string().optional(),
		details: z.string().optional(),
	}),
	{
		fields: {
			address: 'Street address.',
			zipCode: 'Zip code.',
			city: 'City.',
			country: 'Country.',
			state: 'State.',
			details: 'Additional address details.',
		},
	},
);

export const AddressExample: AddressApi = {
	address: '123 Main St',
	zipCode: '12345',
	city: 'New York',
	country: 'United States',
	state: 'New York',
};
