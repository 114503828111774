import { OrderDelivery } from './OrderDelivery';
import { OrderInfo } from './OrderInfo';
import { OrderItem } from './OrderItem';
import { Person } from './Person';
import { OrderApi, OrderDb } from './types';

export const toApi = (data: OrderDb): OrderApi => {
	const orderInfo = OrderInfo.toApi(data.rentalInfo, {
		items: data.products,
		persons: data.shoppers,
	});
	return {
		...orderInfo,
		persons: data.shoppers.map((p) => Person.toApi(p, { orderInfo: data.rentalInfo })),
		items: data.products.map((p) => OrderItem.toApi(p, { orderInfo: data.rentalInfo })),
		delivery: data.orderDelivery ? OrderDelivery.toApi(data.orderDelivery) : undefined,
	};
};
