import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import * as ViewActions from 'actions/ViewActions';
import * as ViewSelectors from 'selectors/ViewSelectors';

import InstalledAddOnModal from './InstalledAddOnModal';

const AddOnModalManager = () => {
	const dispatch = useDispatch();

	const { open, addOn } = useSelector(ViewSelectors.installedAddOnModalState);

	if (!addOn) return null;

	return (
		<>
			<InstalledAddOnModal
				addOn={addOn}
				open={open}
				onClose={() => dispatch(ViewActions.closeInstalledAddOnModal())}
				onExited={() => dispatch(ViewActions.resetInstalledAddOnModal())}
			/>
			<Prompt
				message={() => {
					dispatch(ViewActions.closeInstalledAddOnModal());
					return true;
				}}
				when={open}
			/>
		</>
	);
};

export default AddOnModalManager;
