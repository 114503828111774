import { sortBy } from 'lodash';
import moment from 'moment-timezone';

import { PLAN_TIERS } from 'common/modules/plans/plans/constants';
import { notUndefined } from 'common/utils/common';

import { AddOnDefinitions, InstalledAddOn } from '.';
import { PlanDefinitions, isLegacyPlan } from '..';
import { Plan, PurchasedPlan } from '../plans/types';
import { ShopFeatures } from '../types';
import { getTypedKeys, getTypedValues } from './../../../utils/objects';
import { AddOn, AllAddOns, ShopAddons } from './types';

export const isAddOnInstalled = (addOn: AddOn, shopFeatures: ShopFeatures | undefined) =>
	!!shopFeatures?.addOns?.[addOn];

export const addonRequiresManualSetup = (addOn: AddOn): boolean => {
	return !!AddOnDefinitions[addOn].manualSetup;
};

export const getBlockValue = (addOn: AddOn): number => {
	const definition = AddOnDefinitions[addOn];
	if (!definition || definition?.hidden) return 0;
	return AddOnDefinitions[addOn].blockSlots ?? 1;
};

export const getUsedBlockSlots = (shopFeatures: ShopFeatures | undefined) => {
	const installedBlocks = getInstalledAddOnsArray(shopFeatures?.addOns);

	return installedBlocks.reduce((a, b) => a + getBlockValue(b.addOn), 0) ?? 0;
};

export const getTotalBlockSlots = (shopFeatures: ShopFeatures | undefined) => {
	return getPlanBlockSlots(shopFeatures?.plan);
};

export const getRemainingBlockSlots = (shopFeatures: ShopFeatures | undefined) => {
	const totalBlocks = getTotalBlockSlots(shopFeatures);
	const usedBlocks = getUsedBlockSlots(shopFeatures);

	return Math.max(totalBlocks - usedBlocks, 0);
};

export const hasRemainingBlockSlots = (shopFeatures: ShopFeatures | undefined) => {
	return getRemainingBlockSlots(shopFeatures) > 0;
};

export const getInstalledAddOnsForShop = (shopFeatures: ShopFeatures | undefined): AddOn[] => {
	if (!shopFeatures) return [];

	return AllAddOns.filter((addOn) => isAddOnInstalled(addOn, shopFeatures));
};

export const getPlanBlockSlots = (purchasedPlan: PurchasedPlan | undefined): number => {
	if (!purchasedPlan) return 0;
	switch (purchasedPlan.blockSlots?.type) {
		case 'custom':
			return purchasedPlan.blockSlots.amount;

		case 'unlimited':
			return Infinity;
		case 'default':
		default: {
			const plan = PlanDefinitions[purchasedPlan.plan];
			return plan.blockSlots;
		}
	}
};

export const getDefaultInstalledAddOn = (addOn: AddOn): InstalledAddOn => {
	return {
		addOn,
		startDate: moment.utc().toISOString(),
	};
};

export const getAddOnsArray = (addOns?: ShopAddons): AddOn[] => {
	return getTypedKeys(addOns ?? {});
};

export const getInstalledAddOnsArray = (addOns?: ShopAddons): InstalledAddOn[] => {
	return getTypedValues(addOns ?? {}).filter(notUndefined);
};

export const isAddOnVisible = (addOn: AddOn): boolean => {
	return AddOnDefinitions[addOn]?.hidden !== true;
};

export const getRequiredPlansForAddOn = (addOn: AddOn): Plan[] | undefined => {
	return AddOnDefinitions[addOn]?.requiresPlan;
};

export const getLowestRequiredPlanForAddOn = (addOn: AddOn): Plan | undefined => {
	const plans = getRequiredPlansForAddOn(addOn);
	return !!plans ? sortBy(plans, (plan) => PLAN_TIERS[plan])[0] : undefined;
};

export const isAddOnAvailableOnPlan = (plan: Plan, addOn: AddOn): boolean => {
	if (isLegacyPlan(plan)) return true;

	const plans = getRequiredPlansForAddOn(addOn);
	return !!plans ? plans.includes(plan) : true;
};
