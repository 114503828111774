import { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import { constrain } from '../utils/common';
import useDebouncedValue from './useDebouncedValue';

interface Object {
	[key: string]: any;
}

interface Options<T extends Object> {
	memoizedInitialState: T;
	memoizedOnDebounce: (changes: Partial<T>, state: T) => void;
	debounceMs?: number;
}

export const MIN_DEBOUNCE_MS = 100;
export const DEFAULT_DEBOUNCE_MS = 300;
export const MAX_DEBOUNCE_MS = 1000;

const useDebouncedForm = <T extends object>(options: Options<T>) => {
	const { memoizedInitialState: initialState, memoizedOnDebounce: onDebounce } = options;
	const debounceMs = constrain(
		options.debounceMs ?? DEFAULT_DEBOUNCE_MS,
		MIN_DEBOUNCE_MS,
		MAX_DEBOUNCE_MS,
	);

	const [changes, setChanges] = useState<Partial<T>>({});
	const effectiveState = { ...initialState, ...changes };
	const debouncedChanges = useDebouncedValue(changes, debounceMs);

	useEffect(() => {
		if (isEmpty(debouncedChanges)) return;
		onDebounce(debouncedChanges, effectiveState);
	}, [onDebounce, debouncedChanges]);

	const editState = useCallback((value: Partial<T>) => {
		setChanges((changes) => ({
			...changes,
			...value,
		}));
	}, []);

	const reset = useCallback(() => {
		setChanges({});
	}, []);

	return {
		state: effectiveState,
		editState,
		reset,
	};
};

export default useDebouncedForm;
