import React from 'react';

import { ButtonBase, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface Props {
	label: string;
	icon: React.FC;
	onClick: () => void;
	to: string;
}
const ModeSelectButton = (props: Props) => {
	const { label, icon: Icon, onClick, to } = props;
	return (
		<Link to={to}>
			<ButtonBase
				onClick={onClick}
				sx={{ p: 1, borderRadius: '6px', background: (theme) => theme.palette.background.default }}
			>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Icon />
					<Typography variant="body2">{label}</Typography>
				</Stack>
			</ButtonBase>
		</Link>
	);
};

export default ModeSelectButton;
