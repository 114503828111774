import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.DELIVERY,
			completeOnClick: true,
		}),
		content: {
			title: t('homeView.onboarding.delivery.title', 'Delivery'),
			descriptions: [
				t(
					'homeView.onboarding.delivery.description',
					'Reach your customers anywhere with delivery & shipping functionality',
				),
			],
			cta: t('homeView.onboarding.delivery.cta', 'View Delivery & Shipping'),
		},
		id: 'VISIT_DELIVERY',
		type: 'card',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.DELIVERY,
			completeOnClick: true,
		}),
		content: {
			title: t(
				'homeView.onboarding.visitDelivery',
				'Visit the Delivery & Shipping page to set up delivery and shipping',
			),
		},
		id: 'VISIT_DELIVERY',
		type: 'row',
	},
});

export const VISIT_DELIVERY_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'VISIT_DELIVERY',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		VISIT_DELIVERY: {
			V3_USERFLOW: versions['V1'],
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
