import { AsyncThunkPayloadCreator, Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { Draft } from 'immer';

import { LoadingData, ReduxState } from 'services/types';

export const setLoading = <T>(stateSlice: Draft<LoadingData<T>>): void => {
	stateSlice.loading = true;
	stateSlice.error = null;
};

export const setError = <T>(stateSlice: Draft<LoadingData<T>>, args: { error: string }): void => {
	stateSlice.loading = false;
	stateSlice.error = args.error;
	stateSlice.data = null;
};

export const setFulfilled = <T>(
	stateSlice: Draft<LoadingData<T>>,
	args: { data: Draft<T> },
): void => {
	stateSlice.loading = false;
	stateSlice.data = args.data;
};

interface DefaultThunkConfig {
	state: ReduxState;
}

type AsyncThunkConfig = {
	state?: unknown;
	dispatch?: Dispatch;
	extra?: unknown;
	rejectValue?: unknown;
	serializedErrorType?: unknown;
	pendingMeta?: unknown;
	fulfilledMeta?: unknown;
	rejectedMeta?: unknown;
};

export const createAppThunk = <
	Returned,
	ThunkArg = void,
	ThunkConfig extends AsyncThunkConfig = DefaultThunkConfig
>(
	type: string,
	payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>,
) => createAsyncThunk<Returned, ThunkArg, ThunkConfig>(type, payloadCreator);
