import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const ADDITIONAL_FEATURES_DEFINITION: Record<
	'ADDITIONAL_FEATURES',
	OnboardingStepDefinition
> = {
	ADDITIONAL_FEATURES: {
		plans: 'all',
		versions: {
			V1_TEMPLATE: ['VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
			V1_NO_TEMPLATE: ['VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
			V0: ['VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
		},
	},
};
