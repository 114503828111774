import React from 'react';

import { Box, Chip, Divider, Stack, styled } from '@mui/material';
import { useHasFeature } from 'hooks';
import {
	RiArrowDownSLine,
	RiArrowLeftLine,
	RiChatHeartLine,
	RiExternalLinkLine,
	RiMenuLine,
	RiPagesLine,
	RiQuestionMark,
	RiStarSLine,
	RiUserLine,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { isFreemiumPlan } from 'common/modules/plans';
import * as Sleekplan from 'common/services/analytics/sleekplan';
import * as UserActions from 'actions/UserActions';
import HeaderButton from 'components/Layout/Header/HeaderButton';
import HeaderMenu, { HeaderMenuItem } from 'components/Layout/Header/HeaderMenu';
import { useLayoutContext } from 'components/Layout/context';
import { useAnalytics } from 'hooks/useAnalytics';
import useOnboardingAction from 'hooks/useOnboardingAction';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import { useTranslation } from 'services/localization';
import { Routes, goBack, push, useTypedLocation } from 'routing';

type HeaderIsolatedProps = {
	variant: 'isolated';
	to?: string;
	label?: string;
	onClick?: () => void;
};

type HeaderSidebarProps = {
	variant: 'sidebar';
};

export type HeaderProps = {
	showProfileMenu?: boolean;
	showSupportMenu?: boolean;
	showOnlineStoreLink?: boolean;
} & (HeaderIsolatedProps | HeaderSidebarProps);

const Header = (props: HeaderProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const routeLocation = useTypedLocation();
	const { showProfileMenu = true, showSupportMenu = true, showOnlineStoreLink = true } = props;
	const { setSidebarOpen, isMobile } = useLayoutContext();
	const [, setVisitOnlineStoreDone] = useOnboardingAction('VISIT_ONLINE_STORE');
	const { hasFeature } = useHasFeature();
	const { logAnalyticsEvent } = useAnalytics();

	const shopPlan = useSelector(ShopSelectors.shopPlan);
	const mainlocationId = useSelector(ShopSelectors.activeShopMainLocationId);
	const locationsById = useSelector(ShopSelectors.allShopLocationsById);
	const activeUserEmail = useSelector(UserSelectors.activeUserEmail);
	const userLocations = useSelector(UserSelectors.userLocations);
	const activeLocationId = useSelector(UserSelectors.userActiveLocationId)!;
	const activeLocation = locationsById[activeLocationId];
	const shopStoreUrl = useSelector(ShopSelectors.activeShopOnlineURL);
	const locationUrls = useSelector(ShopSelectors.activeShopLocationUrlsById);
	const locationUrl = locationUrls[activeLocationId];

	const showUpgradeButton = !shopPlan || isFreemiumPlan(shopPlan);

	const handleSidebarOpen = () => {
		setSidebarOpen((prev) => !prev);
	};

	const handleBackClick = (to?: string, onClick?: () => void) => () => {
		onClick?.();
		if (!!to) {
			push(to);
		} else {
			goBack();
		}
	};

	const handleOnlineStoreOpen = () => {
		logAnalyticsEvent({ name: 'view_online_store_button_click', params: { plan: shopPlan } });
		setVisitOnlineStoreDone();
		const url =
			activeLocationId === mainlocationId
				? `${shopStoreUrl}/shop?admin=true`
				: `${shopStoreUrl}/l/${locationUrl}?admin=true`;

		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const handleSignout = () => {
		dispatch(UserActions.logout());
		push(Routes.LOGIN);
	};

	const headerMenuItems = ((): HeaderMenuItem[] => {
		let items: HeaderMenuItem[] = [
			{
				type: 'button',
				label: t('headerBar.viewUpdates', 'View updates'),
				icon: RiStarSLine,
				onClick: () => Sleekplan.open(),
			},

			{
				type: 'external-link',
				label: t('headerBar.helpCenter', 'Help center'),
				icon: RiPagesLine,
				href: 'https://support.rentle.io',
			},
		];

		if (hasFeature('CONTACT_SUPPORT')) {
			items = [
				...items,
				{
					type: 'external-link',
					label: t('headerBar.contactSupport', 'Contact support'),
					icon: RiChatHeartLine,
					href: 'mailto:cgregytq@rentle.intercom-mail.com',
					className: 'intercom_launcher',
				},
			];
		}
		return items;
	})();

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="stretch"
			height="100%"
			maxWidth="100%"
			overflow="hidden"
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="center" height="100%" flex={0}>
				{props.variant === 'isolated' && (
					<HeaderButton
						icon={RiArrowLeftLine}
						iconPosition="start"
						label={props.label ?? t('common:actions.goBack', 'Go back')}
						size="large"
						onClick={handleBackClick(props.to, props.onClick)}
						data-qa={'header-back-button'}
					/>
				)}
				{props.variant === 'sidebar' && (
					<>
						{isMobile ? (
							<HeaderButton
								onClick={handleSidebarOpen}
								label={t('common:actions.openMenu', 'Open menu')}
								collapsed
								icon={RiMenuLine}
								data-qa="header-sidebar-menu-button"
							/>
						) : activeLocation ? (
							userLocations.length > 1 ? (
								<HeaderMenu
									label={activeLocation.name}
									icon={RiArrowDownSLine}
									items={userLocations.map((location) => ({
										label: location?.name,
										type: 'button',
										onClick: () => dispatch(UserActions.setActiveLocationId(location.id)),
										selected: location.id === activeLocationId,
									}))}
								/>
							) : (
								<HeaderButton label={activeLocation.name} disabled={true} />
							)
						) : null}
						{showUpgradeButton && (
							<Link to={{ pathname: Routes.PLANS, state: { from: routeLocation.pathname } }}>
								<UpgradeChip label={t('common:actions.upgrade', 'Upgrade')} />
							</Link>
						)}
					</>
				)}
			</Stack>
			<Stack
				direction="row"
				justifyContent="flex-end"
				alignItems="center"
				height="100%"
				divider={<Divider orientation="vertical" />}
				flex={1}
			>
				{/**
				 * We render an empty box so that Stack renders an extra divider at the start
				 */}
				<Box />
				{showSupportMenu && (
					<HeaderMenu
						icon={RiQuestionMark}
						label={t('headerBar.helpAndSupport', 'Help & support')}
						collapsed={true}
						items={headerMenuItems}
					/>
				)}
				{showOnlineStoreLink && (
					<HeaderButton
						icon={RiExternalLinkLine}
						label={t('common:salesChannel.onlineStore', 'Online store')}
						onClick={handleOnlineStoreOpen}
						data-qa="header-online-store-button"
					/>
				)}

				{showProfileMenu && (
					<HeaderMenu
						icon={RiUserLine}
						label={activeUserEmail ?? ''}
						collapsedIconOnly
						collapsedMenuLabel
						collapsed={isMobile}
						items={[
							{
								type: 'internal-link',
								label: t('account.profile.pageTitle'),
								route: Routes.ACCOUNT_PROFILE,
							},
							{
								type: 'button',
								label: t('common:actions.logout', 'Log out'),
								onClick: handleSignout,
							},
						]}
					/>
				)}
			</Stack>
		</Stack>
	);
};

const UpgradeChip = styled(Chip)(({ theme }) => ({
	background: 'linear-gradient(to right, #FFCFB8, #EAB1E8 51%, #FFCFB8) var(--x, 0)/ 200%',
	marginLeft: theme.spacing(0.5),
	padding: theme.spacing(0.5, 0),
	fontSize: '12px',
	fontWeight: 600,
	lineHeight: '18px',
	height: 'fit-content',
	transition: '0.4s',
	'&:hover': {
		'--x': '-100%',
	},
}));

export default Header;
