import { z } from 'zod';

export const zDayTypeApi = z.enum([
	'all_days',
	'weekdays',
	'weekends',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
]);
