import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

export const zPaymentObjectApi = withOpenApi(
	z.object({
		value: z.number().int().nonnegative(),
		currency: z.string(),
		paid: z.number().int().nonnegative(),
		refunded: z.number().int().nonnegative().optional(),
		authorised: z.number().int().nonnegative().optional(),
		cancelled: z.number().int().nonnegative().optional(),
		captured: z.number().int().nonnegative().optional(),
		/**
		 * Available in database, but not in API currently
		 */
		// description: z.string().optional(),
		// pendingRefunds: z.record(z.number()).optional(),
		// failedRefunds: z.record(z.number()).optional(),
		// pendingCaptures: z.record(z.number()).optional(),
		// failedCaptures: z.record(z.number()).optional()
	}),
	{
		description: 'Payment object. All values are in minor units.',
		fields: {
			value: 'Total value of the payment.',
			currency: 'Currency of the payment.',
			paid: 'Paid amount.',
			refunded: 'Refunded amount.',
			authorised: 'Authorised amount.',
			cancelled: 'Cancelled amount.',
			captured: 'Captured amount.',
		},
		example: {
			value: 100_00,
			currency: 'USD',
			paid: 100_00,
			refunded: 0,
			authorised: 100_00,
			cancelled: 0,
			captured: 100_00,
		},
	},
);
