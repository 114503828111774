import { faker } from '@faker-js/faker/locale/en';
import { range } from 'lodash';

import {
	InventoryTransfer,
	InventoryTransferPaymentMethods,
	InventoryTransferStatuses,
} from 'common/modules/inventoryTransfers';

import { getInventoryTransferItem } from './InventoryTransferItem.mock';
import { getOrderPricing } from './OrderPricing.mock';

const generateInventoryTransfer = (): InventoryTransfer => {
	return {
		id: faker.datatype.uuid(),
		transferNumber: faker.datatype.number({ max: 10000 }),
		createdAt: faker.datatype.datetime().toISOString(),
		updatedAt: faker.datatype.datetime().toISOString(),
		merchantId: faker.datatype.string(),
		status: faker.helpers.arrayElement(Object.values(InventoryTransferStatuses)),
		items: range(5).map(() => getInventoryTransferItem()),
		paid: faker.datatype.boolean(),
		source: {
			type: 'customer',
			customer: {
				firstName: faker.name.firstName(),
				lastName: faker.name.lastName(),
				email: faker.internet.email(),
				phone: faker.phone.number(),
				additionalInfo: faker.lorem.sentence(),
			},
		},
		destination: {
			type: 'store',
			store: {
				id: faker.datatype.uuid(),
			},
		},
		arrivalDate: faker.datatype.datetime().toISOString(),
		price: getOrderPricing(),
		paymentMethod: faker.helpers.arrayElement(Object.values(InventoryTransferPaymentMethods)),
	};
};

export const getInventoryTransfer = (p?: Partial<InventoryTransfer>): InventoryTransfer => {
	return { ...generateInventoryTransfer(), ...p };
};
