import {
	AdyenClientPaymentResult,
	AdyenPaymentDetails,
	AdyenRedirectDetails,
} from 'common/api/db/functions/_callable/orders';
import { Languages } from 'common/modules/translations';
import { BrowserInfo, PaymentCardDetails, PaymentMethodData } from 'common/types';

import { CallableCreator } from '..';

interface ReserveDepositReq {
	orderId: string;
	shopId: string;
}

type ReserveDepositRes = {
	status: 'success';
};

export interface AdyenChangePaymentCardReq {
	browserInfo: BrowserInfo;
	shopperIP: string;
	transactionId: string;
	orderId: string;
	paymentData: {
		provider: 'ADYEN';
		methodName: 'CARD_ONLINE';
		paymentMethodData: PaymentMethodData;
	};
	returnUrl: string;
	origin: string;
}

export interface StripeChangePaymentCardReq {
	orderId: string;
	cardId: string;
	paymentData: {
		provider: 'STRIPE';
		methodName: 'CARD_ONLINE_STRIPE';
	};
}

export type ChangePaymentCardReq = AdyenChangePaymentCardReq | StripeChangePaymentCardReq;

export interface CompletedChangePaymentCardResult {
	status: 'SUCCESS';
	providerResult?: AdyenClientPaymentResult;
	cardDetails?: PaymentCardDetails;
}

export type InCompleteChangePaymentCardResult = {
	status: 'FAILED' | 'REQUIRES_ACTION';
	providerResult?: AdyenClientPaymentResult;
};

export type ChangePaymentCardRes =
	| CompletedChangePaymentCardResult
	| InCompleteChangePaymentCardResult;

export interface ChangePaymentCardAdditionalDetailsReq {
	orderId: string;
	transactionId: string;
	returnUrl: string;
	origin: string;
	paymentDetails: AdyenPaymentDetails | AdyenRedirectDetails;
	userLanguage: Languages;
}

export const modifications = (createCallable: CallableCreator) => ({
	reserveDeposit: createCallable<ReserveDepositReq, ReserveDepositRes>(
		'orders-modifications-reserveDeposit',
	),
	changePaymentCard: createCallable<ChangePaymentCardReq, ChangePaymentCardRes>(
		'orders-modifications-changePaymentCard',
	),
	changePaymentCardAdditionalDetails: createCallable<
		ChangePaymentCardAdditionalDetailsReq,
		ChangePaymentCardRes
	>('orders-modifications-changePaymentCardAdditionalDetails'),
});
