import { z } from 'zod';

import { zShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { zAppliedDiscountCodes, zDepositType } from 'common/types';
import { zDiscount } from 'common/types/common';

import { zOrderServiceFee } from '../serviceFee/types';
import { zTaxLine } from '../taxes/types';

export const zExtendedPricingProperties = z.object({
	subtotalWithoutManualDiscount: z.number().int(),
	subtotalWithoutDiscountCodes: z.number().int(),
});

export const zDepositPricing = z.object({
	value: z.number().int(),
	type: zDepositType.optional(),
	additionalInfo: z.string().optional(),
	description: z.string().optional(),
});

export const zOrderPricing = z.object({
	currency: z.string(),
	/**
	 * Total price including taxes and discounts
	 */
	total: z.number().int(),
	/**
	 * Price after discounts and before taxes if taxes are excluded
	 */
	subtotal: z.number().int(),
	/**
	 * Used to indicate special pricing-related information
	 */
	additionalInfo: z.string().optional(),
	totalTaxes: z.number().int(),
	totalDiscounts: z.number().int(),
	taxExcluded: z.boolean(),
	taxLines: z.array(z.lazy(() => zTaxLine)),
	discountCodes: zAppliedDiscountCodes.optional(),
	manualDiscount: zDiscount.optional(),
	serviceFee: zOrderServiceFee.optional(),
	deposit: z
		.object({
			value: z.number().int(),
			type: zDepositType.optional(),
			additionalInfo: z.string().optional(),
			description: z.string().optional(),
		})
		.optional(),
	paymentMethod: zShopOnlinePaymentMethodObject.optional(),
});

export const zExtendedOrderPricing = zOrderPricing.merge(zExtendedPricingProperties);

export const zItemPricing = zOrderPricing
	.omit({
		manualDiscount: true,
		serviceFee: true,
		deposit: true,
		paymentMethod: true,
	})
	.extend({
		manualDiscount: z.number().int().optional(),
		deposit: z.number().int().optional(),
		listPrice: z.number().int(),
		originalListPrice: z.number().int(),
	});

export const zItemPricingWithoutCurrency = zItemPricing.omit({ currency: true }).extend({
	currency: z.literal(undefined),
});

export const zExtendedItemPricing = z.union([
	zItemPricing.merge(zExtendedPricingProperties),
	zItemPricingWithoutCurrency.merge(zExtendedPricingProperties),
]);

export const zOrderPricingForEmail = zOrderPricing.extend({
	total: z.string(),
	subtotal: z.string(),
	totalTaxes: z.string(),
	totalDiscounts: z.string(),
	taxLines: z.array(
		z.lazy(() =>
			zTaxLine.extend({
				price: z.string(),
				rate: z.string(),
				label: z.string(),
			}),
		),
	),
	manualDiscount: zDiscount
		.extend({
			amount: z.string(),
		})
		.optional(),
	discountCodes: z
		.array(
			z.object({
				code: z.string(),
				value: z.string(),
			}),
		)
		.optional(),
	serviceFee: zOrderServiceFee
		.extend({
			total: z.string(),
			taxLines: z.array(
				z.lazy(() =>
					zTaxLine.extend({
						price: z.string(),
						rate: z.string(),
						label: z.string(),
					}),
				),
			),
		})
		.optional(),
	deposit: z.string().optional(),
});

export const zItemExtensionPricing = z.object({});

export type OrderPricing = z.infer<typeof zOrderPricing>;
export type OrderPricingForEmail = z.infer<typeof zOrderPricingForEmail>;
export type ExtendedOrderPricing = z.infer<typeof zExtendedOrderPricing>;
export type ItemPricing = z.infer<typeof zItemPricing>;
export type ItemPricingWithoutCurrency = z.infer<typeof zItemPricingWithoutCurrency>;
export type ExtendedItemPricing = z.infer<typeof zExtendedItemPricing>;
export type DepositPricing = z.infer<typeof zDepositPricing>;
export type ItemExtensionPricing = z.infer<typeof zItemExtensionPricing>;
export type ExtendedPricingProperties = z.infer<typeof zExtendedPricingProperties>;
