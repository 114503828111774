import React from 'react';

import { Button, CircularProgress } from '@mui/material';

import HasFeature from 'components/features/HasFeature';
import LockedButton from 'components/features/LockedButton';
import { useTranslation } from 'services/localization';

interface InstallButtonProps {
	loading: boolean;
	onClick: () => void;
}

const InstallButton = (props: InstallButtonProps) => {
	const { loading, onClick } = props;
	const { t } = useTranslation();
	return (
		<HasFeature
			requiredFeature="ADD_ONS"
			fallback={
				<LockedButton color="primary" variant="contained" fullWidth>
					{t('account.addOns.modal.installCTA', 'Install now')}
				</LockedButton>
			}
		>
			<Button color="primary" variant="contained" onClick={onClick} disabled={loading} fullWidth>
				{loading ? (
					<CircularProgress size={18} color="inherit" />
				) : (
					t('account.addOns.modal.installCTA', 'Install now')
				)}
			</Button>
		</HasFeature>
	);
};

export default InstallButton;
