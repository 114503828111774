import { NewRentalAction } from 'actions';

import { getInitialOrderPricing } from 'common/modules/atoms/pricing';
import { Shopper } from 'common/types';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';
import { getTimeNearestToNextQuarterHour } from 'common/utils/dateCalculations';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import { NewRentalState } from 'services/types';

const getInitialState = (shopId: string = ''): NewRentalState => {
	const firstShopperId = newFirestoreId();
	const emptyShopper: Shopper = {
		id: firstShopperId,
		created: new Date().toISOString(),
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		marketing: false,
		userProperties: {},
		productIds: [],
		rentalId: '', // This is populated on shop load if not given as parameter
		shopId, // This is populated on shop load if not given as parameter
		categoryIds: [],
	};
	return {
		selectedCategoryId: 'all',
		isDurationManuallyChanged: false,
		rentalInfo: {
			id: '',
			shopId,
			created: '',
			startDate: getTimeNearestToNextQuarterHour().toISOString(),
			initialStartDate: getTimeNearestToNextQuarterHour().toISOString(),
			startDateException: false,
			returnTimeNext: null,
			endDateReturned: null,
			startLocation: {
				name: '',
				address: '',
				city: '',
				zipCode: '',
				id: '',
			},
			endLocation: {
				name: '',
				address: '',
				city: '',
				zipCode: '',
				id: '',
			},
			responsiblePerson: {
				external: false,
				shopperId: firstShopperId,
			},
			channel: 'ADMIN',
			rentalState: 'INIT',
			activeState: 'RENT_INIT',
			handlePayment: false,
			charge: {
				paid: 0,
			},
			deposit: {
				paid: 0,
			},
			terms: {
				accepted: false,
			},
			live: false,
			shopperNames: [],
			shopperNameKeywords: [],
			shopperIdsWithProducts: {},
			rentalProductCodes: [],
			categoryIds: [],
			stockProductIds: [],
			pricing: getInitialOrderPricing({
				currency: getDefaultCurrencyObject().code,
				taxExcluded: false,
				paymentMethod: {
					id: 'PAY_STORE',
				},
			}),
			purchaseTypes: ['rental'],
			purchaseType: 'rental',
		},
		shoppers: [emptyShopper],
		products: [],
		phone: {
			countryCode: 'FI',
			areaCode: '+358',
			numberWithoutCode: '',
		},
		productAvailabilities: {},
		reservationId: null,
	};
};

const INITIAL_STATE = getInitialState();

const NewRentalReducer = (state = INITIAL_STATE, action: NewRentalAction): NewRentalState => {
	switch (action.type) {
		case 'UPDATE_RENTAL':
			const { rentalInfo, shoppers, products } = action.rental;
			return {
				...state,
				rentalInfo,
				shoppers,
				products,
			};
		case 'UPDATE_RENTAL_INFO':
			return {
				...state,
				rentalInfo: action.rentalInfo,
			};
		case 'UPDATE_SHOPPERS':
			return {
				...state,
				shoppers: action.shoppers,
			};
		case 'UPDATE_PRODUCTS':
			return {
				...state,
				products: action.products,
			};
		case 'UPDATE_PAYMENT_METHOD': {
			return {
				...state,
				rentalInfo: {
					...state.rentalInfo,
					pricing: {
						...state.rentalInfo.pricing,
						paymentMethod: action.paymentMethod,
					},
				},
			};
		}
		case 'UPDATE_RESPONSIBLE_PERSON':
			return {
				...state,
				rentalInfo: {
					...state.rentalInfo,
					responsiblePerson: action.responsiblePerson,
				},
			};
		case 'UPDATE_START_LOCATION':
			return {
				...state,
				rentalInfo: {
					...state.rentalInfo,
					startLocation: action.location,
				},
			};
		case 'UPDATE_END_LOCATION':
			return {
				...state,
				rentalInfo: {
					...state.rentalInfo,
					endLocation: action.location,
				},
			};
		case 'CHANGE_SELECTED_CATEGORY_ID':
			return {
				...state,
				selectedCategoryId: action.categoryId,
			};
		case 'UPDATE_PHONE':
			return {
				...state,
				phone: action.phone,
			};
		case 'UPDATE_PRODUCT_AVAILABILITIES':
			return {
				...state,
				productAvailabilities: action.productAvailabilities,
			};
		case 'RESET_NEW_RENTAL_STATE':
			if (!action.shopId) {
				return getInitialState();
			}
			return getInitialState(action.shopId);
		case 'UPDATE_RESERVATION_ID':
			return {
				...state,
				reservationId: action.reservationId,
			};
		case 'SET_IS_DURATION_MANUALLY_CHANGED':
			return {
				...state,
				isDurationManuallyChanged: action.payload,
			};
		default:
			return state;
	}
};

export default NewRentalReducer;
