import { faker } from '@faker-js/faker/locale/en';

import { Discount } from 'common/types';

import { checkForInvalidProperties, checkForUnallowedValueTypes } from './index.mock';

export const MandatoryProperties: Array<keyof Discount> = ['amount'];
export const VoluntaryProperties: Array<keyof Discount> = ['percentage'];

const generateDiscount = (): Discount => {
	return {
		percentage: faker.datatype.number({ min: 0, max: 100 }),
		amount: faker.datatype.number({ min: 1, max: 100 }),
	};
};

export const getDiscount = (p?: Partial<Discount>): Discount => {
	if (p) {
		checkForInvalidProperties(generateDiscount(), p, 'Discount');
		checkForUnallowedValueTypes(generateDiscount(), p, 'Discount', { amount: '', percentage: '' });
	}
	return { ...generateDiscount(), ...p };
};
