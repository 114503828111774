// Merchant terms documents
export const RENTLE_SHOP_TERMS_VERSION = 6.2;
export const RENTLE_SHOP_TERMS_URL =
	'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/rentle-tos-pricing%2Ftos%2FRentle%20Terms%20and%20Conditions%20v.6.2.pdf?alt=media&token=9392035b-733e-4d3b-aba3-dd09ef78a127';
export const RENTLE_SHOP_PRIVACY_URL = 'https://rentle.io/privacy';

// Consumer terms documents
export const RENTLE_TERMS_URL_CONSUMERS =
	'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/public%2FRentle-User_Terms_of_Service_v1.pdf?alt=media&token=fff1ffcc-c07c-4957-8c94-b190d6339c5d';
export const RENTLE_PRIVACY_URL_CONSUMERS =
	'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/public%2FRentle_Privacy_Policy_Consumers_v1.pdf?alt=media&token=7d9d4a42-4356-4714-8df4-9ee0f123ad66';
