import { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

interface DebounceOptions {
	leading?: boolean;
	trailing?: boolean;
	maxWait?: number;
}

const useDebouncedValue = <T>(value: T, delay: number, options?: DebounceOptions) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	const _setDebouncedValue = useMemo(() => {
		return debounce(setDebouncedValue, delay, options);
	}, [delay, options]);

	useEffect(() => {
		return () => {
			_setDebouncedValue.cancel();
		};
	}, [_setDebouncedValue]);

	useEffect(() => {
		if (value === debouncedValue) return;
		_setDebouncedValue(value);
	}, [value, debouncedValue, _setDebouncedValue]);

	return debouncedValue;
};

export default useDebouncedValue;
