import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const SKU_CREATION_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'SKU_CREATION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	SKU_CREATION: {
		all: {
			action: getRouteOnboardingStepItem({
				routeTo: Routes.INVENTORY_SKUS,
			}),
			content: {
				title: t(
					'homeView.onboarding.createFirstSku2',
					'Create an SKU to group and track your articles',
				),
			},
			id: 'SKU_CREATION',
			type: 'row',
		},
	},
});
