import { ReceiptApi } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getInvoiceReceiptQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.invoiceReceipts;
	const dataUpdater = undefined;
	const query = buildQuery<ReceiptApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<ReceiptApi>(collectionPath, dbRefs, dataUpdater),
	};
};
