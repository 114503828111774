import React from 'react';

import { Box, BoxProps, Link } from '@mui/material';
import { RiExternalLinkLine } from 'react-icons/ri';
import { Link as RRLink } from 'react-router-dom';

import { AddOn, getAddOnDetails } from 'common/modules/plans';
import { switchUnreachable } from 'common/utils/common';
import { useAnalytics } from 'hooks/useAnalytics';
import { getAddOnSetupPage } from 'services/addOns';
import { useTranslation } from 'services/localization';
import { Routes } from 'routing';

interface AddOnLinkProps extends BoxProps {
	addOn: AddOn;
	type: 'watch-demo' | 'book-demo' | 'manage-add-ons' | 'setup' | 'help-article';
}

const AddOnLink = (props: AddOnLinkProps) => {
	const { type, addOn, ...boxProps } = props;
	const { t } = useTranslation();
	const { logAnalyticsEvent } = useAnalytics();

	const { linkText, external, to } = (() => {
		switch (type) {
			case 'watch-demo': {
				return {
					linkText: t('account.addOns.modal.watchDemoCTA', 'Watch a demo'),
					to: 'https://www.rentle.io/demo',
					external: true,
				};
			}
			case 'book-demo': {
				return {
					linkText: t('account.addOns.modal.bookADemoCTA', 'Book a demo'),
					to: 'https://www.rentle.io/meetings/demo-rentle/30min',
					external: true,
				};
			}
			case 'manage-add-ons': {
				return {
					linkText: t('account.addOns.modal.manageAddOns', 'Manage add-ons'),
					to: Routes.AddOnStoreTab('installed'),
					external: false,
				};
			}
			case 'setup': {
				return {
					linkText: t('account.addOns.installedAddOns.goToSettings', 'Go to settings'),
					to: getAddOnSetupPage(addOn),
					external: false,
				};
			}
			case 'help-article': {
				return {
					linkText: t('account.addOns.installedAddOns.helpArticle', 'Help article'),
					to: getAddOnDetails(addOn, t).supportArticle,
					external: true,
				};
			}
			default: {
				return switchUnreachable(type);
			}
		}
	})();

	if (!to) return null;
	return (
		<Box {...boxProps}>
			{external ? (
				<Link
					onClick={() =>
						logAnalyticsEvent({
							name: 'block_click',
							params: {
								type: 'dialog',
								data: { block: addOn, action: type },
							},
						})
					}
					rel="noopener noreferrer"
					target="_blank"
					href={to}
				>
					{linkText}
					<Box sx={{ ml: '4px', display: 'inline' }}>
						<RiExternalLinkLine size={16} />
					</Box>
				</Link>
			) : (
				<Link
					onClick={() =>
						logAnalyticsEvent({
							name: 'block_click',
							params: {
								type: 'dialog',
								data: { block: addOn, action: type },
							},
						})
					}
					component={RRLink}
					to={to}
				>
					{linkText}
				</Link>
			)}
		</Box>
	);
};

export default AddOnLink;
