import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.massReturn.name', 'Mass Return'),
	subHeader: t(
		'common:blocks.details.massReturn.subHeader',
		'Significantly speed up your return process',
	),
	description: t(
		'common:blocks.details.massReturn.description',
		`Mass return mode supercharges your return process and is a great operational booster for high-volume rental businesses. You can let your customers drop off their rental equipment and check the returned bookings when it suits you the best.

		Time. Saved.`,
	),
	supportArticle: SupportArticles.MASS_RETURN,
});

export default details;
