import * as React from 'react';

import Button, { ButtonProps } from '@mui/material/Button';
import { Link } from 'react-router-dom';

interface LinkButtonProps {
	to: string;
}

const LinkButton = <Link extends typeof Link>(props: ButtonProps<Link, {}> & LinkButtonProps) => (
	<Button {...props} component={Link}>
		{props.children}
	</Button>
);

export default LinkButton;
