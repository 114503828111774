import { Components, Theme } from '@mui/material/styles';
import { merge } from 'lodash';

import * as Fonts from './fonts';

interface OverrideOptions {
	fontFamily?: string;
	extras?: Components<Theme>;
}

export const defaultOptions: Required<OverrideOptions> = {
	fontFamily: Fonts.inter,
	extras: {},
};

export const getOverrides = (options: OverrideOptions = defaultOptions): Components<Theme> => {
	const bodyFont = options.fontFamily ?? defaultOptions.fontFamily;
	const base: Components<Theme> = {
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					fontFamily: bodyFont,
				},
				html: {
					boxSizing: 'border-box',
					height: '100%',
					width: '100%',
					fontSize: '62.5%',
					'&.large-font': {
						fontSize: '68.75%',
					},
				},
				'*, *:before, *:after': {
					boxSizing: 'inherit',
				},
				body: {
					margin: 0,
					padding: 0,
					height: '100%',
					width: '100%',
					touchAction: 'manipulation',
					fontSize: '1.4rem',
					fontWeight: 400,
				},
				'#root': {
					width: '100%',
					height: '100%',
				},
				a: {
					color: 'inherit',
					textDecoration: 'none',
				},
				'.MuiTypography-root a': {
					fontWeight: 500,
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				root: {
					borderRadius: 50,
				},
				extended: {
					borderRadius: 50,
				},
			},
		},
		// https://github.com/mui-org/material-ui/issues/17701
		MuiMenuItem: {
			styleOverrides: {
				root: {
					minHeight: '48px !important',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				body2: {
					fontWeight: 500,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					fontWeight: 500,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 50,
					fontWeight: 700,
					padding: '6px 16px',
					whiteSpace: 'nowrap',
				},
				sizeSmall: {
					padding: '6px 16px',
				},
				sizeMedium: {
					padding: '8px 16px',
				},
				sizeLarge: {
					padding: '12px 22px',
				},
				outlined: {
					borderWidth: '2px !important',
					minWidth: 112,
				},
				outlinedSizeSmall: {
					padding: '4px 14px',
				},
				outlinedSizeMedium: {
					padding: '6px 16px',
				},
				outlinedSizeLarge: {
					padding: '10px 20px',
				},
				contained: {
					minWidth: 112,
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					background: '#000000',
					fontSize: '1.4rem',
				},
				arrow: {
					color: '#000000',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: ({ theme }) => ({
					background: theme.palette.background.paper,
				}),
			},
		},
	};
	return merge({}, base, options.extras);
};
