import { CurrencyConversions } from 'common/modules/billing';
import currencyCodes from 'common/services/currencyCodes.json';
import { CurrencyInfo, CurrencyObject } from 'common/types';

export const currenciesByCode = currencyCodes.currencies;
export const currencies = Object.values(currencyCodes.currencies) as CurrencyInfo[];

export const getCurrencyCodesWithSpecificMinor = (minor: number): CurrencyInfo[] =>
	currencies.filter(
		(currency) =>
			currency.minor === minor && currency.countries.length > 0 && currency.symbols.length > 0,
	);

/**
 * Currently, only currencies with minor units 2 are supported.
 */
export const currencyOptions = getCurrencyCodesWithSpecificMinor(2);

export const getCurrencyInfoFromCountryCode = (countryCode: string): CurrencyInfo | null =>
	currencyOptions.filter((value) => value.countries.includes(countryCode.toUpperCase()))[0] ?? null;

export const getCurrencyInfoFromCurrencyCode = (currencyCode: string): CurrencyInfo | null =>
	currencyOptions.filter((value) => value.code === currencyCode.toUpperCase())[0] ?? null;

export const getCurrencySymbolFromCurrencyCode = (currencyCode: string): string =>
	getCurrencyInfoFromCurrencyCode(currencyCode)?.symbols[0] ?? currencyCode;

export const getDefaultCurrencyObject = (): CurrencyObject => ({
	code: 'USD',
	displayAs: '$',
	position: 'prefix',
	noSpacing: true,
});

export const isDisplayAsSymbol = (currency: CurrencyObject): boolean =>
	currency.code !== currency.displayAs;

export const getCurrencyObjectFromCurrencyCode = (
	currencyCode: string,
	shopCurrency?: CurrencyObject,
): CurrencyObject => {
	// Fallback for orders in the very old of history
	if (!currencyCode) {
		if (shopCurrency && shopCurrency.code === 'EUR') {
			return shopCurrency;
		}
		return getDefaultCurrencyObject();
	}

	// After currency is changed
	if (shopCurrency && currencyCode === shopCurrency.code) {
		return shopCurrency;
	}

	return {
		code: currencyCode,
		position: (shopCurrency && shopCurrency.position) || 'suffix',
		displayAs:
			shopCurrency && isDisplayAsSymbol(shopCurrency)
				? getCurrencySymbolFromCurrencyCode(currencyCode)
				: currencyCode,
	};
};

export const convertCurrency = (params: {
	from: string;
	to: string;
	amount: number;
	rates: CurrencyConversions['rates'];
}) => {
	const { amount, from, to, rates } = params;
	return Math.round((amount / rates[from]) * rates[to]);
};
