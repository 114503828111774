import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { Api } from 'common/db/api/paths';
import { DbUpdateObject } from 'common/db/api/types';
import { BulkInventoryItem, InventoryItem, InventoryStatus } from 'common/modules/inventory';
import { ById } from 'common/types';

export const updateItemActiveOrders = (
	api: Api,
	args: {
		itemId: string;
		orderId: string;
		state: 'IN' | 'OUT';
	},
) => {
	const { itemId, orderId, state } = args;
	return api.inventoryItems.doc(itemId).update({
		...(state === 'OUT' && { lastUsed: moment().toISOString() }),
		activeOrderIds:
			state === 'OUT' ? api.FieldValues.arrayUnion(orderId) : api.FieldValues.arrayRemove(orderId),
	});
};

export const updateSingleInventoryItemStatus = (
	api: Api,
	itemId: string,
	status: InventoryStatus,
) => {
	return api.inventoryItems.doc(itemId).update({
		status,
	});
};

export const updateBulkInventoryItemProperties = (args: {
	api: Api;
	itemId: string;
	fields: DbUpdateObject<BulkInventoryItem>;
}) => {
	const { api, itemId, fields } = args;
	return api.inventoryItems.doc(itemId).update(fields);
};

export const updateItemReservedCountsByOrderId = (
	api: Api,
	args: {
		itemId: string;
		updatesByOrderId: ById<number>;
	},
) => {
	const { itemId, updatesByOrderId } = args;
	if (isEmpty(updatesByOrderId)) return;

	const updates = Object.entries(updatesByOrderId).reduce((result, [orderId, amount]) => {
		const updatePath = `reservedCountsByOrderId.${orderId}` as 'reservedCountsByOrderId.123';
		result[updatePath] = api.FieldValues.increment(amount);
		return result;
	}, {} as DbUpdateObject<InventoryItem>);

	return api.inventoryItems.doc(itemId).update(updates);
};
