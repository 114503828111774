import { partition } from 'lodash';

import { Api } from 'common/db/api/paths';
import { InventoryItemApi } from 'common/modules/inventory/InventoryItemApi';
import { InventoryStatus } from 'common/modules/inventory/types';
import {
	changeBulkInventoryItemAllocations,
	changeBulkInventoryItemStatus,
	getBulkItemsSkuIdPairsFromOrderProducts,
} from 'common/modules/inventory/utils';
import { OrderProduct } from 'common/types';
import { notUndefined } from 'common/utils/common';

export const handleSingleItemStatusChange = async (
	api: Api,
	args: {
		shopId: string;
		itemCodes: string[];
		targetStatus: InventoryStatus;
	},
) => {
	const { shopId, itemCodes, targetStatus } = args;
	const singleInventoryItems = await Promise.all(
		itemCodes.map((code) => InventoryItemApi.get.singleItemByCode(api, { shopId, code })),
	);
	await Promise.all(
		singleInventoryItems
			.filter(notUndefined)
			.map(({ id: itemId }) =>
				InventoryItemApi.set.updateSingleInventoryItemStatus(api, itemId, targetStatus),
			),
	);
};

export const changeBulkInventoryItemStatusAndAllocations = async (
	api: Api,
	args: {
		shopId: string;
		locationId: string;
		skuId: string;
		amount: number;
		from: InventoryStatus;
		to: InventoryStatus;
	},
) => {
	const { shopId, locationId, skuId, amount, from, to } = args;
	const inventoryItem = await InventoryItemApi.get.bulkItemBySkuId(api, {
		shopId,
		locationId,
		skuId,
	});

	await InventoryItemApi.set.updateBulkInventoryItemProperties({
		api: api,
		itemId: inventoryItem.id,
		fields: {
			...changeBulkInventoryItemStatus({
				api,
				itemId: inventoryItem.id,
				from,
				to,
				amount,
			}),
			...changeBulkInventoryItemAllocations({
				api,
				allocationToChange: 'sales',
				amount: amount * getAllocationMultiplier({ from, to }),
			}),
		},
	});
};

const getAllocationMultiplier = (args: { from: InventoryStatus; to: InventoryStatus }) => {
	const { from, to } = args;
	const itemSaleWasCancelled = from === 'SOLD' && to !== 'SOLD';
	const itemWasSold = from !== 'SOLD' && to === 'SOLD';
	return itemSaleWasCancelled ? 1 : itemWasSold ? -1 : 0;
};

export const handleInventoryItemStatusChanges = async (
	api: Api,
	args: {
		shopId: string;
		itemCodes: string[];
		products: OrderProduct[];
		currentStatus: InventoryStatus;
		targetStatus: InventoryStatus;
	},
) => {
	const { shopId, itemCodes, products, currentStatus, targetStatus } = args;

	if (!!itemCodes.length) {
		await handleSingleItemStatusChange(api, {
			shopId,
			itemCodes,
			targetStatus,
		});
	}

	if (!!products.length) {
		const locationId = products[0].startLocationId;
		const [setProducts, normalProducts] = partition(products, (p) => !!p.set);
		const productsInSet = setProducts.flatMap((s) => s.setProducts) as OrderProduct[];

		const bulkItemsBySkuId = getBulkItemsSkuIdPairsFromOrderProducts(normalProducts);
		const setBulkItemsBySkuId = getBulkItemsSkuIdPairsFromOrderProducts(productsInSet);

		await Promise.all(
			[...bulkItemsBySkuId, ...setBulkItemsBySkuId].map(([skuId, items]) =>
				changeBulkInventoryItemStatusAndAllocations(api, {
					shopId,
					locationId,
					skuId,
					amount: items.length,
					from: currentStatus,
					to: targetStatus,
				}),
			),
		);
	}
};
