import { AddOn, AddOns } from 'common/modules/plans';

import AnalyticsIcon from './ANALYTICS/icon.svg';
import BrandingIcon from './BRANDING/icon.svg';
import CategoryTermsIcon from './CATEGORY_TERMS/icon.svg';
import CloudPosIcon from './CLOUD_POS/icon.svg';
import DashboardIcon from './DASHBOARD/icon.svg';
import DinSettingsIcon from './DIN_SETTINGS/icon.svg';
import DiscountCodesIcon from './DISCOUNT_CODES/icon.svg';
import ExtensionsIcon from './EXTENSIONS/icon.svg';
import MaintenanceTimeIcon from './MAINTENANCE_TIME/icon.svg';
import ManualPaymentMethodsIcon from './MANUAL_PAYMENT_METHODS/icon.svg';
import MassReturnIcon from './MASS_RETURN/icon.svg';
import PackageProductsIcon from './PACKAGE_PRODUCTS/icon.svg';
import ProductVariantsIcon from './PRODUCT_VARIANTS/icon.svg';
import SkidataIcon from './SKIDATA/icon.svg';
import TeksoIcon from './TEKSO/icon.svg';

export const getAddOnIcon = (addOn: AddOn) => {
	const icons: Record<AddOn, string | null> = {
		[AddOns.EXTENSIONS]: ExtensionsIcon,
		[AddOns.GOOGLE_ANALYTICS]: AnalyticsIcon,
		[AddOns.BRANDING]: BrandingIcon,
		[AddOns.PACKAGE_PRODUCTS]: PackageProductsIcon,
		[AddOns.PRODUCT_VARIANTS]: ProductVariantsIcon,
		[AddOns.MASS_RETURN]: MassReturnIcon,
		[AddOns.DIN_SETTINGS]: DinSettingsIcon,
		[AddOns.DISCOUNT_CODES]: DiscountCodesIcon,
		[AddOns.MAINTENANCE_TIME]: MaintenanceTimeIcon,
		[AddOns.CATEGORY_TERMS]: CategoryTermsIcon,
		[AddOns.MANUAL_PAYMENT_METHODS]: ManualPaymentMethodsIcon,
		[AddOns.DASHBOARD]: DashboardIcon,
		[AddOns.SKIDATA]: SkidataIcon,
		[AddOns.TEKSO]: TeksoIcon,
		[AddOns.CLOUD_POS]: CloudPosIcon,
	};
	return icons[addOn];
};
