import { SkidataPackageProductApi } from 'common/modules/skidata/types';

import { ProductApi, StartTimeFixed, StartTimeInterval, StartTimeSlots } from './common';

export const isInterval = (startTimeSlot: StartTimeSlots): startTimeSlot is StartTimeInterval => {
	return startTimeSlot.type === 'interval';
};

export const isFixed = (startTimeSlot: StartTimeSlots): startTimeSlot is StartTimeFixed => {
	return startTimeSlot.type === 'fixed';
};

export const isSkidataPackageProduct = (
	product: ProductApi | SkidataPackageProductApi,
): product is SkidataPackageProductApi => {
	return !!(product as SkidataPackageProductApi).packageItems;
};
