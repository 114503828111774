import { createQueryKeys } from '@lukemorales/query-key-factory';

import { Api } from 'common/core-api';
import { PagedInventoryArticleEventsDTO } from 'common/core-api/generated';

import { createUseQuery, fnQueryKeys } from './utils';

const queryKeys = createQueryKeys('inventoryEvents', {
	get: fnQueryKeys(Api.inventoryArticleEvents.get),
	list: fnQueryKeys(Api.inventoryArticleEvents.list),
	listAll: fnQueryKeys(Api.inventoryArticleEvents.listAll),
});

export const get = createUseQuery(Api.inventoryArticleEvents.get, queryKeys.get);
export const list = createUseQuery(
	Api.inventoryArticleEvents.list,
	queryKeys.list,
	({ data }) => data as PagedInventoryArticleEventsDTO['data'],
);
export const listAll = createUseQuery(Api.inventoryArticleEvents.listAll, queryKeys.listAll);
