import React from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Theme, darken } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import HeaderBackground from 'common/assets/dialog-stripes.svg';

import Spinner from './Spinner';
import StripePattern from './StripePattern';

interface Props {
	title: string | JSX.Element;
	children?: any;
	headerVariant?: 'text' | 'contained';
	btnActions?: {
		primary: {
			text: string;
			loading?: boolean;
			disabled?: boolean;
			onClick: () => void;
		};
		secondary?: {
			text: string;
			loading?: boolean;
			disabled?: boolean;
			onClick: () => void;
		};
	};
	btnActionsEl?: React.ReactNode;
	onClose: () => void;
	variant?: 'info' | 'danger';
}

const ActionDialog = (props: Props) => {
	const { onClose, title, btnActions, btnActionsEl, children } = props;
	const { classes, cx } = useStyles(props);
	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle className={classes.title} sx={{ position: 'relative' }}>
				<StripePattern color="dark" opacity={0.4} />
				{title}
			</DialogTitle>
			<Box p={1}>
				<DialogContent>{children}</DialogContent>
				{btnActions && (
					<DialogActions className={classes.btnActions}>
						{btnActions.secondary ? (
							<Button
								onClick={btnActions.secondary.onClick}
								disabled={btnActions.secondary.disabled}
								color="secondary"
								variant="text"
							>
								{btnActions.secondary.loading ? (
									<Spinner padding={8} thickness={4} color="white" />
								) : (
									btnActions.secondary.text
								)}
							</Button>
						) : null}
						<Button
							onClick={btnActions.primary.onClick}
							disabled={btnActions.primary.disabled}
							color="primary"
							variant="contained"
							className={cx({ [classes.redButton]: props.variant === 'danger' })}
						>
							{btnActions.primary.loading ? (
								<Spinner padding={8} thickness={4} color="white" />
							) : (
								btnActions.primary.text
							)}
						</Button>
					</DialogActions>
				)}
				{!btnActions && !!btnActionsEl ? btnActionsEl : null}
			</Box>
		</Dialog>
	);
};

const useStyles = makeStyles<Props>()((theme: Theme, props) => ({
	title: {
		backgroundColor:
			props.variant === 'danger'
				? theme.palette.error.main
				: props.headerVariant === 'contained'
				? theme.palette.primary.main
				: 'transparent',
		color: props.headerVariant === 'contained' ? theme.palette.common.white : 'unset',
		padding: props.headerVariant === 'contained' ? theme.spacing(3) : theme.spacing(2, 3),
		backgroundImage: props.variant === 'danger' ? `url("${HeaderBackground}")` : 'none',
	},
	btnActions: {
		padding: theme.spacing(2),
	},
	redButton: {
		color: '#fff',
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: darken(theme.palette.error.main, 0.3),
		},
	},
}));

export default ActionDialog;
