import * as React from 'react';

import { Provider } from 'react-redux';
import reducers from 'reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(ReduxThunk)));

const ReduxRoot = ({ children }: any) => {
	return <Provider store={store}>{children}</Provider>;
};

export default ReduxRoot;
