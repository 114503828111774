import { useCallback } from 'react';

import { StringMap, TOptions } from 'i18next';
import { UseTranslationOptions, useTranslation as useTransalationHook } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AdminKey, CommonKey } from 'common/locales/utils/TranslationKeys';
import { getTranslation } from 'common/modules/translations';
import { LocaleField } from 'common/types';
import * as LanguageSelectors from 'selectors/LanguageSelectors';

export function useTranslation<TInterpolationMap extends object = StringMap>(
	options?: UseTranslationOptions,
) {
	const language = useSelector(LanguageSelectors.language);
	const { t: _t, i18n: _i18n, ready: _ready } = useTransalationHook<string[]>(
		['admin', 'common'],
		options,
	);

	const _getTranslation = useCallback(
		(locale: LocaleField | undefined) => {
			return getTranslation(locale, language);
		},
		[language],
	);

	const t = useCallback(
		(
			key: AdminKey | CommonKey | (CommonKey | AdminKey)[],
			options?: TOptions<TInterpolationMap> | string,
		) => {
			return _t<string>(key, options);
		},
		[_t],
	);

	return {
		t,
		i18n: _i18n,
		ready: _ready,
		getTranslation: _getTranslation,
		lang: language,
	};
}
