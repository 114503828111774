/**
 * Main feature listings
 */

import { Plan } from 'common/modules/plans/plans';
import {
	ADVANCED_AND_ABOVE,
	ADVANCE_AND_ABOVE,
	BASIC_AND_ABOVE,
	BUILD_AND_ABOVE,
	DISCOVER_AND_ABOVE,
	FREE_AND_ABOVE,
	GROW_AND_ABOVE,
	LITE_AND_ABOVE,
} from 'common/modules/plans/plans/constants';
import { Permission, Role } from 'common/types';

import { AddOn } from '../add-ons/types';
import { RestrictedFeature } from './types';

interface FeatureDefinition {
	/**
	 * A human-readable description of what the feature does
	 */
	description: string;

	/**
	 * Which plans this feature is included in
	 *
	 */
	plans: 'all' | 'none' | Plan[];

	/**
	 * Which add-ons this feature is included in
	 *
	 */
	addOns?: AddOn[];

	/**
	 * Which roles are required to access this feature
	 *
	 * - The user must have all listed roles to access this feature
	 * - If not defined, all roles have access to the feature
	 */
	roles?: Role[];

	/**
	 * Which permissions are required to access this feature
	 *
	 * - The user must have all listed permissions to access this feature
	 * - If not defined, no permissions are needed to access the feature
	 */
	permissions?: Permission[];

	/**
	 * The feature will be enabled by default if running rentle in a non-production environment
	 */
	dev?: boolean;

	/**
	 * The feature flag is only for internal use, and should not be manually configurable via internal-admin or other admin UIs
	 */
	internal?: boolean;
}

export const restrictedFeatures: Record<RestrictedFeature, FeatureDefinition> = {
	/**
	 * Features available to all users, regardless of plan or role
	 */
	CUSTOMIZER: {
		description: 'Ability to customize the online store',
		plans: 'all',
	},
	/**
	 * Features restricted by permission
	 */
	DOMAINS: {
		description: 'Edit account and store domains',
		plans: 'all',
		roles: ['manager'],
	},
	EDIT_CATEGORIES: {
		description: 'Access to create, edit and delete categories',
		plans: 'all',
		roles: ['admin'],
	},
	INTEGRATIONS: {
		description: 'Access to edit Integrations',
		plans: 'all',
		roles: ['admin'],
	},
	LEGAL_SETTINGS: {
		description: 'Access to edit legal information',
		plans: 'all',
		roles: ['admin'],
	},
	LOCALIZATION: {
		description: 'Access to edit Localization settings',
		plans: 'all',
		roles: ['admin'],
	},
	MODIFY_PAYMENTS: {
		description: 'User can do payment modification actions - refund payments or capture deposits',
		permissions: ['modify_payments'],
		plans: 'all',
	},
	NOTIFICATIONS: {
		description: 'Access to edit Notifications',
		plans: 'all',
		roles: ['admin'],
	},
	PAYMENT_SETTINGS: {
		description: 'Access to edit Shop payment settings',
		plans: 'all',
		roles: ['admin'],
	},
	PLAN_BILLING: {
		description: 'Access to Plan and Billing',
		plans: 'all',
		roles: ['admin'],
	},
	PRODUCTS: {
		description: 'Access to Products view',
		plans: 'all',
		roles: ['editor'],
	},
	REPORTS: {
		description: 'Access to Reports view',
		plans: 'all',
		roles: ['manager'],
	},
	STORE_SETTINGS: {
		description: 'Access to Store settings',
		plans: 'all',
		roles: ['manager'],
	},
	STORES: {
		description: 'Access to edit stores',
		plans: 'all',
		roles: ['admin'],
	},
	TAXES: {
		description: 'Access to edit Tax settings',
		plans: 'all',
		roles: ['admin'],
	},
	INVENTORY: {
		description: 'Access to Inventory view',
		plans: 'all',
		roles: ['editor'],
	},
	ADD_ONS: {
		description: 'Access to purchase and manage Add-ons',
		plans: 'all',
		roles: ['admin'],
	},
	EDIT_LANGUAGES: {
		description:
			'Permission: access to edit which languages are enabled and editing the default/source language for translations',
		plans: 'all',
		roles: ['admin'],
	},
	EDIT_TRANSLATIONS: {
		description: 'Permission:access to edit translations for a shop',
		plans: 'all',
		roles: ['editor'],
	},
	NEW_RENTAL: {
		description: 'Access to New Booking view',
		plans: [...GROW_AND_ABOVE, ...FREE_AND_ABOVE],
	},
	ADMIN_CREATE_ORDER: {
		description: 'Ability to manually create orders',
		plans: [...GROW_AND_ABOVE, ...DISCOVER_AND_ABOVE],
	},
	BOOKING_LINK: {
		description: 'Ability to send booking links without payments',
		plans: [...GROW_AND_ABOVE, ...DISCOVER_AND_ABOVE],
	},
	PAYMENT_LINK: {
		description: 'Ability to send payment links',
		plans: [...GROW_AND_ABOVE, ...FREE_AND_ABOVE],
	},
	USERS: {
		description: 'Can add more users to shop',
		plans: 'all',
		roles: ['manager'],
	},
	HIDE_POWERED_BY_RENTLE: {
		description: 'Can hide powered by Rentle',
		plans: [...BASIC_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	/**
	 * Features included only in specific plans
	 */
	SEGMENTS: {
		description: 'Show segments in product pricing options',
		plans: 'none',
	},
	CHECK_IN: {
		description: 'Show check-in related settings',
		plans: [...ADVANCED_AND_ABOVE, ...ADVANCE_AND_ABOVE],
	},
	CUSTOM_DOMAINS: {
		description: 'Enable store to add custom domains',
		plans: [...BASIC_AND_ABOVE, ...DISCOVER_AND_ABOVE],
	},
	BETA_API: {
		description: 'Use Rentle API and can create API keys',
		roles: ['admin'],
		plans: [...BASIC_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	WEBHOOKS: {
		description: 'Access Webhooks settings under Integrations',
		roles: ['admin'],
		plans: [...BASIC_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	LANGUAGES: {
		description: 'Ability to have languages and translations',
		roles: ['manager'],
		plans: [...BASIC_AND_ABOVE, ...FREE_AND_ABOVE],
	},
	USER_ROLES: {
		description: 'Ability to assign custom user roles',
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	DEPOSITS: {
		description: 'Shop can enable manual deposits',
		plans: [...LITE_AND_ABOVE, ...DISCOVER_AND_ABOVE],
	},
	AUTOMATED_DEPOSITS: {
		description: 'Shop can enable automated deposits',
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	AUTOMATED_EMAILS: {
		description: 'Shop can enable and disabled automated emails',
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	PRODUCT_RECOMMENDATIONS: {
		description: 'Show product recommendations in product settings',
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	CUSTOMER_DATA: {
		description: 'Show customer page in sidebar',
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	RECEIPT_CUSTOMIZATION: {
		description: 'Show receipt customization in store settings',
		plans: [...LITE_AND_ABOVE, ...BUILD_AND_ABOVE],
	},
	// Contact support is communicated only from Discover onwards,
	// but we still want to show it in Free plan for now
	CONTACT_SUPPORT: {
		description: 'Can contact support from Rentle admin',
		plans: [...LITE_AND_ABOVE, ...FREE_AND_ABOVE],
	},
	/**
	 * Features only enabled by request, or beta features
	 */
	OSKAR_POS: {
		description: 'Enable Oskar POS integration',
		plans: 'none',
		roles: ['admin'],
	},
	CUSTOM_ACCOUNTING_GROUPS: {
		description: 'Shop can create custom accounting groups',
		plans: 'none',
		roles: ['manager'],
	},
	DISABLE_ONLINE_LOCATIONS_PAGE: {
		description:
			'Disable the location select page in the online store for multi-location stores (redirect to main location instead)',
		plans: 'none',
	},
	BOOKINGS_VIEW_ALL_LOCATIONS_FILTER: {
		description: 'Adds possibility to filter all locations in the Bookings view',
		plans: 'none',
	},
	POS: {
		description: 'Shop can enable POS functionality',
		plans: 'none',
	},
	DISABLE_CHECK_IN_VARIANTS: {
		description: 'Disable variant selection in check-in',
		plans: 'none',
	},
	DISABLE_PRODUCT_AVAILABILITY_LOADING: {
		description:
			'Disable product availability loading for a shop in some admin views. Intended as a temporary fix to performance issues while the issue is being resolved.',
		plans: 'none',
	},
	DISABLE_CHECK_IN_AVAILABILITIES: {
		description: 'Disable availabilities checks in check-in, to improve performance',
		plans: 'none',
	},
	DISABLE_CHECK_IN_DURATION_OPENING_HOURS_CHECK: {
		description:
			'Disable opening hours checks in check-in duration selector. Temporary fix to allow selling e.g. 3 hour durations for the last 2 hours of opening times.',
		plans: 'none',
	},
	HIDE_RENTAL_LINK_BUTTON: {
		description:
			'Hide the booking link button in admin. Temporary fix for disabling the booking link usage until further development of the booking link.',
		plans: 'none',
	},
	HIDE_ONBOARDING_LIST: {
		description: 'Hide the onboarding list in admin.',
		plans: 'none',
	},
	ADDITIONAL_CUSTOM_DOMAIN: {
		description:
			'Enable store to add an additional custom domain (intended for temporary use, e.g. when store wants to move to a new domain)',
		plans: 'none',
		// This is enabled by default in DEV stores, so that all dev stores can have max. 2 domains instead of just 1
		dev: true,
	},
	CAN_APPLY_SHOP_TEMPLATE: {
		description: 'A shop template can be applied to store',
		plans: 'none',
	},
	CAN_BE_SHOP_TEMPLATE: {
		description: 'A shop template can be created based on store',
		plans: 'none',
	},
	PLAN_PAUSED: {
		description: 'Shop is paused',
		plans: 'none',
	},
	/**
	 * Add-ons
	 */
	MANUAL_PAYMENT_METHODS: {
		description: 'Create customizable payment methods for Admin and Online',
		plans: 'none',
		addOns: ['MANUAL_PAYMENT_METHODS'],
		roles: ['admin'],
	},
	SET_PRODUCT: {
		description: 'Option to create Set products',
		plans: 'none',
		roles: ['editor'],
		addOns: ['PACKAGE_PRODUCTS'],
	},
	VARIANTS: {
		description: 'Can add variants for products',
		plans: 'none',
		roles: ['editor'],
		addOns: ['PRODUCT_VARIANTS'],
	},
	BRANDING: {
		description: 'Custom branding options',
		plans: 'none',
		roles: ['admin'],
		addOns: ['BRANDING'],
	},
	CUSTOM_CHECKOUT_FIELD: {
		description: 'Ask your customers an custom question during checkout',
		plans: 'all',
		roles: ['editor'],
	},
	CLOUD_POS: {
		description: 'Enable Solteq Commerce Cloud integration',
		plans: 'none',
		roles: ['admin'],
		addOns: ['CLOUD_POS'],
	},
	MAINTENANCE_TIME: {
		description: 'Maintenance time settings',
		plans: 'none',
		roles: ['editor'],
		addOns: ['MAINTENANCE_TIME'],
	},
	CATEGORY_TERMS: {
		description: 'Can set own terms for categories',
		plans: 'none',
		roles: ['editor'],
		addOns: ['CATEGORY_TERMS'],
	},
	DELIVERY_SERVICE: {
		description: 'Delivery module for admin and online.',
		roles: ['manager'],
		plans: [...BASIC_AND_ABOVE, ...FREE_AND_ABOVE],
	},
	DIN_SETTINGS: {
		description: 'Show DIN settings option in Product settings',
		roles: ['editor'],
		addOns: ['DIN_SETTINGS'],
		plans: 'none',
	},
	DISCOUNTS: {
		description: 'Shop can access Discounts tab in product settings',
		plans: 'none',
		roles: ['editor'],
		addOns: ['DISCOUNT_CODES'],
	},
	DASHBOARD: {
		description: 'Access to Dashboard view',
		plans: 'none',
		roles: ['manager'],
		addOns: ['DASHBOARD'],
	},
	SKIDATA: {
		description: 'Enable Skidata integration',
		plans: 'none',
		roles: ['admin'],
		addOns: ['SKIDATA'],
	},
	TEKSO_POS: {
		description: 'Enable Tekso POS integration',
		plans: 'none',
		roles: ['admin'],
		addOns: ['TEKSO'],
	},
	BETA_EXTENSIONS: {
		description: 'Use booking extensions feature',
		plans: 'none',
		addOns: ['EXTENSIONS'],
	},
	GOOGLE_ANALYTICS: {
		description: 'Store can set their own GA tags',
		plans: 'none',
		addOns: ['GOOGLE_ANALYTICS'],
	},
	MASS_RETURN: {
		description: 'Access to Mass Return button in Bookings view',
		plans: 'none',
		addOns: ['MASS_RETURN'],
	},
	SHOW_ITEM_CODES_FROM_ALL_STORES: {
		description:
			'When selecting an item code in order view, show codes also from other the inventory of other stores',
		plans: 'none',
	},
	/**
	 * To be made into a block, will be added as a feature flag for all current Core customers
	 */
	INVENTORY_USAGE_HISTORY: {
		description: 'See usage history of inventory items',
		plans: 'none',
	},

	DECATHLON_LOGIN: {
		description: 'Use Decathlon login in online store',
		plans: 'none',
	},
	MOCK_LOGIN_INTERNAL: {
		description: 'Internal feature for mock online login',
		plans: 'none',
	},
	BLOCK_ONLINE_PAYMENTS: {
		description: 'Block all online payments from merchant',
		plans: 'none',
	},

	/** Internal helpers */
	DEV: {
		description: 'Internal feature for dev-specific features',
		plans: 'none',
		dev: true,
		internal: true,
	},
	INVENTORY_TRANSFERS: {
		description: 'Internal feature flag for transfers feature',
		plans: 'none',
		dev: true,
		internal: true,
	},
	INVENTORY_EVENT_CAPTURE: {
		description: 'Generate inventory article events from changes to Orders',
		plans: 'none',
		dev: true,
	},
	INVENTORY_EVENT_VIEW: {
		description: 'Ability to view inventory article events',
		plans: 'none',
		dev: false,
	},
};

export const restrictedFeatureKeys = Object.keys(restrictedFeatures) as RestrictedFeature[];
