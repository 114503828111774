import { useCallback, useEffect, useState } from 'react';

import { usePostHog } from 'posthog-js/react';
import { useSelector } from 'react-redux';

import { EcomEventName, EventName, Events } from 'common/services/analytics';
import { shouldCapturePosthogEvents } from 'common/services/analytics/posthog';
import { CustomerPersonas } from 'common/services/analytics/tractionAnalytics';
import * as UserflowService from 'common/services/analytics/userflow';
import { removeUndefinedValues } from 'common/utils/common';
import { getDateStringAsNumber } from 'common/utils/dateUtils';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';

type UsePosthogOptions = {
	identify: boolean;
};

export const usePosthog = (options?: UsePosthogOptions) => {
	const posthog = usePostHog();
	const userEmail = useSelector(UserSelectors.activeUserEmail);
	const userId = useSelector(UserSelectors.activeUserId);
	const userShopId = useSelector(UserSelectors.userActiveShopId);
	const activeShopName = useSelector(ShopSelectors.activeShopName);
	const activeShopCountry = useSelector(ShopSelectors.activeShopCountry);
	const activeShopPlan = useSelector(ShopSelectors.shopPlan);
	const activeShopAnalytics = useSelector(ShopSelectors.activeShopAnalytics);
	const activeShopInfo = useSelector(ShopSelectors.activeShopInfo);
	const activeShopWebsite = useSelector(ShopSelectors.shopWebsiteUrl);
	const activeShopDefaultLanguage = useSelector(ShopSelectors.activeShopDefaultLanguage);

	const [userIdentified, setUserIdentified] = useState(false);

	const hasEnabledCapture = shouldCapturePosthogEvents();

	const newPosthogEvent = useCallback(
		<T extends Exclude<EventName, EcomEventName>>(name: T, params?: Events[T]) => {
			if (hasEnabledCapture) {
				posthog?.capture(name, params);
			}
		},
		[hasEnabledCapture, posthog],
	);

	const resetPosthog = useCallback(() => {
		if (!userIdentified) return;
		posthog?.reset();
		setUserIdentified(false);
	}, [posthog, userIdentified]);

	const getFeatureFlagKey = useCallback(
		(name: string) => {
			return posthog?.getFeatureFlag(name);
		},
		[posthog],
	);

	const isPostHogFeatureEnabled = useCallback(
		(name: string) => {
			return posthog?.isFeatureEnabled(name);
		},
		[posthog],
	);

	useEffect(() => {
		if (!userIdentified) return;
		posthog?.featureFlags.onFeatureFlags(() => {
			const hasUserflowChecklist =
				getFeatureFlagKey('use-userflow-onboarding-checklist') === 'use-userflow';
			if (hasUserflowChecklist) {
				UserflowService.updateUser({
					useUserflowChecklist: hasUserflowChecklist,
				});
			}
		});
	}, [getFeatureFlagKey, posthog?.featureFlags, userIdentified]);

	useEffect(() => {
		if (
			options?.identify &&
			!!userId &&
			!!userShopId &&
			!!userEmail &&
			!!activeShopPlan &&
			!!activeShopCountry &&
			!!activeShopInfo?.createdAt
		) {
			const sharedData = removeUndefinedValues({
				merchant_plan: activeShopPlan,
				merchant_created_at: activeShopInfo?.createdAt,
				merchant_creation_date: getDateStringAsNumber(activeShopInfo?.createdAt), // Needed currently for cohort analysis, as we can't compare dates in the cohort
				merchant_country: activeShopCountry,
				merchant_profile: activeShopAnalytics?.merchantProfile,
				merchant_industries: activeShopAnalytics?.industryCategories?.values.join(','),
				merchant_customer_persona: activeShopAnalytics?.customerPersona?.value,
				merchant_customer_persona_specification:
					activeShopAnalytics?.customerPersona?.specifications?.[CustomerPersonas.REPLACING],
				merchant_revenue: activeShopAnalytics?.revenue,
				merchant_website: activeShopWebsite,
				merchant_language: activeShopDefaultLanguage,
			});
			const userData = {
				email: userEmail,
				...sharedData,
			};
			posthog?.identify(userId, userData);
			const merchantData = {
				...(!!activeShopName && { name: activeShopName }),
				merchant_id: userShopId,
				...sharedData,
			};
			posthog?.group('merchant', userShopId, merchantData);
			setUserIdentified(true);
		}
	}, [
		activeShopAnalytics?.customerPersona?.specifications,
		activeShopAnalytics?.customerPersona?.value,
		activeShopAnalytics?.industryCategories,
		activeShopAnalytics?.merchantProfile,
		activeShopAnalytics?.revenue,
		activeShopCountry,
		activeShopDefaultLanguage,
		activeShopInfo?.createdAt,
		activeShopName,
		activeShopPlan,
		activeShopWebsite,
		options?.identify,
		posthog,
		userEmail,
		userId,
		userShopId,
	]);

	return {
		newPosthogEvent,
		resetPosthog,
		getFeatureFlagKey,
		isPostHogFeatureEnabled,
		userIdentified,
	};
};
