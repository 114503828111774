import { createAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { isEqual } from 'lodash';

import { api } from 'common/frontend/api';
import { isCustomShopPaymentMethodObject } from 'common/modules/payments/paymentMethods';
import {
	CustomContentTranslatableKey,
	Languages,
	LocaleField,
	TranslatableField,
} from 'common/types';
import { SaveTranslationPayload, TranslationsState } from 'reducers/TranslationsReducer';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as StockSelectors from 'selectors/StockSelectors';
import * as TranslationsSelectors from 'selectors/TranslationsSelectors';
import { ReduxState } from 'services/types';
import { createAppThunk } from 'services/utils/redux';

export const setLanguage = createAction<Languages>('Translations/SET_LANGUAGE');
export const setDefaultLanguage = createAction<Languages>('Translations/SET_DEFAULT_LANGUAGE');
export const setTranslations = createAction<TranslationsState['translations']>(
	'Translations/SET_TRANSLATIONS',
);

export const saveTranslation = createAppThunk<void, SaveTranslationPayload, { state: ReduxState }>(
	'Translations/SAVE_TRANSLATION',
	async (args, thunkAPI) => {
		await saveFieldValue(args.field, thunkAPI.getState());
	},
);

export const retryAllFailedSaves = createAppThunk(
	'Translations/RETRY_FAILED_SAVES',
	async (args, thunkAPI) => {
		const state = thunkAPI.getState();
		const errors = TranslationsSelectors.errorFields(state);
		await Promise.all(errors.map((field) => thunkAPI.dispatch(saveTranslation(field))));
	},
);

const saveFieldValue = async (field: TranslatableField, state: ReduxState) => {
	const fieldValue = field.value;
	if (!fieldValue?.def) return;

	switch (field.opts.type) {
		case 'productName': {
			const { productId } = field.opts.args;
			return api().products.doc(productId).update({
				name: fieldValue,
			});
		}
		case 'productDescription': {
			const { productId } = field.opts.args;
			return api().products.doc(productId).update({
				description: fieldValue,
			});
		}
		case 'productVariantProperty': {
			const { productId, propertyId } = field.opts.args;
			const product = StockSelectors.stockProductsById(state)[productId];
			if (!product?.variants.properties) return;

			const updatedVariantProperties = product.variants.properties.map((property) =>
				property.id === propertyId ? { ...property, name: fieldValue } : property,
			);

			const hasChanged = !isEqual(updatedVariantProperties, product.variants.properties);

			return (
				hasChanged &&
				api().products.doc(productId).update({
					'variants.properties': updatedVariantProperties,
				})
			);
		}
		case 'productVariantValue': {
			const { productId, propertyId, originalValue } = field.opts.args;
			const product = StockSelectors.stockProductsById(state)[productId];
			if (!product) return;

			const changes: any[] = [];

			const updatedProductVariants = product.variants.options.map((variant) => {
				if (variant.values[propertyId]?.def === originalValue) {
					const newVariant = {
						...variant,
						values: {
							...variant.values,
							[propertyId]: fieldValue,
						},
					};

					changes.push(newVariant);

					return newVariant;
				}

				return variant;
			});

			const hasChanged = !isEqual(updatedProductVariants, product.variants.options);

			return (
				hasChanged &&
				api().products.doc(productId).update({
					'variants.options': updatedProductVariants,
				})
			);
		}
		case 'productPricingLabel': {
			const { productId, index } = field.opts.args;
			const product = StockSelectors.stockProductsById(state)[productId];
			if (!product?.pricing) return;

			const updatedProductPricing = produce(product.pricing, (draft) => {
				draft[index].label = fieldValue;
			});

			const hasChanged = !isEqual(updatedProductPricing, product.pricing);

			return (
				hasChanged &&
				api().products.doc(productId).update({
					pricing: updatedProductPricing,
				})
			);
		}
		case 'productSegmentPricingLabel': {
			const { productId, segmentPricingIndex, index } = field.opts.args;
			const product = StockSelectors.stockProductsById(state)[productId];

			const updatedProductSegmentPricings = produce(product.segmentPricings, (draft) => {
				const row = draft?.[segmentPricingIndex]?.pricingTable?.[index];
				if (!!row?.label?.def) row.label = fieldValue;
			});

			const hasChanged = !isEqual(updatedProductSegmentPricings, product.segmentPricings);

			return (
				hasChanged &&
				api().products.doc(productId).update({
					segmentPricings: updatedProductSegmentPricings,
				})
			);
		}
		case 'categoryName': {
			const { categoryId } = field.opts.args;
			return api().productCategories.doc(categoryId).update({
				name: fieldValue,
			});
		}
		case 'categoryDescription': {
			const { categoryId } = field.opts.args;
			return api().productCategories.doc(categoryId).update({
				description: fieldValue,
			});
		}
		case 'storeDescription': {
			const { shopId, storeId } = field.opts.args;
			const shopPublicInfo = ShopSelectors.activeShopPublicInfo(state);
			if (!shopPublicInfo) return;

			if (storeId === shopPublicInfo.visitingAddress?.id) {
				return api().shops.doc(shopId).public.update({
					'visitingAddress.description': fieldValue,
				});
			} else if (!!shopPublicInfo.shopLocations) {
				const updatedShopLocations = produce(shopPublicInfo.shopLocations, (draft) => {
					const storeToUpdate = draft.find((s) => s.id === storeId);
					if (!storeToUpdate) return;
					storeToUpdate.description = fieldValue;
				});

				const hasChanged = !isEqual(updatedShopLocations, shopPublicInfo.shopLocations);
				return (
					hasChanged &&
					api().shops.doc(shopId).public.update({
						shopLocations: updatedShopLocations,
					})
				);
			}
			break;
		}
		case 'storeAdditionalInformation': {
			const { shopId, storeId } = field.opts.args;
			const shopPublicInfo = ShopSelectors.activeShopPublicInfo(state);
			if (!shopPublicInfo) return;

			if (storeId === shopPublicInfo.visitingAddress?.id) {
				return api().shops.doc(shopId).public.update({
					'visitingAddress.additionalInformation': fieldValue,
				});
			} else if (!!shopPublicInfo.shopLocations) {
				const updatedAdditionalInformation = produce(shopPublicInfo.shopLocations, (draft) => {
					const storeToUpdate = draft.find((s) => s.id === storeId);
					if (!storeToUpdate) return;
					storeToUpdate.additionalInformation = fieldValue;
				});

				const hasChanged = !isEqual(updatedAdditionalInformation, shopPublicInfo.shopLocations);
				return (
					hasChanged &&
					api().shops.doc(shopId).public.update({
						shopLocations: updatedAdditionalInformation,
					})
				);
			}
			return;
		}
		case 'storeCheckoutCommentField': {
			const { shopId, storeId } = field.opts.args;
			return api()
				.shops.doc(shopId)
				.public.update({
					[`checkoutCommentField.${storeId}.label` as `checkoutCommentField.${123}.label`]: fieldValue,
				});
		}
		case 'storeInStoreEndText': {
			const { shopId, storeId, index } = field.opts.args;
			const shopPublicInfo = ShopSelectors.activeShopPublicInfo(state);
			if (!shopPublicInfo) return;

			if (storeId === shopPublicInfo.visitingAddress?.id) {
				const inStoreEndText = produce(shopPublicInfo.inStoreEndText?.defaultValue, (draft) => {
					if (!!draft?.[index]?.text?.def) draft[index].text = fieldValue;
				});

				const hasChanged = !isEqual(inStoreEndText, shopPublicInfo.inStoreEndText?.defaultValue);
				if (!inStoreEndText) return;
				return (
					hasChanged &&
					api().shops.doc(shopId).public.update({
						'inStoreEndText.defaultValue': inStoreEndText,
					})
				);
			} else {
				const inStoreEndText = produce(
					shopPublicInfo.inStoreEndText?.location?.[storeId],
					(draft) => {
						if (!!draft?.[index]?.text?.def) draft[index].text = fieldValue;
					},
				);

				const hasChanged = !isEqual(inStoreEndText, shopPublicInfo.inStoreEndText?.location);
				if (!inStoreEndText) return;
				return (
					hasChanged &&
					api()
						.shops.doc(shopId)
						.public.update({
							[`inStoreEndText.location.${storeId}` as `inStoreEndText.location.${123}`]: inStoreEndText,
						})
				);
			}
		}
		case 'pricingTableLabel': {
			const { shopId, index, pricingTableName } = field.opts.args;
			const shopPublicInfo = ShopSelectors.activeShopPublicInfo(state);
			if (!shopPublicInfo) return;

			const pricingTables = produce(shopPublicInfo.pricingTables, (draft) => {
				const row = draft?.[pricingTableName]?.[index];
				if (!!row?.label?.def) row.label = fieldValue;
			});

			const hasChanged = !isEqual(pricingTables, shopPublicInfo.pricingTables);

			if (!pricingTables) return;

			return (
				hasChanged &&
				api().shops.doc(shopId).public.update({
					pricingTables,
				})
			);
		}
		case 'marketingConsentText': {
			const { shopId } = field.opts.args;
			const shopPublicInfo = ShopSelectors.activeShopPublicInfo(state);
			if (!shopPublicInfo?.marketingConsentText) return;

			return api()
				.shops.doc(shopId)
				.public.update({
					marketingConsentText: {
						...shopPublicInfo.marketingConsentText,
						text: fieldValue,
					},
				});
		}
		case 'deliveryOptionName': {
			const { deliveryOptionId } = field.opts.args;

			return api().deliveryOptions.doc(deliveryOptionId).update({
				name: fieldValue,
			});
		}
		case 'deliveryOptionDescription': {
			const { deliveryOptionId } = field.opts.args;

			return api().deliveryOptions.doc(deliveryOptionId).update({
				description: fieldValue,
			});
		}
		case 'deliveryOptionReturnInstructions': {
			const { deliveryOptionId } = field.opts.args;

			return api().deliveryOptions.doc(deliveryOptionId).update({
				returnInstructions: fieldValue,
			});
		}
		case 'deliveryCarrierName': {
			const { carrierId } = field.opts.args;

			return api().carriers.doc(carrierId).update({
				name: fieldValue,
			});
		}

		case 'skidataDiscountGroup': {
			const { segmentId, categoryId } = field.opts.args;
			const liftTicketCategory = StockSelectors.liftTicketCategory(state);

			if (!liftTicketCategory) return;

			const updatedSegmentMapping = produce(liftTicketCategory.segmentMapping, (draft) => {
				const segment = draft?.find((d) => d?.externalSegmentId === segmentId);
				if (!!segment) segment.name = fieldValue;
			});

			const hasChanged = !isEqual(updatedSegmentMapping, liftTicketCategory?.segmentMapping);

			return (
				hasChanged &&
				api().productCategories.doc(categoryId).update({
					segmentMapping: updatedSegmentMapping,
				})
			);
		}

		case 'manualPaymentMethodLabel': {
			const { shopId, methodId } = field.opts.args;
			const manualPaymentMethods = ShopSelectors.getManualPaymentMethods(state);
			const activePaymentMethods = ShopSelectors.getActivePaymentMethods(state);

			const updatedManualPaymentMethods = produce(manualPaymentMethods, (draft) => {
				const method = draft.find((method) => method.id === methodId);
				if (!!method) method.label = fieldValue;
			});

			const updatedActivePaymentMethods = produce(activePaymentMethods, (draft) => {
				const method = draft.find((m) => {
					return isCustomShopPaymentMethodObject(m) && m.details.id === methodId;
				});

				if (!!method && isCustomShopPaymentMethodObject(method)) method.details.label = fieldValue;
			});

			return Promise.all([
				api().shops.doc(shopId).update({
					manualPaymentMethods: updatedManualPaymentMethods,
				}),
				api().shops.doc(shopId).public.update({
					activePaymentMethods: updatedActivePaymentMethods,
				}),
			]);
		}

		case 'manualPaymentMethodDescription': {
			const { shopId, methodId } = field.opts.args;
			const manualPaymentMethods = ShopSelectors.getManualPaymentMethods(state);
			const activePaymentMethods = ShopSelectors.getActivePaymentMethods(state);

			const updatedManualPaymentMethods = produce(manualPaymentMethods, (draft) => {
				const method = draft.find((method) => method.id === methodId);
				if (!!method) method.description = fieldValue;
			});

			const updatedActivePaymentMethods = produce(activePaymentMethods, (draft) => {
				const method = draft.find((m) => {
					return isCustomShopPaymentMethodObject(m) && m.details.id === methodId;
				});

				if (!!method && isCustomShopPaymentMethodObject(method))
					method.details.description = fieldValue;
			});

			return Promise.all([
				api().shops.doc(shopId).update({
					manualPaymentMethods: updatedManualPaymentMethods,
				}),
				api().shops.doc(shopId).public.update({
					activePaymentMethods: updatedActivePaymentMethods,
				}),
			]);
		}

		case 'footerAboutHeading': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.footer.about.heading`]: fieldValue });
		}

		case 'footerAboutDescription': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.footer.about.description`]: fieldValue });
		}

		case 'footerMenuHeading': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.footer.menu.heading`]: fieldValue });
		}

		case 'footerLinksHeading': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.footer.links.heading`]: fieldValue });
		}

		case 'footerLinkLabel': {
			const { themeId, index } = field.opts.args;
			const themes = ShopSelectors.customThemesData(state);
			const theme = themes?.find((t) => t.id === themeId);
			if (!theme) return;

			const linkObjects = produce(theme.live.footer?.links?.linkObjects, (draft) => {
				const toUpdate = draft?.[index];
				if (!!toUpdate?.label?.def) {
					toUpdate.label = fieldValue;
				}
			});

			return !!linkObjects
				? api()
						.customThemes.doc(themeId)
						.update({
							[`live.footer.links.linkObjects`]: linkObjects,
						})
				: undefined;
		}

		case 'featuredImageHeading': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.featuredImage.heading`]: fieldValue });
		}

		case 'featuredImageDescription': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.featuredImage.description`]: fieldValue });
		}

		case 'featuredImageButtonLabel': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.featuredImage.button.label`]: fieldValue });
		}

		case 'aboutHeading': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.about.heading`]: fieldValue });
		}

		case 'aboutTextContent': {
			const { themeId } = field.opts.args;
			return api()
				.customThemes.doc(themeId)
				.update({ [`live.about.textContent`]: fieldValue });
		}

		case 'customImageHeading':
		case 'customImageDescription':
		case 'customTextHeading':
		case 'customTextBody': {
			return updateCustomContent({
				...field.opts.args,
				state,
				fieldValue,
				page: field.opts.args.page,
			});
		}
		case 'customTextfieldLabel': {
			return updateCustomContent({
				...field.opts.args,
				state,
				fieldValue,
				page: 'checkout',
			});
		}
		case 'customCheckboxLabel': {
			return updateCustomContent({
				...field.opts.args,
				state,
				fieldValue,
				page: 'checkout',
			});
		}

		default:
			return;
	}
};

const updateCustomContent = (args: {
	themeId: string;
	sectionIndex: number;
	contentIndex: number;
	state: ReduxState;
	fieldValue: LocaleField;
	key: CustomContentTranslatableKey;
	page: 'about' | 'checkout';
}) => {
	const { themeId, sectionIndex, contentIndex, state, fieldValue, key, page } = args;

	const themes = ShopSelectors.customThemesData(state);
	const theme = themes?.find((t) => t.id === themeId);
	if (!theme) return;

	const pagePath = (page: 'about' | 'checkout') => {
		switch (page) {
			case 'about':
				return theme.live.about?.customContent?.sections;
			case 'checkout':
				return theme.live.checkout?.customContent?.sections;
		}
	};

	const updatedSections = produce(pagePath(page), (draft) => {
		const customContent = draft?.[sectionIndex]?.content?.[contentIndex];
		if (!!customContent?.[key]?.def) {
			customContent[key] = fieldValue;
		}
	});

	return api()
		.customThemes.doc(themeId)
		.update({
			[`live.${page}.customContent.sections` as `live.${'about'}.customContent.sections`]: updatedSections,
		});
};
