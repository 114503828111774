import { GenerateContentInput } from '../generators';

const basic: GenerateContentInput[] = [
	{
		category: {
			name: 'Bicycles',
			description: "Whether you are hitting the roads or the woods, we've got you covered!",
			image:
				'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fdummy-image-bike.png?alt=media&token=85857949-3236-4572-88a9-c166db3ffe3f',
		},
		products: [
			{
				name: 'Road bicycle',
				description: 'High quality road bike to take you through the beautiful local roads.',
				price: 2000,
				quantity: 20,
				stockType: 'bulk',
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fdummy-image-bike.png?alt=media&token=85857949-3236-4572-88a9-c166db3ffe3f',
				additionalProductIds: ['helmet'],
			},
			{
				name: 'Mountain bike',
				description: 'The best choice when wanting to go off-road and into the bike trails.',
				price: 3000,
				quantity: 20,
				stockType: 'bulk',
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fdummy-image-bike.png?alt=media&token=85857949-3236-4572-88a9-c166db3ffe3f',
				additionalProductIds: ['helmet'],
			},
		],
	},
	{
		category: {
			name: 'Accessories',
			description: 'Are you one helmet away from a perfect trip? No worries!',
			image:
				'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fdummy-image-helmet.png?alt=media&token=9d88ecbd-a1f3-48b6-bee3-54c654f4b88a',
		},
		products: [
			{
				id: 'helmet',
				name: 'Helmet',
				price: 500,
				quantity: 20,
				stockType: 'bulk',
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fdummy-image-helmet.png?alt=media&token=9d88ecbd-a1f3-48b6-bee3-54c654f4b88a',
				pricingTableType: 'accessory',
				productVariants: [
					{
						priceIncrease: '',
						name: 'S',
					},
					{
						priceIncrease: '',
						name: 'M',
					},
					{
						priceIncrease: '',
						name: 'L',
					},
					{
						priceIncrease: '',
						name: 'XL',
					},
				],
				variantPropertyName: 'Size',
			},
		],
	},
];

export default basic;
