import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { createBasicDbOperations } from '../utils';
import { DeletedShopApi } from 'common/db/collections/deletedShops/types';

export const getDeletedShopsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.deletedShops;
	return {
		...createBasicDbOperations<DeletedShopApi>(collectionPath, dbRefs),
	};
};
