import {
	DINChart,
	GetDINOptions,
	GetSkierCodeOptions,
	SkierCode,
	SkierCodeInfo,
} from 'common/types';

const skierCodes: SkierCodeInfo[] = [
	{ code: 'A', minWeight: 10, maxWeight: 13 },
	{ code: 'B', minWeight: 14, maxWeight: 17 },
	{ code: 'C', minWeight: 18, maxWeight: 21 },
	{ code: 'D', minWeight: 22, maxWeight: 25 },
	{ code: 'E', minWeight: 26, maxWeight: 30 },
	{ code: 'F', minWeight: 31, maxWeight: 35 },
	{ code: 'G', minWeight: 36, maxWeight: 41 },
	{ code: 'H', minWeight: 42, maxWeight: 48, maxHeight: 148 },
	{ code: 'I', minWeight: 49, maxWeight: 57, minHeight: 149, maxHeight: 157 },
	{ code: 'J', minWeight: 58, maxWeight: 66, minHeight: 158, maxHeight: 166 },
	{ code: 'K', minWeight: 67, maxWeight: 78, minHeight: 167, maxHeight: 178 },
	{ code: 'L', minWeight: 79, maxWeight: 94, minHeight: 179, maxHeight: 194 },
	{ code: 'M', minWeight: 95, minHeight: 195 },
	{ code: 'N' },
	{ code: 'O' },
	{ code: 'P' },
];

/**
 * Boot sole length (mm) groups:
 * 0: <= 230,
 * 1: 231 -> 250,
 * 2: 251 -> 270,
 * 3: 271 -> 290,
 * 4: 291 -> 310,
 * 5: 311 -> 330,
 * 6: 331 -> 350,
 * 7: >= 351
 */

export const dinChart: DINChart = {
	atomic: {
		name: 'Atomic',
		data: [
			{ code: 'A', 0: '0.75', 1: '0.75', 2: '0.75' },
			{ code: 'B', 0: '1.00', 1: '0.75', 2: '0.75', 3: '0.75' },
			{ code: 'C', 0: '1.50', 1: '1.25', 2: '1.25', 3: '1.00' },
			{ code: 'D', 0: '2.00', 1: '1.75', 2: '1.50', 3: '1.50', 4: '1.25' },
			{ code: 'E', 0: '2.50', 1: '2.25', 2: '2.00', 3: '1.75', 4: '1.50', 5: '1.50' },
			{ code: 'F', 0: '3.00', 1: '2.75', 2: '2.50', 3: '2.25', 4: '2.00', 5: '1.75', 6: '1.75' },
			{ code: 'G', 1: '3.50', 2: '3.00', 3: '2.75', 4: '2.50', 5: '2.25', 6: '2.00' },
			{ code: 'H', 2: '3.50', 3: '3.00', 4: '3.00', 5: '2.75', 6: '2.50' },
			{ code: 'I', 2: '4.50', 3: '4.00', 4: '3.50', 5: '3.50', 6: '3.00' },
			{ code: 'J', 2: '5.50', 3: '5.00', 4: '4.50', 5: '4.00', 6: '3.50', 7: '3.00' },
			{ code: 'K', 2: '6.50', 3: '6.00', 4: '5.50', 5: '5.00', 6: '4.50', 7: '4.00' },
			{ code: 'L', 2: '7.50', 3: '7.00', 4: '6.50', 5: '6.00', 6: '5.50', 7: '5.00' },
			{ code: 'M', 3: '8.50', 4: '8.00', 5: '7.00', 6: '6.50', 7: '6.00' },
			{ code: 'N', 3: '10.00', 4: '9.50', 5: '8.50', 6: '8.00', 7: '7.50' },
			{ code: 'O', 3: '11.50', 4: '11.00', 5: '10.00', 6: '9.50', 7: '9.00' },
			{ code: 'P', 5: '12.00', 6: '11.00', 7: '10.50' },
		],
	},
};

const MAX_WEIGHT = 95;
const MAX_HEIGHT = 195;
const MAX_INDEX_BY_WEIGHT_AND_HEIGHT = 12;
const SKIER_TYPE_DEFAULT = 1;

/**
 * Skier Type:
 * 0: Type 1-,
 * 1: Type 1,
 * 2: Type 2,
 * 3: Type 3,
 * 4: Type 3+
 */

export const getSkierCode = ({
	weight,
	height,
	age,
	skierType,
}: GetSkierCodeOptions): SkierCode => {
	const roundedWeight = Math.round(weight);
	const indexFromWeight =
		roundedWeight >= MAX_WEIGHT
			? MAX_INDEX_BY_WEIGHT_AND_HEIGHT
			: skierCodes.findIndex(
					(skierCode) => !!skierCode.maxWeight && roundedWeight <= skierCode.maxWeight,
			  );
	const roundedHeight = Math.round(height);
	const indexFromHeight =
		roundedHeight >= MAX_HEIGHT
			? MAX_INDEX_BY_WEIGHT_AND_HEIGHT
			: skierCodes.findIndex(
					(skierCode) => !!skierCode.maxHeight && roundedHeight <= skierCode.maxHeight,
			  );
	let index = indexFromWeight < indexFromHeight ? indexFromWeight : indexFromHeight;
	index = (age < 10 || age >= 50) && index > 0 ? index - 1 : index; // Skiers >=50 or <10 years old get 1 index lower, index = 0 is the minimum
	index = index + skierType - SKIER_TYPE_DEFAULT;
	return skierCodes[index].code;
};

const BOOT_SOLE_MINIMUM_LENGTH = 230; // Boot sole length below this value is group 0
const BOOT_SOLE_LENGTH_GROUP_DIVISOR = 20; // Number of values for each boot sole length group
const BOOT_SOLE_LENGTH_GROUP_MIN = 0;
const BOOT_SOLE_LENGTH_GROUP_MAX = 7;

export const getDIN = ({
	weight,
	height,
	age,
	bootSoleLength,
	skierType,
	dinVariation,
}: GetDINOptions): string | undefined => {
	const skierCode: SkierCode = getSkierCode({ weight, height, age, skierType });
	const chart = dinChart.atomic.data;
	const index = chart.findIndex((row) => row.code === skierCode);
	let bootSoleLengthGroup = Math.ceil(
		(Number(bootSoleLength) - BOOT_SOLE_MINIMUM_LENGTH) / BOOT_SOLE_LENGTH_GROUP_DIVISOR,
	);
	bootSoleLengthGroup =
		bootSoleLengthGroup < BOOT_SOLE_LENGTH_GROUP_MIN
			? BOOT_SOLE_LENGTH_GROUP_MIN
			: bootSoleLengthGroup > BOOT_SOLE_LENGTH_GROUP_MAX
			? BOOT_SOLE_LENGTH_GROUP_MAX
			: bootSoleLengthGroup;
	const chartDin = chart[index][bootSoleLengthGroup];
	const dinWithVariation =
		dinVariation && chartDin ? Number(chartDin) + Number(dinVariation) : null;
	return dinWithVariation != null && !isNaN(dinWithVariation)
		? dinWithVariation?.toFixed(2)
		: chartDin;
};
