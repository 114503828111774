import { Theme } from '@mui/material';

import { Listing } from '@rentle/resell-api-client';

export const getChipColor = (status: Listing['status'], theme: Theme) => {
	switch (status) {
		case 'active':
			return {
				backgroundColor: theme.palette.success.lightest,
				border: `1px solid ${theme.palette.success.lighter}`,
				color: theme.palette.success.dark,
				icon: theme.palette.success.main,
			};
		case 'inactive':
			return {
				backgroundColor: theme.palette.warning.lightest,
				border: `1px solid ${theme.palette.warning.lighter}`,
				color: theme.palette.warning.dark,
				icon: theme.palette.warning.main,
			};
		case 'reserved':
			return {
				backgroundColor: theme.palette.warning.lightest,
				border: `1px solid ${theme.palette.warning.lighter}`,
				color: theme.palette.warning.dark,
				icon: theme.palette.warning.main,
			};
		case 'sold':
			return {
				backgroundColor: theme.palette.error.lightest,
				border: `1px solid ${theme.palette.error.lighter}`,
				color: theme.palette.error.dark,
				icon: theme.palette.error.main,
			};
		default:
			return {
				backgroundColor: theme.palette.success.lightest,
				border: `1px solid ${theme.palette.success.lighter}`,
				color: theme.palette.success.main,
				icon: theme.palette.success.main,
			};
	}
};

export const getStatusString = (status: Listing['status']) => {
	switch (status) {
		case 'active':
			return 'Published';
		case 'inactive':
			return 'Draft';
		case 'reserved':
			return 'Reserved';
		case 'sold':
			return 'Sold';
		default:
			return 'Unknown';
	}
};
