import moment from 'moment-timezone';
import { z } from 'zod';

import { LocaleField } from 'common/models/_atoms/LocaleField';
import { PurchaseType } from 'common/models/_atoms/PurchaseType';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { getOpenApiExample, withOpenApi } from 'common/utils/openApi';
import { zIsoString } from 'common/utils/zod';

import { OrderPayment } from '../OrderPayment';
import { ItemPricing } from './ItemPricing';
import { ItemSkus } from './ItemSkus';

/**
 * Used as the base order item so PackageItem can pick and extend from this without needing to duplicate model data.
 * This cannot live in the api.ts folder of OrderItem as it would cause a circular dependency with PackageItem.
 */
const zBaseOrderItemApi = withOpenApi(
	z.object({
		id: z.string(),
		// TODO No need to expose currently, while we don't have persons in API yet
		// personId: z.string(),
		productId: z.string(),
		name: LocaleField.api,
		itemCodes: z.string().array().optional(),
		variantId: z.string().optional(),
		variantValues: LocaleField.api.array().optional(),
		startDate: zIsoString,
		endDate: zIsoString,
		returnedDate: zIsoString.optional(),
		cancelled: z.boolean().optional(),
		payment: OrderPayment.api,
		deposit: OrderPayment.api.nullable(),
		pricing: ItemPricing.api,
		purchaseType: PurchaseType.api,
		itemSkus: ItemSkus.api.array().optional(),
	}),
	{
		fields: {
			id: 'Unique order item identifier.',
			productId: 'Identifier of the product catalog product.',
			name: 'Name of the item.',
			itemCodes: 'List of given inventory item codes for the item.',
			variantId: 'Selected variant id.',
			variantValues: 'Selected variant values.',
			startDate: 'Start date of the item.',
			endDate:
				'End date of the item. This will show the initial end date even when the item is returned.',
			returnedDate: 'Returned date of the item. Does not exist if the item is not returned.',
			cancelled: 'Whether the item is cancelled.',
			payment: 'Payment information for the item.',
			deposit: 'Deposit information for the item.',
			pricing: 'Pricing information for the item.',
			purchaseType: 'Purchase type of the item.',
			itemSkus: 'List of inventory items for the item.',
		},
		example: () => {
			const created = moment();
			const start = created.clone().add(1, 'day').minutes(0).seconds(0).milliseconds(0);
			const end = start.clone().add(1, 'day');
			return {
				id: newFirestoreId(),
				productId: newFirestoreId(),
				name: { def: 'E-bike' },
				itemCodes: ['E-1'],
				variantId: newFirestoreId(),
				variantValues: [{ def: 'Small' }],
				startDate: start.toISOString(),
				endDate: end.toISOString(),
				returnedDate: undefined,
				cancelled: false,
				payment: OrderPayment.example,
				deposit: OrderPayment.example,
				pricing: ItemPricing.example,
				purchaseType: 'rental' as const,
				itemSkus: [
					{
						units: 1,
						skuId: newFirestoreId(),
						type: 'single' as const,
						itemCodes: ['E-1'],
					},
				],
			};
		},
	},
);

export const BaseOrderItem = {
	api: zBaseOrderItemApi,
	example: getOpenApiExample(zBaseOrderItemApi),
};
