import React from 'react';

import {
	Box,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
	styled,
	useMediaQuery,
} from '@mui/material';
import { RiArrowRightSLine, RiCheckFill } from 'react-icons/ri';
import { HashLink } from 'react-router-hash-link';
import { useStyles } from 'tss-react/mui';

import { OnboardingItem } from 'services/onboarding';
import { Routes } from 'routing';

type Props = {
	item: OnboardingItem;
};

const OnboardingItemRow = (props: Props) => {
	const { item } = props;
	const { content, routeTo, routeHash, onClick, done } = item;
	const { title } = content;
	const { theme } = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down(600));

	const scrollWithOffset = (el: HTMLElement) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = isMobile ? -70 : -180;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	return (
		<HashLink
			to={{
				pathname: routeTo,
				state: { from: Routes.HOME_VIEW },
				hash: routeHash,
			}}
			smooth
			scroll={(el) => scrollWithOffset(el)}
		>
			<ListItem
				divider={true}
				sx={{
					p: 2,
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
						cursor: 'pointer',
					},
				}}
				onClick={onClick}
			>
				<StyledIcon>
					{done ? <RiCheckFill color={theme.palette.success.main} /> : <StyledRoundIcon />}
				</StyledIcon>
				<ListItemText
					sx={{ pr: 4.5, my: 0, fontWeight: 400 }}
					disableTypography // Needed to override the fontweight (https://stackoverflow.com/questions/43975839/material-ui-styling-text-inside-listitemtext)
					primary={
						<Typography
							variant="body1"
							sx={{
								fontWeight: 400,
								color: done ? theme.palette.text.tertiary : theme.palette.text.primary,
							}}
						>
							{title}
						</Typography>
					}
				/>
				<ListItemSecondaryAction
					sx={{
						right: theme.spacing(4),
						my: 0,
					}}
				>
					<IconButton edge="end" aria-label="go" size="large" onClick={onClick}>
						<RiArrowRightSLine size={24} />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</HashLink>
	);
};

const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
	height: '24px',
	width: '40px',
	minWidth: '40px',
	marginRight: theme.spacing(2),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const StyledRoundIcon = styled(Box)(({ theme }) => ({
	width: '20px',
	height: '20px',
	borderRadius: '50%',
	outline: `1px solid ${theme.palette.text.tertiary}`,
}));

export default OnboardingItemRow;
