import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const CUSTOMIZE_ONLINE_STORE_DEFINITION: Record<
	'CUSTOMIZE_ONLINE_STORE',
	OnboardingStepDefinition
> = {
	CUSTOMIZE_ONLINE_STORE: {
		plans: 'all',
		versions: {
			V0: ['APPEARANCE_ONLINE_STORE', 'LOGO'],
			V1_TEMPLATE: ['APPEARANCE_ONLINE_STORE', 'LOGO'],
			V1_NO_TEMPLATE: ['APPEARANCE_ONLINE_STORE', 'LOGO'],
			V2_TEMPLATE: ['APPEARANCE_ONLINE_STORE', 'LOGO'],
			V2_NO_TEMPLATE: ['APPEARANCE_ONLINE_STORE', 'LOGO'],
		},
	},
};
