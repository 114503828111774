import { TFunction } from 'react-i18next';

import { OnboardingSection, OnboardingSections } from 'common/services/analytics/tractionAnalytics';

export const ONBOARDING_SECTIONS = (
	t: TFunction,
): Record<OnboardingSection, { id: OnboardingSection; orderIndex: number; title?: string }> => ({
	QUICK_START: {
		id: OnboardingSections.QUICK_START,
		orderIndex: 0,
		title: t('homeView.onboarding.sections.quickStart.title', 'Your quick start guide'),
	},
	HIGHLIGHTED_FEATURES: {
		id: OnboardingSections.HIGHLIGHTED_FEATURES,
		orderIndex: 1,
		title: t('homeView.onboarding.sections.highlightedFeatures.title', 'Highlighted features'),
	},
	READY_FOR_CUSTOMERS: {
		id: OnboardingSections.READY_FOR_CUSTOMERS,
		orderIndex: 2,
		title: t('homeView.onboarding.sections.readyForCustomers.title', 'Get ready for customers'),
	},
	CONFIDENCE_AND_COLLABORATION: {
		id: OnboardingSections.CONFIDENCE_AND_COLLABORATION,
		orderIndex: 3,
		title: t(
			'homeView.onboarding.sections.confidenceAndCollaboration.title',
			'Boost your business confidence and collaboration',
		),
	},
	NO_SECTION: {
		id: OnboardingSections.NO_SECTION,
		orderIndex: 4,
	},
});
