import { ProductApi } from 'common/types';

import { CallableCreator } from '..';

export interface CreateBookingParams {
	/*
		Id of the shop where the orders will be inserted
	 */
	shopId: string;
	/*
		Optional: Ids of specific products to be added to the orders
	 */
	productIds?: Array<string>;
	/*
		Optional: Duration that can be used to slow down the creation of orders
	 */
	timespanInSec?: number;
	/*
		Optional: Number of orders to be created
	*/
	nOrders?: number;
}

export const generators = (createCallable: CallableCreator) => ({
	generateBookings: createCallable<CreateBookingParams, void>('generators-generateBookings'),
	generateProducts: createCallable<Partial<ProductApi>, void>('generators-generateProducts'),
});
