import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const posName = 'Solteq Commerce Cloud';

const details = (t: TFunction): AddOnDetails => ({
	name: posName,
	subHeader: t('common:blocks.details.cloudPOS.subHeader', {
		defaultValue: 'Handle in-store payments with {{name}}',
		name: posName,
	}),
	description: t('common:blocks.details.cloudPOS.description', {
		defaultValue:
			'Get booking details to {{name}} cash register system directly from Rentle. Have bookings automatically marked as paid once they have been paid in the POS. Decide the most convenient moment to send booking details: when you print a booking confirmation receipt, start a booking or as a separate action',
		name: posName,
	}),
	supportArticle: SupportArticles.CLOUD_POS,
	uninstallNotice: t('common:blocks.details.cloudPOS.uninstallNotice', {
		defaultValue: 'By uninstalling {{name}}, your configurations will be removed',
		name: posName,
	}),
});

export default details;
