import { faker } from '@faker-js/faker/locale/en';

import { OrderPricing } from 'common/modules/atoms/pricing';

import { getCurrency } from './Currency.mock';
import { getDiscount } from './Discount.mock';
import { getTaxLine } from './TaxLine.mock';
import { arrify } from './index.mock';

const generateOrderPricing = (): OrderPricing => {
	return {
		currency: getCurrency(),
		/**
		 * Total price including taxes and discounts
		 */
		total: faker.datatype.number({ min: 10, max: 1000 }),
		/**
		 * Price after discounts and before taxes if taxes are excluded
		 */
		subtotal: faker.datatype.number({ min: 10, max: 1000 }),
		totalTaxes: faker.datatype.number({ min: 10, max: 1000 }),
		totalDiscounts: faker.datatype.number({ min: 10, max: 1000 }),
		taxExcluded: faker.datatype.boolean(),
		taxLines: arrify(getTaxLine, faker.datatype.number({ min: 1, max: 4 })),
		discountCodes: {
			[faker.word.noun()]: {
				totalDiscountValue: faker.datatype.number({ min: 10, max: 1000 }),
				quantity: 1,
			},
		},
		manualDiscount: getDiscount(),
		deposit: {
			type: 'payment',
			value: faker.datatype.number({ min: 0, max: 1000 }),
		},
		paymentMethod: {
			id: 'CARD_ONLINE',
		},
	};
};

export const getOrderPricing = (p?: Partial<OrderPricing>): OrderPricing => {
	return { ...generateOrderPricing(), ...p };
};
