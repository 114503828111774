import { zVariantApi, zVariantPropertyApi } from './api';
import { toApi, toDb } from './mapper';
import { VariantPropertyApi, VariantPropertyDb } from './types';

export * from './types';

export const Variant = {
	toApi,
	toDb: toDb,
	api: zVariantApi,
};

export const VariantProperty = {
	toApi: (data: VariantPropertyDb) => data,
	toDb: (data: VariantPropertyApi) => data,
	api: zVariantPropertyApi,
};
