import React from 'react';

import { Chip, styled, useTheme } from '@mui/material';

import { Listing } from '@rentle/resell-api-client';

import { getChipColor, getStatusString } from './utils';

export interface Props {
	status: Listing['status'];
}
const ListingStatusChip = (props: Props) => {
	const { status } = props;
	const theme = useTheme();

	const { backgroundColor, border, color, icon } = getChipColor(status, theme);

	const DotIcon = styled('span')(({ theme }) => ({
		width: 8,
		height: 8,
		borderRadius: '50%',
		display: 'inline-block',
		backgroundColor: icon,
	}));

	return (
		<Chip
			label={getStatusString(status)}
			icon={<DotIcon />}
			size="small"
			sx={{
				backgroundColor,
				border,
				color,
				px: 0.5,
			}}
		/>
	);
};

export default ListingStatusChip;
