import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

import { PackageItem } from './PackageItem';
import { BaseOrderItem } from './_BaseOrderItem.api';

export const zOrderItemApi = withOpenApi(
	BaseOrderItem.api.extend({
		packageItems: z.array(PackageItem.api).optional(),
	}),
	{
		fields: {
			packageItems: 'List of package items if the order item is a package',
		},
		example: {
			...BaseOrderItem.example!,
			packageItems: !!PackageItem.example ? [PackageItem.example] : [],
		},
	},
);
