import React, {
	ReactNode,
	createContext,
	useContext,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { atom, useAtomValue, useSetAtom } from 'jotai';

import { MODES, Mode, SIDEBAR_WIDTH, SIDEBAR_WIDTH_COLLAPSED } from './constants';

const isSidebarOpenAtom = atom<boolean>(false);
const isSidebarExpandedAtom = atom<boolean>(
	!!localStorage.getItem('isSidebarExpandedAtom')
		? localStorage.getItem('isSidebarExpandedAtom') === 'true'
		: true,
);

const persistedIsSidebarExpandedAtom = atom(
	(get) => get(isSidebarExpandedAtom),
	(get, set, update: boolean | ((prevValue: boolean) => boolean)) => {
		const nextValue = typeof update === 'function' ? update(get(isSidebarExpandedAtom)) : update;
		set(isSidebarExpandedAtom, nextValue);
		localStorage.setItem('isSidebarExpandedAtom', nextValue ? 'true' : 'false');
	},
);

const modeAtom = atom<Mode>(MODES.rentals); // For now, always set to MODES.rentals, as we always redirect to /home when creating a store / logging in.

const persistedModeAtom = atom(
	(get) => get(modeAtom),
	(get, set, update: Mode | ((prevValue: Mode) => Mode)) => {
		const nextValue = typeof update === 'function' ? update(get(modeAtom)) : update;
		set(modeAtom, nextValue);
		localStorage.setItem('mode', nextValue);
	},
);

/**
 * - headerElement: A reference to the header DOM element.
 * - isSidebarOpen: A boolean indicating if the sidebar is open. Used in mobile view.
 * - setSidebarOpen: A function to set the 'isSidebarOpen' state. Used in mobile view.
 * - isSidebarExpanded: A boolean indicating if the sidebar is expanded. Used in desktop view.
 * - setSidebarExpanded: A function to set the 'isSidebarExpanded' state. Used in desktop view.
 * - isMobile: A boolean indicating if the viewport matches a mobile device.
 * - hidden: A boolean indicating if a layout element is hidden. Used to hide the parent layout when a child layout is rendered.
 * - setHidden: A function to set the 'hidden' state. Used to hide the parent layout when a child layout is rendered.
 * - sidebarWidth: A number indicating the width of the sidebar.
 * - mode: A string indicating the current mode.
 * - setMode: A function to set the 'mode' state.
 */

type LayoutContextType = {
	headerElement: HTMLDivElement | null;
	isSidebarOpen: boolean;
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isSidebarExpanded: boolean;
	setSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
	isMobile: boolean;
	hidden: boolean;
	setHidden: React.Dispatch<React.SetStateAction<boolean>>;
	sidebarWidth: number;
	mode: Mode;
	setMode: React.Dispatch<React.SetStateAction<Mode>>;
};

const LayoutContext = createContext<LayoutContextType | null>(null);

export const useLayoutContext = () => {
	const context = useContext(LayoutContext);

	if (!context) {
		throw new Error('useLayoutContext must be used within a LayoutContextProvider');
	}

	return context;
};

export const useHideParentLayout = () => {
	const context = useContext(LayoutContext);

	useLayoutEffect(() => {
		const c = context;
		if (!!c) {
			c.setHidden(true);
		}

		return () => {
			if (!!c) {
				c.setHidden(false);
			}
		};
	}, [context]);
};

export const LayoutContextProvider = (props: {
	headerRef: React.RefObject<HTMLDivElement>;
	hasSidebar: boolean;
	children: (hidden: boolean) => ReactNode;
}) => {
	const { children, headerRef, hasSidebar } = props;
	const [hidden, setHidden] = useState<boolean>(false);

	const isSidebarOpen = useAtomValue(useMemo(() => atom((get) => get(isSidebarOpenAtom)), []));
	const setSidebarOpen = useSetAtom(isSidebarOpenAtom);

	const isSidebarExpanded = useAtomValue(
		useMemo(() => atom((get) => get(persistedIsSidebarExpandedAtom)), []),
	);
	const setSidebarExpanded = useSetAtom(persistedIsSidebarExpandedAtom);

	const mode = useAtomValue(useMemo(() => atom((get) => get(persistedModeAtom)), []));
	const setMode = useSetAtom(persistedModeAtom);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const sidebarWidth = useMemo(
		() =>
			isMobile || !hasSidebar || hidden
				? 0
				: isSidebarExpanded
				? SIDEBAR_WIDTH
				: SIDEBAR_WIDTH_COLLAPSED,
		[hasSidebar, hidden, isMobile, isSidebarExpanded],
	);

	return (
		<LayoutContext.Provider
			value={{
				headerElement: headerRef.current,
				isSidebarOpen,
				setSidebarOpen,
				isSidebarExpanded,
				setSidebarExpanded,
				isMobile,
				hidden,
				setHidden,
				sidebarWidth,
				mode,
				setMode,
			}}
		>
			{children(hidden)}
		</LayoutContext.Provider>
	);
};
