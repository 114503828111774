/**
 * Delivery
 */
export const DELIVERY = 'https://support.rentle.io/en/delivery';
export const DELIVERY__SETTING_UP_LOCAL_DELIVERIES =
	'https://support.rentle.io/en/setting-up-local-deliveries-for-online-orders';
export const DELIVERY__MANAGING_DELIVERY_CARRIERS =
	'https://support.rentle.io/en/delivery-carriers';

export const PACKAGE_PRODUCTS = 'https://support.rentle.io/en/creating-product-packages';
export const PRODUCT_VARIANTS = 'https://support.rentle.io/en/creating-product-variants';
export const LANGUAGES = 'https://support.rentle.io/en/sell-in-multiple-languages';

export const EXTENSIONS = 'https://support.rentle.io/en/booking-extension';

export const ANALYTICS = 'https://support.rentle.io/en/setting-up-analytics-in-rentle';
export const BRANDING = 'https://support.rentle.io/en/customizing-check-in-kiosk';

export const CUSTOM_CHECKOUT_FIELD = 'https://support.rentle.io/en/checkout-form-custom-fields';

export const MASS_RETURN = '';

export const DIN_SETTINGS =
	'https://support.rentle.io/en/how-to-setup-din-value-calculation-relevant-for-ski-rentals';

export const DISCOUNT_CODES = 'https://support.rentle.io/en/creating-discount-codes-and-gift-cards';

export const MAINTENANCE_TIME = 'https://support.rentle.io/en/setting-up-maintenance-times';

export const CATEGORY_TERMS = 'https://support.rentle.io/en/category-terms-block';

export const MANUAL_PAYMENT_METHODS = 'https://support.rentle.io/en/manual-payment-methods';

export const DASHBOARD = '';

export const SKIDATA = '';

export const TEKSO = 'https://support.rentle.io/en/tekso-pos';

export const CLOUD_POS = 'https://support.rentle.io/en/cloud-pos';

export const WEBHOOKS = 'https://support.rentle.io/en/creating-webhooks';

export const CUSTOMIZER = 'https://support.rentle.io/en/online-appearance';

export const START_TIMES = 'https://support.rentle.io/en/customize-start-times-for-product';

export const INVENTORY_OVERVIEW = 'https://support.rentle.io/en/inventory-overview';

export const INVENTORY =
	'https://support.rentle.io/en/inventory#building-your-inventory-step-by-step';

export const DEPRECATED_WEBHOOKS_GUIDE =
	'https://support.rentle.io/en/updating-your-old-rentle-webhooks';

export const DEPOSITS = 'https://support.rentle.io/en/introduction-to-security-deposits';
export const AUTOMATED_DEPOSITS =
	'https://support.rentle.io/en/working-with-automatic-security-deposits';
