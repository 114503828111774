import { TFunction } from 'react-i18next';

import { getNormalizedUrl, isValidUrl } from 'common/modules/atoms/validation';
import { RevenueOption, RevenueOptions } from 'common/services/analytics/tractionAnalytics';
import { getPricingString } from 'common/utils/common';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';

export const validateWebsiteUrl = (value: string | undefined) => {
	const normalizedUrl = getNormalizedUrl(value, {
		defaultProtocol: 'https',
	});
	return isValidUrl(normalizedUrl, {
		allowEmpty: true,
		allowedProtocols: ['http', 'https'],
		requireTLD: true,
	});
};

export const getRevenueOptions = (t: TFunction): { value: RevenueOption; label: string }[] => {
	const currency = getDefaultCurrencyObject();
	const amountsInCents = {
		'5k': 500000,
		'50k': 5000000,
		'250k': 25000000,
		'1M': 100000000,
	};
	return [
		{
			value: RevenueOptions['0-5k'],
			label: t('common:amounts.upTo', {
				defaultValue: 'Up to {{amount}}',
				amount: `${getPricingString(amountsInCents['5k'], currency)} ${currency.code}`,
			}),
		},
		{
			value: RevenueOptions['5-50k'],
			label: t('common:amounts.between', {
				defaultValue: '{{amount1}} to {{amount2}}',
				amount1: `${getPricingString(amountsInCents['5k'], currency)} ${currency.code}`,
				amount2: `${getPricingString(amountsInCents['50k'], currency)} ${currency.code}`,
			}),
		},
		{
			value: RevenueOptions['50-250k'],
			label: t('common:amounts.between', {
				defaultValue: '{{amount1}} to {{amount2}}',
				amount1: `${getPricingString(amountsInCents['50k'], currency)} ${currency.code}`,
				amount2: `${getPricingString(amountsInCents['250k'], currency)} ${currency.code}`,
			}),
		},
		{
			value: RevenueOptions['250k-1M'],
			label: t('common:amounts.between', {
				defaultValue: '{{amount1}} to {{amount2}}',
				amount1: `${getPricingString(amountsInCents['250k'], currency)} ${currency.code}`,
				amount2: `${getPricingString(amountsInCents['1M'], currency)} ${currency.code}`,
			}),
		},
		{
			value: RevenueOptions['1M+'],
			label: t('common:amounts.above', {
				defaultValue: 'Above {{amount}}',
				amount: `${getPricingString(amountsInCents['1M'], currency)} ${currency.code}`,
			}),
		},
	];
};
