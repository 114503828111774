import { OpenApiZodAny, extendApi } from '@anatine/zod-openapi';
import type { SchemaObject } from 'openapi3-ts';
import { z } from 'zod';

import { getTypedEntries } from 'common/utils/objects';

type RemoveIndex<T> = {
	[K in keyof T as string extends K ? never : number extends K ? never : K]: T[K];
};

type WithUndefined<T extends object> = {
	[K in keyof T]: T[K] | undefined;
};

interface ZodFieldOpenApiOptions<T extends z.ZodTypeAny>
	extends Omit<RemoveIndex<SchemaObject>, 'example' | 'examples'> {
	example?: (() => WithUndefined<z.infer<T>>) | WithUndefined<z.infer<T>>;
	examples?: WithUndefined<z.infer<T>>[] | (() => WithUndefined<z.infer<T>>[]);
}

export interface ZodOpenApiOptions<T extends z.ZodTypeAny> extends ZodFieldOpenApiOptions<T> {
	fields?: T extends z.ZodObject<z.ZodRawShape>
		? {
				[key in keyof T['shape']]?: string | ZodOpenApiOptions<T['shape'][key]>;
		  }
		: undefined;
}

const isZodObjectType = (schema: z.ZodTypeAny): schema is z.ZodObject<z.ZodRawShape> => {
	return schema instanceof z.ZodObject;
};

const getExtendedApiSchema = <T extends OpenApiZodAny>(schema: T): T => {
	const hasOpenApiDescription =
		!!schema.metaOpenApi && Array.isArray(schema.metaOpenApi)
			? schema.metaOpenApi[0]?.description
			: schema.metaOpenApi?.description;
	/**
	 * If schema already has a description, we need to create a copy of the schema so it doesn't mutate the original schema.
	 * This is now done by using an empty .describe('') call.
	 */
	return hasOpenApiDescription ? schema.describe('') : schema;
};

const extendOpenApi = <T extends OpenApiZodAny>(
	_schema: T,
	options: ZodFieldOpenApiOptions<T>,
): T => {
	const schema = getExtendedApiSchema(_schema);
	const example = options.example instanceof Function ? options.example() : options.example;
	const examples = options.examples instanceof Function ? options.examples() : options.examples;
	const schemaObject = { ...options, example, examples };
	return extendApi(schema, schemaObject);
};

export const withOpenApi = <T extends z.ZodTypeAny>(
	schema: T,
	options: ZodOpenApiOptions<T>,
): T => {
	const { fields, ...openApiFields } = options;
	if (!isZodObjectType(schema)) {
		return extendOpenApi(schema, openApiFields);
	}
	const fieldsWithOpenApiInfo = getTypedEntries(schema.shape).reduce((acc, [key, keySchema]) => {
		const fieldOpenApiValue = fields?.[key];
		const fieldOpenApiInfo: ZodOpenApiOptions<T> | undefined =
			typeof fieldOpenApiValue === 'string'
				? { description: fieldOpenApiValue }
				: fieldOpenApiValue;
		const hasNestedFields = fieldOpenApiInfo?.fields;
		const newKeySchema =
			hasNestedFields && !!fieldOpenApiInfo
				? withOpenApi(keySchema, fieldOpenApiInfo)
				: !!fieldOpenApiInfo
				? extendOpenApi(keySchema, fieldOpenApiInfo)
				: keySchema;
		return {
			...acc,
			[key]: newKeySchema,
		};
	}, {} as typeof schema['shape']);
	// We need to cast this explicitly to T to keep the original schema shape. The extendApi() does not change the schema shape so this is safe.
	const schemaWithOpenApiFields = (z.object(fieldsWithOpenApiInfo) as unknown) as T;
	return extendOpenApi(schemaWithOpenApiFields, openApiFields);
};

// This is a workaround for z.record() with fixed keys. These are not correctly generated to the Open API spec with the current tooling.
export const getFixedRecordOpenApiProperties = (
	keys: string[],
	value: 'number' | 'string',
	description: string,
): Pick<SchemaObject, 'additionalProperties' | 'properties'> => {
	return {
		additionalProperties: false,
		properties: keys.reduce((acc, key) => {
			acc[key] = {
				description,
				type: value,
			};
			return acc;
		}, {} as Record<string, SchemaObject>),
	};
};

export const getOpenApiExample = <T extends z.ZodTypeAny>(_schema: T): z.infer<T> | undefined => {
	const schema = _schema as OpenApiZodAny;
	const metaOpenApi = schema.metaOpenApi;
	if (Array.isArray(metaOpenApi)) {
		return metaOpenApi[0]?.example;
	}
	return metaOpenApi?.example;
};
