import { EnumValues, UserApi } from 'common/types';
import { stringEnum } from 'common/utils/objects';

import errorHandler from '../errorHandling/errorHandler';

declare var $sleek: any;

export type SleekplanUser = {
	id: string;
	email: string;
	meta: {
		shops: string;
	};
};

export const SleekplanEvents = stringEnum(['init', 'open', 'close', 'widget_init']);

type SleekplanEvent = EnumValues<typeof SleekplanEvents>;

export const open = () => {
	if (!$sleek) {
		errorHandler.report('sleekplan not defined');
		return;
	}
	try {
		$sleek.open();
	} catch (err) {
		errorHandler.report(`error opening sleekplan: ${err}`);
	}
};

export const close = () => {
	if (!$sleek) {
		errorHandler.report('sleekplan not defined');
		return;
	}
	try {
		$sleek.close();
	} catch (err) {
		errorHandler.report(`error closing sleekplan: ${err}`);
	}
};

export const setUser = (user: UserApi | null) => {
	if (!$sleek) {
		errorHandler.report('sleekplan not defined');
		return;
	}
	if (!user) {
		errorHandler.report('user not defined');
		return;
	}
	const sleekplanUser: SleekplanUser = {
		id: user.id,
		email: user.email,
		meta: {
			shops: Object.keys(user.shops).join('+'),
		},
	};
	try {
		$sleek.setUser(sleekplanUser);
	} catch (err) {
		errorHandler.report(`error setting sleekplan user: ${err}`);
	}
};

export const resetUser = () => {
	if (!$sleek) {
		errorHandler.report('sleekplan not defined');
		return;
	}
	try {
		$sleek.resetUser();
	} catch (err) {
		errorHandler.report(`error resetting sleekplan user: ${err}`);
	}
};

export const on = (event: SleekplanEvent, callback: () => void) => {
	if (!$sleek) {
		errorHandler.report('sleekplan not defined');
		return;
	}
	try {
		$sleek.on(event, callback);
	} catch (err) {
		errorHandler.report(`error setting sleekplan event: ${err}`);
	}
};
