import { updateToNewShopPublicInfoType } from 'common/api/dataUpdates';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { WebhookDb } from 'common/models/Webhook/db';
import {
	ApiKeyInfo,
	ApiKeyStatsDay,
	ShopApi,
	ShopPublicInfo,
	ShopReadOnlyData,
	WebhookStatsDay,
} from 'common/types';

import {
	createBasicCollectionOperations,
	createBasicDbOperations,
	createBasicDocOperations,
} from '../utils';

export const getShopQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.shops;
	const dataUpdater = undefined;
	return {
		doc: (id: string) => ({
			...createBasicDocOperations<ShopApi>(collectionPath, dbRefs, id, dataUpdater),
			public: shopPublicQueries(dbRefs, id),
			readOnly: shopReadOnlyQueries(dbRefs, id),
			webhooks: shopWebhooksQueries(dbRefs, id),
			webhookStats: shopWebhookStatsQueries(dbRefs, id),
			apiKeys: shopApiKeysQueries(dbRefs, id),
			apiKeyStats: shopApiKeyStatsQueries(dbRefs, id),
		}),
		...createBasicCollectionOperations<ShopApi>(collectionPath, dbRefs, dataUpdater),
	};
};

const shopPublicQueries = (dbRefs: DbRefs, shopId: string) => {
	const collectionPath = `${dbPath.shopDoc(shopId)}/public`;
	const documentId = 'data';
	const dataUpdater = updateToNewShopPublicInfoType; // updateToNewOrderTypes;
	return createBasicDocOperations<ShopPublicInfo>(collectionPath, dbRefs, documentId, dataUpdater);
};

const shopReadOnlyQueries = (dbRefs: DbRefs, shopId: string) => {
	const collectionPath = `${dbPath.shopDoc(shopId)}/readOnly`;
	const documentId = 'data';
	return createBasicDocOperations<ShopReadOnlyData>(collectionPath, dbRefs, documentId);
};

const shopWebhooksQueries = (dbRefs: DbRefs, shopId: string) => {
	const collectionPath = `${dbPath.shopDoc(shopId)}/webhooks`;
	return createBasicDbOperations<WebhookDb>(collectionPath, dbRefs);
};

const shopWebhookStatsQueries = (dbRefs: DbRefs, shopId: string) => {
	const collectionPath = `${dbPath.shopDoc(shopId)}/webhookStats`;
	return createBasicDbOperations<WebhookStatsDay>(collectionPath, dbRefs);
};

const shopApiKeysQueries = (dbRefs: DbRefs, shopId: string) => {
	const collectionPath = `${dbPath.shopDoc(shopId)}/apiKeys`;
	return createBasicDbOperations<ApiKeyInfo>(collectionPath, dbRefs);
};

const shopApiKeyStatsQueries = (dbRefs: DbRefs, shopId: string) => {
	const collectionPath = `${dbPath.shopDoc(shopId)}/apiKeyStats`;
	return createBasicDbOperations<ApiKeyStatsDay>(collectionPath, dbRefs);
};
