import { TFunction } from 'i18next';
import { uniqWith } from 'lodash';

import { Currency, ItemObject, LocaleField } from 'common/types';

import { getTotalItemPricing } from '../pricing';
import { TaxAmounts, TaxDetails, TaxLine } from './types';

export const getTotalTaxes = (taxLines: TaxLine[]) => {
	return taxLines.reduce((prev, curr) => prev + curr.price, 0);
};

export const getTaxPercentFromTaxRate = (taxRate: number) => {
	const taxRateDecimalPlaces =
		taxRate >= 1 ? 0 : Math.max(0, (String(taxRate).split('.')[1]?.length ?? 2) - 2);
	const taxRatePercent = (taxRate * 100).toFixed(taxRateDecimalPlaces);
	return Number(taxRatePercent);
};

export const getTaxRateFromVatPercent = (vatPercent: number) => {
	const vatPercentDecimalPlaces = 2 + (String(vatPercent).split('.')[1]?.length ?? 0);
	const taxRate = (vatPercent / 100).toFixed(vatPercentDecimalPlaces);
	return Number(taxRate);
};

export const getDefaultTaxLabel = (taxRate: number, t: TFunction) => {
	const taxRatePercent = getTaxPercentFromTaxRate(taxRate).toString();
	return `${t('common:taxation.taxes', 'Taxes')} (${taxRatePercent}%)`;
};

/**
 * User only for calculating combined tax lines, when displaying combined tax labels
 */
export const getCombinedTaxLines = (taxLines: TaxLine[]) => {
	return taxLines.reduce((prev, curr) => {
		let matchFound = false;
		const taxLines = prev.map((line) => {
			const isSameCombinedLabel =
				!!line.combinedLabel &&
				!!curr.combinedLabel &&
				line.combinedLabel?.def === curr.combinedLabel?.def;
			const isSameLabelAndRate = hasSameRateAndLabel(line, curr);
			const isSame = isSameCombinedLabel || isSameLabelAndRate;
			if (isSame) {
				matchFound = true;
				return {
					...line,
					rate: isSameCombinedLabel ? line.rate + curr.rate : line.rate,
					label: isSameCombinedLabel ? line.combinedLabel ?? line.label : line.label,
					price: line.price + curr.price,
				};
			}
			return line;
		});
		if (matchFound) {
			return taxLines;
		}
		return [...taxLines, curr];
	}, [] as TaxLine[]);
};

export const mergeTaxlines = (taxLines: TaxLine[]) => {
	return taxLines.reduce((prev, curr) => {
		let matchFound = false;
		const taxLines = prev.map((line) => {
			const isSame = hasSameRateAndLabel(line, curr);
			if (isSame) {
				matchFound = true;
				return {
					...line,
					price: line.price + curr.price,
				};
			}
			return line;
		});
		if (matchFound) {
			return taxLines;
		}
		return [...taxLines, curr];
	}, [] as TaxLine[]);
};

interface TaxDetailProps {
	label?: LocaleField;
	rate: number;
	taxExcluded: boolean;
}

export const getTaxDetails = (
	price: number,
	taxes: TaxDetailProps[] | TaxDetailProps,
): TaxDetails => {
	const taxesArray = Array.isArray(taxes) ? taxes : [taxes];
	const taxLines: TaxLine[] = taxesArray.map((tax) => {
		const totalTaxes = getTaxAmountsFromPrice(price, {
			taxRate: tax.rate,
			taxExcluded: tax.taxExcluded,
		}).taxAmount;
		return {
			label: tax.label,
			rate: tax.rate,
			price: totalTaxes,
		};
	});
	const mergedTaxLines = mergeTaxlines(taxLines);
	const totalTaxes = mergedTaxLines.reduce((tot, curr) => tot + curr.price, 0);
	return {
		totalTaxes,
		taxLines: mergedTaxLines,
	};
};

export const getTaxAmountsFromPrice = (
	price: number,
	options: { taxRate: number; taxExcluded: boolean },
) => {
	const { taxExcluded, taxRate } = options;
	if (taxExcluded) {
		return getTaxAmountsFromPriceExcludingTax(price, taxRate);
	}
	return getTaxAmountsFromPriceIncludingTax(price, taxRate);
};

const getTaxAmountsFromPriceIncludingTax = (price: number, taxRate: number): TaxAmounts => {
	const priceExcludingTax = Math.round(price / (1 + taxRate));
	const tax = price - priceExcludingTax;
	return {
		total: price,
		netAmount: priceExcludingTax,
		taxAmount: tax,
		taxRate,
	};
};

const getTaxAmountsFromPriceExcludingTax = (price: number, taxRate: number): TaxAmounts => {
	const priceIncludingTax = Math.round(price * (1 + taxRate));
	const tax = priceIncludingTax - price;
	return {
		total: priceIncludingTax,
		netAmount: price,
		taxAmount: tax,
		taxRate,
	};
};

type RateLabelList = [number, LocaleField | undefined];

export const getUniqueTaxRatesAndLabels = (taxLines: TaxLine[]): Array<RateLabelList> => {
	const rateAndLabelDefEqual = (taxLine1: RateLabelList, taxLine2: RateLabelList) => {
		const [rate1, label1] = taxLine1;
		const [rate2, label2] = taxLine2;
		return hasSameRateAndLabel({ rate: rate1, label: label1 }, { rate: rate2, label: label2 });
	};

	const uniqueTaxRatesAndLabels: Array<[number, LocaleField | undefined]> = uniqWith(
		taxLines.map((line) => [line.rate, line.label]),
		rateAndLabelDefEqual,
	);
	return uniqueTaxRatesAndLabels;
};

type RateLabelObject = Pick<TaxLine, 'rate' | 'label'>;

export const hasSameRateAndLabel = (obj1: RateLabelObject, obj2: RateLabelObject) => {
	return obj1.rate === obj2.rate && obj1?.label?.def === obj2?.label?.def;
};

interface TaxBreakdown extends TaxAmounts {
	label: LocaleField | undefined;
	currency: Currency | undefined;
}

export const getTaxBreakdownFromItems = (items: ItemObject[]): TaxBreakdown[] => {
	const totalItemPricing = getTotalItemPricing(items.map((i) => i.pricing));
	const uniqueTaxRatesAndLabels = getUniqueTaxRatesAndLabels(totalItemPricing.taxLines);
	return uniqueTaxRatesAndLabels.map(([rate, label]) => {
		const validItems = items.filter((item) =>
			item.pricing.taxLines.some((line) => hasSameRateAndLabel(line, { rate, label })),
		);
		const pricing = getTotalItemPricing(validItems.map((i) => i.pricing));
		const validTaxLines = validItems
			.flatMap((items) => items.pricing.taxLines)
			.filter((line) => hasSameRateAndLabel(line, { rate, label }));
		const total = pricing.total;
		const taxAmount = getTotalTaxes(validTaxLines);
		const netAmount = total - taxAmount;
		return {
			label,
			taxRate: rate,
			total,
			taxAmount,
			netAmount,
			currency: pricing.currency,
		};
	});
};
