import { z } from 'zod';

import { isHHMMString, isIsoDate } from './dateUtils';
import { withOpenApi } from './openApi';

export const zAmountObject = z.object({
	value: z.number(),
	currency: z.string(),
});

export const zIsoString = withOpenApi(
	z
		.string()
		.refine(isIsoDate, { message: 'Not a valid ISO 8601 date-time string' })
		// The input date can be in any timezone, so we transform it to UTC:
		.transform((date) => new Date(date).toISOString()),
	{
		title: 'ISO 8601 date-time',
		description: 'Date-time in ISO 8601 format.',
	},
);

export const zHHMMString = withOpenApi(
	z.string().refine(isHHMMString, { message: 'String is not in HH:MM format.' }),
	{
		title: 'HH:MM time',
	},
);

export const zNonEmptyString = z
	.string()
	.refine((s) => s.trim().length > 0, { message: 'String is empty' });
