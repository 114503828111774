/**
 * Utility class for safely using firestore writeBatches without extraneous boilerplate code. This utility ensures
 * that no single batch has more than 500 write operations, which is the limit imposed by firestore.
 *
 * ClientBatchManager: for use with firestore client SDK
 * AdminBatchManager: for use with firestore admin SDK
 */

/**
 * Limit of how many operations can be in a single batch
 */
const MAX_OPS_PER_BATCH = 499;

/**
 * Limit of how many operations can be stored in memory before they should be committed
 */
const MAX_OPS_BEFORE_COMMIT = MAX_OPS_PER_BATCH * 4 - 1;

export interface BatchInterface {
	commit: () => Promise<any>;
}

export abstract class BaseBatchManager<T extends BatchInterface> {
	batches: T[] = [];
	operationCount: number = 0;

	abstract createBatch(): T;

	/** Get a batch that is safe to add more operations to
	 *
	 * @returns A WriteBatch
	 */
	batch() {
		const batchIndex = Math.floor(this.operationCount / MAX_OPS_PER_BATCH);
		this.operationCount++;

		if (!this.batches[batchIndex]) {
			this.batches.push(this.createBatch());
		}

		return this.batches[batchIndex];
	}

	shouldCommit() {
		return this.operationCount >= MAX_OPS_BEFORE_COMMIT;
	}

	/**
	 * Commit all of the batches in this manager, and reset to initial state when done
	 */
	async commit() {
		await Promise.all(this.batches.map((b) => b.commit()));

		this.batches = [];
		this.operationCount = 0;
	}
}
