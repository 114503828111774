import React from 'react';

import { Grid } from '@mui/material';

import { OnboardingStep } from 'services/onboarding';

import OnboardingItemCard from './OnboardingItemCard';

export interface Props {
	step: OnboardingStep;
}
const OnboardingStepGrid = (props: Props) => {
	const { step } = props;
	const { items } = step;
	return (
		<Grid container direction="row" spacing={2} mb={2}>
			{items?.map((item, index) => (
				<Grid item xs={12} md={4} key={item.content.title + index}>
					<OnboardingItemCard item={item} index={index} />
				</Grid>
			))}
		</Grid>
	);
};

export default OnboardingStepGrid;
