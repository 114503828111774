import { AddOnDefinition, AddOns } from '../types';

/**
 * When we remove the legacy plans, we can remove this add-on since the functionality will be available for all plans.
 *
 * For now though, we need to just "auto-install" it for all of the new plans.
 */
const definition: AddOnDefinition = {
	addOn: AddOns.PRODUCT_VARIANTS,
	blockSlots: 1,
	autoInstall: true,
};

export default definition;
