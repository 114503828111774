import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.discountCodes.name', 'Discount Codes'),
	subHeader: t(
		'common:blocks.details.discountCodes.subHeader',
		'Create campaigns and reward your customers',
	),
	description: t(
		'common:blocks.details.discountCodes.description',
		"Create public campaigns for your audience or give personalized discounts to your most loyal customers. Rentle's discount codes block opens a vast range of possibilities to boost your sales and activate your customers to make a purchase",
	),
	supportArticle: SupportArticles.DISCOUNT_CODES,
	uninstallNotice: t(
		'common:blocks.details.discountCodes.uninstallNotice',
		'By uninstalling Discount Codes, you will lose all your created codes',
	),
});

export default details;
