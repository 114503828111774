import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.dashboard.insights',
			'Daily, weekly and monthly insights',
		),
	},
];

export default valueProps;
