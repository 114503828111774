import React from 'react';

import { Button, ButtonProps, Grid, Stack, Typography, useTheme } from '@mui/material';

import ContainerBox from 'components/ContainerBox';

export interface Props {
	img: string;
	imgAlt: string;
	title: string;
	description: string;
	ctas: ButtonProps[];
	externalLink?: JSX.Element;
}
const HomeCTA = (props: Props) => {
	const { img, imgAlt, title, description, externalLink, ctas } = props;
	const theme = useTheme();
	return (
		<ContainerBox>
			<Grid container spacing={2} alignItems="center">
				<Grid item sm={12} md={2}>
					<img style={{ width: '100px' }} src={img} alt={imgAlt} />
				</Grid>
				<Grid item sm={12} md={7.5}>
					<Typography variant="h6">{title}</Typography>
					<Stack
						direction="row"
						alignItems="center"
						spacing={1}
						sx={{ marginTop: theme.spacing(1) }}
					>
						<Typography
							sx={{
								fontWeight: 400,
							}}
							variant="body2"
						>
							{description}
						</Typography>
						{externalLink}
					</Stack>
				</Grid>
				<Grid item sm={12} md={2.5}>
					<Stack direction={{ xs: 'row', md: 'column' }} alignItems="center" spacing={1.5}>
						{ctas.map((cta, idx) => (
							<Button key={idx} {...cta} sx={{ textAlign: 'center' }} />
						))}
					</Stack>
				</Grid>
			</Grid>
		</ContainerBox>
	);
};

export default HomeCTA;
