import { OpeningHours, OpeningTimes } from './types';

export const DEFAULT_OPENING_TIMES: OpeningTimes = {
	openTime: '00:00',
	closeTime: '23:59',
	isClosed: false,
};

export const DEFAULT_OPENING_HOURS: OpeningHours = {
	base: {
		rows: [
			{
				dayType: 'allDays',
				times: DEFAULT_OPENING_TIMES,
			},
		],
	},
	exceptions: [],
};
