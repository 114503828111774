import { z } from 'zod';

export const zAddress = z.object({
	address: z.string().min(1),
	zipCode: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
	state: z.string().optional(),
	details: z.string().optional(),
});

export type Address = z.infer<typeof zAddress>;

export type AddressField = keyof Address;
