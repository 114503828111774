import buildPath from './buildPath';
import * as paths from './constants';

export const paymentDoc = (paymentId: string) => buildPath(paths.paymentDocBase, paymentId);

export const transactionDoc = (transactionId: string) =>
	buildPath(paths.transactionDocBase, transactionId);

export const transactionMissingDoc = (transactionMissingId: string) =>
	buildPath(paths.transactionsMissingDocBase, transactionMissingId);

export const transactionLedgerDoc = (transactionLedgerId: string) =>
	buildPath(paths.transactionLedgerDocBase, transactionLedgerId);

export const shopBalanceDoc = (shopBalanceId: string) =>
	buildPath(paths.shopBalancesDocBase, shopBalanceId);

export const receiptDoc = (receiptId: string) => buildPath(paths.receiptDocBase, receiptId);

export const shopDoc = (shopId: string) => buildPath(paths.shopDocBase, shopId);

export const orderDoc = (orderId: string) => buildPath(paths.orderDocBase, orderId);

export const orderPaymentRequests = (orderId: string) =>
	buildPath(paths.orderPaymentRequestsBase, orderId);

export const orderPaymentRequestDoc = ({
	orderId,
	paymentRequestId,
}: {
	orderId: string;
	paymentRequestId: string;
}) => buildPath(paths.orderPaymentRequestDocBase, [orderId, paymentRequestId]);

export const orderEvents = (orderId: string) => buildPath(paths.orderEventsBase, orderId);

export const orderEventsDoc = ({ orderId, eventId }: { orderId: string; eventId: string }) =>
	buildPath(paths.orderEventDocBase, [orderId, eventId]);

export const orderHistory = (orderId: string) => buildPath(paths.orderHistoryBase, orderId);

export const orderHistoryDoc = ({ orderId, historyId }: { orderId: string; historyId: string }) =>
	buildPath(paths.orderHistoryDocBase, [orderId, historyId]);

export const orderProductDoc = (productId: string) =>
	buildPath(paths.orderProductDocBase, productId);

export const orderShopperDoc = (shopperId: string) =>
	buildPath(paths.orderShopperDocBase, shopperId);

export const orderDeliveriesDoc = (deliveryId: string) =>
	buildPath(paths.orderDeliveriesDocBase, deliveryId);

export const shopPublicDoc = (shopId: string) => buildPath(paths.shopPublicDocBase, shopId);

export const shopExceptionPeriods = (shopId: string) =>
	buildPath(paths.shopExceptionPeriodsBase, shopId);

export const shopExceptionPeriodDoc = ({
	shopId,
	periodId,
}: {
	shopId: string;
	periodId: string;
}) => buildPath(paths.shopExceptionPeriodDocBase, [shopId, periodId]);

export const shopReadOnlyDoc = (shopId: string) => buildPath(paths.shopReadOnlyDocBase, shopId);

export const pendingRefundDoc = (refundId: string) =>
	buildPath(paths.pendingRefundDocBase, refundId);

export const pendingOrderRequestDoc = (orderId: string) =>
	buildPath(paths.pendingOrderRequestDocBase, orderId);

export const pendingExtendRequestDoc = (orderId: string) =>
	buildPath(paths.pendingExtendRequestDocBase, orderId);

export const pendingTransactionDoc = (transactionId: string) =>
	buildPath(paths.pendingTransactionDocBase, transactionId);

export const pendingDepositDoc = (depositId: string) =>
	buildPath(paths.pendingDepositDocBase, depositId);

export const pendingCaptureDoc = (captureId: string) =>
	buildPath(paths.pendingCaptureDocBase, captureId);

export const categoryDoc = (categoryId: string) =>
	buildPath(paths.productCategoriesDocBase, categoryId);

export const productDoc = (productId: string) => buildPath(paths.productDocBase, productId);

export const reportDoc = (reportId: string) => buildPath(paths.reportExportsDocBase, reportId);

export const shopAnalyticsDoc = (docId: string) => buildPath(paths.shopAnalyticsDocBase, docId);

export const userDoc = (userId: string) => buildPath(paths.usersDocBase, userId);

export const deliveryOptionDoc = (deliveryOptionId: string) =>
	buildPath(paths.deliveryOptionDocBase, deliveryOptionId);

export const carrierDoc = (carrierId: string) => buildPath(paths.carriersDocBase, carrierId);

export const customerOrderHistory = (customerId: string) =>
	buildPath(paths.customersOrderHistoryBase, customerId);
