import { range } from 'lodash';
import { Size, convert, iso } from 'shoe-size-converter';

import { Measurement, SizeChart, SizeChartType, UnitSystemType, UnitSystems } from 'common/types';
import { removeUndefinedValues } from 'common/utils/common';

import { GetUserDetailOptionsProps, UserDetailOptions } from '.';

export type ShoeSizeUnit = 'EU' | 'US' | 'UK';

/**
 * US/UK sizes are [1..13.5] (Kids) [1..n] (Men/Women/Adult).
 * For making value unique, [1..n] (Men/Women/Adult) will be plus 13 ([1..n] => [14..n]).
 *
 * There are overlaps between kids and adult sizes:
 * - UK: 13.5 (Kids) = 1 (Adult) = 32.5 EU. So 13.5 (Kids) will be hidden.
 * - US:
 *   - [13..13.5] (Kids) = [1..1.5] (Men) = [31..32] EU. So 13.5 (Kids), 1 (Men) will be hidden.
 *   - [12..13.5] (Kids) = [1..2.5] (Women) = [30..32] EU. So 13.5 (Kids), [1..2] (Women) will be hidden.
 *
 * References:
 * https://en.wikipedia.org/wiki/Shoe_size
 * https://www.sizeguide.net/sizes-for-childrens-shoes-convert-kids-shoe-sizes.html
 * https://www.sizeguide.net/shoe-sizes.html
 */
export const getShoeSizeOptions = (props?: GetUserDetailOptionsProps): UserDetailOptions => {
	const unitSystem = props?.unitSystem ?? UnitSystems.METRIC;
	if (unitSystem === UnitSystems.US) {
		const values =
			props?.sizeChart === SizeChart.KIDS
				? range(8, 20.5, 0.5).filter((s) => s < 13.5 || s > 14)
				: props?.sizeChart === SizeChart.WOMEN
				? range(16, 30, 0.5)
				: range(15, 29, 0.5);
		return {
			values: values.map(String),
			unit: 'US',
		};
	}
	if (unitSystem === UnitSystems.UK) {
		const values = props?.sizeChart === SizeChart.KIDS ? range(7.5, 13.5, 0.5) : range(14, 28, 0.5);
		return {
			values: values.map(String),
			unit: 'UK',
		};
	}
	return {
		values: range(25, 51, 1).map(String),
		unit: 'EU',
	};
};

export const isUSUKChildrenShoeSizeOption = (size: string | number) => {
	return Number(size) <= 13.5;
};

/**
 * Map option value to correct shoe size value, [14..n] => [1..n-13] (Men/Women/Adult)
 */
export const USUKOptionValueToShoeSize = (optionValue: string) => {
	const parsedOptionValue = Number(optionValue);
	const size = isUSUKChildrenShoeSizeOption(parsedOptionValue)
		? parsedOptionValue
		: parsedOptionValue - 13;
	return size.toString();
};

/**
 * Map shoe size to option value, return adult value if children is not true / provided
 */
export const USUKShoeSizeToOptionValue = (size: string | number, children?: boolean) => {
	const parsedSize = Number(size);
	const optionValue = children ? parsedSize : parsedSize + 13;
	return optionValue.toString();
};

const getUSSizeChartFromConvertedResult = (result: Size, sizeChart?: SizeChartType) =>
	result.children || (sizeChart === SizeChart.KIDS && Number(result.size) <= 7)
		? SizeChart.KIDS
		: result.women
		? SizeChart.WOMEN
		: SizeChart.MEN;

const getUKSizeChartFromConvertedResult = (result: Size, sizeChart?: SizeChartType) =>
	result.children ? SizeChart.KIDS : SizeChart.ADULTS;

/**
 * US has 3 size charts (Men/Women/Kids), UK has 2 (Adult/Kids).
 * EU uses 1 size chart for all, so EU is used as the reference size for all units and saved to db.
 * `women` key is also saved to db for all unit for future conversions.
 * If `women` is not added, the Men's size chart is used as default for US, because unisex also uses the Men's size.
 * `children` key is not saved to db, only used on UI together with US/UK for converting to EU to save to db.
 *
 * The `convert` function takes any input shoe size and returns the converted in all possible units.
 */
export const convertShoeSize = (input: Measurement, unitSystem: UnitSystemType): Measurement =>
	removeUndefinedValues(_convertShoeSize(input, unitSystem));

const _convertShoeSize = (input: Measurement, unitSystem: UnitSystemType): Measurement => {
	const { value, unit, sizeChart } = input;
	const size = unit === 'US' || unit === 'UK' ? USUKOptionValueToShoeSize(value) : value;
	const children = unit === 'US' || unit === 'UK' ? isUSUKChildrenShoeSizeOption(value) : undefined;
	const women = !children ? sizeChart === SizeChart.WOMEN : undefined;

	const possibleResults = convert({ size, system: unit.toLowerCase(), women, children }, iso);

	if (unitSystem === UnitSystems.US) {
		const result = possibleResults.filter(
			(r) => r.system === 'us' && (r.children || (women ? r.women : r.men)),
		)[0];
		return result
			? {
					value: USUKShoeSizeToOptionValue(result.size, result.children),
					unit: 'US',
					sizeChart: getUSSizeChartFromConvertedResult(result, sizeChart),
			  }
			: input;
	}
	if (unitSystem === UnitSystems.UK) {
		const result = possibleResults.filter((r) => r.system === 'uk')[0];
		return result
			? {
					value: USUKShoeSizeToOptionValue(result.size, result.children),
					unit: 'UK',
					sizeChart: getUKSizeChartFromConvertedResult(result, sizeChart),
			  }
			: input;
	}
	const result = possibleResults.filter((r) => r.system === 'eu')[0];
	return input.unit !== 'EU' && result
		? { value: result.size.toString(), unit: 'EU', sizeChart }
		: input.unit === 'EU'
		? { value: Math.round(Number(value)).toString(), unit: 'EU', sizeChart }
		: input;
};
