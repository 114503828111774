import { TFunction } from 'i18next';
import { mapValues } from 'lodash';
import moment from 'moment-timezone';

import {
	ProductPricing,
	isFreePrice,
	multiplyProductPricing,
	sumProductPricing,
} from 'common/modules/plans/common';
import { AmountObject, ISOString, YYYY_MM_DD } from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { constrain } from 'common/utils/math';
import { multiplyPrice } from 'common/utils/pricing';

import { Plan, PlanDefinitions, PurchasedPlan, getIncludedStoreCount } from '../plans';
import { BILLING_CURRENCY } from './../../billing/constants';
import { BillingCycle, LocationSubscription, Subscription } from './types';

export const isLastDayOfMonth = (date: ISOString) =>
	moment.utc(date).daysInMonth() === moment.utc(date).date();

export const calculateUpcomingSubscriptionDate = (
	date: ISOString,
	billingCycle: number,
): YYYY_MM_DD =>
	isLastDayOfMonth(date)
		? moment.utc(date).add(billingCycle, 'month').endOf('month').format('YYYY-MM-DD')
		: moment.utc(date).add(billingCycle, 'month').format('YYYY-MM-DD');

export const calculatePreviousSubscriptionDate = (
	date: ISOString,
	billingCycle: number,
): YYYY_MM_DD =>
	isLastDayOfMonth(date)
		? moment.utc(date).subtract(billingCycle, 'month').endOf('month').format('YYYY-MM-DD')
		: moment.utc(date).subtract(billingCycle, 'month').format('YYYY-MM-DD');

export const getSubscriptionDates = (args: {
	dateFrom: YYYY_MM_DD;
	billingCycle: BillingCycle;
}): {
	previousBillingDate: YYYY_MM_DD;
	upcomingBillingDate: YYYY_MM_DD;
} => {
	const previousBillingDate = moment.utc(args.dateFrom).startOf('day').format('YYYY-MM-DD');
	const upcomingBillingDate = calculateUpcomingSubscriptionDate(
		previousBillingDate,
		args.billingCycle,
	);
	return {
		previousBillingDate,
		upcomingBillingDate,
	};
};

export const setSubscriptionBillingDates = (args: {
	subscription: Subscription;
	upcomingBillingDate: YYYY_MM_DD;
}): Subscription => {
	const { subscription, upcomingBillingDate } = args;
	const previousBillingDate = calculatePreviousSubscriptionDate(
		upcomingBillingDate,
		subscription.billingCycle,
	);

	return {
		...subscription,
		previousBillingDate,
		upcomingBillingDate,
		locations: mapValues(subscription.locations, (locationSubscription) => ({
			...locationSubscription,
			previousBillingDate,
			upcomingBillingDate,
		})),
	};
};

export const getMonthlyPriceMultiplierForBillingCycle = (billingCycle: BillingCycle): number => {
	switch (billingCycle) {
		case BillingCycle.MONTHLY:
			return 1;
		case BillingCycle.YEARLY:
			return 0.75;
		default:
			return switchUnreachable(billingCycle);
	}
};

export const getPurchasedPlanBillingCycle = (purchasedPlan: PurchasedPlan): BillingCycle => {
	return purchasedPlan.subscription?.billingCycle ?? BillingCycle.MONTHLY;
};

export const getMerchantTotalSubscriptionPrice = (args: {
	purchasedPlan: PurchasedPlan;
	allLocationIds: string[];
	mainLocationId: string;
}): ProductPricing | null => {
	const { purchasedPlan, allLocationIds, mainLocationId } = args;
	const locationPrices = allLocationIds.map((locationId) => {
		return getStoreSubscriptionPrice({
			purchasedPlan,
			locationId,
			mainLocationId,
			allLocationIds,
		});
	});

	return sumProductPricing(locationPrices);
};

export const getStoreSubscriptionPrice = (args: {
	purchasedPlan: PurchasedPlan;
	locationId: string;
	mainLocationId: string;
	allLocationIds: string[];
}): ProductPricing | null => {
	const { purchasedPlan, locationId, mainLocationId, allLocationIds } = args;
	const plan = PlanDefinitions[purchasedPlan.plan];
	const isMainLocation = locationId === mainLocationId;
	switch (plan.billing.type) {
		case 'none':
			return null;
		case 'fixed-store-count': {
			return isMainLocation ? getPurchasedPlanBasePrice(purchasedPlan) : null;
		}
		case 'subscription-per-store': {
			const subscription = getLocationSubscription(purchasedPlan, locationId);
			return LEGACY_getStoreSubscriptionPrice({ purchasedPlan, subscription });
		}
		case 'price-per-additional-store': {
			if (isMainLocation) return getPurchasedPlanBasePrice(purchasedPlan);
			const includedStoreCount = getIncludedStoreCount({ purchasedPlan });
			const isIncluded = isIncludedStore({
				includedStoreCount,
				locationIndex: allLocationIds.indexOf(locationId),
				mainLocationIndex: allLocationIds.indexOf(mainLocationId),
			});

			return isIncluded ? null : getPurchasedPlanAdditionalStorePrice(purchasedPlan);
		}
	}
};

export const getPlanDefaultBasePrice = (
	plan: Plan,
	billingCycle: BillingCycle,
): ProductPricing | null => {
	const planDefinition = PlanDefinitions[plan];
	const pricing = {
		fixed: planDefinition.pricing?.fixed?.amount ?? null,
	};

	if (isFreePrice(pricing)) return null;

	const monthlyPriceMultiplier = getMonthlyPriceMultiplierForBillingCycle(billingCycle);
	return multiplyProductPricing(pricing, monthlyPriceMultiplier, {
		roundDownToClosestInteger: true,
	});
};

export const getPurchasedPlanBasePrice = (purchasedPlan: PurchasedPlan): ProductPricing | null => {
	const billingCycle = getPurchasedPlanBillingCycle(purchasedPlan);
	const monthlyPrice = (() => {
		switch (purchasedPlan.pricing.type) {
			case 'default':
				return getPlanDefaultBasePrice(purchasedPlan.plan, billingCycle);
			case 'custom': {
				const pricing = purchasedPlan.pricing.price;

				return isFreePrice(pricing) ? null : pricing;
			}
			case 'free': {
				return null;
			}
			default: {
				return switchUnreachable(purchasedPlan.pricing);
			}
		}
	})();

	return multiplyProductPricing(monthlyPrice, billingCycle);
};

export const getPlanDefaultAdditionalStorePrice = (
	plan: Plan,
	billingCycle: BillingCycle,
): ProductPricing | null => {
	const planDefinition = PlanDefinitions[plan];

	if (planDefinition.billing.type !== 'price-per-additional-store') return null;

	const pricing = {
		fixed: planDefinition.billing.pricePerAdditionalStore?.fixed?.amount ?? null,
	};

	if (isFreePrice(pricing)) return null;

	const monthlyPriceMultiplier = getMonthlyPriceMultiplierForBillingCycle(billingCycle);
	return multiplyProductPricing(pricing, monthlyPriceMultiplier, {
		roundDownToClosestInteger: true,
	});
};

export const getPurchasedPlanAdditionalStorePrice = (
	purchasedPlan: PurchasedPlan,
): ProductPricing | null => {
	const plan = PlanDefinitions[purchasedPlan.plan];
	const billingCycle = getPurchasedPlanBillingCycle(purchasedPlan);
	if (plan.billing.type !== 'price-per-additional-store') return null;

	const monthlyPrice = (() => {
		switch (purchasedPlan.additionalStorePricing.type) {
			case 'default':
				return getPlanDefaultAdditionalStorePrice(purchasedPlan.plan, billingCycle);
			case 'custom': {
				const pricing = purchasedPlan.additionalStorePricing.price;

				return isFreePrice(pricing) ? null : pricing;
			}
			case 'free': {
				return null;
			}
			default: {
				return switchUnreachable(purchasedPlan.additionalStorePricing);
			}
		}
	})();

	return multiplyProductPricing(monthlyPrice, billingCycle);
};

export const LEGACY_getStoreSubscriptionPrice = (args: {
	purchasedPlan: PurchasedPlan;
	subscription: LocationSubscription | null;
}): ProductPricing | null => {
	const { purchasedPlan, subscription } = args;
	if (!subscription) return null;

	switch (subscription.pricing.type) {
		case 'free':
			return null;
		case 'custom':
			return subscription.pricing.price;
		case 'default':
		default:
			return getPurchasedPlanBasePrice(purchasedPlan);
	}
};

export const isRevenueDiscountEnabledForPlan = (plan: PurchasedPlan): boolean => {
	switch (plan.plan) {
		case 'BASIC':
		case 'GROW':
		case 'ADVANCED':
			return true;
		default:
			return false;
	}
};

export const getLocationSubscription = (
	purchasedPlan: PurchasedPlan,
	locationId: string,
): LocationSubscription | null => {
	return purchasedPlan.subscription?.locations[locationId] ?? null;
};

export const getNewSubscription = (args: {
	startDate: ISOString;
	locationIds: string[];
	billingCycle: BillingCycle;
	currentSubscription?: Pick<
		Subscription,
		'billingCycle' | 'previousBillingDate' | 'upcomingBillingDate'
	>;
	resetBillingCycle?: boolean;
}): Subscription => {
	const { startDate, locationIds, billingCycle, currentSubscription, resetBillingCycle } = args;

	const hasBillingCycleChanged = currentSubscription?.billingCycle !== billingCycle;

	const subscriptionDates =
		!currentSubscription || hasBillingCycleChanged || resetBillingCycle
			? getSubscriptionDates({
					dateFrom: startDate,
					billingCycle,
			  })
			: {
					previousBillingDate: currentSubscription.previousBillingDate,
					upcomingBillingDate: currentSubscription.upcomingBillingDate,
			  };

	const { previousBillingDate, upcomingBillingDate } = subscriptionDates;

	return locationIds.reduce(
		(result, locationId) => {
			result.locations[locationId] = getNewLocationSubscription({
				startDate,
				currentSubscription,
				billingCycle,
			});

			return result;
		},
		{
			upcomingBillingDate,
			previousBillingDate,
			billingCycle,
			locations: {},
		} as Subscription,
	);
};

export const getNewLocationSubscription = (args: {
	startDate: ISOString;
	billingCycle: BillingCycle;
	currentSubscription?: Pick<
		Subscription,
		'billingCycle' | 'previousBillingDate' | 'upcomingBillingDate'
	>;
}): LocationSubscription => {
	const { startDate, currentSubscription, billingCycle } = args;

	const subscriptionDates =
		currentSubscription?.billingCycle === billingCycle
			? {
					previousBillingDate: currentSubscription.previousBillingDate,
					upcomingBillingDate: currentSubscription.upcomingBillingDate,
			  }
			: getSubscriptionDates({
					dateFrom: startDate,
					billingCycle,
			  });

	const { previousBillingDate, upcomingBillingDate } = subscriptionDates;
	return {
		startDate: moment.utc(startDate).format('YYYY-MM-DD'),
		billingCycle,
		previousBillingDate,
		previousBillingInfo: {
			refundableAmount: {
				value: 0,
				currency: BILLING_CURRENCY.code,
			},
			hasRevenueDiscount: false,
		},
		upcomingBillingDate,
		pricing: {
			type: 'default',
		},
	};
};

const getPassedCycleDays = (
	subscription: Pick<Subscription, 'previousBillingDate' | 'upcomingBillingDate'>,
	date: ISOString,
) =>
	Math.max(
		moment.utc(date).diff(moment.utc(subscription.previousBillingDate).startOf('day'), 'days'),
		0,
	);

const getTotalCycleDays = (
	subscription: Pick<Subscription, 'previousBillingDate' | 'upcomingBillingDate'>,
) =>
	moment
		.utc(subscription.upcomingBillingDate)
		.startOf('day')
		.diff(moment.utc(subscription.previousBillingDate).startOf('day'), 'days');

export const getCycleProgression = (
	subscription: Pick<Subscription, 'previousBillingDate' | 'upcomingBillingDate'>,
	date: ISOString,
) => {
	const totalCycleDays = getTotalCycleDays(subscription);
	const passedDays = getPassedCycleDays(subscription, date);

	return constrain(0, 1)(passedDays / totalCycleDays);
};

export const getSubscriptionBillingCycle = (subscription: Subscription): BillingCycle => {
	return subscription.billingCycle ?? BillingCycle.MONTHLY;
};

export const getSubscriptionBillingCycleDates = (
	subscription: Subscription | LocationSubscription,
) => ({
	start: subscription.previousBillingDate,
	end: subscription.upcomingBillingDate,
});

/**
 * Get the amount of refund credits to give out when cancelling a subscription, or changing to another subscription
 */
export const getSubscriptionCancelRefund = (args: {
	subscription: LocationSubscription | null;
	cancellationDate: ISOString;
}): AmountObject => {
	const { subscription, cancellationDate } = args;

	if (!subscription) return { value: 0, currency: BILLING_CURRENCY.code };

	const daysPassedPercentage = getCycleProgression(subscription, cancellationDate);
	const daysRemainingPercentage = 1 - daysPassedPercentage;
	const { refundableAmount } = subscription.previousBillingInfo;

	return multiplyPrice(refundableAmount, daysRemainingPercentage);
};

/**
 * Get the amount of discount to give when changing to a new plan midway through a billing cycle
 */
export const getSubscriptionStartDiscount = (args: {
	subscriptionPrice: AmountObject | null;
	subscription: Pick<LocationSubscription, 'previousBillingDate' | 'upcomingBillingDate'> | null;
	startDate: ISOString;
}): AmountObject => {
	const { subscriptionPrice, subscription, startDate } = args;
	if (!subscriptionPrice || !subscription) return { value: 0, currency: BILLING_CURRENCY.code };

	const daysPassedPercentage = getCycleProgression(subscription, startDate);
	return multiplyPrice(subscriptionPrice, daysPassedPercentage);
};

export const shouldRenewSubscription = (
	subscription: Subscription | LocationSubscription,
	date: ISOString,
) => {
	return subscription.upcomingBillingDate <= moment.utc(date).format('YYYY-MM-DD');
};

export const isIncludedStore = (args: {
	includedStoreCount: number;
	locationIndex: number;
	mainLocationIndex: number;
}): boolean => {
	const { includedStoreCount, locationIndex, mainLocationIndex } = args;
	if (locationIndex === mainLocationIndex) return true;

	const lastIncludedStoreIndex =
		locationIndex > mainLocationIndex ? includedStoreCount - 1 : includedStoreCount - 2;

	return locationIndex <= lastIncludedStoreIndex;
};

export const isAdditionalStore = (args: {
	plan: PurchasedPlan;
	locationId: string;
	mainLocationId: string;
	allLocationIds: string[];
}): boolean => {
	const { plan, locationId, mainLocationId, allLocationIds } = args;
	const planDefinition = PlanDefinitions[plan.plan];
	if (planDefinition.billing.type !== 'price-per-additional-store') return false;
	const includedStoreCount = getIncludedStoreCount({ purchasedPlan: plan });
	return !isIncludedStore({
		includedStoreCount,
		locationIndex: allLocationIds.indexOf(locationId),
		mainLocationIndex: allLocationIds.indexOf(mainLocationId),
	});
};

export const getBillingCycleLabel = (cycle: BillingCycle, t: TFunction) => {
	const labels: Record<BillingCycle, string> = {
		[BillingCycle.MONTHLY]: t('common:times.monthly', 'Monthly'),
		[BillingCycle.YEARLY]: t('common:times.yearly', 'Yearly'),
	};

	return labels[cycle];
};
