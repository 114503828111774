import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getRouteOnboardingStepItem } from '../utils';

export const LOGO_ITEM = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<
	'LOGO',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	LOGO: {
		all: {
			action: showProductTours
				? getFlowOnboardingStepItem({ onboardingAction: 'LOGO' })
				: getRouteOnboardingStepItem({
						routeTo: Routes.ACCOUNT_GENERAL,
						routeHash: '#appearance',
				  }),
			content: {
				title: t('homeView.onboarding.addLogo', 'Add your logo'),
			},
			id: 'LOGO',
			type: 'row',
		},
	},
});
