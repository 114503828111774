import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { Action, AnyAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import * as RoutingTypes from 'routing/types';

import { ProductTotalAvailability } from 'common/api/frontend/inventory_new';
import { CustomerApi } from 'common/models/Customer/types';
import { InventoryConfiguration, InventoryItem, SkuItem } from 'common/modules/inventory/types';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { AddOn, AddOnChannel, RestrictedFeature } from 'common/modules/plans';
import {
	ById,
	Category,
	DiscountCodeApi,
	Duration,
	ISOString,
	Languages,
	Location,
	OrderDelivery,
	OrderInfo,
	OrderProduct,
	PhoneObject,
	ProductApi,
	RemoteData,
	ShopPublicInfo,
	Shopper,
	UserApi,
} from 'common/types';
import { NotificationState } from 'reducers/NotificationReducer';
import { RentalViewState } from 'reducers/RentalViewReducer';
import { ShopState } from 'reducers/ShopReducer';
import { TranslationsState } from 'reducers/TranslationsReducer';
import { UserState } from 'reducers/UserReducer';

export type TimeRange =
	| {
			type: 'past-week';
	  }
	| {
			type: 'past-month';
	  }
	| {
			type: 'past-3-months';
	  }
	| {
			type: 'custom';
			range: [moment.Moment | null, moment.Moment | null];
	  }
	| {
			type: 'all';
	  };

export interface ReduxState {
	users: UsersState;
	user: UserState;
	shop: ShopState;
	stock: StockState;
	lang: Languages;
	newRental: NewRentalState;
	view: ViewState;
	storeRentals: StoreRentalsState;
	rentalView: RentalViewState;
	activeProduct: ActiveProductState;
	activeDiscount: ActiveDiscountState;
	availability: AvailabilityState;
	orders: OrdersState;
	notifications: NotificationState;
	translations: TranslationsState;
	customers: CustomersState;
}
export type ThunkResult<R = void> = ThunkAction<R, ReduxState, unknown, AnyAction>;
export type ReduxThunkAction = ThunkAction<void, ReduxState, unknown, Action<string>>;

export type LoadingData<T> =
	| { loading: true; error: null; data: T | null }
	| { loading: false; error: string; data: null }
	| { loading: false; error: null; data: T };

export type UsersState = RemoteData<UserApi[]>;

export type OrdersState = RemoteData<OrderInfo[]>;

export type CustomersListFilters = Partial<{ marketing: boolean }>;

export type CustomersPageState = LoadingData<CustomerApi[]>;
export interface CustomersState {
	pageSize: number;
	timeRange: TimeRange;
	page: CustomersPageState;
	pageTokens: Record<number, string | undefined>;
	pageNumber: number;
	total: number;
	export: { exporting: boolean; error: string | null };
	searchQuery?: string;
	listFilters: CustomersListFilters;
}

export interface InventoryState {
	skuItems: LoadingData<SkuItem[]>;
	inventoryItems: LoadingData<InventoryItem[]>;
	configuration: LoadingData<InventoryConfiguration | undefined>;
}

export interface ActiveProductState {
	product: LoadingData<ProductApi>;
	localChanges: {
		product: Partial<ProductApi>;
	};
	saving: boolean;
	error: string | null;
	savedAt: ISOString | null;
}

export interface ActiveDiscountState {
	discountCode: LoadingData<DiscountCodeApi>;
	localChanges: Partial<DiscountCodeApi>;
	saving: boolean;
	error: string | null;
}

export interface StockState {
	stockProducts: LoadingData<ProductApi[]>;
	categories: LoadingData<Category[]>;
}

export interface RentalLinkData {
	shopPublicInfo: ShopPublicInfo;
	startLocation: Location;
	email: string;
	phone: PhoneObject;
	payLinkUrl: string;
	language: string;
	rentalId: string;
	status: string;
	paymentMethodOptions: ShopOnlinePaymentMethodObject[];
}

export interface ViewState {
	sideBarOpen: boolean;
	payLinkInProgress: boolean;
	timePanelOpen: boolean;
	expandedRentalDialogPanel: string;
	imageUploading: boolean;
	userIsActive: boolean;
	startDate: string;
	endDate: string;
	duration: Duration;
	rentNowToggle: boolean;
	mobileRentalSideBarOpen: boolean;
	showRentalViewFormErrors: boolean;
	calendarView: string;
	startPrint: boolean;
	rentalLinkSendingFailed: boolean;
	pendingRentalLinks: RentalLinkData[];
	rentalsActiveTab: RentalsTabValue;
	showEarlyOrLatePickupDialog: boolean;
	upgradeModalOpen: boolean;
	bookingsViewLocationType: 'current' | 'all';
	devPanelOpen: boolean;
	ordersViewLocationType: 'current' | 'all';
	newClientUpdate: boolean;
	dataGridStates: { [key: string]: GridInitialStatePro };
	installedAddOnModalState: {
		addOn: AddOn | null;
		open: boolean;
	};
	addOnModalState: {
		addOn: AddOn | null;
		addOnChannel: AddOnChannel | null;
		open: boolean;
		nextRoute?: string;
	};
	authState: {
		isAuthenticated: boolean;
		loading: boolean;
	};
	earnCreditsModalOpen: boolean;
	switchToNewPlansModalOpen: boolean;
	growthbookInitialized: boolean;
}

export interface StoreRentalsState {
	activeBookedStoreRentals: RemoteData<OrderInfo[]>;
	expiredBookedStoreRentals: RemoteData<OrderInfo[]>;
	activeStoreRentals: RemoteData<OrderInfo[]>;
	pendingStoreRentals: RemoteData<OrderInfo[]>;
	completedStoreRentals: RemoteData<OrderInfo[]>;
	allOutgoingProducts: RemoteData<OrderProduct[]>;
	expiringIncomingProducts: RemoteData<OrderProduct[]>;
	expiredIncomingProducts: RemoteData<OrderProduct[]>;
}

export interface NewRentalState {
	rentalInfo: OrderInfo;
	shoppers: Shopper[];
	products: OrderProduct[];
	selectedCategoryId: string;
	signatureBlob?: Blob;
	phone: PhoneObject;
	productAvailabilities: ById<ProductTotalAvailability>;
	orderDelivery?: OrderDelivery;
	reservationId: string | null;
	isDurationManuallyChanged: boolean;
}

export type AvailabilityObject = {
	[day: string]: {
		hours: {
			[hour: string]: {
				variants: {
					[variantId: string]: number; // The availability of a given variant for a single hour
				};
				lowest: number | null; // The lowest availability for the hour (null if unknown)
			};
		};
		lowest: number | null; // The lowest availability for the day (null if unknown)
	};
};

export type OpeningHoursObject = {
	[day: string]: {
		openMinutes: number;
		closeMinutes: number;
		isClosed: boolean;
	};
};

export interface AvailabilityState {
	availabilities: AvailabilityObject;
	openingHours: OpeningHoursObject;
	loading: boolean;
	minDate: string | null;
	maxDate: string | null;
	identifier: string | null; // Store this to discard any responses that are stale
}

export interface TabItem {
	name: string;
	pathValue: string;
	count?: number;
	disabled?: boolean;
	hidden?: boolean;
	component?: any;
	tooltipText?: string;
	requiredFeature?: RestrictedFeature | RestrictedFeature[];
	dataQA?: string;
}

export interface TabItemWithRoute extends TabItem {
	routeComponent: React.ComponentType<any>;
	pathValue: RoutingTypes.Tab;
	exactPath?: boolean;
}

export enum RentalsTabValues {
	BOOKED = 'BOOKED',
	ACTIVE = 'ACTIVE',
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	SKIDATA = 'SKIDATA',
}

export type RentalsTabValue = RentalsTabValues;

export type ShopTabValue = 'GENERAL' | 'ONLINESTORE' | 'SELFSERVICE' | 'OPENINGHOURS';

export type SaveStatus = 'SAVING' | 'SAVED' | 'NOT_SAVED';

export enum PaymentStatus {
	Paid = 'PAID',
	Unpaid = 'UNPAID',
	All = 'ALL',
}

export enum PreparedStatus {
	Prepared = 'PREPARED',
	NotPrepared = 'NOT-PREPARED',
	All = 'ALL',
}

export enum CompletedStatus {
	Completed = 'COMPLETED',
	Cancelled = 'CANCELLED',
	Paid = 'PAID',
	Unpaid = 'UNPAID',
	All = 'ALL',
}

export enum ConfirmedStatus {
	Confirmed = 'PAID',
	Pending = 'PENDING',
	All = 'ALL',
}

export enum PurchaseTypeStatus {
	All = 'ALL',
	Rentals = 'RENTALS',
	Subscriptions = 'SUBSCRIPTIONS',
	Sales = 'SALES',
}

export interface FilterStatus {
	payment: PaymentStatus;
	prepared: PreparedStatus;
	completed: CompletedStatus;
	confirmed: ConfirmedStatus;
	purchaseType: PurchaseTypeStatus;
	products: ProductApi[];
	categories: Category[];
}

export type AvailabilityStatusType = 'available' | 'unavailable' | 'loading' | 'low';
