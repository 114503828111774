import { DepositPricing, OrderPricing, getTotalItemPricing } from 'common/modules/atoms/pricing';
import {
	OrderServiceFee,
	StoreServiceFee,
	getOrderServiceFee,
} from 'common/modules/atoms/serviceFee';
import { mergeTaxlines } from 'common/modules/atoms/taxes';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import {
	Discount,
	OrderDelivery,
	OrderInfo,
	OrderProduct,
	RecurringData,
	ResponsiblePersonDetails,
	Shopper,
} from 'common/types';
import { getResponsiblePersonDetails } from 'common/utils/newRentalUtils';

import { getTotalProductPricing } from '../products';

interface PricingOptions {
	serviceFee?: StoreServiceFee | OrderServiceFee;
	depositInfo?: Omit<DepositPricing, 'value'>;
	discountPercentage?: number;
	taxExcluded: boolean;
	fallbackCurrency: string;
	paymentMethod?: ShopOnlinePaymentMethodObject;
}

export const getOrderHasDeposit = (orderInfo: OrderInfo) => {
	return !!orderInfo.pricing.deposit?.value;
};

export const getOrderAmount = (orderInfo: OrderInfo) => {
	return orderInfo.pricing.total;
};

export const getPricingOptions = (pricing: OrderPricing): PricingOptions => {
	return {
		serviceFee: pricing.serviceFee,
		depositInfo: {
			type: pricing.deposit?.type,
			additionalInfo: pricing.deposit?.additionalInfo,
			description: pricing.deposit?.description,
		},
		discountPercentage: pricing.manualDiscount?.percentage || undefined,
		taxExcluded: pricing.taxExcluded,
		fallbackCurrency: pricing.currency,
		paymentMethod: pricing.paymentMethod,
	};
};

export const getOrderPricing = (
	products: OrderProduct[],
	orderDelivery: OrderDelivery | undefined | null,
	opts: PricingOptions,
): OrderPricing => {
	const itemPricings = [...products, ...(!!orderDelivery ? [orderDelivery] : [])].map(
		(item) => item.pricing,
	);
	const totalItemPricing = getTotalItemPricing(itemPricings, opts.fallbackCurrency);
	const orderServiceFee = opts?.serviceFee
		? getOrderServiceFee(opts.serviceFee, totalItemPricing.total, {
				taxExcluded: totalItemPricing.taxExcluded,
		  })
		: undefined;
	const taxLines = mergeTaxlines([
		...totalItemPricing.taxLines,
		...(orderServiceFee?.taxLines ?? []),
	]);
	const totalTaxes = taxLines.reduce((prev, curr) => prev + curr.price, 0);
	const totalPrice = totalItemPricing.total + (orderServiceFee?.total ?? 0);
	const subtotal = opts.taxExcluded ? totalPrice - totalTaxes : totalPrice;
	return {
		currency: totalItemPricing.currency,
		total: totalPrice,
		subtotal,
		totalTaxes,
		totalDiscounts: totalItemPricing.totalDiscounts,
		taxExcluded: opts.taxExcluded,
		taxLines,
		...(totalItemPricing.manualDiscount && {
			manualDiscount: {
				amount: totalItemPricing.manualDiscount,
				percentage: opts?.discountPercentage,
			},
		}),
		discountCodes: totalItemPricing.discountCodes,
		serviceFee: orderServiceFee,
		deposit: {
			value: totalItemPricing.deposit ?? 0,
			type: opts?.depositInfo?.type ?? 'payment',
			additionalInfo: opts?.depositInfo?.additionalInfo,
			description: opts?.depositInfo?.description,
		},
		paymentMethod: opts.paymentMethod,
	};
};

export const getManualOrderDiscount = (products: OrderProduct[]): Discount => {
	const pricing = getTotalProductPricing(products);
	return { amount: pricing.manualDiscount ?? 0 };
};

export const getPaymentCardDetailsFromOrder = (args: {
	orderInfo: OrderInfo;
	shoppers: Shopper[];
}): RecurringData | undefined => {
	const { orderInfo, shoppers } = args;
	const responsiblePerson = getResponsiblePersonDetails(orderInfo.responsiblePerson, shoppers);
	return getPaymentCardDetailsFromShopper(responsiblePerson);
};

export const getPaymentCardDetailsFromShopper = (
	responsiblePerson: ResponsiblePersonDetails | Shopper,
): RecurringData | undefined => {
	return responsiblePerson.savedPaymentInfo;
};

export const isAcsOrder = (orderInfo: OrderInfo) => {
	return !!orderInfo.acs;
};

export const hasMoneyDeposit = (orderInfo: OrderInfo) => {
	return !!orderInfo.pricing.deposit?.value;
};

export const getStartDate = (orderInfo: OrderInfo) => {
	return orderInfo.startDate;
};
