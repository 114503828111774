import React from 'react';

import { Box, Button, CircularProgress, Collapse } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface Props {
	saving: boolean;
	visible: boolean;
	onSave: () => void;
	onCancel: () => void;
	position?: 'fixed' | 'absolute';
}
const SaveSnackbar = (props: Props) => {
	const { classes } = useStyles();
	const { saving, visible, onSave, onCancel, position } = props;

	return (
		<Collapse in={visible} className={classes.wrapper} sx={{ position }}>
			<Box className={classes.saveSnackbar}>
				<Button onClick={onCancel} disabled={saving}>
					Discard changes
				</Button>
				<Box mr={2} />
				<Button variant="contained" color="primary" disabled={saving} onClick={onSave}>
					{saving ? <CircularProgress size={20} color="inherit" /> : 'Save changes'}
				</Button>
			</Box>
		</Collapse>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	wrapper: {
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: theme.zIndex.modal,
	},
	saveSnackbar: {
		padding: theme.spacing(2),
		background: theme.palette.colors.peach.light,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
}));

export default SaveSnackbar;
