import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { OrderProduct } from 'common/types';
import { getUniqueProductWithVariantNamesFromVariantIds } from 'common/utils/newRentalUtils';
import { Trans } from 'services/localization';
import { useTranslation } from 'services/localization/useTranslation';

import Banner from './Banner';

interface OverbookedBannerStyling {
	link?: boolean;
	isBarcodeScanner?: boolean;
	sticky?: boolean;
}

interface OpeningHoursProps {
	isOutsideOpeningHours?: boolean;
	isBeforeOpeningHours?: boolean;
	isClosed?: boolean;
}

interface Props {
	allowOverbookings: boolean;
	rentalProducts: OrderProduct[];
	overbookedVariantIds: string[];
	openingHours?: OpeningHoursProps;
	title?: string;
	description?: JSX.Element;
	styling?: OverbookedBannerStyling;
}
const OverbookedBanner = (props: Props) => {
	const {
		allowOverbookings,
		rentalProducts,
		overbookedVariantIds,
		styling,
		description,
		openingHours,
	} = props;
	const { classes } = useStyles();
	const { t, lang } = useTranslation();
	const hasOverbookedVariants = !!overbookedVariantIds.length;

	const renderOutsideOfHoursBannerText = () => {
		return (
			<>
				{openingHours?.isClosed
					? t('newRental.storeIsClosed', 'Store is closed on the selected day.')
					: openingHours?.isBeforeOpeningHours
					? t(
							'newRental.beforeOpeningHours2',
							'Order starts before the store opens. Change order start time',
					  )
					: t(
							'newRental.afterOpeningHours2',
							'Order ends outside of opening hours. Change order end time',
					  )}
			</>
		);
	};

	const overbookedProductNames = getUniqueProductWithVariantNamesFromVariantIds(
		rentalProducts,
		overbookedVariantIds,
		lang,
	);

	const renderOverbookingBannerText = () => {
		return styling?.link ? (
			<Trans
				i18nKey="newRental.exceededAvailability"
				defaults={'Not enough <link>{{products}} </link> available'}
				values={{ products: overbookedProductNames.join(', ') }}
				components={{
					link: (
						<Link
							className={classes.productLink}
							to="/"
							target="_blank"
							rel="noopener noreferrer"
						></Link>
					),
				}}
			/>
		) : (
			t('newRental.exceededAvailabilityNoLink', {
				defaultValue: 'Not enough {{products}} available',
				products: overbookedProductNames.join(', '),
			})
		);
	};

	const isOverbookingAndOutsideHoursWarning =
		openingHours?.isOutsideOpeningHours && hasOverbookedVariants;

	return (
		<Box className={styling?.sticky ? classes.sticky : undefined}>
			<Banner
				variant="danger"
				title={
					!styling?.isBarcodeScanner
						? allowOverbookings
							? t('newRental.avoidOverbooking', 'Avoid overbooking')
							: t('newRental.updateToContinue2', 'Update order to continue')
						: undefined
				}
				description={
					description ?? (
						<ul
							className={
								isOverbookingAndOutsideHoursWarning
									? classes.bannerList
									: classes.unstyledBannerList
							}
						>
							{hasOverbookedVariants && (
								<li className={classes.listItems}>{renderOverbookingBannerText()}</li>
							)}
							{openingHours?.isOutsideOpeningHours && (
								<li className={classes.listItems}>{renderOutsideOfHoursBannerText()}</li>
							)}
						</ul>
					)
				}
			/>
		</Box>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	bannerList: {
		paddingLeft: theme.spacing(2),
		margin: 0,
		marginTop: theme.spacing(0.5),
	},
	unstyledBannerList: {
		listStyle: 'none',
		paddingLeft: 0,
		margin: 0,
	},
	listItems: {
		marginBottom: theme.spacing(0.5),
		'&:last-child': {
			marginBottom: 0,
		},
	},
	productLink: {
		color: theme.palette.primary.main,
		textDecoration: 'underline',
	},
	sticky: {
		[theme.breakpoints.down('lg')]: {
			position: 'sticky',
			top: 72,
		},
		zIndex: 1100,
	},
}));

export default OverbookedBanner;
