import { createSelector } from '@reduxjs/toolkit';

import { getProductsByCategory } from 'common/modules/products/utils';
import { hasSingleVariant } from 'common/modules/products/variants';
import { hashByUniqueField } from 'common/utils/arrays';
import { isLiftTicketCategory, isLiftTicketProduct } from 'common/utils/liftTicketUtils';
import * as UserSelectors from 'selectors/UserSelectors';
import { ReduxState } from 'services/types';

import {
	getProductsVisibleInLocation,
	getProductsWithoutCategory,
} from './../../../common/modules/products/utils';

export const stockProducts = (state: ReduxState) => state.stock.stockProducts;
export const stockProductsData = (state: ReduxState) => stockProducts(state).data || [];
export const stockProductsLoading = (state: ReduxState) => stockProducts(state).loading;
export const stockProductsError = (state: ReduxState) => stockProducts(state).error;
export const stockProductsCount = (state: ReduxState) => stockProductsData(state).length;
export const maxProductOrderIndex = (state: ReduxState) =>
	Math.max(...stockProductsData(state).map((p) => p.orderIndex ?? 0), 0);

export const categories = (state: ReduxState) => state.stock.categories;
export const categoriesData = (state: ReduxState) => categories(state).data || [];
export const categoriesLoading = (state: ReduxState) => categories(state).loading;
export const categoriesError = (state: ReduxState) => categories(state).error;
export const categoriesCount = (state: ReduxState) => categoriesData(state).length;
export const maxCategoryOrderIndex = (state: ReduxState) =>
	Math.max(...categoriesData(state).map((c) => c.orderIndex ?? 0), 0);

export const stockProductsById = createSelector(stockProductsData, (products) =>
	hashByUniqueField(products, 'id'),
);

export const stockProductsByCategory = createSelector(stockProductsData, (products) =>
	getProductsByCategory(products),
);

export const stockProductsWithoutCategory = createSelector(stockProductsData, (products) =>
	getProductsWithoutCategory(products),
);

export const categoriesById = createSelector(categoriesData, (categories) =>
	hashByUniqueField(categories, 'id'),
);

export const isStockLoading = createSelector(
	stockProductsLoading,
	categoriesLoading,
	(productsLoading, categoriesLoading) => productsLoading || categoriesLoading,
);

export const hasStockProducts = createSelector(
	stockProductsData,
	(products) => products.length > 0,
);

export const isStockEmpty = createSelector(
	stockProductsCount,
	categoriesCount,
	(productsCount, categoriesCount) => productsCount === 0 && categoriesCount === 0,
);

export const firstProductWithNoVariants = createSelector(stockProductsData, (products) => {
	return products.find(hasSingleVariant);
});

export const hasDepositProducts = createSelector(stockProductsData, (products) => {
	return products.some((product) => !!product.terms?.deposit);
});

export const productsUsingPricingTables = createSelector(stockProductsData, (products) => {
	const result: { [key: string]: { name: string; id: string }[] } = products.reduce(
		(result, product) => {
			if (product.useSavedPricingTable && product.savedPricingTable) {
				if (result[product.savedPricingTable]) {
					result[product.savedPricingTable].push({
						name: product.name.def,
						id: product.id,
					});
				} else {
					result[product.savedPricingTable] = [
						{
							name: product.name.def,
							id: product.id,
						},
					];
				}
			}
			return result;
		},
		{},
	);

	return result;
});

export const liftTicketCategory = createSelector(categoriesData, (categories) =>
	categories.find(isLiftTicketCategory),
);

export const liftTicketSegments = createSelector(
	liftTicketCategory,
	(category) => category?.segmentMapping,
);

export const liftTicketProducts = createSelector(stockProductsData, (products) =>
	products.filter((p) => isLiftTicketProduct(p)),
);

export const stockProductIdAccountingGroupMap = createSelector(stockProductsData, (products) =>
	products.reduce((prev, curr) => {
		if (!curr.customAccountingGroups?.length) {
			return prev;
		}
		return {
			...prev,
			[curr.id]: curr.customAccountingGroups,
		};
	}, {} as { [id: string]: string[] }),
);

export const activeLocationProducts = createSelector(
	stockProductsData,
	UserSelectors.userActiveLocationId,
	(products, locationId) => {
		if (!locationId) return [];
		return getProductsVisibleInLocation(products, locationId);
	},
);

export const stockProductsWithAdditionalProducts = createSelector(stockProductsData, (products) =>
	products.filter((p) => p.additionalProductIds?.length),
);
