import { getOpenApiExample } from 'common/utils/openApi';

import { zOrderInfoApi } from './api';
import { toApi } from './mapper';

export const OrderInfo = {
	api: zOrderInfoApi,
	toApi,
	example: getOpenApiExample(zOrderInfoApi),
};
