import { z } from 'zod';

import { newFirestoreId } from 'common/utils/dataMigrations';
import { withOpenApi } from 'common/utils/openApi';
import { zIsoString } from 'common/utils/zod';

import { BookingBuffer } from '../_atoms/BookingBuffer';
import { zCatalogVisibilityChannelApi } from '../_atoms/Channel/api';
import { LocaleField } from '../_atoms/LocaleField';
import { PricingItem } from './PricingItem';
import { StartTimes } from './StartTimes';
import { Variant, VariantProperty } from './Variant';

const _examplePropertyId = newFirestoreId();

export const zProductApi = withOpenApi(
	z.object({
		id: z.string(),
		createdAt: zIsoString,
		categoryIds: z.string().array(),
		name: LocaleField.api,
		description: LocaleField.api.optional(),
		images: z.string().url().array(),
		isPackage: z.boolean(),
		packageProductIds: z.string().array().optional(),
		taxPercentage: z.number().int().min(0).max(100),
		recommendedProductIds: z.string().array(),
		limitations: z.object({
			storeIds: z.array(z.string()).optional(),
			channels: z.array(zCatalogVisibilityChannelApi).optional(),
			maintenanceTimeMinutes: z.number().int().nonnegative().optional(),
			onlineAvailabilityPercentage: z.number().int().min(0).max(100).optional(),
			startTimes: StartTimes.api.optional(),
			bookingBuffer: BookingBuffer.api.optional(),
			visibleInListing: z.boolean(),
		}),
		order: z
			.number()
			.int()
			.transform((value) => Math.max(value, 0)),
		rentals: z.object({
			enabled: z.boolean(),
			basePrice: z.number().int().nonnegative(),
			pricing: PricingItem.api.array().optional(),
			deposit: z.number().int().nonnegative().optional(),
		}),
		sales: z.object({
			basePrice: z.number().int().nonnegative(),
			enabled: z.boolean(),
		}),
		// Wait before subscriptions release to add subscriptions data to API
		// subscriptions: z.object({
		// 	enabled: z.boolean(),
		// 	options: ProductSubscription.api.array(),
		// }),
		variantProperties: VariantProperty.api.array(),
		variants: Variant.api.array(),
	}),
	{
		title: 'Product',
		description: 'Product document',
		fields: {
			id: 'Unique identifier of the object.',
			createdAt: 'Created date.',
			categoryIds: 'List of category IDs the product belongs to.',
			name: 'Name of the product.',
			description: 'Product description.',
			images: 'List of product image URLs.',
			isPackage: 'Whether the product is a package or not.',
			packageProductIds:
				'List of product IDs that are included in the package. Only exists if product is a package.',
			taxPercentage: 'The tax percentage of the product, in percentages.',
			recommendedProductIds:
				'List of product IDs that are recommended to be bought together with this product.',
			limitations: {
				description: 'Limitation details of the product.',
				fields: {
					storeIds:
						'Array of store IDs where the product is visible in. If null, product is visible in all stores.',
					channels:
						'Array of channels where the product is visible in. If null, product is visible in all channels.',
					maintenanceTimeMinutes:
						'The time in minutes that the product is unavailable for a new booking after the booking had ended.',
					onlineAvailabilityPercentage:
						'The percentage of the total product quantity that is available for online booking.',
					startTimes: 'Start times settings of the product.',
					visibleInListing:
						'If true, the product will be visible in the store listing. If this is false, product can still be booked as an additional product, as part of a product package, or by visiting the product page via a direct link.',
				},
			},
			order:
				'Order of the product compared to other products. Smaller number means higher priority.',
			rentals: {
				description: 'Rental settings of the product.',
				fields: {
					enabled: 'If true, the product can be booked as a rental product.',
					basePrice: 'Base price of the product for rentals. In minor units.',
					pricing:
						'Pricing of the product. If not set, the product has a fixed base price, defined in `basePrice`.',
					deposit: 'Deposit price of the product. In minor units.',
				},
			},
			sales: {
				description: 'Sales settings of the product.',
				fields: {
					enabled: 'If true, the product can be booked as a sales product.',
					basePrice: 'Base price of the product for sales. In minor units.',
				},
			},
			variants:
				'Variants of the product. These define all the possible product options that the user can select from.',
			variantProperties:
				'Variant properties define the unique product characteristics that user can select from, such as Color or Size. These are used to define the variants of the product.',
		},
		example: {
			id: newFirestoreId(),
			createdAt: new Date().toISOString(),
			categoryIds: [newFirestoreId()],
			name: { def: 'E-bike', en: 'E-bike' },
			description: { def: 'Perfect e-bike for beginners', en: 'Perfect e-bike for beginners' },
			images: ['image_url'],
			isPackage: false,
			taxPercentage: 10,
			recommendedProductIds: [newFirestoreId()],
			limitations: {
				storeIds: [newFirestoreId()],
				channels: ['online'],
				maintenanceTimeMinutes: 60,
				onlineAvailabilityPercentage: 100,
				startTimes: {
					type: 'interval',
					intervalMinutes: 60,
				},
				bookingBuffer: {
					daysBefore: 1,
					latestTime: 18,
				},
				visibleInListing: true,
			},
			order: 1,
			rentals: {
				enabled: true,
				basePrice: 20_00,
				pricing: [
					{
						timePeriod: 'days',
						timeValue: 1,
						price: 20_00,
					},
				],
				deposit: 100_00,
			},
			sales: {
				basePrice: 199_00,
				enabled: true,
			},
			variantProperties: [
				{
					id: _examplePropertyId,
					name: { def: 'Size', en: 'Size' },
				},
			],
			variants: [
				{
					id: newFirestoreId(),
					properties: [
						{
							name: { def: 'Size', en: 'Size' },
							value: { def: 'Small', en: 'Small' },
							propertyId: _examplePropertyId,
						},
					],
					sales: {
						enabled: true,
					},
					rentals: {
						enabled: true,
					},
					inventory: [
						[
							{
								skuId: 'E-BIK-S',
								units: 1,
								type: 'single',
							},
						],
					],
				},
				{
					id: newFirestoreId(),
					properties: [
						{
							name: { def: 'Size', en: 'Size' },
							value: { def: 'Large', en: 'Large' },
							propertyId: _examplePropertyId,
						},
					],
					sales: {
						enabled: true,
					},
					rentals: {
						enabled: true,
					},
					inventory: [
						[
							{
								skuId: 'E-BIK-L',
								units: 1,
								type: 'single',
							},
						],
					],
				},
			],
		},
	},
);
