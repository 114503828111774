import { createReducer } from '@reduxjs/toolkit';

import {
	LoadingData,
	OrderDelivery,
	OrderInfo,
	OrderProduct,
	SetProductWithParentId,
	Shopper,
} from 'common/types';
import * as RentalViewActions from 'actions/RentalViewActions';
import { SaveStatus } from 'services/types';
import { setError, setFulfilled, setLoading } from 'services/utils/redux';

export interface RentalViewState {
	activeShopperId: string | null;
	selectedNonSetProductIds: string[];
	selectedSetProducts: SetProductWithParentId[];
	orderInfo: LoadingData<OrderInfo>;
	orderProducts: LoadingData<OrderProduct[]>;
	orderShoppers: LoadingData<Shopper[]>;
	orderDelivery: LoadingData<OrderDelivery | null>;
	saveStatus: SaveStatus;
	hideProductCodeErrors: boolean;
}

const INITIAL_STATE: RentalViewState = {
	activeShopperId: null,
	selectedNonSetProductIds: [],
	selectedSetProducts: [],
	saveStatus: 'SAVED',
	hideProductCodeErrors: false,
	orderInfo: {
		loading: true,
		data: null,
		error: null,
	},
	orderProducts: {
		loading: true,
		data: null,
		error: null,
	},
	orderShoppers: {
		loading: true,
		data: null,
		error: null,
	},
	orderDelivery: {
		loading: true,
		data: null,
		error: null,
	},
};

const RentalViewReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(RentalViewActions.updateActiveShopperId, (state, action) => {
		state.activeShopperId = action.payload;
	});

	builder.addCase(RentalViewActions.updateSelectedNonSetProductIds, (state, action) => {
		state.selectedNonSetProductIds = action.payload;
	});

	builder.addCase(RentalViewActions.updateSelectedSetProducts, (state, action) => {
		state.selectedSetProducts = action.payload;
	});

	builder.addCase(RentalViewActions.updateSaveStatus, (state, action) => {
		state.saveStatus = action.payload;
	});

	builder.addCase(RentalViewActions.setHideProductCodeErrors, (state, action) => {
		state.hideProductCodeErrors = action.payload;
	});

	builder.addCase(RentalViewActions.updateOrderProducts, (state, action) => {
		if (!!state.orderProducts.data) {
			state.orderProducts.data = state.orderProducts.data.map((product) => {
				const updates = action.payload.find((item) => item.id === product.id);
				if (!!updates) {
					return {
						...product,
						...updates.data,
					};
				}

				return product;
			});
		}
	});

	builder.addCase(RentalViewActions.updateOrderShoppers, (state, action) => {
		if (!!state.orderShoppers.data) {
			state.orderShoppers.data = state.orderShoppers.data.map((shopper) => {
				const updates = action.payload.find((item) => item.id === shopper.id);
				if (!!updates) {
					return {
						...shopper,
						...updates.data,
					};
				}
				return shopper;
			});
		}
	});

	builder.addCase(RentalViewActions.updateOrderInfo, (state, action) => {
		if (!!state.orderInfo.data) {
			state.orderInfo.data = {
				...state.orderInfo.data,
				...action.payload,
			};
		}
	});

	builder
		.addCase(RentalViewActions.deleteOrderProductWithId, (state, action) => {
			if (!!state.orderProducts.data) {
				state.orderProducts.data = state.orderProducts.data.filter(
					(product) => product.id !== action.payload,
				);
			}
		})
		.addCase(RentalViewActions.deleteOrderProductsWithIds, (state, action) => {
			if (!!state.orderProducts.data) {
				state.orderProducts.data = state.orderProducts.data.filter(
					(product) => !action.payload.includes(product.id),
				);
			}
		});

	builder
		.addCase(RentalViewActions.deleteOrderShopperWithId, (state, action) => {
			if (!!state.orderShoppers.data) {
				state.orderShoppers.data = state.orderShoppers.data.filter(
					(shopper) => shopper.id !== action.payload,
				);
			}
		})
		.addCase(RentalViewActions.deleteOrderShoppersWithIds, (state, action) => {
			if (!!state.orderShoppers.data) {
				state.orderShoppers.data = state.orderShoppers.data.filter(
					(shopper) => !action.payload.includes(shopper.id),
				);
			}
		});

	builder
		.addCase(RentalViewActions.addOrderInfoListener.pending, (state) => {
			setLoading(state.orderInfo);
		})
		.addCase(RentalViewActions.setOrderInfoData, (state, action) => {
			setFulfilled(state.orderInfo, { data: action.payload });
		})
		.addCase(RentalViewActions.addOrderInfoListener.rejected, (state, action) => {
			setError(state.orderInfo, { error: action.error.message ?? 'Error getting order info' });
		})
		.addCase(RentalViewActions.setOrderInfoError, (state, action) => {
			setError(state.orderInfo, { error: action.payload });
		});

	builder
		.addCase(RentalViewActions.addOrderProductsListener.pending, (state) => {
			setLoading(state.orderProducts);
		})
		.addCase(RentalViewActions.setOrderProductsData, (state, action) => {
			setFulfilled(state.orderProducts, { data: action.payload });
		})
		.addCase(RentalViewActions.addOrderProductsListener.rejected, (state, action) => {
			setError(state.orderProducts, {
				error: action.error.message ?? 'Error loading order products',
			});
		})
		.addCase(RentalViewActions.setOrderProductsError, (state, action) => {
			setError(state.orderProducts, { error: action.payload });
		});

	builder
		.addCase(RentalViewActions.addOrderShoppersListener.pending, (state) => {
			setLoading(state.orderShoppers);
		})
		.addCase(RentalViewActions.setOrderShoppersData, (state, action) => {
			setFulfilled(state.orderShoppers, { data: action.payload });
		})
		.addCase(RentalViewActions.addOrderShoppersListener.rejected, (state, action) => {
			setError(state.orderShoppers, {
				error: action.error.message ?? 'Error loading order shoppers',
			});
		})
		.addCase(RentalViewActions.setOrderShoppersError, (state, action) => {
			setError(state.orderShoppers, { error: action.payload });
		});

	builder
		.addCase(RentalViewActions.addOrderDeliveryListener.pending, (state) => {
			setLoading(state.orderDelivery);
		})
		.addCase(RentalViewActions.setOrderDeliveryData, (state, action) => {
			setFulfilled(state.orderDelivery, { data: action.payload });
		})
		.addCase(RentalViewActions.addOrderDeliveryListener.rejected, (state, action) => {
			setError(state.orderDelivery, {
				error: action.error.message ?? 'Error loading order delivery',
			});
		})
		.addCase(RentalViewActions.setOrderDeliveryError, (state, action) => {
			setError(state.orderDelivery, { error: action.payload });
		});

	builder.addCase(RentalViewActions.removeActiveOrderListeners.fulfilled, () => INITIAL_STATE);
});

export default RentalViewReducer;
