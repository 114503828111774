import { EnumValues } from 'common/types';
import { getTypedKeys, stringEnum } from 'common/utils/objects';

import { ProductPricingDefinition, PurchasedProductPricing } from '../common';
import { Plan } from '../plans';

export const MerchantSolutions = stringEnum(['DELIVERY']);

export type MerchantSolution = EnumValues<typeof MerchantSolutions>;

export const AllMerchantSolutions = getTypedKeys(MerchantSolutions);

export interface MerchantSolutionDefinition {
	/**
	 * The unique id code of the merchant solution
	 */
	id: MerchantSolution;

	/**
	 * A human-readable name for this merchant solution
	 */
	name: string;

	/**
	 * the pricing of this merchant solution, or null if it is free
	 * - default: The price used if no plan-specific price has been defined
	 * - byPlan: The price to use by plan
	 */

	pricing: {
		default: ProductPricingDefinition | null;
		byPlan?: { [key in Plan]?: ProductPricingDefinition | null };
	};

	/**
	 * Which plans is this merchant solution included in by default?
	 */
	plans: Plan[];
}

export interface PurchasedMerchantSolution {
	/**
	 * The purchased merchant solution
	 */
	merchantSolution: MerchantSolution;

	/**
	 * The price of this plan for this specific merchant and store
	 */
	pricing: PurchasedProductPricing;

	/**
	 * An optional internal note, which is shown in internal admin
	 */
	internalNote?: string;
}

/**
 * The merchant solution configuration of a specific merchant
 */
export type ShopMerchantSolutions = {
	[key in MerchantSolution]?: PurchasedMerchantSolution;
};
