import React from 'react';

import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Stack,
	Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BILLING_CURRENCY, LOWEST_PLAN_PRICE_CENTS } from 'common/modules/billing';
import { isPaidPlan } from 'common/modules/plans';
import { getPricingString } from 'common/utils/common';
import { localFormat } from 'common/utils/dateUtils';
import * as ViewActions from 'actions/ViewActions';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { useTranslation } from 'services/localization';
import { Routes, push } from 'routing';

import PlanUpgradeCtaCard from './PlanUpgradeCtaCard';

const lowestPlanPrice = getPricingString(LOWEST_PLAN_PRICE_CENTS, BILLING_CURRENCY);

const SwitchToNewPlansModal = () => {
	const isModalOpen = useSelector(ViewSelectors.isSwitchToNewPlansModalOpen);
	const shopPlan = useSelector(ShopSelectors.shopPlan)!;
	const shopDateFormat = useSelector(ShopSelectors.getShopDateFormat);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();

	const handleFreePlanClick = () => {
		dispatch(ViewActions.toggleSwitchToNewPlansModal(false));
		push(Routes.PlanConfirm('FREE'), { from: location.pathname });
	};

	const handlePaidPlanClick = () => {
		dispatch(ViewActions.toggleSwitchToNewPlansModal(false));
		push(Routes.PLANS);
	};

	const handleModalClose = () => {
		dispatch(ViewActions.toggleSwitchToNewPlansModal(false));
	};

	const renderFreePlanContent = () => {
		return (
			<>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h6">
							{t('updatedPricingModal.title', "We've updated our pricing")}
						</Typography>
						<IconButton>
							<RiCloseLine onClick={handleModalClose} size={20} />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<DialogContentText fontSize="1.4rem">
							{t('updatedPricingModal.body2', {
								finalDate: localFormat(moment('2023').endOf('year'), 'DD.MM.YYYY', shopDateFormat),
								defaultValue:
									'Enhanced Free, Discover, Build, and Advance plans have replaced the old ones. The legacy plans will no longer be available after {{finalDate}}. Get started by choosing your new plan today!',
							})}
						</DialogContentText>
						<Stack direction="row" spacing={2} justifyContent="space-between">
							<PlanUpgradeCtaCard
								copies={{
									title: t('common:pricing.free', 'Free'),
									price: '$ 0',
									listItems: [
										{
											label: t(
												'updatedPricingModal.standaloneOnlineStore',
												'Standalone Online store',
											),
										},
										{
											label: t(
												'updatedPricingModal.flexibleCatalog',
												'Flexible Catalog & Inventory',
											),
										},
										{
											label: t(
												'updatedPricingModal.secureOnlinePayments',
												'Secure Online Payments',
											),
										},
										{ label: t('updatedPricingModal.englishPlusOne', 'English + 1 Language') },
										{ label: t('updatedPricingModal.oneStore', '1 store') },
									],
									buttonLabel: t('updatedPricingModal.chooseFree', 'Choose Free'),
								}}
								onClick={handleFreePlanClick}
							/>
							<PlanUpgradeCtaCard
								copies={{
									title: t('updatedPricingModal.paidPlans', 'Paid plans'),
									price: t('common.pricing.fromMonthlyShort', {
										price: lowestPlanPrice,
										defaultValue: 'from {{price}}/mo',
									}),
									listItems: [
										{
											label: t(
												'updatedPricingModal.everythingFromFree',
												'Everything from Free plan',
											),
										},
										{
											label: t(
												'updatedPricingModal.includesOneToThreeStores',
												'Includes 1 to 3 stores in a plan',
											),
										},
										{ label: t('updatedPricingModal.zeroDelivery', 'Zero Delivery fees') },
										{ label: t('updatedPricingModal.moreLanguages', 'More languages') },
									],
									buttonLabel: t('updatedPricingModal.chooseYourPlan', 'Choose your plan'),
								}}
								sx={{
									border: (theme) => `1px solid ${theme.palette.border.divider}`,
									borderRadius: '4px',
									background: (theme) => theme.palette.background.upgradeGradient,
								}}
								onClick={handlePaidPlanClick}
							/>
						</Stack>
					</Stack>
				</DialogContent>
			</>
		);
	};

	const renderPaidPlanContent = () => {
		const listItems = [
			{
				label: t('updatedPricingModal.startingAt', {
					price: lowestPlanPrice,
					defaultValue: 'Starting at {{price}} per month',
				}),
			},
			{
				label: t(
					'updatedPricingModal.includesOneToThreeStores',
					'Includes 1 to 3 stores in a plan',
				),
			},
			{ label: t('updatedPricingModal.zeroDelivery', 'Zero Delivery fees') },
			{
				label: t(
					'updatedPricingModal.manualOrdersAvaialble',
					'Manual order creation accessible from the lowest tier',
				),
			},
			{ label: t('updatedPricingModal.reducedPaymentCommission', 'Reduced payment commissions') },
		];

		return (
			<Stack spacing={4}>
				<DialogTitle sx={{ background: 'linear-gradient(to right, #FFCFB8 0%,  #EAB1E8 100%)' }}>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="h6">
							{t('updatePricingModal.title', "We've updated our pricing")}
						</Typography>
						<IconButton onClick={handleModalClose}>
							<RiCloseLine size={20} />
						</IconButton>
					</Stack>
				</DialogTitle>
				<DialogContent sx={{ padding: (theme) => theme.spacing(0, 4, 4, 4) }}>
					<Stack spacing={2}>
						<DialogContentText fontSize="1.4rem">
							{t('updatedPricingModal.body', {
								finalDate: localFormat(moment('2023').endOf('year'), 'DD.MM.YYYY', shopDateFormat),
								defaultValue:
									'Enhanced Free, Discover, Build, and Advance plans have replaced the old ones. The legacy plans will no longer be available after {{finalDate}}',
							})}
						</DialogContentText>
						<Stack>
							<ul style={{ paddingLeft: '16px' }}>
								{listItems.map((item) => (
									<li key={item.label}>
										<Typography variant="body2" sx={{ fontWeight: 400 }}>
											{item.label}
										</Typography>
									</li>
								))}
							</ul>
						</Stack>
						<Button onClick={handlePaidPlanClick} fullWidth variant="contained">
							{t('updatedPricingModal.chooseYourPlan', 'Choose your plan')}
						</Button>
					</Stack>
				</DialogContent>
			</Stack>
		);
	};
	return (
		<Dialog
			open={isModalOpen}
			onClose={() => dispatch(ViewActions.toggleSwitchToNewPlansModal(false))}
		>
			{isPaidPlan(shopPlan) ? renderPaidPlanContent() : renderFreePlanContent()}
		</Dialog>
	);
};

export default SwitchToNewPlansModal;
