import React from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import { AddOn, addonRequiresManualSetup } from 'common/modules/plans';
import { getAddOnDetails } from 'common/modules/plans/add-ons/details';
import { getAddOnIcon } from 'common/modules/plans/add-ons/getAddOnIcon';
import { getAddOnValueProps } from 'services/addOns';
import { useTranslation } from 'services/localization';

interface Props {
	addOn: AddOn;
}
const AddOnModalDetailsSection = (props: Props) => {
	const { addOn } = props;
	const { t } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const addOnDetails = getAddOnDetails(addOn, t);
	const addOnIcon = getAddOnIcon(addOn);
	const addOnValueProps = getAddOnValueProps(addOn);
	const isIntegration = addonRequiresManualSetup(addOn);

	return (
		<Box>
			<Box
				sx={{
					background: theme.palette.background.paper,
					width: 'fit-content',
				}}
				mb={4}
			>
				<StyledImage src={addOnIcon ?? undefined} alt={addOnDetails.name} />
			</Box>
			<Box mb={4}>
				<Typography mb={1} variant="h5">
					{addOnDetails?.name}
				</Typography>
				<Typography mb={4}>{addOnDetails.description}.</Typography>

				{isIntegration && (
					<Typography>
						{t(
							'account.addOns.modal.bookAMeeting',
							'Book a meeting with our sales representative to go through the details to enable the integration',
						)}
						.
					</Typography>
				)}
				{!isMobile && (
					<ul style={{ padding: theme.spacing(0, 0, 0, 2.5) }}>
						{addOnValueProps.map((vp, idx) => (
							<Box m={1}>
								<li key={idx}>
									<Typography>{vp.description}</Typography>
								</li>
							</Box>
						))}
					</ul>
				)}
			</Box>
		</Box>
	);
};

const StyledImage = styled('img')(({ theme }) => ({
	height: '64px',
	width: '64px',
	margin: theme.spacing(5),
	alignSelf: 'flex-start',
	background: theme.palette.background.paper,
	[theme.breakpoints.down('sm')]: {
		height: '100px',
		width: '100px',
		border: `1px solid ${theme.palette.border.inputOutline}`,
		padding: theme.spacing(2),
		margin: 0,
	},
}));

export default AddOnModalDetailsSection;
