import { faker } from '@faker-js/faker/locale/en';

import {
	SetPropertyNotAllowedError,
	SetPropertyValueNotAllowedTypeError,
} from './ErrorHandling.mock';

export const arrify = <T>(source: () => T, length?: number): T[] => {
	const arr = new Array(length).fill(0).map(source);
	return arr;
};

export const toDynamicObject = <T>(array: T[]): { [key: string]: T } => {
	return array.reduce((tot, curr) => ({ ...tot, [faker.datatype.uuid()]: curr }), {});
};

export const oneOf = (source: any[]) => {
	const possibleValuesCount = source.length;
	const randomIndex = getRandomInt(0, possibleValuesCount - 1);
	return source[randomIndex];
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const mock = (source: () => any, amount?: number) => {
	const toBeMocked = amount ? arrify(source, amount) : source();
	return { data: toBeMocked };
};

export const checkForInvalidProperties = (
	trueObject: any,
	checkableObject: any,
	calledFrom?: string,
) => {
	const allowedProperties = Object.keys(trueObject);
	const invalidProperties = Object.keys(checkableObject).filter(
		(key) => !allowedProperties.includes(key),
	);
	if (invalidProperties.length > 0) {
		throw new SetPropertyNotAllowedError(invalidProperties, calledFrom);
	}
};

/*
EscapedTypes:

1. Pass in either a object like {id: ''} to explicitly tell the checker that you want id
to be able to be set to empty string. But it would still fail for '123'(string) if in the trueObject name was set to be
123 (number).

2. You can also pass in object like {id: {types: ['string', 'number']}}, to tell that id can be of type string or number.
Types are given in the format supported by JS typeof
*/
export const checkForUnallowedValueTypes = (
	trueObject: any,
	checkableObject: any,
	calledFrom?: string,
	escapedTypes?: any,
) => {
	const propertiesToBeSet = Object.keys(checkableObject);

	propertiesToBeSet.forEach((property, index) => {
		if (typeof checkableObject[property] !== typeof trueObject[property]) {
			if (escapedTypes) {
				const escapedProperties = Object.keys(escapedTypes);
				escapedProperties.map((key) => {
					let hasTypesKey = false;
					if (checkableObject[key] === escapedTypes[key]) {
						if (escapedTypes[property] !== null && escapedTypes[property].hasOwnProperty('types')) {
							hasTypesKey = true;
						}
						if (!hasTypesKey) {
							trueObject[key] = escapedTypes[key];
						}
					}
				});
			}
			if (typeof checkableObject[property] !== typeof trueObject[property]) {
				if (
					escapedTypes &&
					escapedTypes[property] &&
					escapedTypes[property].hasOwnProperty('types')
				) {
					if (!escapedTypes[property].types.includes(typeof checkableObject[property])) {
						throw new SetPropertyValueNotAllowedTypeError(
							checkableObject[property],
							property,
							calledFrom,
						);
					}
				} else {
					throw new SetPropertyValueNotAllowedTypeError(
						checkableObject[property],
						property,
						calledFrom,
					);
				}
			}
		}
	});
};
