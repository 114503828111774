import { basic, bikeShop, lite } from 'common/api/frontend/mockProducts/templates';
import { Category, ProductApi } from 'common/types';

import { GenerateContentInput } from '../generators';

const splitToProductsAndCategories = (inputArray: GenerateContentInput[]) => {
	const categories = [];
	const products = [];
	for (const input of inputArray) {
		if (input.category) {
			categories.push(input.category);
		}
		products.push(...input.products);
	}
	return { categories, products };
};

export const removeTemplateProducts = (products: ProductApi[]) => {
	const allMockProductsAndCategories = [...lite, ...bikeShop, ...basic];
	const { products: mockProducts } = splitToProductsAndCategories(allMockProductsAndCategories);
	return products.filter((p) =>
		mockProducts.every((mockP) => {
			return (
				mockP.name !== p.name.def ||
				(mockP.description || null) !== (p.description?.def || null) ||
				mockP.image !== p.image
			);
		}),
	);
};

export const removeTemplateCategories = (categories: Category[]) => {
	const allMockProductsAndCategories = [...lite, ...bikeShop, ...basic];
	const { categories: mockCategories } = splitToProductsAndCategories(allMockProductsAndCategories);
	return categories.filter((c) =>
		mockCategories.every((mockC) => {
			return (
				mockC.name !== c.name.def ||
				(mockC.description || null) !== (c.description?.def || null) ||
				mockC.image !== c.imageUrl
			);
		}),
	);
};
