import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconType } from 'react-icons';
import { RiArrowRightSLine } from 'react-icons/ri';
import { makeStyles } from 'tss-react/mui';
import { getItemsMatchingSearch, sortItemsByGroup } from 'views/__INTERNAL__/DevShortcut/utils';

import { push } from 'routing';

import { allSearchablesArray, commonSearchablesArray } from './searchables';
import { SearchTypes } from './types';

const DevShortcut = () => {
	const { classes } = useStyles();
	const [isVisible, setIsVisible] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const itemsToRender = useMemo(() => {
		const items = !!inputValue
			? getItemsMatchingSearch(allSearchablesArray, inputValue)
			: commonSearchablesArray;

		return sortItemsByGroup(items);
	}, [inputValue]);
	const maxIndex = itemsToRender.length - 1;

	const activeItem = itemsToRender[activeIndex];

	useEffect(() => {
		setActiveIndex(0);
	}, [itemsToRender]);

	useHotkeys(
		'ctrl+shift+k, cmd+k',
		() => {
			setIsVisible(true);
		},
		{ enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
		[],
	);

	const handleClose = () => {
		setInputValue('');
		setActiveIndex(0);
		setIsVisible(false);
	};

	const moveToPrevious = () => {
		setActiveIndex((prev) => {
			if (prev === 0) {
				return maxIndex;
			} else {
				return prev - 1;
			}
		});
	};
	const moveToNext = () => {
		setActiveIndex((prev) => {
			if (prev === maxIndex) {
				return 0;
			} else {
				return prev + 1;
			}
		});
	};

	const handleSelectActive = () => {
		const item = itemsToRender[activeIndex];

		if (!item) {
			return handleClose();
		}

		switch (item.type) {
			case SearchTypes.route: {
				handleClose();
				return push(item.value);
			}
			default: {
				break;
			}
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		switch (event.key) {
			case 'Enter': {
				event.preventDefault();
				event.stopPropagation();
				return handleSelectActive();
			}
			case 'Escape':
			case 'Tab': {
				event.preventDefault();
				event.stopPropagation();
				return handleClose();
			}
			case 'ArrowDown': {
				event.preventDefault();
				event.stopPropagation();
				return moveToNext();
			}
			case 'ArrowUp': {
				event.preventDefault();
				event.stopPropagation();
				return moveToPrevious();
			}
			default: {
				break;
			}
		}
	};

	if (!isVisible) return null;

	return (
		<Dialog
			open
			onClose={() => setIsVisible(false)}
			maxWidth="md"
			fullWidth
			className={classes.dialog}
			classes={{
				paper: classes.dialogPaper,
				container: classes.dialogContainer,
			}}
		>
			<SearchHeader
				value={inputValue}
				onChange={setInputValue}
				onKeydown={handleKeyDown}
				suggestion={activeItem?.label}
			/>

			<Divider />
			<DialogContent sx={{ padding: 1 }}>
				{itemsToRender.length === 0 ? (
					<Stack direction="column" alignItems="center" p={3}>
						<Typography textAlign="center">{'No results'}</Typography>
					</Stack>
				) : (
					<List role="listbox" sx={{ p: 0 }}>
						{itemsToRender.map((item, index) => {
							const prevItem = itemsToRender[index - 1];
							const showGroupHeader = prevItem?.group !== item.group;
							return (
								<React.Fragment key={item.group + item.label}>
									{showGroupHeader && <ListSubheader disableSticky>{item.group}</ListSubheader>}
									<SearchResultItem
										label={item.label}
										selected={activeIndex === index}
										icon={item.icon ?? RiArrowRightSLine}
									/>
								</React.Fragment>
							);
						})}
					</List>
				)}
			</DialogContent>
		</Dialog>
	);
};

const SearchResultItem = (props: { label: string; selected: boolean; icon: IconType }) => {
	const ref = useRef<HTMLLIElement>(null);
	const Icon = props.icon;

	useEffect(() => {
		if (props.selected) {
			ref.current?.scrollIntoView({ block: 'nearest' });
		}
	}, [props.selected]);

	return (
		<ListItem selected={props.selected} ref={ref} sx={{ borderRadius: 2 }}>
			<ListItemIcon sx={{ color: (theme) => theme.palette.text.secondary }}>
				<Icon />
			</ListItemIcon>
			<ListItemText primary={props.label} />
		</ListItem>
	);
};

const SearchHeader = (props: {
	value: string;
	onChange: (value: string) => void;
	onKeydown: React.KeyboardEventHandler<HTMLInputElement>;
	suggestion?: string;
}) => {
	return (
		<DialogTitle sx={{ padding: 0 }}>
			<Stack direction="column" alignItems="flex-start">
				<TextField
					fullWidth
					value={props.value}
					onChange={(e) => props.onChange(e.target.value)}
					placeholder="Search or jump to"
					variant="standard"
					InputProps={{
						disableUnderline: true,
						startAdornment:
							!!props.suggestion && !!props.value ? (
								<Stack direction="row" sx={{ position: 'absolute', left: 0 }} spacing={1}>
									<div
										style={{
											fontSize: '16px',
											overflow: 'hidden',
											opacity: 0,
											visibility: 'hidden',
											whiteSpace: 'break-spaces',
										}}
									>
										{props.value}
									</div>
									<Typography
										sx={{
											whiteSpace: 'nowrap',
											color: (theme) => theme.palette.text.disabled,
										}}
									>
										{props.suggestion}
									</Typography>
								</Stack>
							) : null,
					}}
					onBlur={(e) => e.target.focus()}
					onKeyDown={props.onKeydown}
					autoFocus
					autoComplete="off"
					autoCorrect="off"
					autoCapitalize="off"
					sx={{ padding: 2, background: (theme) => theme.palette.background.default }}
				/>
			</Stack>
		</DialogTitle>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	dialog: {
		margin: theme.spacing(2),
	},
	dialogContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingTop: '20vh',
		paddingBottom: '20vh',
	},
	dialogPaper: {
		background: theme.palette.background.default,
		width: '600px',
		maxWidth: '100%',
	},
}));

export default DevShortcut;
