import { Box, Button, Grid, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Listing } from '@rentle/resell-api-client';
import { queries } from 'common/frontend/queries';
import { CurrencyObject } from 'common/types';
import * as NotificationActions from 'actions/NotificationActions';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { useTranslation } from 'services/localization';

import CreateListingForm from './ListingCreateForm';
import ListingPreview from './ListingPreview';

export interface ListingFormData extends Omit<Listing, 'currency' | 'price'> {
	currency: CurrencyObject;
	price: number;
}

const CreateListing = () => {
	const currency = useSelector(ShopSelectors.getShopCurrency);
	const form = useForm<ListingFormData>({
		defaultValues: {
			currency: currency,
			price: 0,
			imageURLs: [],
		},
	});
	const { isValid } = form.formState;
	const listing = form.watch();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const createListing = queries.listings.create.useMutation();

	const formDataToListing = (data: ListingFormData): Listing => {
		return {
			...data,
			currency: data.currency.code,
		};
	};

	const onSubmit = async (data: ListingFormData) => {
		const listing = formDataToListing(data);
		try {
			await createListing.mutateAsync({ listing });
		} catch (err) {
			dispatch(
				NotificationActions.showNotification({
					variant: 'error',
					message: t('common:errors.somethingWentWrongShort'),
				}),
			);
		}
	};

	return (
		<FormProvider {...form}>
			<Grid container sx={{ height: '100%' }}>
				<Grid item xs={12} md={4} sx={{ background: (theme) => theme.palette.background.paper }}>
					<Stack p={2} justifyContent="space-between" sx={{ height: '100%' }}>
						<CreateListingForm />
						<Button
							disabled={!isValid}
							onClick={form.handleSubmit(onSubmit)}
							variant="contained"
							color="primary"
							data-qa="create-listing-submit-button"
						>
							{t('listings.createListing', 'Create Listing')}
						</Button>
					</Stack>
				</Grid>
				<Grid item xs={8}>
					<Box px={6} py={4} sx={{ height: '100%', display: { xs: 'none', md: 'block' } }}>
						<ListingPreview value={formDataToListing(listing)} />
					</Box>
				</Grid>
			</Grid>
		</FormProvider>
	);
};

export default CreateListing;
