import { isLegacyWebhookEvent } from '../_atoms/WebhookEvent/utils';
import { WebhookDb } from './db';
import { LegacyWebhookApi, LegacyWebhookDb, WebhookApi } from './types';

export const isLegacyWebhookDb = (data: WebhookDb): data is LegacyWebhookDb => {
	return isLegacyWebhookEvent(data.event);
};

export const isLegacyWebhookApi = (data: WebhookApi): data is LegacyWebhookApi => {
	return isLegacyWebhookEvent(data.event);
};
