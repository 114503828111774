import moment from 'moment-timezone';
import { z } from 'zod';

import { Address } from 'common/models/_atoms/Address';
import { LocaleField } from 'common/models/_atoms/LocaleField';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { getOpenApiExample, withOpenApi } from 'common/utils/openApi';
import { zIsoString } from 'common/utils/zod';

import { ItemPricing } from '../OrderItem/ItemPricing';
import { OrderPayment } from '../OrderPayment';

const zTimeSlotApi = withOpenApi(
	z.object({
		startDate: zIsoString,
		endDate: zIsoString,
	}),
	{
		fields: {
			startDate: 'Start date of the time slot.',
			endDate: 'End date of the time slot.',
		},
	},
);

const zOrderDeliveryDetailsApi = withOpenApi(
	z.object({
		timeslot: zTimeSlotApi.optional(),
		pricing: ItemPricing.api,
		handlingTimeMinutes: z.number().int().nonnegative(),
		location: z.object({
			address: Address.api.optional(),
		}),
		carrierId: z.string().optional(),
		carrierName: LocaleField.api.optional(),
		trackingId: z.string().optional(),
	}),
	{
		fields: {
			timeslot: 'Time slot of the delivery.',
			pricing: 'Pricing of the delivery.',
			handlingTimeMinutes: 'Handling time of the delivery in minutes.',
			location: {
				description: 'Location of the delivery.',
				fields: {
					address: 'Address of the delivery.',
				},
			},
			carrierId: 'Unique identifier of the carrier.',
			carrierName: 'Name of the carrier.',
			trackingId: 'Tracking id of the delivery.',
		},
		example: () => {
			const timeslotStart = moment().startOf('minute');
			const timeslotEnd = timeslotStart.clone().add(3, 'hours');
			return {
				timeslot: {
					startDate: timeslotStart.toISOString(),
					endDate: timeslotEnd.toISOString(),
				},
				pricing: ItemPricing.example,
				handlingTimeMinutes: 120,
				location: {
					address: Address.example,
				},
				carrierId: newFirestoreId(),
				carrierName: { def: 'Van F255' },
				trackingId: newFirestoreId(),
			};
		},
	},
);

export const zOrderDeliveryApi = withOpenApi(
	z.object({
		name: LocaleField.api,
		pricing: ItemPricing.api,
		payment: OrderPayment.api,
		deliveryOptionId: z.string(),
		to: zOrderDeliveryDetailsApi.optional(),
		from: zOrderDeliveryDetailsApi.optional(),
		returnInstructions: LocaleField.api.optional(),
	}),
	{
		fields: {
			name: 'Name of the delivery option.',
			pricing: 'Pricing of the delivery.',
			payment: 'Payment details of the delivery.',
			deliveryOptionId: 'Unique identifier of the delivery option.',
			to: 'Details where delivery should be delivered to.',
			from: 'Details where the delivery should be picked up from.',
			returnInstructions: 'Instructions for returning the delivery.',
		},
		example: {
			name: { def: 'Express delivery' },
			pricing: ItemPricing.example,
			payment: OrderPayment.example,
			deliveryOptionId: newFirestoreId(),
			to: getOpenApiExample(zOrderDeliveryDetailsApi),
			from: getOpenApiExample(zOrderDeliveryDetailsApi),
		},
	},
);
