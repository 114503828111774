import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { TransactionLedgerApi } from 'common/types';
import { createBasicDbOperations } from '../utils';

export const getTransactionLedgerQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.transactionLedger;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<TransactionLedgerApi>(collectionPath, dbRefs, dataUpdater),
	};
};
