import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import * as ViewActions from 'actions/ViewActions';
import * as ViewSelectors from 'selectors/ViewSelectors';

import AddOnModal from './AddOnModal';

const AddOnModalManager = () => {
	const dispatch = useDispatch();

	const { open, addOn, addOnChannel } = useSelector(ViewSelectors.addOnModalState);

	if (!addOn || !addOnChannel) return null;

	return (
		<>
			<AddOnModal
				addOn={addOn}
				addOnChannel={addOnChannel}
				open={open}
				onClose={() => dispatch(ViewActions.closeAddOnModal())}
				onExited={() => dispatch(ViewActions.resetAddOnModal())}
			/>
			<Prompt
				message={() => {
					dispatch(ViewActions.closeAddOnModal());
					return true;
				}}
				when={open}
			/>
		</>
	);
};

export default AddOnModalManager;
