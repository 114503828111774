import { isEmpty, uniq } from 'lodash';

import { SkuItem } from 'common/modules/inventory';
import {
	AccessClaims,
	Location,
	Permission,
	Permissions,
	Role,
	Roles,
	ShopRole,
	ShopRoles,
	UserApi,
} from 'common/types';
import { isDevEnv, isRentleEmail } from 'common/utils/common';

import { DEFAULTS_BY_ROLE } from './constants';

export const isRentleSupport = (roles: Role[]) => roles.includes(Roles.rentle_support);
export const isAdmin = (roles: Role[]) => roles.includes(Roles.admin);
export const isManager = (roles: Role[]) => roles.includes(Roles.manager);
export const isEditor = (roles: Role[]) => roles.includes(Roles.editor);
export const isMember = (roles: Role[]) => roles.includes(Roles.member);

export const isRentleSupportFromUser = (user: UserApi, shopId: string) => {
	const roles = user.access[shopId]?.roles;
	const hasRentleSupportRole = isRentleSupport(roles);
	const hasRentleEmail = !!user?.email && isRentleEmail(user.email);
	const isRentleEmailAdminUser = !isDevEnv() && isAdmin(roles) && hasRentleEmail;
	return hasRentleSupportRole || isRentleEmailAdminUser;
};

const rolesToNumber = (roles: Role[]) => {
	if (isRentleSupport(roles)) return 5;
	if (isAdmin(roles)) return 4;
	if (isManager(roles)) return 3;
	if (isEditor(roles)) return 2;
	return 1;
};

export const hasLowerAccess = (roles: Role[], comparedToRoles: Role[]) => {
	return rolesToNumber(roles) < rolesToNumber(comparedToRoles);
};

export const hasHigherOrEqualAccess = (roles: Role[], comparedToRoles: Role[]) => {
	return rolesToNumber(roles) >= rolesToNumber(comparedToRoles);
};

export const getAllShopRoles = (): ShopRole[] => Object.keys(ShopRoles) as ShopRole[];

export const getAllPermissions = (): Permission[] => Object.keys(Permissions) as Permission[];

export const getDefaultPermissionsForRoles = (roles: Role[]) => {
	return uniq(
		roles.flatMap((role) => {
			const subRoles = DEFAULTS_BY_ROLE[role]?.roles;
			return subRoles.flatMap((role) => DEFAULTS_BY_ROLE[role]?.permissions ?? []);
		}),
	);
};

export const getUserAccessClaims = (user: UserApi, shopId: string): AccessClaims | null => {
	return user.access?.[shopId] ?? null;
};

export const getAllPermissionsForUser = (user: AccessClaims) => {
	const { roles, permissions, restrictions } = user;

	const defaultRolePermissions = getDefaultPermissionsForRoles(roles);

	const allPermissionsForUser = uniq([...defaultRolePermissions, ...permissions]).filter(
		(perm) => !restrictions?.includes(perm),
	);

	return allPermissionsForUser;
};

export const getAllRolesForUser = (user: AccessClaims): Role[] =>
	uniq(user.roles.flatMap((role) => DEFAULTS_BY_ROLE[role].roles));

export const getShopRoles = (roles: Role[]): ShopRole[] =>
	uniq(roles.map((role) => (role === Roles.rentle_support ? Roles.admin : role)));

export const canEditUsers = (access: AccessClaims): boolean => {
	/**
	 * Only managers and above are allowed to edit other users in general
	 */
	const userRoles = getAllRolesForUser(access);
	return hasHigherOrEqualAccess(userRoles, [Roles.manager]);
};

export const canEditUser = (user: AccessClaims, userToEdit: AccessClaims): boolean => {
	if (isAdmin(user.roles)) return true;
	if (isManager(user.roles)) {
		if (isAdmin(userToEdit.roles) || isManager(userToEdit.roles)) return false;
		if (hasAccessToAllOfLocations(user, userToEdit.locations)) return true;
	}
	return false;
};

export const canViewUser = (userAccess: AccessClaims, userToViewAccess: AccessClaims): boolean => {
	if (isAdmin(userAccess.roles)) return true;
	if (isManager(userAccess.roles)) {
		if (isAdmin(userToViewAccess.roles)) return true;
		if (hasAccessToSomeOfLocations(userAccess, userToViewAccess.locations ?? [])) return true;
	}
	return false;
};

export const validateAccessToGive = (access: AccessClaims, accessToGive: AccessClaims): void => {
	if (!canEditUsers(access)) {
		throw new Error('User is not allowed to edit other users');
	}

	if (!canGiveRoles(access, accessToGive.roles, true)) {
		throw new Error(`User is not allowed to give roles ${accessToGive.roles.join(', ')}`);
	}

	if (!canGivePermissions(access, accessToGive.permissions, true)) {
		throw new Error(
			`User is not allowed to give permissions ${accessToGive.permissions.join(', ')}`,
		);
	}

	if (accessToGive.locations?.length === 0) {
		throw new Error('Locations must be a non-empty array or undefined');
	}

	if (!canGiveLocations(access, accessToGive.locations, true)) {
		throw new Error(`User is not allowed to give locations ${accessToGive.locations?.join(', ')}`);
	}
};

export const canGiveRoles = (
	access: AccessClaims,
	roles: Role[],
	skipEditCheck?: true,
): boolean => {
	return (
		(skipEditCheck || canEditUsers(access)) &&
		hasHigherOrEqualAccess(getAllRolesForUser(access), roles)
	);
};

export const canGivePermissions = (
	access: AccessClaims,
	permissions: Permission[],
	skipEditCheck?: true,
): boolean => {
	/**
	 * We can later add restrictions to giving specific permissions, for now we only check if
	 * the user is allowed to edit other users in the first place
	 */
	return skipEditCheck || canEditUsers(access);
};

export const canGiveLocations = (
	access: AccessClaims,
	locations: string[] | undefined,
	skipEditCheck?: true,
): boolean => {
	/**
	 * A user can only give access to the locations they also have access to
	 */

	return (skipEditCheck || canEditUsers(access)) && hasAccessToAllOfLocations(access, locations);
};

export const isEmptyLocations = (locations: string[] | undefined): locations is undefined => {
	return isEmpty(locations);
};

export const hasAccessToAllLocations = (access: AccessClaims): boolean => {
	return !!access && isEmptyLocations(access.locations);
};

export const hasAccessToLocation = (access: AccessClaims, locationId: string): boolean => {
	return hasAccessToAllLocations(access) || !!access.locations?.includes(locationId);
};

export const hasAccessToAllOfLocations = (
	access: AccessClaims,
	locationIds: string[] | undefined,
): boolean => {
	if (isEmptyLocations(locationIds)) return hasAccessToAllLocations(access);
	return locationIds.every((locationId) => hasAccessToLocation(access, locationId));
};

export const hasAccessToSomeOfLocations = (
	access: AccessClaims,
	locationIds: string[],
): boolean => {
	if (isEmptyLocations(locationIds)) return true;
	return locationIds.some((locationId) => hasAccessToLocation(access, locationId));
};

export const getUserLocations = (args: {
	access: AccessClaims;
	locations: Location[];
}): Location[] => {
	if (hasAccessToAllLocations(args.access)) return args.locations;
	return args.locations.filter((location) => hasAccessToLocation(args.access, location.id));
};

export const getUserDefaultLocation = (args: {
	access: AccessClaims;
	locations: Location[];
	mainLocationId: string;
}): Location | null => {
	const userLocations = getUserLocations({ access: args.access, locations: args.locations });
	if (userLocations.length === 0) return null;
	return userLocations.find((l) => l.id === args.mainLocationId) ?? userLocations[0];
};

export const getDisabledUserSkuIds = (
	skuItems: SkuItem[],
	userLocations: Location[],
	userRoles: Role[],
) => {
	if (hasLowerAccess(userRoles, ['editor'])) {
		return skuItems.map(({ id }) => id);
	}

	const userLocationIds = userLocations.map((loc) => loc.id);
	return skuItems
		.filter(
			({ statusesByLocation }) =>
				!Object.keys(statusesByLocation).every((locationId) =>
					userLocationIds.includes(locationId),
				),
		)
		.map(({ id }) => id);
};
