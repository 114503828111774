import { OrderDb } from '../types';
import { OrderStateApi, OrderStateDb } from './types';

export const toApi = (
	data: OrderStateDb,
	args: { activeState: OrderDb['rentalInfo']['activeState'] },
): OrderStateApi => {
	const map: Record<OrderStateDb, OrderStateApi> = {
		INIT: 'booked',
		BOOKED: 'booked',
		ACTIVE: 'active',
		COMPLETED: args.activeState === 'RENT_CANCEL' ? 'cancelled' : 'completed',
	};
	return map[data];
};
