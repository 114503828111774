import { isUndefined } from 'lodash';

import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { isDeliveryType } from 'common/modules/delivery/utils';
import { DeliveryOption } from 'common/types';

import { buildQuery, createBasicCollectionOperations, createBasicDbOperations } from '../utils';

export function dataUpdaterFn(item: DeliveryOption): DeliveryOption {
	if ((item.type as any) === 'TWO_WAY') {
		item.type = 'DELIVERY_AND_PICKUP';
	} else if (!isDeliveryType(item.type)) {
		item.type = 'DELIVERY_AND_OPTIONAL_PICKUP';
	}

	if (isUndefined(item.orderIndex)) {
		item.orderIndex = 999;
	}

	if (
		isUndefined(item.area) ||
		(item.area.type === 'address' && isUndefined(item.area.countryCodes))
	) {
		item.area = {
			type: 'address',
			countryCodes: 'all',
		};
	}

	return item;
}

export const getDeliveryOptionsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.deliveryOptions;
	const query = buildQuery<DeliveryOption>(collectionPath, dbRefs, dataUpdaterFn);

	return {
		...createBasicDbOperations<DeliveryOption>(collectionPath, dbRefs, dataUpdaterFn),
		...createBasicCollectionOperations<DeliveryOption>(collectionPath, dbRefs, dataUpdaterFn),
		byShopId: (shopId: string) => query.where('shopId', '==', shopId),
	};
};
