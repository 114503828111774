import React, { useState } from 'react';

import {
	Avatar,
	Box,
	Collapse,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import moment from 'moment-timezone';
import { TFunction } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import {
	AddOn,
	InstalledAddOn,
	Plan,
	getAddOnDetails,
	getDefaultInstalledAddOn,
} from 'common/modules/plans';
import { getAddOnIcon } from 'common/modules/plans/add-ons/getAddOnIcon';

import BackdropDialog from '../BackdropDialog';

interface Props {
	addOn: AddOn;
	plan: Plan;
	value: InstalledAddOn | null;
	onChange: (value: InstalledAddOn | null) => void;
	mode: 'admin' | 'internal-admin';
	t: TFunction;
}
const ConfigureAddOn = (props: Props) => {
	const { classes } = useStyles();
	const { addOn, value, onChange, t, mode } = props;
	const addOnDetails = getAddOnDetails(addOn, t);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleEnabledChange = (enabled: boolean) => {
		if (!enabled) {
			setIsModalOpen(true);
		} else {
			onChange(getDefaultInstalledAddOn(addOn));
		}
	};

	const handleInternalNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!value) return;
		onChange({
			...value,
			internalNote: e.target.value,
		});
	};

	return (
		<>
			<Box mt={2}>
				<Box className={classes.header}>
					<ListItem>
						<ListItemAvatar>
							<Avatar variant="rounded" src={getAddOnIcon(addOn) ?? undefined} />
						</ListItemAvatar>
						<ListItemText
							primary={addOnDetails.name}
							secondary={
								value?.startDate
									? `Installed since ${moment(value.startDate).format('MMMM Do HH:mm, YYYY')}`
									: ''
							}
						/>
					</ListItem>
					<Switch
						checked={!!value}
						color="primary"
						onChange={(e, checked) => handleEnabledChange(checked)}
					/>
				</Box>
				<Collapse in={!!value && (!!value.internalNote || mode === 'internal-admin')}>
					<Box py={2}>
						<TextField
							label="Internal note"
							helperText={
								mode !== 'internal-admin'
									? 'Please use internal admin to edit this'
									: 'Add a note to this add-on - visible only here in internal admin.'
							}
							placeholder="Temporarily installed as a trial. Should revisit this in May 2035 -Kelly"
							variant="outlined"
							fullWidth
							disabled={mode !== 'internal-admin'}
							rows={5}
							multiline
							value={value?.internalNote ?? ''}
							onChange={handleInternalNoteChange}
						/>
					</Box>
				</Collapse>
			</Box>
			<BackdropDialog
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				label={`Uninstall ${addOnDetails.name}`}
				body={
					<Box>
						<Typography variant="body2">{addOnDetails.uninstallNotice}</Typography>
						<Box mb={2} />
						<Typography variant="body2">{'Changes are applied after saving.'}</Typography>
					</Box>
				}
				variant="danger"
				width="xs"
				confirm={{
					label: 'I understand',
					callback: () => onChange(null),
					closeAfterDone: true,
				}}
				cancel={{
					label: 'Cancel',
				}}
			/>
		</>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: theme.spacing(2),
	},
}));

export default ConfigureAddOn;
