import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

export const zCancellationObjectApi = withOpenApi(
	z.object({
		hoursBefore: z.number().int().nonnegative(),
		refundPercentage: z.number().int().min(0).max(100),
		displayAs: z.enum(['hours', 'days', 'weeks']).default('hours'),
	}),
	{
		fields: {
			hoursBefore: 'Hours before the start of the booking when the cancellation is possible.',
			refundPercentage: 'Percentage of the booking price that will be refunded.',
			displayAs: 'Display unit of the cancellation period.',
		},
		example: {
			hoursBefore: 24,
			refundPercentage: 50,
			displayAs: 'days',
		},
	},
);

export const zCancellationPolicyApi = z.array(zCancellationObjectApi);
