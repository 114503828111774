import { DISCOVER_AND_ABOVE } from 'common/modules/plans/plans/constants';

import { AddOns } from '..';
import { AddOnDefinition } from '../types';

const definition: AddOnDefinition = {
	addOn: AddOns.GOOGLE_ANALYTICS,
	blockSlots: 1,
	requiresPlan: [...DISCOVER_AND_ABOVE],
	autoInstall: true,
};

export default definition;
