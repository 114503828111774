export const ROOT = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const PASSWORD_RESET = '/password-reset';
export const REGISTER_GET_STARTED = '/register/get-started';
export const PAY = '/pay/:payId';

export const STORE_ID = '/store/:storeId';
export const STORE_ID_LOCATION = '/store/:storeId/:locationId';

export const LISTINGS = '/listings';
export const LISTINGS_CREATE = '/listings/create';

export const NEW_RENTAL = '/new-rental';
export const NEW_RENTAL_SHOPPERINFO = '/new-rental/:rentalType/shopperinfo';
export const NEW_RENTAL_DONE = '/new-rental/:rentalType/done';
export const NEW_RENTAL_CUSTOMER_DETAILS = '/new-rental/customer-details';

export const BOOKINGS = '/bookings';
export const BOOKINGS_TAB = '/bookings/:tabId';
export const BOOKING_PAGE = '/booking/:bookingId';
export const BOOKING_PAGE_EDIT_SHOPPER = '/booking/:bookingId/edit/:shopperId';
export const BOOKING_PAGE_ORDER_SUMMARY = '/booking/:bookingId/summary';
export const BOOKING_PAGE_PAYMENT_SUMMARY = '/booking/:bookingId/payments';

export const CATALOG = '/catalog';
export const CATALOG_TAB = '/catalog/:tabId';
export const CATALOG_CATEGORY = '/catalog/categories/:categoryId';
export const CATALOG_CREATE_CATEGORY = '/catalog/categories/new';
export const CATALOG_PRODUCT = '/catalog/products/:productId';
export const CATALOG_PRODUCT_TAB = '/catalog/products/:productId/:tabId';
export const CATALOG_PRODUCT_SUB_TAB = '/catalog/products/:productId/:tabId/:subTabId';
export const CATALOG_CREATE_PRODUCT = '/catalog/products/new';
export const CATALOG_DISCOUNT = '/catalog/discounts/:discountId';
export const CATALOG_CREATE_DISCOUNT = '/catalog/discounts/new';

export const CUSTOMERS = '/customers';

export const INVENTORY = '/inventory';
export const INVENTORY_TAB = '/inventory/:tabId';
export const INVENTORY_ARTICLES = '/inventory/articles';
export const INVENTORY_ARTICLES_ADD = '/inventory/articles/add';
export const INVENTORY_ARTICLES_SINGLE = '/inventory/articles/:itemId';
export const INVENTORY_ARTICLES_SINGLE_TAB = '/inventory/articles/:itemId/:tabId';
export const INVENTORY_SKUS = '/inventory/skus';
export const INVENTORY_SKUS_SINGLE = '/inventory/skus/:skuId';
export const INVENTORY_IMPORT = '/inventory/import';
export const INVENTORY_IMPORT_VERIFY = '/inventory/import/verify';
export const INVENTORY_TRANSFERS = '/inventory/transfers';
export const INVENTORY_TRANSFER_SINGLE = '/inventory/transfers/:transferId';
export const INVENTORY_TRANSFER_SINGLE_PROCESS = '/inventory/transfers/:transferId/process';
export const INVENTORY_TRANSFERS_CREATE = '/inventory/transfers/create';

export const PLANS = '/plans';
export const PLANS_PAUSE = '/plans/pause';
export const PLANS_CONFIRM = '/plans/:planId/confirm';

export const DASHBOARD = '/dashboard';
export const RETURN = '/returnmode';

export const SKIDATA = '/skidata';

export const INTEGRATIONS = '/integrations';
export const INTEGRATIONS_TAB = '/integrations/:tabId';

export const HOME_VIEW = '/home';

export const STORE = '/settings';
export const STORE_TAB = '/settings/:tabId';

export const DELIVERY = '/delivery';
export const NEW_DELIVERY = `${DELIVERY}/new`;
export const SINGLE_DELIVERY = `${DELIVERY}/:deliveryId`;
export const NEW_CARRIER = `${DELIVERY}/carriers/new`;
export const SINGLE_CARRIER = `${DELIVERY}/carriers/:carrierId`;

export const ACCOUNT = '/account';
export const ACCOUNT_GENERAL = '/account/general';
export const ACCOUNT_TERMS = '/account/terms';
export const ACCOUNT_STORES = '/account/stores';
export const ACCOUNT_STORES_NEW = '/account/stores/new';
export const ACCOUNT_BILLING = '/account/billing';
export const ACCOUNT_USERS = '/account/users';
export const ACCOUNT_PAYMENTS = '/account/payments';
export const ACCOUNT_INTEGRATIONS = '/account/integrations';
export const ACCOUNT_ACCOUNTING = '/account/accounting';
export const ACCOUNT_REPORTS = '/account/reports';
export const ACCOUNT_LOCALIZATION = '/account/localization';
export const ACCOUNT_NOTIFICATIONS = '/account/notifications';
export const ACCOUNT_TAXES = '/account/taxes';
export const ACCOUNT_PROFILE = '/account/profile';
export const ACCOUNT_MARKETING = '/account/marketing';
export const ACCOUNT_DOMAINS_TAB = '/account/domains/:tabId';
export const ACCOUNT_DOMAINS = '/account/domains';
export const ACCOUNT_DOMAINS_URLS = '/account/domains/urls';

export const ACCOUNT_LANGUAGES = '/account/languages';
export const ACCOUNT_LANGUAGES_TAB = '/account/languages/:tabId';

export const BLOCKS = '/blocks';

export const ADD_ONS = '/add-ons';
export const ADD_ONS_STORE = '/add-ons/store';
export const ADD_ONS_STORE_TAB = '/add-ons/store/:tabId';

export const NOT_FOUND = '/404';
