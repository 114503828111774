import { createQueryKeys } from '@lukemorales/query-key-factory';
import { produce } from 'immer';
import { omit } from 'lodash';

import { api } from 'common/frontend/api';
import { FieldValues } from 'common/frontend/firebase/firestore';
import { InventoryConfiguration, InventoryField } from 'common/modules/inventory';
import { hashByUniqueField } from 'common/utils/arrays';

import { queryClient } from './client';
import { createUseMutation, createUseQuery, fnQueryKeys, queryUpdater } from './utils';

const queries = {
	get: async (args: { shopId: string }): Promise<InventoryConfiguration | null> => {
		const { shopId } = args;

		const data = await api().inventoryConfigurations.doc(shopId).get();
		return data ?? null;
	},
};

const mutations = {
	update: async (args: {
		shopId: string;
		updates: Partial<InventoryConfiguration>;
	}): Promise<void> => {
		const { shopId, updates } = args;

		return api().inventoryConfigurations.doc(shopId).update(updates);
	},
	updateInventoryFields: async (args: {
		shopId: string;
		inventoryFields: InventoryField[];
	}): Promise<void> => {
		const { shopId, inventoryFields } = args;
		const configurationDocWithoutFields: Omit<InventoryConfiguration, 'fields'> = {
			id: shopId,
			shopId,
		};
		const inventoryFieldObject = hashByUniqueField(inventoryFields, 'id');

		return api()
			.inventoryConfigurations.doc(shopId)
			.set(
				{
					...configurationDocWithoutFields,
					fields: {
						order: FieldValues.arrayUnion(...inventoryFields.map((f) => f.id)),
						values: {
							...inventoryFieldObject,
						},
					},
				},
				{ merge: true },
			);
	},
	renameColumn: async (args: {
		shopId: string;
		columnId: string;
		label: string;
	}): Promise<void> => {
		const { shopId, columnId, label } = args;
		return api()
			.inventoryConfigurations.doc(shopId)
			.update({
				[`fields.values.${columnId}.label` as `fields.values.${123}.label`]: label,
			});
	},
	deleteColumn: async (args: { shopId: string; columnId: string }): Promise<void> => {
		const { shopId, columnId } = args;
		return api()
			.inventoryConfigurations.doc(shopId)
			.update({
				'fields.order': FieldValues.arrayRemove(columnId),
				[`fields.values.${columnId}` as `fields.values.${123}`]: FieldValues.delete(),
			});
	},
	updateColumnOrder: async (args: { shopId: string; order: string[] }): Promise<void> => {
		const { shopId, order } = args;
		return api().inventoryConfigurations.doc(shopId).update({
			'fields.order': order,
		});
	},
};

const queryKeys = createQueryKeys('inventoryConfigurations', {
	get: fnQueryKeys(queries.get),
});

export const get = createUseQuery(queries.get, queryKeys.get);

export const update = createUseMutation(mutations.update, {
	onMutate: async ({ shopId, updates }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates,
				condition: (item) => item.shopId === shopId,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const updateInventoryFields = createUseMutation(mutations.updateInventoryFields, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const renameColumn = createUseMutation(mutations.renameColumn, {
	onMutate: async ({ shopId, columnId, label }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates: (item: InventoryConfiguration) => {
					return produce(item, (draft) => {
						if (!!draft.fields?.values[columnId]) {
							draft.fields.values[columnId].label = label;
						}
					});
				},
				condition: (item: InventoryConfiguration) => item.shopId === shopId,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const updateColumnOrder = createUseMutation(mutations.updateColumnOrder, {
	onMutate: async ({ shopId, order }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates: (item: InventoryConfiguration) => {
					return produce(item, (draft) => {
						if (!!draft.fields) {
							draft.fields.order = order;
						}
					});
				},
				condition: (item: InventoryConfiguration) => item.shopId === shopId,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const deleteColumn = createUseMutation(mutations.deleteColumn, {
	onMutate: async ({ shopId, columnId }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates: (item: InventoryConfiguration) => {
					return produce(item, (draft) => {
						if (!!draft.fields) {
							draft.fields.order = draft.fields.order.filter((id) => id !== columnId);
							draft.fields.values = omit(draft.fields.values, columnId);
						}
					});
				},
				condition: (item: InventoryConfiguration) => item.shopId === shopId,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});
