import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.skidata.name', 'SKIDATA'),
	subHeader: t('common:blocks.details.skidata.subHeader', 'Connect Rentle with SKIDATA'),
	description: t(
		'common:blocks.details.skidata.description',
		'With the integration, you can synchronize your Skidata catalog, manage which ski tickets are available for purchase in the Rentle online store, and sell them individually or offer them as an additional product with your rental equipment',
	),
	supportArticle: SupportArticles.SKIDATA,
	uninstallNotice: t(
		'common:blocks.details.skidata.uninstallNotice',
		'By uninstalling SKIDATA integration, your SKIDATA configurations will be removed',
	),
});

export default details;
