import { z } from 'zod';

import { zLocaleField } from 'common/modules/atoms/localization';

import { zTaxLine } from '../taxes';

export const zStoreServiceFee = z.object({
	label: z.string(),
	taxable: z.boolean(),
	tax: z.object({
		label: zLocaleField,
		rate: z.number(),
	}),
	rate: z.object({
		/**
		 * Fixed price added to order, in cents
		 */
		fixed: z.number().int(),
		/**
		 * Percentage from total order price, in decimals
		 */
		percentage: z.number(),
	}),
});

export const zOrderServiceFee = zStoreServiceFee.extend({
	total: z.number().int(),
	taxLines: z.array(z.lazy(() => zTaxLine)),
	taxExcluded: z.boolean(),
});

export type StoreServiceFee = z.infer<typeof zStoreServiceFee>;
export type OrderServiceFee = z.infer<typeof zOrderServiceFee>;
