import { zStartTimesApi } from './api';
import { toApi, toDb } from './mapper';

export * from './types';

export const StartTimes = {
	toApi,
	toDb,
	api: zStartTimesApi,
};
