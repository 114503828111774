import React from 'react';

import { Box, ButtonBase, ButtonBaseProps, Stack, Typography, styled } from '@mui/material';
import { IconType } from 'react-icons';

interface SidebarButtonProps extends ButtonBaseProps {
	label?: string;
	icon?: IconType;
	iconPosition?: 'start' | 'end';
	size?: 'default' | 'large';
}
const SidebarButton = (props: SidebarButtonProps) => {
	const { icon: Icon, iconPosition = 'end', size = 'default', label, ...rest } = props;

	const icon = !!Icon ? (
		<Box>
			<Icon size={size === 'default' ? 20 : 24} />
		</Box>
	) : null;
	return (
		<StyledButton {...rest}>
			<Stack direction="row" justifyContent={'space-between'} width={'100%'}>
				{!!icon && iconPosition === 'start' && icon}
				{!!label && (
					<Typography variant="h6" fontSize={size === 'default' ? '1.4rem' : '1.8rem'} noWrap>
						{props.label}
					</Typography>
				)}
				{!!icon && iconPosition === 'end' && icon}
			</Stack>
		</StyledButton>
	);
};

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
	justifyContent: 'flex-start',
	padding: theme.spacing(2, 2),
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
	'&:focus': {
		backgroundColor: theme.palette.action.selected,
	},
}));

export default SidebarButton;
