import {
	StorageReference,
	connectStorageEmulator as _connectStorageEmulator,
	getStorage,
	ref,
} from 'firebase/storage';

import { RemoveFirstFromTuple } from 'common/types/utils';

import { firebaseApp } from './app';

export {
	list,
	listAll,
	updateMetadata,
	uploadBytes,
	uploadBytesResumable,
	uploadString,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';

export const storage = getStorage(firebaseApp);

// prettier-ignore
export function storageRef(...args: RemoveFirstFromTuple<Parameters<typeof ref>>): ReturnType<typeof ref>;
// prettier-ignore
export function storageRef(storageRef: StorageReference, ...args: RemoveFirstFromTuple<Parameters<typeof ref>>): ReturnType<typeof ref>;
// prettier-ignore
export function storageRef(storageRefOrUrl?: StorageReference | string, ...args: RemoveFirstFromTuple<Parameters<typeof ref>>): ReturnType<typeof ref> {
    if (storageRefOrUrl === undefined) {
        return ref(storage, ...args);
    }
	if (typeof storageRefOrUrl === 'string') {
		return ref(storage, storageRefOrUrl);
	}
	return ref(storageRefOrUrl, ...args);
}

export const connectStorageEmulator = () => _connectStorageEmulator(storage, '127.0.0.1', 9199);
