import { z } from 'zod';

import { Segment } from 'common/models/_atoms/Segment';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { withOpenApi } from 'common/utils/openApi';
import { zIsoString } from 'common/utils/zod';

import { CustomerDetails } from '../CustomerDetails';
import { AdditionalDetails } from './AdditionalDetails';

export const zPersonApi = withOpenApi(
	CustomerDetails.api.extend({
		lastName: z.string().optional(),
		phone: z.string().optional(),
		email: z.string().optional(),
		id: z.string(),
		createdAt: zIsoString,
		isLiableCustomer: z.boolean(),
		segment: Segment.api.optional(),
		additionalDetails: AdditionalDetails.api.optional(),
		staffComment: z.string().optional(),
		itemIds: z.array(z.string()),
	}),
	{
		fields: {
			id: 'Unique ID of the person',
			firstName: 'First name of the person.',
			lastName: 'Last name of the person.',
			phone: 'Phone number of the person.',
			email: 'Email of the person.',
			marketingConsent: 'Whether the person has given marketing consent.',
			language: 'Language of the person.',
			address: 'Address of the person.',
			customerId: 'Unique identifier of the customer.',
			createdAt: 'Person creation date',
			isLiableCustomer: 'Whether the person is the liable customer of the order',
			segment: 'Segment of the person, if defined',
			additionalDetails: 'Additional person details',
			staffComment: 'Internal comment about the person, added by the store staff.',
			itemIds: 'List of the item IDs of the order that belongs to the person',
		},
		example: {
			...CustomerDetails.example!,
			id: newFirestoreId(),
			createdAt: new Date().toISOString(),
			isLiableCustomer: true,
			additionalDetails: [],
			staffComment: 'This customer will arrive 15min late',
			itemIds: [newFirestoreId()],
		},
	},
);
