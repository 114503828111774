import { getAllPermissionsForUser, getAllRolesForUser } from 'common/modules/users/utils';
import { AccessClaims } from 'common/types';
import { isDevEnv } from 'common/utils/common';

import { AddOn, getInstalledAddOnsForShop } from '../add-ons';
import { Plan } from '../plans';
import { ShopFeatures } from '../types';
import { restrictedFeatureKeys, restrictedFeatures } from './features';
import { RestrictedFeature } from './types';

/** Check whether a given shop has a given feature or features enabled. Takes into account shop plan, add-ons and
 * separately enabled/hidden features.
 *
 * @param features The feature(s) to check.
 * @param filteringType The filtering type to use
 * 		- SOME: Returns true if ANY of the provided features are enabled
 * 		- ALL: Returns true if ALL of the provided features are enabled
 * @param shopFeatures The ShopFeatures object
 * @returns A boolean
 */
export const shopHasFeatures = (
	features: RestrictedFeature | RestrictedFeature[] | undefined,
	filteringType: 'SOME' | 'ALL' = 'ALL',
) => (shopFeatures?: ShopFeatures): boolean => {
	if (!features) return true;
	const featuresArray = getFeaturesAsArray(features);
	return filteringType === 'SOME'
		? featuresArray.some((feature) => shopHasFeature(feature)(shopFeatures))
		: featuresArray.every((feature) => shopHasFeature(feature)(shopFeatures));
};

/** Check whether a given user has access to a specific feature or features. Does not take into account
 * whether the features are enabled for the shop.
 *
 * @param features The feature(s) to check
 * @param filteringType The filtering type to use
 * 		- SOME: Returns true if the user has access to ANY of the provided features
 * 		- ALL: Returns true if the user has access to ALL of the provided features
 * @param user The user to check against
 * @returns A boolean
 */
export const userHasFeatures = (
	features: RestrictedFeature | RestrictedFeature[] | undefined,
	filteringType: 'SOME' | 'ALL' = 'ALL',
) => (user?: AccessClaims): boolean => {
	if (!features) return true;
	const featuresArray = getFeaturesAsArray(features);
	return filteringType === 'SOME'
		? featuresArray.some((feature) => userHasFeature(feature)(user))
		: featuresArray.every((feature) => userHasFeature(feature)(user));
};

/** Check whether a given plan includes a specific feature or features
 *
 * @param features The feature(s) to check
 * @param filteringType The filtering type to use
 * 		- SOME: Returns true if the plan includes ANY of the provided features
 * 		- ALL: Returns true if the plan includes ALL of the provided features
 * @param plan The plan
 * @returns A boolean
 */
export const planIncludesFeatures = (
	features: RestrictedFeature | RestrictedFeature[] | undefined,
	filteringType: 'SOME' | 'ALL' = 'ALL',
) => (plan?: Plan): boolean => {
	if (!features) return true;
	const featuresArray = getFeaturesAsArray(features);
	return filteringType === 'SOME'
		? featuresArray.some((feature) => planIncludesFeature(feature, plan))
		: featuresArray.every((feature) => planIncludesFeature(feature, plan));
};

/** Check whether a given add-on includes a specific feature or features
 *
 * @param features The feature(s) to check
 * @param filteringType The filtering type to use
 * 		- SOME: Returns true if the add-on includes ANY of the provided features
 * 		- ALL: Returns true if the add-on includes ALL of the provided features
 * @param addOn The add-on
 * @returns A boolean
 */
export const addOnIncludesFeatures = (
	features: RestrictedFeature | RestrictedFeature[] | undefined,
	filteringType: 'SOME' | 'ALL' = 'ALL',
) => (addOn?: AddOn): boolean => {
	if (!features) return true;
	const featuresArray = getFeaturesAsArray(features);
	return filteringType === 'SOME'
		? featuresArray.some((feature) => addOnIncludesFeature(feature, addOn))
		: featuresArray.every((feature) => addOnIncludesFeature(feature, addOn));
};

/** Get all of the features enabled for a shop. Takes into account the shop plan, add-ons
 * and separately enabled/hidden features.
 *
 * @param shopFeatures The ShopFeatures object
 * @returns A list of features enabled for the shop
 */
export const getShopFeatures = (shopFeatures?: ShopFeatures): RestrictedFeature[] => {
	return restrictedFeatureKeys.filter((feature) => {
		return shopHasFeature(feature)(shopFeatures);
	});
};

/** Get all of the features a given user has access to. Does not take into account whether
 * the user's shop has a feature enabled.
 *
 * @param user The user
 * @returns A list of features the user has access to
 */
export const getUserFeatures = (user?: AccessClaims): RestrictedFeature[] => {
	return restrictedFeatureKeys.filter((key) => userHasFeature(key)(user));
};

/** Get all of the features included in a given plan.
 *
 * @param plan The plan
 * @returns A list of features the plan includes
 */
export const getFeaturesInPlan = (plan?: Plan): RestrictedFeature[] => {
	return restrictedFeatureKeys.filter((feature) => planIncludesFeature(feature, plan));
};

/** Get all of the features included in a given add-on.
 *
 * @param addOn The add-on
 * @returns A list of features the add-on includes
 */
export const getFeaturesInAddOn = (addOn?: AddOn): RestrictedFeature[] => {
	return restrictedFeatureKeys.filter((feature) => addOnIncludesFeature(feature, addOn));
};

/** Get all features that are restricted based on the shop plan
 *
 * @returns A list of features
 */
export const getFeaturesWithPlanRestrictions = () => {
	return restrictedFeatureKeys.filter((key) => {
		const featureDefinition = restrictedFeatures[key];
		return featureDefinition.plans !== 'all';
	});
};

/** Get all features that are restricted based on enabled add-ons
 *
 * @returns A list of features
 */
export const getFeaturesWithAddOnRestrictions = () => {
	return restrictedFeatureKeys.filter((key) => {
		const featureDefinition = restrictedFeatures[key];
		return !!featureDefinition.addOns?.length;
	});
};

/** Get all features that are restricted based on user roles or permissions
 *
 * @returns A list of features
 */
export const getFeaturesWithUserRestrictions = () => {
	return restrictedFeatureKeys.filter((key) => {
		const featureDefinition = restrictedFeatures[key];
		return !!featureDefinition.roles?.length || !!featureDefinition.permissions?.length;
	});
};

/** Get all features that can be toggled via admin UIs
 *
 * @returns A list of features
 */
export const getToggleableFeatures = () => {
	return restrictedFeatureKeys.filter(isFeatureToggleable);
};

/** Check whether a given feature can be manually enabled/disabled for a shop (i.e. it is not an internal-only feature flag)
 *
 * @param feature The feature to check
 * @returns A boolean indicating whether the feature can be toggled
 */
export const isFeatureToggleable = (feature: RestrictedFeature) => {
	const featureDefinition = restrictedFeatures[feature];
	return !featureDefinition.internal;
};

/** Check whether a feature/features has been explicitly hidden
 *
 * @param features The feature or features to check
 * @param hiddenFeatures The list of hidden features for a shop
 * @param filteringType whether it is enough for one of the features to be hidden ("SOME") or if all need to be hidden ("ALL")
 * @returns A boolean
 */
export const featureIsHidden = (
	features: RestrictedFeature | RestrictedFeature[] | undefined,
	hiddenFeatures: RestrictedFeature[],
	filteringType: 'SOME' | 'ALL' = 'SOME',
): boolean => {
	const featuresArray = getFeaturesAsArray(features);
	return filteringType === 'SOME'
		? featuresArray.some((feature) => hiddenFeatures.includes(feature))
		: featuresArray.every((feature) => hiddenFeatures.includes(feature));
};

/***
 * Helpers
 */
const getFeaturesAsArray = (
	features: RestrictedFeature | RestrictedFeature[] | undefined,
): RestrictedFeature[] => {
	if (!features) return [];
	return Array.isArray(features) ? features : [features];
};

export const userHasFeature = (feature: RestrictedFeature) => (user?: AccessClaims): boolean => {
	const { roles, permissions } = restrictedFeatures[feature];
	if (!user) {
		return !roles?.length && !permissions?.length;
	}

	const userPermissions = getAllPermissionsForUser(user);
	const userRoles = getAllRolesForUser(user);

	const hasAllRoles = roles?.every((role) => userRoles.includes(role)) ?? true;
	const hasAllPermissions =
		permissions?.every((permission) => userPermissions.includes(permission)) ?? true;

	return hasAllRoles && hasAllPermissions;
};

const shopHasFeature = (feature: RestrictedFeature) => (shopFeatures?: ShopFeatures): boolean => {
	if (!shopFeatures) {
		return false;
	}
	const shopAddons = getInstalledAddOnsForShop(shopFeatures);

	const isEnabledForDev = isDevEnv() && isDevFeature(feature);
	const isSeparatelyEnabled = shopFeatures.enabledFeatures?.includes(feature) ?? false;
	const isSeparatelyHidden = shopFeatures.hiddenFeatures?.includes(feature) ?? false;
	const isIncludedInPlan = planIncludesFeature(feature, shopFeatures.plan.plan);
	const isIncludedInAddOns = shopAddons.some((addOn) => addOnIncludesFeature(feature, addOn));

	return (
		!isSeparatelyHidden &&
		(isSeparatelyEnabled || isIncludedInPlan || isIncludedInAddOns || isEnabledForDev)
	);
};

const addOnIncludesFeature = (feature: RestrictedFeature, addOn?: AddOn): boolean => {
	if (!addOn) return false;
	const featureDefinition = restrictedFeatures[feature];
	return featureDefinition?.addOns?.includes(addOn) ?? false;
};

export const planIncludesFeature = (feature: RestrictedFeature, plan?: Plan): boolean => {
	const featureInfo = restrictedFeatures[feature];
	const plans = featureInfo?.plans;

	switch (plans) {
		case 'none':
			return false;
		case 'all':
			return true;
		default: {
			if (!plan || !plans) return false;

			return plans.includes(plan);
		}
	}
};

export const isDevFeature = (feature: RestrictedFeature): boolean => {
	const featureDefinition = restrictedFeatures[feature];
	return !!featureDefinition?.dev;
};
