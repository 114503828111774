import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getLinkOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({
	t,
	onlineStoreUrl,
	showProductTours,
}: {
	t: TFunction;
	onlineStoreUrl: string;
	showProductTours: boolean;
}): Record<'V2' | 'V1' | 'V0', OnboardingStepItem> => ({
	V2: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'VISIT_ONLINE_STORE' })
			: getLinkOnboardingStepItem({ toUrl: onlineStoreUrl }),
		content: {
			title: t('homeView.onboarding.createAnOrder', 'Create an order'),
		},
		id: 'BOOKING_CREATION',
		type: 'row',
	},
	V1: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'VISIT_ONLINE_STORE' })
			: getLinkOnboardingStepItem({ toUrl: onlineStoreUrl }),
		content: {
			title: t(
				'homeView.onboarding.placeAnOrderInYourOnlineStoreLikeACustomer',
				'Place an order in your Online Store like a customer',
			),
		},
		id: 'BOOKING_CREATION',
		type: 'row',
	},
	V0: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'VISIT_ONLINE_STORE' })
			: getLinkOnboardingStepItem({ toUrl: onlineStoreUrl }),
		content: {
			title: t(
				'homeView.onboarding.createOrderFromOnlineStore',
				'Create an order from your online store',
			),
		},
		id: 'BOOKING_CREATION',
		type: 'row',
	},
});

export const BOOKING_CREATION_ITEM = ({
	t,
	onlineStoreUrl,
	showProductTours,
}: {
	t: TFunction;
	onlineStoreUrl: string;
	showProductTours: boolean;
}): Record<
	'BOOKING_CREATION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t, onlineStoreUrl, showProductTours });
	return {
		BOOKING_CREATION: {
			V3: versions['V2'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
