import { PaymentResult } from 'common/modules/payments/types';
import { AmountObject, Channel, Languages, PaymentMethod } from 'common/types';

import { CallableCreator } from '..';

export interface CaptureRequest {
	originalTransactionId: string;
	amount: AmountObject;
	channel: Channel;
	method: PaymentMethod;
	captureDescription?: string;
	shopMessage?: string;
	userEmail?: string;
	language?: Languages;
}

export interface RefundRequest {
	originalTransactionId: string;
	amount?: AmountObject; // If missing, refunding all
	channel: Channel;
	method: PaymentMethod;
}

export type CancelRequest = Omit<RefundRequest, 'amount'>;

export const payments = (createCallable: CallableCreator) => ({
	captureTransaction: createCallable<CaptureRequest, void | PaymentResult<'CAPTURE'>>(
		'payments-captureTransaction',
	),
	refundTransaction: createCallable<RefundRequest, void | PaymentResult<'REFUND'>>(
		'payments-refundTransaction',
	),
	cancelTransaction: createCallable<CancelRequest, void | PaymentResult<'CANCEL'>>(
		'payments-cancelTransaction',
	),
});
