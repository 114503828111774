import { clamp, range } from 'lodash';
import moment from 'moment-timezone';

import { YYYY_MM_DD } from 'common/types';
import { notNull, switchUnreachable } from 'common/utils/common';

import { OrderProductSubscription } from '../types';
import { MAX_SUBSCRIPTION_MONTHS } from './../constants';

/**
 * Get the next billing date for a given subscription, based on the current cycle.
 *
 * @param subscription The subscription
 * @returns The next billing date
 */
export const getNextBillingDate = (
	subscription: Pick<OrderProductSubscription, 'startDate' | 'cycle' | 'cycles'>,
): YYYY_MM_DD | null => {
	if (
		subscription.cycles.total !== null &&
		subscription.cycles.current >= subscription.cycles.total
	) {
		return null;
	}
	return getNthBillingDate({
		subscription,
		n: subscription.cycles.current + 1,
	});
};

/**
 * Get the nth billing date for a given subscription.
 *
 * @param subscription The subscription
 * @param n The cycle number to get the billing date for (1 = first cycle)
 * @returns The nth billing date
 */
export const getNthBillingDate = (args: {
	subscription: Pick<OrderProductSubscription, 'startDate' | 'cycle'>;
	n: number;
}): YYYY_MM_DD => {
	const { subscription } = args;

	const n = clamp(args.n - 1, 0, MAX_SUBSCRIPTION_MONTHS);

	const billingDate = (() => {
		switch (subscription.cycle.unit) {
			case 'months': {
				return moment
					.utc(subscription.startDate, 'YYYY-MM-DD')
					.add(subscription.cycle.value * n, 'months')
					.format('YYYY-MM-DD');
			}
			case 'years':
				return moment
					.utc(subscription.startDate, 'YYYY-MM-DD')
					.add(subscription.cycle.value * n, 'years')
					.format('YYYY-MM-DD');
			default: {
				return switchUnreachable(subscription.cycle.unit);
			}
		}
	})();

	return billingDate;
};

export const getBillingCyclesUntilDate = (args: {
	subscription: Pick<OrderProductSubscription, 'startDate' | 'cycle'>;
	date: YYYY_MM_DD;
}): number => {
	const { subscription, date } = args;

	let current = 0;

	while (true) {
		const currentDate = getNthBillingDate({ subscription, n: current + 1 });
		if (currentDate >= date) break;
		current++;
	}

	return current;
};

/**
 * Get billing dates for a subscription
 *
 * @param subscription The subscription
 * @param opts.from (Optional) Only return billing dates after and including a given cycle number
 * @param opts.until (Optional) Only return billing dates up to and including a given cycle number
 *
 * @returns An array of upcoming billing dates
 */
export const getBillingDates = (
	subscription: OrderProductSubscription | undefined,
	opts?: {
		from?: number;
		until?: number;
	},
): YYYY_MM_DD[] => {
	if (!subscription) return [];

	const start = opts?.from ?? 1;
	const end = opts?.until ?? subscription?.cycles.total ?? MAX_SUBSCRIPTION_MONTHS;

	return range(start, end + 1)
		.map((n) => getNthBillingDate({ subscription, n }))
		.filter(notNull);
};

/**
 * Get all passed billing dates for a given subscription
 *
 * @param subscription The subscription
 * @returns An array of passed billing dates
 */
export const getPassedBillingDates = (
	subscription: OrderProductSubscription | undefined,
): YYYY_MM_DD[] => {
	if (!subscription?.cycles.current) return [];
	return getBillingDates(subscription, {
		until: subscription?.cycles.current,
	});
};

/**
 * Get all upcoming billing dates for a given subscription, based on the current cycle number
 *
 * @param subscription The subscription
 * @returns An array of upcoming billing dates, up the end of the subscription (or the maximum limit if no end date)
 */
export const getUpcomingBillingDates = (
	subscription: OrderProductSubscription | undefined,
): YYYY_MM_DD[] => {
	return getBillingDates(subscription, {
		from: (subscription?.cycles.current ?? 0) + 1,
	});
};

/**
 * Get all committed billing dates for a given subscription (past and upcoming)
 *
 * @param subscription The subscription
 * @returns An array of committed billing dates
 */
export const getCommittedBillingDates = (
	subscription: OrderProductSubscription | undefined,
): YYYY_MM_DD[] => {
	if (!subscription?.cycles.committed) return [];
	return getBillingDates(subscription, {
		until: subscription.cycles.committed,
	});
};

/**
 *
 * @param billingDate The date of billing
 * @param paymentCreatedAt The createdAt of the failedPayment
 * @returns If the failed payment should be retried
 */

export const shouldRetryFailedSubscriptionPayment = (
	billingDate: YYYY_MM_DD,
	paymentCreatedAt: YYYY_MM_DD,
) => {
	const daysDiff = moment(billingDate).diff(paymentCreatedAt, 'days');
	if (daysDiff === 0 || daysDiff > 12) return false;
	const isEveryThirdDay = daysDiff % 3 === 0;
	return daysDiff === 1 || isEveryThirdDay;
};
