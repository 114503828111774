import { ReactNode, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { auth } from 'common/frontend/firebase/auth';
import Spinner from 'components/Spinner';
import { usePosthog } from 'hooks/usePosthog';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { Routes, push } from 'routing';

type PrivateRouteProps = {
	children: ReactNode;
};

const AuthRoute = ({ children }: PrivateRouteProps) => {
	const { currentUser } = auth;

	const { userIdentified: posthogUserIdentified } = usePosthog({ identify: true });

	const isAuthLoading = useSelector(ViewSelectors.isAuthLoading);

	useEffect(() => {
		if (!currentUser && !isAuthLoading) {
			push(Routes.LOGIN);
		}
	}, [isAuthLoading, currentUser]);

	if (currentUser && !isAuthLoading && posthogUserIdentified) {
		return <>{children}</>;
	}
	return <Spinner in />;
};

export default AuthRoute;
