import { PendingDeposit } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getPendingDepositQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.pendingDeposits;
	const dataUpdater = undefined;
	const query = buildQuery<PendingDeposit>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PendingDeposit>(collectionPath, dbRefs, dataUpdater),
	};
};
