import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export const setDatadogUser = (userId: string, merchantId: string) => {
	datadogRum.setUser({
		id: userId,
		merchantId,
	});
	datadogLogs.setUser({
		id: userId,
		merchantId,
	});
};
