import type { Stripe } from 'stripe';
import { z } from 'zod';

import { zLocaleField } from 'common/modules/atoms/localization';
import { AmountObject, EnumValues, RecurringData } from 'common/types';

import {
	AdyenMethodTypes,
	AdyenMethods,
	AutomaticDepositMethods,
	CustomManualMethodId,
	ExternalMethods,
	ManualDepositMethods,
	ManualMethods,
	OnlineMethodsByProvider,
	OnlinePaymentProviders,
	OnlineProviderMethods,
	RecurringMethods,
	StripeMethods,
	zCustomManualMethodId,
	zOnlineMethod,
	zShopOnlineMethod,
	zWalletMethods,
} from './constants';

export type PaymentProvider = keyof typeof OnlineMethodsByProvider;

export type OnlinePaymentProvider = EnumValues<typeof OnlinePaymentProviders>;

export type RecurringOnlinePaymentProvider = Extract<OnlinePaymentProvider, 'ADYEN' | 'STRIPE'>;

export type AdyenPaymentMethod = EnumValues<typeof AdyenMethods>;

export type StripePaymentMethod = EnumValues<typeof StripeMethods>;

export type ExternalPaymentMethod = EnumValues<typeof ExternalMethods>;

export type FixedManualPaymentMethod = EnumValues<typeof ManualMethods>;

export type OnlineProviderPaymentMethod = EnumValues<typeof OnlineProviderMethods>;

export type OnlinePaymentMethod = z.infer<typeof zOnlineMethod>;

export type OnlinePaymentMethodWithManual = OnlinePaymentMethod | CustomManualPaymentMethodId;

export type WalletPaymentMethod = z.infer<typeof zWalletMethods>;

export type RecurringPaymentMethod = EnumValues<typeof RecurringMethods>;

export type AutomaticDepositPaymentMethod = EnumValues<typeof AutomaticDepositMethods>;

export type ManualDepositPaymentMethod = EnumValues<typeof ManualDepositMethods>;

export type DepositPaymentMethod = AutomaticDepositPaymentMethod | ManualDepositPaymentMethod;

export type ShopOnlinePaymentMethod = z.infer<typeof zShopOnlineMethod>;

export type NonWalletAdyenPaymentMethod = Exclude<AdyenPaymentMethod, WalletPaymentMethod>;

export type NonRecurringPaymentMethod = Exclude<OnlinePaymentMethod, RecurringPaymentMethod>;

export type NonDepositPaymentMethod = Exclude<OnlinePaymentMethod, DepositPaymentMethod>;

export type NonWalletOnlineProviderPaymentMethod = Exclude<
	OnlineProviderPaymentMethod,
	WalletPaymentMethod
>;

export type CardPaymentMethod = Extract<
	OnlineProviderPaymentMethod,
	'CARD_ONLINE' | 'CARD_ONLINE_STRIPE'
>;

export type AdyenMethodType = EnumValues<typeof AdyenMethodTypes>;

export type CustomManualPaymentMethodId = EnumValues<typeof CustomManualMethodId>;

const zManualPaymentMethodDetails = z.object({
	id: z.string(),
	label: zLocaleField,
	description: zLocaleField.optional(),
});
export type ManualPaymentMethodDetails = z.infer<typeof zManualPaymentMethodDetails>;

const zShopFixedOnlinePaymentMethodObject = z.object({
	id: zShopOnlineMethod,
});

const zShopCustomOnlinePaymentMethodObject = z.object({
	id: zCustomManualMethodId,
	details: zManualPaymentMethodDetails,
});

const zFixedManualPaymentMethodObject = zShopFixedOnlinePaymentMethodObject.extend({
	id: z.literal('PAY_STORE'),
});

export const zShopOnlinePaymentMethodObject = z.union([
	zShopFixedOnlinePaymentMethodObject,
	zShopCustomOnlinePaymentMethodObject,
	zFixedManualPaymentMethodObject,
]);

export type ShopOnlinePaymentMethodObject = z.infer<typeof zShopOnlinePaymentMethodObject>;
export type ShopFixedOnlinePaymentMethodObject = z.infer<
	typeof zShopFixedOnlinePaymentMethodObject
>;
export type ShopCustomOnlinePaymentMethodObject = z.infer<
	typeof zShopCustomOnlinePaymentMethodObject
>;
export type FixedManualPaymentMethodObject = z.infer<typeof zFixedManualPaymentMethodObject>;

export type ShopManualOnlinePaymentMethodObject =
	| ShopCustomOnlinePaymentMethodObject
	| FixedManualPaymentMethodObject;

export type MarketPlaceAccountCsvRow = {
	'Market Place': string;
	'Account Holder': string;
	Account: string;
	'Psp Reference': string;
	'Payment Psp Reference': string;
	'Payment Merchant Reference': string;
	'Booking Date': string;
	TimeZone: string;
	Description: string;
	'Main Currency': string;
	'Main Amount': string;
	'Record Type': string;
	'Balance Currency': string;
	'Pending (BC)': string;
	'Balance (BC)': string;
};

export type AdyenTransactionRow = {
	accountHolder: string;
	account: string;
	pspReference: string;
	pspPaymentReference: string;
	transactionId: string;
	date: string;
	timeZone: string;
	description: string;
	amount: AmountObject;
	recordType: string;
	pendingBalance: AmountObject;
	balanceImpact: AmountObject;
	csvFileOrder: number;
	source: string;
};
export interface UpdatePayOutArgs {
	url: string;
	merchantId?: string;
}

export interface UpdateShopBalanceArgs {
	url: string;
}

export type MarketpayBalanceCsvRow = {
	'Account Holder': string;
	'Virtual Account': string;
	Currency: string;
	Balance: number;
	'Pending Credit': string;
	'Pending Debit': string;
	'On Hold Balance': string;
};

export type AdyenMarketpayBalanceRow = {
	accountHolder: string;
	account: string;
	currency: string;
	balance: number;
	pendingCredit: number;
	pendingDebit: number;
	onHoldBalance: number;
};

export type PaymentResultType = 'PAYMENT' | 'CANCEL' | 'REFUND' | 'CAPTURE' | 'CANCEL_OR_REFUND';

export interface AdyenPaymentResult<T extends PaymentResultType> {
	pspReference?: string;
	provider: 'ADYEN';
	recurringData?: RecurringData;
	type: T;
	result: T extends 'PAYMENT'
		? ICheckout.PaymentResponse
		: T extends 'REFUND' | 'CANCEL' | 'CAPTURE' | 'CANCEL_OR_REFUND'
		? IPayments.ModificationResult
		: never;
}

export interface StripePaymentResult<T extends PaymentResultType> {
	pspReference?: string;
	provider: 'STRIPE';
	recurringData?: RecurringData;
	type: T;
	result: T extends 'PAYMENT' | 'CAPTURE' | 'CANCEL'
		? Stripe.Response<Stripe.PaymentIntent>
		: T extends 'REFUND'
		? Stripe.Response<Stripe.Refund>
		: never;
}

export type PaymentResult<T extends PaymentResultType> =
	| AdyenPaymentResult<T>
	| StripePaymentResult<T>;
