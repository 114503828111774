import { StockAction } from 'actions';

import { StockState } from 'services/types';

const INITIAL_STATE: StockState = {
	stockProducts: { loading: true, data: null, error: null },
	categories: { loading: true, data: null, error: null },
};

const reducer = (state = INITIAL_STATE, action: StockAction): StockState => {
	switch (action.type) {
		case 'UPDATE_STOCK':
			return action.stock;
		case 'UPDATE_STOCK_PRODUCTS':
			return { ...state, stockProducts: action.stockProducts };
		case 'UPDATE_STOCK_CATEGORIES':
			return { ...state, categories: action.categories };
		case 'UPDATE_STOCK_CATEGORY':
			if (action.category.data && state.categories.data) {
				const updatedCategories = state.categories.data.map((c) =>
					action.category.data && c.id === action.category.data.id ? action.category.data : c,
				);
				return {
					...state,
					categories: {
						...state.categories,
						data: updatedCategories,
					},
				};
			}
			return state;
		default:
			return state;
	}
};

export default reducer;
