import { createAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { isEmpty } from 'lodash';

import { addProductListener } from 'common/api/frontend';
import { api } from 'common/frontend/api';
import { FieldValues } from 'common/frontend/firebase/firestore';
import { MAX_DEBOUNCE_MS } from 'common/hooks/useDebouncedForm';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { ISOString, ProductApi } from 'common/types';
import * as asyncUtils from 'common/utils/async';
import { replaceUndefinedValuesWith } from 'common/utils/common';
import { sortSubscriptions } from 'common/utils/pricing';
import * as NotificationActions from 'actions/NotificationActions';
import * as ActiveProductSelectors from 'selectors/ActiveProductSelectors';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { t } from 'services/localization';
import { setOnboardingActionDone } from 'services/onboarding';
import { LoadingData, ReduxThunkAction } from 'services/types';
import { Routes, replace } from 'routing';

export const setActiveProductData = createAction<LoadingData<ProductApi>>(
	'ActiveProduct/SET_ACTIVE_PRODUCT_DATA',
);

export const editLocalProduct = createAction<Partial<ProductApi>>(
	'ActiveProduct/EDIT_LOCAL_PRODUCT',
);

export const resetLocalProduct = createAction('ActiveProduct/RESET_LOCAL_PRODUCT');

export const setSaving = createAction<boolean>('ActiveProduct/SET_SAVING');
export const setSavedAt = createAction<ISOString>('ActiveProduct/SET_SAVED_AT');
export const setError = createAction<string | null>('ActiveProduct/SET_ERROR');
export const reset = createAction('ActiveProduct/RESET');
export const clearLocalChanges = createAction('ActiveProduct/CLEAR_LOCAL_CHANGES');

export const setProductListener = (productId: string): ReduxThunkAction => (dispatch) => {
	return addProductListener(
		productId,
		(product: ProductApi) => {
			dispatch(
				setActiveProductData({
					loading: false,
					error: null,
					data: product,
				}),
			);
		},
		(error) => {
			dispatch(
				setActiveProductData({
					loading: false,
					error: error.message,
					data: null,
				}),
			);
		},
	);
};

export const deleteActiveProduct = (): ReduxThunkAction => async (dispatch, getState) => {
	const activeProduct = ActiveProductSelectors.productData(getState());
	if (!activeProduct) return;
	dispatch(
		setActiveProductData({
			loading: true,
			error: null,
			data: null,
		}),
	);
	try {
		await api().products.doc(activeProduct.id).delete();
	} catch (e) {
		errorHandler.report(e);
	} finally {
		replace(Routes.CATALOG);
	}
};

const sanitizeChanges = (data: Partial<ProductApi>): Partial<ProductApi> => {
	if (data.subscriptions) {
		const sorted = produce(data, (draft) => {
			draft.subscriptions?.options.sort(sortSubscriptions);
		});
		return sorted;
	}
	return data;
};

export const saveChanges = (): ReduxThunkAction => async (dispatch, getState) => {
	dispatch(setSaving(true));
	await asyncUtils.sleep(MAX_DEBOUNCE_MS);
	const state = getState();
	const productId = ActiveProductSelectors.productData(state)?.id;
	const productChanges = sanitizeChanges(ActiveProductSelectors.localProductChanges(state));
	const shopAnalytics = ShopSelectors.activeShopAnalytics(state);
	if (!productId) {
		dispatch(setSaving(false));
		return;
	}

	/** Firestore does not allow setting undefined values, so they must be replaced here.
	 */
	const changes = replaceUndefinedValuesWith(productChanges, FieldValues.delete());

	try {
		if (!isEmpty(changes)) {
			await api().products.doc(productId).update(changes);
			dispatch(clearLocalChanges());
			dispatch(setSavedAt(new Date().toISOString()));
		}
	} catch (err) {
		errorHandler.report(err);
		dispatch(setError(err.message));
		dispatch(
			NotificationActions.showNotification({
				variant: 'error',
				message: t('common:states.somethingWentWrongWhileSaving'),
			}),
		);
	} finally {
		const hasPricingChanges =
			productChanges.subscriptions || productChanges.rentals || productChanges.sales;

		if (!!hasPricingChanges) {
			setOnboardingActionDone('EDIT_PRODUCT_PRICING', shopAnalytics);
		}
		const someSkuConnected = productChanges.variants?.options?.some(
			(option) => option.stockSources,
		);
		if (someSkuConnected) {
			setOnboardingActionDone(['PRODUCT_SKU_CONNECTION', 'EDIT_PRODUCT_QUANTITY'], shopAnalytics);
		}
		const hasAdditionalProductChanges = !!productChanges.additionalProductIds;
		if (hasAdditionalProductChanges) {
			setOnboardingActionDone('RECOMMEND_ADD_ON_PRODUCTS', shopAnalytics);
		}
		dispatch(setSaving(false));
	}
};
