import { range } from 'lodash';

import { Measurement, UnitSystemType, UnitSystems } from 'common/types';

import { UserDetailOptions } from '.';

export type WeightUnit = 'kg' | 'lb' | 'st';

export type HeightUnit = 'cm' | 'ft';

export const getWeightOptions = (unitSystem?: UnitSystemType): UserDetailOptions => {
	switch (unitSystem) {
		case UnitSystems.US:
			return {
				values: range(20, 331, 10).map(String),
				unit: 'lb',
			};
		case UnitSystems.UK:
			return {
				values: range(2, 25, 1).map(String),
				unit: 'st',
			};
		default:
			return {
				values: range(10, 151, 5).map(String),
				unit: 'kg',
			};
	}
};

export const getHeightOptions = (unitSystem?: UnitSystemType): UserDetailOptions => {
	switch (unitSystem) {
		case UnitSystems.US:
		case UnitSystems.UK:
			const inchesRange = (ft: number) =>
				ft === 2 ? [8, 10] : ft === 7 ? [0, 2] : range(0, 11, 2);
			return {
				values: range(2, 8, 1).flatMap((feet) =>
					inchesRange(feet).map((inches) => `${feet}′${inches}″`),
				),
				unit: 'ft',
			};
		default:
			return {
				values: range(80, 221, 5).map(String),
				unit: 'cm',
			};
	}
};

const LB_TO_KG_RATE = 0.45359237; // https://en.wikipedia.org/wiki/Pound_(mass)
const ST_TO_LB_RATE = 14; // https://en.wikipedia.org/wiki/Stone_(unit)
const ST_TO_KG_RATE = ST_TO_LB_RATE * LB_TO_KG_RATE; // ~6.35
const IN_TO_CM_RATE = 2.54; // https://en.wikipedia.org/wiki/Inch
const FT_TO_IN_RATE = 12; // https://en.wikipedia.org/wiki/Foot_(unit)
const FT_TO_CM_RATE = FT_TO_IN_RATE * IN_TO_CM_RATE; // 30.48

interface ConvertMeasurement<T> {
	input: Measurement<T>;
	toUnit: T;
}

type ConvertMeasurementProps = ConvertMeasurement<WeightUnit> | ConvertMeasurement<HeightUnit>;

/**
 * Convert weight and height unit between unit systems (metric, US, UK).
 * US, UK => non-rounded Metric for saving to DB.
 * Metric => rounded US, UK for displaying on UI.
 */
export const convertMeasurement = ({ input, toUnit }: ConvertMeasurementProps): string => {
	// Rounded to 10 lb
	if (input.unit === 'kg' && toUnit === 'lb') {
		const toValue = Number(input.value) / LB_TO_KG_RATE;
		return (Math.round(toValue / 10) * 10).toFixed(0);
	}
	// Non-rounded
	if (input.unit === 'lb' && toUnit === 'kg') {
		const toValue = Number(input.value) * LB_TO_KG_RATE;
		return toValue.toString();
	}
	// Rounded to 1 st
	if (input.unit === 'kg' && toUnit === 'st') {
		const toValue = Number(input.value) / ST_TO_KG_RATE;
		return toValue.toFixed(0);
	}
	// Non rounded
	if (input.unit === 'st' && toUnit === 'kg') {
		const toValue = Number(input.value) * ST_TO_KG_RATE;
		return toValue.toString();
	}
	// Rounded to 1 st
	if (input.unit === 'lb' && toUnit === 'st') {
		const toValue = Number(input.value) / ST_TO_LB_RATE;
		return toValue.toFixed(0);
	}
	// Rounded to 10 lb
	if (input.unit === 'st' && toUnit === 'lb') {
		const toValue = Number(input.value) * ST_TO_LB_RATE;
		return (Math.round(toValue / 10) * 10).toFixed(0);
	}
	// Rounded to 2″
	if (input.unit === 'cm' && toUnit === 'ft') {
		const cm = Number(input.value);
		const feet = Math.floor(cm / FT_TO_CM_RATE);
		const inches = Math.round((cm - feet * FT_TO_CM_RATE) / IN_TO_CM_RATE / 2) * 2;
		const toValue = inches === FT_TO_IN_RATE ? `${feet + 1}′0″` : `${feet}′${inches}″`;
		return toValue;
	}
	// Non rounded
	if (input.unit === 'ft' && toUnit === 'cm') {
		const [feet, inches] = input.value
			.split(/′|″/)
			.filter(Boolean)
			.map((n) => Number(n));
		const toValue = feet * FT_TO_CM_RATE + inches * IN_TO_CM_RATE;
		return toValue.toString();
	}
	return input.value;
};

// Round metric value to 5 to display on UI
const roundBy5 = (value: string): string => (Math.round(Number(value) / 5) * 5).toString();

/**
 * Convert measurements by unit system
 */
export const convertMeasurementByUnitSystem = (
	input: Measurement<WeightUnit> | Measurement<HeightUnit>,
	unitSystem: UnitSystemType,
): Measurement => {
	switch (unitSystem) {
		case UnitSystems.US:
			return input.unit === 'kg' || input.unit === 'st'
				? { value: convertMeasurement({ input, toUnit: 'lb' }), unit: 'lb' }
				: input.unit === 'cm'
				? { value: convertMeasurement({ input, toUnit: 'ft' }), unit: 'ft' }
				: input;
		case UnitSystems.UK:
			return input.unit === 'kg' || input.unit === 'lb'
				? { value: convertMeasurement({ input, toUnit: 'st' }), unit: 'st' }
				: input.unit === 'cm'
				? { value: convertMeasurement({ input, toUnit: 'ft' }), unit: 'ft' }
				: input;
		default:
			return input.unit === 'lb' || input.unit === 'st'
				? { value: convertMeasurement({ input, toUnit: 'kg' }), unit: 'kg' }
				: input.unit === 'ft'
				? { value: convertMeasurement({ input, toUnit: 'cm' }), unit: 'cm' }
				: { value: roundBy5(input.value), unit: input.unit };
	}
};
