import React from 'react';

import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Container from 'common/components/Container';
import { Plans, getPlanInfo } from 'common/modules/plans';
import { useTranslation } from 'services/localization';
import { Routes, push } from 'routing';

import PlanPromoPage from './PlanPromoPage';
import image from './assets/sales-img-book.jpg';

const CustomersPromoPage = () => {
	const { t } = useTranslation();
	const location = useLocation();

	const description = (
		<>
			<Typography variant="body1" sx={{ mb: 3 }}>
				{t('promoPages.customers.description1', 'Get insightful data of your all your customers')}
			</Typography>
			<Typography variant="body1" sx={{ mb: 3 }}>
				{t('promoPages.customers.description2', {
					planName: getPlanInfo({ plan: Plans.BUILD, t }).name,
					defaultValue: 'Upgrade to {{planName}} plan to access your customer data',
				})}
			</Typography>
		</>
	);
	return (
		<Container>
			<PlanPromoPage
				tag={t('promoPages.customers.tag', 'Customers')}
				title={t('promoPages.customers.title', 'Get to know your customers better')}
				description={description}
				image={image}
				primaryCta={{
					text: t('common:actions.comparePlans', 'Compare plans'),
					onClick: () => push(Routes.PLANS, { from: location.pathname }),
				}}
				secondaryCta={{
					text: t('homeView.bookADemo', 'Book a demo'),
					onClick: () => window.open('https://www.rentle.io/meetings/demo-rentle/30min', '_blank'),
				}}
			/>
		</Container>
	);
};

export default CustomersPromoPage;
