import React, { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';

import { Box, Drawer, styled } from '@mui/material';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import Header, { HeaderProps } from 'components/Layout/Header';
import SaveBanner from 'components/Layout/SaveBanner';
import Sidebar from 'components/Layout/Sidebar';
import StatusBar from 'components/Layout/StatusBar';
import {
	LayoutContextProvider,
	useHideParentLayout,
	useLayoutContext,
} from 'components/Layout/context';

import HeaderNEW from './HeaderNEW';

type LayoutProps = {
	statusBar?: boolean;
	header: HeaderProps;
};

const Layout = (props: PropsWithChildren<LayoutProps>) => {
	/**
	 * useHideParentLayout hook is used to hide the parent layout-component when there's a child
	 * context. This is useful for preventing the parent layout from rendering when a nested or
	 * child layout context is present.
	 */
	useHideParentLayout();
	const { header, statusBar = true, children } = props;
	const [stickyElementsHeight, setStickyElementsHeight] = useState<number>(0);
	const headerRef = useRef<HTMLDivElement>(null);
	const stickyElementsRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (!stickyElementsRef.current) return;
		setStickyElementsHeight(stickyElementsRef.current.clientHeight);
	}, [stickyElementsRef]);

	const hasSidebar = header.variant === 'sidebar';
	const hasNewListings = useFeatureFlagEnabled('new-listing-views');

	return (
		<LayoutContextProvider headerRef={headerRef} hasSidebar={hasSidebar}>
			{(hidden) => (
				<Box>
					{!hidden && (
						<>
							<Sticky>
								<OverscrollFiller
									sx={{
										backgroundColor: (theme) =>
											statusBar ? theme.palette.common.black : theme.palette.background.default,
									}}
								/>
								<Box ref={stickyElementsRef}>
									{statusBar && <StatusBar />}
									<LayoutHeaderWrapper ref={headerRef}>
										{hasNewListings ? <HeaderNEW /> : <Header {...header} />}
									</LayoutHeaderWrapper>
								</Box>
							</Sticky>
							{hasSidebar && (
								<LayoutDrawer insetTop={stickyElementsHeight}>
									<Sidebar />
								</LayoutDrawer>
							)}
						</>
					)}

					<LayoutContent>{children}</LayoutContent>
				</Box>
			)}
		</LayoutContextProvider>
	);
};

Layout.SaveBanner = SaveBanner;

const LayoutContent = (props: PropsWithChildren<{}>) => {
	const { sidebarWidth } = useLayoutContext();

	return <Box paddingLeft={`${sidebarWidth}px`}>{props.children}</Box>;
};

const LayoutDrawer = (props: PropsWithChildren<{ insetTop: number }>) => {
	const { insetTop } = props;
	const { isSidebarOpen, setSidebarOpen, isMobile } = useLayoutContext();

	return (
		<Drawer
			variant={isMobile ? 'temporary' : 'permanent'}
			open={isMobile ? isSidebarOpen : true}
			onClose={() => setSidebarOpen(false)}
			PaperProps={{
				sx: {
					paddingTop: isMobile ? 0 : `${insetTop}px`,
					zIndex: (theme) => theme.zIndex.appBar - 1,
				},
			}}
		>
			{props.children}
		</Drawer>
	);
};

const Sticky = styled(Box)(({ theme }) => ({
	position: 'sticky',
	top: 0,
	zIndex: theme.zIndex.appBar,
}));

const LayoutHeaderWrapper = styled(Box)(({ theme }) => ({
	background: theme.palette.background.paper,
	borderBottomColor: theme.palette.border.paper,
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	height: '56px',
	position: 'relative',
	overflow: 'hidden',
}));

const OverscrollFiller = styled(Box)(({ theme }) => ({
	height: '200px',
	width: '100%',
	background: theme.palette.common.black,
	position: 'absolute',
	top: '-200px',
	left: 0,
	zIndex: theme.zIndex.appBar,
}));

export default Layout;
