import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const TAX_RATES_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'TAX_RATES',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	TAX_RATES: {
		all: {
			action: getRouteOnboardingStepItem({
				routeTo: Routes.ACCOUNT_TAXES,
				completeOnClick: true,
			}),
			content: {
				title: t('homeView.onboarding.verifyYourTaxRates', 'Verify your tax rates'),
			},
			id: 'TAX_RATES',
			type: 'row',
		},
	},
});
