import { getOpenApiExample } from 'common/utils/openApi';

import { zPackageItemApi } from './api';
import { toApi } from './mapper';

export const PackageItem = {
	api: zPackageItemApi,
	toApi,
	example: getOpenApiExample(zPackageItemApi),
};
