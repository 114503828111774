import { ADMIN_API_PATH, ApiBaseUrls, STOREFRONT_API_PATH } from 'common/constants/api';
import { getClientEnv } from 'common/utils/frontUtils';

export const getApiBaseUrl = () => {
	const environment = getClientEnv();
	if (environment === 'production') {
		return ApiBaseUrls.PROD;
	}
	if (process.env.REACT_APP_CORE_API_URL) {
		return `${process.env.REACT_APP_CORE_API_URL}`;
	}
	if (environment === 'local' && process.env.REACT_APP_USE_LOCAL_SERVER) {
		return ApiBaseUrls.LOCAL;
	}
	if (environment === 'local') {
		return ApiBaseUrls.DEV;
	}
	return ApiBaseUrls.DEV;
};

export const getAdminApiBaseUrl = () => {
	return `${getApiBaseUrl()}/${ADMIN_API_PATH}`;
};

export const getStorefrontApiBaseUrl = () => {
	return `${getApiBaseUrl()}/${STOREFRONT_API_PATH}`;
};
