import React from 'react';

import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	List,
	ListItem,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { uniq } from 'lodash';
import { makeStyles } from 'tss-react/mui';

import ContainerBox from 'common/components/layout/ContainerBox';
import {
	Plan,
	RestrictedFeature,
	ShopAddons,
	addOnIncludesFeatures,
	getToggleableFeatures,
	isDevFeature,
	planIncludesFeatures,
	restrictedFeatures,
} from 'common/modules/plans';
import { isDevEnv } from 'common/utils/common';
import { getTypedKeys } from 'common/utils/objects';

interface Props {
	addOns: ShopAddons;
	plan: Plan;
	value: {
		enabledFeatures: RestrictedFeature[];
		hiddenFeatures: RestrictedFeature[];
	};
	onChange: (value: {
		enabledFeatures: RestrictedFeature[];
		hiddenFeatures: RestrictedFeature[];
	}) => void;
}

const featuresToShow = getToggleableFeatures().sort();

const ConfigureFeatures = (props: Props) => {
	const { classes } = useStyles();
	const { plan, addOns, value, onChange } = props;

	const { enabledFeatures, hiddenFeatures } = value;
	const addOnKeys = getTypedKeys(addOns);

	const renderEnabledCheckbox = (feature: RestrictedFeature) => {
		const isHidden = hiddenFeatures.includes(feature);
		const isEnabled = enabledFeatures.includes(feature);
		const isIncludedInPlan = planIncludesFeatures(feature)(plan);
		const isEnabledForDev = isDevEnv() && isDevFeature(feature);
		const isIncludedInAddOns = addOnKeys.some((addOn) => addOnIncludesFeatures(feature)(addOn));

		const isDisabled = isHidden || isIncludedInPlan || isIncludedInAddOns || isEnabledForDev;
		const isChecked =
			!isHidden && (isEnabled || isIncludedInPlan || isIncludedInAddOns || isEnabledForDev);

		const handleChange = (e: any, checked: boolean) => {
			if (checked) {
				onChange({
					enabledFeatures: uniq([...enabledFeatures, feature]),
					hiddenFeatures: hiddenFeatures.filter((f) => f !== feature),
				});
			} else {
				onChange({
					enabledFeatures: enabledFeatures.filter((f) => f !== feature),
					hiddenFeatures,
				});
			}
		};

		const getTooltipTitle = () => {
			if (isIncludedInPlan) {
				return `Included in the current plan`;
			}

			if (isIncludedInAddOns) {
				return `Included in one of the enabled add-ons`;
			}

			if (isEnabledForDev) {
				return `Enabled by default for all dev shops`;
			}

			return '';
		};

		return (
			<Tooltip title={getTooltipTitle()}>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							checked={isChecked}
							disabled={isDisabled}
							onChange={handleChange}
						/>
					}
					label="Enabled"
				/>
			</Tooltip>
		);
	};

	const renderHiddenCheckbox = (feature: RestrictedFeature) => {
		const handleChange = (e: any, checked: boolean) => {
			if (checked) {
				onChange({
					hiddenFeatures: uniq([...hiddenFeatures, feature]),
					enabledFeatures: enabledFeatures.filter((f) => f !== feature),
				});
			} else {
				onChange({
					hiddenFeatures: hiddenFeatures.filter((f) => f !== feature),
					enabledFeatures,
				});
			}
		};
		return (
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={hiddenFeatures.includes(feature)}
						onChange={handleChange}
					/>
				}
				label="Disabled"
			/>
		);
	};

	return (
		<ContainerBox>
			<Typography variant="h6">Feature flags</Typography>
			<Box maxWidth={800}>
				<Typography variant="body1" color="textSecondary">
					Override the features enabled for the shop. If a feature has been made into an add-on, you
					should probably rather use that to enable the feature.
				</Typography>
			</Box>
			<Box mt={4} />
			<List>
				{featuresToShow.map((feature) => (
					<ListItem divider key={feature}>
						<Box className={classes.feature}>
							<Box flex={1} mr={2}>
								<ListItemText
									primary={feature}
									secondary={restrictedFeatures[feature].description}
								/>
							</Box>
							<FormGroup row>
								{renderEnabledCheckbox(feature)}
								{renderHiddenCheckbox(feature)}
							</FormGroup>
						</Box>
					</ListItem>
				))}
			</List>
		</ContainerBox>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	feature: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	},
}));

export default ConfigureFeatures;
