import React from 'react';

import NotFound from 'views/404';

import { getQueryParam } from 'common/utils/frontUtils';

import RecoverEmail from './RecoverEmail';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';

type AuthAction = 'resetPassword' | 'recoverEmail' | 'verifyEmail';

const AuthUserActions = () => {
	const mode: AuthAction | string | null = getQueryParam('mode');
	const isInvitation: boolean = Boolean(getQueryParam('invitation'));
	const code = getQueryParam('oobCode') || '';

	switch (mode) {
		case 'resetPassword':
			return <ResetPassword code={code} isInvitation={isInvitation} />;
		case 'recoverEmail':
			return <RecoverEmail code={code} />;
		case 'verifyEmail':
			return <VerifyEmail code={code} />;
		default:
			return <NotFound />;
	}
};

export default AuthUserActions;
