///<reference path="./declarations.d.ts"/>
import StackdriverErrorReporter from 'stackdriver-errors-js';

const env = process.env.REACT_APP_ENV as 'local' | 'development' | 'production' | undefined;
const stackdriverApiKey = process.env.REACT_APP_STACKDRIVER_API_KEY;
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

type ErrorHandler =
	| StackdriverErrorReporter
	| {
			report: () => void;
			setUser: (userId: string) => void;
	  };

let errorHandler: ErrorHandler;

if (!!env && env !== 'local' && !!stackdriverApiKey && !!projectId) {
	errorHandler = new StackdriverErrorReporter();
	errorHandler.start({
		key: stackdriverApiKey,
		projectId,
		service: process.env.REACT_APP_PROJECT || 'web', // (optional)
		// version: '<my-service-version>',         		// (optional)
		// reportUncaughtExceptions: false          		// (optional) Set to false to stop reporting unhandled exceptions.
		// reportUnhandledPromiseRejections: false  		// (optional) Set to false to stop reporting unhandled promise rejections.
		// disabled: true                           		// (optional) Set to true to not report errors when calling report(), this can be used when developing locally.
		// context: {user: 'user1'}                 		// (optional) You can set the user later using setUser()
	});
} else {
	errorHandler = {
		report: console.error, //eslint-disable-line
		setUser: () => undefined,
	};
}

export default errorHandler;
