import { createSelector } from '@reduxjs/toolkit';
import { isEqual, uniqWith } from 'lodash';

import { getOverbookedVariantIds } from 'common/api/frontend/inventory_new';
import { getOrderHasDeposit } from 'common/modules/orders';
import { isMethodInLimits } from 'common/modules/payments/paymentMethods';
import {
	ShopManualOnlinePaymentMethodObject,
	ShopOnlinePaymentMethodObject,
} from 'common/modules/payments/types';
import { shopHasFeatures } from 'common/modules/plans';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { ReduxState } from 'services/types';

export const products = (state: ReduxState) => state.newRental.products ?? [];

export const rentalInfo = (state: ReduxState) => state.newRental.rentalInfo;

export const shoppers = (state: ReduxState) => state.newRental.shoppers;

export const startLocation = (state: ReduxState) => state.newRental.rentalInfo.startLocation;
export const endLocation = (state: ReduxState) => state.newRental.rentalInfo.endLocation;
export const reservationId = (state: ReduxState) => state.newRental.reservationId;

export const productAvailabilities = (state: ReduxState) => state.newRental.productAvailabilities;

export const overbookedVariantIds = createSelector(
	productAvailabilities,
	products,
	(productAvailabilities, products) => {
		return getOverbookedVariantIds(productAvailabilities, products);
	},
);

export const paymentMethod = createSelector(
	rentalInfo,
	(rentalInfo): ShopOnlinePaymentMethodObject | undefined => rentalInfo.pricing.paymentMethod,
);
export const isDurationManuallyChanged = (state: ReduxState) =>
	state.newRental.isDurationManuallyChanged;

export const validManualPaymentMethods = createSelector(
	ShopSelectors.getManualPaymentMethods,
	(manualPaymentMethods): ShopManualOnlinePaymentMethodObject[] => {
		const methods = [
			{
				id: 'PAY_STORE' as 'PAY_STORE',
			},
			...manualPaymentMethods.map((details) => ({
				id: 'MANUAL' as 'MANUAL',
				details,
			})),
		];
		return uniqWith(methods, (m1, m2) => isEqual(m1, m2));
	},
);

export const validPaymentLinkMethods = createSelector(
	rentalInfo,
	validManualPaymentMethods,
	ShopSelectors.getActivePaymentMethods,
	ShopSelectors.shopFeatures,
	ShopSelectors.getShopCurrency,
	ShopSelectors.activeShopCountry,
	(
		rentalInfo,
		manualPaymentMethods,
		activePaymentMethods,
		shopFeatures,
		currency,
		country,
	): ShopOnlinePaymentMethodObject[] => {
		const shopHasBookingLinkFeature = shopHasFeatures('BOOKING_LINK', 'ALL')(shopFeatures);
		const methods = [
			...activePaymentMethods.filter((method) =>
				isMethodInLimits(method, {
					recurringRequired: false,
					currency: currency.code,
					country: country,
					paymentIsInIframe: false,
					hasDeposit: getOrderHasDeposit(rentalInfo),
					isCustomDomain: false,
				}),
			),
			...manualPaymentMethods,
		];
		const uniqueMethods = uniqWith(methods, (m1, m2) => isEqual(m1, m2));
		return shopHasBookingLinkFeature
			? uniqueMethods
			: uniqueMethods.filter((method) => method.id !== 'PAY_STORE' && method.id !== 'MANUAL');
	},
);

export const defaultRentalLinkPaymentMethods = createSelector(
	rentalInfo,
	ShopSelectors.getActivePaymentMethods,
	ShopSelectors.getShopCurrency,
	ShopSelectors.activeShopCountry,
	(rentalInfo, activePaymentMethods, currency, country): ShopOnlinePaymentMethodObject[] => {
		return activePaymentMethods.filter((method) =>
			isMethodInLimits(method, {
				recurringRequired: false,
				currency: currency.code,
				country: country,
				paymentIsInIframe: false,
				hasDeposit: getOrderHasDeposit(rentalInfo),
				isCustomDomain: false,
			}),
		);
	},
);
