import React from 'react';

import { Box, Stack, SvgIcon, Typography } from '@mui/material';

import { ReactComponent as CheckmarkCircleIcon } from 'common/assets/icons/icon-checkmark-circle-outline.svg';

export interface Props {
	title: string;
	text: string;
}
const CtaWithIcon = ({ text, title }: Props) => {
	return (
		<Stack direction="row" key={title} spacing={3}>
			<SvgIcon
				sx={{
					color: (theme) => theme.palette.common.white,
					fill: (theme) => theme.palette.common.white,
					fontSize: '4rem',
				}}
			>
				<CheckmarkCircleIcon />
			</SvgIcon>
			<Box>
				<Typography variant="h4" gutterBottom>
					{title}
				</Typography>
				<Typography variant="body1" fontWeight={300} maxWidth={350}>
					{text}
				</Typography>
			</Box>
		</Stack>
	);
};

export default CtaWithIcon;
