import { SalesReport } from 'common/types';

import { CallableCreator } from '..';

export interface SalesReportRequest {
	startDate: string;
	endDate: string;
	locationId: string | null;
	shopId: string;
	shopTimezone: string;
}

export interface SalesReportByCurrency {
	[currency: string]: {
		total: SalesReport;
		daily: {
			[day: number]: {
				total: SalesReport;
				hourly: {
					// Revenue per hour
					[hour: number]: number;
				};
			};
		};
	};
}

export const reports = (createCallable: CallableCreator) => ({
	getSalesReport: createCallable<SalesReportRequest, SalesReportByCurrency>(
		'reports-getSalesReport',
	),
});
