import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import {
	RestrictedFeature,
	featureIsHidden,
	shopHasFeatures,
	userHasFeatures,
} from 'common/modules/plans';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';

export const useFeatures = () => {
	const shopFeatures = useSelector(ShopSelectors.shopFeatures);
	const userRolesAndPermissions = useSelector(UserSelectors.userRolesPermissions);

	const _shopHasFeatures = useCallback(
		(
			features: RestrictedFeature | RestrictedFeature[] | undefined,
			filteringType: 'SOME' | 'ALL' = 'SOME',
		) => {
			return shopHasFeatures(features, filteringType)(shopFeatures);
		},
		[shopFeatures],
	);

	const _userHasFeatures = useCallback(
		(
			features: RestrictedFeature | RestrictedFeature[] | undefined,
			filteringType: 'SOME' | 'ALL' = 'SOME',
			options?: {
				ignoreShopFeatures?: boolean;
			},
		) => {
			return (
				(!!options?.ignoreShopFeatures || shopHasFeatures(features, filteringType)(shopFeatures)) &&
				userHasFeatures(features, filteringType)(userRolesAndPermissions)
			);
		},
		[shopFeatures, userRolesAndPermissions],
	);

	const _featureIsHidden = useCallback(
		(
			feature: RestrictedFeature | RestrictedFeature[] | undefined,
			filteringType: 'SOME' | 'ALL' = 'SOME',
		) => {
			return featureIsHidden(feature, shopFeatures?.hiddenFeatures ?? [], filteringType);
		},
		[shopFeatures?.hiddenFeatures],
	);

	return {
		shopHasFeatures: _shopHasFeatures,
		userHasFeatures: _userHasFeatures,
		featureIsHidden: _featureIsHidden,
	};
};
