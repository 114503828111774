import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.extension.name', 'Booking Extension'),
	subHeader: t(
		'common:blocks.details.extension.subHeader',
		'Get your customers to experience more',
	),
	description: t(
		'common:blocks.details.extension.description',
		"When your customers want to extend their booking to end later than planned, Rentle makes it convenient. With booking extensions your customers can independently extend their booking on the go with only a few clicks. Payments and availability checks are handled automatically so there's no extra labor for you",
	),
	supportArticle: SupportArticles.EXTENSIONS,
	uninstallNotice: t(
		'common:blocks.details.extension.uninstallNotice',
		'By uninstalling Booking Extensions, you will lose your existing extension configurations and your customers will no longer be able to extend their rentals',
	),
});

export default details;
