import { AddressApi, AddressDb } from './types';

export const toApi = (data: AddressDb): AddressApi => {
	return {
		address: data.address,
		zipCode: data.zipCode,
		city: data.city,
		country: data.country,
		state: data.state,
		details: data.details,
	};
};

export const toDb = (data: AddressApi): AddressDb => {
	return {
		address: data.address,
		zipCode: data.zipCode,
		city: data.city,
		country: data.country,
		state: data.state,
		details: data.details,
	};
};
