import './fonts.css';
import { BrandingFont } from 'common/types';

declare module '@mui/material/styles/createTypography' {
	interface FontStyleOptions {
		paragraphFont: CSSProperties['fontFamily'];
	}
	interface FontStyle {
		paragraphFont: CSSProperties['fontFamily'];
	}
}

export const inter = '"Inter", "Helvetica", "Arial", sans-serif';

export const getBrandingFontFamily = (font: BrandingFont): string => {
	const fallBackFonts = font.fontFamily !== 'Inter' ? '"Inter", sans-serif' : 'sans-serif';
	return `"${font.fontFamily}", ${fallBackFonts}`;
};

export const getWebFontloaderGoogleFamilyString = (font: BrandingFont): string | undefined => {
	if (font.provider !== 'google') return undefined;
	const fontWeights = '400,500,700';
	return `${font.fontFamily}:${fontWeights}`;
};
