import moment from 'moment-timezone';

import { OPEN_ENDED_BLOCKER_YEARS } from 'common/modules/inventoryBlockers';
import { SubscriptionAutoRenewOptions } from 'common/modules/subscriptions/constants';
import { OrderSubscriptionPayment, ProductSubscription } from 'common/modules/subscriptions/types';
import {
	AmountObject,
	CartProduct,
	DeliveryOption,
	DeliveryTypes,
	Duration,
	ISOString,
	OrderProduct,
	OrderProductWithUnits,
	ProductApi,
} from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import { multiplyPrice } from 'common/utils/pricing';

import { OrderProductSubscription, SubscriptionPeriod } from '../types';
import { getNextBillingDate } from './billingDates';

/**
 * Create a new order product subscription from a given subscription option
 *
 * The startCycle argument can be used to control whether the subscription
 * should be created so that the first cycle has already been paid for.
 *
 * If the startCycle is 1 (default), the next billing date will be 1 cycle in the future
 * If the startCycle is 0, the next billing date will be the same as the start date
 *
 *
 * @param args
 * @returns
 */
export const createOrderProductSubscription = (args: {
	subscription: ProductSubscription;
	startDate: ISOString;
	startCycle?: 0 | 1;
}): OrderProductSubscription => {
	const { startDate, subscription, startCycle = 1 } = args;

	const cycleCount = getCycleCountFromSubscriptionPeriod(
		subscription.minCommitment,
		subscription.cycle,
	);

	const cycles = {
		current: startCycle,
		committed: cycleCount,
		total: isAutoRenewProductSubscription(subscription) ? null : cycleCount,
	};

	return {
		id: newFirestoreId(),
		cycle: subscription.cycle,
		cycles,
		startDate,
		nextBillingDate: getNextBillingDate({
			cycle: subscription.cycle,
			cycles,
			startDate,
		}),
	};
};

export const getUniqIdForOrderProductSubscription = (
	subscription: OrderProductSubscription,
): OrderProductSubscription => ({ ...subscription, id: newFirestoreId() });

/**
 * Update the current cycle of a subscription.
 *
 * @param subscription The subscription to update
 * @param increment The number of cycles to increment by
 * @returns The updated subscription
 */
export const incrementCurrentCycle = (args: {
	subscription: OrderProductSubscription;
	increment?: number;
}): OrderProductSubscription => {
	const { subscription, increment = 1 } = args;
	let updatedSubscription = {
		...subscription,
		cycles: { ...subscription.cycles, current: subscription.cycles.current + increment },
	};
	updatedSubscription.nextBillingDate = getNextBillingDate(updatedSubscription);
	return updatedSubscription;
};

/**
 * Update the committed cycles of a subscription.
 *
 * @param subscription The subscription to update
 * @param newCommittedCycles The new number of committed cycles
 * @returns The updated subscription
 */
export const updateCommittedCycles = (args: {
	subscription: OrderProductSubscription;
	newCommittedCycles: number;
}): OrderProductSubscription => {
	const { subscription, newCommittedCycles } = args;
	let updatedSubscription = {
		...subscription,
		cycles: { ...subscription.cycles, committed: newCommittedCycles },
	};

	if (!!subscription.cycles.total && newCommittedCycles > subscription.cycles.total) {
		updatedSubscription.cycles.total = newCommittedCycles;
	}

	return updatedSubscription;
};

/**
 * Update the total cycles of a subscription.
 *
 * @param subscription The subscription to update
 * @param newTotalCycles The new number of total cycles
 * @returns The updated subscription
 */
export const updateTotalCycles = (args: {
	subscription: OrderProductSubscription;
	newTotalCycles: number | null;
}): OrderProductSubscription => {
	const { subscription, newTotalCycles } = args;

	let updatedSubscription = {
		...subscription,
		cycles: { ...subscription.cycles, total: newTotalCycles },
	};

	if (!!newTotalCycles && subscription.cycles.committed > newTotalCycles) {
		updatedSubscription.cycles.committed = newTotalCycles;
	}
	return updatedSubscription;
};

/**
 * Updates a subscription with new start or end dates.
 *
 * If the start date is updated, only the start date and next billing date are updated.
 * If the end date is updated, the total cycles are updated to match the new end date.
 *
 * @param args The subscription to update
 * @returns The updated subscription
 */
export const updateSubscriptionStartDate = (args: {
	subscription: OrderProductSubscription;
	newStartDate: ISOString;
}): OrderProductSubscription => {
	const { subscription, newStartDate } = args;

	return {
		...subscription,
		startDate: newStartDate,
		nextBillingDate: getNextBillingDate({
			cycle: subscription.cycle,
			cycles: subscription.cycles,
			startDate: newStartDate,
		}),
	};
};

/**
 * Get the amount of billing cycles needed for a given subscription period
 *
 * The result is rounded up, so that the resulting cycle count is always at least
 * enough to cover the given period. For example with a 6 month period and a 4 month cycle,
 * the result will be 2 cycles, since one cycle is not enough to cover the period.
 *
 * @param period The subscription period
 * @param cycle The payment cycle
 * @returns The amount of billing cycles needed to cover the given period
 */
export const getCycleCountFromSubscriptionPeriod = (
	period: SubscriptionPeriod,
	cycle: SubscriptionPeriod,
) => {
	const periodInMonths = getSubscriptionPeriodAsMonths(period);
	const cycleInMonths = getSubscriptionPeriodAsMonths(cycle);

	return Math.ceil(periodInMonths / cycleInMonths);
};

/**
 * Get a subscription period as months
 *
 * @param period The subscription period
 * @returns The amount of months in the given period
 */
export const getSubscriptionPeriodAsMonths = (period: SubscriptionPeriod) => {
	switch (period.unit) {
		case 'months':
			return period.value;
		case 'years':
			return period.value * 12;
		default:
			return switchUnreachable(period.unit);
	}
};

/**
 * Get a subscription period as years
 *
 * @param period The subscription period
 * @returns The amount of years in the given period
 */
export const getSubscriptionPeriodAsYears = (period: SubscriptionPeriod) => {
	switch (period.unit) {
		case 'months':
			return period.value / 12;
		case 'years':
			return period.value;
		default:
			return switchUnreachable(period.unit);
	}
};

/**
 * Check whether a subscription is active (i.e. should still be billed)
 *
 * @param subscription The subscription
 * @returns A boolean indicating whether the subscription is active
 */
export const isSubscriptionActive = (subscription: OrderProductSubscription | undefined) => {
	if (!subscription) return false;
	return subscription.cycles.current < (subscription.cycles.total ?? Infinity);
};

export const getMatchingSubscriptionOption = (args: {
	subscription: OrderProductSubscription;
	subscriptionOptions: ProductSubscription[];
}): ProductSubscription | undefined => {
	const { subscription, subscriptionOptions } = args;
	const months = getCommittedMonths(subscription);
	return subscriptionOptions.find((o) => {
		return getSubscriptionPeriodAsMonths(o.minCommitment) === months;
	});
};

/**
 * Get the amount of upcoming billing cycles for a given subscription
 *
 * @param subscription The subscription
 * @returns The amount of upcoming billing cycles, or Infinity if the subscription does not have an end date
 */
export const getUpcomingBillingCycleCount = (
	subscription: OrderProductSubscription | undefined,
): number => {
	if (!subscription) return 0;
	return getTotalBillingCycleCount(subscription) - subscription.cycles.current;
};

/**
 * Get the amount of current billing cycles (i.e. cycles where the billing has already
 * happened) for a given subscription
 *
 * @param subscription The subscription
 * @returns The amount of current billing cycles
 */
export const getCurrentBillingCycleCount = (
	subscription: OrderProductSubscription | undefined,
): number => {
	if (!subscription) return 0;
	return subscription.cycles.current;
};

/**
 * Get the amount of committed billing cycles for a given subscription (past and upcoming)
 *
 * @param subscription The subscription
 * @returns The total amount of committed billing cycles
 */
export const getCommittedBillingCycleCount = (
	subscription: OrderProductSubscription | undefined,
): number => {
	return subscription?.cycles.committed ?? 0;
};

/**
 * Get the total amount of billing cycles for a given subscription
 *
 * @param subscription The subscription
 * @returns The amount of billing cycles, or Infinity if the subscription does not have an end date
 */
export const getTotalBillingCycleCount = (
	subscription: OrderProductSubscription | undefined,
): number => {
	return subscription?.cycles.total ?? Infinity;
};

/**
 * Get the end date of a subscription
 *
 * @param subscription The subscription
 * @returns The end date, or null if the subscription does not have an end date
 */
export const getSubscriptionEndDate = (
	subscription: OrderProductSubscription,
): ISOString | null => {
	const subscriptionDurationInMonths = getSubscriptionDurationInMonths(subscription);

	if (isAutoRenewSubscription(subscription) || !subscriptionDurationInMonths) {
		return null;
	}

	return moment(subscription.startDate).add(subscriptionDurationInMonths, 'months').toISOString();
};

/**
 * Get the duration of subscription in months
 *
 * @param subscription
 * @returns The duration as months
 */

export const getSubscriptionDurationInMonths = (subscription: OrderProductSubscription): number => {
	const months = getSubscriptionPeriodAsMonths(subscription.cycle);
	return isAutoRenewSubscription(subscription) || !subscription.cycles.total
		? months * subscription.cycles.committed
		: months * subscription.cycles.total;
};

/**
 * Get the date a subscription is committed until
 *
 * @param subscription The subscription
 * @returns The committed date, or the end date (whichever is sooner)
 */
export const getSubscriptionCommittedDate = (subscription: OrderProductSubscription): ISOString => {
	const months = getSubscriptionPeriodAsMonths(subscription.cycle);
	const cycles = Math.min(subscription.cycles.committed, subscription.cycles.total ?? Infinity);
	return moment(subscription.startDate)
		.add(months * cycles, 'months')
		.toISOString();
};

export const getCommittedMonths = (subscription: OrderProductSubscription) => {
	return getSubscriptionPeriodAsMonths(subscription.cycle) * subscription.cycles.committed;
};

export const getSubscriptionDuration = (
	subscription: OrderProductSubscription | undefined,
): Duration => {
	if (!subscription) {
		return { durationInSeconds: 0, durationType: '24h', durationName: null };
	}

	const endDate = getSubscriptionEndDate(subscription);

	const durationInSeconds = !!endDate
		? moment(endDate).diff(subscription.startDate, 'seconds')
		: moment.duration(getSubscriptionDurationInMonths(subscription), 'months').asSeconds();

	return {
		durationType: '24h',
		durationInSeconds,
		durationName: null,
	};
};

export const getSubscriptionOptionDuration = (
	subscriptionOption: ProductSubscription,
	startDate: ISOString,
): Duration => {
	const subscription = createOrderProductSubscription({
		subscription: subscriptionOption,
		startDate,
	});
	return getSubscriptionDuration(subscription);
};

export const getSubscriptionOptionCommittedPrice = (
	subscriptionOption: ProductSubscription,
): AmountObject => {
	const { pricePerCycle, cycle, minCommitment } = subscriptionOption;
	const minCycles = getCycleCountFromSubscriptionPeriod(minCommitment, cycle);

	return multiplyPrice(pricePerCycle, minCycles);
};

export const getCurrentCyclesById = (subscriptionProducts: OrderProduct[]) => {
	return subscriptionProducts.reduce((acc, p) => {
		const productSubscription = p.subscription;
		return {
			...acc,
			...(!!productSubscription && {
				[productSubscription.id]: productSubscription.cycles.current,
			}),
		};
	}, {} as OrderSubscriptionPayment['cycles']);
};

export const endOrderProductSubscription = (
	subscription: OrderProductSubscription,
): OrderProductSubscription => {
	return {
		...subscription,
		cycles: {
			...subscription.cycles,
			committed: subscription.cycles.current,
			total: subscription.cycles.current,
		},
		nextBillingDate: null,
	};
};

export const hasAutoRenewProductApiSubscription = (product: ProductApi) => {
	return !!product.subscriptions?.options.some(isAutoRenewProductSubscription); //For now individuals options cannot be either or, so all options are auto renewing or not
};

export const hasAutoRenewProductSubscription = (products: OrderProduct[] | CartProduct[]) =>
	products.some(isAutoRenewSubscriptionOrderProduct);

export const allOrderProductsHaveAutoRenewSubscription = (orderProducts: OrderProduct[]) =>
	orderProducts.every(isAutoRenewSubscriptionOrderProduct);

export const isAutoRenewProductSubscription = (productSubscription: ProductSubscription) =>
	productSubscription.autoRenew === SubscriptionAutoRenewOptions.cycle;

export const isAutoRenewSubscriptionOrderProduct = (
	orderProduct: OrderProduct | CartProduct | OrderProductWithUnits,
) => isAutoRenewSubscription(orderProduct?.subscription);

export const isAutoRenewSubscription = (subscription?: OrderProductSubscription) =>
	subscription?.cycles.total === null;

export const getLongestSubscriptionOptionAsYears = (options: ProductSubscription[]) => {
	return Math.max(...options.map(getSubscriptionOptionDurationAsYears), 0);
};

export const getSubscriptionOptionDurationAsYears = (option: ProductSubscription) => {
	return option.autoRenew
		? OPEN_ENDED_BLOCKER_YEARS
		: getSubscriptionPeriodAsYears(option.minCommitment);
};

export const doesProductSubscriptionSupportDelivery = (
	product: ProductApi,
	deliveryOption: DeliveryOption,
) => {
	return (
		!hasAutoRenewProductApiSubscription(product) ||
		deliveryOption.type === DeliveryTypes.DELIVERY_AND_OPTIONAL_PICKUP ||
		deliveryOption.type === DeliveryTypes.DELIVERY_ONLY
	);
};

export const hasMatchingSubscriptionCommittedDate = (
	product: CartProduct,
	cartProducts: CartProduct[],
) => {
	return cartProducts.some(
		(p) =>
			!!product.subscription &&
			!!p.subscription &&
			getSubscriptionCommittedDate(p.subscription) ===
				getSubscriptionCommittedDate(product.subscription),
	);
};
