import React, { useEffect, useMemo } from 'react';

import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { difference } from 'lodash';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import ConfigureAddOns from 'common/components/__INTERNAL/ConfigureAddOns';
import ConfigureFeatures from 'common/components/__INTERNAL/ConfigureFeatures';
import SaveSnackbar from 'common/components/__INTERNAL/SaveSnackbar';
import { Callable } from 'common/frontend/callable';
import { RestrictedFeature, ShopAddons, getDefaultPurchasedPlan } from 'common/modules/plans';
import { getTypedKeys } from 'common/utils/objects';
import * as ViewActions from 'actions/ViewActions';
import { Banner } from 'components/Banners';
import Container from 'components/Container';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { useTranslation } from 'services/localization';

type FormValues = {
	addOns: ShopAddons;
	features: {
		enabledFeatures: RestrictedFeature[];
		hiddenFeatures: RestrictedFeature[];
	};
};

const DevSettings = () => {
	const { t } = useTranslation();
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const isVisible = useSelector(ViewSelectors.isDevPanelOpen);
	const readOnly = useSelector(ShopSelectors.activeShopReadOnly);
	const shopId = useSelector(ShopSelectors.activeShopId);

	useHotkeys(
		'ctrl+shift+d',
		() => {
			dispatch(ViewActions.setDevPanelOpen(!isVisible));
		},
		[isVisible],
	);

	const defaultValues: FormValues = useMemo(
		() => ({
			plan: readOnly?.features.plan ?? getDefaultPurchasedPlan('LITE'),
			addOns: readOnly?.features.addOns ?? {},
			features: {
				enabledFeatures: readOnly?.features.enabledFeatures ?? [],
				hiddenFeatures: readOnly?.features.hiddenFeatures ?? [],
			},
		}),
		[readOnly],
	);

	const form = useForm<FormValues>({
		defaultValues,
	});

	const { isDirty, isSubmitting, dirtyFields } = form.formState;
	const { reset } = form;

	const addOns: FormValues['addOns'] = useWatch({
		control: form.control,
		name: 'addOns',
		defaultValue: defaultValues.addOns,
	});

	const handleCancel = () => {
		reset(defaultValues);
	};

	useEffect(() => {
		if (!isVisible) {
			reset(defaultValues);
		}
	}, [isVisible, defaultValues, reset]);

	const handleSubmit = async (data: FormValues) => {
		if (!shopId) return;
		try {
			const ops = [];

			if (!!dirtyFields.addOns) {
				const addOnsBefore = getTypedKeys(defaultValues.addOns);
				const addOnsNow = getTypedKeys(data.addOns);
				const removedAddOns = difference(addOnsBefore, addOnsNow);
				const addedAddOns = difference(addOnsNow, addOnsBefore);

				if (removedAddOns.length > 0) {
					ops.push(
						Callable.plans.addOns.disable({
							shopId,
							addOns: removedAddOns,
						}),
					);
				}

				if (addedAddOns.length > 0) {
					ops.push(
						Callable.plans.addOns.enable({
							shopId,
							addOns: addedAddOns,
						}),
					);
				}
			}

			if (!!dirtyFields.features) {
				ops.push(
					Callable.plans.features.update({
						shopId,
						enabledFeatures: data.features.enabledFeatures,
						hiddenFeatures: data.features.hiddenFeatures,
					}),
				);
			}

			await Promise.all(ops);

			reset(data);
		} catch (err) {
			window.alert(err);
		}
	};

	if (!isVisible) return null;

	return (
		<Dialog
			open
			onClose={() => dispatch(ViewActions.setDevPanelOpen(false))}
			maxWidth="lg"
			fullWidth
			className={classes.dialog}
			classes={{
				paper: classes.dialogPaper,
			}}
		>
			<AppBar className={classes.appbar}>
				<Toolbar>
					<IconButton
						onClick={() => dispatch(ViewActions.setDevPanelOpen(false))}
						sx={{ color: (theme) => theme.palette.primary.contrastText }}
					>
						<RiCloseLine color="inherit" />
					</IconButton>
					<Box flex={1}>
						<Typography variant="h6">{'Internal settings'}</Typography>
					</Box>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Container>
					<Banner
						title="Tip: hotkeys"
						description="You can open and close this view by pressing Ctrl + Shift + D"
						variant="info"
					/>
					<Controller
						name="addOns"
						control={form.control}
						render={(renderProps) => (
							<ConfigureAddOns
								value={renderProps.field.value}
								onChange={renderProps.field.onChange}
								plan={readOnly!.features.plan.plan}
								mode="admin"
								t={t}
							/>
						)}
					/>
					<Controller
						name="features"
						control={form.control}
						render={(renderProps) => (
							<ConfigureFeatures
								addOns={addOns}
								plan={readOnly!.features.plan.plan}
								value={renderProps.field.value}
								onChange={renderProps.field.onChange}
							/>
						)}
					/>
				</Container>
			</DialogContent>
			<SaveSnackbar
				onSave={form.handleSubmit(handleSubmit)}
				onCancel={handleCancel}
				saving={isSubmitting}
				visible={isDirty}
				position="absolute"
			/>
		</Dialog>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	appbar: {
		background: theme.palette.primary.main,
		position: 'relative',
	},
	dialog: {
		margin: theme.spacing(2),
	},
	dialogPaper: {
		background: theme.palette.background.default,
	},
	toggleButton: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: 10000000,
		background: theme.palette.primary.main,
		padding: theme.spacing(2),
		borderRadius: '50%',
	},
	enabledChip: {
		background: theme.palette.primary.main,
		'&:hover': {
			background: theme.palette.primary.main,
		},
		'&:focus': {
			background: theme.palette.primary.main,
		},
		color: theme.palette.primary.contrastText,
		opacity: 1,
		fontWeight: 'bold',
	},
	disabledChip: {
		background: theme.palette.action.disabled,
		'&:hover': {
			background: theme.palette.action.hover,
		},
		'&:focus': {
			background: theme.palette.action.focus,
		},
		color: theme.palette.text.primary,
		opacity: 1,
		fontWeight: 'bold',
	},
}));

export default DevSettings;
