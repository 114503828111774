import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

export const zLocaleFieldApi = withOpenApi(
	withOpenApi(z.record(z.string()), {
		additionalProperties: {
			type: 'string',
			description:
				'The property name is the locale code, and the value is the translated text in that locale.',
		},
	}).and(
		withOpenApi(
			z.object({
				def: z.string(),
			}),
			{
				fields: {
					def: 'The value in default locale.',
				},
			},
		),
	),
	{
		title: 'Localized field',
		description: 'Localized text field.',
	},
);
