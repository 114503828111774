import React from 'react';

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { RiInformationLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import {
	AddOn,
	AllAddOns,
	addonRequiresManualSetup,
	getBlockValue,
	getPlanName,
	isAddOnInstalled,
} from 'common/modules/plans';
import CustomTooltip from 'components/CustomTooltip';
import AddOnLink from 'components/modals/AddOnModal/components/AddOnLink';
import BlockSlotsBoxes from 'components/modals/AddOnModal/components/BlockSlotsBoxes';
import BookDemoButton from 'components/modals/AddOnModal/components/BookDemoButton';
import InstallButton from 'components/modals/AddOnModal/components/InstallButton';
import UninstallButton from 'components/modals/AddOnModal/components/UninstallButton';
import UpgradePlanButton from 'components/modals/AddOnModal/components/UpgradePlanButton';
import { usePlanRestrictions } from 'hooks/usePlanRestrictions';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { Trans, useTranslation } from 'services/localization';

interface Props {
	addOn: AddOn;
	onClose: () => void;
	onInstall: () => void;
	onUninstall: () => void;
	loading: boolean;
}
const LEGACY_AddOnModalInstallSection = (props: Props) => {
	const { addOn, onInstall, onUninstall, loading } = props;
	const { t } = useTranslation();
	const theme = useTheme();

	const shopFeatures = useSelector(ShopSelectors.shopFeatures);
	const shopPlan = useSelector(ShopSelectors.shopPlan);

	const allAddOnsAmount = AllAddOns.length;

	const isIntegration = addonRequiresManualSetup(addOn);
	const currentBlockCost = getBlockValue(addOn);

	const isInstalled = !loading && isAddOnInstalled(addOn, shopFeatures);

	const {
		remainingCount: remainingAddOns,
		currentCount: usedBlocks,
		maxCount: totalBlocks,
	} = usePlanRestrictions('blocks');

	return (
		<Stack flex={1} spacing={2} direction="column" justifyContent="space-between">
			<Box mb={2} flex={1}>
				{isFinite(totalBlocks) && (
					<>
						<BlockSlotsBoxes
							usedBlockSlots={usedBlocks}
							totalBlockSlots={totalBlocks}
							currentBlockCost={currentBlockCost}
							currentBlockInstalled={isInstalled}
						/>
						<Stack direction="row" alignItems="center" mb={1}>
							<Typography sx={{ fontWeight: 600 }}>
								{remainingAddOns === 0
									? t('account.addOns.modal.noAddOnsLeft', 'No add-ons left')
									: t('account.addOns.modal.addOnsLeft', {
											addOns: isFinite(remainingAddOns) ? remainingAddOns : allAddOnsAmount,
											defaultValue: '{{addOns}} add-ons left',
									  })}
							</Typography>
							<CustomTooltip
								title={t(
									'account.addOns.modal.tooltip2',
									'Customize your store with Add-ons. With each paid plan you can select a certain amount of add-ons for your account.',
								)}
							>
								<IconButton
									color="secondary"
									size="small"
									aria-label="info"
									sx={{ color: (theme) => theme.palette.text.primary }}
								>
									<RiInformationLine size={20} />
								</IconButton>
							</CustomTooltip>
						</Stack>
					</>
				)}
				<Typography sx={{ fontWeight: 400 }} variant="body2">
					{
						<Trans
							i18nKey="account.addOns.modal.currentPlan2"
							defaults="You are currently on <shopPlan>{{shopPlan}}</shopPlan> ({{totalBlocks}} add-ons)"
							values={{
								shopPlan: shopPlan ? getPlanName(shopPlan) : '',
								totalBlocks: isFinite(totalBlocks) ? totalBlocks : allAddOnsAmount,
							}}
							components={{
								shopPlan: <span style={{ fontWeight: 500 }}></span>,
							}}
						/>
					}
				</Typography>
				{isInstalled ? (
					<Box mt={2}>
						<AddOnLink type="setup" addOn={addOn} mb={1.5} />
						<AddOnLink type="help-article" addOn={addOn} mb={1.5} />
					</Box>
				) : (
					<Box mt={2}>
						{usedBlocks > 0 && <AddOnLink type="manage-add-ons" addOn={addOn} mb={1.5} />}
						<AddOnLink type="help-article" addOn={addOn} mb={1.5} />
						{!isIntegration && <AddOnLink type="book-demo" addOn={addOn} />}
					</Box>
				)}
			</Box>
			{isInstalled ? (
				<Box>
					<UninstallButton mt={8} onClick={onUninstall} />
				</Box>
			) : (
				<Box
					sx={{
						[theme.breakpoints.down('sm')]: {
							borderTop: (theme) => `1px solid ${theme.palette.divider}`,
							py: 4,
						},
					}}
				>
					{isIntegration ? (
						<BookDemoButton
							analytics={{
								block: addOn,
								action: 'book-demo',
							}}
						/>
					) : remainingAddOns <= 0 ? (
						<UpgradePlanButton />
					) : (
						<InstallButton loading={loading} onClick={onInstall} />
					)}
				</Box>
			)}
		</Stack>
	);
};

export default LEGACY_AddOnModalInstallSection;
