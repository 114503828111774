import { createSelector } from '@reduxjs/toolkit';
import { chain } from 'lodash';
import moment from 'moment-timezone';

import { adminLanguages } from 'common/locales/utils/constants';
import { getShopPrimaryPaymentMethod } from 'common/modules/billing';
import {
	DEFAULT_OPENING_HOURS,
	OpeningHours,
	getMerchantDefaultOpeningHours,
	getOpeningHoursForStore,
} from 'common/modules/openingHours';
import {
	isCustomShopPaymentMethodObject,
	isDepositPaymentMethod,
} from 'common/modules/payments/paymentMethods';
import {
	FixedManualPaymentMethodObject,
	ManualPaymentMethodDetails,
	ShopManualOnlinePaymentMethodObject,
} from 'common/modules/payments/types';
import { getFeaturesInPlan, getShopFeatures } from 'common/modules/plans';
import { getOnlineStoreUrl } from 'common/modules/urls';
import { isAdmin, isRentleSupportFromUser } from 'common/modules/users/utils';
import { DEFAULT_BRANDING } from 'common/styles/constants';
import * as Themes from 'common/styles/themes';
import { getThemeWithBranding } from 'common/styles/utils';
import { ById, Languages } from 'common/types';
import { getDefaultVatTable } from 'common/utils/common';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';
import { getDefaultDateObject, getDefaultTimezone } from 'common/utils/dateUtils';
import { getClientEnv } from 'common/utils/frontUtils';
import { isOnlinePaymentsInUse } from 'common/utils/payments';
import { getShopLocationsWithContact } from 'common/utils/shopUtils';
import { ReduxState } from 'services/types';

import { hashByUniqueField } from './../../../common/utils/arrays';
import { userActiveLocationId } from './UserSelectors';

const devEnv = process.env.REACT_APP_ENV !== 'production';
const urlPrefix = devEnv ? 'dev.' : '';

export const activeShop = (state: ReduxState) => state.shop.shop;
export const activeShopData = (state: ReduxState) => activeShop(state).data;
export const activeShopLoading = (state: ReduxState) => activeShop(state).loading;
export const activeShopError = (state: ReduxState) => activeShop(state).error;

export const discountCodes = (state: ReduxState) => state.shop.discountCodes;
export const discountCodesData = (state: ReduxState) => discountCodes(state).data ?? [];
export const discountCodesLoading = (state: ReduxState) => discountCodes(state).loading;
export const discountCodesError = (state: ReduxState) => discountCodes(state).error;

export const adyenPaymentMethods = (state: ReduxState) => state.shop.adyenPaymentMethods;
export const adyenPaymentMethodsData = (state: ReduxState) => adyenPaymentMethods(state).data;
export const adyenPaymentMethodsLoading = (state: ReduxState) => adyenPaymentMethods(state).loading;
export const adyenPaymentMethodsError = (state: ReduxState) => adyenPaymentMethods(state).error;

export const users = (state: ReduxState) => state.shop.users;
export const usersData = (state: ReduxState) => users(state).data ?? [];
export const usersLoading = (state: ReduxState) => users(state).loading;
export const usersError = (state: ReduxState) => users(state).error;

export const emailSettings = (state: ReduxState) => state.shop.emailSettings;
export const emailSettingsData = (state: ReduxState) => emailSettings(state).data ?? [];
export const emailSettingsLoading = (state: ReduxState) => emailSettings(state).loading;
export const emailSettingsError = (state: ReduxState) => emailSettings(state).error;

export const customThemes = (state: ReduxState) => state.shop.customThemes;
export const customThemesData = (state: ReduxState) => customThemes(state).data;
export const customThemesLoading = (state: ReduxState) => customThemes(state).loading;
export const customThemesError = (state: ReduxState) => customThemes(state).error;

export const rentleCustomTheme = (state: ReduxState) => state.shop.rentleCustomTheme;
export const rentleCustomThemeData = (state: ReduxState) => rentleCustomTheme(state).data;
export const rentleCustomThemeLoading = (state: ReduxState) => rentleCustomTheme(state).loading;
export const rentleCustomThemeError = (state: ReduxState) => rentleCustomTheme(state).error;

export const openingHours = (state: ReduxState) => state.shop.openingHours;
export const openingHoursData = (state: ReduxState) => openingHours(state).data ?? [];
export const openingHoursLoading = (state: ReduxState) => openingHours(state).loading;
export const openingHoursError = (state: ReduxState) => openingHours(state).error;

export const activeShopId = createSelector(activeShopData, (shop) => shop?.shopInfo.id);
export const activeShopInfo = createSelector(activeShopData, (shop) => shop?.shopInfo);
export const activeShopPublicInfo = createSelector(activeShopData, (shop) => shop?.publicInfo);
export const activeShopReadOnly = createSelector(activeShopData, (shop) => shop?.readOnly);
export const activeShopUrls = createSelector(activeShopData, (shop) => shop?.shopUrls);
export const activeShopAnalytics = createSelector(activeShopData, (shop) => shop?.shopAnalytics);

export const adminUsers = createSelector(usersData, activeShopId, (users, shopId) => {
	if (!shopId) return [];
	return users.filter((user) => {
		const userAccess = user.access[shopId];
		if (!userAccess) return false;
		const isRentleSupport = isRentleSupportFromUser(user, shopId);
		return userAccess && isAdmin(userAccess.roles) && !isRentleSupport;
	});
});

export const shopFeatures = createSelector(activeShopReadOnly, (readOnly) => readOnly?.features);
export const shopBilling = createSelector(activeShopReadOnly, (readOnly) => readOnly?.billing);

export const shopCustomDomainDetails = createSelector(
	activeShopReadOnly,
	(readOnly) => readOnly?.customDomains,
);

export const paymentMethodsStoredInAdyen = createSelector(
	adyenPaymentMethodsData,
	(data): ICheckout.StoredPaymentMethod[] => data?.storedPaymentMethods ?? [],
);

export const paymentMethodsStoredInAdyenById = createSelector(
	paymentMethodsStoredInAdyen,
	(methods) => {
		return hashByUniqueField(methods, 'id');
	},
);

export const shopPaymentCards = createSelector(
	activeShopReadOnly,
	paymentMethodsStoredInAdyen,
	(readOnly, storedPaymentMethods) => {
		const cardInformation = readOnly?.billing.cardInformation ?? [];
		return cardInformation.filter((card) =>
			storedPaymentMethods.some((method) => method.id === card.cardToken),
		);
	},
);

export const shopPlan = createSelector(shopFeatures, (features) => features?.plan.plan);
export const shopPurchasedPlan = createSelector(shopFeatures, (features) => features?.plan);

export const shopBillingCycle = createSelector(
	shopFeatures,
	(features) => features?.plan.subscription?.billingCycle,
);

export const hasCustomBilling = createSelector(
	activeShopReadOnly,
	(readOnly) => readOnly?.billing?.billingMethod === 'custom',
);

export const locationSubscriptions = createSelector(
	shopFeatures,
	(features) => features?.plan.subscription?.locations ?? {},
);

export const hasUpcomingBillingDateInPast = createSelector(
	locationSubscriptions,
	(locationSubscriptions) => {
		return !!locationSubscriptions
			? chain(locationSubscriptions)
					.values()
					.minBy('upcomingBillingDate')
					.thru((locationSubscription) =>
						moment().isAfter(moment(locationSubscription?.upcomingBillingDate)),
					)
					.value()
			: false;
	},
);

export const enabledFeatures = createSelector(
	activeShopReadOnly,
	(readOnly) => readOnly?.features.enabledFeatures ?? [],
);

export const hiddenFeatures = createSelector(
	activeShopReadOnly,
	(readOnly) => readOnly?.features.hiddenFeatures ?? [],
);

export const planFeatures = createSelector(shopPlan, (shopPlan) => getFeaturesInPlan(shopPlan));

export const allShopFeatures = createSelector(shopFeatures, (shopFeatures) =>
	getShopFeatures(shopFeatures),
);

export const activeShopVisitingAddress = createSelector(
	activeShopPublicInfo,
	(info) => info?.visitingAddress,
);

export const activeShopBaseURL = createSelector(activeShopUrls, (shopUrls) => {
	return shopUrls?.storeUrl ?? '';
});

export const activeShopOnlineURL = createSelector(activeShopUrls, (shopUrls) =>
	getOnlineStoreUrl({
		env: getClientEnv(),
		shopUrlDoc: shopUrls,
		locationId: null,
	}),
);

export const activeShopCheckInURL = createSelector(
	activeShopBaseURL,
	(shopBaseURL) => `https://${urlPrefix}store.rentle.shop/${shopBaseURL}`,
);

export const activeShopAdditionalLocations = createSelector(
	activeShopPublicInfo,
	(info) => info?.shopLocations ?? [],
);

export const activeShopDefaultLanguage = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.defaultLanguage ?? 'en',
);

export const activeShopLanguages = createSelector(
	activeShopPublicInfo,
	(publicInfo): Languages[] => publicInfo?.languages ?? ['en'],
);

export const activeShopCountry = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.country ?? 'FI',
);

export const activeShopImages = createSelector(activeShopInfo, (shopInfo) =>
	!!shopInfo?.images ? [...shopInfo.images].reverse() : [],
);

export const getActivePaymentMethods = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.activePaymentMethods ?? [],
);

export const getManualPaymentMethods = createSelector(
	activeShopInfo,
	(shopInfo): ManualPaymentMethodDetails[] => shopInfo?.manualPaymentMethods ?? [],
);

export const getActiveManualPaymentMethodOptions = createSelector(
	getActivePaymentMethods,
	(activePaymentMethods): ShopManualOnlinePaymentMethodObject[] => {
		const payInStoreOption: FixedManualPaymentMethodObject = { id: 'PAY_STORE' };
		const activeManualPaymentMethods = activePaymentMethods.filter(
			(m): m is ShopManualOnlinePaymentMethodObject =>
				isCustomShopPaymentMethodObject(m) || m.id === 'PAY_STORE',
		);
		return activeManualPaymentMethods.some((m) => m.id === 'PAY_STORE')
			? activeManualPaymentMethods
			: [payInStoreOption, ...activeManualPaymentMethods];
	},
);

export const shopDepositPaymentMethods = createSelector(getActivePaymentMethods, (paymentMethods) =>
	paymentMethods.map((m) => m.id).filter(isDepositPaymentMethod),
);

export const shopPrimaryPaymentMethod = createSelector(shopPaymentCards, (cards) =>
	getShopPrimaryPaymentMethod(cards),
);

export const shopHasPrimaryPaymentMethod = createSelector(
	shopPrimaryPaymentMethod,
	(method) => !!method,
);

export const customPosPaymentMethods = createSelector(
	activeShopInfo,
	(shop) => shop?.customPaymentMethods ?? [],
);

export const getShopDateFormat = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.dateFormat ?? getDefaultDateObject(),
);

export const getShopUnitSystem = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.unitSystem ?? 'METRIC',
);

export const getShopTimeZone = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.timeZone ?? getDefaultTimezone(),
);

export const getShopCurrency = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.currency ?? getDefaultCurrencyObject(),
);

export const getShopTaxExcluded = createSelector(
	activeShopPublicInfo,
	(publicInfo) => !!publicInfo?.taxExcluded,
);

export const getOnlinePaymentsEnabled = createSelector(getActivePaymentMethods, (paymentMethods) =>
	isOnlinePaymentsInUse(paymentMethods),
);

export const getUseAsPos = createSelector(
	activeShopInfo,
	(shopInfo) => shopInfo?.useAsPos ?? false,
);

export const activeShopAllLocations = createSelector(activeShopPublicInfo, (publicInfo) => {
	return publicInfo ? getShopLocationsWithContact(publicInfo) : [];
});

export const activeShopAdminLocations = createSelector(activeShopPublicInfo, (publicInfo) => {
	return publicInfo ? getShopLocationsWithContact(publicInfo, 'ADMIN') : [];
});

export const allShopLocationsById = createSelector(activeShopAllLocations, (locations) =>
	hashByUniqueField(locations, 'id'),
);

export const allShopLocationsCount = createSelector(
	activeShopAllLocations,
	(locations) => locations.length,
);

export const activeShopLanguagesAvailable = createSelector(activeShopLanguages, (languages) =>
	languages.filter((lang) => adminLanguages.some((defLang) => defLang === lang)),
);

export const activeShopVatTable = createSelector(activeShopInfo, (shopInfo) =>
	shopInfo?.vatTable ? shopInfo.vatTable : getDefaultVatTable(),
);

export const activeShopDefaultVat = createSelector(
	activeShopVatTable,
	(vatTable) => vatTable.defaultVat,
);

export const activeShopPricingTables = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.pricingTables ?? {},
);

export const activeShopTerms = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.termsOfService,
);

export const useCategoryTermsOverwrite = createSelector(
	activeShopPublicInfo,
	(publicInfo) => !!publicInfo?.legacySettings?.useCategoryTermsOverwrite,
);

export const activeShopPrivacyPolicy = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.privacyPolicy,
);

export const activeShopName = createSelector(
	activeShopPublicInfo,
	(publicInfo) => publicInfo?.name,
);

export const activeShopLocationUrls = createSelector(
	activeShopUrls,
	activeShopVisitingAddress,
	(shopUrls, visitingAddress) => {
		const mainStoreUrl = shopUrls?.storeUrl;
		const mainLocationId = visitingAddress?.id;
		const locationUrls = { ...(shopUrls?.locationUrls ?? {}) };
		if (
			!!mainStoreUrl &&
			!!mainLocationId &&
			!Object.values(locationUrls).includes(mainLocationId)
		) {
			locationUrls[mainStoreUrl] = mainLocationId;
		}
		return locationUrls;
	},
);

export const activeShopLocationUrlsById = createSelector(activeShopLocationUrls, (locationUrls) =>
	Object.entries(locationUrls ?? {}).reduce((result, [locationName, locationId]) => {
		result[locationId] = locationName;
		return result;
	}, {}),
);

export const activeShopPaymentMethodCommission = createSelector(
	activeShopReadOnly,
	(readOnly) => readOnly?.paymentMethodCommission,
);

export const skidataInfo = createSelector(
	activeShopReadOnly,
	(readonly) => readonly?.integrations?.SKIDATA,
);

export const activeShopMainLocationId = createSelector(
	activeShopVisitingAddress,
	(mainLocation) => {
		return mainLocation!.id;
	},
);

export const activeShopMainLocationId_SAFE = createSelector(
	activeShopVisitingAddress,
	(mainLocation) => {
		return mainLocation?.id;
	},
);

export const customAccountingGroups = createSelector(activeShopInfo, (shopInfo) => {
	return shopInfo?.plugins?.customAccountingGroups ?? [];
});

export const userIds = createSelector(activeShopInfo, (shopInfo) => {
	return Object.keys(shopInfo?.users ?? {});
});

export const shopDeliveryOptions = (state: ReduxState) => state.shop.deliveryOptions.data ?? [];
export const shopDeliveryOptionsById = createSelector(shopDeliveryOptions, (options) => {
	return hashByUniqueField(options, 'id');
});

export const activeLocationDeliveryOptions = createSelector(
	shopDeliveryOptions,
	(state: ReduxState) => userActiveLocationId(state),
	(allDeliveryOptions, userActiveLocationId) => {
		return userActiveLocationId
			? allDeliveryOptions.filter((deliveryOption) =>
					deliveryOption.locationIds.includes(userActiveLocationId),
			  )
			: [];
	},
);

export const maxDeliveryOptionOrderIndex = createSelector(
	activeLocationDeliveryOptions,
	(options) => Math.max(...options.map((c) => c.orderIndex), 0),
);

export const activeLocationDeliveryOptionsById = createSelector(
	activeLocationDeliveryOptions,
	(options) => hashByUniqueField(options, (option) => option.id),
);

export const activeShopCarriers = (state: ReduxState) => state.shop.carriers.data ?? [];

export const shopDinCollectionMethod = createSelector(activeShopInfo, (shopInfo) => {
	return shopInfo?.dinCollectionMethod ?? null;
});

export const useDinVariation = createSelector(activeShopInfo, (shopInfo) => {
	return !!shopInfo?.dinCollectionMethod && !!shopInfo?.plugins?.din?.useDinVariation;
});

export const isDeliveryOnlyStore = createSelector(
	(state: ReduxState) => userActiveLocationId(state),
	activeShopPublicInfo,
	(userActiveLocationId, publicInfo) => {
		return (
			(userActiveLocationId
				? publicInfo?.hideStorePickup?.location?.[userActiveLocationId]
				: false) ?? false
		);
	},
);

export const onlineThemes = createSelector(
	activeShopData,
	(shop) => shop?.publicInfo?.themesByLocationId?.ONLINE,
);

export const activeLocationEmailSettings = createSelector(
	(state: ReduxState) => userActiveLocationId(state),
	emailSettingsData,
	(locationId, allEmailSettings) =>
		!!locationId ? allEmailSettings.find((s) => s.locationId === locationId) : undefined,
);

export const activeLocationCustomizerThemeId = createSelector(
	(state: ReduxState) => userActiveLocationId(state),
	onlineThemes,
	(locationId, themes) => (!!locationId ? themes?.[locationId] ?? undefined : undefined),
);

export const shopTheme = createSelector(activeShopData, (shop) => {
	const branding = shop?.publicInfo.customBranding ?? DEFAULT_BRANDING;
	return getThemeWithBranding(Themes.WhiteLabelLight, branding);
});

export const shopLogo = createSelector(activeShopPublicInfo, (shopInfo) => {
	return shopInfo?.logoImgUrl;
});

export const shopWebsiteUrl = createSelector(activeShopPublicInfo, (shopInfo) => {
	return shopInfo?.websiteUrl;
});

export const openingHoursByStore = createSelector(
	activeShopAllLocations,
	openingHoursData,
	(locations, openingHoursDocs) => {
		return locations.reduce((result, location) => {
			result[location.id] = getOpeningHoursForStore({
				openingHoursDocs,
				storeId: location.id,
			});

			return result;
		}, {} as ById<OpeningHours>);
	},
);

export const merchantDefaultOpeningHours = createSelector(openingHoursData, (openingHoursDocs) => {
	return getMerchantDefaultOpeningHours({ openingHoursDocs });
});

export const merchantDefaultOpeningHours_unsafe = createSelector(
	openingHoursData,
	(openingHoursDocs) => {
		return openingHoursDocs.find((o) => o.isDefault);
	},
);

export const storesUsingDefaultHours = createSelector(
	openingHoursData,
	activeShopAllLocations,
	(openingHoursDocs, locations) => {
		if (!openingHoursDocs.some((o) => o.isDefault)) return [];
		return locations.filter((l) => !openingHoursDocs.find((doc) => doc.storeId === l.id));
	},
);

export const activeStoreOpeningHours = createSelector(
	(state: ReduxState) => userActiveLocationId(state),
	openingHoursData,
	(storeId, openingHoursDocs) => {
		if (!storeId) return DEFAULT_OPENING_HOURS;
		return getOpeningHoursForStore({
			openingHoursDocs,
			storeId,
		});
	},
);

export const activeStoreOpeningHours_unsafe = createSelector(
	(state: ReduxState) => userActiveLocationId(state),
	openingHoursData,
	(storeId, openingHoursDocs) => {
		if (!storeId) return null;
		return openingHoursDocs.find((doc) => doc.storeId === storeId) ?? null;
	},
);

export const activeShopMerchantProfile = createSelector(activeShopAnalytics, (analytics) => {
	return analytics?.merchantProfile;
});
