import { CssBaseline, ThemeProvider } from '@mui/material';
import { Route, Switch } from 'react-router-dom';

import { RentleLight } from 'common/styles/themes';
import Layout from 'components/Layout';
import { Routes } from 'routing';

import ListingsHome from './Home';
import CreateListing from './ListingCreate';

const ListingsRouter = () => {
	return (
		<ThemeProvider theme={RentleLight}>
			<CssBaseline />
			<Layout header={{ variant: 'sidebar' }}>
				<Switch>
					<Route path={Routes.LISTINGS_CREATE} component={CreateListing} />
					<Route path={Routes.ROOT} component={ListingsHome} />
				</Switch>
			</Layout>
		</ThemeProvider>
	);
};

export default ListingsRouter;
