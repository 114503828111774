import { createQueryKeys } from '@lukemorales/query-key-factory';

import { api } from 'common/frontend/api';
import { DeliveryLocation } from 'common/types';
import { chunks } from 'common/utils/arrays';

import { queryClient } from './client';
import { createUseMutation, createUseQuery, fnQueryKeys, queryUpdater } from './utils';

const queries = {
	listByStore: async (args: { storeId: string }): Promise<DeliveryLocation[]> => {
		const { storeId } = args;

		return api().deliveryLocations.byStoreId(storeId).get();
	},
	listByMerchant: async (args: { merchantId: string }): Promise<DeliveryLocation[]> => {
		const { merchantId } = args;

		return api().deliveryLocations.byMerchantId(merchantId).get();
	},
	listByIds: async (args: {
		storeId: string;
		locationIds: string[] | 'all';
	}): Promise<DeliveryLocation[]> => {
		const { storeId, locationIds } = args;

		if (locationIds === 'all' || locationIds.length === 0) {
			return api().deliveryLocations.byStoreId(storeId).get();
		} else {
			const batches = chunks(locationIds, 9);
			return Promise.all(
				batches.map((ids) => api().deliveryLocations.byIds(ids).get()),
			).then((res) => res.flat());
		}
	},
};

const mutations = {
	create: async (args: { data: DeliveryLocation }): Promise<void> => {
		const { data } = args;

		return api().deliveryLocations.doc(data.id).set(data);
	},
	update: async (args: {
		id: string;
		data: Omit<DeliveryLocation, 'id' | 'merchantId' | 'storeId'>;
	}): Promise<void> => {
		const { id, data } = args;

		return api().deliveryLocations.doc(id).update(data);
	},
	remove: async (args: { id: string }): Promise<void> => {
		const { id } = args;

		return api().deliveryLocations.doc(id).delete();
	},
};

const queryKeys = createQueryKeys('deliveryLocations', {
	listByStore: fnQueryKeys(queries.listByStore),
	listByMerchant: fnQueryKeys(queries.listByMerchant),
	listByIds: fnQueryKeys(queries.listByIds),
});

export const listByStore = createUseQuery(queries.listByStore, queryKeys.listByStore);
export const listByMerchant = createUseQuery(queries.listByMerchant, queryKeys.listByMerchant);
export const listByIds = createUseQuery(queries.listByIds, queryKeys.listByIds);

export const create = createUseMutation(mutations.create, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const update = createUseMutation(mutations.update, {
	onMutate: async ({ id, data }) => {
		queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater<DeliveryLocation>({
				condition: (item) => item.id === id,
				updates: data,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const remove = createUseMutation(mutations.remove, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});
