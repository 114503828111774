import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';

import { OnboardingStepItem } from '../types';
import { getVideoOnboardingStepItem } from '../utils';

export const INTRO_VIDEO_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'INTRO_VIDEO',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	INTRO_VIDEO: {
		all: {
			action: getVideoOnboardingStepItem({
				url:
					'https://www.youtube.com/embed/CRkM12TcERc?modestbranding=1&plyasinline=0&rel=0&widget_referrer=admin.rentle.io',
				completeOnClick: true,
			}),
			content: {
				title: t(
					'homeView.onboarding.seeWhatYoullBeAbleToDoWithRentle',
					'See what you’ll be able to do with Rentle',
				),
				descriptions: [
					t(
						'homeView.onboarding.introVideo.description_1',
						'Welcome to Rentle! We’re excited to help you power up your business.',
					),
					t(
						'homeView.onboarding.introVideo.description_2',
						'We recommend to watch our 7 min introduction video to get an overview of all the things you can achieve with Rentle.',
					),
				],
				cta: t('common:actions.getStarted', 'Get started'),
			},
			id: 'INTRO_VIDEO',
			type: 'row',
		},
	},
});
