import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import {
	BillableEvent,
	BillingCreditEvent,
	BillingData,
	CurrencyConversions,
	Invoice,
} from 'common/modules/billing';

import { createBasicCollectionOperations, createBasicDocOperations } from '../utils';

export const getBillingQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.billingCollection;
	const dataUpdater = undefined;

	return {
		data: createBasicDocOperations<BillingData>(collectionPath, dbRefs, 'data', dataUpdater),
		events: getBillingEventQueries(dbRefs),
		invoices: getBillingInvoiceQueries(dbRefs),
		currencies: getCurrencyConversionsQueries(dbRefs),
		credits: getBillingCreditQueries(dbRefs),
	};
};

const getBillingEventQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.billingEventsCollection;
	const dataUpdater = undefined;

	return {
		doc: (id: string) => ({
			...createBasicDocOperations<BillableEvent>(collectionPath, dbRefs, id, dataUpdater),
		}),
		...createBasicCollectionOperations<BillableEvent>(collectionPath, dbRefs, dataUpdater),
	};
};

const getBillingCreditQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.billingCreditsCollection;
	const dataUpdater = undefined;

	return {
		doc: (id: string) => ({
			...createBasicDocOperations<BillingCreditEvent>(collectionPath, dbRefs, id, dataUpdater),
		}),
		...createBasicCollectionOperations<BillingCreditEvent>(collectionPath, dbRefs, dataUpdater),
	};
};

const getBillingInvoiceQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.billingInvoicesCollection;
	const dataUpdater = undefined;

	return {
		doc: (id: string) => ({
			...createBasicDocOperations<Invoice>(collectionPath, dbRefs, id, dataUpdater),
		}),
		...createBasicCollectionOperations<Invoice>(collectionPath, dbRefs, dataUpdater),
	};
};

const getCurrencyConversionsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.currencyConversions;
	const dataUpdater = undefined;

	return {
		doc: (id: string) => ({
			...createBasicDocOperations<CurrencyConversions>(collectionPath, dbRefs, id, dataUpdater),
		}),
		...createBasicCollectionOperations<CurrencyConversions>(collectionPath, dbRefs, dataUpdater),
	};
};
