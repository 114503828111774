import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.analytics.name', 'Analytics'),
	subHeader: t(
		'common:blocks.details.analytics.subHeader',
		'Get insights on your online store visitors',
	),
	description: t(
		'common:blocks.details.analytics.description',
		'Integrate your store with Google Analytics 4 (GA4), Universal Analytics (UA) and Google Tag Manager (GTM) to track your online store data. Create reports and learn more about your customer acquisition, user behaviour and conversion paths',
	),
	supportArticle: SupportArticles.ANALYTICS,
	uninstallNotice: t(
		'common:blocks.details.analytics.uninstallNotice',
		'By uninstalling Analytics, you will no longer be able to manage and collect analytics from Rentle',
	),
});

export default details;
