/**
 * Helper class for managing Firebase listener instances
 */

interface Listener<T extends string> {
	id: T;
	listener: () => void;
	key: string;
}

export class ListenerManager<T extends string> {
	private listeners: Listener<T>[] = [];

	update(listener: Listener<T>): boolean {
		const existing = this.listeners.find((l) => l.id === listener.id);

		if (existing) {
			this.clear(existing.id);
		}

		this.add(listener);
		return true;
	}

	private add(listener: Listener<T>) {
		this.listeners.push(listener);
	}

	clear(id: string) {
		this.listeners = this.listeners.filter((l) => {
			if (l.id === id) {
				l.listener();
				return false;
			}
			return true;
		});
	}

	clearAll() {
		this.listeners.forEach((l) => {
			l.listener();
		});
		this.listeners = [];
	}
}
