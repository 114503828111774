import React, { useEffect, useState } from 'react';

import {
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { get, groupBy } from 'lodash';
import { RiInformationLine } from 'react-icons/ri';
import { useRegisterContext } from 'views/Register/RegisterContext';
import { GET_STARTED_STEP_NAMES, getNameFromSegmentType } from 'views/Register/utils';

import {
	IndustryCategory,
	IndustrySegment,
	IndustrySegments,
} from 'common/services/analytics/tractionAnalytics';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';
import { getGroupedIndustryCategoryOptions } from 'utils/registerUtils';
import { Routes, push } from 'routing';

import { useRegistration } from '../../../useRegistration';
import RegistrationFooter from '../RegistrationFooter';
import IndustrySegmentRow from './IndustrySegmentRow';

export interface Props {
	onBack: () => void;
}
const RegistrationIndustry = (props: Props) => {
	const { onBack } = props;
	const { t } = useTranslation();
	const [state, setStore] = useRegisterContext((state) => state);
	const [showCreatingStore, setShowCreatingStore] = useState(false);
	const {
		industryCategories: selectedIndustryCategories,
		registrationStatus,
		startWithTemplate,
	} = state;
	const { handleEnterStore } = useRegistration();
	const { logAnalyticsEvent, logRegisterActionEvent } = useAnalytics();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const industryCategories = getGroupedIndustryCategoryOptions();

	const industryCategoriesBySegment = groupBy(industryCategories, (industry) => {
		return get(industry, 'segment', IndustrySegments.OTHER);
	});

	const hasMultipleSegments = Object.keys(industryCategoriesBySegment).length > 1;

	useEffect(() => {
		if (registrationStatus === 'done') {
			setShowCreatingStore(false);
		}
	}, [registrationStatus]);

	const handleIndustryCategoriesChange = async (value: IndustryCategory[]) => {
		logRegisterActionEvent('industry_categories', value);
		setStore((store) => {
			store.dirty = true;
			store.industryCategories.values = value;
		});
	};

	const handleStartWithTemplateChange = async (value: boolean) => {
		logRegisterActionEvent('start_with_template', value);
		setStore((store) => {
			store.dirty = true;
			store.startWithTemplate = value;
		});
	};

	const handleMainCtaClick = () => {
		if (registrationStatus !== 'done') {
			setShowCreatingStore(true);
			return;
		}
		logRegisterActionEvent('continue', {
			step: GET_STARTED_STEP_NAMES.INDUSTRY,
		});
		logAnalyticsEvent({
			name: 'enter_store',
			optionalServices: {
				userflow: true,
				gtm: true,
			},
		});
		handleEnterStore();
		push(Routes.HOME_VIEW);
	};

	const renderTemplateSelect = () => {
		return (
			<FormGroup sx={{ mb: isMobile ? 2 : 1 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<FormControlLabel
						control={
							<Checkbox
								checked={startWithTemplate}
								onChange={(e) => handleStartWithTemplateChange(e.target.checked)}
								sx={{ marginLeft: isMobile ? '-12px' : undefined }}
							/>
						}
						sx={{ m: 0, textAlign: isMobile ? 'left' : 'center' }}
						label={t(
							'getStarted.storeSetupBasedOnSelections',
							`I'd like my store to be set up based on my selections`,
						)}
					/>
					<Tooltip
						title={
							<Typography variant="caption">
								{t(
									'getStarted.startWithStoreTemplateTooltip',
									`Explore and launch faster with a store template tailored for your experience. Don't worry, it's easy to remove later. Unchecking the box is advised only for users already familiar with Rentle.`,
								)}
							</Typography>
						}
						sx={{ mr: isMobile ? '-8px' : undefined }}
					>
						<IconButton>
							<RiInformationLine fontSize="small" />
						</IconButton>
					</Tooltip>
				</Stack>
			</FormGroup>
		);
	};

	const renderMainCTA = () => {
		return (
			<Stack>
				{!isMobile && renderTemplateSelect()}
				{showCreatingStore ? (
					<Button
						variant="text"
						disabled
						sx={{
							maxWidth: 400,
							mx: 'auto',
							textTransform: 'none',
						}}
						fullWidth={!isMobile}
					>
						{t('getStarted.creatingYourStore', 'Creating your store...')}
					</Button>
				) : (
					<Button
						sx={{
							maxWidth: 400,
							mx: 'auto',
							mt: 2,
						}}
						fullWidth={!isMobile}
						disabled={!selectedIndustryCategories.values.length}
						variant="black"
						onClick={handleMainCtaClick}
					>
						{t('getStarted.enterMyStore', 'Enter my store')}
					</Button>
				)}
			</Stack>
		);
	};

	return (
		<>
			<Container sx={{ pb: 22, zIndex: 1 }}>
				{Object.entries(industryCategoriesBySegment).map(([segment, industries], index) => (
					<IndustrySegmentRow
						value={segment as IndustrySegment}
						title={
							hasMultipleSegments
								? getNameFromSegmentType(segment as IndustrySegment, t)
								: undefined
						}
						options={industries}
						key={`${segment}-${index}`}
						index={index}
						selectedOptions={selectedIndustryCategories.values}
						onChange={handleIndustryCategoriesChange}
					/>
				))}
			</Container>
			<RegistrationFooter
				mainCTA={{
					alignment: isMobile ? 'right' : 'center',
					component: renderMainCTA(),
				}}
				options={{
					back: {
						onClick: onBack,
					},
					...(isMobile && { topContent: { component: renderTemplateSelect() } }),
				}}
			/>
		</>
	);
};

export default RegistrationIndustry;
