import { TFunction } from 'i18next';
import {
	cloneDeepWith,
	isArray,
	isEmpty,
	isEqual,
	isFunction,
	isObject,
	isUndefined,
	transform,
	xor,
} from 'lodash';
import moment from 'moment-timezone';

import { Address } from 'common/modules/atoms/addresses';
import { CountryCode, getDefaultTaxRateFromCountry } from 'common/modules/atoms/countries';
import countryCodes from 'common/services/countryCodes.json';
import { CurrencyObject, Location, PhoneObject, TermsDocument, VatTable } from 'common/types';
import { sleep } from 'common/utils/async';

export const isDevEnv = () => {
	const isDevelopmentProjectId = (projectId: string) => {
		if (projectId === 'rentle-prod' || projectId === 'rentle-beta') {
			return false;
		}
		return true;
	};
	/**
	 * All frontends use REACT_APP_ENV as env variables to
	 * determine in which environment they are running.
	 */
	if (!!process.env.REACT_APP_ENV) {
		return process.env.REACT_APP_ENV !== 'production';
	}
	/**
	 * The way to check environment in server-side Firebase functions
	 * is to check FIREBASE_CONFIG project ID, and determine the
	 * environment from the project ID.
	 */
	if (typeof window === 'undefined' && !!process.env.FIREBASE_CONFIG) {
		try {
			const projectId = JSON.parse(process.env.FIREBASE_CONFIG).projectId;
			return isDevelopmentProjectId(projectId);
		} catch (e) {}
	}
	/**
	 * In our Core backend we use RENTLE_ENV to determine
	 * the environment.
	 */
	if (typeof window === 'undefined' && !!process.env.RENTLE_ENV) {
		return process.env.RENTLE_ENV !== 'production';
	}
	/**
	 * These projectId checks act as fallback to determine the environment
	 * based on the project ID in Google Cloud environment. The possible
	 * GCLOUD_PROJECT and GCP_PROJECT env vars are set by Google Cloud.
	 */
	const projectId = process.env.GCLOUD_PROJECT ?? process.env.GCP_PROJECT;
	if (projectId) {
		return isDevelopmentProjectId(projectId);
	}
	return true;
};

export const isLocalEnv = () => {
	return (
		process.env.REACT_APP_ENV === 'local' ||
		process.env.FUNCTIONS_EMULATOR === 'true' ||
		(process.env.FUNCTIONS_EMULATOR as string | boolean) === true ||
		process.env.RENTLE_ENV === 'local'
	);
};

export const getDefaultVatTable = (country?: CountryCode) => {
	const defaultVat = !!country ? getDefaultTaxRateFromCountry(country) : 24;
	const table: VatTable = {
		vatValues: [{ value: defaultVat, description: '' }],
		defaultVat,
	};
	return table;
};

export const showAddressFields = (shopId: string) => {
	if (
		shopId === '4cGKLxdv6O68yP0Y68X8' || // Vuokatti
		shopId === 'WhIllxGPy18BbXe0H2DS' || // Toomasshop
		shopId === 'Ja52y9V4izigAk3UeXLx' // Laajis
	) {
		return true;
	}
	return false;
};

export const formatPrice = (cents: number, noZeroDecimals?: boolean) => {
	const formattedPrice = (cents / 100)
		.toFixed(2)
		.split('')
		.reduce(
			(pre, cur, i, array) =>
				i < array.length - 6 && (array.length - i - 1) % 3 === 0 ? pre + cur + ' ' : pre + cur,
			'',
		);

	/**
	 * Optionally remove .00 from the end of formatted prices
	 */
	if (noZeroDecimals) {
		const decimals = formattedPrice.split('.').pop();
		if (decimals?.replace(/^0*$/g, '') === '') {
			return formattedPrice.split('.')[0];
		}
	}

	return formattedPrice;
};

const currencyIsString = (currency?: CurrencyObject | string): currency is string => {
	if (typeof currency === 'string') {
		return true;
	}
	return false;
};

export const centsToMajorUnits = (cents: number): number => {
	return cents / 100;
};

export const getPricingString = (
	cents: number,
	currency?: CurrencyObject | string,
	noZeroDecimals?: boolean,
) => {
	const isNegative = cents < 0;
	const pricingString = isFinite(cents) ? formatPrice(Math.abs(cents), noZeroDecimals) : '∞';
	if (currencyIsString(currency)) {
		const baseString = `${pricingString} ${currency}`;
		return isNegative ? `-${baseString}` : baseString;
	}

	const spacing = currency?.noSpacing ? '' : ' ';
	const baseString = currency
		? currency.position === 'prefix'
			? `${currency.displayAs}${spacing}${pricingString}`
			: `${pricingString}${spacing}${currency.displayAs}`
		: pricingString;

	return isNegative ? `-${baseString}` : baseString;
};

export const getPricingStringPerDuration = (
	pricingString: string,
	duration: 'month' | 'year',
	t: TFunction,
) => {
	switch (duration) {
		case 'month':
			return t('common:times.pricePerMonth', {
				price: pricingString,
				defaultValue: '{{price}} /month',
			});
		case 'year':
		default:
			return pricingString;
	}
};

export const getSampleTerms = (): TermsDocument => {
	const sampleTosUrl =
		'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/public%2Fsample_tos.pdf?alt=media&token=7f8bab7b-99b7-4e43-bf48-134d12de2a95';
	return {
		name: 'Sample TOS',
		url: sampleTosUrl,
		verified: false,
	};
};

export const getAddressStringFromLocation = (
	location: Location | Address,
	opts?: {
		includeCountry?: boolean;
	},
) => {
	const part1 = location.address;
	const part2 = [location.zipCode, location.city].filter((i) => !!i).join(' ');
	const part3 = opts?.includeCountry ? location.country : '';
	return [part1, part2, part3].filter((i) => !!i).join(', ');
};

export const notNull = <T>(value: T | null): value is T => {
	return value !== null;
};

export const notUndefined = <T>(value: T | undefined): value is T => {
	return value !== undefined;
};

export const notNullish = <T>(value: T | null | undefined): value is T => {
	return value !== null && value !== undefined;
};

export const notFalsey = <T>(value: T | null | undefined | false): value is T => {
	return !!value;
};

export const isNonEmpty = <T>(arr: Array<T>): arr is [T, ...T[]] => {
	return arr.length > 0;
};

/**
 * Map language code to country code, [languageCode]: countryCode
 */
export const countryCodeFromLang: { [languageCode: string]: string } = {
	fi: 'FI',
	ru: 'RU',
	de: 'DE',
	sk: 'SK',
	sv: 'SE',
	sl: 'SI',
};

export const getCountryCodeFromLang = (lang: string, shopCountry: string = '') =>
	lang !== 'en' && countryCodeFromLang[lang] ? countryCodeFromLang[lang] : shopCountry;

export const getPhoneObjectForCountry = (country: string): PhoneObject | null => {
	if (!country) {
		return null;
	}
	const countryObject = countryCodes.find(
		(countryObj) => countryObj.code === country.toUpperCase(),
	);
	if (!countryObject) {
		return null;
	}
	return {
		areaCode: countryObject.dial_code,
		countryCode: countryObject.code,
		numberWithoutCode: '',
	};
};

export const getPhoneObjectForDialCode = (dialCode: string): PhoneObject | null => {
	const countryObject = countryCodes.find((countryObj) => countryObj.dial_code === dialCode);
	if (!countryObject) {
		return null;
	}
	return {
		areaCode: countryObject.dial_code,
		countryCode: countryObject.code,
		numberWithoutCode: '',
	};
};

/**
 * Automatically handles different scenarios for selector with multiple values
 *
 * @param {any[]} values
 * @param {any[]} prevValues
 * @param {any} selectAllObjects
 * @param {any} hideAllObjects
 * @param {number} maxLength
 * @returns
 */
export const getValueForMultipleSelector = (
	values: any[],
	prevValues: any[],
	selectAllObjects?: any,
	hideAllObjects?: any,
	maxLength?: number,
) => {
	if (
		(prevValues.includes(selectAllObjects) || !prevValues || !prevValues.length) &&
		values.includes(selectAllObjects) &&
		values.length > 1
	) {
		return values.filter((item) => item !== selectAllObjects);
	} else if (values.includes(selectAllObjects)) {
		return values.filter((item) => item === selectAllObjects);
	} else if (
		prevValues.includes(hideAllObjects) &&
		values.includes(hideAllObjects) &&
		values.length > 1
	) {
		return values.filter((item) => item !== hideAllObjects);
	} else if (values.includes(hideAllObjects)) {
		return values.filter((item) => item === hideAllObjects);
	}
	if (!values.length && hideAllObjects) {
		return [hideAllObjects];
	} else if (maxLength && values.length === maxLength) {
		return [selectAllObjects];
	} else {
		return values;
	}
};

/**
 * Generates array of objects from a generator function
 *
 * @param times number of returned items in the array
 * @param generator function for item generation
 * @param params array of parameters for the generator function
 */
export const getArrayOf = (times: number, generator: Function, params?: any[]) => {
	const result = [];

	for (let i = 0; i < times; ++i) {
		result.push(generator(...(params || [])));
	}

	return result;
};

/**
 * Generates dictionary of objects from a generator and keys from idGenerator
 *
 * @param times number of returned items in the dictionary
 * @param generator function for item generation
 * @param idGenerator function for key generation
 * @param params array of parameters for the generator function
 * @param paramsId array of parameters for the idGenerator function
 */
export const getDictionaryOf = (
	times: number,
	generator: Function,
	idGenerator: () => string,
	params?: any[],
	paramsId?: any[],
) => {
	let result = {
		[idGenerator()]: generator(),
	};

	for (let i = 0; i < times - 1; ++i) {
		result = {
			...result,
			[idGenerator()]: generator(),
		};
	}

	return result;
};

interface RetryOptions<T> {
	retryWaitBetween?: number;
	retryCount?: number;
	maxRetryCount: number;
	resultsToTriggerRetry?: T[];
}

/**
 * Wrapper for retrying functions in case they return error
 * @param fn function to execute
 * @param retryOptions options for retries
 */
export const withRetry = async <T>(fn: () => T, retryOptions: RetryOptions<T>): Promise<T> => {
	let {
		retryCount = 0,
		maxRetryCount,
		retryWaitBetween = 2000,
		resultsToTriggerRetry = [],
	} = retryOptions;
	try {
		if (retryCount > 0) {
			await sleep(retryWaitBetween);
		}
		retryCount++;
		const result = await fn();
		if (resultsToTriggerRetry.includes(result) && retryCount < maxRetryCount) {
			return withRetry(fn, {
				...retryOptions,
				retryCount,
			});
		}
		return result;
	} catch (e) {
		if (retryCount < maxRetryCount) {
			return withRetry(fn, {
				...retryOptions,
				retryCount,
			});
		} else {
			throw new Error(e);
		}
	}
};

export const getObjectValuesSum = (obj: object): number => {
	return Object.values(obj).reduce((tot, val) => tot + (isNaN(val) ? 0 : val), 0);
};

export const constrain = (value: number, min: number, max: number) => {
	return Math.min(Math.max(value, min), max);
};

export const editIdsArray = (props: {
	possibleIds: string[];
	currentIds: string[];
	valueToEdit: string;
	include: boolean;
}) => {
	const { possibleIds, currentIds, valueToEdit, include } = props;
	const newIds = (() => {
		if (currentIds.includes('ALL') && !include) {
			return possibleIds.filter((id) => id !== valueToEdit);
		}
		if (currentIds.includes(valueToEdit) && !include) {
			return currentIds.filter((id) => id !== valueToEdit);
		}
		if (!currentIds.includes(valueToEdit) && include) {
			return currentIds.concat(valueToEdit);
		}
		return currentIds;
	})();

	return isEmpty(xor(possibleIds, newIds)) ? ['ALL'] : newIds;
};

export const replaceUndefinedValuesWith = <T extends object, U, R extends object>(
	obj: T,
	replaceValue: U,
): R => {
	return Object.keys(obj).reduce((result, field) => {
		if (obj[field] && !Array.isArray(obj[field]) && typeof obj[field] === 'object') {
			result[field] = replaceUndefinedValuesWith(obj[field], replaceValue);
		} else if (obj[field] === undefined) {
			result[field] = typeof replaceValue === 'function' ? replaceValue() : replaceValue;
		} else {
			result[field] = obj[field];
		}
		return result;
	}, {} as R);
};

export const replaceParam = (
	url: string,
	path: string,
	params: { [key: string]: string | undefined },
) => {
	const pathArray = path.split('/');
	const urlArray = url.split('/');
	const updatedUrlArray = pathArray.map((pathItem, idx) => {
		const pathItemAsKey = pathItem.replace(':', '');
		return !!params[pathItemAsKey] ? params[pathItemAsKey] : urlArray[idx];
	});

	return updatedUrlArray.join('/');
};

export const buildUrl = (
	path: string,
	params: { [key: string]: string | undefined | boolean },
	strict: boolean = false,
): string => {
	const pathArray = path.split('/').filter(Boolean);

	return pathArray.reduce((result, pathItem) => {
		if (pathItem.charAt(0) === ':') {
			const pathItemAsKey = pathItem.substr(1);
			const value = params[pathItemAsKey];

			if (!value && strict)
				throw new Error('Provided parameter "path" is not matching attributes of "params".');

			return value ? `${result}/${value}` : result;
		} else if (pathItem.charAt(0) === '?') {
			const pathItemAsKey = pathItem.substr(1);
			const value = params[pathItemAsKey];

			if (!value && strict)
				throw new Error('Provided parameter "path" is not matching attributes of "params".');

			return value ? `${result}/${pathItemAsKey}` : result;
		} else {
			return `${result}/${pathItem}`;
		}
	}, '');
};

export const replaceUndefinedValues = <T extends object, U>(
	obj: T,
	replaceValue: U,
): {
	[K in keyof T]: T[K] | U;
} => {
	return cloneDeepWith(obj, (val) => (val === undefined ? replaceValue : undefined));
};

export const replaceValues = <T extends object, U, V>(
	obj: T,
	isValueToReplace: (val: U) => boolean,
	replaceWith: V,
): { [K in keyof T]: T[K] | V } =>
	cloneDeepWith(obj, (val) => (isValueToReplace(val) ? replaceWith : undefined));

export const removeUndefinedValues = <T extends object>(obj: any): T =>
	transform(obj, (r, v, k) => {
		if (isUndefined(v)) return;
		r[k] = isObject(v) ? removeUndefinedValues(v) : v;
	});

export const removeValue = <T extends object, V>(value: V | ((value: V) => boolean), obj: any): T =>
	transform<V, T>(obj, (r, v, k) => {
		if (isFunction(value) ? value(v) : isEqual(v, value)) return;
		r[k] = isObject(v) ? removeValue(value, v) : v;
	});

export const switchUnreachable = (x: never): never => {
	throw new Error('Should never get here');
};

export const fillWithLeadingZeros = (text: string, totalLength: number) => {
	while (text.length < totalLength) {
		text = '0' + text;
	}
	return text;
};

export const newId = (characters: number = 8): string => {
	// Alphanumeric characters
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let autoId = '';
	for (let i = 0; i < characters; i++) {
		autoId += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return autoId;
};

export const isRentleEmail = (email: string) => {
	return email.includes('@rentle.io');
};

export const isStoreNewerThanRelease = (createdAt: string, releaseDate: string): boolean => {
	return moment(createdAt).isAfter(moment(releaseDate));
};

export const removeFieldFromObject = (obj: object, key: string | number) => {
	if (!isObject(obj)) return obj;
	Object.keys(obj).forEach((i) => {
		if (i.toString() === key.toString()) {
			delete obj[key];
		} else if (typeof obj[i] == 'object') {
			removeFieldFromObject(obj[i], key);
		}
	});
	return obj;
};
