import { AmountObject, ById } from 'common/types';

import { PurchasedProductPricing } from '../common/types';

export interface Subscription {
	/**
	 * The next billing date for the subscription
	 */
	upcomingBillingDate: string;

	/**
	 * The previous billing date for the subscription
	 */
	previousBillingDate: string;
	/**
	 * Billing cycle in months
	 */
	billingCycle: number;
	/**
	 * Location specific subscription details
	 */
	locations: {
		[locationId: string]: LocationSubscription;
	};
}

export interface LocationSubscription {
	startDate: string;
	billingCycle: number;
	previousBillingDate: string;
	upcomingBillingDate: string;
	previousBillingInfo: {
		refundableAmount: AmountObject;
		hasRevenueDiscount: boolean;
	};
	pricing: PurchasedProductPricing;
}

export interface RevenueDiscountEligibility {
	byLocationId: ById<boolean>;
	currencyExchangeRatesDocId?: string;
}

export enum BillingCycle {
	MONTHLY = 1,
	YEARLY = 12,
}
