import { useCallback, useEffect, useRef, useState } from 'react';

import { QueryBuilder } from 'common/db/api/utils';

export interface UseQueryArgs<T extends object> {
	query: QueryBuilder<T> | null;
	initialLimit?: number;
	disableLimit?: boolean;
	queryFilter?: (resultItem: T) => boolean; // if defined, the server side limiting is disabled and done on the client instead
}

export interface UseQueryResult<T> {
	data: T[] | null;
	loading: boolean;
	error: string | null;
	count: number;
	limit: number;
	loadedLimit: number | null;
	initialLimit: number;
	setLimit: React.Dispatch<React.SetStateAction<number>>;
}

const INITIAL_LOAD_LIMIT = 100;

export const useQuery = <T extends object>(args: UseQueryArgs<T>): UseQueryResult<T> => {
	const { query, initialLimit = INITIAL_LOAD_LIMIT, disableLimit, queryFilter } = args;

	const [limit, setLimit] = useState<number>(initialLimit);
	const loadingTimeout = useRef<NodeJS.Timeout>();
	const [data, setData] = useState<{
		data: T[] | null;
		limit: number | null;
		loading: boolean;
		error: string | null;
	}>({
		data: null,
		loading: true,
		error: null,
		limit: null,
	});

	useEffect(() => {
		setLimit(initialLimit);
	}, [initialLimit]);

	const setListener = useCallback(() => {
		if (!!loadingTimeout.current) {
			clearTimeout(loadingTimeout.current);
		}
		setData((prev) => ({
			...prev,
			loading: true,
			error: null,
		}));
		if (!query) return;
		const listener = !!disableLimit || !!queryFilter ? query : query.limit(limit);
		const clearListener = listener.onSnapshot(
			(result, _, metadata) => {
				const isFromCache = metadata?.fromCache ?? false;

				const filteredData = !!queryFilter ? result.filter(queryFilter) : result;
				const limitedData =
					!!queryFilter && !disableLimit ? filteredData.slice(0, limit) : filteredData;

				setData({
					loading: isFromCache ? true : false,
					error: null,
					data: limitedData,
					limit: !!disableLimit ? null : limit,
				});

				if (isFromCache) {
					loadingTimeout.current = setTimeout(() => {
						setData((prev) => ({
							...prev,
							loading: false,
						}));
					}, 1000);
				}
			},
			(err) => {
				// eslint-disable-next-line no-console
				console.error(err);
				setData({
					loading: false,
					error: err.message,
					data: null,
					limit: null,
				});
			},
		);

		return () => {
			if (!!loadingTimeout.current) {
				clearTimeout(loadingTimeout.current);
			}
			clearListener();
		};
	}, [disableLimit, limit, query, queryFilter]);

	useEffect(() => {
		const clearListener = setListener();

		return () => {
			clearListener?.();
		};
	}, [setListener]);

	const count = data.data?.length ?? 0;

	return {
		data: data.data,
		loading: data.loading,
		error: data.error,
		count,
		limit,
		initialLimit,
		loadedLimit: data.limit,
		setLimit,
	};
};
