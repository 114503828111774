import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const WAIVER_DEFINITION: Record<'WAIVER', OnboardingStepDefinition> = {
	WAIVER: {
		plans: 'all',
		versions: {
			V1_TEMPLATE: ['BUSINESS_CONTACT_INFORMATION', 'PRIVACY_POLICY', 'TERMS_OF_SERVICE'],
			V1_NO_TEMPLATE: ['BUSINESS_CONTACT_INFORMATION', 'PRIVACY_POLICY', 'TERMS_OF_SERVICE'],
			V0: ['BUSINESS_CONTACT_INFORMATION', 'PRIVACY_POLICY', 'TERMS_OF_SERVICE'],
		},
	},
};
