import { GoogleAnalytics } from 'common/types';
import { isDevEnv } from 'common/utils/common';

export const NO_CATEGORY = 'no_category';
export const NO_SHOPID = 'no_shopId';
export const NO_ORDERID = 'no_orderId';
export const NO_SHOPNAME = 'no_shopName';
export const NO_VARIANTS = 'no_variants';

export const websiteGa4PropertyId = 'G-NQBPY7YWZW'; // Not in use for now, but good to have here for documentation / possible future use
export const websiteStreamId = 'G-60XYMJEV0J'; // Not in use for now, but good to have here for documentation / possible future use
export const websiteGtmId = 'GTM-5W4X7BC';
export const websiteGtmIdDev = 'GTM-TDH3268';

export const websiteGaTag: GoogleAnalytics = {
	GTM: [isDevEnv() ? websiteGtmIdDev : websiteGtmId],
};

export const GOOGLE_CONVERSION_VALUE = 250; // see https://docs.google.com/document/d/1__jTGvfZdB4jtngrJeOTr_lpJWTr1-yE0t-Lt_ODr-8/edit?usp=sharing for more info
