import { TFunction } from 'react-i18next';
import { IconType } from 'react-icons';

import { Plan } from 'common/modules/plans';
import {
	OnboardingAction,
	OnboardingItem as OnboardingItemType,
	OnboardingListVersion,
	OnboardingSection,
	ShopAnalytics,
} from 'common/services/analytics/tractionAnalytics';

/**

Represents the base properties of an onboarding step item.
*/

export type OnboardingStepItemBase = {
	id: OnboardingItemType;
	action: OnboardingStepItemAction;
	collapsable?: boolean;
	marksItemAsDone?: OnboardingAction[];
};

/**

Represents a card type onboarding step item.
*/

export type OnboardingStepItemCard = OnboardingStepItemBase & {
	type: 'card';
	content: {
		title: string;
		descriptions: string[];
		cta: string;
	};
};

/**

Represents a row type onboarding step item.
*/

export type OnboardingStepItemRow = OnboardingStepItemBase & {
	type: 'row';
	content: {
		title: string;
		descriptions?: string[];
		cta?: string;
	};
};

/**

Represents an onboarding step item, which can be either a card or a row.
*/

export type OnboardingStepItem = OnboardingStepItemCard | OnboardingStepItemRow;

/**

Represents the possible types of onboarding step items.
*/

export enum OnboardingStepItemTypes {
	ROUTE = 'ROUTE',
	FLOW = 'FLOW',
	LINK = 'LINK',
	VIDEO = 'VIDEO',
}

/**

Represents an action of an onboarding step item of type 'FLOW'.
*/

export type OnboardingStepItemActionFlow = {
	type: OnboardingStepItemTypes.FLOW;
	value: {
		flowId: string | undefined;
		completeOnClick?: boolean;
	};
};

/**

Represents an action of an onboarding step item of type 'LINK'.
*/

export type OnboardingStepItemActionLink = {
	type: OnboardingStepItemTypes.LINK;
	value: {
		toUrl: string | undefined;
		completeOnClick?: boolean;
	};
};

/**

Represents an action of an onboarding step item of type 'ROUTE'.
*/

export type OnboardingStepItemActionRoute = {
	type: OnboardingStepItemTypes.ROUTE;
	value: {
		routeTo: string;
		routeHash?: string;
		completeOnClick?: boolean;
	};
};

export type OnboardingStepItemActionVideo = {
	type: OnboardingStepItemTypes.VIDEO;
	value: {
		url: string;
		completeOnClick?: boolean;
	};
};

/**

Represents an action of an onboarding step item.
*/

type OnboardingStepItemAction =
	| OnboardingStepItemActionFlow
	| OnboardingStepItemActionLink
	| OnboardingStepItemActionRoute
	| OnboardingStepItemActionVideo;

/**

Represents an onboarding item.
*/

export type OnboardingItem = {
	content: {
		title: string;
		descriptions?: string[];
		cta?: string;
	};
	action: OnboardingStepItemAction;
	done: boolean;
	onClick: () => void;
	routeTo?: string;
	routeHash?: string;
	hidden?: boolean;
	id: OnboardingItemType;
};

/**

Represents a step in the onboarding process.
@param {string} name - The name of the step.
@param {'list' | 'grid'} type - The type of the step.
@param {OnboardingItem[]} items - The list of onboarding items included in the step.
@param {IconType} [icon] - The icon component for the step, optional.
@param {OnboardingSection} [section] - The section the step is a part of, optional.
*/

export type OnboardingStep = {
	name: string;
	type: 'list' | 'grid';
	items: OnboardingItem[];
	icon?: IconType;
	section?: OnboardingSection;
};

/**

Represents the arguments for the onboarding steps.
@param {string} onlineStoreUrl - The URL of the online store.
@param {TFunction} t - The translation function.
@param {ShopAnalytics | undefined} shopAnalytics - The shop analytics object.
@param {boolean} showProductTours - Indicates if product tours should be shown.
@param {string} country - The country of the shop.
@param {Plan} [plan] - The plan object.
*/

export type OnboardingStepsArgs = {
	onlineStoreUrl: string;
	t: TFunction;
	shopAnalytics: ShopAnalytics | undefined;
	showProductTours: boolean;
	country: string;
	onboardingListVersion: OnboardingListVersion;
	plan?: Plan;
};
