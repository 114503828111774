import { TFunction } from 'react-i18next';

import { BaseProductVariant, InventoryItem, ProductVariant } from 'common/modules/inventory';
import {
	getOrderProductStock,
	getOrderProductSummary,
	getTotalProductPricing,
} from 'common/modules/orders';
import { getVariantSkuOption } from 'common/modules/products/variants';
import {
	DinCollectionMethod,
	MatchedProduct,
	OrderProduct,
	PartialWithId,
	PricingTableObject,
	ProductApi,
	ProductDinValues,
	SetProduct,
	UserProperties,
} from 'common/types';
import { updateOrderProductsPricing } from 'common/utils/productUtils';

import { getCalculatedDinValue } from './ShopperProducts/ProductItem/utils';

interface CodeChangeProps {
	code: string;
	orderProductId: string;
	setIndex: number | null;
	currentVariant: BaseProductVariant | undefined;
	newVariantId: string | undefined;
	selectedStockItem: InventoryItem | undefined;
	stockProduct: ProductApi | null;
	shopperProducts: OrderProduct[];
	useDinVariation: boolean;
	userProperties: UserProperties;
	t: TFunction;
	dinCollectionMethod: DinCollectionMethod;
}

export const getUpdatedPartialProductsOnCodeChange = ({
	code,
	orderProductId,
	setIndex,
	currentVariant,
	newVariantId,
	stockProduct,
	selectedStockItem,
	shopperProducts,
	useDinVariation,
	userProperties,
	t,
	dinCollectionMethod,
}: CodeChangeProps) => {
	const updatedPartialProducts: PartialWithId<OrderProduct>[] = [];
	const orderProduct = shopperProducts.find((p) => p.id === orderProductId)!;
	const productToUpdate =
		setIndex != null ? orderProduct.setProducts.find((p, i) => i === setIndex)! : orderProduct;
	let newVariant: null | ProductVariant = null;
	const oldVariantId = (currentVariant && currentVariant.id) || null;
	if (newVariantId && newVariantId !== oldVariantId && stockProduct) {
		newVariant = (stockProduct.variants.options || []).find((v) => v.id === newVariantId) || null;
	}
	const variant = newVariant ?? currentVariant;
	const variantSku = variant ? getVariantSkuOption(variant) : null;
	const updatedStock = getOrderProductStock({
		skuOption: variantSku,
		product: { ...orderProduct, variant },
		itemCode: code,
	});
	let updatedPartialProduct: Partial<OrderProduct | SetProduct> = {
		productCode: code,
		...(newVariant && { variant }),
		...(newVariant && { selectedVariant: newVariant }),
		...(updatedStock && { stock: updatedStock }),
	};
	if (productToUpdate.collectDin) {
		const dinVariation = useDinVariation
			? selectedStockItem?.fixedFieldValues?.dinVariation ?? null
			: null;
		const updatedProductDinValues = getNewDinValues(productToUpdate, {
			dinVariation,
			userProperties,
			t,
			dinCollectionMethod,
		});
		updatedPartialProduct = {
			...updatedPartialProduct,
			...(!!updatedProductDinValues && { dinValues: updatedProductDinValues }),
		};
	}
	let updatedPartialOrderProduct: Partial<OrderProduct> = {};
	if (setIndex != null) {
		const setProducts = orderProduct.setProducts.map((setProduct, index) => {
			if (setIndex === index) {
				return {
					...setProduct,
					...updatedPartialProduct,
				};
			}
			return setProduct;
		});
		const productWithNewSetProducts: OrderProduct = {
			...orderProduct,
			setProducts,
		};
		const newSummary = getOrderProductSummary(productWithNewSetProducts);
		updatedPartialOrderProduct = {
			setProducts,
			summary: newSummary,
			includedVariantIds: newSummary.skuIds,
		};
	} else {
		const productWithNewVariant: OrderProduct = {
			...orderProduct,
			...updatedPartialProduct,
		};
		const newSummary = getOrderProductSummary(productWithNewVariant);
		updatedPartialOrderProduct = {
			...updatedPartialProduct,
			summary: newSummary,
			includedVariantIds: newSummary.skuIds,
		};
	}
	if (productToUpdate.collectBootSoleLength) {
		const bootSoleLengthToUpdate = selectedStockItem?.fixedFieldValues?.soleLength ?? undefined;
		for (const shopperProduct of shopperProducts) {
			if (
				shopperProduct.collectDin ||
				shopperProduct.collectBootSoleLength ||
				shopperProduct.setProducts.some((setP) =>
					Boolean(setP.collectBootSoleLength || setP.collectDin),
				)
			) {
				const sameProductThanSelected = shopperProduct.id === orderProductId;
				const product = sameProductThanSelected
					? {
							...orderProduct,
							...updatedPartialOrderProduct,
					  }
					: shopperProduct;
				const updatedProductDinValues = getUpdatedProductDinValues(product, {
					soleLength: bootSoleLengthToUpdate,
					userProperties,
					t,
					dinCollectionMethod,
				});
				if (!!updatedProductDinValues) {
					updatedPartialProducts.push({
						id: shopperProduct.id,
						data: sameProductThanSelected
							? {
									...updatedPartialOrderProduct,
									...updatedProductDinValues,
							  }
							: updatedProductDinValues,
					});
				}
			}
		}
	} else {
		updatedPartialProducts.push({
			id: orderProductId,
			data: updatedPartialOrderProduct,
		});
	}
	return updatedPartialProducts;
};

export const getUpdatedProductDinValues = (
	product: OrderProduct,
	options: {
		soleLength?: string | null;
		dinVariation?: string | null;
		userProperties: UserProperties;
		t: TFunction;
		dinCollectionMethod: DinCollectionMethod;
	},
): { dinValues?: ProductDinValues; setProducts?: SetProduct[] } | null => {
	const { soleLength, userProperties, t, dinCollectionMethod, dinVariation } = options;
	const newDinValues = getNewDinValues(product, {
		soleLength,
		userProperties,
		t,
		dinCollectionMethod,
		dinVariation,
	});
	if (
		product.set &&
		product.setProducts &&
		product.setProducts.some((setP) => setP.collectDin || setP.collectBootSoleLength)
	) {
		const setProducts = product.setProducts.map((setProduct) => {
			const newSetDinValues = getNewDinValues(setProduct, {
				soleLength,
				userProperties,
				t,
				dinCollectionMethod,
				dinVariation,
			});
			return {
				...setProduct,
				...(newSetDinValues && { dinValues: newSetDinValues }),
			};
		});
		return {
			setProducts,
			...(newDinValues && { dinValues: newDinValues }),
		};
	}
	return newDinValues ? { dinValues: newDinValues } : null;
};

export const getNewDinValues = (
	product: SetProduct | OrderProduct,
	options: {
		soleLength?: string | null;
		dinVariation?: string | null;
		dinCollectionMethod: DinCollectionMethod;
		t: TFunction;
		userProperties: UserProperties;
	},
): ProductDinValues | undefined => {
	const { soleLength, dinCollectionMethod, t, userProperties, dinVariation } = options;
	if (product.collectDin || product.collectBootSoleLength) {
		let updatedDinValues: ProductDinValues = {
			...product.dinValues,
			...(soleLength !== undefined && { soleLength }),
			...(dinVariation !== undefined && { dinVariation: dinVariation }),
		};
		if (product.collectDin && dinCollectionMethod === 'automatic') {
			const newDinValue = getCalculatedDinValue({
				userProperties,
				soleLength: updatedDinValues?.soleLength,
				dinVariation: updatedDinValues?.dinVariation,
				t,
			});
			updatedDinValues = {
				...updatedDinValues,
				calculatedDin: newDinValue.din,
			};
		}
		return updatedDinValues;
	}
	return undefined;
};

export const getOrderProductWithScannedId = (allProducts: OrderProduct[], scannedId: string) => {
	let shopperId = '';
	const productWithScannedId = allProducts.reduce((matchedProducts: MatchedProduct[], p) => {
		if (p.set) {
			for (let i = 0; i < p.setProducts.length; i++) {
				const setP = p.setProducts[i];
				if (setP.productCode === scannedId) {
					const matchedProduct: MatchedProduct = {
						setIndex: i,
						orderProductId: p.id,
						productApiId: p.productApiId,
						productCode: setP.productCode,
						selectedVariant: setP.variant,
					};
					shopperId = p.shopperId;
					matchedProducts.push(matchedProduct);
				}
			}
		} else {
			if (p.productCode === scannedId) {
				const matchedProduct: MatchedProduct = {
					setIndex: null,
					productApiId: p.productApiId,
					orderProductId: p.id,
					productCode: p.productCode,
					selectedVariant: p.variant,
				};
				shopperId = p.shopperId;
				matchedProducts.push(matchedProduct);
			}
		}
		return matchedProducts;
	}, []);
	return { productWithScannedId: productWithScannedId[0], shopperId: shopperId };
};

export const doesShopperStillHaveProductsWithoutCode = (shopperProducts: OrderProduct[]) => {
	let productsWithoutCode = false;
	shopperProducts.forEach((p) => {
		if (p.set) {
			p.setProducts.forEach((setP) => {
				if (!setP.productCode) {
					productsWithoutCode = true;
				}
			});
		} else if (!p.productCode) {
			productsWithoutCode = true;
		}
	});
	return productsWithoutCode;
};

export const calculatePriceDifferenceForSegment = (args: {
	segment: string;
	shopperProducts: OrderProduct[];
	stockProducts: ProductApi[];
	savedPricingTables: PricingTableObject;
	disableDeposit: boolean;
}) => {
	const { segment, shopperProducts, stockProducts, savedPricingTables, disableDeposit } = args;
	const updatedShopperProducts = updateOrderProductsPricing({
		orderProducts: shopperProducts,
		stockProducts,
		savedPricingTables,
		channel: 'ADMIN',
		segment,
		disableDeposit,
	});
	const oldTotal = getTotalProductPricing(shopperProducts).total;
	const newTotal = getTotalProductPricing(updatedShopperProducts).total;
	const priceDifference = newTotal - oldTotal;
	return priceDifference;
};

/**
 * This is used in shopper card components rendered inside React Virtualized containers.
 * It calculates the total height of the virtualized container, so we can get the correct height for an overlay
 */
export const getShopperCardOverlayHeight = () => {
	const virtualizedContainer = document.querySelector('.ReactVirtualized__List');
	const innerScrollContainer = document.querySelector(
		'.ReactVirtualized__Grid__innerScrollContainer',
	);
	const virtualizedContainerHeight = virtualizedContainer?.scrollHeight ?? window.innerHeight;
	const scrollContainerHeight = innerScrollContainer?.scrollHeight ?? window.innerHeight;
	if (virtualizedContainerHeight >= scrollContainerHeight) {
		return virtualizedContainerHeight;
	}
	const virtualizedContainerPaddingTop = !virtualizedContainer
		? 0
		: parseFloat(
				window.getComputedStyle(virtualizedContainer, null).getPropertyValue('padding-top'),
		  );
	const virtualizedContainerPaddingBottom = !virtualizedContainer
		? 0
		: parseFloat(
				window.getComputedStyle(virtualizedContainer, null).getPropertyValue('padding-bottom'),
		  );
	const totalPadding = virtualizedContainerPaddingTop + virtualizedContainerPaddingBottom;
	return scrollContainerHeight + (isNaN(totalPadding) ? 0 : totalPadding);
};
