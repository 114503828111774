import { z } from 'zod';

import { Address } from 'common/models/_atoms/Address';
import { Language } from 'common/models/_atoms/Language';
import { Metafields } from 'common/models/_atoms/Metafields';
import { withOpenApi } from 'common/utils/openApi';
import { newFirestoreId } from 'common/utils/newRentalUtils';

export const zCustomerDetailsApi = withOpenApi(
	z.object({
		firstName: z.string(),
		lastName: z.string(),
		phone: z.string(),
		email: z.string(),
		marketingConsent: z.boolean(),
		language: Language.api.optional(),
		address: Address.api.optional(),
		// Metafields is used to store additional metadata that is not part of the core system data model.
		metafields: Metafields.api.optional(),
		customerId: z.string().nullable(),
	}),
	{
		fields: {
			firstName: 'First name of the customer.',
			lastName: 'Last name of the customer.',
			phone: 'Phone number of the customer.',
			email: 'Email of the customer.',
			marketingConsent: 'Whether the customer has given marketing consent.',
			language: 'Language of the customer.',
			address: 'Address of the customer.',
			customerId: 'Unique identifier of the customer.',
		},
		example: {
			firstName: 'John',
			lastName: 'Doe',
			phone: '+1555555555',
			email: 'john@doe.com',
			marketingConsent: true,
			language: 'en' as const,
			address: Address.example,
			customerId: newFirestoreId(),
		}
	},
);
