import { notUndefined } from 'common/utils/common';

export type UtmTags = {
	source?: string;
	medium?: string;
	content?: string;
};

const UTM_MAPPING: Record<keyof UtmTags, string> = {
	source: 'utm_source',
	medium: 'utm_medium',
	content: 'utm_campaign',
};

/**
 * Replace all non-alphanumeric characters with '-'
 * @param str - the string to normalize
 * @returns - normalized string or undefined
 */
const replaceAllNonAlphaNumeric = (str?: string): string | undefined => {
	if (!str) return undefined;

	const replaced = str.replace(/[^a-z0-9]/gi, '-');
	return /^-+$/.test(replaced) ? undefined : replaced;
};

const normalizeUtmTags = (tags: UtmTags): UtmTags => {
	return Object.keys(tags).reduce((acc, key) => {
		const normalized = replaceAllNonAlphaNumeric(tags[key as keyof UtmTags]);
		if (normalized) acc[key as keyof UtmTags] = normalized;
		return acc;
	}, {} as UtmTags);
};

export const getNormalizedUtmTagString = (tags?: UtmTags): string => {
	if (!tags) return '';
	const normalizedTags = normalizeUtmTags(tags);
	return Object.keys(UTM_MAPPING)
		.map((key) => {
			const value = normalizedTags[key as keyof UtmTags];
			return notUndefined(value) ? `${UTM_MAPPING[key as keyof UtmTags]}=${value}` : undefined;
		})
		.filter(notUndefined)
		.join('&');
};
