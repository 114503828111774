import {
	OrderProductAvailability,
	OrderProductAvailabilityFields,
	fetchOrderProductAvailabilities,
	fetchSoldOrderProductAvailabilities,
} from 'common/api/firestore';
import { Api } from 'common/db/api/paths';
import { isClientDb } from 'common/db/api/utils/typeGuards';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import { OrderProduct, PurchaseTypes } from 'common/types';

import { AvailabilityDataSources } from './types';
import { isRelevantAvailability, orderProductToAvailability } from './utils';

export interface FetchRentedSkusProps {
	skuId: string;
	startDate: string;
	endDate: string;
	startLocationId: string;
	ignoredOrderProductIds?: string[];
	dataSources?: AvailabilityDataSources;
	api: Api;
}

export interface FetchSalesSkusProps {
	skuId: string;
	startDate: string;
	endDate: string;
	startLocationId: string;
	ignoredOrderProductIds?: string[];
	dataSources?: AvailabilityDataSources;
	api: Api;
}
interface GetRentedSkusFromDataProps extends FetchRentedSkusProps {
	orderProducts: OrderProduct[];
}

interface GetSoldSkusFromDataProps extends FetchRentedSkusProps {
	orderProducts: OrderProduct[];
}

export const fetchRentedSkus = async (
	props: FetchRentedSkusProps,
): Promise<OrderProductAvailability[]> => {
	const {
		skuId,
		startDate,
		endDate,
		startLocationId,
		ignoredOrderProductIds,
		dataSources,
		api,
	} = props;

	if (!!dataSources) {
		return getRentedSkusFromData({
			...props,
			orderProducts: dataSources.orderProducts,
		}).map(orderProductToAvailability);
	}

	const orderProductAvailabilities = !isClientDb(api._db)
		? await api.orderProducts.get
				.whereArray('summary.skuIds', 'array-contains', skuId)
				.where('startLocationId', '==', startLocationId)
				.orderBy('unavailable.until')
				.startAt(startDate)
				.select(Object.values(OrderProductAvailabilityFields))
				.get()
		: await fetchOrderProductAvailabilities({
				skuId,
				startDate,
				startLocationId,
		  });

	return orderProductAvailabilities.filter(
		isRelevantAvailability({
			startDate,
			endDate,
			ignoredOrderProductIds,
		}),
	);
};

export const fetchSalesSkus = async (
	props: FetchSalesSkusProps,
): Promise<OrderProductAvailability[]> => {
	const {
		skuId,
		startDate,
		endDate,
		startLocationId,
		ignoredOrderProductIds,
		dataSources,
		api,
	} = props;

	if (!!dataSources) {
		return getSoldSkusFromData({
			...props,
			orderProducts: dataSources.orderProducts,
		}).map(orderProductToAvailability);
	}

	const soldProductAvailabilities = !isClientDb(api._db)
		? await api.orderProducts.get
				.whereArray('summary.skuIds', 'array-contains', skuId)
				.where('startLocationId', '==', startLocationId)
				.where('purchaseType', '==', PurchaseTypes.sales)
				.where('fulfillmentDate', '==', null)
				.where('endDateReturned', '==', null)
				.select(Object.values(OrderProductAvailabilityFields))
				.get()
		: await fetchSoldOrderProductAvailabilities({
				skuId,
				startLocationId,
		  });

	const soldProductAvailabilitiesWithDates = soldProductAvailabilities.map((availability) => {
		return {
			...availability,
			unavailable: {
				...availability.unavailable,
				from: startDate,
				until: endDate,
			},
		};
	});

	const filtered = soldProductAvailabilitiesWithDates.filter(
		isRelevantAvailability({ startDate, endDate, ignoredOrderProductIds }),
	);

	return filtered;
};

const getRentedSkusFromData = (args: GetRentedSkusFromDataProps): OrderProduct[] => {
	const {
		skuId,
		startDate,
		endDate,
		startLocationId,
		orderProducts,
		ignoredOrderProductIds,
	} = args;

	const matchingProducts = orderProducts.filter((p) => {
		return (
			p.summary.skuIds.includes(skuId) &&
			p.startLocationId === startLocationId &&
			!!p.unavailable.until &&
			p.unavailable.until > startDate &&
			!p.cancelled
		);
	});

	return matchingProducts
		.filter((product) => {
			const { from, until } = product.unavailable;
			if (from && until) {
				return doesDateRangeOverlap(startDate, endDate, from, until);
			}
			return false;
		})
		.filter((product) =>
			ignoredOrderProductIds ? !ignoredOrderProductIds.includes(product.id) : true,
		);
};

const getSoldSkusFromData = (args: GetSoldSkusFromDataProps): OrderProduct[] => {
	const { skuId, startLocationId, orderProducts, ignoredOrderProductIds } = args;

	const matchingProducts = orderProducts.filter((p) => {
		return (
			p.summary.skuIds.includes(skuId) &&
			p.startLocationId === startLocationId &&
			p.purchaseType === PurchaseTypes.sales &&
			p.fulfillmentDate === null &&
			p.endDateReturned === null &&
			!p.cancelled
		);
	});

	return matchingProducts.filter((product) =>
		ignoredOrderProductIds ? !ignoredOrderProductIds.includes(product.id) : true,
	);
};
