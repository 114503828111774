import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const HIGHLIGHTED_FEATURES_DEFINITION: Record<
	'HIGHLIGHTED_FEATURES',
	OnboardingStepDefinition
> = {
	HIGHLIGHTED_FEATURES: {
		plans: 'all',
		versions: {
			V3_USERFLOW: ['PAYMENTS', 'VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
			V3: ['PAYMENTS', 'VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
			V2_NO_TEMPLATE: ['PAYMENTS', 'VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
			V2_TEMPLATE: ['PAYMENTS', 'VISIT_BLOCK_STORE', 'VISIT_DELIVERY'],
		},
	},
};
