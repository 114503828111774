import { sumBy } from 'lodash';

import { getPricingString } from 'common/utils/common';

import { ProductPricing, ProductPricingDefinition } from './types';

export const getProductPricingString = (price: ProductPricing): string | null => {
	return !!price.fixed?.value
		? `${getPricingString(price.fixed.value, price.fixed.currency)}`
		: null;
};

export const multiplyProductPricing = (
	price: ProductPricing | null,
	multiplier: number,
	opts?: {
		roundDownToClosestInteger?: boolean;
	},
): ProductPricing | null => {
	if (!price?.fixed) return null;

	let value = price.fixed.value * multiplier;

	if (!!opts?.roundDownToClosestInteger) {
		value = Math.floor(value / 100) * 100;
	}

	return {
		fixed: {
			...price.fixed,
			value,
		},
	};
};

export const sumProductPricing = (prices: (ProductPricing | null)[]): ProductPricing | null => {
	const filteredPrices = prices.filter((price) => !isFreePrice(price));
	if (filteredPrices.length === 0) return null;

	return {
		fixed: {
			value: sumBy(filteredPrices, (price) => price?.fixed?.value ?? 0),
			currency: filteredPrices[0]!.fixed!.currency,
		},
	};
};

export const isFreePrice = (price?: ProductPricing | null): boolean => {
	return !price?.fixed?.value;
};

export const getProductPricingFromPricingDefinition = (
	definition: ProductPricingDefinition | null,
): ProductPricing | null => {
	if (!definition) return null;
	const pricing = {
		fixed: definition?.fixed?.amount ?? null,
	};

	return isFreePrice(pricing) ? null : pricing;
};
