import { useEffect, useState } from 'react';

import { useHistory } from 'react-router';

/** Resets the scroll position of the page on every route change
 */

const ScrollToTop = () => {
	const history = useHistory();
	const [pathname, setPathname] = useState<string>('');

	useEffect(() => {
		const unlisten = history.listen((args) => {
			if (args.pathname !== pathname) {
				window.scrollTo(0, 0);
			}
			setPathname(args.pathname);
		});
		return () => {
			unlisten();
		};
	}, [history, pathname]);

	return null;
};

export default ScrollToTop;
