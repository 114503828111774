import { TFunction } from 'i18next';

import analytics from './ANALYTICS/details';
import branding from './BRANDING/details';
import categoryTerms from './CATEGORY_TERMS/details';
import cloudPOS from './CLOUD_POS/details';
import dashboard from './DASHBOARD/details';
import dinSettings from './DIN_SETTINGS/details';
import discountCodes from './DISCOUNT_CODES/details';
import extensions from './EXTENSIONS/details';
import maintenanceTime from './MAINTENANCE_TIME/details';
import manualPaymentMethods from './MANUAL_PAYMENT_METHODS/details';
import massReturn from './MASS_RETURN/details';
import packageProducts from './PACKAGE_PRODUCTS/details';
import productVariants from './PRODUCT_VARIANTS/details';
import skidata from './SKIDATA/details';
import tekso from './TEKSO/details';
import { AddOn, AddOnDetails, AddOns } from './types';

export const getAddOnDetails = (addOn: AddOn, t: TFunction): AddOnDetails => {
	const details: Record<AddOn, AddOnDetails> = {
		[AddOns.EXTENSIONS]: extensions(t),
		[AddOns.GOOGLE_ANALYTICS]: analytics(t),
		[AddOns.BRANDING]: branding(t),
		[AddOns.PACKAGE_PRODUCTS]: packageProducts(t),
		[AddOns.PRODUCT_VARIANTS]: productVariants(t),
		[AddOns.MASS_RETURN]: massReturn(t),
		[AddOns.DIN_SETTINGS]: dinSettings(t),
		[AddOns.DISCOUNT_CODES]: discountCodes(t),
		[AddOns.MAINTENANCE_TIME]: maintenanceTime(t),
		[AddOns.CATEGORY_TERMS]: categoryTerms(t),
		[AddOns.MANUAL_PAYMENT_METHODS]: manualPaymentMethods(t),
		[AddOns.DASHBOARD]: dashboard(t),
		[AddOns.SKIDATA]: skidata(t),
		[AddOns.TEKSO]: tekso(t),
		[AddOns.CLOUD_POS]: cloudPOS(t),
	};

	return (
		details[addOn] ?? {
			name: '',
			subHeader: '',
			paymentCycle: '',
			valueProps: [],
		}
	);
};
