import React, { useEffect, useState } from 'react';

import { Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { RiCheckLine, RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { makeStyles } from 'tss-react/mui';

import LinkButton from 'common/components/LinkButton';
import { Callable } from 'common/frontend/callable';
import { confirmPasswordReset, verifyPasswordResetCode } from 'common/frontend/firebase/auth';
import useFormField, { Form, validateForm } from 'common/hooks/useFormField';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { getQueryParam } from 'common/utils/frontUtils';
import AuthContainer from 'components/AuthContainer';
import PasswordResetDialog from 'components/PasswordResetDialog';
import Spinner from 'components/Spinner';
import { useTranslation } from 'services/localization/useTranslation';
import { LoadingData } from 'services/types';
import { validatePassword } from 'services/utils';
import { Routes } from 'routing';

const ResetPassword = ({ code, isInvitation }: { code: string; isInvitation: boolean }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [validCode, setValidCode] = useState<LoadingData<boolean>>({
		loading: true,
		data: null,
		error: null,
	});
	const [email, setEmail] = useState<string>('');
	const [passwordResetSuccess, setPasswordResetSuccess] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		const validateCode = async (code: string) => {
			try {
				const email = await verifyPasswordResetCode(code);
				setEmail(email);
				setValidCode({
					loading: false,
					data: true,
					error: null,
				});
			} catch (e) {
				setValidCode({
					loading: false,
					data: false,
					error: null,
				});
			}
		};
		validateCode(code);
	}, [code]);

	const form: Form<{ password: string }> = {
		password: useFormField<string>('', (password) => validatePassword(password, t)),
	};

	const resetPassword = async (authCode: string, newPassword: string) => {
		try {
			await confirmPasswordReset(authCode, newPassword);
			return;
		} catch (e) {
			errorHandler.report(e);
			return t('authActions.passwordResetFailed', 'Resetting password failed. Please try again');
		}
	};

	const handleSubmit = async () => {
		const { errors, values } = validateForm(form);

		if (errors.length > 0) {
			return;
		}

		setLoading(true);
		const error = await resetPassword(code, values.password);

		if (error) {
			setError(error);
			setLoading(false);
		} else {
			setPasswordResetSuccess(true);
		}
	};

	return (
		<AuthContainer>
			{validCode.loading ? (
				<Spinner />
			) : validCode.data ? (
				<Box mt={{ xs: 5.25, sm: 7 }} mb={3}>
					{!passwordResetSuccess ? (
						<ResetPasswordForm
							email={email}
							form={form}
							loading={loading}
							error={error}
							handleSubmit={handleSubmit}
							isInvitation={isInvitation}
						/>
					) : (
						<ResetSuccessView isInvitation={isInvitation} />
					)}
				</Box>
			) : (
				<InvalidPasswordCode isInvitation={isInvitation} />
			)}
		</AuthContainer>
	);
};

const InvalidPasswordCode = ({ isInvitation }: { isInvitation: boolean }) => {
	const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState<boolean>(false);
	const { t } = useTranslation();
	const [state, setState] = useState<'initial' | 'done'>('initial');
	const email = getQueryParam('email');
	const { classes } = useStyles();

	const handlePasswordResetDialogClose = () => {
		setPasswordResetDialogOpen(false);
	};

	const handleClick = async () => {
		if (!!email) {
			if (isInvitation) {
				await Callable.users.sendInvitationLink({ email });
			} else {
				await Callable.users.sendPasswordResetLink({ email });
			}
			setState('done');
		} else {
			setPasswordResetDialogOpen(true);
		}
	};

	const DoneState = () => {
		return (
			<Box mt={11} mb={6.5}>
				<Typography variant="h5" gutterBottom>
					{t('authActions.linkSentTo', { email, defaultValue: 'Link sent to {{email}}' })}
				</Typography>
				<Typography variant="body1">
					{t(
						'authActions.checkYourEmail',
						'Check your email - you should receive a new invitation link within a few minutes.',
					)}
				</Typography>
			</Box>
		);
	};

	const InitialState = () => {
		return (
			<Box mt={11} mb={6.5}>
				<Typography variant="h5" gutterBottom>
					{isInvitation
						? t('authActions.expiredInvitationLink', 'Expired or invalid invitation link')
						: t('authActions.expiredPasswordResetLink', 'Expired or incorrect password reset link')}
				</Typography>
				<Typography variant="body1">
					{isInvitation
						? t(
								'authActions.expiredInvitationLinkInfo',
								'Your invitation link has expired, is invalid or has already been used',
						  )
						: t(
								'authActions.expiredPasswordResetLinkInfo',
								'Your password reset link has expired, the link is incorrect or it has already been used. Please try again',
						  )}
					{'.'}
				</Typography>
				<Box mt={4} />
				<Button
					variant="contained"
					color="primary"
					className={classes.blackButton}
					onClick={handleClick}
				>
					{!!email
						? t('authActions.sendNewLink', 'Resend link')
						: t('authActions.resetPassword', 'Reset your password')}
				</Button>
				{passwordResetDialogOpen && (
					<PasswordResetDialog
						open={passwordResetDialogOpen}
						onClose={handlePasswordResetDialogClose}
						email={''}
					/>
				)}
			</Box>
		);
	};

	return <>{state === 'done' ? <DoneState /> : <InitialState />}</>;
};

interface ResetPasswordFormProps {
	email: string;
	form: Form<{ password: string }>;
	loading: boolean;
	error?: string;
	handleSubmit: () => void;
	isInvitation: boolean;
}

const ResetPasswordForm = ({
	email,
	form,
	loading,
	error,
	handleSubmit,
	isInvitation,
}: ResetPasswordFormProps) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const { t } = useTranslation();
	const { classes } = useStyles();

	const passwordHelper = t('common:validation.lengthAtLeastIncludeLettersNumbers', {
		field: t('common:form.password'),
		int: '7',
	});
	return (
		<>
			<Box>
				<Typography variant="h4" gutterBottom>
					{isInvitation
						? t('authActions.welcomeToRentle', 'Welcome to Rentle')
						: t('authActions.resetPassword', 'Reset your password')}
				</Typography>
				{Boolean(email) && (
					<Typography mb={1} variant="body1" color="textSecondary">
						{isInvitation
							? t('authActions.setPasswordForEmail', {
									email,
									defaultValue: 'Set your password for {{email}}',
							  })
							: t('authActions.forEmail', { email: email, defaultValue: 'For email {{email}}' })}
					</Typography>
				)}
			</Box>
			<TextField
				variant="outlined"
				label={t('authActions.newPassword', 'New password')}
				placeholder={t('common:form.passwordPlaceholder')}
				name="password"
				value={form.password.value}
				onChange={form.password.handleChange}
				margin="none"
				type={showPassword ? 'text' : 'password'}
				error={Boolean(form.password.error)}
				helperText={Boolean(form.password.error) ? form.password.error : passwordHelper}
				required
				fullWidth
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
								edge="end"
								size="small"
								sx={{
									color: (theme) => theme.palette.primary.main,
								}}
							>
								{showPassword ? (
									<RiEyeOffFill size={20} color={'inherit'} />
								) : (
									<RiEyeFill size={20} color={'inherit'} />
								)}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<Box mt={8} />
			<Button
				disabled={loading}
				onClick={handleSubmit}
				color="primary"
				variant="contained"
				fullWidth
				className={classes.blackButton}
			>
				{loading ? (
					<Spinner padding={8} thickness={4} color="inherit" />
				) : (
					t('authActions.saveNewPassword', 'Save new password')
				)}
			</Button>
			<Box mt={1} mb={7}>
				{Boolean(error) && (
					<Typography variant="body2" color="error" align="center">
						{error}
					</Typography>
				)}
			</Box>
		</>
	);
};

const ResetSuccessView = ({ isInvitation }: { isInvitation: boolean }) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	return (
		<Box mt={11} mb={6.5}>
			<div className={classes.doneWrapper}>
				<RiCheckLine size={56} className={classes.checkIcon} />
				<Typography variant="h6">
					{isInvitation
						? t('authActions.passwordSet', 'Your password has been set')
						: t('authActions.passwordChanged', 'Your password has been changed')}
				</Typography>
			</div>
			<Typography>
				{t(
					'authActions.passwordChangedInfo',
					'Click below to get back to Login screen and sign in with your new password',
				)}
			</Typography>
			<Box mt={4} />
			<LinkButton fullWidth variant="contained" className={classes.blackButton} to={Routes.LOGIN}>
				{t('authActions.toLogin', 'To Login')}
			</LinkButton>
		</Box>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	doneWrapper: {
		display: 'flex',
		margin: '16px auto',
		alignItems: 'center',
	},
	checkIcon: {
		color: 'green',
		marginRight: 16,
	},
	title: {
		marginBottom: theme.spacing(3),
	},
	closeButton: {
		width: 190,
		borderRadius: 50,
		marginTop: theme.spacing(2),
	},
	blackButton: {
		width: '100%',
		background: theme.palette.common.black,
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},
}));

export default ResetPassword;
