import type {
	TrpcRouter,
	TrpcRouterInput,
	TrpcRouterOutput,
} from '@rentle/resell-backend/src/trpc';
import * as trpcClient from '@trpc/client';
import superjson from 'superjson';

export type Listing = TrpcRouterOutput['listings']['get'];
export type CreateListingInput = TrpcRouterInput['listings']['create'];
export type UpdateListingInput = TrpcRouterInput['listings']['update'];
export type ListingsListInput = TrpcRouterInput['listings']['list'];
export type ListingsListOutput = TrpcRouterOutput['listings']['list'];

export type CreateTrpcClientOpts = {
	serverUrl?: string;
};
export const createTrpcClient = (opts: CreateTrpcClientOpts = {}) => {
	return trpcClient.createTRPCProxyClient<TrpcRouter>({
		links: [
			trpcClient.httpLink({
				url: opts.serverUrl ?? 'http://localhost:3000/_trpc',
			}),
		],
		transformer: superjson,
	});
};

export const isTRPCClientError = (
	err: unknown
): err is trpcClient.TRPCClientError<TrpcRouter> => {
	return err instanceof trpcClient.TRPCClientError;
};

export const isNotFoundError = (err: unknown): boolean => {
	return isTRPCClientError(err) && err.data?.code === 'NOT_FOUND';
};
