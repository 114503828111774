import React from 'react';

import { Grid, Stack, useTheme } from '@mui/material';

import RegisterBg from 'common/assets/register-bg.png';

import CtaWithIcon from './CtaWithIcon';

export interface Props {
	content: {
		title: string;
		text: string;
	}[];
}
const CtaContentWithIcon = (props: Props) => {
	const theme = useTheme();
	const { content } = props;
	return (
		<Grid
			container
			spacing={0}
			item
			lg={6}
			justifyContent="center"
			alignItems="baseline"
			alignContent="flex-start"
			sx={{
				backgroundImage: `url("${RegisterBg}")`,
				backgroundRepeat: 'no-repeat',
				backgroundColor: theme.palette.common.black,
				backgroundPosition: 'right top',
				color: theme.palette.common.white,
				paddingTop: theme.spacing(18),
				[theme.breakpoints.down('sm')]: {
					paddingTop: '130px',
				},
			}}
		>
			<Stack direction="column" spacing={15}>
				{content.map(({ title, text }, index) => (
					<CtaWithIcon title={title} text={text} key={`cta-${title}-${index}`} />
				))}
			</Stack>
		</Grid>
	);
};

export default CtaContentWithIcon;
