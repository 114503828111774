import { useCallback } from 'react';

import {
	CustomEvents,
	EcomEventName,
	EventName,
	Events,
	logEvent,
	setGaUserProperties,
	setHubspotProperties,
} from 'common/services/analytics';
import * as Gtm from 'common/services/analytics/gtm';
import * as IntercomService from 'common/services/analytics/intercom';
import * as UserflowService from 'common/services/analytics/userflow';
import { removeUndefinedValues } from 'common/utils/common';

import { usePosthog } from './usePosthog';

export const useAnalytics = () => {
	const { newPosthogEvent } = usePosthog();
	const logAnalyticsEvent = useCallback(
		<T extends Exclude<EventName, EcomEventName>>({
			name,
			params,
			optionalServices,
		}: {
			name: T;
			params?: Events[T];
			optionalServices?: {
				gtm?: boolean;
				userflow?: boolean;
			};
		}) => {
			logEvent(name, params);
			newPosthogEvent(name, params);
			if (optionalServices?.gtm) {
				Gtm.sendEvent(name, params);
			}
			if (optionalServices?.userflow) {
				UserflowService.trackEvent(name, params);
			}
		},
		[newPosthogEvent],
	);

	const logRegisterActionEvent = useCallback(
		(input: CustomEvents['register_action']['input'], data?: any) => {
			logAnalyticsEvent({ name: 'register_action', params: { input, data } });
		},
		[logAnalyticsEvent],
	);

	const logSignupEvent = useCallback(
		(params: Events['sign_up'] & { merchant_id: string }) => {
			const { affiliate_id, email, merchant_id, ...eventParams } = params;
			logAnalyticsEvent({
				name: 'sign_up',
				params: eventParams,
			});
			Gtm.sendEvent('sign_up', params);
			setHubspotProperties(
				{
					rentle_merchant_id: merchant_id,
					rentle_signup_timestamp: new Date().getTime(),
				},
				email,
			);
		},
		[logAnalyticsEvent],
	);

	const logAdditionalSignupInfoEvent = useCallback(
		(params: Events['additional_signup_info'] & { email: string }) => {
			const { email, ...eventParams } = params;
			logAnalyticsEvent({
				name: 'additional_signup_info',
				params: eventParams,
				optionalServices: { gtm: true },
			});
			setGaUserProperties(eventParams, { sendToAllTags: true });
			setHubspotProperties(
				removeUndefinedValues({
					rentle_customer_persona: eventParams.customer_persona,
					rentle_industry_categories: eventParams.industry_categories?.join(';'),
					rentle_website_url: eventParams.website_url,
					rentle_shop_revenue: eventParams.revenue,
					rentle_merchant_profile: eventParams.merchant_profile,
				}),
				email,
			);
		},
		[logAnalyticsEvent],
	);

	const logPlanChangeEvent = useCallback(
		(params: Events['merchant_plan_change']) => {
			const { type, ...eventParams } = params;
			const eventName = type === 'upgrade' ? 'merchant_upgrade' : 'merchant_downgrade';
			logAnalyticsEvent({
				name: eventName,
				params: {
					...eventParams,
					planCycle: `${eventParams.toPlan}${eventParams.billingCycle}`,
				},
				optionalServices: { gtm: true, userflow: true },
			});
			setHubspotProperties({
				...(type === 'upgrade' && { rentle_merchant_plan_upgrade_timestamp: new Date().getTime() }),
				...(type === 'downgrade' && {
					rentle_merchant_plan_downgrade_timestamp: new Date().getTime(),
				}),
			});
		},
		[logAnalyticsEvent],
	);

	const logOnboardingClickEvent = (params: Events['onboarding_action_press']) => {
		logAnalyticsEvent({
			name: 'onboarding_action_press',
			params,
			optionalServices: { gtm: true, userflow: true },
		});
		IntercomService.trackEvent(IntercomService.ONBOARDING_EVENT, {
			onboarding_action: params.action,
		});
	};

	return {
		logAnalyticsEvent,
		logSignupEvent,
		logAdditionalSignupInfoEvent,
		logPlanChangeEvent,
		logOnboardingClickEvent,
		logRegisterActionEvent,
	};
};
