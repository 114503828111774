import * as React from 'react';

import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

interface Props {
	className?: any;
	style?: any;
	children?: any;
	id?: string;
	relative?: boolean;
	padding?: 'none' | 'small' | 'normal';
	margin?: 'none' | 'normal';
	sx?: SxProps<Theme>;
	['data-qa']?: string;
}

const ContainerBox = (props: Props) => {
	const { id, className, style, children, sx } = props;
	const { classes, cx } = useStyles(props);
	return (
		<Box
			component="div"
			id={id}
			style={style}
			sx={sx}
			className={cx(classes.root, className)}
			data-qa={props['data-qa']}
		>
			{children}
		</Box>
	);
};

const useStyles = makeStyles<Props>()(
	(theme: Theme, { padding = 'normal', margin = 'normal', relative }) => ({
		root: {
			padding: padding === 'none' ? 0 : padding === 'small' ? theme.spacing(2) : theme.spacing(3),
			width: '100%',
			borderRadius: '4px',
			border: `1px solid ${theme.palette.border.paper}`,
			backgroundColor: theme.palette.background.paper,
			margin: margin === 'none' ? 0 : '16px 0',
			[theme.breakpoints.down('md')]: {
				padding: padding === 'none' ? theme.spacing(0) : theme.spacing(3),
			},
			position: relative ? 'relative' : undefined,
		},
	}),
);

export default ContainerBox;
