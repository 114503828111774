import React from 'react';

import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

import { OnboardingItem } from 'services/onboarding';
import { Routes } from 'routing';

export interface Props {
	item: OnboardingItem;
	index: number;
}
const OnboardingItemCard = (props: Props) => {
	const { item, index } = props;
	const { content, routeTo, routeHash, onClick } = item;
	const { title, descriptions, cta } = content;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down(600));

	const scrollWithOffset = (el: HTMLElement) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = isMobile ? -70 : -180;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	return (
		<Stack
			sx={{
				borderRadius: '12px',
				p: 3,
				backgroundColor: index % 3 === 0 ? '#FAEDFA' : index % 2 ? '#E7EFF1' : '#FFF1EB',
				width: '100%',
				height: '100%',
				[theme.breakpoints.down('sm')]: {
					minHeight: 200,
				},
			}}
			spacing={1}
			justifyContent="space-between"
		>
			<Typography variant="caption" color="secondary" sx={{ textTransform: 'uppercase' }}>
				{title}
			</Typography>
			{descriptions?.map((desc, index) => (
				<Typography variant="body1" key={`${desc}_${index}`}>
					{desc}
				</Typography>
			))}

			<HashLink
				to={{
					pathname: routeTo,
					state: { from: Routes.HOME_VIEW },
					hash: routeHash,
				}}
				smooth
				scroll={(el) => scrollWithOffset(el)}
			>
				<Button
					onClick={() => onClick()}
					variant="outlined"
					size="small"
					sx={{ whiteSpace: 'normal', maxWidth: '100%' }}
				>
					{cta}
				</Button>
			</HashLink>
		</Stack>
	);
};

export default OnboardingItemCard;
