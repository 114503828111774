import { ItemPricingApi, ItemPricingDb } from './types';

export const toApi = (data: ItemPricingDb): ItemPricingApi => {
	return {
		currency: data.currency,
		total: data.total,
		subtotal: data.subtotal,
		totalTaxes: data.totalTaxes,
		totalDiscounts: data.totalDiscounts,
		taxExcluded: data.taxExcluded,
		taxLines: data.taxLines,
		manualDiscount: data.manualDiscount ?? undefined,
		discountCodes: !!data.discountCodes
			? Object.entries(data.discountCodes).map(([code, value]) => {
					return {
						code,
						value: value.totalDiscountValue,
						quantity: value.quantity,
					};
			  })
			: undefined,
		deposit: data.deposit,
		listPrice: data.listPrice,
		originalListPrice: data.originalListPrice,
	};
};

export const toDb = (data: ItemPricingApi): ItemPricingDb => {
	return {
		currency: data.currency,
		total: data.total,
		subtotal: data.subtotal,
		totalTaxes: data.totalTaxes,
		totalDiscounts: data.totalDiscounts,
		taxExcluded: data.taxExcluded,
		taxLines: data.taxLines,
		manualDiscount: data.manualDiscount ?? undefined,
		discountCodes: !!data.discountCodes
			? data.discountCodes.reduce((acc, { code, value, quantity }) => {
					return {
						...acc,
						[code]: {
							totalDiscountValue: value,
							quantity: quantity,
						},
					};
			  }, {} as ItemPricingDb['discountCodes'])
			: undefined,
		deposit: data.deposit,
		listPrice: data.listPrice,
		originalListPrice: data.originalListPrice,
	};
};
