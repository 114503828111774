import { RiAddLine, RiShareLine } from 'react-icons/ri';

import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		icon: RiAddLine,
		title: i18n.t(
			'account.addOns.valueProps.manualPayment.offerPaymentMethodTitle',
			'Offer any payment method',
		),
		description: i18n.t(
			'account.addOns.valueProps.manualPayment.offerPaymentMethodDescription',
			'Define manual payment methods to allow your customers to pay by invoice, voucher, or any other way that suits your business',
		),
	},
	{
		icon: RiShareLine,
		title: i18n.t(
			'account.addOns.valueProps.manualPayment.allChannelsTitle',
			'Use in all channels',
		),
		description: i18n.t(
			'account.addOns.valueProps.manualPayment.allChannelsDescription2',
			'Use your manual payment methods, whether creating an order via admin or in your online store',
		),
	},
	// Comment back in when method filtering is in place
	// {
	// 	icon: RiPencilRuler2Line,
	// 	title: i18n.t(
	// 		'account.addOns.valueProps.manualPayment.rightMethodsTitle',
	// 		'Right methods, at the right time',
	// 	),
	// 	description: i18n.t(
	// 		'account.addOns.valueProps.manualPayment.rightMethodsDescription',
	// 		'Control which booking types your payment methods are available for',
	// 	),
	// },
];

export default valueProps;
