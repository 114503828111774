import React from 'react';

import { Box } from '@mui/material';
import { range } from 'lodash';
import { RiCheckFill } from 'react-icons/ri';

interface BlockSlotsBoxesProps {
	usedBlockSlots: number;
	totalBlockSlots: number;
	currentBlockCost: number;
	currentBlockInstalled: boolean;
}

const BlockSlotsBoxes = (props: BlockSlotsBoxesProps) => {
	const { usedBlockSlots, totalBlockSlots, currentBlockCost, currentBlockInstalled } = props;

	return (
		<Box
			ml={-0.5}
			mb={2}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				width: {
					xs: '80%',
					md: '100%',
				},
			}}
		>
			{range(0, totalBlockSlots).map((_, idx) => {
				const isUsed = idx < usedBlockSlots;
				const isHighlighted = currentBlockInstalled
					? idx < usedBlockSlots && idx >= usedBlockSlots - currentBlockCost
					: idx >= usedBlockSlots && idx < usedBlockSlots + currentBlockCost;

				return (
					<Box
						key={idx}
						sx={{
							width: '30px',
							height: '30px',
							background: isHighlighted
								? (theme) => theme.palette.success.lightest
								: isUsed
								? (theme) => theme.palette.background.secondary
								: (theme) => theme.palette.background.paper,
							color: (theme) => theme.palette.text.primary,
							borderRadius: (theme) => theme.spacing(0.5),
							border: isHighlighted
								? (theme) => `1px dashed ${theme.palette.success.main}`
								: isUsed
								? 'none'
								: (theme) => `1px dashed ${theme.palette.border.inputOutline}`,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
							margin: (theme) => theme.spacing(0.5),
						}}
					>
						{isUsed && <RiCheckFill size={16} color="inherit" />}
					</Box>
				);
			})}
		</Box>
	);
};

export default BlockSlotsBoxes;
