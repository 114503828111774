import { api } from 'common/frontend/api';
import { runTransaction } from 'common/frontend/firebase/firestore';
import { OpeningHoursDoc } from 'common/modules/openingHours';
import { ShopAnalytics } from 'common/services/analytics/tractionAnalytics';
import {
	Carrier,
	DeliveryOption,
	DiscountCodeApi,
	ShopApi,
	ShopPublicInfo,
	ShopReadOnlyData,
	ShopUrlApi,
} from 'common/types';
import { newFirestoreId } from 'common/utils/dataMigrations';

export const getShopPublicInfo = async (shopId: string) => {
	const publicInfo = api().shops.doc(shopId).public.get();
	if (!publicInfo) {
		throw new Error('Shop public info not found');
	}
	return publicInfo;
};

export const getShopInfo = async (shopId: string) => {
	const shopDoc = await api().shops.doc(shopId).get();
	if (!shopDoc) {
		throw new Error('Shop info not found');
	}
	return shopDoc;
};

export const getShopInfoListener = (
	shopId: string,
	callback: (shopInfo: ShopApi | undefined) => void,
	errorCallback: (error: Error) => void,
) => {
	const shopRef = api().shops.doc(shopId);
	return shopRef.listen((shopInfo) => {
		callback(shopInfo);
	}, errorCallback);
};

export const getShopPublicInfoListener = (
	shopId: string,
	callback: (shopPublicInfo: ShopPublicInfo | undefined) => void,
	errorCallback?: (error: Error) => void,
) => {
	const shopPublicRef = api().shops.doc(shopId).public;
	return shopPublicRef.listen((shopPublicInfo) => {
		callback(shopPublicInfo);
	}, errorCallback);
};

export const getShopOpeningHoursListener = (
	shopId: string,
	callback: (openingHours: OpeningHoursDoc[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const openingHoursRef = api().openingHours.byMerchantId(shopId);
	return openingHoursRef.onSnapshot((data) => {
		callback(data);
	}, errorCallback);
};

export const getShopUrlsListener = (
	shopId: string,
	callback: (shopUrls: ShopUrlApi | undefined) => void,
	errorCallback: (error: Error) => void = () => null,
): (() => void) => {
	const shopUrlsRef = api().shopUrls.get.where('shopId', '==', shopId);
	return shopUrlsRef.onSnapshot(
		async (shopUrlApis) => {
			// We know that there should only be one url doc per shopId
			const firstShopUrl = shopUrlApis[0];
			if (!firstShopUrl) {
				callback(undefined);
			} else {
				const { docId, data } = firstShopUrl;
				const shopUrls: ShopUrlApi = {
					...data,
					storeUrl: data.storeUrl ?? docId,
				};
				callback(shopUrls);
			}
		},
		errorCallback,
		{ withDocId: true },
	);
};

export const getDeliveryOptionsListener = (
	shopId: string,
	callback: (deliveryOptions: DeliveryOption[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const deliveryOptionsRef = api().deliveryOptions.byShopId(shopId);
	return deliveryOptionsRef.onSnapshot(async (querySnapshot) => {
		const deliveryOptions: DeliveryOption[] = [];
		querySnapshot.forEach((deliveryOption) => {
			deliveryOptions.push(deliveryOption);
		});
		callback(deliveryOptions);
	}, errorCallback ?? undefined);
};

export const getCarriersListener = (
	shopId: string,
	callback: (carriers: Carrier[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const carriesRef = api().carriers.byShopId(shopId).orderBy('createdAt', 'asc');

	return carriesRef.onSnapshot(async (querySnapshot) => {
		const carriers: Carrier[] = [];
		querySnapshot.forEach((carrier) => {
			carriers.push(carrier);
		});
		callback(carriers);
	}, errorCallback ?? undefined);
};

export const getShopReadOnlyDataListener = (
	shopId: string,
	callback: (readOnlyData: ShopReadOnlyData | undefined) => void,
	errorCallback?: (error: Error) => void,
) => {
	const readOnlyDataRef = api().shops.doc(shopId).readOnly;
	return readOnlyDataRef.listen((data) => {
		callback(data);
	}, errorCallback);
};

export const getShopAnalyticsListener = (
	shopId: string,
	callback: (shopAnalytics: ShopAnalytics) => void,
	errorCallback?: (error: Error) => void,
) => {
	const shopAnalyticsRef = api().shopAnalytics.get.where('shopId', '==', shopId);
	return shopAnalyticsRef.onSnapshot(async (shopAnalytics) => {
		if (!!shopAnalytics.length) {
			callback(shopAnalytics[0]);
		} else {
			const newAnalyticsId = newFirestoreId();
			const analyticsShopData: ShopAnalytics = {
				id: newAnalyticsId,
				shopId,
			};
			await api().shopAnalytics.doc(newAnalyticsId).set(analyticsShopData);
		}
	});
};

export const getDiscountCodesListener = (
	shopId: string,
	callback: (discountCodes: DiscountCodeApi[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const discountCodesRef = api().discountCodes.get.whereArray('shopIds', 'array-contains', shopId);
	return discountCodesRef.onSnapshot((discountCodes) => {
		callback(discountCodes);
	}, errorCallback || undefined);
};

export const getDiscountCodeListener = (
	discountCodeId: string,
	callback: (discountCode: DiscountCodeApi) => void,
	errorCallback?: (error: Error) => void,
) => {
	const discountCodeRef = api().discountCodes.doc(discountCodeId);
	return discountCodeRef.listen((discountCode) => {
		if (!!discountCode) {
			callback(discountCode);
		} else {
			if (errorCallback) {
				errorCallback(new Error('No discount found'));
			}
		}
	}, errorCallback || undefined);
};

export const saveShopPublicInfo = (shopId: string, publicInfo: ShopPublicInfo) => {
	return api()
		.shops.doc(shopId)
		.public.update({ ...publicInfo });
};

export const getNewPaymentNumber = async (shopId: string) => {
	return runTransaction(async (transaction) => {
		const transactionApi = api(transaction);
		const shopData = await transactionApi.shops.doc(shopId).get();
		if (!shopData) {
			throw new Error('No shop data exists');
		}
		const paymentNumber = (shopData.paymentNumber || 0) + 1;
		await transactionApi.shops.doc(shopId).update({
			paymentNumber,
		});
		return paymentNumber;
	});
};
