import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.CatalogTab('products'),
		}),
		content: {
			title: t(
				'homeView.onboarding.connectProductToSkuToTrackProductAvailability',
				'Connect your product to an SKU to track product availability',
			),
		},
		id: 'PRODUCT_SKU_CONNECTION',
		type: 'row',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.CatalogTab('products'),
		}),
		content: {
			title: t(
				'homeView.onboarding.connectProductToSku',
				'Connect a product to an SKU to limit product availability based on stock',
			),
		},
		id: 'PRODUCT_SKU_CONNECTION',
		type: 'row',
	},
});

export const PRODUCT_SKU_CONNECTION_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'PRODUCT_SKU_CONNECTION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		PRODUCT_SKU_CONNECTION: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
