import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { updateToNewProductTypes } from 'common/api/dataUpdates';
import { buildQuery, createBasicDbOperations } from '../utils';
import { ProductApi } from 'common/types';

export const getProductQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.products;
	const dataUpdater = updateToNewProductTypes;
	const query = buildQuery<ProductApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<ProductApi>(collectionPath, dbRefs, dataUpdater),
	};
};
