import { Channel, GoogleAnalytics } from 'common/types';
import { Events, EventName, EcomEventName, UserProperties, EcomEvent, OnboardingEventName } from './types';
import { isEmpty } from 'lodash';
import { getFormattedUrlPath } from './utils';
import errorHandler from '../errorHandling/errorHandler';
import { isDevEnv } from 'common/utils/common';
import * as Gtag from './gtag';
import * as Gtm from './gtm';
import { mapToUaEvent } from './ecomEventMapping';
import { isLocalhost } from 'common/utils/browserUtils';
import { analytics as FirebaseAnalytics, CustomParams } from 'common/frontend/firebase/analytics';
import * as IntercomService from './intercom';
import * as UserflowService from './userflow';
import * as PosthogService from './posthog';

export const logEvent = <T extends Exclude<EventName, EcomEventName>>(name: T, params?: Events[T]): void => {
	const eventName = name as string;
	FirebaseAnalytics.logEvent(eventName, params);
}

const getEcomEventLabel = <T extends EcomEventName>(event: EcomEvent<T>) => {
	const { name, params } = event;
	let eventLabel = undefined;
	const eventsWithItemsEventLabel: EcomEventName[] = ['add_to_cart', 'add_to_wishlist', 'remove_from_cart', 'select_item', 'view_item'];
	if (eventsWithItemsEventLabel.includes(name)) {
		eventLabel = params?.items?.length === 1 ? params.items[0].item_name : undefined;
	}
	if (name === 'view_item_list') {
		const params = (event as EcomEvent<'view_item_list'>).params;
		eventLabel = params?.item_list_name;
	}
	if (name === 'select_promotion') {
		const params = (event as EcomEvent<'select_promotion'>).params;
		eventLabel = params?.promotion_name;
	}
	if (name === 'view_promotion') {
		const params = (event as EcomEvent<'view_promotion'>).params;
		eventLabel = params?.promotion_name;
	}
	return eventLabel;
}

export const logEcomEvent = <T extends EcomEventName>(event: EcomEvent<T>, shopGa?: GoogleAnalytics): void => {
	const { name, params } = event;
	const eventName = name as string;
	const eventLabel = getEcomEventLabel(event);
	FirebaseAnalytics.logEvent(eventName, params);
	// If we do debugging locally using prod, don't send analytics
	if (isLocalhost() && !isDevEnv()) return;
	if (shopGa && !isEmpty(shopGa)) {
		const { UA, GA4, GTM } = shopGa;
		if (!!GA4?.length) {
			Gtag.sendEvent(name, { 
				...(!!eventLabel && { event_label: eventLabel }),
				...params,
				send_to: GA4
			});
		}
		if (!!UA?.length) {
			const uaEvent = mapToUaEvent(event);
			if (uaEvent) {
				Gtag.sendEvent(uaEvent.name, {
					...(!!eventLabel && { event_label: eventLabel }),
					...uaEvent.params,
					send_to: UA
				});
			}
		}
		if (!!GTM?.length) {
			Gtm.sendEcomEvent(event);
		}
	}
}

export const setGaUserProperties = (userProperties: UserProperties, opts: { sendToAllTags: boolean } ) => {
	const { user_id, ...customUserProperties } = userProperties;
	if (user_id) {
		FirebaseAnalytics.setUserId(user_id);
		if (!!opts.sendToAllTags) {
			Gtag.setUserId(user_id);
			Gtm.sendUserProperties({user_id});
		}
	}
	if (!isEmpty(customUserProperties)) {
		const params: CustomParams = { ...userProperties };
		FirebaseAnalytics.setUserProperties(params);
		if (!!opts.sendToAllTags) {
			Gtag.setUserProperties(params);
			Gtm.sendUserProperties(params);
		}
	}
};

export const pageView = async (url: string, channel: Channel, shopGa?: GoogleAnalytics) => {
	const formattedUrl = channel === 'ADMIN' ? getFormattedUrlPath(url) : url;
	const pageViewParams = { page_path: formattedUrl };
	try {
		FirebaseAnalytics.logEvent('page_view', pageViewParams);
	} catch (e) {
		errorHandler.report(e);
	}
	// If we do debugging locally using prod, don't send analytics
	if (isLocalhost() && !isDevEnv()) return;
	if (shopGa && !isEmpty(shopGa)) {
		try {
			const { UA, GA4, GTM } = shopGa;
			if (!!GA4?.length) {
				Gtag.sendPageView({ ...pageViewParams, send_to: GA4 });
			}
			if (!!UA?.length) {
				Gtag.sendPageView({ ...pageViewParams, send_to: UA });
			}
			if (!!GTM?.length) {
				Gtm.sendPageView({ ...pageViewParams });
			}
		} catch (e) {
			errorHandler.report(e);
		}
	}
};

export const newOnboardingClickEvent = (params: Events['onboarding_action_press']) => {
	logEvent('onboarding_action_press', params);
	Gtm.sendEvent('onboarding_action_press', params);
	IntercomService.trackEvent(IntercomService.ONBOARDING_EVENT, { onboarding_action: params.action });
	PosthogService.logEvent('onboarding_action_press', params);
}

export const newOnboardingActionCompletedEvent = (event: OnboardingEventName) => {
	logEvent(event);
	Gtm.sendEvent(event);
	PosthogService.logEvent(event);
	UserflowService.trackEvent(event);
}
