import {
	BaseProductVariant,
	ProductVariant,
	StockSourceOption,
} from 'common/modules/inventory/types';
import { OrderProductSkuInfo } from 'common/modules/orders/products';
import { LocaleField, PartialVariant, ProductApi } from 'common/types';

import { newFirestoreId } from './newRentalUtils';
import { getProductNameWithVariant } from './productUtils';

export const oldVariantToNewVariant = (
	variant: PartialVariant,
	newVariantProperties: { id: string; name: LocaleField }[],
): ProductVariant => {
	const values = variant.variantValues.reduce((tot, value, index) => {
		const fieldId = newVariantProperties[index].id ?? newFirestoreId();
		return {
			...tot,
			[fieldId]: value,
		};
	}, {});
	return {
		id: variant._newProductVariantId ?? newFirestoreId(),
		...(variant.internalId && { internalId: variant.internalId }),
		values,
		sales: {
			enabled: false,
			priceOverride: null,
		},
		rentals: {
			enabled: true,
			priceIncrease:
				!variant.priceIncrease && variant.priceIncrease !== 0 ? null : variant.priceIncrease,
		},
		// TODO Check
		stockSources: null,
	};
};

export const initialStockFromVariant = (
	skuOption: StockSourceOption,
	product: ProductApi,
	selectedVariant: BaseProductVariant,
	itemCode: string | null,
): OrderProductSkuInfo => {
	if (skuOption.type === 'item') {
		return {
			skuId: skuOption.skuId,
			name: getProductNameWithVariant({ ...product, variant: selectedVariant }, 'def'),
			units: 1,
			type: skuOption.type,
			items: [
				{
					code: itemCode,
				},
			],
		};
	}
	return {
		skuId: skuOption.skuId,
		name: getProductNameWithVariant({ ...product, variant: selectedVariant }, 'def'),
		units: 1,
		type: skuOption.type,
	};
};
