import errorHandler from '../errorHandling/errorHandler';
import { PageViewOptions } from './gtag';
import { EcomEvent, EcomEventName, EventName, UserProperties } from './types';

export const GTM_DATA_LAYER_NAME = 'gtmLayer';

declare global {
	interface Window {
		gtmLayer: any;
	}
}

export const sendPageView = (options: PageViewOptions) => {
	if (!window.gtmLayer) {
		errorHandler.report('gtmLayer not defined');
		return;
	}
	window.gtmLayer.push({
		event: 'page_view',
		...options,
	});
};

export const sendEvent = (event: EventName, params?: object) => {
	if (!window.gtmLayer) {
		errorHandler.report('gtmLayer not defined');
		return;
	}
	window.gtmLayer.push({ event: event, ...params });
};

export const sendEcomEvent = <T extends EcomEventName>(event: EcomEvent<T>) => {
	const { name, params } = event;
	if (!window.gtmLayer) {
		errorHandler.report('gtmLayer not defined');
		return;
	}
	window.gtmLayer.push({ ecommerce: null });
	window.gtmLayer.push({
		event: name,
		...(!!params && { ecommerce: params }),
	});
};

export const sendUserProperties = (params?: UserProperties) => {
	if (!window.gtmLayer) {
		errorHandler.report('gtmLayer not defined');
		return;
	}
	window.gtmLayer.push({ event: 'user_properties', user_properties: { ...params } });
};
