import React, { useMemo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { get, groupBy, sortBy } from 'lodash';

import { OnboardingSections } from 'common/services/analytics/tractionAnalytics';
import { useTranslation } from 'services/localization';
import { OnboardingStep } from 'services/onboarding';
import { ONBOARDING_SECTIONS } from 'services/onboarding/sections';

import OnboardingListHeader from './OnboardingListHeader';
import OnboardingStepGrid from './OnboardingStepGrid';
import OnboardingStepList from './OnboardingStepList';

type OnboardingListProps = {
	onboardingSteps: OnboardingStep[];
	hasUserflowChecklist: boolean;
};

const OnboardingList = (props: OnboardingListProps) => {
	const { onboardingSteps, hasUserflowChecklist } = props;
	const { t } = useTranslation();
	const onboardingItems = onboardingSteps.flatMap((step) => step.items);
	const allItemsCompleted = onboardingItems.every((item) => item.done);
	const [showOnboarding, toggleShowOnboarding] = useState(!allItemsCompleted);

	const groupedOnboardingSteps = useMemo(
		() => groupBy(onboardingSteps, (step) => get(step, 'section', OnboardingSections.NO_SECTION)),
		[onboardingSteps],
	);

	const onboardingSections = useMemo(() => sortBy(ONBOARDING_SECTIONS(t), 'orderIndex'), [t]);

	const firstNotCompletedListStep = (() => {
		const notCompletedSection = onboardingSections.find((section) =>
			groupedOnboardingSteps[section.id]?.some(
				(s) => s.type === 'list' && s.items.some((item) => !item.done),
			),
		);
		if (!!notCompletedSection) {
			return groupedOnboardingSteps[notCompletedSection.id].find(
				(s) => s.type === 'list' && s.items.some((item) => !item.done),
			);
		}
		return undefined;
	})();

	const renderStep = ({
		step,
		index,
		isListOpen,
	}: {
		step: OnboardingStep;
		index: number;
		isListOpen: boolean;
	}) => {
		switch (step.type) {
			case 'list':
				return <OnboardingStepList key={step.name + index} step={step} isListOpen={isListOpen} />;
			case 'grid':
				return <OnboardingStepGrid key={step.name + index} step={step} />;
		}
	};

	return (
		<>
			{!hasUserflowChecklist && (
				<OnboardingListHeader
					showOnboarding={showOnboarding}
					toggleShowOnboarding={toggleShowOnboarding}
					onboardingItems={onboardingItems}
				/>
			)}
			{showOnboarding && (
				<Stack spacing={3} mb={3}>
					{onboardingSections.map((section) => {
						const showSectionTitle =
							section.id !== OnboardingSections.NO_SECTION &&
							section.id !== OnboardingSections.HIGHLIGHTED_FEATURES &&
							!!section.title?.length;
						const steps = groupedOnboardingSteps[section.id];
						return !!steps?.length ? (
							<Box key={section.id}>
								{showSectionTitle && (
									<Typography variant="body1" color="secondary" mb={2}>
										{section.title}
									</Typography>
								)}
								{groupedOnboardingSteps[section.id].map((step, index) => {
									return renderStep({
										step,
										index,
										isListOpen: step.name === firstNotCompletedListStep?.name,
									});
								})}
							</Box>
						) : null;
					})}
				</Stack>
			)}
		</>
	);
};

export default OnboardingList;
