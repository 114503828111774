import { ReceiptApi } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { updateToNewReceiptTypes } from './dataUpdater';

export const getReceiptQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.receipts;
	const dataUpdater = updateToNewReceiptTypes;
	const query = buildQuery<ReceiptApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<ReceiptApi>(collectionPath, dbRefs, dataUpdater),
	};
};
