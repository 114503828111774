import { Link, Typography } from '@mui/material';

import { RENTLE_SHOP_PRIVACY_URL, RENTLE_SHOP_TERMS_URL } from 'common/constants/urls';
import { Trans } from 'services/localization';

export const TermsAcceptanceText = () => {
	return (
		<Typography color="secondary" variant="body2">
			<Trans
				i18nKey="register.agreeToTerms"
				defaults="By proceeding, you agree to the <terms>Terms of Service</terms> and <privacy>Privacy Policy</privacy>"
				components={{
					terms: (
						<Link
							sx={{
								color: (theme) => theme.palette.primary.main,
								textDecoration: 'none',
							}}
							href={RENTLE_SHOP_TERMS_URL}
							target="_blank"
							rel="noopener noreferrer"
						>
							{'Terms of Service'}
						</Link>
					),
					privacy: (
						<Link
							sx={{
								color: (theme) => theme.palette.primary.main,
								textDecoration: 'none',
							}}
							href={RENTLE_SHOP_PRIVACY_URL}
							target="_blank"
							rel="noopener noreferrer"
						>
							{'Privacy Policy'}
						</Link>
					),
				}}
			/>
		</Typography>
	);
};
