import { AccessClaims, Role } from 'common/types';

export const DEFAULTS_BY_ROLE: Record<Role, AccessClaims> = {
	rentle_support: {
		roles: ['rentle_support', 'admin', 'manager', 'editor', 'member'],
		permissions: ['modify_payments', 'allow_overbookings'],
	},
	admin: {
		roles: ['admin', 'manager', 'editor', 'member'],
		permissions: ['modify_payments', 'allow_overbookings'],
	},
	manager: {
		roles: ['manager', 'editor', 'member'],
		permissions: ['allow_overbookings'],
	},
	editor: {
		roles: ['editor', 'member'],
		permissions: [],
	},
	member: {
		roles: ['member'],
		permissions: ['edit_booking', 'cancel_booking'],
	},
};
