import { getOpenApiExample } from 'common/utils/openApi';

import { zOrderDeliveryApi } from './api';
import { toApi } from './mapper';

export const OrderDelivery = {
	api: zOrderDeliveryApi,
	toApi,
	example: getOpenApiExample(zOrderDeliveryApi),
};
