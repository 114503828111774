import { countBy, uniq } from 'lodash';
import moment from 'moment-timezone';

import { Api } from 'common/db/api/paths';
import {
	ItemPricing,
	ItemPricingWithoutCurrency,
	getExtendedItemPricing,
	getTotalItemPricing,
	recalculateItemPricingFromListPrice,
} from 'common/modules/atoms/pricing';
import {
	PublicDiscountCode,
	getDiscountCodeValueForOrder,
	isProductEntitledForDiscountCode,
} from 'common/modules/discountCodes';
import { AllocationType, BaseProductVariant, StockSourceOption } from 'common/modules/inventory';
import { InventoryItemApi } from 'common/modules/inventory/InventoryItemApi';
import { getProductVariantsBySkuId, getProductsBySkuId } from 'common/modules/products/utils';
import { getVariantSkuOption } from 'common/modules/products/variants';
import {
	CartProduct,
	Duration,
	OrderProduct,
	PartialWithId,
	ProductApi,
	PurchaseType,
	PurchaseTypes,
	SetProduct,
} from 'common/types';
import { notNull, notUndefined, switchUnreachable } from 'common/utils/common';
import { assignPartialData } from 'common/utils/partialData';
import { calculateProportionOfDiscount } from 'common/utils/pricing';
import { getProductNameWithVariant } from 'common/utils/productUtils';

import { isSkuInfoBulk } from './typeGuards';
import { OrderProductReturnStatus, OrderProductSkuInfoSingle, OrderProductStock } from './types';

export interface MappedDataWithChanges<T> {
	full: T;
	changes: T extends Array<infer E> ? PartialWithId<E>[] : PartialWithId<T>;
}

export const getUniqueCategoryIds = (products: OrderProduct[]) => {
	return uniq(products.flatMap((p) => p.categoryIds).filter(notUndefined));
};

export const addDiscountToProducts = (
	products: OrderProduct[],
	discountAmount: number,
): MappedDataWithChanges<OrderProduct[]> => {
	const subtotalsWithoutManualDiscount = products.map(
		(p) => getExtendedItemPricing(p.pricing).subtotalWithoutManualDiscount,
	);
	const partialChanges: PartialWithId<OrderProduct>[] = products.map((product, index) => {
		const firstProduct = index === 0;
		const productSubtotalWithoutManualDiscount = getExtendedItemPricing(product.pricing)
			.subtotalWithoutManualDiscount;
		const discountForProduct = calculateProportionOfDiscount(
			productSubtotalWithoutManualDiscount,
			subtotalsWithoutManualDiscount,
			discountAmount || 0,
			firstProduct,
		);
		const newPricing = recalculateItemPricingFromListPrice({
			...product.pricing,
			manualDiscount: discountForProduct,
		});
		return {
			id: product.id,
			data: {
				pricing: newPricing,
			},
		};
	});
	return {
		changes: partialChanges,
		full: assignPartialData(products, partialChanges),
	};
};

export const applyDiscountCodeToOrderProducts = (
	discountCode: PublicDiscountCode,
	products: OrderProduct[],
): MappedDataWithChanges<OrderProduct[]> => {
	const productsToApplyDiscountFor = products.filter((p) =>
		isProductEntitledForDiscountCode(p, discountCode),
	);
	const totalProductAmountsWithoutDiscountCodes = productsToApplyDiscountFor.map(
		(p) => getExtendedItemPricing(p.pricing).subtotalWithoutDiscountCodes,
	);
	const totalDiscountAmount = getDiscountCodeValueForOrder(discountCode, products);
	const partialChanges: PartialWithId<OrderProduct>[] = productsToApplyDiscountFor.map(
		(product, index) => {
			const firstProduct = index === 0;
			const extendedPricing = getExtendedItemPricing(product.pricing);
			const discountFromCodeForProduct = calculateProportionOfDiscount(
				extendedPricing.subtotalWithoutDiscountCodes,
				totalProductAmountsWithoutDiscountCodes,
				totalDiscountAmount,
				firstProduct,
			);
			const newPricing = recalculateItemPricingFromListPrice({
				...product.pricing,
				discountCodes: {
					...product.pricing.discountCodes,
					[discountCode.code]: {
						quantity: 1,
						totalDiscountValue: discountFromCodeForProduct,
					},
				},
			});
			return {
				id: product.id,
				data: {
					pricing: newPricing,
				},
			};
		},
	);
	return {
		changes: partialChanges,
		full: assignPartialData(products, partialChanges),
	};
};

export function getTotalProductPricing(
	products: (OrderProduct | CartProduct)[],
): ItemPricing | ItemPricingWithoutCurrency;
export function getTotalProductPricing(
	products: (OrderProduct | CartProduct)[],
	fallbackCurrency: string,
): ItemPricing;
export function getTotalProductPricing(
	products: (OrderProduct | CartProduct)[],
	fallbackCurrency?: string,
): ItemPricing | ItemPricingWithoutCurrency {
	const productPricingArray = products.map((p) => p.pricing);
	if (!!fallbackCurrency) return getTotalItemPricing(productPricingArray, fallbackCurrency);
	return getTotalItemPricing(productPricingArray);
}

export const updateProductListPrice = (
	newListPrice: number,
	product: OrderProduct,
): MappedDataWithChanges<OrderProduct> => {
	const { id, pricing } = product;
	const newPricing = recalculateItemPricingFromListPrice({
		...pricing,
		listPrice: newListPrice,
	});
	const partialChange: PartialWithId<OrderProduct> = {
		id,
		data: {
			pricing: newPricing,
		},
	};
	return {
		changes: partialChange,
		full: assignPartialData(product, partialChange),
	};
};

export const getOrderProductWithSetProducts = (
	product: OrderProduct,
): [OrderProduct, ...SetProduct[]] => {
	return [product, ...(product.setProducts ?? [])];
};

export const getAllProductVariants = (
	mainVariant: BaseProductVariant | undefined,
	setProducts: SetProduct[],
): BaseProductVariant[] => {
	return [mainVariant, ...setProducts.map((p) => p.variant)].filter(notUndefined);
};

export const getOrderProductSkuId = (product: OrderProduct | SetProduct): string | undefined => {
	return Object.keys(product.stock)?.[0];
};

export const getStockProductIds = (products: OrderProduct[]) =>
	products.flatMap((p) => p.productApiIds);

export const getShopperIds = (products: OrderProduct[]) => products.map((p) => p.shopperId);

export const getVariantIds = (products: OrderProduct[]) =>
	products.flatMap((p) => p.summary.variantIds);

export const getSkuIds = (products: OrderProduct[]) => products.flatMap((p) => p.summary.skuIds);

export const getSkuInfosOfOrderProductWithSetProducts = (product: OrderProduct) => {
	const productWithSetProducts = getOrderProductWithSetProducts(product);
	return productWithSetProducts.flatMap((p) => getSkuInfos(p));
};

export const getSkuInfos = ({ stock }: { stock: OrderProductStock }) => {
	return Object.entries(stock ?? {}).map(([skuId, skuInfo]) => ({ ...skuInfo, skuId }));
};

export const requiresProductCode = (product: OrderProduct | SetProduct) => {
	const skuInfos = getSkuInfos(product);
	const variantSkuOption = product.variant ? getVariantSkuOption(product.variant) : null;
	return variantSkuOption?.type === 'item' || skuInfos.some((info) => info.type === 'item');
};

export const getProductStockCode = (product: OrderProduct | SetProduct) => {
	const skuInfos = getSkuInfos(product);
	return skuInfos.filter((info): info is OrderProductSkuInfoSingle => info.type === 'item')[0]
		?.items[0]?.code;
};

export const isRequiredProductCodeFilled = (product: OrderProduct | SetProduct) => {
	if (!requiresProductCode(product)) return true;
	return product.productCode != null && product.productCode !== '';
};

export const hasProductStockCodesFilled = (product: OrderProduct | SetProduct) => {
	const skuInfos = getSkuInfos(product);
	return skuInfos
		.filter((info): info is OrderProductSkuInfoSingle => info.type === 'item')
		.flatMap((info) => info.items)
		.every((item) => item.code != null && item.code !== '');
};

export const getItemCodesFromStock = ({ stock }: { stock: OrderProductStock }) => {
	const skuInfos = getSkuInfos({ stock });
	return skuInfos
		.filter((info): info is OrderProductSkuInfoSingle => info.type === 'item')
		.flatMap((info) => info.items)
		.map((item) => item.code)
		.filter(notNull);
};

export const getItemCodesFromOrderProducts = (products: OrderProduct[]) => {
	const allProducts = products.flatMap(getOrderProductWithSetProducts);
	const allStocks: OrderProductStock[] = allProducts.map((p) => p.stock);
	return allStocks.flatMap((s) => getItemCodesFromStock({ stock: s }));
};

export const getSkuIdsFromStock = ({ stock }: { stock: OrderProductStock }) => {
	const skuInfos = getSkuInfos({ stock });
	return skuInfos.flatMap((info) => info.skuId);
};

const getInventoryItemsFromSingleSkuInfoItems = async (
	api: Api,
	opts: { items: OrderProductSkuInfoSingle['items']; shopId: string },
) => {
	const itemsHistory = await Promise.all(
		opts.items.map(async (item) => {
			const code = item.code;
			if (code == null) return undefined;
			const inventoryItem = await InventoryItemApi.get.singleItemByCode(api, {
				shopId: opts.shopId,
				code,
			});
			if (!inventoryItem) return undefined;
			return inventoryItem;
		}),
	);
	return itemsHistory.filter(notUndefined);
};

export const getInventoryItemsOfProducts = async (api: Api, products: OrderProduct[]) => {
	const firstProduct = products[0];
	if (!firstProduct) return [];
	const validInventoryItemsFromSkuInfo = await Promise.all(
		products.map(async (product) => {
			const { shopId, startLocationId: locationId, endDate, endDateReturned } = firstProduct;
			const skuInfos = getSkuInfosOfOrderProductWithSetProducts(product);
			const productEndDate = endDateReturned ?? endDate;
			const productInventoryItems = await Promise.all(
				skuInfos.map(async (skuInfo) => {
					if (isSkuInfoBulk(skuInfo)) {
						const inventoryItem = await InventoryItemApi.get.bulkItemBySkuId(api, {
							shopId,
							locationId,
							skuId: skuInfo.skuId,
						});
						if (!inventoryItem) return [];
						return [inventoryItem];
					} else {
						return getInventoryItemsFromSingleSkuInfoItems(api, { items: skuInfo.items, shopId });
					}
				}),
			);
			const validItems = productInventoryItems
				.flat()
				.filter((i) => !productEndDate || moment(i.createdAt).isBefore(moment(productEndDate)));
			return validItems;
		}),
	);
	return validInventoryItemsFromSkuInfo.flat();
};

export const getOrderProductStock = ({
	skuOption,
	product,
	itemCode,
}: {
	skuOption: StockSourceOption | null;
	product: OrderProduct | SetProduct;
	itemCode: string | null;
}): OrderProductStock => {
	if (!skuOption) return {};
	if (skuOption.type === 'item') {
		return {
			[skuOption.skuId]: {
				skuId: skuOption.skuId,
				name: getProductNameWithVariant(product, 'def'),
				units: 1,
				type: skuOption.type,
				items: [
					{
						code: itemCode,
					},
				],
			},
		};
	}
	return {
		[skuOption.skuId]: {
			skuId: skuOption.skuId,
			name: getProductNameWithVariant(product, 'def'),
			units: 1,
			type: skuOption.type,
		},
	};
};

export const getOrderProductSummary = (product: OrderProduct): OrderProduct['summary'] => {
	const nonRemovedSetProducts = product.setProducts.filter((p) => !p.removedFromParent);
	const updatedProductVariants = getAllProductVariants(product.variant, nonRemovedSetProducts);
	const updatedSkuIds = [product, ...(nonRemovedSetProducts ?? [])].map(getSkuIdsFromStock).flat();

	const allItemCodes = [product, ...(nonRemovedSetProducts ?? [])].flatMap(getItemCodesFromStock);

	return {
		variantIds: updatedProductVariants.map((v) => v.id),
		skuIds: updatedSkuIds,
		itemCodes: allItemCodes,
	};
};

export const getProductDuration = (orderProduct: OrderProduct): Duration => {
	return {
		durationInSeconds: orderProduct.rentalDurationInSeconds,
		durationType: orderProduct.durationType,
		durationName: orderProduct.durationName,
	};
};

export const getReservedProductIdsCountFromSkuIds = (
	skuIds: string[],
	stockProducts: ProductApi[],
) => {
	const allProductIds = skuIds
		.flatMap((skuId) => getProductsBySkuId(stockProducts, skuId))
		.map((p) => p.id);
	return countBy(allProductIds);
};

export const getReservedVariantIdsCountFromSkuIds = (
	skuIds: string[],
	stockProducts: ProductApi[],
) => {
	const allVariantIds = skuIds
		.flatMap((skuId) => getProductVariantsBySkuId(stockProducts, skuId))
		.map((v) => v.id);
	return countBy(allVariantIds);
};

export const getVariantIdsWithSku = (skuId: string, products: OrderProduct[]) => {
	return products
		.flatMap(getOrderProductWithSetProducts)
		.filter((p) => getOrderProductSkuId(p) === skuId)
		.map((p) => p.variant?.id)
		.filter(notUndefined);
};

export const isSalesProduct = (product: OrderProduct | SetProduct) =>
	isSalesPurchaseType(product.purchaseType);
export const isSalesPurchaseType = (type: PurchaseType | undefined | null) =>
	type === PurchaseTypes.sales;

export const isRentalProduct = (product: OrderProduct | SetProduct) =>
	isRentalPurchaseType(product.purchaseType);

export const isRentalPurchaseType = (type: PurchaseType | undefined | null) => {
	return type === PurchaseTypes.rental;
};

export const isSubscriptionProduct = (product: OrderProduct | SetProduct) =>
	isSubscriptionPurchaseType(product.purchaseType);
export const isSubscriptionPurchaseType = (type: PurchaseType | undefined | null) =>
	type === PurchaseTypes.subscription;

export const getAllocationType = (
	purchaseType: PurchaseType | undefined | null,
): AllocationType => {
	switch (purchaseType) {
		case PurchaseTypes.sales:
			return 'sales';
		case PurchaseTypes.rental:
		case PurchaseTypes.subscription:
			return 'rental';
		default:
			return 'rental';
	}
};

export const includesSingleInventoryStock = (orderProduct: OrderProduct | SetProduct) =>
	Object.values(orderProduct.stock).some((s) => s.type === 'item');

export const includesBulkInventoryStock = (orderProduct: OrderProduct | SetProduct) =>
	Object.values(orderProduct.stock).some((s) => s.type === 'bulk');

export const getOrderProductReturnStatus = (args: {
	purchaseType: PurchaseType;
	returnedDate: string | null | undefined;
	fulfillmentDate: string | null | undefined;
}): OrderProductReturnStatus => {
	const { purchaseType, returnedDate, fulfillmentDate } = args;
	switch (purchaseType) {
		case PurchaseTypes.sales:
			return !!fulfillmentDate ? (!!returnedDate ? 'restocked' : 'fulfilled') : 'none';
		case PurchaseTypes.rental:
		case PurchaseTypes.subscription:
			return !!returnedDate ? 'returned' : 'none';
		default:
			return switchUnreachable(purchaseType);
	}
};

export const isFixedPriceOrderProduct = (product: OrderProduct) => {
	return product.rentalDurationInSeconds === 0;
};

/**
 * Helper for assigning an item code to order product.
 * NOTE: This does not currently support some cases, such as:
 * - Updating the DIN and sole length of products that support DIN settings
 *
 */
export const assignItemCodeToOrderProduct = (args: {
	orderProduct: OrderProduct;
	itemCode: string;
	setIndex: number | null;
}): PartialWithId<OrderProduct> | undefined => {
	const { orderProduct, setIndex, itemCode } = args;
	const orderProductId = orderProduct.id;
	const productToUpdate =
		setIndex != null ? orderProduct?.setProducts.find((_, i) => i === setIndex) : orderProduct;
	if (!productToUpdate) {
		return undefined;
	}
	const skuOption = productToUpdate.variant ? getVariantSkuOption(productToUpdate.variant) : null;
	const updatedStock = getOrderProductStock({
		skuOption,
		product: productToUpdate,
		itemCode,
	});
	let updatedPartialProduct: Partial<OrderProduct | SetProduct> = {
		productCode: itemCode,
		stock: updatedStock,
	};
	if (setIndex != null) {
		const setProducts = orderProduct.setProducts.map((setProduct, index) => {
			if (setIndex === index) {
				return {
					...setProduct,
					...updatedPartialProduct,
				};
			}
			return setProduct;
		});
		const productWithNewSetProducts: OrderProduct = {
			...orderProduct,
			setProducts,
		};
		const newSummary = getOrderProductSummary(productWithNewSetProducts);
		return {
			id: orderProductId,
			data: {
				setProducts,
				summary: newSummary,
				includedVariantIds: newSummary.skuIds,
			},
		};
	} else {
		const updatedProduct: OrderProduct = {
			...orderProduct,
			...updatedPartialProduct,
		};
		const newSummary = getOrderProductSummary(updatedProduct);
		return {
			id: orderProductId,
			data: {
				...updatedPartialProduct,
				summary: newSummary,
				includedVariantIds: newSummary.skuIds,
			},
		};
	}
};
