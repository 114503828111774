import { TFunction } from 'react-i18next';

import {
	OnboardingListVersion,
	OnboardingSections,
	ShopAnalytics,
} from 'common/services/analytics/tractionAnalytics';

import { OnboardingStep, OnboardingStepItem } from '../../types';
import { getOnboardingItemFromOnboardingStepItem } from '../../utils';

const STEP_VERSIONS = ({
	items,
	shopAnalytics,
	t,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
}): Record<'V0', OnboardingStep> => ({
	V0: {
		name: t(
			'homeView.onboarding.checkPremiumFeaturesToPowerUpYourStore',
			'Check premium features to power up your store',
		),
		section: OnboardingSections.HIGHLIGHTED_FEATURES,
		type: 'grid',
		items: items.map((item) => getOnboardingItemFromOnboardingStepItem(item, shopAnalytics)),
	},
});

export const HIGHLIGHTED_FEATURES_STEP = ({
	items,
	shopAnalytics,
	t,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
}): { all: OnboardingStep } | { [key in OnboardingListVersion]?: OnboardingStep } => {
	const versions = STEP_VERSIONS({ items, shopAnalytics, t });
	return {
		V3_USERFLOW: versions['V0'],
		V3: versions['V0'],
		V2_TEMPLATE: versions['V0'],
		V2_NO_TEMPLATE: versions['V0'],
	};
};
