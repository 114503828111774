import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { ShopBalancesApi } from 'common/types';

import { createBasicDbOperations } from '../utils';

export const getShopBalancesQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.shopBalances;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<ShopBalancesApi>(collectionPath, dbRefs, dataUpdater),
	};
};
