import { Dictionary, groupBy } from 'lodash';

import { ItemsPerPeriod, OrderProduct } from 'common/types';

interface Item {
	date: string;
	products: {
		product: OrderProduct;
		quantity: number;
	}[];
}

export const getProductsByDate = (products: OrderProduct[]) =>
	groupBy(products, (p) => `${p.startDate}_${p.endDate}`);

export const getItemsByDate = (productsByDate: Dictionary<OrderProduct[]>): Item[] => {
	return Object.entries(productsByDate).map(([date, products]) => {
		const uniqueProducts = groupBy(
			products,
			(p) => `${p.productApiId}_${p.summary.variantIds.join(',')}`,
		);

		return {
			date,
			products: Object.entries(uniqueProducts).map(([, products]) => ({
				product: products[0],
				quantity: products.length,
			})),
		};
	});
};

export const getItemsPerPeriod = (
	items: Item[],
	startDate: string | null,
	endDate: string | null,
): ItemsPerPeriod[] => {
	return items.map((item) => {
		const reservationItems = item.products.map((productWithQuantity) => {
			const { quantity, product } = productWithQuantity;
			const variantIds = product.summary.variantIds;
			const skuIds = product.summary.skuIds;
			const unavailable = product.unavailable;
			return {
				quantity,
				variantIds,
				skuIds,
				productId: product.productApiId,
				unavailable,
			};
		});
		return {
			startDate,
			endDate,
			items: reservationItems,
		};
	});
};
