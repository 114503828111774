import { getOpenApiExample } from 'common/utils/openApi';

import { zOrderPaymentApi } from './api';
import { toApi } from './mapper';

export const OrderPayment = {
	toApi,
	api: zOrderPaymentApi,
	example: getOpenApiExample(zOrderPaymentApi),
};
