import React, { useState } from 'react';

import {
	Box,
	Button,
	Collapse,
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Stack,
	Typography,
	styled,
	useMediaQuery,
} from '@mui/material';
import { RiArrowDownSLine, RiArrowUpSLine, RiCheckFill } from 'react-icons/ri';
import { useStyles } from 'tss-react/mui';

import BackdropDialog from 'common/components/BackdropDialog';
import { OnboardingItem, OnboardingStepItemActionVideo } from 'services/onboarding';

type Props = {
	item: OnboardingItem;
};
const OnboardingItemRowVideo = (props: Props) => {
	const { item } = props;
	const { content, onClick, done, action: _action } = item;
	const action = _action as OnboardingStepItemActionVideo;
	const { title } = content;
	const { theme } = useStyles();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isMobile = useMediaQuery(theme.breakpoints.down(600));
	const [collapsed, setCollapsed] = useState(!done);
	const [showVideoModal, setShowVideoModal] = useState(false);

	const CollapsedContent = (() => {
		return (
			<Stack
				direction={isSmallScreen ? 'column' : 'row'}
				alignItems="flex-start"
				spacing={isSmallScreen ? 3 : 2}
			>
				<Stack>
					{item.content.descriptions?.map((description, index) => (
						<Typography
							key={`${description}_${index}`}
							mb={index !== item.content.descriptions!.length - 1 ? 2 : 0}
						>
							{description}
						</Typography>
					))}
					<Box sx={{ mt: 2 }} />
					<Box>
						<Button
							variant="contained"
							onClick={() => {
								setShowVideoModal(true);
								onClick();
							}}
						>
							{item.content.cta}
						</Button>
					</Box>
				</Stack>
				<Box
					sx={{
						width: '100%',
						height: isSmallScreen && !isMobile ? 400 : 200,
						position: 'relative',
					}}
				>
					<OverlayedClickBox
						onClick={() => {
							setShowVideoModal(true);
							onClick();
						}}
					/>
					<iframe
						src={action.value.url}
						title={title}
						width="100%"
						height="100%"
						allowFullScreen
						frameBorder="0"
					/>
				</Box>
			</Stack>
		);
	})();

	return (
		<>
			<ListItem
				divider={true}
				sx={{
					p: 2,
					alignItems: 'flex-start',
				}}
			>
				<StyledIcon>{done && <RiCheckFill color={theme.palette.success.main} />}</StyledIcon>

				<ListItemText
					sx={{ pr: 4.5, my: 0, fontWeight: 400 }}
					disableTypography // Needed to override the fontweight (https://stackoverflow.com/questions/43975839/material-ui-styling-text-inside-listitemtext)
					primary={
						<>
							<Typography
								variant="body1"
								sx={{
									fontWeight: 400,
									color: done ? theme.palette.text.tertiary : theme.palette.text.primary,
									'&:hover': {
										cursor: 'pointer',
									},
								}}
								onClick={() => {
									setCollapsed(!collapsed);
								}}
							>
								{title}
							</Typography>
							<Collapse
								in={collapsed}
								sx={{
									m: (theme) => (collapsed ? theme.spacing(4, 0) : undefined),
									pr: 4.5,
									opacity: collapsed ? 1 : 0,
								}}
							>
								{CollapsedContent}
							</Collapse>
						</>
					}
				/>
				<ListItemSecondaryAction
					sx={{
						right: theme.spacing(4),
						my: 0,
						top: 32,
					}}
				>
					<IconButton
						edge="end"
						aria-label="collapse"
						size="large"
						onClick={() => {
							setCollapsed(!collapsed);
						}}
					>
						{collapsed ? <RiArrowUpSLine size={24} /> : <RiArrowDownSLine size={24} />}
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
			{showVideoModal && (
				<BackdropDialog
					isOpen={showVideoModal}
					onClose={() => setShowVideoModal(false)}
					variant="primary"
					titleCloseButton
					label={title}
					width="md"
					body={
						<iframe
							src={action.value.url}
							title={title}
							width="100%"
							height="100%"
							style={{ minHeight: 500 }}
							allowFullScreen
							frameBorder="0"
						/>
					}
				/>
			)}
		</>
	);
};

const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
	height: '24px',
	width: '40px',
	minWidth: '40px',
	marginRight: theme.spacing(2),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const OverlayedClickBox = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100%',
	zIndex: 1100,
	position: 'absolute',
	'&:hover': {
		cursor: 'pointer',
	},
}));

export default OnboardingItemRowVideo;
