import type { Query } from 'common/frontend/firebase/firestore';

import type {
	AdminDbBatch,
	AdminDbTransaction,
	ClientDb,
	ClientDbBatch,
	ClientDbRefs,
	ClientDbTransaction,
	ClientQueryBuilderRefs,
	Db,
	DbRefs,
	QueryBuilderRefs,
} from '../types';

export const isOptsType = (data: { withDocId: true } | any): data is { withDocId: true } => {
	return !!data?.withDocId;
};

export const isClientDb = (db: Db): db is ClientDb => {
	if ((db as ClientDb).app !== undefined) {
		return true;
	}
	return false;
};

export const isClientQuery = (ref: Query | FirebaseFirestore.Query): ref is Query => {
	if (isClientDb(ref.firestore)) {
		return true;
	}
	return false;
};

/**
 * Type guard for checking if frontend or backend options passed
 * @param refs passed db references
 */
export const isClientDbRefs = (refs: DbRefs): refs is ClientDbRefs => {
	if (isClientDb(refs.db)) {
		return true;
	}
	return false;
};

export const isClientQueryRefs = <T extends object>(
	refs: QueryBuilderRefs<T>,
): refs is ClientQueryBuilderRefs<T> => {
	if (isClientDbRefs(refs.dbRefs)) {
		return true;
	}
	return false;
};

/**
 * Type guard for checking if frontend or backend options passed
 * @param refs passed db references
 */
export const isBatch = (
	batchOrTransaction: ClientDbBatch | AdminDbBatch | ClientDbTransaction | AdminDbTransaction,
): batchOrTransaction is ClientDbBatch | AdminDbBatch => {
	// Batch functions do not have a "get" method, but only transactions do
	if (!!(batchOrTransaction as ClientDbTransaction | AdminDbTransaction).get) {
		return false;
	}
	return true;
};
