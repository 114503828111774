import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

export const zBookingBufferApi = withOpenApi(
	z.object({
		daysBefore: z.number().int().nonnegative(),
		minutesBefore: z.number().int().min(0).max(60).optional(),
		latestTime: z.number().int().min(0).max(23).optional(),
	}),
	{
		title: 'Booking buffer',
		description: 'Defines how far in advance a booking can be made.',
		fields: {
			daysBefore: 'The number of days before a booking can be made',
			latestTime: {
				description:
					'The latest time of day a booking can be made, in 24 hour format. If daysBefore is 0, the latestTime is not taken into account.',
				example: 18,
			},
			minutesBefore:
				'The number of minutes before a booking can be made. If daysBefore is more than 0, the minutesBefore are not taken into account.',
		},
		example: {
			daysBefore: 1,
			latestTime: 18,
		},
	},
);
