import { z } from 'zod';

import { zCatalogVisibilityChannelApi } from 'common/models/_atoms/Channel/api';
import { LocaleField } from 'common/models/_atoms/LocaleField';
import { TimePeriod } from 'common/models/_atoms/TimePeriod';
import { withOpenApi } from 'common/utils/openApi';

export const zPricingItemApi = withOpenApi(
	z.object({
		// TODO We should return default label that we use when not defined
		label: LocaleField.api.optional(),
		timePeriod: TimePeriod.api,
		timeValue: z.number().int().nonnegative(),
		price: z.number().int().nonnegative(),
		channels: z.array(zCatalogVisibilityChannelApi).optional(),
	}),
	{
		fields: {
			label: 'Custom label of the pricing option.',
			timePeriod: 'Time period of the pricing option.',
			timeValue: 'Time value of the pricing option.',
			price: 'Price of the pricing option, in minor units.',
			channels:
				'List of channels the pricing option is limited to. If not defined, the option is available on all channels.',
		},
	},
);
