import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { DiscountCodeApi } from 'common/types';
import { ReduxState } from 'services/types';

export const discountCode = (state: ReduxState) => state.activeDiscount.discountCode;
export const discountCodeData = (state: ReduxState) => discountCode(state).data;
export const discountCodeLoading = (state: ReduxState) => discountCode(state).loading;
export const discountCodeError = (state: ReduxState) => discountCode(state).error;

export const localChanges = (state: ReduxState) => state.activeDiscount.localChanges;

export const isSaving = (state: ReduxState) => state.activeDiscount.saving;
export const error = (state: ReduxState) => state.activeDiscount.error;

export const discountCodeWithChanges = createSelector(
	discountCodeData,
	localChanges,
	(data, changes) => {
		return {
			...data,
			...changes,
		} as DiscountCodeApi;
	},
);

export const hasDiscountChanges = createSelector(
	discountCodeData,
	localChanges,
	(discountCode, localChanges) => {
		if (!discountCode) return false;
		return Object.keys(localChanges).some((field) => {
			return !isEqual(localChanges[field], discountCode[field]);
		});
	},
);
