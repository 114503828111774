import {
	AvailabilityFetchProps,
	ProductTotalAvailability,
} from 'common/api/frontend/inventory_new/types';

import { CallableCreator } from '..';

export interface GetProductCodesInUseArgs {
	skuId: string;
	startDate: string;
	endDate: string;
	shopId: string;
	excludeOrderId?: string;
}

export interface GetProductCodesInUseResult {
	productCodes: string[];
}

export interface IsProductCodeInUseArgs {
	code: string;
	startDate: string;
	endDate: string;
	shopId: string;
	excludeOrderId?: string;
}

export const availability = (createCallable: CallableCreator) => ({
	getQuantitiesAndAvailabilities: createCallable<AvailabilityFetchProps, ProductTotalAvailability>(
		'availability-getQuantitiesAndAvailabilities',
	),
	getProductCodesInUse: createCallable<GetProductCodesInUseArgs, GetProductCodesInUseResult>(
		'availability-getProductCodesInUse',
	),
	isProductCodeInUse: createCallable<IsProductCodeInUseArgs, boolean>(
		'availability-isProductCodeInUse',
	),
});
