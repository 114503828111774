import { DayType } from 'common/models/_atoms/DayType';
import { isFixed } from 'common/types/typeGuards';
import { notUndefined } from 'common/utils/common';

import { StartTimesApi, StartTimesDb } from './types';
import { isStartTimeFixed } from './utils';

export const toApi = (db: StartTimesDb): StartTimesApi => {
	if (isFixed(db)) {
		return {
			orderLimit: db.orderLimit ?? undefined,
			type: db.type,
			fixedTimes: db.fixedTimes
				.map((t) => {
					if (t.dayType === 'custom') {
						if (!t.customDate) return undefined;
						return {
							day: 'custom',
							customDate: t.customDate,
							time: t.time,
						} as const;
					}
					return {
						day: DayType.toApi(t.dayType),
						time: t.time,
					} as const;
				})
				.filter(notUndefined),
			allowTimesOutsideOpeningHours: db.outsideOpeningHours,
		};
	} else {
		return {
			orderLimit: db.orderLimit ?? undefined,
			type: db.type,
			intervalMinutes: db.intervalMinutes,
		};
	}
};

export const toDb = (api: StartTimesApi): StartTimesDb => {
	if (isStartTimeFixed(api)) {
		return {
			orderLimit: api.orderLimit ?? null,
			type: api.type,
			fixedTimes: api.fixedTimes.map((t) => {
				if (t.day === 'custom') {
					return {
						dayType: t.day,
						customDate: t.customDate,
						time: t.time,
					};
				}
				return {
					dayType: DayType.toDb(t.day),
					time: t.time,
				};
			}),
			outsideOpeningHours: api.allowTimesOutsideOpeningHours,
		};
	} else {
		return {
			orderLimit: api.orderLimit ?? null,
			type: api.type,
			intervalMinutes: api.intervalMinutes,
		};
	}
};
