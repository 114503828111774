import { CustomizerTheme } from 'common/modules/customization/types';
import { buildQuery, createBasicDbOperations, createBasicCollectionOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getCustomThemeQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.customThemes;
	const query = buildQuery<CustomizerTheme>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<CustomizerTheme>(collectionPath, dbRefs),
		...createBasicCollectionOperations<CustomizerTheme>(collectionPath, dbRefs),
	};
};
