import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'PRODUCT_CREATION' })
			: getRouteOnboardingStepItem({ routeTo: Routes.CATALOG_CREATE_PRODUCT }),
		content: {
			title: t('homeView.onboarding.createAProduct', 'Create a product'),
		},
		id: 'PRODUCT_CREATION',
		type: 'row',
	},
	V0: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'PRODUCT_CREATION' })
			: getRouteOnboardingStepItem({ routeTo: Routes.CATALOG_CREATE_PRODUCT }),
		content: {
			title: t('homeView.onboarding.createProductTitle', 'Create your first product'),
		},
		id: 'PRODUCT_CREATION',
		type: 'row',
	},
});

export const PRODUCT_CREATION_ITEM = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<
	'PRODUCT_CREATION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t, showProductTours });
	return {
		PRODUCT_CREATION: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V0'],
			V2_NO_TEMPLATE: versions['V0'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
