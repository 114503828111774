import { isEmpty, isEqual } from 'lodash';

import {
	CreateOrUpdateContact,
	addHubspotProperties,
	addHubspotSession,
} from 'common/api/db/functions';
import {
	removeTemplateCategories,
	removeTemplateProducts,
} from 'common/api/frontend/mockProducts/templates/utils';
import { auth } from 'common/frontend/firebase/auth';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { Category, ProductApi, ShopPublicInfo, ShopReadOnlyData } from 'common/types';
import { hasSameItems } from 'common/utils/arrays';
import { isDevEnv, removeUndefinedValues } from 'common/utils/common';
import { isOnlinePaymentsInUse } from 'common/utils/payments';
import { getShopLocationsWithContact } from 'common/utils/shopUtils';

import { ShopAnalytics } from './tractionAnalytics';

let hubspotPropertiesTimer: number | null = null;
let hubspotPropertyQueue: { [email: string]: CreateOrUpdateContact['properties'] } = {};

const sendHubspotProperties = async () => {
	const propertyQueue = hubspotPropertyQueue;
	for (const [email, properties] of Object.entries(propertyQueue)) {
		if (isEmpty(properties)) {
			continue;
		}
		try {
			if (isDevEnv()) {
				// Do not send Hubspot properties if we are in DEV environment.
				// Uncomment this for testing Hubspot properties in DEV
				// console.log(
				// 	`[MOCK] Setting new Hubspot properties for ${email}:`,
				// 	JSON.stringify(properties),
				// );
				hubspotPropertyQueue = {};
				return;
			}
			await addHubspotProperties({ email, properties });
		} catch (e) {
			errorHandler.report(e);
		}
	}
	hubspotPropertyQueue = {};
};

export const setHubspotProperties = async (
	_properties: CreateOrUpdateContact['properties'],
	email?: string,
) => {
	const properties = removeUndefinedValues(_properties);
	if (isEmpty(properties)) return;
	const userEmail = email ?? auth.currentUser?.email;
	if (userEmail) {
		hubspotPropertyQueue[userEmail] = {
			...(hubspotPropertyQueue[userEmail] ?? {}),
			...properties,
		};
		if (hubspotPropertiesTimer != null) {
			clearTimeout(hubspotPropertiesTimer);
			hubspotPropertiesTimer = null;
		}
		hubspotPropertiesTimer = window.setTimeout(sendHubspotProperties, 5000);
	}
};

export const addSession = async (email?: string) => {
	if (isDevEnv()) {
		return;
	}
	const userEmail = email ?? auth.currentUser?.email;
	if (userEmail) {
		try {
			await addHubspotSession({ email: userEmail });
		} catch (e) {
			errorHandler.report(e);
		}
	}
};

export const setShopPublicInfoHubspotProperties = ({
	oldData,
	newData,
}: {
	newData: ShopPublicInfo;
	oldData: ShopPublicInfo | undefined;
}) => {
	const hubspotProperties: CreateOrUpdateContact['properties'] = {};
	if (!isEqual(newData.activePaymentMethods, oldData?.activePaymentMethods)) {
		hubspotProperties.rentle_payments_in_use = isOnlinePaymentsInUse(
			newData.activePaymentMethods || [],
		)
			? 'true'
			: 'false';
	}
	if (newData.currency.code !== oldData?.currency.code) {
		hubspotProperties.rentle_currency = newData.currency.code;
	}
	if (newData.country !== oldData?.country) {
		hubspotProperties.rentle_country = newData.country;
	}
	const newLocationCount = getShopLocationsWithContact(newData).length;
	const oldLocationCount = oldData ? getShopLocationsWithContact(oldData).length : undefined;
	if (newLocationCount !== oldLocationCount) {
		hubspotProperties.rentle_location_count = newLocationCount;
	}
	if (newData.phone !== oldData?.phone) {
		hubspotProperties.rentle_shop_phone = newData.phone;
	}
	setHubspotProperties(hubspotProperties);
};

export const setShopReadOnlyHubspotProperties = ({
	oldData,
	newData,
}: {
	newData: ShopReadOnlyData;
	oldData: ShopReadOnlyData | undefined;
}) => {
	const hubspotProperties: CreateOrUpdateContact['properties'] = {};
	if (newData.features.plan.plan !== oldData?.features.plan.plan) {
		hubspotProperties.rentle_plan = newData.features.plan.plan;
	}
	setHubspotProperties(hubspotProperties);
};

export const setShopAnalyticsHubspotProperties = ({
	oldData,
	newData,
}: {
	newData: ShopAnalytics;
	oldData: ShopAnalytics | undefined;
}) => {
	const hubspotProperties: CreateOrUpdateContact['properties'] = {};
	if (
		!isEqual(newData.industryCategories?.values ?? [], oldData?.industryCategories?.values ?? [])
	) {
		hubspotProperties.rentle_industry_categories = newData.industryCategories?.values.join(';');
	}
	if (
		!hasSameItems(
			Object.keys(newData.onboardingActions ?? {}),
			Object.keys(oldData?.onboardingActions ?? {}),
		)
	) {
		hubspotProperties.rentle_onb_specific_steps = Object.keys(newData.onboardingActions ?? {}).join(
			';',
		);
		hubspotProperties.rentle_onb_completed_total = Object.keys(
			newData.onboardingActions ?? {},
		).length;
	}
	setHubspotProperties(hubspotProperties);
};

export const setProductsHubspotProperties = ({
	oldData,
	newData,
}: {
	newData: ProductApi[];
	oldData: ProductApi[];
}) => {
	const hubspotProperties: CreateOrUpdateContact['properties'] = {};
	const newProductCount = removeTemplateProducts(newData).length;
	const oldProductCount = removeTemplateProducts(oldData).length;
	if (newProductCount !== oldProductCount) {
		hubspotProperties.rentle_products_count = newProductCount;
	}
	setHubspotProperties(hubspotProperties);
};

export const setCategoriesHubspotProperties = ({
	oldData,
	newData,
}: {
	newData: Category[];
	oldData: Category[];
}) => {
	const hubspotProperties: CreateOrUpdateContact['properties'] = {};
	const newCategoryCount = removeTemplateCategories(newData).length;
	const oldCategoryCount = removeTemplateCategories(oldData).length;
	if (newCategoryCount !== oldCategoryCount) {
		hubspotProperties.rentle_categories_count = newCategoryCount;
	}
	setHubspotProperties(hubspotProperties);
};
