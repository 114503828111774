import { createQueryKeys } from '@lukemorales/query-key-factory';
import { produce } from 'immer';

import { api } from 'common/frontend/api';
import { BatchManager } from 'common/frontend/firebase/firestore';
import { AllocationType, SkuItem } from 'common/modules/inventory';

import { queryClient } from './client';
import {
	createUseMutation,
	createUseQuery,
	fnQueryKeys,
	queryDeleter,
	queryUpdater,
} from './utils';

const queries = {
	get: async (args: { id: string }): Promise<SkuItem | null> => {
		const { id } = args;

		const data = await api().skuItems.doc(id).get();
		return data ?? null;
	},
	listByShop: async (args: { shopId: string }): Promise<SkuItem[]> => {
		const { shopId } = args;

		return api().skuItems.get.where('shopId', '==', shopId).get();
	},
};

const mutations = {
	create: async (args: { skuItem: SkuItem }): Promise<void> => {
		const { skuItem } = args;

		await api().skuItems.doc(skuItem.id).set(skuItem);
	},
	update: async (args: { id: string; updates: Partial<SkuItem> }): Promise<void> => {
		const { id, updates } = args;

		await api().skuItems.doc(id).update(updates);
	},
	deleteMany: async (args: { ids: string[] }): Promise<void> => {
		const { ids } = args;
		const batchManager = new BatchManager();

		ids.forEach((id) => {
			api(batchManager.batch()).skuItems.doc(id).delete();
		});

		return batchManager.commit();
	},
};

const queryKeys = createQueryKeys('skuItems', {
	get: fnQueryKeys(queries.get),
	listByShop: fnQueryKeys(queries.listByShop),
});

export const get = createUseQuery(queries.get, queryKeys.get);
export const listByShop = createUseQuery(queries.listByShop, queryKeys.listByShop);

export const create = createUseMutation(mutations.create, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const update = createUseMutation(mutations.update, {
	onMutate: async ({ id, updates }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates,
				condition: (item) => item.id === id,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const deleteMany = createUseMutation(mutations.deleteMany, {
	onMutate: async ({ ids }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryDeleter({
				condition: (item: SkuItem) => ids.includes(item.id),
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

/**
 * Call this when adding articles to an sku at the same time as creating it, in order to optimistically
 * update the amount of articles in the sku item. These are updated on the server with a slight delay.
 */
export const handleArticlesAdded = (args: {
	skuId: string;
	articleCount: number;
	locationId: string;
	allocation: AllocationType;
}) => {
	const { skuId, articleCount, locationId, allocation } = args;
	queryClient.setQueriesData(
		queryKeys._def,
		queryUpdater({
			condition: (item: SkuItem) => item.id === skuId,
			updates: (item: SkuItem) => {
				return produce(item, (draft) => {
					draft.quantity = articleCount;
					draft.statusesByLocation = {
						[locationId]: {
							IN_USE: articleCount,
						},
					};
					draft.allocationsByLocation = {
						[locationId]: {
							sales: allocation === 'sales' ? articleCount : 0,
							rental: allocation === 'rental' ? articleCount : 0,
						},
					};
				});
			},
		}),
	);
};
