import React from 'react';

import { ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem } from '@mui/material';
import { IconType } from 'react-icons';
import { RiCheckLine } from 'react-icons/ri';

import useMenuState from 'common/hooks/useMenuState';

import SidebarButton from './SidebarButton';

export interface SidebarMenuItem {
	label: string;
	icon?: IconType;
	selected?: boolean;
	onClick?: () => void;
}

interface SidebarMenuProps {
	icon?: IconType;
	label?: string;
	items?: SidebarMenuItem[];
}

const SidebarMenu = (props: SidebarMenuProps) => {
	const { icon, label, items } = props;
	const { menuProps, menuActions } = useMenuState();
	return (
		<>
			<SidebarButton onClick={menuActions.open} icon={icon} label={label} />
			<Menu {...menuProps} onClick={menuActions.close}>
				{items?.map((item) => {
					const ItemIcon = item.icon;
					return (
						<MenuItem key={item.label} selected={item.selected} onClick={item.onClick}>
							{!!ItemIcon && (
								<ListItemIcon>
									<ItemIcon />
								</ListItemIcon>
							)}

							<ListItemText primary={item.label} />
							<ListItemSecondaryAction>
								{item.selected && <RiCheckLine size={20} />}
							</ListItemSecondaryAction>
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default SidebarMenu;
