import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.productVariants.unlimitedVariants',
			'Unlimited variants & combinations',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.productVariants.dynamicVariants',
			'Dynamic variant properties',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.productVariants.realtimeAvailability',
			'Real-time stock availability',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.productVariants.customizablePricing',
			'Customizable variant pricing',
		),
	},
];

export default valueProps;
