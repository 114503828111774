import {
	OnboardingItem,
	OnboardingListVersion,
	OnboardingStepDefinition,
} from 'common/services/analytics/tractionAnalytics';
import { switchUnreachable } from 'common/utils/common';

const BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS = (
	version: OnboardingListVersion,
): OnboardingItem[] => {
	switch (version) {
		case 'V3_USERFLOW':
			return [];
		case 'V3':
		case 'V2_TEMPLATE':
		case 'V2_NO_TEMPLATE':
			return ['BUSINESS_CONTACT_INFORMATION', 'WAIVER_AND_POLICY'];
		case 'V1_TEMPLATE':
		case 'V1_NO_TEMPLATE':
		case 'V0':
			return ['STORE_DETAILS', 'OPENING_HOURS', 'CANCELLATION_POLICY'];
		default:
			return switchUnreachable(version);
	}
};

export const BUSINESS_AND_CONTACT_INFORMATION_DEFINITION: Record<
	'BUSINESS_AND_CONTACT_INFORMATION',
	OnboardingStepDefinition
> = {
	BUSINESS_AND_CONTACT_INFORMATION: {
		plans: 'all',
		versions: {
			V3: BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS('V3'),
			V2_TEMPLATE: BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS('V2_TEMPLATE'),
			V2_NO_TEMPLATE: BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS('V2_NO_TEMPLATE'),
			V1_TEMPLATE: BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS('V1_TEMPLATE'),
			V1_NO_TEMPLATE: BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS('V1_NO_TEMPLATE'),
			V0: BUSINESS_AND_CONTACT_INFORMATION_INCLUDED_ITEMS('V0'),
		},
	},
};
