import { createQueryKeys } from '@lukemorales/query-key-factory';

import { Listing, createTrpcClient } from '@rentle/resell-api-client';

import { createUseMutation, createUseQuery, fnQueryKeys } from './utils';

const client = createTrpcClient();

const queries = {
	get: async (args: { id: string }): Promise<Listing | null> => {
		const { id } = args;

		const data = await client.listings.get.query(id);
		return data ?? null;
	},
	listAll: async (): Promise<Listing[]> => {
		const data = await client.listings.listAll.query();
		return data ?? [];
	},
};

const mutations = {
	create: async (args: { listing: Listing }): Promise<void> => {
		const { listing } = args;

		await client.listings.create.mutate(listing);
	},
	update: async (args: { id: string; updates: Partial<Listing> }): Promise<void> => {
		const { id, updates } = args;

		await client.listings.update.mutate({ id, update: updates });
	},
	delete: async (args: { id: string }): Promise<void> => {
		const { id } = args;

		await client.listings.delete.mutate(id);
	},
};

const queryKeys = createQueryKeys('listings', {
	get: fnQueryKeys(queries.get),
	listAll: fnQueryKeys(queries.listAll),
});

export const get = createUseQuery(queries.get, queryKeys.get);
export const create = createUseMutation(mutations.create, {});
export const listAll = createUseQuery(queries.listAll, queryKeys.listAll);
