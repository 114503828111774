import React, { useCallback, useState } from 'react';

import { Box, ButtonBase, Hidden, Stack, Typography, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { Detector } from 'react-detect-offline';
import { RiRefreshLine, RiSettings4Line, RiWifiLine, RiWifiOffLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import RentleLogo from 'common/components/RentleLogo';
import useCurrentTime from 'common/hooks/useCurrentTime';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import * as ViewActions from 'actions/ViewActions';
import HasFeature from 'components/features/HasFeature';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization/useTranslation';

const StatusBar = () => {
	const dispatch = useDispatch();
	const currentTime = useCurrentTime();
	const { localFormat } = useShopFormat();

	const formattedTime = localFormat(currentTime, 'dddd DD.MM. HH:mm');

	const [isChecking, setIsChecking] = useState<boolean>(false);

	const { t } = useTranslation();

	/** This is mainly to just give the user a feeling like they're doing something.
	 *  In reality it's always polling the online status.
	 */
	const startCheck = useCallback(() => {
		setIsChecking(true);
		setTimeout(() => {
			setIsChecking(false);
		}, 5000);
	}, []);

	const pollingConfig = {
		url: `/online-check.png?id=${newFirestoreId()}`,
		interval: 10000,
		timeout: 10000,
	};

	return (
		<Detector
			polling={pollingConfig}
			render={({ online }: { online: boolean }) => (
				<Box position="relative">
					<StatusBarWrapper>
						<StatusBarSection justifyContent="flex-start">
							<StatusBarText variant="caption" noWrap>
								{formattedTime}
							</StatusBarText>
						</StatusBarSection>
						<Hidden mdDown>
							<StatusBarSection justifyContent="center">
								<RentleLogo width={55} invert opacity={0.5} type="black" />
							</StatusBarSection>
						</Hidden>

						<StatusBarSection justifyContent="flex-end">
							<HasFeature requiredFeature="DEV">
								<Box
									mr={1}
									display="flex"
									alignItems="center"
									onClick={() => dispatch(ViewActions.setDevPanelOpen(true))}
								>
									<RiSettings4Line size={18} />
								</Box>
							</HasFeature>
							{online ? (
								<StatusBarText noWrap>
									<RiWifiLine size={18} />
									<span style={{ marginLeft: '3px' }}>
										{t('common:statusBar.online', 'Online')}
									</span>
								</StatusBarText>
							) : (
								<StatusBarText noWrap>
									<RiWifiOffLine size={18} />
									<span style={{ marginLeft: '3px' }}>
										{t('common:statusBar.offline', 'Offline')}
									</span>
								</StatusBarText>
							)}
						</StatusBarSection>
					</StatusBarWrapper>
					<OfflineIndicatorWrapper>
						<OfflineIndicator
							onClick={startCheck}
							animate={{
								y: !online ? 100 : 0,
							}}
						>
							{isChecking ? (
								<Typography variant="caption">
									{t('common:statusBar.checkingConnection', 'Checking connection')}
									{'...'}
								</Typography>
							) : (
								<>
									<Typography variant="caption" sx={{ mr: 2 }}>
										{t('common:statusBar.notConnected', 'Not connected')}
									</Typography>
									<RiRefreshLine size={16} />
								</>
							)}
						</OfflineIndicator>
					</OfflineIndicatorWrapper>
				</Box>
			)}
		/>
	);
};

const StatusBarWrapper = styled(Stack)(({ theme }) => ({
	background: theme.palette.common.black,
	color: theme.palette.common.white,
	flexDirection: 'row',
	height: '32px',
	padding: theme.spacing(0, 2),
	zIndex: theme.zIndex.appBar,
	position: 'relative',
	overflow: 'hidden',
}));

const StatusBarText = styled(Typography)(
	({ theme }) => ({
		fontSize: '12px',
		color: theme.palette.common.white,
	}),
	{
		noWrap: true,
	},
);

const StatusBarSection = styled(Stack)(({ theme }) => ({
	flexDirection: 'row',
	alignItems: 'center',
	flex: 1,
}));

const OfflineIndicatorWrapper = styled(motion(Box))(({ theme }) => ({
	position: 'absolute',
	top: '-50px',
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	zIndex: theme.zIndex.appBar - 1,
}));

const OfflineIndicator = styled(motion(ButtonBase))(({ theme }) => ({
	background: theme.palette.warning.light,
	borderColor: theme.palette.warning.main,
	borderWidth: '2px',
	borderStyle: 'solid',
	borderRadius: '2px',
	boxShadow: '0 1px 8px 0 rgba(0, 0, 0, 0.2)',
	width: '150px',
	height: '31px',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
}));

export default StatusBar;
