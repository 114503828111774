import { Timestamp } from 'common/frontend/firebase/firestore';
import { BaseDbDoc, EnumValues, Languages } from 'common/types';
import { stringEnum } from 'common/utils/objects';

export interface UserApi extends BaseDbDoc {
	shops: {
		[shopId: string]: boolean;
	};
	activeShopId: string;
	firstName?: string;
	lastName?: string;
	language?: Languages;
	email: string;
	access: ShopAccess;
	accessUpdated?: Timestamp;
	userPreferences?: {
		/**
		 * For possible future use
		 * global: any
		 */
		merchants?: {
			[shopId: string]: {
				hideEarnCreditsBanner?: boolean;
			};
		};
	};
}

export const ShopRoles = stringEnum(['admin', 'manager', 'editor', 'member']);
export const RentleSupportRoles = stringEnum(['rentle_support']);
export const Roles = { ...ShopRoles, ...RentleSupportRoles };

export const Permissions = stringEnum([
	'modify_payments',
	'allow_overbookings',
	'edit_booking',
	'cancel_booking',
]);

export type ShopRole = EnumValues<typeof ShopRoles>;
export type RentleSupportRole = EnumValues<typeof RentleSupportRoles>;
export type Role = ShopRole | RentleSupportRole;
export type Permission = EnumValues<typeof Permissions>;
export type RolePermission = Role | Permission;

export interface AccessClaims {
	roles: Role[];
	permissions: Permission[];
	restrictions?: Permission[];
	locations?: string[];
}

export interface ShopAccess {
	[shopId: string]: AccessClaims;
}
