import { FeatureProps } from 'components/features/types';
import { useFeatures } from 'hooks/useFeatures';

import { FailedChecks } from '../components/features/types';

/** FeatureToggle logic in hook form.
 */

export const useHasFeature = () => {
	const { userHasFeatures, shopHasFeatures } = useFeatures();

	const hasFeatureWithInfo = (
		requiredFeature: FeatureProps['requiredFeature'],
		options?: Omit<FeatureProps, 'requiredFeature'>,
	): { result: boolean; failedChecks: FailedChecks } => {
		const { filteringType = 'ALL', scope = 'both' } = options ?? {};
		const failedChecks = {
			user:
				['user', 'both'].includes(scope) &&
				!userHasFeatures(requiredFeature, filteringType, { ignoreShopFeatures: true }),
			shop: ['shop', 'both'].includes(scope) && !shopHasFeatures(requiredFeature, filteringType),
		};

		return {
			failedChecks,
			result: !failedChecks.user && !failedChecks.shop,
		};
	};

	const hasFeature = (
		requiredFeature: FeatureProps['requiredFeature'],
		options?: Omit<FeatureProps, 'requiredFeature'>,
	): boolean => {
		return hasFeatureWithInfo(requiredFeature, options).result;
	};

	return { hasFeature, hasFeatureWithInfo };
};
