import { createQueryKeys } from '@lukemorales/query-key-factory';
import { chunk, isEmpty, range } from 'lodash';

import { api } from 'common/frontend/api';
import { getInventoryTransfer } from 'common/MockFactory/mocks/InventoryTransfer.mock';
import { InventoryTransfer, InventoryTransferStatus } from 'common/modules/inventoryTransfers';
import { sleep } from 'common/utils/async';

import { queryClient } from './client';
import { createUseMutation, createUseQuery, fnQueryKeys, queryUpdater } from './utils';

/**
 * TODO: Remove once no longer needed
 */
const MOCK_TRANSFERS = range(0, 100).map(() => getInventoryTransfer());

const queries = {
	get: async (args: { id: string }): Promise<InventoryTransfer | null> => {
		const { id } = args;
		const data = await api().inventoryTransfers.doc(id).get();

		return data ?? null;
	},
	get_mock: async (args: { id: string }): Promise<InventoryTransfer | null> => {
		await sleep(1000);
		return MOCK_TRANSFERS.find((transfer) => transfer.id === args.id) ?? null;
	},
	listByShop: async (args: {
		merchantId: string;
		storeIds?: string[];
		// Todo: Add filter by status
		statuses?: InventoryTransferStatus[];
	}): Promise<InventoryTransfer[]> => {
		const { merchantId, storeIds } = args;

		if (!merchantId) return [];

		if (isEmpty(storeIds)) {
			return api().inventoryTransfers.get.where('merchantId', '==', merchantId).get();
		} else {
			const chunks = chunk(storeIds, 10);

			return Promise.all(
				chunks.map((storeIds) => {
					return api().inventoryTransfers.get.where('merchantId', '==', merchantId).where('destination.store.id', 'in', storeIds).get();
				}),
			).then((res) => res.flat());
		}
	},
	listByShop_mock: async (_args: any) => {
		await sleep(1000);
		return MOCK_TRANSFERS;
	},
};

const mutations = {
	create: async (args: { data: Omit<InventoryTransfer, 'transferNumber'> }): Promise<void> => {
		const { data } = args;
		await api()
			.inventoryTransfers.doc(data.id)
			.set({
				...data,
				transferNumber: 1,
			});

		// TODO: Add transaction to increment transferNumber
	},
	update: async (args: { id: string; data: Partial<InventoryTransfer> }): Promise<void> => {
		const { id, data } = args;
		await api().inventoryTransfers.doc(id).update(data);
	}
};

const queryKeys = createQueryKeys('inventoryItems', {
	get: fnQueryKeys(queries.get),
	get_mock: fnQueryKeys(queries.get_mock),
	listByShop: fnQueryKeys(queries.listByShop),
	listByShop_mock: fnQueryKeys(queries.listByShop_mock),
});

export const get = createUseQuery(queries.get, queryKeys.get);
export const get_mock = createUseQuery(queries.get_mock, queryKeys.get_mock);
export const listByShop = createUseQuery(queries.listByShop, queryKeys.listByShop);
export const listByShop_mock = createUseQuery(queries.listByShop_mock, queryKeys.listByShop_mock);

export const create = createUseMutation(mutations.create, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def, {});
	},
});

export const update = createUseMutation(mutations.update, {
	onMutate: async ({ id, data }) => {
		await queryClient.cancelQueries(queryKeys._def, {});
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates: data,
				condition: (item) => item.id === id,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def, {});
	}
});
