import { CurrencyObject } from 'common/types';

export const BILLING_CURRENCY: CurrencyObject = {
	code: 'USD',
	displayAs: '$',
	position: 'prefix',
	noSpacing: true,
};

export const PAUSED_PRICE_CENTS = 800;

export const LOWEST_PLAN_PRICE_CENTS = 2900;
