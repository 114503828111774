import { createQueryKeys } from '@lukemorales/query-key-factory';
import { chunk, isEmpty, omit } from 'lodash';

import { api } from 'common/frontend/api';
import { BatchManager, FieldValues } from 'common/frontend/firebase/firestore';
import { InventoryItem, SingleInventoryItem } from 'common/modules/inventory';

import { queryClient } from './client';
import {
	createUseMutation,
	createUseQuery,
	fnQueryKeys,
	queryDeleter,
	queryUpdater,
} from './utils';

const queries = {
	get: async (args: { id: string }): Promise<InventoryItem | null> => {
		const { id } = args;
		const data = await api().inventoryItems.doc(id).get();

		return data ?? null;
	},
	listByShop: async (args: {
		shopId: string;
		locationIds?: string[];
	}): Promise<InventoryItem[]> => {
		const { shopId, locationIds } = args;

		if (!shopId) return [];

		if (isEmpty(locationIds)) {
			return api().inventoryItems.get.where('shopId', '==', shopId).get();
		} else {
			const chunks = chunk(locationIds, 10);

			return Promise.all(
				chunks.map((locationIds) => {
					return api()
						.inventoryItems.get.where('shopId', '==', shopId)
						.where('locationId', 'in', locationIds)
						.get();
				}),
			).then((res) => res.flat());
		}
	},
	listBySku: async (args: {
		shopId: string;
		skuId: string;
		locationIds?: string[];
	}): Promise<InventoryItem[]> => {
		const { shopId, skuId, locationIds } = args;

		if (isEmpty(locationIds)) {
			return api()
				.inventoryItems.get.where('shopId', '==', shopId)
				.where('skuId', '==', skuId)
				.get();
		} else {
			const chunks = chunk(locationIds, 10);

			return Promise.all(
				chunks.map((locationIds) => {
					return api()
						.inventoryItems.get.where('shopId', '==', shopId)
						.where('skuId', '==', skuId)
						.where('locationId', 'in', locationIds)
						.get();
				}),
			).then((res) => res.flat());
		}
	},
	listBySkus: async (args: { shopId: string; skuIds: string[] }): Promise<InventoryItem[]> => {
		const { shopId, skuIds } = args;

		const chunks = chunk(skuIds, 10);

		return Promise.all(
			chunks.map((skuIds) => {
				return api()
					.inventoryItems.get.where('shopId', '==', shopId)
					.where('skuId', 'in', skuIds)
					.get();
			}),
		).then((res) => res.flat());
	},
};

const mutations = {
	create: async (args: { item: InventoryItem }): Promise<void> => {
		const { item } = args;

		return api().inventoryItems.doc(item.id).set(item);
	},
	createMany: async (args: { items: InventoryItem[] }): Promise<void> => {
		const { items } = args;

		const batchManager = new BatchManager();

		items.forEach((item) => {
			api(batchManager.batch()).inventoryItems.doc(item.id).set(item);
		});

		return batchManager.commit();
	},
	update: async (args: { id: string; updates: Partial<InventoryItem> }): Promise<void> => {
		const { id, updates } = args;
		return api().inventoryItems.doc(id).update(updates);
	},
	updateMany: async (args: { ids: string[]; updates: Partial<InventoryItem> }): Promise<void> => {
		const { ids, updates } = args;
		const batchManager = new BatchManager();

		ids.forEach((id) => {
			api(batchManager.batch()).inventoryItems.doc(id).update(updates);
		});

		return batchManager.commit();
	},
	deleteMany: async (args: { ids: string[] }): Promise<void> => {
		const { ids } = args;
		const batchManager = new BatchManager();

		ids.forEach((id) => {
			api(batchManager.batch()).inventoryItems.doc(id).delete();
		});

		return batchManager.commit();
	},
	deleteColumn: async (args: { ids: string[]; columnId: string }) => {
		const { ids, columnId } = args;
		const batchManager = new BatchManager();

		ids.forEach((id) => {
			api(batchManager.batch())
				.inventoryItems.doc(id)
				.update({
					[`fieldValues.${columnId}` as `fieldValues.${123}`]: FieldValues.delete() as any,
				});
		});

		return batchManager.commit();
	},
};

const queryKeys = createQueryKeys('inventoryItems', {
	get: fnQueryKeys(queries.get),
	listByShop: fnQueryKeys(queries.listByShop),
	listBySku: fnQueryKeys(queries.listByShop),
	listBySkus: fnQueryKeys(queries.listBySkus),
});

export const get = createUseQuery(queries.get, queryKeys.get);
export const listByShop = createUseQuery(queries.listByShop, queryKeys.listByShop);
export const listBySku = createUseQuery(queries.listBySku, queryKeys.listBySku);
export const listBySkus = createUseQuery(queries.listBySkus, queryKeys.listBySkus);

export const create = createUseMutation(mutations.create, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def, {});
	},
});

export const createMany = createUseMutation(mutations.createMany, {
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def, {});
	},
});

export const update = createUseMutation(mutations.update, {
	onMutate: async ({ id, updates }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates: updates as Partial<SingleInventoryItem>,
				condition: (item) => item.id === id,
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def, {});
	},
});

export const updateMany = createUseMutation(mutations.updateMany, {
	onMutate: async ({ ids, updates }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				updates: updates as Partial<SingleInventoryItem>,
				condition: (item) => ids.includes(item.id),
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const deleteMany = createUseMutation(mutations.deleteMany, {
	onMutate: async ({ ids }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryDeleter({
				condition: (prev: InventoryItem) => ids.includes(prev.id),
			}),
		);
		ids.forEach((id) => {
			queryClient.removeQueries(queryKeys.get({ id }).queryKey);
		});
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});

export const deleteColumn = createUseMutation(mutations.deleteColumn, {
	onMutate: async ({ ids, columnId }) => {
		await queryClient.cancelQueries(queryKeys._def);
		queryClient.setQueriesData(
			queryKeys._def,
			queryUpdater({
				condition: (prev: InventoryItem) => ids.includes(prev.id),
				updates: (prev: InventoryItem) => {
					return {
						...prev,
						fieldValues: omit(prev.fieldValues, columnId),
					};
				},
			}),
		);
	},
	onSettled: () => {
		queryClient.invalidateQueries(queryKeys._def);
	},
});
