import { GenerateContentInput } from '../generators';

const bikeshop: GenerateContentInput[] = [
	{
		category: {
			id: 'city-bikes',
			name: 'City Bikes',
			description: '',
			image:
				'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F105097928_m.jpg?alt=media&token=d08b2017-bdd3-42fb-b27e-fd5a2f09bc54',
		},
		products: [
			{
				name: 'Handbrake bike',
				description: 'Good quality and easy-to-ride bikes. Handbrake version.',
				price: 900,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F66156581_s.jpg?alt=media&token=b49cae60-eb5b-46be-afc9-77c22f0d55e6',
				additionalProductIds: ['bike-insurance', 'bike-helmet', 'childrens-seat'],
			},
			{
				name: 'Pedalbrake bike',
				description: 'Good quality and easy-to-ride bikes. Basic pedalbrake version.',
				price: 700,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F33486264_s.jpg?alt=media&token=5f959d61-548b-4bcd-b465-82b22c10bc21',
				additionalProductIds: ['bike-insurance', 'bike-helmet', 'childrens-seat'],
			},
			{
				name: "Childrens' Bike",
				description: 'Easy-to-ride bikes for the little ones',
				price: 600,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F46900231_s.jpg?alt=media&token=79fabeb6-d971-47d0-82e9-d6912c03171b',
				additionalProductIds: ['bike-insurance', 'childrens-free-helmet'],
				productVariants: [
					{
						priceIncrease: '',
						name: '6-10 y.o',
					},
					{
						priceIncrease: '',
						name: '10-14 y.o',
					},
				],
				variantPropertyName: 'Size',
			},
		],
	},
	{
		category: {
			id: 'bikes-for-longer-trips',
			name: 'Bikes for longer trips',
			image:
				'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F1_black-and-red-road-bike-near-black-train-972100.jpg?alt=media&token=ebf65d4b-f2e5-4b17-8aba-d01d59dbed76',
		},
		products: [
			{
				name: 'Road Bike',
				description:
					'Models from Bianchi, Peloton and Merida. When your goal is to go fast and swallow kilometers, these bikes are your choice.',
				price: 1600,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F15386894_s.jpg?alt=media&token=c73514e4-9804-4b0f-90de-3d30cd2d3f04',
				additionalProductIds: ['bike-insurance-premium', 'bike-helmet', 'childrens-seat'],
				productVariants: [
					{
						priceIncrease: '',
						name: 'S (150-165cm)',
					},
					{
						priceIncrease: '',
						name: 'M (165-180cm)',
					},
					{
						priceIncrease: '',
						name: 'L (180-195cm)',
					},
					{
						priceIncrease: '',
						name: 'XL (190cm->)',
					},
				],
				variantPropertyName: 'Size',
			},
			{
				name: 'Touring Bike',
				description:
					'Models from Felt and Merida. Nimble yet fast. For longer trips or fast shorter rides.',
				price: 1600,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F50947547_s.jpg?alt=media&token=b226b104-0b38-4caa-9513-5a44dc17d895',
				additionalProductIds: ['bike-insurance-premium', 'bike-helmet'],
				productVariants: [
					{
						priceIncrease: '',
						name: 'S (150-165cm)',
					},
					{
						priceIncrease: '',
						name: 'M (165-180cm)',
					},
					{
						priceIncrease: '',
						name: 'L (180-195cm)',
					},
					{
						priceIncrease: '',
						name: 'XL (190cm->)',
					},
				],
				variantPropertyName: 'Size',
			},
			{
				name: 'Mountain Bike',
				description:
					'Models from Trek and Giant. If the city life is not for you (or you just hate bumps), these bikes like you.',
				price: 1600,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fmtb.jpg?alt=media&token=e69099b5-c5bb-44fe-81d3-9e8c58928845',
				additionalProductIds: ['bike-insurance-premium', 'bike-helmet'],
				productVariants: [
					{
						priceIncrease: '',
						name: 'S (150-165cm)',
					},
					{
						priceIncrease: '',
						name: 'M (165-180cm)',
					},
					{
						priceIncrease: '',
						name: 'L (180-195cm)',
					},
					{
						priceIncrease: '',
						name: 'XL (190cm->)',
					},
				],
				variantPropertyName: 'Size',
			},
			{
				name: 'E-Bike',
				description: 'Longer, faster, less effort? Embrace the technology!',
				price: 2000,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F113061822_s.jpg?alt=media&token=84676450-1ffa-4151-997c-f18c4b339358',
				additionalProductIds: ['bike-insurance-premium', 'bike-helmet'],
				productVariants: [
					{
						priceIncrease: '',
						name: 'S (150-165cm)',
					},
					{
						priceIncrease: '',
						name: 'M (165-180cm)',
					},
					{
						priceIncrease: '',
						name: 'L (180-195cm)',
					},
					{
						priceIncrease: '',
						name: 'XL (190cm->)',
					},
				],
				variantPropertyName: 'Size',
			},
		],
	},
	{
		category: {
			id: 'special-bikes',
			name: 'Special Bikes',
			image:
				'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F63871450_m.jpg?alt=media&token=8004523b-3deb-4f3c-874a-f593de25c4c6',
		},
		products: [
			{
				name: 'Tandem Bike',
				description:
					'It takes two to tango. Remember to communicate clearly with each other when turning.',
				price: 1600,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F52178530_s.jpg?alt=media&token=3e0a4951-1943-49db-9586-6a046c8afaf6',
				additionalProductIds: ['bike-insurance-premium', 'bike-helmet'],
			},
			{
				name: 'Cargo Bike',
				description: 'Planning to buy a bit more from the market today?',
				price: 1600,
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fcargos.jpg?alt=media&token=1bb3dfb0-a1af-487a-aa20-8658a79845a5',
				additionalProductIds: ['bike-insurance-premium', 'bike-helmet'],
			},
		],
	},
	{
		category: {
			id: 'accessories',
			name: 'Accessories',
			image:
				'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Fhelmet.jpg?alt=media&token=d1d64f9a-3039-4535-b47d-cdb9421956d7',
		},
		products: [
			{
				id: 'bike-insurance',
				name: 'Bike Insurance',
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Finsurance.png?alt=media&token=ca4b4c37-c860-437e-91ee-422472f019e6',
				price: 300,
				pricingTableType: 'accessory',
			},
			{
				id: 'bike-insurance-premium',
				name: 'Bike Insurance Premium',
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2Finsurance.png?alt=media&token=ca4b4c37-c860-437e-91ee-422472f019e6',
				price: 500,
				pricingTableType: 'accessory',
			},
			{
				id: 'bike-helmet',
				name: 'Bike Helmet',
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F55066601_s.jpg?alt=media&token=4cc15253-67c8-4fea-8e27-09206f9dd91d',
				price: 300,
				pricingTableType: 'accessory',
			},
			{
				id: 'childrens-seat',
				name: "Children's Seat",
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F128119581_s.jpg?alt=media&token=2c4629f4-f20d-413f-8485-bdc5d89ac020',
				price: 400,
				pricingTableType: 'accessory',
			},
		],
	},
	{
		category: null,
		products: [
			{
				id: 'childrens-free-helmet',
				name: "Children's Free Helmet",
				image:
					'https://firebasestorage.googleapis.com/v0/b/rentle-prod.appspot.com/o/demo-shop-images%2F38251624_s.jpg?alt=media&token=b0f859e6-3eab-4570-bc30-ebff452d0cc2',
				price: 0,
				additionalProduct: true,
				pricingTableType: 'accessory',
			},
		],
	},
];

export default bikeshop;
