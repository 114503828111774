import { createAction, unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

import { getAdyenPaymentMethods } from 'common/api/db/functions';
import {
	getCarriersListener,
	getDeliveryOptionsListener,
	getShopAnalyticsListener,
	getShopInfoListener,
	getShopPublicInfoListener,
	getShopReadOnlyDataListener,
	getShopUrlsListener,
	getShopUsersListener,
} from 'common/api/frontend';
import { api } from 'common/frontend/api';
import { BILLING_CURRENCY } from 'common/modules/billing';
import { PLAIN_THEME_ID } from 'common/modules/customization';
import { CustomizerTheme } from 'common/modules/customization/types';
import { LocationEmailSettings } from 'common/modules/emails/types';
import { OpeningHoursDoc } from 'common/modules/openingHours';
import {
	shopHasAdyenPayment,
	shopHasAnyManualPaymentMethod,
	shopHasStripePayment,
} from 'common/modules/payments/paymentMethods';
import {
	AddOn,
	getMerchantTotalSubscriptionPrice,
	getTotalBlockSlots,
	getUsedBlockSlots,
} from 'common/modules/plans';
import { getOnlineStoreUrl } from 'common/modules/urls';
import { ListenerManager } from 'common/services/ListenerManager';
import {
	setGaUserProperties,
	setShopAnalyticsHubspotProperties,
	setShopPublicInfoHubspotProperties,
	setShopReadOnlyHubspotProperties,
} from 'common/services/analytics';
import * as IntercomService from 'common/services/analytics/intercom';
import { IntercomCompany, IntercomUser } from 'common/services/analytics/intercom';
import { ShopAnalytics } from 'common/services/analytics/tractionAnalytics';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	Carrier,
	DeliveryOption,
	DiscountCodeApi,
	GetPaymentMethods,
	Shop,
	ShopApi,
	ShopPublicInfo,
	ShopReadOnlyData,
	ShopUrlApi,
	UserApi,
} from 'common/types';
import { isRentleEmail, notUndefined, removeUndefinedValues } from 'common/utils/common';
import { getClientEnv } from 'common/utils/frontUtils';
import { isOnlinePaymentsInUse } from 'common/utils/payments';
import {
	getShopLocationById,
	getShopLocations,
	getShopLocationsWithContact,
} from 'common/utils/shopUtils';
import * as NewRentalActions from 'actions/NewRentalActions';
import { userActiveLocationId, userData } from 'selectors/UserSelectors';
import { setDatadogUser } from 'services/datadog/utils';
import * as GrowthbookService from 'services/growthbook/utils';
import { setOnboardingActionDone } from 'services/onboarding/utils';
import { ReduxState } from 'services/types';
import { createAppThunk } from 'services/utils/redux';

import { setStockListeners } from './StockAction';
import { getStoreRentals } from './StoreRentalsActions';
import { updateLanguage } from './ViewActions';

const shopListeners = new ListenerManager<
	| 'shops'
	| 'discountCodes'
	| 'shopInfo'
	| 'publicInfo'
	| 'urls'
	| 'exceptionPeriods'
	| 'readOnly'
	| 'analytics'
	| 'users'
	| 'deliveryOptions'
	| 'carriers'
	| 'emailSettings'
	| 'customThemes'
	| 'rentleCustomTheme'
	| 'openingHours'
>();

export const setDiscountCodesData = createAction<DiscountCodeApi[]>('Shop/SET_DISCOUNT_CODES_DATA');
export const setDiscountCodesError = createAction<string>('Shop/SET_DISCOUNT_CODES_ERROR');
export const setDiscountCodesListener = createAppThunk(
	'Shop/SET_DISCOUNT_CODES_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<DiscountCodeApi[]>((resolve, reject) => {
			shopListeners.update({
				id: 'discountCodes',
				key: shopId,
				listener: api()
					.discountCodes.get.whereArray('shopIds', 'array-contains', shopId)
					.onSnapshot(
						(discountCodes) => {
							thunkAPI.dispatch(setDiscountCodesData(discountCodes));
							return resolve(discountCodes);
						},
						(error) => {
							const err = error.message ?? 'Error getting discount codes';
							thunkAPI.dispatch(setDiscountCodesError(err));
							return reject(err);
						},
					),
			});
		});
	},
);
export const clearDiscountCodesListener = createAppThunk('Shop/CLEAR_DISCOUNT_CODES_LISTENER', () =>
	shopListeners.clear('discountCodes'),
);

export const setShopInfoData = createAction<ShopApi>('Shop/SET_SHOP_INFO');
export const setShopInfoError = createAction<string>('Shop/SET_SHOP_INFO_ERROR');
export const setShopInfoListener = createAppThunk(
	'Shop/SET_SHOP_INFO_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<ShopApi>((resolve, reject) => {
			shopListeners.update({
				id: 'shopInfo',
				key: shopId,
				listener: getShopInfoListener(
					shopId,
					(shopInfo) => {
						if (!!shopInfo) {
							thunkAPI.dispatch(setShopInfoData(shopInfo));
							setGaUserProperties(
								{
									shop_created: shopInfo.createdAt,
								},
								{ sendToAllTags: true },
							);
							return resolve(shopInfo);
						} else {
							const err = 'Shop not found';
							thunkAPI.dispatch(setShopInfoError(err));
							return reject(err);
						}
					},
					(error) => {
						const err = error.message ?? 'Error getting shop info';
						thunkAPI.dispatch(setShopInfoError(err));
						return reject(err);
					},
				),
			});
		});
	},
);
export const clearShopInfoListener = createAppThunk('Shop/CLEAR_SHOP_INFO_LISTENER', () => {
	shopListeners.clear('shopInfo');
});

export const setShopPublicInfoData = createAction<ShopPublicInfo>('Shop/SET_SHOP_PUBLIC_INFO_DATA');
export const setShopPublicInfoError = createAction<string>('Shop/SET_SHOP_PUBLIC_INFO_ERROR');
export const setShopPublicInfoListener = createAppThunk(
	'Shop/SET_SHOP_PUBLIC_INFO_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<ShopPublicInfo>((resolve, reject) => {
			shopListeners.update({
				id: 'publicInfo',
				key: shopId,
				listener: getShopPublicInfoListener(
					shopId,
					(shopPublicInfo) => {
						if (!!shopPublicInfo) {
							const currentLanguage = thunkAPI.getState().lang;
							if (shopPublicInfo.languages && !shopPublicInfo.languages.includes(currentLanguage)) {
								thunkAPI.dispatch(updateLanguage(shopPublicInfo.defaultLanguage));
							}
							setGaUserProperties(
								{
									shop_id: shopId,
									location_count: getShopLocationsWithContact(shopPublicInfo).length,
									payments_in_use: isOnlinePaymentsInUse(shopPublicInfo.activePaymentMethods || []),
									shop_name: shopPublicInfo.name,
								},
								{ sendToAllTags: true },
							);
							setShopPublicInfoHubspotProperties({
								newData: shopPublicInfo,
								oldData: thunkAPI.getState().shop.shop.data?.publicInfo,
							});
							thunkAPI.dispatch(setShopPublicInfoData(shopPublicInfo));
							return resolve(shopPublicInfo);
						} else {
							const err = 'Shop not found';
							thunkAPI.dispatch(setShopPublicInfoError(err));
							return reject(err);
						}
					},
					(error) => {
						const err = error.message ?? 'Error getting shop public info';
						thunkAPI.dispatch(setShopPublicInfoError(err));
						return reject(err);
					},
				),
			});
		});
	},
);
export const clearShopPublicInfoListener = createAppThunk(
	'Shop/CLEAR_SHOP_PUBLIC_INFO_LISTENER',
	() => {
		shopListeners.clear('publicInfo');
	},
);

export const setShopUrlsData = createAction<ShopUrlApi | undefined>('Shop/SET_SHOP_URLS');
export const setShopUrlsError = createAction<string>('Shop/SET_SHOP_URLS_ERROR');
export const setShopUrlsListener = createAppThunk(
	'Shop/SET_SHOP_URLS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<ShopUrlApi | undefined>((resolve, reject) => {
			shopListeners.update({
				id: 'urls',
				key: shopId,
				listener: getShopUrlsListener(
					shopId,
					(shopUrls) => {
						thunkAPI.dispatch(setShopUrlsData(shopUrls));
						return resolve(shopUrls);
					},
					(error) => {
						const err = error.message ?? 'Error getting shop URLs';
						thunkAPI.dispatch(setShopUrlsError(err));
						return reject(err);
					},
				),
			});
		});
	},
);
export const clearShopUrlsListener = createAppThunk('Shop/CLEAR_SHOP_URLS_LISTENER', () => {
	shopListeners.clear('urls');
});

export const setShopReadOnlyData = createAction<ShopReadOnlyData>('Shop/SET_SHOP_READ_ONLY_DATA');
export const setShopReadOnlyError = createAction<string>('Shop/SET_SHOP_READ_ONLY_ERROR');
export const setShopReadOnlyListener = createAppThunk(
	'Shop/SET_SHOP_READ_ONLY_LISTENER',
	async (shopId: string, thunkAPI) => {
		return new Promise<ShopReadOnlyData>((resolve, reject) => {
			shopListeners.update({
				id: 'readOnly',
				key: shopId,
				listener: getShopReadOnlyDataListener(
					shopId,
					(readOnlyData) => {
						if (!!readOnlyData) {
							const shopPlan = readOnlyData.features.plan.plan;

							setGaUserProperties(
								{
									...(shopPlan && { shop_plan: shopPlan }),
								},
								{ sendToAllTags: true },
							);
							setShopReadOnlyHubspotProperties({
								newData: readOnlyData,
								oldData: thunkAPI.getState().shop.shop.data?.readOnly,
							});
							thunkAPI.dispatch(setShopReadOnlyData(readOnlyData));
							updateOnboardingSteps(
								readOnlyData,
								thunkAPI.getState().shop.shop.data?.shopAnalytics,
							);
							return resolve(readOnlyData);
						} else {
							const err = 'Shop read only data not found';
							thunkAPI.dispatch(setShopReadOnlyError(err));
							return reject(err);
						}
					},
					(error) => {
						const err = error.message ?? 'Error getting shop read only data';
						thunkAPI.dispatch(setShopReadOnlyError(err));
						return reject(err);
					},
				),
			});
		});
	},
);
export const clearShopReadOnlyListener = createAppThunk(
	'Shop/CLEAR_SHOP_READ_ONLY_LISTENER',
	() => {
		shopListeners.clear('readOnly');
	},
);

export const setShopAnalyticsData = createAction<ShopAnalytics>('Shop/SET_ANALYTICS_DATA');
export const setShopAnalyticsError = createAction<string>('Shop/SET_ANALYTICS_DATA_ERROR');
export const setShopAnalyticsListener = createAppThunk(
	'Shop/SET_ANALYTICS_LISTENER',
	async (shopId: string, thunkAPI) => {
		return new Promise<ShopAnalytics>((resolve, reject) => {
			shopListeners.update({
				id: 'analytics',
				key: shopId,
				listener: getShopAnalyticsListener(
					shopId,
					(shopAnalytics: ShopAnalytics) => {
						setShopAnalyticsHubspotProperties({
							newData: shopAnalytics,
							oldData: thunkAPI.getState().shop.shop.data?.shopAnalytics,
						});
						thunkAPI.dispatch(setShopAnalyticsData(shopAnalytics));
						return resolve(shopAnalytics);
					},
					(error) => {
						const err = error.message ?? 'Error getting shop analytics data';
						thunkAPI.dispatch(setShopAnalyticsError(err));
						return reject(err);
					},
				),
			});
		});
	},
);
export const clearShopAnalyticsListener = createAppThunk('Shop/CLEAR_ANALYTICS_LISTENER', () => {
	shopListeners.clear('analytics');
});

export const setActiveShopUsersData = createAction<UserApi[]>('Shop/SET_SHOP_USERS_DATA');
export const setActiveShopUsersError = createAction<string>('Shop/SET_SHOP_USERS_ERROR');
export const setActiveShopUsersListener = createAppThunk(
	'Shop/SET_SHOP_USERS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<UserApi[]>((resolve, reject) => {
			shopListeners.update({
				id: 'users',
				key: shopId,
				listener: getShopUsersListener(
					shopId,
					(users) => {
						thunkAPI.dispatch(setActiveShopUsersData(users));
						return resolve(users);
					},
					(error) => {
						const err = error.message ?? 'Error getting shop users';
						thunkAPI.dispatch(setActiveShopUsersError(err));
						return reject(err);
					},
				),
			});
		});
	},
);

export const clearActiveShopUsersListener = createAppThunk(
	'Shop/CLEAR_ACTIVE_SHOP_USERS_LISTENER',
	() => {
		shopListeners.clear('users');
	},
);

export const setActiveShopEmailSettingsData = createAction<LocationEmailSettings[]>(
	'Shop/SET_SHOP_EMAIL_SETTINGS',
);
export const setActiveShopEmailSettingsError = createAction<string>(
	'Shop/SET_SHOP_EMAIL_SETTINGS_ERROR',
);
export const setActiveShopEmailSettingsListener = createAppThunk(
	'Shop/SET_SHOP_EMAIL_SETTINGS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<LocationEmailSettings[]>((resolve, reject) => {
			shopListeners.update({
				id: 'emailSettings',
				key: shopId,
				listener: api()
					.emailSettings.get.where('merchantId', '==', shopId)
					.onSnapshot(
						(emailSettings) => {
							thunkAPI.dispatch(setActiveShopEmailSettingsData(emailSettings));
							return resolve(emailSettings);
						},
						(error) => {
							const err = error.message ?? 'Error getting shop emailSettings';
							thunkAPI.dispatch(setActiveShopEmailSettingsError(err));
							return reject(err);
						},
					),
			});
		});
	},
);
export const clearActiveShopEmailSettingsListener = createAppThunk(
	'Shop/CLEAR_ACTIVE_SHOP_EMAIL_SETTINGS_LISTENER',
	() => {
		shopListeners.clear('emailSettings');
	},
);

export const setMerchantCustomThemes = createAction<CustomizerTheme[]>(
	'Shop/SET_SHOP_CUSTOMIZER_THEMES',
);
export const setMerchantCustomThemesError = createAction<string>(
	'Shop/SET_SHOP_CUSTOMIZER_THEMES_ERROR',
);
export const setMerchantCustomThemesListener = createAppThunk(
	'Shop/SET_SHOP_CUSTOMIZER_THEMES_LISTENER',
	(merchantId: string, thunkAPI) => {
		return new Promise<CustomizerTheme[]>((resolve, reject) => {
			shopListeners.update({
				id: 'customThemes',
				key: merchantId,
				listener: api()
					.customThemes.get.where('merchantId', '==', merchantId)
					.onSnapshot(
						(themes) => {
							thunkAPI.dispatch(setMerchantCustomThemes(themes));
							return resolve(themes);
						},
						(error) => {
							const err = error.message ?? 'Error getting shop custom themes';
							thunkAPI.dispatch(setMerchantCustomThemesError(err));
							return reject(err);
						},
					),
			});
		});
	},
);
export const clearActiveShopOnlineThemesListener = createAppThunk(
	'Shop/CLEAR_SHOP_CUSTOMIZER_THEMES_LISTENER',
	() => {
		shopListeners.clear('customThemes');
	},
);

export const setRentleCustomTheme = createAction<CustomizerTheme | undefined>(
	'Shop/SET_RENTLE_CUSTOM_THEME',
);
export const setRentleCustomThemeError = createAction<string>('Shop/SET_RENTLE_CUSTOM_THEME_ERROR');
export const setRentleCustomThemeListener = createAppThunk(
	'Shop/SET_RENTLE_CUSTOM_THEME_LISTENER',
	(merchantId: string, thunkAPI) => {
		return new Promise<CustomizerTheme | undefined>((resolve, reject) => {
			shopListeners.update({
				id: 'rentleCustomTheme',
				key: merchantId,
				listener: api()
					.customThemes.doc(PLAIN_THEME_ID)
					.listen(
						(theme) => {
							thunkAPI.dispatch(setRentleCustomTheme(theme));
							return resolve(theme);
						},
						(error) => {
							const err = error.message ?? 'Error getting shop custom themes';
							thunkAPI.dispatch(setRentleCustomThemeError(err));
							return reject(err);
						},
					),
			});
		});
	},
);

export const clearRentleCustomThemeListener = createAppThunk(
	'Shop/CLEAR_RENTLE_CUSTOM_THEME_LISTENER',
	() => {
		shopListeners.clear('rentleCustomTheme');
	},
);

export const setShopOpeningHours = createAction<OpeningHoursDoc[]>('Shop/SET_SHOP_OPENING_HOURS');
export const setShopOpeningHoursError = createAction<string>('Shop/SET_SHOP_OPENING_HOURS_ERROR');
export const setShopOpeningHoursListener = createAppThunk(
	'Shop/SET_SHOP_OPENING_HOURS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<OpeningHoursDoc[]>((resolve, reject) => {
			shopListeners.update({
				id: 'openingHours',
				key: shopId,
				listener: api()
					.openingHours.byMerchantId(shopId)
					.onSnapshot(
						(openingHours) => {
							thunkAPI.dispatch(setShopOpeningHours(openingHours));
							return resolve(openingHours);
						},
						(error) => {
							const err = error.message ?? 'Error getting shop opening hours';
							thunkAPI.dispatch(setShopOpeningHoursError(err));
							return reject(err);
						},
					),
			});
		});
	},
);

export const setActiveShopListeners = createAppThunk<Shop, string, { state: ReduxState }>(
	'Shop/SET_ACTIVE_SHOP_LISTENERS',
	async (shopId: string, thunkAPI) => {
		return new Promise<Shop>(async (resolve, reject) => {
			try {
				const [shopInfo, publicInfo, shopUrls, readOnly, shopAnalytics] = await Promise.all([
					thunkAPI.dispatch(setShopInfoListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopPublicInfoListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopUrlsListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopReadOnlyListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopAnalyticsListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopDeliveryOptionsListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopCarriersListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setDiscountCodesListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setActiveShopUsersListener(shopId)),
					thunkAPI.dispatch(setActiveShopEmailSettingsListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setMerchantCustomThemesListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setRentleCustomThemeListener(shopId)).then(unwrapResult),
					thunkAPI.dispatch(setShopOpeningHoursListener(shopId)).then(unwrapResult),
				]);

				const shop: Shop = {
					shopInfo,
					publicInfo,
					shopUrls,
					readOnly,
					shopAnalytics,
				};
				thunkAPI.dispatch(NewRentalActions.initialiseRentalInfo(shop));
				return resolve(shop);
			} catch (err) {
				return reject(err.message);
			}
		});
	},
);
export const clearActiveShopListeners = createAppThunk('Shop/CLEAR_ACTIVE_SHOP_LISTENERS', () => {
	shopListeners.clearAll();
});

export const setActiveShop = createAppThunk<Shop, string, { state: ReduxState }>(
	'Shop/SET_ACTIVE_SHOP',
	async (shopId: string, thunkAPI) => {
		const shop = await thunkAPI.dispatch(setActiveShopListeners(shopId)).then(unwrapResult);
		const shopCountry = shop.publicInfo.country;
		thunkAPI.dispatch(setStockListeners(shopId));
		thunkAPI.dispatch(getStoreRentals(shopId));
		thunkAPI.dispatch(
			fetchAdyenPaymentMethods({
				shopId: shopId ?? '',
				amount: {
					value: 0,
					currency: BILLING_CURRENCY.code,
				},
				...(shopCountry && { countryCode: shopCountry.toUpperCase() }),
				shopperReference: shopId,
			}),
		);
		updateIntercom(shop, thunkAPI.getState());
		updateGrowthbook(shop, thunkAPI.getState());
		const userId = thunkAPI.getState()?.user?.user?.data?.id;
		setDatadogUser(userId ?? 'unknown', shopId);
		return shop;
	},
);

export const setShopDeliveryOptionsData = createAction<DeliveryOption[]>(
	'Shop/SET_DELIVERY_OPTIONS_DATA',
);
export const setShopDeliveryOptionsError = createAction<string>('Shop/SET_DELIVERY_OPTIONS_ERROR');

export const setShopDeliveryOptionsListener = createAppThunk(
	'Shop/SET_SHOP_DELIVERY_OPTIONS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<DeliveryOption[]>((resolve, reject) => {
			shopListeners.update({
				id: 'deliveryOptions',
				key: shopId,
				listener: getDeliveryOptionsListener(
					shopId,
					(deliveryOptions: DeliveryOption[]) => {
						thunkAPI.dispatch(setShopDeliveryOptionsData(deliveryOptions));
						return resolve(deliveryOptions);
					},
					(error) => {
						const err = error.message ?? 'Error getting shop delivery options';
						thunkAPI.dispatch(setShopDeliveryOptionsError(err));
						return reject(error);
					},
				),
			});
		});
	},
);

export const clearShopDeliveryOptionsListener = createAppThunk(
	'Shop/CLEAR_SHOP_DELIVERY_OPTIONS_LISTENER',
	() => {
		shopListeners.clear('deliveryOptions');
	},
);

export const setShopCarriersData = createAction<Carrier[]>('Shop/SET_SHOP_CARRIERS_DATA');
export const setShopCarriersError = createAction<string>('Shop/SET_SHOP_CARRIERS_ERROR');

export const setShopCarriersListener = createAppThunk(
	'Shop/SET_SHOP_CARRIERS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<Carrier[]>((resolve, reject) => {
			shopListeners.update({
				id: 'carriers',
				key: shopId,
				listener: getCarriersListener(
					shopId,
					(carriers: Carrier[]) => {
						thunkAPI.dispatch(setShopCarriersData(carriers));
						return resolve(carriers);
					},
					(error) => {
						const err = error.message ?? 'Error getting shop delivery options';
						thunkAPI.dispatch(setShopCarriersError(err));
						return reject(error);
					},
				),
			});
		});
	},
);

export const clearShopCarriersListener = createAppThunk('Shop/CLEAR_SHOP_CARRIERS_LISTENER', () => {
	shopListeners.clear('carriers');
});

const updateGrowthbook = (shop: Shop, state: ReduxState) => {
	const user = userData(state);
	const userEmail = user?.email;
	const userId = user?.id;
	const currentLanguage = state.lang;
	const shopFeatures = shop.readOnly?.features;
	const shopPlanObject = shopFeatures?.plan;
	const shopPlan = shopPlanObject?.plan;
	GrowthbookService.setAttributes(
		removeUndefinedValues({
			country: shop.publicInfo.country,
			internalUser: !!userEmail ? isRentleEmail(userEmail) : undefined,
			language: currentLanguage,
			merchantId: shop.shopInfo.id,
			plan: shopPlan,
			signupTimestamp: shop.shopInfo.createdAt,
			userId,
		}),
	);
};

const updateIntercom = (shop: Shop, state: ReduxState) => {
	const shopFeatures = shop.readOnly?.features;
	const shopPlanObject = shopFeatures?.plan;
	const plan = shopPlanObject?.plan;
	const shopBlocks = shopFeatures?.addOns;
	const publicInfo = shop.publicInfo;
	const shopName = publicInfo?.name ?? '';
	const shopUrl = shop.shopUrls?.storeUrl ?? '';
	const user = userData(state);
	const userEmail = user?.email;
	const userLocationId = userActiveLocationId(state);
	const currentLanguage = state.lang;
	const shopLocations = getShopLocations(publicInfo);
	const shopPaymentMethods = shop.publicInfo.activePaymentMethods ?? [];
	const internalAdminLink = `https://internal-admin-dot-rentle-prod.appspot.com/merchant/${shop.shopInfo.id}`;
	const shopOnlineStoreUrl = getOnlineStoreUrl({
		env: getClientEnv(),
		shopUrlDoc: shop.shopUrls,
		locationId: null,
	});

	const totalSubscriptionPrice = !!shopPlanObject
		? getMerchantTotalSubscriptionPrice({
				purchasedPlan: shopPlanObject,
				allLocationIds: shopLocations.map((location) => location.id),
				mainLocationId: publicInfo.visitingAddress!.id,
		  })
		: null;
	const fixedMonthlyDollarSpend = (totalSubscriptionPrice?.fixed?.value ?? 0) / 100;

	const company: IntercomCompany = removeUndefinedValues({
		company_id: shop.shopInfo.id,
		name: shop.publicInfo?.name,
		created_at: moment(shop.shopInfo.createdAt).unix(),
		plan,
		monthly_spend: fixedMonthlyDollarSpend,
		website: shopOnlineStoreUrl,
		shopUrl,
		industry: shop.shopAnalytics?.industryCategories?.values.join(','),
		blocks_total: shopFeatures ? getTotalBlockSlots(shopFeatures) : undefined,
		blocks_installed: shopFeatures ? getUsedBlockSlots(shopFeatures) : undefined,
		blocks_list: Object.values(shopBlocks ?? {})
			.map((block) => block.addOn)
			.join(', '),
		store_count: shopLocations.length,
		users_count: Object.keys(shop.shopInfo?.users).length,
		currency: publicInfo.currency,
		country: publicInfo.country,
		payments_rentle: shopHasAdyenPayment(shopPaymentMethods),
		payments_stripe: shopHasStripePayment(shopPaymentMethods),
		payments_manual: shopHasAnyManualPaymentMethod(shopPaymentMethods),
		internal_admin_link: internalAdminLink,
		customer_persona: shop.shopAnalytics?.customerPersona?.value,
		order_count: shop.readOnly?.orderNumber ?? 0,
		merchant_profile: shop.shopAnalytics?.merchantProfile,
		revenue: shop.shopAnalytics?.revenue,
		// Product count added in StockActions when products have been fetched
		// products_count: 0,
	});

	const intercomArgs: IntercomUser = removeUndefinedValues({
		email: userEmail,
		user_id: user?.id,
		name: [user?.firstName, user?.lastName].filter(notUndefined).join(' '),
		created_at: moment(user?.createdAt).unix(),
		roles: user?.access[shop.shopInfo.id]?.roles.join(', '),
		permissions: user?.access[shop.shopInfo.id]?.permissions.join(', '),
		ui_language: currentLanguage,
		company: company,
		plan,
		shopUrl,
		shopName,
		website: shopOnlineStoreUrl,
		current_store: userLocationId
			? getShopLocationById(userLocationId, shop.publicInfo)?.name
			: undefined,
		internal_admin_link: internalAdminLink,
	});
	IntercomService.update(intercomArgs);
};

export const setAdyenPaymentMethodsData = createAction<ICheckout.PaymentMethodsResponse | null>(
	'Shop/SET_ADYEN_PAYMENT_METHODS_DATA',
);

export const setAdyenPaymentMethodsError = createAction<string>(
	'Shop/SET_ADYEN_PAYMENT_METHODS_ERROR',
);

export const fetchAdyenPaymentMethods = createAppThunk(
	'Shop/UPDATE_ADYEN_PAYMENT_METHODS',
	async (requestData: GetPaymentMethods, thunkAPI) => {
		try {
			const paymentMethods = await getAdyenPaymentMethods(requestData);
			thunkAPI.dispatch(setAdyenPaymentMethodsData(paymentMethods.data));
		} catch (error) {
			errorHandler.report(error);
			thunkAPI.dispatch(setAdyenPaymentMethodsError(error.message));
		}
	},
);

export const addInstalledAddOn = createAction<AddOn>('Shop/ADD_INSTALLED_ADD_ON');

const updateOnboardingSteps = (
	shopReadOnly: ShopReadOnlyData,
	shopAnalytics: ShopAnalytics | undefined,
) => {
	if (shopReadOnly.orderNumber) {
		setOnboardingActionDone('BOOKING_CREATION', shopAnalytics);
	}
};
