import { Redirect, RouteComponentProps } from 'react-router-dom';

import { Routes } from 'routing';

const environment = process.env.REACT_APP_ENV;

export const InstoreRedirect = (
	routeProps: RouteComponentProps<{ storeId: string; locationId?: string }>,
) => {
	const { storeId, locationId } = routeProps.match.params;

	const suffix = locationId ? `${storeId}/${locationId}` : storeId;

	if (environment === 'local') {
		window.location.replace(`http://localhost:7000/${suffix}`);
		return null;
	} else if (environment === 'development') {
		window.location.replace(`https://store.dev.rentle.shop/${suffix}`);
		return null;
	} else if (environment === 'production') {
		window.location.replace(`https://store.rentle.shop/${suffix}`);
		return null;
	} else {
		return <Redirect to={Routes.ROOT} />;
	}
};

export const PayLinkRedirect = (routeProps: RouteComponentProps<{ payId: string }>) => {
	const { payId } = routeProps.match.params;

	if (environment === 'local') {
		window.location.replace(`http://localhost:3000/${payId}`);
		return null;
	} else if (environment === 'development' && payId) {
		window.location.replace(`https://link.dev.rentle.shop/${payId}`);
		return null;
	} else if (environment === 'production' && payId) {
		window.location.replace(`https://link.rentle.shop/${payId}`);
		return null;
	} else {
		return <Redirect to={Routes.ROOT} />;
	}
};
