import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { ShopUrlApi } from 'common/types';

import { createBasicDbOperations } from '../utils';

export const getShopUrlsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.shopUrls;
	return {
		...createBasicDbOperations<ShopUrlApi>(collectionPath, dbRefs),
	};
};
