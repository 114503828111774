import { BaseDbDoc, OrderObject } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export interface PendingOrder extends BaseDbDoc {
	id: string; // orderId
	order: OrderObject;
}

export const getPendingOrderQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.pendingOrders;
	const dataUpdater = undefined;
	const query = buildQuery<PendingOrder>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PendingOrder>(collectionPath, dbRefs, dataUpdater),
	};
};
