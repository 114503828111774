import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'common/frontend/firebase/init';
import 'common/services/errorHandling/errorHandler';
import 'services/initDataGridPro';
import 'services/initPosthog';
import 'services/datadog/init';
import * as React from 'react';
import { Suspense } from 'react';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { IconContext } from 'react-icons';

import Spinner from 'common/components/Spinner';
import { queryClient } from 'common/frontend/queries';
import ErrorBoundary from 'common/services/errorHandling/components/ErrorBoundary';
import ErrorStateFallback from 'common/services/errorHandling/components/ErrorStateFallback';
import iconConfig from 'common/styles/icons';
import * as Themes from 'common/styles/themes';
import NotificationManager from 'components/NotificationManager';
import { growthbook } from 'services/growthbook/utils';
import { useTranslation } from 'services/localization';

import AppRouter from './AppRouter';
import ReduxRoot from './ReduxRoot';
// prettier-ignore
import UserManager from 'components/UserManager';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

const AdminApp = () => {
	const { t } = useTranslation();
	return (
		<Suspense fallback={<Spinner />}>
			<ErrorBoundary
				FallbackComponent={({ error, resetErrorBoundary }) => (
					<ErrorStateFallback error={error} resetErrorBoundary={resetErrorBoundary} t={t} />
				)}
			>
				<AppRouter />
				<NotificationManager />
				<UserManager />
			</ErrorBoundary>
		</Suspense>
	);
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<ReduxRoot>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={Themes.RentleLight}>
				<LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
					<CssBaseline />
					<QueryClientProvider client={queryClient}>
						<IconContext.Provider value={iconConfig}>
							<PostHogProvider client={posthog}>
								<GrowthBookProvider growthbook={growthbook}>
									<AdminApp />
								</GrowthBookProvider>
							</PostHogProvider>
						</IconContext.Provider>
					</QueryClientProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</ReduxRoot>,
);
