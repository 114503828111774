import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { ProductTotalAvailability } from 'common/api/frontend/inventory_new';
import { setNewOrder } from 'common/api/frontend/orders';
import { api } from 'common/frontend/api';
import { Callable } from 'common/frontend/callable';
import { BufferTime } from 'common/modules/atoms/bufferTimes';
import { getInitialOrderPricing } from 'common/modules/atoms/pricing';
import { OpeningHours } from 'common/modules/openingHours';
import { getOrderPricing, getPricingOptions } from 'common/modules/orders';
import { addDiscountToProducts } from 'common/modules/orders/products';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { shopHasFeatures } from 'common/modules/plans';
import * as IntercomService from 'common/services/analytics/intercom';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	ById,
	CancellationObject,
	Discount,
	Duration,
	Languages,
	Location,
	OrderInfo,
	OrderObject,
	OrderProduct,
	PhoneObject,
	RentalLinkInfo,
	Reservation,
	ResponsiblePerson,
	ResponsiblePersonDetails,
	Shop,
	Shopper,
} from 'common/types';
import { getPhoneObjectForCountry } from 'common/utils/common';
import { StorageKeys, Storages, removeFromStorage, saveToStorage } from 'common/utils/frontUtils';
import {
	getEmptyShopper,
	getResponsiblePersonDetails,
	newFirestoreId,
} from 'common/utils/newRentalUtils';
import {
	updateOrderProductsDates,
	updateOrderProductsDuration,
	updateOrderProductsPricing,
} from 'common/utils/productUtils';
import { getShopLocationsWithContact } from 'common/utils/shopUtils';
import * as NotificationActions from 'actions/NotificationActions';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import { ReduxState, ThunkResult } from 'services/types';
import { getRentalLinkUrl } from 'services/utils/common';
import { calculateExpirationDate } from 'utils/expirationPeriod';
import { getItemsByDate, getItemsPerPeriod, getProductsByDate } from 'utils/reservations';
import { Routes, push } from 'routing';

import * as NewRentalSelectors from '../selectors/NewRentalSelectors';

export type NewRentalAction =
	| UpdateRentalInfo
	| ResetNewRental
	| UpdateStartLocation
	| UpdateEndLocation
	| ChangeSelectedCategoryId
	| UpdateShoppers
	| UpdateResponsiblePerson
	| UpdateProducts
	| UpdateRental
	| UpdatePhone
	| UpdateProductAvailabilities
	| UpdateReservationId
	| UpdatePaymentMethod
	| SetIsDurationManuallyChanged;

/** RENTAL INFO ACTIONS */

interface UpdateRental {
	type: 'UPDATE_RENTAL';
	rental: OrderObject;
}

interface UpdatePhone {
	type: 'UPDATE_PHONE';
	phone: PhoneObject;
}

export const updatePhone = (phone: PhoneObject): UpdatePhone => {
	return {
		type: 'UPDATE_PHONE',
		phone,
	};
};

interface UpdateProductAvailabilities {
	type: 'UPDATE_PRODUCT_AVAILABILITIES';
	productAvailabilities: ById<ProductTotalAvailability>;
}

export const updateProductAvailabilities = (
	productAvailabilities: ById<ProductTotalAvailability>,
): UpdateProductAvailabilities => {
	return {
		type: 'UPDATE_PRODUCT_AVAILABILITIES',
		productAvailabilities,
	};
};

export const updateRental = (rental: OrderObject): UpdateRental => {
	return {
		type: 'UPDATE_RENTAL',
		rental,
	};
};

interface UpdateRentalInfo {
	type: 'UPDATE_RENTAL_INFO';
	rentalInfo: OrderInfo;
}

export const updateRentalInfo = (rentalInfo: OrderInfo): UpdateRentalInfo => {
	return {
		type: 'UPDATE_RENTAL_INFO',
		rentalInfo,
	};
};

interface UpdatePaymentMethod {
	type: 'UPDATE_PAYMENT_METHOD';
	paymentMethod: ShopOnlinePaymentMethodObject;
}

export const updatePaymentMethod = (
	paymentMethod: ShopOnlinePaymentMethodObject,
): UpdatePaymentMethod => {
	return {
		type: 'UPDATE_PAYMENT_METHOD',
		paymentMethod,
	};
};

export const setRentalCancellationPolicy = (
	cancellationPolicy: CancellationObject[] | undefined,
): ThunkResult => {
	return (dispatch, getState) => {
		const rentalInfo = getState().newRental.rentalInfo;
		if (cancellationPolicy) {
			dispatch({
				type: 'UPDATE_RENTAL_INFO',
				rentalInfo: {
					...rentalInfo,
					cancellationPolicy,
				},
			});
		}
	};
};

export const setRentalStartDate = (value: string): ThunkResult => {
	return (dispatch, getState) => {
		const rentalInfo = getState().newRental.rentalInfo;
		dispatch({
			type: 'UPDATE_RENTAL_INFO',
			rentalInfo: {
				...rentalInfo,
				startDate: value,
			},
		});
	};
};

interface UpdateStartLocation {
	type: 'UPDATE_START_LOCATION';
	location: Location;
}

export const updateStartLocation = (location: Location): UpdateStartLocation => {
	return {
		type: 'UPDATE_START_LOCATION',
		location,
	};
};

interface UpdateEndLocation {
	type: 'UPDATE_END_LOCATION';
	location: Location;
}

export const updateEndLocation = (location: Location): UpdateEndLocation => {
	return {
		type: 'UPDATE_END_LOCATION',
		location,
	};
};

interface UpdateReservationId {
	type: 'UPDATE_RESERVATION_ID';
	reservationId: string | null;
}

export const updateReservationId = (reservationId: string | null): UpdateReservationId => {
	return {
		type: 'UPDATE_RESERVATION_ID',
		reservationId,
	};
};

export interface UpdateResponsiblePerson {
	type: 'UPDATE_RESPONSIBLE_PERSON';
	responsiblePerson: ResponsiblePerson;
}

export const updateResponsiblePerson = (
	responsiblePerson: ResponsiblePerson,
): UpdateResponsiblePerson => {
	return {
		type: 'UPDATE_RESPONSIBLE_PERSON',
		responsiblePerson,
	};
};

export const updateRentalInfoCurrency = (currencyCode: string): ThunkResult => {
	return (dispatch, getState) => {
		const rentalInfo = getState().newRental.rentalInfo;
		dispatch(
			updateRentalInfo({
				...rentalInfo,
				pricing: {
					...rentalInfo.pricing,
					currency: currencyCode,
				},
			}),
		);
	};
};

export const addRentalDiscount = (discount: Discount): ThunkResult => {
	return (dispatch, getState) => {
		const { rentalInfo, products, orderDelivery } = getState().newRental;
		const productsWithDiscount = addDiscountToProducts(products, discount.amount || 0).full;
		const pricingWithDiscount = {
			...rentalInfo.pricing,
			manualDiscount: discount,
		};
		const newPricing = getOrderPricing(
			productsWithDiscount,
			orderDelivery,
			getPricingOptions(pricingWithDiscount),
		);
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			pricing: newPricing,
		};
		dispatch(updateRentalInfo(newRentalInfo));
		dispatch(updateProducts(productsWithDiscount));
	};
};

/** SHOPPER ACTIONS */

interface UpdateShoppers {
	type: 'UPDATE_SHOPPERS';
	shoppers: Shopper[];
}

export const updateShoppers = (shoppers: Shopper[]): UpdateShoppers => {
	return {
		type: 'UPDATE_SHOPPERS',
		shoppers,
	};
};

/** PRODUCT ACTIONS */

interface UpdateProducts {
	type: 'UPDATE_PRODUCTS';
	products: OrderProduct[];
}

export const updateProducts = (products: OrderProduct[]): UpdateProducts => {
	return {
		type: 'UPDATE_PRODUCTS',
		products,
	};
};

/** CATEGORY ACTIONS */

interface ChangeSelectedCategoryId {
	type: 'CHANGE_SELECTED_CATEGORY_ID';
	categoryId: string;
}

export const changeSelectedCategoryId = (categoryId: string): ChangeSelectedCategoryId => {
	return {
		type: 'CHANGE_SELECTED_CATEGORY_ID',
		categoryId,
	};
};

export const sendRentalLink = (
	lang: Languages,
	openingHours: OpeningHours,
	rentalLinkInfo: RentalLinkInfo,
	expirationPeriod?: BufferTime,
): ThunkResult<Promise<boolean>> => {
	return async (dispatch, getState) => {
		const activeShop = ShopSelectors.activeShopData(getState());
		if (!activeShop) {
			return false;
		}
		const rental = getState().newRental;
		const { rentalInfo, shoppers, products } = rental;
		const { linkId, firstName, lastName, email, phone } = rentalLinkInfo;
		// Temporarily allow only test payments when handle payment
		const shopMeta = activeShop.publicInfo;
		const timestamp = new Date().toISOString();
		const expirationDate = calculateExpirationDate({
			timestamp,
			startDate: rentalInfo.startDate,
			openingHours,
			timezone: shopMeta.timeZone,
			expirationPeriod,
		});
		const responsiblePersonDetails = getResponsiblePersonDetails(
			rentalInfo.responsiblePerson,
			shoppers,
		);
		const externalResponsiblePerson = rentalInfo.responsiblePerson.external;
		const partialResponsiblePersonDetails: Partial<ResponsiblePersonDetails> = {
			firstName: firstName ?? undefined,
			lastName: lastName ?? undefined,
			email: email ?? undefined,
			phone: phone ?? undefined,
		};
		const newRentalInfo: OrderInfo = {
			...rental.rentalInfo,
			rentalLink: rentalLinkInfo,
			channel: 'PAYLINK',
			rentalState: 'BOOKED',
			activeState: 'PAYLINK_SENT',
			created: timestamp,
			expirationDate,
			live: false,
			...(rentalInfo.responsiblePerson.external && {
				responsiblePerson: {
					external: true,
					person: {
						...rentalInfo.responsiblePerson.person,
						...responsiblePersonDetails,
					},
				},
			}),
		};
		const newShoppers = externalResponsiblePerson
			? shoppers
			: shoppers.map((s) =>
					s.id === responsiblePersonDetails.id
						? {
								...s,
								...partialResponsiblePersonDetails,
						  }
						: s,
			  );
		const newRental: OrderObject = {
			rentalInfo: newRentalInfo,
			products,
			shoppers: newShoppers,
		};
		try {
			await setNewOrder(newRental);
		} catch (e) {
			errorHandler.report(e);
			return false;
		}
		dispatch(updateRental(newRental));
		try {
			const rentalLinkUrl = getRentalLinkUrl(linkId, lang);
			await Callable.orders.admin.sendPaymentLink({
				shopId: activeShop.publicInfo.shopId,
				order: newRental,
				language: lang,
				rentalLinkInfo,
				rentalLinkUrl,
			});
			IntercomService.trackEvent(IntercomService.CREATED_ORDER, { channel: 'PAYLINK' });
			const confirmState = {
				rentalLink: rentalLinkInfo,
			};
			push(Routes.NewRentalDone('link'), confirmState);
		} catch (e) {
			dispatch(
				NotificationActions.showNotification({
					message: 'Something went wrong when sending the payment link',
					variant: 'error',
				}),
			);
			const confirmState = {
				rentalLink: rentalLinkInfo,
			};
			push(Routes.NewRentalDone('link'), confirmState);
		}
		dispatch(resetNewRentalState());
		return true;
	};
};

export const setOrderProductsDates = (
	startDate: string | null,
	endDate: string | null,
	duration: Duration,
): ThunkResult => {
	return (dispatch, getState) => {
		const { products, orderDelivery } = getState().newRental;
		const stockProducts = getState().stock.stockProducts.data!;
		const rentalInfo = getState().newRental.rentalInfo;
		const savedPricingTables = ShopSelectors.activeShopPricingTables(getState());
		const readOnly = ShopSelectors.activeShopReadOnly(getState());
		const hasDepositsFeature = shopHasFeatures('DEPOSITS')(readOnly?.features);
		let newProducts = updateOrderProductsDates(products, startDate, endDate);
		if (startDate && endDate) {
			newProducts = updateOrderProductsDuration(newProducts, duration);
		}
		newProducts = updateOrderProductsPricing({
			orderProducts: newProducts,
			stockProducts,
			savedPricingTables: savedPricingTables || {},
			channel: 'ADMIN',
			disableDeposit: !hasDepositsFeature,
		});
		const newPricing = getOrderPricing(
			newProducts,
			orderDelivery,
			getPricingOptions(rentalInfo.pricing),
		);
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			pricing: newPricing,
		};
		dispatch(updateRentalInfo(newRentalInfo));
		dispatch(updateProducts(newProducts));
	};
};

export const setOrderProductsDuration = (duration: Duration): ThunkResult => {
	return (dispatch, getState) => {
		const { products, rentalInfo, orderDelivery } = getState().newRental;
		const stockProducts = getState().stock.stockProducts.data!;
		const readOnly = ShopSelectors.activeShopReadOnly(getState());
		const savedPricingTables = ShopSelectors.activeShopPricingTables(getState());
		const hasDepositsFeature = shopHasFeatures('DEPOSITS')(readOnly?.features);
		let newProducts = updateOrderProductsDuration(products, duration);
		newProducts = updateOrderProductsPricing({
			orderProducts: newProducts,
			stockProducts,
			savedPricingTables: savedPricingTables || {},
			channel: 'ADMIN',
			disableDeposit: !hasDepositsFeature,
		});
		const newPricing = getOrderPricing(
			newProducts,
			orderDelivery,
			getPricingOptions(rentalInfo.pricing),
		);
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			pricing: newPricing,
		};
		dispatch(updateRentalInfo(newRentalInfo));
		dispatch(updateProducts(newProducts));
	};
};

export const addNewShopper = (id: string): ThunkResult => {
	return (dispatch, getState) => {
		const shoppers = getState().newRental.shoppers;
		const shopId = ShopSelectors.activeShopId(getState())!;
		const rentalInfo = getState().newRental.rentalInfo;
		const newShopper = getEmptyShopper(id, rentalInfo.id, shopId);
		const newShoppers = [...shoppers, newShopper];
		dispatch(updateShoppers(newShoppers));
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			shopperIdsWithProducts: {
				...rentalInfo.shopperIdsWithProducts,
				[newShopper.id]: [],
			},
		};
		dispatch(updateRentalInfo(newRentalInfo));
	};
};

export const deleteShopper = (shopperId: string): ThunkResult => {
	return (dispatch, getState) => {
		const { rentalInfo, shoppers, products, orderDelivery } = getState().newRental;
		const newShoppers = shoppers.filter((s) => s.id !== shopperId);
		const newProducts = products.filter((p) => p.shopperId !== shopperId);
		const shopperIdsWithProducts = rentalInfo.shopperIdsWithProducts;
		// ES6 desctructuring method to remove shopperId content from object of objects
		const { [shopperId]: removedShopperId, ...restShopperIds } = shopperIdsWithProducts;
		const newPricing = getOrderPricing(
			newProducts,
			orderDelivery,
			getPricingOptions(rentalInfo.pricing),
		);
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			shopperIdsWithProducts: restShopperIds,
			pricing: newPricing,
		};
		dispatch(updateShoppers(newShoppers));
		dispatch(updateProducts(newProducts));
		dispatch(updateRentalInfo(newRentalInfo));
		if (
			!rentalInfo.responsiblePerson.external &&
			rentalInfo.responsiblePerson.shopperId === shopperId
		) {
			const updatedResponsiblePerson: ResponsiblePerson = {
				external: false,
				shopperId: newShoppers[0].id,
			};
			dispatch(updateResponsiblePerson(updatedResponsiblePerson));
		}
	};
};

export const updateProductsForShopper = (
	orderProducts: OrderProduct[],
	shopperId: string,
): ThunkResult => {
	return (dispatch, getState) => {
		const { rentalInfo, products, shoppers, orderDelivery } = getState().newRental;
		const newProducts = [...products.filter((p) => p.shopperId !== shopperId), ...orderProducts];
		const newPricing = getOrderPricing(
			newProducts,
			orderDelivery,
			getPricingOptions(rentalInfo.pricing),
		);
		let newShopperIdsWithProducts = { ...rentalInfo.shopperIdsWithProducts };
		const newShoppers = shoppers.map((shopper) => {
			if (shopper.id === shopperId) {
				const productIds = orderProducts.map((p) => p.id);
				newShopperIdsWithProducts = {
					[shopper.id]: productIds,
					...rentalInfo.shopperIdsWithProducts,
				};
				return {
					...shopper,
					productIds,
				};
			}
			return shopper;
		});
		const newRentalInfo = {
			...rentalInfo,
			shopperIdsWithProducts: newShopperIdsWithProducts,
			pricing: newPricing,
		};
		dispatch(updateRentalInfo(newRentalInfo));
		dispatch(updateProducts(newProducts));
		dispatch(updateShoppers(newShoppers));
	};
};

export const addProduct = (orderProduct: OrderProduct): ThunkResult => {
	return (dispatch, getState) => {
		const { rentalInfo, products, shoppers, orderDelivery } = getState().newRental;
		const newProducts: OrderProduct[] = [...products, orderProduct];
		const newPricing = getOrderPricing(
			newProducts,
			orderDelivery,
			getPricingOptions(rentalInfo.pricing),
		);
		let newShopperIdsWithProducts = { ...rentalInfo.shopperIdsWithProducts };
		const newShoppers = shoppers.map((shopper) => {
			if (shopper.id === orderProduct.shopperId) {
				const productIds = [...shopper.productIds, orderProduct.id];
				newShopperIdsWithProducts = {
					[shopper.id]: productIds,
					...rentalInfo.shopperIdsWithProducts,
				};
				return {
					...shopper,
					productIds,
					categoryIds: [
						...new Set([
							...(shopper.categoryIds || []),
							...(orderProduct.categoryIds && orderProduct.categoryIds.length
								? orderProduct.categoryIds
								: []),
						]),
					],
				};
			}
			return shopper;
		});
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			shopperIdsWithProducts: newShopperIdsWithProducts,
			pricing: newPricing,
		};
		dispatch(updateRentalInfo(newRentalInfo));
		dispatch(updateProducts(newProducts));
		dispatch(updateShoppers(newShoppers));
	};
};

export const deleteProduct = (orderProductId: string): ThunkResult => {
	return (dispatch, getState) => {
		const { rentalInfo, products, shoppers, orderDelivery } = getState().newRental;
		let productUnits = 0;
		const newProducts = products.filter((p) => {
			if (p.id === orderProductId) {
				productUnits++;
				return false;
			}
			return true;
		});

		const newPricing = getOrderPricing(
			newProducts,
			orderDelivery,
			getPricingOptions(rentalInfo.pricing),
		);
		let newShopperIdsWithProducts = { ...rentalInfo.shopperIdsWithProducts };
		const newShoppers = shoppers.map((shopper) => {
			let productIds: string[] = [];
			if (shopper.productIds.includes(orderProductId) && productUnits === 1) {
				const newCategoryIds = newProducts
					.filter((p) => p.shopperId === shopper.id)
					.flatMap((p) => (p.categoryIds && p.categoryIds.length ? p.categoryIds : []));
				productIds = shopper.productIds.filter((pId) => pId !== orderProductId);
				newShopperIdsWithProducts = {
					...rentalInfo.shopperIdsWithProducts,
					[shopper.id]: productIds,
				};
				return {
					...shopper,
					productIds,
					categoryIds: [...new Set(newCategoryIds)],
				};
			}
			return shopper;
		});
		const newRentalInfo: OrderInfo = {
			...rentalInfo,
			shopperIdsWithProducts: newShopperIdsWithProducts,
			pricing: newPricing,
		};
		dispatch(updateRentalInfo(newRentalInfo));
		dispatch(updateProducts(newProducts));
		dispatch(updateShoppers(newShoppers));
	};
};

export const setHandlePayment = (payment: boolean): ThunkResult => {
	return (dispatch, getState) => {
		const rentalInfo = getState().newRental.rentalInfo;
		const updatedRentalInfo: OrderInfo = {
			...rentalInfo,
			handlePayment: payment,
		};
		dispatch(updateRentalInfo(updatedRentalInfo));
	};
};

export const setRentalLinkInfo = (
	field: string,
	value: string | ShopOnlinePaymentMethodObject[] | null,
): ThunkResult => {
	return (dispatch, getState) => {
		const rentalInfo = getState().newRental.rentalInfo;
		const rentalLinkData = rentalInfo.rentalLink;
		const updatedRentalLinkData = !!rentalLinkData?.linkId
			? { ...rentalLinkData, [field]: value }
			: undefined;
		const updatedRentalInfo: OrderInfo = {
			...rentalInfo,
			rentalLink: updatedRentalLinkData,
		};
		dispatch(updateRentalInfo(updatedRentalInfo));
	};
};

export const initialiseRentalInfo = (activeShop: Shop): ThunkResult => {
	return (dispatch, getState) => {
		const rentalInfo = getState().newRental.rentalInfo;
		const activeLocation = UserSelectors.userActiveLocation(getState());
		if (!activeLocation) {
			return;
		}
		const shopId = activeShop.shopInfo.id;
		const currency = activeShop.publicInfo.currency.code;
		const newOrderId = newFirestoreId();
		const cancellationPolicy = activeShop.publicInfo.cancellationPolicy;
		const startDate = getState().view.startDate;
		const taxExcluded = ShopSelectors.getShopTaxExcluded(getState());
		const populatedRentalInfo: OrderInfo = {
			...rentalInfo,
			startLocation: activeLocation,
			endLocation: activeLocation,
			id: newOrderId,
			shopId,
			live: false,
			startDate,
			initialStartDate: startDate,
			...(cancellationPolicy && { cancellationPolicy }),
			pricing: getInitialOrderPricing({
				currency,
				taxExcluded,
				paymentMethod: rentalInfo.pricing.paymentMethod,
			}),
		};
		dispatch(updateRentalInfo(populatedRentalInfo));
		dispatch(
			setDefaultRentalLocation(
				activeShop,
				populatedRentalInfo.startLocation,
				populatedRentalInfo.endLocation,
			),
		);
		const shoppers = getState().newRental.shoppers.map((shopper) => {
			return {
				...shopper,
				shopId,
				rentalId: newOrderId,
			};
		});
		dispatch(updateShoppers(shoppers));
		const initialPhoneObject = getPhoneObjectForCountry(activeShop.publicInfo.country || '');
		if (initialPhoneObject) {
			dispatch(updatePhone(initialPhoneObject));
		}
	};
};

export const setDefaultRentalLocation = (
	activeShop: Shop,
	startLocation: Location,
	endLocation: Location,
): ThunkResult => {
	return (dispatch) => {
		const defaultLocations: Location[] = getShopLocationsWithContact(
			activeShop.publicInfo,
			'ADMIN',
		);
		const defaultStartLocation: Location | undefined =
			defaultLocations.find((location) => location.id === startLocation.id) ?? defaultLocations[0];
		const defaultEndLocation: Location | undefined =
			defaultLocations.find((location) => location.id === endLocation.id) ?? defaultLocations[0];
		if (defaultStartLocation) {
			dispatch(updateStartLocation(defaultStartLocation));
		}
		if (defaultEndLocation) {
			dispatch(updateEndLocation(defaultEndLocation));
		}
	};
};

interface ResetNewRental {
	type: 'RESET_NEW_RENTAL_STATE';
	shopId: string | null;
}

export const resetNewRentalState = (): ThunkResult => {
	return (dispatch, getState) => {
		const activeShop = getState().shop.shop.data;
		dispatch({
			type: 'RESET_NEW_RENTAL_STATE',
			shopId: activeShop ? activeShop.shopInfo.id : null,
		});
		if (activeShop) {
			dispatch(initialiseRentalInfo(activeShop));
		}
	};
};

export const createReservation = createAsyncThunk<
	boolean,
	void,
	{
		state: ReduxState;
	}
>('NewRental/CREATE_RESERVATION', async (_, thunkAPI) => {
	const state = thunkAPI.getState();
	const products = NewRentalSelectors.products(state);
	const singleCartProduct = products?.[0];
	if (!singleCartProduct) {
		thunkAPI.rejectWithValue('No items selected!');
	}
	const { startLocationId, endLocationId, startDate, endDate } = singleCartProduct;
	const variantIds = uniq(products.flatMap((p) => p.summary.variantIds));
	const skuIds = uniq(products.flatMap((p) => p.summary.skuIds));

	const byDate = getProductsByDate(products);
	const itemsByDate = getItemsByDate(byDate);
	const itemsPerPeriod = getItemsPerPeriod(itemsByDate, startDate, endDate);

	const reservationId = newFirestoreId();

	const reservation: Reservation = {
		id: reservationId,
		created: new Date().toISOString(),
		startLocationId,
		endLocationId,
		channel: 'ADMIN',
		includedVariantIds: variantIds,
		skuIds,
		itemsPerPeriod,
	};
	api().reservations.doc(reservationId).set(reservation);
	thunkAPI.dispatch(updateReservationId(reservationId));
	saveToStorage(Storages.SESSION, StorageKeys.RESERVATION_ID, reservationId);
	return true;
});

export const removeReservation = createAsyncThunk<
	boolean,
	void,
	{
		state: ReduxState;
	}
>('NewRental/REMOVE_RESERVATION', async (_, thunkAPI) => {
	const state = thunkAPI.getState();
	const reservationId = NewRentalSelectors.reservationId(state);
	if (!reservationId) {
		return thunkAPI.rejectWithValue('No reservationId');
	}
	api().reservations.doc(reservationId).delete();
	thunkAPI.dispatch(updateReservationId(null));
	removeFromStorage(Storages.SESSION, StorageKeys.RESERVATION_ID);
	return true;
});

interface SetIsDurationManuallyChanged {
	type: 'SET_IS_DURATION_MANUALLY_CHANGED';
	payload: boolean;
}

export const setIsDurationManuallyChanged = (value: boolean): SetIsDurationManuallyChanged => {
	return {
		type: 'SET_IS_DURATION_MANUALLY_CHANGED',
		payload: value,
	};
};
