import { BookingBufferApi, BookingBufferDb } from './types';

export const toApi = (db: BookingBufferDb): BookingBufferApi => {
	const { daysBefore, minutesBefore, latestTime } = db;
	return {
		daysBefore,
		minutesBefore,
		latestTime,
	};
};

export const toDb = (api: BookingBufferApi): BookingBufferDb => {
	const { daysBefore, minutesBefore, latestTime } = api;
	return {
		daysBefore,
		minutesBefore,
		latestTime,
	};
};
