import { CallableCreator } from '..';

export interface DomainRecordsRequest {
	hostname: string;
}

export type DomainRecordsResponse = Array<{
	key: 'CNAME' | 'A' | 'AAAA';
	value: string;
}>;

export type CreateCustomDomainArgs = {
	merchantId: string;
	domainName: string;
	dnsVerified: boolean;
	isExistingDomain: boolean;
};

export type UpdateCustomDomainArgs = {
	merchantId: string;
	domainId: string;
};

export const customDomains = (createCallable: CallableCreator) => ({
	fetchDnsRecords: createCallable<DomainRecordsRequest, DomainRecordsResponse>(
		'customDomains-fetchDnsRecords',
	),
	createCustomDomain: createCallable<CreateCustomDomainArgs, void>(
		'customDomains-createCustomDomain',
	),
	refreshDomainRecords: createCallable<UpdateCustomDomainArgs, void>(
		'customDomains-refreshDomainRecords',
	),
	deleteCustomDomain: createCallable<UpdateCustomDomainArgs, void>(
		'customDomains-deleteCustomDomain',
	),
});
