import React, { forwardRef, useMemo } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import NumberFormat from 'react-number-format';

import { CurrencyObject } from 'common/types';
import { getPricingString } from 'common/utils/common';

import useFocusWithin from '../hooks/useFocusWithin';

interface MoneyTextFieldProps {
	value: number | undefined | null;
	onValueChange?: (value: number) => void;
	currency: CurrencyObject | undefined;
	decimalPlaces?: number;
	allowNegative?: boolean;
	useMinorUnits?: boolean;
	allowChangeWhenUnfocused?: boolean;
	dataQA?: string;
}

interface MoneyFormatProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
	value: number;
}

interface MoneyFormatArgs {
	currencySymbol: string;
	currencyPosition: 'prefix' | 'suffix';
	decimalScale: number;
	allowNegative?: boolean;
	useMinorUnits?: boolean;
}

const MoneyFormat = (args: MoneyFormatArgs) =>
	React.forwardRef((props: MoneyFormatProps, ref) => {
		const { onChange, value, ...other } = props;

		return (
			<NumberFormat
				{...other}
				value={value}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator={' '}
				allowEmptyFormatting={false}
				allowNegative={args.allowNegative ?? false}
				prefix={args.currencyPosition === 'prefix' ? `${args.currencySymbol} ` : undefined}
				suffix={args.currencyPosition === 'suffix' ? ` ${args.currencySymbol}` : undefined}
				decimalScale={args.decimalScale}
				allowedDecimalSeparators={['.', ',']}
				allowLeadingZeros={false}
			/>
		);
	});

type Props = TextFieldProps & MoneyTextFieldProps;
const MoneyTextField = forwardRef((props: Props, ref: React.ForwardedRef<any>) => {
	const {
		currency,
		decimalPlaces,
		value,
		allowNegative,
		onChange,
		onValueChange,
		useMinorUnits,
		dataQA,
		...textFieldProps
	} = props;
	const [hasFocus, setHasFocus, focusProps] = useFocusWithin();

	const InputComponent = useMemo(() => {
		if (!currency) return;
		return MoneyFormat({
			currencySymbol: currency.displayAs,
			currencyPosition: currency.position,
			decimalScale: decimalPlaces ?? 2,
			useMinorUnits,
			allowNegative,
		});
	}, [currency, decimalPlaces, allowNegative, useMinorUnits]);

	const disabledProps =
		!currency || textFieldProps.disabled
			? {
					disabled: true,
					value: '',
			  }
			: {};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!hasFocus && !props.allowChangeWhenUnfocused) return;
		if (onValueChange) {
			const rawValue = parseFloat(e.target.value);
			if (isNaN(rawValue)) {
				onValueChange(0);
			} else {
				onValueChange(useMinorUnits ? rawValue * 100 : rawValue);
			}
		}

		if (onChange) {
			onChange(e);
		}
	};

	const inputValue = !value ? '' : useMinorUnits ? value / 100 : value;

	return (
		<TextField
			{...textFieldProps}
			{...disabledProps}
			value={inputValue}
			onChange={handleChange}
			InputProps={{
				...textFieldProps.InputProps,
				...focusProps,
				inputComponent: InputComponent as any,
			}}
			placeholder={textFieldProps.placeholder ?? getPricingString(0, currency)}
			inputProps={{
				'data-qa': dataQA,
			}}
			ref={ref}
		/>
	);
});

export default MoneyTextField;
