import { OrderPricingApi, OrderPricingDb } from './types';

export const toApi = (data: OrderPricingDb): OrderPricingApi => {
	return {
		currency: data.currency,
		total: data.total,
		subtotal: data.subtotal,
		totalTaxes: data.totalTaxes,
		totalDiscounts: data.totalDiscounts,
		taxExcluded: data.taxExcluded,
		taxLines: data.taxLines,
		manualDiscount: data.manualDiscount
			? {
					value: data.manualDiscount.amount || 0,
					percentage: data.manualDiscount.percentage || undefined,
			  }
			: undefined,
		discountCodes: !!data.discountCodes
			? Object.entries(data.discountCodes).map(([code, value]) => {
					return {
						code,
						value: value.totalDiscountValue,
						quantity: value.quantity,
					};
			  })
			: undefined,
		deposit: data.deposit
			? {
					value: data.deposit.value,
					type: data.deposit.type ?? 'payment',
			  }
			: undefined,
	};
};

export const toDb = (data: OrderPricingApi): OrderPricingDb => {
	return {
		currency: data.currency,
		total: data.total,
		subtotal: data.subtotal,
		totalTaxes: data.totalTaxes,
		totalDiscounts: data.totalDiscounts,
		taxExcluded: data.taxExcluded,
		taxLines: data.taxLines,
		manualDiscount: data.manualDiscount
			? {
					amount: data.manualDiscount.value,
					percentage: data.manualDiscount.percentage,
			  }
			: undefined,
		discountCodes: !!data.discountCodes
			? Object.entries(data.discountCodes).reduce((acc, [code, value]) => {
					return {
						...acc,
						[code]: {
							totalDiscountValue: value.value,
							quantity: value.quantity,
						},
					};
			  }, {} as OrderPricingDb['discountCodes'])
			: undefined,
		deposit: data.deposit
			? {
					value: data.deposit.value,
					type: data.deposit.type,
			  }
			: undefined,
	};
};
