import { Languages, LocaleField, Translatable } from './types';

export const getTranslation = (
	locale: LocaleField | undefined | null,
	lang: Languages | 'def',
): string => {
	if (!locale) return '';
	// For possible old locale string types
	if (typeof locale === 'string') {
		return locale;
	}

	return (getValueForLanguage(locale, lang) || getDefault(locale) || '').trim();
};

export const getDefault = (localeField: LocaleField | undefined): string => {
	return localeField?.def || '';
};

export const getValueForLanguage = (
	localeField: LocaleField | undefined,
	language: Languages | 'def',
): string => {
	return localeField?.[language] || '';
};

export const updateTranslation = (localeField: LocaleField, language: Languages, value: string) => {
	return {
		...localeField,
		[language]: value,
	};
};

export const hasTranslation = (localeField: LocaleField | undefined, language: Languages) => {
	return !!localeField?.[language];
};

export const hasValue = (localeField: LocaleField | undefined) => {
	return !!localeField?.def;
};

export const updateDefault = (
	localeField: LocaleField,
	defaultLanguage: Languages,
	value: string,
) => {
	return {
		...localeField,
		def: value,
		[defaultLanguage]: value,
	};
};

export const getLocaleFieldTranslationPercentage = (
	localeField: LocaleField,
	languages: Languages[],
) => {
	const translatedLanguages = languages.filter((language) => hasTranslation(localeField, language))
		.length;

	return Math.round((100 * translatedLanguages) / languages.length);
};

export const getTranslationPercentage = (translatable: Translatable, language: Languages) => {
	const { translated, total } = Object.entries(translatable.fields).reduce(
		(result, [key, field]) => {
			if (!hasValue(field.value)) return result;
			result.total += 1;
			if (hasTranslation(field.value, language)) result.translated += 1;
			return result;
		},
		{ translated: 0, total: 0 },
	);

	return {
		translated,
		total,
		percentage: total === 0 ? 100 : Math.round((translated * 100) / total),
	};
};

export const getTranslationPercentageMany = (
	translatables: Translatable[],
	language: Languages,
) => {
	const { translated, total } = translatables.reduce(
		(result, translatable) => {
			const value = getTranslationPercentage(translatable, language);
			result.total += value.total;
			result.translated += value.translated;
			return result;
		},
		{ translated: 0, total: 0 },
	);

	return {
		translated,
		total,
		percentage: total === 0 ? 100 : Math.round((translated * 100) / total),
	};
};

export const createLocaleField = (value: string, defaultLanguage: Languages) => {
	return { def: value, [defaultLanguage]: value };
};

export const availableLanguagesWithInfo: Array<{ lang: Languages; info: string }> = [
	{ lang: 'en', info: 'rentle-free' },
	{ lang: 'fi', info: 'rentle-free' },
	{ lang: 'de', info: 'rentle-free' },
	{ lang: 'cs', info: 'rentle-free' },
	{ lang: 'es', info: 'rentle-free' },
	{ lang: 'et', info: 'rentle-free' },
	{ lang: 'it', info: 'in-store & online' },
	{ lang: 'sv', info: 'in-store & online' },
	{ lang: 'ru', info: 'in-store & online' },
	{ lang: 'sk', info: 'in-store & online' },
	{ lang: 'nl', info: 'in-store & online' },
	{ lang: 'hu', info: 'in-store & online' },
	{ lang: 'no', info: 'in-store & online' },
	{ lang: 'fr', info: 'in-store & online' },
	{ lang: 'da', info: 'in-store & online' },
	{ lang: 'lt', info: 'in-store & online' },
	{ lang: 'pl', info: 'in-store & online' },
	{ lang: 'sl', info: 'in-store & online' },
];
