import { zChannelApi } from './api';
import { toApi, toDb } from './mapper';

export * from './types';

export const Channel = {
	toApi,
	toDb,
	api: zChannelApi,
};
