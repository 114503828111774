import { sumBy } from 'lodash';
import moment from 'moment-timezone';

import { DeliveryTimeSlot, OrderDeliveryDetails } from 'common/modules/delivery/types';
import { OPEN_ENDED_BLOCKER_YEARS } from 'common/modules/inventoryBlockers';
import { ISOString } from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { yearsToMinutes } from 'common/utils/dateUtils';
import { EMPTY_UNAVAILABLE_OBJECT } from 'common/utils/newRentalUtils';

import { InventoryBlocker, InventoryBlockerMeta, InventoryBlockerReason } from './types';

interface InventoryBlockerArgs {
	/**
	 * The start date of the booking
	 */
	startDate?: ISOString | null;

	/**
	 * The end date of the booking
	 */
	endDate?: ISOString | null;

	/**
	 * The end date of the booking, if it was already returned
	 */
	endDateReturned?: ISOString | null;

	/**
	 * Maintenance time of the product, in minutes
	 */
	maintenanceTime?: number | null;

	/**
	 * Delivery handling time before, in minutes
	 */
	deliveryHandlingBefore?: number | null;

	/**
	 * Delivery slot as dates
	 */
	deliverySlot?: DeliveryTimeSlot | null;

	/**
	 * Pickup slot as dates
	 */
	pickupSlot?: DeliveryTimeSlot | null;

	/**
	 * Delivery handling time after, in minutes
	 */
	deliveryHandlingAfter?: number | null;

	/**
	 * Full pickup details object, which can be provided instead of separately
	 * providing the handling time and timeslot
	 */
	pickupDetails?: OrderDeliveryDetails | null;

	/**
	 * Full delivery details object, which can be provided instead of separately
	 * providing the handling time and timeslot
	 */
	deliveryDetails?: OrderDeliveryDetails | null;

	/**
	 * If a booking should be made openEnded from inventory blocker perspetive
	 */
	isOpenEnded?: boolean;
}

export const getInventoryBlocker = (
	existingBlocker: InventoryBlocker | null,
	changes: InventoryBlockerArgs,
): InventoryBlocker => {
	const datesFromBlocker = getRentalDatesFromInventoryBlocker(existingBlocker);

	const currentStartDate = datesFromBlocker.startDate;
	const currentEndDate = datesFromBlocker.endDate;

	const newStartDate = 'startDate' in changes ? changes.startDate ?? null : currentStartDate;
	let newEndDate =
		'endDateReturned' in changes && !!changes.endDateReturned
			? changes.endDateReturned
			: 'endDate' in changes
			? changes.endDate ?? null
			: currentEndDate;

	const isReturned = !!changes.endDateReturned;

	if (!newStartDate) return EMPTY_UNAVAILABLE_OBJECT;
	if (!newEndDate) newEndDate = newStartDate;

	const rentalDuration = ensurePositive(moment(newEndDate).diff(newStartDate, 'minutes'));

	const maintenanceTime = ensurePositive(
		'maintenanceTime' in changes
			? changes.maintenanceTime
			: existingBlocker?.details.maintenanceTime?.minutes,
	);

	const openEndedBlocker =
		changes.endDateReturned || changes.isOpenEnded === false
			? 0
			: ensurePositive(
					!!changes.isOpenEnded
						? yearsToMinutes(OPEN_ENDED_BLOCKER_YEARS)
						: existingBlocker?.details.openEnded?.minutes,
			  );

	const deliveryHandlingBefore = ensurePositive(
		'deliveryHandlingBefore' in changes
			? changes.deliveryHandlingBefore
			: 'deliveryDetails' in changes
			? getHandlingTimeFromDeliveryDetails(changes.deliveryDetails)
			: existingBlocker?.details.deliveryHandlingBefore?.minutes,
	);

	const deliveryHandlingAfter = !isReturned
		? ensurePositive(
				'deliveryHandlingAfter' in changes
					? changes.deliveryHandlingAfter
					: 'pickupDetails' in changes
					? getHandlingTimeFromDeliveryDetails(changes.pickupDetails)
					: existingBlocker?.details.deliveryHandlingAfter?.minutes,
		  )
		: null;

	const deliverySlotDates = ensureChronologicalTimeslot(
		'deliverySlot' in changes
			? changes.deliverySlot
			: 'deliveryDetails' in changes
			? getTimeslotFromDeliveryDetails(changes.deliveryDetails)
			: getDeliverySlotDatesFromInventoryBlocker({
					blocker: existingBlocker,
					rentalStartDate: currentStartDate,
			  }),
	);

	const deliverySlot = ensurePositive(
		!!deliverySlotDates
			? moment(deliverySlotDates.endDate).diff(deliverySlotDates.startDate, 'minutes')
			: null,
	);

	const deliverySlotDiff = !!deliverySlotDates
		? moment(newStartDate).diff(deliverySlotDates.endDate, 'minutes')
		: null;

	const pickupSlotDates = !isReturned
		? ensureChronologicalTimeslot(
				'pickupSlot' in changes
					? changes.pickupSlot
					: 'pickupDetails' in changes
					? getTimeslotFromDeliveryDetails(changes.pickupDetails)
					: getPickupSlotDatesFromInventoryBlocker({
							blocker: existingBlocker,
							rentalEndDate: currentEndDate,
					  }),
		  )
		: null;

	const pickupSlot = ensurePositive(
		!!pickupSlotDates
			? moment(pickupSlotDates.endDate).diff(pickupSlotDates.startDate, 'minutes')
			: null,
	);

	const pickupSlotDiff = !!pickupSlotDates
		? moment(pickupSlotDates.startDate).diff(newEndDate, 'minutes')
		: null;

	const details: InventoryBlockerMeta = {
		...(!!deliveryHandlingBefore && {
			deliveryHandlingBefore: {
				minutes: deliveryHandlingBefore,
			},
		}),
		...(!!deliverySlot && {
			deliverySlot: {
				minutes: deliverySlot,
			},
		}),
		...(!!deliverySlotDiff && {
			deliverySlotDiff: {
				minutes: deliverySlotDiff,
			},
		}),
		...(!!rentalDuration && {
			rentalDuration: {
				minutes: rentalDuration,
			},
		}),
		...(!!pickupSlotDiff && {
			pickupSlotDiff: {
				minutes: pickupSlotDiff,
			},
		}),
		...(!!pickupSlot && {
			pickupSlot: {
				minutes: pickupSlot,
			},
		}),
		...(!!deliveryHandlingAfter && {
			deliveryHandlingAfter: {
				minutes: deliveryHandlingAfter,
			},
		}),
		...(!!maintenanceTime && {
			maintenanceTime: {
				minutes: maintenanceTime,
			},
		}),
		...(!!openEndedBlocker && {
			openEnded: {
				minutes: openEndedBlocker,
			},
		}),
	};

	return {
		...getUnavailableRangeFromDetails(details, newStartDate),
		details,
	};
};

export const isInventoryBlockerValid = (blocker?: InventoryBlocker | null): boolean => {
	if (!blocker) return false;
	if (!blocker.from || !blocker.until) return false;

	const durationInMinutes = moment(blocker.until).diff(blocker.from, 'minutes');
	const sumOfDetailsInMinutes = sumBy(
		Object.values(blocker.details),
		(detail) => detail.minutes ?? 0,
	);

	return durationInMinutes === sumOfDetailsInMinutes;
};

const getRentalDatesFromInventoryBlocker = (
	blocker?: InventoryBlocker | null,
): { startDate: ISOString | null; endDate: ISOString | null } => {
	if (!blocker) return { startDate: null, endDate: null };

	const {
		rentalDuration,
		deliverySlot,
		deliveryHandlingBefore,
		deliverySlotDiff,
	} = blocker.details;

	const startDate = !!blocker.from
		? moment(blocker.from)
				.add(deliverySlot?.minutes ?? 0, 'minutes')
				.add(deliveryHandlingBefore?.minutes ?? 0, 'minutes')
				.add(deliverySlotDiff?.minutes ?? 0, 'minutes')
				.toISOString()
		: !!blocker.until
		? moment(blocker.until)
				.subtract(rentalDuration?.minutes ?? 0, 'minutes')
				.toISOString()
		: null;

	const endDate = !!startDate
		? moment(startDate)
				.add(blocker.details.rentalDuration?.minutes ?? 0, 'minutes')
				.toISOString()
		: null;

	return { startDate, endDate };
};

const getDeliverySlotDatesFromInventoryBlocker = (args: {
	blocker: InventoryBlocker | null;
	rentalStartDate: ISOString | null;
}): DeliveryTimeSlot | null => {
	const { blocker, rentalStartDate } = args;
	const hasDelivery = !!blocker?.details?.deliverySlot || !!blocker?.details?.deliverySlotDiff;
	if (!hasDelivery || !rentalStartDate) return null;
	const deliverySlotStartTime = moment(rentalStartDate)
		.subtract(blocker.details.deliverySlotDiff?.minutes ?? 0, 'minutes')
		.subtract(blocker.details.deliverySlot?.minutes ?? 0, 'minutes');
	const deliverySlotEndTime = deliverySlotStartTime
		.clone()
		.add(blocker.details.deliverySlot?.minutes ?? 0, 'minutes');

	return {
		startDate: deliverySlotStartTime.toISOString(),
		endDate: deliverySlotEndTime.toISOString(),
	};
};

const getPickupSlotDatesFromInventoryBlocker = (args: {
	blocker: InventoryBlocker | null;
	rentalEndDate: ISOString | null;
}): DeliveryTimeSlot | null => {
	const { blocker, rentalEndDate } = args;
	const hasPickup = !!blocker?.details?.pickupSlot || !!blocker?.details.pickupSlotDiff;
	if (!hasPickup || !rentalEndDate) return null;

	const pickupSlotStartTime = moment(rentalEndDate).add(
		blocker.details.pickupSlotDiff?.minutes ?? 0,
		'minutes',
	);
	const pickupSlotEndTime = moment(pickupSlotStartTime).add(
		blocker.details.pickupSlot?.minutes,
		'minutes',
	);

	return {
		startDate: pickupSlotStartTime.toISOString(),
		endDate: pickupSlotEndTime.toISOString(),
	};
};

export const getTimeslotFromDeliveryDetails = (
	deliveryDetails: OrderDeliveryDetails | null | undefined,
): DeliveryTimeSlot | null => {
	return !deliveryDetails?.disabled ? deliveryDetails?.timeslot ?? null : null;
};

export const getHandlingTimeFromDeliveryDetails = (
	deliveryDetails: OrderDeliveryDetails | null | undefined,
): number | null => {
	return deliveryDetails?.handlingTimeMinutes ?? null;
};

const getUnavailableRangeFromDetails = (
	meta: InventoryBlockerMeta,
	startDate: ISOString,
): Pick<InventoryBlocker, 'from' | 'until'> => {
	const { from, until } = Object.entries(meta).reduce(
		(result, [_reason, { minutes }]) => {
			const reason = _reason as InventoryBlockerReason;
			switch (reason) {
				case 'deliveryHandlingBefore':
				case 'deliverySlotDiff':
				case 'deliverySlot': {
					return {
						...result,
						from: moment(result.from).subtract(minutes, 'minutes'),
					};
				}
				case 'rentalDuration':
				case 'pickupSlot':
				case 'pickupSlotDiff':
				case 'deliveryHandlingAfter':
				case 'maintenanceTime':
				case 'openEnded': {
					return {
						...result,
						until: moment(result.until).add(minutes, 'minutes'),
					};
				}
				default: {
					try {
						switchUnreachable(reason);
					} catch (err) {}
					return result;
				}
			}
		},
		{
			from: moment(startDate),
			until: moment(startDate),
		},
	);

	return {
		from: from.toISOString(),
		until: until.toISOString(),
	};
};

const ensurePositive = (value?: number | null): number => {
	return value != null ? Math.max(value, 0) : 0;
};

const ensureChronologicalTimeslot = (value?: DeliveryTimeSlot | null): DeliveryTimeSlot | null => {
	if (!value) return null;

	return {
		startDate: value.startDate,
		endDate: moment(value.endDate).isBefore(value.startDate) ? value.startDate : value.endDate,
	};
};
