import { Languages, OrderObject, RentalLinkInfo } from 'common/types';

import { CallableCreator } from '..';

type CreateAdminOrder = {
	shopId: string;
	order: OrderObject;
	language: Languages;
	chargeType: {
		type: 'CHARGE_NOW' | 'PAYMENT_LINK' | 'CHARGE_LATER';
		options: object;
	};
};

type SendPaymentLinkArgs = {
	shopId: string;
	order: OrderObject;
	language: Languages;
	rentalLinkInfo: RentalLinkInfo;
	rentalLinkUrl: string;
};

export type CompletedOrderResult = {
	status: 'SUCCESS';
	order: OrderObject;
};

export type InCompleteOrderResult = {
	status: 'FAILED' | 'REQUIRES_ACTION';
};

export type CreateAdminOrderResult = CompletedOrderResult | InCompleteOrderResult;

export type SendPaymentLinkResult = {
	status: 'SUCCESS' | 'FAILED';
	failedServices?: string[];
};

export const admin = (createCallable: CallableCreator) => ({
	create: createCallable<CreateAdminOrder, CreateAdminOrderResult>('orders-admin-create'),
	sendPaymentLink: createCallable<SendPaymentLinkArgs, SendPaymentLinkResult>(
		'orders-admin-sendPaymentLink',
	),
});
