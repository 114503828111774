import React from 'react';

import { Button, Pagination, Stack } from '@mui/material';
import {
	GridFooterContainer,
	gridPageCountSelector,
	gridPageSelector,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid-pro';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';

const ListingsTableFooter = () => {
	const apiRef = useGridApiContext();
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	const CustomPagination = () => {
		return (
			<Pagination
				color="standard"
				count={pageCount}
				page={page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
				shape="rounded"
				hideNextButton
				hidePrevButton
			/>
		);
	};
	return (
		<GridFooterContainer sx={{ width: '100%' }}>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ width: '100%' }}
				p={2}
			>
				<Button onClick={() => apiRef.current.setPage(page - 1)} variant="outlined">
					<Stack direction="row">
						<ArrowLeft /> {'Previous'}
					</Stack>
				</Button>
				<CustomPagination />
				<Button onClick={() => apiRef.current.setPage(page + 1)} variant="outlined">
					<Stack direction="row">
						{'Next'} <ArrowRight />
					</Stack>
				</Button>
			</Stack>
		</GridFooterContainer>
	);
};

export default ListingsTableFooter;
