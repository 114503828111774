import React, { useState } from 'react';

import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { IconType } from 'react-icons';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { makeStyles } from 'tss-react/mui';

import Banner from './Banner';
import { BannerGroupProps, IconByVariant } from './types';

const BannerGroup = (props: BannerGroupProps) => {
	const { variant, title, icon, items, ...boxProps } = props;
	const { classes } = useStyles(props);
	const [isOpen, setIsOpen] = useState(false);
	const Icon = icon ?? IconByVariant[variant];

	const renderHeader = (title: string, icon: IconType) => (
		<Stack
			direction="column"
			key={title}
			onClick={() => setIsOpen(!isOpen)}
			sx={{ cursor: 'pointer' }}
		>
			<Stack direction="row" justifyContent="flex-start" alignItems="center">
				<Box className={classes.iconWrapper}>{!!icon && <Icon color="inherit" />}</Box>
				<Typography
					variant="h6"
					color="text.primary"
					sx={{
						flex: 1,
						mr: 2,
						fontWeight: '500',
						fontSize: 16,
					}}
				>
					{title}
				</Typography>
				<IconButton edge="end" aria-label="go" size="small">
					{isOpen ? <RiArrowUpSLine size={24} /> : <RiArrowDownSLine size={24} />}
				</IconButton>
			</Stack>
			{!!isOpen && <Box sx={{ mb: 2 }} />}
		</Stack>
	);

	return (
		<Box className={classes.wrapper} {...boxProps}>
			{renderHeader(title, Icon)}
			<Collapse in={isOpen}>
				{items.map((item) => (
					<Box sx={{ mb: isOpen ? 1 : 0 }} key={item.title}>
						<Banner {...item} variant="none" />
					</Box>
				))}
			</Collapse>
		</Box>
	);
};

const useStyles = makeStyles<BannerGroupProps>()((theme: Theme, { variant, raised }) => ({
	wrapper: {
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		borderRadius: 4,
		boxShadow: raised ? '0 1px 10px 0 rgba(0,0,0,0.35)' : 'none',
		backgroundColor: (() => {
			switch (variant) {
				case 'info':
					return theme.palette.info.lightest;
				case 'warning':
					return theme.palette.warning.lightest;
				case 'danger':
					return theme.palette.error.lightest;
				case 'success':
					return theme.palette.success.lightest;
				default:
					return undefined;
			}
		})(),
	},
	iconWrapper: {
		paddingRight: theme.spacing(2),
		minWidth: 40,
		maxWidth: 40,
		color: (() => {
			switch (variant) {
				case 'info':
					return theme.palette.info.main;
				case 'warning':
					return theme.palette.warning.main;
				case 'danger':
					return theme.palette.error.main;
				case 'success':
					return theme.palette.success.main;
				default:
					return undefined;
			}
		})(),
	},
}));

export default BannerGroup;
