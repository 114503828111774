import React from 'react';

import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { AddOn, getAddOnDetails } from 'common/modules/plans';
import { getAddOnIcon } from 'common/modules/plans/add-ons/getAddOnIcon';
import { useTranslation } from 'services/localization';
import { Routes, replace } from 'routing';

interface Props {
	addOn: AddOn;
	open: boolean;
	onClose: () => void;
	onExited: () => void;
}

const InstalledAddOnModal = (props: Props) => {
	const { addOn, open, onClose, onExited } = props;
	const { classes } = useStyles();
	const { t } = useTranslation();
	const addOnDetails = getAddOnDetails(addOn, t);

	const handleBrowseMoreAddOns = () => {
		onClose();
		replace(Routes.ADD_ONS_STORE);
	};

	return (
		<Modal
			open={open}
			BackdropComponent={Backdrop}
			BackdropProps={{ timeout: 300 }}
			onClose={onClose}
			className={classes.modal}
		>
			<Fade in={open} onExited={onExited}>
				<div className={classes.container} role="dialog">
					<Box className={classes.contentContainer}>
						<img className={classes.icon} src={getAddOnIcon(addOn) ?? undefined} alt={addOn} />
						<Box>
							<Typography variant="h5">
								{t('account.addOns.modal.title', "Yay! You're all set")}
							</Typography>
						</Box>
						<Box mt={4} className={classes.body}>
							<Box m={1} className={classes.content}>
								<Typography variant="body1" color="textSecondary">
									{t('account.addOns.modal.body', {
										name: addOnDetails.name,
										defaultValue: '{{name}} is now installed on your Rentle account.',
									})}
								</Typography>
							</Box>
						</Box>

						<Box className={classes.buttonContainer}>
							<Box mt={4}>
								<Button
									className={classes.button}
									variant="contained"
									color="primary"
									onClick={onClose}
								>
									{t('common:actions.continue')}
								</Button>
							</Box>
							<Box mt={4}>
								<Button
									fullWidth
									className={classes.button}
									variant="text"
									onClick={handleBrowseMoreAddOns}
								>
									{t('account.addOns.modal.browseMoreAddOns', 'Browse more add-ons')}
								</Button>
							</Box>
						</Box>
					</Box>
				</div>
			</Fade>
		</Modal>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	modal: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		maxHeight: '100%',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(8, 4),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 4, 0, 4),
		},
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 2,
		background: theme.palette.background.paper,
		borderRadius: 8,
		position: 'relative',
		'&:focus': { outline: 'none' },
		overflow: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: '100%',
		},
	},
	icon: {
		height: '124px',
		width: '124px',
		marginBottom: theme.spacing(5),
		borderRadius: '50%',
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '70%',
		position: 'relative',
		flex: 1,
		zIndex: 1,
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4),
		},
	},
	button: {
		width: '303px',
		fontWeight: 450,
	},
}));

export default InstalledAddOnModal;
