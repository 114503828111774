import React from 'react';

import { Box, Link, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { RiExternalLinkLine } from 'react-icons/ri';

interface Props {
	href: string;
	sx?: SxProps<Theme>;
}

const ExternalLink: React.FC<React.PropsWithChildren<Props>> = (props) => {
	return (
		<Link sx={props.sx} rel="noopener noreferrer" target="_blank" href={props.href}>
			{props.children}
			<Box sx={{ ml: '4px', display: 'inline' }}>
				<RiExternalLinkLine size={16} />
			</Box>
		</Link>
	);
};

export default ExternalLink;
