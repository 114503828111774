import React from 'react';

import { useHasFeature } from 'hooks/useHasFeature';

import { FailedChecks, FeatureProps } from './types';

export interface HasFeatureProps extends FeatureProps {
	fallback?: JSX.Element | ((failedChecks: FailedChecks) => JSX.Element | null) | null;
	render?: (enabled: boolean) => JSX.Element | null;
}

const HasFeature: React.FC<React.PropsWithChildren<HasFeatureProps>> = ({
	requiredFeature,
	filteringType = 'ALL',
	scope = 'both',
	fallback = null,
	render = null,
	children,
}) => {
	const { hasFeatureWithInfo } = useHasFeature();

	const { result, failedChecks } = hasFeatureWithInfo(requiredFeature, {
		filteringType,
		scope,
	});

	const renderFallback = (failedChecks: { user: boolean; shop: boolean }) => {
		if (typeof fallback === 'function') {
			return fallback(failedChecks);
		}
		return fallback;
	};

	return !!render ? render(result) : result ? <>{children}</> : renderFallback(failedChecks);
};

export default HasFeature;
