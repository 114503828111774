import { api } from 'common/frontend/api';

export const hideEarnCreditsBanner = (userId: string | null, shopId: string | undefined) => {
	if (!shopId || !userId) return;

	const hideBannerPath = `userPreferences.merchants.${shopId}.hideEarnCreditsBanner` as `userPreferences.merchants.${123}.hideEarnCreditsBanner`;

	return api()
		.users.doc(userId)
		.update({
			[hideBannerPath]: true,
		});
};

export interface EarnCreditsContent {
	title: string;
	method: string;
	credits: number;
	list: string[];
	cta: {
		label: string;
		src: string;
	};
}
