import moment from 'moment-timezone';

import { api } from 'common/frontend/api';
import { OrderProduct } from 'common/types';
import { sortBySet } from 'common/utils/productUtils';

export let removeOrderProductsListener: () => void = () => undefined;
export let removeAllOutgoingProductsListener: () => void = () => undefined;
export let removeExpiredIncomingListener: () => void = () => undefined;
export let removeExpiringIncomingListener: () => void = () => undefined;

export const addOrderProductsListener = (
	rentalId: string,
	shopId: string,
	callback: (products: OrderProduct[]) => void,
) => {
	const rentalProductsRef = api()
		.orderProducts.get.where('rentalId', '==', rentalId)
		.where('shopId', '==', shopId)
		.orderBy('productApiId');
	removeOrderProductsListener = rentalProductsRef.onSnapshot(async (orderProducts) => {
		callback(orderProducts.sort(sortBySet));
	});
};

export const getOrderProducts = async (
	rentalId: string,
	shopId: string,
): Promise<OrderProduct[]> => {
	const rentalProductsRef = api()
		.orderProducts.get.where('rentalId', '==', rentalId)
		.where('shopId', '==', shopId)
		.orderBy('productApiId');
	const orderProducts = await rentalProductsRef.get();
	return orderProducts.sort(sortBySet);
};

export const allOutgoingProductsListener = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
	callback: (products: OrderProduct[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const start = moment(startDate).startOf('day').toISOString();
	const end = moment(endDate).endOf('day').toISOString();
	const rentalProductsRef = api()
		.orderProducts.get.where('shopId', '==', shopId)
		.where('startDate', '>=', start)
		.where('startDate', '<=', end)
		.orderBy('startDate', 'asc');
	removeAllOutgoingProductsListener = rentalProductsRef.onSnapshot(async (orderProducts) => {
		const validOrderProducts = orderProducts.filter((product) => !product.cancelled);
		callback(validOrderProducts);
	}, errorCallback || undefined);
};

export const expiredIncomingListener = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
	callback: (products: OrderProduct[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const start = moment(startDate).startOf('day').toISOString();
	const end = moment(endDate).endOf('day').toISOString();
	const rentalProductsEndDateReturnedRef = api()
		.orderProducts.get.where('shopId', '==', shopId)
		.where('endDateReturned', '>=', start)
		.where('endDateReturned', '<=', end)
		.orderBy('endDateReturned', 'asc');
	removeExpiredIncomingListener = rentalProductsEndDateReturnedRef.onSnapshot(
		async (orderProducts) => {
			callback(orderProducts);
		},
		errorCallback || undefined,
	);
};

export const expiringIncomingListener = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
	callback: (products: OrderProduct[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const start = moment(startDate).startOf('day').toISOString();
	const end = moment(endDate).endOf('day').toISOString();
	const rentalProductsEndDateReturnedRef = api()
		.orderProducts.get.where('shopId', '==', shopId)
		.where('endDate', '>=', start)
		.where('endDate', '<=', end)
		.orderBy('endDate', 'asc');
	removeExpiringIncomingListener = rentalProductsEndDateReturnedRef.onSnapshot(
		async (orderProducts) => {
			const validOrderProducts = orderProducts.filter((product) => !product.cancelled);
			callback(validOrderProducts);
		},
		errorCallback || undefined,
	);
};

export const getProductsStartedWithinSpecificTimePeriod = async (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
) => {
	const start = moment(startDate).startOf('day').toISOString();
	const end = moment(endDate).endOf('day').toISOString();
	const orderProducts = await api()
		.orderProducts.get.where('shopId', '==', shopId)
		.orderBy('startDate', 'asc')
		.startAt(start)
		.endAt(end)
		.get();
	return orderProducts;
};

export const getProductsEndedWithinSpecificTimePeriod = async (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
) => {
	const start = moment(startDate).startOf('day').toISOString();
	const end = moment(endDate).endOf('day').toISOString();
	const _productsWithEndDate = await api()
		.orderProducts.get.where('shopId', '==', shopId)
		.orderBy('endDate', 'asc')
		.startAt(start)
		.endAt(end)
		.get();
	const productsWithEndDateReturned = await api()
		.orderProducts.get.where('shopId', '==', shopId)
		.orderBy('endDateReturned', 'asc')
		.startAt(start)
		.endAt(end)
		.get();
	const productsWithEndDate = _productsWithEndDate.filter((p) => !p.endDateReturned);
	const products = productsWithEndDate.concat(productsWithEndDateReturned);
	const returnProducts = products.filter((product) =>
		products.findIndex((p) => p.id === product.id),
	);
	return returnProducts;
};
