import { TFunction } from 'react-i18next';

import {
	CAPABILITY_QUESTION_NAMES,
	CHANNELS_OPTION_NAMES,
	CapabilityOptionName,
	CapabilityQuestionName,
	CustomerPersona,
	CustomerPersonas,
	FULFILLMENT_OPTION_NAMES,
	IndustrySegment,
	IndustrySegments,
	OFFERING_OPTION_NAMES,
} from 'common/services/analytics/tractionAnalytics';
import { EnumValues } from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import {
	getFromStorage,
	getQueryParam,
	removeFromStorage,
	saveToStorage,
} from 'common/utils/frontUtils';
import { stringEnum } from 'common/utils/objects';

const IDEV_ID_QUERY_PARAM = 'idev_id';
const AFFILIATE_LOCAL_STORAGE_KEY = 'affiliate';

export const EXTERNAL_AUTH_PARTNERS = ['wix'] as const;

export type AuthPartner = typeof EXTERNAL_AUTH_PARTNERS[number];

export type AuthProvider = 'email_password' | 'google' | 'facebook';

export const getAffiliateId = (): string | null => {
	const affiliateIdQueryParam = getQueryParam(IDEV_ID_QUERY_PARAM);
	if (!!affiliateIdQueryParam) {
		var affiliateObject = {
			id: affiliateIdQueryParam,
			timestamp: new Date().getTime(),
		};
		saveToStorage('localStorage', AFFILIATE_LOCAL_STORAGE_KEY, JSON.stringify(affiliateObject));
		return affiliateIdQueryParam;
	}

	const affiliateLocalStorageString = getFromStorage('localStorage', AFFILIATE_LOCAL_STORAGE_KEY);
	const affiliateLocalStorageObj = !!affiliateLocalStorageString
		? JSON.parse(affiliateLocalStorageString)
		: null;
	const { id, timestamp } = (affiliateLocalStorageObj ?? {}) as { id?: string; timestamp?: number };
	if (!id || !timestamp) return null;

	const timeNow = new Date().getTime();
	const dayAsMilliseconds = 60 * 60 * 24 * 1000;
	const timeSinceAffiliateIdAddedInDays = (timeNow - timestamp) / dayAsMilliseconds;
	const AFFILIATE_ID_EXPIRES_IN_DAYS = 90;
	if (timeSinceAffiliateIdAddedInDays < AFFILIATE_ID_EXPIRES_IN_DAYS) {
		return id;
	} else {
		removeFromStorage('localStorage', AFFILIATE_LOCAL_STORAGE_KEY);
		return null;
	}
};

export const GET_STARTED_STEP_NAMES = stringEnum(['SETUP', 'CAPABILITIES', 'INDUSTRY']);

export type GetStartedStepName = EnumValues<typeof GET_STARTED_STEP_NAMES>;

export type GetStartedStep = {
	id: GetStartedStepName;
	title: string;
	description: string;
};

export const getStartedSteps = (
	t: TFunction,
	customerPersona?: CustomerPersona,
): GetStartedStep[] => {
	const isDiscovering = customerPersona === CustomerPersonas.DISCOVERING;
	return [
		{
			id: GET_STARTED_STEP_NAMES.SETUP,
			title: t('getStarted.setup.title', "Let's get you started"),
			description: `${t(
				'getStarted.setup.description',
				"We'll help you explore and get set up based on your business needs",
			)}.`,
		},
		{
			id: GET_STARTED_STEP_NAMES.CAPABILITIES,
			title: isDiscovering
				? `${t(
						'getStarted.capabilities.titleDiscovery',
						'What kind of capabilities are you looking for',
				  )}?`
				: `${t('getStarted.capabilities.title', 'What capabilities are you looking for')}?`,
			description: `${t(
				'getStarted.capabilities.description',
				'Pick as many as you like. You can always change or add more later',
			)}.`,
		},

		{
			id: GET_STARTED_STEP_NAMES.INDUSTRY,
			title: t('getStarted.industry.title2', 'What products do you have?'),
			description: t(
				'getStarted.industry.description2',
				'Choose one main category you have products in.',
			),
		},
	];
};

export type CapabilityOption = {
	id: CapabilityOptionName;
	title: string;
	description?: string;
};

export type CapabilityQuestion = {
	title: string;
	options: CapabilityOption[];
	id: CapabilityQuestionName;
};

export const getCapabilityQuestions = (
	t: TFunction,
	customerPersona?: CustomerPersona,
): CapabilityQuestion[] => {
	const isDiscovering = customerPersona === CustomerPersonas.DISCOVERING;
	return [
		{
			id: CAPABILITY_QUESTION_NAMES.OFFERING,
			title: isDiscovering
				? `${t('getStarted.capabilities.offering.titleDiscovery', 'What do you want to offer')}?`
				: `${t('getStarted.capabilities.offering.title', 'What do you offer')}?`,
			options: [
				{
					id: OFFERING_OPTION_NAMES.RENTALS,
					title: t('getStarted.capabilities.offering.rentals.title', 'Rentals'),
					description: t(
						'getStarted.capabilities.offering.bookings.description',
						'Sell a one-time access for a specific duration',
					),
				},
				{
					id: OFFERING_OPTION_NAMES.SALES,
					title: t('getStarted.capabilities.offering.sales.title2', 'Sales & Resales'),
					description: t(
						'getStarted.capabilities.offering.sales.description',
						'Sell products by change of ownership',
					),
				},
				{
					id: OFFERING_OPTION_NAMES.SUBSCRIPTIONS,
					title: t('getStarted.capabilities.offering.subscriptions.title', 'Subscriptions'),
					description: t(
						'getStarted.capabilities.offering.subscriptions.description',
						'Sell continuous access to a product for a recurring fee',
					),
				},
				{
					id: OFFERING_OPTION_NAMES.SERVICE_BOOKINGS,
					title: t('getStarted.capabilities.offering.serviceBookings.title', 'Service bookings'),
					description: t(
						'getStarted.capabilities.offering.serviceBookings.description',
						'Sell a one-time access to a service',
					),
				},
			],
		},
		{
			id: CAPABILITY_QUESTION_NAMES.CHANNELS,
			title: isDiscovering
				? `${t('getStarted.capabilities.channels.titleDiscovery', 'Where do you want to sell')}?`
				: `${t('getStarted.capabilities.channels.title', 'Where do you sell')}?`,
			options: [
				{
					id: CHANNELS_OPTION_NAMES.ONLINE_STORE,
					title: t('getStarted.capabilities.channels.onlineStore.title', 'Online store'),
					description: t(
						'getStarted.capabilities.channels.onlineStore.description',
						'Sell and showcase your offering online',
					),
				},
				{
					id: CHANNELS_OPTION_NAMES.EMAIL_OR_PHONE,
					title: t('getStarted.capabilities.channels.emailOrPhone.title', 'Email or phone'),
					description: t(
						'getStarted.capabilities.channels.emailOrPhone.description',
						'Receive and confirm email and phone orders',
					),
				},
				{
					id: CHANNELS_OPTION_NAMES.IN_PERSON,
					title: t('getStarted.capabilities.channels.inPerson.title', 'In person'),
					description: t(
						'getStarted.capabilities.channels.inPerson.description',
						'Sell at stores or other physical locations',
					),
				},
				{
					id: CHANNELS_OPTION_NAMES.SELF_SERVICE,
					title: t('getStarted.capabilities.channels.selfServe.title2', 'Self-serve kiosks'),
					description: t(
						'getStarted.capabilities.channels.selfServe.description',
						'Receive contactless self-serve orders at your location',
					),
				},
			],
		},
		{
			id: CAPABILITY_QUESTION_NAMES.FULFILLMENT,
			title: isDiscovering
				? `${t(
						'getStarted.capabilities.fulfillment.titleDiscovery',
						'How do you want to handle orders',
				  )}?`
				: `${t('getStarted.capabilities.fulfillment.title', 'How do you handle orders')}?`,
			options: [
				{
					id: FULFILLMENT_OPTION_NAMES.LOCAL_DELIVERY,
					title: t('getStarted.capabilities.fulfillment.localDelivery.title', 'Local delivery'),
					description: t(
						'getStarted.capabilities.fulfillment.localDelivery.description',
						'Deliver orders directly to customers in the area',
					),
				},
				{
					id: FULFILLMENT_OPTION_NAMES.POSTAL_SHIPPING,
					title: t('getStarted.capabilities.fulfillment.shipping.title', 'Postal shipping'),
					description: t(
						'getStarted.capabilities.fulfillment.shipping.description',
						'Send orders to your customers by post',
					),
				},
				{
					id: FULFILLMENT_OPTION_NAMES.PICKUP_POINTS,
					title: t('getStarted.capabilities.fulfillment.pickupPoints.title', 'Pickup points'),
					description: t(
						'getStarted.capabilities.fulfillment.pickupPoints.description',
						'Customers have their orders brought to pre-defined pickup locations',
					),
				},
				{
					id: FULFILLMENT_OPTION_NAMES.IN_STORE,
					title: t('getStarted.capabilities.fulfillment.inStore.title', 'In-store'),
					description: t(
						'getStarted.capabilities.fulfillment.inStore.description',
						'Customers receive their orders in-person at your location',
					),
				},
			],
		},
	];
};

export const getNameFromSegmentType = (type: IndustrySegment, t: TFunction) => {
	switch (type) {
		case IndustrySegments.ACTIVITIES_AREAS:
			return t('register.industrySegments.ActivitiesArenas', 'Activities & Areas');
		case IndustrySegments.CLOTHING_ACCESSORIES:
			return t('register.industrySegments.ClothingAccessories', 'Clothing & Accessories');
		case IndustrySegments.EVENTS_LEISURE:
			return t('register.industrySegments.EventsLeisure', 'Events & Leisure');
		case IndustrySegments.OTHER:
			return t('register.industrySegments.Other', 'Other');
		case IndustrySegments.SPORTS:
			return t('register.industrySegments.Sports', 'Sports');
		case IndustrySegments.TOOLS_APPLIANCES:
			return t('register.industrySegments.ToolsAppliances', 'Tools & Appliances');
		case IndustrySegments.VEHICLES:
			return t('register.industrySegments.Vehicles', 'Vehicles');
		default:
			return switchUnreachable(type);
	}
};
