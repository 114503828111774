import { Box, ButtonBase, Stack, styled } from '@mui/material';
import { useHasFeature } from 'hooks';
import moment from 'moment-timezone';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { RiArrowDownSLine, RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isFreePlan, isLegacyPlan } from 'common/modules/plans';
import { hasHigherOrEqualAccess } from 'common/modules/users/utils';
import * as UserActions from 'actions/UserActions';
import * as ViewActions from 'actions/ViewActions';
import EarnCreditsBanner from 'components/EarnCreditsBanner';
import {
	MODES,
	SIDEBAR_WIDTH,
	SIDEBAR_WIDTH_COLLAPSED,
	getModeTranslation,
} from 'components/Layout/constants';
import { useLayoutContext } from 'components/Layout/context';
import SwitchToNewPlansBanner from 'components/SwitchToNewPlansBanner';
import { useAnalytics } from 'hooks/useAnalytics';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import { hideEarnCreditsBanner } from 'services/earnCredits/utils';
import { useTranslation } from 'services/localization';
import { Routes, isRouteActive, push } from 'routing';

import SidebarItem from './SidebarItem';
import SidebarMenu from './SidebarMenu';
import { getResaleSidebarItems, getSidebarItems } from './utils';

const Sidebar = () => {
	const {
		isMobile,
		isSidebarExpanded,
		setSidebarExpanded,
		mode: selectedMode,
		setMode,
	} = useLayoutContext();
	const { t } = useTranslation();
	const { hasFeature } = useHasFeature();
	const dispatch = useDispatch();
	const location = useLocation();
	const { logAnalyticsEvent } = useAnalytics();
	const sidebarItems = getSidebarItems(t, hasFeature);
	const resellSidebarItems = getResaleSidebarItems();
	const shopInfo = useSelector(ShopSelectors.activeShopInfo);
	const shopPlan = useSelector(ShopSelectors.shopPlan)!;
	const locationsById = useSelector(ShopSelectors.allShopLocationsById);
	const userId = useSelector(UserSelectors.activeUserId);
	const userRoles = useSelector(UserSelectors.userRoles);
	const userLocations = useSelector(UserSelectors.userLocations);
	const activeLocationId = useSelector(UserSelectors.userActiveLocationId)!;
	const activeLocation = locationsById[activeLocationId];

	const userMerchantPreferences = useSelector(UserSelectors.userMerchantLevelPreferences);
	const isCreditsBannerHidden = userMerchantPreferences?.hideEarnCreditsBanner;
	const has7DaysPassedSinceSignUp = moment().diff(moment(shopInfo?.createdAt), 'days') > 7;
	const isManagerOrAbove = hasHigherOrEqualAccess(userRoles, ['manager']);

	const showEarnCreditsBanner =
		!isCreditsBannerHidden &&
		has7DaysPassedSinceSignUp &&
		!isFreePlan(shopPlan) &&
		isManagerOrAbove;
	const visibleSidebarItems = (selectedMode === MODES.resell
		? resellSidebarItems
		: sidebarItems
	).filter((item) => !item.hidden);
	const hasNewListings = useFeatureFlagEnabled('new-listing-views');

	const isExpanded = isSidebarExpanded || isMobile;

	const handleEarnCreditsBannerClick = () => {
		dispatch(ViewActions.toggleEarnCreditsModal(true));
		logAnalyticsEvent({ name: 'earn_credits_banner_click' });
	};

	const handleEarnCreditsBannerClose = () => {
		hideEarnCreditsBanner(userId, shopInfo?.id);
		logAnalyticsEvent({
			name: 'earn_credits_banner_close',
			params: {
				shopId: shopInfo?.id,
				userId: userId ?? undefined,
			},
		});
	};

	const modeMenuItems = [
		{
			label: getModeTranslation(MODES.rentals, t),
			selected: selectedMode === MODES.rentals,
			onClick: () => {
				setMode(MODES.rentals);
				push(Routes.HOME_VIEW);
			},
		},
		{
			label: getModeTranslation(MODES.resell, t),
			selected: selectedMode === MODES.resell,
			onClick: () => {
				setMode(MODES.resell);
				push(Routes.LISTINGS);
			},
		},
	];

	return (
		<SidebarWrapper sx={{ width: isExpanded ? SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED }}>
			{isMobile ? (
				hasNewListings ? (
					<SidebarMenu
						label={getModeTranslation(selectedMode, t)}
						icon={RiArrowDownSLine}
						items={modeMenuItems}
					/>
				) : (
					<SidebarMenu
						label={activeLocation.name}
						icon={RiArrowDownSLine}
						items={userLocations.map((location) => ({
							label: location.name,
							onClick: () => dispatch(UserActions.setActiveLocationId(location.id)),
							selected: location.id === activeLocationId,
						}))}
					/>
				)
			) : null}
			<Stack direction="column" alignItems="flex-start" flex={1}>
				{visibleSidebarItems.map((item) => {
					const isActive = isRouteActive(item.to, location.pathname);
					return (
						<SidebarItem key={item.label} item={item} isExpanded={isExpanded} isActive={isActive} />
					);
				})}
			</Stack>
			<Stack spacing={2}>
				{showEarnCreditsBanner && (
					<BannerWrapper sx={{ pb: isMobile ? 1 : undefined }}>
						<EarnCreditsBanner
							onClick={handleEarnCreditsBannerClick}
							onClose={handleEarnCreditsBannerClose}
							isExpanded={isExpanded}
						/>
					</BannerWrapper>
				)}
				{isLegacyPlan(shopPlan) && (
					<BannerWrapper sx={{ pb: isMobile ? 1 : undefined }}>
						<SwitchToNewPlansBanner
							onClick={() => dispatch(ViewActions.toggleSwitchToNewPlansModal(true))}
							isExpanded={isExpanded}
						/>
					</BannerWrapper>
				)}
			</Stack>

			{!isMobile && (
				<Stack direction="row" justifyContent="flex-end">
					<StyledExpandButton onClick={() => setSidebarExpanded((prev) => !prev)}>
						{isSidebarExpanded ? <RiArrowLeftSLine /> : <RiArrowRightSLine />}
					</StyledExpandButton>
				</Stack>
			)}
		</SidebarWrapper>
	);
};

const StyledExpandButton = styled(ButtonBase)(({ theme }) => ({
	height: 40,
	width: 40,
	backgroundColor: theme.palette.grey[100],
	borderRadius: 4,
	margin: theme.spacing(1),
}));

const SidebarWrapper = styled(Box)(({ theme }) => ({
	background: theme.palette.background.paper,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
}));

const BannerWrapper = styled(Stack)(({ theme }) => ({
	width: '100%',
	paddingLeft: theme.spacing(1),
	paddingRight: theme.spacing(1),
}));

export default Sidebar;
