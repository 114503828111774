import React from 'react';

import { Box, Button, Typography, linearProgressClasses, useTheme } from '@mui/material';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';

import ProgressWithLabel from 'components/ProgressWithLabel';
import { useTranslation } from 'services/localization';
import { OnboardingItem } from 'services/onboarding';

export interface Props {
	showOnboarding: boolean;
	toggleShowOnboarding: (showOnboarding: boolean) => void;
	onboardingItems: OnboardingItem[];
}
const OnboardingSectionHeader = (props: Props) => {
	const { showOnboarding, toggleShowOnboarding, onboardingItems } = props;
	const { t } = useTranslation();
	const theme = useTheme();
	const allItemsCount = onboardingItems.length;
	const completedItemsCount = onboardingItems.filter((item) => item.done).length;

	const completedItemsPercentage = (completedItemsCount / allItemsCount) * 100;

	const allItemsCompleted = completedItemsCount === allItemsCount;
	return allItemsCompleted ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				margin: (theme) => theme.spacing(4, 0),
			}}
		>
			<Typography variant="h6">{t('homeView.yourChecklist', 'Your checklist')}</Typography>
			<Button
				variant="text"
				sx={{
					p: 0,
					justifyContent: 'flex-end',
					'&:hover': {
						backgroundColor: 'transparent',
					},
					color: theme.palette.primary.light,
				}}
				disableRipple={true}
				onClick={() => toggleShowOnboarding(!showOnboarding)}
			>
				{showOnboarding ? (
					<>
						{t('homeView.onboarding.hide', 'Hide')}
						<RiSubtractLine color={theme.palette.primary.main} />
					</>
				) : (
					<>
						{t('homeView.onboarding.show', 'Show')}
						<RiAddLine color={theme.palette.primary.main} />
					</>
				)}
			</Button>
		</Box>
	) : (
		<Box mt={2} mb={5}>
			<Typography variant="h4">{`${t(
				'homeView.getStartedWithRentle',
				'Get started with Rentle',
			)}!`}</Typography>
			<Box mt={1} />
			<Typography variant="body1" color="secondary">
				{t('homeView.stepsToGetYouStarted', `We've gathered a few steps to get you started`)}
			</Typography>
			<Box mt={3} />
			<ProgressWithLabel
				spacing={3}
				value={completedItemsPercentage}
				sx={{
					height: '4px',
					borderRadius: 0,
					[`& .${linearProgressClasses.bar}`]: {
						backgroundColor: theme.palette.success.main,
						borderRadius: 0,
					},
					[`&.${linearProgressClasses.root}`]: {
						backgroundColor: theme.palette.action.hover,
					},
				}}
			/>
		</Box>
	);
};

export default OnboardingSectionHeader;
