import React, { Suspense } from 'react';

import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from 'views/404';
import Home from 'views/Home/Home';

import Spinner from 'common/components/Spinner';
import Layout from 'components/Layout';
import CustomersPromoPage from 'components/features/promos/CustomersPromoPage';
import { useHasFeature } from 'hooks/useHasFeature';
import * as UserSelectors from 'selectors/UserSelectors';
import { Routes } from 'routing';

const AddOns = React.lazy(() => import('views/AddOns'));
const Customers = React.lazy(() => import('views/Customers'));
const InventoryRouter = React.lazy(() => import('views/Inventory/index'));
const Plans = React.lazy(() => import('views/Plans'));
const ProductRouter = React.lazy(() => import('views/Product/index'));
const Products = React.lazy(() => import('views/Products'));
const CategoryView = React.lazy(() => import('views/Products/CategoryView'));
const CreateCategory = React.lazy(() => import('views/Products/CreateCategory'));
const DiscountRouter = React.lazy(() => import('views/Products/Discount/index'));
const RentalView = React.lazy(() => import('views/Rental/index'));
const RentalsRouter = React.lazy(() => import('views/RentalsView'));
const ReturnView = React.lazy(() => import('views/RentalsView/ReturnView'));
const Store = React.lazy(() => import('views/Store'));
const Account = React.lazy(() => import('views/Account'));
const Skidata = React.lazy(() => import('views/Skidata'));
const Dashboard = React.lazy(() => import('views/Dashboard'));
const DeliveryRouter = React.lazy(() => import('views/Delivery'));
const NewRentalRouter = React.lazy(() => import('views/NewRental'));

const HomeRouter = () => {
	const { hasFeature } = useHasFeature();
	const activeLocationId = useSelector(UserSelectors.userActiveLocationId);

	return (
		/*
		Layout uses a useLayoutEffect hook to set the height of fixed elements at the top.
		Due to limitations of Suspense together with lazy-loading, the useLayoutEffect hook is prematurely called.
		To avoid this, we wrap the  Suspense component in the Layout component instead of having it the other way around.
		*/
		<Layout header={{ variant: 'sidebar' }}>
			<Suspense fallback={<Spinner />}>
				<React.Fragment key={activeLocationId}>
					<Switch>
						<Route exact path={Routes.HOME_VIEW} component={Home} />

						{hasFeature('NEW_RENTAL', { scope: 'user' }) && (
							<Route key={Routes.NEW_RENTAL} path={Routes.NEW_RENTAL} component={NewRentalRouter} />
						)}

						<Route path={Routes.BOOKINGS} component={RentalsRouter} />
						<Route path={Routes.BOOKING_PAGE} component={RentalView} />
						{hasFeature('MASS_RETURN') && <Route path={Routes.RETURN} component={ReturnView} />}

						<Route exact path={Routes.SKIDATA} component={Skidata} />

						{hasFeature('PRODUCTS') && [
							<Route
								key={Routes.CATALOG_PRODUCT}
								path={Routes.CATALOG_PRODUCT}
								component={ProductRouter}
							/>,
							<Route key={Routes.CATALOG} exact path={Routes.CATALOG} component={Products} />,
							<Route
								key={Routes.CATALOG_CREATE_CATEGORY}
								exact
								path={Routes.CATALOG_CREATE_CATEGORY}
								component={CreateCategory}
							/>,
							<Route
								key={Routes.CATALOG_CATEGORY}
								path={Routes.CATALOG_CATEGORY}
								component={CategoryView}
							/>,
							<Route
								key={Routes.CATALOG_CREATE_DISCOUNT}
								path={Routes.CATALOG_CREATE_DISCOUNT}
								component={DiscountRouter}
							/>,
							<Route
								key={Routes.CATALOG_DISCOUNT}
								path={Routes.CATALOG_DISCOUNT}
								component={DiscountRouter}
							/>,
							<Route key={Routes.CATALOG_TAB} path={Routes.CATALOG_TAB} component={Products} />,
						]}

						{hasFeature('INVENTORY') && [
							<Route key={Routes.INVENTORY} path={Routes.INVENTORY} component={InventoryRouter} />,
						]}
						{hasFeature('CUSTOMER_DATA') ? (
							<Route path={Routes.CUSTOMERS} component={Customers} />
						) : (
							<Route path={Routes.CUSTOMERS} component={CustomersPromoPage} />
						)}

						{hasFeature('DELIVERY_SERVICE', { scope: 'user' }) && (
							<Route path={Routes.DELIVERY} component={DeliveryRouter} />
						)}

						{hasFeature('DASHBOARD') && (
							<Route exact path={Routes.DASHBOARD} component={Dashboard} />
						)}

						{hasFeature('STORE_SETTINGS') && <Route path={Routes.STORE} component={Store} />}

						<Route path={Routes.ADD_ONS_STORE} component={AddOns} />

						<Route path={Routes.ACCOUNT} component={Account} />
						{hasFeature('PLAN_BILLING') && <Route path={Routes.PLANS} component={Plans} />}

						<Route path={Routes.NOT_FOUND} component={NotFound} />

						<Redirect exact path={Routes.ROOT} to={Routes.HOME_VIEW} />
						{/** Redirects for old routes */}
						<Redirect path={Routes.BLOCKS} to={Routes.ADD_ONS_STORE} />
						<Redirect path="/marketing-list" to={Routes.ACCOUNT_MARKETING} />
						<Redirect path="/products" to={Routes.CATALOG} />
						<Redirect path="/product" to={Routes.CatalogTab('products')} />
						<Redirect path="/discount" to={Routes.CatalogTab('discounts')} />
						<Redirect path="/catalogue/products" to={Routes.CatalogTab('products')} />
						<Redirect path="/catalogue/categories" to={Routes.CatalogTab('categories')} />
						<Redirect path="/catalogue/discounts" to={Routes.CatalogTab('discounts')} />
						<Redirect path="/catalogue" to={Routes.CATALOG} />
						<Redirect path="/rental" to={Routes.BOOKINGS} />
						<Redirect path="/rentals" to={Routes.BOOKINGS} />
						{/** This is better as a default than redirecting to /404, as it preserves the url you tried to visit */}
						<Route component={NotFound} />
					</Switch>
				</React.Fragment>
			</Suspense>
		</Layout>
	);
};

export default HomeRouter;
