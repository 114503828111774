import { getProductIdsInPackage } from 'common/modules/products/utils';
import { ByProductId, SetIncludesItem, ShopPublicInfo } from 'common/types';
import { getTypedKeys } from 'common/utils/objects';
import { getPricingTableForStockProduct } from 'common/utils/pricing';
import { getItemsWithBasePrice, getProductImageSrcs } from 'common/utils/productUtils';

import { BookingBuffer } from '../_atoms/BookingBuffer';
import { CatalogVisibilityChannelApi, Channel } from '../_atoms/Channel';
import { PricingItem, PricingItemDb } from './PricingItem';
import { StartTimes } from './StartTimes';
import { Variant, VariantProperty } from './Variant';
import { ProductApi, ProductDb } from './types';

export const toApi = (
	db: ProductDb,
	args: { pricingTemplates: ShopPublicInfo['pricingTables'] },
): ProductApi => {
	const getChannelLimitation = (
		data: ProductDb['hiddenFromChannels'],
	): ProductApi['limitations']['channels'] => {
		if (Object.values(data ?? {}).every((v) => v === false)) return undefined;
		return getTypedKeys(data ?? {})
			.filter(([channel]) => data?.[channel] === false)
			.map((c) => Channel.toApi(c));
	};

	const getStoreIdLimitation = (
		data: ProductDb['visibleInLocations'],
	): ProductApi['limitations']['storeIds'] => {
		if (!data || data.includes('ALL')) {
			return undefined;
		}
		return data;
	};

	const getProductRentalPricingTable = (
		data: ProductDb,
		pricingTemplates: ShopPublicInfo['pricingTables'],
	): PricingItemDb[] | undefined => {
		const basePrice = data.rentals?.basePrice;
		const pricingTable = getPricingTableForStockProduct(data, pricingTemplates ?? {});
		const itemsWithBasePrice = getItemsWithBasePrice(pricingTable, basePrice ?? 0);
		return !!itemsWithBasePrice.length ? itemsWithBasePrice : undefined;
	};

	const rentalPricing = getProductRentalPricingTable(db, args.pricingTemplates);

	return {
		id: db.id,
		createdAt: db.created,
		categoryIds: db.categoryIds ?? [],
		description: db.description,
		images: getProductImageSrcs(db) ?? [],
		name: db.name,
		isPackage: db.set,
		packageProductIds: db.set ? getProductIdsInPackage(db) : undefined,
		taxPercentage: db.vatPercent,
		recommendedProductIds: db.additionalProductIds ?? [],
		limitations: {
			visibleInListing: !db.additionalProduct,
			channels: getChannelLimitation(db.hiddenFromChannels),
			storeIds: getStoreIdLimitation(db.visibleInLocations),
			maintenanceTimeMinutes: db.maintenanceTimeMinutes ?? undefined,
			onlineAvailabilityPercentage:
				db.onlineLimit == null ? undefined : Math.round(db.onlineLimit * 100),
			startTimes: !!db.startTimeSlots ? StartTimes.toApi(db.startTimeSlots) : undefined,
			bookingBuffer: !!db.bookingBuffer ? BookingBuffer.toApi(db.bookingBuffer) : undefined,
		},
		variantProperties: db.variants.properties.map((v) => VariantProperty.toApi(v)),
		variants: db.variants.options.map((v) =>
			Variant.toApi(v, { variantProperties: db.variants.properties }),
		),
		order: db.orderIndex ?? 0,
		rentals: {
			enabled: db.rentals?.enabled ?? false,
			basePrice: db.rentals?.basePrice ?? 0,
			pricing: rentalPricing
				? rentalPricing.map((i) => PricingItem.toApi(i, db.rentals?.basePrice ?? 0))
				: undefined,
			deposit: db.terms?.deposit !== '' ? db.terms?.deposit ?? undefined : undefined,
		},
		sales: {
			enabled: db.sales?.enabled ?? false,
			basePrice: db.sales?.basePrice ?? 0,
		},
		// Wait before subscriptions release to add subscriptions data to API
		// subscriptions: {
		// 	enabled: db.subscriptions?.enabled ?? false,
		// 	options: db.subscriptions?.options ?? [],
		// },
	};
};

export const toDb = (merchantId: string, api: ProductApi): ProductDb => {
	const getHiddenFromChannel = (
		data: ProductApi['limitations']['channels'],
	): ProductDb['hiddenFromChannels'] => {
		const togglableChannels = ['ADMIN', 'STORE', 'ONLINE'];
		if (data === undefined) return undefined;
		if (data === null)
			return togglableChannels.reduce((acc, channel) => ({ ...acc, [channel]: false }), {});
		return togglableChannels.reduce((acc, [channel]) => {
			return { ...acc, [channel]: !data.includes(channel as CatalogVisibilityChannelApi) };
		}, {});
	};

	const getVisibleStoreIds = (
		data: ProductApi['limitations']['storeIds'],
	): ProductDb['visibleInLocations'] => {
		if (!data) return ['ALL'];
		return data;
	};

	const getSetIncludes = (data: ProductApi['packageProductIds']): ProductDb['setIncludes'] => {
		if (!data) return {};
		return data.reduce(
			(acc, id, index) => ({ ...acc, [id]: { units: 1, index } }),
			{} as ByProductId<SetIncludesItem>,
		);
	};

	return {
		id: api.id,
		created: api.createdAt,
		highlightProduct: false,
		shopId: merchantId,
		categoryIds: api.categoryIds ?? [],
		description: api.description ?? undefined,
		images: api.images,
		name: api.name,
		set: api.isPackage,
		setIncludes: getSetIncludes(api.packageProductIds),
		vatPercent: api.taxPercentage,
		additionalProductIds: api.recommendedProductIds ?? [],
		additionalProduct: api.limitations.visibleInListing,
		visibleInLocations: getVisibleStoreIds(api.limitations.storeIds),
		hiddenFromChannels: getHiddenFromChannel(api.limitations.channels),
		maintenanceTimeMinutes: api.limitations.maintenanceTimeMinutes,
		onlineLimit:
			api.limitations.onlineAvailabilityPercentage != null
				? api.limitations.onlineAvailabilityPercentage / 100
				: null,
		startTimeSlots: api.limitations.startTimes
			? StartTimes.toDb(api.limitations.startTimes)
			: undefined,
		bookingBuffer: api.limitations.bookingBuffer
			? BookingBuffer.toDb(api.limitations.bookingBuffer)
			: undefined,
		variants: {
			properties: api.variantProperties,
			options: api.variants.map((v) => Variant.toDb(v)),
		},
		orderIndex: api.order,
		pricing: api.rentals.pricing?.map((i) => PricingItem.toDb(i, api.rentals.basePrice)),
		rentals: {
			enabled: api.rentals?.enabled ?? false,
			basePrice: api.rentals?.basePrice ?? 0,
		},
		sales: {
			enabled: api.sales?.enabled ?? false,
			basePrice: api.sales?.basePrice ?? 0,
		},
		subscriptions: null,
		// subscriptions: {
		// 	enabled: api.subscriptions?.enabled ?? false,
		// 	options: api.subscriptions?.options ?? [],
		// },
		terms: {
			deposit: api.rentals.deposit ?? undefined,
		},
	};
};
