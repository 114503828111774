import { MockAcsDbDoc } from 'common/acs';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

import { buildQuery, createBasicDbOperations } from '../utils';

export const getMockAcsDbQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.mockAcsDb;
	const dataUpdater = undefined;
	const query = buildQuery<MockAcsDbDoc>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<MockAcsDbDoc>(collectionPath, dbRefs, dataUpdater),
	};
};
