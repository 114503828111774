import { Carrier } from 'common/types';
import { buildQuery, createBasicDbOperations, createBasicCollectionOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getCarriersQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.carriers;
	const query = buildQuery<Carrier>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<Carrier>(collectionPath, dbRefs),
		...createBasicCollectionOperations<Carrier>(collectionPath, dbRefs),
		byShopId: (shopId: string) => query.where('shopId', '==', shopId),
	};
};
