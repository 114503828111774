import { faker } from '@faker-js/faker/locale/en';

import { getItemPricing } from 'common/MockFactory/mocks/ItemPricing.mock';
import {
	InventoryTransferItem,
	InventoryTransferStatuses,
} from 'common/modules/inventoryTransfers';

const generateInventoryTransferItem = (): InventoryTransferItem => {
	return {
		id: faker.datatype.uuid(),
		label: faker.commerce.product(),
		skuId: faker.datatype.uuid(),
		price: getItemPricing(),
		status: faker.helpers.arrayElement(Object.values(InventoryTransferStatuses)),
		condition: {
			score: faker.datatype.number({ min: 0, max: 100 }),
		},
		allocation: faker.helpers.arrayElement(['rental', 'sales']),
		inventoryStatus: faker.helpers.arrayElement(['IN_USE', 'OUT_OF_USE']),
		customIds: [],
	};
};

export const getInventoryTransferItem = (
	p?: Partial<InventoryTransferItem>,
): InventoryTransferItem => {
	return { ...generateInventoryTransferItem(), ...p };
};
