import { EnumValues, WithRequired } from 'common/types';
import { stringEnum } from 'common/utils/objects';

import { ProductPricingDefinition, PurchasedProductPricing } from '../common/types';
import { Subscription } from '../subscriptions/types';

export const FreePlans = stringEnum(['LITE', 'FREE', 'DEMO', 'INTERNAL']);
export const PaidPlans = stringEnum([
	'BASIC',
	'GROW',
	'ADVANCED',
	'DISCOVER',
	'BUILD',
	'ADVANCE',
	'ENTERPRISE',
]);
export const Plans = {
	...FreePlans,
	...PaidPlans,
} as const;

export type FreePlan = EnumValues<typeof FreePlans>;
export type PaidPlan = EnumValues<typeof PaidPlans>;
export type Plan = EnumValues<typeof Plans>;

export const PurchasedPlanCampaigns = stringEnum([
	'core_commission_to_advanced_with_grow_price',
	'core_commission_to_core_demo_inactive_account_disabled',
	'core_commission_to_enterprise',
	'core_fixed_to_enterprise',
	'old_enterprise_to_core_demo',
	'old_enterprise_to_core_demo_account_disabled',
	'core_demo_to_core_demo_disabled',
]);

export interface PlanDefinition {
	/**
	 * The unique id of the plan
	 */
	id: Plan;

	/**
	 * A human-readable name of the plan
	 */
	name: string;

	/**
	 * The pricing of this plan, or null if it is free
	 */
	pricing: ProductPricingDefinition | null;

	/**
	 * How many block slots does the plan include (by default)
	 */
	blockSlots: number;

	/**
	 * How many users does the plan include (by default)
	 */
	users: number;

	/**
	 * How many languages does the plan include (by default)
	 * (For now English is always included so +1 should be added to the limit)
	 */
	languages: number;

	/**
	 * How many inventory articles does the plan include (by default)
	 */
	articles: number;

	/**
	 * How many stores does the plan include (by default)
	 */
	stores: number;

	/**
	 * How many pickup locations does the plan include (by default)
	 */
	pickupLocations: number;

	/**
	 * How the billing for this plan works
	 *
	 * - None: the plan is not billed, or is billed in a separate system
	 * - Subscription per store: each store gets their own subscription, and receives a separate invoice
	 * - Price per additional store: only the main store has a subscription, and the merchant receives a single invoice. Additional stores are added to the invoice as a separate line item.
	 * - Fixed store count: only the main store has a subscription, and the merchant receives a single invoice. The merchant cannot add more stores.
	 *
	 */
	billing:
		| {
				type: 'none';
		  }
		| {
				type: 'subscription-per-store';
		  }
		| {
				type: 'price-per-additional-store';
				pricePerAdditionalStore: ProductPricingDefinition;
		  }
		| {
				type: 'fixed-store-count';
		  };

	/**
	 * Whether this is one of the legacy plans, which will be removed in the future
	 */
	legacy?: boolean;

	/**
	 * Whether this plan can be purchased by the merchant, or is only available via internal admin
	 */
	public: boolean;
}

export interface PurchasedPlan {
	/**
	 * The purchased plan
	 */
	plan: Plan;

	/**
	 * When this plan was purchased, or when the billing for the add-on should begin
	 */
	startDate: string;

	/**
	 * The price of this plan for this specific merchant and store
	 */
	pricing: PurchasedProductPricing;

	/**
	 * The price for adding additional stores, if applicable for the plan.
	 *
	 */
	additionalStorePricing: PurchasedProductPricing;

	/**
	 * An optional description, which will be shown in the corresponding invoice row instead of the default description if provided
	 */
	description?: string;

	/**
	 * An optional internal note, which is shown in internal admin
	 */
	internalNote?: string;

	/*
	 * The amount of available block slots included in Plan
	 */
	blockSlots: PurchasedPlanConfigurableValue;

	/**
	 * How many users does the plan include? If undefined, we use the plan default.
	 */
	users?: PurchasedPlanConfigurableValue;

	/**
	 * How many languages does the plan include? If undefined, we use the plan default.
	 */
	languages?: PurchasedPlanConfigurableValue;

	/**
	 * How many inventory articles does the plan include? If undefined, we use the plan default.
	 */
	articles?: PurchasedPlanConfigurableValue;

	/**
	 * How many stores does the plan include? If undefined, we use the plan default.
	 */
	stores?: PurchasedPlanConfigurableValue;

	/**
	 * Subscription details of the plan
	 */
	subscription?: Subscription;

	/**
	 * How many pickuploactions does the plan include? If undefined, we use the plan default.
	 */
	pickupLocations?: PurchasedPlanConfigurableValue;

	/**
	 * The campaign used to purchase this plan
	 */
	campaign?: string;
}

export type PurchasedPlanWithSubscription = WithRequired<PurchasedPlan, 'subscription'>;

export type PurchasedPlanConfigurableValue =
	| {
			/**
			 * Use the default value for the plan
			 */
			type: 'default';
	  }
	| {
			/**
			 * A custom value for this specific merchant
			 */
			type: 'custom';
			amount: number;
	  }
	| {
			/**
			 * No limit
			 */
			type: 'unlimited';
	  };
