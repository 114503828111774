import moment from 'moment-timezone';

import { OrderProductAvailability } from 'common/api/firestore';
import { Api } from 'common/db/api/paths';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import {
	AvailabilityByStartDay,
	getValidReservationItems,
} from 'common/modules/availabilities/startTimeCounts';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { PartialReservation, StartTimeCount } from 'common/types';
import { notNull } from 'common/utils/common';

import { dateIsInAvailabilityRange, fetchReservations, fetchSalesReservations } from '.';
import { fetchStartTimes } from './fetchStartTimes';
import { fetchRentedSkus, fetchSalesSkus } from './skusFetch';
import { AvailabilityDataSources, VariantAvailabilityObject } from './types';

export interface GetAvailabilityProps {
	totalQuantity: number | null;
	variantObject: VariantAvailabilityObject;
	startDate: string;
	endDate: string;
	startLocationId: string;
	ownReservationId?: string;
	ignoredOrderProductIds?: string[];
	dataSources?: AvailabilityDataSources;
	hasStartTimeLimit: boolean;
	productId: string;
	api: Api;
}

export interface AvailabilityRange {
	start: string;
	end: string;
	units: number | null;
}

export interface AvailabilityObject {
	startDate: string | null;
	endDate: string | null;
	units: number;
}

export const getAvailabilityRangesAndStartTimes = async (
	props: GetAvailabilityProps,
): Promise<{ availabilityRanges: AvailabilityRange[]; startTimeCounts: StartTimeCount }> => {
	const {
		totalQuantity,
		variantObject,
		startDate,
		endDate,
		ownReservationId,
		startLocationId,
		ignoredOrderProductIds,
		dataSources,
		hasStartTimeLimit,
		productId,
		api,
	} = props;
	try {
		const { skuId } = variantObject;
		const [reservations, orderProductAvailabilities, startTimes] = await Promise.all([
			!skuId
				? []
				: fetchReservations({
						skuId,
						startDate,
						endDate,
						ownReservationId,
						startLocationId,
						dataSources,
						api,
				  }),
			!skuId
				? []
				: fetchRentedSkus({
						skuId,
						startDate,
						endDate,
						startLocationId,
						ignoredOrderProductIds,
						dataSources,
						api,
				  }),
			!hasStartTimeLimit
				? []
				: fetchStartTimes({
						productId,
						startDate,
						endDate,
						startLocationId,
						ignoredOrderProductIds,
						dataSources,
						api,
				  }),
		]);
		return buildAvailabilityRanges({
			orderProductAvailabilities,
			startTimes,
			reservations,
			totalQuantity,
			startDate,
			endDate,
		});
	} catch (e) {
		errorHandler.report(e);
		return Promise.reject(`Failed to fetch hourly availability data.`);
	}
};

export const getSalesAvailabilityRanges = async (
	props: GetAvailabilityProps,
): Promise<{ availabilityRanges: AvailabilityRange[] }> => {
	const {
		totalQuantity,
		variantObject,
		startDate,
		endDate,
		ownReservationId,
		startLocationId,
		ignoredOrderProductIds,
		dataSources,
		api,
	} = props;
	try {
		const { skuId } = variantObject;
		const [reservations, orderProductAvailabilities] = await Promise.all([
			!skuId
				? []
				: fetchSalesReservations({
						skuId,
						startDate,
						endDate,
						ownReservationId,
						startLocationId,
						dataSources,
						api,
				  }),
			!skuId
				? []
				: fetchSalesSkus({
						skuId,
						startDate,
						endDate,
						startLocationId,
						ignoredOrderProductIds,
						dataSources,
						api,
				  }),
		]);

		return buildAvailabilityRanges({
			orderProductAvailabilities,
			startTimes: [],
			reservations,
			totalQuantity,
			startDate,
			endDate,
		});
	} catch (e) {
		errorHandler.report(e);
		return Promise.reject(`Failed to fetch hourly availability data.`);
	}
};

const buildAvailabilityRanges = (args: {
	orderProductAvailabilities: OrderProductAvailability[];
	startTimes: AvailabilityByStartDay[];
	reservations: PartialReservation[];
	totalQuantity: number | null;
	startDate: string;
	endDate: string;
}): {
	availabilityRanges: AvailabilityRange[];
	startTimeCounts: StartTimeCount;
} => {
	const {
		orderProductAvailabilities,
		startTimes,
		reservations,
		totalQuantity,
		startDate,
		endDate,
	} = args;

	const startTimesWithData = startTimes
		.map((startTime) => startTime.times)
		.reduce((tot, curr) => ({ ...tot, ...curr }), {});

	const startTimeCounts = Object.entries(startTimesWithData).reduce((tot, [time, values]) => {
		if (time < startDate || time > endDate) return tot;
		const orderCount = values.orderIds?.length ?? 0;
		const reservationCount = Object.keys(getValidReservationItems(values.reservations)).length;
		return {
			...tot,
			[time]: orderCount + reservationCount,
		};
	}, {} as { [time: string]: number });

	const initialAvailability = getInitialAvailabilityRange(totalQuantity, startDate, endDate);
	// null total quantity indicates (practically) "unlimited" stock. E.g. lift tickets or helmets
	if (totalQuantity === null) {
		return { availabilityRanges: [initialAvailability], startTimeCounts };
	}

	const reservationsAvailabilities: AvailabilityObject[] = reservations.map((r) => ({
		startDate: r.startDate ?? null,
		endDate: r.endDate ?? null,
		units: r.units,
	}));

	const productAvailabilities: AvailabilityObject[] = orderProductAvailabilities.map((p) => ({
		startDate: p.unavailable.from,
		endDate: p.unavailable.until,
		units: 1,
	}));

	const sortByLatestEndDate = (a: AvailabilityObject, b: AvailabilityObject) => {
		if (!a.endDate) return 1;
		if (!b.endDate) return -1;
		return a.endDate >= b.endDate ? -1 : 1;
	};

	const allAvailabilitiesDescending = [
		...reservationsAvailabilities,
		...productAvailabilities,
	].sort(sortByLatestEndDate);
	const totalAvailability = allAvailabilitiesDescending.reduce(mergeSortedAvailabilities, [
		initialAvailability,
	]);

	return { availabilityRanges: totalAvailability, startTimeCounts };
};

export const getInitialAvailabilityRange = (
	totalQuantity: number | null,
	startDate: string,
	endDate: string,
): AvailabilityRange => {
	return { start: startDate, end: endDate, units: totalQuantity };
};

// Internal function, not to be used elsewhere
const mergeSortedAvailabilities = (
	total: AvailabilityRange[],
	subtract: AvailabilityObject,
): AvailabilityRange[] => {
	const { startDate, endDate, units } = subtract;

	const mergedUnitsCalculationFunc = (a: number, b: number) => a - b;
	const start = startDate!;
	const _end = endDate!;
	const end = startDate === endDate ? moment(_end).add(1, 'millisecond').toISOString() : _end; //No-duration range fix (e.g. fixed products)
	/**
	 * Availabilities are checked from latest end date first, so we know that if the last checked
	 * range ends after the looped ranges end time, all the rest of the ranges will not overlap anymore
	 * with the currently looped range
	 * */
	let rangeCannotOverlap = false;
	const merged = total.flatMap((availabilityRange, i) => {
		if (rangeCannotOverlap) {
			return availabilityRange;
		}
		const availabilitycurrentEndsAfter = availabilityRange.end > end;
		if (availabilitycurrentEndsAfter) {
			rangeCannotOverlap = true;
		}
		return mergeAvailabilityRanges(
			availabilityRange,
			{ start, end, units },
			mergedUnitsCalculationFunc,
		);
	});
	return merged;
};

export const mergeAvailabilityRangeToRanges = (
	total: AvailabilityRange[],
	rangeToMerge: AvailabilityRange,
	mergedUnitsCalculationFunc: (a: number, b: number) => number,
) => {
	const { start, end: _end, units } = rangeToMerge;
	const end = start === _end ? moment(_end).add(1, 'millisecond').toISOString() : _end; //No-duration range fix (e.g. fixed products)
	return total.flatMap((availabilityRange) =>
		mergeAvailabilityRanges(
			availabilityRange,
			{ start, end, units: units ?? 0 },
			mergedUnitsCalculationFunc,
		),
	);
};

const mergeAvailabilityRanges = (
	currentRange: AvailabilityRange,
	rangeToMerge: AvailabilityRange,
	mergedUnitsCalculationFunc: (a: number, b: number) => number,
) => {
	const currentStart = currentRange.start;
	const currentEnd = currentRange.end;

	const toMergeStart = rangeToMerge.start;
	const toMergeEnd = rangeToMerge.end;

	if (
		currentRange.units != null &&
		doesDateRangeOverlap(toMergeStart, toMergeEnd, currentStart, currentEnd)
	) {
		const currentAvailability = currentRange.units;
		const newAvailability =
			rangeToMerge.units === null
				? null
				: mergedUnitsCalculationFunc(currentAvailability, rangeToMerge.units);

		const sameOrBeforeStart = toMergeStart <= currentStart;
		const sameOrAfterEnd = toMergeEnd >= currentEnd;

		if (sameOrBeforeStart && sameOrAfterEnd) {
			return {
				...currentRange,
				units: newAvailability,
			};
		}
		if (sameOrBeforeStart && !sameOrAfterEnd) {
			const range1: AvailabilityRange = {
				start: currentStart,
				end: toMergeEnd,
				units: newAvailability,
			};
			const range2: AvailabilityRange = {
				start: toMergeEnd,
				end: currentEnd,
				units: currentAvailability,
			};
			return [range1, range2];
		}
		if (!sameOrBeforeStart && sameOrAfterEnd) {
			const range1: AvailabilityRange = {
				start: currentStart,
				end: toMergeStart,
				units: currentAvailability,
			};
			const range2: AvailabilityRange = {
				start: toMergeStart,
				end: currentEnd,
				units: newAvailability,
			};
			return [range1, range2];
		}
		if (!sameOrBeforeStart && !sameOrAfterEnd) {
			const range1: AvailabilityRange = {
				start: currentStart,
				end: toMergeStart,
				units: currentAvailability,
			};
			const range2: AvailabilityRange = {
				start: toMergeStart,
				end: toMergeEnd,
				units: newAvailability,
			};
			const range3: AvailabilityRange = {
				start: toMergeEnd,
				end: currentEnd,
				units: currentAvailability,
			};
			return [range1, range2, range3];
		}
		return [];
	}
	return currentRange;
};

export const calculateAvailabilityForTimePeriod = (
	startDate: string,
	endDate: string,
	availabilityRanges: AvailabilityRange[],
	options?: {
		/**
		 * If no matching ranges for the start -> end date, return unlimited availability
		 */
		unlimitedIfNoMatch?: boolean;
	},
): number | null => {
	const validAvailabilityRanges = availabilityRanges.filter((range) => {
		const { start, end } = range;
		return doesDateRangeOverlap(startDate, endDate, start, end);
	});

	if (validAvailabilityRanges.length === 0 && !!options?.unlimitedIfNoMatch) return null;

	return getMinAvailabilityFromRange(validAvailabilityRanges);
};

export const calculateAvailabilityForDate = (
	date: string,
	availabilityRanges: AvailabilityRange[],
) => {
	const validAvailabilityRanges = availabilityRanges.filter((range) => {
		const { start, end } = range;
		return dateIsInAvailabilityRange(moment(date), moment(start), moment(end));
	});
	return getMinAvailabilityFromRange(validAvailabilityRanges);
};

export const getMinAvailabilityFromRange = (availabilityRanges: AvailabilityRange[]) => {
	if (!availabilityRanges.length) return 0;
	const nonNullAvailabilityRanges = availabilityRanges
		.map((availability) => availability.units)
		.filter(notNull);
	return nonNullAvailabilityRanges.length ? Math.min(...nonNullAvailabilityRanges) : null;
};

export const getStartAndEndTimeFromRanges = (availabilityRanges: AvailabilityRange[]) => {
	const times = availabilityRanges.reduce(
		(prev, range) => {
			const newFirstStart = !prev.start
				? range.start
				: prev.start < range.start
				? prev.start
				: range.start;
			const newLastEnd = !prev.end ? range.end : prev.end > range.end ? prev.end : range.end;
			return {
				start: newFirstStart,
				end: newLastEnd,
			};
		},
		{
			start: null,
			end: null,
		} as {
			start: string | null;
			end: string | null;
		},
	);
	return times;
};
