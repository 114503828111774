import React from 'react';

import { Button, Typography } from '@mui/material';

import { useTranslation } from 'services/localization';

const RegistrationError = ({ onBack }: { onBack: () => void }) => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant="h5" textAlign="center" fontSize="2.8rem" sx={{ mb: 1 }}>
				{t('common:errors.somethingWentWrongShort', 'Something went wrong')}
			</Typography>
			<Typography variant="body1" color="secondary" textAlign="center">
				{t(
					'getStarted.errorDescription',
					'Unfortunately we encountered an unexpected error while creating your store... Please go back to the previous page to try again. Apologies for the inconvenience!',
				)}
			</Typography>
			<Button
				sx={{
					mt: 7,
				}}
				variant="black"
				onClick={onBack}
			>
				{t('common:actions.takeMeBack', 'Take me back')}
			</Button>
		</>
	);
};

export default RegistrationError;
