import { TFunction } from 'i18next';
import { uniq } from 'lodash';

import {
	Measurement,
	Option,
	OrderProduct,
	SetProduct,
	Shopper,
	SizeChart,
	SizeChartType,
	UnitSystemType,
	UnitSystems,
	UserDetailName,
	UserDetailNames,
	UserProperties,
	UserProperty,
} from 'common/types';

import { removeUndefinedValues } from '../common';
import {
	HeightUnit,
	WeightUnit,
	convertMeasurementByUnitSystem,
	getHeightOptions,
	getWeightOptions,
} from './measurements';
import {
	ShoeSizeUnit,
	USUKOptionValueToShoeSize,
	convertShoeSize,
	getShoeSizeOptions,
	isUSUKChildrenShoeSizeOption,
} from './shoeSize';

export * from './measurements';
export * from './shoeSize';

export type MeasurementUnit = WeightUnit | HeightUnit | ShoeSizeUnit;

export interface UserDetailOptions {
	values: string[];
	unit: MeasurementUnit | '';
}

export type UserDetailsOptions = { [K in UserDetailName]: UserDetailOptions };

export interface GetUserDetailOptionsProps {
	unitSystem?: UnitSystemType;
	sizeChart?: SizeChartType;
}

export const getUnitSystemOptions = (t: TFunction): Option<UnitSystemType>[] => [
	{ value: UnitSystems.METRIC, label: t('common:units.metric', 'Metric (kg, cm)') },
	{ value: UnitSystems.US, label: t('common:units.us', 'US (lb, ft & in)') },
	{ value: UnitSystems.UK, label: t('common:units.uk', 'UK (st, ft & in)') },
];

export const getAllUserDetailOptions = (props?: GetUserDetailOptionsProps): UserDetailsOptions => {
	return {
		weight: getWeightOptions(props?.unitSystem),
		height: getHeightOptions(props?.unitSystem),
		shoeSize: getShoeSizeOptions(props),
		experienceLevel: {
			values: ['beginner', 'intermediate', 'advanced'],
			unit: '',
		},
		age: {
			values: ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-'],
			unit: '',
		},
		stance: {
			values: ['regular', 'goofy', 'unknown'],
			unit: '',
		},
	};
};

export const getUserDetailOptions = (
	detail: UserDetailName,
	props?: GetUserDetailOptionsProps,
): UserDetailOptions | undefined => getAllUserDetailOptions(props)[detail];

/**
 * The universal converting function, convert any user details to target unit system
 */
export const getConvertedUserDetails = (
	detailName: UserDetailName,
	input?: Measurement,
	unitSystem?: UnitSystemType,
): Measurement =>
	input && input.value
		? (detailName === UserDetailNames.height || detailName === UserDetailNames.weight) && unitSystem
			? convertMeasurementByUnitSystem(input as Measurement<WeightUnit | HeightUnit>, unitSystem)
			: detailName === UserDetailNames.shoeSize && unitSystem
			? convertShoeSize(input, unitSystem)
			: input
		: { value: '', unit: '' };

const getExperience = (t: TFunction) => {
	return [
		{
			value: 'beginner',
			name: t('common:experience.beginner'),
			info: t('common:experience.beginnerInfo'),
		},
		{
			value: 'intermediate',
			name: t('common:experience.intermediate'),
			info: t('common:experience.intermediateInfo'),
		},
		{
			value: 'advanced',
			name: t('common:experience.advanced'),
			info: t('common:experience.advancedInfo'),
		},
	];
};

export const getLocalizedExperienceLevel = (experienceValue: string, t: TFunction): string => {
	return getExperience(t).find((exp) => exp.value === experienceValue)?.name ?? '';
};

const getStance = (t: TFunction) => {
	return [
		{
			value: 'regular',
			name: 'Regular',
			info: t('common:stance.regular_description', 'Left foot front'),
		},
		{
			value: 'goofy',
			name: 'Goofy',
			info: t('common:stance.goofy_description', 'Right foot front'),
		},
		{ value: 'unknown', name: t('common:stance.unknown', `I don't know`) },
	];
};

export const getLocalizedStance = (stance: string, t: TFunction): string => {
	return getStance(t).find((exp) => exp.value === stance)?.name ?? '';
};

export const getLocalizedStanceDescription = (stance: string, t: TFunction): string | undefined => {
	return getStance(t).find((exp) => exp.value === stance)?.info ?? undefined;
};

const getUSUKLabelValue = (input: Measurement) => {
	const valueSuffix =
		input.unit === 'US' && input.sizeChart === SizeChart.KIDS
			? isUSUKChildrenShoeSizeOption(input.value)
				? 'C'
				: 'Y'
			: '';
	return `${USUKOptionValueToShoeSize(input.value)}${valueSuffix}`;
};

const getDisplayLabelUnit = (input: Measurement, t: TFunction) => {
	if (input.unit === 'US') {
		const sizeChart =
			input.sizeChart === SizeChart.KIDS
				? ''
				: input.sizeChart === SizeChart.WOMEN
				? ` (${t('common:userDetails.women', 'Women')})`
				: ` (${t('common:userDetails.men', 'Men')})`;
		return ` ${input.unit}${sizeChart}`;
	}
	if (input.unit === 'UK') {
		const sizeChart =
			input.sizeChart === SizeChart.KIDS ? ` (${t('common:userDetails.kids', 'Kids')})` : '';
		return ` ${input.unit}${sizeChart}`;
	}
	return input.unit && input.unit !== 'ft' ? ` ${input.unit}` : '';
};

/**
 * Label displays in shopper info
 */
export const getDetailDisplayLabel = (detail: UserDetailName, input: Measurement, t: TFunction) => {
	const labelValue =
		detail === 'experienceLevel'
			? getLocalizedExperienceLevel(input.value, t)
			: detail === 'stance'
			? getLocalizedStance(input.value, t)
			: input.value;
	const labelUnit = getDisplayLabelUnit(input, t);
	return `${labelValue}${labelUnit}`;
};

/**
 * Label in options dropdown list
 */
export const getDetailOptionLabel = (detail: UserDetailName, input: Measurement, t: TFunction) => {
	const labelValue =
		input.unit === 'US' || input.unit === 'UK'
			? getUSUKLabelValue(input)
			: detail === 'experienceLevel'
			? getLocalizedExperienceLevel(input.value, t)
			: detail === 'stance'
			? getLocalizedStance(input.value, t)
			: input.value;
	const labelUnit =
		detail !== UserDetailNames.shoeSize && input.unit && input.unit !== 'ft'
			? ` ${input.unit}`
			: '';

	return `${labelValue}${labelUnit}`;
};

/**
 * Secondary description in options dropdown list
 */
export const getDetailOptionDescription = (
	detail: UserDetailName,
	input: Measurement,
	t: TFunction,
) => {
	const labelDescription =
		detail === 'stance' ? getLocalizedStanceDescription(input.value, t) : undefined;
	return labelDescription;
};

export const isMeasurementDetail = (detail: UserDetailName) =>
	detail === UserDetailNames.height ||
	detail === UserDetailNames.weight ||
	detail === UserDetailNames.shoeSize;

export const detailsIncludeMeasurementUnits = (details: UserDetailName[]) =>
	details.some((detail) => isMeasurementDetail(detail));

export const getUpdatedShopperFromDetailChange = (
	shopper: Shopper,
	detail: UserDetailName,
	value: UserProperty | null,
): Shopper => {
	if (!value) {
		const { [detail]: removedDetail, ...otherDetails } = shopper.userProperties;
		return { ...shopper, userProperties: otherDetails };
	}
	return { ...shopper, userProperties: { ...shopper.userProperties, [detail]: value } };
};

/**
 * Convert the metric properties to new unit,
 * then convert back to the corresponding metric value of the new unit.
 */
export const getUpdatedShopperFromUnitSystemChange = (
	shopper: Shopper,
	newUnitSystem: UnitSystemType,
): Shopper => {
	const updatedUserProperties = Object.entries(shopper.userProperties).reduce(
		(updatedUserProperties: UserProperties, [detailName, userProperty]) => {
			const detail = detailName as UserDetailName;
			const metricInput: Measurement = getMetricInputFromUserProperty(userProperty);
			const newLocalInput = getConvertedUserDetails(detail, metricInput, newUnitSystem);
			const newMetricInput = getConvertedUserDetails(detail, newLocalInput, UnitSystems.METRIC);
			updatedUserProperties[detailName] = {
				value: newMetricInput.value,
				unit: newMetricInput.unit,
				...(isMeasurementDetail(detail) && {
					localInput: getLocalInputFromMeasurement(newLocalInput),
				}),
			};
			return updatedUserProperties;
		},
		{},
	);
	return { ...shopper, userProperties: updatedUserProperties };
};

export const justifySizeChartByUnitSystem = (
	detail: UserDetailName,
	input: Measurement,
	unitSystem: UnitSystemType,
) =>
	detail === UserDetailNames.shoeSize
		? unitSystem === UnitSystems.US
			? input.sizeChart && input.sizeChart !== SizeChart.ADULTS
				? input.sizeChart
				: SizeChart.MEN
			: unitSystem === UnitSystems.UK
			? input.sizeChart === SizeChart.KIDS
				? input.sizeChart
				: SizeChart.ADULTS
			: input.sizeChart
		: undefined;

export const getMetricInputFromUserProperty = (userProperty: UserProperty): Measurement => ({
	value: userProperty.value,
	unit: userProperty.unit ?? '',
	sizeChart: userProperty.localInput?.sizeChart,
});

export const getLocalInputFromUserProperty = (
	detail: UserDetailName,
	userProperty: UserProperty,
): Measurement =>
	isMeasurementDetail(detail) && userProperty.localInput
		? userProperty.localInput
		: { value: userProperty.value, unit: userProperty.unit ?? '' };

export const getLocalInputFromMeasurement = (input: Measurement): Measurement => {
	const { value, unit, sizeChart } = input;
	const localSize = unit === 'US' || unit === 'UK' ? getUSUKLabelValue(input) : value;
	const localSizeChart =
		unit === 'UK'
			? isUSUKChildrenShoeSizeOption(value)
				? SizeChart.KIDS
				: SizeChart.ADULTS
			: sizeChart;
	return removeUndefinedValues({ ...input, value: localSize, sizeChart: localSizeChart });
};

export const getAllUserDetailNames = () => {
	return Object.keys(UserDetailNames) as UserDetailName[];
};

export const sortUserDetails = (details: UserDetailName[]): UserDetailName[] => {
	const preferredOrder = getAllUserDetailNames();
	const sortedDetails = [...details].sort((a, b) => {
		const indexOfA = preferredOrder.indexOf(a);
		const indexOfB = preferredOrder.indexOf(b);
		if (indexOfA === -1) return 1;
		if (indexOfB === -1) return -1;
		return indexOfA - indexOfB;
	});
	return sortedDetails;
};

export const getSortedShopperProperties = (shopper: Shopper) => {
	const details = Object.keys(shopper.userProperties || {}) as UserDetailName[];
	const sortedDetails = sortUserDetails(details);
	return sortedDetails.map((detailName) => ({
		detailName,
		detailValue: shopper.userProperties[detailName],
	}));
};

const getProductsDetails = (products: (OrderProduct | SetProduct)[]) =>
	products.flatMap((p) => p.userDetails?.map((d) => d.name) ?? []);

export const getRequiredDetails = (products: OrderProduct[]) => {
	const details: UserDetailName[] = getProductsDetails(products);
	const setDetails: UserDetailName[] = products.flatMap((p) =>
		p.set && p.setProducts ? getProductsDetails(p.setProducts) : [],
	);
	return uniq([...details, ...setDetails]);
};

export const getSortedRequiredDetails = (products: OrderProduct[]) => {
	const details = getRequiredDetails(products);
	return sortUserDetails(details);
};
