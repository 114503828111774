import { createReducer } from '@reduxjs/toolkit';

import { ShopPublicInfo, UserApi } from 'common/types';
import * as UserActions from 'actions/UserActions';
import { LoadingData } from 'services/types';
import { setError, setFulfilled, setLoading } from 'services/utils/redux';

export type UserState = {
	user: LoadingData<UserApi>;
	shops: LoadingData<ShopPublicInfo[]>;
	activeLocations: {
		[userId: string]: string;
	};
};

const INITIAL_STATE: UserState = {
	user: {
		loading: true,
		error: null,
		data: null,
	},
	shops: {
		loading: true,
		error: null,
		data: null,
	},
	activeLocations: JSON.parse(localStorage.getItem('activeLocations') ?? '{}'),
};

const UserReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(UserActions.setActiveUserListener.pending, (state) => {
			setLoading(state.user);
		})
		.addCase(UserActions.setActiveUserError, (state, action) => {
			setError(state.user, { error: action.payload });
		})
		.addCase(UserActions.setActiveUserData, (state, action) => {
			setFulfilled(state.user, { data: action.payload });
		});

	builder
		.addCase(UserActions.setActiveUserShopsListener.pending, (state) => {
			setLoading(state.shops);
		})
		.addCase(UserActions.setActiveUserShopsError, (state, action) => {
			setError(state.shops, { error: action.payload });
		})
		.addCase(UserActions.setActiveUserShopsData, (state, action) => {
			setFulfilled(state.shops, { data: action.payload });
		});

	builder.addCase(UserActions.setActiveLocationId.fulfilled, (state, action) => {
		state.activeLocations = action.payload;
	});

	builder.addCase(UserActions.logout.fulfilled, (state) => {
		return {
			...state,
			user: INITIAL_STATE.user,
			shops: INITIAL_STATE.shops,
		};
	});
});

export default UserReducer;
