import {
	OnboardingItem,
	OnboardingListVersion,
	OnboardingListVersions,
	OnboardingStepDefinition,
} from 'common/services/analytics/tractionAnalytics';
import { switchUnreachable } from 'common/utils/common';

const PRODUCT_CATALOG_INCLUDED_ITEMS = (version: OnboardingListVersion): OnboardingItem[] => {
	switch (version) {
		case OnboardingListVersions.V3_USERFLOW:
			return [];
		case OnboardingListVersions.V3:
		case OnboardingListVersions.V2_TEMPLATE:
		case OnboardingListVersions.V2_NO_TEMPLATE:
			return [
				'CREATE_MULTIPLE_PRODUCTS',
				'EDIT_PRODUCT_PRICING',
				'EDIT_PRODUCT_QUANTITY',
				'RECOMMEND_ADD_ON_PRODUCTS',
				'CREATE_CATEGORY',
			];
		case OnboardingListVersions.V1_TEMPLATE:
		case OnboardingListVersions.V1_NO_TEMPLATE:
		case OnboardingListVersions.V0:
			return [
				'VIEW_CATALOG',
				'CREATE_MULTIPLE_PRODUCTS',
				'EDIT_PRODUCT_PRICING',
				'EDIT_PRODUCT_QUANTITY',
				'RECOMMEND_ADD_ON_PRODUCTS',
				'CREATE_CATEGORY',
			];
		default:
			return switchUnreachable(version);
	}
};

export const PRODUCT_CATALOG_DEFINITION: Record<'PRODUCT_CATALOG', OnboardingStepDefinition> = {
	PRODUCT_CATALOG: {
		plans: 'all',
		versions: {
			V3: PRODUCT_CATALOG_INCLUDED_ITEMS('V3'),
			V2_NO_TEMPLATE: PRODUCT_CATALOG_INCLUDED_ITEMS('V2_NO_TEMPLATE'),
			V2_TEMPLATE: PRODUCT_CATALOG_INCLUDED_ITEMS('V2_TEMPLATE'),
			V1_NO_TEMPLATE: PRODUCT_CATALOG_INCLUDED_ITEMS('V1_NO_TEMPLATE'),
			V1_TEMPLATE: PRODUCT_CATALOG_INCLUDED_ITEMS('V1_TEMPLATE'),
			V0: PRODUCT_CATALOG_INCLUDED_ITEMS('V0'),
		},
	},
};
