import { z } from 'zod';

export const zLanguageApi = z.enum([
	'fi',
	'en',
	'ru',
	'de',
	'sk',
	'sv',
	'nl',
	'hu',
	'no',
	'fr',
	'es',
	'it',
	'cs',
	'da',
	'lt',
	'et',
	'pl',
	'sl',
]);
