import { ViewAction } from 'actions';

import { Languages } from 'common/types';
import { getInitialAdminLanguage } from 'services/localization';

const INITIAL_STATE: Languages = getInitialAdminLanguage();

const LangReducer = (state = INITIAL_STATE, action: ViewAction): Languages => {
	switch (action.type) {
		case 'UPDATE_LANGUAGE':
			return action.lang;
		default:
			return state;
	}
};
export default LangReducer;
