import { useState } from 'react';

import moment from 'moment-timezone';

import { useInterval } from './useInterval';

const useCurrentTime = (): moment.Moment => {
	const [time, setTime] = useState(moment());
	const updateTime = () => setTime(moment());

	useInterval(updateTime, 1000);

	return time;
};

export default useCurrentTime;
