import { useEffect } from 'react';

import { Typography } from '@mui/material';

import ActionDialog from 'common/components/ActionDialog';
import { useTranslation } from 'services/localization';

interface Props {
	variant: 'success' | 'error';
	attemptAutoClose: boolean;
}

export const PartnerAuthDoneDialog = (props: Props) => {
	const { t } = useTranslation();

	// We may attempt to close the window automatically on successful auth
	// This might not always work even if we would like to auto-close the window due to window.close security limitations
	useEffect(() => {
		const closeWindow = () => {
			const hostWindow = window.opener;
			window.focus();
			window.close();
			if (!!hostWindow) {
				// Some iOS versions return error when trying to focus host window
				try {
					hostWindow.focus();
				} catch (e) {}
			}
		};
		if (props.attemptAutoClose) {
			closeWindow();
		}
	}, [props.attemptAutoClose]);

	const getDialogTexts = (variant: Props['variant']): { title: string; body: string } => {
		switch (variant) {
			case 'success':
				return {
					title: t('register.setupDoneTitle', 'Setup completed'),
					body: t(
						'register.setupDoneBody',
						'Account setup is completed, you can close this browser window and return to the service.',
					),
				};
			case 'error':
				return {
					title: t('register.setupFailedTitle', 'Setup failed'),
					body: t(
						'register.setupFailedBody',
						'An error occured while setting up the account. Please close this browser window, return to the service and try again.',
					),
				};
		}
	};

	const texts = getDialogTexts(props.variant);

	return (
		<ActionDialog
			onClose={() => undefined}
			variant={props.variant === 'success' ? 'info' : 'danger'}
			title={texts.title}
		>
			<Typography sx={{ my: 2 }}>{texts.body}</Typography>
		</ActionDialog>
	);
};
