import { faker } from '@faker-js/faker/locale/en';

import { ItemPricing, getItemPricingFromListPrice } from 'common/modules/atoms/pricing';
import { AmountObject } from 'common/types';

import { getCurrency } from './Currency.mock';
import { getTaxLine } from './TaxLine.mock';
import { arrify } from './index.mock';

const generateItemPricing = (): ItemPricing => {
	return {
		currency: getCurrency(),
		/**
		 * Total price including taxes and discounts
		 */
		total: faker.datatype.number({ min: 10, max: 1000 }),
		/**
		 * Price after discounts and before taxes if taxes are excluded
		 */
		subtotal: faker.datatype.number({ min: 10, max: 1000 }),
		totalTaxes: faker.datatype.number({ min: 10, max: 1000 }),
		totalDiscounts: faker.datatype.number({ min: 10, max: 1000 }),
		taxExcluded: faker.datatype.boolean(),
		taxLines: arrify(getTaxLine, faker.datatype.number({ min: 1, max: 4 })),
		discountCodes: {
			[faker.word.noun()]: {
				totalDiscountValue: faker.datatype.number({ min: 10, max: 1000 }),
				quantity: 1,
			},
		},
		manualDiscount: faker.datatype.number({ min: 0, max: 1000 }),
		deposit: faker.datatype.number({ min: 0, max: 1000 }),
		listPrice: faker.datatype.number({ min: 10, max: 1000 }),
		originalListPrice: faker.datatype.number({ min: 10, max: 1000 }),
	};
};

export const getItemPricing = (p?: Partial<ItemPricing>): ItemPricing => {
	return { ...generateItemPricing(), ...p };
};

export const getMockItemPricingFromListPriceAndTax = (
	listPriceAmount: AmountObject,
	tax: { taxExcluded: boolean; taxRate: number },
	overrides?: Partial<
		Pick<ItemPricing, 'manualDiscount' | 'discountCodes' | 'originalListPrice' | 'deposit'>
	>,
): ItemPricing => {
	const listPrice = listPriceAmount.value;
	const pricing = getItemPricingFromListPrice(listPrice, {
		deposit: overrides?.deposit,
		taxRate: tax.taxRate,
		taxExcluded: tax.taxExcluded,
		currency: listPriceAmount.currency,
		manualDiscount: overrides?.manualDiscount,
		discountCodes: overrides?.discountCodes,
	});
	return pricing;
};
