import { createReducer } from '@reduxjs/toolkit';
import { addToPurchasedOrders, updatePurchasedOrders } from 'actions';
import { uniqBy } from 'lodash';

import { OrdersState } from 'services/types';

const INITIAL_STATE: OrdersState = { kind: 'LOADING' };

export default createReducer<OrdersState>(INITIAL_STATE, (builder) => {
	builder.addCase(updatePurchasedOrders, (state, action) => ({ ...state, ...action.payload }));
	builder.addCase(addToPurchasedOrders, (state, action) => {
		return state.kind === 'FETCHED' && action.payload.kind === 'FETCHED'
			? {
					...state,
					data: uniqBy([...state.data, ...action.payload.data], (p) => p.id),
			  }
			: state;
	});
});
