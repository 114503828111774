import { z } from 'zod';

import { zLocaleField } from 'common/modules/atoms/localization';
import { Plans } from 'common/modules/plans/plans';
import { BillingCycle } from 'common/modules/plans/subscriptions';

export const zPlanCampaignDiscountTypes = z.enum([
	'PERCENTAGE_OFF_FIRST_PAYMENT',
	'FIXED_PRICE_OFF_FIRST_PAYMENT',
	'FIXED_PRICE_FIRST_PAYMENT',
]);

export const zPlanCampaignVisibilityTypes = z.enum(['PUBLIC', 'PRIVATE']);

export const zPlanCampaignPromotionTypes = z.enum(['PLAN_CARD', 'PLANS_BANNER']);

export const zPlanCampaignPromotion = z.object({
	title: zLocaleField,
	description: zLocaleField.optional(),
});

export const zPlanCampaignVisibility = z
	.discriminatedUnion('type', [
		z.object({
			type: z.literal(zPlanCampaignVisibilityTypes.Enum.PUBLIC),
			promotions: z.record(zPlanCampaignPromotionTypes, zPlanCampaignPromotion).nullable(),
		}),
		z.object({
			type: z.literal(zPlanCampaignVisibilityTypes.Enum.PRIVATE),
		}),
	])
	.refine(
		(v) => {
			if (v.type === zPlanCampaignVisibilityTypes.Enum.PUBLIC) {
				return !!v.promotions && Object.values(v.promotions).some((p) => !!p.title.def);
			}

			return true;
		},
		{ message: 'Promotion element & promotion element title is required' },
	);

export const zPlanCampaignDiscount = z
	.discriminatedUnion('type', [
		z.object({
			type: z.literal(zPlanCampaignDiscountTypes.Enum.PERCENTAGE_OFF_FIRST_PAYMENT),
			value: z.number().min(0).max(100),
		}),
		z.object({
			type: z.literal(zPlanCampaignDiscountTypes.Enum.FIXED_PRICE_OFF_FIRST_PAYMENT),
			value: z.number(),
		}),
		z.object({
			type: z.literal(zPlanCampaignDiscountTypes.Enum.FIXED_PRICE_FIRST_PAYMENT),
			value: z.number(),
		}),
	])
	.refine(
		(v) => {
			if (v.type === zPlanCampaignDiscountTypes.Enum.PERCENTAGE_OFF_FIRST_PAYMENT) {
				return v.value > 0 && v.value <= 100;
			}
			return true;
		},
		{ message: 'Discount percentage must be between 0 and 100' },
	)
	.refine(
		(v) => {
			if (v.type === zPlanCampaignDiscountTypes.Enum.FIXED_PRICE_OFF_FIRST_PAYMENT) {
				return v.value > 0;
			}

			return true;
		},
		{
			message: 'Discount amount must be more than 0',
		},
	);

export const zPlanCampaignValidity = z
	.object({
		startDate: z.string().nullable(),
		endDate: z.string().nullable(),
	})
	.refine((v) => !!v.startDate, { message: 'Start date is required' })
	.refine((v) => !!v.endDate, { message: 'End date is required ' });

export const zPlanCampaignRules = z.object({
	newPlan: z.array(z.nativeEnum(Plans)).optional(),
	currentPlan: z.array(z.nativeEnum(Plans)).optional(),
	newBillingCycle: z.array(z.nativeEnum(BillingCycle)).optional(),
	currentBillingCycle: z.array(z.nativeEnum(BillingCycle)).optional(),
});

export const zPlanCampaign = z.object({
	id: z.string(),
	/**
	 * The public name of the campaign
	 */
	name: zLocaleField.refine((v) => v.def.length > 0, { message: 'Name is required' }),
	/**
	 * The unique code of the campaign
	 */
	code: z.string().refine((v) => v.length > 0, { message: 'Code is required' }),
	/**
	 * The discount provided by the campaign
	 */
	discount: zPlanCampaignDiscount,
	/**
	 * Validity date range for the campaign. If nullish, the campaign is always active.
	 */
	validity: zPlanCampaignValidity.nullable(),
	/**
	 * The visibility and possible promotional elements of the campaign
	 */
	visibility: zPlanCampaignVisibility,
	/**
	 * Validity rules for the campaign. If nullish, the campaign is valid for
	 * all merchants.
	 */
	rules: zPlanCampaignRules.nullable(),
});

export type PlanCampaign = z.infer<typeof zPlanCampaign>;
export type PlanCampaignDiscount = z.infer<typeof zPlanCampaignDiscount>;
export type PlanCampaignDiscountType = z.infer<typeof zPlanCampaignDiscountTypes>;
export type PlanCampaignValidity = z.infer<typeof zPlanCampaignValidity>;
export type PlanCampaignRules = z.infer<typeof zPlanCampaignRules>;
export type PlanCampaignVisibility = z.infer<typeof zPlanCampaignVisibility>;
export type PlanCampaignVisibilityType = z.infer<typeof zPlanCampaignVisibilityTypes>;
export type PlanCampaignPromotionType = z.infer<typeof zPlanCampaignPromotionTypes>;
export type PlanCampaignPromotion = z.infer<typeof zPlanCampaignPromotion>;

export type PublicPlanCampaign = Omit<PlanCampaign, 'visibility'> & {
	visibility: {
		type: 'PUBLIC';
		promotions: {
			[Promotion in PlanCampaignPromotionType]?: PlanCampaignPromotion;
		};
	};
};
