import React from 'react';

import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { upperCase } from 'lodash';

import DecathlonLogo from 'common/assets/customer-reference-logos/decathlon-logo.png';
import LaplandHotelsLogo from 'common/assets/customer-reference-logos/lapland-hotels-logo.png';
import RabLogo from 'common/assets/customer-reference-logos/rab-logo.png';
import RidleyLogo from 'common/assets/customer-reference-logos/ridley-logo.png';
import { t } from 'services/localization';

export interface Props {}
const CtaTrustedBy = (props: Props) => {
	const theme = useTheme();
	const isReallySmallScreen = useMediaQuery(theme.breakpoints.down(370));
	const logoHeight = useMediaQuery(theme.breakpoints.between(370, 'lg')) ? 65 : 75;

	const renderReferenceLogo = (logo: string, alt: string, height: number) => (
		<img src={logo} alt={alt} style={{ height, width: height }} />
	);

	return (
		<Stack
			sx={{
				[theme.breakpoints.down('lg')]: {
					p: theme.spacing(2, 2, 1, 2),
					border: `1px solid ${theme.palette.border.paper}`,
					borderRadius: '8px',
					maxWidth: 355,
					margin: '0 auto',
				},
			}}
		>
			<Typography
				variant="caption"
				sx={{
					color: {
						xs: theme.palette.text.primary,
						lg: theme.palette.primary.main,
					},
					fontWeight: 500,
				}}
			>
				{upperCase(t('register.trustedBy', 'Trusted by'))}
			</Typography>
			<Stack direction={isReallySmallScreen ? 'column' : 'row'} justifyContent="space-between">
				{isReallySmallScreen ? (
					<>
						<Stack direction="row" justifyContent="space-evenly">
							{renderReferenceLogo(DecathlonLogo, 'Decathlon logo', logoHeight)}
							{renderReferenceLogo(LaplandHotelsLogo, 'Lapland Hotels logo', logoHeight)}
						</Stack>
						<Stack direction="row" justifyContent="space-evenly">
							{renderReferenceLogo(RabLogo, 'Rab logo', logoHeight)}
							{renderReferenceLogo(RidleyLogo, 'Ridley logo', logoHeight)}
						</Stack>
					</>
				) : (
					<>
						{renderReferenceLogo(DecathlonLogo, 'Decathlon logo', logoHeight)}
						{renderReferenceLogo(LaplandHotelsLogo, 'Lapland Hotels logo', logoHeight)}
						{renderReferenceLogo(RabLogo, 'Rab logo', logoHeight)}
						{renderReferenceLogo(RidleyLogo, 'Ridley logo', logoHeight)}
					</>
				)}
			</Stack>
		</Stack>
	);
};

export default CtaTrustedBy;
