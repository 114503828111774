import { OnboardingStepDefinition } from 'common/services/analytics/tractionAnalytics';

export const PAYMENTS_DEFINITION: Record<'PAYMENTS', OnboardingStepDefinition> = {
	PAYMENTS: {
		plans: 'all',
		versions: {
			V1_TEMPLATE: ['PAYMENTS'],
			V1_NO_TEMPLATE: ['PAYMENTS'],
			V0: ['PAYMENTS'],
		},
	},
};
