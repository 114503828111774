import { PublicDiscountCode } from 'common/types';

import { CallableCreator } from '..';

export interface DiscountCodeValidationRequest {
	code: string;
	shopId: string;
	productIds: string[];
	categoryIds: string[];
	bookingStartDate: string | null;
}

export type DiscountCodeValidationResult =
	| {
			valid: false;
			reason: string;
	  }
	| { valid: true; code: PublicDiscountCode };

export const discountCodes = (createCallable: CallableCreator) => ({
	validate: createCallable<DiscountCodeValidationRequest, DiscountCodeValidationResult>(
		'discountCodes-validate',
	),
	exists: createCallable<{ shopId: string }, boolean>('discountCodes-exists'),
});
