import { TFunction } from 'react-i18next';
import { RiSettings3Line } from 'react-icons/ri';

import { getCountryNameFromCountryCode, isCountryCode } from 'common/modules/atoms/countries';
import {
	OnboardingListVersion,
	OnboardingSections,
	ShopAnalytics,
} from 'common/services/analytics/tractionAnalytics';

import { OnboardingStep, OnboardingStepItem } from '../../types';
import { getOnboardingItemFromOnboardingStepItem } from '../../utils';

const STEP_VERSIONS = ({
	items,
	shopAnalytics,
	t,
	country,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
	country?: string;
}): Record<'V0' | 'V1', OnboardingStep> => ({
	V1: {
		name:
			country && isCountryCode(country)
				? t('homeView.onboarding.verifyYourStoreSettingsForSellingInCountry', {
						defaultValue: 'Verify your store settings are correct for selling in {{country}}',
						country: getCountryNameFromCountryCode(country),
				  })
				: t('homeView.onboarding.localizeYourStore', 'Localize your store'),
		section: OnboardingSections.READY_FOR_CUSTOMERS,
		type: 'list',
		icon: RiSettings3Line,
		items: items.map((item) => getOnboardingItemFromOnboardingStepItem(item, shopAnalytics)),
	},
	V0: {
		name:
			country && isCountryCode(country)
				? t('homeView.onboarding.localizeYourStoreForCountry', {
						defaultValue: 'Localize your store for {{country}}',
						country: getCountryNameFromCountryCode(country),
				  })
				: t('homeView.onboarding.localizeYourStore', 'Localize your store'),
		type: 'list',
		items: items.map((item) => getOnboardingItemFromOnboardingStepItem(item, shopAnalytics)),
	},
});

export const LOCALIZATION_STEP = ({
	items,
	shopAnalytics,
	t,
	country,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
	country?: string;
}): { all: OnboardingStep } | { [key in OnboardingListVersion]?: OnboardingStep } => {
	const versions = STEP_VERSIONS({ items, shopAnalytics, t, country });
	return {
		V3: versions['V1'],
		V2_TEMPLATE: versions['V1'],
		V2_NO_TEMPLATE: versions['V1'],
		V1_TEMPLATE: versions['V0'],
		V1_NO_TEMPLATE: versions['V0'],
		V0: versions['V0'],
	};
};
