import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { ACSRetirementDb } from 'common/modules/acs';

import { createBasicDbOperations } from '../utils';

export const getAcsRetirementQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.acsRetirements;
	return createBasicDbOperations<ACSRetirementDb>(collectionPath, dbRefs);
};
