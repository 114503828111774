import { BillingCardInformation, ShopBilling } from 'common/modules/billing';
import { AllEmails } from 'common/modules/emails/types';
import { ShopFeatures } from 'common/modules/plans';
import { ShopTemplate } from 'common/modules/shopTemplates';
import { CurrencyObject, Languages } from 'common/types';

import { CallableCreator } from '..';

export type ShopSummary = {
	id: string;
	name: string;
	businessId?: string;
	createdAt: string;
	country?: string;
	currency: CurrencyObject;
	email: string;
	locationCount: number;
	features: ShopFeatures;
	billing: ShopBilling;
	paymentMethod: BillingCardInformation | null;
};

export interface TestEmailRequest {
	emailType: AllEmails;
	emailToSend: string;
	language: Languages;
	merchantId?: string;
	locationId?: string;
	shopName?: string;
}

export interface CreateShopTemplateArgs {
	name: string;
	shopId: string;
	userId: string;
	description?: string;
	locationId?: string;
	dryRun?: boolean;
}

export const internal = (createCallable: CallableCreator) => ({
	getShopSummaries: createCallable<void, ShopSummary[]>('internalAdmin-getShopSummaries'),
	sendEmailTest: createCallable<TestEmailRequest, void>('internal-sendEmailTest'),
	createShopTemplate: createCallable<CreateShopTemplateArgs, void>('internal-createShopTemplate'),
	getShopTemplates: createCallable<void, ShopTemplate[]>('internal-getShopTemplates'),
	updateShopTemplate: createCallable<Pick<ShopTemplate, 'id' | 'description' | 'name'>, void>(
		'internal-updateShopTemplate',
	),
	deleteShopTemplate: createCallable<Pick<ShopTemplate, 'id'>, void>('internal-deleteShopTemplate'),
});
