import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const BUSINESS_CONTACT_INFORMATION_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'BUSINESS_CONTACT_INFORMATION',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	BUSINESS_CONTACT_INFORMATION: {
		all: {
			action: getRouteOnboardingStepItem({
				routeTo: Routes.ACCOUNT_GENERAL,
				completeOnClick: true,
			}),
			content: {
				title: t(
					'homeView.onboarding.verifyYourBusinessContactInformation',
					'Verify your business contact information',
				),
			},
			id: 'BUSINESS_CONTACT_INFORMATION',
			type: 'row',
		},
	},
});
