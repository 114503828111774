import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { createBasicDbOperations } from '../utils';

export interface ClientUpdate {
	version: number;
}

export const getClientUpdatesQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.clientUpdates;
	return {
		...createBasicDbOperations<ClientUpdate>(collectionPath, dbRefs),
	};
};
