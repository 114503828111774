import { z } from 'zod';

const Channels = ['admin', 'check_in', 'online', 'booking_link', 'manage_booking', 'api'] as const;
const InternalChannel = 'internal';
const CatalogVisibilityChannel = ['admin', 'check_in', 'online'] as const;
const BookingChannels = ['admin', 'check_in', 'online', 'booking_link', 'api'] as const;

export const zChannelApi = z.enum(Channels);
// TODO Should use Extract once zod supports it: https://github.com/colinhacks/zod/pull/1652
export const zCatalogVisibilityChannelApi = z.enum(CatalogVisibilityChannel);
export const zInternalChannelApi = z.literal(InternalChannel);
export const zBookingChannelApi = z.enum(BookingChannels);
