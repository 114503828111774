import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

export const STORE_DETAILS_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'STORE_DETAILS',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	STORE_DETAILS: {
		all: {
			action: getRouteOnboardingStepItem({
				routeTo: Routes.StoreTab('general'),
			}),
			content: {
				title: t(
					'homeView.onboarding.addAddressAndContactDetails',
					'Add your address and contact details',
				),
			},
			id: 'STORE_DETAILS',
			type: 'row',
		},
	},
});
