const ORDER_BARCODE_PREFIX = 'R';

export const getDefaultOrderBarcode = (orderNumber: number | undefined, orderId: string) => {
	if (orderNumber === undefined) return orderId;
	return `${ORDER_BARCODE_PREFIX}${orderNumber.toString()}`;
};

export const getOrderNumberFromOrderBarcode = (barcode: string): number | undefined => {
	const [, orderNumberString] = barcode.split(ORDER_BARCODE_PREFIX);
	if (!orderNumberString) return undefined;
	return !isNaN(Number(orderNumberString)) ? Number(orderNumberString) : undefined;
};
