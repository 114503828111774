import { randomNumber } from './math';

/** Promisified variant of setTimeout */
export const sleep = (ms: number = 1) => {
	return new Promise<void>((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
};

/** Ensures that a promise doesn't resolve too quickly - use for smoother loading animations, transitions, etc. */
export const slow = async <T>(ms: number, promise: Promise<T>) => {
	const promises: [Promise<T>, Promise<void>] = [promise, sleep(ms)];
	return Promise.all(promises).then((results) => results[0]);
};

export const createTransactionRetryDelay = () => {
	let attempts = 0;
	return {
		async addRetryDelay() {
			const randomDelay = randomNumber(0.1, 0.5);
			// E.g. for attempt 3, would be 0.1-0.5s * 3 + 0.2s
			const delayInSeconds = randomDelay * attempts + (attempts / 10 - 0.1);
			const delayInMs = delayInSeconds * 1000;
			attempts++;
			await sleep(delayInMs);
		},
	};
};
