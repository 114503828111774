import React from 'react';

import { Stack, TextField } from '@mui/material';
import { useRegisterContext } from 'views/Register/RegisterContext';

import {
	GroupedIndustryCategories,
	IndustryCategory,
	IndustryOption as IndustryOptionType,
	IndustrySegment,
	IndustrySegments,
} from 'common/services/analytics/tractionAnalytics';
import { useTranslation } from 'services/localization';

import RegistrationOption from '../RegistrationOption';
import RegistrationRow from '../RegistrationRow';
import IndustryOption from './IndustryOption';

export interface Props {
	value: IndustrySegment;
	options: IndustryOptionType[];
	index: number;
	selectedOptions: IndustryCategory[];
	onChange: (updatedOptions: IndustryCategory[]) => void;
	title?: string;
}
const IndustrySegmentRow = (props: Props) => {
	const { index, value, options, selectedOptions, onChange, title } = props;
	const { t } = useTranslation();
	const [industrySpecifications, setStore] = useRegisterContext(
		(state) => state.industryCategories.specifications,
	);
	const showSpecification =
		value === IndustrySegments.OTHER && selectedOptions.includes(GroupedIndustryCategories.OTHER);

	const handleOnChange = (isActive: boolean, optionName: IndustryCategory) => {
		const updatedOptions = isActive
			? [optionName]
			: selectedOptions.filter((option) => option !== optionName);
		onChange(updatedOptions);
	};
	return (
		<Stack spacing={1} sx={{ maxWidth: '560px', margin: '0 auto' }}>
			<RegistrationRow title={title} index={index}>
				{options.map((option, index) => {
					const isLast = index === options.length - 1;
					const isSelected = selectedOptions.includes(option.type);
					return (
						<RegistrationOption
							key={`${option.type}-${index}`}
							sizes={{
								xs: 12,
								sm: 12,
								md: 12,
							}}
							isLast={isLast}
							styles={{ mb: 1 }}
						>
							<IndustryOption
								option={option}
								isSelected={isSelected}
								onChange={(isSelected) => handleOnChange(isSelected, option.type)}
							/>
						</RegistrationOption>
					);
				})}
			</RegistrationRow>
			{showSpecification && (
				<TextField
					fullWidth
					label={`${t('common:pleaseSpecify', 'Please specify')}`}
					value={industrySpecifications?.[GroupedIndustryCategories.OTHER] ?? ''}
					onChange={(e) =>
						setStore((store) => {
							store.dirty = true;
							if (!!store.industryCategories.specifications) {
								store.industryCategories.specifications[GroupedIndustryCategories.OTHER] =
									e.target.value;
							} else {
								store.industryCategories.specifications = {
									[GroupedIndustryCategories.OTHER]: e.target.value,
								};
							}
						})
					}
					autoFocus
					sx={{ p: 0, mb: (theme) => theme.spacing(2) }}
				/>
			)}
		</Stack>
	);
};

export default IndustrySegmentRow;
