import { z } from 'zod';

import { withOpenApi } from 'common/utils/openApi';

export const zMetafieldsApi = withOpenApi(
	z.record(z.string(), z.record(z.string(), z.any()).optional()),
	{
		title: 'Metafields',
		description:
			'Additional metafields added to the resource. Each key is a namespace for the metafield group, and the value of each key should be an object, including the data for that metafield namespace as key-value pairs.',
	},
);
