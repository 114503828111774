import { updateToNewOrderTypes } from 'common/api/dataUpdates';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { HistoryObject, OrderEventApi, OrderInfo, OrderPaymentRequest } from 'common/types';

import {
	buildQuery,
	createBasicCollectionOperations,
	createBasicDbOperations,
	createBasicDocOperations,
} from '../utils';

export const getOrderQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.orders;
	const dataUpdater = updateToNewOrderTypes;
	const query = buildQuery<OrderInfo>(collectionPath, dbRefs, dataUpdater);
	return {
		doc: (id: string) => ({
			...createBasicDocOperations<OrderInfo>(collectionPath, dbRefs, id, dataUpdater),
			orderPaymentRequests: orderPaymentRequestsQueries(dbRefs, id),
			orderEventsExtension: orderEventRequestsQueries(dbRefs, id),
			orderHistory: orderHistory(dbRefs, id),
		}),
		...createBasicCollectionOperations<OrderInfo>(collectionPath, dbRefs, dataUpdater),
		byLinkId: (linkId: string) => query.where('rentalLink.linkId', '==', linkId),
		bySkidataConfirmationNumber: (confirmationNumber: string) =>
			query.where('skidataProps.confirmationNumber', '==', confirmationNumber),
	};
};

const orderPaymentRequestsQueries = (dbRefs: DbRefs, orderId: string) => {
	const collectionPath = dbPath.orderPaymentRequests(orderId);
	const dataUpdater = undefined;
	return createBasicDbOperations<OrderPaymentRequest>(collectionPath, dbRefs, dataUpdater);
};

const orderEventRequestsQueries = (dbRefs: DbRefs, orderId: string) => {
	const collectionPath = dbPath.orderEvents(orderId);
	const dataUpdater = undefined;
	return createBasicDbOperations<OrderEventApi<'EXTENSION'>>(collectionPath, dbRefs, dataUpdater);
};

const orderHistory = (dbRefs: DbRefs, orderId: string) => {
	const collectionPath = dbPath.orderHistory(orderId);
	const dataUpdater = undefined;
	return createBasicDbOperations<HistoryObject>(collectionPath, dbRefs, dataUpdater);
};
