import React from 'react';

import { Stack, Typography, styled } from '@mui/material';
import { RiArrowUpLine } from 'react-icons/ri';

import { useTranslation } from 'services/localization';

export interface Props {
	onClick: () => void;
	isExpanded: boolean;
}
const SwitchToNewPlansBanner = (props: Props) => {
	const { onClick, isExpanded } = props;
	const { t } = useTranslation();

	return isExpanded ? (
		<StyledWrapper onClick={onClick}>
			<Stack direction="row" spacing={2} alignItems="center">
				<RiArrowUpLine />
				<Typography variant="body2" sx={{ fontWeight: 400 }}>
					{t('updatedPricingModal.banner.title', "We've updated our pricing")}
				</Typography>
			</Stack>
		</StyledWrapper>
	) : (
		<StyledWrapper onClick={onClick}>
			<Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
				<RiArrowUpLine size={18} />
			</Stack>
		</StyledWrapper>
	);
};

const StyledWrapper = styled(Stack)(({ theme }) => ({
	background: theme.palette.background.upgradeGradient,
	padding: theme.spacing(1.5),
	height: '100%',
	cursor: 'pointer',
	position: 'relative',
	flexDirection: 'row',
	alignItems: 'flex-start',
	borderRadius: '4px',
}));
export default SwitchToNewPlansBanner;
