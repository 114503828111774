import { PendingTransaction } from 'common/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';

export const getPendingTransactionQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.pendingTransactions;
	const dataUpdater = undefined;
	const query = buildQuery<PendingTransaction>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PendingTransaction>(collectionPath, dbRefs, dataUpdater),
	};
};
