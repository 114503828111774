import { z } from 'zod';

import { DayType } from 'common/models/_atoms/DayType';
import { withOpenApi } from 'common/utils/openApi';
import { zHHMMString, zIsoString } from 'common/utils/zod';

const zStartTimeSlotsBase = withOpenApi(
	z.object({
		orderLimit: z.number().int().nonnegative().optional(),
	}),
	{
		fields: {
			orderLimit:
				'The maximum number of orders that can be placed including this product at the same time.',
		},
	},
);

export const zStartTimesIntervalApi = withOpenApi(
	zStartTimeSlotsBase.extend({
		type: z.literal('interval'),
		intervalMinutes: z.number().int().nonnegative().optional().default(60),
	}),
	{
		title: 'Interval start times',
		fields: {
			type:
				'Interval start times means that product has new start times based on the given intervalMinutes value.',
			intervalMinutes: 'The time in minutes between each start time.',
		},
	},
);

const zFixedTimesCustomDay = withOpenApi(
	z.object({
		day: z.literal('custom'),
		customDate: zIsoString,
		time: zHHMMString,
	}),
	{
		title: 'Single date',
		fields: {
			day: 'Custom day means a specific date defined in `customDate`.',
			customDate: 'The custom date in ISO format.',
			time: 'Time of day in 24 hour HH:MM format.',
		},
	},
);

const zFixedTimesRepeatingDay = withOpenApi(
	z.object({
		day: DayType.api,
		time: zHHMMString,
	}),
	{
		title: 'Repeating day',
		fields: {
			day: 'Which day or days the time is valid.',
			time: 'Time of day in 24 hour HH:MM format.',
		},
	},
);

export const zStartTimesFixedApi = withOpenApi(
	zStartTimeSlotsBase.extend({
		type: z.literal('fixed'),
		fixedTimes: z.array(z.union([zFixedTimesCustomDay, zFixedTimesRepeatingDay])),
		allowTimesOutsideOpeningHours: z.boolean(),
	}),
	{
		title: 'Fixed start times',
		fields: {
			type: 'Fixed start times means that product has only a fixed set of start times defined.',
			fixedTimes: 'List of the fixed start times.',
			allowTimesOutsideOpeningHours:
				'Whether to allow fixed start times that are outside store opening hours.',
		},
	},
);

export const zStartTimesApi = z.union([zStartTimesIntervalApi, zStartTimesFixedApi]);
