import { getOpenApiExample } from 'common/utils/openApi';

import { zProductApi } from './api';
import { toApi, toDb } from './mapper';

export * from './types';

export const Product = {
	toApi,
	toDb,
	api: zProductApi,
	example: getOpenApiExample(zProductApi),
};
