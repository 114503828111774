import type {
	AdminDbRefs,
	ClientDbRefs,
	DbFullSetObject,
	DbPartialSetObject,
	DbRefs,
	DbSetObject,
	DbUpdateObject,
	DocSnapshot,
	QueryBuilderRefs,
	QuerySnapshot,
} from 'common/db/api/types';
import {
	addDocListener,
	addQueryListener,
	deleteDoc,
	getDoc,
	getList,
	isClientDbRefs,
	isClientQueryRefs,
	setDoc,
	updateDoc,
} from 'common/db/api/utils';
import type {
	DocumentData,
	DocumentReference,
	OrderByDirection,
	Query,
	QueryDocumentSnapshot,
	SnapshotMetadata,
	WhereFilterOp,
	endAt,
	endBefore,
	limit,
	limitToLast,
	startAfter,
	startAt,
} from 'common/frontend/firebase/firestore';
import { TypeToArray } from 'common/types';
import { DotNotation, DotNotationToObject, DotValue } from 'common/types/dotObject';
import { notUndefined } from 'common/utils/common';

interface CreateSetter<T extends object> {
	(data: DbFullSetObject<T>): Promise<void>;
	(data: DbPartialSetObject<T>, { merge }: { merge: true }): Promise<void>;
}

const createSetter = <T extends object>(
	id: string,
	collectionPath: string,
	dbRefs: DbRefs,
): CreateSetter<T> => {
	function set(data: DbFullSetObject<T>): Promise<void>;
	function set(data: DbPartialSetObject<T>, { merge }: { merge: true }): Promise<void>;
	function set(data: DbSetObject<T>, options?: { merge: true }): Promise<void> {
		const ref = getDocRef<T>(dbRefs, collectionPath, id);
		return setDoc<T>({ docRef: ref, data, dbRefs, merge: options?.merge });
	}
	return set;
};

interface CreateUpdater<T extends object> {
	<R extends T = T>(data: DbUpdateObject<R>): Promise<void>;
}

const createUpdater = <T extends object>(
	id: string,
	collectionPath: string,
	dbRefs: DbRefs,
): CreateUpdater<T> => {
	function update<R extends T = T>(data: DbUpdateObject<R>) {
		const ref = getDocRef<R>(dbRefs, collectionPath, id);
		return updateDoc<R>({ docRef: ref, data, dbRefs });
	}
	return update;
};

interface CreateDocGetter<T extends object> {
	(): Promise<T | undefined>;
	(opts: { withDocId: true }): Promise<{
		docId: string;
		data: T | undefined;
		snapshot: DocSnapshot<T>;
	}>;
}

const createDocGetter = <T extends object>(
	id: string,
	collectionPath: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
): CreateDocGetter<T> => {
	async function get(): Promise<T | undefined>;
	async function get(opts: {
		withDocId: true;
	}): Promise<{ docId: string; data: T | undefined; snapshot: DocSnapshot<T> }>;
	async function get(opts?: {
		withDocId: true;
	}): Promise<T | undefined | { docId: string; data: T | undefined; snapshot: DocSnapshot<T> }> {
		const ref = getDocRef<T>(dbRefs, collectionPath, id);
		if (opts) {
			const data = await getDoc<T>({ docRef: ref, dbRefs }, opts);
			return data.data && !!dataUpdater
				? { docId: data.docId, data: dataUpdater(data.data), snapshot: data.snapshot }
				: data;
		}
		const data = await getDoc<T>({ docRef: ref, dbRefs });
		return data && !!dataUpdater ? dataUpdater(data) : data;
	}
	return get;
};

interface QueryGetter<T extends object> {
	(): Promise<T[]>;
	(opts: { withDocId: true }): Promise<
		{
			docId: string;
			data: T;
			snapshot: QueryDocumentSnapshot<T> | FirebaseFirestore.QueryDocumentSnapshot<T>;
		}[]
	>;
}

const createQueryGetter = <T extends object>(
	queryRef: Query<T> | FirebaseFirestore.Query<T>,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
): QueryGetter<T> => {
	async function get(): Promise<T[]>;
	async function get(opts: {
		withDocId: true;
	}): Promise<
		{
			docId: string;
			data: T;
			snapshot: QueryDocumentSnapshot<T> | FirebaseFirestore.QueryDocumentSnapshot<T>;
		}[]
	>;
	async function get(opts?: {
		withDocId: true;
	}): Promise<
		| T[]
		| {
				docId: string;
				data: T | undefined;
				snapshot: QueryDocumentSnapshot<T> | FirebaseFirestore.QueryDocumentSnapshot<T>;
		  }[]
	> {
		if (opts) {
			const data = await getList<T>({ queryRef, dbRefs }, { withDocId: true });
			return data.length && !!dataUpdater
				? data.map((d) => ({ docId: d.docId, data: dataUpdater(d.data), snapshot: d.snapshot }))
				: data;
		}
		const data = await getList<T>({ queryRef, dbRefs });
		return data.length && !!dataUpdater ? data.map((d) => dataUpdater(d)) : data;
	}
	return get;
};

const createCountGetter = (
	queryRef: Query<DocumentData> | FirebaseFirestore.Query<FirebaseFirestore.DocumentData>,
) => {
	if ('count' in queryRef) {
		return async () => {
			const count = await queryRef.count().get();
			return count.data().count;
		};
	} else {
		return () => {
			throw new Error('.count() not supported by the firestore client');
		};
	}
};

interface GetManyGetter<T extends object> {
	(ids: string[]): Promise<(T | undefined)[]>;
	<B extends boolean>(ids: string[], opts?: { filterUndefined: B }): Promise<
		B extends true ? T[] : (T | undefined)[]
	>;
}

const createManyGetter = <T extends object>(
	collectionPath: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
): GetManyGetter<T> => {
	async function getMany(ids: string[]): Promise<(T | undefined)[]>;
	async function getMany<B extends boolean>(
		ids: string[],
		opts: { filterUndefined: B },
	): Promise<B extends true ? T : (T | undefined)[]>;
	async function getMany(
		ids: string[],
		opts?: { filterUndefined: boolean },
	): Promise<T[] | (T | undefined)[]> {
		const filterUndefined = !!opts?.filterUndefined;
		if (isClientDbRefs(dbRefs)) {
			const docRefs = ids.map((id) => getDocRef<T>(dbRefs, collectionPath, id));
			const docs = await Promise.all(
				docRefs.map(async (docRef) => {
					const data = await getDoc<T>({ docRef, dbRefs });
					return data && !!dataUpdater ? dataUpdater(data) : data;
				}),
			);
			return filterUndefined ? docs.filter(notUndefined) : docs;
		} else {
			const docRefs = ids.map((id) => getDocRef<T>(dbRefs, collectionPath, id));
			if (!docRefs.length) return [];
			const _docs = (await dbRefs.db.getAll(...docRefs)) as FirebaseFirestore.DocumentSnapshot<T>[];
			const docData = _docs.map((doc) => doc.data());
			const docs = !!dataUpdater
				? docData.map((data) => (!!data ? dataUpdater(data) : data))
				: docData;
			return filterUndefined ? docs.filter(notUndefined) : docs;
		}
	}
	return getMany;
};
export interface CreateDocListener<T extends object> {
	(
		callback: (data: T | undefined, metadata: SnapshotMetadata | undefined) => void,
		errorCallback?: (error: Error) => void,
	): () => void;
	(
		callback: (
			data: { docId: string; data: T | undefined },
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback: (error: Error) => void,
		opts: { withDocId: true },
	): () => void;
}

const createDocListener = <T extends object>(
	id: string,
	collectionPath: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
): CreateDocListener<T> => {
	// This fuction overload with different callback arguments for some reason are not working correctly with TS
	// The implementation still returns correct results that comply with the overload, so using ts-ignore-error to suppress the TS error
	// @ts-ignore-error
	function listen(
		callback: (data: T | undefined, metadata: SnapshotMetadata | undefined) => void,
		errorCallback?: (error: Error) => void,
	): () => void;
	function listen(
		callback: (
			data: { docId: string; data: T | undefined },
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback: (error: Error) => void,
		opts: { withDocId: true },
	): () => void;
	function listen(
		callback: (
			data: T | undefined | { docId: string; data: T | undefined },
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback?: (error: Error) => void,
		opts?: { withDocId: true },
	): () => void {
		const docRef = getDocRef<T>(dbRefs, collectionPath, id);
		if (!!opts) {
			const emptyErrorHandler = () => undefined;
			return addDocListener<T>(
				{ docRef, dbRefs },
				(data, metadata) =>
					callback(
						!!dataUpdater && !!data.data
							? { docId: data.docId, data: dataUpdater(data.data) }
							: data,
						metadata,
					),
				errorCallback ?? emptyErrorHandler,
				opts,
			);
		}
		return addDocListener<T>(
			{ docRef, dbRefs },
			(data, metadata) => callback(!!dataUpdater && !!data ? dataUpdater(data) : data, metadata),
			errorCallback,
		);
	}
	return listen;
};

export interface QueryListener<T extends object> {
	(
		callback: (
			data: T[],
			snapshot: QuerySnapshot<T>,
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback?: (error: Error) => void,
	): () => void;
	(
		callback: (
			data: { docId: string; data: T }[],
			snapshot: QuerySnapshot<T>,
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback: (error: Error) => void,
		opts: { withDocId: true },
	): () => void;
}

const createQueryListener = <T extends object>(
	queryRef: Query<T> | FirebaseFirestore.Query<T>,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
): QueryListener<T> => {
	// This fuction overload with different callback arguments for some reason are not working correctly with TS
	// The implementation still returns correct results that comply with the overload, so using ts-ignore-error to suppress the TS error
	// @ts-ignore-error
	function listen(
		callback: (
			data: T[],
			snapshot: QuerySnapshot<T>,
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback?: (error: Error) => void,
	): () => void;
	function listen(
		callback: (
			data: { docId: string; data: T }[],
			snapshot: QuerySnapshot<T>,
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback: (error: Error) => void,
		opts: { withDocId: true },
	): () => void;
	function listen(
		callback: (
			data: T[] | { docId: string; data: T }[],
			snapshot: QuerySnapshot<T>,
			metadata: SnapshotMetadata | undefined,
		) => void,
		errorCallback?: (error: Error) => void,
		opts?: { withDocId: true },
	): () => void {
		if (!!opts) {
			const emptyErrorHandler = () => undefined;
			return addQueryListener<T>(
				{ queryRef, dbRefs },
				(_data, snapshot, metadata) => {
					const data =
						_data.length && !!dataUpdater
							? _data.map((d) => ({ docId: d.docId, data: dataUpdater(d.data) }))
							: _data;
					callback(data, snapshot, metadata);
				},
				errorCallback ?? emptyErrorHandler,
				opts,
			);
		}
		return addQueryListener<T>(
			{ queryRef, dbRefs },
			(data, snapshot, metadata) =>
				callback(!!dataUpdater ? data.map((d) => dataUpdater(d)) : data, snapshot, metadata),
			errorCallback,
		);
	}
	return listen;
};

interface CreateDelete {
	(): Promise<void>;
}

const createDelete = <T extends object>(
	id: string,
	collectionPath: string,
	dbRefs: DbRefs,
): CreateDelete => {
	function deleteFn() {
		const ref = getDocRef<T>(dbRefs, collectionPath, id);
		return deleteDoc({ docRef: ref, dbRefs });
	}
	return deleteFn;
};

export function buildQuery<T extends object>(
	collectionPath: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
) {
	if (isClientDbRefs(dbRefs)) {
		const ref = getCollectionRef<T>(dbRefs, collectionPath);
		return queryBuilder<T>({ queryRef: ref, dbRefs }, dataUpdater);
	} else {
		const ref = getCollectionRef<T>(dbRefs, collectionPath);
		return queryBuilder<T>({ queryRef: ref, dbRefs }, dataUpdater);
	}
}

export function buildCollectionGroupQuery<T extends object>(
	collectionName: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
) {
	if (isClientDbRefs(dbRefs)) {
		const ref = getCollectionGroupRef<T>(dbRefs, collectionName);
		return queryBuilder<T>({ queryRef: ref, dbRefs }, dataUpdater);
	} else {
		const ref = getCollectionGroupRef<T>(dbRefs, collectionName);
		return queryBuilder<T>({ queryRef: ref, dbRefs }, dataUpdater);
	}
}

type FirestoreArrayWhereOperation = 'array-contains';
type FirestoreInWhereOperation = 'in' | 'not-in';

type FirestoreArrayWhereOperationResult<T extends object, Field extends DotNotation<T>> = Exclude<
	DotValue<T, Field>,
	undefined
> extends (infer R)[]
	? R
	: DotValue<T, Field>;
type FirestoreInWhereOperationResult<T extends object, Field extends DotNotation<T>> = Exclude<
	DotValue<T, Field>,
	undefined
>[];
type FirestoreNormalWhereOperationResult<T extends object, Field extends DotNotation<T>> = Exclude<
	DotValue<T, Field>,
	undefined
>;

export type WhereFilterResult<
	T extends object,
	Field extends DotNotation<T>,
	Op extends WhereFilterOp
> = Op extends FirestoreArrayWhereOperation
	? FirestoreArrayWhereOperationResult<T, Field>
	: Op extends FirestoreInWhereOperation
	? FirestoreInWhereOperationResult<T, Field>
	: FirestoreNormalWhereOperationResult<T, Field>;

export interface QueryBuilder<T extends object> {
	where: <Field extends DotNotation<T>, Op extends WhereFilterOp>(
		field: Field,
		compare: Op,
		value: WhereFilterResult<T, Field, Op>,
	) => QueryBuilder<T>;
	whereArray: <Field extends DotNotation<T>>(
		field: Field,
		compare: FirestoreArrayWhereOperation,
		value: FirestoreArrayWhereOperationResult<T, Field>,
	) => QueryBuilder<T>;
	whereIn: <Field extends DotNotation<T>>(
		field: Field,
		compare: FirestoreInWhereOperation,
		value: FirestoreInWhereOperationResult<T, Field>,
	) => QueryBuilder<T>;
	orderBy: <Field extends DotNotation<T>>(
		field: Field,
		directionStr?: OrderByDirection,
	) => QueryBuilder<T>;
	limit: (...args: Parameters<typeof limit>) => QueryBuilder<T>;
	limitToLast: (...args: Parameters<typeof limitToLast>) => QueryBuilder<T>;
	select: <Field extends DotNotation<T>>(
		field: Field | Field[],
	) => QueryBuilder<DotNotationToObject<T, TypeToArray<typeof field>[number]>>;
	startAt: (...args: Parameters<typeof startAt>) => QueryBuilder<T>;
	startAfter: (...args: Parameters<typeof startAfter>) => QueryBuilder<T>;
	endBefore: (...args: Parameters<typeof endBefore>) => QueryBuilder<T>;
	endAt: (...args: Parameters<typeof endAt>) => QueryBuilder<T>;
	get: QueryGetter<T>;
	onSnapshot: QueryListener<T>;
	/**
	 * Retrieves the count of items matching the query
	 * **NOTE: This currently only works on the server side! On the client it will throw an error.**
	 * @deprecated Not actually deprecated, but marked such for visibility until it is implemented on the client side.
	 */
	count: () => Promise<number>;
}

const queryBuilder = <T extends object>(
	refs: QueryBuilderRefs<T>,
	dataUpdater?: (data: any) => T,
): QueryBuilder<T> => ({
	where: <Field extends DotNotation<T>, Op extends WhereFilterOp>(
		field: Field,
		compare: Op,
		value: WhereFilterResult<T, Field, Op>,
	) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, where } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, where(field, compare, value));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.where(field, compare, value);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	whereArray: <Field extends DotNotation<T>>(
		field: Field,
		compare: FirestoreArrayWhereOperation,
		value: FirestoreArrayWhereOperationResult<T, Field>,
	) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, where } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, where(field, compare, value));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.where(field, compare, value);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	whereIn: <Field extends DotNotation<T>>(
		field: Field,
		compare: FirestoreInWhereOperation,
		value: FirestoreInWhereOperationResult<T, Field>,
	) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, where } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, where(field, compare, value));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.where(field, compare, value);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	orderBy: <Field extends DotNotation<T>>(field: Field, directionStr?: OrderByDirection) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, orderBy } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, orderBy(field, directionStr));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.orderBy(field, directionStr);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	limit: (...args: Parameters<typeof limit>) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, limit } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, limit(...args));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.limit(...args);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	limitToLast: (...args: Parameters<typeof limitToLast>) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, limitToLast } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, limitToLast(...args));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.limitToLast(...args);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	select: <Field extends DotNotation<T>>(field: Field | Field[]) => {
		if (isClientQueryRefs(refs)) {
			throw new Error('.select is not available in client queries');
		} else {
			const { queryRef, dbRefs } = refs;
			const fieldPaths = Array.isArray(field) ? field : [field];
			const newRef = queryRef.select(...fieldPaths) as FirebaseFirestore.Query<
				DotNotationToObject<T, TypeToArray<typeof field>[number]>
			>;
			return queryBuilder(
				{ queryRef: newRef, dbRefs },
				// dataUpdater,
				undefined,
			);
		}
	},
	startAt: (...args: Parameters<typeof startAt>) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, startAt } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, startAt(...args));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.startAt(...args);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	startAfter: (...args: Parameters<typeof startAfter>) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, startAfter } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, startAfter(...args));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.startAfter(...args);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	endBefore: (...args: Parameters<typeof endBefore>) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, endBefore } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, endBefore(...args));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.endBefore(...args);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	endAt: (...args: Parameters<typeof endAt>) => {
		if (isClientQueryRefs(refs)) {
			const { queryRef, dbRefs } = refs;
			const { query, endAt } = dbRefs.firestoreMethods;
			const newRef = query(queryRef, endAt(...args));
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		} else {
			const { queryRef, dbRefs } = refs;
			const newRef = queryRef.endAt(...args);
			return queryBuilder({ queryRef: newRef, dbRefs }, dataUpdater);
		}
	},
	get: createQueryGetter(refs.queryRef, refs.dbRefs, dataUpdater),
	onSnapshot: createQueryListener(refs.queryRef, refs.dbRefs, dataUpdater),
	count: createCountGetter(refs.queryRef),
});

export interface DocOperations<T extends object> {
	set: CreateSetter<T>;
	update: CreateUpdater<T>;
	get: CreateDocGetter<T>;
	listen: CreateDocListener<T>;
	delete: CreateDelete;
}

export const createBasicDocOperations = <T extends object>(
	collectionPath: string,
	dbRefs: DbRefs,
	id: string,
	dataUpdater?: (data: any) => T,
): DocOperations<T> => {
	return {
		set: createSetter<T>(id, collectionPath, dbRefs),
		update: createUpdater<T>(id, collectionPath, dbRefs),
		get: createDocGetter<T>(id, collectionPath, dbRefs, dataUpdater),
		listen: createDocListener<T>(id, collectionPath, dbRefs, dataUpdater),
		delete: createDelete(id, collectionPath, dbRefs),
	};
};

export type CollectionListenerFunction<T extends object> = (
	callback: (data: T[]) => void,
	errorCallback?: (err: Error) => void,
) => () => void;

export type CollectionOperations<T extends object> = {
	get: QueryBuilder<T>;
	getAll: () => Promise<T[]>;
	listenAll: CollectionListenerFunction<T>;
	getMany: GetManyGetter<T>;
	count: () => Promise<number>;
	dataUpdater: ((data: any) => T) | undefined;
};

export const createBasicCollectionOperations = <T extends object>(
	collectionPath: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
): CollectionOperations<T> => {
	const query = buildQuery<T>(collectionPath, dbRefs, dataUpdater);
	return {
		get: query,
		getAll: () => query.get(),
		listenAll: (callback: (data: T[]) => void, errorCallback?: (error: Error) => void) =>
			buildQuery<T>(collectionPath, dbRefs, dataUpdater).onSnapshot(callback, errorCallback),
		getMany: createManyGetter<T>(collectionPath, dbRefs, dataUpdater),
		count: () => query.count(),
		dataUpdater: dataUpdater ?? undefined,
	};
};

export const createCollectionGroupOperations = <T extends object>(
	collectionName: string,
	dbRefs: DbRefs,
) => {
	const query = buildCollectionGroupQuery<T>(collectionName, dbRefs);
	return {
		get: query,
		getAll: () => query.get(),
		listenAll: (callback: (data: T[]) => void, errorCallback?: (error: Error) => void) =>
			query.onSnapshot(callback, errorCallback),
	};
};

export const createBasicDbOperations = <T extends object>(
	collectionPath: string,
	dbRefs: DbRefs,
	dataUpdater?: (data: any) => T,
) => {
	return {
		doc: (id: string) => createBasicDocOperations(collectionPath, dbRefs, id, dataUpdater),
		...createBasicCollectionOperations<T>(collectionPath, dbRefs, dataUpdater),
	};
};

function getDocRef<T extends object>(
	dbRefs: ClientDbRefs,
	collectionPath: string,
	docId: string,
): DocumentReference<T>;
function getDocRef<T extends object>(
	dbRefs: AdminDbRefs,
	collectionPath: string,
	docId: string,
): FirebaseFirestore.DocumentReference<T>;
function getDocRef<T extends object>(
	dbRefs: DbRefs,
	collectionPath: string,
	docId: string,
): FirebaseFirestore.DocumentReference<T> | DocumentReference<T>;
function getDocRef<T extends object>(
	dbRefs: DbRefs,
	collectionPath: string,
	docId: string,
): FirebaseFirestore.DocumentReference<T> | DocumentReference<T> {
	if (isClientDbRefs(dbRefs)) {
		return dbRefs.firestoreMethods.doc(collectionPath, docId) as DocumentReference<T>;
	}
	return dbRefs.db.collection(collectionPath).doc(docId) as FirebaseFirestore.DocumentReference<T>;
}

function getCollectionRef<T extends object>(dbRefs: ClientDbRefs, collectionPath: string): Query<T>;
function getCollectionRef<T extends object>(
	dbRefs: AdminDbRefs,
	collectionPath: string,
): FirebaseFirestore.CollectionReference<T>;
function getCollectionRef<T extends object>(
	dbRefs: DbRefs,
	collectionPath: string,
): Query<T> | FirebaseFirestore.CollectionReference<T> {
	if (isClientDbRefs(dbRefs)) {
		return dbRefs.firestoreMethods.collection(collectionPath) as Query<T>;
	}
	return dbRefs.db.collection(collectionPath) as FirebaseFirestore.CollectionReference<T>;
}

function getCollectionGroupRef<T extends object>(
	dbRefs: ClientDbRefs,
	collectionPath: string,
): Query<T>;
function getCollectionGroupRef<T extends object>(
	dbRefs: AdminDbRefs,
	collectionPath: string,
): FirebaseFirestore.CollectionGroup<T>;
function getCollectionGroupRef<T extends object>(
	dbRefs: DbRefs,
	collectionName: string,
): FirebaseFirestore.CollectionGroup<T> | Query<T> {
	if (isClientDbRefs(dbRefs)) {
		return dbRefs.firestoreMethods.collectionGroup(collectionName) as Query<T>;
	}
	return dbRefs.db.collectionGroup(collectionName) as FirebaseFirestore.CollectionGroup<T>;
}
