import { getOpenApiExample } from 'common/utils/openApi';

import { zCancellationObjectApi, zCancellationPolicyApi } from './api';
import { toApi, toDb } from './mapper';

export * from './types';

export const CancellationPolicy = {
	toApi,
	toDb,
	api: zCancellationPolicyApi,
	example: [getOpenApiExample(zCancellationObjectApi)!],
};
