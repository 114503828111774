import moment from 'moment-timezone';

import {
	addActiveBookedOrdersInfoListener,
	addActiveOrdersInfoListener,
	addCompletedOrdersInfoListener,
	addExpiredBookedOrdersInfoListener,
	addPendingOrdersInfoListener,
	allOutgoingProductsListener,
	expiredIncomingListener,
	expiringIncomingListener,
} from 'common/api/frontend';
import { OrderInfo, OrderProduct, PurchaseTypes, RemoteData } from 'common/types';
import { arrayIncludesSome } from 'common/utils/arrays';
import { ThunkResult } from 'services/types';

export type StoreRentalsAction =
	| UpdateActiveBookedStoreRentals
	| UpdateExpiredBookedStoreRentals
	| UpdatePendingStoreRentals
	| UpdateActiveStoreRentals
	| UpdateCompletedStoreRentals
	| UpdateOutgoingProducts
	| UpdateExpiringIncomingProducts
	| UpdateExpiredIncomingProducts
	| AddAllOutgoingListenerStarted
	| AddExpiringIncomingListenerStarted
	| AddExpiredIncomingListenerStarted;

export interface UpdateActiveBookedStoreRentals {
	type: 'UPDATE_ACTIVE_BOOKED_STORE_RENTALS';
	rentalList: RemoteData<OrderInfo[]>;
}

const updateActiveBookedStoreRentals = (
	rentalList: RemoteData<OrderInfo[]>,
): UpdateActiveBookedStoreRentals => {
	return {
		type: 'UPDATE_ACTIVE_BOOKED_STORE_RENTALS',
		rentalList,
	};
};

export interface UpdatePendingStoreRentals {
	type: 'UPDATE_PENDING_STORE_RENTALS';
	rentalList: RemoteData<OrderInfo[]>;
}

const updatePendingStoreRentals = (
	rentalList: RemoteData<OrderInfo[]>,
): UpdatePendingStoreRentals => {
	return {
		type: 'UPDATE_PENDING_STORE_RENTALS',
		rentalList,
	};
};

export interface UpdateExpiredBookedStoreRentals {
	type: 'UPDATE_EXPIRED_BOOKED_STORE_RENTALS';
	rentalList: RemoteData<OrderInfo[]>;
}

const updateExpiredBookedStoreRentals = (
	rentalList: RemoteData<OrderInfo[]>,
): UpdateExpiredBookedStoreRentals => {
	return {
		type: 'UPDATE_EXPIRED_BOOKED_STORE_RENTALS',
		rentalList,
	};
};

export interface UpdateActiveStoreRentals {
	type: 'UPDATE_ACTIVE_STORE_RENTALS';
	rentalList: RemoteData<OrderInfo[]>;
}

const updateActiveStoreRentals = (
	rentalList: RemoteData<OrderInfo[]>,
): UpdateActiveStoreRentals => {
	return {
		type: 'UPDATE_ACTIVE_STORE_RENTALS',
		rentalList,
	};
};

export interface UpdateCompletedStoreRentals {
	type: 'UPDATE_COMPLETED_STORE_RENTALS';
	rentalList: RemoteData<OrderInfo[]>;
}

export const updateCompletedStoreRentals = (
	rentalList: RemoteData<OrderInfo[]>,
): UpdateCompletedStoreRentals => {
	return {
		type: 'UPDATE_COMPLETED_STORE_RENTALS',
		rentalList,
	};
};

interface UpdateOutgoingProducts {
	type: 'UPDATE_OUTGOING_PRODUCTS';
	products: RemoteData<OrderProduct[]>;
}

export const updateOutgoingProducts = (
	products: RemoteData<OrderProduct[]>,
): UpdateOutgoingProducts => {
	return {
		type: 'UPDATE_OUTGOING_PRODUCTS',
		products,
	};
};

interface UpdateExpiringIncomingProducts {
	type: 'UPDATE_EXPIRING_INCOMING_PRODUCTS';
	products: RemoteData<OrderProduct[]>;
}

export const updateExpiringIncomingProducts = (
	products: RemoteData<OrderProduct[]>,
): UpdateExpiringIncomingProducts => {
	return {
		type: 'UPDATE_EXPIRING_INCOMING_PRODUCTS',
		products,
	};
};

interface UpdateExpiredIncomingProducts {
	type: 'UPDATE_EXPIRED_INCOMING_PRODUCTS';
	products: RemoteData<OrderProduct[]>;
}

export const updateExpiredIncomingProducts = (
	products: RemoteData<OrderProduct[]>,
): UpdateExpiredIncomingProducts => {
	return {
		type: 'UPDATE_EXPIRED_INCOMING_PRODUCTS',
		products,
	};
};

interface AddAllOutgoingListenerStarted {
	type: 'ADD_ALL_OUTGOING_LISTENER_STARTED';
}

export const addAllOutgoingListenerStarted = (): AddAllOutgoingListenerStarted => {
	return {
		type: 'ADD_ALL_OUTGOING_LISTENER_STARTED',
	};
};

interface AddExpiringIncomingListenerStarted {
	type: 'ADD_EXPIRING_INCOMING_LISTENER_STARTED';
}

export const addExpiringIncomingListenerStarted = (): AddExpiringIncomingListenerStarted => {
	return {
		type: 'ADD_EXPIRING_INCOMING_LISTENER_STARTED',
	};
};

interface AddExpiredIncomingListenerStarted {
	type: 'ADD_EXPIRED_INCOMING_LISTENER_STARTED';
}

export const addExpiredIncomingListenerStarted = (): AddExpiredIncomingListenerStarted => {
	return {
		type: 'ADD_EXPIRED_INCOMING_LISTENER_STARTED',
	};
};

export const getStoreRentals = (shopId: string): ThunkResult => {
	return (dispatch) => {
		dispatch(addActiveBookedStoreRentalsListener(shopId));
		dispatch(addActiveStoreRentalsListener(shopId));
		dispatch(addPendingOrdersListener(shopId));
	};
};

const addActiveBookedStoreRentalsListener = (shopId: string): ThunkResult => {
	return (dispatch) => {
		addActiveBookedOrdersInfoListener(
			shopId,
			(orders) => {
				const rentalOrders = orders.filter(
					(o) =>
						!!o.services?.delivery ||
						arrayIncludesSome(o.purchaseTypes, [PurchaseTypes.rental, PurchaseTypes.subscription]),
				);
				const rentals: RemoteData<OrderInfo[]> = { kind: 'FETCHED', data: rentalOrders };
				dispatch(updateActiveBookedStoreRentals(rentals));
			},
			(error) => {
				const rentals: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updateActiveBookedStoreRentals(rentals));
			},
		);
	};
};

export const addPendingOrdersListener = (shopId: string): ThunkResult => {
	return (dispatch) => {
		addPendingOrdersInfoListener(
			shopId,
			(orders) => {
				const pendingOrders: RemoteData<OrderInfo[]> = { kind: 'FETCHED', data: orders };
				dispatch(updatePendingStoreRentals(pendingOrders));
			},
			(error) => {
				const data: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updatePendingStoreRentals(data));
			},
		);
	};
};

export const addExpiredBookedStoreRentalsListener = (
	shopId: string,
	limit?: number,
): ThunkResult => {
	return (dispatch) => {
		addExpiredBookedOrdersInfoListener(
			shopId,
			(orders) => {
				const rentals: RemoteData<OrderInfo[]> = { kind: 'FETCHED', data: orders };
				dispatch(updateExpiredBookedStoreRentals(rentals));
			},
			(error) => {
				const rentals: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting expired rentals',
				};
				dispatch(updateExpiredBookedStoreRentals(rentals));
			},
			limit,
		);
	};
};

const addActiveStoreRentalsListener = (shopId: string): ThunkResult => {
	return (dispatch) => {
		addActiveOrdersInfoListener(
			shopId,
			(orders) => {
				const rentals: RemoteData<OrderInfo[]> = { kind: 'FETCHED', data: orders };
				dispatch(updateActiveStoreRentals(rentals));
			},
			(error) => {
				const rentals: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updateActiveStoreRentals(rentals));
			},
		);
	};
};

export const addCompletedStoreRentalsListener = (
	shopId: string,
	limit?: number,
	locationId?: string,
): ThunkResult => {
	return (dispatch) => {
		addCompletedOrdersInfoListener(
			{ shopId, locationId },
			(orders) => {
				const rentals: RemoteData<OrderInfo[]> = { kind: 'FETCHED', data: orders };
				dispatch(updateCompletedStoreRentals(rentals));
			},
			(error) => {
				const rentals: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updateCompletedStoreRentals(rentals));
			},
			limit,
		);
	};
};

export const addAllOutgoingListener = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
): ThunkResult => {
	return (dispatch) => {
		dispatch(addAllOutgoingListenerStarted());
		allOutgoingProductsListener(
			shopId,
			startDate,
			endDate,
			(orders) => {
				const products: RemoteData<OrderProduct[]> = { kind: 'FETCHED', data: orders };
				dispatch(updateOutgoingProducts(products));
			},
			(error) => {
				const products: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updateOutgoingProducts(products));
			},
		);
	};
};

export const addExpiringIncomingListener = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
): ThunkResult => {
	return (dispatch) => {
		dispatch(addExpiringIncomingListenerStarted());
		expiringIncomingListener(
			shopId,
			startDate,
			endDate,
			(orders) => {
				const products: RemoteData<OrderProduct[]> = {
					kind: 'FETCHED',
					data: orders.filter((product) => !product.endDateReturned),
				};
				dispatch(updateExpiringIncomingProducts(products));
			},
			(error) => {
				const products: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updateExpiringIncomingProducts(products));
			},
		);
	};
};

export const addExpiredIncomingListener = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
): ThunkResult => {
	return (dispatch) => {
		dispatch(addExpiredIncomingListenerStarted());
		expiredIncomingListener(
			shopId,
			startDate,
			endDate,
			(orders) => {
				const products: RemoteData<OrderProduct[]> = { kind: 'FETCHED', data: orders };
				dispatch(updateExpiredIncomingProducts(products));
			},
			(error) => {
				const products: RemoteData<OrderInfo[]> = {
					kind: 'ERROR',
					error: error.message || 'Error getting active rentals',
				};
				dispatch(updateExpiredIncomingProducts(products));
			},
		);
	};
};

export const addDashboardListeners = (
	shopId: string,
	startDate: moment.Moment,
	endDate: moment.Moment,
): ThunkResult => {
	return (dispatch) => {
		dispatch(addExpiredIncomingListener(shopId, startDate, endDate));
		dispatch(addExpiringIncomingListener(shopId, startDate, endDate));
		dispatch(addAllOutgoingListener(shopId, startDate, endDate));
	};
};
