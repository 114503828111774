import { TFunction } from 'i18next';

import { ProductSubscription } from 'common/modules/subscriptions/types';
import { CurrencyObject, DurationWithPrice } from 'common/types';
import { getPricingString } from 'common/utils/common';

import { OrderProductSubscription } from '../types';
import {
	getSubscriptionPeriodAsMonths,
	isAutoRenewProductSubscription,
	isAutoRenewSubscription,
} from './subscriptions';

export const getSubscriptionDurationString = (
	subscription: OrderProductSubscription,
	t: TFunction,
	format: 'long' | 'short' = 'long',
) => {
	const cycleMonths = getSubscriptionPeriodAsMonths(subscription.cycle);
	const hasAutoRenew = isAutoRenewSubscription(subscription);
	const committedMonths = cycleMonths * subscription.cycles.committed;
	switch (subscription.cycle.unit) {
		case 'months': {
			const value = committedMonths;
			const unit =
				format === 'long'
					? value === 1
						? t('common:times.month', 'month')
						: t('common:times.months', 'months')
					: t('common:times.monthsShort', 'mo') +
					  ` - (${t('common:times.monthlyPayment', 'Monthly payment')})`;

			return `${value}${hasAutoRenew ? '+' : ''} ${unit}`;
		}
		case 'years': {
			const value = Math.floor(committedMonths / 12);
			const unit =
				format === 'long'
					? value === 1
						? t('common:times.year', 'year')
						: t('common:times.years', 'years')
					: t('common:times.yearsShort', 'y') +
					  ` - (${t('common:times.monthlyPayment', 'Monthly payment')})`;

			return `${value}${hasAutoRenew ? '+' : ''} ${unit}`;
		}
	}
};

export const getSubscriptionDurationStringFromProductSubscription = (
	subscription: ProductSubscription,
	t: TFunction,
) => {
	const committedMonths = getSubscriptionPeriodAsMonths(subscription.minCommitment);

	switch (subscription.minCommitment.unit) {
		case 'months': {
			return committedMonths !== 1
				? `${committedMonths} ${t('common:times.months', 'months')}`
				: `${committedMonths} ${t('common:times.month', 'month')}`;
		}
		case 'years': {
			const value = Math.floor(committedMonths / 12);
			return value !== 1
				? `${value} ${t('common:times.years', 'years')}`
				: `${value} ${t('common:times.year', 'year')}`;
		}
	}
};

export const getSubscriptionPricingDescriptionString = (
	args: {
		quantity: number;
		charge: number;
		duration: DurationWithPrice | null;
		currency: CurrencyObject;
		subscription: ProductSubscription;
		durationLabel: string;
	},
	t: TFunction,
) => {
	const { subscription, charge, quantity, currency, durationLabel } = args;
	const autoRenewalCancelText = `, ${t('common:cancelAfterAnytime', 'afterwards cancel anytime')}.`;

	const description = `${t('common:booking.subscriptionSecondary', {
		price: getPricingString(charge * quantity, currency),
		duration: durationLabel,
		defaultValue: '{{price}} per month for {{duration}}',
	})}`;

	return isAutoRenewProductSubscription(subscription)
		? description.concat(autoRenewalCancelText)
		: description;
};
