import moment from 'moment-timezone';

import { api } from 'common/frontend/api';
import { BatchManager, WriteBatch } from 'common/frontend/firebase/firestore';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	ActiveState,
	HistoryObject,
	OrderInfo,
	OrderObject,
	OrderPaymentRequest,
	OrderProduct,
	ProductTypes,
	RentalState,
	Shopper,
} from 'common/types';
import { newFirestoreId } from 'common/utils/dataMigrations';
import { createCompleteOrderObject } from 'common/utils/newRentalUtils';

import { getOrderDelivery, removeOrderDeliveryListener } from './orderDeliveries';
import { getOrderProducts, removeOrderProductsListener } from './orderProducts';
import { getOrderShoppers, removeOrderShoppersListener } from './orderShoppers';
import {
	stopActiveRentalsListener,
	stopBookedRentalsListener,
	stopCompletedRentalsListener,
} from './rentals';

export let removeActiveOrdersInfoListener: () => void = () => undefined;
export let removeActiveBookedOrdersInfoListener: () => void = () => undefined;
export let removeExpiredBookedOrdersInfoListener: () => void = () => undefined;
export let removeCompletedOrdersInfoListener: () => void = () => undefined;
export let removePendingOrdersInfoListener: () => void = () => undefined;

export let removeOrderInfoListener: () => void = () => undefined;
export let removePurchasedOrdersInfoListener: () => void = () => undefined;

export const addOrderInfoListener = (
	rentalId: string,
	callback: (order: OrderInfo | undefined) => void,
) => {
	removeOrderInfoListener = api()
		.orders.doc(rentalId)
		.listen((order) => {
			callback(order);
		});
};

export const removeSelectedOrderListeners = () => {
	removeOrderInfoListener();
	removeOrderProductsListener();
	removeOrderShoppersListener();
	removeOrderDeliveryListener();
};

export const getOrder = async (orderId: string): Promise<OrderInfo | undefined> => {
	return api().orders.doc(orderId).get();
};

export const setNewOrder = (order: OrderObject) => {
	const updatedOrder = createCompleteOrderObject(order);
	const { rentalInfo, shoppers, products } = updatedOrder;
	const batchManager = new BatchManager();
	for (const shopper of shoppers) {
		api(batchManager.batch()).orderShoppers.doc(shopper.id).set(shopper);
	}
	for (const product of products) {
		api(batchManager.batch()).orderProducts.doc(product.id).set(product);
	}
	api(batchManager.batch()).orders.doc(rentalInfo.id).set(rentalInfo, { merge: true });
	return batchManager.commit();
};

export const setOrderHistory = (orderId: string, history: HistoryObject): Promise<void> => {
	if (!history.id) {
		const id = newFirestoreId();
		history = {
			...history,
			id,
		};
	}
	return api().orders.doc(orderId).orderHistory.doc(history.id).set(history);
};

export const addPurchasedOrdersInfoListener = (
	shopId: string,
	callback: (orders: OrderInfo[]) => void,
	errorCallback?: (error: Error) => void,
	limit: number = 50,
) => {
	const purchasedOrdersRef = api()
		.orders.get.where('shopId', '==', shopId)
		.where('includedProductTypes', 'array-contains-any', [ProductTypes.ACCESS])
		.orderBy('startDate', 'desc')
		.limit(limit);
	removePurchasedOrdersInfoListener = purchasedOrdersRef.onSnapshot((orders) => {
		callback(orders);
	}, errorCallback || undefined);
};

export const addActiveOrdersInfoListener = (
	shopId: string,
	callback: (orders: OrderInfo[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const activeOrdersInfoRef = api()
		.orders.get.where('shopId', '==', shopId)
		.where('rentalState', '==', 'ACTIVE')
		.where('includedProductTypes', 'array-contains-any', [
			ProductTypes.RENTAL,
			ProductTypes.SERVICE,
		])
		.orderBy('returnTimeNext', 'asc');
	removeActiveOrdersInfoListener = activeOrdersInfoRef.onSnapshot((orders) => {
		callback(orders);
	}, errorCallback || undefined);
};

export const addActiveBookedOrdersInfoListener = (
	shopId: string,
	callback: (orders: OrderInfo[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const activeBookedOrdersRef = api()
		.orders.get.where('shopId', '==', shopId)
		.where('rentalState', '==', 'BOOKED')
		.where('includedProductTypes', 'array-contains-any', [
			ProductTypes.RENTAL,
			ProductTypes.SERVICE,
		])
		.where('startDate', '>=', moment().startOf('day').toISOString())
		.orderBy('startDate', 'asc');
	removeActiveBookedOrdersInfoListener = activeBookedOrdersRef.onSnapshot((orders) => {
		callback(orders);
	}, errorCallback || undefined);
};

export const addPendingOrdersInfoListener = (
	shopId: string,
	callback: (orders: OrderInfo[]) => void,
	errorCallback?: (error: Error) => void,
) => {
	const pendingOrdersRef = api()
		.orders.get.where('shopId', '==', shopId)
		.where('rentalState', '==', 'BOOKED')
		.where('purchaseType', '==', 'sales');

	removePendingOrdersInfoListener = pendingOrdersRef.onSnapshot((orders) => {
		callback(orders);
	}, errorCallback);
};

export const addExpiredBookedOrdersInfoListener = (
	shopId: string,
	callback: (orders: OrderInfo[]) => void,
	errorCallback?: (error: Error) => void,
	limit?: number,
) => {
	let expiredBookedOrdersRef = api()
		.orders.get.where('shopId', '==', shopId)
		.where('rentalState', '==', 'BOOKED')
		.where('includedProductTypes', 'array-contains-any', [
			ProductTypes.RENTAL,
			ProductTypes.SERVICE,
		])
		.where('startDate', '<', moment().startOf('day').toISOString())
		.orderBy('startDate', 'desc');
	if (limit) {
		expiredBookedOrdersRef = expiredBookedOrdersRef.limit(limit);
	}
	removeExpiredBookedOrdersInfoListener = expiredBookedOrdersRef.onSnapshot((orders) => {
		callback(orders);
	}, errorCallback || undefined);
};

export const updatePartialRentalInfoToDb = (
	batch: WriteBatch | null,
	rentalId: string,
	partialRentalInfoObject: Partial<OrderInfo>,
) => {
	const apiRef = api(batch ?? undefined);
	return apiRef.orders.doc(rentalId).update({
		...partialRentalInfoObject,
	});
};

export const addCompletedOrdersInfoListener = (
	args: { shopId: string; locationId?: string },
	callback: (orders: OrderInfo[]) => void,
	errorCallback?: (error: Error) => void,
	limit?: number,
) => {
	const { shopId, locationId } = args;
	if (!!removeCompletedOrdersInfoListener) {
		removeCompletedOrdersInfoListener();
	}
	let completedOrdersRef = api()
		.orders.get.where('shopId', '==', shopId)
		.where('rentalState', '==', 'COMPLETED');
	if (!!locationId) {
		completedOrdersRef = completedOrdersRef.where('endLocation.id', '==', locationId);
	}
	completedOrdersRef = completedOrdersRef
		.orderBy('endDateReturned', 'desc')
		.limit(!!limit ? limit : 50);
	removeCompletedOrdersInfoListener = completedOrdersRef.onSnapshot((orders) => {
		callback(orders);
	}, errorCallback || undefined);
};

export const removeRentalsListeners = () => {
	removeActiveOrdersInfoListener();
	removePendingOrdersInfoListener();
	removeActiveBookedOrdersInfoListener();
	removeExpiredBookedOrdersInfoListener();
	removeCompletedOrdersInfoListener();
	removePurchasedOrdersInfoListener();
	stopActiveRentalsListener();
	stopBookedRentalsListener();
	stopCompletedRentalsListener();
};

export const getOrderProductsAndShoppers = async (rentalInfo: OrderInfo) => {
	const productObjects: OrderProduct[] = await getOrderProducts(rentalInfo.id, rentalInfo.shopId);
	const shopperObjects: Shopper[] = await getOrderShoppers(rentalInfo.id, rentalInfo.shopId);
	return { productObjects, shopperObjects };
};

export const addOrderPaymentRequest = async (data: OrderPaymentRequest) => {
	const { orderId } = data;
	try {
		await api().orders.doc(orderId).orderPaymentRequests.doc(data.id).set(data);
	} catch (e) {
		errorHandler.report(e);
	}
};

export const getOrderObject = async (
	orderId: string,
	shopId: string,
): Promise<OrderObject | null> => {
	try {
		const [rentalInfo, shoppers, products, orderDelivery] = await Promise.all([
			getOrder(orderId),
			getOrderShoppers(orderId, shopId),
			getOrderProducts(orderId, shopId),
			getOrderDelivery(orderId, shopId),
		]);
		if (!rentalInfo) {
			return null;
		}
		return {
			rentalInfo,
			shoppers: shoppers,
			products: products,
			orderDelivery,
		};
	} catch (e) {
		return null;
	}
};

export const fetchActiveOrdersByProductCodes = async (
	productCodes: string[],
	shopId: string,
): Promise<OrderInfo[]> => {
	if (!productCodes.length) {
		return [];
	}
	const size = 10;
	const codesInChunks = [];
	for (let i = 0; i < productCodes.length; i += size) {
		codesInChunks.push(productCodes.slice(i, i + size));
	}
	const orderQuerys = await Promise.all(
		codesInChunks.map(async (codes) => {
			return await api()
				.orders.get.where('shopId', '==', shopId)
				.where('rentalProductCodes', 'array-contains-any', codes)
				.where('rentalState', '==', 'ACTIVE')
				.get();
		}),
	);
	const orderDocs = orderQuerys
		.flat()
		.filter((order, i, arr) => arr.map((mapObj) => mapObj.id).indexOf(order.id) === i);
	return orderDocs;
};

export interface ReturnProductsProps {
	orderId: string;
	shopId: string;
	productCodes: string[];
}

export interface UpdateOrderStateHistory {
	orderId: string;
	oldStates: {
		activeState: ActiveState;
		rentalState: RentalState;
	};
	newStates: {
		activeState: ActiveState;
		rentalState?: RentalState;
	};
}

export const updateOrderStateHistory = ({
	orderId,
	oldStates,
	newStates,
}: UpdateOrderStateHistory) => {
	const timestamp = moment().toISOString();
	const activeStateHistory: HistoryObject = {
		id: '',
		timestamp,
		eventType: 'ACTIVE_STATE',
		field: 'activeState',
		previousValue: oldStates.activeState,
		newValue: newStates.activeState,
	};
	setOrderHistory(orderId, activeStateHistory);
	if (newStates.rentalState) {
		const rentalStateHistory: HistoryObject = {
			id: '',
			timestamp,
			eventType: 'RENTAL_STATE',
			field: 'rentalState',
			previousValue: oldStates.rentalState,
			newValue: newStates.rentalState,
		};
		setOrderHistory(orderId, rentalStateHistory);
	}
};

export const fetchOrdersByConfirmationIdOrNames = async (
	shopId: string,
	confirmationIdOrName: string,
	callback: (orders: OrderInfo[]) => void,
	errorCallback: (error: Error) => void,
) => {
	const confirmationIdQuery = api()
		.orders.get.where('shopId', '==', shopId)
		.where('skidataProps.confirmationNumber', '==', confirmationIdOrName)
		.where('includedProductTypes', 'array-contains-any', [ProductTypes.ACCESS])
		.get();

	const shopperNameQuery = api()
		.orders.get.where('shopId', '==', shopId)
		.whereArray('shopperNameKeywords', 'array-contains', confirmationIdOrName.trim().toLowerCase())
		.get();

	try {
		const results = await Promise.all([confirmationIdQuery, shopperNameQuery]);
		const orders = results
			.flat()
			.filter((order) => (order.includedProductTypes ?? []).includes(ProductTypes.ACCESS));
		callback(orders);
	} catch (e) {
		errorCallback(e);
	}
};

export const isValidOrderId = async (orderId: string, shopId: string, orders: OrderInfo[]) => {
	if (orderId.length !== 20) {
		return false;
	}

	if (orders.find((order) => order.id === orderId)) {
		return true;
	}

	const apiOrders = await api()
		.orders.get.where('shopId', '==', shopId)
		.where('id', '==', orderId)
		.get();
	return !!apiOrders.length;
};

export const getOrderByOrderNumber = async (
	orderNumber: number,
	shopId: string,
	orders: OrderInfo[],
) => {
	const localOrder = orders.find((order) => order.orderNumber === orderNumber);
	if (!!localOrder) return localOrder;
	const apiOrders = await api()
		.orders.get.where('shopId', '==', shopId)
		.where('orderNumber', '==', orderNumber)
		.get();
	return apiOrders[0] ?? undefined;
};
