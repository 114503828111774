import { createReducer } from '@reduxjs/toolkit';

import { ById, Languages, Translatable, TranslatableField } from 'common/types';

import * as TranslationsActions from '../actions/TranslationsActions';

export interface TranslationsState {
	translations: {
		products: ById<Translatable>;
		categories: ById<Translatable>;
		stores: ById<Translatable>;
		pricingTables: ById<Translatable>;
		miscellaneous: ById<Translatable>;
		skidata: ById<Translatable>;
		manualPaymentMethods: ById<Translatable>;
		customizations: ById<Translatable>;
	};
	saveQueue: ById<{
		status: 'saving' | 'error';
		payload: SaveTranslationPayload;
	}>;
	language: Languages | null;
	defaultLanguage: Languages | null;
}

export type TranslationGroup = keyof TranslationsState['translations'];

export interface SaveTranslationPayload {
	group: TranslationGroup;
	docId: string;
	field: TranslatableField;
}

const INITIAL_STATE: TranslationsState = {
	translations: {
		products: {},
		categories: {},
		stores: {},
		pricingTables: {},
		miscellaneous: {},
		skidata: {},
		manualPaymentMethods: {},
		customizations: {},
	},
	saveQueue: {},
	language: null,
	defaultLanguage: null,
};

const ShopReducer = createReducer(INITIAL_STATE, (builder) => {
	/**
	 * Set active translation language
	 */
	builder
		.addCase(TranslationsActions.setLanguage, (state, action) => {
			state.language = action.payload;
		})
		.addCase(TranslationsActions.setDefaultLanguage, (state, action) => {
			state.defaultLanguage = action.payload;
		});

	/**
	 * Set all translations. Preserves any edits that have already been made
	 */
	builder.addCase(TranslationsActions.setTranslations, (state, action) => {
		state.translations = action.payload;
		Object.values(state.saveQueue).forEach(({ payload }) => {
			const { group, docId } = payload;
			const { id, value } = payload.field;
			const field = state.translations[group]?.[docId]?.fields?.[id];
			if (!!field) field.value = value;
		});
	});

	/**
	 * Save a committed field
	 */
	builder
		.addCase(TranslationsActions.saveTranslation.pending, (state, action) => {
			const { group, docId, field } = action.meta.arg;
			state.saveQueue[field.id] = {
				status: 'saving',
				payload: {
					group,
					docId,
					field,
				},
			};
		})
		.addCase(TranslationsActions.saveTranslation.fulfilled, (state, action) => {
			const { field } = action.meta.arg;
			delete state.saveQueue[field.id];
		})
		.addCase(TranslationsActions.saveTranslation.rejected, (state, action) => {
			const { group, docId, field } = action.meta.arg;
			state.saveQueue[field.id] = {
				status: 'error',
				payload: {
					group,
					docId,
					field,
				},
			};
		});
});

export default ShopReducer;
