import React from 'react';

import { useSelector } from 'react-redux';

import * as UserSelectors from 'selectors/UserSelectors';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { useTranslation } from 'services/localization';
import { Routes, push } from 'routing';

import Banner from './Banner';

const PaymentMethodBanner = () => {
	const { t } = useTranslation();
	const visible = useSelector(ViewSelectors.showPaymentCardBanner);
	const isAdmin = useSelector(UserSelectors.isAdmin);

	if (!visible) return null;

	return (
		<Banner
			variant="danger"
			title={t('paymentMethodBanner.title', 'Payment method missing')}
			description={t(
				'paymentMethodBanner.description',
				'Please add a payment method to pay your Rentle invoices with. If you do not add a payment method and we are unable to charge you for your usage, your account may be closed. If you have any questions, please contact our support.',
			)}
			button={
				isAdmin
					? {
							text: t('paymentMethodBanner.cta', 'Add payment method'),
							onClick: () => push(Routes.ACCOUNT_BILLING),
					  }
					: undefined
			}
		/>
	);
};

export default PaymentMethodBanner;
