import AuthRoute from 'components/AuthRoute';
import BarCodeReceiptScan from 'components/BarCodeReceiptScan';

import HomeView from './HomeView';

const Home = () => {
	return (
		<AuthRoute>
			<BarCodeReceiptScan>
				<HomeView />
			</BarCodeReceiptScan>
		</AuthRoute>
	);
};

export default Home;
