import { buildQuery, createBasicDbOperations } from '../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { AvailabilityByStartDay } from 'common/modules/availabilities/startTimeCounts';

export const getAvailabilityStartTimes = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.availabilityStartTimes;
	const query = buildQuery<AvailabilityByStartDay>(collectionPath, dbRefs);
	return createBasicDbOperations<AvailabilityByStartDay>(collectionPath, dbRefs);
};
