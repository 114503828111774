import React from 'react';

import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {}

const FlexBox: React.FC<React.PropsWithChildren<Props>> = ({ children, ...props }: Props) => (
	<Box display="flex" justifyContent="space-between" alignItems="center" {...props}>
		{children}
	</Box>
);

export default FlexBox;
