import { TFunction } from 'react-i18next';

import { switchUnreachable } from 'common/utils/common';
import { stringEnum } from 'common/utils/objects';

export const SIDEBAR_WIDTH = 200;
export const SIDEBAR_WIDTH_COLLAPSED = 56;

export const MODES = stringEnum(['rentals', 'resell']);

export type Mode = keyof typeof MODES;

export const getModeTranslation = (mode: Mode, t: TFunction) => {
	switch (mode) {
		case 'rentals':
			return t('modes.rentals', 'Rentals');
		case 'resell':
			return t('modes.resell', 'Resell');
		default:
			switchUnreachable(mode);
	}
	return '';
};
