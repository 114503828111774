export const createUniqueKey = (object: Object | null, key: string, count?: number): string => {
	if (key && (!object || !object.hasOwnProperty(key))) {
		return key;
	}
	let adding = count || 1;
	return createUniqueKey(object, key + adding, ++adding);
};

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
export const stringNormalize = (string: string) =>
	string
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');

export const sanitizeString = (str?: string | null) => str?.replace(/^(javascript\:)/, '');

export const isEqualCaseInsensitive = (a: string, b: string) => {
	const aLowercase = a.toLowerCase();
	const bLowercase = b.toLowerCase();
	return aLowercase === bLowercase;
};

export const jsonParseString = (string: string | undefined): Object | null => {
	const jsonRegex = /^[\s\{]*\{.+\}[\s\}]*$/;
	if (!!string && jsonRegex.test(string)) {
		try {
			return JSON.parse(string);
		} catch (e) {
			console.error('Error parsing JSON string', e);
		}
	}
	return null;
};
