import React, { useState } from 'react';

import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import BackdropDialog from 'common/components/BackdropDialog';
import { Callable } from 'common/frontend/callable';
import { AddOn, AddOnChannel, getAddOnDetails, isLegacyPlan } from 'common/modules/plans';
import * as NotificationActions from 'actions/NotificationActions';
import * as ShopActions from 'actions/ShopActions';
import * as ViewActions from 'actions/ViewActions';
import AddOnModalDetailsSection from 'components/modals/AddOnModal/AddOnModalDetailsSection';
import AddOnModalInstallSection from 'components/modals/AddOnModal/AddOnModalInstallSection';
import LegacyAddOnModalInstallSection from 'components/modals/AddOnModal/LEGACY_AddOnModalInstallSection';
import { useAnalytics } from 'hooks/useAnalytics';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { getAddOnSetupPage } from 'services/addOns';
import { useTranslation } from 'services/localization';
import { replace } from 'routing';

interface Props {
	addOn: AddOn;
	addOnChannel: AddOnChannel;
	onClose: () => void;
	dividerType?: 'background' | 'divider';
}
const AddOnModalContent = (props: Props) => {
	const { addOn, onClose, addOnChannel } = props;
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const shopPlan = useSelector(ShopSelectors.shopPlan);
	const shopId = useSelector(ShopSelectors.activeShopId)!;
	const showLegacyInstallSection = !shopPlan || isLegacyPlan(shopPlan);
	const addOnDetails = getAddOnDetails(addOn, t);
	const { logAnalyticsEvent } = useAnalytics();

	const [loading, setLoading] = useState<boolean>(false);
	const [uninstallDialogOpen, setUninstallDialogOpen] = useState<boolean>(false);

	const handleUninstall = async () => {
		try {
			await Callable.plans.addOns.disable({ shopId, addOns: [addOn] });
			onClose();
			logAnalyticsEvent({
				name: 'block_click',
				params: {
					type: 'dialog',
					data: { block: addOn, action: 'uninstall' },
				},
			});
			dispatch(
				NotificationActions.showNotification({
					message: t('account.addOns.installedAddOns.uninstalled2', 'The add-on was uninstalled'),
					variant: 'info',
				}),
			);
		} catch (err) {
			dispatch(
				NotificationActions.showNotification({
					message: t('common:errors.somethingWentWrongShort'),
					variant: 'error',
				}),
			);
		}
	};

	const handleInstall = async () => {
		setLoading(true);
		try {
			await Callable.plans.addOns.enable({ shopId, addOns: [addOn] });
			dispatch(ShopActions.addInstalledAddOn(addOn));
			logAnalyticsEvent({
				name: 'block_installed',
				params: { block: addOn, channel: addOnChannel },
				optionalServices: { gtm: true },
			});
			onClose();
			logAnalyticsEvent({
				name: 'block_click',
				params: {
					type: 'dialog',
					data: { block: addOn, action: 'install' },
				},
			});
			if (addOnChannel === 'block_store') {
				replace(getAddOnSetupPage(addOn));
			}
			dispatch(ViewActions.openInstalledAddOnModal(addOn));
		} catch (err) {
			dispatch(
				NotificationActions.showNotification({
					message: t('common:errors.somethingWentWrongShort'),
					variant: 'error',
				}),
			);
		}
		setLoading(false);
	};

	return (
		<>
			<Grid
				container
				sx={{
					width: '100%',
				}}
			>
				<Grid
					sx={{
						padding: theme.spacing(5.25, 5.25, !isMobile ? 5.25 : 0, 5.25),
						background: !isMobile
							? theme.palette.background.default
							: theme.palette.background.paper,
					}}
					item
					xs={!isMobile ? 7 : 12}
				>
					<AddOnModalDetailsSection addOn={addOn} />
				</Grid>

				{isMobile && (
					<Box sx={{ width: '100%' }} mx={4.5}>
						<Divider />
					</Box>
				)}

				<Grid
					sx={{
						padding: theme.spacing(5.25),
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
					}}
					item
					xs={!isMobile ? 5 : 12}
				>
					{showLegacyInstallSection ? (
						<LegacyAddOnModalInstallSection
							addOn={addOn}
							onClose={onClose}
							onInstall={handleInstall}
							onUninstall={() => setUninstallDialogOpen(true)}
							loading={loading}
						/>
					) : (
						<AddOnModalInstallSection
							addOn={addOn}
							onClose={onClose}
							onInstall={handleInstall}
							onUninstall={() => setUninstallDialogOpen(true)}
							loading={loading}
						/>
					)}
				</Grid>
			</Grid>
			<BackdropDialog
				isOpen={uninstallDialogOpen}
				onClose={() => setUninstallDialogOpen(false)}
				label={t('account.addOns.installedAddOns.dialogLabel', {
					addOn: addOnDetails.name,
					defaultValue: 'Uninstall {{addOn}}',
				})}
				body={
					<Box>
						<Typography variant="body2">{addOnDetails.uninstallNotice}</Typography>
						<Box mb={2} />
						<Typography variant="body2">
							{t(
								'account.addOns.installedAddOns.changesAppliedImmediately',
								'Changes are applied immediately. This cannot be undone.',
							)}
						</Typography>
					</Box>
				}
				variant="danger"
				width="xs"
				confirm={{
					label: t('account.addOns.installedAddOns.uninstall'),
					callback: handleUninstall,
					closeAfterDone: true,
				}}
				cancel={{
					label: t('common:actions.cancel'),
				}}
			/>
		</>
	);
};

export default AddOnModalContent;
