import { Stack, Typography, useTheme } from '@mui/material';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { useRegisterContext } from 'views/Register/RegisterContext';

import Spinner from 'common/components/Spinner';
import { RENTLE_SHOP_TERMS_VERSION } from 'common/constants/urls';
import { api } from 'common/frontend/api';
import { Callable } from 'common/frontend/callable';
import { runTransaction } from 'common/frontend/firebase/firestore';
import { RENTLE_FREE_LANGUAGES } from 'common/locales/utils/constants';
import {
	CountryCode,
	getDefaultCurrencyObjectForCountry,
	getInitialUnitSystemFromCountry,
	getTaxExcludedFromCountry,
} from 'common/modules/atoms/countries';
import { getNormalizedUrl } from 'common/modules/atoms/validation';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { getUniqueTemplateIdsForIndustyCategories } from 'common/modules/shopTemplates';
import { getFreeShopUrl } from 'common/modules/urls/shopUrl';
import { getMerchantProfile, setGaUserProperties } from 'common/services/analytics';
import {
	CAPABILITY_QUESTION_NAMES,
	CapabilityOptionName,
	GroupedIndustryCategories,
	IndustryCategory,
	MerchantProfile,
	OFFERING_OPTION_NAMES,
	Session,
	ShopSetups,
} from 'common/services/analytics/tractionAnalytics';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { ById, Languages, ProductTypes } from 'common/types';
import { isRentleEmail, removeUndefinedValues } from 'common/utils/common';
import { getDateFormatFromCountry } from 'common/utils/dateUtils';
import { StorageKeys, Storages, removeFromStorage, saveToStorage } from 'common/utils/frontUtils';
import { jsonParseString } from 'common/utils/strings';
import * as NotificationActions from 'actions/NotificationActions';
import { useAnalytics } from 'hooks/useAnalytics';
import { getLanguage, useTranslation } from 'services/localization';

interface HandleRegisterParams {
	uid: string;
	email: string;
	countryCode: CountryCode;
	affiliateId: string | undefined;
	promotionCode: string | undefined;
}

export const useRegistration = () => {
	const [state, setStore] = useRegisterContext((state) => state);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { logSignupEvent, logAdditionalSignupInfoEvent } = useAnalytics();
	const theme = useTheme();

	const {
		defaultLanguage,
		shopId,
		email,
		shopName,
		shopAnalyticsDocId,
		websiteUrl,
		startWithTemplate,
		customerPersona,
		industryCategories,
		capabilities,
		capabilitySpecifications,
		revenue,
		placeholderUrlId,
		placeholderShopName,
	} = state;

	const handleAnalyticsUpdates = async ({
		merchant_profile,
		website_url,
	}: {
		merchant_profile: MerchantProfile | undefined;
		website_url: string;
	}) => {
		try {
			logAdditionalSignupInfoEvent(
				removeUndefinedValues({
					website_url,
					customer_persona: customerPersona.value,
					industry_categories: industryCategories.values,
					email,
					shop_setup: state.startWithTemplate ? ShopSetups.TEMPLATE : ShopSetups.VANILLA,
					revenue,
					merchant_profile,
				}),
			);
		} catch (err) {
			errorHandler.report(err);
		}
	};

	const handleApplyTemplates = async ({
		shopId,
		industryCategories,
		capabilities,
	}: {
		shopId: string | null;
		industryCategories: IndustryCategory[];
		capabilities: ById<CapabilityOptionName[]>;
	}) => {
		if (!shopId) {
			dispatch(
				NotificationActions.showNotification({
					message: t(
						'register.notAbleToApplyTemplatesToYourStore',
						'Not able to apply templates to your store',
					),
					variant: 'error',
					autoDismissMs: 4000,
				}),
			);
			return;
		}
		const hasSelectedPricingOptions = !!capabilities[CAPABILITY_QUESTION_NAMES.OFFERING];
		const pricingOptions = hasSelectedPricingOptions
			? Object.values(capabilities[CAPABILITY_QUESTION_NAMES.OFFERING]).filter(
					(capability) =>
						Object.keys(OFFERING_OPTION_NAMES).includes(capability) &&
						capability !== OFFERING_OPTION_NAMES.OTHER,
			  )
			: [];
		const uniqueTemplateIds = getUniqueTemplateIdsForIndustyCategories(
			state.templateIdsByIndustry,
			!!industryCategories.length ? industryCategories : [GroupedIndustryCategories.OTHER],
		);
		try {
			await Promise.all(
				uniqueTemplateIds.map((templateId, index) => {
					return Callable.admin.applyTemplateToShop({
						shopId,
						templateId,
						defaultLanguage,
						applyTheme: index === 0, // until we have a separate theme selection step
						pricingOptions,
					});
				}),
			);
			dispatch(NotificationActions.dismissAllNotifications());
			dispatch(
				NotificationActions.showNotification({
					message: `${t('register.yourStoreIsReady', 'Your store is ready')}!`,
					variant: 'success',
					autoDismissMs: 4000,
				}),
			);
		} catch (err) {
			dispatch(
				NotificationActions.showNotification({
					message: t(
						'register.notAbleToApplyTemplatesToYourStore',
						'Not able to apply templates to your store',
					),
					variant: 'error',
					autoDismissMs: 4000,
				}),
			);
			errorHandler.report(err);
		}
	};

	const replaceShopUrl = async (args: { shopId: string; shopUrl: string }) => {
		const { shopId, shopUrl } = args;
		await runTransaction(async (transaction) => {
			// Double check that we are not overwriting any existing doc
			const existing = await api(transaction).shopUrls.doc(shopUrl).get();
			if (!!existing) {
				errorHandler.report(`Attempting to update an existing url ${shopUrl} to shop ${shopId}`);
				return;
			}
			const existingShopUrlDoc = await api(transaction)
				.shopUrls.get.where('shopId', '==', shopId)
				.get({ withDocId: true })
				.then((d) => d[0]);
			if (!existingShopUrlDoc.data) {
				errorHandler.report(`No url document found for shop ${shopId}`);
				return;
			}
			await api(transaction)
				.shopUrls.doc(shopUrl)
				.set({
					...existingShopUrlDoc.data,
					storeUrl: shopUrl,
				});
			await api(transaction).shopUrls.doc(existingShopUrlDoc.docId).delete();
		});
	};

	const handleEnterStore = async () => {
		try {
			if (startWithTemplate) {
				dispatch(
					NotificationActions.showNotification({
						message: (
							<Stack direction="row">
								<Spinner size={24} relative color={theme.palette.common.white} />
								<Typography
									sx={{
										marginBottom: 0,
										fontSize: '1.4rem',
										fontWeight: 500,
										minWidth: '250px',
										maxWidth: '400px',
										textAlign: 'center',
									}}
								>
									{t('register.gettingEverythingReadyForYou', 'Getting everything ready for you')}
								</Typography>
							</Stack>
						),
						variant: 'info',
					}),
				);
			}
			const promises: Promise<void>[] = [];
			const merchantProfile = getMerchantProfile({
				customerPersona,
				revenue,
			});

			const normalizedUrl = getNormalizedUrl(websiteUrl, { defaultProtocol: 'https' });

			if (!!shopAnalyticsDocId) {
				const promise = api()
					.shopAnalytics.doc(shopAnalyticsDocId)
					.update({
						industryCategories,
						shopSetup: startWithTemplate ? ShopSetups.TEMPLATE : ShopSetups.VANILLA,
						capabilities: {
							values: Object.values(capabilities).flatMap((capability) => capability),
							specifications: capabilitySpecifications,
						},
						merchantProfile,
						...(!!customerPersona?.value && {
							customerPersona: {
								...customerPersona,
								value: customerPersona.value,
							},
						}),
						...(!!revenue && { revenue }),
					});
				promises.push(promise);
			}

			if (!!shopId) {
				const newShopName = shopName ?? placeholderShopName;
				const promise = api().shops.doc(shopId).public.update({
					websiteUrl: normalizedUrl,
					name: newShopName,
					'visitingAddress.name': newShopName,
				});
				promises.push(promise);
				// Update new shop URL based on the new shop name or generated placeholder
				const nameForUrl = shopName ?? `store-${placeholderUrlId}`;
				const shopUrl = await getFreeShopUrl(nameForUrl, { api: api() });
				promises.push(replaceShopUrl({ shopId, shopUrl }));
			}
			await Promise.all(promises);

			handleAnalyticsUpdates({ merchant_profile: merchantProfile, website_url: normalizedUrl });

			if (startWithTemplate) {
				handleApplyTemplates({
					shopId,
					industryCategories: industryCategories.values,
					capabilities,
				});
			}
		} catch (err) {
			errorHandler.report(err);
		} finally {
			removeFromStorage(Storages.SESSION, StorageKeys.REGISTRATION_SHOP_ID);
			removeFromStorage(Storages.SESSION, StorageKeys.REGISTRATION_ANALYTICS_ID);
			setStore((store) => {
				store.dirty = false;
			});
		}
	};

	const handleRegister = async (args: HandleRegisterParams) => {
		const getDefaultLanguage = (language: Languages | null) => {
			return language && RENTLE_FREE_LANGUAGES.includes(language) ? language : 'en';
		};

		const { uid, email, countryCode, affiliateId, promotionCode } = args;
		setStore((store) => {
			store.registrationStatus = 'registering';
		});

		const systemDefaultLanguage = getDefaultLanguage(getLanguage());
		setStore((store) => {
			store.defaultLanguage = systemDefaultLanguage;
		});

		const initialSessionCookie = Cookies.get('cUser');
		const signupSessionCookie = Cookies.get('cSession');

		const hasSessionCookieData = !!initialSessionCookie || !!signupSessionCookie;

		try {
			const payStoreObject: ShopOnlinePaymentMethodObject = { id: 'PAY_STORE' };
			const { shopId, shopAnalyticsDocId } = await Callable.users.registerShop({
				shopType: ProductTypes.RENTAL,
				uid,
				email,
				termsVersionAccepted: RENTLE_SHOP_TERMS_VERSION,
				shopInfo: {
					defaultLanguage: systemDefaultLanguage,
					country: countryCode,
					currency: getDefaultCurrencyObjectForCountry(countryCode),
					dateFormat: getDateFormatFromCountry(countryCode),
					unitSystem: getInitialUnitSystemFromCountry(countryCode),
					taxExcluded: getTaxExcludedFromCountry(countryCode),
					activePaymentMethods: [payStoreObject],
				},
				timeZone: moment.tz.guess(),
				affiliateId,
				promotionCode,
				...(hasSessionCookieData && {
					sessions: {
						initial: jsonParseString(initialSessionCookie) as Session | null,
						signup: jsonParseString(signupSessionCookie) as Session | null,
					},
				}),
			});
			saveToStorage(Storages.SESSION, StorageKeys.REGISTRATION_SHOP_ID, shopId);
			saveToStorage(Storages.SESSION, StorageKeys.REGISTRATION_ANALYTICS_ID, shopAnalyticsDocId);
			setStore((store) => {
				store.dirty = false;
				store.shopId = shopId;
				store.shopAnalyticsDocId = shopAnalyticsDocId;
				store.email = email;
				store.registrationStatus = 'done';
			});
			setGaUserProperties(
				{
					internal_user: isRentleEmail(email),
				},
				{ sendToAllTags: true },
			);
			logSignupEvent({
				method: 'email',
				internal_user: isRentleEmail(email),
				affiliate_id: affiliateId,
				email,
				merchant_id: shopId,
			});
		} catch (err) {
			setStore((store) => {
				store.dirty = true;
				store.registrationStatus = 'error';
			});
		}
	};

	return {
		handleEnterStore,
		handleRegister,
	};
};
