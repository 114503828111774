import { faker } from '@faker-js/faker/locale/en';

import { TaxLine } from 'common/modules/atoms/taxes';

import { getLocaleField } from './LocaleField.mock';

const generateTaxLine = (): TaxLine => {
	return {
		label: faker.helpers.arrayElement([getLocaleField(), undefined]),
		price: faker.datatype.number({ min: 0, max: 1000 }),
		rate: faker.datatype.number({ min: 0, max: 100 }),
	};
};

export const getTaxLine = (p?: Partial<TaxLine>): TaxLine => {
	return { ...generateTaxLine(), ...p };
};
