import { createAction } from '@reduxjs/toolkit';

import {
	addPurchasedOrdersInfoListener,
	fetchOrdersByConfirmationIdOrNames,
} from 'common/api/frontend';
import { OrderInfo, RemoteData } from 'common/types';
import { ReduxThunkAction } from 'services/types';

export const updatePurchasedOrders = createAction<RemoteData<OrderInfo[]>>(
	'UPDATE_PURCHASED_ORDERS',
);

export const addToPurchasedOrders = createAction<RemoteData<OrderInfo[]>>(
	'ADD_TO_PURCHASED_ORDERS',
);

export const addPurchasedOrdersListener = (shopId: string, limit?: number): ReduxThunkAction => {
	return (dispatch) => {
		addPurchasedOrdersInfoListener(
			shopId,
			(orders) => dispatch(updatePurchasedOrders({ kind: 'FETCHED', data: orders })),
			(error) => dispatch(updatePurchasedOrders({ kind: 'ERROR', error: error.message })),
			limit,
		);
	};
};

export const getOrdersByConfirmationIdOrNames = (
	shopId: string,
	searchInput: string,
): ReduxThunkAction => {
	return (dispatch) => {
		fetchOrdersByConfirmationIdOrNames(
			shopId,
			searchInput,
			(orders) => dispatch(addToPurchasedOrders({ kind: 'FETCHED', data: orders })),
			(error) => dispatch(addToPurchasedOrders({ kind: 'ERROR', error: error.message })),
		);
	};
};
