import { Plan } from 'common/modules/plans';

import buildPath from './buildPath';
import * as Routes from './routes';
import * as T from './types';

export const NewRentalDone = (rentalType: T.RentalType) =>
	buildPath(Routes.NEW_RENTAL_DONE, { rentalType });

export const NewRentalShopperInfo = (rentalType: T.RentalType) =>
	buildPath(Routes.NEW_RENTAL_SHOPPERINFO, { rentalType });

export const StoreTab = (tabId: T.StoreTab) => buildPath(Routes.STORE_TAB, { tabId });

export const CatalogTab = (tabId: T.ProductsTab) => buildPath(Routes.CATALOG_TAB, { tabId });

export const ProductPage = (productId: string) => buildPath(Routes.CATALOG_PRODUCT, { productId });
export const ProductPageTab = (productId: string, tabId: T.ProductPageTab) =>
	buildPath(Routes.CATALOG_PRODUCT_TAB, { productId, tabId });
export const ProductPagePricingTab = (productId: string, pricingTabId: T.ProductPagePricingTab) =>
	buildPath(Routes.CATALOG_PRODUCT_SUB_TAB, {
		productId,
		tabId: 'pricing',
		subTabId: pricingTabId,
	});

export const DiscountPage = (discountId: string) =>
	buildPath(Routes.CATALOG_DISCOUNT, { discountId });

export const CategoryPage = (categoryId: string) =>
	buildPath(Routes.CATALOG_CATEGORY, { categoryId });

export const Bookings = () => Routes.BOOKINGS;
export const BookingsTab = (tabId: T.BookingsTab) => buildPath(Routes.BOOKINGS_TAB, { tabId });
export const BookingPage = (bookingId: string) => buildPath(Routes.BOOKING_PAGE, { bookingId });
export const BookingPageEditShopper = (bookingId: string, shopperId: string) =>
	buildPath(Routes.BOOKING_PAGE_EDIT_SHOPPER, { bookingId, shopperId });
export const BookingPageOrderSummary = (bookingId: string) =>
	buildPath(Routes.BOOKING_PAGE_ORDER_SUMMARY, { bookingId });

export const BookingPagePaymentSummary = (bookingId: string) =>
	buildPath(Routes.BOOKING_PAGE_PAYMENT_SUMMARY, { bookingId });

export const SingleDelivery = (deliveryId: string) =>
	buildPath(Routes.SINGLE_DELIVERY, { deliveryId });
export const SingleCarrier = (carrierId: string) => buildPath(Routes.SINGLE_CARRIER, { carrierId });

export const InventoryItem = (itemId: string) =>
	buildPath(Routes.INVENTORY_ARTICLES_SINGLE, { itemId });

export const InventoryItemTab = (itemId: string, tabId: T.InventoryArticlesTabs) =>
	buildPath(Routes.INVENTORY_ARTICLES_SINGLE_TAB, { itemId, tabId });

export const InventoryTransfer = (transferId: string) =>
	buildPath(Routes.INVENTORY_TRANSFER_SINGLE, { transferId });

export const InventoryTransferProcess = (transferId: string) =>
	buildPath(Routes.INVENTORY_TRANSFER_SINGLE_PROCESS, { transferId });

export const InventorySku = (skuId: string) => buildPath(Routes.INVENTORY_SKUS_SINGLE, { skuId });

export const InventoryTab = (tabId: T.InventoryTabs) => buildPath(Routes.INVENTORY_TAB, { tabId });

export const AddOnStoreTab = (tabId: T.AddOnStoreTabs) =>
	buildPath(Routes.ADD_ONS_STORE_TAB, { tabId });

export const LanguagesTab = (tabId: T.LanguagesTabs) =>
	buildPath(Routes.ACCOUNT_LANGUAGES_TAB, { tabId });

export const PlanConfirm = (planId: Plan) => buildPath(Routes.PLANS_CONFIRM, { planId });
