import { useCallback, useRef, useState } from 'react';

type focusProps = {
	onBlur: () => void;
	onFocus: () => void;
};

export default (): [boolean, (focus: boolean) => void, focusProps] => {
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const [hasFocus, setHasFocus] = useState<boolean>(false);

	const onBlur = useCallback(() => {
		timeout.current = setTimeout(() => {
			if (hasFocus) {
				setHasFocus(false);
			}
		}, 0);
	}, [hasFocus]);

	const onFocus = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		if (!hasFocus) {
			setHasFocus(true);
		}
	}, [hasFocus]);

	return [
		hasFocus,
		setHasFocus,
		{
			onBlur,
			onFocus,
		},
	];
};
