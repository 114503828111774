import * as UpdateActiveOrders from './updateActiveOrders';
import * as UpdateReservedOrderIds from './updateReservedOrderIds';
import * as UpdateStatuses from './updateStatuses';

const _InventoryItemController = () => {
	return {
		...UpdateActiveOrders,
		...UpdateStatuses,
		...UpdateReservedOrderIds,
	};
};

export const InventoryItemController = _InventoryItemController();
