import React from 'react';

import { Box, Grid, useTheme } from '@mui/material';

import RegisterBgV2 from 'common/assets/register-bg-v2.png';

import CtaCarousel from './CtaCarousel';
import CtaTestimonial from './CtaTestimonial';
import CtaTrustedBy from './CtaTrustedBy';

export interface Props {
	content: {
		title: string;
		text: string;
	}[];
}
const CtaContentCarousel = (props: Props) => {
	const theme = useTheme();
	const { content } = props;
	return (
		<Grid
			item
			lg={6}
			sx={{
				backgroundImage: `url("${RegisterBgV2}")`,
				backgroundRepeat: 'round',
				backgroundSize: 'cover',
				px: theme.spacing(15),
				paddingTop: theme.spacing(18),
			}}
		>
			<Box
				sx={{
					maxWidth: 500,
					margin: '0 auto',
				}}
			>
				<CtaCarousel content={content} />
				<Box mb={5} />
				<CtaTestimonial />
				<Box mb={5} />
				<CtaTrustedBy />
			</Box>
		</Grid>
	);
};

export default CtaContentCarousel;
