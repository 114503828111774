import { CountryCode, isEUMemberState } from 'common/modules/atoms/countries';

import { VatNumberWithValidation } from './types';

export const hasValidVat = (vat: VatNumberWithValidation | undefined) => {
	return !!vat?.validated && !!vat?.value;
};

export const isVatExempt = (vat: VatNumberWithValidation | undefined) => {
	return !!vat?.validated && !!vat?.exempt;
};

export const shouldChargeVat = (
	country: string | undefined,
	vat: VatNumberWithValidation | undefined,
) => {
	const countryCode = country as CountryCode | undefined;
	// Finland always has VAT
	if (countryCode === CountryCode.Finland || countryCode === undefined) return true;

	if (isEUMemberState(countryCode) && isVatExempt(vat)) return false;

	// EU Member states that don't have a validated VAT number always have VAT
	if (isEUMemberState(countryCode) && !hasValidVat(vat)) return true;

	// Non EU countries and countries with validated VAT do not have VAT
	return false;
};

export const isReverseCharge = (
	country: string | undefined,
	vat?: VatNumberWithValidation | undefined,
) => {
	const countryCode = country as CountryCode | undefined;
	return (
		!!countryCode &&
		countryCode !== CountryCode.Finland &&
		isEUMemberState(countryCode) &&
		hasValidVat(vat)
	);
};
