import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { DateFormatObject, FirstDayOfWeek } from 'common/types';
import {
	MomentDateFormat,
	getStartDayAsISOWeekday,
	localFormat,
	localFormatString,
} from 'common/utils/dateUtils';
import * as ShopSelectors from 'selectors/ShopSelectors';

/** Use this when using localised time formats
 *
 * const { localFormat } = useShopFormat();
 *
 */

export type UseShopFormat = {
	shopDateFormat: DateFormatObject;
	firstDayOfWeek: FirstDayOfWeek;
	timeFormat24: boolean;
	localFormat: (
		dateTime: string | moment.Moment | undefined,
		dateFormat: MomentDateFormat,
	) => string;
	localFormatString: (dateFormat: MomentDateFormat) => string;
};

const useShopFormat = (): UseShopFormat => {
	const shopDateFormat = useSelector(ShopSelectors.getShopDateFormat);

	const _localFormat = (
		dateTime: string | moment.Moment | undefined,
		dateFormat: MomentDateFormat,
	) => {
		return localFormat(dateTime, dateFormat, shopDateFormat);
	};

	const _localFormatString = (dateFormat: MomentDateFormat) =>
		localFormatString(dateFormat, shopDateFormat);

	const timeFormat24 = shopDateFormat.timeFormat === 'HH:mm';

	return {
		shopDateFormat,
		timeFormat24,
		localFormat: _localFormat,
		localFormatString: _localFormatString,
		firstDayOfWeek: getStartDayAsISOWeekday(shopDateFormat.startDay),
	};
};

export default useShopFormat;
