import { createBasicDbOperations } from '../../utils';
import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { StoreEventsEntity } from './entities';

export const getStoreEvents = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.storeEvents;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<StoreEventsEntity>(collectionPath, dbRefs, dataUpdater),
	};
};
