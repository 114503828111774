import { CancellationPolicyApi, CancellationPolicyDb } from './types';

export const toApi = (data: CancellationPolicyDb): CancellationPolicyApi => {
	return data.map((d) => {
		return {
			hoursBefore: d.beforeHours || 0,
			refundPercentage: d.refundPercentage || 0,
			displayAs: d.displayAs ?? 'hours',
		};
	});
};

export const toDb = (data: CancellationPolicyApi): CancellationPolicyDb => {
	return data.map((d) => {
		return {
			beforeHours: d.hoursBefore,
			refundPercentage: d.refundPercentage,
			displayAs: d.displayAs,
		};
	});
};
