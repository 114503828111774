import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.INVENTORY,
			completeOnClick: true,
		}),
		content: {
			title: t('homeView.onboarding.overviewOfYourInventory', 'Overview of your inventory'),
		},
		id: 'VISIT_INVENTORY',
		type: 'row',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.INVENTORY,
			completeOnClick: true,
		}),
		content: {
			title: t('homeView.onboarding.checkInventory', 'Check the inventory overview'),
		},
		id: 'VISIT_INVENTORY',
		type: 'row',
	},
});

export const VISIT_INVENTORY_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'VISIT_INVENTORY',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		VISIT_INVENTORY: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
