import { DayType, ISOWeekdays } from './types';

export const DAY_TYPE_OPTIONS: DayType[] = [
	'allDays',
	'weekDays',
	'weekends',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

export const ALL_WEEKDAYS = [
	ISOWeekdays.Monday,
	ISOWeekdays.Tuesday,
	ISOWeekdays.Wednesday,
	ISOWeekdays.Thursday,
	ISOWeekdays.Friday,
	ISOWeekdays.Saturday,
	ISOWeekdays.Sunday,
];
