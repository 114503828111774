import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import { PaymentApi } from 'common/types';

export const getInvoicePaymentQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.invoicePayments;
	const dataUpdater = undefined;
	const query = buildQuery<PaymentApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PaymentApi>(collectionPath, dbRefs, dataUpdater),
	};
};
