import React, { useState } from 'react';

import {
	Button,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { Container } from '@mui/system';
import { motion } from 'framer-motion';
import { RiQuestionLine } from 'react-icons/ri';
import { useRegisterContext } from 'views/Register/RegisterContext';
import { GET_STARTED_STEP_NAMES } from 'views/Register/utils';

import { CustomerPersonas, RevenueOption } from 'common/services/analytics/tractionAnalytics';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';
import { validateShopName } from 'services/utils';

import RegistrationFooter from '../RegistrationFooter';
import CustomerPersonaPicker from './CustomerPersonaPicker';
import { getRevenueOptions, validateWebsiteUrl } from './utils';

export interface Props {
	onContinue: () => void;
	options: {
		transitionDelay: string;
	};
}

const RegistrationSetup = (props: Props) => {
	const { onContinue, options } = props;

	const [
		{ customerPersona, revenue, websiteUrl, shopName, placeholderShopName },
		setStore,
	] = useRegisterContext((state) => state);

	const { t } = useTranslation();
	const [shopNameError, setShopNameError] = useState<string | undefined>(undefined);
	const [websiteUrlError, setWebsiteUrlError] = useState<boolean>();
	const revenueOptions = getRevenueOptions(t);
	const { logRegisterActionEvent } = useAnalytics();

	const handleRevenueChange = (value: RevenueOption) => {
		logRegisterActionEvent('revenue', value);
		setStore((store) => {
			store.dirty = true;
			store.revenue = value;
		});
	};

	const handleWebsiteUrlChange = (value: string) => {
		logRegisterActionEvent('website_url', value);
		setStore((store) => {
			store.dirty = true;
			store.websiteUrl = value;
		});
		if (!!websiteUrlError) {
			setWebsiteUrlError(!validateWebsiteUrl(value));
		}
	};

	const handleShopNameChange = (value: string) => {
		logRegisterActionEvent('shop_name', value);
		setStore((store) => {
			store.dirty = true;
			store.shopName = value;
		});
		if (!!shopNameError) {
			const error = validateShopName(value, t);
			setShopNameError(error);
		}
	};

	const handleContinueClick = () => {
		if (!customerPersona.value) return;
		logRegisterActionEvent('continue', {
			step: GET_STARTED_STEP_NAMES.SETUP,
		});
		onContinue();
	};

	const renderRevenueSelect = () => {
		const label =
			customerPersona.value === CustomerPersonas.AGENCIES
				? `${t('register.yourClientsAnnualRevenue', `What is your client's annual revenue`)}?`
				: `${t(
						'register.revenueOfYourCoreBusiness',
						'How much revenue does your core business generate annually',
				  )}?`;

		return (
			<TextField
				label={label}
				select
				value={revenue ?? ''}
				onChange={(e) => handleRevenueChange(e.target.value as RevenueOption)}
			>
				{revenueOptions.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						<Typography variant="body1">{option.label}</Typography>
					</MenuItem>
				))}
			</TextField>
		);
	};

	const renderMainCTA = () => {
		const disabledDueToCustomerPersona =
			!customerPersona.value ||
			(customerPersona.value === CustomerPersonas.REPLACING &&
				!customerPersona.specifications?.[CustomerPersonas.REPLACING]);
		const disabled = disabledDueToCustomerPersona || websiteUrlError;
		return (
			<Button
				sx={{
					maxWidth: 370,
					margin: '0 auto',
				}}
				fullWidth
				variant="black"
				onClick={handleContinueClick}
				disabled={disabled}
			>
				{t('common:actions.continue', 'Continue')}
			</Button>
		);
	};

	return (
		<Container sx={{ pb: 12 }}>
			<motion.div
				initial={{
					opacity: 0,
					transform: 'translateY(40px)',
					transitionDuration: '0.6s',
					transitionDelay: options.transitionDelay,
				}}
				animate={{
					opacity: 1,
					transform: 'translateY(0)',
					transitionDuration: '0.6s',
					transitionDelay: options.transitionDelay,
				}}
			>
				<Stack
					direction="column"
					alignItems="center"
					sx={{
						maxWidth: 560,
						m: '0 auto',
					}}
					spacing={2}
				>
					<Stack direction="column" spacing={2} mb={5} sx={{ width: '100%' }}>
						<TextField
							variant="outlined"
							label={t('register.storeName', 'Give your store a name')}
							onBlur={() => {
								if (!shopName) {
									setShopNameError(undefined);
									return;
								}
								const error = validateShopName(shopName, t);
								setShopNameError(error);
							}}
							placeholder={placeholderShopName}
							name="shopName"
							autoComplete="organization"
							value={shopName}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={(e) => handleShopNameChange(e.target.value)}
							margin="none"
							type="text"
							error={Boolean(shopNameError)}
							helperText={shopNameError}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Tooltip
											title={t(
												'register.shopNameHelper',
												"The name of your store which is shown to your customers. If you don't have a store name yet, you can put anything here. This can be changed later.",
											)}
											onFocus={() => logRegisterActionEvent('shop_name_helper')}
										>
											<IconButton aria-label="help" edge="end" size="small">
												<RiQuestionLine color="#848484" />
											</IconButton>
										</Tooltip>
									</InputAdornment>
								),
							}}
						/>
						<CustomerPersonaPicker />
						{customerPersona.value !== 'DISCOVERING' && renderRevenueSelect()}
						<TextField
							label={t('register.linkToYourWebsite', 'Link to your website or business profile')}
							placeholder={t('register.websiteUrlPlaceholder', 'Example: example.com')}
							error={!!websiteUrlError}
							onBlur={() => {
								const isValid = validateWebsiteUrl(websiteUrl);
								setWebsiteUrlError(!isValid);
							}}
							helperText={
								!!websiteUrlError
									? t('register.enterValidWebsiteUrl', 'Please enter a valid website URL')
									: ''
							}
							value={websiteUrl ?? ''}
							onChange={(e) => handleWebsiteUrlChange(e.target.value)}
							variant="outlined"
							fullWidth
						/>
					</Stack>
				</Stack>
			</motion.div>
			<RegistrationFooter mainCTA={{ alignment: 'center', component: renderMainCTA() }} />
		</Container>
	);
};

export default RegistrationSetup;
