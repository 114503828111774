import { BoxProps } from '@mui/material';
import { IconType } from 'react-icons';
import {
	RiAlertLine,
	RiCheckboxCircleLine,
	RiErrorWarningLine,
	RiGiftLine,
	RiInformationLine,
} from 'react-icons/ri';

export interface BannerProps extends Omit<BoxProps, 'className'> {
	variant: 'info' | 'warning' | 'danger' | 'success' | 'upgrade' | 'none';
	title?: string;
	description?: string | React.ReactNode;
	icon?: IconType;
	raised?: boolean;
	button?: {
		text: string;
		onClick: () => void;
		loading?: boolean;
	};
	buttonEl?: React.ReactNode;
	onClose?: () => void;
}

export interface BannerGroupProps extends Omit<BoxProps, 'className'> {
	variant: 'info' | 'warning' | 'danger' | 'success' | 'upgrade';
	title: string;
	icon?: IconType;
	items: BannerGroupItemProps[];
	raised?: boolean;
}

export type BannerGroupItemProps = Omit<BannerProps, 'variant' | 'raised' | 'icon'>;

export const IconByVariant: Record<BannerGroupProps['variant'], IconType> = {
	info: RiInformationLine,
	warning: RiAlertLine,
	danger: RiErrorWarningLine,
	success: RiCheckboxCircleLine,
	upgrade: RiGiftLine,
};
