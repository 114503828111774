import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.CATALOG_CREATE_PRODUCT,
		}),
		content: {
			title: t('homeView.onboarding.addYourProducts', 'Add your products'),
		},
		id: 'CREATE_MULTIPLE_PRODUCTS',
		type: 'row',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.CATALOG_CREATE_PRODUCT,
		}),
		content: {
			title: t('homeView.onboarding.createMoreProducts', 'Create more products'),
		},
		id: 'CREATE_MULTIPLE_PRODUCTS',
		type: 'row',
	},
});

export const CREATE_MULTIPLE_PRODUCTS_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'CREATE_MULTIPLE_PRODUCTS',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		CREATE_MULTIPLE_PRODUCTS: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
