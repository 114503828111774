import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.maintenanceTime.name', 'Maintenance Time'),
	subHeader: t(
		'common:blocks.details.maintenanceTime.subHeader',
		'Reserve time for product maintenance',
	),
	description: t(
		'common:blocks.details.maintenanceTime.description',
		'Adding a maintenance buffer keeps your products automatically unavailable for a fixed time after they have returned. It ensures you have time to inspect, clean, and maintain the products for your next customer. A bit of automation to smoothen your day-to-day',
	),
	supportArticle: SupportArticles.MAINTENANCE_TIME,
	uninstallNotice: t(
		'common:blocks.details.maintenanceTime.uninstallNotice',
		'By uninstalling Maintenance Time, your features configurations will be removed',
	),
});

export default details;
