import { z } from 'zod';

import { zItemPricing, zOrderPricing } from 'common/modules/atoms/pricing';
import {
	InventoryTransferPaymentMethods,
	InventoryTransferStatuses,
} from 'common/modules/inventoryTransfers/constants';
import { zBaseDbDoc } from 'common/types';
import validator from "validator";
import { AllocationTypes, zAllocationType } from '../inventory/types';
import { InventoryStatuses, zInventoryStatus } from '../inventory/constants';

export const zInventoryTransferStatus = z.nativeEnum(InventoryTransferStatuses);
export const zInventoryTransferPaymentMethod = z.nativeEnum(InventoryTransferPaymentMethods);

export const zInventoryTransferItemCondition = z.object({
	score: z.number().min(0).max(100).int().default(100),
});

export const zInventoryTransferItem = z.object({
	id: z.string(),
	label: z.string(),
	skuId: z.string(),
	price: zItemPricing,
	status: zInventoryTransferStatus.default(InventoryTransferStatuses.PENDING),
	condition: zInventoryTransferItemCondition,
	allocation: zAllocationType.default(AllocationTypes.rental),
	inventoryStatus: zInventoryStatus.default(InventoryStatuses.IN_USE),
	customIds: z.array(z.string()).default([]),
});

export const zInventoryTransferCustomer = z.object({
	firstName: z.string().min(1).optional(),
	lastName: z.string().min(1).optional(),
	email: z.string().refine(validator.isEmail).optional(),
	phone: z.string().refine(validator.isMobilePhone).optional(),
	additionalInfo: z.string().optional(),
});

export const zInventoryTransferSource = z.discriminatedUnion('type', [
	z.object({ type: z.literal('customer'), customer: zInventoryTransferCustomer.nullable() }),
]);

export const zInventoryTransferDestination = z.discriminatedUnion('type', [
	z.object({ type: z.literal('store'), store: z.object({ id: z.string() }) }),
]);

export const zInventoryTransfer = zBaseDbDoc.extend({
	updatedAt: z.string().datetime(),
	transferNumber: z.number().int().default(0),
	merchantId: z.string(),
	status: zInventoryTransferStatus.default(InventoryTransferStatuses.PENDING),
	items: z.array(zInventoryTransferItem).default([]),
	source: zInventoryTransferSource,
	destination: zInventoryTransferDestination,
	arrivalDate: z.string().datetime(),
	price: zOrderPricing,
	paymentMethod: zInventoryTransferPaymentMethod.default(InventoryTransferPaymentMethods.MANUAL),
	additionalDetails: z.string().default("").optional(),
	paid: z.boolean().default(false),
});

export type InventoryTransferStatus = z.infer<typeof zInventoryTransferStatus>;
export type InventoryTransferPaymentMethod = z.infer<typeof zInventoryTransferPaymentMethod>;
export type InventoryTransferItem = z.infer<typeof zInventoryTransferItem>;
export type InventoryTransferCustomer = z.infer<typeof zInventoryTransferCustomer>;
export type InventoryTransferSource = z.infer<typeof zInventoryTransferSource>;
export type InventoryTransferDestination = z.infer<typeof zInventoryTransferDestination>;
export type InventoryTransfer = z.infer<typeof zInventoryTransfer>;
export type InventoryTransferItemCondition = z.infer<typeof zInventoryTransferItemCondition>;
