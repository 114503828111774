import { AppliedDiscountCodes, CartProduct, OrderProduct } from 'common/types';
import { percentageOf } from 'common/utils/math';

import { getExtendedItemPricing } from '../atoms/pricing';
import { getTotalProductPricing } from '../orders/products';
import { isLiftTicketPackageOrderProduct } from '../skidata';
import { DiscountCodeApi, PublicDiscountCode } from './types';

export const getDiscountCodeValueForOrder = (
	discountCode: PublicDiscountCode,
	products: OrderProduct[],
) => {
	const entitledProducts = products.filter((p) =>
		isProductEntitledForDiscountCode(p, discountCode),
	);
	const totalProductPriceWithoutCode = getExtendedItemPricing(
		getTotalProductPricing(entitledProducts),
	).subtotalWithoutDiscountCodes;
	const discountCodeValue = getDiscountCodeValue(discountCode, totalProductPriceWithoutCode);
	return Math.min(totalProductPriceWithoutCode, discountCodeValue);
};

export const getDiscountProductId = (product: CartProduct | OrderProduct) => {
	if (isLiftTicketPackageOrderProduct(product)) {
		return product.stockPackageId;
	}
	return product.productApiId;
};

export const getDiscountCategoryIds = (product: CartProduct | OrderProduct) => {
	return product.categoryIds ?? [];
};

const getDiscountCodeValue = (discountCode: PublicDiscountCode, total: number) => {
	if (discountCode.priceRule.valueType === 'fixed_amount') {
		return discountCode.priceRule.value;
	}
	if (discountCode.priceRule.valueType === 'percentage') {
		return Math.round(percentageOf(discountCode.priceRule.value, total));
	}
	return 0;
};

interface DiscountCodeToPublicDiscountCode {
	discountCode: DiscountCodeApi;
	productIdsToKeep?: string[];
	categoryIdsToKeep?: string[];
}

export const discountCodeToPublicDiscountCode = (
	args: DiscountCodeToPublicDiscountCode,
): PublicDiscountCode => {
	const { discountCode, productIdsToKeep, categoryIdsToKeep } = args;
	const { code, priceRule, entitledCategoryIds, entitledProductIds } = discountCode;
	const updatedCategoryIds =
		categoryIdsToKeep && entitledCategoryIds
			? entitledCategoryIds.filter((id) => categoryIdsToKeep.includes(id))
			: entitledCategoryIds;
	const updatedProductIds =
		productIdsToKeep && entitledProductIds
			? entitledProductIds.filter((id) => productIdsToKeep.includes(id))
			: entitledProductIds;
	return {
		code,
		priceRule,
		entitledCategoryIds: updatedCategoryIds,
		entitledProductIds: updatedProductIds,
	};
};

export const isProductEntitledForDiscountCode = (
	p: OrderProduct,
	discountCode: PublicDiscountCode,
) => {
	const discountProductId = getDiscountProductId(p);
	const discountCategoryIds = getDiscountCategoryIds(p);
	const productIdIncluded =
		!discountCode.entitledProductIds ||
		(!!discountProductId && discountCode.entitledProductIds.includes(discountProductId));
	if (!productIdIncluded) {
		return false;
	}
	const categoryIdIncluded =
		!discountCode.entitledCategoryIds ||
		discountCode.entitledCategoryIds.some((id) => discountCategoryIds.includes(id));
	if (!categoryIdIncluded) {
		return false;
	}
	return true;
};

export const getTotalDiscountFromAppliedDiscountCodes = (
	appliedDiscountCodes: AppliedDiscountCodes,
) => {
	let totalDiscount = 0;
	Object.keys(appliedDiscountCodes).forEach((code) => {
		const discount = appliedDiscountCodes[code].totalDiscountValue;
		totalDiscount += discount;
		return totalDiscount;
	});
	return totalDiscount;
};

export const mergeDiscountCodes = (
	existingDiscountCodes: AppliedDiscountCodes,
	newDiscountCodes: AppliedDiscountCodes,
) => {
	return Object.entries(newDiscountCodes).reduce((prev, curr) => {
		const [code, object] = curr;
		if (!!prev[code]) {
			return {
				...prev,
				[code]: {
					...prev[code],
					totalDiscountValue: prev[code].totalDiscountValue + object.totalDiscountValue,
				},
			};
		}
		return {
			...prev,
			[code]: object,
		};
	}, existingDiscountCodes);
};

export const sortyByCodeName = (code1: DiscountCodeApi, code2: DiscountCodeApi) => {
	return code1.code.localeCompare(code2.code);
};
