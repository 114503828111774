import { faker } from '@faker-js/faker/locale/en';

import { allChannelLanguages } from 'common/locales/utils/constants';
import { LocaleField } from 'common/types';
import { getRandomElement } from 'common/utils/arrays';

import { checkForInvalidProperties, checkForUnallowedValueTypes } from './index.mock';

export const MandatoryProperties: string[] = ['def'];

const generateLocaleField = (): LocaleField => {
	const dynamicLangFields: { [lang: string]: string } = {};
	while (Math.random() > 0.5) {
		dynamicLangFields[getRandomElement(allChannelLanguages)] = faker.word.noun();
	}
	return {
		def: faker.word.noun(),
		...dynamicLangFields,
	};
};

export const getLocaleField = (p?: Partial<LocaleField>): LocaleField => {
	if (p) {
		checkForInvalidProperties(
			{
				...generateLocaleField(),
				fi: '',
				en: '',
			},
			p,
			'LocaleField',
		);
		checkForUnallowedValueTypes(
			{
				...generateLocaleField(),
				fi: '',
				en: '',
			},
			p,
			'LocaleField',
		);
	}
	return { ...generateLocaleField(), ...p } as LocaleField;
};
