import { TFunction } from 'i18next';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment-timezone';

import { getTaxDetails, getTaxRateFromVatPercent } from 'common/modules/atoms/taxes';
import { getDeliveryInventoryBlockersBeforeAsMinutes } from 'common/modules/delivery/utils';
import { BaseProductVariant } from 'common/modules/inventory';
import { getInventoryBlocker } from 'common/modules/inventoryBlockers';
import { OpeningHours } from 'common/modules/openingHours';
import {
	getTotalProductPricing,
	isCartProduct,
	isOrderProduct,
	isRentalPurchaseType,
	isSubscriptionPurchaseType,
} from 'common/modules/orders/products';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import {
	getProductsInPackage,
	getStockProductMaintenanceMinutes,
	isPackageProduct,
} from 'common/modules/products/utils';
import {
	getProductVariants,
	getSortedVariantValues,
	hasMultipleVariants,
} from 'common/modules/products/variants';
import { isLiftTicketProduct, isValidKeycardNumber } from 'common/modules/skidata';
import { getTranslation } from 'common/modules/translations';
import {
	BaseProduct,
	BookingBuffer,
	CartDelivery,
	CartProduct,
	Category,
	DeliveryOption,
	DinCollectionMethod,
	Duration,
	FixedStockProperty,
	Languages,
	OrderProduct,
	OrderProductWithUnits,
	PartialVariant,
	PricingTableObject,
	ProductApi,
	StartTimeSlots,
} from 'common/types';
import { Hash } from 'common/utils/arrays';
import {
	OrderProductCreation,
	PartialOrderProductFields,
	createOrderProductFromProductApi,
} from 'common/utils/newRentalUtils';

import {
	PricingItem,
	PurchaseType,
	PurchaseTypes,
	SetProduct,
	UpdateOrderProductsCreation,
} from './../types/common';
import { notFalsey, removeUndefinedValues } from './common';
import { getDurationString, getNewDateProperties } from './dateCalculations';
import { isOnlinePaymentsInUse } from './payments';
import {
	getChargesFromPricingInfoForGivenDuration,
	getPricingInfoPropertiesForProduct,
	getVariantPriceIncrease,
} from './pricing';

type ProductApiWithVariant = ProductApi & { variant?: BaseProductVariant };

export const getProductNameWithVariant = (
	product: OrderProduct | CartProduct | SetProduct | ProductApiWithVariant,
	language: Languages | 'def',
	options?: {
		noSetVariants?: boolean;
	},
) => {
	const productName = getTranslation(product.name, language);
	const variantName = getVariantName({ variant: product.variant, lang: language });

	if (
		(isOrderProduct(product) || isCartProduct(product)) &&
		!!product.set &&
		!options?.noSetVariants
	) {
		const selectedVariantNames = product.setProducts
			.map((setProduct) => {
				if (setProduct.variant) {
					const setProductVariantName = getVariantName({
						variant: setProduct.variant,
						lang: language,
					});
					return setProductVariantName;
				}
				return null;
			})
			.filter((name) => Boolean(name));

		return [productName, selectedVariantNames.join(', ')].filter(notFalsey).join(' - ');
	} else {
		return [productName, variantName].filter(notFalsey).join(' - ');
	}
};

export const getVariantName = ({
	variant,
	lang,
	sortIds,
}: {
	variant: BaseProductVariant | PartialVariant | undefined | null;
	lang: Languages | 'def';
	sortIds?: string[];
}): string => {
	if (!variant) return '';

	const isPartialVariant = (
		variant: PartialVariant | BaseProductVariant,
	): variant is PartialVariant => {
		return !!(variant as PartialVariant).variantId;
	};

	const values = isPartialVariant(variant)
		? variant.variantValues
		: getSortedVariantValues(variant, sortIds);

	return values
		.map((value) => getTranslation(value, lang))
		.filter(notFalsey)
		.join(', ');
};

export const getVariantNameFromString = (variantValues: string[]) => {
	if (!variantValues.length) {
		return '';
	}
	return variantValues
		.filter((value) => value !== '' && value.replace(/\s/g, '').length)
		.reduce((acc, value, index) => (index === 0 ? (acc += value) : (acc += ', ' + value)), '');
};

const sumOfObjectsNumericKeys = <T extends object>(...objects: T[]): T => {
	return objects.reduce((a, b) => {
		for (const k in b) {
			if (b.hasOwnProperty(k)) {
				if (a[k] && typeof a[k] === 'number' && typeof b[k] === 'number') {
					a[k] = (((a[k] as any) || 0) + ((b[k] as any) || 0)) as any;
				} else if (a[k]) {
					a[k] = a[k];
				} else {
					a[k] = b[k];
				}
			}
		}
		return a;
	}, {} as T);
};

export const sortBySet = (p1: OrderProduct, p2: OrderProduct) => {
	if (p1.set && !p2.set) {
		return -1;
	} else if (!p1.set && p2.set) {
		return 1;
	}
	return 0;
};

export const getDefaultStartTimeSlots = (): StartTimeSlots => ({
	type: 'interval',
	intervalMinutes: 60,
	orderLimit: null,
});

export const getOrderProductsWithUnitsFromOrderProductList = (
	orderProducts: OrderProduct[],
): OrderProductWithUnits[] => {
	let orderProductsWithUnits: OrderProductWithUnits[] = [];
	for (const orderProduct of orderProducts) {
		let isFound = false;
		orderProductsWithUnits = orderProductsWithUnits.map((productWithUnits) => {
			if (
				orderProduct.variant &&
				productWithUnits.variant &&
				productWithUnits.variant.id === orderProduct.variant.id &&
				productWithUnits.rentalDurationInSeconds === orderProduct.rentalDurationInSeconds
			) {
				isFound = true;
				const chargeObj = sumOfObjectsNumericKeys(productWithUnits.charge, orderProduct.charge);
				const pricing = getTotalProductPricing(
					[productWithUnits, orderProduct],
					orderProduct.pricing.currency,
				);
				return {
					...productWithUnits,
					units: productWithUnits.units + 1,
					charge: chargeObj,
					includedIds: [...productWithUnits.includedIds, orderProduct.id],
					pricing,
				};
			} else if (
				!orderProduct.variant &&
				productWithUnits.productApiId === orderProduct.productApiId &&
				productWithUnits.rentalDurationInSeconds === orderProduct.rentalDurationInSeconds
			) {
				isFound = true;
				const chargeObj = sumOfObjectsNumericKeys(productWithUnits.charge, orderProduct.charge);
				const pricing = getTotalProductPricing(
					[productWithUnits, orderProduct],
					orderProduct.pricing.currency,
				);
				return {
					...productWithUnits,
					units: productWithUnits.units + 1,
					charge: chargeObj,
					includedIds: [...productWithUnits.includedIds, orderProduct.id],
					pricing,
				};
			}
			return productWithUnits;
		});
		if (!isFound) {
			orderProductsWithUnits = [
				...orderProductsWithUnits,
				{
					...orderProduct,
					units: 1,
					includedIds: [orderProduct.id],
				},
			];
		}
	}
	return orderProductsWithUnits;
};

export const getProductNameWithDuration = (
	product: OrderProduct,
	lang: Languages,
	durationFormat: 'long' | 'short' = 'long',
	t: TFunction,
) => {
	const { rentalDurationInSeconds, durationType, durationName } = product;
	const duration = { durationInSeconds: rentalDurationInSeconds, durationType, durationName };
	const durationString = getDurationString(duration, durationFormat, lang, t);
	const productNameWithVariant = getProductNameWithVariant(product, lang);
	return productNameWithVariant + (durationString ? `, ${durationString}` : '');
};

/*const sortOrderProductsByProductApiId = (p1: OrderProductWithUnits, p2: OrderProductWithUnits) => {
    return p1.productApiId.localeCompare(p2.productApiId);
};*/

export const arraysEqual = (a: string[], b: string[]) => {
	if (a === b) {
		return true;
	}
	if (a == null || b == null) {
		return false;
	}
	if (a.length !== b.length) {
		return false;
	}
	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) {
			return false;
		}
	}
	return true;
};

const hasVariantList = (product: OrderProductWithUnits | OrderProduct) => {
	return !!product.summary.variantIds?.length;
};

export const getVariantOrderProductsWithUnitsFromOrderProductList = (
	orderProducts: OrderProduct[],
	unConfirmed?: boolean,
): OrderProductWithUnits[] => {
	let orderProductsWithUnits: OrderProductWithUnits[] = [];

	for (const orderProduct of orderProducts) {
		let isFound = false;
		orderProductsWithUnits = orderProductsWithUnits.map((productWithUnits) => {
			if (
				!hasVariantList(productWithUnits) &&
				!hasVariantList(orderProduct) &&
				productWithUnits.productApiId === orderProduct.productApiId &&
				productWithUnits.endDate === orderProduct.endDate &&
				isEqual(productWithUnits?.subscription?.cycles, orderProduct?.subscription?.cycles)
			) {
				if (productWithUnits.segment !== orderProduct.segment) {
					return productWithUnits;
				}
				isFound = true;
				const charge = sumOfObjectsNumericKeys(productWithUnits.charge, orderProduct.charge);
				const pricing = getTotalProductPricing(
					[productWithUnits, orderProduct],
					orderProduct.pricing.currency,
				);
				const deposit =
					productWithUnits.deposit && orderProduct.deposit
						? sumOfObjectsNumericKeys(productWithUnits.deposit, orderProduct.deposit)
						: productWithUnits.deposit
						? productWithUnits.deposit
						: orderProduct.deposit
						? orderProduct.deposit
						: undefined;
				return {
					...productWithUnits,
					units: productWithUnits.units + 1,
					charge,
					...(deposit && { deposit }),
					includedIds: [...productWithUnits.includedIds, orderProduct.id],
					unConfirmed,
					...(isValidKeycardNumber(orderProduct) && {
						liftKeycardNumbers: !!productWithUnits.liftKeycardNumbers
							? [...productWithUnits.liftKeycardNumbers, orderProduct.liftKeycardNumber!]
							: [orderProduct.liftKeycardNumber!],
					}),
					pricing,
				};
			} else if (
				hasVariantList(productWithUnits) &&
				hasVariantList(orderProduct) &&
				productWithUnits.productApiId === orderProduct.productApiId &&
				arraysEqual(productWithUnits.summary.variantIds, orderProduct.summary.variantIds) &&
				productWithUnits.segment === orderProduct.segment &&
				productWithUnits.ticketId === orderProduct.ticketId &&
				productWithUnits.externalSegment?.externalSegmentId ===
					orderProduct.externalSegment?.externalSegmentId &&
				productWithUnits.endDate === orderProduct.endDate &&
				isEqual(productWithUnits?.subscription?.cycles, orderProduct?.subscription?.cycles)
			) {
				isFound = true;
				const charge = sumOfObjectsNumericKeys(productWithUnits.charge, orderProduct.charge);
				const pricing = getTotalProductPricing(
					[productWithUnits, orderProduct],
					orderProduct.pricing.currency,
				);
				const deposit =
					productWithUnits.deposit && orderProduct.deposit
						? sumOfObjectsNumericKeys(productWithUnits.deposit, orderProduct.deposit)
						: productWithUnits.deposit
						? productWithUnits.deposit
						: orderProduct.deposit
						? orderProduct.deposit
						: undefined;

				return {
					...productWithUnits,
					units: productWithUnits.units + 1,
					charge,
					...(deposit && { deposit }),
					includedIds: [...productWithUnits.includedIds, orderProduct.id],
					unConfirmed,
					...(isValidKeycardNumber(orderProduct) && {
						liftKeycardNumbers: !!productWithUnits.liftKeycardNumbers
							? [...productWithUnits.liftKeycardNumbers, orderProduct.liftKeycardNumber!]
							: [orderProduct.liftKeycardNumber!],
					}),
					pricing,
				};
			}
			return productWithUnits;
		});
		if (!isFound) {
			orderProductsWithUnits = [
				...orderProductsWithUnits,
				{
					...orderProduct,
					units: 1,
					includedIds: [orderProduct.id],
					unConfirmed,
					...(isValidKeycardNumber(orderProduct) && {
						liftKeycardNumbers: [orderProduct.liftKeycardNumber!],
					}),
				},
			];
		}
	}
	return orderProductsWithUnits;
};

export const findStockProductForOrderProduct = (
	orderProduct: OrderProduct,
	stockProducts: ProductApi[],
) => {
	for (const stockProduct of stockProducts) {
		if (stockProduct.id === orderProduct.productApiId) {
			return stockProduct;
		}
	}
	return null;
};

export const updateOrderProductsDuration = (
	products: OrderProduct[],
	duration: Duration,
): OrderProduct[] => {
	return products.map((product) => {
		return updateOrderProductDuration(product, duration);
	});
};

export const updateOrderProductsDates = (
	products: OrderProduct[],
	startDate: string | null,
	endDate: string | null,
): OrderProduct[] => {
	return products.map((product) => {
		return updateOrderProductDates(product, startDate, endDate);
	});
};

export const updateOrderProductDates = (
	product: OrderProduct,
	startDate: string | null,
	endDate: string | null,
): OrderProduct => {
	return {
		...product,
		startDate,
		endDate,
		unavailable: getInventoryBlocker(product.unavailable, {
			startDate,
			endDate,
			endDateReturned: product.endDateReturned,
		}),
	};
};

export const updateOrderProductsEndDate = (
	products: OrderProduct[],
	date: string,
): OrderProduct[] => {
	return products.map((product) => {
		return updateOrderProductEndDate(product, date);
	});
};

export const updateOrderProductEndDate = (product: OrderProduct, date: string): OrderProduct => {
	return {
		...product,
		endDate: date,
	};
};

export const updateOrderProductDuration = (
	product: OrderProduct,
	duration: Duration,
): OrderProduct => {
	const { durationInSeconds, durationType, durationName } = duration;
	return {
		...product,
		rentalDurationInSeconds: durationInSeconds || 0,
		durationType,
		durationName,
	};
};

export const setOrderProductsStartDates = (
	orderProducts: OrderProduct[],
	value: string,
): OrderProduct[] => {
	return orderProducts.map((product) => {
		return {
			...product,
			startDate: value,
		};
	});
};

export const getBaseProductFromStockProduct = (stockProduct: ProductApi): BaseProduct => {
	const {
		shopId,
		set,
		image,
		images,
		name,
		categoryIds,
		vatPercent,
		terms,
		description,
		additionalDetails,
		type,
		highlightProduct,
		segmentFilters,
		collectProductCode,
		customValuesToCollect,
		userDetails,
		bookingBuffer,
		collectDin,
		collectBootSoleLength,
		external,
		offerLiftTickets,
		segmentMapping,
		isKeycard,
		externalId,
		externalCategoryIds,
		startTimeSlots,
		startTimes,
		skidataProperties,
	} = stockProduct;
	const baseProduct: BaseProduct = {
		shopId,
		set,
		image,
		images,
		name,
		categoryIds,
		vatPercent,
		terms,
		description,
		additionalDetails,
		type,
		highlightProduct,
		segmentFilters,
		collectProductCode,
		customValuesToCollect,
		userDetails,
		bookingBuffer,
		collectDin,
		collectBootSoleLength,
		external,
		offerLiftTickets,
		segmentMapping,
		isKeycard,
		externalId,
		externalCategoryIds,
		startTimeSlots,
		startTimes,
		skidataProperties,
	};
	return removeUndefinedValues(baseProduct);
};

export const updateOrderProductsPricing = (args: {
	orderProducts: OrderProduct[];
	stockProducts: ProductApi[];
	savedPricingTables: PricingTableObject;
	channel: 'ADMIN' | 'ONLINE' | 'STORE';
	segment?: string;
	disableDeposit: boolean;
}) => {
	const {
		orderProducts,
		stockProducts,
		savedPricingTables,
		channel,
		segment,
		disableDeposit,
	} = args;
	return orderProducts.map((orderProduct) => {
		const stockProduct = findStockProductForOrderProduct(orderProduct, stockProducts);
		return !stockProduct
			? orderProduct
			: updateOrderProductPricing({
					orderProduct,
					stockProduct,
					savedPricingTables,
					channel,
					segment: orderProduct.segment ? orderProduct.segment : segment,
					disableDeposit,
			  });
	});
};

export const updateOrderProductPricing = (args: {
	orderProduct: OrderProduct;
	stockProduct: ProductApi;
	savedPricingTables: PricingTableObject;
	channel: 'ADMIN' | 'ONLINE' | 'STORE';
	segment?: string;
	disableDeposit: boolean;
}): OrderProduct => {
	const { orderProduct, stockProduct, savedPricingTables, channel, segment, disableDeposit } = args;
	const {
		rentalDurationInSeconds,
		durationType,
		durationName,
		pricing: { taxExcluded },
		vatPercent,
	} = orderProduct;
	const duration: Duration = {
		durationInSeconds: rentalDurationInSeconds || 0,
		durationType: durationType || '24h',
		durationName: durationName || null,
	};

	const pricingInfo = getPricingInfoPropertiesForProduct(
		stockProduct,
		savedPricingTables,
		getVariantPriceIncrease(orderProduct),
		channel,
		segment,
	);

	const { charge: listPrice, deposit } = getChargesFromPricingInfoForGivenDuration(
		pricingInfo,
		duration,
	);
	const subtotal = listPrice - orderProduct.pricing.totalDiscounts;
	const taxDetails = getTaxDetails(subtotal, {
		rate: getTaxRateFromVatPercent(vatPercent),
		taxExcluded: orderProduct.pricing.taxExcluded,
	});
	const totalPrice = taxExcluded ? subtotal + taxDetails.totalTaxes : subtotal;
	const isModifiedPrice = orderProduct.pricing.listPrice !== orderProduct.pricing.originalListPrice;
	const stockProductHasDeposit = !disableDeposit && stockProduct?.terms?.deposit !== undefined;

	if (isModifiedPrice) {
		return {
			...orderProduct,
			pricing: {
				...orderProduct.pricing,
				originalListPrice: listPrice,
				...(stockProductHasDeposit && { deposit }),
			},
		};
	} else {
		return {
			...orderProduct,
			pricing: {
				...orderProduct.pricing,
				total: totalPrice,
				subtotal,
				listPrice,
				originalListPrice: listPrice,
				taxLines: taxDetails.taxLines,
				totalTaxes: taxDetails.totalTaxes,
				...(stockProductHasDeposit && { deposit }),
			},
		};
	}
};

export const getFixedStockPropertiesForProduct = (
	product: ProductApi,
	dinCollectionMethod: DinCollectionMethod,
	useDinVariation: boolean,
): FixedStockProperty[] => {
	const fixedStockProperties: FixedStockProperty[] = [];
	if (product.collectDin && dinCollectionMethod === 'automatic') {
		fixedStockProperties.push('bindingType');
		if (useDinVariation) {
			fixedStockProperties.push('dinVariation');
		}
	}
	if (product.collectBootSoleLength) {
		fixedStockProperties.push('soleLength');
	}
	return fixedStockProperties;
};

export const getVariantIdsForProductArray = (products: ProductApi[]) => {
	return products
		.map((p) => getProductVariants(p).map((v) => v.id))
		.reduce((previous, current) => previous.concat(current), []);
};

export const getDurationInSecondsForOrderProduct = (product: OrderProduct) => {
	if (!product.startDate || !product.endDate) {
		return 0;
	}
	return moment(product.endDate).diff(moment(product.startDate), 'seconds');
};

/**
 * Given the parameters, returns whether or not a product picker button is disabled or not,
 * and its color that indicates high or low availability or stock-out.
 *
 * Note that the availability parameter already takes into account the required amount (which is
 * also supplied as another parameter). The required parameter is used for disabling the subtract
 * button when required === 0.
 *
 * @param isQuantityLoaded Quantities have been fetched from firebase
 * @param availability = total quantity (can be grand total or total after already booked
 * rentals) - items in cart - required
 * @param required How many items are you trying to reserve right now
 * @param type minus or plus button
 * @param unitsUntilLimit used in case availability is undefined
 * @param setIdsOfVariantLength
 */

export const getOnlineButtonProps = (
	itemsLeftToAdd: number | undefined,
	isQuantityLoaded: boolean,
	unitsUntilLimit?: number,
	isAddedItemOverOrderLimit: boolean = false,
): { isDisabled: boolean; color: 'inherit' | 'disabled' } => {
	const isDisabled =
		(itemsLeftToAdd !== undefined && itemsLeftToAdd < 1) ||
		!isQuantityLoaded ||
		isAddedItemOverOrderLimit ||
		(unitsUntilLimit !== undefined && unitsUntilLimit <= 0);
	return {
		isDisabled,
		color: isDisabled ? 'disabled' : 'inherit',
	};
};

export const setHasMultipleVariantsInMultipleProducts = (
	stockProduct: ProductApi,
	stockProductsData: ProductApi[],
): boolean => {
	const setProducts = getProductsInPackage(stockProduct, stockProductsData);
	return (
		setProducts
			.map((product) => getProductVariants(product).length)
			.filter((variants) => variants > 1).length > 1
	);
};

export const selectedItemsAreValid = (selectedItems: OrderProduct[]): boolean => {
	return selectedItems.every(
		(item) => item.summary.variantIds.length === item.productApiIds.length,
	);
};

export const groupProductsBy = (
	products: OrderProduct[],
	keyGetter: (product: OrderProduct) => string,
) => {
	let returnObject: { [key: string]: OrderProduct[] } = {};
	products.forEach((item: OrderProduct) => {
		let key: string = '';
		key = keyGetter(item);
		if (returnObject[key]) {
			returnObject[key] = [...returnObject[key], item];
		} else {
			returnObject[key] = [item];
		}
	});
	return returnObject;
};

export const getCategoryProducts = (products: ProductApi[], categoryId: string) => {
	if (categoryId === undefined) {
		return products;
	}
	return products.filter((product) => {
		if (product.categoryIds && product.categoryIds.length > 0) {
			return product.categoryIds.includes(categoryId);
		}
		if (
			(!product.categoryIds || (product.categoryIds && product.categoryIds.length === 0)) &&
			!categoryId
		) {
			return true;
		}
		return false;
	});
};

export const updateOrderProducts = ({
	products,
	stockProducts,
	shoppers,
	shop,
	startDate,
	currencyCode,
	keepProductCodes,
	channel,
	openingHours,
	disableDeposit,
}: UpdateOrderProductsCreation) => {
	return products
		.map((product) => {
			const stockProduct = stockProducts.find((sp) => sp.id === product.productApiId);
			if (stockProduct) {
				const {
					id,
					shopperId,
					rentalId,
					endDate,
					rentalDurationInSeconds,
					durationType,
					durationName,
					variant,
					selectedAsAdditional,
					pricing: { taxExcluded },
					summary,
					purchaseType,
				} = product;
				const shopper = shoppers.find((s) => s.id === product.shopperId);
				// Calculate new endDate from new startDate and old product duration
				const productDuration: Duration = {
					durationInSeconds: rentalDurationInSeconds || 0,
					durationType: durationType || '24h',
					durationName: durationName || null,
				};
				const newDateProperties = getNewDateProperties(
					{
						new: { startDate: startDate || undefined },
						old: { duration: productDuration, endDate: endDate || undefined },
					},
					openingHours,
					shop.publicInfo.timeZone,
				);
				const newEndDate = newDateProperties.endDate || endDate;

				const partialOrderProductFields: PartialOrderProductFields = {
					id,
					shopperId,
					rentalId,
					rentalDurationInSeconds,
					selectedAsAdditional,
					durationType,
					durationName,
					startDate,
					endDate: newEndDate,
				};
				const orderProductCreationObject: OrderProductCreation = {
					stockProduct: stockProduct,
					stockProductListForSetProducts: stockProducts,
					variantId: variant?.id ?? null,
					variantIdsForSetProducts: summary.variantIds,
					savedPricingTables: shop.publicInfo.pricingTables || {},
					partialOrderProductFields,
					segment: shopper && shopper.segment ? shopper.segment : null,
					currencyCode,
					taxExcluded,
					channel,
					purchaseType,
					timezone: shop.publicInfo.timeZone,
					openingHours,
					disableDeposit,
				};
				let newProduct = createOrderProductFromProductApi(orderProductCreationObject);
				if (keepProductCodes && purchaseType !== PurchaseTypes.sales) {
					const newSetProducts = (newProduct.setProducts || []).map((sP, index) => {
						const originalSetProduct = product.setProducts[index];
						if (originalSetProduct && originalSetProduct.productCode) {
							return {
								...sP,
								...(originalSetProduct.productCode && {
									productCode: product.setProducts[index].productCode,
								}),
								...(originalSetProduct.dinValues && { dinValues: originalSetProduct.dinValues }),
								...(originalSetProduct.customValues && {
									customValues: originalSetProduct.customValues,
								}),
							};
						}
						return sP;
					});
					newProduct = {
						...newProduct,
						setProducts: newSetProducts,
						...(product.productCode && { productCode: product.productCode }),
						...(product.dinValues && { dinValues: product.dinValues }),
						...(product.customValues && { customValues: product.customValues }),
					};
				}
				return newProduct;
			}
			return null;
		})
		.filter((product) => Boolean(product)) as OrderProduct[];
};

export const getSegmentForProducts = (products: OrderProduct[]): string => {
	const rentleSegments = ['kid', 'teen', 'regular', 'adult', 'senior'];
	const scores = {
		kid: 1,
		teen: 2,
		senior: 3,
		adult: 10,
		regular: 10,
	};
	const segments = products.map((product) => product.segment || '').filter((seg) => seg.length);
	if (!segments.every((segment) => rentleSegments.includes(segment))) {
		return segments.find((segment) => !rentleSegments.includes(segment))!;
	} else {
		return segments.filter((s) => s !== 'regular').length
			? segments.reduce(
					(result, curr) => (scores[curr] && scores[curr] < scores[result] ? curr : result),
					segments[0],
			  )
			: 'regular';
	}
};

export const productHasUnlimitedStock = (product: ProductApi) => {
	const isPackage = isPackageProduct(product);
	if (isPackage) return false;
	if (
		!isPackage &&
		product.variants.options.every(
			(variant) => !variant.stockSources || isEmpty(variant.stockSources),
		)
	)
		return true;
	if (isLiftTicketProduct(product)) return true;
	return false;
};

export const productHasStartTimesDefined = (product: ProductApi) => {
	const usesDefaultStartTimes = product.startTimes === undefined;
	const hasStartTimes = !!product.startTimes?.value?.length;
	return usesDefaultStartTimes || hasStartTimes;
};

export const productHasStartTimeLimits = (product: ProductApi, purchaseType: PurchaseType) => {
	return (
		(isRentalPurchaseType(purchaseType) || isSubscriptionPurchaseType(purchaseType)) &&
		product.startTimes?.maxOrdersPerStartTime != null
	);
};

export const filterByPaymentMethods = <T extends ProductApi | Category>(
	list: T[],
	shopPaymentMethods: ShopOnlinePaymentMethodObject[],
) =>
	isOnlinePaymentsInUse(shopPaymentMethods)
		? list
		: list.filter((item) => item.external !== 'LIFT_TICKET');

export const getProductRowDetail = (product: OrderProduct, lang?: string) => {
	return Object.values(product.variant?.values ?? {})
		.map((variantValue) => variantValue[lang ?? 'def'])
		.join(', ');
};

export const getProductRowKey = (product: OrderProduct) => {
	const variants = product.summary.variantIds.slice().sort().join('');
	const dates = `${product.startDate}_${product.endDate}`;
	return `${product.productApiId}__${variants}__${dates}`;
};

export const hasVariantsToConfigure = (product: ProductApi, productsById: Hash<ProductApi>) =>
	hasMultipleVariants(product) ||
	getProductsInPackage(product, productsById).some(hasMultipleVariants);

export const getMostRestrictiveBookingBuffer = (
	buffers: BookingBuffer[],
): BookingBuffer | undefined => {
	if (!buffers.length) return undefined;
	return buffers.reduce((prev: BookingBuffer, curr) => {
		if (curr.daysBefore < prev.daysBefore) return prev;
		if (curr.daysBefore > prev.daysBefore) return curr;
		if ((curr.latestTime ?? Infinity) < (prev.latestTime ?? Infinity)) {
			return curr;
		}
		if ((curr.minutesBefore ?? 0) > (prev.minutesBefore ?? 0)) {
			return curr;
		}
		return prev;
	});
};

export const getOrderProductMaintenanceMinutes = (
	product: OrderProduct | CartProduct,
	stockProducts: ProductApi[],
) => {
	const stockProduct = stockProducts.find((s) => s.id === product.productApiId);
	if (!stockProduct) return 0;
	return getStockProductMaintenanceMinutes(stockProduct, stockProducts);
};

export const getStartDateWithInventoryBlockers = (args: {
	startDate: string | null;
	deliveryProps?: {
		deliveryOption: DeliveryOption;
		cartDelivery: CartDelivery;
		timezone: string;
		openingHours: OpeningHours;
	};
}) => {
	const { startDate, deliveryProps } = args;
	const deliveryInventoryBlockerMinutes = !!deliveryProps
		? getDeliveryInventoryBlockersBeforeAsMinutes(deliveryProps)
		: 0;
	return moment(startDate).subtract(deliveryInventoryBlockerMinutes, 'minutes').toISOString();
};

export const getEndDateWithInventoryBlockers = (
	endDate: string | null,
	product: ProductApi,
	stockProducts: ProductApi[],
) => {
	const inventoryBlockersInMinutes = getProductInventoryBlockerMinutes(product, stockProducts);
	return moment(endDate).add(inventoryBlockersInMinutes, 'minutes').toISOString();
};

export const getProductInventoryBlockerMinutes = (
	product: ProductApi,
	stockProducts: ProductApi[],
) => {
	return getStockProductMaintenanceMinutes(product, stockProducts);
};

export const getProductNameFromIds = (
	productIds: string[],
	lang: Languages,
	stockProducts: ProductApi[],
) => {
	const productName = stockProducts
		?.filter((p) => productIds.some((product) => product.includes(p.id)))
		.map(({ name }) => getTranslation(name, lang));

	return productName;
};

export const getProductImageSrc = (product: ProductApi | OrderProduct | CartProduct) =>
	!!product.images?.length ? product.images[0] : product.image;

export const getProductImageSrcs = (product: ProductApi | OrderProduct | CartProduct) => {
	const singleImgSrc = getProductImageSrc(product);
	return !!product.images ? product.images : !!singleImgSrc ? [singleImgSrc] : undefined;
};

export const toMultiImgFormat = (urls: string | string[] | undefined) =>
	!urls || !urls.length ? undefined : Array.isArray(urls) ? urls : [urls];

export const getItemsWithBasePrice = (items: PricingItem[], basePrice: number) => {
	return items.map((item) => ({
		...item,
		price: Math.round((item.multiplier || 0) * basePrice),
	}));
};

export const isOrderProductReturned = (product: OrderProduct) => !!product.endDateReturned;

export const isFixedPriceProduct = (product: ProductApi) =>
	!product.pricing?.length && !product.useSavedPricingTable;
