import React from 'react';

import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Plan } from 'common/modules/plans';
import HasFeature from 'components/features/HasFeature';
import LockedButton from 'components/features/LockedButton';
import { useTranslation } from 'services/localization';
import { Routes, push } from 'routing';

interface UpgradePlanButtonProps {
	plan?: Plan;
}

const UpgradePlanButton = (props: UpgradePlanButtonProps) => {
	const { plan } = props;
	const { t } = useTranslation();
	const location = useLocation();

	const handleClick = () => {
		if (!!plan) {
			push(Routes.PlanConfirm(plan), { from: location.pathname });
		} else {
			push(Routes.PLANS, { from: location.pathname });
		}
	};
	return (
		<HasFeature
			requiredFeature={['PLAN_BILLING', 'ADD_ONS']}
			fallback={
				<LockedButton color="primary" variant="contained" fullWidth>
					{t('shop.billingView.upgradePlan')}
				</LockedButton>
			}
		>
			<Button color="primary" variant="contained" onClick={handleClick} fullWidth>
				{t('shop.billingView.upgradePlan')}
			</Button>
		</HasFeature>
	);
};

export default UpgradePlanButton;
