import {
	BufferTimeDayTypes,
	BufferTimeUnit,
	BufferTimeUnits,
	FixedBufferTimeWithType,
	NoneBufferTimeWithType,
	RelativeBufferTimeWithType,
} from './types';

export const NONE_BUFFER_TIME: NoneBufferTimeWithType = {
	type: 'none',
};

export const EMPTY_FIXED_BUFFER_TIME: FixedBufferTimeWithType = {
	type: 'fixed',
	value: {
		unit: BufferTimeUnits.days,
		amount: 0,
	},
};

export const EMPTY_FIXED_BUFFER_TIME_MINUTES: FixedBufferTimeWithType = {
	type: 'fixed',
	value: {
		unit: BufferTimeUnits.minutes,
		amount: 0,
	},
};

export const EMPTY_RELATIVE_BUFFER_TIME: RelativeBufferTimeWithType = {
	type: 'relative',
	value: {
		days: 0,
		timeOfDay: '00:00',
		dayType: BufferTimeDayTypes.days,
	},
};

/**
 * Maximum range to check for buffer times when using opening days as the criteria.
 * This is used to prevent infinite loops when checking for buffer times, in case
 * the store is closed from a certain date to perpetuity.
 */
export const MAX_BUFFER_RANGE_DAYS = 99;

/**
 * Maximum values for each of the units
 *
 */
export const MAX_AMOUNT_PER_BUFFER_TIME_UNIT: Record<BufferTimeUnit, number> = {
	days: 999,
	weekDays: 30,
	openingDays: 30,
	hours: 999,
	minutes: 999,
};

/**
 * Maximum buffer time in minutes - larger values than this can be regarded as not available
 */
export const MAX_BUFFER_TIME_MINUTES = 24 * 60 * 1000;
