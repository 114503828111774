import React from 'react';

import { Card, CardContent, Typography } from '@mui/material';

import { useTranslation } from 'services/localization';

const CtaTestimonial = () => {
	const { t } = useTranslation();
	return (
		<Card sx={{ borderRadius: '8px', boxShadow: '4px 4px 0px 0px #275F69' }}>
			<CardContent>
				<Typography variant="body1" sx={{ color: (theme) => theme.palette.primary.main, mb: 1 }}>
					{`“${t(
						'register.ctaTestimonial.quote',
						'Highly customizable scheduling to fit your business needs. Security deposit feature. Very easy to manage inventory, and changes go live in seconds. The support team has been great from the start',
					)}.”`}
				</Typography>
				<Typography
					variant="body2"
					sx={{ color: (theme) => theme.palette.primary.main, fontWeight: 500 }}
				>
					{`Sam R - ${t('register.ctaTestimonial.jobTitle', 'Small Business Owner')}`}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default CtaTestimonial;
