import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { buildQuery, createBasicDbOperations } from '../utils';
import { PendingExtendRequestApi } from 'common/types';

export const getPendingExtendRequestQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.pendingExtendRequests;
	const dataUpdater = undefined;
	const query = buildQuery<PendingExtendRequestApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<PendingExtendRequestApi>(collectionPath, dbRefs, dataUpdater),
	};
};
