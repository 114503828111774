import moment from 'moment-timezone';

import {
	BufferTime,
	getFixedBufferTimeAsMinutes,
	getRelativeBufferTimeAsMinutes,
} from 'common/modules/atoms/bufferTimes';
import { OpeningHours } from 'common/modules/openingHours';
import { switchUnreachable } from 'common/utils/common';

export const calculateExpirationDate = (args: {
	timestamp: string;
	startDate: string;
	openingHours: OpeningHours;
	timezone: string;
	expirationPeriod?: BufferTime;
}) => {
	const { timestamp, startDate, openingHours, timezone, expirationPeriod } = args;
	if (!expirationPeriod) return undefined;
	const { type } = expirationPeriod;

	switch (type) {
		case 'none':
			return undefined;
		case 'fixed':
			const fixedTimeAsMinutes = getFixedBufferTimeAsMinutes({
				value: expirationPeriod.value,
				from: timestamp,
				direction: 'after',
				openingHours,
			});
			return moment(timestamp).add(fixedTimeAsMinutes, 'minutes').toISOString();
		case 'relative':
			const relativeTimeAsMinutes = getRelativeBufferTimeAsMinutes({
				value: expirationPeriod.value,
				from: startDate,
				direction: 'before',
				openingHours,
				timezone,
			});
			return moment(startDate)
				.subtract(relativeTimeAsMinutes, 'minutes')
				.startOf('hour')
				.toISOString();
		default:
			return switchUnreachable(type);
	}
};
