import moment from 'moment-timezone';

import { Duration, DurationWithPrice } from 'common/types';

import { getDurationInSecondsAsYears } from './dateCalculations';

export const isSameDurationOption = (
	duration1: Duration,
	duration2: Duration,
	opts?: { ignoreLabel?: boolean },
) => {
	const sameDurationType = duration1.durationType === duration2.durationType;
	const sameName =
		!!opts?.ignoreLabel ||
		(duration1.durationName?.def || undefined) === (duration2.durationName?.def || undefined);
	if (!sameDurationType || !sameName) {
		return false;
	}
	if (duration1.durationType === '24h') {
		return duration1.durationInSeconds === duration2.durationInSeconds;
	}
	const daysInDuration1 = Math.ceil(
		moment.duration(duration1.durationInSeconds, 'seconds').asDays(),
	);
	const daysInDuration2 = Math.ceil(
		moment.duration(duration2.durationInSeconds, 'seconds').asDays(),
	);
	return daysInDuration1 === daysInDuration2;
};

export const getLongestDurationOptionAsYears = (options: DurationWithPrice[]) =>
	Math.max(...options.map((o) => getDurationInSecondsAsYears(o.durationInSeconds)));
