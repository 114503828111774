import { Plan } from 'common/modules/plans';
import { ByCapabilityOption, ByCustomerPersona, ByIndustry, EnumValues } from 'common/types';
import { stringEnum } from 'common/utils/objects';

export const CustomerPersonas = stringEnum(['DISCOVERING', 'REPLACING', 'PLAYING', 'AGENCIES']);
export type CustomerPersona = EnumValues<typeof CustomerPersonas>;

export const RevenueOptions = stringEnum(['0-5k', '5-50k', '50-250k', '250k-1M', '1M+']);
export type RevenueOption = EnumValues<typeof RevenueOptions>;

export const MerchantProfiles = stringEnum(['A_1', 'A_2', 'B', 'C']);
export type MerchantProfile = EnumValues<typeof MerchantProfiles>;

/**
 * @deprecated, We should use the GroupedIndustryCategories in the future. However, we still need to keep them as they are used in the analytics.
 * Axel Cedercreutz 17th of October 2023
 * */

const DetailedIndustryCategories = stringEnum([
	'APARTMENT_ROOMS',
	'ART_HANDCRAFTS',
	'AV_EQUIPMENT',
	'BABY_MATERNITY',
	'BICYCLES',
	'BOATS_YACHTS',
	'CARS_AUTOMOTIVE',
	'CONSUMER_ELECTRONICS',
	'ENTRANCE_TICKETS',
	'EVENTS_DECOR',
	'FURNITURE',
	'GAMES_TOYS',
	'GUIDED_ACTIVITIES_TOURS',
	'MOTORSPORTS',
	'OCCASION_WEAR',
	'OTHER_SPORTS',
	'OTHER',
	'OUTDOOR_EQUIPMENT',
	'POOLS_SAUNAS',
	'SCOOTERS_MOTORCYCLES',
	'SPECIALTY_ITEMS',
	'STREET_FASHION',
	'TOOLS_CONSTRUCTION',
	'UTILITY_VEHICLES',
	'WATERSPORTS',
	'WINTERSPORTS',
]);

export const GroupedIndustryCategories = stringEnum([
	'SPORTS_OUTDOORS',
	'VEHICLES',
	'EVENTS_AV',
	'CLOTHING_ACCESSORIES',
	'OTHER',
]);

export type IndustryCategory =
	| EnumValues<typeof DetailedIndustryCategories>
	| EnumValues<typeof GroupedIndustryCategories>;

export const IndustrySegments = stringEnum([
	'ACTIVITIES_AREAS',
	'CLOTHING_ACCESSORIES',
	'EVENTS_LEISURE',
	'OTHER',
	'SPORTS',
	'TOOLS_APPLIANCES',
	'VEHICLES',
]);

export type IndustrySegment = EnumValues<typeof IndustrySegments>;

export interface IndustryOption {
	type: IndustryCategory;
	heading: string;
	segment?: IndustrySegment;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
}

export type Session = {
	timestamp: string;
	landingPage?: string;
	referrer?: string;
	source?: string;
	medium?: string;
	campaign?: string;
	term?: string;
	content?: string;
	id?: string;
	fbClId?: string;
	gClId?: string;
	gClSrc?: string;
	dClId?: string;
	msClkId?: string;
	aff?: string;
};

export const ShopSetups = stringEnum(['TEMPLATE', 'VANILLA']);
export type ShopSetup = EnumValues<typeof ShopSetups>;

export interface ShopAnalytics {
	id: string;
	shopId?: string;
	merchantProfile?: MerchantProfile;
	customerPersona?: {
		value: CustomerPersona;
		specifications?: ByCustomerPersona<string>;
	};
	industryCategories?: {
		values: IndustryCategory[];
		specifications?: ByIndustry<string>;
	};
	capabilities?: {
		values: CapabilityOptionName[];
		specifications?: ByCapabilityOption<string>;
	};
	shopSetup?: ShopSetup;
	promotionCode?: string;
	revenue?: RevenueOption;
	showOnboarding?: boolean;
	onboardingActions?: {
		[key in OnboardingAction]?: boolean;
	};
	churn?: {
		timestamp: string;
		reasonsForDowngrade: ChurnReason[];
		missingFeatures?: string;
		nextPlatform?: string;
		additionalInformation?: string;
	};
	sessions?: {
		initial: Session | null;
		signup: Session | null;
	};
}

export const OnboardingItems = [
	'APPEARANCE_ONLINE_STORE',
	'ARTICLE_CREATION',
	'BOOKING_COMPLETION',
	'BOOKING_CREATION',
	'BUSINESS_CONTACT_INFORMATION',
	'BUSINESS_ID',
	'CANCELLATION_POLICY',
	'CREATE_CATEGORY',
	'CREATE_MULTIPLE_PRODUCTS',
	'EDIT_PRODUCT_PRICING',
	'EDIT_PRODUCT_QUANTITY',
	'LINK_ONLINE_STORE',
	'LOGO',
	'OPENING_HOURS',
	'PAYMENTS',
	'PRIVACY_POLICY',
	'PRODUCT_SKU_CONNECTION',
	'RECOMMEND_ADD_ON_PRODUCTS',
	'REGIONAL_FORMATS',
	'SKU_CREATION',
	'STORE_DETAILS',
	'TAX_RATES',
	'USERS',
	'TERMS_OF_SERVICE',
	'VERIFY_LANGUAGE',
	'VIEW_CATALOG',
	'VISIT_BLOCK_STORE',
	'VISIT_DELIVERY',
	'VISIT_INVENTORY',
	'VISIT_INVENTORY_SUPPORT_LINK',
	'VISIT_ONLINE_STORE',
	'PRODUCT_CREATION',
	'WAIVER_AND_POLICY',
	'INTRO_VIDEO',
] as const;

export type OnboardingItem = typeof OnboardingItems[number];

export const OnlyOnboardingActions = [
	'VISIT_ONLINE_STORE_MODAL',
	'VISIT_ONLINE_STORE_BUTTON',
	'VISIT_BOOKINGS', // Deprecated
] as const;

export const OnboardingActions = [...OnboardingItems, ...OnlyOnboardingActions] as const;

export type OnboardingAction = typeof OnboardingActions[number];

export const OnboardingSteps = stringEnum([
	'GET_TO_KNOW_RENTLE',
	'CUSTOMIZE_ONLINE_STORE',
	'PRODUCT_CATALOG',
	'LINK_STORE',
	'PAYMENTS',
	'ADDITIONAL_FEATURES',
	'LOCALIZATION',
	'INVENTORY',
	'USERS',
	'BUSINESS_AND_CONTACT_INFORMATION',
	'WAIVER',
	'HIGHLIGHTED_FEATURES',
]);

export type OnboardingStep = keyof typeof OnboardingSteps;

export const OnboardingSections = stringEnum([
	'QUICK_START',
	'HIGHLIGHTED_FEATURES',
	'READY_FOR_CUSTOMERS',
	'CONFIDENCE_AND_COLLABORATION',
	'NO_SECTION',
]);

export type OnboardingSection = keyof typeof OnboardingSections;

/**

Enum for the available versions of the Onboarding List.
@property {string} V3_USERFLOW - The Onboarding List version used for the A/B test of using userflow checklists.
@property {string} V3 - The Onboarding List version, released 2023-09-05.
@property {string} V2_TEMPLATE - The Onboarding List version with a template, released 2023-06-27.
@property {string} V2_NO_TEMPLATE - The Onboarding List version without a template, released 2023-06-27.
@property {string} V1_TEMPLATE - The Onboarding List version with a template, released 2023-02-24.
@property {string} V1_NO_TEMPLATE - The Onboarding List version without a template, released 2023-02-24.
@property {string} V0 - The initial version of the Onboarding List, prior to 2023-02-24.
*/

export const OnboardingListVersions = stringEnum([
	'V3_USERFLOW',
	'V3',
	'V2_TEMPLATE',
	'V2_NO_TEMPLATE',
	'V1_TEMPLATE',
	'V1_NO_TEMPLATE',
	'V0',
]);

export type OnboardingListVersion = keyof typeof OnboardingListVersions;

export type OnboardingItemDefinition = {
	plans: Plan[] | 'all' | 'none';
};

export type OnboardingStepDefinition = {
	plans: Plan[] | 'all' | 'none';
	versions:
		| {
				[key in 'all' | OnboardingListVersion]?: OnboardingItem[];
		  }
		| null;
};

export type ChurnReason =
	| 'FEATURES'
	| 'PRICE'
	| 'SALES'
	| 'SETTING_UP'
	| 'TEMPORARY_USE'
	| 'CLOSING_STORE'
	| 'OTHER_PLATFORM'
	| 'OTHER_REASON_FOR_DOWNGRADE';

export const CAPABILITY_QUESTION_NAMES = stringEnum(['OFFERING', 'CHANNELS', 'FULFILLMENT']);

export type CapabilityQuestionName = EnumValues<typeof CAPABILITY_QUESTION_NAMES>;

export const OFFERING_OPTION_NAMES = stringEnum([
	'RENTALS',
	'SALES',
	'SUBSCRIPTIONS',
	'SERVICE_BOOKINGS',
	'BOOKINGS', // Deprecated, not in use anymore but still in the database
	'OTHER', // Deprecated, not in use anymore but still in the database
]);

export const CHANNELS_OPTION_NAMES = stringEnum([
	'ONLINE_STORE',
	'EMAIL_OR_PHONE',
	'IN_PERSON',
	'SELF_SERVICE',
]);

export const FULFILLMENT_OPTION_NAMES = stringEnum([
	'LOCAL_DELIVERY',
	'POSTAL_SHIPPING',
	'PICKUP_POINTS',
	'IN_STORE',
]);

export type CapabilityOptionName =
	| EnumValues<typeof OFFERING_OPTION_NAMES>
	| EnumValues<typeof CHANNELS_OPTION_NAMES>
	| EnumValues<typeof FULFILLMENT_OPTION_NAMES>;
