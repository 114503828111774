import { percentageOf } from 'common/utils/math';

import { OrderServiceFee, StoreServiceFee } from './types';

export const getOrderServiceFee = (
	serviceFee: StoreServiceFee,
	totalPrice: number,
	opts: { taxExcluded: boolean },
): OrderServiceFee => {
	const { fixed, percentage } = serviceFee.rate;
	const percentagePrice = Math.round(percentageOf(percentage * 100, totalPrice));
	const subtotal = fixed + percentagePrice;
	const totalTax = Math.round(percentageOf(serviceFee.tax.rate * 100, subtotal));
	const total = opts.taxExcluded ? subtotal + totalTax : subtotal;
	return {
		...serviceFee,
		total: Math.round(total),
		taxLines: [
			{
				label: serviceFee.tax.label,
				price: totalTax,
				rate: serviceFee.tax.rate,
			},
		],
		taxExcluded: opts.taxExcluded,
	};
};
