import React from 'react';

import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';

export interface Props {
	copies: {
		title: string;
		price: string;
		listItems: {
			label: string;
		}[];
		buttonLabel: string;
	};
	onClick: () => void;
	sx?: SxProps<Theme>;
}
const PlanUpgradeCtaCard = (props: Props) => {
	const { copies, sx, onClick } = props;
	const { title, price, listItems, buttonLabel } = copies;
	return (
		<Stack
			sx={{
				...sx,
				border: (theme) => `1px solid ${theme.palette.border.divider}`,
				borderRadius: '4px',
				width: '100%',
			}}
			py={3}
			px={2}
			alignItems="center"
			justifyContent="space-around"
			spacing={3}
		>
			<Stack>
				<Typography variant="h5">{title}</Typography>
				<Typography>{price}</Typography>
			</Stack>
			<ul style={{ paddingLeft: '16px' }}>
				{listItems.map((item) => (
					<li>
						<Typography variant="body2" sx={{ fontWeight: 400 }}>
							{item.label}
						</Typography>
					</li>
				))}
			</ul>
			<Box>
				<Button onClick={onClick} variant={sx ? 'contained' : 'outlined'}>
					{buttonLabel}
				</Button>
			</Box>
		</Stack>
	);
};

export default PlanUpgradeCtaCard;
