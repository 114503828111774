import React, { useState } from 'react';

import { Box, Button, Stack, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import useCarousel from 'common/hooks/useCarousel';
import { useTranslation } from 'services/localization';

import CtaCarouselItem from './CtaCarouselItem';

interface CarouselProps {
	content: {
		title: string;
		text: string;
	}[];
}

const CtaCarousel: React.FC<React.PropsWithChildren<CarouselProps>> = ({ content }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { activeIndex, setActiveIndex } = useCarousel({
		itemCount: content.length,
		autoplayInterval: 7500,
	});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	return (
		<Stack sx={{ minHeight: 170 }}>
			<motion.div
				key={`div-ref-${anchorEl?.id}`}
				animate={{ height: anchorEl?.clientHeight, transition: { duration: 0.5 } }}
			>
				<Box>
					{content.map(({ title, text }, index) => {
						if (index !== activeIndex) return <React.Fragment key={title + index}></React.Fragment>;
						return (
							<AnimatePresence key={`item-div-${title + index}`} initial={false}>
								<motion.div
									initial={{ opacity: 0 }}
									animate={{
										opacity: 1,
										height: anchorEl?.clientHeight,
										transition: { duration: 0.5 },
									}}
									exit={{ opacity: 0, transition: { duration: 0.3 } }}
									style={{
										maxWidth: '100%',
										position: 'relative',
									}}
								>
									<CtaCarouselItem
										title={title}
										text={text}
										key={`item-${title + index}`}
										innerRef={setAnchorEl}
									/>
								</motion.div>
							</AnimatePresence>
						);
					})}
				</Box>
			</motion.div>
			<Stack direction="row" spacing={0.5} mt={2}>
				{content.map((_child, index) => (
					<Button
						key={index}
						onClick={() => setActiveIndex(index)}
						sx={{
							backgroundColor:
								activeIndex === index ? theme.palette.primary.main : theme.palette.primary.lighter,
							height: 8,
							width: 8,
							borderRadius: '50%',
							cursor: 'pointer',
							minWidth: 0,
							padding: 0,
							'&:hover': {
								backgroundColor: theme.palette.primary.main,
							},
						}}
						aria-label={`${t('register.goToSlide', 'Go to slide')} ${index + 1}`}
					/>
				))}
			</Stack>
		</Stack>
	);
};

export default CtaCarousel;
