import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.manualPaymentMethods.name', 'Manual Payment Methods'),
	subHeader: t(
		'common:blocks.details.manualPaymentMethods.subHeader',
		'Flexible payments for you and your customers',
	),
	description: t(
		'common:blocks.details.manualPaymentMethods.description',
		'Create a personalized checkout experience',
	),
	supportArticle: SupportArticles.MANUAL_PAYMENT_METHODS,
	uninstallNotice: t(
		'common:blocks.details.manualPaymentMethods.uninstallNotice',
		'If you uninstall this add-on, your manual payment methods will be removed',
	),
});

export default details;
