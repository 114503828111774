import { EnumValues } from 'common/types/utils';

export const Scopes = {
	READ_PRODUCTS: 'read:products',
	CREATE_PRODUCTS: 'create:products',
	UPDATE_PRODUCTS: 'update:products',
	DELETE_PRODUCTS: 'delete:products',

	READ_SHOPS: 'read:shops',

	CREATE_ORDERS: 'create:orders',
	READ_ORDERS: 'read:orders',
	UPDATE_ORDERS: 'update:orders',
	DELETE_ORDERS: 'delete:orders',

	READ_CUSTOMERS: 'read:customers',

	READ_INVENTORY_ARTICLE_EVENTS: 'read:inventory-article-events',
	CREATE_INVENTORY_ARTICLE_EVENTS: 'create:inventory-article-events',
	UPDATE_INVENTORY_ARTICLE_EVENTS: 'update:inventory-article-events',
	DELETE_INVENTORY_ARTICLE_EVENTS: 'delete:inventory-article-events',

	READ_INVENTORY_ARTICLES: 'read:inventory-articles',
	CREATE_INVENTORY_ARTICLES: 'create:inventory-articles',
	UPDATE_INVENTORY_ARTICLES: 'update:inventory-articles',
	DELETE_INVENTORY_ARTICLES: 'delete:inventory-articles',

	READ_INVENTORY_FIELDS: 'read:inventory-fields',
	CREATE_INVENTORY_FIELDS: 'create:inventory-fields',
	UPDATE_INVENTORY_FIELDS: 'update:inventory-fields',
	DELETE_INVENTORY_FIELDS: 'delete:inventory-fields',

	READ_INVENTORY_SKUS: 'read:inventory-skus',
	CREATE_INVENTORY_SKUS: 'create:inventory-skus',
	UPDATE_INVENTORY_SKUS: 'update:inventory-skus',
	DELETE_INVENTORY_SKUS: 'delete:inventory-skus',

	READ_CATEGORIES: 'read:categories',

	READ_DISCOUNT_CODES: 'read:discount-codes',
	CREATE_DISCOUNT_CODES: 'create:discount-codes',
	UPDATE_DISCOUNT_CODES: 'update:discount-codes',
	DELETE_DISCOUNT_CODES: 'delete:discount-codes',

	READ_MERCHANT: 'read:merchant',

	READ_WEBHOOKS: 'read:webhooks',
	CREATE_WEBHOOKS: 'create:webhooks',
	UPDATE_WEBHOOKS: 'update:webhooks',
	DELETE_WEBHOOKS: 'delete:webhooks',
} as const;

export type Scope = EnumValues<typeof Scopes>;

export const ADMIN_API_PATH = 'admin';
export const STOREFRONT_API_PATH = 'storefront';

export const ADMIN_API_DOCS_URL = `https://api.rentle.io/${ADMIN_API_PATH}`;
export const LEGACY_API_DOCS_URL = 'https://api.rentle.io/docs';

export const WEBHOOKS_API_DOCS_URL = `https://api.rentle.io/${ADMIN_API_PATH}/#tag/WebhooksOverview`;

export const AdminApiUrls = {
	LOCAL: `http://localhost:3000/${ADMIN_API_PATH}`,
	DEV: `https://dev.api.rentle.io/${ADMIN_API_PATH}`,
	PROD: `https://api.rentle.io/${ADMIN_API_PATH}`,
};

export const ApiBaseUrls = {
	LOCAL: `http://localhost:3000`,
	DEV: `https://dev.api.rentle.io`,
	PROD: `https://api.rentle.io`,
};
