import { assign, isArray } from 'lodash';

import { PartialWithId } from 'common/types';

interface ObjectWithIdField {
	id: string;
}

export function assignPartialData<T extends ObjectWithIdField>(
	existing: T,
	partialChanges: PartialWithId<T> | undefined | null,
): T;
export function assignPartialData<T extends ObjectWithIdField>(
	existing: T[],
	partialChanges: Array<PartialWithId<T> | undefined | null>,
): T[];
export function assignPartialData<T extends ObjectWithIdField>(
	existing: T | T[],
	partialChanges:
		| (PartialWithId<T> | undefined | null)
		| Array<PartialWithId<T> | undefined | null>,
): T | T[] {
	if (isArray(existing) && isArray(partialChanges)) {
		return existing.map((item) =>
			assignPartialData(
				item,
				partialChanges.find((change) => change?.id === item?.id),
			),
		);
	}
	if (!isArray(existing) && !isArray(partialChanges)) {
		if (!partialChanges) {
			return existing;
		}
		return assign({}, existing, partialChanges.data);
	}
	throw new Error('Incorrect data type');
}
