import { TFunction } from 'i18next';

import { Languages } from 'common/types';
import {
	StorageKeys,
	Storages,
	getFromStorage,
	getHashParam,
	getQueryParam,
	saveToStorage,
} from 'common/utils/frontUtils';

import { adyenPaymentComponentLocales, allChannelLanguages } from './constants';

export const COMMON_NS = 'common';

// From https://gist.github.com/mirontoli/4722797 by @mirontoli
/**
 * @author Phil Teare
 * using wikipedia data
 */

export const getLanguageNativeName = (lang: Languages) => {
	return {
		en: 'English',
		fi: 'suomi',
		sv: 'svenska',
		de: 'Deutsch',
		fr: 'français',
		es: 'español',
		it: 'Italiano',
		no: 'Norsk',
		nl: 'Nederlands',
		da: 'dansk',
		et: 'eesti',
		lt: 'lietuvių kalba',
		cs: 'česky',
		sk: 'slovenčina',
		hu: 'Magyar',
		ru: 'русский язык',
		pl: 'Polskie',
		sl: 'Slovenščina',
	}[lang];
};

export const getLanguageName = (lang: Languages, t: TFunction) => {
	return {
		en: t('common:languages.en', 'English'),
		fi: t('common:languages.fi', 'Finnish'),
		sv: t('common:languages.sv', 'Swedish'),
		de: t('common:languages.de', 'German'),
		fr: t('common:languages.fr', 'French'),
		es: t('common:languages.es', 'Spanish'),
		it: t('common:languages.it', 'Italian'),
		no: t('common:languages.no', 'Norwegian'),
		nl: t('common:languages.nl', 'Dutch'),
		da: t('common:languages.da', 'Danish'),
		et: t('common:languages.et', 'Estonian'),
		lt: t('common:languages.lt', 'Lithuanian'),
		cs: t('common:languages.cs', 'Czech'),
		sk: t('common:languages.sk', 'Slovak'),
		hu: t('common:languages.hu', 'Hungarian'),
		ru: t('common:languages.ru', 'Russian'),
		pl: t('common:languages.pl', 'Polish'),
		sl: t('common:languages.sl', 'Slovenian'),
	}[lang];
};

const mapToCorrectLangCode = (languageString: string): string => {
	const langCode = languageString.toLowerCase().substring(0, 2);
	switch (langCode) {
		case 'nb':
		case 'nn':
		case 'no':
			return 'no';
		case 'si': // Slovenian Alpha-2 code
			return 'sl';
		default:
			return langCode;
	}
};

export const getInitialLanguage = (fromLanguages?: Languages[]): Languages => {
	const supportedLanguages = fromLanguages ?? allChannelLanguages;
	// Check query parameter
	let queryParamLanguage = getQueryParam('lang');
	queryParamLanguage = queryParamLanguage && mapToCorrectLangCode(queryParamLanguage);
	if (queryParamLanguage && supportedLanguages.includes(queryParamLanguage as Languages)) {
		saveToStorage(Storages.SESSION, StorageKeys.LANG, queryParamLanguage);
		return queryParamLanguage as Languages;
	}

	// Check hash
	let hashParamLanguage = getHashParam('lang');
	hashParamLanguage = hashParamLanguage && mapToCorrectLangCode(hashParamLanguage);
	if (hashParamLanguage && supportedLanguages.includes(hashParamLanguage as Languages)) {
		saveToStorage(Storages.SESSION, StorageKeys.LANG, hashParamLanguage);
		return hashParamLanguage as Languages;
	}

	const sessionStorageLang = getFromStorage(Storages.SESSION, StorageKeys.LANG);
	if (!!sessionStorageLang && supportedLanguages.includes(sessionStorageLang as Languages)) {
		return sessionStorageLang as Languages;
	}

	const localStorageLang = getFromStorage(Storages.LOCAL, StorageKeys.LANG);
	if (!!localStorageLang && supportedLanguages.includes(localStorageLang as Languages)) {
		return localStorageLang as Languages;
	}

	// Check browser
	let browserLanguage = navigator.language;
	if (navigator.languages && navigator.languages.length) {
		browserLanguage = navigator.languages[0];
	}
	if (
		browserLanguage !== 'undefined' &&
		browserLanguage !== null &&
		browserLanguage !== undefined
	) {
		browserLanguage = mapToCorrectLangCode(browserLanguage.substring(0, 2));
		if (supportedLanguages.includes(browserLanguage as Languages)) {
			return browserLanguage as Languages;
		}
	}
	// Default
	return 'en';
};

export const getMomentLocaleCode = (lang: Languages) => {
	switch (lang) {
		case 'no':
			return 'nb';
		default:
			return lang;
	}
};

export const getPaymentComponentLocale = (lang: Languages) => {
	const defaultLocale = 'en-US';
	const adyenLocale = adyenPaymentComponentLocales.find((locale) => {
		const isoLangCode = locale.split('-')[0];
		return isoLangCode.toLocaleLowerCase() === lang.toLocaleLowerCase();
	});
	return adyenLocale || defaultLocale;
};
