import {
	getAnalytics,
	initializeAnalytics,
	isSupported,
	logEvent,
	setAnalyticsCollectionEnabled,
	setCurrentScreen,
	setUserId,
	setUserProperties,
} from 'firebase/analytics';

import errorHandler from 'common/services/errorHandling/errorHandler';
import { RemoveFirstFromTuple, RemoveFirstFunctionParameter } from 'common/types/utils';

import { firebaseApp } from './app';

export type { CustomParams, Item, EventParams } from 'firebase/analytics';

interface AnalyticsObject {
	logEvent: RemoveFirstFunctionParameter<typeof logEvent>;
	setCurrentScreen: RemoveFirstFunctionParameter<typeof setCurrentScreen>;
	setUserId: RemoveFirstFunctionParameter<typeof setUserId>;
	setUserProperties: RemoveFirstFunctionParameter<typeof setUserProperties>;
	setAnalyticsCollectionEnabled: RemoveFirstFunctionParameter<typeof setAnalyticsCollectionEnabled>;
	isMock: boolean;
}

const getAnalyticsMock = (): AnalyticsObject => ({
	logEvent: () => undefined,
	setCurrentScreen: () => undefined,
	setUserId: () => undefined,
	setUserProperties: () => undefined,
	setAnalyticsCollectionEnabled: () => undefined,
	isMock: true,
});

export let analytics: Omit<AnalyticsObject, 'app'> = getAnalyticsMock();

const getAnalyticsObject = () => {
	const analyticsInstance = analytics.isMock
		? initializeAnalytics(firebaseApp)
		: getAnalytics(firebaseApp);
	const analyticsWrapper = <T extends (...args: any) => any>(
		fn: T,
	): ((...args: RemoveFirstFromTuple<Parameters<T>>) => ReturnType<T>) => {
		return (...paramsWithoutAnalytics: RemoveFirstFromTuple<Parameters<T>>) =>
			fn(analyticsInstance, ...paramsWithoutAnalytics);
	};
	const analyticsObject: AnalyticsObject = {
		logEvent: analyticsWrapper(logEvent),
		setCurrentScreen: analyticsWrapper(setCurrentScreen),
		setUserId: analyticsWrapper(setUserId),
		setUserProperties: analyticsWrapper(setUserProperties),
		setAnalyticsCollectionEnabled: analyticsWrapper(setAnalyticsCollectionEnabled),
		isMock: false,
	};
	return analyticsObject;
};

export const initAnalytics = async () => {
	try {
		const analyticsSupported = await isSupported();
		if (analyticsSupported) {
			analytics = getAnalyticsObject();
		}
	} catch (e) {
		errorHandler.report(e);
	}
};
