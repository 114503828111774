import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.extensions.availabilityCheck',
			'Automatic availability check',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.extensions.paymentProcessing',
			'Payment processing',
		),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.extensions.bookingInterface',
			'Personal booking interface',
		),
	},
];

export default valueProps;
