import React from 'react';

import { Stack } from '@mui/material';

import ContainerBox from 'common/components/layout/ContainerBox';

import ListingsTable from './ListingsTable';

const ListingsHome = () => {
	return (
		<Stack p={4} spacing={2}>
			<ContainerBox padding="none">
				<ListingsTable />
			</ContainerBox>
		</Stack>
	);
};

export default ListingsHome;
