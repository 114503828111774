import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

import { AddOn, AddOnChannel } from 'common/modules/plans';
import { Duration, Languages } from 'common/types';
import { setLanguage } from 'services/localization/utils';
import { RentalLinkData, RentalsTabValue } from 'services/types';

export type ViewAction =
	| OpenSideBar
	| CloseSideBar
	| UpdatePayLinkInProgress
	| ToggleTimePanelOpen
	| UpdateExpandedRentalDialogPanel
	| SetImageUploading
	| SetUserActive
	| UpdateLanguage
	| ToggleMobileRentalSideBar
	| ShowRentalViewFormErrors
	| ShowEarlyOrLatePickupDialog
	| SetEndDate
	| SetStartDate
	| SetRentNowToggle
	| SetCalendarView
	| SetStartPrint
	| SetRentalLinkSendingFailed
	| SetPendingRentalLinks
	| SetDuration
	| SetRentalsActiveTab
	| ToggleUpgradeModal
	| SetBookingsViewLocationType
	| SetDevPanelOpen
	| SetOrdersViewLocationType
	| SetNewClientUpdate
	| SetDataGridState
	| OpenInstalledAddOnModal
	| CloseInstalledAddOnModal
	| ResetInstalledAddOnModal
	| OpenAddOnModal
	| CloseAddOnModal
	| ResetAddOnModal
	| SetAuthState
	| ToggleEarnCreditsModal
	| ToggleSwitchToNewPlansModal
	| SetGrowthbookInitialized;

interface ShowRentalViewFormErrors {
	type: 'SHOW_RENTAL_VIEW_FORM_ERRORS';
	show: boolean;
}

export const setShowRentalViewFormErrors = (show: boolean): ShowRentalViewFormErrors => {
	return {
		type: 'SHOW_RENTAL_VIEW_FORM_ERRORS',
		show,
	};
};

interface ShowEarlyOrLatePickupDialog {
	type: 'SHOW_EARLY_OR_LATE_PICKUP_DIALOG';
	show: boolean;
}

export const setShowEarlyOrLatePickupDialog = (show: boolean): ShowEarlyOrLatePickupDialog => {
	return {
		type: 'SHOW_EARLY_OR_LATE_PICKUP_DIALOG',
		show,
	};
};

interface ToggleMobileRentalSideBar {
	type: 'TOGGLE_MOBILE_RENTAL_SIDE_BAR';
	open: boolean;
}

export const openMobileRentalSideBar = (): ToggleMobileRentalSideBar => {
	return {
		type: 'TOGGLE_MOBILE_RENTAL_SIDE_BAR',
		open: true,
	};
};

export const closeMobileRentalSideBar = (): ToggleMobileRentalSideBar => {
	return {
		type: 'TOGGLE_MOBILE_RENTAL_SIDE_BAR',
		open: false,
	};
};

export interface SetEndDate {
	type: 'SET_END_DATE';
	date: string;
}

export interface SetStartDate {
	type: 'SET_START_DATE';
	date: string;
}

export const setStartDate = (date: string) => {
	return {
		type: 'SET_START_DATE',
		date,
	};
};

export const setEndDate = (date: string) => {
	return {
		type: 'SET_END_DATE',
		date,
	};
};

export interface SetDuration {
	type: 'SET_DURATION';
	duration: Duration;
}

export const setDuration = (duration: Duration): SetDuration => {
	return {
		type: 'SET_DURATION',
		duration,
	};
};

export interface SetRentNowToggle {
	type: 'SET_RENT_NOW_TOGGLE';
	value: boolean;
}

export const setRentNowToggle = (value: boolean): SetRentNowToggle => {
	return {
		type: 'SET_RENT_NOW_TOGGLE',
		value,
	};
};
export interface UpdateExpandedRentalDialogPanel {
	type: 'UPDATE_EXPANDED_RENTAL_DIALOG_PANEL';
	panel: string;
}

export interface SetImageUploading {
	type: 'SET_IMAGE_UPLOADING';
	uploading: boolean;
}

export const setImageUploading = (uploading: boolean): SetImageUploading => {
	return {
		type: 'SET_IMAGE_UPLOADING',
		uploading,
	};
};

export const updateExpandedRentalDialogPanel = (panel: string): UpdateExpandedRentalDialogPanel => {
	return {
		type: 'UPDATE_EXPANDED_RENTAL_DIALOG_PANEL',
		panel,
	};
};

interface ToggleTimePanelOpen {
	type: 'TOGGLE_TIME_PANEL';
}

export const toggleTimePanelOpen = (): ToggleTimePanelOpen => {
	return {
		type: 'TOGGLE_TIME_PANEL',
	};
};

interface OpenSideBar {
	type: 'TOGGLE_SIDE_BAR';
	open: true;
}

interface CloseSideBar {
	type: 'TOGGLE_SIDE_BAR';
	open: false;
}

export interface UpdatePayLinkInProgress {
	type: 'UPDATE_PAYLINK_IN_PROGRESS';
	inProgress: boolean;
}

export const setPayLinkInProgress = (inProgress: boolean): UpdatePayLinkInProgress => {
	return {
		type: 'UPDATE_PAYLINK_IN_PROGRESS',
		inProgress,
	};
};

export const closeSideBar = (): CloseSideBar => {
	return {
		type: 'TOGGLE_SIDE_BAR',
		open: false,
	};
};

export const openSideBar = (): OpenSideBar => {
	return {
		type: 'TOGGLE_SIDE_BAR',
		open: true,
	};
};

export interface SetUserActive {
	type: 'SET_USER_ACTIVE';
	userIsActive: boolean;
}

export const setUserActive = (userIsActive: boolean): SetUserActive => {
	return {
		type: 'SET_USER_ACTIVE',
		userIsActive,
	};
};

export interface UpdateLanguage {
	type: 'UPDATE_LANGUAGE';
	lang: Languages;
}

export const updateLanguage = (lang: Languages): UpdateLanguage => {
	setLanguage(lang);
	return {
		type: 'UPDATE_LANGUAGE',
		lang,
	};
};

export interface SetCalendarView {
	type: 'SET_CALENDARVIEW';
	calendarView: string;
}

export const setCalendarView = (calendarView: string) => {
	return {
		type: 'SET_CALENDARVIEW',
		calendarView,
	};
};

export interface SetStartPrint {
	type: 'SET_START_PRINT';
	startPrint: boolean;
}

export const setStartPrint = (startPrint: boolean): SetStartPrint => {
	return {
		type: 'SET_START_PRINT',
		startPrint,
	};
};

export interface SetRentalLinkSendingFailed {
	type: 'SET_RENTALLINK_SENDING_FAILED';
	failed: boolean;
}

export const setRentalLinkSendingFailed = (failed: boolean): SetRentalLinkSendingFailed => {
	return {
		type: 'SET_RENTALLINK_SENDING_FAILED',
		failed,
	};
};

interface SetPendingRentalLinks {
	type: 'SET_PENDING_RENTALLINKS';
	rentalLinks: RentalLinkData[];
}

export const setPendingRentalLinks = (rentalLinks: RentalLinkData[]): SetPendingRentalLinks => {
	return {
		type: 'SET_PENDING_RENTALLINKS',
		rentalLinks,
	};
};

interface SetRentalsActiveTab {
	type: 'SET_RENTALS_ACTIVE_TAB';
	rentalsActiveTab: RentalsTabValue;
}

export const setRentalsActiveTab = (rentalsActiveTab: RentalsTabValue): SetRentalsActiveTab => {
	return {
		type: 'SET_RENTALS_ACTIVE_TAB',
		rentalsActiveTab,
	};
};

interface ToggleUpgradeModal {
	type: 'View/TOGGLE_UPGRADE_MODAL';
	payload: boolean;
}

export const toggleUpgradeModal = (open: boolean): ToggleUpgradeModal => {
	return {
		type: 'View/TOGGLE_UPGRADE_MODAL',
		payload: open,
	};
};

interface SetBookingsViewLocationType {
	type: 'View/SET_BOOKINGS_VIEW_LOCATION_TYPE';
	payload: 'current' | 'all';
}

export const setBookingsViewLocationType = (
	payload: 'current' | 'all',
): SetBookingsViewLocationType => {
	return {
		type: 'View/SET_BOOKINGS_VIEW_LOCATION_TYPE',
		payload,
	};
};

interface SetDevPanelOpen {
	type: 'View/SET_DEV_PANEL_OPEN';
	payload: boolean;
}

export const setDevPanelOpen = (payload: boolean): SetDevPanelOpen => {
	return {
		type: 'View/SET_DEV_PANEL_OPEN',
		payload,
	};
};

interface SetOrdersViewLocationType {
	type: 'View/SET_ORDERS_VIEW_LOCATION_TYPE';
	payload: 'current' | 'all';
}

export const setOrdersViewLocationType = (
	payload: 'current' | 'all',
): SetOrdersViewLocationType => {
	return {
		type: 'View/SET_ORDERS_VIEW_LOCATION_TYPE',
		payload,
	};
};

interface SetNewClientUpdate {
	type: 'View/SET_NEW_CLIENT_UPDATE';
	payload: boolean;
}

export const setNewClientUpdate = (payload: boolean): SetNewClientUpdate => {
	return {
		type: 'View/SET_NEW_CLIENT_UPDATE',
		payload,
	};
};

interface OpenInstalledAddOnModal {
	type: 'View/OPEN_INSTALLED_ADD_ON_MODAL';
	payload: AddOn;
}

export const openInstalledAddOnModal = (payload: AddOn): OpenInstalledAddOnModal => {
	return {
		type: 'View/OPEN_INSTALLED_ADD_ON_MODAL',
		payload,
	};
};

interface CloseInstalledAddOnModal {
	type: 'View/CLOSE_INSTALLED_ADD_ON_MODAL';
}

export const closeInstalledAddOnModal = (): CloseInstalledAddOnModal => {
	return {
		type: 'View/CLOSE_INSTALLED_ADD_ON_MODAL',
	};
};

interface ResetInstalledAddOnModal {
	type: 'View/RESET_INSTALLED_ADD_ON_MODAL';
}

export const resetInstalledAddOnModal = (): ResetInstalledAddOnModal => {
	return {
		type: 'View/RESET_INSTALLED_ADD_ON_MODAL',
	};
};
interface OpenAddOnModal {
	type: 'View/OPEN_ADD_ON_MODAL';
	payload: {
		addOn: AddOn;
		addOnChannel: AddOnChannel;
		nextRoute?: string;
	};
}

export const openAddOnModal = (
	addOn: AddOn,
	addOnChannel: AddOnChannel,
	nextRoute?: string,
): OpenAddOnModal => {
	return {
		type: 'View/OPEN_ADD_ON_MODAL',
		payload: {
			addOn,
			addOnChannel,
			nextRoute,
		},
	};
};

interface CloseAddOnModal {
	type: 'View/CLOSE_ADD_ON_MODAL';
}

export const closeAddOnModal = (): CloseAddOnModal => {
	return {
		type: 'View/CLOSE_ADD_ON_MODAL',
	};
};

interface ResetAddOnModal {
	type: 'View/RESET_ADD_ON_MODAL';
}

export const resetAddOnModal = (): ResetAddOnModal => {
	return {
		type: 'View/RESET_ADD_ON_MODAL',
	};
};

interface SetAuthState {
	type: 'View/SET_AUTH_STATE';
	payload: {
		isAuthenticated: boolean;
		loading: boolean;
	};
}

export const setAuthState = (payload: SetAuthState['payload']): SetAuthState => {
	return {
		type: 'View/SET_AUTH_STATE',
		payload,
	};
};

interface SetDataGridState {
	type: 'View/SET_DATAGRID_STATE';
	payload: {
		key: string;
		state: GridInitialStatePro;
	};
}

export const setDataGridState = (key: string, state: GridInitialStatePro): SetDataGridState => {
	return {
		type: 'View/SET_DATAGRID_STATE',
		payload: {
			key,
			state,
		},
	};
};

interface ToggleEarnCreditsModal {
	type: 'View/TOGGLE_EARN_CREDITS_MODAL';
	payload: boolean;
}

export const toggleEarnCreditsModal = (payload: boolean): ToggleEarnCreditsModal => {
	return {
		type: 'View/TOGGLE_EARN_CREDITS_MODAL',
		payload,
	};
};

interface ToggleSwitchToNewPlansModal {
	type: 'View/TOGGLE_SWITCH_TO_NEW_PLANS_MODAL';
	payload: boolean;
}

export const toggleSwitchToNewPlansModal = (payload: boolean): ToggleSwitchToNewPlansModal => {
	return {
		type: 'View/TOGGLE_SWITCH_TO_NEW_PLANS_MODAL',
		payload,
	};
};

interface SetGrowthbookInitialized {
	type: 'View/SET_GROWTHBOOK_INITIALIZED';
	payload: boolean;
}

export const setGrowthbookInitialized = (payload: boolean): SetGrowthbookInitialized => {
	return {
		type: 'View/SET_GROWTHBOOK_INITIALIZED',
		payload,
	};
};
