import { z } from 'zod';

import { zLocaleField } from 'common/modules/atoms/localization';

export const zTaxLine = z.object({
	/**
	 * Name of the combined tax label, if in use for tax line
	 * Combined label is used to combine multiple tax lines under one label, if they share the same combined label
	 * @example { label: 'GST', combinedLabel: 'Tax'}, { label: 'HST', combinedLabel: 'Tax'} -> Show as "Tax" in consumer channels
	 */
	combinedLabel: zLocaleField.optional(),

	/**
	 * Name of the tax
	 */
	label: zLocaleField.optional(),

	/**
	 * Total tax price to be applied
	 */
	price: z.number().int(),

	/**
	 * Tax rate in decimals
	 */
	rate: z.number(),
});

export const zTaxDetails = z.object({
	totalTaxes: z.number().int(),
	taxLines: z.array(zTaxLine),
});

export const zTaxAmounts = z.object({
	/**
	 * Total price of the taxable price
	 */
	total: z.number().int(),

	/**
	 * Price excluding taxes
	 */
	netAmount: z.number().int(),

	/**
	 * Amount of tax
	 */
	taxAmount: z.number().int(),

	/**
	 * Tax rate in decimals
	 */
	taxRate: z.number(),
});

export type TaxLine = z.infer<typeof zTaxLine>;
export type TaxDetails = z.infer<typeof zTaxDetails>;
export type TaxAmounts = z.infer<typeof zTaxAmounts>;
