import { chain, sum } from 'lodash';

import {
	BILLING_CURRENCY,
	BillingCreditsBalance,
	addCredits,
	getCreditsBalanceObject,
	getCreditsChange,
	getCreditsToUseAndBalanceAfter,
} from 'common/modules/billing';
import { PlanCampaignDiscount, PurchasedPlan } from 'common/modules/plans';
import { getMonthlyCampaignDiscountAmount } from 'common/modules/plans/campaigns/utils';
import {
	getLocationSubscription,
	getStoreSubscriptionPrice,
	getSubscriptionCancelRefund,
	getSubscriptionStartDiscount,
	isAdditionalStore,
} from 'common/modules/plans/subscriptions';
import { AmountObject, ById, ISOString, ShopPublicInfo, ShopReadOnlyData } from 'common/types';
import { applyDiscountToPrice } from 'common/utils/pricing';
import { getShopLocations } from 'common/utils/shopUtils';

export interface SubscriptionPriceBreakdown {
	totalSubscriptionPrice: AmountObject;
	totalCampaignDiscount: AmountObject;
	totalStartDiscount: AmountObject;
	totalCancelDiscount: AmountObject;
	totalLeftToPay: AmountObject;
	creditsBalanceAfter: BillingCreditsBalance;
	creditsBalanceChange: BillingCreditsBalance;
	subscriptionPriceByLocation: ById<AmountObject>;
	refundableAmountByLocation: ById<AmountObject>;
	additionalStores: ById<boolean>;
	totalCreditsUsed: AmountObject;
}

const EMPTY_AMOUNT_OBJECT: AmountObject = { value: 0, currency: BILLING_CURRENCY.code };

export const getPurchaseSubscriptionPriceBreakdown = (args: {
	shopPublicInfo: ShopPublicInfo;
	shopReadOnly: ShopReadOnlyData;
	newPlan: PurchasedPlan;
	date: ISOString;
	campaignDiscount?: PlanCampaignDiscount;
}): SubscriptionPriceBreakdown => {
	const { shopPublicInfo, shopReadOnly, newPlan, date, campaignDiscount } = args;
	const mainLocationId = shopPublicInfo.visitingAddress?.id;

	if (!mainLocationId) {
		throw new Error(`Merchant ${shopPublicInfo.shopId} does not have a main location ID`);
	}
	const locationIds = getShopLocations(shopPublicInfo).map((l) => l.id);
	const creditsBalanceBefore = getCreditsBalanceObject(shopReadOnly);

	const {
		subscriptionPriceByLocation,
		startDiscountByLocation,
		campaignDiscountByLocation,
		additionalStores,
		totalCancelRefund,
	} = locationIds.reduce(
		(result, locationId) => {
			const currentSubscription = getLocationSubscription(shopReadOnly.features.plan, locationId);
			const newSubscriptionPrice =
				getStoreSubscriptionPrice({
					purchasedPlan: newPlan,
					locationId,
					mainLocationId,
					allLocationIds: locationIds,
				})?.fixed ?? EMPTY_AMOUNT_OBJECT;

			const shouldApplyCampaignDiscount = locationId === mainLocationId;
			const campaignDiscountAmount =
				!!campaignDiscount && shouldApplyCampaignDiscount
					? getMonthlyCampaignDiscountAmount(newSubscriptionPrice, campaignDiscount)
					: EMPTY_AMOUNT_OBJECT;

			const subscriptionPriceWithCampaignDiscount = applyDiscountToPrice(
				newSubscriptionPrice,
				campaignDiscountAmount,
			);

			result.campaignDiscountByLocation[locationId] = campaignDiscountAmount.value;
			result.subscriptionPriceByLocation[locationId] = newSubscriptionPrice.value;

			result.startDiscountByLocation[locationId] = !!newPlan.subscription
				? getSubscriptionStartDiscount({
						subscription: newPlan.subscription,
						subscriptionPrice: subscriptionPriceWithCampaignDiscount,
						startDate: date,
				  }).value
				: 0;

			result.totalCancelRefund += getSubscriptionCancelRefund({
				subscription: currentSubscription,
				cancellationDate: date,
			}).value;

			if (
				isAdditionalStore({
					plan: newPlan,
					locationId,
					allLocationIds: locationIds,
					mainLocationId,
				})
			) {
				result.additionalStores[locationId] = true;
			}

			return result;
		},
		{
			subscriptionPriceByLocation: {} as ById<number>,
			startDiscountByLocation: {} as ById<number>,
			campaignDiscountByLocation: {} as ById<number>,
			additionalStores: {} as ById<boolean>,
			totalCancelRefund: 0,
		},
	);

	const {
		leftToPayByLocation,
		refundableAmountByLocation,
		creditsBalance,
		cancelRefundRemaining,
		cancelRefundUsedAsDiscount,
	} = locationIds.reduce(
		(result, locationId) => {
			const fullSubscriptionPrice = subscriptionPriceByLocation[locationId];

			const campaignDiscount = campaignDiscountByLocation[locationId];
			const priceAfterCampaignDiscount = fullSubscriptionPrice - campaignDiscount;

			const startDiscount = startDiscountByLocation[locationId];
			const priceAfterStartDiscount = priceAfterCampaignDiscount - startDiscount;
			const refundCreditsToUseAsDiscount = Math.min(
				result.cancelRefundRemaining,
				priceAfterStartDiscount,
			);

			if (refundCreditsToUseAsDiscount > 0) {
				result.cancelRefundRemaining -= refundCreditsToUseAsDiscount;
				result.cancelRefundUsedAsDiscount += refundCreditsToUseAsDiscount;
			}

			const priceAfterCancelDiscount = priceAfterStartDiscount - refundCreditsToUseAsDiscount;

			const creditsUsedResult = getCreditsToUseAndBalanceAfter({
				creditsBalance: result.creditsBalance,
				creditsToUse: priceAfterCancelDiscount,
				type: 'any',
			});

			const refundableAmount = priceAfterStartDiscount - creditsUsedResult.creditsToUse.manual;
			const priceAfterCreditsUsed = priceAfterCancelDiscount - creditsUsedResult.creditsToUse.total;

			result.refundableAmountByLocation[locationId] = refundableAmount;
			result.leftToPayByLocation[locationId] = priceAfterCreditsUsed;
			result.creditsBalance = creditsUsedResult.balanceAfter;

			return result;
		},
		{
			leftToPayByLocation: {} as ById<number>,
			refundableAmountByLocation: {} as ById<number>,
			creditsBalance: creditsBalanceBefore,
			cancelRefundRemaining: totalCancelRefund,
			cancelRefundUsedAsDiscount: 0,
		},
	);

	const totalSubscriptionPrice = sum(Object.values(subscriptionPriceByLocation));
	const totalCampaignDiscount = sum(Object.values(campaignDiscountByLocation));
	const totalStartDiscount = sum(Object.values(startDiscountByLocation));
	const totalLeftToPay = sum(Object.values(leftToPayByLocation));

	const creditsBalanceAfter =
		cancelRefundRemaining > 0
			? addCredits({
					creditsBalance,
					amount: cancelRefundRemaining,
					type: 'refund',
			  })
			: creditsBalance;

	const creditsBalanceChange = getCreditsChange({
		before: creditsBalanceBefore,
		after: creditsBalanceAfter,
	});

	const refundCreditsUsed =
		creditsBalanceChange.refund.value < 0 ? -creditsBalanceChange.refund.value : 0;
	const manualCreditsUsed =
		creditsBalanceChange.manual.value < 0 ? -creditsBalanceChange.manual.value : 0;
	const totalCreditsUsed = refundCreditsUsed + manualCreditsUsed;

	return {
		totalSubscriptionPrice: toBillingAmountObject(totalSubscriptionPrice),
		totalCampaignDiscount: toBillingAmountObject(totalCampaignDiscount),
		totalStartDiscount: toBillingAmountObject(totalStartDiscount),
		totalCancelDiscount: toBillingAmountObject(cancelRefundUsedAsDiscount),
		totalLeftToPay: toBillingAmountObject(totalLeftToPay),
		creditsBalanceAfter,
		creditsBalanceChange,
		subscriptionPriceByLocation: toBillingAmountObjects(subscriptionPriceByLocation),
		refundableAmountByLocation: toBillingAmountObjects(refundableAmountByLocation),
		additionalStores,
		totalCreditsUsed: toBillingAmountObject(totalCreditsUsed),
	};
};

const toBillingAmountObject = (value: number): AmountObject => {
	return {
		value,
		currency: BILLING_CURRENCY.code,
	};
};

const toBillingAmountObjects = (value: ById<number>): ById<AmountObject> => {
	return chain(value).mapValues(toBillingAmountObject).value();
};
