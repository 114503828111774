import React, { useMemo, useState } from 'react';

import { Box, styled } from '@mui/material';
import { DataGridPro, GridColDef, GridPaginationModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';

import { queries } from 'common/frontend/queries';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { useTranslation } from 'services/localization';

import ListingsTableFooter from './utils/ListingsTableFooter';
import ListingsTableToolbar from './utils/ListingsTableToolbar';
import { getListingsTableColumns } from './utils/columns';

const ListingsTable = () => {
	const dateFormat = useSelector(ShopSelectors.getShopDateFormat);
	const { t } = useTranslation();
	const columns = useMemo(() => getListingsTableColumns({ dateFormat, t }), [dateFormat, t]);
	const apiRef = useGridApiRef();
	const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({
		page: 0,
		pageSize: 10,
	});

	const { data: allListings, isLoading } = queries.listings.listAll.useQuery();
	const listingsCount = allListings?.length;

	const handlePaginationModelChange = (model: GridPaginationModel) => {
		setPaginationModel(model);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<StyledDataGrid
				apiRef={apiRef}
				loading={isLoading}
				rows={allListings ?? []}
				rowCount={listingsCount}
				columns={columns as GridColDef[]}
				checkboxSelection
				autoHeight
				density="comfortable"
				pagination
				paginationModel={paginationModel}
				onPaginationModelChange={(model) => handlePaginationModelChange(model)}
				slots={{
					pagination: ListingsTableFooter,
					toolbar: () => <ListingsTableToolbar listingsCount={listingsCount ?? 0} />,
				}}
			/>
		</Box>
	);
};

export default ListingsTable;

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: theme.palette.background.paper,
	},
	'& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
		outline: 'none',
	},
	'& .MuiDataGrid-menuIcon': {
		display: 'none',
	},
	'& .MuiDataGrid-toolbar': {
		padding: theme.spacing(1),
	},
	'& .MuiDataGrid-columnSeparator': {
		display: 'none',
	},
	'& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnSeparator': {
		display: 'none',
	},
	'& .MuiDataGrid-cell': {
		border: 'none',
		fontSize: '1.4rem',
	},
	'& .MuiDataGrid-row': {
		cursor: 'pointer',
		borderBottom: `1px solid ${theme.palette.divider}`,
		'& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
			outline: 'none',
		},
	},
	'& .overflow-cell': {
		overflowX: 'auto',
	},
	'& .no-sku-cell': {
		color: theme.palette.error.main,
		fontStyle: 'italic',
	},
	'& .MuiDataGrid-columnHeaders + div': {
		//Forces scrollbar to table bottom
		minHeight: '100%',
	},
	'& .MuiDataGrid-virtualScroller': {
		//Forces scrollbar to table bottom
		minHeight: 'calc(100% - 56px) !important',
	},
	'& .MuiDataGrid-pinnedColumns': {
		background: theme.palette.background.paper,
	},
	'& .MuiDataGrid-pinnedColumnHeaders--left': {
		background: theme.palette.background.secondary,
	},
	'& .MuiDataGrid-overlay': {
		zIndex: 10,
	},
	'& .MuiLinearProgress-root': {
		zIndex: 10,
	},
}));
