import { ItemTypeApi, ItemTypeDb } from './types';

export const toApi = (data: ItemTypeDb): ItemTypeApi => {
	const mapping: Record<ItemTypeDb, ItemTypeApi> = {
		bulk: 'bulk',
		single: 'single',
		item: 'single',
	};
	return mapping[data];
};

export const toDb = <T extends 'single' | 'item'>(
	data: ItemTypeApi,
	args: { single: T },
): 'bulk' | T => {
	const mapping: Record<ItemTypeApi, 'bulk' | T> = {
		bulk: 'bulk',
		single: args.single,
	};
	return mapping[data];
};
