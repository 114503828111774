import errorHandler from 'common/services/errorHandling/errorHandler';
import { Callable as CallableType, callableGenerator } from 'common/api/db/functions/_callable';
import { httpsCallable, HttpsCallableOptions } from './firebase/functions';

export const createCallable = <In, Out>(name: string): CallableType<In, Out> => {
	const callableFunction = async (
		args: In,
		callableOptions?: HttpsCallableOptions,
	): Promise<Out> => {
		const callable = httpsCallable<In, Out>(name, callableOptions);
		try {
			const result = await callable(args);
			return result.data;
		} catch (e) {
			errorHandler.report(e);
			throw e;
		}
	};
	return callableFunction;
};

export const Callable = callableGenerator(createCallable);
