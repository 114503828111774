import React from 'react';

import {
	Box,
	ButtonBase,
	ButtonBaseProps,
	Stack,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import { IconType } from 'react-icons';

interface HeaderButtonProps extends ButtonBaseProps {
	icon?: IconType;
	iconPosition?: 'start' | 'end';
	label?: string;
	size?: 'default' | 'large';
	collapsed?: boolean;
	collapsedIconOnly?: boolean;
}

const HeaderButton = (props: HeaderButtonProps) => {
	const {
		icon: Icon,
		iconPosition = 'end',
		size = 'default',
		collapsed,
		collapsedIconOnly,
		label,
		...rest
	} = props;

	const showIcon = !!Icon && (collapsedIconOnly ? !!collapsed : true);
	const showLabel = !!label && !collapsed;

	const icon = !!Icon ? (
		<Box>
			<Icon size={size === 'default' ? 20 : 24} />
		</Box>
	) : null;
	return (
		<Tooltip
			title={!!collapsed ? label ?? '' : ''}
			placement="left"
			arrow
			enterDelay={300}
			leaveDelay={0}
		>
			<StyledButton {...rest}>
				<Stack direction="row" alignItems="center" spacing={1}>
					{showIcon && iconPosition === 'start' && icon}
					{showLabel && (
						<Typography variant="h6" fontSize={size === 'default' ? '1.4rem' : '1.8rem'} noWrap>
							{label}
						</Typography>
					)}
					{showIcon && iconPosition === 'end' && icon}
				</Stack>
			</StyledButton>
		</Tooltip>
	);
};

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
	height: '100%',
	padding: theme.spacing(0, 2),
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
	'&:focus': {
		backgroundColor: theme.palette.action.selected,
	},
}));

export default HeaderButton;
