import React from 'react';

import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';

export interface Props {
	isLast: boolean;
	styles?: {
		mb: number;
	};
	sizes?: {
		xs?: number;
		sm?: number;
		md?: number;
	};
}
const RegistrationOption: React.FC<React.PropsWithChildren<Props>> = ({
	isLast,
	styles,
	sizes,
	children,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<Grid
			item
			xs={sizes?.xs ?? 12}
			sm={sizes?.sm ?? 6}
			md={sizes?.md ?? 3}
			mb={styles?.mb ?? (isMobile && !isLast ? 1 : 4)}
		>
			<motion.div
				initial={{
					opacity: 0,
					transitionDuration: '0.3s',
				}}
				animate={{
					opacity: 1,
					transitionDuration: `0.3s`,
				}}
				style={{ height: '100%' }}
				variants={{
					hidden: {
						opacity: 0,
					},
					show: {
						transition: {
							staggerChildren: 0.3,
						},
					},
				}}
			>
				{children}
			</motion.div>
		</Grid>
	);
};

export default RegistrationOption;
