import { TFunction } from 'i18next';
import { maxBy, minBy } from 'lodash';
import moment from 'moment-timezone';

import { yyyyMmDd } from 'common/modules/atoms/times';
import {
	OpeningHours,
	getClosingTimestampForDate,
	isDateTimeAfterOpeningHours,
	isStoreOpenForDay,
} from 'common/modules/openingHours';
import { isLiftTicketProduct } from 'common/modules/skidata';
import {
	getSubscriptionDurationString,
	isAutoRenewSubscriptionOrderProduct,
} from 'common/modules/subscriptions';
import {
	DateProperties,
	Duration,
	ISOString,
	Languages,
	OrderInfo,
	OrderProduct,
	PurchaseTypes,
} from 'common/types';

import { notNull } from './common';

export const isTimeBeforeTimeNow = (time: string) => {
	const timeNow = moment();
	return moment(time).isBefore(timeNow);
};

export const getDifferenceBetweenMomentsMinutes = (
	timeNow: moment.Moment,
	givenTime: moment.Moment,
): number => {
	return timeNow.diff(givenTime, 'minutes');
};

export const getTimeNearestToNextQuarterHour = (time: moment.Moment = moment()): moment.Moment => {
	const returnedTime = moment(time);
	const quarters = [0, 15, 30, 45];
	const index = quarters.findIndex(
		(minutes) => returnedTime.diff(moment(time).minutes(minutes).startOf('minute')) <= 0,
	);
	return index < 0
		? returnedTime.add(1, 'hour').minutes(0).startOf('minute')
		: returnedTime.minutes(quarters[index]).startOf('minute');
};

export const roundToClosestXMinutes = (
	time: moment.Moment = moment(),
	minutes: number,
	rounding: 'floor' | 'ceil' | 'round' = 'round',
) => {
	const roundingMethod =
		rounding === 'floor' ? Math.floor : rounding === 'ceil' ? Math.ceil : Math.round;
	const roundingMs = minutes * 60 * 1000;
	const currentMs = moment(time).valueOf();
	const newMs = roundingMethod(currentMs / roundingMs) * roundingMs;
	const msToAdd = newMs - currentMs;
	return moment(time).add(msToAdd, 'milliseconds').startOf('minute');
};

export const getNewEndTime = (
	startDate: string,
	duration: Duration | null,
	openingHours: OpeningHours,
	timezone: string,
): string => {
	if (duration && duration.durationType === 'opening_hours') {
		let daysToAdd = Math.floor(moment.duration(duration.durationInSeconds, 'seconds').asDays());

		if (
			isStoreOpenForDay({
				openingHours,
				date: yyyyMmDd(startDate),
			}) &&
			isDateTimeAfterOpeningHours({ openingHours, dateTime: startDate, timezone })
		) {
			daysToAdd++;
		}
		const endDate = moment.tz(startDate, timezone).add(daysToAdd, 'days').format('YYYY-MM-DD');
		return getClosingTimestampForDate({
			openingHours,
			date: endDate,
			timezone,
		});
	}
	return getEndDate({ startDate, duration: duration || undefined });
};

export const getNewDateProperties = (
	{ new: newDateProperties, old: oldDateProperties }: { new: DateProperties; old: DateProperties },
	openingHours: OpeningHours,
	timezone: string,
): DateProperties => {
	const { startDate, endDate, duration } = newDateProperties;
	const oldDuration = oldDateProperties.duration;
	const oldStartDate = oldDateProperties.startDate;
	if (startDate) {
		const durationType =
			(duration && duration.durationType) || (oldDuration && oldDuration.durationType) || '24h';
		const durationName = (duration && duration.durationName) || null;
		const newEndDate =
			endDate || getNewEndTime(startDate, duration || oldDuration || null, openingHours, timezone);
		return {
			startDate,
			endDate: newEndDate,
			duration: duration || {
				durationInSeconds: moment(newEndDate).diff(moment(startDate), 'seconds'),
				durationType,
				durationName,
			},
		};
	} else if (endDate) {
		const durationType = '24h';
		const durationName = (duration && duration.durationName) || null;
		const durationInSeconds = moment(endDate).diff(startDate || oldStartDate || endDate, 'seconds');
		return {
			startDate: startDate || oldStartDate || endDate,
			endDate,
			duration: duration || {
				durationInSeconds,
				durationType,
				durationName,
			},
		};
	} else if (duration) {
		const newStartDate = startDate || oldStartDate || moment().toISOString();
		const newEndDate =
			endDate || moment(newStartDate).add(duration.durationInSeconds, 'seconds').toISOString();
		return {
			startDate: newStartDate,
			endDate: newEndDate,
			duration,
		};
	}
	return oldDateProperties;
};

export const getEndDate = (dateProperies: DateProperties) => {
	const { startDate, endDate, duration } = dateProperies;
	const usedStartDate = startDate ? moment(startDate) : moment().hour(9).startOf('hour');
	if (!duration) {
		return endDate || moment(usedStartDate).hour(23).minute(45).startOf('minute').toISOString();
	} else {
		/**
		 * We check if duration consists of full days, and add the duration as days instead of seconds, to keep the correct end date even in case of DST.
		 * If we would add in seconds precision, moment would return a time in different hour because of clocks changing.
		 *
		 * https://momentjs.com/docs/#/manipulating/add/
		 * "There are also special considerations to keep in mind when adding time that crosses over daylight saving time.
		 * If you are adding years, months, weeks, or days, the original hour will always match the added hour.
		 * If you are adding hours, minutes, seconds, or milliseconds, the assumption is that you want precision to the hour, and will result in a different hour."
		 */
		const durationAsDays = moment.duration(duration.durationInSeconds, 'seconds').asDays();
		const dayIsWholeNumber = durationAsDays % 1 === 0;
		return moment(usedStartDate)
			.add(dayIsWholeNumber ? { days: durationAsDays } : { seconds: duration.durationInSeconds })
			.toISOString();
	}
};

export const getDurationStringFromProduct = (
	product: OrderProduct | undefined,
	lang: Languages,
	t: TFunction,
): string => {
	if (!product) return '';
	switch (product.purchaseType) {
		case PurchaseTypes.rental: {
			return getDurationString(
				{
					durationInSeconds: product.rentalDurationInSeconds,
					durationType: product.durationType,
					durationName: product.durationName,
				},
				'long',
				lang,
				t,
			);
		}
		case PurchaseTypes.subscription: {
			const { subscription } = product;
			return !!subscription ? getSubscriptionDurationString(subscription, t) : '';
		}
		case PurchaseTypes.sales:
		default:
			return '';
	}
};

export const getDurationString = (
	duration: Duration,
	format: 'long' | 'short' = 'long',
	lang: Languages | undefined,
	t: TFunction,
) => {
	const { durationInSeconds, durationType, durationName } = duration;
	if (durationName && durationName.def) {
		return durationName[lang ?? 'en'] || durationName.def;
	}

	if (durationInSeconds === 0) {
		return '';
	}
	const durationMoment = moment.duration(durationInSeconds, 'seconds');

	if (durationType === 'opening_hours') {
		const daysWithinOpeningHours = Math.ceil(durationMoment.asDays());
		const dayLocale =
			daysWithinOpeningHours === 1 ? t('common:times.day', 'day') : t('common:times.days', 'days');
		const dayString = format === 'short' ? t('common:times.daysShort', 'd') : dayLocale;
		return daysWithinOpeningHours + ' ' + dayString;
	}

	const months = Math.floor(durationMoment.asDays() / 30);

	if (months >= 1) {
		const monthLocale =
			months <= 1 ? t('common:times.month', 'month') : t('common:times.months', 'months');
		const monthString = format === 'short' ? t('common:times.monthsShort', 'mo') : monthLocale;
		return `${months} ${monthString}`.toLowerCase();
	}

	const weeks = durationMoment.asWeeks();

	if (weeks >= 1 && weeks % 1 === 0) {
		const weekLocale =
			weeks === 1 ? t('common:times.week', 'week') : t('common:times.weeks', 'weeks');
		const weekString = format === 'short' ? t('common:times.weeksShort', 'w') : weekLocale;
		return `${weeks} ${weekString}`;
	}

	const days = Math.floor(durationMoment.asDays());

	const hours = durationMoment.hours();
	const minutes = durationMoment.minutes();

	const dayLocale = days <= 1 ? t('common:times.day', 'day') : t('common:times.days', 'days');

	const hourLocale =
		hours === 1 ? t('common:times.hour', 'hour') : t('common:times.hours', 'hours');

	const day24Locale =
		days === 1 ? t('common:times.day24', 'day (24)') : t('common:times.days24', 'days (24)');
	const dayString =
		format === 'short' ? t('common:times.daysShort', 'd') : hours ? dayLocale : day24Locale;
	const hourString = format === 'short' ? t('common:times.hoursShort', 'h') : hourLocale;

	const minString = t('common:times.minutesShort', 'min');

	return (
		(days ? `${days} ${dayString} ` : '') +
		(hours ? `${hours} ${hourString} ` : '') +
		(!days && minutes ? `${minutes} ${minString}` : '')
	).trim();
};

export const getTimeDifferenceInSeconds = (startDate: ISOString, endDate: ISOString) => {
	return moment(endDate).diff(moment(startDate), 'seconds');
};

export const getTotalRentalDurationFromProducts = (products: OrderProduct[]): Duration => {
	const earliestStartTime = getEarliestProductStartTime(products);
	return products
		.filter((p) => !isAutoRenewSubscriptionOrderProduct(p))
		.reduce(
			(prev: Duration, current) => {
				const startTimeDiff =
					!earliestStartTime || !current.startDate
						? 0
						: moment(current.startDate).diff(moment(earliestStartTime), 'seconds');
				const totalRentalDuration = (current.rentalDurationInSeconds || 0) + startTimeDiff;
				return totalRentalDuration > prev.durationInSeconds
					? {
							durationInSeconds: totalRentalDuration,
							durationType: current.durationType || '24h',
							durationName: current.durationName || null,
					  }
					: prev;
			},
			{ durationInSeconds: 0, durationType: '24h', durationName: null },
		);
};

export const getProductReturnTimes = (products: OrderProduct[]): string[] => {
	const productsWithDuration = products.filter((p) => !!p.rentalDurationInSeconds);
	// Ignore no-duration products if the order has some products that have duration
	const productsToCheck = !!productsWithDuration.length ? productsWithDuration : products;

	const returnTimes = productsToCheck
		.map((product) => {
			if (!product.startDate || isAutoRenewSubscriptionOrderProduct(product)) return null;

			return (
				product.endDate ||
				getProductEndTimeFromStartDateAndDuration(
					product.startDate,
					product.rentalDurationInSeconds,
				)
			);
		})
		.filter(notNull);

	return returnTimes;
};

export const getNextProductReturnTime = (products: OrderProduct[]): string | null => {
	return minBy(getProductReturnTimes(products)) ?? null;
};

export const getLastProductReturnTime = (products: OrderProduct[]): string | null => {
	return maxBy(getProductReturnTimes(products)) ?? null;
};

const getProductEndTimeFromStartDateAndDuration = (
	startDate: string,
	durationInSeconds: number,
) => {
	return moment(startDate)
		.add(durationInSeconds || 0, 'seconds')
		.toISOString();
};

export const getLatestProductEndTime = (products: OrderProduct[]): string | null => {
	return products.reduce((prev: null | string, current) => {
		if (!current.startDate || isAutoRenewSubscriptionOrderProduct(current)) {
			return prev;
		}
		let endTime = getProductEndTimeFromStartDateAndDuration(
			current.startDate,
			current.rentalDurationInSeconds,
		);
		if (current.endDate) {
			endTime = current.endDate;
		}
		if (!prev || moment(endTime).isAfter(moment(prev))) {
			return endTime;
		}
		return prev;
	}, null);
};

export const getLatestReturnedProductEndTime = (products: OrderProduct[]): string | null => {
	return products.reduce((prev: null | string, current) => {
		if (!current.endDateReturned) {
			return prev;
		}
		if (!prev || moment(current.endDateReturned).isAfter(moment(prev))) {
			return current.endDateReturned;
		}
		return prev;
	}, null);
};

export const getLatestProductStartTime = (products: OrderProduct[]) => {
	return products.reduce((prev: null | string, current) => {
		if (!current.startDate) {
			return prev;
		}
		if (!prev || moment(current.startDate).isAfter(moment(prev))) {
			return current.startDate;
		}
		return prev;
	}, null);
};

export const getEarliestProductStartTime = (products: OrderProduct[]) => {
	const rentalProducts = products.filter((p) => !isLiftTicketProduct(p));
	return (rentalProducts.length ? rentalProducts : products).reduce(
		(prev: null | string, current) => {
			if (!current.startDate) {
				return prev;
			}
			if (!prev || moment(current.startDate).isBefore(moment(prev))) {
				return current.startDate;
			}
			return prev;
		},
		null,
	);
};

export const getRentalDeliveryStartTime = (rental: OrderInfo) => {
	const rentalStartDate =
		rental.services?.delivery?.to?.startDate || rental.startDate || rental.created;
	return moment(rentalStartDate).toString();
};

export const getRentalDeliveryEndTime = (rental: OrderInfo) => {
	const rentalEndDate = rental.services?.delivery?.from?.endDate || rental.returnTimeNext;
	return moment(rentalEndDate).toString();
};

export const getDurationInSecondsAsYears = (durationInSeconds: number) =>
	moment.duration(durationInSeconds, 'seconds').asYears();

export const isOrderReturnLate = (rental: OrderInfo, products: OrderProduct[]): boolean => {
	const latestReturnDate = getLastProductReturnTime(products);
	return (
		rental.rentalState === 'ACTIVE' &&
		!!latestReturnDate &&
		!moment(rental.startDate).isSame(moment(latestReturnDate)) &&
		isTimeBeforeTimeNow(latestReturnDate)
	);
};
