import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { CustomerDb, CustomerOrderHistoryDb } from 'common/models/Customer/types';

import {
	createBasicCollectionOperations,
	createBasicDbOperations,
	createBasicDocOperations,
} from '../../utils';

export const getCustomersQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.customers;
	const dataUpdater = undefined;
	return {
		doc: (id: string) => ({
			...createBasicDocOperations<CustomerDb>(collectionPath, dbRefs, id, dataUpdater),
			orderHistory: customerOrderHistory(dbRefs, id),
		}),
		...createBasicCollectionOperations<CustomerDb>(collectionPath, dbRefs, dataUpdater),
	};
};

const customerOrderHistory = (dbRefs: DbRefs, customerId: string) => {
	const collectionPath = dbPath.customerOrderHistory(customerId);
	const dataUpdater = undefined;
	return createBasicDbOperations<CustomerOrderHistoryDb>(collectionPath, dbRefs, dataUpdater);
};
