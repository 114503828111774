import {
	OnboardingItem,
	OnboardingListVersion,
	OnboardingStepDefinition,
} from 'common/services/analytics/tractionAnalytics';
import { switchUnreachable } from 'common/utils/common';

const INVENTORY_INCLUDED_ITEMS = (version: OnboardingListVersion): OnboardingItem[] => {
	switch (version) {
		case 'V3_USERFLOW':
			return [];
		case 'V3':
		case 'V2_TEMPLATE':
		case 'V2_NO_TEMPLATE':
			return ['VISIT_INVENTORY', 'SKU_CREATION', 'ARTICLE_CREATION', 'PRODUCT_SKU_CONNECTION'];
		case 'V1_TEMPLATE':
		case 'V1_NO_TEMPLATE':
		case 'V0':
			return [
				'VISIT_INVENTORY',
				'VISIT_INVENTORY_SUPPORT_LINK',
				'SKU_CREATION',
				'ARTICLE_CREATION',
				'PRODUCT_SKU_CONNECTION',
			];
		default:
			return switchUnreachable(version);
	}
};

export const INVENTORY_DEFINITION: Record<'INVENTORY', OnboardingStepDefinition> = {
	INVENTORY: {
		plans: 'all',
		versions: {
			V3: INVENTORY_INCLUDED_ITEMS('V3'),
			V2_TEMPLATE: INVENTORY_INCLUDED_ITEMS('V2_TEMPLATE'),
			V2_NO_TEMPLATE: INVENTORY_INCLUDED_ITEMS('V2_NO_TEMPLATE'),
			V1_TEMPLATE: INVENTORY_INCLUDED_ITEMS('V1_TEMPLATE'),
			V1_NO_TEMPLATE: INVENTORY_INCLUDED_ITEMS('V1_NO_TEMPLATE'),
			V0: INVENTORY_INCLUDED_ITEMS('V0'),
		},
	},
};
