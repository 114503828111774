import * as dbPaths from './constants';

const paths = Object.values(dbPaths);

const buildPath = (path: typeof paths[number], args: string | string[]): string => {
	if (typeof args === 'string') {
		args = [args];
	}
	let argsCounter = 0;
	return path
		.split('/')
		.map((part, i) => {
			// Check if path starts with : (such as :orderId), if it does, replace with value from args
			if (part.match(/:.*?/g)) {
				const replacedPart = args[argsCounter];
				argsCounter++;
				return replacedPart;
			}
			return part;
		})
		.join('/');
};

export default buildPath;
