import { z } from 'zod';

import { Carrier, DeliveryOption } from 'common/modules/delivery/types';
import { UserApi } from 'common/modules/users/types';
import { ShopAnalytics } from 'common/services/analytics/tractionAnalytics';

import { DiscountCodeApi } from '.';
import {
	AdminApi,
	Category,
	OLD_PaymentApi,
	OLD_RentalApi,
	OrderInfo,
	OrderProduct,
	PendingDeposit,
	ProductApi,
	Reservation,
	ShopUrlApi,
	Shopper,
	ShopperApi,
} from './common';
import { ShopApi } from './index';
import { OrderEventData, OrderEventType } from './orderEvents';

export const zBaseDbDoc = z.object({
	id: z.string(),
	createdAt: z.string(),
});

export type BaseDbDoc = z.infer<typeof zBaseDbDoc>;

/*
	DATABASE STRUCTURE
*/
export interface DbStructure {
	shoppers: Array<{
		[shopperId: string]: ShopperApi;
	}>;
	shops: Array<{
		[shopId: string]: ShopApi;
	}>;
	users: Array<{
		[userId: string]: UserApi;
	}>;
	products: Array<{
		[productId: string]: ProductApi;
	}>;
	productCategories: Array<{
		[categoryId: string]: Category;
	}>;
	receipts: Array<{
		[receiptId: string]: ProductApi;
	}>;
	shopUrls: Array<{
		[shopUrl: string]: ShopUrlApi;
	}>;
	admin: {
		data: AdminApi;
	};
	orders: Array<{
		[orderId: string]: OrderInfo & {
			// SUBCOLLECTIONS
			versionHistory: Array<{
				[historyId: string]: VersionHistoryApi<OrderInfo>;
			}>;
			events: Array<{
				[eventId: string]: OrderEventApi<OrderEventType>;
			}>;
		};
	}>;
	orderProducts: Array<{
		[orderProductId: string]: OrderProduct;
	}>;
	orderShoppers: Array<{
		[orderShopperId: string]: Shopper;
	}>;
	reservations: Array<{
		[reservationId: string]: Reservation;
	}>;
	discountCodes: Array<{
		[discountCodeId: string]: DiscountCodeApi;
	}>;
	// DEPRECATED
	storeRentals: Array<{
		[rentalId: string]: OrderInfo;
	}>;
	// DEPRECATED
	rentals: Array<{
		[rentalId: string]: OLD_RentalApi;
	}>;
	// DEPRECATED
	OLD_payments: Array<{
		[paymentId: string]: OLD_PaymentApi;
	}>;
	// DEPRECATED
	OLD_transactions: Array<{
		[transactionId: string]: OLD_PaymentApi;
	}>;
	pendingDeposits: Array<{
		[id: string]: PendingDeposit;
	}>;
	analyticsShop: Array<{
		[docId: string]: ShopAnalytics;
	}>;
	deliveryOptions: Array<{
		[id: string]: DeliveryOption;
	}>;
	carriers: Array<{
		[id: string]: Carrier;
	}>;
}

export interface VersionHistoryApi<T> {
	id: string;
	createdAt: string;
	added?: Partial<T>; // Deprecated, in use before 11/20
	updated?: Partial<T>; // Deprecated, in use before 11/20
	removedKeys: Array<keyof T>;
	addedKeys?: Array<keyof T>; // In use from 11/20
	updatedKeys?: Array<keyof T>; // In use from 11/20
	data?: T; // Data after, deprecated
	dataBefore?: T; // In use from 11/20
}

export interface OrderEventApi<T extends OrderEventType> extends OrderEventData<T> {
	id: string;
	createdAt: string;
	event: T;
}
