import React from 'react';

import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useRegisterContext } from 'views/Register/RegisterContext';

import { CustomerPersona, CustomerPersonas } from 'common/services/analytics/tractionAnalytics';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';

const CustomerPersonaPicker = () => {
	const [customerPersona, setStore] = useRegisterContext((state) => state.customerPersona);
	const { value, specifications } = customerPersona;
	const { t } = useTranslation();
	const { logRegisterActionEvent } = useAnalytics();

	const handleCustomerPersonaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value as CustomerPersona;
		logRegisterActionEvent('customer_persona', value);
		setStore((store) => {
			store.dirty = true;
			store.customerPersona.value = value;
		});
	};

	const handleCustomerPersonaSpecificationChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		customerPersona: CustomerPersona,
	) => {
		const value = e.target.value;
		logRegisterActionEvent('customer_persona_specification', value);
		setStore((store) => {
			store.dirty = true;
			if (!!store.customerPersona.specifications) {
				store.customerPersona.specifications[customerPersona] = value;
			} else {
				store.customerPersona.specifications = {
					[customerPersona]: value,
				};
			}
		});
	};

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const renderCustomerPersonaSpecifiction = () => {
		const specificationOptions = [
			{
				value: 'CORE_BUSINESS',
				label: t('register.forMyCoreBusiness', 'For my core business'),
			},
			{
				value: 'SECONDARY_BUSINESS',
				label: t('register.forMySecondaryBusiness', 'For my secondary business'),
			},
		];
		return (
			<TextField
				label={`${t(
					'register.howAreYouPlanningToUseRentle',
					'How are you planning to use Rentle',
				)}?`}
				select
				value={specifications?.[CustomerPersonas.REPLACING] ?? ''}
				onChange={(e) => handleCustomerPersonaSpecificationChange(e, CustomerPersonas.REPLACING)}
			>
				{specificationOptions.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						<Typography variant="body1">{option.label}</Typography>
					</MenuItem>
				))}
			</TextField>
		);
	};

	return (
		<Stack spacing={2} direction={'column'}>
			<FormControl>
				<Box
					sx={{
						border: '1px solid rgba(0,0,0,0.23)',
						borderRadius: '4px',
						width: '100%',
						p: 2,
						position: 'relative',
						'&:focus': {
							borderColor: (theme) => theme.palette.primary.main,
						},
					}}
				>
					<FormLabel
						id="demo-radio-buttons-group-label"
						sx={{
							position: 'absolute',
							top: (theme) => `-14px`,
							px: (theme) => theme.spacing(0.5),
							left: (theme) => theme.spacing(2),
							backgroundColor: '#FAFAFA',
							zIndex: 1,
						}}
					>
						<Typography variant="caption">{`${t(
							'register.whichOfTheseBestDescribeYou',
							'Which of these best describe you',
						)}?`}</Typography>
					</FormLabel>
					<RadioGroup
						aria-labelledby="customer-persona-label"
						name="customer-persona"
						value={value ?? ''}
						onChange={handleCustomerPersonaChange}
					>
						<FormControlLabel
							value={CustomerPersonas.DISCOVERING}
							control={<Radio />}
							label={t('register.userPersonaDiscovering2', 'I’m starting a new business')}
							componentsProps={{
								typography: {
									fontSize: isMobile ? '1.4rem' : undefined,
								},
							}}
						/>
						<FormControlLabel
							value={CustomerPersonas.REPLACING}
							control={<Radio />}
							label={t('register.userPersonaReplacing2', 'I’m already selling online or in person')}
							componentsProps={{
								typography: {
									fontSize: isMobile ? '1.4rem' : undefined,
								},
							}}
						/>
						<FormControlLabel
							value={CustomerPersonas.AGENCIES}
							control={<Radio />}
							label={t('register.userPersonaAgencies', 'I’m looking for a solution for my client')}
							componentsProps={{
								typography: {
									fontSize: isMobile ? '1.4rem' : undefined,
								},
							}}
						/>
						<FormControlLabel
							value={CustomerPersonas.PLAYING}
							control={<Radio />}
							label={t('register.userPersonaPlaying', 'I’m just playing around')}
							componentsProps={{
								typography: {
									fontSize: isMobile ? '1.4rem' : undefined,
								},
							}}
						/>
					</RadioGroup>
				</Box>
			</FormControl>
			{value === CustomerPersonas.REPLACING && renderCustomerPersonaSpecifiction()}
		</Stack>
	);
};

export default CustomerPersonaPicker;
