import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'EDIT_PRODUCT_PRICING' })
			: getRouteOnboardingStepItem({
					routeTo: Routes.CatalogTab('products'),
			  }),
		content: {
			title: t(
				'homeView.onboarding.customizeYourProductsPricingOptions',
				`Customize your products' pricing options`,
			),
		},
		id: 'EDIT_PRODUCT_PRICING',
		type: 'row',
	},
	V0: {
		action: showProductTours
			? getFlowOnboardingStepItem({ onboardingAction: 'EDIT_PRODUCT_PRICING' })
			: getRouteOnboardingStepItem({
					routeTo: Routes.CatalogTab('products'),
			  }),
		content: {
			title: t('homeView.onboarding.editProductPricing', `Customize your product's pricing`),
		},
		id: 'EDIT_PRODUCT_PRICING',
		type: 'row',
	},
});

export const EDIT_PRODUCT_PRICING_ITEM = ({
	t,
	showProductTours,
}: {
	t: TFunction;
	showProductTours: boolean;
}): Record<
	'EDIT_PRODUCT_PRICING',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t, showProductTours });
	return {
		EDIT_PRODUCT_PRICING: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
