import React, { useEffect, useState } from 'react';

import { Box, GlobalStyles, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
	FacebookAuthProvider,
	GoogleAuthProvider,
	UserCredential,
	getAdditionalUserInfo,
} from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc';
import { RiFacebookCircleFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { StaticContext } from 'react-router';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { AuthProvider } from 'views/Register/utils';

import { TextDivider } from 'common/components/TextDivider';
import { signInWithEmailAndPassword, signInWithPopup } from 'common/frontend/firebase/auth';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { isDevEnv, switchUnreachable } from 'common/utils/common';
import { getQueryParam } from 'common/utils/frontUtils';
import * as UserActions from 'actions/UserActions';
import AuthContainer from 'components/AuthContainer';
import { Banner } from 'components/Banners';
import Spinner from 'components/Spinner';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes } from 'routing';

import { addIsUnregisteredUserLogin } from './unregisteredUserLogin';

type LocationState = {
	from: { [key: string]: string };
};

type Props = RouteComponentProps<{}, StaticContext, LocationState>;

type InputEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>;

const Login = (props: Props) => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState<AuthProvider | null>(null);
	const [redirectAfterLogin, setRedirectAfterLogin] = useState<boolean>(false);
	const showSocialLogins = isDevEnv() || getQueryParam('social') === 'true';

	const { classes } = useStyles();
	const { t } = useTranslation();
	const { logAnalyticsEvent } = useAnalytics();

	useEffect(() => {
		dispatch(UserActions.logout());
	}, [dispatch]);

	const handleEmailChange = (event: InputEvent) => {
		const value = event.target.value;
		setEmail(value);
	};

	const handlePasswordChange = (event: InputEvent) => {
		const value = event.target.value;
		setPassword(value);
	};

	const getAuthError = (error: any): { success: false; error: string; code?: string } => {
		const emailInUseCodes = ['auth/account-exists-with-different-credential'];
		const invalidEmailOrPasswordCodes = [
			'auth/invalid-email',
			'auth/user-disabled',
			'auth/wrong-password',
		];
		const noUserFoundCodes = ['auth/user-not-found'];
		let errorText = t(
			'common:errors.somethingWentWrong',
			'Something went wrong - please try again.',
		);
		if (emailInUseCodes.includes(error?.code)) {
			errorText = t(
				'login.emailExists',
				'An account with this email already exists, and is connected to another login method.',
			);
		}
		if (invalidEmailOrPasswordCodes.includes(error?.code)) {
			errorText = t(
				'login.invalidEmailOrPassword',
				'Incorrect email or password. Please try again.',
			);
		}
		if (noUserFoundCodes.includes(error?.code)) {
			errorText = t(
				'login.noUserFound',
				"We don't recognize that email. Please try again or create an account first.",
			);
		}
		return { success: false, error: errorText, code: error?.code };
	};

	const loginWithProvider = async (
		provider: AuthProvider,
	): Promise<
		| { success: true; userCredential: UserCredential }
		| { success: false; error: string; code?: string }
	> => {
		const loginUser = () => {
			switch (provider) {
				case 'email_password': {
					return signInWithEmailAndPassword(email, password);
				}
				case 'google': {
					const provider = new GoogleAuthProvider();
					provider.setCustomParameters({
						prompt: 'select_account',
					});
					return signInWithPopup(provider);
				}
				case 'facebook': {
					const provider = new FacebookAuthProvider();
					provider.setCustomParameters({
						display: 'popup',
					});
					return signInWithPopup(provider);
				}
				default: {
					return switchUnreachable(provider);
				}
			}
		};
		try {
			const result = await loginUser();
			const additionalUserInfo = getAdditionalUserInfo(result);
			/**
			 * Social logins do not have sign up action separately, but only sign-in. In Login, we want
			 * to ensure that users are registered before login, so during social login, we need to check
			 * if the user is a new user, and if so, sign them out and show an error message instead.
			 * NOTE: signOut is done onAuthStateChanged callback to ensure it's done at correct time
			 */
			if (!additionalUserInfo || additionalUserInfo.isNewUser) {
				addIsUnregisteredUserLogin();
				return {
					success: false,
					error: t(
						'login.noUserFound',
						"We don't recognize that email. Please try again or create an account first.",
					),
				};
			}
			return { success: true, userCredential: result };
		} catch (e) {
			return getAuthError(e);
		}
	};

	const login = async (provider: AuthProvider) => {
		setError(null);
		setLoading(provider);
		const result = await loginWithProvider(provider);
		if (result.success) {
			setError(null);
			setPassword('');
			setEmail('');
			setLoading(null);
			setRedirectAfterLogin(true);
			logAnalyticsEvent({ name: 'login', params: { method: 'email' } });
		} else {
			errorHandler.report(`${result.error}, code: ${result.code}`);
			setError(result.error);
			setLoading(null);
		}
	};

	const { from } = (props.location && props.location.state) || {
		from: { pathname: Routes.ROOT },
	};

	if (redirectAfterLogin) {
		return <Redirect to={from} />;
	}

	return (
		<>
			<GlobalStyles
				styles={(theme) => ({ body: { backgroundColor: theme.palette.common.white } })}
			/>
			<AuthContainer
				title={t('common:actions.log_in', 'Log in')}
				description={t('login.continueToX', {
					service: 'Rentle',
					defaultValue: 'Continue to {{service}}',
				})}
			>
				{!!error && (
					<Box my={3}>
						<Banner variant="danger" description={error} />
					</Box>
				)}
				<form
					onSubmit={(e) => {
						e.preventDefault();
						login('email_password');
					}}
				>
					<TextField
						id="email"
						variant="outlined"
						label={t('common:form.email')}
						name="email"
						autoComplete="email"
						className={classes.textField}
						value={email}
						onChange={handleEmailChange}
						margin="normal"
						type="text"
						required
					/>
					<TextField
						id="password"
						variant="outlined"
						label={t('common:form.password', 'Password')}
						name="password"
						autoComplete="current-password"
						className={classes.textField}
						value={password}
						onChange={handlePasswordChange}
						margin="normal"
						type="password"
						required
					/>
					<Link to={Routes.PASSWORD_RESET + `?email=${encodeURIComponent(email)}`}>
						<Typography variant="caption" className={classes.noTextTransform}>
							{t('common:form.forgotPassword', 'Forgot your password?')}
						</Typography>
					</Link>
					<Box mt={2}>
						<Button
							className={classes.loginBtn}
							variant="contained"
							type="submit"
							disabled={!!loading}
							data-qa="login"
							color="secondary"
						>
							{loading === 'email_password' ? (
								<Spinner padding={8} thickness={4} />
							) : (
								t('common:actions.log_in', 'Log in')
							)}
						</Button>
					</Box>
				</form>
				{showSocialLogins && (
					<>
						<Box my={2}>
							<TextDivider text={t('common:conjunctions.or', 'or')} />
						</Box>
						<Button
							sx={{ textTransform: 'none', borderRadius: '8px' }}
							disabled={!!loading}
							onClick={() => login('google')}
							variant="outlined"
							fullWidth
							startIcon={<FcGoogle />}
						>
							{'Log in with Google'}
						</Button>
						<Box mt={2} />
						<Button
							sx={{ textTransform: 'none', borderRadius: '8px' }}
							disabled={!!loading}
							onClick={() => login('facebook')}
							variant="outlined"
							fullWidth
							startIcon={<RiFacebookCircleFill color="#1877F2" />}
						>
							{'Log in with Facebook'}
						</Button>
					</>
				)}
				<Box mt={2} />
				<Typography className={classes.notRegistered} variant="body2">
					{t('login.newToRentle', 'New to Rentle?')}{' '}
					<Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>
						<Link className={classes.notRegistered} to={Routes.REGISTER}>
							{t('login.createAccount', 'Create your account')}
						</Link>
					</Box>
				</Typography>
			</AuthContainer>
		</>
	);
};

const useStyles = makeStyles()((theme) => ({
	loginBtn: {
		width: '100%',
		background: theme.palette.common.black,
		borderRadius: '8px',
		textTransform: 'none',
	},
	textField: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
	},
	noTextTransform: {
		textTransform: 'none',
		color: theme.palette.primary.main,
	},
	notRegistered: {
		width: 'fit-content',
	},
}));

export default Login;
