import { useCallback } from 'react';

import { Box, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageFiles } from 'views/Store/Tabs/OnlineStore/components/Customizer/utils';

import MoneyTextField from 'common/components/MoneyTextField';
import * as NotificationActions from 'actions/NotificationActions';
import * as ShopSelectors from 'selectors/ShopSelectors';
import { useTranslation } from 'services/localization';

import ImageUploader from './ImageUploader';
import { ListingFormData } from './ListingCreate';
import CurrencySelect from './components/CurrencySelect';

const CreateListingForm = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const currency = useSelector(ShopSelectors.getShopCurrency);
	const mainLocationId = useSelector(ShopSelectors.activeShopMainLocationId);

	const {
		control,
		formState: { errors },
	} = useFormContext<ListingFormData>();

	const imageUrls = useWatch({
		control,
		name: 'imageURLs',
	});

	const onDrop = useCallback(
		(files: File[], onChange: any) => {
			try {
				uploadImageFiles(
					files,
					{
						shopId: mainLocationId,
						width: 1280,
						height: 600,
						path: 'featured-images',
					},
					(url) => {
						onChange([...(imageUrls ?? []), url]);
					},
				);
			} catch (e) {
				dispatch(
					NotificationActions.showNotification({
						message: t('common:errors.wrongFileType', 'Wrong file type'),
						variant: 'error',
						autoDismissMs: 2000,
					}),
				);
			}
		},
		[dispatch, imageUrls, mainLocationId, t],
	);

	return (
		<Stack spacing={2}>
			<Typography variant="h5">{t('listings.createListing', 'Create listing')}</Typography>
			<Stack>
				<Grid container spacing={2}>
					{imageUrls?.map((url) => (
						<Grid key={url} item xs={6}>
							<Box key={url}>
								<img src={url} alt={url} width="100%"></img>
							</Box>
						</Grid>
					))}
					<Grid item xs={imageUrls?.length ? 6 : 12}>
						<Controller
							name="imageURLs"
							control={control}
							rules={{
								required: true,
							}}
							render={({ field }) => (
								<ImageUploader onDrop={(files) => onDrop(files, field.onChange)} />
							)}
						/>
					</Grid>
				</Grid>
			</Stack>
			<Controller
				name="title"
				control={control}
				rules={{
					required: true,
				}}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('listings.title', 'Title')}
						variant="outlined"
						fullWidth
						error={!!errors.title}
						helperText={errors.title?.message}
					/>
				)}
			/>
			<Controller
				name="price"
				control={control}
				rules={{
					required: true,
				}}
				render={({ field }) => (
					<MoneyTextField
						{...field}
						onChange={(e) => field.onChange(parseFloat(e.target.value))}
						label={t('listings.price', 'Price')}
						variant="outlined"
						fullWidth
						currency={currency}
						error={!!errors.price}
						helperText={errors.price?.message}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Controller
										name="currency"
										control={control}
										render={({ field }) => <CurrencySelect variant="standard" {...field} />}
									/>
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
			<Controller
				name="description"
				control={control}
				rules={{
					required: true,
				}}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('listings.description', 'Description')}
						variant="outlined"
						fullWidth
						multiline
						rows={4}
						error={!!errors.description}
						helperText={errors.description?.message}
					/>
				)}
			/>
		</Stack>
	);
};

export default CreateListingForm;
