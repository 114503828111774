import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';
import { Routes } from 'routing';

import { OnboardingStepItem } from '../types';
import { getRouteOnboardingStepItem } from '../utils';

const ITEM_VERSIONS = ({ t }: { t: TFunction }): Record<'V1' | 'V0', OnboardingStepItem> => ({
	V1: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.ACCOUNT_LOCALIZATION,
			completeOnClick: true,
		}),
		content: {
			title: t(
				'homeView.onboarding.verifyYourCurrencyAndTimeZone',
				'Verify your currency and time zone',
			),
		},
		id: 'REGIONAL_FORMATS',
		type: 'row',
	},
	V0: {
		action: getRouteOnboardingStepItem({
			routeTo: Routes.ACCOUNT_LOCALIZATION,
			completeOnClick: true,
		}),
		content: {
			title: t(
				'homeView.onboarding.regionalFormatsTitle2',
				'Verify your currency and regional formats',
			),
		},
		id: 'REGIONAL_FORMATS',
		type: 'row',
	},
});

export const REGIONAL_FORMATS_ITEM = ({
	t,
}: {
	t: TFunction;
}): Record<
	'REGIONAL_FORMATS',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => {
	const versions = ITEM_VERSIONS({ t });
	return {
		REGIONAL_FORMATS: {
			V3: versions['V1'],
			V2_TEMPLATE: versions['V1'],
			V2_NO_TEMPLATE: versions['V1'],
			V1_TEMPLATE: versions['V0'],
			V1_NO_TEMPLATE: versions['V0'],
			V0: versions['V0'],
		},
	};
};
