import { Plan } from 'common/modules/plans/plans';
import { EnumValues } from 'common/types';
import { getTypedKeys, stringEnum } from 'common/utils/objects';

export const AddOns = stringEnum([
	'BRANDING',
	'PACKAGE_PRODUCTS',
	'PRODUCT_VARIANTS',
	'EXTENSIONS',
	'GOOGLE_ANALYTICS',
	'MASS_RETURN',
	'DIN_SETTINGS',
	'DISCOUNT_CODES',
	'MAINTENANCE_TIME',
	'CATEGORY_TERMS',
	'MANUAL_PAYMENT_METHODS',
	'DASHBOARD',
	'SKIDATA',
	'TEKSO',
	'CLOUD_POS',
]);
export type AddOn = EnumValues<typeof AddOns>;

export const AllAddOns = getTypedKeys(AddOns);

export const AddOnChannels = stringEnum(['promo_page', 'block_store', 'cta_button']);

export type AddOnChannel = EnumValues<typeof AddOnChannels>;

export interface AddOnDefinition {
	/**
	 * The unique id code of the add-on
	 */
	addOn: AddOn;

	/**
	 * How many block slots this add-on takes up
	 */
	blockSlots: number;

	/**
	 * This add-on will require manual work from Rentle staff
	 */

	manualSetup?: boolean;

	/**
	 * The add-on will be hidden from Admin
	 */

	hidden?: boolean;

	/**
	 * The add-on cannot be installed without one of the following plans
	 */
	requiresPlan?: Plan[];

	/**
	 * Should this add-on be automatically installed when upgrading to one of the supported plans?
	 */
	autoInstall?: boolean;
}

export interface AddOnDetails {
	/**
	 * The localized name of the add-on
	 */
	name: string;

	/**
	 * The localized short description of the add-on
	 */
	subHeader: string;

	/**
	 * The localized longer description of the add-on
	 */
	description?: string;

	/**
	 * A link to the support page for this add-on
	 */
	supportArticle?: string;

	/**
	 * A custom uninstall notice for this specific add-on
	 */
	uninstallNotice?: string;
}

export interface InstalledAddOn {
	/**
	 * The purchased add-on
	 */
	addOn: AddOn;

	/**
	 * When this add-on was installed
	 */
	startDate: string;

	/**
	 * An optional internal note about this block for a specific merchant, which can be edited in internal admin
	 */
	internalNote?: string;
}

/**
 * The add-ons a specific merchant has installed
 */
export type ShopAddons = {
	[key in AddOn]?: InstalledAddOn;
};
