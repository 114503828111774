import { useLocation } from 'react-router-dom';

import _buildPath from './buildPath';
import * as _Routes from './routes';
import * as RoutesWithArguments from './routesWithArguments';
import { StateArguments } from './types';

export const Routes = {
	..._Routes,
	...RoutesWithArguments,
};

export const useTypedLocation = () => {
	return useLocation<StateArguments | undefined>();
};

export const buildPath = _buildPath;
export { push, replace, goBack, goForward, isRouteActive } from './utils';
