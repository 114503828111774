import { minBy } from 'lodash';

import {
	ItemPricing,
	getEmptyItemPricing,
	getTotalItemPricing,
	multiplyItemPricing,
} from 'common/modules/atoms/pricing';
import { OrderProduct, PurchaseTypes, YYYY_MM_DD } from 'common/types';
import { notNull } from 'common/utils/common';

import { getNextBillingDate } from './billingDates';
import {
	getCommittedBillingCycleCount,
	getCurrentBillingCycleCount,
	getUpcomingBillingCycleCount,
	isAutoRenewSubscriptionOrderProduct,
} from './subscriptions';

/**
 * Get the recurring subscription price for an order product
 *
 * @param product An order product
 * @returns The recurring subscription price
 */
export const getRecurringSubscriptionPriceFromProduct = (product: OrderProduct): ItemPricing => {
	if (!product.subscription || product.purchaseType !== PurchaseTypes.subscription) {
		return getEmptyItemPricing(product.pricing.currency);
	}

	return product.pricing;
};

/**
 * Get the combined recurring subscription price for an array of order products
 *
 * @param products An array of order products
 * @returns The combined recurring subscription price
 */
export const getRecurringSubscriptionPriceFromProducts = (
	products: OrderProduct[],
): ItemPricing | null => {
	if (products.length === 0) return null;
	return getTotalItemPricing(
		products.map((p) => getRecurringSubscriptionPriceFromProduct(p)),
		products[0].pricing.currency,
	);
};

/**
 * Get the total subscription price for an order product
 *
 * @param product An order product
 * @returns The total subscription price (or infinity if the subscription does not have an end date)
 */
export const getTotalSubscriptionPriceFromProduct = (product: OrderProduct): ItemPricing => {
	const realisedPrice = getRealisedSubscriptionPriceFromProduct(product);

	if (isAutoRenewSubscriptionOrderProduct(product)) {
		const totalPrice = getTotalItemPricing([realisedPrice], product.pricing.currency);
		return {
			...totalPrice,
			deposit: product.pricing.deposit,
		};
	} else {
		const remainingCycles = getUpcomingBillingCycleCount(product.subscription);
		const pricePerCycle = getRecurringSubscriptionPriceFromProduct(product);
		const remainingPrice = multiplyItemPricing(pricePerCycle, remainingCycles);

		const totalPrice = getTotalItemPricing(
			[realisedPrice, remainingPrice],
			product.pricing.currency,
		);
		return {
			...totalPrice,
			deposit: product.pricing.deposit,
		};
	}
};

/**
 * Get the combined total subscription price for an array of order products
 *
 * @param products An array of order products
 * @returns The combined total subscription price (or infinity if one of the subscriptions does not have an end date)
 */
export const getTotalSubscriptionPriceFromProducts = (
	products: OrderProduct[],
): ItemPricing | null => {
	if (products.length === 0) return null;
	return getTotalItemPricing(
		products.map((p) => getTotalSubscriptionPriceFromProduct(p)),
		products[0].pricing.currency,
	);
};

/**
 * Get the realised subscription price for an order product (payments that should have already happened)
 *
 * @param product An order product
 * @returns The realised subscription price
 */
export const getRealisedSubscriptionPriceFromProduct = (product: OrderProduct): ItemPricing => {
	/**
	 * TODO: This logic needs to somehow take into account payments that have been made.
	 *
	 * Examples of problematic cases:
	 *
	 * - The subscription has been charged 3 times, but only 2 payments have succeeded
	 * - The subscription price has changed at some point, in which case {current price per cycle} * {cycles} will not give the correct end result
	 */
	const pricePerCycle = getRecurringSubscriptionPriceFromProduct(product);
	const currentCycles = getCurrentBillingCycleCount(product.subscription);
	return multiplyItemPricing(pricePerCycle, currentCycles);
};

/**
 * Get the combined realised subscription price for an array of order products
 *
 * @param products An array of order products
 * @returns The combined realised subscription price
 */
export const getRealisedSubscriptionPriceFromProducts = (
	products: OrderProduct[],
): ItemPricing | null => {
	if (products.length === 0) return null;
	return getTotalItemPricing(
		products.map((p) => getRealisedSubscriptionPriceFromProduct(p)),
		products[0].pricing.currency,
	);
};

/**
 * Get the upcoming subscription price for an order product (payments that are scheduled to happen in the future)
 *
 * @param product An order product
 * @returns The upcoming subscription price
 */
export const getUpcomingSubscriptionPriceFromProduct = (product: OrderProduct): ItemPricing => {
	const pricePerCycle = getRecurringSubscriptionPriceFromProduct(product);
	const upcomingCycles = getUpcomingBillingCycleCount(product.subscription);
	return multiplyItemPricing(pricePerCycle, upcomingCycles);
};

/**
 * Get the combined upcoming subscription price for an array of order products
 *
 * @param products An array of order products
 * @returns The combined upcoming subscription price
 */
export const getUpcomingSubscriptionPriceFromProducts = (
	products: OrderProduct[],
): ItemPricing | null => {
	if (products.length === 0) return null;
	return getTotalItemPricing(
		products.map((p) => getUpcomingSubscriptionPriceFromProduct(p)),
		products[0].pricing.currency,
	);
};

/**
 * Get the total committed subscription price for an order product (the minimum price the customer has agreed to pay)
 *
 * @param product An order product
 * @returns The total committed subscription price
 */
export const getCommittedSubscriptionPriceFromProduct = (product: OrderProduct): ItemPricing => {
	const pricePerCycle = getRecurringSubscriptionPriceFromProduct(product);
	const committedCycles = getCommittedBillingCycleCount(product.subscription);
	return multiplyItemPricing(pricePerCycle, committedCycles);
};

/**
 * Get the total committed subscription price for an array of order products
 *
 * @param products An array of order products
 * @returns The combined total committed subscription price
 */
export const getTotalCommittedSubscriptionPriceFromProducts = (
	products: OrderProduct[],
): ItemPricing | null => {
	if (products.length === 0) return null;
	return getTotalItemPricing(
		products.map((p) => getCommittedSubscriptionPriceFromProduct(p)),
		products[0].pricing.currency,
	);
};

/**
 * Get the next subscription instalment date & amount for an order product
 *
 * @param product The order product
 * @returns The next instalment, or null if there is no next instalment
 */
export const getNextInstalmentFromProduct = (
	product: OrderProduct,
): { date: YYYY_MM_DD; amount: ItemPricing } | null => {
	if (!product.subscription) return null;
	if (!!product.endDateReturned) return null;
	const date = getNextBillingDate(product.subscription);
	const amount = getRecurringSubscriptionPriceFromProduct(product);
	if (!date || !amount?.total) return null;

	return {
		date,
		amount,
	};
};

/**
 * Get the next subscription instalment date & amount for an array of order products
 *
 * @param products The order products
 * @returns The next instalment, or null if there is no next instalment
 */
export const getNextInstalmentFromProducts = (
	products: OrderProduct[],
): { date: YYYY_MM_DD; amount: ItemPricing } | null => {
	const instalments = products.map((p) => getNextInstalmentFromProduct(p)).filter(notNull);
	const nextDate = minBy(instalments, (i) => i.date)?.date ?? null;
	if (!nextDate) return null;
	const items = instalments.filter((i) => i.date === nextDate);
	if (!items.length) return null;

	return {
		date: nextDate,
		amount: getTotalItemPricing(
			items.map((i) => i.amount),
			items[0].amount.currency,
		),
	};
};
