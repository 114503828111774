export const isValidEmail = (value: string) => {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(value).toLowerCase());
};

export const isValidGA4AnalyticsTag = (value: string) => {
	const ga4TagSplit = value.split('-');
	const [tag, number] = ga4TagSplit;
	return ga4TagSplit.length === 2 && tag === 'G' && number.length > 5;
};

export const isValidUAAnalyticsTag = (value: string) => {
	const uaTagSplit = value.split('-');
	const [tag, number, ending] = uaTagSplit;
	return uaTagSplit.length === 3 && tag === 'UA' && number.length > 5 && !!ending?.length;
};

export const isValidGtmTag = (value: string) => {
	const gmtTagSplit = value.split('-');
	const [tag, ending] = gmtTagSplit;
	return gmtTagSplit.length === 2 && tag === 'GTM' && ending.length > 5;
};

export const isValidHexColor = (value: string) => {
	const re = /^(#(?:[0-9a-fA-F]{3}){1,2})$/;
	return re.test(value);
};

export const removeIncorrectDbPathCharacters = (text: string) => {
	// Firestore does not allow []/~* characters as field paths
	// We do not want to allow . because it can cause adding an unwanted nested object if included to .update field path, such as .update('pricing.bike.m': {...})
	const validString = text.replace(/[\][/.~*]/g, '');
	return validString;
};
