import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { BaseDbDoc } from 'common/types';

import { createBasicDbOperations } from '../utils';

export type ReportExportFile = {
	uri: string;
	fileName: string;
	bucketName: string;
};

export interface ReportExportApi extends BaseDbDoc {
	expirationDateTime: string | null;
	status: 'pending' | 'deleted' | 'ready' | 'failed';
	file: ReportExportFile | null;
}

export const getReportExportQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.reportExportsCollection;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<ReportExportApi>(collectionPath, dbRefs, dataUpdater),
	};
};
