import moment from 'moment-timezone';

import { TimeRange } from 'services/types';

export const getFormattedTimeDifferenceComparedToTimeNow = (dateString: string) => {
	const momentTimeDifference = moment.duration(moment().diff(moment(dateString)));
	let timeDifferenceAsString;

	if (momentTimeDifference.asMinutes() < 60) {
		timeDifferenceAsString = momentTimeDifference.asMinutes().toFixed() + 'min';
	} else {
		timeDifferenceAsString = momentTimeDifference.asHours().toFixed() + 'hours';
	}
	return timeDifferenceAsString;
};

/**
 * Translates the time range object into start and end timestamps. The
 * timestamps are ISO 8601 UTC strings. One or both might be undefined to signal
 * an open-ended range.
 *
 * @param timeRange the `TimeRange` object to evaluate
 */
export const evaluateTimeRange = (timeRange: TimeRange) => {
	let start: string | undefined;
	let end: string | undefined;
	switch (timeRange.type) {
		case 'all':
			break; // no filters
		case 'past-week':
			start = moment().subtract(1, 'week').toISOString();
			break;
		case 'past-month':
			start = moment().subtract(1, 'month').toISOString();
			break;
		case 'past-3-months':
			start = moment().subtract(3, 'month').toISOString();
			break;
		case 'custom':
			start = moment(timeRange.range[0]).toISOString();
			end = moment(timeRange.range[1]).toISOString();
			break;
	}
	return { start, end };
};

/**
 * Returns the "signature" of the time range object. This should be treated
 * as an opaque string that can be used to compare to see if the range
 * object has changed.
 */
export const getTimeRangeSignature = (timeRange: TimeRange): string => {
	if (timeRange.type === 'custom') {
		return `${timeRange.type}-${timeRange.range[0]}-${timeRange.range[1]}`;
	}
	return timeRange.type;
};
