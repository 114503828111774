import React from 'react';

import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Stack,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACCOUNT_LANGUAGES, ADD_ONS_STORE, PLANS } from 'routing/routes';
import { isRouteActive } from 'routing/utils';
import { makeStyles } from 'tss-react/mui';

import RentleLogo from 'common/components/RentleLogo';
import * as IntercomService from 'common/services/analytics/intercom';
import ExternalLink from 'components/ExternalLink';
import { useAnalytics } from 'hooks/useAnalytics';
import * as ShopSelectors from 'selectors/ShopSelectors';
import history from 'services/history';
import { useTranslation } from 'services/localization/useTranslation';
import { replace } from 'routing';

export interface AccountDisabledModalProps {}

const AccountDisabledModal = (props: AccountDisabledModalProps) => {
	const { classes } = useStyles();
	const { t } = useTranslation();
	const location = useLocation();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { logAnalyticsEvent } = useAnalytics();

	const isInAddOnStore = isRouteActive(ADD_ONS_STORE, location.pathname);
	const isInPlan = isRouteActive(PLANS, location.pathname);
	const isInLanguages = isRouteActive(ACCOUNT_LANGUAGES, location.pathname);
	const shopFeatures = useSelector(ShopSelectors.shopFeatures);
	const isDemoPlan = shopFeatures?.plan?.plan === 'DEMO';
	const accountDisabled =
		shopFeatures?.plan?.campaign === 'core_commission_to_core_demo_inactive_account_disabled' ||
		shopFeatures?.plan?.campaign === 'old_enterprise_to_core_demo_account_disabled' ||
		shopFeatures?.plan?.campaign === 'core_demo_to_core_demo_disabled';

	const handleTalkToUsClick = () => {
		IntercomService.open();
		logAnalyticsEvent({ name: 'chat_with_us_press', params: { from: history.location.pathname } });
	};

	return (
		<Dialog
			open={isDemoPlan && accountDisabled && !isInAddOnStore && !isInPlan && !isInLanguages}
			maxWidth="sm"
			fullWidth
			fullScreen={fullScreen}
		>
			<DialogContent className={classes.content}>
				<Box mb={5}>
					<RentleLogo width="200px" type="black" />
				</Box>
				<Typography variant="h5">
					{t('accountDisabledModal.title', 'Hi! Choose your new Rentle plan to continue')}
				</Typography>
				<Box my={3}>
					<Typography variant="body1">
						{t(
							'accountDisabledModal.body2',
							`Rentle’s pricing has been updated on 5.7.2023. To continue to use your account, you’ll
						need to choose your new plan: Free, Discover, Build or Advance.`,
						)}
					</Typography>
				</Box>

				<Box mb={2}>
					<Typography variant="body1">
						<ExternalLink href="https://rentle.io/pricing">
							{t('accountDisabledModal.link2', 'New pricing plans')}
						</ExternalLink>
					</Typography>
				</Box>

				<Box mt={8}>
					<Stack direction={{ xs: 'column', md: 'column' }} alignItems="center" spacing={1.5}>
						<Button variant="contained" onClick={() => replace(PLANS)}>
							{t('accountDisabledModal.btn1', 'Choose a new plan')}
						</Button>
						<Button variant="text" onClick={handleTalkToUsClick}>
							{t('accountDisabledModal.btn2', 'Ask to cancel account')}
						</Button>
					</Stack>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	content: {
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(12, 8),
		},
		textAlign: 'center',
	},
	dialogActions: {
		padding: theme.spacing(0, 5, 5, 5),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1),
		},
	},
}));

export default AccountDisabledModal;
