import moment from 'moment-timezone';

import { Api } from 'common/db/api/paths';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { PartialReservation, SalesReservation } from 'common/types';

import { AvailabilityDataSources } from './types';

export interface FetchSalesReservationsProps {
	skuId: string;
	startDate: string;
	endDate: string;
	startLocationId: string;
	ownReservationId?: string;
	dataSources?: AvailabilityDataSources;
	api: Api;
}

export interface FetchSalesReservationsFromDataProps extends FetchSalesReservationsProps {
	salesReservations: SalesReservation[];
}

export const fetchSalesReservations = async (
	props: FetchSalesReservationsProps,
): Promise<PartialReservation[]> => {
	try {
		if (!!props.dataSources) {
			return getSalesReservationsFromData({
				...props,
				salesReservations: props.dataSources.salesReservations,
			});
		}
		return getSalesReservations(props);
	} catch (e) {
		errorHandler.report(e);
		return [];
	}
};

const getSalesReservationsFromData = (
	args: FetchSalesReservationsFromDataProps,
): PartialReservation[] => {
	const { skuId, startDate, endDate, ownReservationId, startLocationId, salesReservations } = args;

	return salesReservations
		.filter((r) => {
			return (
				r.skuIds.includes(skuId) &&
				r.startLocationId === startLocationId &&
				moment(r.created).isAfter(moment().subtract(20, 'minutes')) &&
				r.id !== ownReservationId
			);
		})
		.flatMap((reservation) =>
			salesReservationToPartialReservations(reservation, startDate, endDate),
		)
		.filter(isReservationOverlapping(skuId, startDate, endDate));
};

const getSalesReservations = async (
	props: FetchSalesReservationsProps,
): Promise<PartialReservation[]> => {
	const { skuId, startDate, endDate, ownReservationId, startLocationId, api } = props;

	try {
		const snapshot = await api.reservations.sales.get
			.whereArray('skuIds', 'array-contains', skuId)
			.where('startLocationId', '==', startLocationId)
			.orderBy('created')
			.startAt(moment().add(-20, 'minutes').toISOString())
			.get();

		return snapshot
			.filter((reservation) => reservation.id !== ownReservationId)
			.flatMap((reservation) =>
				salesReservationToPartialReservations(reservation, startDate, endDate),
			)
			.filter(isReservationOverlapping(skuId, startDate, endDate));
	} catch (e) {
		return Promise.reject(
			`Failed fetching sales reservations for sku ${skuId} due to error: ${JSON.stringify(
				e,
				null,
				2,
			)}`,
		);
	}
};

const salesReservationToPartialReservations = (
	reservation: SalesReservation,
	startDate: string,
	endDate: string,
): PartialReservation[] =>
	reservation.items.flatMap((item) => ({
		startDate,
		endDate,
		units: item.quantity,
		skuIds: item.skuIds,
	}));

const isReservationOverlapping = (skuId: string, startDate: string, endDate: string) => (
	partialReservation: PartialReservation,
) => {
	if (partialReservation.startDate && partialReservation.endDate) {
		const hasSkuId = partialReservation.skuIds.includes(skuId);
		if (!hasSkuId) return false;

		const reservationStartDate = partialReservation.startDate;
		const reservationEndDate = partialReservation.endDate;
		return doesDateRangeOverlap(startDate, endDate, reservationStartDate, reservationEndDate);
	}
	return false;
};
