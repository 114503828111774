import { LoadFeaturesOptions } from '@growthbook/growthbook';
import { GrowthBook } from '@growthbook/growthbook-react';

import { Plan } from 'common/modules/plans';
import { logEvent } from 'common/services/analytics';
import { CountryCode, Languages } from 'common/types';
import { isDevEnv } from 'common/utils/common';

import { AppFeatures } from './generated-types';

type GrowthBookAttributes = {
	userId?: string;
	country?: CountryCode;
	internalUser?: boolean;
	language?: Languages;
	merchantId?: string;
	plan?: Plan;
	signupTimestamp?: string;
	pseudoId?: string;
	isDesktop?: boolean;
	isMobile?: boolean;
};

export const growthbook = new GrowthBook<AppFeatures>({
	apiHost: 'https://cdn.growthbook.io',
	clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
	decryptionKey: isDevEnv() ? undefined : process.env.REACT_APP_GROWTNBOOK_DECRYPTION_KEY,
	enableDevMode: isDevEnv(),
	onFeatureUsage: (featureKey, result) => {
		logEvent('feature_viewed', {
			feature_id: featureKey,
			experiment_id: result.experiment?.key,
			variation_id: result.experimentResult?.variationId,
		});
	},
	trackingCallback: (experiment, result) => {
		logEvent('experiment_viewed', {
			experiment_id: experiment.key,
			variation_id: result.variationId,
		});
	},
});

export const loadFeatures = async (options?: LoadFeaturesOptions) =>
	await growthbook.loadFeatures(options);

export const setAttributes = (args?: GrowthBookAttributes) => {
	if (!args) return;
	const existingAttributes = growthbook.getAttributes();
	growthbook.setAttributes({
		...existingAttributes,
		...args,
	});
};
