import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { buildQuery, createBasicCollectionOperations, createBasicDocOperations } from '../utils';
import {
	BulkInventoryItem,
	InventoryItem,
	SingleInventoryItem,
} from 'common/modules/inventory/types';
import {
	fixInventoryItemAllocationBoolean,
	fixInventoryItemAllocationQuantity,
} from 'common/modules/inventory';

export function dataUpdaterFn(item: SingleInventoryItem): SingleInventoryItem;
export function dataUpdaterFn(item: BulkInventoryItem): BulkInventoryItem;
export function dataUpdaterFn(
	item: SingleInventoryItem | BulkInventoryItem,
): SingleInventoryItem | BulkInventoryItem {
	if (!!(item as SingleInventoryItem).status) {
		item.type = 'single';
	} else {
		item.type = 'bulk';
	}

	if (item.type === 'bulk') {
		item.allocations = fixInventoryItemAllocationQuantity(item.allocations, item.statuses.IN_USE);
	}

	if (item.type === 'single') {
		item.allocation = fixInventoryItemAllocationBoolean(item.allocation);
	}

	return item;
}

export const getInventoryItemQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.inventoryItems;
	const dataUpdater = dataUpdaterFn;
	const query = buildQuery<InventoryItem>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicCollectionOperations<InventoryItem>(collectionPath, dbRefs, dataUpdater),
		doc: (id: string) => ({
			...createBasicDocOperations<InventoryItem>(collectionPath, dbRefs, id, dataUpdater),
		}),
		singleItems: buildQuery<SingleInventoryItem>(collectionPath, dbRefs, dataUpdater).where(
			'type',
			'==',
			'single',
		),
		bulkItems: buildQuery<BulkInventoryItem>(collectionPath, dbRefs, dataUpdater).where(
			'type',
			'==',
			'bulk',
		),
	};
};
