import { intersection, isEmpty, isEqual, isPlainObject, uniq } from 'lodash';

import { notNullish } from 'common/utils/common';

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
interface DiffResult<T> {
	removedKeys: (keyof T)[];
	addedKeys: (keyof T)[];
	updatedKeys: (keyof T)[];
}

export const getObjectKeysDiff = <T extends object>(
	newObj: Partial<T>,
	oldObj: Partial<T>,
): DiffResult<T> => {
	const newObjKeys = Object.keys(newObj);
	const oldObjKeys = Object.keys(oldObj);
	const removedKeys = oldObjKeys.filter((k) => !newObj.hasOwnProperty(k)) as (keyof T)[];
	const addedKeys = newObjKeys.filter((k) => !oldObj.hasOwnProperty(k)) as (keyof T)[];
	const updatedKeys = newObjKeys.filter(
		(k) => oldObj.hasOwnProperty(k) && newObj.hasOwnProperty(k) && !isEqual(newObj[k], oldObj[k]),
	) as (keyof T)[];
	return {
		removedKeys,
		addedKeys,
		updatedKeys,
	};
};

/*
	The ignored keys are a subset of the updated keys 
	(i.e. the intersection of updated keys and ignored keys is equal to the updated keys)
*/
export const allUpdatedKeysAreFromIgnoredKeys = <T extends object>(
	updatedKeys: Array<keyof T>,
	ignoredKeys: Array<keyof T>,
): boolean => {
	return intersection(updatedKeys, ignoredKeys).length === updatedKeys.length;
};

/* 
	Utility to analyze if the updated object keys contains only the set of keys from the key variable
*/
export const includesOnlyUpdatedKeysFrom = <T extends object>(
	newObj: Partial<T>,
	oldObj: Partial<T>,
	keys: Array<keyof T>,
): boolean => {
	const { addedKeys, removedKeys, updatedKeys } = getObjectKeysDiff(newObj, oldObj);
	const updatedKeysReduced = uniq(updatedKeys);
	/*
		There are no new or removed keys,		
	*/
	return (
		addedKeys.length === 0 &&
		removedKeys.length === 0 &&
		allUpdatedKeysAreFromIgnoredKeys(updatedKeysReduced, keys)
	);
};

/** Utility function to create a K:V from a list of strings */
/* From https://basarat.gitbook.io/typescript/type-system/literal-types#string-based-enums */
export const stringEnum = <T extends string>(o: T[]): { [K in T]: K } => {
	return o.reduce((res, key) => {
		res[key] = key;
		return res;
	}, Object.create(null));
};

/**
 * This helper allows you to do an Object.keys through object keys, and receive the typed values of those.
 * The return type checks if object is non-empty, and in that case also returns a non-empty array type
 */

export const getTypedKeys = Object.keys as <T extends object>(
	obj: T,
) => {} extends T ? Array<keyof T> : [keyof T, ...(keyof T)[]];

export const getTypedValues = Object.values as <T extends object>(obj: T) => Array<T[keyof T]>;

export const getTypedEntries = Object.entries as <T extends object>(
	obj: T,
) => Array<[keyof T, T[keyof T]]>;

export const objectWithoutKey = <T>(object: T, key: keyof T) => {
	const { [key]: deletedKey, ...otherKeys } = object;
	return otherKeys;
};

/**
 * Checks if all the object values are empty, even if it would have keys.
 * Empty object value in this case means that the value is either undefined or empty array(object).
 *
 */
export const isDeepEmptyObject = <T extends object>(object: T): boolean => {
	const isEmptyObject = (obj: object): boolean => {
		if (isEmpty(obj)) return true;
		return isEmpty(
			Object.values(obj)
				.map((value) => {
					if (value === undefined) return true;
					if (value instanceof Object) return isEmptyObject(value);
					return false;
				})
				.filter((b) => b === false),
		);
	};
	return isEmptyObject(object);
};

/**
 * Sanitizes, or in other words recursively removes all undefined and null properties from an array or an object,
 * as well as any nested arrays or objects.
 *
 * @param obj The object to sanitize
 * @returns The sanitized object
 */
export const removeNullOrUndefinedProperties = (obj: any): any => {
	if (Array.isArray(obj)) {
		return obj
			.filter(notNullish)
			.map(removeNullOrUndefinedProperties)
			.filter((item) => {
				const isEmptyObject = isPlainObject(item) && isEmpty(item);
				return !isEmptyObject;
			});
	}
	if (isPlainObject(obj)) {
		return Object.entries(obj).reduce((result, [key, value]) => {
			return notNullish(value)
				? {
						...result,
						[key]: removeNullOrUndefinedProperties(value),
				  }
				: result;
		}, {});
	}

	return obj;
};
