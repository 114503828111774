import {
	BillingAddress,
	BillingCreditsBalance,
	BillingEmail,
	BillingInformation,
} from 'common/modules/billing';

import { CallableCreator } from '..';

export interface GetInvoiceUrlArgs {
	invoiceId: string;
	type: 'receipt' | 'invoice';
}

export interface CancelInvoiceArgs {
	invoiceId: string;
	/**
	 * Whether to un-assign events related to this invoice
	 *
	 * - If yes, the events will be freed up for use in future invoices
	 * - If no, the events will not be freed up for use in future invoices, and cannot be charged later
	 */
	unassignEvents: boolean;
}

export interface ChargeInvoiceArgs {
	invoiceId: string;
}

export interface RefundInvoiceArgs {
	invoiceId: string;
}

export interface UpdateVatNumberArgs {
	merchantId: string;
	vatNumber: string;
	exempt: boolean;
}

export interface MarkInvoiceAsPaidArgs {
	invoiceId: string;
}

export interface RegenerateInvoicePDFsArgs {
	invoiceId: string;
}

export interface MarkInvoiceAsRefundedArgs {
	invoiceId: string;
}

export interface UpdateBillingEmailArgs {
	merchantId: string;
	email: BillingEmail;
}

export interface UpdateBillingAddressArgs {
	merchantId: string;
	address: BillingAddress;
}

export interface UpdateBillingInformationArgs {
	merchantId: string;
	billingInformation: BillingInformation;
}

export type AddManualCreditsArgs = {
	merchantId: string;
	description: string;
	amount: number;
};

export type AddManualCreditsReturnArgs = {
	balanceAfter: BillingCreditsBalance;
	creditsAdded: { refund: number; manual: number; total: number };
};

export type RemoveManualCreditsArgs = {
	merchantId: string;
	description: string;
	amount: number;
};

export type RemoveManualCreditsResult = {
	balanceAfter: BillingCreditsBalance;
	creditsRemoved: number;
};

export const billing = (createCallable: CallableCreator) => ({
	invoices: {
		getUrl: createCallable<GetInvoiceUrlArgs, string | null>('billing-f-invoices-getUrl'),
		cancel: createCallable<CancelInvoiceArgs, void>('billing-f-invoices-cancel'),
		refund: createCallable<RefundInvoiceArgs, void>('billing-f-invoices-refund'),
		charge: createCallable<ChargeInvoiceArgs, void>('billing-f-invoices-charge'),
		markPaid: createCallable<MarkInvoiceAsPaidArgs, void>('billing-f-invoices-markPaid'),
		markRefunded: createCallable<MarkInvoiceAsRefundedArgs, void>(
			'billing-f-invoices-markRefunded',
		),
		regeneratePDFs: createCallable<RegenerateInvoicePDFsArgs, void>(
			'billing-f-invoices-regeneratePDFs',
		),
	},
	addManualCredits: createCallable<AddManualCreditsArgs, AddManualCreditsReturnArgs>(
		'billing-f-addManualCredits',
	),
	removeManualCredits: createCallable<RemoveManualCreditsArgs, RemoveManualCreditsResult>(
		'billing-f-removeManualCredits',
	),
	updateVatNumber: createCallable<UpdateVatNumberArgs, void>('billing-f-updateVatNumber'),
	updateBillingEmail: createCallable<UpdateBillingEmailArgs, void>('billing-f-updateBillingEmail'),
	updateBillingAddress: createCallable<UpdateBillingAddressArgs, void>(
		'billing-f-updateBillingAddress',
	),
	updateBillingInformation: createCallable<UpdateBillingInformationArgs, void>(
		'billing-f-updateBillingInformation',
	),
});
