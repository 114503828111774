import { TFunction } from 'react-i18next';

import { OnboardingListVersion } from 'common/services/analytics/tractionAnalytics';

import { OnboardingStepItem } from '../types';
import { getFlowOnboardingStepItem, getLinkOnboardingStepItem } from '../utils';

export const VISIT_ONLINE_STORE_ITEM = ({
	t,
	onlineStoreUrl,
	showProductTours,
}: {
	t: TFunction;
	onlineStoreUrl: string;
	showProductTours: boolean;
}): Record<
	'VISIT_ONLINE_STORE',
	{ all: OnboardingStepItem } | { [key in OnboardingListVersion]?: OnboardingStepItem }
> => ({
	VISIT_ONLINE_STORE: {
		all: {
			action: showProductTours
				? getFlowOnboardingStepItem({ onboardingAction: 'VISIT_ONLINE_STORE' })
				: getLinkOnboardingStepItem({ toUrl: onlineStoreUrl }),
			content: {
				title: t('homeView.onboarding.viewYourOnlineStore', 'View your online store'),
			},
			marksItemAsDone: [
				'VISIT_ONLINE_STORE',
				'VISIT_ONLINE_STORE_BUTTON',
				'VISIT_ONLINE_STORE_MODAL',
				'BOOKING_CREATION',
			],
			id: 'VISIT_ONLINE_STORE',
			type: 'row',
		},
	},
});
