import type { FieldValue as _FieldValue } from 'firebase/firestore';
import {
	CollectionReference,
	DocumentReference,
	Transaction,
	collection as _collection,
	collectionGroup as _collectionGroup,
	connectFirestoreEmulator as _connectFirestoreEmulator,
	doc as _doc,
	runTransaction as _runTransaction,
	writeBatch as _writeBatch,
	arrayRemove,
	arrayUnion,
	deleteField,
	enableIndexedDbPersistence,
	increment,
	initializeFirestore,
	serverTimestamp,
} from 'firebase/firestore';

import { ClientFieldValueMethods } from 'common/db/api/types';
import { ClientBatchManager } from 'common/db/helpers/ClientBatchManager';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { RemoveFirstFromTuple } from 'common/types/utils';
import {
	browserSupportsIndexedDB,
	isBotUserAgent,
	runningJestTests,
} from 'common/utils/browserUtils';

import { firebaseApp } from './app';

export type {
	Timestamp,
	WriteBatch,
	CollectionReference,
	DocumentReference,
	DocumentData,
	Query,
	Transaction,
	Firestore,
	DocumentSnapshot,
	QueryDocumentSnapshot,
	QuerySnapshot,
	WhereFilterOp,
	OrderByDirection,
	SetOptions,
	SnapshotMetadata,
} from 'firebase/firestore';
export type FieldValue = _FieldValue;
export {
	updateDoc,
	setDoc,
	getDoc,
	deleteDoc,
	getDocs,
	onSnapshot,
	limit,
	orderBy,
	limitToLast,
	startAt,
	startAfter,
	endBefore,
	endAt,
	where,
	query,
	arrayUnion,
	arrayRemove,
	increment,
	deleteField,
	serverTimestamp,
} from 'firebase/firestore';

const useEmulator = process.env.REACT_APP_USE_EMULATOR === 'true';
const env = process.env.REACT_APP_ENV as 'local' | 'development' | 'production';

const isDocRef = (ref: CollectionReference | DocumentReference): ref is DocumentReference => {
	return ref.type === 'document';
};

export const db = initializeFirestore(firebaseApp, {
	ignoreUndefinedProperties: true,
	...(!runningJestTests() &&
		isBotUserAgent(navigator.userAgent) && { experimentalForceLongPolling: true }),
});

const firestoreWrapper = <T extends (...args: any) => any>(
	fn: T,
): ((...args: RemoveFirstFromTuple<Parameters<T>>) => ReturnType<T>) => {
	return (...paramsWithoutFirestore: RemoveFirstFromTuple<Parameters<T>>) =>
		fn(db, ...paramsWithoutFirestore);
};

export const writeBatch = firestoreWrapper(_writeBatch);

export const BatchManager = ClientBatchManager;

export const runTransaction = <T>(updateFunction: (transaction: Transaction) => Promise<T>) =>
	_runTransaction<T>(db, updateFunction);

export const connectFirestoreEmulator = () => _connectFirestoreEmulator(db, 'localhost', 8100);

export const collectionGroup = firestoreWrapper(_collectionGroup);

// prettier-ignore
export function collection(...args: RemoveFirstFromTuple<Parameters<typeof _collection>>): ReturnType<typeof _collection>;
// prettier-ignore
export function collection<T extends object>(collectionReference: CollectionReference<T>, ...args: RemoveFirstFromTuple<Parameters<typeof _collection>>): ReturnType<typeof _collection>;
// prettier-ignore
export function collection<T extends object>(documentReference: DocumentReference<T>, ...args: RemoveFirstFromTuple<Parameters<typeof _collection>>): ReturnType<typeof _collection>;
// prettier-ignore
export function collection(docOrCollectionOrPath: CollectionReference | DocumentReference | string, ...args: RemoveFirstFromTuple<Parameters<typeof _collection>>): ReturnType<typeof _collection> {
    if (typeof docOrCollectionOrPath === 'string') {
        return _collection(db, docOrCollectionOrPath, ...args);
    }
    if (isDocRef(docOrCollectionOrPath)) {
        return _collection(docOrCollectionOrPath, ...args);
    } else {
        return _collection(docOrCollectionOrPath, ...args);
    }
}

// prettier-ignore
export function doc(...args: RemoveFirstFromTuple<Parameters<typeof _doc>>): ReturnType<typeof _doc>;
// prettier-ignore
export function doc<T extends object>(collectionReference: CollectionReference<T>, ...args: RemoveFirstFromTuple<Parameters<typeof _doc>>): ReturnType<typeof _doc>;
// prettier-ignore
export function doc<T extends object>(documentReference: DocumentReference<T>, ...args: RemoveFirstFromTuple<Parameters<typeof _doc>>): ReturnType<typeof _doc>;
// prettier-ignore
export function doc(docOrCollectionOrPath: CollectionReference | DocumentReference | string, ...args: RemoveFirstFromTuple<Parameters<typeof _doc>>): ReturnType<typeof _doc> {
    if (typeof docOrCollectionOrPath === 'string') {
        return _doc(db, docOrCollectionOrPath, ...args);
    }
    if (isDocRef(docOrCollectionOrPath)) {
        return _doc(docOrCollectionOrPath, ...args);
    } else {
        return _doc(docOrCollectionOrPath, ...args);
    }
}

// Offline persistence supprted only in DEV
const isPersistenceSupported = () => {
	return browserSupportsIndexedDB() && env !== 'production' && !runningJestTests() && !useEmulator;
};

export const setDbPersistence = async () => {
	if (isPersistenceSupported()) {
		try {
			await enableIndexedDbPersistence(db);
		} catch (e) {
			// Cannot initialize offline persistence
			errorHandler.report(e);
		}
	}
};

export const FieldValues: ClientFieldValueMethods = {
	arrayUnion: (...args: Parameters<typeof arrayUnion>) =>
		!args.length ? undefined : arrayUnion(...args),
	arrayRemove: (...args: Parameters<typeof arrayRemove>) =>
		!args.length ? undefined : arrayRemove(...args),
	increment: (...args: Parameters<typeof increment>) => increment(...args),
	delete: (...args: Parameters<typeof deleteField>) => deleteField(...args),
	serverTimestamp: (...args: Parameters<typeof serverTimestamp>) => serverTimestamp(...args),
};
