import { TFunction } from 'i18next';

import { notFalsey, switchUnreachable } from 'common/utils/common';

import { getCountryNameFromCountryCode, isCountryCode } from '../countries';
import { Address, AddressField } from './types';

export const validateAddressField =
	(field: AddressField, t: TFunction) =>
	(value: string | undefined): string | null => {
		switch (field) {
			case 'address': {
				if (!value) return t('common:mandatory');

				return null;
			}
			case 'zipCode': {
				if (!value) return t('common:mandatory');

				return null;
			}
			case 'city': {
				if (!value) return t('common:mandatory');

				return null;
			}
			case 'state': {
				return null;
			}
			case 'country': {
				if (!value) return t('common:mandatory');
				if (!isCountryCode(value))
					return t('common:validation.isNotValidCountry', 'Not a valid country');
				return null;
			}
			case 'details': {
				if (!!value && value.length > 500)
					return t('common:validation.lengthAtMost', {
						field: t('common:address.additionalInformation', 'Additional information'),
						int: 500,
						defaultValue: '{{field}} can be at most {{int}} characters',
					});
				return null;
			}
			default: {
				return switchUnreachable(field);
			}
		}
	};

export const validateAddress = (
	address: Partial<Address>,
	t: TFunction,
): Record<AddressField, string | null> | null => {
	const errors = {
		address: validateAddressField('address', t)(address.address),
		zipCode: validateAddressField('zipCode', t)(address.zipCode),
		city: validateAddressField('city', t)(address.city),
		state: validateAddressField('state', t)(address.state),
		country: validateAddressField('country', t)(address.country),
		details: validateAddressField('details', t)(address.details),
	};

	return Object.values(errors).some((e) => !!e) ? errors : null;
};

export const isAddress = (value: Partial<Address>): value is Address => {
	return !!value.address;
};

export const getFormattedAddress = (address?: Address | null): string => {
	if (!address) return '';
	return getFormattedAddressParts(address).filter(notFalsey).join(', ');
};

export const getFormattedAddressParts = (addressObject: Address): string[] => {
	const { address, zipCode, city, country, state } = addressObject;

	const cityStateZipCodePart = (() => {
		if (!!city && !!state && !!zipCode) return `${city}, ${state} ${zipCode}`;
		if (!!city && !!state) return `${city}, ${state}`;

		return [city, state, zipCode].filter(notFalsey).join(' ');
	})();

	const countryPart = (() => {
		if (!country) return '';
		if (isCountryCode(country)) return getCountryNameFromCountryCode(country);
		return country;
	})();

	return [address, cityStateZipCodePart, countryPart];
};
