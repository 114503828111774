import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.packageProducts.name', 'Package Products'),
	subHeader: t('common:blocks.details.packageProducts.subHeader', 'Bundle products into packages'),
	description: t(
		'common:blocks.details.packageProducts.description',
		'Product packages allow you to create different product and service combinations from the items you have in your inventory. Bundling up your products makes your product catalog more streamlined, thus helping your customers to browse and book your products more efficiently',
	),
	supportArticle: SupportArticles.PACKAGE_PRODUCTS,
	uninstallNotice: t(
		'common:blocks.details.packageProducts.uninstallNotice',
		'If you uninstall this add-on, all of your existing product packages will be deleted',
	),
});

export default details;
