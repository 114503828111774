import * as dbPath from 'common/api/db/paths';
import { buildQuery, createBasicDbOperations } from '../utils';
import { Shopper } from 'common/types';
import { DbRefs } from '../types';

export const getOrderShopperQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.orderShoppers;
	const dataUpdater = undefined;
	const query = buildQuery<Shopper>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<Shopper>(collectionPath, dbRefs, dataUpdater),
		byOrderId: (orderId: string) => query.where('rentalId', '==', orderId),
	};
};
