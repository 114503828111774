import { GetQueryFields, QueryFieldsHelpers, WithQueryFields } from '../types';

const createWithQueryFields = <T extends object, Q extends object>(
	getter: GetQueryFields<T, Q>,
): WithQueryFields<T, Q> => {
	return (doc) => {
		const __query = getter(doc);
		return {
			...doc,
			__query,
		};
	};
};

export const createQueryFieldsHelpers = <T extends object, Q extends object>(
	getter: GetQueryFields<T, Q>,
): QueryFieldsHelpers<T, Q> => {
	return {
		getQueryFields: getter,
		withQueryFields: createWithQueryFields(getter),
	};
};
