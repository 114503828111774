import countriesAndTimezones from 'countries-and-timezones';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';

import { isOverlappingDayType } from 'common/modules/atoms/dates';
import {
	OpeningHours,
	getOpeningTimesForDate,
	isTimeWithinOpeningTimes,
} from 'common/modules/openingHours';
import {
	FixedTimesObject,
	Languages,
	LocaleField,
	Location,
	LocationUrls,
	LocationVisibleChannel,
	LocationWithContact,
	ShopPublicInfo,
	ShopReceiptInfo,
} from 'common/types';

import { notUndefined } from './common';

export const getCustomMarketingConsent = (
	lang: Languages,
	customMarketingConsent: { text: LocaleField; active: boolean } | null,
): string | null => {
	if (customMarketingConsent && customMarketingConsent.active && customMarketingConsent.text) {
		return customMarketingConsent.text[lang]
			? customMarketingConsent.text[lang]
			: customMarketingConsent.text.def
			? customMarketingConsent.text.def
			: null;
	} else {
		return null;
	}
};

export const getCustomTermsAcceptance = (
	lang: Languages,
	customTermsAcceptance: { text: LocaleField; active: boolean } | null,
): string | null => {
	if (customTermsAcceptance && customTermsAcceptance.active) {
		if (customTermsAcceptance.text && customTermsAcceptance.text[lang]) {
			return customTermsAcceptance.text[lang] || customTermsAcceptance.text.def;
		} else {
			return null;
		}
	} else {
		return null;
	}
};

export const getShopLocations = (
	shopPublicInfo?: ShopPublicInfo,
	channel?: LocationVisibleChannel,
): Location[] =>
	sortBy(
		[shopPublicInfo?.visitingAddress, ...(shopPublicInfo?.shopLocations ?? [])]
			.filter(notUndefined)
			.filter((location) => (channel && location.channels?.includes(channel)) ?? true),
		(location) => {
			return location.orderIndex ?? (location.id === shopPublicInfo?.visitingAddress?.id ? -1 : 0);
		},
	);

export const getLocationName = (
	shopPublicInfo?: ShopPublicInfo,
	locationId?: string,
): string | null => {
	return (
		getShopLocations(shopPublicInfo).find((location) => location.id === locationId)?.name ?? null
	);
};

export const getShopLocationsWithContact = (
	shopPublicInfo: ShopPublicInfo,
	channel?: LocationVisibleChannel,
): LocationWithContact[] =>
	getShopLocations(shopPublicInfo, channel).map((l) => locationWithContact(l, shopPublicInfo));

export const getShopLocationById = (
	locationId: string,
	shopPublicInfo: ShopPublicInfo,
	channel?: LocationVisibleChannel,
): LocationWithContact | undefined => {
	const shopLocations = getShopLocationsWithContact(shopPublicInfo, channel);
	const shopLocation = shopLocations.find((location) => location.id === locationId);
	return shopLocation;
};

export const locationWithContact = (
	location: Location,
	shopPublicInfo: ShopPublicInfo,
): LocationWithContact => {
	const locationWithContact: LocationWithContact = {
		...location,
		email: location.email ?? shopPublicInfo.email,
		phone: location.phone ?? shopPublicInfo.phone,
	};
	return locationWithContact;
};

export const getCountryTimeZone = (country: string | undefined): string => {
	if (country) {
		const timezonesForCountry = countriesAndTimezones.getTimezonesForCountry(country);
		const guess = moment.tz.guess();
		const countryIncludesGuess =
			timezonesForCountry && timezonesForCountry.filter((timezone) => timezone.name === guess);
		if (countryIncludesGuess && countryIncludesGuess.length) {
			return countryIncludesGuess[0].name;
		}
		if (timezonesForCountry && timezonesForCountry.length) {
			const primaryTimeZone = timezonesForCountry.find((t) => t.aliasOf == null);
			return primaryTimeZone?.name || timezonesForCountry[0].name;
		} else {
			return 'Europe/Helsinki';
		}
	} else {
		return 'Europe/Helsinki';
	}
};

export const getShopReceiptInfo = (
	shopPublicInfo: ShopPublicInfo,
	locationId: string | null,
): ShopReceiptInfo => {
	const { name, phone, businessId, receiptOpenTextfield } = shopPublicInfo;
	const location = locationId ? getShopLocationById(locationId, shopPublicInfo) : undefined;
	return {
		name: location?.name ?? name,
		legalName: name,
		visitingAddress: location ?? null,
		phone: location?.phone ?? phone ?? '',
		businessId: businessId || '',
		receiptOpenTextfield,
	};
};

export const getRentlePaymentReceiptInfo = (): ShopReceiptInfo => {
	return {
		name: 'Rentle',
		legalName: 'Rentle Oy',
		visitingAddress: null,
		phone: '010 299 8200',
		businessId: '2634632-5',
	};
};

export const getLocationIdByUrl = (
	locationName: string,
	locationUrls: LocationUrls | undefined,
) => {
	return locationUrls?.[locationName] ?? undefined;
};

export const getLocationUrlById = (
	locationId: string,
	locationUrls: LocationUrls | undefined,
): string | undefined => {
	const locationEntries = Object.entries(locationUrls ?? {}).find(([_, id]) => id === locationId);
	return locationEntries?.[0] ?? undefined;
};

export const customFixedDatesOutsideOpeningHours = (
	openingHours: OpeningHours,
	fixedTime: FixedTimesObject,
) => {
	const openingTimes = getOpeningTimesForDate({
		openingHours,
		date: moment(fixedTime.customDate).format('YYYY-MM-DD'),
	});
	if (
		openingTimes &&
		(openingTimes.isClosed ||
			openingTimes.openTime > fixedTime.time ||
			openingTimes.closeTime < fixedTime.time)
	) {
		return true;
	}
	return false;
};

export const isFixedTimeOutsideOpeningHours = (
	openingHours: OpeningHours,
	fixedTime: FixedTimesObject,
): boolean => {
	if (fixedTime.dayType === 'custom') {
		return customFixedDatesOutsideOpeningHours(openingHours, fixedTime);
	}

	if (!!fixedTime.customDate) {
		const openingTimes = getOpeningTimesForDate({
			openingHours,
			date: moment(fixedTime.customDate).format('YYYY-MM-DD'),
		});
		return !isTimeWithinOpeningTimes({
			openingTimes,
			time: fixedTime.time,
			inclusive: {
				start: true,
				end: false,
			},
		});
	} else {
		const matchingRows = openingHours.base.rows.filter((row) => {
			if (row.dayType === 'custom' || fixedTime.dayType === 'custom') return false;
			return isOverlappingDayType(row.dayType, fixedTime.dayType);
		});

		return matchingRows.some((row) => {
			return !isTimeWithinOpeningTimes({
				openingTimes: row.times,
				time: fixedTime.time,
				inclusive: {
					start: true,
					end: false,
				},
			});
		});
	}
};

export const getFixedTimesOutsideOpeningHours = (
	fixedTimes: FixedTimesObject[] | undefined,
	openingHours: OpeningHours,
) => {
	if (!fixedTimes) {
		return undefined;
	}
	const timesOutsideOpeningHours = fixedTimes.filter((fixedTime) =>
		isFixedTimeOutsideOpeningHours(openingHours, fixedTime),
	);
	return timesOutsideOpeningHours;
};
