import * as dbPath from 'common/api/db/paths';
import buildPath from 'common/api/db/paths/buildPath';
import { DbRefs } from 'common/db/api/types';
import { ACSPurchaseOrderDb } from 'common/modules/acs';

import {
	createBasicCollectionOperations,
	createBasicDbOperations,
	createBasicDocOperations,
} from '../utils';

export const getAcsPurchaseOrderQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.acsPurchaseOrders;
	return {
		doc: (id: string) => ({
			...createBasicDocOperations<ACSPurchaseOrderDb>(collectionPath, dbRefs, id),
			versions: acsPurchaseOrderVersionsQueries(dbRefs, id),
		}),
		...createBasicCollectionOperations<ACSPurchaseOrderDb>(collectionPath, dbRefs),
	};
};

const acsPurchaseOrderVersionsQueries = (dbRefs: DbRefs, acsOrderId: string) => {
	const collectionPath = `${buildPath(dbPath.acsPurchaseOrdersDocBase, acsOrderId)}/versions`;
	return createBasicDbOperations<ACSPurchaseOrderDb>(collectionPath, dbRefs);
};
