import { useCallback, useState } from 'react';

const useMenuState = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const close = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const open = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	}, []);

	return {
		menuProps: {
			open: Boolean(anchorEl),
			onClose: close,
			anchorEl,
		},
		menuActions: {
			open,
			close,
		},
	};
};

export default useMenuState;
