import React from 'react';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { CapabilityOption as CapabilityOptionType } from 'views/Register/utils';

export interface Props {
	option: CapabilityOptionType;
	isSelected: boolean;
	onChange: (isSelected: boolean) => void;
}
const CapabilityOption = (props: Props) => {
	const { option, isSelected, onChange } = props;
	return (
		<Box
			sx={{
				outline: (theme) =>
					isSelected
						? `2px solid ${theme.palette.primary.main}`
						: `1px solid ${theme.palette.border.inputOutline}`,
				'&:hover': {
					outline: (theme) => (!isSelected ? `1px solid ${theme.palette.primary.dark}` : undefined),
					boxShadow: (theme) => theme.shadows[6],
				},
				borderRadius: (theme) => theme.spacing(0.5),
				padding: 2,
				height: '100%',
			}}
		>
			<FormControlLabel
				key={option.title}
				sx={{ alignItems: 'flex-start', mr: 0, display: 'flex', height: '100%' }}
				control={
					<Checkbox
						color="primary"
						checked={isSelected}
						onChange={(e) => onChange(e.target.checked)}
						value={option.title}
						sx={{
							pt: 0,
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					/>
				}
				label={
					<>
						<Typography
							variant="h6"
							sx={{
								fontSize: '1.8rem',
								lineHeight: 1.4,
							}}
						>
							{option.title}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								fontSize: '1.4rem',
								lineHeight: 1.4,
								fontWeight: 400,
							}}
						>
							{option.description}
						</Typography>
					</>
				}
			/>
		</Box>
	);
};

export default CapabilityOption;
