import { withOpenApi } from 'common/utils/openApi';

import { OrderDelivery } from './OrderDelivery';
import { OrderInfo } from './OrderInfo';
import { OrderItem } from './OrderItem';
import { Person } from './Person';

export const zOrderApi = withOpenApi(
	OrderInfo.api.extend({
		persons: Person.api.array(),
		items: OrderItem.api.array(),
		delivery: OrderDelivery.api.optional(),
	}),
	{
		title: 'Order',
		fields: {
			persons: 'List of the persons in the order.',
			items: 'List of order items.',
			delivery: 'Order delivery information.',
		},
		example: {
			...OrderInfo.example!,
			items: [OrderItem.example!],
			persons: [Person.example!],
			delivery: OrderDelivery.example,
		},
	},
);
