import * as React from 'react';

import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

interface Props {
	className?: any;
	style?: any;
	children?: any;
	id?: string;
	relative?: boolean;
	padding?: 'none' | 'small' | 'normal';
	sx?: SxProps<Theme>;
}

const ContainerBox = (props: Props) => {
	const { id, className, style, children } = props;
	const { classes, cx } = useStyles(props);
	return (
		<Box sx={props.sx} id={id} style={style} className={cx(classes.root, className)}>
			{children}
		</Box>
	);
};

const useStyles = makeStyles<Props>()((theme: Theme, props) => ({
	root: {
		padding:
			props.padding === 'none'
				? 0
				: props.padding === 'small'
				? theme.spacing(2)
				: theme.spacing(3),
		width: '100%',
		borderRadius: '4px',
		border: '1px solid #e5e5e5',
		backgroundColor: theme.palette.common.white,
		margin: '16px 0',
		[theme.breakpoints.down('md')]: {
			padding: props.padding === 'none' ? theme.spacing(0) : theme.spacing(2),
		},
		position: props.relative ? 'relative' : 'initial',
	},
}));

export default ContainerBox;
