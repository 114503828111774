import React, { useState } from 'react';

import { Collapse, List, useTheme } from '@mui/material';

import {
	OnboardingItem,
	OnboardingStepItemTypes,
	OnboardingStep as OnboardingStepType,
} from 'services/onboarding';

import OnboardingItemRow from './OnboardingItemRow';
import OnboardingItemRowVideo from './OnboardingItemRowVideo';
import OnboardingStepHeader from './OnboardingStepHeader';

export interface Props {
	step: OnboardingStepType;
	isListOpen: boolean;
}
const OnboardingStepList = (props: Props) => {
	const { step, isListOpen } = props;
	const { items } = step;
	const [showList, setShowList] = useState(isListOpen);
	const theme = useTheme();

	const renderItem = (item: OnboardingItem, index: number) => {
		switch (item.action.type) {
			case OnboardingStepItemTypes.VIDEO:
				return <OnboardingItemRowVideo item={item} key={item.content.title + index} />;
			default:
				return <OnboardingItemRow item={item} key={item.content.title + index} />;
		}
	};

	return (
		<List
			disablePadding
			sx={{
				backgroundColor: theme.palette.background.paper,
				boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.08)',
				marginBottom: 1,
				borderRadius: '6px',
			}}
		>
			<OnboardingStepHeader step={step} onClick={() => setShowList(!showList)} isOpen={showList} />
			<Collapse in={showList}>{items?.map((item, index) => renderItem(item, index))}</Collapse>
		</List>
	);
};

export default OnboardingStepList;
