import { stringEnum } from 'common/utils/objects';

export const WebhookEvents_LEGACY = stringEnum([
	'ORDER_CREATED',
	'ORDER_UPDATED',
	'ORDER_PREPARED',
	'ORDER_STARTED',
	'ORDER_RETURNED',
	'PRODUCT_CREATED',
	'PRODUCT_UPDATED',
	'PRODUCT_DELETED',
]);

export const OrderWebhookEventsDb = stringEnum([
	'order/created',
	'order/updated',
	'order/prepared',
	'order/started',
	'order/returned',
]);

export const ProductWebhookEventsDb = stringEnum([
	'product/created',
	'product/updated',
	'product/deleted',
]);

export const AllWebhookEventsDb = {
	...WebhookEvents_LEGACY,
	...OrderWebhookEventsDb,
	...ProductWebhookEventsDb,
};

export const WebhookEventsDb = {
	...WebhookEvents_LEGACY,
	...OrderWebhookEventsDb,
	...ProductWebhookEventsDb,
};
