import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { updateToNewTransactionType } from 'common/api/dataUpdates';
import { buildQuery, createBasicDbOperations } from '../utils';
import { TransactionApi } from 'common/types';

export const getPlanAuthTransactionQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.planAuthTransactions;
	const dataUpdater = updateToNewTransactionType;
	const query = buildQuery<TransactionApi>(collectionPath, dbRefs, dataUpdater);
	return {
		...createBasicDbOperations<TransactionApi>(collectionPath, dbRefs, dataUpdater),
	};
};
