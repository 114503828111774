import i18n from 'services/localization/i18n';

import { AddOnValueProp } from '../types';

const valueProps: AddOnValueProp[] = [
	{
		description: i18n.t(
			'account.addOns.valueProps.discountCodes.fixedOrPercentage',
			'Fixed or percentage based discounts',
		),
	},
	{
		description: i18n.t('account.addOns.valueProps.discountCodes.usageLimites', 'Set usage limits'),
	},
	{
		description: i18n.t(
			'account.addOns.valueProps.discountCodes.defineApplicableProducts',
			'Define the applicable products',
		),
	},
];

export default valueProps;
