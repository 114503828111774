import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import gradientPattern from 'common/assets/signup-wizard-gradient.png';
import RentleLogo from 'common/components/RentleLogo';

interface Props {
	title?: string;
	description?: string;
}

const AuthContainer: React.FC<React.PropsWithChildren<Props>> = ({
	title,
	children,
	description,
}) => {
	return (
		<Grid item xs={12} lg={6} sx={{ px: 3 }}>
			<Box
				sx={{
					position: 'fixed',
					top: -10,
					left: 0,
					right: 0,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					zIndex: 0,
				}}
			>
				<Box
					component="img"
					sx={{
						width: 1080,
						height: 173,
						maxWidth: '100%',
						objectFit: 'cover',
					}}
					src={gradientPattern}
					alt=""
				/>
			</Box>
			<Box
				sx={{
					pb: 11,
					maxWidth: 355,
					margin: '0 auto',
					width: '100%',
					pt: {
						xs: 5.25,
						md: 8,
					},
				}}
			>
				<RentleLogo width={120} height={38} type="color" />
				<Box mt={{ xs: 5.25, sm: 6 }} mb={5}>
					{title && (
						<Typography variant="h5" sx={{ fontSize: '28px' }}>
							{title}
						</Typography>
					)}
					{description && (
						<Typography mt="2px" variant="body2" color="secondary">
							{description}
						</Typography>
					)}
				</Box>
				{children}
			</Box>
		</Grid>
	);
};

export default AuthContainer;
