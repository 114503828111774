import i18n from './i18n';
import { getInitialAdminLanguage, setLanguage } from './utils';

export { updateI18nLanguage, t } from './i18n';
export { Trans } from './Trans';
export { useTranslation } from './useTranslation';
export * from './utils';

setLanguage(getInitialAdminLanguage());

export default i18n;
