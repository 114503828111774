import { Api } from 'common/db/api/paths';
import {
	ensureAllocationsExistForSkuItem,
	getAvailableQuantityFromAllocations,
} from 'common/modules/inventory';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { PurchaseType } from 'common/types';

import { AvailabilityDataSources } from './types';

export interface GetTotalQuantityProps {
	skuId: string;
	startLocationId: string;
	dataSources?: AvailabilityDataSources;
	api: Api;
	purchaseType: PurchaseType;
}

export const getTotalQuantityForSku = async (props: GetTotalQuantityProps): Promise<number> => {
	const { skuId, startLocationId, dataSources, api, purchaseType } = props;
	try {
		const skuItem = !!dataSources
			? ensureAllocationsExistForSkuItem(dataSources.skuItems.find((item) => item.id === skuId))
			: await api.skuItems.doc(skuId).get();

		return getAvailableQuantityFromAllocations(skuItem, startLocationId, purchaseType);
	} catch (e) {
		errorHandler.report(e);
		return 0;
	}
};
