import { createSelector } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import {
	getPackagesThatProductBelongsTo,
	isPackageProduct,
	isProductEditableByUser,
	isProductVisibleInMultipleLocations,
} from 'common/modules/products/utils';
import { isLiftTicketPackageProduct, isLiftTicketProduct } from 'common/modules/skidata';
import { ProductApi } from 'common/types';
import { getDefaultStartTimeSlots } from 'common/utils/productUtils';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as StockSelectors from 'selectors/StockSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import { ReduxState } from 'services/types';

export const product = (state: ReduxState) => state.activeProduct.product;
export const productData = (state: ReduxState) => product(state).data;
export const productLoading = (state: ReduxState) => product(state).loading;
export const productError = (state: ReduxState) => product(state).error;

export const localChanges = (state: ReduxState) => state.activeProduct.localChanges;
export const localProductChanges = (state: ReduxState) => localChanges(state).product;

export const isSaving = (state: ReduxState) => state.activeProduct.saving;
export const error = (state: ReduxState) => state.activeProduct.error;
export const savedAt = (state: ReduxState) => state.activeProduct.savedAt;

export const isSharedProduct = createSelector(
	productData,
	ShopSelectors.allShopLocationsCount,
	(product, locationCount) => {
		return !!product && isProductVisibleInMultipleLocations(product, locationCount);
	},
);

export const isEditingDisabled = createSelector(
	productData,
	UserSelectors.userActiveShopAccess,
	(product, user) => {
		return !!product && !!user && !isProductEditableByUser(product, user);
	},
);

export const startTimeSlots = createSelector(
	productData,
	(productData) => productData?.startTimeSlots ?? getDefaultStartTimeSlots(),
);

export const productDataWithChanges = createSelector(
	productData,
	localProductChanges,
	(data, changes) => {
		return {
			...data,
			...changes,
		} as ProductApi;
	},
);

export const isSetProduct = createSelector(
	productDataWithChanges,
	(productData) => !!productData && isPackageProduct(productData),
);

export const isSkidataProduct = createSelector(
	productData,
	(productData) => !!productData && isLiftTicketProduct(productData),
);

export const isSkidataPackageProduct = createSelector(
	productData,
	(productData) => !!productData && isLiftTicketPackageProduct(productData),
);

export const belongsToSets = createSelector(
	productData,
	StockSelectors.stockProductsData,
	(product, stockProducts) => {
		if (!product || !stockProducts) return [];
		return getPackagesThatProductBelongsTo(product.id, stockProducts);
	},
);

export const hasProductChanges = createSelector(
	productData,
	localProductChanges,
	(product, localChanges) => {
		if (!product) return false;
		return Object.keys(localChanges).some((field) => {
			return !isEqual(localChanges[field], product[field]);
		});
	},
);
