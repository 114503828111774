import { useEffect, useMemo } from 'react';

import {
	useFeatureIsOn as _useFeatureIsOn,
	useFeatureValue as _useFeatureValue,
} from '@growthbook/growthbook-react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import errorHandler from 'common/services/errorHandling/errorHandler';
import { newId } from 'common/utils/common';
import * as ViewActions from 'actions/ViewActions';
import * as LanguageSelectors from 'selectors/LanguageSelectors';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { AppFeatures } from 'services/growthbook';
import * as GrowthbookServices from 'services/growthbook/utils';

const useGrowthbook = () => {
	const dispatch = useDispatch();
	const growthbookInitialized: boolean = useSelector(ViewSelectors.isGrowthbookInitialized);
	const lang = useSelector(LanguageSelectors.language);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const userPseudoId = useMemo(() => localStorage.getItem('growthbook_pseudo_id') ?? newId(20), []);

	useEffect(() => {
		GrowthbookServices.setAttributes({
			pseudoId: userPseudoId,
			language: lang,
			isDesktop,
			isMobile,
		});
		localStorage.setItem('growthbook_pseudo_id', userPseudoId);
	}, [isDesktop, isMobile, lang, userPseudoId]);

	useEffect(() => {
		const loadFeatures = async () => {
			try {
				await GrowthbookServices.loadFeatures({ autoRefresh: true });
			} catch (e) {
				errorHandler.report(e);
			}
			dispatch(ViewActions.setGrowthbookInitialized(true));
		};
		if (growthbookInitialized) return;
		loadFeatures();
	}, [dispatch, growthbookInitialized]);
};

export const useFeatureEnabled = (id: keyof AppFeatures & string): boolean =>
	_useFeatureIsOn<AppFeatures>(id);

export const useFeatureValue = <T extends keyof AppFeatures>(id: T, fallback: AppFeatures[T]) =>
	_useFeatureValue<AppFeatures[T]>(id, fallback);

export default useGrowthbook;
