import { auth } from 'common/frontend/firebase/auth';
import { UserApi } from 'common/types';

export const refreshAuthUserIfNecessary = async (before: UserApi, after: UserApi) => {
	const beforeTimestamp = before.accessUpdated;
	const afterTimestamp = after.accessUpdated;
	if (!beforeTimestamp || (!!afterTimestamp && !afterTimestamp.isEqual(beforeTimestamp))) {
		await auth?.currentUser?.getIdToken(true);
	}
};
