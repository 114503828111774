import React, { useMemo } from 'react';

import { Button, Container } from '@mui/material';
import { useRegisterContext } from 'views/Register/RegisterContext';
import { GET_STARTED_STEP_NAMES, getCapabilityQuestions } from 'views/Register/utils';

import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';

import RegistrationFooter from '../RegistrationFooter';
import CapabilityQuestionRow from './CapabilityQuestionRow';

export interface Props {
	onBack: () => void;
	onContinue: () => void;
}
const RegistrationCapabilities = (props: Props) => {
	const [{ capabilities, customerPersona }, setStore] = useRegisterContext((state) => state);
	const { onBack, onContinue } = props;
	const { t } = useTranslation();
	const { logRegisterActionEvent } = useAnalytics();

	const handleContinue = () => {
		logRegisterActionEvent('continue', {
			step: GET_STARTED_STEP_NAMES.CAPABILITIES,
		});
		onContinue();
	};

	const handleSkip = () => {
		logRegisterActionEvent('skip', {
			step: GET_STARTED_STEP_NAMES.CAPABILITIES,
		});
		onContinue();
	};

	const capabilityQuestions = useMemo(() => getCapabilityQuestions(t, customerPersona.value), [
		t,
		customerPersona.value,
	]);

	return (
		<>
			<Container sx={{ pb: 12, zIndex: 1 }}>
				{capabilityQuestions.map((capabilityQuestion, index) => (
					<CapabilityQuestionRow
						index={index}
						value={capabilityQuestion}
						key={`${capabilityQuestion.title}-${index}`}
						selectedOptions={capabilities[capabilityQuestion.id] ?? []}
						onChange={(updatedOptions) =>
							setStore((store) => {
								store.capabilities[capabilityQuestion.id] = updatedOptions;
								store.dirty = true;
							})
						}
					/>
				))}
			</Container>
			<RegistrationFooter
				mainCTA={{
					alignment: 'right',
					component: (
						<Button
							sx={{
								p: (theme) => theme.spacing(1, 4),
							}}
							variant="black"
							onClick={handleContinue}
						>
							{t('common:actions.continue', 'Continue')}
						</Button>
					),
				}}
				options={{
					back: {
						onClick: onBack,
					},
					skip: {
						onClick: handleSkip,
					},
				}}
			/>
		</>
	);
};

export default RegistrationCapabilities;
