import React from 'react';

import { Button, Chip, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { useTranslation } from 'services/localization';
import { Routes } from 'routing';

export interface Props {
	listingsCount: number;
}
const ListingsTableToolbar = (props: Props) => {
	const { listingsCount } = props;
	const { t } = useTranslation();
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} p={2}>
			<Stack direction="row" alignItems="center" spacing={1}>
				<Typography variant="h5">{t('listings.Listings', 'Listings')}</Typography>
				<Chip sx={{ color: '#6941C6', fontWeight: 500 }} label={listingsCount} />
			</Stack>
			<Link to={Routes.LISTINGS_CREATE}>
				<Button variant="contained">{t('listings.createListing', 'Create listing')}</Button>
			</Link>
		</Stack>
	);
};

export default ListingsTableToolbar;
