import { stringEnum } from 'common/utils/objects';

export const InventoryTransferStatuses = stringEnum([
	'PENDING',
	'RECEIVED',
	'REJECTED',
	'ACCEPTED',
	'COMPLETED',
	'PROCESSED'
]);

export const InventoryTransferPaymentMethods = stringEnum(['MANUAL']);
