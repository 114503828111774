import React from 'react';

import { Box, Container } from '@mui/material';
import { motion } from 'framer-motion';

import gradientPattern from 'common/assets/signup-wizard-gradient.png';
import RentleLogo from 'common/components/RentleLogo';

const RegistrationLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<Container>
			<Box
				sx={{
					position: 'fixed',
					top: -10,
					left: 0,
					right: 0,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					zIndex: 0,
				}}
			>
				<Box
					component="img"
					sx={{
						width: 1080,
						height: 173,
						maxWidth: '100%',
						objectFit: 'cover',
					}}
					src={gradientPattern}
					alt=""
				/>
			</Box>
			<Box
				sx={{
					width: 560,
					maxWidth: '100%',
					mx: 'auto',
					position: 'relative',
					zIndex: 1,
				}}
			>
				<motion.div
					initial={{ opacity: 0, transitionDuration: '0.3s' }}
					animate={{ opacity: 1, transitionDuration: '0.3s' }}
				>
					<Box mb={5} mt={4} width="100%" display="flex" flexDirection="column" alignItems="center">
						<RentleLogo width={120} grayscale={true} type="black" />
					</Box>
				</motion.div>
				<motion.div
					initial={{
						opacity: 0,
						transform: 'translateY(40px)',
						transitionDuration: '0.6s',
						transitionDelay: '0.3s',
					}}
					animate={{
						opacity: 1,
						transform: 'translateY(0)',
						transitionDuration: '0.6s',
						transitionDelay: '0.3s',
					}}
				>
					{children}
				</motion.div>
			</Box>
		</Container>
	);
};

export default RegistrationLayout;
