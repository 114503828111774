import { createAction } from '@reduxjs/toolkit';

import { Api } from 'common/core-api';
import { CustomerApi } from 'common/models/Customer/types';
import { sleep } from 'common/utils/async';
import * as NotificationActions from 'actions/NotificationActions';
import { t } from 'services/localization/i18n';
import { CustomersListFilters, TimeRange } from 'services/types';
import { evaluateTimeRange } from 'services/utils/dateCalculations';
import { createAppThunk } from 'services/utils/redux';

export const addCustomersPage = createAction<CustomerApi[]>('Customers/ADD_CUSTOMERS_PAGE');
export const setCustomersPageSize = createAction<number>('Customers/SET_PAGE_SIZE');
export const setCustomersTimeRange = createAction<TimeRange>('Customers/SET_TIME_RANGE');
export const resetCustomersPageCache = createAction<void>('Customers/RESET_PAGE_CACHE');
export const customersPageLoadError = createAction<string>('Customers/CUSTOMERS_PAGE_LOAD_ERROR');
export const setCustomersSearchQuery = createAction<string>('Customers/CUSTOMERS_SET_SEARCH_QUERY');
export const setCustomersListFilters = createAction<CustomersListFilters>(
	'Customers/CUSTOMERS_SET_LIST_FILTERS',
);

export const loadCustomersPage = createAppThunk(
	'Customers/LOAD_CUSTOMERS_PAGE',
	async (args: {
		pagenum: number;
		limit: number;
		pageToken?: string;
		start?: string;
		end?: string;
		searchQuery?: string;
		listFilters?: Partial<{ marketing: boolean }>;
	}) => {
		const lastPurchaseDate =
			args.start || args.end ? { gte: args.start, lte: args.end } : undefined;
		if (args.searchQuery) {
			return await Api.customers.search({
				limit: args.limit,
				pageToken: args.pageToken,
				query: args.searchQuery,
				lastPurchaseDate,
			});
		} else {
			return await Api.customers.list({
				...args.listFilters,
				limit: args.limit,
				pageToken: args.pageToken,
				lastPurchaseDate,
			});
		}
	},
);

export const getFirstCustomersPage = createAppThunk(
	'Customers/GET_FIRST_CUSTOMERS_PAGE',
	async (_, { getState, dispatch }) => {
		const state = getState();
		const limit = state.customers.pageSize;
		const range = evaluateTimeRange(state.customers.timeRange);
		const searchQuery = state.customers.searchQuery;
		const listFilters = state.customers.listFilters;
		dispatch(loadCustomersPage({ pagenum: 0, limit, searchQuery, listFilters, ...range }));
	},
);

export const getNextCustomersPage = createAppThunk(
	'Customers/GET_NEXT_CUSTOMERS_PAGE',
	async (_, { getState, dispatch }) => {
		const state = getState();
		const pagenum = state.customers.pageNumber;
		const limit = state.customers.pageSize;
		const pageToken = state.customers.pageTokens[pagenum + 1];
		const range = evaluateTimeRange(state.customers.timeRange);
		const searchQuery = state.customers.searchQuery;
		const listFilters = state.customers.listFilters;
		dispatch(
			loadCustomersPage({
				pagenum: pagenum + 1,
				limit,
				pageToken,
				searchQuery,
				listFilters,
				...range,
			}),
		);
	},
);

export const getPreviousCustomersPage = createAppThunk(
	'Customers/GET_PREVIOUS_CUSTOMERS_PAGE',
	async (_, { getState, dispatch }) => {
		const state = getState();
		const pagenum = state.customers.pageNumber;
		const limit = state.customers.pageSize;
		const pageToken = state.customers.pageTokens[pagenum - 1];
		const range = evaluateTimeRange(state.customers.timeRange);
		const searchQuery = state.customers.searchQuery;
		const listFilters = state.customers.listFilters;
		dispatch(
			loadCustomersPage({
				pagenum: Math.max(pagenum - 1, 0),
				limit,
				pageToken,
				searchQuery,
				listFilters,
				...range,
			}),
		);
	},
);

export const exportCustomers = createAppThunk(
	'Customers/EXPORT_CUSTOMERS',
	async (_, { getState, dispatch }) => {
		const state = getState();
		const range = evaluateTimeRange(state.customers.timeRange);
		const searchQuery = state.customers.searchQuery;
		const listFilters = state.customers.listFilters;
		try {
			let exportTask = await Api.customers.createExport({
				aggregation: !!searchQuery
					? {
							type: 'search',
							query: searchQuery,
							lastPurchaseDate: { gte: range.start, lte: range.end },
					  }
					: {
							type: 'list',
							lastPurchaseDate: { gte: range.start, lte: range.end },
							...listFilters,
					  },
			});
			for (let i = 0; i < 5; i++) {
				await sleep(3000);
				exportTask = await Api.customers.getExport(exportTask.id);
				if (exportTask.ready) {
					const blob = await fetch(exportTask.downloadURL).then((r) => r.blob());
					const dataURL = URL.createObjectURL(blob);
					const anchor = document.createElement('a');
					anchor.href = dataURL;
					anchor.download = exportTask.name;
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(dataURL);
					break;
				}
			}
			if (!exportTask.ready) {
				throw new Error('Export task failed');
			}
		} catch (err) {
			dispatch(
				NotificationActions.showNotification({
					message: t('common:errors.somethingWentWrong'),
					variant: 'error',
				}),
			);
			throw err;
		}
	},
);
