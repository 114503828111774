import { TFunction } from 'react-i18next';
import { RiTeamLine } from 'react-icons/ri';

import {
	OnboardingListVersion,
	OnboardingSections,
	ShopAnalytics,
} from 'common/services/analytics/tractionAnalytics';

import { OnboardingStep, OnboardingStepItem } from '../../types';
import { getOnboardingItemFromOnboardingStepItem } from '../../utils';

const STEP_VERSIONS = ({
	items,
	shopAnalytics,
	t,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
}): Record<'V0' | 'V1', OnboardingStep> => ({
	V1: {
		name: t('homeView.onboarding.shareWithYourTeam', 'Share with your team'),
		section: OnboardingSections.CONFIDENCE_AND_COLLABORATION,
		type: 'list',
		icon: RiTeamLine,
		items: items.map((item) => getOnboardingItemFromOnboardingStepItem(item, shopAnalytics)),
	},
	V0: {
		name: t('homeView.onboarding.usersTitle3', 'Invite more users to join in Rentle'),
		type: 'list',
		items: items.map((item) => getOnboardingItemFromOnboardingStepItem(item, shopAnalytics)),
	},
});

export const USERS_STEP = ({
	items,
	shopAnalytics,
	t,
}: {
	items: OnboardingStepItem[];
	shopAnalytics: ShopAnalytics | undefined;
	t: TFunction;
}): { all: OnboardingStep } | { [key in OnboardingListVersion]?: OnboardingStep } => {
	const versions = STEP_VERSIONS({ items, shopAnalytics, t });
	return {
		V3: versions['V1'],
		V2_TEMPLATE: versions['V1'],
		V2_NO_TEMPLATE: versions['V1'],
		V1_TEMPLATE: versions['V0'],
		V1_NO_TEMPLATE: versions['V0'],
		V0: versions['V0'],
	};
};
