import { createReducer } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE } from 'views/Customers/constants';

import * as actions from 'actions/CustomerActions';
import { CustomersState } from 'services/types';

const INITIAL_STATE: CustomersState = {
	pageSize: DEFAULT_PAGE_SIZE,
	timeRange: { type: 'all' },
	pageTokens: { 0: undefined },
	pageNumber: 0,
	page: { data: [], loading: false, error: null },
	total: 0,
	export: { exporting: false, error: null },
	searchQuery: '',
	listFilters: {},
};

const CustomersReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(actions.loadCustomersPage.pending, (state, action) => {
		state.pageNumber = action.meta.arg.pagenum;
		state.page = {
			data: [],
			loading: true,
			error: null,
		};
	});
	builder.addCase(actions.loadCustomersPage.fulfilled, (state, action) => {
		state.total = action.payload.total;
		state.pageTokens[action.meta.arg.pagenum + 1] = action.payload.nextPageToken;
		state.page = {
			data: action.payload.data,
			loading: false,
			error: null,
		};
	});
	builder.addCase(actions.loadCustomersPage.rejected, (state, action) => {
		state.page = {
			data: null,
			loading: false,
			error: action.error.message ?? 'Unknown exception',
		};
	});
	builder.addCase(actions.setCustomersPageSize, (state, action) => {
		state.pageSize = action.payload;
		state.pageTokens = { 0: undefined };
	});
	builder.addCase(actions.setCustomersTimeRange, (state, action) => {
		state.timeRange = action.payload;
	});
	builder.addCase(actions.exportCustomers.pending, (state) => {
		state.export.exporting = true;
		state.export.error = null;
	});
	builder.addCase(actions.exportCustomers.fulfilled, (state) => {
		state.export.exporting = false;
		state.export.error = null;
	});
	builder.addCase(actions.exportCustomers.rejected, (state, action) => {
		state.export.exporting = false;
		state.export.error = action.error.message ?? 'Failed to export customers';
	});
	builder.addCase(actions.setCustomersSearchQuery, (state, action) => {
		state.searchQuery = action.payload;
	});
	builder.addCase(actions.setCustomersListFilters, (state, action) => {
		state.listFilters = action.payload;
	});
});

export default CustomersReducer;
