import { ReactComponent as OtherSportsIcon } from 'common/assets/icons/icon-balls-line.svg';
import { ReactComponent as CarsIcon } from 'common/assets/icons/icon-campervan-line.svg';
import { ReactComponent as ClothesIcon } from 'common/assets/icons/icon-clothes-line.svg';
import { ReactComponent as EventIcon } from 'common/assets/icons/icon-party-decorations-line.svg';
import { ReactComponent as OtherIcon } from 'common/assets/icons/icon-question-line.svg';
import {
	GroupedIndustryCategories,
	IndustryCategory,
	IndustryOption,
} from 'common/services/analytics/tractionAnalytics';
import i18n from 'services/localization';

export const getGroupedIndustryCategoryOptions = (): IndustryOption[] => [
	{
		type: GroupedIndustryCategories.SPORTS_OUTDOORS,
		heading: i18n.t('register.industryCategory.SportsOutdoors.heading', 'Sports & Outdoors'),
		icon: OtherSportsIcon,
	},
	{
		type: GroupedIndustryCategories.VEHICLES,
		heading: i18n.t('register.industryCategory.Vehicles.heading', 'Vehicles'),
		icon: CarsIcon,
	},
	{
		type: GroupedIndustryCategories.EVENTS_AV,
		heading: i18n.t('register.industryCategory.EventsAv.heading', 'Events & AV'),
		icon: EventIcon,
	},
	{
		type: GroupedIndustryCategories.CLOTHING_ACCESSORIES,
		heading: i18n.t(
			'register.industryCategory.ClothingAccessories.heading',
			'Clothing & Accessories',
		),
		icon: ClothesIcon,
	},
	{
		type: GroupedIndustryCategories.OTHER,
		heading: i18n.t('register.industryCategory.Other.heading', 'Other'),
		icon: OtherIcon,
	},
];

export const getIndustryCategoryLabel = (category: IndustryCategory) =>
	getGroupedIndustryCategoryOptions().find((industry) => industry.type === category)?.heading;

export const getCtaContent = (signupVersion: string) => {
	switch (signupVersion) {
		case 'V1':
			return [
				{
					title: i18n.t('register.ctaTitleFirst', 'Start for free'),
					text: i18n.t(
						'register.ctaheadingFirst2',
						'Get started with Rentle Free. No credit card required. Upgrade for more features at your own pace without time constraints.',
					),
				},
				{
					title: i18n.t('register.ctaTitleSecond3', 'Monetize your offering'),
					text: i18n.t(
						'register.ctaheadingSecond3',
						'Quick launch with store templates. Multiple business models supported. Customize to meet complex business needs.',
					),
				},
				{
					title: i18n.t('register.ctaTitleThird3', 'Integrate or use standalone'),
					text: i18n.t(
						'register.ctaheadingThird3',
						'Customizable product catalog and an online store always included. Integrate with your existing website in minutes or use as a standalone website.',
					),
				},
			];
		case 'V0':
		default:
			return [
				{
					title: i18n.t('register.ctaTitleFirst', 'Start for free'),
					text: i18n.t(
						'register.ctaheadingFirst',
						'Get started with Rentle Free, choose to upgrade later',
					),
				},
				{
					title: i18n.t('register.ctaTitleSecond', 'Get started in minutes'),
					text: i18n.t('register.ctaheadingSecond', 'No technical skills required.'),
				},
				{
					title: i18n.t('register.ctaTitleThird', 'Start selling online'),
					text: i18n.t(
						'register.ctaheadingThird',
						'Bring your idea to life with the tools you need to start selling online.',
					),
				},
			];
	}
};
