import React, { useEffect, useMemo, useState } from 'react';

import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Callable } from 'common/frontend/callable';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { switchUnreachable } from 'common/utils/common';
import * as ViewSelectors from 'selectors/ViewSelectors';
import { useTranslation } from 'services/localization';
import { Routes } from 'routing';

import { useRegisterContext } from '../RegisterContext';
import { GET_STARTED_STEP_NAMES, GetStartedStep, getStartedSteps } from '../utils';
import RegistrationCapabilities from './components/RegistrationCapabilities';
import RegistrationError from './components/RegistrationError';
import RegistrationIndustry from './components/RegistrationIndustry';
import RegistrationLayout from './components/RegistrationLayout';
import RegistrationSetup from './components/RegistrationSetup';

const GetStarted = () => {
	const [
		{ registrationStatus, customerPersona, shopId, shopAnalyticsDocId },
		setStore,
	] = useRegisterContext((state) => state);

	const { t } = useTranslation();
	const isAuthenticated = useSelector(ViewSelectors.isAuthenticated);
	const isAuthLoading = useSelector(ViewSelectors.isAuthLoading);
	const [stepIndex, setStepIndex] = useState<number>(0);
	const [movedBack, setMovedBack] = useState<boolean>(false);

	const isInitialStateWithNoShopIds =
		registrationStatus === 'initial' && shopId === null && shopAnalyticsDocId === null;

	const steps: GetStartedStep[] = useMemo(() => {
		return getStartedSteps(t, customerPersona.value);
	}, [t, customerPersona.value]);

	const currentStep = steps[stepIndex];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [stepIndex]);

	useEffect(() => {
		const getTemplateIdsByIndustry = async () => {
			try {
				const templateIds = await Callable.admin.getTemplateIdsByIndustry();
				setStore((store) => {
					store.dirty = true;
					store.templateIdsByIndustry = templateIds;
				});
			} catch (err) {
				errorHandler.report(err);
			}
		};
		getTemplateIdsByIndustry();
	}, [setStore]);

	const renderTitle = (currentStep: GetStartedStep) => {
		const stepNumber = stepIndex + 1;
		const totalSteps = steps.length + 1; // as the last step has to be completed before the progress bar is completely full
		return (
			<Stack direction="column">
				<Typography variant="h5" textAlign="center" fontSize="2.8rem" sx={{ mb: 1 }}>
					{currentStep.title}
				</Typography>
				<Typography variant="body1" color="secondary" textAlign="center">
					{currentStep.description}
				</Typography>
				<Box my={4}>
					<LinearProgress variant="determinate" value={(stepNumber / totalSteps) * 100} />
				</Box>
			</Stack>
		);
	};

	const handleBack = () => {
		setMovedBack(true);
		setStepIndex(stepIndex - 1);
	};

	const handleContinue = () => {
		setStepIndex(stepIndex + 1);
		setMovedBack(false);
	};

	const renderStep = (currentStep: GetStartedStep) => {
		switch (currentStep.id) {
			case GET_STARTED_STEP_NAMES.SETUP:
				return (
					<RegistrationSetup
						onContinue={handleContinue}
						options={{ transitionDelay: movedBack ? '0s' : '0.6s' }}
					/>
				);
			case GET_STARTED_STEP_NAMES.CAPABILITIES:
				return <RegistrationCapabilities onBack={handleBack} onContinue={handleContinue} />;
			case GET_STARTED_STEP_NAMES.INDUSTRY:
				return <RegistrationIndustry onBack={handleBack} />;
			default:
				return switchUnreachable(currentStep.id);
		}
	};

	if (isInitialStateWithNoShopIds) {
		return isAuthLoading ? null : isAuthenticated ? (
			<Redirect to={Routes.HOME_VIEW} />
		) : (
			<Redirect to={Routes.REGISTER} />
		);
	}

	return (
		<>
			<RegistrationLayout>
				{registrationStatus !== 'error' ? (
					renderTitle(currentStep)
				) : (
					<RegistrationError
						onBack={() => {
							setStore((store) => {
								store.dirty = true;
								store.registrationStatus = 'initial';
							});
						}}
					/>
				)}
			</RegistrationLayout>
			{registrationStatus !== 'error' && (
				<Stack direction="column" alignItems="center" spacing={2}>
					{renderStep(currentStep)}
				</Stack>
			)}
		</>
	);
};

export default GetStarted;
