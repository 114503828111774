import {
	OnboardingItem,
	OnboardingListVersion,
	OnboardingStepDefinition,
} from 'common/services/analytics/tractionAnalytics';
import { switchUnreachable } from 'common/utils/common';

const LINK_STORE_INCLUDED_ITEMS = (version: OnboardingListVersion): OnboardingItem[] => {
	switch (version) {
		case 'V3_USERFLOW':
			return [];
		case 'V3':
		case 'V2_TEMPLATE':
		case 'V2_NO_TEMPLATE':
			return ['STORE_DETAILS', 'OPENING_HOURS', 'CANCELLATION_POLICY', 'LINK_ONLINE_STORE'];
		case 'V1_TEMPLATE':
		case 'V1_NO_TEMPLATE':
		case 'V0':
			return ['LINK_ONLINE_STORE'];
		default:
			return switchUnreachable(version);
	}
};

export const LINK_STORE_DEFINITION: Record<'LINK_STORE', OnboardingStepDefinition> = {
	LINK_STORE: {
		plans: 'all',
		versions: {
			V3: LINK_STORE_INCLUDED_ITEMS('V3'),
			V2_TEMPLATE: LINK_STORE_INCLUDED_ITEMS('V2_TEMPLATE'),
			V2_NO_TEMPLATE: LINK_STORE_INCLUDED_ITEMS('V2_NO_TEMPLATE'),
			V1_TEMPLATE: LINK_STORE_INCLUDED_ITEMS('V1_TEMPLATE'),
			V1_NO_TEMPLATE: LINK_STORE_INCLUDED_ITEMS('V1_NO_TEMPLATE'),
			V0: LINK_STORE_INCLUDED_ITEMS('V0'),
		},
	},
};
