import {
	RiAddBoxLine,
	RiArrowLeftRightLine,
	RiListSettingsLine,
	RiPriceTag3Line,
	RiSettings3Line,
	RiTableLine,
} from 'react-icons/ri';
import * as Routes from 'routing/routes';

import { SearchGroups, SearchTypes, Searchable } from './types';
import { noDynamicRoutes, toPathLabelString } from './utils';

export const commonSearchables: Searchable = {
	BOOKINGS: {
		group: SearchGroups.Common,
		type: SearchTypes.route,
		label: toPathLabelString(Routes.BOOKINGS),
		value: Routes.BOOKINGS,
		icon: RiArrowLeftRightLine,
	},
	CREATE_ORDER: {
		group: SearchGroups.Common,
		type: SearchTypes.route,
		label: 'Create order',
		value: Routes.NEW_RENTAL,
		icon: RiAddBoxLine,
	},
	CATALOG: {
		group: SearchGroups.Common,
		type: SearchTypes.route,
		label: toPathLabelString(Routes.CATALOG),
		value: Routes.CATALOG,
		icon: RiPriceTag3Line,
	},
	INVENTORY: {
		group: SearchGroups.Common,
		type: SearchTypes.route,
		label: toPathLabelString(Routes.INVENTORY),
		value: Routes.INVENTORY,
		icon: RiTableLine,
	},
	SETTINGS: {
		group: SearchGroups.Common,
		type: SearchTypes.route,
		label: 'Store settings',
		value: Routes.STORE,
		icon: RiListSettingsLine,
	},
	ACCOUNT_SETTINGS: {
		group: SearchGroups.Common,
		type: SearchTypes.route,
		label: 'Account settings',
		value: Routes.ACCOUNT,
		icon: RiSettings3Line,
	},
};

export const routeSearchables: Searchable = Object.entries(Routes)
	.filter(([_, value]) => noDynamicRoutes(value))
	.reduce(
		(acc, [key, value]) => ({
			...acc,
			[key]: {
				group: SearchGroups.Routes,
				type: SearchTypes.route,
				label: toPathLabelString(value),
				value,
			},
		}),
		{} as Searchable,
	);

export const routeSearchablesArray = Object.values(routeSearchables);
export const commonSearchablesArray = Object.values(commonSearchables);
export const allSearchablesArray = [...commonSearchablesArray, ...routeSearchablesArray];
