import * as React from 'react';

import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FallbackProps } from 'react-error-boundary';
import { TFunction } from 'react-i18next';

interface Props extends FallbackProps {
	t: TFunction;
}

const ErrorStateFallback = (props: Props) => {
	const { t, resetErrorBoundary } = props;
	return (
		<Box
			textAlign="center"
			padding={(theme) => theme.spacing(2)}
			sx={{ flex: 1, width: '100%', height: '100%' }}
		>
			<Typography variant="h6">{t('common:errors.somethingWentWrong')}</Typography>
			<Button sx={{ mt: 2 }} variant="text" onClick={resetErrorBoundary}>
				{t('common:errors.reloadPage', 'Reload page')}
			</Button>
		</Box>
	);
};

export default ErrorStateFallback;
