import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { PlanCampaign } from 'common/modules/plans';
import { isPublicPlanCampaign } from 'common/modules/plans/campaigns/utils';

import { buildQuery, createBasicDbOperations } from '../utils';

export const getCampaignsQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.campaigns;
	const query = buildQuery<PlanCampaign>(collectionPath, dbRefs);
	return {
		...createBasicDbOperations<PlanCampaign>(collectionPath, dbRefs),
		getByCode: async (code: string) => {
			const results = await query.where('code', '==', code).get();
			if (results.length === 0) return undefined;
			return results[0];
		},
		getPublicCampaigns: async () => {
			const results = await query.where('visibility.type', '==', 'PUBLIC').get();
			return results.filter(isPublicPlanCampaign);
		},
	};
};
