import React from 'react';

import { Box, Typography } from '@mui/material';

import LinkButton from 'common/components/LinkButton';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes } from 'routing';

export const InvalidCode = () => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant="h5" gutterBottom>
				{t('authActions.expiredLink', 'Expired or incorrect link')}
			</Typography>
			<Typography>
				{t(
					'authActions.expiredLinkInfo',
					'Your link has expired, the link is incorrect or it has already been used',
				)}
				.
			</Typography>
			<Box mt={4} />
			<LinkButton variant="contained" color="primary" to={Routes.ROOT}>
				<Typography variant="caption">{t('authActions.backHome', 'Back to home page')}</Typography>
			</LinkButton>
		</>
	);
};

export default InvalidCode;
