import { ZodRawShape, z } from 'zod';

import { ZodOpenApiOptions, withOpenApi } from 'common/utils/openApi';

export const zBaseDbDoc = z.object({
	id: z.string(),
	createdAt: z.string(),
	updatedAt: z.string(),
});

export type BaseDbDoc = z.infer<typeof zBaseDbDoc>;

export const zInternal = <T extends ZodRawShape>(
	shape: T,
	options?: ZodOpenApiOptions<z.ZodObject<T>>['fields'],
) => {
	if (!!options) {
		return withOpenApi(z.object(shape), { fields: options }).optional();
	}
	return z.object(shape).optional();
};
