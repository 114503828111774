import { Channel } from 'common/models/_atoms/Channel';
import { TimePeriod } from 'common/models/_atoms/TimePeriod';

import { PricingItemApi, PricingItemDb } from './types';

type NonSpecialChannelName = Exclude<
	NonNullable<PricingItemDb['channels']>[number],
	'ALL' | 'HIDDEN'
>;

export const toApi = (db: PricingItemDb, basePrice: number): PricingItemApi => {
	const getChannelLimit = (data: PricingItemDb['channels']): PricingItemApi['channels'] => {
		if (!data || data.includes('ALL')) return undefined;
		if (data.includes('HIDDEN')) return [];
		return data
			.filter((c): c is NonSpecialChannelName => c !== 'ALL' && c !== 'HIDDEN')
			.map((c) => Channel.toApi(c));
	};

	const getPriceFromMultiplier = (multiplier: number, basePrice: number) => {
		return !!basePrice ? Math.round(basePrice * multiplier) : 0;
	};

	return {
		label: db.label,
		timePeriod: TimePeriod.toApi(db.timePeriod),
		// TODO Is it safe to default '' to 0?
		timeValue: db.timeValue || 0,
		// TODO Is it safe to default '' to 0?
		price: getPriceFromMultiplier(db.multiplier || 0, basePrice),
		channels: getChannelLimit(db.channels),
	};
};

export const toDb = (api: PricingItemApi, basePrice: number): PricingItemDb => {
	const getChannels = (data: PricingItemApi['channels']): PricingItemDb['channels'] => {
		if (!data) return ['ALL'];
		if (!data.length) return ['HIDDEN'];
		return data.map((c) => Channel.toDb(c));
	};

	const getMultiplierFromPrice = (price: number, basePrice: number) => {
		return !!basePrice ? price / basePrice : 0;
	};

	return {
		label: api.label,
		timePeriod: TimePeriod.toDb(api.timePeriod),
		timeValue: api.timeValue,
		price: api.price,
		channels: getChannels(api.channels),
		multiplier: getMultiplierFromPrice(api.price, basePrice),
	};
};
