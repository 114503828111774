import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { createBasicCollectionOperations, createBasicDocOperations } from '../utils';
import { AdminApi, ApiKeyApi } from 'common/types';

export const getAdminQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.adminCollection;
	const dataUpdater = undefined;
	return {
		data: createBasicDocOperations<AdminApi>(collectionPath, dbRefs, 'data', dataUpdater),
		apiKeys: getApiKeysQueries(dbRefs),
	};
};

const getApiKeysQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.adminApiKeys;
	const dataUpdater = undefined;

	return {
		doc: (id: string) => ({
			...createBasicDocOperations<ApiKeyApi>(collectionPath, dbRefs, id, dataUpdater),
		}),
		...createBasicCollectionOperations<ApiKeyApi>(collectionPath, dbRefs, dataUpdater),
	};
};
