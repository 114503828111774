import { addCategoriesListener, addProductsListener } from 'common/api/frontend';
import {
	removeTemplateCategories,
	removeTemplateProducts,
} from 'common/api/frontend/mockProducts/templates/utils';
import {
	setCategoriesHubspotProperties,
	setGaUserProperties,
	setProductsHubspotProperties,
} from 'common/services/analytics';
import * as IntercomService from 'common/services/analytics/intercom';
import { Category, ProductApi } from 'common/types';
import { LoadingData, StockState, ThunkResult } from 'services/types';

export type StockAction =
	| UpdateStock
	| UpdateStockProducts
	| UpdateStockCategories
	| UpdateStockCategory;

export interface UpdateStockProducts {
	type: 'UPDATE_STOCK_PRODUCTS';
	stockProducts: LoadingData<ProductApi[]>;
}

export const updateStockProducts = (
	stockProducts: LoadingData<ProductApi[]>,
): UpdateStockProducts => {
	return {
		type: 'UPDATE_STOCK_PRODUCTS',
		stockProducts,
	};
};

export interface UpdateStock {
	type: 'UPDATE_STOCK';
	stock: StockState;
}

export const updateStock = (stock: StockState): UpdateStock => {
	return {
		type: 'UPDATE_STOCK',
		stock,
	};
};

export const setStockListeners = (activeShopId: string): ThunkResult => {
	return async (dispatch) => {
		dispatch(setProductsListener(activeShopId));
		dispatch(setCategoriesListener(activeShopId));
	};
};

export const setProductsListener = (shopId: string): ThunkResult => {
	return (dispatch, getState) => {
		addProductsListener(
			shopId,
			(products: ProductApi[]) => {
				const stockProducts: LoadingData<ProductApi[]> = {
					loading: false,
					data: products,
					error: null,
				};
				const productCountForAnalytics = removeTemplateProducts(stockProducts.data).length;
				setGaUserProperties({ product_count: productCountForAnalytics }, { sendToAllTags: true });
				setProductsHubspotProperties({
					newData: products,
					oldData: getState().stock.stockProducts.data ?? [],
				});
				IntercomService.update({
					company: { company_id: shopId, product_count: productCountForAnalytics },
				});
				dispatch(updateStockProducts(stockProducts));
			},
			(error) => {
				const stockProducts: LoadingData<ProductApi[]> = {
					loading: false,
					data: null,
					error: error.message || 'Error getting products',
				};
				dispatch(updateStockProducts(stockProducts));
			},
		);
	};
};

interface UpdateStockCategories {
	type: 'UPDATE_STOCK_CATEGORIES';
	categories: LoadingData<Category[]>;
}

export const updateStockCategories = (
	categories: LoadingData<Category[]>,
): UpdateStockCategories => {
	return {
		type: 'UPDATE_STOCK_CATEGORIES',
		categories,
	};
};

interface UpdateStockCategory {
	type: 'UPDATE_STOCK_CATEGORY';
	category: LoadingData<Category>;
}

export const updateStockCategory = (category: LoadingData<Category>): UpdateStockCategory => {
	return {
		type: 'UPDATE_STOCK_CATEGORY',
		category,
	};
};

const setCategoriesListener = (shopId: string): ThunkResult<Promise<Category[]>> => {
	return async (dispatch, getState) => {
		return new Promise<Category[]>(() => {
			addCategoriesListener(
				shopId,
				(categories: Category[]) => {
					const loadedCategories: LoadingData<Category[]> = {
						loading: false,
						data: categories,
						error: null,
					};
					const categoryCountForAnalytics = removeTemplateCategories(loadedCategories.data).length;
					setGaUserProperties(
						{ category_count: categoryCountForAnalytics },
						{ sendToAllTags: true },
					);
					setCategoriesHubspotProperties({
						newData: categories,
						oldData: getState().stock.categories.data ?? [],
					});
					dispatch(updateStockCategories(loadedCategories));
				},
				(error) => {
					const errorCategories: LoadingData<Category[]> = {
						loading: false,
						data: null,
						error: error.message || 'Error getting categories',
					};
					dispatch(updateStockCategories(errorCategories));
				},
			);
		});
	};
};
