import { Button, Stack, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';

import { Listing } from '@rentle/resell-api-client';
import { getPricingString } from 'common/utils/common';

import ImageCarousel from './ImageCarousel';

interface ListingProps {
	listing: Listing;
	t: TFunction;
	onCheckoutClick?: () => void;
}

const ListingCard = (props: ListingProps) => {
	const { listing, onCheckoutClick, t } = props;
	return (
		<Stack sx={{ height: '100%' }} direction="row" alignItems="stretch">
			<Stack sx={{ flex: 1 }} alignItems="stretch">
				<ImageCarousel images={listing.imageURLs} title={listing.title} t={t} />
			</Stack>
			<Stack px={2} sx={{ flex: 1 }} justifyContent="space-between">
				<Stack>
					<Typography variant="h4">
						{listing.title ?? t('common:listings.previewTitlePlaceholder', 'Title')}
					</Typography>
					<Typography variant="body1">
						{getPricingString(listing.price) ??
							t('common:listings.previewPricePlaceholder', 'Price')}
					</Typography>
					<Typography variant="body1" mt={2}>
						{listing.description ??
							t('common:listings.previewDescriptionPlaceholder', 'Description will appear here')}
					</Typography>
				</Stack>
				<Button variant="contained" disabled={!onCheckoutClick} onClick={onCheckoutClick}>
					{t('common:listings.checkout', 'Checkout')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default ListingCard;
