import Stripe from 'stripe';

import {
	AdyenPaymentMethod,
	ExternalPaymentMethod,
	FixedManualPaymentMethod,
	ShopCustomOnlinePaymentMethodObject,
	StripePaymentMethod,
} from 'common/modules/payments/types';
import { BrowserInfo, Languages, OrderObject, PaymentMethodData } from 'common/types';

import { CallableCreator } from '..';

export interface AdyenClientPaymentResult {
	provider: 'ADYEN';
	resultCode: ICheckout.PaymentResponse['resultCode'];
	refusalReason?: ICheckout.PaymentResponse['refusalReason'];
	refusalReasonCode?: ICheckout.PaymentResponse['refusalReasonCode'];
	action?: ICheckout.PaymentResponse['action'];
}

export interface StripeClientPaymentResult {
	provider: 'STRIPE';
	status: Stripe.PaymentIntent['status'];
	next_action: Stripe.PaymentIntent['next_action'];
	cancellation_reason: Stripe.PaymentIntent['cancellation_reason'];
	id: string;
	clientSecret: string | null;
}

export interface ExternalPaymentRequest {
	provider: 'EXTERNAL';
	method: ExternalPaymentMethod;
}

export interface ManualPaymentRequest {
	provider: 'MANUAL';
	method: FixedManualPaymentMethod | ShopCustomOnlinePaymentMethodObject;
}

export interface StripePaymentRequest {
	provider: 'STRIPE';
	method: StripePaymentMethod;
	paymentMethodId: string;
	returnUrl: string;
	origin: string;
	browserInfo: BrowserInfo;
	transactionId: string;
}

export interface AdyenPaymentRequest {
	provider: 'ADYEN';
	method: AdyenPaymentMethod;
	paymentMethodData: PaymentMethodData;
	returnUrl: string;
	origin: string;
	browserInfo: BrowserInfo;
	transactionId: string;
}

export type ClientPaymentData =
	| ExternalPaymentRequest
	| ManualPaymentRequest
	| StripePaymentRequest
	| AdyenPaymentRequest;
export interface CreateOnlineOrder {
	order: OrderObject;
	shopId: string;
	userLanguage: Languages;
	clientPaymentData: ClientPaymentData;
}

export interface VerifyOnlinePayment {
	orderId: string;
	transactionId: string;
	returnUrl: string;
	origin: string;
	paymentDetails: AdyenPaymentDetails | AdyenRedirectDetails | StripePaymentDetails;
	userLanguage: Languages;
}

export type AdyenPaymentDetails = {
	provider: 'ADYEN';
	details: ICheckout.DetailsRequest;
};

export type AdyenRedirectDetails = {
	provider: 'ADYEN';
	redirect: true;
};

export type StripePaymentDetails = {
	provider: 'STRIPE';
	intentId: string;
	stripeAccountId: string;
};

export type CompletedClientPaymentResult = {
	status: 'SUCCESS';
	order: OrderObject;
	providerResult?: AdyenClientPaymentResult | StripeClientPaymentResult;
};

export type InCompleteClientPaymentResult = {
	status: 'FAILED' | 'REQUIRES_ACTION';
	providerResult?: AdyenClientPaymentResult | StripeClientPaymentResult;
};

export type ClientPaymentResult = CompletedClientPaymentResult | InCompleteClientPaymentResult;

export const online = (createCallable: CallableCreator) => ({
	create: createCallable<CreateOnlineOrder, ClientPaymentResult>('orders-online-create'),
	verify: createCallable<VerifyOnlinePayment, ClientPaymentResult>('orders-online-verify'),
});
