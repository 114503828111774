import { initializeApp } from 'firebase/app';

const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
	projectId: firebaseProjectId,
	storageBucket: `${firebaseProjectId}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
