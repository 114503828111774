import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
	removeCategoriesListener,
	removeProductsListener,
	removeRentalsListeners,
} from 'common/api/frontend';
import { User, onIdTokenChanged } from 'common/frontend/firebase/auth';
import { hasAccessToLocation } from 'common/modules/users/utils';
import * as ShopActions from 'actions/ShopActions';
import * as UserActions from 'actions/UserActions';
import * as ViewActions from 'actions/ViewActions';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';

const UserManager = () => {
	const dispatch = useDispatch();

	const [authUserId, setAuthUserId] = useState<string | null>(null);
	const [hasAccessClaims, setHasAccessClaims] = useState<boolean>(false);
	const activeShopId = useSelector(UserSelectors.userActiveShopId);
	const activeUserLanguage = useSelector(UserSelectors.activeUserLanguage);

	const shopLocationsById = useSelector(ShopSelectors.allShopLocationsById);
	const activeLocationId = useSelector(UserSelectors.userActiveLocationId);
	const activeUserAccess = useSelector(UserSelectors.userActiveShopAccess);
	const userDefaultLocation = useSelector(UserSelectors.userDefaultLocation);
	const defaultLocationId = userDefaultLocation?.id ?? null;

	useEffect(() => {
		if (!!activeUserLanguage) {
			dispatch(ViewActions.updateLanguage(activeUserLanguage));
		}
	}, [dispatch, activeUserLanguage]);

	const checkAccessClaims = async (user: User | null) => {
		if (user) {
			const userToken = await user.getIdTokenResult();
			const claims = userToken.claims;
			if (!!claims.access) {
				setHasAccessClaims(true);
				return;
			}
		}
		setHasAccessClaims(false);
	};

	useEffect(() => {
		onIdTokenChanged(
			(user) => {
				checkAccessClaims(user);
				setAuthUserId(user?.uid ?? null);
			},
			(_error) => {
				setAuthUserId(null);
			},
		);
	}, []);

	useEffect(() => {
		if (!!authUserId) {
			dispatch(UserActions.setUserListeners(authUserId));
		}
		return () => {
			dispatch(UserActions.clearUserListeners());
		};
	}, [dispatch, authUserId]);

	useEffect(() => {
		if (!!activeShopId && hasAccessClaims) {
			dispatch(ShopActions.setActiveShop(activeShopId));
		}

		return () => {
			dispatch(ShopActions.clearActiveShopListeners());
			removeRentalsListeners();
			removeProductsListener();
			removeCategoriesListener();
		};
	}, [dispatch, activeShopId, hasAccessClaims]);

	useEffect(() => {
		if (!defaultLocationId || !activeUserAccess) return;
		if (
			!activeLocationId ||
			!shopLocationsById[activeLocationId] ||
			!hasAccessToLocation(activeUserAccess, activeLocationId)
		) {
			dispatch(UserActions.setActiveLocationId(defaultLocationId));
		}
	}, [activeUserAccess, defaultLocationId, activeLocationId, shopLocationsById, dispatch]);

	return null;
};

export default UserManager;
