import { isEmpty } from 'lodash';

import type {
	DocumentSnapshot,
	QueryDocumentSnapshot,
	SnapshotMetadata,
} from 'common/frontend/firebase/firestore';

import type {
	AdminDbQueryRef,
	AdminDocRef,
	ClientDbQueryRef,
	ClientDocRef,
	DbDocRef,
	DbGetRefParams,
	DbQueryRef,
	DbQueryRefParams,
	DbRefs,
	DocSnapshot,
	QuerySnapshot,
	SetRefParams,
	UpdateRefParams,
} from '../types';
import { isClientDbRefs } from './typeGuards';

export async function updateDoc<T extends object>({ data, dbRefs, docRef }: UpdateRefParams<T>) {
	if (isEmpty(data)) {
		return;
	}
	if (isClientDbRefs(dbRefs)) {
		const { batch, transaction } = dbRefs;
		const { updateDoc: _updateDoc } = dbRefs.firestoreMethods;
		const ref = docRef as ClientDocRef<T>;
		if (batch) {
			batch.update<any>(ref, data);
		} else if (transaction) {
			transaction.update<any>(ref, data);
		} else {
			await _updateDoc<any>(ref, data);
		}
	} else {
		const { batch, transaction } = dbRefs;
		const ref = docRef as AdminDocRef<T>;
		if (batch) {
			batch.update<any>(ref, data);
		} else if (transaction) {
			transaction.update<any>(ref, data);
		} else {
			await ref.update(data as any);
		}
	}
	return;
}

export async function setDoc<T extends object>({ data, docRef, dbRefs, merge }: SetRefParams<T>) {
	if (isEmpty(data)) {
		return;
	}
	if (isClientDbRefs(dbRefs)) {
		const { batch, transaction } = dbRefs;
		const { setDoc: _setDoc } = dbRefs.firestoreMethods;
		const ref = docRef as ClientDocRef<T>;
		if (!!batch) {
			batch.set<any>(ref, data, { merge: !!merge });
		} else if (!!transaction) {
			transaction.set<any>(ref, data, { merge: !!merge });
		} else {
			await _setDoc<any>(ref, data, { merge: !!merge });
		}
	} else {
		const { batch, transaction } = dbRefs;
		const ref = docRef as AdminDocRef<T>;
		if (!!batch) {
			batch.set<any>(ref, data, { merge: !!merge });
		} else if (!!transaction) {
			transaction.set<any>(ref, data, { merge: !!merge });
		} else {
			await ref.set(data as any, { merge: !!merge });
		}
	}
	return;
}

export async function getDoc<T extends object>({
	docRef,
	dbRefs,
}: DbGetRefParams<T>): Promise<T | undefined>;
export async function getDoc<T extends object>(
	{ docRef, dbRefs }: DbGetRefParams<T>,
	opts: { withDocId: true },
): Promise<{ docId: string; data: T | undefined; snapshot: DocSnapshot<T> }>;
export async function getDoc<T extends object>(
	{ docRef, dbRefs }: DbGetRefParams<T>,
	opts?: { withDocId: true },
): Promise<T | { docId: string; data: T | undefined; snapshot: DocSnapshot<T> } | undefined> {
	const returnWithDocId = !!opts?.withDocId;
	if (isClientDbRefs(dbRefs)) {
		const { transaction } = dbRefs;
		const { getDoc: _getDoc } = dbRefs.firestoreMethods;
		const ref = docRef as ClientDocRef<T>;
		let doc: DocumentSnapshot<T>;
		if (transaction) {
			doc = await transaction.get<T>(ref);
		} else {
			doc = await _getDoc<T>(ref);
		}
		const docData = doc.data();
		return returnWithDocId ? { docId: doc.id, data: docData, snapshot: doc } : docData;
	} else {
		const { transaction } = dbRefs;
		const ref = docRef as AdminDocRef<T>;
		let doc: FirebaseFirestore.DocumentSnapshot<T>;
		if (transaction) {
			doc = await transaction.get<T>(ref);
		} else {
			doc = await ref.get();
		}
		const docData = doc.data();
		return returnWithDocId ? { docId: doc.id, data: docData, snapshot: doc } : docData;
	}
}

export async function deleteDoc<T extends object>({
	docRef,
	dbRefs,
}: DbGetRefParams<T>): Promise<void> {
	if (isClientDbRefs(dbRefs)) {
		const { batch, transaction } = dbRefs;
		const { deleteDoc: _deleteDoc } = dbRefs.firestoreMethods;
		const ref = docRef as ClientDocRef<T>;
		if (batch) {
			batch.delete(ref);
		} else if (transaction) {
			transaction.delete(ref);
		} else {
			await _deleteDoc(ref);
		}
	} else {
		const { batch, transaction } = dbRefs;
		const ref = docRef as AdminDocRef<T>;
		if (batch) {
			batch.delete(ref);
		} else if (transaction) {
			transaction.delete(ref);
		} else {
			await ref.delete();
		}
	}
	return;
}

export async function getList<T extends object>({
	queryRef,
	dbRefs,
}: DbQueryRefParams<T>): Promise<T[]>;
export async function getList<T extends object>(
	{ queryRef, dbRefs }: DbQueryRefParams<T>,
	opts: { withDocId: true },
): Promise<
	{
		docId: string;
		data: T;
		snapshot: QueryDocumentSnapshot<T> | FirebaseFirestore.QueryDocumentSnapshot<T>;
	}[]
>;
export async function getList<T extends object>(
	{ queryRef, dbRefs }: DbQueryRefParams<T>,
	opts?: { withDocId: true },
): Promise<
	| T[]
	| {
			docId: string;
			data: T;
			snapshot: QueryDocumentSnapshot<T> | FirebaseFirestore.QueryDocumentSnapshot<T>;
	  }[]
> {
	const returnWithDocId = !!opts?.withDocId;
	if (isClientDbRefs(dbRefs)) {
		const { getDocs } = dbRefs.firestoreMethods;
		const ref = queryRef as ClientDbQueryRef<T>;
		const querySnapshot = await getDocs(ref);
		const docs = querySnapshot.docs;
		if (returnWithDocId) {
			return docs.map((doc) => ({ docId: doc.id, data: doc.data() as T, snapshot: doc }));
		}
		return docs.map((doc) => doc.data());
	} else {
		const { transaction } = dbRefs;
		const ref = queryRef as AdminDbQueryRef<T>;
		const querySnapshot = transaction ? await transaction.get(ref) : await ref.get();
		const docs = querySnapshot.docs;
		if (returnWithDocId) {
			return docs.map((doc) => ({ docId: doc.id, data: doc.data() as T, snapshot: doc }));
		}
		return docs.map((doc) => doc.data());
	}
}

interface DocListenerRefs<T extends object> {
	docRef: DbDocRef<T>;
	dbRefs: DbRefs;
}

export function addDocListener<T extends object>(
	refs: DocListenerRefs<T>,
	callback: (data: T | undefined, metadata: SnapshotMetadata | undefined) => void,
	errorCallback?: (error: Error) => void,
): () => void;
export function addDocListener<T extends object>(
	refs: DocListenerRefs<T>,
	callback: (
		data: { docId: string; data: T | undefined },
		metadata: SnapshotMetadata | undefined,
	) => void,
	errorCallback: (error: Error) => void,
	opts: { withDocId: true },
): () => void;
export function addDocListener<T extends object>(
	refs: DocListenerRefs<T>,
	callback: (
		data: T | undefined | { docId: string; data: T | undefined },
		metadata: SnapshotMetadata | undefined,
	) => void,
	errorCallback?: (error: Error) => void,
	opts?: { withDocId: true },
): () => void {
	const returnWithDocId = !!opts?.withDocId;
	const { dbRefs, docRef } = refs;
	if (isClientDbRefs(dbRefs)) {
		const { onSnapshot } = dbRefs.firestoreMethods;
		const ref = docRef as ClientDocRef<T>;
		const removeListener = onSnapshot(
			ref,
			(doc) => {
				const data = doc.data();
				if (returnWithDocId) {
					callback({ docId: doc.id, data }, doc.metadata);
				} else {
					callback(data, doc.metadata);
				}
			},
			errorCallback || undefined,
		);
		return removeListener;
	} else {
		const ref = docRef as AdminDocRef<T>;
		const removeListener = ref.onSnapshot((doc) => {
			const data = doc.data();
			if (returnWithDocId) {
				callback({ docId: doc.id, data }, undefined);
			} else {
				callback(data, undefined);
			}
		}, errorCallback || undefined);
		return removeListener;
	}
}

interface QueryListenerRefs<T extends object> {
	queryRef: DbQueryRef<T>;
	dbRefs: DbRefs;
}

export function addQueryListener<T extends object>(
	refs: QueryListenerRefs<T>,
	callback: (data: T[], snapshot: QuerySnapshot<T>, metadata: SnapshotMetadata | undefined) => void,
	errorCallback?: (error: Error) => void,
): () => void;
export function addQueryListener<T extends object>(
	refs: QueryListenerRefs<T>,
	callback: (
		data: { docId: string; data: T }[],
		snapshot: QuerySnapshot<T>,
		metadata: SnapshotMetadata | undefined,
	) => void,
	errorCallback: (error: Error) => void,
	opts: { withDocId: true },
): () => void;
export function addQueryListener<T extends object>(
	refs: QueryListenerRefs<T>,
	callback: (
		data: T[] | { docId: string; data: T }[],
		snapshot: QuerySnapshot<T>,
		metadata: SnapshotMetadata | undefined,
	) => void,
	errorCallback?: (error: Error) => void,
	opts?: { withDocId: true },
): () => void {
	const returnWithDocId = !!opts?.withDocId;
	const { queryRef, dbRefs } = refs;
	if (isClientDbRefs(dbRefs)) {
		const ref = queryRef as ClientDbQueryRef<T>;
		const { onSnapshot } = dbRefs.firestoreMethods;
		const removeListener = onSnapshot(
			ref,
			(querySnapshop) => {
				const docs = querySnapshop.docs;
				if (returnWithDocId) {
					callback(
						docs.map((doc) => ({ docId: doc.id, data: doc.data() })),
						querySnapshop,
						querySnapshop.metadata,
					);
				} else {
					callback(
						docs.map((doc) => doc.data()),
						querySnapshop,
						querySnapshop.metadata,
					);
				}
				return docs.map((doc) => doc.data() as T);
			},
			errorCallback || undefined,
		);
		return removeListener;
	} else {
		const ref = queryRef as AdminDbQueryRef<T>;
		const removeListener = ref.onSnapshot((querySnapshop) => {
			const docs = querySnapshop.docs;
			if (returnWithDocId) {
				callback(
					docs.map((doc) => ({
						docId: doc.id,
						data: doc.data(),
					})),
					querySnapshop,
					undefined,
				);
			} else {
				callback(
					docs.map((doc) => doc.data() as T),
					querySnapshop,
					undefined,
				);
			}
			return docs.map((doc) => doc.data() as T);
		}, errorCallback || undefined);
		return removeListener;
	}
}
