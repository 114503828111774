import { isEmpty } from 'lodash';

import { PurchaseType } from 'common/models/_atoms/PurchaseType';

import { OrderInfoDb } from '../OrderInfo/types';
import { OrderPayment } from '../OrderPayment';
import { ItemPricing } from './ItemPricing';
import { ItemSkus } from './ItemSkus';
import { PackageItem } from './PackageItem';
import { OrderItemApi, OrderItemDb } from './types';

export const toApi = (data: OrderItemDb, options: { orderInfo: OrderInfoDb }): OrderItemApi => {
	return {
		id: data.id,
		productId: data.productApiId,
		name: data.name,
		itemCodes: data.summary.itemCodes,
		variantId: data.variant?.id,
		variantValues: data.variant?.values ? Object.values(data.variant.values) : undefined,
		returnedDate: data.endDateReturned ?? undefined,
		startDate: data.startDate ?? options.orderInfo.startDate,
		endDate: data.endDate ?? options.orderInfo.returnTimeNext ?? options.orderInfo.startDate,
		cancelled: data.cancelled,
		payment: OrderPayment.toApi(data.charge),
		deposit: data.deposit ? OrderPayment.toApi(data.deposit) : data.deposit,
		pricing: ItemPricing.toApi(data.pricing),
		purchaseType: PurchaseType.toApi(data.purchaseType),
		packageItems: data.setProducts.map((p) => PackageItem.toApi(p)),
		itemSkus: isEmpty(data.stock)
			? undefined
			: Object.values(data.stock).map((value) => ItemSkus.toApi(value)),
	};
};
