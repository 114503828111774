import { ADVANCE_AND_ABOVE } from 'common/modules/plans/plans/constants';

import { AddOnDefinition, AddOns } from '..';

const definition: AddOnDefinition = {
	addOn: AddOns.TEKSO,
	blockSlots: 1,
	manualSetup: true,
	requiresPlan: [...ADVANCE_AND_ABOVE],
};

export default definition;
