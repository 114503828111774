import {
	ClientPaymentResult,
	VerifyOnlinePayment,
} from 'common/api/db/functions/_callable/orders/online';

import { CallableCreator } from '..';

type VerifyFailedPaymentRequest =
	| {
			action: 'charge';
			subscriptionPaymentId: string;
			transactionId: string;
			returnUrl: string;
			origin: string;
	  }
	| ({
			subscriptionPaymentId: string;
			action: 'additional-details';
	  } & VerifyOnlinePayment);

export const subscriptions = (createCallable: CallableCreator) => ({
	charge: createCallable<{ orderId: string }, any>('orders-subscriptions-charge'),
	chargeFailedPayment: createCallable<{ subscriptionPaymentId: string }, any>(
		'orders-subscriptions-chargeFailedPayment',
	),
	verifyFailedPayment: createCallable<VerifyFailedPaymentRequest, ClientPaymentResult>(
		'orders-subscriptions-verifyFailedPayment',
	),
	sendCancelSubscriptionEmail: createCallable<{ orderId: string }, any>(
		'orders-subscriptions-sendCancelSubscriptionEmail',
	),
});
