import { getOpenApiExample } from 'common/utils/openApi';

import { zOrderPricingApi } from './api';
import { toApi, toDb } from './mapper';

export const OrderPricing = {
	api: zOrderPricingApi,
	toApi,
	toDb,
	example: getOpenApiExample(zOrderPricingApi),
};
