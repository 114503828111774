import React from 'react';

import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import { RiLock2Fill } from 'react-icons/ri';

import { useTranslation } from 'services/localization';

type LockedButtonProps = {
	tooltipText?: string;
} & ButtonProps;

const LockedButton = (props: LockedButtonProps) => {
	const { t } = useTranslation();
	const { tooltipText, ...buttonProps } = props;

	return (
		<Tooltip
			title={
				tooltipText ??
				t('permissions.notPermitted', "You don't have the required permissions to do this")
			}
		>
			<Box
				display="inline-block"
				sx={{ position: 'relative', width: buttonProps.fullWidth ? '100%' : 'auto' }}
			>
				<Button {...buttonProps} disabled startIcon={<RiLock2Fill size={16} />} />
			</Box>
		</Tooltip>
	);
};

export default LockedButton;
