import { indexOf, sortBy } from 'lodash';

import { VariantProperty } from 'common/modules/inventory/types';
import { getProductsInPackage } from 'common/modules/products/utils';
import {
	AllPurchaseTypes,
	Languages,
	LocaleField,
	ProductApi,
	PurchaseType,
	PurchaseTypes,
} from 'common/types';
import { notNull, switchUnreachable } from 'common/utils/common';
import { getVariantName } from 'common/utils/productUtils';

import { BaseProductVariant, ProductVariant, StockSourceOption } from '../inventory/types';
import { getTranslation } from '../translations/utils';

export const getBaseVariant = (variant: ProductVariant): BaseProductVariant => {
	const { id, internalId, values, stockSources, sales, rentals } = variant;
	return {
		id,

		internalId,
		values,
		stockSources,
		sales,
		rentals,
	};
};

export const getProductVariantProperties = (product: ProductApi) => product.variants.properties;

export function getProductVariantPropertiesNames(product: ProductApi): LocaleField[];
export function getProductVariantPropertiesNames(
	properties: ProductApi['variants']['properties'],
): LocaleField[];
export function getProductVariantPropertiesNames(
	productOrProperties: ProductApi | ProductApi['variants']['properties'],
): LocaleField[] {
	const isProductApi = (
		obj: ProductApi | ProductApi['variants']['properties'],
	): obj is ProductApi => {
		return !!(obj as ProductApi).variants;
	};
	const properties = isProductApi(productOrProperties)
		? productOrProperties.variants?.properties
		: productOrProperties;
	return properties.map((p) => p.name);
}

export const getProductVariants = (product: ProductApi) => product?.variants?.options ?? [];

export const getVariantIds = (product: ProductApi) => getProductVariants(product).map((v) => v.id);

export const hasSingleVariant = (product: ProductApi) => getProductVariants(product).length === 1;

export const hasMultipleVariants = (product: ProductApi) => getProductVariants(product).length > 1;

export const getVariantValues = (variant: ProductVariant | BaseProductVariant) => variant.values;

// Fills product variant IDs with first ones if they are not found from selectedVariantIds
export const getAllProductVariantIds = (
	product: ProductApi,
	stockProducts: ProductApi[],
	selectedVariantIds: string[],
): string[] => {
	const productsInPackage = getProductsInPackage(product, stockProducts);
	const includedProducts = [product, ...productsInPackage];
	return includedProducts.map(
		(p) =>
			getProductVariants(p)
				.map((v) => v.id)
				.find((id) => selectedVariantIds.includes(id)) ?? getProductVariants(p)[0].id,
	);
};

export const getProductVariantName = (
	productName: string | null,
	variant: ProductVariant,
	language: Languages | 'def',
) => {
	const variantName = getVariantName({ variant, lang: language });
	if (!productName) return variantName;
	if (variantName === '') return productName;
	return productName + ' - ' + variantName;
};

export const getProductVariant = (variantId: string, product: ProductApi) =>
	getProductVariants(product).find((v) => v.id === variantId);

// We now only allow connecting to a single SKU, that's why we only get the first SKU
export const getVariantSkuOption = (variant: BaseProductVariant) => {
	const stockSources: StockSourceOption[][] = Object.values(variant.stockSources ?? {});
	const firstSkuOfFirstSource = !stockSources?.length ? null : stockSources[0][0] ?? null;
	return firstSkuOfFirstSource;
};

// We now only allow connecting to a single SKU, that's why we only get the first SKU ID
export const getVariantSkuId = (variant: BaseProductVariant): string | null => {
	return getVariantSkuOption(variant)?.skuId ?? null;
};

export const getVariantSkuIds = (variants: BaseProductVariant[]): string[] => {
	return variants.map((v) => getVariantSkuId(v)).filter(notNull);
};

export const getVariantsBySkuId = (variants: BaseProductVariant[], skuId: string) => {
	return variants.filter((v) => {
		const variantSkuId = getVariantSkuId(v);
		return variantSkuId === skuId;
	});
};

export const isVariantForSale = (variant: BaseProductVariant): boolean => {
	return variant.sales?.enabled ?? false;
};

export const isVariantForRent = (variant: BaseProductVariant): boolean => {
	return variant.rentals?.enabled ?? false;
};

export const isVariantForSubscriptions = (variant: BaseProductVariant): boolean => {
	return true; //TODO: Implement this later
};

export const isVariantAvailableForPurchaseType = (
	variant: BaseProductVariant,
	purchaseType: PurchaseType,
): boolean => {
	switch (purchaseType) {
		case PurchaseTypes.rental:
			return isVariantForRent(variant);
		case PurchaseTypes.subscription:
			return isVariantForSubscriptions(variant);
		case PurchaseTypes.sales:
			return isVariantForSale(variant);
		default:
			return switchUnreachable(purchaseType);
	}
};

export const getVariantPurchaseTypes = (variant: BaseProductVariant): PurchaseType[] => {
	return AllPurchaseTypes.filter((type) => isVariantAvailableForPurchaseType(variant, type));
};

export const getSortedVariantValues = (
	variant: BaseProductVariant | undefined,
	sortIds?: string[],
): LocaleField[] => {
	if (!variant) return [];
	return sortBy(Object.entries(variant.values), ([id]) => {
		const idx = indexOf(sortIds, id);
		return idx >= 0 ? idx : (sortIds ?? []).length + 1;
	}).map(([, locale]) => locale);
};

export const getFormattedVariantName = (
	variant: BaseProductVariant | undefined,
	language: Languages | 'def',
	separator?: string,
): string => {
	return getSortedVariantValues(variant)
		.map((value) => getTranslation(value, language))
		.join(separator ?? ', ');
};
