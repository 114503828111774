import { invert } from 'lodash';

import { Currency, PaymentMethod } from 'common/types/common';
import { notUndefined } from 'common/utils/common';

import {
	AdyenMethods,
	AutomaticDepositMethods,
	CARD_METHODS_BY_ONLINE_PROVIDER,
	DepositMethods,
	ExternalMethods,
	ManualDepositMethods,
	ManualMethods,
	ONLINE_PAYMENT_METHOD_LIMITATIONS,
	OnlineMethods,
	OnlineProviderMethods,
	PAYMENT_METHOD_TO_ADYEN_TYPE_MAP,
	StripeMethods,
	WalletMethods,
} from './constants';
import {
	AdyenMethodType,
	AdyenPaymentMethod,
	AutomaticDepositPaymentMethod,
	CardPaymentMethod,
	CustomManualPaymentMethodId,
	DepositPaymentMethod,
	NonWalletOnlineProviderPaymentMethod,
	OnlinePaymentMethod,
	OnlinePaymentMethodWithManual,
	OnlinePaymentProvider,
	ShopCustomOnlinePaymentMethodObject,
	ShopFixedOnlinePaymentMethodObject,
	ShopManualOnlinePaymentMethodObject,
	ShopOnlinePaymentMethod,
	ShopOnlinePaymentMethodObject,
	StripePaymentMethod,
	WalletPaymentMethod,
} from './types';

// Online provider methods

export const getOnlineProviderPaymentMethods = () => {
	return Object.values(OnlineProviderMethods);
};

export const isOnlineProviderPaymentMethod = (method: OnlinePaymentMethodWithManual) => {
	return getOnlineProviderPaymentMethods().some((m) => m === method);
};

export const getPaymentProviderPaymentMethodsWithoutWallets = (): NonWalletOnlineProviderPaymentMethod[] => {
	return Object.values(OnlineProviderMethods).filter(
		(m) => !isWalletPaymentMethod(m),
	) as NonWalletOnlineProviderPaymentMethod[];
};

// Online payment methods

export const getOnlinePaymentMethods = () => {
	return Object.values(OnlineMethods);
};

export const isOnlinePaymentMethod = (method: PaymentMethod): method is OnlinePaymentMethod => {
	return getOnlinePaymentMethods().includes(method as any);
};

// Adyen payment methods

export const getAdyenPaymentMethods = () => {
	return Object.values(AdyenMethods);
};

export const isAdyenPaymentMethod = (method: PaymentMethod): method is AdyenPaymentMethod => {
	return getAdyenPaymentMethods().includes(method as any);
};

export const getAdyenPaymentMethodsWithoutWallets = () => {
	return Object.values(AdyenMethods).filter((m) => !isWalletPaymentMethod(m));
};

export const isAdyenPaymentMethodWithoutWallets = (method: PaymentMethod) => {
	return getAdyenPaymentMethodsWithoutWallets().includes(method as any);
};

// Stripe payment methods

export const getStripePaymentMethods = () => {
	return Object.values(StripeMethods);
};

export const isStripePaymentMethod = (method: PaymentMethod): method is StripePaymentMethod => {
	return getStripePaymentMethods().includes(method as any);
};

// Manual payment methods

export const getManualPaymentMethods = () => {
	return Object.values(ManualMethods);
};

// Deposit payment methods

export const getManualDepositPaymentMethods = () => {
	return Object.values(ManualDepositMethods);
};

export const isManualDepositPaymentMethod = (method: OnlinePaymentMethod) => {
	return getManualDepositPaymentMethods().includes(method as any);
};

export const getAutomaticDepositPaymentMethods = () => {
	return Object.values(AutomaticDepositMethods);
};

export const isAutomaticDepositPaymentMethod = (
	method: OnlinePaymentMethodWithManual,
): method is AutomaticDepositPaymentMethod => {
	return getAutomaticDepositPaymentMethods().includes(method as any);
};

export const isStripeDepositPaymentMethod = (
	method: OnlinePaymentMethodWithManual,
): method is Extract<StripePaymentMethod, 'CARD_ONLINE_STRIPE'> => {
	return method === StripeMethods.CARD_ONLINE_STRIPE;
};

export const getDepositPaymentMethods = () => {
	return Object.values(DepositMethods);
};

export const isDepositPaymentMethod = (
	method: OnlinePaymentMethodWithManual,
): method is DepositPaymentMethod => {
	return getDepositPaymentMethods().includes(method as any);
};

export const isCustomShopPaymentMethodObject = (
	m: ShopFixedOnlinePaymentMethodObject | ShopCustomOnlinePaymentMethodObject,
): m is ShopCustomOnlinePaymentMethodObject => {
	return (
		(m as ShopCustomOnlinePaymentMethodObject).id === 'MANUAL' &&
		!!(m as ShopCustomOnlinePaymentMethodObject).details
	);
};

export const isManualShopPaymentMethodObject = (
	m: ShopOnlinePaymentMethodObject,
): m is ShopManualOnlinePaymentMethodObject => {
	return isCustomShopPaymentMethodObject(m) || m.id === 'PAY_STORE';
};

export const isPayStorePaymentMethod = (
	method: 'PAY_STORE' | ShopCustomOnlinePaymentMethodObject,
): method is 'PAY_STORE' => method === 'PAY_STORE';

export const getPaymentMethodId = (method: ShopOnlinePaymentMethodObject) => {
	return isCustomShopPaymentMethodObject(method) ? method.details.id : method.id;
};

export const getPaymentMethodTypeId = (method: ShopOnlinePaymentMethodObject) => {
	return method.id;
};

export const isPaymentMethodEnabled = (
	method: OnlinePaymentMethod | ShopOnlinePaymentMethodObject,
	shopMethods: ShopOnlinePaymentMethodObject[],
) => {
	if (method === 'APPLEPAY' || method === 'GOOGLEPAY') {
		return shopMethods.some((m) => m.id === 'CARD_ONLINE');
	}
	return shopMethods.some((shopPaymentMethod) => {
		if (typeof method === 'string') return shopPaymentMethod.id === method;
		if (
			isCustomShopPaymentMethodObject(method) &&
			isCustomShopPaymentMethodObject(shopPaymentMethod)
		) {
			return method.details.id === shopPaymentMethod.details.id;
		}
		if (
			!isCustomShopPaymentMethodObject(method) &&
			!isCustomShopPaymentMethodObject(shopPaymentMethod)
		) {
			return method.id === shopPaymentMethod.id;
		}
		return false;
	});
};

// External payment methods

export const getExternalPaymentMethods = () => {
	return Object.values(ExternalMethods);
};

// Wallet payment methods

export const getWalletPaymentMethods = () => {
	return Object.values(WalletMethods);
};

export const isWalletPaymentMethod = (method: PaymentMethod): method is WalletPaymentMethod => {
	return getWalletPaymentMethods().includes(method as any);
};

// Recurring payment methods

export const isRecurringPaymentMethod = (method: OnlinePaymentMethod) => {
	const methodLimitations = ONLINE_PAYMENT_METHOD_LIMITATIONS[method];
	return !!methodLimitations?.recurring;
};

export const needsExternalRedirect = (method: OnlinePaymentMethod) => {
	const methodLimitations = ONLINE_PAYMENT_METHOD_LIMITATIONS[method];
	return !!methodLimitations?.externalRedirect;
};

export const getAdyenPaymentMethodType = (method: PaymentMethod): AdyenMethodType | undefined => {
	return PAYMENT_METHOD_TO_ADYEN_TYPE_MAP[method];
};

export const getPaymentMethodFromAdyenType = (type: string): AdyenPaymentMethod | undefined => {
	return Object.entries(PAYMENT_METHOD_TO_ADYEN_TYPE_MAP).find(([_, val]) => val === type)?.[0] as
		| AdyenPaymentMethod
		| undefined;
};

export const getSortedPaymentMethods = (activePaymentMethods: ShopOnlinePaymentMethodObject[]) => {
	const preferredOrder: (ShopOnlinePaymentMethod | CustomManualPaymentMethodId)[] = [
		'CARD_ONLINE_STRIPE',
		'CARD_ONLINE',
		'TRUSTLY',
		'PAYTRAIL',
		'MOBILEPAY',
		'PAY_STORE',
		'MANUAL',
	];
	const sortedPaymentMethods: ShopOnlinePaymentMethodObject[] = [...activePaymentMethods];
	sortedPaymentMethods.sort(function (a, b) {
		const indexOfA = preferredOrder.indexOf(a.id);
		const indexOfB = preferredOrder.indexOf(b.id);
		if (indexOfA === -1) return 1;
		if (indexOfB === -1) return -1;
		return preferredOrder.indexOf(a.id) - preferredOrder.indexOf(b.id);
	});
	return sortedPaymentMethods;
};

export const getAdyenMethodTypes = (
	methodsResponse: ICheckout.PaymentMethodsResponse | null,
): string[] | null => {
	return methodsResponse?.paymentMethods?.map((method) => method.type).filter(notUndefined) ?? null;
};

interface ValidMethodsProps {
	shopMethods: ShopOnlinePaymentMethodObject[];
	adyenMethodTypes: { loading: true } | { loading: false; data: null | string[] };
	invalidMethodNames?: (OnlinePaymentMethod | CustomManualPaymentMethodId)[];
	currency: Currency;
	country: string | undefined;
	paymentIsInIframe: boolean;
	isCustomDomain: boolean;
	hasDeposit: boolean;
	hasSubscription?: boolean;
}

export const getValidPaymentMethods = (
	props: ValidMethodsProps,
): ShopOnlinePaymentMethodObject[] => {
	const {
		shopMethods,
		adyenMethodTypes,
		invalidMethodNames = [],
		hasDeposit,
		currency,
		country,
		paymentIsInIframe,
		isCustomDomain,
		hasSubscription,
	} = props;
	if (adyenMethodTypes.loading) {
		return [];
	}

	// Remove MobilePay temporarily because of Adyen authorisation issue
	const validShopMethodsWithoutMobilePay =
		shopMethods.length > 1 ? shopMethods.filter((m) => m.id !== 'MOBILEPAY') : shopMethods;

	return (
		validShopMethodsWithoutMobilePay
			// Remove methods that are not found from fetched adyen method types
			.filter((method) => {
				const adyenPaymentMethod = isAdyenPaymentMethod(method.id);
				const adyenType = getAdyenPaymentMethodType(method.id);
				return !adyenPaymentMethod || (adyenType && adyenMethodTypes.data?.includes(adyenType));
			})
			// Check payment method against recurring, currency or country limitation
			.filter((method) =>
				isMethodInLimits(method, {
					country,
					currency,
					paymentIsInIframe,
					hasDeposit,
					isCustomDomain,
					hasSubscription,
				}),
			)
			// Remove methods set as invalid methods
			.filter((method) => !invalidMethodNames.includes(method.id))
	);
};

export interface LimitationObject {
	recurringRequired?: boolean;
	country: string | undefined;
	currency: Currency;
	paymentIsInIframe: boolean;
	hasDeposit: boolean;
	isCustomDomain: boolean;
	hasSubscription?: boolean;
}

export const isMethodInLimits = (
	method: ShopOnlinePaymentMethodObject | OnlinePaymentMethod,
	{
		recurringRequired,
		country,
		currency,
		paymentIsInIframe,
		hasDeposit,
		isCustomDomain,
		hasSubscription,
	}: LimitationObject,
) => {
	const methodLimitations =
		typeof method === 'string'
			? ONLINE_PAYMENT_METHOD_LIMITATIONS[method]
			: ONLINE_PAYMENT_METHOD_LIMITATIONS[method.id];
	if (!methodLimitations) return false;
	if (recurringRequired && !methodLimitations.recurring) {
		return false;
	}
	if (methodLimitations.currencies && !methodLimitations.currencies.includes(currency)) {
		return false;
	}
	if (country && methodLimitations.countries && !methodLimitations.countries.includes(country)) {
		return false;
	}
	if (!!methodLimitations.disabledInIframe && paymentIsInIframe) {
		return false;
	}
	if (hasDeposit && !methodLimitations.deposits) {
		return false;
	}
	if (isCustomDomain && !!methodLimitations.rentleDomainOnly) {
		return false;
	}

	if (hasSubscription && !methodLimitations.subscriptions) {
		return false;
	}
	return true;
};

export const getPaymentMethodById = (
	paymentMethod: OnlinePaymentMethod | string,
	paymentMethods: ShopOnlinePaymentMethodObject[],
) => {
	return paymentMethods.find((method) => {
		if (isCustomShopPaymentMethodObject(method) && method.details.id === paymentMethod) {
			return true;
		}
		if (!isCustomShopPaymentMethodObject(method) && method.id === paymentMethod) {
			return true;
		}
		return false;
	});
};

export const shopHasPaymentMethod = (
	paymentMethod: OnlinePaymentMethod | string,
	paymentMethods: ShopOnlinePaymentMethodObject[] | OnlinePaymentMethod[],
): boolean => {
	for (const method of paymentMethods) {
		if (typeof method === 'string' && method === paymentMethod) {
			return true;
		}
		if (
			typeof method !== 'string' &&
			isCustomShopPaymentMethodObject(method) &&
			method.details.id === paymentMethod
		) {
			return true;
		}
		if (typeof method !== 'string' && method.id === paymentMethod) {
			return true;
		}
	}
	return false;
};

export const shopHasManualPaymentMethod = (
	manualPaymentMethodDetailId: string,
	paymentMethods: ShopOnlinePaymentMethodObject[],
): boolean => {
	return paymentMethods.some(
		(method) =>
			isCustomShopPaymentMethodObject(method) && method.details.id === manualPaymentMethodDetailId,
	);
};

export const shopHasAnyManualPaymentMethod = (
	activePaymentMethods: ShopOnlinePaymentMethodObject[],
) => {
	return activePaymentMethods.some((method) => isCustomShopPaymentMethodObject(method));
};

export const shopHasAdyenPayment = (activePaymentMethods: ShopOnlinePaymentMethodObject[]) => {
	return activePaymentMethods.some((method) => isAdyenPaymentMethod(method.id));
};

export const shopHasStripePayment = (activePaymentMethods: ShopOnlinePaymentMethodObject[]) => {
	return activePaymentMethods.some((method) => isStripePaymentMethod(method.id));
};

export const onlinePaymentMethodToShopOnlineMethodObject = (
	onlinePaymentMethod: OnlinePaymentMethod,
): ShopOnlinePaymentMethodObject => {
	if (onlinePaymentMethod === 'APPLEPAY' || onlinePaymentMethod === 'GOOGLEPAY') {
		return { id: 'CARD_ONLINE' };
	}
	return { id: onlinePaymentMethod };
};

export const getCardPaymentMethodForProvider = (provider: OnlinePaymentProvider) => {
	return CARD_METHODS_BY_ONLINE_PROVIDER[provider];
};

export const getProviderForCardPaymentMethod = (paymentMethod: CardPaymentMethod) => {
	return invert(CARD_METHODS_BY_ONLINE_PROVIDER)[paymentMethod];
};
