import React from 'react';

import { Box, Button, CircularProgress, Stack, styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import ReactDOM from 'react-dom';
import { Prompt } from 'react-router-dom';

import Container from 'components/Container';
import { useLayoutContext } from 'components/Layout/context';
import { useTranslation } from 'services/localization';
import { isRouteActive } from 'routing';

export interface SaveBannerProps {
	onSave: () => void;
	onDiscard: () => void;
	isActive?: boolean;
	isValid?: boolean;
	isLoading?: boolean;
	allowedRoutes?: string[];
	fluid?: boolean;
}

const SaveBanner = (props: SaveBannerProps) => {
	const { t } = useTranslation();
	const { onSave, onDiscard, isActive, isLoading, isValid = true, allowedRoutes, fluid } = props;
	const { sidebarWidth } = useLayoutContext();
	return (
		<>
			<Prompt
				when={isActive}
				message={(location) => {
					if (allowedRoutes?.some((route) => isRouteActive(route, location.pathname))) {
						return true;
					}
					return t('common:prompts.unsavedChanges');
				}}
			/>
			<AnimatePresence>
				{isActive && (
					<SaveBannerWrapper
						initial={{
							y: '-100%',
						}}
						animate={{
							y: 0,
						}}
						exit={{
							y: '-100%',
						}}
						transition={{ type: 'spring', duration: 0.3, bounce: 0 }}
						sx={{ paddingLeft: `${sidebarWidth}px` }}
					>
						<Container noVerticalPadding fluid={fluid} style={{ height: '100%' }}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								alignItems="center"
								height="100%"
								spacing={1}
							>
								<Button variant="white-text" onClick={onDiscard} disabled={isLoading}>
									{t('common:actions.discard', 'Discard')}
								</Button>
								<Button variant="white" onClick={onSave} disabled={isLoading || !isValid}>
									{isLoading ? (
										<CircularProgress size={20} color="inherit" />
									) : (
										t('common:actions.saveChanges', 'Save changes')
									)}
								</Button>
							</Stack>
						</Container>
					</SaveBannerWrapper>
				)}
			</AnimatePresence>
		</>
	);
};

const SaveBannerWrapper = styled(motion(Box))(({ theme }) => ({
	background: theme.palette.primary.dark,
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	zIndex: 2,
}));

const SaveBannerPortal = (props: SaveBannerProps) => {
	const { headerElement } = useLayoutContext();
	if (!headerElement) return null;
	return ReactDOM.createPortal(<SaveBanner {...props} />, headerElement);
};

export default SaveBannerPortal;
