import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { createBasicDbOperations } from '../utils';
import { DiscountCodeApi } from 'common/types';

export const getDiscountCodeQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.discountCodesCollection;
	return {
		...createBasicDbOperations<DiscountCodeApi>(collectionPath, dbRefs),
	};
};
