import React, { useCallback } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

import useTimeout from 'common/hooks/useTimeout';
import * as NotificationActions from 'actions/NotificationActions';
import { Notification as NotificationType } from 'actions/NotificationActions';
import * as NotificationSelectors from 'selectors/NotificationSelectors';

interface NotificationProps {
	notification: NotificationType;
}

const Notification = (props: NotificationProps) => {
	const dispatch = useDispatch();
	const { classes } = useStyles();
	const { notification } = props;

	const handleClose = useCallback(() => {
		dispatch(NotificationActions.dismissNotification(notification.id));
	}, [dispatch, notification.id]);

	const [cancelTimeout, resetTimeout] = useTimeout(
		handleClose,
		!notification.disableAutoDismiss ? notification.autoDismissMs ?? 10000 : null,
	);

	return (
		<Stack
			key={notification.id}
			direction="row"
			alignItems="center"
			justifyContent="space-between"
			className={classes.notification}
			color={notification.variant}
			data-variant={notification.variant}
			onMouseEnter={() => cancelTimeout()}
			onMouseLeave={!notification.disableAutoDismiss ? () => resetTimeout(5000) : undefined}
		>
			<Box className={classes.notificationContent}>
				{typeof notification.message === 'string' ? (
					<Typography className={classes.notificationMessage}>{notification.message}</Typography>
				) : (
					notification.message
				)}
			</Box>
			{!notification.hideDismiss && (
				<Box className={classes.notificationRight}>
					<IconButton
						size="small"
						className={classes.notificationClose}
						onClick={() => dispatch(NotificationActions.dismissNotification(notification.id))}
					>
						<RiCloseLine color="inherit" />
					</IconButton>
				</Box>
			)}
		</Stack>
	);
};

const NotificationManager = () => {
	const { classes } = useStyles();
	const notificationsToShow = useSelector(NotificationSelectors.notificationsToShow);

	return (
		<Box className={classes.wrapper}>
			<AnimatePresence>
				{notificationsToShow.map((notification) => (
					<motion.div
						key={notification.id}
						className={classes.notificationWrapper}
						initial={{ height: 0 }}
						animate={{ height: 'auto' }}
						exit={{ opacity: 0, height: 0, zIndex: 1 }}
					>
						<Notification notification={notification} />
					</motion.div>
				))}
			</AnimatePresence>
		</Box>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	wrapper: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		pointerEvents: 'none',
		zIndex: 100000000,
	},
	notificationWrapper: {
		position: 'relative',
		zIndex: 2,
		maxWidth: '100%',
	},
	notification: {
		marginBottom: theme.spacing(2),
		boxShadow: '0 1px 10px 0 rgba(0,0,0,0.35)',
		padding: theme.spacing(2),
		maxWidth: '100%',
		borderRadius: 4,
		zIndex: 1,
		pointerEvents: 'auto',
		transition: 'background 0.1s ease-in-out',
		'&[data-variant="success"]': {
			background: theme.palette.success.main,
			color: theme.palette.success.contrastText,
		},
		'&[data-variant="info"]': {
			background: theme.palette.info.darker,
			color: theme.palette.info.contrastText,
		},
		'&[data-variant="error"]': {
			background: theme.palette.error.main,
			color: theme.palette.error.contrastText,
		},
	},
	notificationMessage: {
		marginBottom: 0,
		fontSize: '1.4rem',
		fontWeight: 500,
		minWidth: '250px',
		maxWidth: '400px',
	},
	notificationLeft: {
		width: 30,
	},
	notificationContent: {
		flex: 1,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	notificationRight: {
		width: 30,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	notificationClose: {
		color: 'inherit',
	},
}));

export default NotificationManager;
