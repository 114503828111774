import { ViewAction } from 'actions';

import {
	getTimeDifferenceInSeconds,
	getTimeNearestToNextQuarterHour,
} from 'common/utils/dateCalculations';
import { RentalsTabValues, ViewState } from 'services/types';

const getInitialState = (): ViewState => {
	const startDate = getTimeNearestToNextQuarterHour().toISOString();
	const endDate = getTimeNearestToNextQuarterHour().add(1, 'hours').toISOString();
	return {
		sideBarOpen: false,
		payLinkInProgress: false,
		timePanelOpen: true,
		expandedRentalDialogPanel: '',
		imageUploading: false,
		userIsActive: true,
		startDate,
		endDate,
		duration: {
			durationType: '24h',
			durationName: null,
			durationInSeconds: getTimeDifferenceInSeconds(endDate, startDate),
		},
		rentNowToggle: true,
		mobileRentalSideBarOpen: false,
		showRentalViewFormErrors: false,
		calendarView: '',
		startPrint: false,
		rentalLinkSendingFailed: false,
		pendingRentalLinks: [],
		rentalsActiveTab: RentalsTabValues.BOOKED,
		showEarlyOrLatePickupDialog: false,
		upgradeModalOpen: false,
		bookingsViewLocationType: 'current',
		devPanelOpen: false,
		ordersViewLocationType: 'current',
		newClientUpdate: false,
		dataGridStates: {},
		installedAddOnModalState: {
			addOn: null,
			open: false,
		},
		addOnModalState: {
			addOn: null,
			addOnChannel: null,
			open: false,
		},
		authState: {
			isAuthenticated: false,
			loading: true,
		},
		earnCreditsModalOpen: false,
		switchToNewPlansModalOpen: false,
		growthbookInitialized: false,
	};
};

const INITIAL_STATE = getInitialState();

const ViewReducer = (state = INITIAL_STATE, action: ViewAction): ViewState => {
	switch (action.type) {
		case 'TOGGLE_SIDE_BAR':
			return { ...state, sideBarOpen: action.open };
		case 'UPDATE_PAYLINK_IN_PROGRESS':
			return { ...state, payLinkInProgress: action.inProgress };
		case 'TOGGLE_TIME_PANEL':
			return { ...state, timePanelOpen: !state.timePanelOpen };
		case 'UPDATE_EXPANDED_RENTAL_DIALOG_PANEL':
			return { ...state, expandedRentalDialogPanel: action.panel };
		case 'SET_IMAGE_UPLOADING':
			return { ...state, imageUploading: action.uploading };
		case 'SET_USER_ACTIVE':
			return { ...state, userIsActive: action.userIsActive };
		case 'SET_START_DATE':
			return {
				...state,
				startDate: action.date,
			};
		case 'SET_END_DATE':
			return {
				...state,
				endDate: action.date,
			};
		case 'SET_DURATION':
			return {
				...state,
				duration: action.duration,
			};
		case 'SET_RENT_NOW_TOGGLE':
			return {
				...state,
				rentNowToggle: action.value,
			};
		case 'TOGGLE_MOBILE_RENTAL_SIDE_BAR':
			return { ...state, mobileRentalSideBarOpen: action.open };
		case 'SHOW_RENTAL_VIEW_FORM_ERRORS':
			return { ...state, showRentalViewFormErrors: action.show };
		case 'SHOW_EARLY_OR_LATE_PICKUP_DIALOG':
			return { ...state, showEarlyOrLatePickupDialog: action.show };
		case 'SET_CALENDARVIEW':
			return { ...state, calendarView: action.calendarView };
		case 'SET_START_PRINT':
			return { ...state, startPrint: action.startPrint };
		case 'SET_RENTALLINK_SENDING_FAILED':
			return { ...state, rentalLinkSendingFailed: action.failed };
		case 'SET_PENDING_RENTALLINKS':
			return { ...state, pendingRentalLinks: action.rentalLinks };
		case 'SET_RENTALS_ACTIVE_TAB':
			return { ...state, rentalsActiveTab: action.rentalsActiveTab };
		case 'View/SET_BOOKINGS_VIEW_LOCATION_TYPE':
			return { ...state, bookingsViewLocationType: action.payload };
		case 'View/SET_DEV_PANEL_OPEN':
			return { ...state, devPanelOpen: action.payload };
		case 'View/SET_ORDERS_VIEW_LOCATION_TYPE':
			return { ...state, ordersViewLocationType: action.payload };
		case 'View/TOGGLE_UPGRADE_MODAL':
			return { ...state, upgradeModalOpen: action.payload };
		case 'View/SET_NEW_CLIENT_UPDATE':
			return { ...state, newClientUpdate: action.payload };
		case 'View/SET_DATAGRID_STATE':
			return {
				...state,
				dataGridStates: {
					...state.dataGridStates,
					[action.payload.key]: action.payload.state,
				},
			};
		case 'View/OPEN_INSTALLED_ADD_ON_MODAL': {
			return { ...state, installedAddOnModalState: { addOn: action.payload, open: true } };
		}
		case 'View/CLOSE_INSTALLED_ADD_ON_MODAL': {
			return {
				...state,
				installedAddOnModalState: { ...state.installedAddOnModalState, open: false },
			};
		}
		case 'View/RESET_INSTALLED_ADD_ON_MODAL': {
			return {
				...state,
				installedAddOnModalState: INITIAL_STATE.installedAddOnModalState,
			};
		}
		case 'View/OPEN_ADD_ON_MODAL':
			return {
				...state,
				addOnModalState: {
					addOn: action.payload.addOn,
					addOnChannel: action.payload.addOnChannel,
					nextRoute: action.payload.nextRoute,
					open: true,
				},
			};
		case 'View/CLOSE_ADD_ON_MODAL':
			return { ...state, addOnModalState: { ...state.addOnModalState, open: false } };
		case 'View/RESET_ADD_ON_MODAL':
			return { ...state, addOnModalState: INITIAL_STATE.addOnModalState };
		case 'View/SET_AUTH_STATE': {
			return { ...state, authState: action.payload };
		}
		case 'View/TOGGLE_EARN_CREDITS_MODAL':
			return {
				...state,
				earnCreditsModalOpen: action.payload,
			};
		case 'View/TOGGLE_SWITCH_TO_NEW_PLANS_MODAL':
			return {
				...state,
				switchToNewPlansModalOpen: action.payload,
			};
		case 'View/SET_GROWTHBOOK_INITIALIZED':
			return {
				...state,
				growthbookInitialized: action.payload,
			};
		default:
			return state;
	}
};

export default ViewReducer;
