import { Box, Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import moment from 'moment-timezone';

import { Listing } from '@rentle/resell-api-client';
import { DateFormatObject } from 'common/types';
import { getPricingString } from 'common/utils/common';
import { localFormat } from 'common/utils/dateUtils';

import ListingStatusChip from './ListingStatusChip';

type ListingsTableColumnDef = GridColDef & {
	field: keyof Listing;
	renderCell?: (params: GridRenderCellParams<any, Listing>) => React.ReactNode;
};

export const getListingsTableColumns = (args: { dateFormat: DateFormatObject; t: TFunction }) => {
	const { dateFormat } = args;

	const columns: ListingsTableColumnDef[] = [
		{
			field: 'title',
			headerName: 'Listing',
			minWidth: 500,
			renderCell: ({ row }) => {
				return (
					<Stack spacing={1} direction="row" alignItems="center">
						<Box>
							<img
								style={{ objectFit: 'cover', height: '40px', width: '40px', borderRadius: '4px' }}
								src={row.imageURLs?.[0]}
								alt={row.title}
							/>
						</Box>
						<Stack>
							<Typography sx={{ fontWeight: 500 }}>{row.title}</Typography>
						</Stack>
					</Stack>
				);
			},
		},
		{
			field: 'status',
			headerName: 'Status',
			minWidth: 150,
			renderCell: ({ row }) => {
				return <ListingStatusChip status={row.status} />;
			},
		},
		{
			field: 'price',
			headerName: 'Price',
			minWidth: 50,
			valueFormatter: ({ value }) => getPricingString(value),
		},
		{
			field: 'createdAt',
			headerName: 'Created',
			minWidth: 50,
			valueFormatter: ({ value }) =>
				localFormat(moment(value).toISOString(), 'DD.MM.YYYY', dateFormat),
		},
		{
			field: 'updatedAt',
			headerName: 'Updated',
			minWidth: 50,
			valueFormatter: ({ value }) =>
				localFormat(moment(value).toISOString(), 'DD.MM.YYYY', dateFormat),
		},
	];
	return columns;
};
