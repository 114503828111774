import React, { useState } from 'react';

import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

import { queries } from 'common/frontend/queries';
import { isLegacyWebhookApi } from 'common/models/Webhook/utils';
import { DEPRECATED_WEBHOOKS_GUIDE } from 'common/modules/support/articles';
import { hasHigherOrEqualAccess } from 'common/modules/users/utils';
import { StorageKeys, Storages, getFromStorage, saveToStorage } from 'common/utils/frontUtils';
import * as UserSelectors from 'selectors/UserSelectors';
import { useTranslation } from 'services/localization';
import { Routes, push } from 'routing';

import Banner from './Banner';

interface Props {
	renderCta: 'link_webhooks' | 'link_external_knowledge_base';
	renderCloseBtn: boolean;
	ignoreHiddenByUser?: boolean;
}

const DeprecatedWebhooksBanner = (props: Props) => {
	const { t } = useTranslation();
	const userRoles = useSelector(UserSelectors.userRoles);
	const isManagerOrAbove = hasHigherOrEqualAccess(userRoles, ['manager']);
	const { data } = queries.webhooks.useWebhooksList(
		{
			limit: 50,
		},
		{ enabled: isManagerOrAbove },
	);
	const hasDeprecatedWebhooks = data?.pages.some((page) =>
		page.data.some((webhook) => isLegacyWebhookApi(webhook)),
	);

	const getIsBannerHiddenByUser = () => {
		if (props.ignoreHiddenByUser) return false;
		const hideBannerExpirationTs = getFromStorage(
			Storages.LOCAL,
			StorageKeys.HIDE_WEBHOOK_DEPRECATION_EXPIRE_TIMESTAMP,
		);
		if (!hideBannerExpirationTs) return false;
		if (
			moment(hideBannerExpirationTs).isValid() &&
			moment(hideBannerExpirationTs).isAfter(moment())
		) {
			return true;
		}
		return false;
	};

	const [isBannerHiddenByUser, setIsBannerHiddenByUser] = useState<boolean>(
		getIsBannerHiddenByUser(),
	);

	const visible = hasDeprecatedWebhooks && !isBannerHiddenByUser;

	if (!visible) return null;

	const hideBanner = () => {
		const hideBannerExpiration = moment().add(1, 'week').toISOString();
		saveToStorage(
			Storages.LOCAL,
			StorageKeys.HIDE_WEBHOOK_DEPRECATION_EXPIRE_TIMESTAMP,
			hideBannerExpiration,
		);
		setIsBannerHiddenByUser(true);
	};

	return (
		<Banner
			variant="warning"
			title={t('webhooksDeprecation.bannerTitle', 'You have deprecated webhooks in use')}
			description={t(
				'webhooksDeprecation.bannerDescription',
				'Some of your webhooks are using an old Rentle API that is no longer maintained. Please update your old webhooks to ensure they keep working.',
			)}
			button={
				props.renderCta === 'link_webhooks'
					? {
							text: t('webhooksDeprecation.bannerCta', 'Go to Webhooks'),
							onClick: () => push(`${Routes.ACCOUNT_INTEGRATIONS}#webhooks`),
					  }
					: props.renderCta === 'link_external_knowledge_base'
					? {
							text: t('webhooksDeprecation.readMore', 'Read more'),
							onClick: () => window.open(DEPRECATED_WEBHOOKS_GUIDE, '_blank'),
					  }
					: undefined
			}
			onClose={props.renderCloseBtn ? hideBanner : undefined}
		/>
	);
};

export default DeprecatedWebhooksBanner;
