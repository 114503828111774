import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { Routes } from 'routing';

import GetStarted from './GetStarted';
import Register from './Register';
import { RegisterContextProvider, getInitialState } from './RegisterContext';

const RegisterRouter = () => {
	return (
		<RegisterContextProvider initialState={getInitialState()}>
			<Switch>
				<Route exact path={Routes.REGISTER} component={Register} />
				<Route exact path={Routes.REGISTER_GET_STARTED} component={GetStarted} />
				<Redirect to={Routes.REGISTER} />
			</Switch>
		</RegisterContextProvider>
	);
};

export default RegisterRouter;
