import * as dbPath from 'common/api/db/paths';
import { DbRefs } from 'common/db/api/types';
import { AppmixerUser } from 'common/modules/appmixer/types';

import { createBasicDbOperations } from '../utils';

export const getAppmixerUsersQueries = (dbRefs: DbRefs) => {
	const collectionPath = dbPath.appmixerUsers;
	const dataUpdater = undefined;
	return {
		...createBasicDbOperations<AppmixerUser>(collectionPath, dbRefs, dataUpdater),
	};
};
