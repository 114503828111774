import { CustomParams } from 'common/frontend/firebase/analytics';
import { isDevEnv, switchUnreachable } from 'common/utils/common';

import errorHandler from '../errorHandling/errorHandler';

declare var gtag: any;

export interface PageViewOptions {
	page_title?: string;
	page_location?: string;
	page_path: string;
}

export const sendPageView = (options: PageViewOptions & { send_to: string | string[] }) => {
	if (gtag === undefined) {
		errorHandler.report('Gtag not defined');
		return;
	}
	gtag('event', 'page_view', options);
};

export const sendEvent = (event: string, options?: object) => {
	if (gtag === undefined) {
		errorHandler.report('Gtag not defined');
		return;
	}
	gtag('event', event, options);
};

export const setUserId = (uid: string | null) => {
	if (gtag === undefined) {
		errorHandler.report('Gtag not defined');
		return;
	}
	gtag('set', { user_id: uid });
};

export const setUserProperties = (params: CustomParams) => {
	if (gtag === undefined) {
		errorHandler.report('Gtag not defined');
		return;
	}
	gtag('set', { user_properties: params });
};

type AdEvent = 'lite_signup_conversion';

export const sendAdConversionEvent = (event: AdEvent) => {
	const GTAG_AD_ID_PRODUCTION = 'AW-808000674';
	let sendTo = '';
	switch (event) {
		case 'lite_signup_conversion':
			sendTo = `${GTAG_AD_ID_PRODUCTION}/DBVmCKT6vdUBEKK5pIED`;
			break;
		default:
			switchUnreachable(event);
			break;
	}
	if (isDevEnv() || !sendTo) return;
	sendEvent('conversion', { send_to: sendTo });
};
