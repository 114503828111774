import { zItemTypeApi } from './api';
import { toApi, toDb } from './mapper';

export * from './types';

export const ItemType = {
	toApi,
	toDb,
	api: zItemTypeApi,
};
