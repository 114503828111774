import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { getClientEnv } from 'common/utils/frontUtils';

const env = getClientEnv();
const tracesSampleRate = env === 'production' ? 10 : 100;
const sessionsSampleRate = env === 'production' ? 10 : 100;
const datadogApplicationId = 'd0083692-bcc7-456d-8090-85f0a572aedf';
const datadogClientToken = 'pub99e3750beb50253d6d3f1c216b5151bd';
const datadogSite = 'datadoghq.eu';
const datadogService = 'admin';

if (!!env && env !== 'local') {
	initDatadogLogging();
	initDatadogRum();
}

function initDatadogLogging() {
	datadogLogs.init({
		clientToken: datadogClientToken,
		site: datadogSite,
		service: datadogService,
		env,
		// Specify a version number if you want to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		silentMultipleInit: true,
		forwardErrorsToLogs: true,
		forwardConsoleLogs: env === 'development' ? ['debug'] : [],
		sessionSampleRate: sessionsSampleRate,
	});
}

function initDatadogRum() {
	datadogRum.init({
		applicationId: datadogApplicationId,
		clientToken: datadogClientToken,
		site: datadogSite,
		service: datadogService,
		env,
		// Specify a version number if you want to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: sessionsSampleRate,
		sessionReplaySampleRate: 0,
		trackUserInteractions: true,
		trackFrustrations: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask',
		silentMultipleInit: true,
		/**
		 * Match all strings that contain 'api.rentle.io' or 'rentle-*.cloudfunctions.net'
		 * This is to allow tracing of all requests to our Core API and Cloud Functions
		 */
		allowedTracingUrls: [(url) => url.includes('api.rentle.io'), /rentle[-].*\.cloudfunctions.net/],
		traceSampleRate: tracesSampleRate,
	});
}
