import { combineReducers } from 'redux';

import ActiveDiscountReducer from './ActiveDiscountReducer';
import ActiveProductReducer from './ActiveProductReducer';
import CustomersReducer from './CustomersReducer';
import LangReducer from './LangReducer';
import NewRentalReducer from './NewRentalReducer';
import NotificationReducer from './NotificationReducer';
import OrdersReducer from './OrdersReducer';
import RentalViewReducer from './RentalViewReducer';
import ShopReducer from './ShopReducer';
import StockReducer from './StockReducer';
import StoreRentalsReducer from './StoreRentalsReducer';
import TranslationsReducer from './TranslationsReducer';
import UserReducer from './UserReducer';
import ViewReducer from './ViewReducer';

const appReducer = combineReducers({
	user: UserReducer,
	shop: ShopReducer,
	stock: StockReducer,
	lang: LangReducer,
	newRental: NewRentalReducer,
	view: ViewReducer,
	storeRentals: StoreRentalsReducer,
	rentalView: RentalViewReducer,
	activeProduct: ActiveProductReducer,
	activeDiscount: ActiveDiscountReducer,
	orders: OrdersReducer,
	notifications: NotificationReducer,
	translations: TranslationsReducer,
	customers: CustomersReducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === 'USER_LOGOUT') {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
