import { Route, RouteArguments } from './types';

const buildPath = (route: Route, args: RouteArguments): string => {
	return Object.keys(args).reduce((result, argument) => {
		const value = args[argument];
		return value !== undefined ? result.replace(`:${argument}`, value) : result;
	}, route);
};

export default buildPath;
