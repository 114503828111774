import moment from 'moment-timezone';

import { SkidataErrors, USER_DIDNT_INPUT_KEYCARD } from 'common/constants/skidataErrors';
import { getItemsPayments } from 'common/modules/atoms/paymentAmounts';
import {
	DTAContactImageData,
	DTAImageTypes,
	DTAOrderStatus,
	DTAOrderStatuses,
	PhotoUploaded,
	SkidataKeycardType,
	SkidataTicketTimePeriods,
} from 'common/modules/skidata';
import {
	CartProduct,
	Category,
	OrderInfo,
	OrderProduct,
	OrderProductWithUnits,
	ProductApi,
	SetProduct,
	Shopper,
} from 'common/types';
import { notUndefined } from 'common/utils/common';

import { BaseProductVariant } from '../inventory';

export const getTicketVariant = (ticket: ProductApi | null, withNewKeycard: boolean) =>
	ticket?.variants.options.find((variant) =>
		withNewKeycard ? variantHasNewKeycard(variant) : !variantHasNewKeycard(variant),
	);

export const variantHasNewKeycard = (variant: BaseProductVariant): boolean =>
	variant?.internalId === SkidataKeycardType.NEW;

export const productHasNewKeycard = (product: OrderProduct | CartProduct): boolean =>
	product.external && product.variant ? variantHasNewKeycard(product.variant) : false;

export const productIsExistingSkipass = (product: OrderProduct | CartProduct): boolean =>
	product.external && product.variant
		? product.variant?.internalId === SkidataKeycardType.EXISTING
		: false;

export const productsIncludeExistingSkipass = (products: OrderProduct[]): boolean =>
	products.some(productIsExistingSkipass);

export const filterExistingSkipassProducts = (products: OrderProduct[]): OrderProduct[] =>
	products.filter(productIsExistingSkipass);

export const isLiftTicketProduct = (
	product: ProductApi | OrderProduct | OrderProductWithUnits | SetProduct,
) => product.external === 'LIFT_TICKET';

export const isLiftTicketPackageProduct = (product: ProductApi) =>
	isLiftTicketProduct(product) && product.set === true;

export const isLiftTicketPackageOrderProduct = (product: CartProduct | OrderProduct) =>
	isLiftTicketProduct(product) && !!product.packageId;

export const isNormalLiftTicketProduct = (product: ProductApi) =>
	isLiftTicketProduct(product) && !isLiftTicketPackageProduct(product);

export const isLiftTicketCategory = (category: Category) => category.external === 'LIFT_TICKET';

export const findProductKeycard = <T extends OrderProduct | OrderProductWithUnits>(
	products: T[],
	rentedTicketId: string,
): T | undefined => products.find((product) => product.ticketId === rentedTicketId);

export const isKeycardProduct = (product: ProductApi | OrderProduct | CartProduct) =>
	Boolean(product.isKeycard);

export const findKeycardStockProduct = (products: ProductApi[]): ProductApi | undefined =>
	products.find(isKeycardProduct);

export const hasLiftTicketProducts = (products: ProductApi[] | OrderProduct[]) =>
	products.some(isLiftTicketProduct);

export const hasOnlyLiftTicketProducts = (products: ProductApi[] | OrderProduct[]) =>
	(products as any[]).every(isLiftTicketProduct);

export const getTicketPrice = (ticket: ProductApi, segmentId: string) =>
	ticket.segmentPricings!.find((pricing) => pricing.segments.includes(segmentId))!.fixedPrice;

export const getKeycardTypeFromProducts = (products: OrderProduct[] | CartProduct[]) =>
	products.some((p: OrderProduct | CartProduct) => p.variant && variantHasNewKeycard(p.variant))
		? SkidataKeycardType.NEW
		: SkidataKeycardType.EXISTING;

export const validKeycardChipIds = ['01', '30', '25'];

const KASURILA = 'JpsbzD3JjafwqH8mYwIq';
const SUOMU = '0kUF2CvWHRHRpJnsXHI7';
const RUKA = '7qBP2TyP5ttAtXI6GV81';
const PYHA = 'FrGz39HdE4LGY0JnGT3j';

//HOTFIX, needs more scalable solution
export const isCustomKeycardShop = (shopId: string | undefined) =>
	!!shopId && [KASURILA, SUOMU, RUKA, PYHA].includes(shopId);
//HOTFIX, needs more scalable solution
export const getCustomValidKeycards = (shopId: string) => {
	switch (shopId) {
		case KASURILA:
			return ['01', '25'];
		case SUOMU:
		case RUKA:
		case PYHA:
			return ['01', '30'];
		default:
			return ['01', '30', '25'];
	}
};
//HOTFIX, needs more scalable solution
export const isValidCustomKeycardChip = (
	validCustomKeycardChipIds: string[],
	serialNumber: string,
) => {
	const [chipId] = serialNumber.split('-');
	return validCustomKeycardChipIds.includes(chipId);
};

export const isAcceptableKeycardNumber = (product: OrderProduct) => !!product.liftKeycardNumber;

export const isValidKeycardFormat = (serialNumber: string) => {
	const [chipId, serial, luhn] = serialNumber.split('-');
	return !!chipId && !!serial && !!luhn;
};

export const isValidKeycardChip = (serialNumber: string) => {
	const [chipId] = serialNumber.split('-');
	return validKeycardChipIds.includes(chipId);
};

export const luhnValidate = (serialNumber: string) => {
	const code = serialNumber.replace(/-/g, '');
	const len = code.length;
	const parity = len % 2;

	let sum = 0;
	for (let i = len - 1; i >= 0; i--) {
		let d = parseInt(code.charAt(i));
		if (i % 2 === parity) {
			d *= 2;
		}
		if (d > 9) {
			d -= 9;
		}
		sum += d;
	}
	return sum % 10 === 0;
};

export const isValidKeycardNumber = (product: OrderProduct) =>
	!!product.liftKeycardNumber &&
	product.liftKeycardNumber !== USER_DIDNT_INPUT_KEYCARD &&
	isValidKeycardChip(product.liftKeycardNumber) &&
	isValidKeycardFormat(product.liftKeycardNumber) &&
	luhnValidate(product.liftKeycardNumber);

export const getKeycardNumber = (product: OrderProduct) =>
	isValidKeycardNumber(product) ? product.liftKeycardNumber ?? '' : '';

export const hasMissingKeycardNumbers = (products: OrderProduct[]) =>
	filterExistingSkipassProducts(products).some((p) => !isValidKeycardNumber(p));

export const hasNewKeycards = (products: OrderProduct[]) => products.some(productHasNewKeycard);

export const keycardNumberAlreadyUsedInOrder = (
	serialNumber: string,
	ticketProducts: OrderProduct[],
) => {
	const existingKeycardNumbers = ticketProducts
		.map((t) => t.liftKeycardNumber)
		.filter(notUndefined);
	return existingKeycardNumbers.includes(serialNumber);
};

export const hasSkidataConfirmationNumber = (rentalInfo: OrderInfo) =>
	!!rentalInfo.skidataProps?.confirmationNumber;

export const hasReservedDTAOrderStatus = (orderStatus?: DTAOrderStatus) =>
	!!orderStatus && orderStatus !== DTAOrderStatuses.BOOKED_AND_TRANSFERRED;

export const hasMissingKeycardsDTAOrderStatus = (rentalInfo: OrderInfo) =>
	rentalInfo.skidataProps?.DTAOrderStatus === DTAOrderStatuses.MISSING_KEYCARDS;

export const dateToMorning = (date: string, tz: string) =>
	moment.tz(date, tz).utc(true).hour(0).add(4, 'hours').toISOString();

export const getTotalToPay = (ticketProduct: OrderProduct) =>
	getItemsPayments(ticketProduct).charge.total;

export const getShopperContactId = (shopper: Shopper): string | null =>
	shopper.userProperties.skidataContactId?.value ?? null;

export const ticketHasPhotoRequired = (product: OrderProduct) =>
	isLiftTicketProduct(product) &&
	!isKeycardProduct(product) &&
	!!product.skidataProperties?.requirePhoto;

export const productsHasPhotoRequired = (products: OrderProduct[]) =>
	products.some(ticketHasPhotoRequired);

export const shoppersPhotoUploadIncomplete = (shoppers: Shopper[], products: OrderProduct[]) => {
	const requiredPhotoAmount = products.filter(ticketHasPhotoRequired).length;
	const uploadedPhotoAmount = shoppers.filter((shopper) => {
		const photoUploaded = shopper.skidataProperties?.photoUploaded;
		return (
			photoUploaded &&
			(photoUploaded === PhotoUploaded.UPLOADED ||
				photoUploaded === PhotoUploaded.USER_DIDNT_INPUT_PHOTO)
		);
	}).length;
	return uploadedPhotoAmount !== requiredPhotoAmount;
};

const allRequiredPhotosUploaded = (shoppers: Shopper[], requiredAmount: number) => {
	const uploadedAmount = shoppers.filter((shopper) => {
		const photoUploaded = shopper.skidataProperties?.photoUploaded;
		return photoUploaded && photoUploaded === PhotoUploaded.UPLOADED;
	}).length;
	return uploadedAmount === requiredAmount;
};

export const shoppersMissingDTAImages = (shoppers: Shopper[], products: OrderProduct[]) => {
	const requiredPhotoAmount = products.filter(ticketHasPhotoRequired).length;
	return !allRequiredPhotosUploaded(shoppers, requiredPhotoAmount);
};

export const getDTAContactImageData = (blob: Blob): Promise<DTAContactImageData> => {
	const imageType = blob.type.match(/image\/jpeg/)
		? DTAImageTypes.Jpeg
		: blob.type.match(/image\/bmp/)
		? DTAImageTypes.Bitmap
		: null;
	const length = blob.size.toString();
	return new Promise((resolve, reject) => {
		if (!imageType || !length) {
			reject(SkidataErrors.LOAD_PHOTO_ERROR);
			return;
		}
		const image = new Image();
		image.onload = () => {
			resolve({
				height: image.height.toString(),
				width: image.width.toString(),
				data: image.src.split(',')[1],
				imageType,
				length,
			});
		};
		const reader = new FileReader();
		reader.onload = (evt) => {
			const src = evt.target?.result;
			if (src) {
				image.src = src as string;
			} else {
				reject(SkidataErrors.LOAD_PHOTO_ERROR);
			}
		};
		reader.readAsDataURL(blob);
	});
};

export const getDataURLFromDTAContactImageData = (image: DTAContactImageData) => {
	const { imageType, data } = image;
	const mime = imageType === DTAImageTypes.Jpeg ? 'image/jpeg' : 'image/bmp';
	return `data:${mime};base64,${data}`;
};

export const getTicketValidFrom = (ticketProduct: OrderProduct, startDate: string) => {
	const validity = ticketProduct.skidataProperties?.validity;
	if (!validity) return undefined;

	const { type, from } = validity;

	if (type === SkidataTicketTimePeriods['ns3:DailyTimePeriodSeries']) {
		const date = moment(startDate).format('YYYY-MM-DD');
		const [, hourWithTz] = from.split('T');
		return moment(`${date}T${hourWithTz}`).toISOString();
	}

	return undefined;
};

export const updateDTAOrderStatus = (rentalInfo: OrderInfo, newStatus: DTAOrderStatuses) => ({
	...rentalInfo,
	...(!!rentalInfo.skidataProps && {
		skidataProps: {
			...rentalInfo.skidataProps,
			DTAOrderStatus: newStatus,
		},
	}),
});
