import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.branding.name', 'Branding'),
	subHeader: t(
		'common:blocks.details.branding.subHeader',
		'Bring brand visibility to your channels',
	),
	description: t(
		'common:blocks.details.branding.description',
		'Edit the look and feel of your online store and check-in kiosk',
	),
	supportArticle: SupportArticles.BRANDING,
	uninstallNotice: t(
		'common:blocks.details.branding.uninstallNotice',
		'If you uninstall Branding, all branding settings will be reset to their defaults',
	),
});

export default details;
