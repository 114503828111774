import React from 'react';

import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import Container from 'components/Container';

interface Props {
	title: string;
	description?: string;
	buttons?: ButtonProps[];
}
const ErrorContainer = (props: Props) => {
	const { classes } = useStyles();

	return (
		<Container>
			<Box p={3} className={classes.wrapper}>
				<Typography variant="h6">{props.title}</Typography>
				{props.description && (
					<Box>
						<Typography variant="body1">{props.description}</Typography>
					</Box>
				)}
				{props.buttons && (
					<Box className={classes.buttons}>
						{props.buttons.map((buttonProps) => (
							<Button {...buttonProps} />
						))}
					</Box>
				)}
			</Box>
		</Container>
	);
};

const useStyles = makeStyles()((theme: Theme) => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginTop: theme.spacing(4),
		'& > *': {
			marginBottom: theme.spacing(1),
			marginLeft: theme.spacing(0.5),
			marginRight: theme.spacing(0.5),
		},
	},
}));

export default ErrorContainer;
