import { api } from 'common/frontend/api';
import { BatchManager } from 'common/frontend/firebase/firestore';
import { Carrier, DeliveryOption, ProductApi } from 'common/types';
import { newFirestoreId } from 'common/utils/dataMigrations';

export const updateDeliveryOption = async (
	partialDeliveryOption: Partial<DeliveryOption>,
	products: ProductApi[],
	deliveryOptionId: string,
	oldProducts: ProductApi[],
) => {
	await api().deliveryOptions.doc(deliveryOptionId).update(partialDeliveryOption);

	const batchManager = new BatchManager();
	const removedDeliveryOptionProducts = oldProducts.filter(
		(product) => !products.find((p) => p.id === product.id),
	);
	const newAddedDeliveryOptionProducts: ProductApi[] = products.filter(
		(product) => !oldProducts.find((p) => p.id === product.id),
	);
	removedDeliveryOptionProducts.forEach((product) => {
		api(batchManager.batch())
			.products.doc(product.id)
			.update({
				deliveryOptionIds: (product.deliveryOptionIds ?? []).filter(
					(id) => id !== deliveryOptionId,
				),
			});
	});
	newAddedDeliveryOptionProducts.forEach((product) => {
		api(batchManager.batch())
			.products.doc(product.id)
			.update({
				deliveryOptionIds: [...new Set([...(product.deliveryOptionIds ?? []), deliveryOptionId])],
			});
	});
	await batchManager.commit();
};

export const createDeliveryOption = async (
	deliveryOption: Omit<DeliveryOption, 'id'>,
	products: ProductApi[],
) => {
	const batchManager = new BatchManager();
	const deliveryOptionId = newFirestoreId();
	await api()
		.deliveryOptions.doc(deliveryOptionId)
		.set({ ...deliveryOption, id: deliveryOptionId });

	products.forEach((product) => {
		api(batchManager.batch())
			.products.doc(product.id)
			.update({
				deliveryOptionIds: [...new Set([...(product.deliveryOptionIds ?? []), deliveryOptionId])],
			});
	});
	await batchManager.commit();
};

export const removeDeliveryOption = async (deliveryOptionId: string, products: ProductApi[]) => {
	await api().deliveryOptions.doc(deliveryOptionId).delete();
	const batchManager = new BatchManager();
	products.forEach((product) => {
		api(batchManager.batch())
			.products.doc(product.id)
			.update({
				deliveryOptionIds: (product.deliveryOptionIds ?? []).filter(
					(id) => id !== deliveryOptionId,
				),
			});
	});
	await batchManager.commit();
};

export const hideDeliveryOption = async (deliveryOptionId: string, hidden: boolean) => {
	await api().deliveryOptions.doc(deliveryOptionId).update({ hidden });
};

export const updateCarrier = async (partialCarrier: Partial<Carrier>, carrierId: string) => {
	await api().carriers.doc(carrierId).update(partialCarrier);
};

export const createCarrier = async (carrier: Omit<Carrier, 'id'>) => {
	const carrierId = newFirestoreId();

	await api()
		.carriers.doc(carrierId)
		.set({ ...carrier, id: carrierId });
};

export const removeCarrier = async (carrierId: string) => {
	await api().carriers.doc(carrierId).delete();
};
