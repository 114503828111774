import { CapabilityOptionName } from 'common/services/analytics/tractionAnalytics';
import { ByIndustry, Languages } from 'common/types';
import { ShopIntegrationData } from 'common/types/integrations';

import { CallableCreator } from '..';

export interface ApplyTemplateToShopArgs {
	templateId: string;
	shopId: string;
	defaultLanguage: Languages;
	applyTheme?: boolean;
	dryRun?: boolean;
	pricingOptions?: CapabilityOptionName[];
}

export interface RemoveIntegrationArgs {
	merchantId: string;
	integration: keyof ShopIntegrationData;
}

export const admin = (createCallable: CallableCreator) => ({
	applyTemplateToShop: createCallable<ApplyTemplateToShopArgs, void>('admin-applyTemplateToShop'),
	getTemplateIdsByIndustry: createCallable<void, ByIndustry<string[]>>(
		'admin-getTemplateIdsByIndustry',
	),
	updateTemplateIdsByIndustry: createCallable<ByIndustry<string[]>, void>(
		'admin-updateTemplateIdsByIndustry',
	),
	removeIntegration: createCallable<RemoveIntegrationArgs, void>('admin-removeIntegration'),
});
