import React from 'react';

import { MenuItem, TextField, TextFieldProps } from '@mui/material';

import { CurrencyObject } from 'common/types';
import { currencyOptions, getCurrencyObjectFromCurrencyCode } from 'common/utils/currencyUtils';

export interface CurrencySelectProps
	extends Pick<TextFieldProps, 'variant' | 'error' | 'disabled' | 'size'> {
	value: CurrencyObject | undefined;
	onChange: (currency: CurrencyObject) => void;
}

const CurrencySelect = (props: CurrencySelectProps) => {
	const { value, onChange, ...textFieldProps } = props;

	const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const currency = currencyOptions.find((currency) => currency.code === value);
		const currencyObject = !!currency ? getCurrencyObjectFromCurrencyCode(currency?.code) : null;
		if (currencyObject) {
			onChange(currencyObject);
		}
	};
	return (
		<TextField
			{...textFieldProps}
			select
			value={value?.code}
			onChange={handleCurrencyChange}
			SelectProps={{
				disableUnderline: true,
			}}
			variant={textFieldProps.variant}
			sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
		>
			{currencyOptions.map((currency) => (
				<MenuItem key={currency.code} value={currency.code}>
					{currency.code}
				</MenuItem>
			))}
		</TextField>
	);
};

export default CurrencySelect;
