export class UnsupportedTypeError extends Error {
	constructor(...args: any[]) {
		super(...args);
		this.name = 'UnsupportedTypeError';
		const errorMessage = `Unsupported type provided for the mockdata factory.

If you didnt change the types files please check the following:

1. The mock data factory is up-to-date with current types.

!! If you changed the types file structure. Please note that you are probably about to make braking changes to the data architecture.
This means you need to:

1. Be ready to migrate old data architecture to the new structure.
2. Write new tests for the mock data factory
3. Update mock data factory to provide data according to the new architecture`;
		this.message = errorMessage;
	}
}

export class SetPropertyNotAllowedError extends Error {
	constructor(...args: any[]) {
		super(...args);
		this.name = 'SetPropertyNotAllowedError';
		const errorMessage = args[0] + ' is/are not valid property for ' + args[1] + '!';
		this.message = errorMessage;
	}
}

export class SetPropertyValueNotAllowedTypeError extends Error {
	constructor(...args: any[]) {
		super(...args);
		this.name = 'SetPropertyValueNotAllowedTypeError';
		const errorMessage = args[0] + ' is not valid type for ' + args[1] + '!';
		this.message = errorMessage;
	}
}
