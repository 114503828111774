import { ADVANCE_AND_ABOVE } from 'common/modules/plans/plans/constants';

import { AddOnDefinition, AddOns } from '../types';

const definition: AddOnDefinition = {
	addOn: AddOns.DIN_SETTINGS,
	blockSlots: 1,
	requiresPlan: [...ADVANCE_AND_ABOVE],
};

export default definition;
