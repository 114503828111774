import { TFunction } from 'i18next';

import { SupportArticles } from 'common/modules/support';

import { AddOnDetails } from '..';

const details = (t: TFunction): AddOnDetails => ({
	name: t('common:blocks.details.tekso.name', 'Tekso POS'),
	subHeader: t(
		'common:blocks.details.tekso.subHeader',
		'Handle in-store payments with Tekso point-of-sale',
	),
	description: t(
		'common:blocks.details.tekso.description',
		'Get booking details to Tekso cash register system directly from Rentle. Have bookings automatically marked as paid once they have been paid in Tekso. Decide the most convenient moment to send booking details: when you print a booking confirmation receipt, start a booking or as a separate action',
	),
	supportArticle: SupportArticles.TEKSO,
	uninstallNotice: t(
		'common:blocks.details.tekso.uninstallNotice',
		'By uninstalling Tekso POS, your Tekso configurations will be removed',
	),
});

export default details;
