import { Components, Theme, darken } from '@mui/material/styles';
import { merge } from 'lodash';
import WebFont from 'webfontloader';

import errorHandler from 'common/services/errorHandling/errorHandler';
import { Branding } from 'common/types';
import { notUndefined, removeUndefinedValues } from 'common/utils/common';

import { getBrandingFontFamily, getWebFontloaderGoogleFamilyString } from './fonts';

export const getThemeWithBranding = (theme: Theme, branding: Branding) => {
	const bodyFont = branding.bodyFont ? getBrandingFontFamily(branding.bodyFont) : undefined;
	const titleFont = branding.titleFont ? getBrandingFontFamily(branding.titleFont) : undefined;
	const googleFontFamiliesToLoad = [branding.bodyFont, branding.titleFont]
		.map((font) => (!font ? undefined : getWebFontloaderGoogleFamilyString(font)))
		.filter(notUndefined);
	if (googleFontFamiliesToLoad.length) {
		// We want to add the &display=swap to the last element to get it added as query parameter to the font query
		const familiesWithDisplaySwap = googleFontFamiliesToLoad.map((font, i) =>
			i === googleFontFamiliesToLoad.length - 1 ? `${font}&display=swap` : font,
		);
		try {
			WebFont.load({
				google: {
					families: familiesWithDisplaySwap,
				},
			});
		} catch (e) {
			errorHandler.report(e, { fonts: googleFontFamiliesToLoad.join(', ') });
		}
	}
	const brandingTheme = {
		palette: {
			primary: {
				main: branding.mainColor,
				dark: darken(branding.mainColor, 0.15),
			},
		},
		typography: {
			fontFamily: bodyFont,
			h1: {
				fontFamily: titleFont,
			},
			h2: {
				fontFamily: titleFont,
			},
			h3: {
				fontFamily: titleFont,
			},
			h4: {
				fontFamily: titleFont,
			},
			h5: {
				fontFamily: titleFont,
			},
			h6: {
				fontFamily: titleFont,
			},
			subtitle1: {
				fontFamily: titleFont,
			},
			subtitle2: {
				fontFamily: bodyFont,
			},
			body1: {
				fontFamily: bodyFont,
			},
			body2: {
				fontFamily: bodyFont,
			},
			button: {
				fontFamily: titleFont,
			},
			caption: {
				fontFamily: bodyFont,
			},
			overline: {
				fontFamily: bodyFont,
			},
		},
		components: getShopBrandingComponentStyleOverrides(branding),
	};
	return merge({}, theme, removeUndefinedValues(brandingTheme));
};

export const getShopBrandingComponentStyleOverrides = (branding?: Branding): Components => {
	if (!branding) return {};
	const bodyFont = branding.bodyFont ? getBrandingFontFamily(branding.bodyFont) : undefined;
	const overrides = {
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					fontFamily: bodyFont,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: branding.buttonRadius,
					textTransform: branding.buttonUppercase ? 'uppercase' : 'none',
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				root: {
					borderRadius: branding.buttonRadius,
				},
				extended: {
					borderRadius: branding.buttonRadius,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					'&:after': {
						borderBottomColor: '#000000',
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						color: '#000000',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: `#000000 !important`,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					backgroundColor: '#ffffff',
					'&.Mui-selected': {
						backgroundColor: '#e0e0e0',
					},
					'&.Mui-selected:hover': {
						backgroundColor: '#e0e0e0',
					},
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: '#000000',
					textDecorationColor: '#000000',
				},
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				colorPrimary: {
					color: '#000000',
				},
				colorSecondary: {
					color: '#000000',
				},
			},
		},
	};
	return removeUndefinedValues(overrides);
};
