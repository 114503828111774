import { Address } from 'common/models/_atoms/Address';
import { CancellationPolicy } from 'common/models/_atoms/CancellationPolicy';
import { Channel } from 'common/models/_atoms/Channel';
import type { LocaleField } from 'common/modules/translations/types';
import { CustomCheckoutField } from 'common/types';
import { getLatestProductEndTime } from 'common/utils/dateCalculations';

import { CustomerDetails } from '../CustomerDetails';
import { OrderItemDb } from '../OrderItem/types';
import { OrderPayment } from '../OrderPayment';
import { OrderPricing } from '../OrderPricing';
import { OrderState } from '../OrderState';
import { PersonDb } from '../Person/types';
import { OrderInfoApi, OrderInfoDb } from './types';

export const toApi = (
	data: OrderInfoDb,
	options: { items: OrderItemDb[]; persons: PersonDb[] },
): OrderInfoApi => {
	return {
		id: data.id,
		createdAt: data.created,
		startDate: data.startDate,
		endDate: getLatestProductEndTime(options.items) ?? data.returnTimeNext ?? data.startDate,
		returnedDate: data.endDateReturned ?? undefined,
		payment: OrderPayment.toApi(data.charge),
		deposit: OrderPayment.toApi(data.deposit),
		cancellationPolicy: !!data.cancellationPolicy
			? CancellationPolicy.toApi(data.cancellationPolicy)
			: undefined,
		channel: data.channel === 'CONSUMER' ? 'admin' : Channel.toApi(data.channel),
		state: OrderState.toApi(data.rentalState, { activeState: data.activeState }),
		store: {
			name: data.startLocation.name,
			storeId: data.startLocation.id,
			address: Address.toApi(data.startLocation),
		},
		customCheckoutFields: data.customCheckoutFields
			// The checkout field label in the database can be null.
			// It doesn't make sense to return those through the API.
			?.filter(
				(
					customCheckoutField,
				): customCheckoutField is Omit<CustomCheckoutField, 'label'> & { label: LocaleField } =>
					customCheckoutField.label != null,
			)
			.map((customCheckoutField) => ({
				label: customCheckoutField.label,
				value: customCheckoutField.value,
			})),
		customerDetails: CustomerDetails.toApi(data.responsiblePerson, { persons: options.persons }),
		staffComment: data.additionalInformation,
		orderNumber: data.orderNumber ?? undefined,
		prepared: data.prepared ?? false,
		pricing: OrderPricing.toApi(data.pricing),
		bookingComment: data.bookingComment
			? {
					label: data.bookingComment.label ?? { def: '' },
					value: data.bookingComment.value,
			  }
			: undefined,
		terms: {
			accepted: data.terms.accepted,
			timestamp: data.terms.accepted ? data.terms.timestamp : undefined,
		},
		_internal: {
			acs: !data.acs
				? undefined
				: {
						updateState: data.acs.updateState,
						updateStateTs: data.acs.updateStateTs,
						updateError: data.acs.updateError,
				  },
		},
		metafields: {
			skiData: {
				confirmationNumber: data.skidataProps?.confirmationNumber,
			},
		},
	};
};
