import { OnboardingAction } from 'common/services/analytics/tractionAnalytics';

/** flow ids */
export const FLOW_IDS: Partial<Record<OnboardingAction, string>> = {
	APPEARANCE_ONLINE_STORE: 'c8c983bf-0a17-4ef2-8c53-b9796ca78839',
	BOOKING_COMPLETION: '2723a4c2-946f-4538-b10e-522738e22b2b',
	EDIT_PRODUCT_PRICING: '3a147619-5f7d-493a-a48e-a60f5211c23e',
	EDIT_PRODUCT_QUANTITY: '4e130d46-69c5-4b1f-a1bf-adbad518fd36',
	LINK_ONLINE_STORE: '2300785e-ef5a-4de7-bdcd-3e44666bf753',
	LOGO: '80388ddf-2169-4e80-951d-0fbc0fceb60c',
	PRODUCT_CREATION: 'd69c126d-17d7-4107-bfad-c80da68ec6c8',
	RECOMMEND_ADD_ON_PRODUCTS: 'b9a1f3e9-c618-4d68-8dd7-afae69577f57',
	VIEW_CATALOG: '37dc64c7-80c9-4a6b-8da5-ad56b0478eba',
	VISIT_ONLINE_STORE: '1159119b-3fc0-4463-a232-d857d89e20fa',
};
