import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import Container from 'common/components/Container';
import RentleLogo from 'common/components/RentleLogo';
import { applyActionCode, checkActionCode } from 'common/frontend/firebase/auth';
import PasswordResetDialog from 'components/PasswordResetDialog';
import Spinner from 'components/Spinner';
import { useTranslation } from 'services/localization/useTranslation';
import { LoadingData } from 'services/types';

import InvalidCode from './InvalidCode';

const RecoverEmail = ({ code }: { code: string }) => {
	const [validCode, setValidCode] = useState<LoadingData<boolean>>({
		loading: true,
		data: null,
		error: null,
	});
	const [email, setEmail] = useState<string>('');
	const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		const validateCode = async (code: string) => {
			try {
				const info = await checkActionCode(code);
				setValidCode({
					loading: false,
					data: true,
					error: null,
				});
				const restoredEmail = info.data.email;
				if (restoredEmail) {
					setEmail(restoredEmail);
				}
				await applyActionCode(code);
			} catch (e) {
				setValidCode({
					loading: false,
					data: false,
					error: null,
				});
			}
		};
		validateCode(code);
	}, [code]);

	const handlePasswordResetDialogClose = () => {
		setPasswordResetDialogOpen(false);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} lg={6}>
					{validCode.loading ? (
						<Spinner />
					) : (
						<Container>
							<RentleLogo width={111} type="black" />
							<Box mt={11} mb={6.5}>
								{validCode.data ? (
									<>
										<Typography variant="h5" gutterBottom>
											{t(
												'authActions.recoverEmailHeader',
												'Your sign-in email to Rentle has been changed back',
											)}
											.
										</Typography>
										<Typography>
											{t('authActions.recoverEmailInfo', {
												email: email || '',
												defaultValue:
													'We have changed your Rentle email address back to your original email address {{email}}. In case someone else was trying to change your email, we recommend also changing your password',
											})}
											.
										</Typography>
										<Box mt={4} />
										<Button
											variant="contained"
											color="primary"
											onClick={() => setPasswordResetDialogOpen(true)}
										>
											<Typography variant="caption">
												{t('authActions.resetPassword', 'Reset your password')}
											</Typography>
										</Button>
										{passwordResetDialogOpen && (
											<PasswordResetDialog
												open={passwordResetDialogOpen}
												onClose={handlePasswordResetDialogClose}
												email={email || ''}
											/>
										)}
									</>
								) : (
									<InvalidCode />
								)}
							</Box>
						</Container>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default RecoverEmail;
