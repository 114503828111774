import { createReducer } from '@reduxjs/toolkit';
import { isEmpty, isEqual } from 'lodash';

import * as Actions from 'actions/ActiveProductActions';
import { ActiveProductState } from 'services/types';

const INITIAL_STATE: ActiveProductState = {
	product: {
		loading: true,
		error: null,
		data: null,
	},
	localChanges: {
		product: {},
	},
	saving: false,
	error: null,
	savedAt: null,
};

/** https://redux-toolkit.js.org/api/createReducer */
const ActiveProductReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(Actions.setActiveProductData, (state, action) => {
		/** Whenever the remote data updates, re-diff the local changes */
		const product = action.payload.data;
		const changes = state.localChanges.product;
		if (product && !isEmpty(changes)) {
			const newChanges = Object.keys(state.localChanges.product).reduce((result, field) => {
				if (!isEqual(product[field], changes[field])) {
					result[field] = changes[field];
				}
				return result;
			}, {});

			return {
				...state,
				product: action.payload,
				localChanges: {
					...state.localChanges,
					product: newChanges,
				},
			};
		}

		return {
			...state,
			product: action.payload,
		};
	});

	builder.addCase(Actions.editLocalProduct, (state, action) => {
		return {
			...state,
			localChanges: {
				...state.localChanges,
				product: {
					...state.localChanges.product,
					...action.payload,
				},
			},
		};
	});

	builder.addCase(Actions.resetLocalProduct, (state) => ({
		...state,
		localChanges: INITIAL_STATE.localChanges,
	}));

	builder.addCase(Actions.setSaving, (state, action) => ({
		...state,
		saving: action.payload,
		error: action.payload === true ? null : state.error,
	}));

	builder.addCase(Actions.setError, (state, action) => ({
		...state,
		error: action.payload,
	}));

	builder.addCase(Actions.setSavedAt, (state, action) => ({
		...state,
		savedAt: action.payload,
	}));

	builder.addCase(Actions.clearLocalChanges, (state) => ({
		...state,
		localChanges: INITIAL_STATE.localChanges,
	}));

	builder.addCase(Actions.reset, () => INITIAL_STATE);
});

export default ActiveProductReducer;
