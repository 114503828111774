import { useCallback } from 'react';

import { setNewClientUpdate } from 'actions';
import { isUndefined } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ListingsRouter from 'views/Listings/ListingsRouter';
import DevSettings from 'views/__INTERNAL__/DevSettings';
import DevShortcut from 'views/__INTERNAL__/DevShortcut';

import useClientUpdateChecker from 'common/hooks/useClientUpdateChecker';
import ErrorBoundary from 'common/services/errorHandling/components/ErrorBoundary';
import * as UserActions from 'actions/UserActions';
import ErrorContainer from 'components/ErrorContainer';
import Spinner from 'components/Spinner';
import VisibilityManager from 'components/VisibilityManager';
import HasFeature from 'components/features/HasFeature';
import AddOnModalManager from 'components/modals/AddOnModalManager';
import InstalledAddOnModalManager from 'components/modals/InstalledAddOnModalManager';
import * as ShopSelectors from 'selectors/ShopSelectors';
import * as UserSelectors from 'selectors/UserSelectors';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes, push } from 'routing';

import HomeRouter from './HomeRouter';
import AccountDisabledModal from './components/AccountDisabledModal';
import EarnCreditsModal from './components/EarnCreditsModal';
import SwitchToNewPlansModal from './components/SwitchToNewPlansModal';

const HomeContainer = () => {
	const { t } = useTranslation();
	const hasNewListings = useFeatureFlagEnabled('new-listing-views');
	const dispatch = useDispatch();
	const userError = useSelector(UserSelectors.userError);
	const userLoading = useSelector(UserSelectors.userLoading);
	const userLocations = useSelector(UserSelectors.userLocations);
	const shopError = useSelector(ShopSelectors.activeShopError);
	const shopLoading = useSelector(ShopSelectors.activeShopLoading);

	useClientUpdateChecker(
		'admin',
		useCallback(() => dispatch(setNewClientUpdate(true)), [dispatch]),
	);

	if (userLoading || shopLoading || isUndefined(hasNewListings)) {
		return <Spinner in={true} />;
	}

	if (!!userError) {
		return (
			<ErrorContainer
				title={t('errors.errorGettingUserInformation', 'Error getting user information')}
				description={t(
					'common:errors.pleaseCheckConnectionAndTryAgain',
					'Please check your internet connection and try again',
				)}
				buttons={[
					{
						children: t('common:actions.logout', 'Log out'),
						color: 'primary',
						variant: 'outlined',
						onClick: () => {
							dispatch(UserActions.logout());
							push(Routes.LOGIN);
						},
					},
					{
						children: t('common:actions.refresh'),
						variant: 'contained',
						color: 'primary',
						onClick: () => window.location.reload(),
					},
				]}
			/>
		);
	}

	if (!!shopError) {
		return (
			<ErrorContainer
				title={t('errors.errorGettingShopInformation', 'Error getting shop information')}
				description={t(
					'common:errors.pleaseCheckConnectionAndTryAgain',
					'Please check your internet connection and try again',
				)}
				buttons={[
					{
						children: t('common:actions.refresh'),
						variant: 'contained',
						color: 'primary',
						onClick: () => window.location.reload(),
					},
				]}
			/>
		);
	}

	if (userLocations.length === 0) {
		return (
			<ErrorContainer
				title={t('errors.noAccessToStores', "You don't have access to any stores")}
				description={t(
					'errors.noAccessToStoresDescription',
					'The stores you have had access to may have been deleted, or your access to them may have been removed by an admin user. Please contact one of your admin users.',
				)}
			/>
		);
	}

	return (
		<>
			<Switch>
				{hasNewListings ? <Route path={Routes.LISTINGS} component={ListingsRouter} /> : null}
				<Route path={Routes.ROOT} component={HomeRouter} />
			</Switch>
			<ErrorBoundary>
				<AccountDisabledModal />
				<VisibilityManager />
				<AddOnModalManager />
				<InstalledAddOnModalManager />
				<EarnCreditsModal />
				<SwitchToNewPlansModal />
				<HasFeature requiredFeature="DEV">
					<DevSettings />
					<DevShortcut />
				</HasFeature>
			</ErrorBoundary>
		</>
	);
};

export default HomeContainer;
